import { authenticate } from '../../../lib/decorators'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { get } from '../../../lib/methods'
import type { DeathDeclarationJSON } from '../../../models/DeathDeclaration'

export class DeathDeclarations {
  @authenticate
  static index(clientId: number): ApiRequestDescriptor<DeathDeclarationJSON[]> {
    return get(`/api/v1/clients/${clientId}/deceased/death_declarations`)
  }
}
