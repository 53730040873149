import { defineMessages } from 'react-intl';

export default defineMessages({
  type: { id: 'app.containers.Billing.type' },
  entity: { id: 'app.containers.Billing.entity' },
  entityCityhall: { id: 'app.containers.Billing.entityCityhall' },
  entityConsulate: { id: 'app.containers.Billing.entityConsulate' },
  entityCrematorium: { id: 'app.containers.Billing.entityCrematorium' },
  entityFuneralParlor: { id: 'app.containers.Billing.entityFuneralParlor' },
  entityGraveyard: { id: 'app.containers.Billing.entityGraveyard' },
  entityHospital: { id: 'app.containers.Billing.entityHospital' },
  entityPolice: { id: 'app.containers.Billing.entityPolice' },
  entityPrefecture: { id: 'app.containers.Billing.entityPrefecture' },
  entityRha: { id: 'app.containers.Billing.entityRha' },
  entitySupplier: { id: 'app.containers.Billing.entitySupplier' },
  entityWorship: { id: 'app.containers.Billing.entityWorship' },
  status: { id: 'app.containers.Billing.status' },
  statusNew: { id: 'app.containers.Billing.statusNew' },
  statusValidated: { id: 'app.containers.Billing.statusValidated' },
  statusUnset: { id: 'app.containers.Billing.statusUnset' },
  statusNotBilled: { id: 'app.containers.Billing.statusNotBilled' },
  search: { id: 'app.containers.Billing.search' },
  addPrestation: { id: 'app.containers.Billing.addPrestation' },
  date: { id: 'app.containers.Billing.date' },
  group: { id: 'app.containers.Billing.group' },
  defunctOrClient: { id: 'app.containers.Billing.defunctOrClient' },
  prestation: { id: 'app.containers.Billing.prestation' },
  priceTaxExcl: { id: 'app.containers.Billing.priceTaxExcl' },
  priceTaxIncl: { id: 'app.containers.Billing.priceTaxIncl' },
  actions: { id: 'app.containers.Billing.actions' },
  noResult: { id: 'app.containers.Billing.noResult' },
  clearConfirmationTitle: {
    id: 'app.containers.Billing.clearConfirmationTitle',
  },
  clearPendingPayments: { id: 'app.containers.Billing.clearPendingPayments' },
  clearConfirmationText: { id: 'app.containers.Billing.clearConfirmationText' },
  clearConfirmationCancel: {
    id: 'app.containers.Billing.clearConfirmationCancel',
  },
  clearConfirmationConfirm: {
    id: 'app.containers.Billing.clearConfirmationConfirm',
  },
  downloadPayments: {
    id: 'app.containers.Billing.downloadPayments',
  },
  downloadConfirmationTitle: {
    id: 'app.containers.Billing.downloadConfirmationTitle',
  },
  downloadConfirmationText: {
    id: 'app.containers.Billing.downloadConfirmationText',
  },
  downloadConfirmationCancel: {
    id: 'app.containers.Billing.downloadConfirmationCancel',
  },
  downloadConfirmationConfirm: {
    id: 'app.containers.Billing.downloadConfirmationConfirm',
  },
  error: { id: 'app.containers.Billing.error' },
  closeError: { id: 'app.containers.Billing.closeError' },
});
