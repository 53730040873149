import { call, takeLatest, put } from 'redux-saga/effects';
import { fetchDocuments } from 'containers/Documents/sagas';
import { getBookingInfo } from 'containers/Deal/sagas';

import { fetchItems } from '../Sections/Todolist/thunk.ts';
import { fetchPaymentsDetails } from '../Payments/thunk.ts';
import { GET_DEAL_MARBLE } from './constants';

export function* getDeal({ deal }) {
  const { uuid } = deal;
  yield put(fetchItems());
  yield call(fetchDocuments, { dealUUID: uuid });
  yield put(fetchPaymentsDetails(uuid));
  yield call(getBookingInfo, { dealUUID: uuid });
}

export default function* dealMarbleData() {
  yield takeLatest(GET_DEAL_MARBLE, getDeal);
}
