import { MimeTypes } from 'utils/constants';

export const DOCUMENT_TEMPLATE_EDITOR = 'DocumentTemplateEditor';

export const DEFAULT_FONT_SIZE = 9;
export const DEFAULT_ZOOM = 1;

export const MIN_INPUT_WIDTH = 20;
export const MIN_INPUT_HEIGHT = 10;
export const MIN_ZOOM = 1;
export const MAX_ZOOM = 3;

export const BACKGROUND_MIME_TYPE = MimeTypes.JPG;
export const BACKGROUND_QUALITY = 0.82;
