// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hospital-module__container--EgVQg{position:relative;width:100%;display:flex;flex-grow:1;overflow:hidden}.Hospital-module__section--j0QF9{flex-grow:1;padding:16px;overflow:auto}`, "",{"version":3,"sources":["webpack://./src/containers/Hospital/Hospital.module.scss"],"names":[],"mappings":"AAAA,mCACE,iBAAA,CACA,UAAA,CACA,YAAA,CACA,WAAA,CACA,eAAA,CAGF,iCACE,WAAA,CACA,YAAA,CACA,aAAA","sourcesContent":[".container {\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.section {\n  flex-grow: 1;\n  padding: 16px;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Hospital-module__container--EgVQg`,
	"section": `Hospital-module__section--j0QF9`
};
export default ___CSS_LOADER_EXPORT___;
