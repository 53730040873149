import { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import {
  Button,
  ErrorText,
  FormattedApiError,
  ModalTitle,
  NumberInput,
  Spinner,
  SubmitButton,
  Text,
} from '@advitam/ui';

import crudMessages from 'containers/Crud/messages';
import actionsMessages from 'messages/actions';
import { assert } from 'lib/support';

import { makeSelectPaymentRefundableMaxAmount } from '../selectors';
import messages from './messages';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectPaymentToRefund,
} from './selectors';
import { refundPayment } from './thunk';
import style from './RefundModal.module.scss';

const AMOUNT_FIELD = 'amount';
const PRICE_STEP = 0.01;

function b(chunks: ReactNode): JSX.Element {
  return <b>{chunks}</b>;
}

interface RefundForm {
  [AMOUNT_FIELD]: number;
}

interface ContentProps {
  onCancel: () => void;
}

export default function Content({ onCancel }: ContentProps): JSX.Element {
  const dispatch = useDispatch();
  const error = useSelector(makeSelectError());
  const isLoading = useSelector(makeSelectIsLoading());

  const paymentToRefund = useSelector(makeSelectPaymentToRefund());
  assert(paymentToRefund !== null);
  const refundableAmount = useSelector(
    makeSelectPaymentRefundableMaxAmount(paymentToRefund),
  );

  const onSubmit = useCallback(
    ({ amount }: RefundForm) => {
      dispatch(refundPayment(amount));
    },
    [dispatch],
  );

  return (
    <>
      <ModalTitle>
        <FormattedMessage id={messages.title.id} />
      </ModalTitle>

      <Text tagName="p">
        <FormattedMessage
          id={messages.text.id}
          values={{ amount: refundableAmount, b }}
        />
      </Text>

      <Form<RefundForm> onSubmit={onSubmit}>
        {({ handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <NumberInput
              required
              name={AMOUNT_FIELD}
              label={<FormattedMessage id={messages.label.id} />}
              suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              step={PRICE_STEP}
              min={PRICE_STEP}
              max={refundableAmount}
            />

            {error && (
              <ErrorText className={style.error}>
                <FormattedApiError error={error} />
              </ErrorText>
            )}

            <div className={style.actions}>
              <SubmitButton
                primary
                disabled={isLoading}
                icon={isLoading ? <Spinner /> : undefined}
                text={<FormattedMessage id={actionsMessages.confirm.id} />}
              />
              <Button
                outline
                onClick={onCancel}
                text={<FormattedMessage id={actionsMessages.cancel.id} />}
                className={style.cancel}
              />
            </div>
          </form>
        )}
      </Form>
    </>
  );
}
