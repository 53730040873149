import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ErrorModal from 'components/ErrorModal';
import HardSpinner from 'components/HardSpinner';

import PaymentList from './PaymentList';
import Actions from './Actions';
import RefundModal from './RefundModal';
import { makeSelectError, makeSelectIsLoading } from './selectors';
import messages from './messages';

interface PaymentsProps {
  dealUUID: string;
}

export default function Payments({ dealUUID }: PaymentsProps): JSX.Element {
  const error = useSelector(makeSelectError());
  const isLoading = useSelector(makeSelectIsLoading());

  return (
    <>
      <section id="payments">
        {isLoading && <HardSpinner />}
        {error && <ErrorModal error={error} />}
        <FormattedMessage id={messages.title.id} tagName="h2" />
        <div className="dealSection">
          <Actions dealUUID={dealUUID} />
          <PaymentList dealUUID={dealUUID} />
        </div>
      </section>
      <RefundModal />
    </>
  );
}
