import { PDFDocument, PDFPage } from 'pdf-lib';

async function download(file: string): Promise<ArrayBuffer> {
  const response = await fetch(file);
  if (!response.ok) {
    throw new Error('download failed');
  }

  const blob = await response.blob();
  return blob.arrayBuffer();
}

async function copyPages(
  result: PDFDocument,
  buffer: ArrayBuffer,
): Promise<PDFPage[]> {
  const doc = await PDFDocument.load(buffer);
  return result.copyPages(doc, doc.getPageIndices());
}

export async function merge(files: string[]): Promise<Uint8Array> {
  const documents = await Promise.all(files.map(file => download(file)));

  const result = await PDFDocument.create();
  const pages = await Promise.all(
    documents.map(buffer => copyPages(result, buffer)),
  );

  pages.flat().forEach(page => result.addPage(page));

  return result.save();
}
