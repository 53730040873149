import { assert, isNil } from '@advitam/support'

import { Validator, ValidatorReturnType } from './Validator'
import { ValueType } from './ValueType'

const ERROR = 'too_long'

export function maxLength(length: number): Validator<string | null> {
  return (value: ValueType): ValidatorReturnType => {
    assert(
      isNil(value) || typeof value === 'string' || Array.isArray(value),
      'maxLength validator may only be used on strings and arrays',
    )

    if (!value || value.length <= length) {
      return undefined
    }

    return ERROR
  }
}
