import { useMemo } from 'react'

import { useDeepCompareMemoize } from './useDeepCompareMemoize'

type UseEffectParams = Parameters<typeof useMemo>
type DependencyList = UseEffectParams[1]

export function useDeepCompareMemo<T>(factory: () => T, deps: DependencyList | undefined): T {
  return useMemo(factory, useDeepCompareMemoize(deps))
}
