import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

import {
  Button,
  ConfirmationModal,
  Container,
  ProgressBar,
  SubmitButton,
} from '@advitam/ui';
import actionsMessages from 'messages/actions';

import {
  makeSelectIsLoading,
  makeSelectProcessedUploadCount,
  makeSelectTotalFilesCount,
} from '../selectors';
import { reset } from '../slice';
import EditActions from './EditActions';
import messages from './messages';
import style from './Header.module.scss';

export default function Header(): JSX.Element {
  const dispatch = useDispatch();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const isLoading = useSelector(makeSelectIsLoading());
  const totalFilesCount = useSelector(makeSelectTotalFilesCount());
  const processedUploadCount = useSelector(makeSelectProcessedUploadCount());

  const onCancelUpload = (): void => {
    dispatch(reset());
    setIsCancelModalOpen(false);
  };

  return (
    <Container className={style.container}>
      <EditActions />
      {isLoading && (
        <ProgressBar
          current={processedUploadCount}
          total={totalFilesCount}
          className={style.progression}
        />
      )}
      <div className={style.save_actions}>
        <Button
          outline
          disabled={isLoading}
          text={<FormattedMessage id={actionsMessages.cancel.id} />}
          onClick={(): void => setIsCancelModalOpen(true)}
        />
        <SubmitButton
          primary
          disabled={isLoading}
          text={<FormattedMessage id={actionsMessages.save.id} />}
        />
      </div>
      <ConfirmationModal
        className="col-6"
        isOpen={isCancelModalOpen}
        text={<FormattedMessage id={messages.cancelText.id} />}
        cancel={<FormattedMessage id={actionsMessages.cancel.id} />}
        onCancel={(): void => setIsCancelModalOpen(false)}
        confirm={<FormattedMessage id={actionsMessages.confirm.id} />}
        onConfirm={onCancelUpload}
      />
    </Container>
  );
}
