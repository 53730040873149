import type { ReactNode } from 'react'

import Link from '../../../../Link'
import { useSetIsMenuOpen } from '../../hooks'
import style from '../../Menu.module.scss'

interface MenuLinkProps {
  text: ReactNode
  href: string
  internal?: boolean
}

export default function MenuLink({ text, href, internal }: MenuLinkProps): JSX.Element {
  const setIsMenuOpen = useSetIsMenuOpen()
  return (
    <Link
      internal={internal}
      href={href}
      className={style.link}
      onClick={(): void => setIsMenuOpen(false)}
    >
      <span className={style.link_text}>{text}</span>
    </Link>
  )
}
