import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { CommunicationChannelType } from '@advitam/api/models/Deal/Communication/ChannelType';
import { ResourceList } from '@advitam/ui';
import type {
  Filter,
  FilterSelection,
} from '@advitam/ui/components/ResourceList/Filters/types';
import { makeSelectFuneralTeam } from 'slices/data';

import { RecipientType } from './types';
import messages from './messages';
import { makeSelectFilters } from './selectors';
import { setFilters } from './slice';
import style from './History.module.scss';

export default function SuppliersFilters(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const team = useSelector(makeSelectFuneralTeam());
  const filters = useSelector(makeSelectFilters());

  const filtersShape: Filter[] = [
    {
      type: 'Select',
      name: 'channelType',
      placeholder: intl.formatMessage(messages.channelTypeFilter),
      items: [
        {
          value: CommunicationChannelType.EMAIL,
          name: intl.formatMessage(messages.email),
        },
        {
          value: CommunicationChannelType.FAX,
          name: intl.formatMessage(messages.fax),
        },
        {
          value: CommunicationChannelType.SMS,
          name: intl.formatMessage(messages.sms),
        },
        {
          value: CommunicationChannelType.LETTER,
          name: intl.formatMessage(messages.letter),
        },
        {
          value: CommunicationChannelType.PHONE,
          name: intl.formatMessage(messages.phone),
        },
      ],
      initialValue: filters.channelType,
    },
    {
      type: 'Select',
      name: 'user',
      placeholder: intl.formatMessage(messages.userFilter),
      items: team
        .filter(({ disabled }) => !disabled)
        .map(({ id, name, email }) => ({ value: id, name: name || email })),
      initialValue: filters.user,
    },
    {
      type: 'Select',
      name: 'recipientType',
      placeholder: intl.formatMessage(messages.recipientTypeFilter),
      items: [
        {
          value: RecipientType.FAMILY,
          name: intl.formatMessage(messages.familyType),
        },
        {
          value: RecipientType.SUPPLIER,
          name: intl.formatMessage(messages.supplierType),
        },
        {
          value: RecipientType.ADMINISTRATION,
          name: intl.formatMessage(messages.administrationType),
        },
        {
          value: RecipientType.THIRD_PARTY,
          name: intl.formatMessage(messages.thirdPartyType),
        },
      ],
      initialValue: filters.recipientType,
    },
    {
      type: 'DateRange',
      startName: 'from',
      endName: 'to',
      placeholder: intl.formatMessage(messages.dateFilter),
      initialStartValue: filters.from,
      initialEndValue: filters.to,
    },
  ];

  const onChange = useCallback(
    (newFilters: Record<string, FilterSelection>) => {
      dispatch(setFilters(newFilters));
    },
    [dispatch],
  );

  const searchFilter = useMemo(
    () => ({
      name: 'q',
      placeholder: intl.formatMessage(messages.search),
      initialValue: filters.q,
    }),
    [filters.q, intl],
  );

  return (
    <ResourceList.Filters
      onChange={onChange}
      filters={filtersShape}
      className={style.filters}
      searchFilter={searchFilter}
    />
  );
}
