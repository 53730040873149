import { defineMessages } from 'react-intl';

export default defineMessages({
  addDocument: { id: 'app.containers.DocumentTemplateEditor.addDocument' },
  inputSearchLabel: {
    id: 'app.containers.DocumentTemplateEditor.inputSearchLabel',
  },
  inputSearchTooltip: {
    id: 'app.containers.DocumentTemplateEditor.inputSearchTooltip',
  },
  inputSearchPlaceholder: {
    id: 'app.containers.DocumentTemplateEditor.inputSearchPlaceholder',
  },
  preview: { id: 'app.containers.DocumentTemplateEditor.preview' },
  zoom: { id: 'app.containers.DocumentTemplateEditor.zoom' },

  decreaseFontSize: {
    id: 'app.containers.DocumentTemplateEditor.decreaseFontSize',
  },
  increaseFontSize: {
    id: 'app.containers.DocumentTemplateEditor.increaseFontSize',
  },
  decreaseZoom: { id: 'app.containers.DocumentTemplateEditor.decreaseZoom' },
  increaseZoom: { id: 'app.containers.DocumentTemplateEditor.increaseZoom' },
  save: { id: 'app.containers.DocumentTemplateEditor.save' },
  cancel: { id: 'app.containers.DocumentTemplateEditor.cancel' },

  unsupportedMimeType: {
    id: 'app.containers.DocumentTemplateEditor.unsupportedMimeType',
  },
  text: { id: 'app.containers.DocumentTemplateEditor.text' },
  checkbox: { id: 'app.containers.DocumentTemplateEditor.checkbox' },
  circle: { id: 'app.containers.DocumentTemplateEditor.circle' },
  check: { id: 'app.containers.DocumentTemplateEditor.check' },
  manualZone: { id: 'app.containers.DocumentTemplateEditor.manualZone' },
  signature: { id: 'app.containers.DocumentTemplateEditor.signature' },
  currentDate: {
    id: 'app.containers.DocumentTemplateEditor.currentDate',
  },

  inputSignature: {
    id: 'app.containers.DocumentTemplateEditor.inputSignature',
  },
});
