import { authenticate } from '../../../lib/decorators'
import { post } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import serialize from './serialize'

import { EditableDocumentJSON } from '../../../models/Documents/Editable'
import { PolymorphicEntity } from '../../../models/PolymorphicEntity'

export class Documents {
  @authenticate
  static create(
    document: EditableDocumentJSON,
    owner: PolymorphicEntity,
  ): ApiRequestDescriptor<EditableDocumentJSON> {
    return post(
      `/api/v1/document_types/${document.document_type.slug}/documents`,
      serialize(document, owner),
    )
  }
}
