import { pick } from 'lodash';

import { DocumentTemplate } from 'models/DocumentTemplate';
import { PublicUpload, PublicUploadJSON } from 'models/PublicUpload';

type PublicUploadIds = Pick<PublicUpload | PublicUploadJSON, 'id'>[];
type DocumentTemplateFields<DataType> = Pick<
  DocumentTemplate<DataType>,
  'resource_type' | 'resource_id' | 'liquid_template' | 'data'
>;

interface DocumentTemplatePayload<DataType>
  extends DocumentTemplateFields<DataType> {
  public_upload_ids: number[];
}

interface DocumentTemplateParam<DataType>
  extends DocumentTemplateFields<DataType> {
  public_uploads: PublicUploadIds;
}

export function serialize<DataType>(
  tpl: DocumentTemplateParam<DataType>,
): DocumentTemplatePayload<DataType> {
  return {
    ...pick(tpl, 'resource_type', 'resource_id', 'liquid_template', 'data'),
    public_upload_ids: tpl.public_uploads.map(up => up.id),
  };
}
