import { ReactNode } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsPopup } from 'containers/Crud';
import { assert } from 'lib/Assert';

import Sidebar from '../Sidebar';
import { makeSelectRawFuneralParlor } from '../selectors';
import { updateFuneralParlorName } from '../thunk';
import style from '../FuneralParlor.module.scss';
import type { FuneralParlorForm } from './types';

interface WrapperProps<T> {
  children: ReactNode;
  initialSectionValues?: T;
  onSubmit: (values: FuneralParlorForm<T>) => void;
}

export default function Wrapper<T>({
  children,
  initialSectionValues,
  onSubmit,
}: WrapperProps<T>): JSX.Element {
  const dispatch = useDispatch();
  const funeralParlor = useSelector(makeSelectRawFuneralParlor());
  assert(funeralParlor !== null);

  const initialValues: Partial<FuneralParlorForm<T>> = {
    funeralParlor,
    sectionValues: initialSectionValues,
  };

  const handleFormSubmit = (values: FuneralParlorForm<T>): void => {
    if (
      values.sectionValues &&
      funeralParlor.name !== values.funeralParlor.name
    ) {
      dispatch(updateFuneralParlorName(values.funeralParlor.name));
    }

    onSubmit(values);
  };

  return (
    <Form<FuneralParlorForm<T>>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <Sidebar />
          {(dirty || !funeralParlor.id) && (
            <ActionsPopup isNew={!funeralParlor.id} />
          )}
          {children}
        </form>
      )}
    </Form>
  );
}
