import { IntlShape, useIntl } from 'react-intl';

import { ClientAbility, ResourceType } from 'models/Client/Ability';
import { ClientResourceDeal } from 'models/Client/Resource/Deal';
import { DealType } from 'models/Deal/Type';
import { ClientResourceMemorial } from 'models/Client/Resource/Memorial';
import { ClientResourceAdministrativeProcedure } from 'models/Client/Resource/AdministrativeProcedure';

import { Person } from '..';
import { formatSection } from './FormattedSection';
import messages from './messages';

const simpleResourceMessages = {
  [ResourceType.SAFE_KEEPER]: messages.safeKeeperOf,
  [ResourceType.FUNERAL_WILL]: messages.funeralWillsOf,
};

export function formatName(ability: ClientAbility, intl: IntlShape): string {
  if (
    ability.resource_type === ResourceType.SAFE_KEEPER ||
    ability.resource_type === ResourceType.FUNERAL_WILL
  ) {
    const name = Person.formatName(ability.resource.owner, intl);
    const message = simpleResourceMessages[ability.resource_type];
    return intl.formatMessage(message, { name });
  }

  if (
    ability.resource instanceof ClientResourceDeal &&
    ability.resource.type !== DealType.FUNERAL
  ) {
    return Person.formatName(ability.resource.owner, intl);
  }

  if (
    ability.resource instanceof ClientResourceDeal ||
    ability.resource instanceof ClientResourceAdministrativeProcedure ||
    ability.resource instanceof ClientResourceMemorial
  ) {
    if (ability.resource.defunct) {
      return Person.formatName(ability.resource.defunct, intl);
    }
  }

  return formatSection(ability.resource_type, intl);
}

interface FormattedNameProps {
  ability: ClientAbility;
}

export function FormattedName({ ability }: FormattedNameProps): JSX.Element {
  const intl = useIntl();
  return <>{formatName(ability, intl)}</>;
}
