import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from 'containers/Crud';

import { saveWarehouse } from '../../thunk';
import type { MiscellaneousForm } from './types';
import Wrapper from '../Wrapper';
import { makeSelectMiscellaneousFormInitialValues } from './selectors';
import Content from './Content';

export default function Miscellaneous(): JSX.Element {
  const dispatch = useDispatch();

  const initialValues = useSelector(makeSelectMiscellaneousFormInitialValues());

  const onSubmit = useCallback(
    (values: MiscellaneousForm) => {
      dispatch(saveWarehouse(values.warehouse));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={initialValues}>
      <Layout.Container>
        <Layout.FieldsetGrid>
          <Content />
        </Layout.FieldsetGrid>
      </Layout.Container>
    </Wrapper>
  );
}
