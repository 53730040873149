import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'app.containers.DealFuneral.MemorialSection.Settings.title' },
  isOpen: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.isOpen',
  },
  isOpenDetails: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.isOpenDetails',
  },
  picture: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.picture',
  },
  calendar: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.calendar',
  },
  calendarDetails: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.calendarDetails',
  },
  attendees: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.attendees',
  },
  attendeesDetails: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.attendeesDetails',
  },
  flowers: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.flowers',
  },
  flowersDetails: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.flowersDetails',
  },
  trees: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.trees',
  },
  treesDetails: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.treesDetails',
  },
  fundingPot: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.fundingPot',
  },
  fundingPotDetails: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.fundingPotDetails',
  },
  indexing: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.indexing',
  },
  indexingDetails: {
    id: 'app.containers.DealFuneral.MemorialSection.Settings.indexingDetails',
  },
});
