import { createSelector, Selector } from 'reselect';
import { Crematorium, CrematoriumJSON } from '@advitam/api/models/Crematorium';
import { SerializedApiError } from '@advitam/api';
import { CREMATORIUM } from './constants';
import { AppStateSubset, State } from './slice';
import { NewCrematorium } from './types';

type CrematoriumSelectors<T> = Selector<AppStateSubset, T>;

const selectCrematoriumDomain = (state: AppStateSubset): State =>
  state[CREMATORIUM];

export const makeSelectRawCrematorium = (): CrematoriumSelectors<
  CrematoriumJSON | NewCrematorium | null
> => createSelector(selectCrematoriumDomain, ({ crematorium }) => crematorium);

export const makeSelectCrematorium = (): CrematoriumSelectors<Crematorium | null> =>
  createSelector(makeSelectRawCrematorium(), rawCrematorium =>
    rawCrematorium?.id ? new Crematorium(rawCrematorium) : null,
  );

export const makeSelectIsLoading = (): CrematoriumSelectors<boolean> =>
  createSelector(selectCrematoriumDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): CrematoriumSelectors<boolean> =>
  createSelector(selectCrematoriumDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): CrematoriumSelectors<boolean> =>
  createSelector(selectCrematoriumDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): CrematoriumSelectors<boolean> =>
  createSelector(
    selectCrematoriumDomain,
    ({ isUpdatingName }) => isUpdatingName,
  );

export const makeSelectError = (): CrematoriumSelectors<SerializedApiError | null> =>
  createSelector(selectCrematoriumDomain, ({ error }) => error);

export const makeSelectDestroyError = (): CrematoriumSelectors<SerializedApiError | null> =>
  createSelector(selectCrematoriumDomain, ({ destroyError }) => destroyError);
