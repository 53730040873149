import { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import Switch from 'components/Form/Switch';
import style from './style.scss';

interface SectionWrapperProps {
  title: MessageDescriptor;
  isSwitchable?: boolean;
  switched?: boolean;
  onSwitch?: (value: boolean) => void;
  className?: string;
  children: ReactNode | ReactNode[];
}

export default function SectionWrapper({
  title,
  isSwitchable,
  switched,
  onSwitch,
  className = '',
  children,
}: SectionWrapperProps): JSX.Element {
  return (
    <div className={`${style.section} ${className}`}>
      <div className={style.header}>
        <FormattedMessage {...title} tagName="h3" />
        {isSwitchable && (
          <Switch
            value={switched || false}
            onChange={({ target: { value } }): void => {
              if (onSwitch) {
                onSwitch(value);
              }
            }}
          />
        )}
      </div>
      {children}
    </div>
  );
}
