import { ReactNode } from 'react';
import Modal from 'react-modal';

import style from './style.scss';

interface FullscreenModalProps {
  children: ReactNode | ReactNode[];
  isOpen?: boolean;
}

export function FullscreenModal({
  isOpen = true,
  children,
}: FullscreenModalProps): JSX.Element | null {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={style.fullscreen__overlay}
      className={isOpen ? style.fullscreen : style['modal--closed']}
    >
      {children}
    </Modal>
  );
}
