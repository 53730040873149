import { createSelector, Selector } from 'reselect';

import { Cityhall, CityhallJSON } from '@advitam/api/models/Cityhall';
import type { SerializedApiError } from '@advitam/api';

import { CITYHALL } from './constants';
import type { AppStateSubset, State } from './slice';

type CityhallSelector<T> = Selector<AppStateSubset, T>;

const selectCityhallDomain = (state: AppStateSubset): State => state[CITYHALL];

export const makeSelectRawCityhall = (): CityhallSelector<CityhallJSON | null> =>
  createSelector(selectCityhallDomain, ({ cityhall }) => cityhall);

export const makeSelectCityhall = (): CityhallSelector<Cityhall | null> =>
  createSelector(makeSelectRawCityhall(), rawCityhall =>
    rawCityhall?.id ? new Cityhall(rawCityhall) : null,
  );

export const makeSelectIsLoading = (): CityhallSelector<boolean> =>
  createSelector(selectCityhallDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): CityhallSelector<boolean> =>
  createSelector(selectCityhallDomain, ({ isSaving }) => isSaving);

export const makeSelectIsUpdatingName = (): CityhallSelector<boolean> =>
  createSelector(selectCityhallDomain, ({ isUpdatingName }) => isUpdatingName);

export const makeSelectError = (): CityhallSelector<SerializedApiError | null> =>
  createSelector(selectCityhallDomain, ({ error }) => error);
