import { Model } from '../Model';
import { Deal } from '../Deal';
import { Defunct, DefunctFields } from './Defunct';
import { Step as MemorialStep, StepJSON as MemorialStepJSON } from './Step';

type MemorialDeal = Pick<Deal, 'id' | 'uuid'>;

export interface MemorialSettings {
  pot_enabled: boolean;
  calendar_enabled: boolean;
  attendance_enabled: boolean;
  flowers_enabled: boolean;
  trees_enabled: boolean;
  search_index_enabled: boolean | null;
  associations_enabled: boolean;
}

interface MemorialBase extends MemorialSettings {
  id: number;
  uuid?: string;
  p_link: string;
  message: string | null;
  url: string;
  association_url: string;
  deal: MemorialDeal;
  open: boolean;
  profile_image?: Blob | null;
}

export interface MemorialJSON extends MemorialBase {
  defunct: DefunctFields;
  steps: MemorialStepJSON[];
  created_at: string;
  updated_at: string;
}

interface Memorial extends MemorialBase {
  defunct: Defunct;
  steps: MemorialStep[];
  created_at: Date;
  updated_at: Date;
}

const emptyMemorial = {} as MemorialJSON;

class Memorial extends Model<MemorialJSON> {
  constructor(data: MemorialJSON = emptyMemorial) {
    super(data);
    this.defunct = new Defunct(data.defunct || undefined);

    this.created_at = Model.parseDate(data.created_at);
    this.updated_at = Model.parseDate(data.updated_at);

    this.steps = (data.steps || []).map(s => new MemorialStep(s));
    this.steps.sort((lhs, rhs) => {
      const lhsTime = lhs.date ? lhs.date.getTime() : -1;
      const rhsTime = rhs.date ? rhs.date.getTime() : -1;
      return lhsTime - rhsTime;
    });
  }

  get settings(): MemorialSettings {
    return {
      pot_enabled: this.pot_enabled,
      calendar_enabled: this.calendar_enabled,
      attendance_enabled: this.attendance_enabled,
      flowers_enabled: this.flowers_enabled,
      trees_enabled: this.trees_enabled,
      search_index_enabled: this.search_index_enabled,
      associations_enabled: this.associations_enabled,
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Memorial {
  export type Step = MemorialStep;
}

export { Memorial };
