/*
 * UrnPickup Messages
 *
 * This contains all the text for the UrnPickup components.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  ashesDestination: {
    id: 'app.components.DealFuneral.StepEditors.ashesDestination',
    defaultMessage: 'Ashes destination',
  },
  destinationChangeDialogHeader: {
    id: 'app.components.DealFuneral.StepEditors.destinationChangeDialogHeader',
    defaultMessage: 'Warning !',
  },
  destinationChangeDialog: {
    id: 'app.components.DealFuneral.StepEditors.destinationChangeDialog',
    defaultMessage:
      'This will cause the deletion of the destination step, do you want to continue ?',
  },
  family: {
    id: 'app.components.DealFuneral.StepEditors.UrnPickup.family',
    defaultMessage: 'Handover to family ',
  },
  foreignLand: {
    id: 'app.components.DealFuneral.StepEditors.UrnPickup.foreignLand',
    defaultMessage: 'Handover to family for foreign land departure ',
  },
  interment: {
    id: 'app.components.DealFuneral.StepEditors.UrnPickup.interment',
    defaultMessage: 'Interment ',
  },
  dispersion: {
    id: 'app.components.DealFuneral.StepEditors.UrnPickup.dispersion',
    defaultMessage: 'Dispersion in the gardens of remembrance ',
  },
  urnSealing: {
    id: 'app.components.DealFuneral.StepEditors.UrnPickup.urnSealing',
    defaultMessage: 'Scellement',
  },
});
