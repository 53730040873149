import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { GraveyardJSON } from '@advitam/api/models/Graveyard';
import { assert } from 'lib/support';

import { GRAVEYARD } from './constants';
import { NewGraveyard } from './types';
import {
  createGraveyard,
  destroyGraveyard,
  fetchGraveyard,
  setIsGraveyardDisabled,
  updateGraveyard,
  updateGraveyardName,
} from './thunk';

export interface State {
  graveyard: GraveyardJSON | NewGraveyard | null;
  isLoading: boolean;
  isSaving: boolean;
  isDestroying: boolean;
  isUpdatingName: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [GRAVEYARD]: State;
}

export const initialState: State = {
  graveyard: null,
  isLoading: false,
  isSaving: false,
  isDestroying: false,
  isUpdatingName: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: GRAVEYARD,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setGraveyard: (
      state,
      { payload }: PayloadAction<GraveyardJSON | NewGraveyard | null>,
    ) => {
      state.graveyard = payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchGraveyard.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchGraveyard.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.graveyard = payload;
    });
    builder.addCase(fetchGraveyard.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createGraveyard.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(createGraveyard.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(createGraveyard.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateGraveyard.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateGraveyard.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.graveyard = payload;
    });
    builder.addCase(updateGraveyard.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateGraveyardName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updateGraveyardName.fulfilled, (state, { payload }) => {
      state.isUpdatingName = false;
      state.graveyard = payload;
    });
    builder.addCase(updateGraveyardName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyGraveyard.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyGraveyard.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroyGraveyard.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setIsGraveyardDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setIsGraveyardDisabled.fulfilled, (state, { meta }) => {
      state.isSaving = false;
      assert(state.graveyard !== null);
      state.graveyard.disabled = meta.arg;
    });
    builder.addCase(setIsGraveyardDisabled.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setGraveyard } = slice.actions;
export default slice;
