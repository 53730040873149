import {
  GET_STATS,
  SET_STATS,
  GET_STATS_PER_MONTH,
  SET_STATS_PER_MONTH,
  ERROR,
} from './constants';

export function getStats(uid) {
  return {
    type: GET_STATS,
    uid,
  };
}

export function setStats(stats) {
  return {
    type: SET_STATS,
    stats,
  };
}

export function getStatsPerMonth(uid, month) {
  return {
    type: GET_STATS_PER_MONTH,
    uid,
    month,
  };
}

export function setStatsPerMonth(statsPerMonth) {
  return {
    type: SET_STATS_PER_MONTH,
    statsPerMonth,
  };
}

export function error(err) {
  return {
    type: ERROR,
    err,
  };
}
