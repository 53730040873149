import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import {
  DeathDeclarationJSON,
  DeathDeclarationState,
} from '@advitam/api/models/DeathDeclaration';

import { DEATH_DECLARATION_VALIDATION } from './constants';
import { fetchClient } from '../thunk';

export const updateDeathDeclaration = createAsyncThunk(
  `${DEATH_DECLARATION_VALIDATION}/UPDATE`,
  async (declaration: DeathDeclarationJSON, { dispatch, rejectWithValue }) => {
    let savedDeclaration: DeathDeclarationJSON;

    try {
      const { body } = await request(
        Api.V1.DeathDeclarations.State.update(declaration),
      );
      savedDeclaration = body;
    } catch (error) {
      return rejectWithValue(error);
    }

    if (declaration.state === DeathDeclarationState.ACCEPTED) {
      await dispatch(fetchClient(declaration.deceased_client.id));
    }

    return savedDeclaration;
  },
);
