import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import { LOCALE, TranslationMessages } from './constants';

interface LanguageProviderProps {
  messages: TranslationMessages;
  children: ReactNode | ReactNode[];
}

export default function LanguageProvider({
  messages,
  children,
}: LanguageProviderProps): JSX.Element {
  return (
    <IntlProvider locale={LOCALE} key={LOCALE} messages={messages[LOCALE]}>
      {children}
    </IntlProvider>
  );
}
