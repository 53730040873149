import { Objects } from '@advitam/support'

import type { EntityInvoiceEntityJSON } from '../../../models/EntityInvoice/Entity'
import type { EntityInvoiceGroupJSON } from '../../../models/EntityInvoice/Group'

import type { NewEntityInvoice, NewEntityInvoicePrestation } from './types'

interface GroupPayload {
  entity: Pick<EntityInvoiceEntityJSON, 'id' | 'type'>
  group: Omit<EntityInvoiceGroupJSON, 'name'>
}

interface CreationPayload extends GroupPayload {
  cost_ht: number
  cost_ttc: number
  description: string
  deal_id: number
}

export function serializeGroup(invoice: NewEntityInvoice): GroupPayload {
  return {
    entity: Objects.pick(invoice.entity, 'id', 'type'),
    group: Objects.pick(invoice.group, 'id', 'type', 'year'),
  }
}

export function serializeCreation(
  invoice: NewEntityInvoice,
  prestation: NewEntityInvoicePrestation,
): CreationPayload {
  return {
    ...Objects.pick(prestation, 'cost_ht', 'cost_ttc', 'description'),
    deal_id: prestation.deal.id,
    ...serializeGroup(invoice),
  }
}

export function serializeUpdate(invoice: NewEntityInvoice): GroupPayload {
  return serializeGroup(invoice)
}
