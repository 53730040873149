import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Select } from 'components/Input/SelectList';
import { NumberInput } from 'components/Input';

import { CoffinWoodType } from '@advitam/api/models/Crematorium/Ceremony';
import { DEFAULT_DEFUNCT_AGE } from 'containers/Deal/DealFuneral/constants';
import messages from './messages';
import style from './Cremation.module.scss';

class Ceremonies extends Component {
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { id, coffin } = this.props;
    const { coffin: prevCoffin } = prevProps;
    const coffinMaterial =
      coffin && coffin.productObject && coffin.productObject.material_strength;
    const prevCoffinMaterial =
      prevCoffin &&
      prevCoffin.productObject &&
      prevCoffin.productObject.material_strength;
    if (id !== prevProps.id || coffinMaterial !== prevCoffinMaterial) {
      this.getData();
    }
  }

  /**
   * Function to get data of ceremonies from database.
   */
  getData = () => {
    const {
      id,
      getCrematorium,
      getCeremonies,
      coffin,
      defunctAge,
    } = this.props;
    const coffinWoodTypes = [CoffinWoodType.ANY];
    const woodType =
      coffin && coffin.productObject && coffin.productObject.material_strength;
    if (woodType) {
      coffinWoodTypes.push(woodType);
    }
    getCrematorium(id);
    getCeremonies(id, {
      age: defunctAge || DEFAULT_DEFUNCT_AGE,
      coffin_wood_type: coffinWoodTypes,
    });
  };

  onChange = ({ target: { name, value } }) => {
    const { handleChange } = this.props;
    handleChange({
      [name]: value === null || value === '' ? null : parseInt(value, 10),
    });
  };

  renderRooms = () => {
    const { step, intl, ceremonies } = this.props;

    return (
      <Select
        onChange={this.onChange}
        name="ceremony"
        defaultValue={step.ceremony || ''}
      >
        <option key="" value="">
          {intl.formatMessage({ ...messages.defaultCeremonie })}
        </option>
        {ceremonies.map(item => (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </Select>
    );
  };

  renderConvivialityRoom() {
    const { step, crematorium } = this.props;

    if (!crematorium.conviviality_room_cost) return null;

    return (
      <div className={style.conviviality_room__wrapper}>
        <div>
          <FormattedMessage {...messages.convivialityRoomUsage} />
        </div>
        <div className={style.conviviality_room}>
          <NumberInput
            name="convivialityRoomUsage"
            onChange={this.onChange}
            defaultValue={step.convivialityRoomUsage}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <h4>
          <FormattedMessage {...messages.ceremonieHeader} />
        </h4>
        <div className={style.ceremony__wrapper}>
          <div>
            <FormattedMessage {...messages.ceremonieSelected} />
          </div>
          {this.renderRooms()}
        </div>
        {this.renderConvivialityRoom()}
      </>
    );
  }
}

Ceremonies.propTypes = {
  /** id of crematorium */
  id: PropTypes.number.isRequired,
  /** Function to get crematorium */
  getCrematorium: PropTypes.func.isRequired,
  /** Function to get ceremonies of crematorium */
  getCeremonies: PropTypes.func.isRequired,
  /** Crematorium data from redux store */
  crematorium: PropTypes.object.isRequired,
  /** Ceremonies data from redux store */
  ceremonies: PropTypes.array.isRequired,
  /** Current step data from redux store */
  step: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  /** function to update a value */
  handleChange: PropTypes.func.isRequired,
  /** Data of coffin selected */
  coffin: PropTypes.object,
  /** Age of defunct */
  defunctAge: PropTypes.number,
};

export default injectIntl(Ceremonies);
