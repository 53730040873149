import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { isNil } from '@advitam/support';
import DialogBox from 'containers/DialogBox';
import messagesAction from 'messages/actions.ts';
import PrestationForm from 'containers/PrestationForm/index.tsx';
import {
  PRESTATION_STATUS_EDITED,
  PRESTATION_COMPUTE_MANUAL,
  PRESTATION_STATUS_NONE,
} from 'components/PrestationDialog/constants';

import messages from './messages';

class PrestationDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      /** new prestation to add */
      prestation: props.prestationSkeleton,
      /** is there a value missing in form */
      isValueMissing: false,
      /** is amount not a positive value */
      isAmountNegative: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isDialogOpen, prestationToEdit } = this.props;
    if (isDialogOpen !== prevProps.isDialogOpen && prestationToEdit) {
      this.setPrestation(prestationToEdit);
    }
  }

  /**
   * Function to set prestation in state
   *
   * @param {object} prestation
   */
  setPrestation = prestation => this.setState({ prestation });

  /**
   * Function to check if a prestation is edited or not
   *
   * @param {object} prestation prestation to check
   * @param {object} prestationToEdit
   * @returns {boolean}
   */
  isPrestationNotEdited = (prestation, prestationToEdit) => {
    const isPrestationNotEdited = [
      'manual_description',
      'manual_price_category',
      'manual_model_id',
      'manual_model_type',
      'manual_tva_rate',
      'manual_cost',
      'manual_price',
    ].every(
      field =>
        prestation.priceLine[field] == null ||
        prestation.priceLine[field] === '',
    );
    if (!prestation.priceLine.manual_category) return isPrestationNotEdited;
    return (
      isPrestationNotEdited &&
      (prestation.priceLine.manual_category.id == null ||
        prestation.priceLine.manual_category.id === '') &&
      prestationToEdit.amount === prestation.amount
    );
  };

  /**
   * Function to update staged prestation
   *
   * @param {string} value value to update
   * @param {string} name key of prestation to update
   */
  onChangePrestationPriceLine = (name, value) => {
    this.setState(prevState => ({
      prestation: {
        ...prevState.prestation,
        priceLine: {
          ...prevState.prestation.priceLine,
          [name]: value,
        },
      },
    }));
  };

  /**
   * Function to update prestation in state
   *
   * @param {string} name name of field to update
   * @param {any} value value to update
   */
  onChangePrestation = (name, value) => {
    this.setState(prevState => ({
      prestation: {
        ...prevState.prestation,
        [name]: value,
      },
    }));
  };

  /**
   * We check that the field do exist (not undefined or null)
   * and it's not an empty string
   *
   * @param {string|number} field value of field
   * @return {boolean}
   */
  isValid = field => {
    if (typeof field === 'number') {
      return !Number.isNaN(field);
    }
    return Boolean(field);
  };

  /**
   * Check if at least one field is valid
   *
   * @param {any} field
   * @param {any} manualField
   */
  validFields = (field, manualField) =>
    this.isValid(this.finalValue(field, manualField));

  finalValue = (field, manualField) => {
    if (!isNil(manualField)) {
      return manualField;
    }
    return field;
  };

  /**
   * Check if the amount of the prestation is zero or negative
   *
   * @param {number} amount amount of prestation
   */
  isAmountNegative = amount => {
    if (amount <= 0) {
      this.setState({ isAmountNegative: true });
      return true;
    }
    this.setState({ isAmountNegative: false });
    return false;
  };

  /**
   * Add or update a prestation
   */
  addPrestation = () => {
    const {
      addPrestation,
      updatePrestation,
      prestationSkeleton,
      prestationToEdit,
      stepId,
    } = this.props;
    const { prestation } = this.state;
    const {
      description,
      manual_description: manualDescription,
      price_category: priceCategory,
      manual_price_category: manualPriceCategory,
      model_id: modelId,
      manual_model_id: manualModelId,
      model_type: modelType,
      manual_model_type: manualModelType,
      tva_rate: tvaRate,
      manual_tva_rate: manualTvaRate,
      cost,
      manual_cost: manualCost,
      price,
      manual_price: manualPrice,
      category,
      manual_category: manualCategory,
    } = prestation.priceLine;
    if (
      !this.validFields(description, manualDescription) ||
      !this.validFields(priceCategory, manualPriceCategory) ||
      !this.validFields(modelId, manualModelId) ||
      !this.validFields(modelType, manualModelType) ||
      !this.validFields(tvaRate, manualTvaRate) ||
      (this.finalValue(priceCategory, manualPriceCategory) !== 'family' &&
        !this.validFields(cost, manualCost)) ||
      !this.validFields(price, manualPrice) ||
      !this.validFields(category.id, manualCategory && manualCategory.id) ||
      !this.isValid(prestation.amount)
    ) {
      this.setState({ isValueMissing: true });
      return;
    }
    this.setState({ isValueMissing: false });

    if (this.isAmountNegative(prestation.amount)) return;

    let updatedPrestation = {
      ...prestationSkeleton,
      ...prestation,
      step_id: isNil(prestation.step_id) ? stepId : prestation.step_id,
      priceLine: {
        ...prestationSkeleton.priceLine,
        ...prestation.priceLine,
        manual_model_type: manualModelId && (manualModelType || modelType),
      },
    };

    // If there is an id it means it already exist and we want to update it
    if (prestation && prestation.id) {
      updatedPrestation = {
        ...updatedPrestation,
        status: this.isPrestationNotEdited(prestation, prestationToEdit)
          ? PRESTATION_STATUS_NONE
          : PRESTATION_STATUS_EDITED,
      };
      updatePrestation(updatedPrestation);
    } else {
      updatedPrestation = {
        ...updatedPrestation,
        compute: PRESTATION_COMPUTE_MANUAL,
        status: PRESTATION_STATUS_EDITED,
      };
      addPrestation(updatedPrestation);
    }

    this.closeDialog();
  };

  /**
   * Function to close dialog and clear values we don't need
   */
  closeDialog = () => {
    const {
      clearPrestationToEdit,
      toggleDialog,
      prestationSkeleton,
    } = this.props;
    clearPrestationToEdit();
    this.setState({ prestation: prestationSkeleton, isValueMissing: false });
    toggleDialog();
  };

  render() {
    const { isDialogOpen, prestationToEdit } = this.props;
    const { prestation, isValueMissing, isAmountNegative } = this.state;
    const headerMessage = prestationToEdit
      ? messages.updatePrestation
      : messages.addPrestation;

    return (
      <DialogBox
        isOpen={isDialogOpen}
        headerMessage={headerMessage}
        options={[
          { msg: messagesAction.cancel, cb: this.closeDialog },
          {
            msg: messagesAction.ok,
            cb: this.addPrestation,
          },
        ]}
        onRequestClose={this.closeDialog}
        className="addPrestationsDialog"
      >
        <PrestationForm
          priceLine={prestation.priceLine}
          amount={prestation.amount}
          isValueMissing={isValueMissing}
          isAmountNegative={isAmountNegative}
          onChangePrestationPriceLine={this.onChangePrestationPriceLine}
          onChangePrestation={this.onChangePrestation}
        />
      </DialogBox>
    );
  }
}

PrestationDialog.propTypes = {
  /** Function to add an prestation */
  addPrestation: PropTypes.func.isRequired,
  /** function to open or close dialog */
  toggleDialog: PropTypes.func.isRequired,
  /** is the dialog open */
  isDialogOpen: PropTypes.bool.isRequired,
  /** prestation to edit */
  prestationToEdit: PropTypes.object,
  /** function to clear prestation to edit */
  clearPrestationToEdit: PropTypes.func.isRequired,
  /** function to update prestation */
  updatePrestation: PropTypes.func.isRequired,
  /** skeleton of prestation */
  prestationSkeleton: PropTypes.object,
  stepId: PropTypes.number,
};

export default PrestationDialog;
