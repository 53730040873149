import { cloneDeep } from 'lodash';
import { Selector, createSelector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';
import type { EntityInvoicePrestationJSON } from '@advitam/api/models/EntityInvoice/Prestation';
import { EntityInvoicePrestationStatus } from '@advitam/api/models/EntityInvoice/Prestation/Status';

import {
  BILLING_EDIT_MODAL,
  INVOICE_SKELETON,
  PRESTATION_SKELETON,
} from './constants';
import type { AppStateSubset, State } from './slice';
import { NewPrestation, PrestationForm } from './types';
import { groupDate } from './utils';

type BillingEditModalSelector<T> = Selector<AppStateSubset, T>;

const selectBillingEditModalDomain = (state: AppStateSubset): State =>
  state[BILLING_EDIT_MODAL];

export const makeSelectIsModalOpen: () => BillingEditModalSelector<
  boolean
> = () => createSelector(selectBillingEditModalDomain, state => state.isOpen);

export const makeSelectIsLoading: () => BillingEditModalSelector<
  boolean
> = () =>
  createSelector(selectBillingEditModalDomain, state => state.isLoading);

export const makeSelectError: () => BillingEditModalSelector<SerializedApiError | null> = () =>
  createSelector(selectBillingEditModalDomain, state => state.error);

export const makeSelectInvoice: () => BillingEditModalSelector<EntityInvoiceJSON | null> = () =>
  createSelector(selectBillingEditModalDomain, state => state.invoice);

export const makeSelectHasInvoiceFields: () => BillingEditModalSelector<
  boolean
> = () =>
  createSelector(makeSelectInvoice(), invoice =>
    Boolean(invoice && invoice.prestations.length === 1),
  );

export const makeSelectPrestation: () => BillingEditModalSelector<EntityInvoicePrestationJSON | null> = () =>
  createSelector(selectBillingEditModalDomain, state => state.prestation);

export const makeSelectInitialFormValues: () => BillingEditModalSelector<
  PrestationForm
> = () =>
  createSelector(
    makeSelectInvoice(),
    makeSelectPrestation(),
    (invoice, prestation) => {
      const values = cloneDeep({
        invoice: invoice || INVOICE_SKELETON,
        prestation: (prestation as NewPrestation | null) || PRESTATION_SKELETON,
        date: invoice && groupDate(invoice, prestation),
        billedByBob: prestation
          ? prestation.status !== EntityInvoicePrestationStatus.UNSET
          : false,
        billedByEntity: prestation
          ? prestation.status !== EntityInvoicePrestationStatus.NOT_BILLED
          : true,
        prestationType: prestation
          ? { id: 0, description: prestation.description }
          : null,
      });

      if (prestation && invoice?.prestations.length === 1) {
        if (invoice.cost_tax_excl !== null) {
          values.prestation.cost_ht = invoice.cost_tax_excl;
        }
        if (invoice.cost_tax_incl !== null) {
          values.prestation.cost_ttc = invoice.cost_tax_incl;
        }
      }

      const deal =
        invoice?.deal ||
        prestation?.deal ||
        invoice?.prestations[0].deal ||
        values.prestation.deal;
      const person = deal.defunct || deal.owner;
      const name = [person?.firstname, person?.lastname]
        .filter(Boolean)
        .join(' ');
      const dealDescription = [name, deal.invoice_business_id].join(' - ');
      values.prestation.deal.description = dealDescription;
      if (invoice && !prestation) {
        values.prestation.deal.id = invoice.prestations[0].deal.id;
        values.prestation.deal.uuid = invoice.prestations[0].deal.uuid;
      }
      return values;
    },
  );
