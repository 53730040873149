import {
  SKELETON_PRESTATION,
  PRESTATION_COMPUTE_MANUAL,
  PRESTATION_STATUS_NONE,
} from 'components/PrestationDialog/constants';

export function formatManualService(stepId, serviceType, serviceDescription) {
  return {
    ...SKELETON_PRESTATION,
    type: serviceType,
    compute: PRESTATION_COMPUTE_MANUAL,
    status: PRESTATION_STATUS_NONE,
    priceLine: {
      ...SKELETON_PRESTATION.priceLine,
      description: serviceDescription,
    },
    step_id: stepId,
    amount: 1,
  };
}
