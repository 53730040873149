import { DateTime } from 'luxon';

import { DealType } from 'models/Deal/Type.ts';

import { TODO_LIST } from './SideBar/constants';
import {
  ERROR,
  SET_ORDERS_LINKED,
  PRE_BOOK_SUPPLIERS,
  BOOK_SUPPLIERS,
  ERROR_MODAL,
  SET_SECTION_OPENED,
  PUT_DEAL_BRAND,
  UPDATE_MANUAL_MODE,
  SET_GRAVEYARD_CONCESSIONS,
  SET_GRAVEYARD_CONCESSION_TYPES,
  SET_GRAVEYARD_BASIC_INFOS,
  ADD_TRIGGER_PING_SERVICE,
  REMOVE_TRIGGER_PING_SERVICE,
} from './constants.ts';
import { saveDeal } from './thunk.ts';
import { saveDealForm } from './Funeral/thunk.ts';
import { updateDealState } from './SideBar/KoReasonModal/thunk.ts';
import { setDeal, dealSaved } from './slice.ts';

import {
  GET_BOOKING_INFO,
  SET_BOOKING_INFO,
  SUCCESS_BOOK_SUPPLIERS,
  SUCCESS_UPDATE_SUPPLIER_BOOKING_STATUS,
  SUCCESS_PRE_BOOK_SUPPLIERS,
  PENDING_PREBOOKING,
  BOOKED,
  ACCEPTED_PREBOOKING,
  UPDATE_BOOKING_COMMENT,
  SUCCESS_UPDATE_BOOKING_COMMENT,
  SYNC_BOOKING_COMMENT,
  UPDATE_SUPPLIER_SELECTION,
} from './BookingSection/constants';
import { updatePaymentProblem } from './Payments/thunk.ts';
import { updateCommentary } from './Commentaries/thunk.ts';

export const initialState = {
  dealState: null,
  error: null,
  errorModal: null,
  loading: false,
  loadingStartTime: null,
  dirty: false,
  ordersLinked: [],
  sectionOpened: TODO_LIST,
  isManualMode: false,
  graveyardConcessionTypes: [],
  graveyardConcessions: {},
  graveyardBasicInfos: {},
  bookingInfo: null,
  isBookingLoading: false,
  // Loader for each textarea booking comment
  // An entry is a supplier id
  bookingCommentLoader: [],
  triggerPingServices: [],
};

const updateSupplierBookingState = (
  state,
  bookingStatus,
  shouldUpdateCallBack,
) => ({
  ...state,
  bookingInfo: {
    ...state.bookingInfo,
    suppliers: state.bookingInfo.suppliers.map(supplier => ({
      ...supplier,
      items: supplier.items.map(item => ({
        ...item,
        booking_status: shouldUpdateCallBack(item, supplier)
          ? bookingStatus
          : item.booking_status,
      })),
    })),
  },
});

function dealReducer(state = initialState, action) {
  switch (action.type) {
    case saveDeal.pending.type:
    case saveDealForm.pending.type:
      return {
        ...state,
        loading: true,
        loadingStartTime: DateTime.now().toISO(),
        error: null,
      };
    case dealSaved.type:
    case saveDealForm.rejected.type:
      return {
        ...state,
        loading: false,
        loadingStartTime: null,
      };
    case setDeal.type:
      return {
        ...state,
        loading:
          action.payload?.deal_type === DealType.FUNERAL
            ? state.loading
            : false,
        triggerPingServices: [],
      };
    case PUT_DEAL_BRAND:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SET_ORDERS_LINKED:
      return {
        ...state,
        ordersLinked: action.ordersLinked,
      };
    case UPDATE_MANUAL_MODE:
      return {
        ...state,
        isManualMode: action.isManualMode,
      };
    case ERROR:
      return {
        ...state,
        error: action.err,
        loading: false,
        isBookingLoading: false,
        bookingCommentLoader: [],
      };
    case saveDeal.rejected.type:
      return {
        ...state,
        loading: false,
        loadingStartTime: null,
        error: action.payload,
      };
    case ERROR_MODAL:
      return {
        ...state,
        errorModal: action.err,
        loading: false,
        isBookingLoading: false,
        bookingCommentLoader: [],
      };
    case SET_SECTION_OPENED:
      return {
        ...state,
        sectionOpened: action.section,
      };
    case SET_GRAVEYARD_CONCESSION_TYPES:
      return {
        ...state,
        graveyardConcessionTypes: action.graveyardConcessionTypes,
      };
    case SET_GRAVEYARD_CONCESSIONS:
      return {
        ...state,
        graveyardConcessions: action.graveyardConcessions,
      };
    case SET_GRAVEYARD_BASIC_INFOS:
      return {
        ...state,
        graveyardBasicInfos: action.graveyardBasicInfos,
      };
    case GET_BOOKING_INFO:
    case BOOK_SUPPLIERS:
    case PRE_BOOK_SUPPLIERS:
    case UPDATE_SUPPLIER_SELECTION:
      return {
        ...state,
        isBookingLoading: true,
        error: null,
      };
    case SET_BOOKING_INFO:
      return {
        ...state,
        bookingInfo: action.bookingInfo,
        isBookingLoading: false,
        error: null,
      };
    case SUCCESS_BOOK_SUPPLIERS:
      return updateSupplierBookingState(state, BOOKED, () => true);
    case SUCCESS_PRE_BOOK_SUPPLIERS:
      return updateSupplierBookingState(
        state,
        PENDING_PREBOOKING,
        item =>
          item.booking_status !== BOOKED &&
          item.booking_status !== ACCEPTED_PREBOOKING,
      );
    case SUCCESS_UPDATE_SUPPLIER_BOOKING_STATUS:
      return updateSupplierBookingState(
        state,
        action.bookingStatus,
        (_, supplier) => supplier.id === action.supplierID,
      );
    case SYNC_BOOKING_COMMENT:
      return {
        ...state,
        bookingInfo: {
          ...state.bookingInfo,
          suppliers: state.bookingInfo.suppliers.map(supplier => {
            if (action.supplierID !== supplier.id) {
              return supplier;
            }
            return {
              ...supplier,
              comment: action.comment,
            };
          }),
        },
      };
    case UPDATE_BOOKING_COMMENT:
      return {
        ...state,
        bookingCommentLoader: state.bookingCommentLoader.concat(
          ...new Set([action.supplierID]),
        ),
        bookingInfo: {
          ...state.bookingInfo,
          suppliers: state.bookingInfo.suppliers.map(supplier => {
            if (action.supplierID !== supplier.id) {
              return supplier;
            }
            return {
              ...supplier,
              comment: action.comment,
            };
          }),
        },
      };
    case SUCCESS_UPDATE_BOOKING_COMMENT:
      return {
        ...state,
        bookingCommentLoader: state.bookingCommentLoader.filter(
          supplierID => supplierID !== action.supplierID,
        ),
      };
    case ADD_TRIGGER_PING_SERVICE: {
      const pingIndex = state.triggerPingServices.findIndex(
        ping =>
          ping.type === action.service.type &&
          ping.step_id === action.service.step_id,
      );

      const triggerPingServices = [...state.triggerPingServices];
      if (pingIndex === -1) {
        triggerPingServices.push({
          type: action.service.type,
          step_id: action.service.step_id,
        });
      }

      return {
        ...state,
        triggerPingServices,
      };
    }
    case REMOVE_TRIGGER_PING_SERVICE:
      return {
        ...state,
        triggerPingServices: state.triggerPingServices.filter(
          ping =>
            ping.type !== action.service.type &&
            ping.step_id !== action.service.step_id,
        ),
      };
    case updatePaymentProblem.pending.type:
      return {
        ...state,
        loading: true,
      };
    case updatePaymentProblem.fulfilled.type:
      return {
        ...state,
        loading: false,
      };
    case updatePaymentProblem.rejected.type:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case updateCommentary.rejected.type:
      return {
        ...state,
        error: action.payload,
      };

    case updateDealState.rejected.type:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default dealReducer;
