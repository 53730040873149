import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FundingAgencyJSON } from '@advitam/api/models/FundingAgency';

import { ApiError } from 'api';
import { SerializedApiError } from '@advitam/api';
import { FundingAgencyFilters } from '@advitam/api/v1/FundingAgencies';
import { fetchFundingAgencies } from './thunk';
import { FUNDING_AGENCIES } from './constants';

export interface State {
  isLoading: boolean;
  fundingAgencies: FundingAgencyJSON[];
  error: SerializedApiError | null;
  filters: FundingAgencyFilters;
  page: number;
  hasMore: boolean;
  lastRequestId: string | null;
}

export interface AppStateSubset {
  [FUNDING_AGENCIES]: State;
}

const initialState: State = {
  isLoading: false,
  fundingAgencies: [],
  error: null,
  filters: {},
  page: 1,
  hasMore: true,
  lastRequestId: null,
};

const slice = createSlice({
  name: FUNDING_AGENCIES,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters: (
      state,
      { payload }: PayloadAction<Partial<FundingAgencyFilters>>,
    ) => {
      state.filters = { ...state.filters, ...payload };
      state.fundingAgencies = [];
      state.page = 1;
      state.hasMore = true;
      state.error = null;
    },
    /* eslint-enable no-param-reassign */
  },
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchFundingAgencies.pending, (state, { meta }) => {
      state.isLoading = true;
      state.lastRequestId = meta.requestId;
    });
    builder.addCase(
      fetchFundingAgencies.fulfilled,
      (state, { payload, meta }) => {
        if (meta.requestId !== state.lastRequestId) {
          return;
        }

        state.isLoading = false;
        state.fundingAgencies.push(...payload.agencies);
        state.page += 1;
        state.hasMore = payload.hasMore;
      },
    );
    builder.addCase(
      fetchFundingAgencies.rejected,
      (state, { payload, meta }) => {
        if (meta.requestId !== state.lastRequestId) {
          return;
        }

        state.isLoading = false;
        state.error = ApiError.serialize(payload);
        state.hasMore = false;
      },
    );
    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters } = slice.actions;
export default slice;
