import { StepType } from '@advitam/api/models/Step';
import { LegacyStep } from 'models/Deal/Step';

const MarbleStepType = 'marble';

export function getFuneralStepId(
  steps: LegacyStep[],
  stepType: StepType | typeof MarbleStepType,
): number {
  if (stepType !== MarbleStepType) {
    return steps.findIndex(s => s.eventType === stepType);
  }
  return steps.findIndex(s =>
    [StepType.INTERMENT, StepType.URN_SEALING].includes(s.eventType),
  );
}
