import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import { WANT_FAMILY_TO_BUY_CONCESSION } from 'utils/constants';
import ErrorModal from 'components/ErrorModal';
import HardSpinner from 'components/HardSpinner';
import SideBar from 'containers/Deal/SideBar';
import { NEW_PATH } from 'containers/App/constants';
import BookingSection from 'containers/Deal/BookingSection';
import Documents from 'containers/Documents';
import DocumentGenerationModal from 'containers/DocumentGenerationModal';
import ProductsSection from 'containers/Deal/DealFuneral/ProductsSection';
import Commentaries from 'containers/Deal/Commentaries/index.tsx';
import { DEAL_TYPE_MARBLE } from 'containers/Deal/constants';
import { updateWishes } from 'containers/Deal/DealFuneral/actions';
import { putDealBrand as putDealBrandDispatch } from 'containers/Deal/SideBar/actions';
import reducerDialogBox from 'containers/DialogBox/reducer';
import reducerAutocomplete from 'containers/AutoComplete/reducer';
import sagaAutoComplete from 'containers/AutoComplete/sagas';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import {
  makeSelectDeal,
  makeSelectDealType,
  makeSelectDealIsDirty,
  makeSelectLoading as makeSelectLoadingDealTypeWrapper,
  makeSelectError as makeSelectErrorDealTypeWrapper,
  makeSelectIsManualMode,
  makeSelectManualSelection,
  makeSelectGraveyardConcessionTypes,
  makeSelectGraveyardConcessions,
  makeSelectGraveyardBasicInfos,
  makeSelectWish,
  makeSelectWishes,
} from 'containers/Deal/selectors';
import { makeSelectUser } from 'slices/auth';
import { getDefunctConcession as getDefunctConcessionDispatch } from 'components/ConcessionSelection/actions';
import {
  TODO_LIST,
  DOCUMENTS,
  PAYMENTS,
  STEPS,
  SUPPLIERS,
  CLIENT,
  HISTORY,
  MARBLE_LINKS,
} from 'containers/Deal/SideBar/constants';
import Services from 'containers/Deal/DealFuneral/StepsSection/Services';
import {
  addService as addServiceDispatch,
  removeService as removeServiceDispatch,
  removeTriggerPingService,
  updateService as updateServiceDispatch,
} from 'containers/Deal/DealFuneral/StepsSection/Services/actions';
import ManualMode from 'components/ManualMode';
import messages from 'containers/Deal/DealFuneral/messages';
import { makeSelectFuneralBrands } from 'slices/data';

import HistorySection from './HistorySection';
import UpdateBanner from '../UpdateBanner/index.tsx';
import {
  updateManualMode as updateManualModeDispatch,
  getGraveyardConcessions as getGraveyardConcessionsDispatch,
  getGraveyardConcessionTypes as getGraveyardConcessionsTypesDispatch,
  getGraveyardBasicInfos as getGraveyardBasicInfosDispatch,
} from '../actions';
import Clients from '../Clients/index.tsx';
import Payments from '../Payments/index.tsx';
import TodoList from '../TodoList/index.tsx';
import Grave from './Grave';
import {
  getDealMarble as getDealMarbleDispatch,
  updateDealMarble as updateDealMarbleDispatch,
  setConcession,
} from './actions';
import {
  makeSelectError,
  makeSelectLoading,
  makeSelectPostalCodes,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import { saveDeal } from '../thunk.ts';
import { updateManualPrice as updateManualPriceDispatch } from '../slice.ts';
import SendBatchModal from '../SendBatchModal';

export class DealMarble extends Component {
  componentDidMount() {
    const {
      match: {
        params: { dealUUID },
      },
      getDealMarble,
      deal,
    } = this.props;
    const isNewDeal = dealUUID === NEW_PATH;
    if (!isNewDeal) getDealMarble(deal);
  }

  renderSections = () => {
    const {
      sectionOpened,
      dealIsDirty,
      user,
      deal,
      match: {
        params: { dealUUID },
      },
      isManualMode,
      updateManualMode,
      intl,
      graveyardConcessions,
      graveyardConcessionTypes,
      updateGraveyard,
      getGraveyardConcessionTypes,
      getGraveyardConcession,
      addService,
      removeService,
      updateService,
      findWish,
      getDefunctConcession,
      getGraveyardBasicInfos,
      graveyardBasicInfos,
      postalCodes,
      wishes,
      updateWish,
    } = this.props;
    const isNewDeal = dealUUID === NEW_PATH;
    const isFamilyBuyingConcession = findWish(WANT_FAMILY_TO_BUY_CONCESSION);
    switch (sectionOpened) {
      case TODO_LIST:
        return !isNewDeal && <TodoList />;
      case CLIENT:
        return <Clients withLink={false} withDuplication />;
      case STEPS:
        return (
          <>
            <Commentaries />
            <div className="margin--10">
              <ManualMode
                isManualMode={isManualMode}
                onChangeManualMode={updateManualMode}
                userRole={user.role}
              />
            </div>
            <Grave
              grave={deal.marble.graveyard}
              updateGraveyard={updateGraveyard}
              intl={intl}
              graveyardConcessionTypes={graveyardConcessionTypes}
              graveyardConcessions={graveyardConcessions}
              getGraveyardConcessionTypes={getGraveyardConcessionTypes}
              getGraveyardConcession={getGraveyardConcession}
              isFamilyBuyingConcession={
                (isFamilyBuyingConcession && isFamilyBuyingConcession.active) ||
                false
              }
              getDefunctConcession={getDefunctConcession}
              getGraveyardBasicInfos={getGraveyardBasicInfos}
              graveyardBasicInfos={graveyardBasicInfos}
              wishes={wishes}
              findWish={findWish}
              updateWish={updateWish}
            />
            <div className="space--10">
              <div className="step__title">
                <div className="steps__header">
                  <h3>{intl.formatMessage(messages.servicesSection)}</h3>
                </div>
              </div>
              <div className="step__content">
                <Services
                  stepId={0}
                  services={deal.marble.services}
                  addService={addService}
                  updateService={updateService}
                  removeService={removeService}
                  isManualMode={isManualMode}
                  userRole={user.role}
                />
              </div>
            </div>
            {postalCodes && (
              <div className="space--10">
                <ProductsSection
                  isNewDeal={isNewDeal}
                  dealUUID={dealUUID}
                  isManualMode={isManualMode}
                  postalCodes={postalCodes}
                />
              </div>
            )}
          </>
        );
      case SUPPLIERS:
        return (
          !isNewDeal && !dealIsDirty && <BookingSection dealUUID={dealUUID} />
        );
      case DOCUMENTS:
        return !isNewDeal && <Documents dealUUID={dealUUID} />;
      case PAYMENTS:
        return (
          !isNewDeal && (
            <Payments dealUUID={dealUUID} price={deal && deal.auto_price} />
          )
        );
      case HISTORY:
        return <HistorySection dealUUID={dealUUID} />;
      default:
        return null;
    }
  };

  renderUpdateBanner() {
    const {
      loading,
      loadingDealTypeWrapper,
      dealIsDirty,
      saveDealMarble,
      manualSelection,
      match: {
        params: { dealUUID },
      },
    } = this.props;
    if (loading || loadingDealTypeWrapper) return null;
    const isNewDeal = dealUUID === NEW_PATH;
    return (
      <UpdateBanner
        isNewDeal={isNewDeal}
        dealIsDirty={dealIsDirty}
        querySaveDeal={saveDealMarble}
        manualSelection={manualSelection}
      />
    );
  }

  renderContent() {
    const { loading, loadingDealTypeWrapper } = this.props;
    return (
      <div id="deal" className="dealMarble__sectionsWrapper">
        {(loading || loadingDealTypeWrapper) && <HardSpinner />}
        {this.renderSections()}
        {this.renderUpdateBanner()}
      </div>
    );
  }

  render() {
    const {
      match: {
        params: { dealUUID },
      },
      error,
      brands,
      deal,
      putDealBrand,
      intl,
      updateManualPrice,
      dealType,
      user,
      setSection,
      sectionOpened,
      errorDealTypeWrapper,
      location,
    } = this.props;
    const isNewDeal = dealUUID === NEW_PATH;
    const dealError = deal.getErrors(intl);

    return (
      <div className="dealMarble">
        <Helmet title={`${deal.mainClient.lastName || ''} - Marbrerie`} />
        <SideBar
          dealUUID={dealUUID}
          isNewDeal={isNewDeal}
          brands={brands}
          brandSelectedId={deal.brand && deal.brand.id}
          putDealBrand={putDealBrand}
          updateManualPrice={updateManualPrice}
          dealType={dealType}
          deal={deal}
          user={user}
          setSection={setSection}
          sectionOpened={sectionOpened}
          links={MARBLE_LINKS}
          location={location}
        />
        <ErrorModal error={error || errorDealTypeWrapper || dealError} />
        {this.renderContent()}
        <DocumentGenerationModal dealId={deal.id} />
        <SendBatchModal />
      </div>
    );
  }
}

DealMarble.propTypes = {
  deal: PropTypes.object,
  dealIsDirty: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  user: PropTypes.object,
  brands: PropTypes.array,
  loading: PropTypes.bool,
  loadingDealTypeWrapper: PropTypes.bool,
  isManualMode: PropTypes.bool,
  match: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  error: PropTypes.object,
  updateManualPrice: PropTypes.func.isRequired,
  putDealBrand: PropTypes.func.isRequired,
  updateManualMode: PropTypes.func.isRequired,
  dealType: PropTypes.string,
  sectionOpened: PropTypes.string.isRequired,
  setSection: PropTypes.func.isRequired,
  getDealMarble: PropTypes.func.isRequired,
  manualSelection: PropTypes.bool,
  saveDealMarble: PropTypes.func.isRequired,
  graveyardConcessions: PropTypes.object,
  graveyardConcessionTypes: PropTypes.array,
  getGraveyardConcession: PropTypes.func.isRequired,
  getGraveyardConcessionTypes: PropTypes.func.isRequired,
  updateGraveyard: PropTypes.func.isRequired,
  /** function to add a service */
  addService: PropTypes.func.isRequired,
  /** function to remove a service */
  removeService: PropTypes.func.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
  /** Function to find a wish */
  findWish: PropTypes.func.isRequired,
  /** list of wishes */
  wishes: PropTypes.array.isRequired,
  /** function to update a wish */
  updateWish: PropTypes.func.isRequired,
  /** get defunct concession by id */
  getDefunctConcession: PropTypes.func.isRequired,
  /** function to get graveyard summary data */
  getGraveyardBasicInfos: PropTypes.func.isRequired,
  /** Basic infos of the selected graveyard */
  graveyardBasicInfos: PropTypes.object,
  postalCodes: PropTypes.object,
  errorDealTypeWrapper: PropTypes.object,
  location: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  deal: makeSelectDeal(),
  dealIsDirty: makeSelectDealIsDirty(),
  user: makeSelectUser(),
  brands: makeSelectFuneralBrands(),
  isManualMode: makeSelectIsManualMode(),
  loading: makeSelectLoading(),
  loadingDealTypeWrapper: makeSelectLoadingDealTypeWrapper(),
  error: makeSelectError(),
  errorDealTypeWrapper: makeSelectErrorDealTypeWrapper(),
  dealType: makeSelectDealType(),
  manualSelection: makeSelectManualSelection(),
  graveyardConcessions: makeSelectGraveyardConcessions(),
  graveyardConcessionTypes: makeSelectGraveyardConcessionTypes(),
  graveyardBasicInfos: makeSelectGraveyardBasicInfos(),
  findWish: makeSelectWish(),
  wishes: makeSelectWishes(),
  postalCodes: makeSelectPostalCodes(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateDealMarble: marble => dispatch(updateDealMarbleDispatch(marble)),
    getGraveyardConcessionTypes: id =>
      dispatch(getGraveyardConcessionsTypesDispatch(id)),
    getGraveyardConcession: id => dispatch(getGraveyardConcessionsDispatch(id)),
    getGraveyardBasicInfos: id => dispatch(getGraveyardBasicInfosDispatch(id)),
    getDealMarble: dealUUID => dispatch(getDealMarbleDispatch(dealUUID)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { deal } = stateProps;
  const { dispatch, updateDealMarble } = dispatchProps;
  const {
    match: {
      params: { dealUUID },
    },
  } = ownProps;

  const setDefunctConcession = concession =>
    setConcession({ ...concession, version_id: null }, deal.marble);

  const updateGraveyard = graveyard =>
    updateDealMarble({
      ...deal.marble,
      graveyard,
    });

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    updateDealMarble,
    updateGraveyard,
    getDefunctConcession: id =>
      dispatch(getDefunctConcessionDispatch(id, setDefunctConcession)),
    saveDealMarble: () => dispatch(saveDeal(deal)),
    updateWish: (wishType, value) =>
      dispatch(updateWishes(wishType, value, deal.marble, DEAL_TYPE_MARBLE)),

    // Services
    removeService: service => {
      dispatch(removeServiceDispatch(service, deal.marble, DEAL_TYPE_MARBLE));
      dispatch(removeTriggerPingService(service));
    },
    addService: service =>
      dispatch(addServiceDispatch(service, deal.marble, DEAL_TYPE_MARBLE)),
    updateService: service =>
      dispatch(updateServiceDispatch(service, deal.marble, DEAL_TYPE_MARBLE)),

    // SideBar
    putDealBrand: brand => dispatch(putDealBrandDispatch(dealUUID, brand)),
    updateManualPrice: manualPrice =>
      dispatch(updateManualPriceDispatch(manualPrice)),
    updateManualMode: isManualMode =>
      dispatch(updateManualModeDispatch(isManualMode)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);

const withReducer = [
  injectReducer({ key: 'dealMarble', reducer }),
  injectReducer({ key: 'dialogBox', reducer: reducerDialogBox }),
  injectReducer({ key: 'autoComplete', reducer: reducerAutocomplete }),
];
const withSaga = [
  injectSaga({ key: 'dealMarble', saga }),
  injectSaga({ key: 'autoComplete', saga: sagaAutoComplete }),
];

export default compose(
  ...withReducer,
  ...withSaga,
  withConnect,
  injectIntl,
)(DealMarble);
