import { Session, withSessionId } from '@advitam/api';

import { assert } from 'lib/Assert';
import { Deal, DealJSON } from 'models/Deal';

import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { post, put } from 'api/methods';
import { Deals } from 'api/serializers/Deals';

import { TriggerPingService } from '.';

export interface MarbleDealPayload {
  deal: DealJSON;
  uuid: string;
}

export default class Marbles {
  @authenticate
  static create(
    deal: Deal | DealJSON,
    triggerPingServices?: TriggerPingService[],
  ): ApiRequestDescriptor<MarbleDealPayload> {
    return post('/api/v1/deals/marbles', {
      deal,
      _session_id: Session.SESSION_ID,
      _trigger_ping_services: triggerPingServices,
    });
  }

  @authenticate
  @withSessionId
  static update(
    deal: Deal | DealJSON,
    triggerPingServices?: TriggerPingService[],
  ): ApiRequestDescriptor<MarbleDealPayload> {
    assert(deal.uuid !== undefined);
    const serializedDeal = Deals.Marble.serialize(deal);

    return put(`/api/v1/deals/marbles/${deal.uuid}`, {
      deal: serializedDeal.deal,
      _trigger_ping_services: triggerPingServices,
    });
  }

  static updateOrCreate(
    deal: Deal | DealJSON,
    triggerPingServices?: TriggerPingService[],
  ): ApiRequestDescriptor<MarbleDealPayload> {
    if (deal.uuid) {
      return Marbles.update(deal, triggerPingServices);
    }
    return Marbles.create(deal, triggerPingServices);
  }
}
