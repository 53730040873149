import { ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';
import { Checkbox, FlatButton, Spinner, Text } from '@advitam/ui';

import { makeSelectIsItemLoading } from '../../selectors';
import { updateItem } from '../../thunk';
import listStyle from '../List.module.scss';
import style from './Task.module.scss';

interface TaskProps {
  item: TodoItem;
  action?: ReactNode;
  checkboxClassName?: string;
}

export default function Task({
  item,
  action,
  checkboxClassName = '',
}: TaskProps): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsItemLoading(item));

  const checkboxClasses = [style.checkbox, checkboxClassName].filter(Boolean);
  const labelClasses = [style.label, item.isDone && style.done].filter(Boolean);

  const onChange = useCallback((): void => {
    const forceDone = !item.isDone !== item.done ? !item.isDone : null;
    dispatch(updateItem({ ...item, force_done: forceDone }));
  }, [dispatch, item]);

  return (
    <div className={listStyle.row}>
      <FlatButton
        onClick={onChange}
        disabled={isLoading}
        className={labelClasses.join(' ')}
      >
        {isLoading ? (
          <span className={style.spinner}>
            <Spinner />
          </span>
        ) : (
          <Checkbox
            checked={item.isDone}
            className={checkboxClasses.join(' ')}
          />
        )}

        <Text>{item.title}</Text>
      </FlatButton>

      <div className={listStyle.actions}>{!item.isDone && action}</div>
    </div>
  );
}
