import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  preventModalCta: { id: 'app.containers.Deal.Funeral.preventModalCta' },
  preventModalMessage: {
    id: 'app.containers.Deal.Funeral.preventModalMessage',
  },
  preventModalTitle: { id: 'app.containers.Deal.Funeral.preventModalTitle' },
  slowSavingTitle: { id: 'app.containers.Deal.Funeral.slowSavingTitle' },
  slowSavingText: { id: 'app.containers.Deal.Funeral.slowSavingText' },
});
