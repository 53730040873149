import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  airport: { id: 'app.containers.Map.airport' },
  cityhall: { id: 'app.containers.Map.cityhall' },
  consulate: { id: 'app.containers.Map.consulate' },
  crematorium: { id: 'app.containers.Map.crematorium' },
  funeralParlor: {
    id: 'app.containers.Map.funeralParlor',
  },
  graveyard: { id: 'app.containers.Map.graveyard' },
  hospital: { id: 'app.containers.Map.hospital' },
  places: { id: 'app.containers.Map.places' },
  police: { id: 'app.containers.Map.police' },
  prefecture: { id: 'app.containers.Map.prefecture' },
  prestations: { id: 'app.containers.Map.prestations' },
  regionalHealthAuthority: {
    id: 'app.containers.Map.regionalHealthAuthority',
  },
  suppliers: { id: 'app.containers.Map.suppliers' },
  worship: { id: 'app.containers.Map.worship' },
});
