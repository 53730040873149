import { useCallback } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ContentModal,
  ErrorText,
  FormattedApiError,
  Input,
  ModalTitle,
  Spinner,
  SubmitButton,
} from '@advitam/ui';
import { withSlice } from '@advitam/react';

import actionsMessages from 'messages/actions';

import messages from './messages';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsOpen,
} from './selectors';
import slice, { setIsOpen } from './slice';
import style from './CreationModal.module.scss';
import { createItem } from './thunk';

const TITLE_FIELD = 'title';

interface CreationForm {
  [TITLE_FIELD]: string;
}

function CreationModal(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector(makeSelectIsOpen());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const onClose = useCallback((): void => {
    dispatch(setIsOpen(false));
  }, [dispatch]);

  const onSubmit = useCallback(
    ({ title }: CreationForm): void => {
      dispatch(createItem(title));
    },
    [dispatch],
  );

  return (
    <ContentModal
      isOpen={isOpen}
      className={`col-4 ${style.modal}`}
      onRequestClose={onClose}
    >
      <ModalTitle>
        <FormattedMessage id={messages.title.id} />
      </ModalTitle>

      <Form onSubmit={onSubmit}>
        {({ handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <Input
              required
              name={TITLE_FIELD}
              label={<FormattedMessage id={messages.titleInputLabel.id} />}
              placeholder={intl.formatMessage(messages.titleInputPlaceholder)}
            />

            {error && (
              <ErrorText className={style.error}>
                <FormattedApiError error={error} />
              </ErrorText>
            )}

            <div className={style.modal_actions}>
              <SubmitButton
                primary
                icon={isLoading ? <Spinner /> : undefined}
                disabled={isLoading}
                text={<FormattedMessage id={actionsMessages.add.id} />}
              />
              <Button
                outline
                text={<FormattedMessage id={actionsMessages.cancel.id} />}
                className={style.cancel_action}
                onClick={onClose}
              />
            </div>
          </form>
        )}
      </Form>
    </ContentModal>
  );
}

export default withSlice(slice)(CreationModal);
