import type { ReactNode } from 'react'

import SummaryStep from './Step/Default'
import TransportSummaryStep from './Step/Transport'

interface SummaryStepsProps {
  children: ReactNode | ReactNode[]
  className?: string
}

export default function SummarySteps({ children, className }: SummaryStepsProps): JSX.Element {
  return <div className={className}>{children}</div>
}

export { SummaryStep, TransportSummaryStep }
