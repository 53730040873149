import { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { FlatButton, Input, Link, Text } from '@advitam/ui';
import CrossIcon from '@advitam/ui/images/icons/times.svg';
import PenIcon from '@advitam/ui/images/icons/pen-solid.svg';
import { Path } from 'containers/App/constants';
import style from 'containers/Crud/Sidebar/Title/Title.module.scss';
import { assert } from 'lib/support';

import { makeSelectSupplier } from '../../selectors';
import { SupplierWarehouseForm } from '../sections/types';
import { makeSelectIsUpdatingName, makeSelectWarehouse } from '../selectors';
import messages from '../messages';
import warehouseStyle from '../Warehouse.module.scss';

export default function Title(): JSX.Element {
  const intl = useIntl();

  const { values, pristine } = useFormState<SupplierWarehouseForm>();
  const [isEditing, setIsEditing] = useState(false);

  const supplier = useSelector(makeSelectSupplier());
  assert(supplier?.id !== undefined);
  const warehouse = useSelector(makeSelectWarehouse());
  const isLoading = useSelector(makeSelectIsUpdatingName());

  const isInputMode = !warehouse || isEditing || isLoading;

  const titleValue = values.warehouse.name;
  const pageTitle = titleValue
    ? `${supplier.name} - ${titleValue} - Advitam`
    : 'Advitam';

  const onCancel = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  useEffect(() => {
    if (pristine) {
      setIsEditing(false);
    }
  }, [pristine]);

  if (isInputMode) {
    return (
      <div className={warehouseStyle.sidebar_header}>
        <Helmet title={pageTitle} />
        <Text tagName="div">
          <Link internal href={Path.SUPPLIER(supplier.id)}>
            {supplier.name}
          </Link>
        </Text>
        <div className={style.container}>
          <Text className={warehouseStyle.title_chevron}>{'>'}</Text>
          <Input
            required
            disabled={isLoading}
            name="warehouse.name"
            placeholder={intl.formatMessage(messages.titlePlaceholder)}
          />
          {warehouse && (
            <FlatButton className={style.icon} onClick={onCancel}>
              <CrossIcon />
            </FlatButton>
          )}
        </div>
      </div>
    );
  }

  return (
    <header className={warehouseStyle.sidebar_header}>
      <Helmet title={pageTitle} />
      <Text tagName="div">
        <Link internal href={Path.SUPPLIER(supplier.id)}>
          {supplier.name}
        </Link>
      </Text>
      <div className={style.container}>
        <h2>
          <Text className={warehouseStyle.title_chevron}>{'>'}</Text>
          <Text>
            <b>{titleValue}</b>
          </Text>
        </h2>
        <FlatButton className={style.icon} onClick={onEdit}>
          <PenIcon />
        </FlatButton>
      </div>
    </header>
  );
}
