import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { makeSelectWorshipTypes } from 'slices/data';
import {
  makeSelectIsManualMode,
  makeSelectWishes,
  makeSelectWish,
  makeSelectGraveyardBasicInfos,
  makeSelectSteps,
  makeSelectDeal,
} from 'containers/Deal/selectors';
import {
  getGraveyardConcessionTypes,
  getGraveyardConcessions,
  getGraveyardBasicInfos as getGraveyardBasicInfosDispatch,
} from 'containers/Deal/actions';
import { DEAL_TYPE_FUNERAL } from 'containers/Deal/constants';
import { makeSelectUser } from 'slices/auth';

import {
  STEP_CASKETING,
  STEP_TBC,
  STEP_INTERMENT,
  STEP_URN_SEALING,
} from '../constants';
import { updateDeal, updateWishes } from '../actions';
import {
  CremationIcon,
  RepatriationIcon,
  CasketingIcon,
  CustomIcon,
  LeavesIcon,
} from '../Images';
import messages from '../messages';
import { makeSelectFuneral } from '../selectors';

import {
  getConcessionPrices,
  openDeleteStepDialog,
  setAshesDestination,
  getCrematorium,
  getCeremonies,
  getFuneralParlorStayTypes,
  setFuneralParlorStayTypes,
  updateDealFromSteps,
} from './actions';
import {
  makeSelectStep,
  makeSelectConcessionPrices,
  makeSelectCrematorium,
  makeSelectDefunctAge,
  makeSelectCeremonies,
  makeSelectConcessionTypes,
  makeSelectFuneralParlorStayTypes,
  makeSelectCoffin,
} from './selectors';
import {
  makeSelectStepServices,
  makeSelectService,
} from './Services/selectors';
import {
  addService as addServiceDispatch,
  removeService as removeServiceAction,
  removeTriggerPingService,
  updateService as updateServiceDispatch,
} from './Services/actions';
import Step from './Step';
import AddStep from './AddStep';
import getSkeleton from './skeletons';
import style from './Steps.module.scss';

class Steps extends Component {
  createSteps = skeletonType => {
    const { handleUpdateDeal } = this.props;
    handleUpdateDeal({ steps: getSkeleton(skeletonType) });
  };

  confirmDeleteStep = (stepId, stepEventType, steps) => {
    const { openDeleteStepDialogProps, handleUpdateDeal } = this.props;
    openDeleteStepDialogProps(stepId, stepEventType);
    const stepCasketingIndex = steps.findIndex(
      step => step.eventType === STEP_CASKETING,
    );
    const stepCasketing = steps[stepCasketingIndex];
    if (
      stepEventType === STEP_TBC &&
      stepCasketing &&
      stepCasketing.tribunal_authorization_date
    ) {
      const newSteps = [...steps];
      newSteps[stepCasketingIndex] = {
        ...steps[stepCasketingIndex],
        tribunal_authorization_date: null,
      };
      handleUpdateDeal({
        steps: newSteps,
      });
    }
  };

  skeletonButtons = () => {
    const { intl } = this.props;
    return (
      <div>
        <button
          type="button"
          className={style.skeleton_button}
          onClick={() => this.createSteps('interment')}
        >
          <div
            data-tooltip={intl.formatMessage({
              ...messages.interment,
            })}
            data-tooltip-location="right"
          >
            <LeavesIcon
              alt={intl.formatMessage({ ...messages.interment })}
              className="steps__skeletonIcon"
            />
          </div>
        </button>
        <button
          type="button"
          className={style.skeleton_button}
          onClick={() => this.createSteps('cremation')}
        >
          <div
            data-tooltip={intl.formatMessage({
              ...messages.cremation,
            })}
            data-tooltip-location="top"
          >
            <CremationIcon
              alt={intl.formatMessage({ ...messages.cremation })}
              className="steps__skeletonIcon"
            />
          </div>
        </button>
        <button
          type="button"
          className={style.skeleton_button}
          onClick={() => this.createSteps('repatriation')}
        >
          <div
            data-tooltip={intl.formatMessage({
              ...messages.repatriation,
            })}
            data-tooltip-location="top"
          >
            <RepatriationIcon
              alt={intl.formatMessage({ ...messages.repatriation })}
              className="steps__skeletonIcon"
            />
          </div>
        </button>
        <button
          type="button"
          className={style.skeleton_button}
          onClick={() => this.createSteps('casketing')}
        >
          <div
            data-tooltip={intl.formatMessage({
              ...messages.casketing,
            })}
            data-tooltip-location="top"
          >
            <CasketingIcon
              alt={intl.formatMessage({ ...messages.casketing })}
              className="steps__skeletonIcon"
            />
          </div>
        </button>
        <button
          type="button"
          className={style.skeleton_button}
          onClick={() => this.createSteps('custom')}
        >
          <div
            data-tooltip={intl.formatMessage({
              ...messages.custom,
            })}
            data-tooltip-location="top"
          >
            <CustomIcon
              alt={intl.formatMessage({ ...messages.custom })}
              className="steps__skeletonIcon"
            />
          </div>
        </button>
      </div>
    );
  };

  render() {
    const {
      steps,
      handleUpdateDeal,
      findService,
      removeService,
      addService,
      updateWish,
      updateStep,
      findWish,
      funeral,
      isManualMode,
      user,
      updateService,
      getGraveyardBasicInfos,
      graveyardBasicInfos,
    } = this.props;
    if (steps) {
      const hasConcessionSelection = steps.find(
        step =>
          step.eventType === STEP_INTERMENT ||
          step.eventType === STEP_URN_SEALING,
      );
      return (
        <div>
          {steps.map(step => (
            <Step
              {...this.props}
              key={`${step.id}${step.eventType}`}
              step={step}
              handleUpdateDeal={handleUpdateDeal}
              confirmDeleteStep={this.confirmDeleteStep}
              findService={findService(step.id)}
              removeService={removeService}
              addService={addService}
              updateWish={updateWish}
              updateStep={updateStep}
              findWish={findWish}
              steps={steps}
              isManualMode={isManualMode}
              userRole={user.role}
              updateService={updateService}
              getGraveyardBasicInfos={getGraveyardBasicInfos}
              graveyardBasicInfos={graveyardBasicInfos}
            />
          ))}
          {!hasConcessionSelection && (
            <AddStep
              opened
              handleUpdateDeal={handleUpdateDeal}
              stepId={steps.length}
              funeral={funeral}
            />
          )}
        </div>
      );
    }
    return this.skeletonButtons();
  }
}

Steps.propTypes = {
  steps: PropTypes.array,
  findWish: PropTypes.func.isRequired,
  handleUpdateDeal: PropTypes.func.isRequired,
  updateWish: PropTypes.func.isRequired,
  updateStep: PropTypes.func.isRequired,
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  findService: PropTypes.func.isRequired,
  openDeleteStepDialogProps: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  funeral: PropTypes.object.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
  /** Function to get basic infos of a graveyard */
  getGraveyardBasicInfos: PropTypes.func.isRequired,
  /** basic info of the selected graveyard */
  graveyardBasicInfos: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  deal: makeSelectDeal(),
  steps: makeSelectSteps(),
  worshipTypes: makeSelectWorshipTypes(),
  concessionPrices: makeSelectConcessionPrices(),
  getStep: makeSelectStep(),
  getServices: makeSelectStepServices(),
  findService: makeSelectService(),
  findWish: makeSelectWish(),
  wishes: makeSelectWishes(),
  crematorium: makeSelectCrematorium(),
  ceremonies: makeSelectCeremonies(),
  defunctAge: makeSelectDefunctAge(),
  ConcessionTypes: makeSelectConcessionTypes(),
  graveyardBasicInfos: makeSelectGraveyardBasicInfos(),
  funeralParlorStayTypes: makeSelectFuneralParlorStayTypes(),
  funeral: makeSelectFuneral(),
  coffin: makeSelectCoffin(),
  isManualMode: makeSelectIsManualMode(),
  user: makeSelectUser(),
});

const mapDispatcthToProps = dispatch => ({
  getConcessionPrices: id => dispatch(getConcessionPrices(id)),
  getCrematorium: id => dispatch(getCrematorium(id)),
  getCeremonies: (id, params) => dispatch(getCeremonies(id, params)),
  getGraveyardConcessionTypes: id => dispatch(getGraveyardConcessionTypes(id)),
  getGraveyardConcessions: id => dispatch(getGraveyardConcessions(id)),
  getGraveyardBasicInfos: id => dispatch(getGraveyardBasicInfosDispatch(id)),
  getFuneralParlorStayTypes: id => dispatch(getFuneralParlorStayTypes(id)),
  setFuneralParlorStayTypes: stayTypes =>
    dispatch(setFuneralParlorStayTypes(stayTypes)),
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { deal, steps, funeral } = stateProps;
  const { dispatch } = dispatchProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    setAshesDestination: destinationType =>
      setAshesDestination(dispatch, steps, destinationType, funeral),
    handleUpdateDeal: update => dispatch(updateDeal(update, funeral)),
    updateWish: (wishType, value) =>
      dispatch(updateWishes(wishType, value, funeral, DEAL_TYPE_FUNERAL)),
    openDeleteStepDialogProps: (stepId, stepEventType) =>
      dispatch(
        openDeleteStepDialog(dispatch, stepId, stepEventType, steps, funeral),
      ),
    removeService: service => {
      dispatch(removeServiceAction(service, funeral, DEAL_TYPE_FUNERAL));
      dispatch(removeTriggerPingService(service));
    },
    addService: service =>
      dispatch(addServiceDispatch(service, funeral, DEAL_TYPE_FUNERAL)),
    updateService: service =>
      dispatch(updateServiceDispatch(service, funeral, DEAL_TYPE_FUNERAL)),
    updateStep: (update, stepToUpdate) =>
      dispatch(updateDealFromSteps(update, stepToUpdate, steps, deal)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatcthToProps, mergeProps);

export default compose(withConnect, injectIntl)(Steps);
