import { lineString } from '@turf/helpers';
import length from '@turf/length';

export function positionToLatLng([
  lng,
  lat,
]: GeoJSON.Position): google.maps.LatLng {
  return new google.maps.LatLng(lat, lng);
}

export function getPolygonsLatLng(
  polygons: GeoJSON.Position[][],
): google.maps.LatLng[][] {
  return polygons.map(polygon => polygon.map(positionToLatLng));
}

export function getPolygonLength(polygon: GeoJSON.MultiPolygon): number {
  const geometryLineString = lineString(polygon.coordinates.flat().flat());
  return length(geometryLineString);
}
