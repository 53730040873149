import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

import { DealDocument } from 'models/Deal/Document/index.ts';

export const selectDocumentsDomain = state => state.documents;

export const makeSelectDocuments = () =>
  createSelector(selectDocumentsDomain, documentState => {
    const documentsData = documentState.documents || [];
    return documentsData.map(data => new DealDocument(data));
  });

export const makeSelectSelectedDocumentIds = () =>
  createSelector(selectDocumentsDomain, state => state.selectedDocumentIds);

export const makeSelectSelectedDocuments = () =>
  createSelector(
    makeSelectDocuments(),
    makeSelectSelectedDocumentIds(),
    (documents, selectedIds) =>
      selectedIds.map(id => documents.find(doc => doc.id === id)),
  );

/**
 * Return the documents grouped by document type.
 */
export const makeSelectDocumentList = () =>
  createSelector(makeSelectDocuments(), documents => {
    if (!documents || isEmpty(documents)) {
      return {};
    }

    return documents.reduce(
      (formatted, doc) => {
        const section = doc.section || 'others';
        const sectionDocuments = formatted[section] || [];
        return {
          ...formatted,
          [section]: [...sectionDocuments, doc],
        };
      },
      { others: [] },
    );
  });

export const makeSelectLoading = () =>
  createSelector(selectDocumentsDomain, documentState => documentState.loading);

export const makeSelectError = () =>
  createSelector(selectDocumentsDomain, documentState => documentState.error);

export const makeSelectAreVersionsLoading = () =>
  createSelector(
    selectDocumentsDomain,
    substate => substate.areVersionsLoading,
  );

export const makeSelectVersions = () =>
  createSelector(
    selectDocumentsDomain,
    substate =>
      substate.versions &&
      substate.versions.map(version => new DealDocument.Version(version)),
  );
