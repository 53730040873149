import { createAction } from 'lib/reactvitam/action_cable';

interface ViewedPayload {
  notification_ids: number[];
}

function viewed(notification: ViewedPayload): ViewedPayload {
  return notification;
}

export default createAction(viewed, 'viewed');
