import { HTMLAttributes, MouseEvent, ReactNode, useContext } from 'react'
import LinkContext from './Context'

interface LinkProps {
  href: string
  newTab?: boolean
  download?: boolean
  className?: string
  children: ReactNode
  internal?: boolean
  property?: HTMLAttributes<unknown>['property']
  typeof?: HTMLAttributes<unknown>['typeof']
  onClick?: (event: MouseEvent<HTMLElement>) => void
  state?: unknown
}

/*
  If you wish to use another wrapper you need to :
  - Import your custom wrapper and Link Context at the root of the app

    ex :
      import MyWrapper from 'components/MyWrapper
      import { LinkContext } from '@advitam/ui';

  - Wrap your app with it
    <LinkContext.Provider value={MyWrapper}>
      <App />
    </LinkContext.Provider>
*/

export default function Link({
  href,
  newTab,
  download,
  className,
  children,
  property,
  internal,
  onClick,
  state,
  typeof: attrTypeof,
}: LinkProps): JSX.Element {
  let target: HTMLAnchorElement['target'] = '_self'
  let rel: HTMLAnchorElement['rel'] | undefined
  const LinkWrapper = useContext(LinkContext)

  if (newTab) {
    target = '_blank'
    rel = 'noreferrer'
  }

  if (!internal) {
    return (
      <a
        href={href}
        target={target}
        rel={rel}
        download={download}
        className={className}
        property={property}
        typeof={attrTypeof}
        onClick={onClick}
      >
        {children}
      </a>
    )
  }

  return (
    <LinkWrapper
      href={href}
      target={target}
      rel={rel}
      className={className}
      property={property}
      typeof={attrTypeof}
      onClick={onClick}
      state={state}
    >
      {children}
    </LinkWrapper>
  )
}

export { LinkContext }
