import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import {
  SupplierWarehouse,
  SupplierWarehouseJSON,
  SupplierWarehouseZoneJSON,
} from '@advitam/api/models/Supplier/Warehouse';
import { SupplierWarehouseZone } from '@advitam/api/models/Supplier/Warehouse/Zone';

import { SUPPLIER_WAREHOUSE } from './constants';
import type { AppStateSubset as SupplierAppStateSubset } from '../slice';
import type { AppStateSubset, State } from './slice';
import type { NewSupplierWarehouse } from './types';
import { makeSelectRawSupplier } from '../selectors';

type WarehouseSelector<T> = Selector<AppStateSubset, T>;

const selectWarehouseDomain = (state: AppStateSubset): State =>
  state[SUPPLIER_WAREHOUSE];

export const makeSelectRawWarehouse = (): WarehouseSelector<
  SupplierWarehouseJSON | NewSupplierWarehouse | null
> => createSelector(selectWarehouseDomain, ({ warehouse }) => warehouse);

export const makeSelectWarehouse = (): WarehouseSelector<SupplierWarehouse | null> =>
  createSelector(makeSelectRawWarehouse(), rawWarehouse =>
    rawWarehouse?.id ? new SupplierWarehouse(rawWarehouse) : null,
  );

export const makeSelectIsDisabled = (): Selector<
  AppStateSubset & SupplierAppStateSubset,
  boolean
> =>
  createSelector(
    makeSelectRawSupplier(),
    makeSelectRawWarehouse(),
    (supplier, warehouse) => supplier?.disabled || warehouse?.disabled || false,
  );

export const makeSelectRawWarehouseZones = (): WarehouseSelector<
  SupplierWarehouseZoneJSON[]
> => createSelector(selectWarehouseDomain, ({ zones }) => zones);

export const makeSelectWarehouseZones = (): WarehouseSelector<
  SupplierWarehouseZone[]
> =>
  createSelector(makeSelectRawWarehouseZones(), rawZones =>
    rawZones.map(zone => new SupplierWarehouseZone(zone)),
  );

export const makeSelectIsLoading = (): WarehouseSelector<boolean> =>
  createSelector(selectWarehouseDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): WarehouseSelector<boolean> =>
  createSelector(selectWarehouseDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): WarehouseSelector<boolean> =>
  createSelector(selectWarehouseDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): WarehouseSelector<boolean> =>
  createSelector(selectWarehouseDomain, ({ isUpdatingName }) => isUpdatingName);

export const makeSelectError = (): WarehouseSelector<SerializedApiError | null> =>
  createSelector(selectWarehouseDomain, ({ error }) => error);

export const makeSelectDestroyError = (): WarehouseSelector<SerializedApiError | null> =>
  createSelector(selectWarehouseDomain, ({ destroyError }) => destroyError);
