import { createAsyncThunk } from '@reduxjs/toolkit';

import { assert } from '@advitam/support';
import Api, { requestAsync as request } from 'api';

import { DEALS_LIST } from './constants';
import { AppStateSubset } from './slice';
import { makeSelectFilters, makeSelectCurrentPage } from './selectors';

export const fetchDeals = createAsyncThunk(
  `${DEALS_LIST}_FETCH_DEALS`,
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as AppStateSubset;
    const filters = makeSelectFilters()(state);
    const currentPage = makeSelectCurrentPage()(state);

    try {
      const { body } = await request(
        Api.V1.Deals.index({
          ...filters,
          page: currentPage,
        }),
      );
      assert(body !== null);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
