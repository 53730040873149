import { useLocation } from 'react-router';

interface LocationStateOptions {
  shouldReset?: boolean;
}

export function useLocationState<T>({
  shouldReset = true,
}: LocationStateOptions = {}): T | null {
  const location = useLocation();
  const state = location.state ? { ...(location.state as T) } : null;

  if (shouldReset) {
    window.history.replaceState(undefined, '');
  }

  return state;
}
