import { Action, AnyAction } from 'redux'

export const HYDRATE = '@advitam/react/hydrate'

export interface HydrateAction extends Action<typeof HYDRATE> {
  type: typeof HYDRATE
  payload: Record<string, unknown>
}

export function hydrate(payload: Record<string, unknown>): HydrateAction {
  return {
    type: HYDRATE,
    payload,
  }
}

export function isHydrate(action: AnyAction): action is HydrateAction {
  return action.type === HYDRATE
}
