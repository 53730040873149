import { push, replace } from 'redux-first-history';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { SupplierJSON } from '@advitam/api/models/Supplier';

import { assert, nextTick } from 'lib/support';

import { Path } from 'containers/App/constants';
import { SUPPLIER } from './constants';
import { NewSupplier } from './types';
import { AppStateSubset, setSupplier } from './slice';
import { makeSelectRawSupplier } from './selectors';

export const fetchSupplier = createAsyncThunk(
  `${SUPPLIER}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Suppliers.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createSupplier = createAsyncThunk(
  `${SUPPLIER}_CREATE`,
  async (supplier: NewSupplier, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(Api.V1.Suppliers.create(supplier));

      dispatch(setSupplier(body));
      // Let the form reset to avoid displaying the change loss modal
      nextTick(() => dispatch(replace(`./${body.id}`)));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateSupplier = createAsyncThunk(
  `${SUPPLIER}_UPDATE`,
  async (supplier: SupplierJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Suppliers.update(supplier));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const saveSupplier = createAsyncThunk(
  `${SUPPLIER}_SAVE`,
  async (supplier: SupplierJSON | NewSupplier, { dispatch }) => {
    if (supplier.id === undefined) {
      await dispatch(createSupplier(supplier));
    } else {
      await dispatch(updateSupplier(supplier));
    }
  },
);

export const updateSupplierName = createAsyncThunk(
  `${SUPPLIER}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const supplier = makeSelectRawSupplier()(state);
    assert(supplier?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Suppliers.update({ id: supplier.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const destroySupplier = createAsyncThunk(
  `${SUPPLIER}_DESTROY`,
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const supplier = makeSelectRawSupplier()(state);
    assert(supplier?.id !== undefined);

    try {
      await request(Api.V1.Suppliers.destroy(supplier.id));
      dispatch(push(Path.SUPPLIERS));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsSupplierDisabled = createAsyncThunk(
  `${SUPPLIER}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const supplier = makeSelectRawSupplier()(state);
    assert(supplier?.id !== undefined);

    try {
      await request(Api.V1.Suppliers.Disabled.update(supplier.id, disabled));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
