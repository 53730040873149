import PropTypes from 'prop-types';

const PhoneIcon = ({ className }) => (
  <svg viewBox="0 0 22.3 22.4" className={className}>
    <path
      fill="none"
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M6.7 8.4s1.6 5.3 7.2 7.4l1.6-1.6s1.4-1.7 3.1-.1l2.4 2.3s1.4 1.3.2 2.7L19 21.2s-2.7 1.8-6.9-.8c0 0-8.2-4.3-11.1-12 0 0-1.5-3.8 0-5.6L3.3 1s1-1.1 2.2 0l2.9 2.9s1.3 1.4 0 2.7L6.7 8.4z"
    />
  </svg>
);

PhoneIcon.propTypes = {
  className: PropTypes.string,
};

export default PhoneIcon;
