import { BillingType } from '../BillingType'
import { CheckoutType } from '../CheckoutType'
import { Country } from '../Country'
import { ContactMedia } from '../Entity/ContactMedia'
import { OpeningHoursJSON } from '../Entity/OpeningHours'
import { Model } from '../Model'
import { LegalisationType } from './LegalisationType'

interface ConsulateBase {
  id: number
  name: string
  address: string | null
  address_l2: string | null
  postal_code: string | null
  insee_code: string | null
  city: string | null
  department: string | null
  country: Country | null
  latitude: number | null
  longitude: number | null
  manual_address: boolean
  phone: string | null
  phone_2: string | null
  phone_3: string | null
  fax: string | null
  email: string | null
  email_2: string | null
  email_3: string | null
  email_4: string | null
  prefered_contact_media: ContactMedia | null
  opening_hours: OpeningHoursJSON | null
  comment: string | null
  billing_type: BillingType | null
  checkout_type: CheckoutType | null
  cheque_order: string | null
  cheque_address: string | null
  rib: string | null
  iban: string | null
  siret: string | null
  vat: string | null
  website: string | null
  authentic_document_needed: boolean | null
  headquarter_name: string | null
  headquarter_address: string | null
  headquarter_postal_code: string | null
  headquarter_country: Country | null
  headquarter_city: string | null
  tax: number | null
  reference_country: Country | null
  nb_paper_to_trad: number | null
  legalisation: LegalisationType | null
  default: boolean
  disabled: boolean
  current_update_user_id: number | null
  last_update_user_id: number | null
}

export interface ConsulateJSON extends ConsulateBase {
  current_update_dt: string | null
  last_update_dt: string | null
}

export interface Consulate extends ConsulateBase {
  current_update_dt: Date | null
  last_update_dt: Date | null
}

export class Consulate extends Model<ConsulateJSON> {
  constructor(data: ConsulateJSON) {
    super(data)
    this.current_update_dt = Model.parseDate(data.current_update_dt)
    this.last_update_dt = Model.parseDate(data.last_update_dt)
  }
}
