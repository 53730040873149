import { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { assert } from '@advitam/support';
import { NumberInput, FormattedPrice } from '@advitam/ui';
import { messages as crudMessages } from 'containers/Crud';

import {
  makeSelectSupplier,
  makeSelectCanEdit,
} from '../../../../../selectors';
import style from '../List.module.scss';
import { useProduct, useProductPrefix } from '../../ProductListProvider';

const FIELD_PLACEHOLDER = '__';

interface ProductsRowProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function ProductsRow({
  rowIndex,
  style: wrapperStyle,
  className,
}: ProductsRowProps): JSX.Element | null {
  const supplier = useSelector(makeSelectSupplier());
  const canUserEdit = useSelector(makeSelectCanEdit());

  const warehouseProduct = useProduct(rowIndex);
  const prefix = useProductPrefix(rowIndex);

  assert(supplier !== null);

  return (
    <div
      style={wrapperStyle}
      className={[className, style.cost_cell].join(' ')}
    >
      <NumberInput
        required={supplier.negociated}
        disabled={!supplier.negociated || !canUserEdit}
        placeholder={FIELD_PLACEHOLDER}
        className={style.input}
        name={`${prefix}.cost`}
        suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
      />
      <span>
        {warehouseProduct.cost !== null && (
          <FormattedPrice value={warehouseProduct.cost} />
        )}
      </span>
    </div>
  );
}
