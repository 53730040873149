import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

interface SectionAliasProps {
  from: string;
  to: string;
}

export default function SectionAlias({ from, to }: SectionAliasProps): null {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash === `#${from}`) {
      navigate(`#${to}`, { replace: true });
    }
  }, [location.hash]);

  return null;
}
