import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { AutocompleteDepartmentsResult } from '@advitam/api/v1/Autocompletes';
import type { WorshipTypeJSON } from '@advitam/api/models/WorshipType';
import type {
  ConcessionSemelleTypeJSON,
  ConcessionTypeJSON,
} from '@advitam/api/models/Concession';
import type { FuneralTeamUserJSON } from '@advitam/api/models/FuneralTeamUser';
import type { FuneralBrandJSON } from '@advitam/api/models/FuneralBrand';
import { assert } from 'lib/support';

import { DATA, Dataset, Datasets } from './constants';
import type { AppStateSubset, State } from './slice';

type DataSelector<T> = Selector<AppStateSubset, T>;

const selectDataDomain = (state: AppStateSubset): State => state[DATA];

const selectDatasets = createSelector(
  selectDataDomain,
  state => state.datasets,
);

export const makeSelectError = (): DataSelector<SerializedApiError | null> =>
  createSelector(selectDataDomain, state => state.error);

export const makeSelectIsLoading = (): DataSelector<boolean> =>
  createSelector(selectDataDomain, state => state.isLoading);

export const makeSelectMissingDatasetsIn = (
  datasets: Dataset[],
): DataSelector<Dataset[]> =>
  createSelector(selectDatasets, data =>
    datasets.filter(dataset => data[dataset] === undefined),
  );

export const makeSelectAreDatasetsLoaded = (
  datasets: Dataset[],
): DataSelector<boolean> =>
  createSelector(
    selectDatasets,
    data => !datasets.some(dataset => data[dataset] === undefined),
  );

const makeSelectDataset = <T extends Dataset>(
  dataset: T,
): DataSelector<Datasets[T]> =>
  createSelector(selectDatasets, data => {
    // A cast is needed because we can not drop the undefined part of a
    // `Partial<Datasets>[T]` with `assert`
    const dataItem = data[dataset] as Datasets[T] | undefined;
    assert(dataItem !== undefined);
    return dataItem;
  });

export const makeSelectDepartments = (): DataSelector<
  AutocompleteDepartmentsResult[]
> => makeSelectDataset('departments');

export const makeSelectWorshipTypes = (): DataSelector<WorshipTypeJSON[]> =>
  makeSelectDataset('worshipTypes');

export const makeSelectWorshipTypesNeedingLocation = (): DataSelector<
  WorshipTypeJSON[]
> =>
  createSelector(makeSelectWorshipTypes(), worshipTypes =>
    worshipTypes.filter(worshipType => worshipType.need_location),
  );
export const makeSelectConcessionTypes = (): DataSelector<
  ConcessionTypeJSON[]
> => makeSelectDataset('concessionTypes');

export const makeSelectGraveyardsSemelles = (): DataSelector<
  ConcessionSemelleTypeJSON[]
> => makeSelectDataset('concessionSemelleTypes');

export const makeSelectFuneralBrands = (): DataSelector<FuneralBrandJSON[]> =>
  makeSelectDataset('funeralBrands');

export const makeSelectFuneralTeam = (): DataSelector<FuneralTeamUserJSON[]> =>
  makeSelectDataset('funeralTeam');

export const makeSelectActiveFuneralTeam = (): DataSelector<
  FuneralTeamUserJSON[]
> =>
  createSelector(makeSelectFuneralTeam(), substate =>
    substate.filter(member => !member.disabled),
  );

export const makeSelectFuneralTeamUser = (
  id: number | null | undefined,
): DataSelector<FuneralTeamUserJSON | null> =>
  createSelector(
    makeSelectFuneralTeam(),
    funeralTeam => funeralTeam.find(member => member.id === id) || null,
  );

export const makeSelectFuneralTeamUserInitials = (
  id: number | null | undefined,
): DataSelector<string | null> =>
  createSelector(makeSelectFuneralTeamUser(id), user => {
    if (!user) {
      return null;
    }
    if (user.name) {
      const [firstname, lastname] = user.name.split(' ');
      if (firstname && lastname) {
        return firstname[0] + lastname[0];
      }
    }
    return user.email.substring(0, 2) || null;
  });

export const makeSelectFuneralTeamUserDisplayName = (
  id: number | null | undefined,
): DataSelector<string | null> =>
  createSelector(
    makeSelectFuneralTeamUser(id),
    user => user?.name || user?.email || null,
  );

export const makeSelectFuneralTeamUserImage = (
  id: number | null | undefined,
): DataSelector<string | null> =>
  createSelector(
    makeSelectFuneralTeamUser(id),
    user => user?.avatar_link || null,
  );
