import { ReactNode } from 'react';
import Modal from 'react-modal';

import Close from 'images/close.svg';

import style from './style.scss';

interface MessageModalProps {
  children: ReactNode | ReactNode[];
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export function MessageModal({
  isOpen,
  children,
  onClose,
  className = '',
}: MessageModalProps): JSX.Element {
  return (
    <Modal
      className={[style['modal__position--message'], className].join(' ')}
      style={{
        overlay: {
          zIndex: 10,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className={style.modal__body}>
        <button
          type="button"
          onClick={onClose}
          className={style['modal__action--close']}
        >
          <Close />
        </button>
        <div className={style['modal__body--inside']}>
          <div className={style.modal__content}>{children}</div>
        </div>
      </div>
    </Modal>
  );
}
