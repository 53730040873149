import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Heading, Modal, Text } from '@advitam/ui';
import { makeSelectUpdatingUser } from 'cables/Resource/selectors';

import messages from './messages';
import style from './UpdatingModal.module.scss';

export default function UpdateModal(): JSX.Element | null {
  const user = useSelector(makeSelectUpdatingUser());

  if (!user) {
    return null;
  }

  return (
    <Modal isOpen className={`new-design col-4 col-md-6 ${style.modal}`}>
      <Heading small tagName="h5">
        <b>
          <FormattedMessage id={messages.updatingModalTitle.id} />
        </b>
      </Heading>
      <Text tagName="p">
        <FormattedMessage
          id={messages.updatingModalText.id}
          values={{ name: user.name }}
        />
      </Text>
    </Modal>
  );
}
