import { useCallback, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { HardSpinner, PageSpinner } from '@advitam/ui';

import { Path } from 'containers/App/constants';
import { useEntitySeed } from 'containers/Entities/useEntitySeed';
import ResourceCable, {
  ResourceDestroyModal,
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { assert } from 'lib/support';
import { withDatasets } from 'slices/data';

import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectRawPolice,
} from './selectors';
import { fetchPolice } from './thunk';
import slice, { clearError, setPolice } from './slice';
import { POLICE_SKELETON } from './constants';

function Container(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const police = useSelector(makeSelectRawPolice());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());
  const seed = useEntitySeed();

  const { id } = useParams();
  const policeId = id && parseInt(id, 10);
  const canRender = !isLoading && police;

  const fetchResource = useCallback(() => {
    assert(typeof policeId === 'number');
    dispatch(fetchPolice(policeId));
  }, [dispatch, policeId]);

  useEffect(() => {
    if (!policeId) {
      if (seed) {
        dispatch(setPolice({ ...POLICE_SKELETON, ...seed }));
      } else {
        navigate(Path.ENTITIES, { replace: true });
      }
    } else if (policeId !== police?.id) {
      fetchResource();
    }

    return () => {
      dispatch(clearError());
    };
  }, [policeId]);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {policeId && (
        <ResourceCable
          resourceId={policeId}
          resourceType={ResourceRoomType.POLICE}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={fetchResource} />
          <ResourceDestroyModal path={Path.ENTITIES} />
        </ResourceCable>
      )}
    </>
  );
}

export default compose(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(Container);
