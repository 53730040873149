import { CrematoriumJSON } from '@advitam/api/models/Crematorium';
import { formatEntity } from '../Entity';

export function formatName(crematorium: CrematoriumJSON): string {
  return formatEntity(crematorium);
}

export function FormattedName(crematorium: CrematoriumJSON): JSX.Element {
  return <>{formatName(crematorium)}</>;
}
