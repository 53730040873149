import ClearIcon from '../../../../images/icons/times-circle.svg'
import { FlatButton } from '../../../Button'
import style from '../UI.module.scss'

interface ClearButtonProps {
  className?: string
  onClick: () => void
}

export default function ClearButton({ className, onClick }: ClearButtonProps): JSX.Element {
  return (
    <FlatButton className={[style.clear, className].filter(Boolean).join(' ')} onClick={onClick}>
      <ClearIcon />
    </FlatButton>
  )
}
