import { FormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';
import { Text } from '@advitam/ui';
import { makeSelectFuneralTeamUser } from 'slices/data';

interface LastUpdateProps {
  userId: number;
  date: Date;
}

export default function LastUpdate({
  userId,
  date,
}: LastUpdateProps): JSX.Element {
  const user = useSelector(makeSelectFuneralTeamUser(userId));
  return (
    <Text extraSmall tagName="div">
      <i>
        <FormattedDate value={date} /> - {user?.email}
      </i>
    </Text>
  );
}
