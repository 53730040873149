import { assert } from '@advitam/support'

import { post, put } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { DealJSON } from '../../../models/Funnel/Funeral'
import type { ClientJSON } from '../../../models/Funnel/Client'
import serialize from './serializer'
import { TrackingAttributes } from '../../types/Tracking'

export class Deals {
  static create(
    this: void,
    deal: DealJSON,
    client: ClientJSON,
    tracking?: TrackingAttributes,
  ): ApiRequestDescriptor<DealJSON> {
    return post(`/api/v1/funeral_funnel/deals`, serialize(deal, client, tracking))
  }

  static update(this: void, deal: DealJSON, client: ClientJSON): ApiRequestDescriptor<DealJSON> {
    assert(deal.uuid !== undefined)
    return put(`/api/v1/funeral_funnel/deals/${deal.uuid}`, serialize(deal, client))
  }
}
