import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { makeSelectWish, makeSelectWishes } from 'containers/Deal/selectors';
import { DEAL_TYPE_FUNERAL } from 'containers/Deal/constants.ts';

import { makeSelectFuneral } from '../selectors';
import { updateWishes } from '../actions';
import CheckboxWishes from '../StepsSection/StepEditors/CheckboxWishes';
import messages from './messages';

export default function UnsubscribeSection() {
  const dispatch = useDispatch();

  const findWish = useSelector(makeSelectWish());
  const wishes = useSelector(makeSelectWishes());
  const funeral = useSelector(makeSelectFuneral());

  const updateWish = useCallback(
    (wishType, value) => {
      dispatch(updateWishes(wishType, value, funeral, DEAL_TYPE_FUNERAL));
    },
    [funeral],
  );

  return (
    <section id="unsubscribe">
      <FormattedMessage {...messages.unsubscribeSection} tagName="h2" />
      <div className="dealSection">
        <CheckboxWishes
          wishType="want_closing_account"
          message={messages.account}
          wishes={wishes}
          findWish={findWish}
          updateWish={updateWish}
        />
        <CheckboxWishes
          wishType="want_closing_social_media"
          message={messages.socialMedia}
          wishes={wishes}
          findWish={findWish}
          updateWish={updateWish}
        />
      </div>
    </section>
  );
}
