import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { Supplier, SupplierJSON } from '@advitam/api/models/Supplier';
import {
  AppStateSubset as AuthAppStateSubset,
  makeSelectUser,
} from 'slices/auth';

import { SUPPLIER } from './constants';
import { AppStateSubset, State } from './slice';
import { NewSupplier } from './types';

type SupplierSelectors<T> = Selector<AppStateSubset, T>;

const selectSupplierDomain = (state: AppStateSubset): State => state[SUPPLIER];

export const makeSelectRawSupplier = (): SupplierSelectors<
  SupplierJSON | NewSupplier | null
> => createSelector(selectSupplierDomain, ({ supplier }) => supplier);

export const makeSelectSupplier = (): SupplierSelectors<Supplier | null> =>
  createSelector(makeSelectRawSupplier(), rawSupplier =>
    rawSupplier?.id ? new Supplier(rawSupplier) : null,
  );

export const makeSelectIsLoading = (): SupplierSelectors<boolean> =>
  createSelector(selectSupplierDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): SupplierSelectors<boolean> =>
  createSelector(selectSupplierDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): SupplierSelectors<boolean> =>
  createSelector(selectSupplierDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): SupplierSelectors<boolean> =>
  createSelector(selectSupplierDomain, ({ isUpdatingName }) => isUpdatingName);

export const makeSelectDestroyError = (): SupplierSelectors<SerializedApiError | null> =>
  createSelector(selectSupplierDomain, ({ destroyError }) => destroyError);

export const makeSelectError = (): SupplierSelectors<SerializedApiError | null> =>
  createSelector(selectSupplierDomain, ({ error }) => error);

export const makeSelectCanEdit = (): Selector<
  AppStateSubset & AuthAppStateSubset,
  boolean
> =>
  createSelector(makeSelectSupplier(), makeSelectUser(), (supplier, user) =>
    Boolean(user?.isFuneralDirectorOrAbove || !supplier?.negociated),
  );
