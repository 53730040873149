import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import type { IntlShape } from 'react-intl';
import { DateTime } from 'luxon';

import Api, { request } from '@advitam/api';
import { Person } from '@advitam/ui';
import { assert } from '@advitam/support';
import LegacyApi from 'api';
import { Advitam, Url } from 'containers/App/constants';
import {
  setFile,
  AppStateSubset as DTEAppStateSubset,
  setDictionary,
} from 'containers/DocumentTemplateEditor/slice';
import {
  fetchData,
  uploadDocument,
} from 'containers/DocumentTemplateEditor/thunk';
import {
  makeSelectDefaultFontSize,
  makeSelectDictionary,
  makeSelectPages,
} from 'containers/DocumentTemplateEditor/selectors';
import type { DocumentTemplateData } from 'containers/DocumentTemplateEditor/models';
import buildPDF from 'containers/DocumentTemplateEditor/utils/buildPDF';
import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import type { AppStateSubset as DealAppStateSubset } from 'containers/Deal/slice';
import { DealDocumentSource, DealDocument } from 'models/Deal/Document';
import {
  makeSelectUser,
  AppStateSubset as AuthAppStateSubset,
} from 'slices/auth';
import { MimeTypes } from 'utils/constants';

import type { AppStateSubset as EditorAppStateSubset } from './slice';
import { DEFAULT_VARIABLES, DOCUMENT_EDITOR } from './constants';
import { makeSelectBlob, makeSelectDealDocumentId } from './selectors';

type AppStateSubset = EditorAppStateSubset &
  DTEAppStateSubset &
  DealAppStateSubset &
  AuthAppStateSubset;

interface FetchDictionaryArgs {
  dealId: number;
  dealDocument: DealDocument;
  intl: IntlShape;
}

export const fetchDictionary = createAsyncThunk(
  `${DOCUMENT_EDITOR}/FETCH_DICTIONARY`,
  async (
    { dealId, dealDocument, intl }: FetchDictionaryArgs,
    { dispatch, getState, rejectWithValue },
  ) => {
    if (dealDocument.source !== DealDocumentSource.EDITABLE) {
      const state = getState() as AppStateSubset;
      const advisor = makeSelectUser()(state);
      assert(advisor !== null);

      dispatch(
        setDictionary({
          current_date: DateTime.now().toLocaleString(DateTime.DATE_SHORT),
          advitam__stamp: Url.STAMP_IMAGE,
          advitam__city: Advitam.CITY,
          advitam__advisor_name: Person.formatName(advisor, intl),
          advitam__advisor_lastname: advisor.lastname,
        }),
      );
      return undefined;
    }

    if (!dealDocument.document) {
      return undefined;
    }

    try {
      const { body } = await request<Record<string, unknown>, unknown>(
        LegacyApi.V1.Deals.Documents.Dictionaries.show(
          dealId,
          dealDocument.document.id,
        ),
      );
      dispatch(setDictionary(body));
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchVariables = createAsyncThunk(
  `${DOCUMENT_EDITOR}/FETCH_VARIABLES`,
  async (document: DealDocument, { rejectWithValue }) => {
    if (document.source !== DealDocumentSource.EDITABLE) {
      return DEFAULT_VARIABLES;
    }

    try {
      const { body } = await request(
        Api.V1.DocumentTypes.Variables.show(document.document_type.slug),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchFile = createAsyncThunk(
  `${DOCUMENT_EDITOR}/FETCH_FILE`,
  async (dealDocument: DealDocument, { dispatch }) => {
    const templateId =
      dealDocument.template_id || dealDocument.document?.template_id;
    if (templateId) {
      await dispatch(fetchData(templateId));
      return null;
    }

    if (!dealDocument.version.file) {
      return null;
    }

    const response = await fetch(dealDocument.version.file);
    const blob = await response.blob();
    dispatch(setFile(blob));
    return blob;
  },
);

interface FetchDocumentPayload {
  dealDocument: DealDocument;
  intl: IntlShape;
}

export const fetchDocument = createAsyncThunk(
  `${DOCUMENT_EDITOR}/FETCH_DOCUMENT`,
  async (
    { dealDocument, intl }: FetchDocumentPayload,
    { dispatch, getState },
  ) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.id !== undefined);

    await Promise.all([
      dispatch(fetchFile(dealDocument)),
      dispatch(fetchDictionary({ dealId: deal.id, dealDocument, intl })),
      dispatch(fetchVariables(dealDocument)),
    ]);
  },
);

async function generatePDF(
  name: string,
  state: AppStateSubset,
  intl: IntlShape,
): Promise<void> {
  const blob = makeSelectBlob()(state);
  assert(blob !== null);
  const buffer = await blob.arrayBuffer();
  const dictionary = makeSelectDictionary()(state);
  const data: DocumentTemplateData = {
    pages: makeSelectPages()(state),
    defaultFontSize: makeSelectDefaultFontSize()(state),
  };

  const fileData = await buildPDF(buffer, data, dictionary, intl);
  const file = new File([fileData], `${name}.pdf`, { type: MimeTypes.PDF });

  const { body: upload } = await request(Api.V1.Upload.Files.create(file));
  assert(upload !== null);
  const id = makeSelectDealDocumentId()(state);
  assert(id !== null);

  const deal = makeSelectDeal()(state);
  assert(deal?.uuid !== undefined);

  await request(
    Api.V1.Deals.Documents.Upload.Files.update(deal.uuid, id, upload.uuid),
  );
  return undefined;
}

interface SaveDocumentPayload {
  document: DealDocument;
  intl: IntlShape;
}

export const saveDocument = createAsyncThunk(
  `${DOCUMENT_EDITOR}/SAVE_DOCUMENT`,
  async (
    { document, intl }: SaveDocumentPayload,
    { dispatch, getState, rejectWithValue },
  ) => {
    if (document.source !== DealDocumentSource.EDITABLE) {
      const state = getState() as AppStateSubset;

      try {
        await generatePDF(document.name, state, intl);
        return undefined;
      } catch (error) {
        return rejectWithValue(error);
      }
    }

    await dispatch(
      uploadDocument({ type: 'DealDocument', id: document.id }),
    ).then(unwrapResult);

    try {
      await request(
        LegacyApi.V1.Deals.Documents.Generation.create([document.id]),
      );
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
