import Fuse from 'fuse.js';

import type { IntlShape } from 'react-intl';
import { safeFormatMessage } from 'utils/functions.typed';

import { Input } from '../models';
import sectionMessages from '../sectionMessages';
import variableMessages from '../variableMessages';

export interface InputDefaultProps {
  size?: [number, number];
}

export const INPUTS = {
  text: { size: [140, 15] as [number, number] },
  circle: { size: [23, 15] as [number, number] },
  check: {},
  signature: { size: [149, 80] as [number, number] },
  currentDate: {
    size: [100, 15] as [number, number],
    variable: 'current_date',
    type: 'text',
  },
};

export const INTEGRATION_INPUTS = {
  manualZone: { size: [40, 40] as [number, number] },
};

export function getInputs(
  isIntegrationMode: boolean,
): Record<string, InputDefaultProps> {
  if (!isIntegrationMode) {
    return INPUTS;
  }
  return {
    ...INPUTS,
    ...INTEGRATION_INPUTS,
  };
}

export interface Variable {
  key: string;
  name: string;
  sectionName: string;
}

export interface Section {
  key: string;
  name: string;
  variables: Variable[];
}

export function getVariables(
  vars: string[],
  filter: string,
  intl: IntlShape,
): Section[] {
  const sorted: Record<string, string[]> = {};
  vars.forEach(variable => {
    const [section, name] = variable.split('__', 2);
    sorted[section] ||= [];
    sorted[section].push(name);
  });

  return Object.keys(sorted)
    .map(key => {
      const name = safeFormatMessage(intl, sectionMessages, key);
      const section = {
        key,
        name,
        variables: sorted[key]
          .map(v => ({
            key: v,
            name: safeFormatMessage(intl, variableMessages, v),
            sectionName: name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      };

      if (filter) {
        const fuse = new Fuse(section.variables, {
          keys: ['name', 'sectionName'],
          threshold: 0.4,
        });
        section.variables = fuse
          .search(filter)
          .map(suggestion => suggestion.item);
      }

      return section;
    })
    .filter(section => section.variables.length > 0)
    .sort((a, b) => a.name.localeCompare(b.name));
}

const VARIABLE_INPUTS: Record<string, Input> = {
  advitam__stamp: {
    type: 'image',
    value: undefined,
    position: [0, 0],
    size: [128, 100],
  },
  advitam__signature: {
    type: 'image',
    value: undefined,
    position: [0, 0],
    size: [128, 100],
  },
};

export function getVariableInput(
  variable: string,
  position: [number, number],
): Input {
  let input = VARIABLE_INPUTS[variable];
  if (!input) {
    input = {
      type: 'text',
      position,
      value: undefined,
      ...INPUTS.text,
    };
  }

  input.position = position;
  input.variable = variable;
  return input;
}
