import type { ReactNode } from 'react';
import Tippy from '@tippyjs/react';

import style from './style.scss';

interface IconProps {
  onClick: () => void;
  disabled: boolean;
  tooltipMessage: ReactNode;
  children: JSX.Element;
}

export default function Icon({
  onClick,
  children,
  disabled,
  tooltipMessage,
}: IconProps): JSX.Element {
  return (
    <Tippy content={tooltipMessage}>
      <button
        type="button"
        onClick={disabled ? undefined : onClick}
        className={`${style.icon} ${disabled ? '' : style.clickable}`}
      >
        {children}
      </button>
    </Tippy>
  );
}
