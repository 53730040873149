import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, SerializedApiError } from 'api';

import { Layer } from 'models/Deal';

import { LAYERS_MODAL } from './constants';
import { cleanLayers, fetchLayers } from './thunk';

export interface State {
  layers: Layer[];
  isOpen: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [LAYERS_MODAL]: State;
}

const initialState: State = {
  layers: [],
  isOpen: false,
  error: null,
};

const slice = createSlice({
  name: LAYERS_MODAL,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchLayers.fulfilled, (state, { payload }) => {
      state.layers = payload;
      if (payload.length > 0) {
        state.isOpen = true;
      }
    });
    builder.addCase(fetchLayers.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
    builder.addCase(cleanLayers.fulfilled, state => {
      state.layers = [];
      state.isOpen = false;
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setIsOpen } = slice.actions;
export default slice;
