import { createAsyncThunk } from '@reduxjs/toolkit';

import { makeSelectRawClient } from 'containers/Client/selectors';
import { assert } from 'lib/support';

import type { AppStateSubset } from '../../slice';
import { updateClient } from '../../thunk';
import { open as openDeceasedModal } from './DeceasedModal/slice';
import { CLIENT_GENERAL } from './constants';
import type { GeneralForm } from './types';

export const SaveStep = {
  DECEASED: 0,
  CLIENT: 1,
};

interface SaveGeneralSectionFromStepPayload {
  step: number;
  values: GeneralForm;
}

/**
 * Implementation for saving the general section.
 *
 * Saving the general section is made of multiple steps, sometimes requiring
 * user interactions between the different steps. To support these use cases,
 * it is implemented as a recursive thunk, each call specifying a step to begin
 * with (likely its `SaveStep` + 1). Steps which are not applicable are skipped.
 *
 * This is meant to be used in thunk implementations only (not even in a reducer).
 */
export const saveGeneralSectionFromStep = createAsyncThunk(
  `${CLIENT_GENERAL}/SAVE_FROM STEP`,
  async (
    { values, step }: SaveGeneralSectionFromStepPayload,
    { dispatch, getState },
  ) => {
    const state = getState() as AppStateSubset;
    const client = makeSelectRawClient()(state);
    assert(client !== null);

    if (
      step <= SaveStep.DECEASED &&
      !client.defunct_id &&
      values.client.deceased
    ) {
      dispatch(openDeceasedModal());
    } else {
      await dispatch(updateClient(values));
    }
  },
);

export const saveGeneralSection = createAsyncThunk(
  `${CLIENT_GENERAL}/SAVE`,
  async (values: GeneralForm, { dispatch }) => {
    await dispatch(saveGeneralSectionFromStep({ step: 0, values }));
  },
);
