import { ChangeEvent, FocusEvent, ReactElement, ReactNode, useCallback } from 'react'
import { BlurEvent } from 'react-autosuggest'

import BaseAutosuggest from '../Autosuggest/Base'
import { Headquarter } from './types'
import { serialize } from './converters'
import { fetchCompanies } from './api'

const SIRET_MASK = '000 000 000 00000'

function getDisplayValue(hq: Headquarter): string {
  return hq.siret || ''
}

function renderSuggestion(hq: Headquarter): string {
  const siret =
    hq.siret &&
    [
      hq.siret.substring(0, 3),
      hq.siret.substring(3, 6),
      hq.siret.substring(6, 9),
      hq.siret.substring(9),
    ].join(' ')
  return [siret, hq.name].filter(Boolean).join(' - ')
}

interface SiretAutocompleteProps {
  value?: Headquarter
  label?: ReactNode
  onChange: (data: Headquarter | undefined) => void
  onInput?: (ev: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLElement>, params?: BlurEvent<Headquarter>) => void
  disabled?: boolean
  expandOnTop?: boolean
  error?: ReactElement | boolean
  className?: string
  placeholder?: string
}

export default function SiretAutocomplete({
  value,
  label,
  disabled,
  expandOnTop,
  error,
  className,
  placeholder,
  onChange,
  onInput,
  onBlur,
}: SiretAutocompleteProps): JSX.Element {
  const fetchSuggestions = useCallback(async (q: string): Promise<Headquarter[]> => {
    try {
      const { results } = await fetchCompanies({ q })
      return results.map(company => serialize(company))
    } catch (err) {
      if (!['production', 'test'].includes(process.env.NODE_ENV || '')) {
        // eslint-disable-next-line no-console
        console.error(err)
      }

      return []
    }
  }, [])

  return (
    <BaseAutosuggest<Headquarter>
      label={label}
      error={error}
      disabled={disabled}
      expandOnTop={expandOnTop}
      className={className}
      placeholder={placeholder}
      value={value}
      getSuggestionValue={getDisplayValue}
      getDisplayValue={getDisplayValue}
      renderSuggestion={renderSuggestion}
      fetchSuggestions={fetchSuggestions}
      onChange={onChange}
      onInput={onInput}
      onBlur={onBlur}
      mask={SIRET_MASK}
    />
  )
}
