import Modal from './Modal'
import CloseButton from './CloseButton'
import ModalTitle from './Title'
import ConfirmationModal from './Content/Confirmation'
import ContentModal from './Content/Content'
import LoadingModal from './Content/Loading'
import MessageModal from './Content/Message'

export default Modal
export { ConfirmationModal, ContentModal, CloseButton, LoadingModal, MessageModal, ModalTitle }
