import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { withSlice } from '@advitam/react';
import { Url } from 'containers/App/constants';
import { Person } from 'components/Format';
import PageSpinner from 'components/Spinner/PageSpinner';
import DefunctProfile from 'components/DefunctProfile';

import Settings from './Settings';
import Donation from './Donation';
import Customization from './Customization';
import { fetchMemorial } from './thunk';
import { makeSelectMemorial, makeSelectIsLoading } from './selectors';
import messages from './messages';
import slice from './slice';
import style from './style.scss';

interface ContentProps {
  uuid: string;
}

function Content({ uuid }: ContentProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const memorial = useSelector(makeSelectMemorial());
  const isLoading = useSelector(makeSelectIsLoading());

  useEffect(() => {
    dispatch(fetchMemorial(uuid));
  }, [dispatch, uuid]);

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!memorial) {
    return <FormattedMessage id={messages.notAvailable.id} />;
  }

  const formattedDefunct = Person.formatName(memorial.defunct, intl);

  return (
    <div className={`dealSection ${style.memorial}`}>
      <div>
        <DefunctProfile
          firstname={memorial.defunct.firstname || ''}
          lastname={memorial.defunct.lastname || ''}
          pictureLink={memorial.p_link}
        />
        <div>
          <h3>{formattedDefunct}</h3>
          <a
            className="link--primary"
            href={`${Url.MEMORIAL}/${memorial.url}`}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage
              id={messages.accessMemorial.id}
              values={{ defunct: formattedDefunct }}
            />
          </a>
        </div>
      </div>
      <div>
        <Customization memorial={memorial} />
        <Settings memorial={memorial} />
        <Donation memorial={memorial} />
      </div>
    </div>
  );
}

export default withSlice<ContentProps>(slice)(Content);
