import { ReactElement, ReactNode } from 'react'
import Text from '../../Text'
import style from './Field.module.scss'

interface FieldCardContentProps {
  icon: ReactElement
  text: ReactNode
}

export default function FieldCardContent({ icon, text }: FieldCardContentProps): JSX.Element {
  return (
    <>
      <span className={style.icon}>{icon}</span>
      <Text large className={style.text}>
        <b>{text}</b>
      </Text>
    </>
  )
}
