import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  label: {
    id: 'app.containers.Deal.SendBatchModal.Documents.label',
  },
  placeholder: {
    id: 'app.containers.Deal.SendBatchModal.Documents.placeholder',
  },
});
