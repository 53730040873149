// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreationModal-module__overlay--vfiAC{z-index:10000}div.CreationModal-module__modal--ioy5M{z-index:10001;padding:24px 32px 32px}@media(max-width: 575px){div.CreationModal-module__modal--ioy5M{width:100%}}div.CreationModal-module__modal--ioy5M button{display:flex;margin:auto}`, "",{"version":3,"sources":["webpack://./src/containers/Entities/parts/CreationModal/CreationModal.module.scss","webpack://./../ui/scss/_media.scss"],"names":[],"mappings":"AAEA,sCACE,aAAA,CAGF,uCACE,aAAA,CACA,sBAAA,CCqBE,yBDvBJ,uCAKI,UAAA,CAAA,CAGF,8CACE,YAAA,CACA,WAAA","sourcesContent":["@import '~@advitam/ui/scss/media';\n\n.overlay {\n  z-index: 10000; // TODO: Remove once z-indices are fixed\n}\n\ndiv.modal {\n  z-index: 10001; // TODO: Remove once z-indices are fixed\n  padding: 24px 32px 32px;\n\n  @include below(sm) {\n    width: 100%;\n  }\n\n  button {\n    display: flex;\n    margin: auto;\n  }\n}\n","$breakpoints: (\n  xxl: 1440px,\n  xl: 1200px,\n  lg: 992px,\n  md: 768px,\n  sm: 576px,\n  xs: 375px\n);\n\n@mixin above($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n@mixin below($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (max-width: ($breakpoint-value - 1)) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n:export {\n  /* stylelint-disable property-no-unknown */\n  xxl: 1440px;\n  xl: 1200px;\n  lg: 992px;\n  md: 768px;\n  sm: 576px;\n  xs: 375px;\n  /* stylelint-enable property-no-unknown */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"overlay": `CreationModal-module__overlay--vfiAC`,
	"modal": `CreationModal-module__modal--ioy5M`
};
export default ___CSS_LOADER_EXPORT___;
