import { ReactNode } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsPopup } from 'containers/Crud';
import { assert } from 'lib/Assert';

import CrematoriumSidebar from '../Sidebar';
import { makeSelectRawCrematorium } from '../selectors';
import { updateCrematoriumName } from '../thunk';
import style from '../Crematorium.module.scss';
import { CrematoriumForm } from './types';

interface WrapperProps<T> {
  children: ReactNode;
  initialSectionValues?: T;
  onSubmit: (values: CrematoriumForm<T>) => void;
}

export default function Wrapper<T>({
  children,
  initialSectionValues,
  onSubmit,
}: WrapperProps<T>): JSX.Element {
  const dispatch = useDispatch();
  const crematorium = useSelector(makeSelectRawCrematorium());
  assert(crematorium !== null);

  const initialValues: Partial<CrematoriumForm<T>> = {
    crematorium,
    sectionValues: initialSectionValues,
  };

  const handleFormSubmit = (values: CrematoriumForm<T>): void => {
    const { crematorium: formCrematorium } = values;

    // update crematorium name when section does not handle crematorium save
    if (values.sectionValues && crematorium.name !== formCrematorium.name) {
      dispatch(updateCrematoriumName(formCrematorium.name));
    }

    onSubmit(values);
  };

  return (
    <Form<CrematoriumForm<T>>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <CrematoriumSidebar />
          {(dirty || !crematorium.id) && (
            <ActionsPopup isNew={!crematorium.id} />
          )}
          {children}
        </form>
      )}
    </Form>
  );
}
