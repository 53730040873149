import { isEmpty, isNil, omitBy, pick } from 'lodash';
import {
  DefunctFatherLayer,
  DefunctLayer,
  DefunctMotherLayer,
  DefunctPartnerLayer,
  EmpoweredClientLayer,
  Layer,
  LayerType,
} from 'models/Deal/Layer';

interface EmpoweredClientLayerValue {
  link?: string | null;
  client?: Partial<EmpoweredClientLayer['value']['client']>;
}

type LayerValue = Layer['value'] & EmpoweredClientLayerValue;
interface LayerPayload {
  layer: {
    action: LayerType;
    value: LayerValue;
  };
}

const clientFields = [
  'civility',
  'firstname',
  'lastname',
  'birth_name',
  'email',
  'phone',
  'phone_2',
  'address',
];
function serializeEmpoweredClient(layer: Layer): LayerValue {
  const { value } = layer as EmpoweredClientLayer;
  const client = omitBy(pick(value.client, ...clientFields), isNil);
  return {
    link: value.link,
    client: isEmpty(client) ? undefined : client,
  };
}

const defunctFields = [
  'civility',
  'firstname',
  'lastname',
  'birth_name',
  'address',
  'nationality',
  'birth_date',
  'birth_place',
  'work',
  'weight',
  'size',
  'pacemaker',
];
function serializeDefunct(layer: Layer): LayerValue {
  const { value } = layer as DefunctLayer;
  return pick(value, ...defunctFields);
}

const defunctFatherFields = [
  'firstname',
  'lastname',
  'birth_name',
  'address',
  'work',
];
function serializeDefunctFather(layer: Layer): LayerValue {
  const { value } = layer as DefunctFatherLayer;
  return pick(value, ...defunctFatherFields);
}

const defunctMotherFields = [
  'firstname',
  'lastname',
  'birth_name',
  'address',
  'work',
];
function serializeDefunctMother(layer: Layer): LayerValue {
  const { value } = layer as DefunctMotherLayer;
  return pick(value, ...defunctMotherFields);
}

const defunctPartnerFields = [
  'civility',
  'firstname',
  'lastname',
  'birth_name',
  'address',
  'work',
];
function serializeDefunctPartner(layer: Layer): LayerValue {
  const { value } = layer as DefunctPartnerLayer;
  return pick(value, ...defunctPartnerFields);
}

const serializers: Record<LayerType, (layer: Layer) => LayerValue> = {
  [LayerType.EMPOWERED_CLIENT]: serializeEmpoweredClient,
  [LayerType.DEFUNCT]: serializeDefunct,
  [LayerType.DEFUNCT_FATHER]: serializeDefunctFather,
  [LayerType.DEFUNCT_MOTHER]: serializeDefunctMother,
  [LayerType.DEFUNCT_PARTNER]: serializeDefunctPartner,
};

export function serialize(layer: Layer): LayerPayload {
  return {
    layer: {
      action: layer.action,
      value: omitBy(serializers[layer.action](layer), isNil),
    },
  };
}
