import { ContactMedia } from '@advitam/api/models/Entity/ContactMedia';
import type { WorshipTypeJSON } from '@advitam/api/models/WorshipType';
import type { NewWorship } from './types';

export function worshipSkeleton(worshipType: WorshipTypeJSON): NewWorship {
  return {
    name: '',
    address: '',
    postal_code: '',
    city: '',
    phone: '',
    phone_2: null,
    email: '',
    fax: '',
    prefered_contact_media: ContactMedia.EMAIL,
    price: null,
    comment: '',
    worship_type: worshipType,
  };
}
