import { Country } from '../Country'
import { Model } from '../Model'
import { Civility } from '../Person/Civility'
import { ClientCompanyJSON } from './Company'

export interface FullClientBase {
  id: number
  civility: Civility | null
  firstname: string | null
  lastname: string | null
  birth_name: string | null
  birth_date: string | null
  birth_location: string | null
  nationality: string | null
  work: string | null
  deceased: boolean
  death_date: string | null
  phone: string | null
  phone_2: string | null
  phone_3: string | null
  email: string | null
  address: string | null
  address_l2: string | null
  postal_code: string | null
  insee_code: string | null
  city: string | null
  country: Country | null
  defunct_id: number | null
  clients_companies: ClientCompanyJSON[]
}

export interface FullClientJSON extends FullClientBase {
  cgs_accepted_at: string | null
  current_sign_in_at: string | null
  opt_out: string | null
  inserted_at: string
}

export interface FullClient extends FullClientBase {
  cgs_accepted_at: Date | null
  current_sign_in_at: Date | null
  opt_out: Date | null
  inserted_at: Date
}

export class FullClient extends Model<FullClientJSON> {
  constructor(data: FullClientJSON) {
    super(data)
    this.cgs_accepted_at = Model.parseDate(data.cgs_accepted_at)
    this.current_sign_in_at = Model.parseDate(data.current_sign_in_at)
    this.opt_out = Model.parseDate(data.opt_out)
    this.inserted_at = Model.parseDate(data.inserted_at)
  }
}
