const defaultTheme: Record<string, string> = {
  main_bg: '#ffffff',
  main_fg: '#0f4447',
  primary_button_bg: '#ffaa78',
  primary_button_fg: '#ffffff',
  secondary_button_bg: '#b7b7b7',
  secondary_button_fg: '#ffffff',
  validated_action_bg: '#93c47d',
  validated_action_fg: '#ffffff',
  navbar_bg: '#ffffff',
  navbar_fg: '#0f4447',
  sidebar_bg: '#fff7ef',
  sidebar_fg: '#0f4447',
  sidebar_current_bg: '#ffefdf',
  sidebar_current_fg: '#0f4447',
  tile_bg: '#ffffff',
  tile_fg: '#0f4447',
  tile_subcontent_bg: '#f3f3f3',
  tile_subcontent_fg: '#0f4447',
  tile_subcontent_alt_bg: '#d9d9d9',
  tile_subcontent_alt_fg: '#0f4447',
  active_step_bg: '#ffffff',
  active_step_fg: '#ffaa78',
  validated_step_bg: '#0f4447',
  validated_step_fg: '#ffffff',

  darker_navbar_bg: '#fff7ef',
  darker_navbar_fg: '#0f4447',
  footer_bg: '#ffffff',
  footer_fg: '#0f4447',
  v2_primary_button_bg: '#2c666e',
  v2_primary_button_fg: '#ffffff',
  v2_primary_button_hovered_bg: '#2e9cab',
  frame_fg: '#0f4447',
  frame_bg: '#ffffff',
  frame_border: '#fff7ef',
  darker_frame_fg: '#0f4447',
  darker_frame_bg: '#fff7ef',
  darker_frame_border: '#ffefdf',
  image_bg: '#fff7ef',
  backdrop_color: '#0f444799',
  separator_color: '#0f444726',
}

export default defaultTheme
