import { authenticate, formdataEncoded } from '../../../lib/decorators'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { get, post, put, del } from '../../../lib/methods'
import type { SupportingDocumentJSON } from '../../../models/SupportingDocuments'
import type { SupportingDocumentIndexJSON } from '../../../models/SupportingDocuments/SupportingDocumentIndex'

export class SupportingDocuments {
  @authenticate
  static index(defunctId: number): ApiRequestDescriptor<SupportingDocumentIndexJSON[]> {
    return get(`/api/v1/defuncts/${defunctId}/supporting_documents`)
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<SupportingDocumentJSON> {
    return get(`/api/v1/defuncts/supporting_documents/${id}`)
  }

  @authenticate
  @formdataEncoded
  static create(
    defunctId: number,
    document_type: number | string,
    file: Blob,
  ): ApiRequestDescriptor<SupportingDocumentJSON> {
    return post(`/api/v1/defuncts/${defunctId}/supporting_documents`, {
      file,
      supporting_document: { document_type },
    })
  }

  @authenticate
  @formdataEncoded
  static update(id: number, file: Blob): ApiRequestDescriptor<SupportingDocumentJSON> {
    return put(`/api/v1/defuncts/supporting_documents/${id}`, {
      file,
    })
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<undefined> {
    return del(`/api/v1/defuncts/supporting_documents/${id}`)
  }
}
