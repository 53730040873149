import { ReactNode, MouseEvent } from 'react'

export interface TransparentButtonProps {
  onClick: (event: MouseEvent<HTMLElement>) => void
  className?: string
  isDoubleClick?: boolean
  children?: ReactNode
}

export default function TransparentButton({
  onClick,
  className,
  isDoubleClick,
  children,
}: TransparentButtonProps): JSX.Element {
  return (
    <div
      className={className}
      tabIndex={0}
      role="button"
      onClick={isDoubleClick ? (): void => undefined : onClick}
      onDoubleClick={isDoubleClick ? onClick : (): void => undefined}
    >
      {children}
    </div>
  )
}
