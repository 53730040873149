import { useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { PageSpinner } from '@advitam/ui';

import { Layout } from 'containers/Crud';
import { makeSelectDeal } from 'containers/Deal/selectors.typed';

import CreationModal from './CreationModal';
import DeletionModal from './DeletionModal';
import Header from './Header';
import List from './List';
import SupplierBookingModal from './SupplierBookingModal';
import { makeSelectIsLoading } from './selectors';
import slice from './slice';

function Todolist(): JSX.Element | null {
  const isLoading = useSelector(makeSelectIsLoading());
  const deal = useSelector(makeSelectDeal());

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!deal?.id) return null;

  return (
    <>
      <Layout.Container>
        <Layout.ColumnGroup>
          <Layout.Column>
            <Header />
            <List />
          </Layout.Column>
        </Layout.ColumnGroup>
      </Layout.Container>

      <CreationModal />
      <DeletionModal />
      <SupplierBookingModal />
    </>
  );
}

export default withSlice(slice)(Todolist);
