/*
 *  BulletList component displaying a list of radio inputs with labels
 */

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TransparentButton } from 'components/Button';
import style from './Input.module.scss';

export default function BulletList({ inline, items, checked, onChange }) {
  return (
    <div className={`${style.bullet_list} ${inline ? style.inline : ''}`}>
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={style.bullet_list__item} key={index}>
          <TransparentButton
            onClick={() => onChange(item.value)}
            htmlFor={item.label.id}
          >
            <input
              type="radio"
              id={index}
              value={item.value}
              checked={checked === item.value}
              onChange={() => {}}
            />
            &nbsp;
            <FormattedMessage {...item.label} />
          </TransparentButton>
        </div>
      ))}
    </div>
  );
}

BulletList.propTypes = {
  items: PropTypes.array.isRequired,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
};
