import type { PDFPage } from 'pdf-lib';

import type { Input } from '../../models';
import type { BuildPDFContext } from './types';
import renderSVG from './renderSVG';

// Data extracted from images/check.svg to avoid parsing it
const Check = {
  width: 24,
  height: 24,
  path: `
    m6.0424907,13.290594 q 0.609375,0 0.921875,1 0.625,1.875 0.890625,1.875
    0.203125,0 0.421875,-0.3125 4.3906253,-7.0312499 8.1250003,-11.3749999
    0.96875,-1.125 3.078125,-1.125 0.5,0 0.671875,0.09375 0.171875,0.09375
    0.171875,0.234375 0,0.21875 -0.515625,0.859375 -6.03125,7.2499999
    -11.1875003,15.3124999 -0.359375,0.5625 -1.46875,0.5625 -1.125,0
    -1.328125,-0.09375 -0.53125,-0.234375 -1.25,-2.390625 -0.8125,-2.390625
    -0.8125,-3 0,-0.65625 1.09375,-1.265625 0.671875,-0.375 1.1875,-0.375 z
  `,
};

export default function renderCheck(
  input: Input,
  page: PDFPage,
  _context: BuildPDFContext,
): Promise<void> {
  if (!input.value) {
    return Promise.resolve();
  }
  renderSVG({ ...input, size: [20, 20] }, page, Check);
  return Promise.resolve();
}
