import { get } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { ProductJSON } from '../../models/Funnel/Marble/Product'
import { MinimalProductJSON } from '../../models/Funnel/Marble/Product/Minimal'

export interface ProductsIndexFilters {
  grave_type_eq?: string | null
  tags_overlaps?: string[] | null
  with_stele_eq?: boolean | null
  color_or_variants_color_eq_any?: string[] | null
  price_lteq?: number | null
}

export class Products {
  static index(
    page: number,
    per_page?: number,
    q?: ProductsIndexFilters,
  ): ApiRequestDescriptor<MinimalProductJSON[]> {
    return get('/api/v1/marble_funnel/products', {
      page,
      per_page,
      q,
    })
  }

  static show(id: number): ApiRequestDescriptor<ProductJSON> {
    return get(`/api/v1/marble_funnel/products/${id}`)
  }
}
