import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { StayJSON } from '@advitam/api/models/FuneralParlor';
import { ConfirmationModal, HardSpinner, PageSpinner } from '@advitam/ui';
import ErrorBanner from 'components/ErrorBanner';
import { Layout } from 'containers/Crud';

import type { FuneralParlorForm } from '../../types';
import { fetchStays } from '../thunk';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
} from '../selectors';
import messages from '../messages';
import Stay from './Stay';
import Filters from './Filters';

export default function Stays(): JSX.Element {
  const dispatch = useDispatch();
  const gridContainer = useRef<HTMLDivElement>(null);

  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());

  const [indexOfStayToDelete, setIndexOfStayToDelete] = useState<number | null>(
    null,
  );

  const form = useForm<FuneralParlorForm<StayJSON[]>>();
  const { values } = useFormState<FuneralParlorForm<StayJSON[]>>();
  const stays = values.sectionValues;

  const closeDeletionConfirmation = useCallback(() => {
    setIndexOfStayToDelete(null);
  }, [setIndexOfStayToDelete]);

  const onDeletionConfirmed = useCallback(() => {
    form.change(
      'sectionValues',
      stays.filter((_, index) => index !== indexOfStayToDelete),
    );
    closeDeletionConfirmation();
  }, [closeDeletionConfirmation, form, stays, indexOfStayToDelete]);

  useEffect(() => {
    dispatch(fetchStays());
  }, []);

  return (
    <>
      <Layout.ColumnGroup>
        <Layout.Column>
          <Filters gridContainer={gridContainer} />
        </Layout.Column>
      </Layout.ColumnGroup>
      {isSaving && <HardSpinner />}
      <Field name="sectionValues">
        {(): JSX.Element =>
          isLoading ? (
            <PageSpinner />
          ) : (
            <Layout.FieldsetGrid ref={gridContainer}>
              {stays.map((stay, index) => (
                <Stay
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}-${stay.id}`}
                  stay={stay}
                  prefix={`sectionValues[${index}]`}
                  onDelete={(): void => setIndexOfStayToDelete(index)}
                />
              ))}
            </Layout.FieldsetGrid>
          )
        }
      </Field>

      {indexOfStayToDelete !== null && (
        <ConfirmationModal
          isOpen
          text={
            <FormattedMessage
              id={messages.deletionConfirmationText.id}
              values={{
                name: stays[indexOfStayToDelete]?.name,
              }}
            />
          }
          confirm={
            <FormattedMessage id={messages.deletionConfirmationConfirm.id} />
          }
          onConfirm={onDeletionConfirmed}
          cancel={
            <FormattedMessage id={messages.deletionConfirmationCancel.id} />
          }
          onCancel={closeDeletionConfirmation}
        />
      )}

      {error && <ErrorBanner errors={error} />}
    </>
  );
}
