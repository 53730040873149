import {
  FETCH_DOCUMENTS,
  SET_DOCUMENTS,
  ERROR_DOCUMENT_LIST,
  DOWNLOAD_FILES,
  MERGE_FILES,
  SET_LOADING,
  FETCH_DOCUMENT_VERSIONS,
  FETCH_DOCUMENT_VERSIONS_SUCCESS,
  CLEAR_DOCUMENT_VERSIONS,
  TOGGLE_LOCKED_VERSION,
  VERSION_LOCK_FAILURE,
  SET_SELECTED_DOCUMENTS,
  TOGGLE_DOCUMENT_SELECTION,
  RESET_DOCUMENTS,
  REMOVE_DOCUMENTS,
} from './constants';
import { fetchDocument } from './thunk.ts';
import messages from './messages';

export const initialState = {
  documents: null,
  selectedDocumentIds: [],
  loading: false,
  error: null,
  versions: null,
  areVersionsLoading: false,
};

function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS:
    case DOWNLOAD_FILES:
    case MERGE_FILES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.documents.sort(
          (a, b) => a.document_type.id - b.document_type.id,
        ),
        loading: false,
      };
    case ERROR_DOCUMENT_LIST:
      return {
        ...state,
        error: action.error,
        loading: false,
        isTemplateLoading: false,
        areVersionsLoading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case SET_SELECTED_DOCUMENTS:
      return {
        ...state,
        selectedDocumentIds: [...action.documentIds],
      };

    case TOGGLE_DOCUMENT_SELECTION: {
      const currentSelection = state.selectedDocumentIds;
      const isAlreadySelected = currentSelection.includes(action.doc.id);
      if (!isAlreadySelected) {
        return {
          ...state,
          selectedDocumentIds: [...currentSelection, action.doc.id],
        };
      }
      return {
        ...state,
        selectedDocumentIds: [
          ...currentSelection.filter(
            selectedId => selectedId !== action.doc.id,
          ),
        ],
      };
    }

    case FETCH_DOCUMENT_VERSIONS:
      return {
        ...state,
        areVersionsLoading: true,
        versions: null,
      };

    case FETCH_DOCUMENT_VERSIONS_SUCCESS:
      return {
        ...state,
        areVersionsLoading: false,
        versions: action.versions,
      };

    case CLEAR_DOCUMENT_VERSIONS:
      return {
        ...state,
        areVersionsLoading: false,
        versions: null,
      };

    case TOGGLE_LOCKED_VERSION: {
      const documents = [...state.documents];
      const index = documents.findIndex(doc => doc.id === action.documentId);
      const version = action.lockedVersion || documents[index].version;

      documents[index] = {
        ...documents[index],
        locked: action.lockedVersion !== null,
        version: {
          ...version,
        },
      };
      return {
        ...state,
        documents,
      };
    }

    case VERSION_LOCK_FAILURE: {
      const documents = [...state.documents];
      const index = documents.findIndex(doc => doc.id === action.documentId);
      const message = documents[index].locked
        ? messages.versionLockFailed
        : messages.versionUnlockFailed;

      documents[index] = {
        ...documents[index],
        ...action.previousState,
      };
      return {
        ...state,
        documents,
        error: {
          status: 500,
          message: {
            ...message,
            values: { documentName: documents[index].name },
          },
        },
      };
    }

    case REMOVE_DOCUMENTS: {
      return {
        ...state,
        documents: state.documents.filter(
          doc => !action.documentIds.includes(doc.id),
        ),
      };
    }

    case fetchDocument.fulfilled.type: {
      const documents = [...state.documents];
      const index = documents.findIndex(doc => doc.id === action.payload.id);

      if (index !== -1) {
        documents[index] = action.payload;
      } else {
        documents.push(action.payload);
      }

      return {
        ...state,
        documents,
      };
    }

    case RESET_DOCUMENTS:
      return initialState;

    default:
      return state;
  }
}

export default documentsReducer;
