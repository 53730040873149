import { useCallback, ReactNode } from 'react'

import { LinkButton } from '../../../../Button'
import { useSetIsMenuOpen } from '../../hooks'
import menuStyle from '../../Menu.module.scss'
import style from './Link.module.scss'

interface MenuLinkButtonProps {
  text: ReactNode
  href: string
  internal?: boolean
}

export default function MenuLinkButton({ text, href, internal }: MenuLinkButtonProps): JSX.Element {
  const setIsMenuOpen = useSetIsMenuOpen()

  const closeMenu = useCallback((): void => setIsMenuOpen(false), [setIsMenuOpen])

  return (
    <LinkButton
      primary
      fixedSize
      text={text}
      internal={internal}
      href={href}
      className={[menuStyle.link_button, style.link_button].join(' ')}
      onClick={closeMenu}
    />
  )
}
