import { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { FlatButton, Input, Text } from '@advitam/ui';
import CrossIcon from '@advitam/ui/images/icons/times.svg';
import PenIcon from '@advitam/ui/images/icons/pen-solid.svg';

import style from './Title.module.scss';

interface TitleProps {
  fieldName: string;
  isReadonly?: boolean;
  isCreation?: boolean;
  isLoading?: boolean;
  placeholder?: string;
}

export default function Title({
  fieldName,
  isReadonly = false,
  isCreation = false,
  isLoading = false,
  placeholder,
}: TitleProps): JSX.Element {
  const { values, pristine } = useFormState();
  const [isEditing, setIsEditing] = useState(false);
  const isInputMode = !isReadonly && (isCreation || isEditing || isLoading);

  const titleValue = get(values, fieldName);
  const pageTitle = titleValue ? `${titleValue} - Advitam` : 'Advitam';

  const onCancel = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  useEffect(() => {
    if (pristine) {
      setIsEditing(false);
    }
  }, [pristine]);

  if (isInputMode) {
    return (
      <div className={style.container}>
        <Helmet title={pageTitle} />
        <Input
          required
          disabled={isLoading}
          name={fieldName}
          placeholder={placeholder}
        />
        {!isCreation && (
          <FlatButton className={style.icon} onClick={onCancel}>
            <CrossIcon />
          </FlatButton>
        )}
      </div>
    );
  }

  return (
    <header className={style.container}>
      <Helmet title={pageTitle} />
      <h2>
        <Text>
          <b>{titleValue}</b>
        </Text>
      </h2>
      {!isReadonly && (
        <FlatButton className={style.icon} onClick={onEdit}>
          <PenIcon />
        </FlatButton>
      )}
    </header>
  );
}
