import { cloneDeep, get } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';
import type { EntityInvoicePrestationJSON } from '@advitam/api/models/EntityInvoice/Prestation';
import { EntityInvoicePrestationStatus } from '@advitam/api/models/EntityInvoice/Prestation/Status';
import { assert } from '@advitam/support';

import { reset } from '../../slice';
import { fetchEntityInvoices } from '../../thunk';
import { BILLING_EDIT_MODAL } from './constants';
import { PrestationForm } from './types';
import { AppStateSubset } from './slice';
import { makeSelectInvoice, makeSelectPrestation } from './selectors';

const GROUP_KEYS = [
  'entity.id',
  'entity.type',
  'group.id',
  'group.type',
  'group.year',
];

function removePrestation(
  invoice: EntityInvoiceJSON,
  prestationId: number,
): void {
  // eslint-disable-next-line no-param-reassign
  invoice.prestations = invoice.prestations.filter(
    prestation => prestation.id !== prestationId,
  );
}

export const savePrestation = createAsyncThunk(
  `${BILLING_EDIT_MODAL}/SAVE_PRESTATION`,
  async (values: PrestationForm, { dispatch, getState, rejectWithValue }) => {
    assert(
      values.invoice.id !== undefined && values.prestation.id !== undefined,
    );

    const state = getState() as AppStateSubset;
    const invoice = makeSelectInvoice()(state);
    assert(invoice !== null);
    let newInvoice = cloneDeep(invoice);

    const groupChanged = GROUP_KEYS.some(
      key => get(invoice, key) !== get(values.invoice, key),
    );
    if (groupChanged) {
      try {
        const { body } = await request(
          Api.V1.EntityInvoices.Prestations.update(
            values.prestation.id,
            values.invoice,
          ),
        );
        newInvoice = body;
      } catch (error) {
        return rejectWithValue(error);
      }
    }

    if (
      !values.billedByBob &&
      values.prestation.status !== EntityInvoicePrestationStatus.UNSET
    ) {
      try {
        await request(
          Api.V1.EntityInvoices.Prestations.delete(values.prestation.id),
        );
      } catch (error) {
        return rejectWithValue(error);
      }
      removePrestation(newInvoice, values.prestation.id);
    } else if (
      !values.billedByEntity &&
      values.prestation.status !== EntityInvoicePrestationStatus.NOT_BILLED
    ) {
      removePrestation(newInvoice, values.prestation.id);
    } else {
      const index = newInvoice.prestations.findIndex(
        prestation => prestation.id === values.prestation.id,
      );
      newInvoice.prestations[index] = values.prestation;
    }

    const isUnique = newInvoice.prestations.length === 1;
    newInvoice.bank_transfer_label = values.invoice.bank_transfer_label;
    newInvoice.cost_tax_excl = isUnique ? null : values.invoice.cost_tax_excl;
    newInvoice.cost_tax_incl = isUnique ? null : values.invoice.cost_tax_incl;

    try {
      await request(Api.V1.EntityInvoices.update(newInvoice));
    } catch (error) {
      return rejectWithValue(error);
    }

    if (groupChanged) {
      dispatch(reset());
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(fetchEntityInvoices(1));
      return undefined;
    }

    return newInvoice;
  },
);

export const saveGroup = createAsyncThunk(
  `${BILLING_EDIT_MODAL}/SAVE_GROUP`,
  async ({ invoice }: PrestationForm, { dispatch, rejectWithValue }) => {
    assert(invoice.id !== undefined);

    let newInvoice = cloneDeep(invoice);

    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const prestation of invoice.prestations) {
        assert(prestation.id !== undefined);
        // eslint-disable-next-line no-await-in-loop
        const { body } = await request(
          Api.V1.EntityInvoices.Prestations.update(prestation.id, invoice),
        );
        newInvoice = body;
      }

      newInvoice.bank_transfer_label = invoice.bank_transfer_label;
      newInvoice.cost_tax_excl = invoice.cost_tax_excl;
      newInvoice.cost_tax_incl = invoice.cost_tax_incl;
      await request(Api.V1.EntityInvoices.update(newInvoice));

      dispatch(reset());
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(fetchEntityInvoices(1));

      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveNewPrestation = createAsyncThunk(
  `${BILLING_EDIT_MODAL}/SAVE_NEW_PRESTATION`,
  async ({ invoice, prestation }: PrestationForm, { rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.EntityInvoices.Prestations.create(
          invoice as EntityInvoiceJSON,
          prestation as EntityInvoicePrestationJSON,
        ),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const save = createAsyncThunk(
  `${BILLING_EDIT_MODAL}/SAVE`,
  async (values: PrestationForm, { getState, dispatch }) => {
    const state = getState() as AppStateSubset;
    const invoice = makeSelectInvoice()(state);
    const prestation = makeSelectPrestation()(state);

    if (prestation) {
      return dispatch(savePrestation(values));
    }
    if (invoice) {
      return dispatch(saveGroup(values));
    }
    return dispatch(saveNewPrestation(values));
  },
);
