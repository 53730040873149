const SHARE_URL = 'https://www.linkedin.com/shareArticle'

function buildShareLink(url: string, mini = true): string {
  const params = [`url=${url}`]
  if (mini) {
    params.push('mini=true')
  }
  return `${SHARE_URL}?${params.join('&')}`
}

export const Linkedin = {
  buildShareLink,
}
