import { useCallback } from 'react';
import {
  FormSpy,
  FormSpyRenderProps,
  useForm,
  useFormState,
} from 'react-final-form';

import { Model } from '@advitam/api/models/Model';
import { ClientRole } from '@advitam/api/models/Client/Role';
import { usePreviousValue } from '@advitam/react';

import { FuneralForm } from '../../../Funeral/types';

export default function SpecialValuesSpy(): JSX.Element {
  const form = useForm();
  const { values } = useFormState<FuneralForm>();
  const { abilities } = values;

  const previousSpecialIndex = usePreviousValue(
    abilities.findIndex(ability => ability.special),
  );
  const previousOwnerIndex = usePreviousValue(
    abilities.findIndex(ability => ability.role === ClientRole.OWNER),
  );

  const onValueChange = useCallback(
    ({
      values: changedValues,
      dirty,
    }: FormSpyRenderProps<FuneralForm>): null => {
      if (!dirty) {
        // Do not run on form reset
        return null;
      }

      const nextSpecialIndex = changedValues.abilities.findIndex(
        (ability, idx) =>
          ability.special &&
          !Model.isDestroyed(ability) &&
          idx !== previousSpecialIndex,
      );
      if (
        previousSpecialIndex !== undefined &&
        nextSpecialIndex !== -1 &&
        nextSpecialIndex !== previousSpecialIndex
      ) {
        form.change(`abilities[${previousSpecialIndex}].special`, false);
      }

      const nextOwnerIndex = changedValues.abilities.findIndex(
        (ability, idx) =>
          ability.role === ClientRole.OWNER &&
          !Model.isDestroyed(ability) &&
          idx !== previousOwnerIndex,
      );
      if (
        previousOwnerIndex !== undefined &&
        nextOwnerIndex !== -1 &&
        nextOwnerIndex !== previousOwnerIndex
      ) {
        form.change(`abilities[${previousOwnerIndex}].role`, ClientRole.EDITOR);
      }

      return null;
    },
    [previousSpecialIndex, previousOwnerIndex, form],
  );

  return (
    <FormSpy subscription={{ values: true, dirty: true }}>
      {onValueChange}
    </FormSpy>
  );
}
