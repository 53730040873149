import { defineMessages } from 'react-intl';

export default defineMessages({
  on: {
    id: 'app.components.FormattedDate.on',
  },
  at: {
    id: 'app.components.FormattedDate.at',
  },
});
