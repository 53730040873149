import { DateTime } from 'luxon'

import type { OpeningEvent, OpeningHours, OpeningHourRange } from './types'

const HOUR_FORMAT = 'HH:mm'

export const YEAR = 2018
export const MONTH = 0
export const DAY = 22

export function buildEvents(hours: OpeningHours): OpeningEvent[] {
  return hours
    .map((dayRanges, dayIndex) =>
      dayRanges.map((range, rangeIndex) => {
        const start = DateTime.fromFormat(range[0], HOUR_FORMAT)
        const end = DateTime.fromFormat(range[1], HOUR_FORMAT)
        const day = DAY + dayIndex

        return {
          start: new Date(YEAR, MONTH, day, start.hour, start.minute, 0),
          end: new Date(YEAR, MONTH, day, end.hour, end.minute, 0),
          dayIndex,
          rangeIndex,
        }
      }),
    )
    .flat()
}

export function updateEvent(
  hours: OpeningHours,
  event: OpeningEvent,
  start: Date,
  end: Date,
): OpeningHours {
  const duplicate = [...hours] as OpeningHours
  duplicate[event.dayIndex] = [...duplicate[event.dayIndex]]

  const startValue = DateTime.fromJSDate(start).toFormat(HOUR_FORMAT)
  const endValue = DateTime.fromJSDate(end).toFormat(HOUR_FORMAT)
  duplicate[event.dayIndex][event.rangeIndex] = [startValue, endValue]

  return duplicate
}

export function addEvent(hours: OpeningHours, start: Date, end: Date): OpeningHours {
  const duplicate = [...hours] as OpeningHours
  const day = start.getDay() - 1
  const dayIndex = day === -1 ? 6 : day
  const startValue = DateTime.fromJSDate(start).toFormat(HOUR_FORMAT)
  const endValue = DateTime.fromJSDate(end).toFormat(HOUR_FORMAT)

  duplicate[dayIndex] = [...duplicate[dayIndex], [startValue, endValue] as OpeningHourRange]

  return duplicate
}

export function removeEvent(hours: OpeningHours, event: OpeningEvent): OpeningHours {
  const duplicate = [...hours] as OpeningHours

  duplicate[event.dayIndex] = duplicate[event.dayIndex].filter((_, idx) => idx !== event.rangeIndex)

  return duplicate
}
