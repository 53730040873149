import type { ReactNode } from 'react'

import style from './Layout.module.scss'

interface RowProps {
  children: ReactNode
  className?: string
}

export function Row({ children, className = '' }: RowProps): JSX.Element {
  return <div className={[style.row, className].join(' ')}>{children}</div>
}
