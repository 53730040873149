import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { SupplierMinimalWarehouseJSON } from '@advitam/api/models/Supplier/Warehouse';
import { ConfirmationModal, ErrorText, FormattedApiError } from '@advitam/ui';
import { useThunkDispatch } from '@advitam/react';

import actionsMessages from 'messages/actions';

import { makeSelectDestroyError, makeSelectIsDeleting } from './selectors';
import { destroyWarehouse } from './thunk';
import messages from './messages';

interface DestroyModalProps {
  warehouseToDelete: SupplierMinimalWarehouseJSON;
  onClose: () => void;
}

export default function DestroyModal({
  warehouseToDelete,
  onClose,
}: DestroyModalProps): JSX.Element {
  const dispatch = useThunkDispatch();
  const isDeleting = useSelector(makeSelectIsDeleting());
  const destroyError = useSelector(makeSelectDestroyError());

  const onDestroyConfirmation = useCallback(async () => {
    try {
      await dispatch(destroyWarehouse(warehouseToDelete.id));
      onClose();
    } catch {
      // let the slice handle
    }
  }, [dispatch, destroyWarehouse, warehouseToDelete]);

  return (
    <ConfirmationModal
      isOpen
      title={<FormattedMessage id={messages.deletionConfirmationTitle.id} />}
      text={
        <>
          <FormattedMessage
            id={messages.deletionConfirmationText.id}
            values={{ name: <b>{warehouseToDelete.name}</b> }}
          />
          {destroyError && (
            <ErrorText>
              <FormattedApiError error={destroyError} />
            </ErrorText>
          )}
        </>
      }
      confirm={<FormattedMessage id={actionsMessages.confirm.id} />}
      onConfirm={onDestroyConfirmation}
      isConfirmationLoading={isDeleting}
      cancel={<FormattedMessage id={actionsMessages.cancel.id} />}
      onCancel={onClose}
    />
  );
}
