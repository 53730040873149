import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';

import EditIcon from './Icon';
import { editGroup } from './slice';

interface EditGroupIconProps {
  invoice: EntityInvoiceJSON;
}

export default function EditGroupIcon({
  invoice,
}: EditGroupIconProps): JSX.Element {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(editGroup(invoice));
  }, [dispatch, invoice]);

  return <EditIcon onClick={onClick} />;
}
