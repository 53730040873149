import PropTypes from 'prop-types';
import { SelectList } from 'components/Input';

const SelectSolution = ({
  groupName,
  solutions,
  checkDependenciesAndUdpate,
}) => {
  const options = solutions.map((solution, index) => ({
    value: index,
    name: `${solution.suppliers.join('/')} - ${solution.price} € ${
      Object.keys(solution.dependencies).length > 0 ? ' - ¶' : ''
    }`,
  }));
  // value name
  const selectedOption = solutions.findIndex(solution => solution.selected);
  return (
    <SelectList
      name="solution"
      onChange={({ target: { value } }) =>
        value && checkDependenciesAndUdpate(groupName, solutions[value])
      }
      value={selectedOption}
      options={options}
    />
  );
};

SelectSolution.propTypes = {
  solutions: PropTypes.array.isRequired,
  checkDependenciesAndUdpate: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
};

export default SelectSolution;
