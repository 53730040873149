import { get } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { ProductJSON } from '../../models/Funnel/Marble/Product'
import { ProductType } from '../../models/Product'

export interface SupplierWarehouseProductsFilters {
  product_type_eq?: ProductType
  supplier_warehouse_id?: number
}

export class SupplierWarehousesProducts {
  static index(
    page: number,
    per_page?: number,
    q?: SupplierWarehouseProductsFilters,
  ): ApiRequestDescriptor<ProductJSON[]> {
    return get('/api/v1/marble_funnel/supplier_warehouses_products', {
      page,
      per_page,
      q,
    })
  }
}
