import { Model } from '../../../../Model'
import { ActionType } from '../Type'
import { DocumentBatchDealDocumentIdJSON } from './DealDocumentId'
import { DocumentBatchRecipientJSON, DocumentBatchRecipientState } from './Recipient'

const STATUS_ORDER = {
  [DocumentBatchRecipientState.SENT]: 1,
  [DocumentBatchRecipientState.RECEIVED]: 2,
  [DocumentBatchRecipientState.DISPATCHED]: 3,
  [DocumentBatchRecipientState.NOTHING]: 4,
  [DocumentBatchRecipientState.PENDING]: 5,
  [DocumentBatchRecipientState.FAILED]: 6,
}

export interface DocumentBatchTodoActionJSON {
  id: number
  type: ActionType.DEAL_DOCUMENT_BATCH
  recipients: DocumentBatchRecipientJSON[]
  deal_document_ids: DocumentBatchDealDocumentIdJSON[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DocumentBatchTodoAction extends DocumentBatchTodoActionJSON {}

export class DocumentBatchTodoAction extends Model<DocumentBatchTodoActionJSON> {
  private get recipientsStatus(): DocumentBatchRecipientState {
    let status: DocumentBatchRecipientState = DocumentBatchRecipientState.SENT

    this.recipients.forEach(recipient => {
      if (STATUS_ORDER[recipient.state] > STATUS_ORDER[status]) {
        status = recipient.state
      }
    })

    return status
  }

  get status(): DocumentBatchRecipientState {
    if (this.recipients.length === 0) {
      return DocumentBatchRecipientState.FAILED
    }

    return this.recipientsStatus
  }

  hasDealDocument(id: number): boolean {
    return this.deal_document_ids.findIndex(docId => docId.id === id) !== -1
  }

  isDealDocumentOptional(id: number): boolean {
    const doc = this.deal_document_ids.find(docId => docId.id === id)

    if (!doc) {
      return true
    }

    return doc.optional
  }
}

export type { DocumentBatchDealDocumentIdJSON, DocumentBatchRecipientJSON }

export { DocumentBatchRecipientState }
