import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { unionWith } from 'lodash';

import { Button, FormLayout, Input } from '@advitam/ui';
import { CoverageJSON } from '@advitam/api/models/Entity/Coverage';
import { Layout } from 'containers/Crud';
import actionsMessages from 'messages/actions';

import { makeSelectCanEdit } from '../../../../selectors';
import { UnsavedCoverage, WarehouseZonesForm } from '../types';
import messages from '../messages';
import AutoAddition from './AutoAddition';
import ManualAddition from './ManualAddition';
import CoveredZone from './CoveredZone';
import style from './Zone.module.scss';

interface ZoneProps {
  zoneIndex: number;
  onZoneDuplication: () => void;
  onZoneDeletion: () => void;
}

export default function Zone({
  zoneIndex,
  onZoneDuplication,
  onZoneDeletion,
}: ZoneProps): JSX.Element {
  const form = useForm();
  const { values } = useFormState<WarehouseZonesForm>();
  const zoneValues = values.sectionValues[zoneIndex];
  const canUserEdit = useSelector(makeSelectCanEdit());

  const zonePrefix = `sectionValues[${zoneIndex}].zone`;
  const coveragePrefix = `sectionValues[${zoneIndex}].coverage`;

  const addCoverageItems = useCallback(
    (items: Array<CoverageJSON | UnsavedCoverage>): void => {
      const newCoverage = unionWith(
        items,
        zoneValues.coverage,
        (a, b) => a.zone_id === b.zone_id && a.zone_type === b.zone_type,
      );
      form.change(coveragePrefix, newCoverage);
    },
    [coveragePrefix, form, zoneValues.coverage],
  );

  return (
    <Layout.Fieldset title={zoneValues.zone.name}>
      <FormLayout.Row>
        <Input
          required
          disabled={!canUserEdit}
          name={`${zonePrefix}.name`}
          label={<FormattedMessage id={messages.title.id} />}
        />
      </FormLayout.Row>

      <Field name={coveragePrefix}>
        {(): JSX.Element => (
          <>
            <AutoAddition
              zoneIndex={zoneIndex}
              addCoverageItems={addCoverageItems}
            />
            <ManualAddition
              zoneIndex={zoneIndex}
              addCoverageItems={addCoverageItems}
            />
            <CoveredZone zoneIndex={zoneIndex} />
          </>
        )}
      </Field>

      <FormLayout.Row className={style.actions_row}>
        <Button
          outline
          disabled={!canUserEdit}
          onClick={onZoneDuplication}
          text={<FormattedMessage id={actionsMessages.duplicate.id} />}
        />
        <Button
          outline
          disabled={!canUserEdit}
          onClick={onZoneDeletion}
          text={<FormattedMessage id={actionsMessages.delete.id} />}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
