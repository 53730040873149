import { defineMessages } from 'react-intl';

export default defineMessages({
  costWithoutVAT: { id: 'app.components.PrestationForm.costWithoutVAT' },
  priceWithVAT: { id: 'app.components.PrestationForm.priceWithVAT' },
  amount: { id: 'app.components.PrestationForm.amount' },
  amountShouldBePositive: {
    id: 'app.components.PrestationForm.amountShouldBePositive',
  },
});
