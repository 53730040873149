import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-places-autocomplete';

import { SKELETON_ADDRESS } from 'components/CRUD/constants';

/**
 * Class for google autocompletion.
 *
 * @class      GoogleAutocomplete
 * @property   {function}  onChange      Receives an update object to the
 *                                       address part of a person (the entire
 *                                       address, not just the address.adrress
 *                                       part)
 * @property   {string}    initialValue  The initial address value
 */
export class GoogleAutocomplete extends PureComponent {
  onError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  onChange = value => {
    const { onChange } = this.props;
    onChange({ address: value });
  };

  /**
   * select item
   *
   * @param {String}  value   value of item selected
   * @param {String}  placeId id of place
   */
  onSelect = (value, placeId) => {
    const { onSelect } = this.props;
    geocodeByPlaceId(placeId)
      .then(response => this.formatGeocodingResponse(response))
      .then(onSelect);
  };

  formatGeocodingResponse = response => {
    const address = { ...SKELETON_ADDRESS };
    const {
      address_components: addressComponents,
      geometry: { location },
    } = response[0];
    addressComponents.forEach(component => {
      if (component.types[0] === 'locality') {
        address.city = component.long_name;
      } else if (component.types[0] === 'postal_code') {
        address.postal_code = component.long_name;
      } else if (component.types[0] === 'country') {
        address.country = component.long_name;
        address.country_code = component.short_name;
      } else if (component.types[0] === 'colloquial_area') {
        address.address = component.long_name;
      } else if (component.types[0] === 'street_number') {
        address.address = component.long_name;
      } else if (component.types[0] === 'route' && !address.address) {
        address.address = component.long_name;
      } else if (component.types[0] === 'route' && address.address) {
        address.address += ` ${component.long_name}`;
      }
    });
    address.latitude = location.lat();
    address.longitude = location.lng();
    return address;
  };

  render() {
    const { classNameInputToAdd, placeholder, value } = this.props;
    const inputProps = { className: 'places-autocomplete__input' };
    if (placeholder) inputProps.placeholder = placeholder;

    return (
      <PlacesAutocomplete
        value={value}
        onChange={this.onChange}
        onSelect={this.onSelect}
        onError={this.onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={`places-autocomplete ${classNameInputToAdd || ''}`}>
            <input {...getInputProps(inputProps)} />
            <div className="places-autocomplete__container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = `places-autocomplete__container__item${
                  suggestion.active ? '--active' : ''
                }`;
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

GoogleAutocomplete.propTypes = {
  /** function triggered onChange */
  onChange: PropTypes.func.isRequired,
  /** function triggered when selecting an option */
  onSelect: PropTypes.func.isRequired,
  /** value for input */
  value: PropTypes.string,
  /** input style */
  classNameInputToAdd: PropTypes.string,
  /** Optional placeholder, defaults to "Search..." */
  placeholder: PropTypes.string,
};

export default GoogleAutocomplete;
