import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import style from './Deal.module.scss';
import { getSectionHash } from './utils';

interface SectionProps {
  path: string;
  newDesign?: boolean;
  children: ReactNode;
}

export default function Section({
  path,
  newDesign,
  children,
}: SectionProps): JSX.Element {
  const location = useLocation();

  const sectionHash = getSectionHash(path);
  const isActive = location.hash === sectionHash || (!location.hash && !path);

  const classes = [
    style.section,
    'compact-design',
    !isActive && style.hidden,
    newDesign && 'new-design',
    newDesign && style.new_design,
  ].filter(Boolean);

  return (
    <section className={classes.join(' ')} data-section-path={path}>
      {children}
    </section>
  );
}
