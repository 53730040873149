import { useIntl } from 'react-intl';

import { isApiError, ErrorCodes, SerializedApiError } from 'api';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from './messages';

function isSerialized(
  error: Error | SerializedApiError,
): error is SerializedApiError {
  return (error as SerializedApiError).errorCodes !== undefined;
}

export function extractApiError(error: Error | SerializedApiError): string {
  if (isSerialized(error)) {
    return error.errorCodes[0] || ErrorCodes.UNHANDLED;
  }

  if (!isApiError(error) || error.body === null) {
    return ErrorCodes.UNHANDLED;
  }

  const body = (error.body as unknown) as { errors: string[] };
  if (!Array.isArray(body.errors)) {
    return ErrorCodes.UNHANDLED;
  }

  const [err] = body.errors;
  return err || ErrorCodes.UNHANDLED;
}

interface FormattedApiErrorProps {
  error: Error | SerializedApiError;
}

export function FormattedApiError({
  error,
}: FormattedApiErrorProps): JSX.Element {
  const intl = useIntl();
  const message = extractApiError(error);
  return <>{safeFormatMessage(intl, messages, message)}</>;
}
