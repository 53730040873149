import { ReactNode } from 'react'

import Desktop from './Desktop'
import Sticky from './Sticky'

interface ShoppingCartProps {
  title: ReactNode
  totals: ReactNode
  children: ReactNode | ReactNode[]
  totalPrice: ReactNode
  action?: ReactNode
  hideSticky?: boolean
  className?: string
  stickyOnXl?: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export default function ShoppingCart({
  title,
  totals,
  totalPrice,
  children,
  className = '',
  action,
  hideSticky,
  stickyOnXl = false,
  isOpen,
  setIsOpen,
}: ShoppingCartProps): JSX.Element {
  return (
    <>
      <Desktop
        title={title}
        totals={totals}
        className={className}
        action={action}
        hideOnXl={stickyOnXl}
      >
        {children}
      </Desktop>
      {!hideSticky && (
        <Sticky
          title={title}
          withSubtitle
          totals={totals}
          totalPrice={totalPrice}
          className={className}
          action={action}
          showOnXl={stickyOnXl}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        >
          {children}
        </Sticky>
      )}
    </>
  )
}
