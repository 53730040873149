import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { withDatasets } from 'slices/data';

import { makeSelectBatchesLogs } from './selectors';
import { fetchBatchesLogs } from './thunk';
import slice from './slice';
import BatchLog from './BatchLog';

function History(): JSX.Element {
  const dispatch = useDispatch();
  const batchesLogs = useSelector(makeSelectBatchesLogs());

  useEffect(() => {
    dispatch(fetchBatchesLogs());
  }, [dispatch]);

  return (
    <section id="history">
      {batchesLogs.map(batchesLog => (
        <BatchLog log={batchesLog} />
      ))}
    </section>
  );
}

export default compose<typeof History>(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(History);
