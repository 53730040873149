import { ReactNode, Ref, useCallback } from 'react'
import Tippy, { TippyProps } from '@tippyjs/react/headless'

import Arrow from './arrow-shape.svg'
import style from './Tooltip.module.scss'

interface TippyAttrs {
  'data-placement': TippyProps['placement']
  'data-reference-hidden'?: string | undefined
  'data-escaped'?: string | undefined
}

interface TooltipProps {
  content: ReactNode
  interactive?: boolean
  disabled?: boolean
  placement?: TippyProps['placement']
  visible?: boolean
  offsetX?: number
  offsetY?: number
  containerRef?: Ref<HTMLElement>
  className?: string
  contentClassName?: string
  children: ReactNode
}

export default function Tooltip({
  content,
  interactive,
  disabled,
  placement = 'auto',
  visible,
  offsetX = 0,
  offsetY = 10,
  containerRef,
  className,
  contentClassName,
  children,
}: TooltipProps): JSX.Element {
  const render = useCallback(
    (attrs: TippyAttrs): JSX.Element => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...attrs} className={[style.tooltip, className].filter(Boolean).join(' ')}>
        {content}
        <div data-popper-arrow className={style.arrow}>
          <Arrow />
        </div>
      </div>
    ),
    [className, content],
  )

  return (
    <Tippy
      ref={containerRef}
      render={render}
      interactive={interactive}
      disabled={disabled}
      placement={placement}
      visible={visible}
      offset={[offsetX, offsetY]}
    >
      <span className={contentClassName}>{children}</span>
    </Tippy>
  )
}
