import { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ContentModal } from '@advitam/ui';

import EditModalContent from './ModalContent';
import {
  makeSelectInitialFormValues,
  makeSelectIsModalOpen,
} from './selectors';
import style from './Edit.module.scss';
import { save } from './thunk';
import type { PrestationForm } from './types';

export default function EditModal(): JSX.Element {
  const dispatch = useDispatch();
  const isOpen = useSelector(makeSelectIsModalOpen());
  const initialValues = useSelector(makeSelectInitialFormValues());

  const onSubmit = useCallback(
    (values: PrestationForm) => {
      dispatch(save(values));
    },
    [dispatch],
  );

  return (
    <ContentModal isOpen={isOpen} className={`${style.modal} compact-design`}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        component={EditModalContent}
      />
    </ContentModal>
  );
}
