import { createAction } from 'lib/reactvitam/action_cable';
import type { DealJSON } from 'models/Deal';

export interface UpdatePayload {
  user_id: number;
  session_id: string;
  deal: DealJSON;
  error: string | null;
  date: string;
}

/**
 * The deal have been updated
 * Not for sending by the frontend, this is only the interface for reception of
 * backend messages.
 */
function updateDeal(
  user_id: number,
  session_id: string,
  deal: DealJSON,
  error: string | null,
  date: string,
): UpdatePayload {
  return { user_id, session_id, deal, error, date };
}

export default createAction(updateDeal, 'update_deal');
