import { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import { DocumentPageRotation } from '@advitam/api/models/DocumentEditions';
import RotateLeft from '@advitam/ui/images/icons/rotate-left.svg';
import RotateRight from '@advitam/ui/images/icons/rotate-right.svg';
import Trash from '@advitam/ui/images/icons/trash.svg';
import TrashFilled from '@advitam/ui/images/icons/trash-filled.svg';
import RetryableImage from 'components/RetryableImage';

import EditAction from '../../parts/EditAction';
import {
  makeSelectIsLoading,
  makeSelectIsSingleDocumentUpload,
} from '../../selectors';
import { DocumentEditionPage, RotateDirection } from '../../types';
import { rotatePage } from '../../utils';
import messages from '../messages';
import style from './Document.module.scss';

function getIsLandscape(
  defaultRotation: DocumentPageRotation,
  pageRotation: DocumentPageRotation,
): boolean {
  const finalRotation = defaultRotation + pageRotation;
  return finalRotation === 90 || finalRotation === 270;
}

interface DocumentPageProps {
  index: number;
  page: DocumentEditionPage;
  updatePage: (page: DocumentEditionPage) => void;
}

export default function DocumentPage({
  index,
  page,
  updatePage,
}: DocumentPageProps): JSX.Element {
  const isLoading = useSelector(makeSelectIsLoading());
  const isSingleUpload = useSelector(makeSelectIsSingleDocumentUpload());
  const [isDefaultLandscape, setIsDefaultLandscape] = useState(false);

  const defaultRotation: DocumentPageRotation = isDefaultLandscape ? 90 : 0;
  const isLandscape = getIsLandscape(defaultRotation, page.rotate || 0);

  const pageClasses = [
    style.page,
    isLandscape && style.landscape,
    !isSingleUpload && style.contained,
  ].filter(Boolean);

  const imageWrapperClasses = [
    style.image,
    isDefaultLandscape && style.landscape,
    page.isRemoved && style.removed,
    page.rotate && style[`rotate--${page.rotate}`],
  ].filter(Boolean);

  const onImageLoad = ({
    currentTarget: { naturalWidth, naturalHeight },
  }: SyntheticEvent<HTMLImageElement>): void => {
    setIsDefaultLandscape(naturalWidth > naturalHeight);
  };

  const onRotationUpdate = (direction: RotateDirection): void => {
    updatePage(rotatePage(page, direction));
  };

  const toggleIsRemoved = (): void => {
    updatePage({ ...page, isRemoved: !page.isRemoved });
  };

  return (
    <Draggable index={index} draggableId={page.uuid} isDragDisabled={isLoading}>
      {({ draggableProps, dragHandleProps, innerRef }): JSX.Element => (
        <div
          /* eslint-disable react/jsx-props-no-spreading */
          {...draggableProps}
          {...dragHandleProps}
          /* eslint-enable react/jsx-props-no-spreading */
          ref={innerRef}
          className={pageClasses.join(' ')}
        >
          <div className={imageWrapperClasses.join(' ')}>
            <RetryableImage
              alt=""
              src={page.miniature_link}
              onLoad={onImageLoad}
              crossOrigin="anonymous"
            />
          </div>
          <div className={style.actions}>
            <EditAction
              Icon={RotateLeft}
              message={messages.rotateLeft}
              onClick={(): void => onRotationUpdate(RotateDirection.LEFT)}
            />
            <EditAction
              Icon={RotateRight}
              message={messages.rotateRight}
              onClick={(): void => onRotationUpdate(RotateDirection.RIGHT)}
            />
            <EditAction
              Icon={page.isRemoved ? TrashFilled : Trash}
              message={page.isRemoved ? messages.recover : messages.delete}
              onClick={toggleIsRemoved}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
}
