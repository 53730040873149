import { ReactNode } from 'react'

import Text from '../Text'
import style from './Modal.module.scss'

interface TitleProps {
  hasCloseButton?: boolean
  children: ReactNode
}

export default function Title({ children, hasCloseButton = false }: TitleProps): JSX.Element {
  const className = [style.title, hasCloseButton && style.with_close_button]
    .filter(Boolean)
    .join(' ')

  return (
    <Text large tagName="div" className={className}>
      <b>{children}</b>
    </Text>
  )
}
