import { useDispatch, useSelector } from 'react-redux';

import { makeSelectCeremonyDT } from 'containers/Deal/selectors';
import DatePicker from 'components/DatePicker';

import { updateCeremonyDate } from '../../../slice';

export default function MarbleWork(): JSX.Element {
  const dispatch = useDispatch();
  const ceremonyDT = useSelector(makeSelectCeremonyDT());

  const onChange = (value: string): void => {
    dispatch(updateCeremonyDate(value));
  };

  return (
    <div className="todoList__defunct">
      <DatePicker
        noLabel
        fieldName="tribunalAuthorizationDate"
        date={ceremonyDT}
        onChange={onChange}
      />
    </div>
  );
}
