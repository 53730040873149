import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectUser } from 'slices/auth';
import { Layout } from 'containers/Crud';

import { saveSupplier } from '../../thunk';
import Wrapper from '../Wrapper';
import type { SupplierForm } from '../types';
import Dependencies from './Dependencies';
import PrestationTypes from './PrestationTypes';
import type { FormSectionValues } from './types';

export default function Misc(): JSX.Element {
  const dispatch = useDispatch();

  const user = useSelector(makeSelectUser());

  const onSubmit = useCallback(
    (values: SupplierForm<FormSectionValues>): void => {
      dispatch(saveSupplier(values.supplier));
    },
    [dispatch],
  );

  return (
    <Wrapper<FormSectionValues>
      onSubmit={onSubmit}
      initialSectionValues={{ newDependency: null }}
    >
      <Layout.Container>
        <Layout.ColumnGroup>
          <Layout.Column>
            <PrestationTypes />
          </Layout.Column>
          <Layout.Column>
            {user?.isFuneralDirectorOrAbove && <Dependencies />}
          </Layout.Column>
        </Layout.ColumnGroup>
      </Layout.Container>
    </Wrapper>
  );
}
