import type { IntlShape } from 'react-intl';

import type { ReminderNotificationJSON } from '@advitam/api/models/Notification';
import { Person } from '@advitam/ui';
import IconComponent from '@advitam/ui/images/icons/later.svg';
import { Path } from 'containers/App/constants';

import icon from './icons/reminder.standalone.png.bin';
import type { TileProps } from './TileProps';
import messages from './messages';

export default function getReminderProps(
  notification: ReminderNotificationJSON,
  intl: IntlShape,
): TileProps {
  const person = notification.comp_info.defunct || notification.comp_info.owner;

  return {
    link: Path.DEAL(notification.comp_info.deal_uuid),
    IconComponent,
    icon,
    title: intl.formatMessage(messages.reminderTitle),
    body: intl.formatMessage(messages.reminderText, {
      defunctName: person && Person.formatName(person, intl),
    }),
  };
}
