import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from 'lib/support';

import type { AppStateSubset as DealAppStateSubset } from '../../../slice';
import { makeSelectDeal } from '../../../selectors.typed';
import { TODO_LIST_CREATION_MODAL } from './constants';
import type { AppStateSubset as TodolistAppStateSubset } from './slice';

type AppStateSubset = DealAppStateSubset & TodolistAppStateSubset;

export const createItem = createAsyncThunk(
  `${TODO_LIST_CREATION_MODAL}/CREATE_ITEM`,
  async (title: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AppStateSubset;
      const deal = makeSelectDeal()(state);
      assert(deal?.uuid !== undefined);

      const { body } = await request(
        Api.V1.Deals.TodoItems.create(deal.uuid, title),
      );

      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
