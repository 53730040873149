import { ReactNode } from 'react'

// Keep a deep import to avoid bundling all form components with the footer
import Select from '../../../Form/UI/Select'
import Link from '../../../Link'
import Text from '../../../Text'
import { Lang, SocialLink } from '../../types'
import style from './AboutColumn.module.scss'

interface AboutColumnProps {
  description: ReactNode
  socialLinks: SocialLink[]
  lang?: Lang
}

export default function AboutColumn({
  description,
  socialLinks,
  lang,
}: AboutColumnProps): JSX.Element {
  return (
    <div className={`col-4 col-md-2 col-xl-3 ${style.column}`}>
      <Text small>{description}</Text>
      <div className={style.bottom}>
        <div className={style.social}>
          {socialLinks.map((socialLink, idx) => (
            <Link key={idx} newTab href={socialLink.href}>
              {socialLink.icon}
            </Link>
          ))}
        </div>
        {lang && (
          <Select
            expandOnTop
            className={style.lang_select}
            selectClassName={style.lang_dropdown}
            value={lang.value}
            items={lang.options}
            onChange={lang.callback}
          />
        )}
      </div>
    </div>
  )
}
