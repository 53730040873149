import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormApi } from 'final-form';

import Api, { request } from '@advitam/api';
import { AutocompleteDealEntitiesResult } from '@advitam/api/v1/Autocompletes';
import { RecipientType } from '@advitam/api/models/RecipientType';

import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import { AppStateSubset as DealAppStateSubset } from 'containers/Deal/slice';
import { assert } from 'lib/support';

import { SEND_BATCH_MODAL_RECIPIENTS } from './constants';
import { AppStateSubset as SendBatchAppStateSubset } from '../../slice';
import { makeSelectBatch } from '../../selectors';
import { SendBatchForm } from '../../types';
import { getRecipientSendingMethodKey } from '../../utils';
import { findExistingEntity } from './utils';

type AppStateSubset = DealAppStateSubset & SendBatchAppStateSubset;

interface CreationArgs {
  entity: AutocompleteDealEntitiesResult;
  formApi: FormApi<SendBatchForm>;
}

export const createRecipient = createAsyncThunk(
  `${SEND_BATCH_MODAL_RECIPIENTS}/CREATE`,
  async ({ entity, formApi }: CreationArgs, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const batch = makeSelectBatch()(state);
    assert(batch !== null);
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      const { body } = await request(
        Api.V1.Deals.Documents.Batches.AddRecipient.create(
          deal.uuid,
          batch.id,
          entity.entity_id,
          entity.entity_type,
        ),
      );

      const createdRecipient = findExistingEntity(entity, body.recipients);
      assert(createdRecipient !== undefined);

      const { values } = formApi.getState();
      const newFormRecipients = [...values.recipientEntities];

      const methodKey = getRecipientSendingMethodKey(createdRecipient);
      const methodValue = methodKey ? createdRecipient[methodKey] : null;

      if (methodKey && methodValue) {
        newFormRecipients.push({
          ...createdRecipient,
          method: methodKey,
          method_value: methodValue,
        });

        formApi.change('recipientEntities', newFormRecipients);
      }

      return createdRecipient;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

interface DestroyRecipientArgs {
  id: number;
  type: RecipientType;
}

export const destroyRecipient = createAsyncThunk(
  `${SEND_BATCH_MODAL_RECIPIENTS}/DESTROY`,
  async ({ id, type }: DestroyRecipientArgs, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const batch = makeSelectBatch()(state);
    assert(batch !== null);
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      await request(
        Api.V1.Deals.Documents.Batches.RemoveRecipient.destroy(
          deal.uuid,
          batch.id,
          id,
          type,
        ),
      );
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
