import { Model } from 'models/Model';
import { OwnerJSON } from './Owner';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientResourceBase {
  id: number;
  owner: OwnerJSON;
}

export interface ClientResourceJSON extends ClientResourceBase {
  created_at: string;
  updated_at: string;
}

export interface ClientResource extends ClientResourceBase {
  created_at: Date;
  updated_at: Date;
}

export class ClientResource<
  T extends ClientResourceJSON = ClientResourceJSON
> extends Model<T> {
  constructor(data: T) {
    super(data);
    this.created_at = Model.parseDate(data.created_at);
    this.updated_at = Model.parseDate(data.updated_at);
  }
}
