import { ApiRequestDescriptor } from '../../lib/request'
import { del, get, post, put } from '../../lib/methods'
import { authenticate, withSessionId } from '../../lib/decorators'
import { GraveyardConcessionJSON } from '../../models/Graveyard/Concession'

export class Concessions {
  @authenticate
  @withSessionId
  static create(
    graveyardId: number,
    concession: Omit<GraveyardConcessionJSON, 'id'>,
  ): ApiRequestDescriptor<GraveyardConcessionJSON> {
    return post(`/api/v1/graveyards/${graveyardId}/concessions`, concession)
  }

  @authenticate
  static index(
    graveyardId: number,
  ): ApiRequestDescriptor<Record<string, GraveyardConcessionJSON[]>> {
    return get(`/api/v1/graveyards/${graveyardId}/concessions`)
  }

  @authenticate
  @withSessionId
  static update(
    concession: GraveyardConcessionJSON,
  ): ApiRequestDescriptor<GraveyardConcessionJSON> {
    return put(`/api/v1/graveyards/concessions/${concession.id}`, concession)
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/graveyards/concessions/${id}`)
  }
}
