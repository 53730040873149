import { ResourceType } from '@advitam/api/models/Client/Ability/ResourceType';

export const Category = {
  ADMINISTRATIVE_PROCEDURE: ResourceType.ADMINISTRATIVE_PROCEDURE,
  FUNERAL_DEAL: `${ResourceType.DEAL}.Funeral`,
  FUNERAL_WILL: ResourceType.FUNERAL_WILL,
  ITEM_DEAL: `${ResourceType.DEAL}.Item`,
  MARBLE_DEAL: `${ResourceType.DEAL}.Marble`,
  MEMORIAL: ResourceType.MEMORIAL,
  SAFE_KEEPER: ResourceType.SAFE_KEEPER,
} as const;

type CategoryType = typeof Category;
export type CategoryValue = CategoryType[keyof CategoryType];

export interface Filters {
  q?: string;
  category_eq?: CategoryValue;
}
