import { ApiError } from 'api';
import { createSelector, Selector } from 'reselect';
import { sumBy } from 'lodash';

import { Summary, SummaryJSON } from 'models/Deal/Summary';
import { PaymentSummary, PaymentSummaryJSON } from 'models/Payment/Summary';
import { PaymentMean } from 'models/Payment/Mean';
import { OpsLog, OpsLogJSON } from 'models/OpsLog';

import { PAYMENTS } from './constants';
import { State, AppStateSubset } from './slice';
import { Filters } from './types';

type PaymentsSelector<T> = Selector<AppStateSubset, T>;

export const selectPaymentsDomain = (state: AppStateSubset): State =>
  state[PAYMENTS];

const makeSelectRawDeals = (): PaymentsSelector<SummaryJSON[]> =>
  createSelector(selectPaymentsDomain, substate => substate.deals);

export const makeSelectDeals = (): PaymentsSelector<Summary[]> =>
  createSelector(makeSelectRawDeals(), deals => deals.map(d => new Summary(d)));

const makeSelectRawDealPayments = (
  dealId: number,
): PaymentsSelector<PaymentSummaryJSON[] | null> =>
  createSelector(
    selectPaymentsDomain,
    substate => substate.payments[dealId] || null,
  );

export const makeSelectDealPayments = (
  dealId: number,
): PaymentsSelector<PaymentSummary[] | null> =>
  createSelector(
    makeSelectRawDealPayments(dealId),
    payments => payments?.map(pay => new PaymentSummary(pay)) || null,
  );

export const makeSelectDealFundingAgencyNames = (
  dealId: number,
): PaymentsSelector<string[] | null> =>
  createSelector(
    makeSelectDealPayments(dealId),
    payments =>
      payments
        ?.filter(p => p.mean === PaymentMean.FUNERAL_CONTRACT)
        .map(p => p.funding_agency?.name)
        .filter(Boolean) || null,
  );

const makeSelectRawDealOpsLogs = (
  dealId: number,
): PaymentsSelector<OpsLogJSON[] | null> =>
  createSelector(selectPaymentsDomain, substate => {
    const logs = substate.opsLogs[dealId];
    if (!logs) {
      return null;
    }
    return [...logs].sort((a, b) => a.created_at.localeCompare(b.created_at));
  });

export const makeSelectDealOpsLogs = (
  dealId: number,
): PaymentsSelector<OpsLog[] | null> =>
  createSelector(
    makeSelectRawDealOpsLogs(dealId),
    substate => substate?.map(log => new OpsLog(log)) || null,
  );

export const makeSelectDealOpsLogCount = (
  dealId: number,
): PaymentsSelector<number> =>
  createSelector(
    makeSelectRawDealOpsLogs(dealId),
    substate => substate?.length || 0,
  );

export const makeSelectOutstandingsTotal = (): PaymentsSelector<number> =>
  createSelector(makeSelectRawDeals(), deals =>
    sumBy(deals, d => d.payments.remaining_to_pay),
  );

export const makeSelectFilters = (): PaymentsSelector<Filters> =>
  createSelector(selectPaymentsDomain, substate => substate.filters);

export const makeSelectPage = (): PaymentsSelector<number> =>
  createSelector(selectPaymentsDomain, substate => substate.page);

export const makeSelectHasMoreDeals = (): PaymentsSelector<boolean> =>
  createSelector(selectPaymentsDomain, substate => substate.hasMore);

export const makeSelectIsLoading = (): PaymentsSelector<boolean> =>
  createSelector(selectPaymentsDomain, substate => substate.isLoading);

export const makeSelectError = (): PaymentsSelector<ApiError | null> =>
  createSelector(selectPaymentsDomain, substate => substate.error);
