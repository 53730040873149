import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Layout } from 'containers/Crud';
import { SelectableCityhalls } from 'containers/Crud/Input';

import { savePolice } from '../thunk';
import Wrapper from './Wrapper';
import { PoliceForm } from './types';
import messages from './messages';

function Miscellaneous(): JSX.Element {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: PoliceForm): void => {
      dispatch(savePolice(values.police));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <Layout.FieldsetGrid>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.managedPlaces.id} />}
            tooltip={<FormattedMessage id={messages.managedPlacesTooltip.id} />}
          >
            <SelectableCityhalls
              name="police.cityhalls"
              label={<FormattedMessage id={messages.place.id} />}
            />
          </Layout.Fieldset>
        </Layout.FieldsetGrid>
      </Layout.Container>
    </Wrapper>
  );
}

export default Miscellaneous;
