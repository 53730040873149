import type { Person } from '../Person'
import { DeathDeclarationState } from './State'

export interface DeathDeclarationJSON {
  id: number
  state: DeathDeclarationState
  created_at: string
  updated_at: string
  comment: string
  act_of_death: string
  client: Person
  deceased_client: Person
}

export { DeathDeclarationState }
