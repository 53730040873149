import { BusinessUser } from '@advitam/api/models/User/BusinessUser';
import { createAction } from 'lib/reactvitam/action_cable';

export interface MarcoPayload {
  session_id: string;
  user_id: number;
}

function marco(session_id: string, user: BusinessUser): MarcoPayload {
  return {
    session_id,
    user_id: user.id,
  };
}

export default createAction(marco, 'marco');
