import { ChangeEvent } from 'react';
import { TextInput } from 'components/Input/index.js';
import { IconButton } from 'components/Button/index.js';
import { MaterialValues } from 'components/Button/constants';
import style from '../../Repatriation.module.scss';

interface TripCrossedBorderProps {
  canBeDeleted: boolean;
  crossedBorder: string;
  onDelete: () => void;
  onInsert: () => void;
  onUpdate: (value: string) => void;
}

export default function TripCrossedBorder({
  canBeDeleted,
  crossedBorder,
  onInsert,
  onDelete,
  onUpdate,
}: TripCrossedBorderProps): JSX.Element {
  const onChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    onUpdate(value);
  };

  return (
    <div className={style.crossed_border}>
      <TextInput value={crossedBorder} onChange={onChange} />
      <IconButton onClick={onInsert}>{MaterialValues.ADD}</IconButton>
      {canBeDeleted && (
        <IconButton className="button--red" onClick={onDelete}>
          {MaterialValues.DELETE}
        </IconButton>
      )}
    </div>
  );
}
