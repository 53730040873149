import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Field } from 'react-final-form';

import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { HardSpinner } from '@advitam/ui';
import { Layout } from 'containers/Crud';

import ErrorBanner from 'components/ErrorBanner';
import Wrapper from '../Wrapper';
import {
  makeSelectError,
  makeSelectProducts,
  makeSelectIsSaving,
} from './selectors';
import slice from './slice';
import { fetchWarehouseProducts, saveProducts } from './thunk';
import { WarehouseProductsForm } from './types';
import ProductList from './List';
import { ProductListProvider } from './ProductListProvider';

function Products(): JSX.Element {
  const dispatch = useDispatch();
  const products = useSelector(makeSelectProducts());

  const { warehouseId } = useParams();
  assert(warehouseId !== undefined);
  const parsedId = parseInt(warehouseId, 10);
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());

  const initialFetch = useCallback(() => {
    dispatch(fetchWarehouseProducts());
  }, [dispatch]);

  useEffect(initialFetch, [initialFetch]);

  const onSubmit = useCallback(
    (values: WarehouseProductsForm) => {
      dispatch(saveProducts({ values, warehouseId: parsedId }));
    },
    [dispatch, parsedId],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={products}>
      <Layout.Container>
        <Field name="sectionValues">
          {(): JSX.Element => (
            <ProductListProvider>
              <ProductList />
            </ProductListProvider>
          )}
        </Field>
      </Layout.Container>

      {isSaving && <HardSpinner />}

      {error && <ErrorBanner errors={error} />}
    </Wrapper>
  );
}

export default withSlice(slice)(Products);
