// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__container--gZA5M{display:flex;flex-direction:column}.style__container--gZA5M>button{width:-moz-fit-content;width:fit-content;margin-left:auto}.style__buttons--dlSgu{display:flex;justify-content:space-between;margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/containers/Documents/Toolbar/DownloadModal/style.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,qBAAA,CAEA,gCACE,sBAAA,CAAA,iBAAA,CACA,gBAAA,CAIJ,uBACE,YAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n\n  > button {\n    width: fit-content;\n    margin-left: auto;\n  }\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style__container--gZA5M`,
	"buttons": `style__buttons--dlSgu`
};
export default ___CSS_LOADER_EXPORT___;
