import Star from './Star'
import style from './Stars.module.scss'

interface StarsProps {
  rate: number
  className?: string
}

export default function Stars({ rate, className }: StarsProps): JSX.Element {
  return (
    <div className={[style.stars, className].filter(Boolean).join(' ')}>
      {[...Array<number>(5)].map((_v, i) => (
        <Star key={i} fillValue={rate - i} />
      ))}
    </div>
  )
}
