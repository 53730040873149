import { cloneDeep } from 'lodash';

import { StepType } from '@advitam/api/models/Step';
import { Ceremony } from '../Ceremony';

import { ProductGroup } from './Group';
import { ProductUse } from './Use';
import { ProductType } from './Type';
import { ProductMaterialStrength } from './MaterialStrength';
import { ProductGraveType } from './GraveType';
import { ProductTag } from './Tag';
import { ProductWindow } from './Window';

export interface ProductJSON {
  id: number | null;
  advitam_name: string;
  description: string;
  type: ProductType;
  ceremony: Ceremony;
  use: ProductUse;
  hermetic: boolean | null;
  window: ProductWindow | null;
  material: string | null;
  shape: string;
  material_strength: ProductMaterialStrength | null;
  color: string;
  size_model: string;
  thickness: number | null;
  inside_length: number | null;
  inside_width: number | null;
  inside_height: number | null;
  outside_length: number | null;
  outside_width: number | null;
  outside_height: number | null;
  diameters: number | null;
  weight: number | null;
  step_type: StepType;
  with_stele: boolean;
  grave_type: ProductGraveType | null;
  tags: ProductTag[];
  group_name: ProductGroup;
  name: string;
  images: string[];
  thumbnail: string;
}

const skeleton: ProductJSON = {
  id: null,
  advitam_name: '',
  description: '',
  type: ProductType.ANNOUNCEMENT,
  ceremony: Ceremony.CREMATION,
  use: ProductUse.UNSPECIFIED,
  hermetic: null,
  window: null,
  material: null,
  shape: '',
  material_strength: null,
  color: '',
  size_model: '',
  thickness: null,
  inside_length: null,
  inside_width: null,
  inside_height: null,
  outside_length: null,
  outside_width: null,
  outside_height: null,
  diameters: null,
  weight: null,
  step_type: StepType.CASKETING,
  with_stele: false,
  grave_type: null,
  tags: [],
  group_name: ProductGroup.ANNOUNCEMENT,
  name: '',
  images: [],
  thumbnail: '',
};

export class Product {
  static get skeleton(): ProductJSON {
    return cloneDeep(skeleton);
  }
}

export {
  ProductGroup,
  ProductUse,
  ProductType,
  ProductMaterialStrength,
  ProductGraveType,
  ProductTag,
  ProductWindow,
};
