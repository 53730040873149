import { useDispatch, useSelector } from 'react-redux';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';

import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import { preCasketingBooking } from 'containers/Deal/BookingSection/actions';
import { ButtonV2 as Button } from 'components/Button';
import { assert } from 'lib/support';

import TaskRow from './Row';
import Label from './Label';

interface PreCasketingProps {
  item: TodoItem;
}

export default function PreCasketingTask({
  item,
}: PreCasketingProps): JSX.Element {
  const dispatch = useDispatch();
  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);

  return (
    <TaskRow item={item}>
      <Button
        className="button--nothing"
        onClick={(): void => {
          dispatch(preCasketingBooking(deal.uuid));
        }}
      >
        <Label title={item.title} />
      </Button>
    </TaskRow>
  );
}
