import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectList, TextInput } from 'components/Input';

import {
  PLAIN_EARTH,
  COLUMBARIUM_CASE,
  VAULT,
  VAULT_FRONT_DOOR,
  VAULT_FRONT_DOOR_UNDERGROUND,
  UNKNOWN,
} from 'containers/Deal/DealFuneral/StepsSection/StepEditors/Interment/constants';

import { isMonument } from 'components/ConcessionSelection/utils';
import { SelectBoolean } from 'components/CRUD';
import messagesCrud from 'messages/crud';

import SelectConcessionType from './SelectConcessionType';
import messages from './messages';

const SEMELLE_TYPE_CEMENT = 'cement';
const SEMELLE_TYPE_GRANITE = 'granite';
const NO_SEMELLE = 'no_semelle';

function Concession({
  concession,
  updateConcession,
  intl,
  isUrn,
  locationId,
  handleChange,
  graveyardConcessions,
  graveyardConcessionTypes,
}) {
  /**
   * Function to update concession type info
   *
   * @param {value} string value updated
   * @param {name} string key to update
   */
  const updateConcessionTypeInfo = (value, name) => {
    updateConcession({
      type: {
        ...concession.type,
        info: {
          ...concession.type.info,
          [name]: value,
        },
      },
    });
  };

  /**
   * Function to update concession places
   *
   * @param {value} string value updated
   * @param {name} string key to update
   */
  const updateConcessionPlaces = ({ target: { value, name } }) => {
    updateConcession({
      places: {
        ...concession.places,
        [name]: value,
      },
    });
  };

  const isVault = concessionTypeName => {
    const vaults = [
      VAULT,
      VAULT_FRONT_DOOR,
      VAULT_FRONT_DOOR_UNDERGROUND,
      UNKNOWN,
    ];
    return vaults.includes(concessionTypeName);
  };

  /**
   * Function to check if a place is available
   *
   * @param {string} concessionTypeName concession to check
   * @returns {boolean} is there a place available
   */
  const isPlaces = concessionTypeName => {
    const concessionWithoutPlaceAvailabe = [COLUMBARIUM_CASE];
    return !concessionWithoutPlaceAvailabe.includes(concessionTypeName);
  };

  const isMonumentAvailable = isMonument(concession.type.name);
  const isPlacesAvailable = isPlaces(concession.type.name);
  const isVaultAvailable = isVault(concession.type.name);

  const OPTION_YES_NAME = intl.formatMessage(messagesCrud.optionYes);
  const OPTION_NO_NAME = intl.formatMessage(messagesCrud.optionNo);

  const options = [
    {
      name: OPTION_YES_NAME,
      value: 'true',
    },
    {
      name: OPTION_NO_NAME,
      value: 'false',
    },
  ];

  const optionsDisposition = [
    { value: 'simple', name: intl.formatMessage(messages.simple) },
    { value: 'double', name: intl.formatMessage(messages.double) },
    { value: 'headToTail', name: intl.formatMessage(messages.headToTail) },
  ];

  const messageForPlacesRemaining = concession.exists
    ? messages.placesLeft
    : messages.places;

  const semelleSelectValue = (isThereSemelle, semelleMaterial) => {
    if (isThereSemelle === null || isThereSemelle === undefined) {
      return null;
    }
    if (isThereSemelle === false) {
      return NO_SEMELLE;
    }
    if (semelleMaterial === SEMELLE_TYPE_GRANITE) {
      return SEMELLE_TYPE_GRANITE;
    }

    if (semelleMaterial === SEMELLE_TYPE_CEMENT) {
      return SEMELLE_TYPE_CEMENT;
    }
    return null;
  };

  const semelleSelectValueToSemelle = value => {
    if (value === '' || value === null || value === undefined) {
      return [null, null];
    }
    const ret = {};
    ret[NO_SEMELLE] = [false, null];
    ret[SEMELLE_TYPE_GRANITE] = [true, SEMELLE_TYPE_GRANITE];
    ret[SEMELLE_TYPE_CEMENT] = [true, SEMELLE_TYPE_CEMENT];
    return ret[value];
  };

  const optionsSemelleTypes = [
    {
      name: intl.formatMessage(messagesCrud.optionNo),
      value: NO_SEMELLE,
    },
    {
      name: intl.formatMessage(messages.granite),
      value: SEMELLE_TYPE_GRANITE,
    },
    {
      name: intl.formatMessage(messages.cement),
      value: SEMELLE_TYPE_CEMENT,
    },
  ];

  return (
    <div className="concession__field">
      <h5>
        <FormattedMessage {...messages.graveState} />
      </h5>
      <SelectConcessionType
        concession={concession}
        locationId={locationId}
        graveyardConcessionTypes={graveyardConcessionTypes}
        graveyardConcessions={graveyardConcessions}
        isUrn={isUrn}
        handleChange={handleChange}
      />
      {concession.type.name === PLAIN_EARTH && (
        <SelectBoolean
          id="fausseCase"
          name="fausse_case"
          value={concession.type.info.fausse_case}
          onChange={e =>
            updateConcessionTypeInfo(e.target.value, e.target.name)
          }
          messages={messages}
          isReadingMode={false}
        />
      )}
      {concession.exists && (
        <div>
          <FormattedMessage {...messages.semelle} />
          <SelectList
            value={semelleSelectValue(
              concession.type.info.isThereSemelle,
              concession.type.info.semelle_material,
            )}
            options={optionsSemelleTypes}
            name="semelle"
            onChange={({ target: { value } }) => {
              const [
                isThereSemelle,
                semelleMaterial,
              ] = semelleSelectValueToSemelle(value);
              updateConcession({
                type: {
                  ...concession.type,
                  info: {
                    ...concession.type.info,
                    isThereSemelle,
                    semelle_material: semelleMaterial,
                  },
                },
              });
            }}
            emptyOption
          />
        </div>
      )}
      {isVaultAvailable && (
        <div>
          <FormattedMessage {...messages.disposition} />
          <SelectList
            value={concession.type.info.disposition}
            options={optionsDisposition}
            name="disposition"
            onChange={e =>
              updateConcessionTypeInfo(e.target.value || null, e.target.name)
            }
            emptyOption
          />
        </div>
      )}
      {isMonumentAvailable && concession.exists && (
        <div>
          <FormattedMessage {...messages.monument} />
          <SelectList
            value={concession.type.info.isThereMonument}
            options={options}
            name="isThereMonument"
            onChange={() =>
              updateConcessionTypeInfo(
                !concession.type.info.isThereMonument,
                'isThereMonument',
              )
            }
            emptyOption
          />
        </div>
      )}
      {isPlacesAvailable && (
        <div>
          <FormattedMessage {...messageForPlacesRemaining} />
          <TextInput
            onChange={updateConcessionPlaces}
            name="remaining"
            value={concession.places.remaining || ''}
          />
        </div>
      )}
    </div>
  );
}

Concession.propTypes = {
  /** concession data */
  concession: PropTypes.object.isRequired,
  /** function to update concession */
  updateConcession: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  /** step location id */
  locationId: PropTypes.number,
  /** concessions types of graveyard selected */
  graveyardConcessionTypes: PropTypes.array,
  /** concessions available in graveyard selected */
  graveyardConcessions: PropTypes.object,
  isUrn: PropTypes.bool,
  /** Function to update the step */
  handleChange: PropTypes.func.isRequired,
};

export default injectIntl(Concession);
