import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { CityhallJSON } from '@advitam/api/models/Cityhall';
import { NearEntityJSON } from '@advitam/api/models/Entity/Near';
import { EntityType } from '@advitam/api/models/Entity/EntityType';

import {
  fetchPrestationCoverages,
  fetchPrestationsCoverageKeys,
} from './Content/Prestations/thunk';
import { fetchSupplierCoverage } from './Content/Suppliers/thunk';
import { MAP } from './constants';
import { fetchCityhall, fetchNearEntities } from './thunk';
import { EntityMarker, MapActiveSupplier } from './types';
import { getMarkerId } from './utils';

export interface State {
  cityhall: CityhallJSON | null;
  nearEntityTypes: EntityType[];
  nearEntities: NearEntityJSON[];
  activeMarkerId: string | null;
  activeSupplier: MapActiveSupplier | null;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [MAP]: State;
}

export const initialState: State = {
  cityhall: null,
  nearEntityTypes: [],
  nearEntities: [],
  activeMarkerId: null,
  activeSupplier: null,
  error: null,
};

const slice = createSlice({
  name: MAP,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    resetCityhall(state) {
      state.cityhall = initialState.cityhall;
    },
    resetNearEntities(state) {
      state.nearEntities = initialState.nearEntities;
    },
    removeNearEntitiesByType(state, { payload }: PayloadAction<EntityType>) {
      state.nearEntities = state.nearEntities.filter(
        entity => entity.type !== payload,
      );
    },
    setActiveMarker(state, { payload }: PayloadAction<EntityMarker | null>) {
      state.activeMarkerId = payload ? getMarkerId(payload) : null;
    },
    setActiveSupplier(
      state,
      { payload }: PayloadAction<MapActiveSupplier | null>,
    ) {
      state.activeSupplier = payload;
    },
    setEntityTypes(state, { payload }: PayloadAction<EntityType[]>) {
      state.nearEntityTypes = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCityhall.fulfilled, (state, { payload }) => {
      state.cityhall = payload;
    });
    builder.addCase(fetchCityhall.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(fetchNearEntities.fulfilled, (state, { payload }) => {
      state.nearEntities = payload;
    });
    builder.addCase(fetchNearEntities.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(
      fetchPrestationsCoverageKeys.rejected,
      (state, { payload }) => {
        state.error = ApiError.serialize(payload);
      },
    );

    builder.addCase(fetchPrestationCoverages.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(fetchSupplierCoverage.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const {
  resetCityhall,
  resetNearEntities,
  removeNearEntitiesByType,
  setActiveMarker,
  setActiveSupplier,
  setEntityTypes,
} = slice.actions;
export default slice;
