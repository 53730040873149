import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Helmet from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import ErrorModal from 'components/ErrorModal';
import HardSpinner from 'components/HardSpinner';
import Documents from 'containers/Documents';
import DocumentGenerationModal from 'containers/DocumentGenerationModal';
import { makeSelectUser } from 'slices/auth';
import { NEW_PATH } from 'containers/App/constants';
import reducerAutocomplete from 'containers/AutoComplete/reducer';
import reducerDialogBox from 'containers/DialogBox/reducer';
import sagaAutoComplete from 'containers/AutoComplete/sagas';
import { makeSelectFuneralBrands } from 'slices/data';
import {
  makeSelectDeal,
  makeSelectManualSelection,
  makeSelectDealIsDirty,
  makeSelectError,
} from '../selectors';
import { error as setError } from '../actions';
import { putDealBrand as putDealBrandDispatch } from '../SideBar/actions';
import SideBar from '../SideBar';
import UpdateBanner from '../UpdateBanner/index.tsx';
import Clients from '../Clients/index.tsx';
import BookingSection from '../BookingSection';
import Commentaries from '../Commentaries/index.tsx';
import { updateManualPrice as updateManualPriceDispatch } from '../slice.ts';

import {
  CLIENT,
  DOCUMENTS,
  PAYMENTS,
  STEPS,
  SUPPLIERS,
  PRODUCTS,
  OTHER,
  ITEM_LINKS,
} from '../SideBar/constants';

import SendBatchModal from '../SendBatchModal';
import Payments from '../Payments/index.tsx';

import DeliverySection from './DeliverySection';
import ProductsSection from './ProductsSection/index.tsx';
import ParentModal from './ParentModal.tsx';
import OtherSection from './OtherSection/index.tsx';

import { saveDeal } from '../thunk.ts';
import { fetchDealDetails } from './actions';
import saga from './saga';
import reducer from './reducer';
import {
  makeSelectIsLoading,
  makeSelectParent,
  makeSelectParentType,
} from './selectors';
import messages from './messages.ts';

import style from './style.scss';

export class DealItems extends Component {
  componentDidMount() {
    const {
      match: {
        params: { dealUUID },
      },
      getDealItem,
    } = this.props;
    const isNewDeal = dealUUID === NEW_PATH;
    // Create structure to check if clients emails already exists
    if (!isNewDeal) getDealItem();
  }

  componentWillUnmount() {
    const { setError: setErrorDealWrapper, setErrorItem } = this.props;
    setErrorDealWrapper(null);
    setErrorItem(null);
  }

  renderUpdateBanner = isNewDeal => {
    const {
      dealIsDirty,
      manualSelection,
      isLoading,
      querySaveDeal,
      deal,
    } = this.props;

    if (isLoading || !deal.mainClient.id) {
      return null;
    }

    return (
      <UpdateBanner
        isNewDeal={isNewDeal}
        dealIsDirty={dealIsDirty}
        querySaveDeal={querySaveDeal}
        manualSelection={manualSelection}
      />
    );
  };

  renderSection = () => {
    const {
      sectionOpened,
      dealIsDirty,
      deal,
      match: {
        params: { dealUUID },
      },
      parent,
      parentType,
    } = this.props;

    const isNewDeal = dealUUID === NEW_PATH;
    switch (sectionOpened) {
      case STEPS:
        return (
          <>
            <Commentaries />
            <DeliverySection delivery={deal.delivery} />
          </>
        );
      case DOCUMENTS:
        return !isNewDeal && <Documents dealUUID={dealUUID} />;
      case PAYMENTS:
        return !isNewDeal && <Payments dealUUID={dealUUID} />;
      case OTHER:
        return (
          !isNewDeal && <OtherSection parent={parent} parentType={parentType} />
        );
      case SUPPLIERS:
        return (
          !isNewDeal && !dealIsDirty && <BookingSection dealUUID={dealUUID} />
        );
      case CLIENT:
        return (
          <>
            <Clients singleClient withLink withDuplication />
            {dealIsDirty && !deal.mainClient.id && (
              <p className="text--error space--0-10-0-10">
                <FormattedMessage {...messages.selectAClient} />
              </p>
            )}
          </>
        );
      case PRODUCTS:
        return <ProductsSection dealUUID={dealUUID} />;
      default:
        return null;
    }
  };

  renderContent() {
    const {
      match: {
        params: { dealUUID },
      },
      isLoading,
      parent,
    } = this.props;
    const isNewDeal = dealUUID === NEW_PATH;
    return (
      <main id="deal" className={style.container}>
        {isLoading && <HardSpinner />}
        {isNewDeal && parent === undefined && <ParentModal />}
        {this.renderSection()}
        {this.renderUpdateBanner(isNewDeal)}
      </main>
    );
  }

  render() {
    const {
      match: {
        params: { dealUUID },
      },
      error,
      brands,
      deal,
      putDealBrand,
      updateManualPrice,
      user,
      setSection,
      sectionOpened,
      location,
    } = this.props;
    const isNewDeal = dealUUID === NEW_PATH;
    return (
      <div className={style.wrapper}>
        <Helmet title={`${deal.mainClient.lastName || ''} - Articles`} />
        <SideBar
          dealUUID={dealUUID}
          isNewDeal={isNewDeal}
          brands={brands}
          putDealBrand={putDealBrand}
          updateManualPrice={updateManualPrice}
          links={ITEM_LINKS}
          user={user}
          deal={deal}
          setSection={setSection}
          sectionOpened={sectionOpened}
          location={location}
          brandSelectedId={deal.brand && deal.brand.id}
        />
        <ErrorModal error={error} />
        {this.renderContent()}
        <DocumentGenerationModal dealId={deal.id} />
        <SendBatchModal />
      </div>
    );
  }
}

DealItems.propTypes = {
  /** location match data */
  match: PropTypes.object.isRequired,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  changeDealState: PropTypes.func.isRequired,
  /** Function to set error */
  setError: PropTypes.func.isRequired,
  /** Function to set error item */
  setErrorItem: PropTypes.func.isRequired,
  /** is deal dirty */
  dealIsDirty: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  /** Function to save deal */
  querySaveDeal: PropTypes.func.isRequired,
  /** is it a manual selection */
  manualSelection: PropTypes.bool,
  /** deal data */
  deal: PropTypes.object,
  /** section currently opened */
  sectionOpened: PropTypes.string.isRequired,
  /** Function to set section */
  setSection: PropTypes.func.isRequired,
  /** Function to get deal item from DB */
  getDealItem: PropTypes.func.isRequired,
  /** list of brands */
  brands: PropTypes.array.isRequired,
  /** Function to update deal brand */
  putDealBrand: PropTypes.func.isRequired,
  /** Function to update manual price */
  updateManualPrice: PropTypes.func.isRequired,
  location: PropTypes.object,
  parent: PropTypes.object,
  parentType: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  error: makeSelectError(),
  isLoading: makeSelectIsLoading(),
  user: makeSelectUser(),
  dealIsDirty: makeSelectDealIsDirty(),
  manualSelection: makeSelectManualSelection(),
  deal: makeSelectDeal(),
  brands: makeSelectFuneralBrands(),
  parent: makeSelectParent(),
  parentType: makeSelectParentType(),
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { deal } = stateProps;
  const { dispatch } = dispatchProps;
  const {
    match: {
      params: { dealUUID },
    },
  } = ownProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    setErrorItem: err => dispatch(setError(err)),
    querySaveDeal: () => dispatch(saveDeal(deal)),

    getDealItem: () => dispatch(fetchDealDetails(deal)),
    putDealBrand: brand => dispatch(putDealBrandDispatch(dealUUID, brand)),
    updateManualPrice: manualPrice =>
      dispatch(updateManualPriceDispatch(manualPrice)),
  };
}

const withConnect = connect(mapStateToProps, null, mergeProps);

const withReducer = [
  injectReducer({ key: 'dealItems', reducer }),
  injectReducer({ key: 'dialogBox', reducer: reducerDialogBox }),
  injectReducer({ key: 'autoComplete', reducer: reducerAutocomplete }),
];
const withSaga = [
  injectSaga({ key: 'dealItems', saga }),
  injectSaga({ key: 'autoComplete', saga: sagaAutoComplete }),
];

export default compose(...withReducer, ...withSaga, withConnect)(DealItems);
