// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(min-width: 576px){.Zones-module__actions--yYSrZ{flex-direction:row;justify-content:space-between}}.Zones-module__add--VqMAq{margin-left:auto;margin-top:16px}@media(min-width: 576px){.Zones-module__add--VqMAq{margin-top:0}}`, "",{"version":3,"sources":["webpack://./../ui/scss/_media.scss","webpack://./src/containers/Supplier/Warehouse/sections/Zones/Zones.module.scss"],"names":[],"mappings":"AAcI,yBCZJ,8BAEI,kBAAA,CACA,6BAAA,CAAA,CAIJ,0BACE,gBAAA,CACA,eAAA,CDGE,yBCLJ,0BAKI,YAAA,CAAA","sourcesContent":["$breakpoints: (\n  xxl: 1440px,\n  xl: 1200px,\n  lg: 992px,\n  md: 768px,\n  sm: 576px,\n  xs: 375px\n);\n\n@mixin above($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n@mixin below($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (max-width: ($breakpoint-value - 1)) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n:export {\n  /* stylelint-disable property-no-unknown */\n  xxl: 1440px;\n  xl: 1200px;\n  lg: 992px;\n  md: 768px;\n  sm: 576px;\n  xs: 375px;\n  /* stylelint-enable property-no-unknown */\n}\n","@import '~@advitam/ui/scss/media';\n\n.actions {\n  @include above(sm) {\n    flex-direction: row;\n    justify-content: space-between;\n  }\n}\n\n.add {\n  margin-left: auto;\n  margin-top: 16px;\n\n  @include above(sm) {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"actions": `Zones-module__actions--yYSrZ`,
	"add": `Zones-module__add--VqMAq`
};
export default ___CSS_LOADER_EXPORT___;
