import { BillingType } from '@advitam/api/models/BillingType';
import type { NewFuneralParlor } from './types';

export const FUNERAL_PARLOR = 'FuneralParlor';

export const Path = {
  GENERAL: '',
  MISCELLANEOUS: 'divers',
  STAYS: 'sejours',
};

export const FUNERAL_PARLOR_SKELETON: NewFuneralParlor = {
  name: '',
  disabled: false,
  address: null,
  address_l2: null,
  postal_code: null,
  insee_code: null,
  city: null,
  department: null,
  country: null,
  latitude: null,
  longitude: null,
  manual_address: false,
  phone: null,
  email: null,
  prefered_contact_media: null,
  opening_hours: null,
  fax: null,
  phone_2: null,
  phone_3: null,
  email_2: null,
  email_3: null,
  email_4: null,
  comment: null,
  current_update_user_id: null,
  last_update_user_id: null,
  billing_type: BillingType.DEAL,
  cheque_order: null,
  cheque_address: null,
  rib: null,
  iban: null,
  vat: null,
  checkout_type: null,
  authentic_document_needed: null,
  siret: null,
  headquarter_name: null,
  headquarter_address: null,
  headquarter_postal_code: null,
  headquarter_country: null,
  headquarter_city: null,
  website: null,
  default_stay_id: null,
  accept_coffin_delivery: null,
  depository: false,
  admission_price: null,
  admission_outside_opening_hours_price: null,
  cleaning_lab_price: null,
  ritual_cleaning_lab_price: null,
  embalming_lab_price: null,
  ceremony_room: null,
  departure_room_price: null,
  casketing_price: null,
  cooler_setup_price: null,
  current_update_dt: null,
  last_update_dt: null,
};
