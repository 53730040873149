import { push } from 'redux-first-history';

import {
  deleteTokens,
  getTokens,
  updateTokensFromResponse,
} from '@advitam/api/lib/tokens.ts';
import { COMPANY_HEADER, REQUEST_HEADERS_BASE } from 'api/request.ts';
import { LOGIN_PATH } from 'containers/App/constants.ts';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  const contentType = response.headers.get('content-type');
  if (
    response.status !== 204 &&
    contentType &&
    contentType.startsWith('application/json')
  ) {
    return response.json();
  }
  return response;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 * Sign out the user if he was unauthorized to make the request
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  // Sign Out user he is not logged to the API
  if (response.status === 401) {
    deleteTokens();
    push(LOGIN_PATH);
  }
  const error = new Error(response.statusText);
  error.status = response.status;
  const contentType = response.headers.get('content-type') || '';
  if (contentType.startsWith('application/json')) {
    error.response = response.json();
  }
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @function request
 * @param  {string} url       The URL we want to request
 * @param  {object} options   The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(process.env.API_ENDPOINT + url, options)
    .then(checkStatus)
    .then(parseJSON);
}

/**
 * Requests a URL, returning a promise
 * Accept json as default response content type
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} options   The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function requestWithAuth(url, options = {}) {
  const newOptions = { ...options };
  newOptions.headers = {
    Accept: 'application/json',
    ...REQUEST_HEADERS_BASE,
    ...COMPANY_HEADER,
    ...newOptions.headers,
    ...getTokens(),
  };

  const response = await fetch(process.env.API_ENDPOINT + url, newOptions);
  updateTokensFromResponse(response);
  checkStatus(response);
  return parseJSON(response);
}
