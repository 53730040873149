import { useState, MouseEvent, ReactNode } from 'react';
import PropTypes from 'prop-types';

import { TransparentButton } from 'components/Button/index.js';
import Popup from 'components/Popup';

import style from './style.scss';

interface DropdownProps {
  children: ReactNode | ReactNode[];
  placeholder: ReactNode;
  contained: boolean;
  openOnHover?: boolean;
  closeOnClick?: boolean;
}

export default function Dropdown({
  children,
  placeholder,
  contained,
  openOnHover,
  closeOnClick,
}: DropdownProps): JSX.Element {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const toggleDropDown = (event: Event): void => {
    setDropDownOpen(!dropDownOpen);

    // The click event is useless for upper elements. However, stopping the
    // event automatically prevents them to take an action, when we are
    // embedded in a link for example.
    event.stopPropagation();
    event.preventDefault();
  };

  const [isTitleHovered, setTitleHovered] = useState(false);
  const [areChildrenHovered, setChildrenHovered] = useState(false);
  const shallShowChildren = openOnHover
    ? areChildrenHovered || isTitleHovered
    : dropDownOpen;

  const onClick = (event: MouseEvent): void => {
    if (dropDownOpen && closeOnClick) {
      setDropDownOpen(false);
    }
    event.stopPropagation();
  };

  return (
    <Popup
      onFocusOut={(): void => setDropDownOpen(false)}
      onClick={onClick}
      onMouseEnter={(): void => setTitleHovered(true)}
      onMouseLeave={(): void => setTitleHovered(false)}
    >
      <TransparentButton className={style.input} onClick={toggleDropDown}>
        <div className={style.placeholder}>{placeholder}</div>
        <i className={`material-icons ${style['drop-icon']}`}>
          arrow_drop_down
        </i>
      </TransparentButton>
      <div className={style.dropdown}>
        {shallShowChildren && (
          <div
            className={`${style['children-wrapper']} ${
              contained ? style.contained : ''
            }`}
            onMouseEnter={(): void => setChildrenHovered(true)}
            onMouseLeave={(): void => setChildrenHovered(false)}
          >
            {children}
          </div>
        )}
      </div>
    </Popup>
  );
}

Dropdown.defaultProps = {
  contained: true,
};

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string.isRequired,
  contained: PropTypes.bool,
  openOnHover: PropTypes.bool,
  closeOnClick: PropTypes.bool,
};
