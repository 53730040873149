import type { IntlShape } from 'react-intl';

import type { LayerCreatedNotificationJSON } from '@advitam/api/models/Notification';
import { Path } from 'containers/App/constants';
import { Path as FuneralPath } from 'containers/Deal/Funeral/constants';

import LayerCreated from './icons/layer-created.svg';
import LayerCreatedStandalone from './icons/layer-created.standalone.png.bin';
import type { TileProps } from './TileProps';
import messages from './messages';

export default function getLayerCreatedProps(
  notification: LayerCreatedNotificationJSON,
  intl: IntlShape,
): TileProps {
  const isFuneralDeal = notification.comp_info.deal_type === 'funeral';

  return {
    link: Path.DEAL(
      notification.comp_info.deal_uuid,
      isFuneralDeal ? FuneralPath.IDENTITY : 'client',
    ),
    IconComponent: LayerCreated,
    icon: LayerCreatedStandalone,
    title: intl.formatMessage(messages.layerCreatedTitle),
    body: intl.formatMessage(messages.layerCreatedText, {
      userName: notification.comp_info.user_full_name,
    }),
  };
}
