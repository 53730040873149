import { createSelector, Selector } from 'reselect';

import { makeSelectIsUpdated } from 'containers/ResourceCable/selectors';
import type { AppStateSubset as ResourceCableAppStateSubset } from 'containers/ResourceCable/slice';
import { DealState } from 'models/Deal/State';

import {
  makeSelectDeal,
  makeSelectIsEmailValid,
  makeSelectIsPhoneValid,
} from '../selectors.js';
import { makeSelectAreClientsValid } from '../Clients/selectors';
import type { AppStateSubset as ClientsAppStateSubset } from '../Clients/slice';
import type { AppStateSubset as DealAppStateSubset } from '../slice';

type AppStateSubset = ResourceCableAppStateSubset &
  DealAppStateSubset &
  ClientsAppStateSubset;

export const makeSelectCanSaveDeal = (): Selector<AppStateSubset, boolean> =>
  createSelector(
    makeSelectDeal(),
    makeSelectIsEmailValid(),
    makeSelectIsPhoneValid(),
    makeSelectAreClientsValid(),
    makeSelectIsUpdated(),
    (deal, isEmailValid, isPhoneValid, hasDuplicatedClient, isUpdated) => {
      const isDealDone = deal?.state === DealState.DONE;
      return (
        !isDealDone &&
        isEmailValid &&
        isPhoneValid &&
        !hasDuplicatedClient &&
        !isUpdated
      );
    },
  );
