import { defineMessages } from 'react-intl';

export default defineMessages({
  graveyards: { id: 'app.components.IntermentLocation.graveyards' },
  semelleType: { id: 'app.components.IntermentLocation.semelleType' },
  semelleShape: { id: 'app.components.IntermentLocation.semelleShape' },
  graveyardType: { id: 'app.components.IntermentLocation.graveyardType' },
  vaultMandatory: { id: 'app.components.IntermentLocation.vaultMandatory' },
  fausseCase: { id: 'app.components.IntermentLocation.fausseCase' },
  fausseCaseValue: { id: 'app.components.IntermentLocation.fausseCaseValue' },
  dimensions: { id: 'app.components.IntermentLocation.semelle' },
  dimensionsValues: { id: 'app.components.IntermentLocation.dimensionsValues' },
  granite: { id: 'app.components.IntermentLocation.granite' },
  cement: { id: 'app.components.IntermentLocation.cement' },
  flat: { id: 'app.components.IntermentLocation.flat' },
  water_lapel: { id: 'app.components.IntermentLocation.water_lapel' },
  landscaped: { id: 'app.components.IntermentLocation.landscaped' },
  classic: { id: 'app.components.IntermentLocation.classic' },
  allowed: { id: 'app.components.IntermentLocation.allowed' },
  required: { id: 'app.components.IntermentLocation.required' },
  forbidden: { id: 'app.components.IntermentLocation.forbidden' },
});
