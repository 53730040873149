import { authenticate } from '../../../lib/decorators'
import { get, post } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { AdministrativeProcedureJSON } from '../../../models/AdministrativeProcedure'
import { DefunctJSON } from '../../../models/Defunct'
import { AutocompleteResult } from '../../Autocompletes'
import { serialize } from './serializers'

export class AdministrativeProcedures {
  @authenticate
  static index(): ApiRequestDescriptor<AdministrativeProcedureJSON[]> {
    return get('/api/v1/client/administrative_procedures')
  }

  @authenticate
  static create(
    defunct: DefunctJSON,
    deathCity: AutocompleteResult,
    familyLink: string,
  ): ApiRequestDescriptor<AdministrativeProcedureJSON> {
    return post(
      '/api/v1/client/administrative_procedures',
      serialize(defunct, deathCity, familyLink),
    )
  }
}
