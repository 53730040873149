import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { sameLocation } from 'containers/Deal/DealFuneral/StepsSection/StepEditors/utils';
import {
  VAULT,
  VAULT_FRONT_DOOR,
  VAULT_FRONT_DOOR_UNDERGROUND,
  PLAIN_EARTH,
  UNKNOWN,
} from 'containers/Deal/DealFuneral/StepsSection/StepEditors/Interment/constants';
import CheckboxLabel from 'components/Input/CheckboxLabel';
import CheckboxWishes from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxWishes';
import { WANT_FAMILY_TO_BUY_CONCESSION } from 'utils/constants';
import messagesWishes from 'messages/wishes';

import { isMonument } from './utils';
import messages from './messages';
import Burieds from './Burieds';
import Buyer from './Buyer';
import Concession from './Concession';
import Place from './Place';
import Rightholders from './Rightholders';
import Semelle from './Semelle';
import Dates from './Dates';

/**
 * Check if concession is inside graveyard concessions
 *
 * @param {string} concession concession selected
 * @param {array} graveyardConcessions concessions in a graveyard
 * @returns {boolean}
 */
export function isConcessionInGraveyardConcessions(
  concession,
  graveyardConcessions,
) {
  switch (concession) {
    case VAULT:
    case VAULT_FRONT_DOOR:
    case VAULT_FRONT_DOOR_UNDERGROUND:
      return (
        concession in graveyardConcessions ||
        PLAIN_EARTH in graveyardConcessions
      );
    default:
      return concession in graveyardConcessions;
  }
}

/**
 * Check if concessionType exists in the Graveyard
 *
 * @param {string} concessionType a concession type
 * @param {Array} graveyardConcessions Hash of graveyard's concession_types
 * @returns {boolean}
 */
export function concessionTypeExistsInGraveyardConcessionTypes(
  concessionType,
  graveyardConcessionTypes,
) {
  const concessionTypes = graveyardConcessionTypes.map(
    concession => concession.concession_type,
  );
  return concessionType in concessionTypes;
}

/**
 * Given a concession will return if a concession can exists in a graveyard
 *
 * @param {Object} concession The concession to check
 * @param {Array} graveyardConcessions Array of concessions (to buy)
 * @param {Array} graveyardConcessionTypes Array of concessions hash concession_types
 * @returns {boolean}
 */
export function concessionExistsInGraveyard(
  concession,
  graveyardConcessions,
  graveyardConcessionTypes,
) {
  if (concession && concession.exists) {
    return concessionTypeExistsInGraveyardConcessionTypes(
      concession.type.name,
      graveyardConcessionTypes,
    );
  }
  // Concesison do not exists lets check the exact same location is available
  return isConcessionInGraveyardConcessions(
    concession.type.name,
    graveyardConcessions,
  );
}

export class ConcessionSelection extends Component {
  componentDidUpdate(prevProps) {
    const {
      concession,
      graveyardConcessions,
      handleChange,
      graveyardConcessionTypes,
      location,
    } = this.props;
    if (
      !sameLocation(prevProps.location, location) &&
      !concessionExistsInGraveyard(
        concession,
        graveyardConcessions,
        graveyardConcessionTypes,
      )
    ) {
      handleChange({
        concession: {
          ...concession,
          type: {
            ...concession.type,
            name: UNKNOWN,
          },
        },
      });
    }
  }

  /**
   * Update the concession
   *
   * @param   {Object}    updatedObject   New value of concession
   */
  handleConcessionChange = updatedObject => {
    const { handleChange, concession } = this.props;
    handleChange({
      concession: {
        ...concession,
        ...updatedObject,
      },
    });
  };

  /**
   * Update the burieds section
   *
   * @param   {Array}    updatedArray   New value for burieds
   */
  handleBuriedsChange = updatedArray => {
    this.handleConcessionChange({
      burieds: updatedArray,
    });
  };

  /**
   * Update the exists key in concession object
   *
   * @param   {Boolean}   checked   New value of exists
   */
  onChangeConcessionExist = checked => {
    this.handleConcessionChange({
      exists: checked,
    });
  };

  render() {
    const {
      concession,
      location,
      handleChange,
      isUrn,
      graveyardConcessions,
      graveyardConcessionTypes,
      isFamilyBuyingConcession,
      getDefunctConcession,
      wishes,
      findWish,
      updateWish,
    } = this.props;

    return (
      <div className="dealFuneral__space">
        <div className="step__title">
          <div className="steps__header">
            <h3>
              <FormattedMessage {...messages.sepulture} />
            </h3>
          </div>
        </div>
        <div className="step__content">
          <div className="concessionSelection">
            <h4>
              <FormattedMessage {...messages.concession} />
            </h4>
            {!isFamilyBuyingConcession && (
              <CheckboxLabel
                message={messages.concessionExists}
                updateCheckbox={() =>
                  this.onChangeConcessionExist(!concession.exists)
                }
                checkboxValue={concession.exists}
              />
            )}
            {!concession.exists && (
              <CheckboxWishes
                wishType={WANT_FAMILY_TO_BUY_CONCESSION}
                message={messagesWishes.buyConcessionFamily}
                wishes={wishes}
                findWish={findWish}
                updateWish={updateWish}
              />
            )}
            <div className="concessionSelection__container">
              <div className="concessionSelection__container__column">
                <Place
                  concession={concession}
                  updateConcession={this.handleConcessionChange}
                  getDefunctConcession={getDefunctConcession}
                  graveyardId={location && location.id}
                />
                <Concession
                  concession={concession}
                  updateConcession={this.handleConcessionChange}
                  handleChange={handleChange}
                  locationId={location && location.id}
                  isUrn={isUrn}
                  graveyardConcessions={graveyardConcessions}
                  graveyardConcessionTypes={graveyardConcessionTypes}
                />
                <Dates
                  concession={concession}
                  updateConcession={this.handleConcessionChange}
                  graveyardConcessions={graveyardConcessions}
                  handleChange={handleChange}
                  isFamilyBuyingConcession={isFamilyBuyingConcession}
                />
                {isMonument(concession.type.name) && (
                  <Semelle
                    concession={concession}
                    updateConcession={this.handleConcessionChange}
                  />
                )}
              </div>
              <div className="concessionSelection__container__column">
                <Buyer
                  buyer={concession.buyer}
                  updateConcession={this.handleConcessionChange}
                />
                <Rightholders
                  rightHolders={concession.rightHolders}
                  updateConcession={this.handleConcessionChange}
                />
                {concession.exists && (
                  <Burieds
                    burieds={concession.burieds}
                    setBurieds={this.handleBuriedsChange}
                    updateConcession={this.handleConcessionChange}
                    concession={concession}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConcessionSelection.propTypes = {
  /** Object which contains data of this step */
  location: PropTypes.object,
  /** Object which contains data of concession key in step */
  concession: PropTypes.object.isRequired,
  /** Function to update the step */
  handleChange: PropTypes.func.isRequired,
  /** Object which contains the function to internationalize the app */
  intl: PropTypes.object.isRequired,
  /** Array which contains the concessions types of graveyard selected */
  graveyardConcessionTypes: PropTypes.array.isRequired,
  /** Object which contains the concessions available in graveyard selected */
  graveyardConcessions: PropTypes.object.isRequired,
  /** step cremation exist */
  isUrn: PropTypes.bool,
  /** family want to buy the concession */
  isFamilyBuyingConcession: PropTypes.bool.isRequired,
  /** function to get concession from id */
  getDefunctConcession: PropTypes.func.isRequired,
  /** list of wishes */
  wishes: PropTypes.array.isRequired,
  /** function to find a wish */
  findWish: PropTypes.func.isRequired,
  /** function to update a wish */
  updateWish: PropTypes.func.isRequired,
};

export default ConcessionSelection;
