import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { DEALS_PATH, Url } from 'containers/App/constants';
import { Deal, DealParentType } from 'models/Deal';
import { Memorial } from 'models/Memorial';

import messages from './messages';

function isParentMemorial(
  _parent: Memorial | Deal,
  parentType: DealParentType,
): _parent is Memorial {
  return parentType === DealParentType.MEMORIAL;
}

interface ParentLinkProps {
  parentType: DealParentType | null;
  parent: Deal | Memorial | null;
}

function ParentLink({ parent, parentType }: ParentLinkProps): JSX.Element {
  const intl = useIntl();

  if (!parent || !parentType) {
    return <FormattedMessage {...messages.noLink} />;
  }

  if (isParentMemorial(parent, parentType)) {
    return (
      <>
        <FormattedMessage {...messages.linkedToMemorialText} />
        <a
          className="link--primary"
          href={`${Url.MEMORIAL}${parent.url}`}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage
            {...messages.linkedToMemorialLink}
            values={{ defunct: parent.defunct.formattedName(intl) }}
          />
        </a>
      </>
    );
  }

  const defunct = parent.defunct && parent.defunct.formattedName(intl);
  return (
    <>
      <FormattedMessage {...messages.linkedToDealText} />
      <Link className="link--blue" to={`${DEALS_PATH}/${parent.uuid}`}>
        <FormattedMessage {...messages.linkedToDealLink} values={{ defunct }} />
      </Link>
    </>
  );
}

export default ParentLink;
