import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EditableDocumentJSON } from '@advitam/api/models/Documents/Editable';
import { ApiError, SerializedApiError } from '@advitam/api';
import { assert } from 'lib/Assert';
import { uploadDocument } from 'containers/DocumentTemplateEditor/thunk';

import { CRUD_DOCUMENTS } from './constants';
import {
  fetchDocuments,
  initializeTemplateEditor,
  saveDocuments,
} from './thunk';

export interface AppStateSubset {
  [CRUD_DOCUMENTS]: State;
}

export interface State {
  documents: EditableDocumentJSON[];
  variables: string[];
  isLoading: boolean;
  isEditorLoading: boolean;
  error: SerializedApiError | null;
}

export const initialState: State = {
  documents: [],
  variables: [],
  isLoading: false,
  isEditorLoading: false,
  error: null,
};

interface SetDocumetnTemplatePayload {
  documentId: number;
  templateId: number;
}

const slice = createSlice({
  name: CRUD_DOCUMENTS,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDocumentTemplate: (
      state,
      { payload }: PayloadAction<SetDocumetnTemplatePayload>,
    ): void => {
      const idx = state.documents.findIndex(d => d.id === payload.documentId);
      assert(idx !== -1);
      state.documents[idx].template_id = payload.templateId;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDocuments.pending, state => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(fetchDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchDocuments.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
      state.isLoading = false;
    });

    builder.addCase(initializeTemplateEditor.pending, state => {
      state.isEditorLoading = true;
    });
    builder.addCase(
      initializeTemplateEditor.fulfilled,
      (state, { payload }) => {
        state.variables = payload.variables;
        state.isEditorLoading = false;
      },
    );
    builder.addCase(initializeTemplateEditor.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
      state.isEditorLoading = false;
    });

    builder.addCase(uploadDocument.pending, state => {
      state.isEditorLoading = true;
    });
    builder.addCase(uploadDocument.fulfilled, state => {
      state.isEditorLoading = false;
    });
    builder.addCase(uploadDocument.rejected, state => {
      state.isEditorLoading = false;
    });

    builder.addCase(saveDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload;
    });
    builder.addCase(saveDocuments.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { setDocumentTemplate } = slice.actions;
export default slice;
