import style from '../Pagination.module.scss'

export default function Dots(): JSX.Element {
  return (
    <div className={style.dots}>
      <span />
      <span />
      <span />
    </div>
  )
}
