import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@advitam/ui';
import Female from '@advitam/ui/images/illustrations/female.svg';

import DefunctParent from './DefunctParent';
import messages from './messages';
import style from './Form.module.scss';

export default function DefunctMother(): JSX.Element {
  return (
    <DefunctParent
      prefix="mother"
      pictogram={
        <Tooltip
          contentClassName={style.section_pictogram}
          content={<FormattedMessage id={messages.motherTooltip.id} />}
        >
          <Female />
        </Tooltip>
      }
    />
  );
}
