import { ReactElement, ReactNode } from 'react';
import { Button, Tooltip } from '@advitam/ui';
import style from './Action.module.scss';

interface ActionProps {
  icon: ReactElement;
  message: ReactNode;
  disabled?: boolean;
  onClick: () => void;
}

export default function Action({
  icon,
  message,
  disabled,
  onClick,
}: ActionProps): JSX.Element {
  return (
    <Tooltip content={message}>
      <Button
        outline
        disabled={disabled}
        icon={icon}
        onClick={onClick}
        className={style.action}
      />
    </Tooltip>
  );
}
