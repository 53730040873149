import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from '@advitam/support';
import { Path } from '@advitam/ui';
import { Url } from 'containers/App/constants';

import { makeSelectRawClient } from '../../../selectors';
import { AppStateSubset as ClientAppStateSubset } from '../../../slice';
import { PASSWORD_RESET_MODAL } from './constants';

export const resetPassword = createAsyncThunk(
  `${PASSWORD_RESET_MODAL}/RESET_PASSWORD`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as ClientAppStateSubset;
    const client = makeSelectRawClient()(state);
    assert(client !== null && client.email !== null);

    try {
      await request(
        Api.V1.Auth.Password.create(
          client.email,
          `${Url.ADVITAM_WEBSITE}${Path.UPDATE_PASSWORD}`,
        ),
      );
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
