export const MAP = 'Map';

export const DEFAULT_CENTER: google.maps.LatLng | google.maps.LatLngLiteral = {
  lat: 47.081012,
  lng: 2.398781999999983,
};

export const DEFAULT_NEAR_RADIUS = 30;
export const NEAR_RADIUS_INCREASE_STEP = 10;
export const DEFAULT_ZOOM = 7;
