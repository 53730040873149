export const enum LocationType {
  AIRPORT = 'Airport',
  CITYHALL = 'Cityhall',
  CONSULATE = 'Consulate',
  CREMATORIUM = 'Crematorium',
  FUNERAL_PARLOR = 'FuneralParlor',
  GRAVEYARD = 'Graveyard',
  HEALTH_FACILITY = 'HealthFacility',
  HOSPITAL = 'Hospital',
  POLICE = 'Police',
  PREFECTURE = 'Prefecture',
  PUBLIC_LOCATION = 'PublicLocation',
  REGIONAL_HEALTH_AUTHORITY = 'RegionalHealthAuthority',
  SUPPLIER_WAREHOUSE = 'SupplierWarehouse',
  WORSHIP = 'Worship',
}
