import { useSelector } from 'react-redux';

import { Tooltip } from '@advitam/ui';

import {
  makeSelectFuneralTeamUserDisplayName,
  makeSelectFuneralTeamUserImage,
  makeSelectFuneralTeamUserInitials,
} from 'slices/data';

import style from './style.scss';

interface BusinessUserBadgeProps {
  userId: number | null | undefined;
  dark?: boolean;
  border?: boolean;
  fade?: boolean;
  statusText?: string;
}

export default function BusinessUserBadge({
  userId,
  dark = false,
  border = false,
  fade = false,
  statusText,
}: BusinessUserBadgeProps): JSX.Element {
  const initials = useSelector(makeSelectFuneralTeamUserInitials(userId));
  const displayName = useSelector(makeSelectFuneralTeamUserDisplayName(userId));
  const image = useSelector(makeSelectFuneralTeamUserImage(userId));

  const tooltip = `${displayName || ''}${statusText ? ` (${statusText})` : ''}`;
  const classes = [
    style.advisor,
    dark && style.dark,
    border && style.border,
    fade && style.fade,
    !image && style.initials,
  ].filter(Boolean);

  return (
    <Tooltip content={tooltip} contentClassName={classes.join(' ')}>
      {image ? <img src={image} alt={initials || ''} /> : initials}
    </Tooltip>
  );
}
