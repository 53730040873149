import { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * InnerBar atom
 */
class InnerBar extends PureComponent {
  render() {
    const { width, className } = this.props;
    return <div style={{ width: `${width}%` }} className={className} />;
  }
}

InnerBar.propTypes = {
  /** Width of inner bar */
  width: PropTypes.number,
  /** style of inner bar */
  className: PropTypes.string,
};

InnerBar.defaultProps = {
  width: 0,
  className: 'progressBar__innerBar',
};

export default InnerBar;
