import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { TodoItem, TodoItemJSON } from '@advitam/api/models/Deal/TodoItem';

import { assert } from 'lib/Assert';
import { SharingSpaceUrl } from 'utils/urls';

import { AppStateSubset as DealAppStateSubset } from '../slice';
import { makeSelectDeal } from '../selectors.typed';
import { TODO_LIST } from './constants';
import { AppStateSubset as TodolistAppStateSubset } from './slice';

type AppStateSubset = DealAppStateSubset & TodolistAppStateSubset;

export const createItem = createAsyncThunk(
  `${TODO_LIST}/CREATE_ITEM`,
  async (title: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AppStateSubset;
      const deal = makeSelectDeal()(state);
      assert(deal?.uuid !== undefined);

      const { body } = await request(
        Api.V1.Deals.TodoItems.create(deal.uuid, title),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateItem = createAsyncThunk(
  `${TODO_LIST}/UPDATE_ITEM`,
  async (item: TodoItemJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Deals.TodoItems.update(item));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteItem = createAsyncThunk(
  `${TODO_LIST}/DELETE_ITEM`,
  async (item: TodoItem, { rejectWithValue }) => {
    try {
      await request(Api.V1.Deals.TodoItems.destroy(item.id));
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createAccount = createAsyncThunk(
  `${TODO_LIST}/CREATE_ACCOUNT`,
  async (_payload: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    const dealUrl = [SharingSpaceUrl.DEAL, deal.id].join('/');
    const redirectUrl = SharingSpaceUrl.CREATE_ACCOUNT;

    try {
      await request(
        Api.V1.Deals.Welcome.create(deal.uuid, dealUrl, redirectUrl),
      );
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
