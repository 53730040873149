import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { withSlice } from '@advitam/react';
import { EditableDocumentJSON } from '@advitam/api/models/Documents/Editable';
import { PolymorphicEntity } from '@advitam/api/models/PolymorphicEntity';
import { LocationType } from '@advitam/api/models/LocationType';
import Layout from 'containers/Crud/Layout';
import {
  EditableDocuments,
  editableDocumentsSlice,
  makeSelectEditableDocuments,
  saveEditableDocuments,
} from 'containers/Crud';
import { assert } from 'lib/support';

import Wrapper from './Wrapper';
import { makeSelectRawPrefecture } from '../selectors';
import { PrefectureForm } from './types';
import messages from './messages';

function EditableDocumentsSection(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const prefecture = useSelector(makeSelectRawPrefecture());
  assert(prefecture?.id !== undefined);
  const documents = useSelector(makeSelectEditableDocuments());

  const entity: PolymorphicEntity = {
    type: LocationType.PREFECTURE,
    id: prefecture.id,
  };

  const onSubmit = useCallback(
    ({ sectionValues }: PrefectureForm<EditableDocumentJSON[]>) => {
      dispatch(saveEditableDocuments({ values: sectionValues, entity }));
    },
    [entity.type, entity.id],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={documents}>
      <Layout.Container>
        <EditableDocuments
          entity={entity}
          entityRules={[
            {
              name: intl.formatMessage(messages.deathPrefecture),
              value: 'id-death_pref',
            },
            {
              name: intl.formatMessage(messages.cremationPrefecture),
              value: 'id-crema_pref',
            },
            {
              name: intl.formatMessage(messages.inhumationPrefecture),
              value: 'id-inhum_pref',
            },
            {
              name: intl.formatMessage(messages.closingPrefecture),
              value: 'id-closing_pref',
            },
          ]}
        />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(editableDocumentsSlice)(EditableDocumentsSection);
