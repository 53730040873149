import { authenticate } from '../lib/decorators'
import { post } from '../lib/methods'
import type { ApiRequestDescriptor } from '../lib/request'

import type { LeadJSON } from '../models/Lead'

export class Leads {
  @authenticate
  static create(lead: LeadJSON): ApiRequestDescriptor<void> {
    return post(`/api/v1/leads`, lead)
  }
}
