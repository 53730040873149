import type { ReactNode } from 'react'

import style from '../Menu.module.scss'

interface MenuButtonProps {
  text: ReactNode
  onClick: () => void
}

export default function MenuButton({ text, onClick }: MenuButtonProps): JSX.Element {
  return (
    <button type="button" className={style.link} onClick={onClick}>
      <span className={style.link_text}>{text}</span>
    </button>
  )
}
