import { useCallback, MouseEvent } from 'react';
import { FormattedDate } from 'react-intl';

import { IndexEntity } from '@advitam/api/models/Entity/Index';
import { CroppedText, Link, ResourceList } from '@advitam/ui';
import { EntityType } from '@advitam/api/models/Entity/EntityType';
import { LocationType } from '@advitam/api/models/LocationType';
import { Path } from 'containers/App/constants';

import EntityIcon from './EntityIcon';
import style from '../Entities.module.scss';

const PLACE_CRUD_URL: Record<EntityType, string | null> = {
  [LocationType.AIRPORT]: null,
  [LocationType.CREMATORIUM]: Path.CREMATORIUMS,
  [LocationType.FUNERAL_PARLOR]: Path.FUNERAL_PARLORS,
  [LocationType.WORSHIP]: Path.WORSHIPS,
  [LocationType.POLICE]: Path.POLICE,
  [LocationType.CITYHALL]: Path.CITYHALLS,
  [LocationType.REGIONAL_HEALTH_AUTHORITY]: Path.RHAS,
  [LocationType.GRAVEYARD]: Path.GRAVEYARDS,
  [LocationType.HOSPITAL]: Path.HOSPITALS,
  [LocationType.PREFECTURE]: Path.PREFECTURES,
  [LocationType.CONSULATE]: Path.CONSULATES,
};

interface RowProps {
  entity: IndexEntity;
}

export default function Row({ entity }: RowProps): JSX.Element {
  const onEmailClick = useCallback((event: MouseEvent): void => {
    event.stopPropagation();
  }, []);
  const link =
    entity.entity_type === LocationType.AIRPORT
      ? {
          href: Path.FLIGHTS,
          internal: true,
          state: {
            filters: {
              departure_airport: {
                id: entity.id,
                name: entity.name,
                description: entity.name,
                iata: '',
              },
            },
          },
        }
      : {
          href: [PLACE_CRUD_URL[entity.entity_type], entity.id].join('/'),
          internal: true,
        };

  return (
    <ResourceList.Row link={link} className={style.row}>
      <ResourceList.Cell className={style.type}>
        <EntityIcon entityType={entity.entity_type} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.name}>
        <CroppedText>{entity.name}</CroppedText>
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.city}>
        <CroppedText>{entity.city}</CroppedText>
      </ResourceList.Cell>
      <ResourceList.Cell className={style.postal_code}>
        {entity.postal_code}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile>{entity.phone}</ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.email}>
        <Link newTab href={`mailto:${entity.email}`} onClick={onEmailClick}>
          <CroppedText>{entity.email}</CroppedText>
        </Link>
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.updated_at}>
        {entity.updated_at && <FormattedDate value={entity.updated_at} />}
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
