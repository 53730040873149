export default function flatten(
  obj: Record<string, unknown>,
): Record<string, unknown> {
  const toReturn: Record<string, unknown> = {};

  Object.entries(obj).forEach(([i, object]) => {
    if (typeof object === 'object' && object !== null) {
      const flatObject = flatten(object as Record<string, unknown>);

      Object.keys(flatObject).forEach(x => {
        // Make a bracket array notation like some[1].array[0]
        const key = `${i}.${x}`.split('.').reduce((str, value) => {
          if (/^\[\d\]/.test(value)) {
            return `${str}${value}`;
          }

          if (!Number.isNaN(Number(value))) {
            return `${str}[${value}]`;
          }

          if (str) {
            return `${str}.${value}`;
          }

          return value;
        }, '');

        toReturn[key] = flatObject[x];
      });
    } else {
      toReturn[i] = object;
    }
  });

  return toReturn;
}
