import PropTypes from 'prop-types';

const LockIcon = ({ isActivated, className }) => (
  <svg
    viewBox="257.2 74.4 327.4 445.5"
    className={`${isActivated ? 'icon--on' : 'icon--off'} ${className}`}
  >
    <g fill="#1B4C51">
      <path d="M554 266.9h-28v-59.5c0-57.7-47.4-105.1-105.1-105.1-57.7 0-105.1 47.4-105.1 105.1v59.5h-27.9v-59.5c0-73.5 59.5-133 133-133s133 59.5 133 133l.1 59.5" />
      <path d="M540 519.9H301.9c-24.2 0-44.6-20.5-44.6-44.6V296.7c0-24.2 20.5-44.6 44.6-44.6H540c24.2 0 44.6 20.5 44.6 44.6v178.6c0 25.1-20.4 44.6-44.6 44.6zM301.9 281.8c-8.4 0-14.9 6.5-14.9 14.9v178.6c0 8.4 6.5 14.9 14.9 14.9H540c8.4 0 14.9-6.5 14.9-14.9V296.7c0-8.4-6.5-14.9-14.9-14.9H301.9z" />
      <path d="M450.7 341.4c0 16.7-13 29.8-29.8 29.8s-29.8-13-29.8-29.8 13-29.8 29.8-29.8 29.8 13.9 29.8 29.8" />
      <path d="M428.4 341.4h-14.9l-14.9 89.2h44.7l-14.9-89.2" />
    </g>
  </svg>
);

LockIcon.propTypes = {
  isActivated: PropTypes.bool,
  className: PropTypes.string,
};

export default LockIcon;
