import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ManualSelectionUpdate from './ManualSelectionUpdate.js';
import messages from './messages';
import { makeSelectCanSaveDeal } from './selectors';

import style from './style.scss';

interface UpdateBannerProps {
  manualSelection: boolean;
  querySaveDeal: () => void;
  dealIsDirty: boolean | string;
  isNewDeal: boolean;
}

export default function UpdateBanner({
  manualSelection,
  querySaveDeal,
  dealIsDirty,
  isNewDeal,
}: UpdateBannerProps): JSX.Element {
  const canSave = useSelector(makeSelectCanSaveDeal());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSaveDeal = (): void => {
    if (manualSelection) {
      setIsModalOpen(true);
    } else {
      querySaveDeal();
    }
  };

  const isOpen = (dealIsDirty || isNewDeal) && canSave;
  return (
    <div className={`${style.wrapper} ${isOpen ? style.opened : ''}`}>
      {!isNewDeal && typeof dealIsDirty === 'string' && (
        <FormattedMessage
          id={messages.lastUpdate.id}
          values={{ lastUpdate: dealIsDirty }}
        />
      )}
      <button
        type="button"
        className={style['save-button']}
        onClick={onSaveDeal}
      >
        <FormattedMessage id={messages.save.id} />
      </button>
      {isModalOpen && (
        <ManualSelectionUpdate
          querySaveDeal={querySaveDeal}
          isModalOpen={isModalOpen}
          closeModal={(): void => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}

UpdateBanner.propTypes = {
  isNewDeal: PropTypes.bool.isRequired,
  manualSelection: PropTypes.bool,
  dealIsDirty: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  querySaveDeal: PropTypes.func.isRequired,
};
