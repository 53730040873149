import { useMemo } from 'react';
import { useForm } from 'react-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Memorial } from 'models/Memorial';

import appMessages from 'messages/actions';
import { UrlInput } from 'components/ReactFormComponent';
import { ButtonV2 } from 'components/Button';
import PageSpinner from 'components/Spinner/PageSpinner';
import { withSlice } from 'utils/injectReducer';

import SectionWrapper from '../SectionWrapper';
import { makeSelectIsLoading } from './selectors';
import { updateUrl, toggleDonation } from './thunk';
import messages from './messages';
import slice from './slice';

interface DonationProps {
  memorial: Memorial;
}

function Donation({ memorial }: DonationProps): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsLoading());

  const { Form, handleSubmit } = useForm({
    onSubmit: (values: Partial<Memorial>): void => {
      dispatch(updateUrl(values.association_url));
    },
    defaultValues: useMemo(() => memorial, [memorial.id]),
  });

  return (
    <SectionWrapper
      title={messages.title}
      isSwitchable
      switched={memorial.associations_enabled}
      onSwitch={(value: boolean): void => {
        dispatch(toggleDonation(value));
      }}
    >
      {isLoading ? (
        <PageSpinner />
      ) : (
        <Form>
          <UrlInput
            field="association_url"
            message={messages.subtitle}
            placeholderMessage={messages.inputPlaceholder}
          />
          <ButtonV2 onClick={handleSubmit}>
            <FormattedMessage id={appMessages.save.id} />
          </ButtonV2>
        </Form>
      )}
    </SectionWrapper>
  );
}

export default withSlice(slice)(Donation);
