import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { PageSpinner } from '@advitam/ui';
import ErrorBanner from 'components/ErrorBanner';

import { saveFuneralParlor } from '../../thunk';
import Wrapper from '../Wrapper';
import { FuneralParlorForm } from '../types';
import slice from './slice';
import { fetchStays } from './thunk';
import { makeSelectError, makeSelectIsLoading } from './selectors';
import Contents from './Contents';

function Miscellaneous(): JSX.Element {
  const dispatch = useDispatch();

  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  useEffect(() => {
    dispatch(fetchStays());
  }, []);

  const onSubmit = useCallback(
    (values: FuneralParlorForm): void => {
      dispatch(saveFuneralParlor(values.funeralParlor));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      {isLoading ? <PageSpinner /> : <Contents />}
      {error && <ErrorBanner errors={error} />}
    </Wrapper>
  );
}

export default withSlice(slice)(Miscellaneous);
