import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  functioning: {
    id: 'app.containers.Hospital.sections.Miscellaneous.functioning',
  },
  cleaning: {
    id: 'app.containers.Hospital.sections.Miscellaneous.cleaning',
  },
  cleaningTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.cleaningTooltip',
  },
  casketing: {
    id: 'app.containers.Hospital.sections.Miscellaneous.casketing',
  },
  casketingTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.casketingTooltip',
  },
  supplyShroud: {
    id: 'app.containers.Hospital.sections.Miscellaneous.supplyShroud',
  },
  supplyShroudTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.supplyShroudTooltip',
  },
  coffinDelivery: {
    id: 'app.containers.Hospital.sections.Miscellaneous.coffinDelivery',
  },
  coffinDeliveryTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.coffinDeliveryTooltip',
  },
  paperHold: {
    id: 'app.containers.Hospital.sections.Miscellaneous.paperHold',
  },
  paperHoldTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.paperHoldTooltip',
  },
  nbHolders: {
    id: 'app.containers.Hospital.sections.Miscellaneous.nbHolders',
  },
  nbHoldersTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.nbHoldersTooltip',
  },
  mortuary: {
    id: 'app.containers.Hospital.sections.Miscellaneous.mortuary',
  },
  mortuaryTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.mortuaryTooltip',
  },
  agreementLocationType: {
    id: 'app.containers.Hospital.sections.Miscellaneous.agreementLocationType',
  },
  agreementLocationTypeTooltip: {
    id:
      'app.containers.Hospital.sections.Miscellaneous.agreementLocationTypeTooltip',
  },
  agreementLocationTypeHospital: {
    id:
      'app.containers.Hospital.sections.Miscellaneous.agreementLocationTypeHospital',
  },
  agreementLocationTypeFuneralParlor: {
    id:
      'app.containers.Hospital.sections.Miscellaneous.agreementLocationTypeFuneralParlor',
  },
  agreementLocationTypeOther: {
    id:
      'app.containers.Hospital.sections.Miscellaneous.agreementLocationTypeOther',
  },
  agreementLocationId: {
    id: 'app.containers.Hospital.sections.Miscellaneous.agreementLocationId',
  },
  agreementLocationIdTooltip: {
    id:
      'app.containers.Hospital.sections.Miscellaneous.agreementLocationIdTooltip',
  },

  billing: {
    id: 'app.containers.Hospital.sections.Miscellaneous.billing',
  },
  billingTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.billingTooltip',
  },
  casketingFee: {
    id: 'app.containers.Hospital.sections.Miscellaneous.casketingFee',
  },
  casketingFeeTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.casketingFeeTooltip',
  },
  labFee: {
    id: 'app.containers.Hospital.sections.Miscellaneous.labFee',
  },
  labFeeTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.labFeeTooltip',
  },
  baseFee: {
    id: 'app.containers.Hospital.sections.Miscellaneous.baseFee',
  },
  baseFeeTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.baseFeeTooltip',
  },
  baseDays: {
    id: 'app.containers.Hospital.sections.Miscellaneous.baseDays',
  },
  baseDaysTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.baseDaysTooltip',
  },
  costPerDay: {
    id: 'app.containers.Hospital.sections.Miscellaneous.costPerDay',
  },
  costPerDayTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.costPerDayTooltip',
  },
  vatRate: {
    id: 'app.containers.Hospital.sections.Miscellaneous.vatRate',
  },
  vatRateTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.vatRateTooltip',
  },
  vatRate0: {
    id: 'app.containers.Hospital.sections.Miscellaneous.vatRate0',
  },
  vatRate5dot5: {
    id: 'app.containers.Hospital.sections.Miscellaneous.vatRate5dot5',
  },
  vatRate10: {
    id: 'app.containers.Hospital.sections.Miscellaneous.vatRate10',
  },
  vatRate20: {
    id: 'app.containers.Hospital.sections.Miscellaneous.vatRate20',
  },
  baseFeeFamily: {
    id: 'app.containers.Hospital.sections.Miscellaneous.baseFeeFamily',
  },
  baseFeeFamilyTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.baseFeeFamilyTooltip',
  },
  baseDaysFamily: {
    id: 'app.containers.Hospital.sections.Miscellaneous.baseDaysFamily',
  },
  baseDaysFamilyTooltip: {
    id: 'app.containers.Hospital.sections.Miscellaneous.baseDaysFamilyTooltip',
  },
  costPerDayFamily: {
    id: 'app.containers.Hospital.sections.Miscellaneous.costPerDayFamily',
  },
  costPerDayFamilyTooltip: {
    id:
      'app.containers.Hospital.sections.Miscellaneous.costPerDayFamilyTooltip',
  },
});
