import { createSelector, Selector } from 'reselect';
import { SerializedApiError } from '@advitam/api';
import { UPDATE_PASSWORD } from './constants';
import type { AppStateSubset, State } from './slice';

type UpdatePasswordSelector<T> = Selector<AppStateSubset, T>;

const selectUpdatePasswordDomain = (state: AppStateSubset): State =>
  state[UPDATE_PASSWORD];

export const makeSelectIsLoading = (): UpdatePasswordSelector<boolean> =>
  createSelector(selectUpdatePasswordDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): UpdatePasswordSelector<SerializedApiError | null> =>
  createSelector(selectUpdatePasswordDomain, ({ error }) => error);
