import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { CeremonyJSON } from '@advitam/api/models/Crematorium/Ceremony';
import { ConfirmationModal, HardSpinner, PageSpinner } from '@advitam/ui';
import ErrorBanner from 'components/ErrorBanner';
import { Layout, addCollectionItem } from 'containers/Crud';

import { assert } from 'lib/support';
import Ceremony from './List/Ceremony';
import Filters from './List/Filters';
import { initialize } from './thunk';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
} from './selectors';
import messages from './messages';
import { CrematoriumForm } from '../types';

const SECTION_PREFIX = 'sectionValues';

export default function Ceremonies(): JSX.Element {
  const dispatch = useDispatch();

  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());

  const [indexOfCeremonyToDelete, setIndexOfCeremonyToDelete] = useState<
    number | null
  >(null);
  const gridContainer = useRef<HTMLDivElement>(null);

  const form = useForm<CrematoriumForm<CeremonyJSON[]>>();
  const { values } = useFormState<CrematoriumForm<CeremonyJSON[]>>();
  const ceremonies = values.sectionValues;

  const closeDeletionConfirmation = useCallback(() => {
    setIndexOfCeremonyToDelete(null);
  }, [setIndexOfCeremonyToDelete]);

  const onDeletionConfirmed = useCallback(() => {
    form.change(
      'sectionValues',
      ceremonies.filter((_, index) => index !== indexOfCeremonyToDelete),
    );
    closeDeletionConfirmation();
  }, [closeDeletionConfirmation, form, ceremonies, indexOfCeremonyToDelete]);

  useEffect(() => {
    dispatch(initialize());
  }, []);

  const onDuplicate = (ceremonyIndex: number): void => {
    assert(gridContainer.current !== null);
    addCollectionItem(
      form,
      SECTION_PREFIX,
      {
        ...ceremonies[ceremonyIndex],
        id: undefined,
      },
      gridContainer.current,
    );
  };

  return (
    <>
      <Layout.ColumnGroup>
        <Layout.Column>
          <Filters gridContainer={gridContainer} />
        </Layout.Column>
      </Layout.ColumnGroup>
      {isSaving && <HardSpinner />}
      <Field name="sectionValues">
        {(): JSX.Element =>
          isLoading ? (
            <PageSpinner />
          ) : (
            <Layout.FieldsetGrid ref={gridContainer}>
              {ceremonies.map((ceremony, index) => (
                <Ceremony
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}-${ceremony.id}`}
                  ceremony={ceremony}
                  prefix={`sectionValues[${index}]`}
                  onDelete={(): void => setIndexOfCeremonyToDelete(index)}
                  onDuplicate={(): void => onDuplicate(index)}
                />
              ))}
            </Layout.FieldsetGrid>
          )
        }
      </Field>

      {indexOfCeremonyToDelete !== null && (
        <ConfirmationModal
          isOpen
          text={
            <FormattedMessage
              id={messages.deletionConfirmationText.id}
              values={{
                title: ceremonies[indexOfCeremonyToDelete]?.title,
              }}
            />
          }
          confirm={
            <FormattedMessage id={messages.deletionConfirmationConfirm.id} />
          }
          onConfirm={onDeletionConfirmed}
          cancel={
            <FormattedMessage id={messages.deletionConfirmationCancel.id} />
          }
          onCancel={closeDeletionConfirmation}
        />
      )}

      {error && <ErrorBanner errors={error} />}
    </>
  );
}
