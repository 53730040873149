import { Objects } from '@advitam/support'
import { ConcessionTypePayload, GraveyardPayload } from './types'

type ApiGraveyardPayload = Omit<GraveyardPayload, 'cityhalls' | 'country'> & {
  cityhall_ids?: number[]
  country_code?: string | null
  headquarter_country_code?: string | null
  concession_semelle_type_id?: number
  default_graveyards_concession_type_id?: number
}

export function serialize(graveyard: GraveyardPayload): ApiGraveyardPayload {
  let countryCode: string | null | undefined
  let headquarterCountryCode: string | null | undefined

  if (graveyard) {
    countryCode = graveyard.country?.code || null
    headquarterCountryCode = graveyard.headquarter_country?.code || null
  }

  return {
    ...Objects.omit(
      graveyard,
      'country',
      'cityhalls',
      'concession_semelle',
      'default_concession_type',
    ),
    cityhall_ids: graveyard.cityhalls?.map(cityhall => cityhall.id),
    concession_semelle_type_id: graveyard.concession_semelle?.id,
    country_code: countryCode,
    default_graveyards_concession_type_id: graveyard.default_concession_type?.id,
    headquarter_country_code: headquarterCountryCode,
  }
}

interface ApiConcessionTypePayload {
  concession_type_id: number
}

export function serializeConcessionType(
  concessionType: ConcessionTypePayload,
): ApiConcessionTypePayload {
  return {
    concession_type_id: concessionType.concession_type.id,
  }
}
