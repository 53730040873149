import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { TransparentButton } from 'components/Button';

import { Checkbox } from './index';

export const RIGHT_POSITION = 'right';
export const LEFT_POSITION = 'left';

/**
 * The checkbox and label can update the value of checkbox.
 */
export default class CheckboxLabel extends PureComponent {
  render() {
    const {
      position,
      message,
      updateCheckbox,
      checkboxValue,
      messageValues,
      classNameLabel,
    } = this.props;

    return (
      <TransparentButton onClick={updateCheckbox}>
        {position === LEFT_POSITION && (
          <span>
            <FormattedMessage {...message} values={messageValues} />
            &nbsp;
          </span>
        )}
        <Checkbox checked={!!checkboxValue} onChange={() => {}} />
        {position === RIGHT_POSITION && (
          <span className={classNameLabel}>
            &nbsp;
            <FormattedMessage {...message} values={messageValues} />
          </span>
        )}
      </TransparentButton>
    );
  }
}

CheckboxLabel.propTypes = {
  /** Label of checkbox translate with intl */
  message: PropTypes.object.isRequired,
  /** Function to update checkbox value */
  updateCheckbox: PropTypes.func.isRequired,
  /** String to indicate the position of label */
  position: PropTypes.oneOf([RIGHT_POSITION, LEFT_POSITION]),
  /** Value of checkbox */
  checkboxValue: PropTypes.bool,
  /** Values for FormattedMessage */
  messageValues: PropTypes.object,
  /** className label */
  classNameLabel: PropTypes.string,
};

CheckboxLabel.defaultProps = {
  position: RIGHT_POSITION,
  checkboxValue: false,
  messageValues: {},
  classNameLabel: '',
};
