export enum Civility {
  MR = 'mr',
  MRS = 'mrs',
}

export enum FormatType {
  /** format: M. Firstname Lastname */
  STANDARD,
  /** format: M. Firstname LASTNAME */
  CAPITALIZED_LASTNAME,
  /** format: M. Firstname L. */
  ANONIMIZED,
  /** format: M. Lastname */
  FORMAL,
}

export interface Nameable {
  civility?: Civility | null
  firstname: string | null
  lastname: string | null
}

export interface Country {
  name: string
}

export interface Addressable {
  address: string | null
  // eslint-disable-next-line camelcase
  postal_code: string | null
  city: string | null
  country?: Country | string | null
}
