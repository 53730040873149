import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { SupportingDocumentOwnerType } from '@advitam/api/models/SupportingDocuments/OwnerType';
import { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import { assert } from '@advitam/support';
import Layout from 'containers/Crud/Layout';
import {
  SupportingDocuments,
  supportingDocumentsSlice,
  makeSelectSupportingDocuments,
  saveSupportingDocuments,
} from 'containers/Crud';

import Wrapper from './Wrapper';
import { makeSelectRawHospital } from '../selectors';
import type { HospitalForm } from './types';

function SupportingDocumentsSection(): JSX.Element {
  const dispatch = useDispatch();

  const hospital = useSelector(makeSelectRawHospital());
  assert(hospital?.id !== undefined);
  const documents = useSelector(makeSelectSupportingDocuments());

  const owner = useMemo(
    () => ({ type: SupportingDocumentOwnerType.HOSPITAL, id: hospital.id }),
    [hospital.id],
  );
  const onSubmit = useCallback(
    ({ sectionValues }: HospitalForm<SupportingDocumentJSON[]>) => {
      dispatch(saveSupportingDocuments({ values: sectionValues, owner }));
    },
    [dispatch, owner],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={documents}>
      <Layout.Container>
        <SupportingDocuments owner={owner} />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(supportingDocumentsSlice)(SupportingDocumentsSection);
