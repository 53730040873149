/*
 *
 * AutoComplete constants
 *
 */

export const QUERY_ENTITIES_LIST = 'app/AutoComplete/QUERY_ENTITIES_LIST';
export const ERASE_ENTITIES_LIST = 'app/AutoComplete/ERASE_ENTITIES_LIST';
export const UPDATE_ENTITIES_LIST = 'app/AutoComplete/UPDATE_ENTITIES_LIST';
export const ERROR_ENTITIES_LIST = 'app/AutoComplete/ERROR_ENTITIES_LIST';
