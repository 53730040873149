import { defineMessages } from 'react-intl';

export default defineMessages({
  newVersionAvailable: {
    id: 'app.containers.App.NewVersion.newVersionAvailable',
  },
  refreshVersion: { id: 'app.containers.App.NewVersion.refreshVersion' },
  title: { id: 'app.containers.App.NewVersion.title' },
  cancel: { id: 'app.containers.App.NewVersion.cancel' },
});
