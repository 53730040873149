import { authenticate, withSessionId } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { FuneralParlorJSON } from '../../models/FuneralParlor'
import { Disabled } from './Disabled'
import { Stays } from './Stays'
import { serialize } from './serializers'
import { FuneralParlorBody } from './types'

export class FuneralParlors {
  static readonly Disabled = Disabled

  static readonly Stays = Stays

  @authenticate
  static show(id: number): ApiRequestDescriptor<FuneralParlorJSON> {
    return get(`/api/v1/funeral_parlors/${id}`)
  }

  @authenticate
  static create(
    funeralParlor: Omit<FuneralParlorBody, 'id'>,
  ): ApiRequestDescriptor<FuneralParlorJSON> {
    return post('/api/v1/funeral_parlors', serialize(funeralParlor))
  }

  @authenticate
  @withSessionId
  static update(funeralParlor: FuneralParlorBody): ApiRequestDescriptor<FuneralParlorJSON> {
    return put(`/api/v1/funeral_parlors/${funeralParlor.id}`, serialize(funeralParlor))
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<FuneralParlorJSON> {
    return del(`/api/v1/funeral_parlors/${id}`)
  }
}
