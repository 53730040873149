import { createSelector } from 'reselect';
import { makeSelectDeal } from 'containers/Deal/selectors';

const makeSelectServices = () =>
  createSelector(makeSelectDeal(), substate => {
    if (!substate) return null;
    return substate.funeral.services;
  });

const makeSelectStepServices = () =>
  createSelector(makeSelectServices(), substate => stepId => {
    if (!substate) return null;
    return substate.filter(s => s.step_id === stepId);
  });

const makeSelectService = () =>
  createSelector(makeSelectServices(), substate => stepId => serviceType => {
    if (!substate) return null;
    return substate.find(s => s.step_id === stepId && s.type === serviceType);
  });

export { makeSelectServices, makeSelectStepServices, makeSelectService };
