import { assert } from 'lib/Assert';
import { DragEvent, useRef, useState } from 'react';
import { createTransparentImage } from '../utils';

import style from './ImageEditor.module.scss';
import { getRotation } from './utils';

interface RotateOverlayProps {
  imageOrigin: [number, number];
  setAngle: (angle: number) => void;
}

export default function RotateOverlay({
  imageOrigin,
  setAngle,
}: RotateOverlayProps): JSX.Element {
  const overlay = useRef<HTMLDivElement>(null);
  const [angle, setStateAngle] = useState(0);
  const [dragOrigin, setDragOrigin] = useState<[number, number]>([0, 0]);
  const [rotation, setRotation] = useState(0);

  const getEventPosition = (ev: DragEvent): [number, number] => {
    assert(overlay.current !== null);
    const { top, left } = overlay.current.getBoundingClientRect();
    return [ev.pageX - left, ev.pageY - top];
  };

  const onDragStart = (ev: DragEvent): void => {
    ev.stopPropagation();
    ev.dataTransfer.setDragImage(createTransparentImage(), 0, 0);
    setDragOrigin(getEventPosition(ev));
  };

  const onDragOver = (ev: DragEvent): void => {
    ev.stopPropagation();
    const rot = getRotation(imageOrigin, dragOrigin, getEventPosition(ev));
    setRotation(rot);
    setAngle(angle + rot);
  };

  const onDragEnd = (): void => {
    setStateAngle(angle + rotation);
  };

  return (
    <div
      ref={overlay}
      draggable
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      className={[style.drag_overlay, style.rotate_overlay].join(' ')}
    />
  );
}
