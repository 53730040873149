import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { LinkButton, ResourceList } from '@advitam/ui';
import type {
  Filter,
  FilterSelection,
} from '@advitam/ui/components/ResourceList/Filters/types';

import { NEW_PATH, SUPPLIERS_PATH } from 'containers/App/constants';
import { makeSelectUser } from 'slices/auth';
import actionMessages from 'messages/actions';

import { PrestationType } from '../constants';
import messages from '../messages';
import { makeSelectDepartmentsOptions, makeSelectFilters } from '../selectors';
import style from '../Suppliers.module.scss';

interface FiltersProps {
  onChange: (filters: Record<string, FilterSelection>) => void;
}

export default function SuppliersFilters({
  onChange,
}: FiltersProps): JSX.Element {
  const intl = useIntl();
  const user = useSelector(makeSelectUser());
  const departmentsOptions = useSelector(makeSelectDepartmentsOptions());
  const filters = useSelector(makeSelectFilters());

  const filtersShape: Filter[] = [
    {
      type: 'Select',
      name: 'prestation_types_overlaps',
      placeholder: intl.formatMessage(messages.typeFilter),
      multiple: true,
      items: [
        {
          value: PrestationType.TRANSPORT,
          name: intl.formatMessage(messages.transportType),
        },
        {
          value: PrestationType.MARBLE,
          name: intl.formatMessage(messages.marbleType),
        },
        {
          value: PrestationType.PAPERWORKS,
          name: intl.formatMessage(messages.paperworksType),
        },
        {
          value: PrestationType.COFFINS,
          name: intl.formatMessage(messages.coffinsType),
        },
      ],
      initialValue: filters.prestation_types_overlaps,
    },
    {
      type: 'Select',
      name: 'department_eq',
      placeholder: intl.formatMessage(messages.departmentFilter),
      filter: true,
      items: departmentsOptions,
      initialValue: filters.department_eq,
    },
    {
      type: 'Select',
      name: 'negociated_eq',
      placeholder: intl.formatMessage(messages.negociatedFilter),
      items: [
        { value: true, name: intl.formatMessage(actionMessages.yes) },
        { value: false, name: intl.formatMessage(actionMessages.no) },
      ],
      isHidden: !user?.isFuneralDirectorOrAbove,
      initialValue: filters.negociated_eq,
    },
  ];

  return (
    <ResourceList.Filters
      onChange={onChange}
      filters={filtersShape}
      className={style.filters}
      searchFilter={{
        name: 'q',
        placeholder: intl.formatMessage(messages.search),
        initialValue: filters.q,
      }}
    >
      <LinkButton
        primary
        internal
        href={`${SUPPLIERS_PATH}/${NEW_PATH}`}
        text={<FormattedMessage id={messages.createSupplier.id} />}
      />
    </ResourceList.Filters>
  );
}
