import { CommunicationEntityType } from '@advitam/api/models/Deal/Communication/EntityType';
import { CommunicationChannelType } from '@advitam/api/models/Deal/Communication/ChannelType';

import { RecipientType } from './types';
import messages from './messages';

export const HISTORY = 'Deal/History';

export const EntityTypes = {
  [RecipientType.FAMILY]: [CommunicationEntityType.CLIENT],
  [RecipientType.SUPPLIER]: [
    CommunicationEntityType.SUPPLIER,
    CommunicationEntityType.SUPPLIER_WAREHOUSE,
  ],
  [RecipientType.ADMINISTRATION]: [
    CommunicationEntityType.CITYHALL,
    CommunicationEntityType.CONSULATE,
    CommunicationEntityType.GRAVEYARD,
    CommunicationEntityType.POLICE,
    CommunicationEntityType.PREFECTURE,
    CommunicationEntityType.REGIONAL_HEALTH_AUTHORITY,
  ],
  [RecipientType.THIRD_PARTY]: [
    CommunicationEntityType.CREMATORIUM,
    CommunicationEntityType.FUNERAL_PARLOR,
    CommunicationEntityType.HOSPITAL,
    CommunicationEntityType.WORSHIP,
  ],
};

export const MODAL_TITLE: Record<CommunicationChannelType, string> = {
  [CommunicationChannelType.EMAIL]: messages.emailModalTitle.id,
  [CommunicationChannelType.PHONE]: messages.phoneModalTitle.id,
  [CommunicationChannelType.FAX]: messages.faxModalTitle.id,
  [CommunicationChannelType.LETTER]: messages.letterModalTitle.id,
  [CommunicationChannelType.SMS]: messages.smsModalTitle.id,
};
