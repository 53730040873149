import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { BatchLogJSON } from '@advitam/api/models/Deal/Document/Batch/Log';

import { HISTORY } from './constants';
import { fetchBatchesLogs } from './thunk';

export interface State {
  batchesLogs: BatchLogJSON[];
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [HISTORY]: State;
}

export const initialState: State = {
  batchesLogs: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: HISTORY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchBatchesLogs.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchBatchesLogs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.batchesLogs = payload;
    });
    builder.addCase(fetchBatchesLogs.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
