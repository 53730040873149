import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { post, put, del } from 'api/methods';
import { Deals } from 'api/serializers/Deals';

import { assert } from 'lib/Assert';
import { AbilityBase } from 'models/Ability';
import { Client, ClientJSON } from 'models/Client';
import { Deal, DealJSON } from 'models/Deal';

export default class Abilities {
  @authenticate
  static create(
    deal: Deal | DealJSON,
    client: Client | ClientJSON,
  ): ApiRequestDescriptor<AbilityBase> {
    assert(deal.id !== undefined);
    return post(
      `/api/v1/deals/${deal.id}/abilities`,
      Deals.Abilities.serializeForCreation(client),
    );
  }

  @authenticate
  static update(client: Client): ApiRequestDescriptor<AbilityBase> {
    assert(client.ability_id !== undefined);
    return put(
      `/api/v1/deals/abilities/${client.ability_id}`,
      Deals.Abilities.serializeForUpdate(client),
    );
  }

  @authenticate
  static destroy(client: Client): ApiRequestDescriptor<void> {
    assert(client.ability_id !== undefined);
    return del(`/api/v1/deals/abilities/${client.ability_id}`);
  }
}
