import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.Deal.TodoList.header',
  },
  addItem: {
    id: 'app.containers.Deal.TodoList.addItem',
  },
});
