import { ReactNode } from 'react'

import style from '../Sticky.module.scss'

interface SubtitleProps {
  text: ReactNode
  totalPrice: ReactNode
}

export default function Subtitle({ text, totalPrice }: SubtitleProps): JSX.Element {
  return (
    <div className={style.subtitle}>
      {totalPrice}
      <div className={style.subtitle__text}>{text}</div>
    </div>
  )
}
