import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError } from 'api';
import { SummaryJSON } from 'models/Deal/Summary';
import { OpsLogJSON } from 'models/OpsLog';
import { PaymentSummaryJSON } from 'models/Payment/Summary';

import {
  DEALS_PER_PAGE,
  DEFAULT_PAID_EQ,
  DEFAULT_PROBLEM_FILTERS,
  DEFAULT_STATE_FILTERS,
  PAYMENTS,
} from './constants';
import { createOpsLog } from './parts/OpsLogs/thunk';
import { fetchDeals, fetchDealsOpsLogs, fetchDealsPayments } from './thunk';
import { Filters } from './types';

export interface State {
  deals: SummaryJSON[];
  payments: Record<string, PaymentSummaryJSON[]>;
  opsLogs: Record<string, OpsLogJSON[]>;
  filters: Filters;
  page: number;
  hasMore: boolean;
  isLoading: boolean;
  error: ApiError | null;
  lastRequestId: string | null;
}

export interface AppStateSubset {
  [PAYMENTS]: State;
}

export const initialState: State = {
  deals: [],
  payments: {},
  opsLogs: {},
  filters: {
    deals_state_key_in: DEFAULT_STATE_FILTERS,
    payment_problem_in: DEFAULT_PROBLEM_FILTERS,
    paid_eq: DEFAULT_PAID_EQ,
  },
  page: 1,
  hasMore: true,
  isLoading: false,
  error: null,
  lastRequestId: null,
};

const slice = createSlice({
  name: PAYMENTS,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    resetPaging(state): void {
      state.deals = [];
      state.page = 1;
      state.hasMore = true;
    },
    setFilters(state, { payload }: PayloadAction<Filters>): void {
      state.deals = [];
      state.page = 1;
      state.hasMore = true;
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    /* eslint-enable no-param-reassign */
  },

  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchDeals.pending, (state, { meta }) => {
      state.isLoading = true;
      state.lastRequestId = meta.requestId;
    });
    builder.addCase(fetchDeals.fulfilled, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }
      state.isLoading = false;
      state.deals = [...state.deals, ...payload];
      state.hasMore = payload.length === DEALS_PER_PAGE;
      state.page += 1;
    });
    builder.addCase(fetchDeals.rejected, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }
      state.isLoading = false;
      state.error = payload as ApiError;
      state.hasMore = false;
    });

    builder.addCase(fetchDealsPayments.fulfilled, (state, { payload }) => {
      state.payments = { ...state.payments, ...payload };
    });
    builder.addCase(fetchDealsPayments.rejected, (state, { payload }) => {
      state.error ||= payload as ApiError;
    });

    builder.addCase(fetchDealsOpsLogs.fulfilled, (state, { payload }) => {
      state.opsLogs = { ...state.opsLogs, ...payload };
    });
    builder.addCase(fetchDealsOpsLogs.rejected, (state, { payload }) => {
      state.error ||= payload as ApiError;
    });

    builder.addCase(createOpsLog.fulfilled, (state, { payload }) => {
      state.opsLogs[payload.deal_id].push(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { resetPaging, setFilters } = slice.actions;
export default slice;
