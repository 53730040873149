import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Api from '@advitam/api';
import { AutocompleteResult } from '@advitam/api/v1/Autocompletes';
import { getTokens } from '@advitam/api/lib/tokens';
import { ErrorText, FormattedApiError, FormUI } from '@advitam/ui';
import { withSlice } from '@advitam/react';

import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import { assert } from 'lib/support';

import { makeSelectIsSending, makeSelectDocuments } from '../../selectors';
import autocompletesStyle from '../Autocompletes.module.scss';
import Document from './Document';
import messages from './messages';
import { makeSelectError, makeSelectIsLoading } from './selectors';
import { createDocument } from './thunk';
import slice from './slice';

function SendBatchModalDocuments(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const documents = useSelector(makeSelectDocuments());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSending = useSelector(makeSelectIsSending());
  const error = useSelector(makeSelectError());
  const deal = useSelector(makeSelectDeal());
  assert(deal?.uuid !== undefined);

  const onAutocompleteChange = useCallback(
    (result?: AutocompleteResult): void => {
      if (!result) {
        return;
      }

      dispatch(createDocument(result));
    },
    [dispatch],
  );

  return (
    <div>
      <FormUI.Autosuggest
        disabled={isLoading || isSending}
        label={<FormattedMessage id={messages.label.id} />}
        placeholder={intl.formatMessage(messages.placeholder)}
        requestHeaders={{ ...getTokens() }}
        searchParams={{ with_upload: 'true' }}
        endpoint={Api.V1.absolute(
          Api.V1.Autocompletes.Path.deals.documents(deal.uuid),
        )}
        onChange={onAutocompleteChange}
      />

      {documents.map(document => (
        <Document key={document.deal_document_id} document={document} />
      ))}

      {error && (
        <ErrorText className={autocompletesStyle.error}>
          <FormattedApiError error={error} />
        </ErrorText>
      )}
    </div>
  );
}

export default withSlice(slice)(SendBatchModalDocuments);
