import { FormattedMessage } from 'react-intl';
import messages from '../messages';

interface InfosProps {
  city: string | null;
  country: string | null;
}

export default function Infos({ city, country }: InfosProps): JSX.Element {
  return (
    <div>
      <div className="flex">
        <h4>
          <FormattedMessage id={messages.destination.id} />
        </h4>
      </div>
      <div>
        <FormattedMessage id={messages.city.id} values={{ city }} />
      </div>
      <div>
        <FormattedMessage id={messages.country.id} values={{ country }} />
      </div>
    </div>
  );
}
