import { defineMessages } from 'react-intl';

export default defineMessages({
  addPrestation: {
    id: 'app.components.PrestationDialog.addPrestation',
  },
  updatePrestation: {
    id: 'app.components.PrestationDialog.updatePrestation',
  },
  tva: {
    id: 'app.components.PrestationDialog.tva',
  },
  tvaReduced: {
    id: 'app.components.PrestationDialog.tvaReduced',
  },
  tvaReduced2: {
    id: 'app.components.PrestationDialog.tvaReduced2',
  },
  noTva: {
    id: 'app.components.PrestationDialog.noTva',
  },
  primary: {
    id: 'app.components.PrestationDialog.primary',
  },
  complementary: {
    id: 'app.components.PrestationDialog.complementary',
  },
  family: {
    id: 'app.components.PrestationDialog.family',
  },
  categorySetUp: {
    id: 'app.components.PrestationDialog.categorySetUp',
  },
  categoryTbc: {
    id: 'app.components.PrestationDialog.categoryTbc',
  },
  categoryTac: {
    id: 'app.components.PrestationDialog.categoryTac',
  },
  categoryCeremony: {
    id: 'app.components.PrestationDialog.categoryCeremony',
  },
  categoryCoffinsProducts: {
    id: 'app.components.PrestationDialog.categoryCoffinsProducts',
  },
  categoryCremation: {
    id: 'app.components.PrestationDialog.categoryCremation',
  },
  categoryInterment: {
    id: 'app.components.PrestationDialog.categoryInterment',
  },
});
