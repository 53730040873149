import { ReactNode } from 'react'
import { Field } from 'react-final-form'

import { NullableString } from '../converters'
import { ErrorMessages, getFormError } from '../errors'
import { PasswordInput } from '../UI'
import { composeValidators, isRequired, Validator } from '../validators'

interface PasswordProps {
  name: string
  label?: ReactNode
  disabled?: boolean
  required?: boolean
  hideErrorMessage?: boolean
  errorMessages?: ErrorMessages
  validate?: Validator<string | null>
  className?: string
  tooltip?: ReactNode
}

export default function Password({
  name,
  label,
  disabled,
  required,
  hideErrorMessage,
  errorMessages,
  validate,
  className,
  tooltip,
}: PasswordProps): JSX.Element {
  return (
    <Field
      name={name}
      parse={NullableString.parse}
      format={NullableString.format}
      formatOnBlur
      validate={composeValidators<string | null>(required && isRequired, validate)}
    >
      {({ input, meta }): JSX.Element => (
        <PasswordInput
          label={label}
          onChange={input.onChange}
          onBlur={input.onBlur}
          value={input.value}
          disabled={disabled}
          error={getFormError(meta, errorMessages, hideErrorMessage)}
          className={className}
          tooltip={tooltip}
        />
      )}
    </Field>
  )
}
