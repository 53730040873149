import { authenticate } from '../../../../lib/decorators'
import { del, get, post, put } from '../../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../../lib/request'
import type { FlightJSON } from '../../../../models/Flight'
import type { FlightIndexJSON } from '../../../../models/Flight/FlightIndex'
import { Disabled } from './Disabled'
import { serialize } from './serializers'

export interface FlightsIndexFilters {
  q?: string
  airline_id?: number
  arrival_airport?: { country_id: number }
  arrival_airport_id?: number
  departure_airport_id?: number
  page?: number
  per_page?: number
  supplier_warehouse_id?: number
}

export class Flights {
  static readonly Disabled = Disabled

  @authenticate
  static index(filters: FlightsIndexFilters): ApiRequestDescriptor<FlightIndexJSON[]> {
    return get('/api/v1/suppliers/warehouses/flights', filters)
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<FlightJSON> {
    return get(`/api/v1/suppliers/warehouses/flights/${id}`)
  }

  @authenticate
  static create(flight: Omit<FlightJSON, 'id'>): ApiRequestDescriptor<FlightJSON> {
    return post(
      `/api/v1/suppliers/warehouses/${flight.supplier_warehouse_id}/flights`,
      serialize(flight),
    )
  }

  @authenticate
  static update(flight: FlightJSON): ApiRequestDescriptor<FlightJSON> {
    return put(`/api/v1/suppliers/warehouses/flights/${flight.id}`, serialize(flight))
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/suppliers/warehouses/flights/${id}`)
  }
}
