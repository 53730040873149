/*
 *  UrnPickup step editor
 */

import PropTypes from 'prop-types';

import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { BulletList } from 'components/Input';
import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';
import Services from '../../Services';

import { renderStepDefaults } from '../stepEditorsUtils';
import {
  familyDestinations,
  stepDestinations,
  getAshesDestination,
} from '../../actions';
import messages from './messages';

class UrnPickup extends PureComponent {
  render() {
    const {
      step,
      handleChange,
      steps,
      setAshesDestination,
      updateDelegate,
      stepServices,
      addService,
      removeService,
      isManualMode,
      userRole,
      updateService,
    } = this.props;

    return (
      <div className="step">
        <div className="step__header">
          {renderStepDefaults(step, handleChange)}
          <CheckboxDelegate
            isDelegate={!!step.doneByAnotherFuneral}
            updateDelegate={updateDelegate}
          />
        </div>
        <div>
          <h4>
            <FormattedMessage {...messages.ashesDestination} />
          </h4>
          <BulletList
            items={[
              { value: familyDestinations[0], label: messages.family },
              { value: familyDestinations[1], label: messages.foreignLand },
              { value: stepDestinations[0], label: messages.dispersion },
              { value: stepDestinations[1], label: messages.interment },
              { value: stepDestinations[2], label: messages.urnSealing },
            ]}
            checked={getAshesDestination(steps)}
            onChange={setAshesDestination}
            inline
          />
        </div>
        <Services
          stepId={step.id}
          services={stepServices}
          addService={addService}
          removeService={removeService}
          isManualMode={isManualMode}
          userRole={userRole}
          updateService={updateService}
        />
      </div>
    );
  }
}

UrnPickup.propTypes = {
  step: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  setAshesDestination: PropTypes.func.isRequired,
  updateDelegate: PropTypes.func.isRequired,
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  stepServices: PropTypes.array.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  updateService: PropTypes.func.isRequired,
};

export default UrnPickup;
