import { useEffect } from 'react'

/**
 * Fix the body
 *
 * Taken and adapted from https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
 *
 * This hook is likely to be used with modals, to prevent the body under them
 * to be scrollable when they are open.
 *
 * @param isFixed When `true`, disallow the body to scroll. When `false`, allows the body to scroll.
 */
export function useFixedBody(isFixed: boolean): void {
  useEffect(() => {
    if (isFixed) {
      document.body.style.position = 'fixed'
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.left = '0'
      document.body.style.right = '0'
    } else {
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.left = ''
      document.body.style.right = ''
    }
  }, [isFixed])
}
