import { TOGGLE_IS_OPEN, SET_DELETE_FUNCTION } from './constants';

/**
 * Close the modal
 *
 * @returns {{type: string}}
 */
export function toggleIsOpen() {
  return {
    type: TOGGLE_IS_OPEN,
  };
}

/**
 * Function to update the function to call when user confirm
 *
 * @param   {Function}    deleteFunction    Function to call
 * @param   {Object}      message           Message to display in modal
 * @returns {{deleteFunction: *, type: string, message: *}}
 */
export function setDeleteFunction(deleteFunction, message = null) {
  return {
    type: SET_DELETE_FUNCTION,
    deleteFunction,
    message,
  };
}
