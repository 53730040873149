import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormLayout, TextArea } from '@advitam/ui';
import { Layout } from 'containers/Crud';

import { makeSelectDeal } from '../../../selectors.typed';
import messages from './messages';
import Standalone from './Standalone';

export default function Commentaries(): JSX.Element {
  const deal = useSelector(makeSelectDeal());

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.title.id} />}>
      <FormLayout.Row>
        {deal?.id ? (
          <Standalone />
        ) : (
          <TextArea
            name="deal.commentary"
            label={<FormattedMessage id={messages.advitam.id} />}
          />
        )}
      </FormLayout.Row>
      <FormLayout.Row>
        <TextArea
          name="deal.client_commentary"
          label={<FormattedMessage id={messages.family.id} />}
          disabled
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
