import { FormattedMessage } from 'react-intl';

import type { EntityInvoicePrestationJSON } from '@advitam/api/models/EntityInvoice/Prestation';
import { LinkButton, Tooltip } from '@advitam/ui';
import Icon from '@advitam/ui/images/icons/arrow-up-right-from-square.svg';
import { Path } from 'containers/App/constants';

import messages from './messages';
import style from './List.module.scss';

interface OpenDealProps {
  prestation: EntityInvoicePrestationJSON;
}

export default function OpenDeal({ prestation }: OpenDealProps): JSX.Element {
  return (
    <Tooltip content={<FormattedMessage id={messages.openDeal.id} />}>
      <LinkButton
        outline
        className={style.action}
        href={Path.DEAL(prestation.deal.uuid)}
        newTab
        icon={<Icon />}
      />
    </Tooltip>
  );
}
