import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  anticipation: {
    id: 'app.containers.Deal.SideBar.KoReasonModal.anticipation',
  },
  duplicate: { id: 'app.containers.Deal.SideBar.KoReasonModal.duplicate' },
  notProvidedService: {
    id: 'app.containers.Deal.SideBar.KoReasonModal.notProvidedService',
  },
  other: { id: 'app.containers.Deal.SideBar.KoReasonModal.other' },
  physicalStore: {
    id: 'app.containers.Deal.SideBar.KoReasonModal.physicalStore',
  },
  toSpecify: { id: 'app.containers.Deal.SideBar.KoReasonModal.toSpecify' },
  text: { id: 'app.containers.Deal.SideBar.KoReasonModal.text' },
  title: { id: 'app.containers.Deal.SideBar.KoReasonModal.title' },
  tooExpensive: {
    id: 'app.containers.Deal.SideBar.KoReasonModal.tooExpensive',
  },
  unreachable: { id: 'app.containers.Deal.SideBar.KoReasonModal.unreachable' },
});
