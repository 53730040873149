import { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentProblem } from 'models/Deal/PaymentProblem';

import { Url } from 'containers/App/constants';
import { makeSelectUser } from 'slices/auth';
import CopyToClipboard from 'components/CopyToClipboard';
import { SelectList } from 'components/Input';

import { updatePaymentProblem } from './thunk';
import { makeSelectPaymentProblem } from './selectors';
import { PAYMENT_PROBLEM_OPTIONS } from './constants';
import messages from './messages';
import style from './style.scss';

interface ActionsProps {
  dealUUID: string;
}

export default function Actions({ dealUUID }: ActionsProps): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const user = useSelector(makeSelectUser());
  const paymentProblem = useSelector(makeSelectPaymentProblem());

  const onProblemChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    dispatch(
      updatePaymentProblem({
        uuid: dealUUID,
        paymentProblem: e.target.value as PaymentProblem,
      }),
    );
  };

  return (
    <div className={style.actions}>
      {user?.isFuneralDirectorOrAbove && (
        <div>
          <FormattedMessage id={messages.riskLabel.id} />{' '}
          <SelectList
            emptyOption={false}
            value={paymentProblem}
            onChange={onProblemChange}
            name="payment_problem"
            options={PAYMENT_PROBLEM_OPTIONS.map(option => ({
              value: option.value,
              name: intl.formatMessage(option.message),
            }))}
          />
        </div>
      )}
      <CopyToClipboard textToCopy={Url.PAYMENT_APP(dealUUID)}>
        <button type="button" className="button">
          <FormattedMessage id={messages.copyToClipboard.id} />
        </button>
      </CopyToClipboard>
    </div>
  );
}
