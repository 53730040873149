import { useCallback } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';
import { EntityInvoicePrestationStatus } from '@advitam/api/models/EntityInvoice/Prestation/Status';
import { assert } from '@advitam/support';
import { Button, ButtonLike, Spinner, Tooltip } from '@advitam/ui';
import Check from '@advitam/ui/images/icons/check.svg';

import { makeSelectIsInvoiceValidating } from '../selectors';
import { validateInvoice } from '../thunk';
import messages from './messages';
import style from './List.module.scss';

interface InvoiceIconProps {
  invoice: EntityInvoiceJSON;
}

export default function InvoiceIcon({
  invoice,
}: InvoiceIconProps): JSX.Element {
  const dispatch = useDispatch();

  const isLoading = useSelector(makeSelectIsInvoiceValidating(invoice.id));
  const isValidated = invoice.prestations.every(
    prestation => prestation.status === EntityInvoicePrestationStatus.VALIDATED,
  );

  const onValidate = useCallback(() => {
    dispatch(validateInvoice(invoice.id));
  }, [dispatch, invoice.id]);

  const className = [
    style.action,
    style.validate,
    isValidated && style.validated,
  ]
    .filter(Boolean)
    .join(' ');

  if (isValidated) {
    assert(invoice.prestations[0].validated_at !== null);
    return (
      <Tooltip
        content={
          <FormattedMessage
            id={messages.validated.id}
            values={{
              date: (
                <FormattedDate
                  value={invoice.prestations[0].validated_at}
                  year="numeric"
                  month="2-digit"
                  day="2-digit"
                  hour="2-digit"
                  minute="2-digit"
                />
              ),
            }}
          />
        }
      >
        <ButtonLike outline icon={<Check />} className={className} />
      </Tooltip>
    );
  }

  if (isLoading) {
    return <ButtonLike outline icon={<Spinner />} className={className} />;
  }

  return (
    <Tooltip content={<FormattedMessage id={messages.validate.id} />}>
      <Button
        outline
        onClick={onValidate}
        icon={<Check />}
        className={className}
      />
    </Tooltip>
  );
}
