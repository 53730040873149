import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  RequestDocumentTodoItem,
  SendBatchTodoItem,
} from '@advitam/api/models/Deal/TodoItem';
import { assert } from '@advitam/support';

import { makeSelectDealUuid } from 'containers/Deal/selectors.typed';
import DocumentsEditor from 'containers/DocumentsEditor';
import { fetchDocuments } from 'containers/Documents/actions';

import { makeSelectDocumentsToUpload } from '../../../selectors';
import { fetchItems } from '../../../thunk';
import ActionFrame from '../../parts/ActionFrame';
import Document from './Document';

interface DocumentRequestProps {
  item: RequestDocumentTodoItem | SendBatchTodoItem;
}

export default function DocumentRequest({
  item,
}: DocumentRequestProps): JSX.Element | null {
  const dispatch = useDispatch();
  const documentsToUpload = useSelector(makeSelectDocumentsToUpload(item));
  const uuid = useSelector(makeSelectDealUuid());
  assert(uuid !== null);

  const onSave = useCallback((): void => {
    dispatch(fetchDocuments(uuid));
    dispatch(fetchItems());
  }, [dispatch, uuid]);

  if (documentsToUpload.length === 0) {
    return null;
  }

  return (
    <ActionFrame>
      <DocumentsEditor afterSaveHook={onSave}>
        {({ uploadFiles }): JSX.Element[] =>
          documentsToUpload.map(document => (
            <Document
              key={document.id}
              document={document}
              uploadFiles={uploadFiles}
            />
          ))
        }
      </DocumentsEditor>
    </ActionFrame>
  );
}
