// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckoutInfos-module__checkout--V26D_{margin-bottom:8px}.CheckoutInfos-module__checkout--V26D_>span{display:block}.CheckoutInfos-module__price--NysA3{white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/DealFuneral/DealSummarySection/parts/CheckoutInfos/CheckoutInfos.module.scss"],"names":[],"mappings":"AAAA,uCACE,iBAAA,CAEA,4CACE,aAAA,CAIJ,oCACE,kBAAA","sourcesContent":[".checkout {\n  margin-bottom: 8px;\n\n  > span {\n    display: block;\n  }\n}\n\n.price {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkout": `CheckoutInfos-module__checkout--V26D_`,
	"price": `CheckoutInfos-module__price--NysA3`
};
export default ___CSS_LOADER_EXPORT___;
