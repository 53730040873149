// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadialProgressBar-module__bar--RL7GT{position:relative;width:-moz-fit-content;width:fit-content}.RadialProgressBar-module__progress_meter--NkMLh{stroke:#fff;fill:none;stroke-width:12}.RadialProgressBar-module__progress_value--wn903{fill:none;stroke-linecap:round;stroke-width:12;stroke:#feab77;stroke-dasharray:339.2920065877;transform:rotate(-90deg);transform-origin:50% 50%}.RadialProgressBar-module__progress_extra--bC30E{stroke:#00e676}`, "",{"version":3,"sources":["webpack://./src/components/RadialProgressBar/RadialProgressBar.module.scss"],"names":[],"mappings":"AAKA,sCACE,iBAAA,CACA,sBAAA,CAAA,iBAAA,CAGF,iDACE,WAAA,CACA,SAAA,CACA,eAAA,CAGF,iDACE,SAAA,CACA,oBAAA,CACA,eAAA,CACA,cAAA,CACA,+BAlBc,CAmBd,wBAAA,CACA,wBAAA,CAGF,iDACE,cAAA","sourcesContent":["@use 'sass:math';\n\n$radius: 54;\n$circumference: 2 * math.$pi * $radius;\n\n.bar {\n  position: relative;\n  width: fit-content;\n}\n\n.progress_meter {\n  stroke: white;\n  fill: none;\n  stroke-width: 12;\n}\n\n.progress_value {\n  fill: none;\n  stroke-linecap: round;\n  stroke-width: 12;\n  stroke: #feab77;\n  stroke-dasharray: $circumference;\n  transform: rotate(-90deg);\n  transform-origin: 50% 50%;\n}\n\n.progress_extra {\n  stroke: #00e676;\n}\n\n/* stylelint-disable */\n:export {\n  circumference: $circumference;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circumference": `339.2920065877`,
	"bar": `RadialProgressBar-module__bar--RL7GT`,
	"progress_meter": `RadialProgressBar-module__progress_meter--NkMLh`,
	"progress_value": `RadialProgressBar-module__progress_value--wn903`,
	"progress_extra": `RadialProgressBar-module__progress_extra--bC30E`
};
export default ___CSS_LOADER_EXPORT___;
