import { sleep } from './sleep'

export function nextTick(callback: () => void): void
export function nextTick(): Promise<void>

export function nextTick(callback?: () => void): Promise<void> | void {
  if (!callback) {
    return sleep(0)
  }

  setTimeout(callback, 0)
  return undefined
}
