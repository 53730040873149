/*
 * OtherFuneralServices Messages
 *
 * This contains all the text for the OtherFuneralServices component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  addServiceTva: { id: 'app.components.OtherFuneralServices.addServiceTva' },
  addService: { id: 'app.components.OtherFuneralServices.addService' },
  tvaService: { id: 'app.components.OtherFuneralServices.tvaService' },
});
