import { PersonAddress } from 'models/Person/Legacy';
import SelectAddress from 'components/SelectAddress/index.js';
import { RepatriationLocation } from '../types';
import Infos from './Infos';

interface CarProps {
  location: RepatriationLocation | null;
  onChange: (value: PersonAddress) => void;
}

export default function Car({ location, onChange }: CarProps): JSX.Element {
  return (
    <div>
      <SelectAddress
        address={location?.address}
        onChange={(value: PersonAddress): void => onChange(value)}
      />
      {location && (
        <Infos
          city={location.address.city || null}
          country={location.address.country || null}
        />
      )}
    </div>
  );
}
