export const DEAL_IDENTITY_CLIENT_AUTOCOMPLETION_MODAL =
  'Deal/Identity/ClientAutocompletionModal';

export const CLIENT_FORM_KEYS = [
  'civility',
  'firstname',
  'lastname',
  'birth_name',
  'address',
  'postal_code',
  'city',
  'country',
  'phone',
  'phone_2',
] as const;
