import { useSelector } from 'react-redux';

import { BatchLog } from '@advitam/api/models/Deal/Document/Batch/Log';
import { SignatureState } from '@advitam/api/models/Signature/State';

import { MaterialValues } from 'components/Button/constants';
import { makeSelectUser } from 'slices/auth';

interface ProcedureStateProps {
  log: BatchLog;
}

interface IconValues extends Record<string, string | undefined> {
  [SignatureState.FINISHED]: MaterialValues.CHECK;
  [SignatureState.EXPIRED]: MaterialValues.CLOSE;
  [SignatureState.REFUSED]: MaterialValues.CLOSE;
  [key: string]: MaterialValues | undefined;
}

const ICON: IconValues = {
  [SignatureState.FINISHED]: MaterialValues.CHECK,
  [SignatureState.EXPIRED]: MaterialValues.CLOSE,
  [SignatureState.REFUSED]: MaterialValues.CLOSE,
};

export default function ProcedureState({
  log,
}: ProcedureStateProps): JSX.Element | null {
  const user = useSelector(makeSelectUser());
  const icon = ICON[log.signature_state];

  if (!log.procedure_url) {
    return null;
  }

  return (
    <>
      {icon && (
        <i className="history__log__signature-status material-icons">{icon}</i>
      )}
      {user?.isFuneralDirectorOrAbove && (
        <a
          href={log.procedure_url}
          target="_blank"
          className="history__log__procedure-link"
          rel="noreferrer"
        >
          <i className="material-icons-outlined">
            {MaterialValues.REMOVE_RED_EYE}
          </i>
        </a>
      )}
    </>
  );
}
