import { FormattedMessage } from 'react-intl';

import { ResourceList } from '@advitam/ui';

import messages from '../messages';
import style from '../SupportingDocuments.module.scss';

export default function Header(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell className={style.name_cell}>
        <FormattedMessage id={messages.documentName.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.date_cell}>
        <FormattedMessage id={messages.issuanceDate.id} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.date_cell}>
        <FormattedMessage id={messages.expirationDate.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.ref_cell}>
        <FormattedMessage id={messages.reference.id} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.actions_header}>
        <FormattedMessage id={messages.actions.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
