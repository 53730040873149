import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Select } from 'components/Input/SelectList';
import {
  VAULT,
  VAULT_FRONT_DOOR,
  VAULT_FRONT_DOOR_UNDERGROUND,
  PLAIN_EARTH,
} from 'containers/Deal/DealFuneral/StepsSection/StepEditors/Interment/constants';
import otherMessages from 'components/ConcessionSelection/messages';
import Input from 'components/Input';
import messages from './messages';

const Dates = ({
  concession,
  intl,
  graveyardConcessions,
  handleChange,
  isFamilyBuyingConcession,
}) => {
  /**
   * Get concessions types by selected type
   *
   * @param      {string}  selectedType  The concession type selected
   * @return     {array}   Array of types
   */
  const getConcessionTypesBySelectedType = selectedType => {
    switch (selectedType) {
      case VAULT:
      case VAULT_FRONT_DOOR:
      case VAULT_FRONT_DOOR_UNDERGROUND:
        return [selectedType, PLAIN_EARTH];
      default:
        return [selectedType];
    }
  };

  /**
   * Push concessions prices depending of the type
   *
   * @param      {string}  selectedType  The concession type selected
   * @param      {object}  concessionPrices  The object with the arrays of concessions prices
   *   @example     { "plainearth": [{name: "Plainearth 20 years"}], "vault": [{ name: "Vault 20 years"}]}
   * @return     {object}  The object with the array of concessions prices
   */
  const getConcessionPricesByType = (selectedType, concessionPrices) => {
    const concessionPricesByType = [];
    const types = getConcessionTypesBySelectedType(selectedType);
    types.forEach(type => {
      if (type in concessionPrices) {
        concessionPricesByType.push(...concessionPrices[type]);
      }
    });
    return concessionPricesByType;
  };

  /**
   * Update the type, id of graveyard concession and term
   *
   * @param   {String}    value     The id of graveyard concession
   */
  const onChangeGraveyardConcession = ({ target: { value } }) => {
    let concessionTerm = null;
    let concessionType = '';
    Object.keys(graveyardConcessions).forEach(key => {
      graveyardConcessions[key].forEach(item => {
        if (String(item.id) === value) {
          concessionTerm = item.term;
          concessionType = key;
        }
      });
    });
    onChangeConcession({
      graveyard_concession_type: concessionType,
      graveyard_concession_id: value,
      term: concessionTerm,
    });
  };

  /**
   * Function to update keys of concession
   *
   * @param {object} updatedConcession keys you want to update
   */
  const onChangeConcession = updatedConcession => {
    handleChange({
      concession: {
        ...concession,
        ...updatedConcession,
      },
    });
  };

  const concessionsByType = getConcessionPricesByType(
    concession.type.name,
    graveyardConcessions,
  );

  if (concession.exists || isFamilyBuyingConcession) {
    return (
      <div>
        <FormattedMessage {...messages.duration} />
        <Input
          className="input"
          onChange={({ target: { value } }) =>
            onChangeConcession({ term: parseInt(value, 10) || null })
          }
          value={concession.term || ''}
        />
      </div>
    );
  }

  if (!concessionsByType || concessionsByType.length <= 0) return null;

  return (
    <div>
      <FormattedMessage {...messages.duration} />
      <Select
        onChange={onChangeGraveyardConcession}
        value={concession.graveyard_concession_id || 'default'}
      >
        {concessionsByType.map(item => (
          <option key={item.id} value={item.id}>
            {intl.formatMessage(messages.concessionType, {
              name: item.name,
              price: item.price,
              isBuilt: item.is_built,
            })}
          </option>
        ))}
        <option key="default" value="default" disabled>
          {intl.formatMessage(otherMessages.defaultSelect)}
        </option>
      </Select>
    </div>
  );
};

Dates.propTypes = {
  /** concession data */
  concession: PropTypes.object.isRequired,
  /** function to update a concession */
  updateConcession: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  /** Concessions available in graveyard selected */
  graveyardConcessions: PropTypes.object,
  /** Function to update the step */
  handleChange: PropTypes.func.isRequired,
  /** family want to buy concession */
  isFamilyBuyingConcession: PropTypes.bool.isRequired,
};

export default injectIntl(Dates);
