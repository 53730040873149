import { useCallback } from 'react'

import { Button } from '@advitam/ui'

import style from './Conversation.module.scss'

interface QuestionSuggestionProps {
  question: string
  disabled: boolean
  onClick: (question: string) => void
}

export default function QuestionSuggestion({
  question,
  disabled,
  onClick,
}: QuestionSuggestionProps): JSX.Element {
  const onQuestionClick = useCallback(() => {
    onClick(question)
  }, [onClick, question])

  return (
    <div className={style.question_suggestion}>
      <Button outline onClick={onQuestionClick} text={question} disabled={disabled} />
    </div>
  )
}
