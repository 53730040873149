import { MessageDescriptor } from 'react-intl';
import Fuse from 'fuse.js';

import {
  ProductTag,
  ProductType,
  ProductWindow,
} from '@advitam/api/models/Product';
import { SupplierWarehouseProductJSON } from '@advitam/api/models/Supplier/Warehouse';

import actionsMessages from 'messages/actions';

import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import { ProductsFilters, UnsavedSupplierWarehouseProduct } from '../types';
import messages from '../messages';

const COLOR_CATEGORY_TYPES: ProductType[] = [
  ProductType.MATTRESS,
  ProductType.URN,
  ProductType.PLATE,
  ProductType.SEMELLE,
  ProductType.MONUMENT,
];

const SIZE_CATEGORY_TYPES: ProductType[] = [
  ProductType.COFFIN,
  ProductType.FLOWER,
];

export function getDetails(
  product: SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct,
): string {
  if (!product.product) {
    return '';
  }

  if (
    product.product.color &&
    COLOR_CATEGORY_TYPES.includes(product.product.type)
  ) {
    return product.product.color;
  }

  if (
    product.product.size_model &&
    SIZE_CATEGORY_TYPES.includes(product.product.type)
  ) {
    return product.product.size_model;
  }

  return '';
}

export function getHermeticMessage(
  product: SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct,
): MessageDescriptor | null {
  if (!product.product || product.product.hermetic === null) {
    return null;
  }

  return product.product?.hermetic ? actionsMessages.yes : actionsMessages.no;
}

const WINDOW_MAP_MESSAGE: Record<ProductWindow, MessageDescriptor> = {
  [ProductWindow.YES]: actionsMessages.yes,
  [ProductWindow.NO]: actionsMessages.no,
  [ProductWindow.PANORAMIC]: messages.panoramic,
};

export function getWindowMessage(
  product: SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct,
): MessageDescriptor | null {
  if (!product.product || product.product.window === null) {
    return null;
  }

  return WINDOW_MAP_MESSAGE[product.product.window];
}

export function getAvailableColorOptions(
  products: Array<
    SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct
  >,
): SelectableItem<string>[] {
  const options: SelectableItem<string>[] = [];

  products.forEach(product => {
    if (!product.product) {
      return;
    }

    const { color } = product.product;
    if (color && !options.some(filter => filter.value === color)) {
      options.push({
        value: color,
        name: color,
      });
    }
  });

  return options;
}

export function getAvailableMaterialOptions(
  products: Array<
    SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct
  >,
): SelectableItem<string>[] {
  const options: SelectableItem<string>[] = [];

  products.forEach(product => {
    if (!product.product) {
      return;
    }

    const { material } = product.product;
    if (material && !options.some(filter => filter.value === material)) {
      options.push({
        value: material,
        name: material,
      });
    }
  });

  return options;
}

export function getAvailableWorshipTagsOptions(
  products: Array<
    SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct
  >,
): SelectableItem<ProductTag>[] {
  const options: SelectableItem<ProductTag>[] = [];

  products.forEach(product => {
    if (!product.product) {
      return;
    }

    const { tags } = product.product;
    tags.forEach(tag => {
      if (!options.find(option => option.value === tag)) {
        options.push({
          value: tag,
          name: tag,
        });
      }
    });
  });

  return options;
}

export function getAvailableSizeOptions(
  products: Array<
    SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct
  >,
): SelectableItem<string>[] {
  const options: SelectableItem<string>[] = [];

  products.forEach(product => {
    if (!product.product) {
      return;
    }

    const { size_model: size } = product.product;
    if (size && !options.some(filter => filter.value === size)) {
      options.push({
        value: size,
        name: size,
      });
    }
  });

  return options;
}

export function getFilteredProductIndices(
  products: Array<
    SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct
  >,
  filters: ProductsFilters,
): number[] {
  const fuse = new Fuse(products, {
    keys: [
      'product.advitam_name',
      'product.color',
      'product.material',
      'product.shape',
      'supplier_ref',
    ],
    threshold: 0.2,
  });
  const fuseItems =
    filters.q && fuse.search(filters.q).map(result => result.item);

  return products.reduce<number[]>((prev, curr, index) => {
    if (!curr.id) {
      return [...prev, index];
    }

    if (
      filters.category_type_eq &&
      curr.product?.type !== filters.category_type_eq
    ) {
      return prev;
    }

    if (filters.color_eq && filters.color_eq !== curr.product?.color) {
      return prev;
    }

    if (
      filters.hermetic_eq !== undefined &&
      filters.hermetic_eq !== curr.product?.hermetic
    ) {
      return prev;
    }

    if (
      filters.is_default_eq !== undefined &&
      filters.is_default_eq !== curr.funnel_default
    ) {
      return prev;
    }

    if (filters.material_eq && filters.material_eq !== curr.product?.material) {
      return prev;
    }

    if (
      filters.worship_tag_eq &&
      !curr.product?.tags.includes(filters.worship_tag_eq as ProductTag)
    ) {
      return prev;
    }

    if (filters.size_eq && filters.size_eq !== curr.product?.size_model) {
      return prev;
    }

    if (fuseItems && !fuseItems.includes(curr)) {
      return prev;
    }

    return [...prev, index];
  }, []);
}
