import { useLocation, useMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Route } from 'containers/App/constants';

import DealItems from './DealItems/index.js';
import DealMarble from './DealMarble/index.js';
import * as actions from './actions.js';
import { DEAL_TYPE_ITEM, DEAL_TYPE_MARBLE } from './constants';
import {
  makeSelectDealType,
  makeSelectErrorModal,
  makeSelectLoading,
  makeSelectSectionOpened,
} from './selectors.js';

interface DealTypeFactoryProps {
  getDeal: () => void;
}

export default function DealTypeFactory({
  getDeal,
}: DealTypeFactoryProps): JSX.Element | null {
  const dispatch = useDispatch();
  const match = useMatch(Route.DEAL);
  const location = useLocation();

  const dealType = useSelector(makeSelectDealType());
  const errorModal = useSelector(makeSelectErrorModal());
  const loading = useSelector(makeSelectLoading());
  const sectionOpened = useSelector(makeSelectSectionOpened());

  const setError = (err: unknown): void => {
    dispatch(actions.error(err));
  };

  const setSection = (section: string): void => {
    dispatch(actions.setSectionOpened(section));
  };

  switch (dealType) {
    case DEAL_TYPE_ITEM:
      return (
        <DealItems
          match={match}
          sectionOpened={sectionOpened}
          setSection={setSection}
          location={location}
          setError={setError}
        />
      );
    case DEAL_TYPE_MARBLE:
      return (
        <DealMarble
          match={match}
          getDeal={getDeal}
          loading={loading}
          dealType={dealType}
          errorModal={errorModal}
          sectionOpened={sectionOpened}
          setSection={setSection}
          location={location}
        />
      );
    default:
      return null;
  }
}
