import { FocusEventHandler, ReactElement, ReactNode, useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByPlaceId, PropTypes } from 'react-places-autocomplete'

import Google from '../../../../images/logos/google.svg'
import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'
import style from '../Autosuggest/Autosuggest.module.scss'
import gstyle from './GooglePlace.module.scss'
import type { Address } from './types'
import { serialize, format } from './converters'
import { ADDRESS_SKELETON } from './constants'

interface GooglePlaceProps {
  label: ReactNode
  tooltip?: ReactNode
  onChange: (v: Address) => void
  onBlur?: FocusEventHandler
  value?: Address
  error?: ReactElement | boolean
  placeholder?: string
  onlyFrance?: boolean
  onlyCities?: boolean
  disabled?: boolean
  formatDisplayValue?: (address: Address) => string
}

export default function GooglePlace({
  label,
  tooltip,
  value,
  error,
  onChange,
  onBlur,
  placeholder,
  disabled = false,
  onlyFrance = true,
  onlyCities = false,
  formatDisplayValue = format,
}: GooglePlaceProps): JSX.Element {
  const [displayValue, setDisplayValue] = useState(value ? formatDisplayValue(value) : '')

  useEffect(() => {
    setDisplayValue(value ? formatDisplayValue(value) : '')
  }, [formatDisplayValue, value])

  function onError(_status: string, clearSuggestions: () => void): void {
    clearSuggestions()
  }

  async function onSelect(address: string, placeId: string): Promise<void> {
    setDisplayValue(address)

    const place = await geocodeByPlaceId(placeId)
    onChange(serialize(place[0]))
  }

  function onInputChange(newValue: string): void {
    setDisplayValue(newValue)
    if (newValue === '') {
      onChange({ ...ADDRESS_SKELETON })
    }
  }

  const classes = [
    uiStyle.input,
    gstyle.input,
    displayValue.length > 0 && uiStyle.filled,
    error && uiStyle.error,
    disabled && uiStyle.disabled,
  ].filter(Boolean)

  const options: PropTypes['searchOptions'] = {}
  if (onlyCities) {
    options.types = ['(cities)']
  }
  if (onlyFrance) {
    options.componentRestrictions = { country: ['fr'] }
  }

  return (
    <PlacesAutocomplete
      value={displayValue}
      onChange={onInputChange}
      debounce={100}
      onSelect={onSelect}
      onError={onError}
      searchOptions={options}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }): JSX.Element => (
        <Label value={label} tooltip={tooltip} className={uiStyle.field} error={error}>
          <span className={style.wrapper}>
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getInputProps({
                className: classes.join(' '),
                placeholder,
                disabled,
                onBlur,
              })}
            />
            <span className={style.suggestions}>
              {suggestions.length > 0 && (
                <>
                  <ul>
                    {suggestions.map(suggestion => (
                      <li
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...getSuggestionItemProps(suggestion, {
                          className: suggestion.active ? 'active' : '',
                        })}
                        key={suggestion.index}
                      >
                        {suggestion.description}
                      </li>
                    ))}
                  </ul>
                  <span className={gstyle.branding}>
                    Powered by <Google />
                  </span>
                </>
              )}
            </span>
          </span>
        </Label>
      )}
    </PlacesAutocomplete>
  )
}
