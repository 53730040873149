import { PDFPage, rgb } from 'pdf-lib';
import svgPath from 'svgpath';

import type { Input } from '../../models';
import { getScale, getY } from './utils';

interface SvgProperties {
  width: number;
  height: number;
  path: string;
}

export default function renderSVG(
  input: Input,
  page: PDFPage,
  svg: SvgProperties,
): void {
  const scale = getScale(page);
  const width = input.size?.[0] || 0;
  const height = input.size?.[1] || 0;

  const sx = width / svg.width;
  const sy = height / svg.height;
  const path = svgPath(svg.path)
    .scale(sx * scale, sy * scale)
    .toString();

  page.drawSvgPath(path, {
    x: input.position[0] * scale,
    y: getY(page, input.position[1] * scale),
    color: rgb(0, 0, 0),
  });
}
