import { useLayoutEffect } from 'react'

type Effect = Parameters<typeof useLayoutEffect>[0]
type Dependencies = Parameters<typeof useLayoutEffect>[1]

/**
 * This hook is a wrapper around useLayoutEffect that will run the effect on the
 * server.
 *
 * @param effect The effect to run.
 * @param deps The dependencies of the effect.
 */
export function useServerLayoutEffect(effect: Effect, deps: Dependencies): void {
  if (typeof window === 'undefined') {
    effect()
    return
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(effect, deps)
}
