import Api, { request } from '@advitam/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  makeSelectUser,
  AppStateSubset as AuthAppStateSubset,
} from 'slices/auth';
import { AppStateSubset as DatasetAppStateSubset } from 'slices/data';
import { SUPPLIERS } from './constants';
import { makeSelectCurrentPage, makeSelectFilters } from './selectors';
import { AppStateSubset } from './slice';

type SuppliersAppStateSubset = AppStateSubset &
  AuthAppStateSubset &
  DatasetAppStateSubset;

export const fetchSuppliers = createAsyncThunk(
  `${SUPPLIERS}_FETCH_SUPPLIERS`,
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as SuppliersAppStateSubset;
    const filters = makeSelectFilters()(state);
    const currentPage = makeSelectCurrentPage()(state);
    const user = makeSelectUser()(state);

    try {
      const { body } = await request(
        Api.V1.Suppliers.index({
          ...filters,
          page: currentPage,
          negociated_eq: user?.isFuneralDirectorOrAbove
            ? filters.negociated_eq
            : false,
        }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
