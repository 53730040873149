import type { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { ButtonText } from '@advitam/ui';

import style from './LinksSection.module.scss';
import { makeHref } from './utils';

interface GetLinkClassProps {
  isActive: boolean;
}

function getLinkClass({ isActive }: GetLinkClassProps): string {
  return [style.link, isActive && style.active].filter(Boolean).join(' ');
}

export interface LinkProps {
  to: string;
  message: ReactNode;
}

export default function Link({ message, to }: LinkProps): JSX.Element {
  return (
    <NavLink end to={makeHref(to)} className={getLinkClass}>
      <ButtonText small>{message}</ButtonText>
    </NavLink>
  );
}
