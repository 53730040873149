import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

import Api, { Pagination, request } from '@advitam/api';
import {
  SupplierWarehouseJSON,
  SupplierWarehouseZoneFuneralWorkJSON,
} from '@advitam/api/models/Supplier/Warehouse';
import { isEqual } from '@advitam/support';

import { PER_PAGE, SUPPLIER_WAREHOUSE_ZONE } from './constants';
import { UnsavedFuneralWork, WarehouseZoneForm } from './types';
import { AppStateSubset } from './slice';
import { makeSelectFuneralWorks } from './selectors';
import { updateWarehouseName } from '../../thunk';

function isSavedFuneralWork(
  funeralWork: SupplierWarehouseZoneFuneralWorkJSON | UnsavedFuneralWork,
): funeralWork is SupplierWarehouseZoneFuneralWorkJSON {
  return funeralWork.id !== undefined;
}

export const fetchFuneralWorks = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE_ZONE}_FETCH`,
  async (zoneId: number, { rejectWithValue }) => {
    const funeralWorks: SupplierWarehouseZoneFuneralWorkJSON[] = [];
    let currentPage = 1;
    let hasMore = true;

    try {
      while (hasMore) {
        // eslint-disable-next-line no-await-in-loop
        const response = await request(
          Api.V1.Suppliers.Warehouses.Zones.FuneralWorks.index(zoneId, {
            page: currentPage,
            per_page: PER_PAGE,
          }),
        );

        funeralWorks.push(...response.body);
        hasMore = Pagination.getPageCount(response) > currentPage;
        currentPage += 1;
      }

      return funeralWorks;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

async function saveFuneralWork(
  funeralWork: SupplierWarehouseZoneFuneralWorkJSON | UnsavedFuneralWork,
  existingFuneralWorks: SupplierWarehouseZoneFuneralWorkJSON[],
  zoneId: number,
): Promise<SupplierWarehouseZoneFuneralWorkJSON[]> {
  if (isSavedFuneralWork(funeralWork)) {
    const existingFuneralWork = existingFuneralWorks.find(
      c => c.id === funeralWork.id,
    );
    if (existingFuneralWork && isEqual(funeralWork, existingFuneralWork)) {
      return [existingFuneralWork];
    }

    const { body } = await request(
      Api.V1.Suppliers.Warehouses.Zones.FuneralWorks.update(funeralWork),
    );

    return body;
  }

  const { body } = await request(
    Api.V1.Suppliers.Warehouses.Zones.FuneralWorks.create(
      zoneId,
      funeralWork as Omit<SupplierWarehouseZoneFuneralWorkJSON, 'id'>,
    ),
  );

  return body;
}

interface SaveFuneralWorksPayload {
  zoneId: number;
  values: WarehouseZoneForm;
}

export const saveFuneralWorks = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE_ZONE}_SAVE`,
  async (
    { zoneId, values }: SaveFuneralWorksPayload,
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const existingFuneralWorks = makeSelectFuneralWorks()(state);

    let warehouse: SupplierWarehouseJSON;
    try {
      const result = await dispatch(updateWarehouseName(values.warehouse.name));
      unwrapResult(result);
      warehouse = result.payload as SupplierWarehouseJSON;
    } catch {
      return undefined;
    }

    try {
      const funeralWorks = await Promise.all(
        values.sectionValues.map(funeralWork =>
          saveFuneralWork(funeralWork, existingFuneralWorks, zoneId),
        ),
      );

      await Promise.all(
        existingFuneralWorks.map(async funeralWork => {
          const hasBeenRemoved = !values.sectionValues.some(
            value => value.id === funeralWork.id,
          );

          if (hasBeenRemoved) {
            await request(
              Api.V1.Suppliers.Warehouses.Zones.FuneralWorks.destroy(
                funeralWork.id,
              ),
            );
          }
        }),
      );

      return { funeralWorks: funeralWorks.flat(), warehouse };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
