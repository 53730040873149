import DateRangePicker from '@wojtekmaj/react-daterange-picker'

import Cross from '../../../../images/icons/times.svg'
import uiStyle from '../UI.module.scss'
import style from './DateRange.module.scss'

interface DateChangeEvent<TValue> {
  endValue: TValue | undefined
  startValue: TValue | undefined
}

interface DateRangeProps<TValue> {
  endValue: TValue
  startValue: TValue
  disabled?: boolean
  maxDate?: Date
  minDate?: Date
  required?: boolean
  hideLeadingZeros?: boolean
  onChange: (ev: DateChangeEvent<TValue>) => void
}

export default function DateRange<TValue extends Date | string = Date | string>({
  endValue,
  startValue,
  minDate,
  maxDate,
  disabled = false,
  required = false,
  hideLeadingZeros = false,
  onChange,
}: DateRangeProps<TValue>): JSX.Element {
  const onRangeChange = (value: Array<Date | string> | Date | string | null): void => {
    if (!value) {
      onChange({ startValue: undefined, endValue: undefined })
      return
    }

    if (!Array.isArray(value)) {
      return
    }

    const [newStartValue, newEndValue] = value
    onChange({
      startValue: newStartValue as TValue,
      endValue: newEndValue as TValue,
    })
  }

  return (
    <DateRangePicker
      className={[uiStyle.input, style.input].join(' ')}
      calendarIcon={null}
      clearIcon={<Cross className={style.clear} />}
      onChange={onRangeChange}
      value={[startValue, endValue]}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      required={required}
      showLeadingZeros={!hideLeadingZeros}
    />
  )
}
