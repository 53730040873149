import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import Api, { request } from '@advitam/api';
import { PolymorphicEntity } from '@advitam/api/models/PolymorphicEntity';
import { EditableDocumentJSON } from '@advitam/api/models/Documents/Editable';
import { RuleJSON } from '@advitam/api/models/Rule';
import { assert } from 'lib/Assert';
import { fetchData } from 'containers/DocumentTemplateEditor/thunk';
import {
  setDictionary,
  setUploads,
} from 'containers/DocumentTemplateEditor/slice';

import { CRUD_DOCUMENTS } from './constants';
import { makeSelectEditableDocuments } from './selectors';
import { AppStateSubset } from './slice';

export const fetchDocuments = createAsyncThunk(
  `${CRUD_DOCUMENTS}/FETCH`,
  async (entity: PolymorphicEntity, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.EditableDocuments.index(entity));
      assert(body !== null);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

async function saveDocument(
  document: EditableDocumentJSON,
  existingDocuments: EditableDocumentJSON[],
  entity: PolymorphicEntity,
): Promise<EditableDocumentJSON> {
  const documentWithDefaults = {
    rule: {
      tag: 'always',
    } as RuleJSON,
    ...(document as Omit<EditableDocumentJSON, 'rule'>),
  };

  if (!document.id) {
    const { body } = await request(
      Api.V1.DocumentTypes.Documents.create(documentWithDefaults, entity),
    );
    return body;
  }

  const existingDocument = existingDocuments.find(
    doc => doc.id === document.id,
  );
  assert(existingDocument !== null);
  if (isEqual(documentWithDefaults, existingDocument)) {
    return documentWithDefaults;
  }

  const { body } = await request(
    Api.V1.EditableDocuments.update(documentWithDefaults),
  );
  return body;
}

interface SaveDocumentsPayload {
  values: EditableDocumentJSON[];
  entity: PolymorphicEntity;
}

export const saveDocuments = createAsyncThunk(
  `${CRUD_DOCUMENTS}/SAVE`,
  async (
    { values, entity }: SaveDocumentsPayload,
    { getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const existingDocuments = makeSelectEditableDocuments()(state);

    try {
      const documents = await Promise.all(
        values.map(document =>
          saveDocument(document, existingDocuments, entity),
        ),
      );
      await Promise.all(
        existingDocuments.map(async document => {
          if (!values.some(value => value.id === document.id)) {
            await request(Api.V1.EditableDocuments.destroy(document.id));
          }
        }),
      );
      return documents;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

async function fetchVariables(
  document: EditableDocumentJSON,
): Promise<string[]> {
  const { body } = await request(
    Api.V1.DocumentTypes.Variables.show(document.document_type.slug),
  );
  assert(body !== null);
  return body;
}

const initializeTemplateData = createAsyncThunk(
  `${CRUD_DOCUMENTS}/INITIALIZE_TEMPLATE_DATA`,
  async (document: EditableDocumentJSON, { dispatch }): Promise<void> => {
    if (document.template_id) {
      const result = await dispatch(fetchData(document.template_id));
      unwrapResult(result);
    } else {
      dispatch(setUploads([]));
    }
  },
);

export const initializeTemplateEditor = createAsyncThunk(
  `${CRUD_DOCUMENTS}/INITIALIZE_TEMPLATE_EDITOR`,
  async (document: EditableDocumentJSON, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setDictionary({}));
      const [variables] = await Promise.all([
        fetchVariables(document),
        dispatch(initializeTemplateData(document)).then(unwrapResult),
      ]);
      return { variables };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
