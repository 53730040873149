import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Person } from 'models/Person/index.ts';
import messages from './messages';

/**
 * Display a select for civilities
 */
export default function CivInput({ value, name, onChange, disabled }) {
  const intl = useIntl();

  return (
    <select
      className="civInput"
      name={name}
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      <option value="">{intl.formatMessage({ ...messages.none })}</option>
      <option value={Person.Civility.MR}>
        {intl.formatMessage({ ...messages.mr })}
      </option>
      <option value={Person.Civility.MRS}>
        {intl.formatMessage({ ...messages.mrs })}
      </option>
    </select>
  );
}

CivInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
