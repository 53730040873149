import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { requestAsync } from 'api';
import { Memorial } from 'models/Memorial';

import { assert, presence } from 'lib/Assert';

import { AppStateSubset } from './slice';
import { MEMORIAL_SPACE } from './constants';
import { makeSelectMemorial } from './selectors';

export const fetchMemorial = createAsyncThunk(
  `${MEMORIAL_SPACE}/FETCH_MEMORIAL`,
  async (dealUUID: string, { rejectWithValue }) => {
    try {
      const { body } = await requestAsync(Api.V1.Deals.Memorial.show(dealUUID));
      assert(body !== null);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateMemorial = createAsyncThunk(
  `${MEMORIAL_SPACE}/UPDATE_MEMORIAL`,
  async (memorial: Partial<Memorial>, { rejectWithValue }) => {
    try {
      const { body } = await requestAsync(Api.V1.Memorials.update(memorial));
      assert(body !== null);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export type UpdateMemorialFulfilledAction = ReturnType<
  typeof updateMemorial['fulfilled']
>;

export const patchMemorial = createAsyncThunk(
  `${MEMORIAL_SPACE}/PATCH_MEMORIAL`,
  (patch: Partial<Memorial>, { getState, dispatch }) => {
    const state = getState() as AppStateSubset;
    const memorial = makeSelectMemorial()(state);
    assert(presence(memorial));

    return dispatch(updateMemorial({ ...patch, id: memorial.id }));
  },
);
