import { defineMessages } from 'react-intl'

export default defineMessages({
  login: { id: 'ui.components.Navigation.login' },
  myPersonalSpace: { id: 'ui.components.Navigation.myPersonalSpace' },
  needHelp: { id: 'ui.components.Navigation.needHelp' },
  requestQuote: { id: 'ui.components.Navigation.requestQuote' },

  services: { id: 'ui.components.Navigation.services' },
  funerals: { id: 'ui.components.Navigation.funerals' },
  repatriation: { id: 'ui.components.Navigation.repatriation' },
  repatriationSubtext: { id: 'ui.components.Navigation.repatriationSubtext' },
  tombstone: { id: 'ui.components.Navigation.tombstone' },
  flowers: { id: 'ui.components.Navigation.flowers' },
  assistGPT: { id: 'ui.components.Navigation.assistGPT' },
  additionalServices: { id: 'ui.components.Navigation.additionalServices' },
  ourCompany: { id: 'ui.components.Navigation.ourCompany' },
  reviews: { id: 'ui.components.Navigation.reviews' },
  commitments: { id: 'ui.components.Navigation.commitments' },
  press: { id: 'ui.components.Navigation.press' },
  blog: { id: 'ui.components.Navigation.blog' },
  blogSubtext: { id: 'ui.components.Navigation.blogSubtext' },
  prosSpace: { id: 'ui.components.Navigation.prosSpace' },
})
