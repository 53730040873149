import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { HardSpinner, MessageModal, PageSpinner } from '@advitam/ui';
import ErrorBanner from 'components/ErrorBanner';
import actionsMessages from 'messages/actions';
import { withDatasets } from 'slices/data';

import concessionInfosSlice from '../../concessionInfosSlice/slice';
import {
  makeSelectIsLoading,
  makeSelectError,
} from '../../concessionInfosSlice/selectors';
import { fetchConcessionInfos } from '../../concessionInfosSlice/thunk';
import Wrapper from '../Wrapper';
import {
  makeSelecthasAlreadyInUseTermError,
  makeSelecthasAlreadyInUseTypeError,
  makeSelectInitialSectionValues,
  makeSelectIsSaving,
} from './selectors';
import { save } from './thunk';
import slice, { clearhasAlreadyInUseTermError } from './slice';
import MiscContainer from './Container';
import { GraveyardMiscForm } from './types';
import messages from './messages';

function Misc(): JSX.Element {
  const dispatch = useDispatch();
  const isSaving = useSelector(makeSelectIsSaving());
  const initialSectionValues = useSelector(makeSelectInitialSectionValues());
  const hasAlreadyInUseTermError = useSelector(
    makeSelecthasAlreadyInUseTermError(),
  );
  const hasAlreadyInUseTypeError = useSelector(
    makeSelecthasAlreadyInUseTypeError(),
  );
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const onSubmit = useCallback(
    (values: GraveyardMiscForm) => {
      dispatch(save(values));
    },
    [dispatch],
  );

  const onCloseErrorTermInUse = useCallback(() => {
    dispatch(clearhasAlreadyInUseTermError());
  }, [dispatch]);

  const onCloseErrorTypeInUse = useCallback(() => {
    dispatch(clearhasAlreadyInUseTermError());
  }, [dispatch]);

  const fetch = useCallback(() => {
    dispatch(fetchConcessionInfos());
  }, [dispatch]);

  useEffect(fetch, []);

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={initialSectionValues}>
      {error && <ErrorBanner errors={error} />}
      {isLoading ? <PageSpinner /> : <MiscContainer />}
      {isSaving && <HardSpinner />}

      <MessageModal
        isOpen={hasAlreadyInUseTermError}
        title={<FormattedMessage id={messages.errorTermInUseModalTitle.id} />}
        message={<FormattedMessage id={messages.errorTermInUseModalText.id} />}
        ctaText={<FormattedMessage id={actionsMessages.close.id} />}
        onClose={onCloseErrorTermInUse}
      />
      <MessageModal
        isOpen={hasAlreadyInUseTypeError && !hasAlreadyInUseTermError}
        title={<FormattedMessage id={messages.errorTypeInUseModalTitle.id} />}
        message={<FormattedMessage id={messages.errorTypeInUseModalText.id} />}
        ctaText={<FormattedMessage id={actionsMessages.close.id} />}
        onClose={onCloseErrorTypeInUse}
      />
    </Wrapper>
  );
}

export default compose<FC<Record<string, never>>>(
  withSlice(slice, concessionInfosSlice),
  withDatasets('concessionTypes', 'concessionSemelleTypes'),
)(Misc);
