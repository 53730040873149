import type { ReactNode } from 'react'

import style from './Reassurance.module.scss'
import ReassuranceItem from './Item'

interface ReassuranceProps {
  children: ReactNode | ReactNode[]
}

export default function Reassurance({ children }: ReassuranceProps): JSX.Element {
  return <div className={style.reassurance}>{children}</div>
}

export { ReassuranceItem }
