import { createAsyncThunk } from '@reduxjs/toolkit';

import { patchMemorial } from '../thunk';
import { MEMORIAL_SPACE_SETTINGS } from './constants';

export const updateProfilePicture = createAsyncThunk(
  `${MEMORIAL_SPACE_SETTINGS}/UPDATE_PROFILE_PICTURE`,
  (file: Blob | null, { dispatch }) => {
    return dispatch(patchMemorial({ profile_image: file }));
  },
);
