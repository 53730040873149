import { authenticate, withSessionId } from '../../../../lib/decorators'
import { del, get, post, put } from '../../../../lib/methods'
import { ApiRequestDescriptor } from '../../../../lib/request'
import { SupplierWarehouseZoneFuneralWorkJSON } from '../../../../models/Supplier/Warehouse'
import { serializeFuneralWork } from './serializers'
import { ZoneFuneralWorkBody } from './types'

export interface FuneralWorksIndexFilters {
  q?: string
  page?: number
  per_page?: number
}

export class FuneralWorks {
  @authenticate
  @withSessionId
  static create(
    zoneId: number,
    funeralWork: Omit<ZoneFuneralWorkBody, 'id'>,
  ): ApiRequestDescriptor<SupplierWarehouseZoneFuneralWorkJSON[]> {
    return post(
      `/api/v1/suppliers/warehouses/zones/${zoneId}/funeral_works`,
      serializeFuneralWork(funeralWork),
    )
  }

  @authenticate
  static index(
    zoneId: number,
    filters?: FuneralWorksIndexFilters,
  ): ApiRequestDescriptor<SupplierWarehouseZoneFuneralWorkJSON[]> {
    return get(`/api/v1/suppliers/warehouses/zones/${zoneId}/funeral_works`, filters)
  }

  @authenticate
  @withSessionId
  static update(
    funeralWork: ZoneFuneralWorkBody,
  ): ApiRequestDescriptor<SupplierWarehouseZoneFuneralWorkJSON[]> {
    return put(
      `/api/v1/suppliers/warehouses/zones/funeral_works/${funeralWork.id}`,
      serializeFuneralWork(funeralWork),
    )
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<SupplierWarehouseZoneFuneralWorkJSON> {
    return del(`/api/v1/suppliers/warehouses/zones/funeral_works/${id}`)
  }
}
