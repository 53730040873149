import { Objects } from '@advitam/support'
import { post } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { PaymentJSON } from '../../../models/Payment'
import { IngenicoInfos } from '../../../models/Ingenico/Infos'
import { Callback } from './Callback'

interface IngenicoCallbackUrls {
  accept_url?: string
  cancel_url?: string
  decline_url?: string
  exception_url?: string
}

export const Ingenico = {
  Callback,

  create: (
    payment: PaymentJSON,
    redirectUrls: IngenicoCallbackUrls,
  ): ApiRequestDescriptor<IngenicoInfos> => {
    const payload = Objects.omit(payment, 'street_number', 'street_name', 'postal_code', 'city')

    return post('/api/v1/payments/ingenico', {
      ...payload,
      ...redirectUrls,
    })
  },
}
