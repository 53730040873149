import { MouseEvent, ReactElement, ReactNode } from 'react'

import style from './Button.module.scss'
import Contents from './parts/Contents'

export interface ButtonStyleProps {
  reverse?: boolean
  primary?: boolean
  outline?: boolean
  disabled?: boolean
  fixedSize?: boolean
  className?: string
}

export function getClassList({
  primary = false,
  outline = false,
  reverse = false,
  disabled = false,
  fixedSize = false,
  className,
}: ButtonStyleProps): string[] {
  const classes = [style.btn]
  if (primary) {
    classes.push(style.primary)
  } else if (outline) {
    classes.push(style.outline)
  } else if (process.env.NODE_ENV !== 'production') {
    throw new Error('Button must have a type (primary or outline)')
  }
  if (reverse) {
    classes.push(style.reverse)
  }
  if (disabled) {
    classes.push(style.disabled)
  }
  if (fixedSize) {
    classes.push(style.fixed_size)
  }
  if (className) {
    classes.push(className)
  }
  return classes
}

interface ButtonProps extends ButtonStyleProps {
  text?: ReactNode
  icon?: ReactElement | string
  onClick: (e: MouseEvent) => void
}

export default function Button({ text, icon, onClick, ...styleProps }: ButtonProps): JSX.Element {
  const classes = getClassList(styleProps)
  const { disabled, outline } = styleProps

  return (
    <button type="button" className={classes.join(' ')} onClick={onClick} disabled={disabled}>
      <Contents text={text} icon={icon} outline={outline} />
    </button>
  )
}
