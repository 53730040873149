import { BusinessUser } from '@advitam/api/models/User/BusinessUser';
import { createAction } from 'lib/reactvitam/action_cable';

export interface PingPayload {
  user_id: number;
}

function ping(user: BusinessUser): PingPayload {
  return { user_id: user.id };
}

export default createAction(ping, 'ping');
