import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-final-form';

import type { SupportingDocumentOwner } from '@advitam/api/models/SupportingDocuments/Owner';
import { Button, ResourceList } from '@advitam/ui';
import type { Filter } from '@advitam/ui/components/ResourceList';

import { SupportingDocumentFilters } from '@advitam/api/v1/SupportingDocuments/Versions';
import messages from '../messages';
import style from '../SupportingDocuments.module.scss';
import { fetchDocuments } from '../thunk';
import { setFilters } from '../slice';
import { makeSelectFilters } from '../selectors';
import { SupportingDocumentsForm } from '../types';

interface FiltersProps {
  owner: SupportingDocumentOwner;
  isMobile: boolean;
}

export default function Filters({
  isMobile,
  owner,
}: FiltersProps): JSX.Element {
  const dispatch = useDispatch();

  const form = useForm<SupportingDocumentsForm>();

  const filters = useSelector(makeSelectFilters());
  const filtersShape: Filter[] = [
    {
      type: 'DateRange' as const,
      startName: 'start_dt',
      endName: 'end_dt',
      initialStartValue: filters.start_dt,
      initialEndValue: filters.end_dt,
      isHidden: isMobile,
    },
    {
      type: 'Switch' as const,
      name: 'current_version',
      placeholder: <FormattedMessage id={messages.currentVersion.id} />,
      initialValue: filters.current_version,
    },
  ];

  const onChange = useCallback(
    (values: SupportingDocumentFilters): void => {
      dispatch(setFilters(values));
      dispatch(fetchDocuments(owner));
    },
    [dispatch],
  );

  const onAddDocument = useCallback(() => {
    const { sectionValues } = form.getState().values;
    form.change('sectionValues', [
      {
        file: null,
        document_type: null,
        uploaded_document: {
          expiry_date: null,
          issue_date: null,
          reference: null,
        },
      },
      ...sectionValues,
    ]);
  }, [form]);

  return (
    <ResourceList.Filters
      filters={filtersShape}
      onChange={onChange}
      className={style.filters}
    >
      {!isMobile && (
        <Button
          primary
          text={<FormattedMessage id={messages.add.id} />}
          onClick={onAddDocument}
        />
      )}
    </ResourceList.Filters>
  );
}
