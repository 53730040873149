import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';

import { DealState } from 'models/Deal/State';
import { SummaryJSON } from 'models/Deal/Summary';
import { DealType } from 'models/Deal/Type';

import { fetchDeals } from './thunk';
import {
  DATE_FILTER_END_NAME,
  DATE_FILTER_START_NAME,
  DEALS_LIST,
  DEFAULT_FILTERS,
  FUNERAL_BRAND_FILTER_NAME,
  SEARCH_FILTER_NAME,
  STATE_FILTER_NAME,
  TYPE_FILTER_NAME,
  USER_FILTER_NAME,
} from './constants';

export interface DealFilters {
  per_page: number;
  [DATE_FILTER_START_NAME]?: Date;
  [DATE_FILTER_END_NAME]?: Date;
  [STATE_FILTER_NAME]?: DealState[];
  [TYPE_FILTER_NAME]?: DealType;
  [FUNERAL_BRAND_FILTER_NAME]?: number;
  [USER_FILTER_NAME]?: number;
  [SEARCH_FILTER_NAME]?: string;
}

export interface State {
  deals: SummaryJSON[];
  filters: DealFilters;
  currentPage: number;
  hasMore: boolean;
  lastRequestId: string | null;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DEALS_LIST]: State;
}

export const initialState: State = {
  deals: [],
  filters: DEFAULT_FILTERS,
  currentPage: 1,
  hasMore: true,
  lastRequestId: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: DEALS_LIST,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters(state, { payload }: PayloadAction<DealFilters>) {
      const { deals, currentPage, hasMore } = initialState;
      state.deals = deals;
      state.currentPage = currentPage;
      state.hasMore = hasMore;
      state.filters = payload;
    },
    clearDeals(state) {
      const { deals, currentPage, hasMore } = initialState;
      state.deals = deals;
      state.currentPage = currentPage;
      state.hasMore = hasMore;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDeals.pending, (state, { meta }) => {
      state.isLoading = true;
      state.lastRequestId = meta.requestId;
    });
    builder.addCase(fetchDeals.fulfilled, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }

      state.isLoading = false;
      state.deals = [...state.deals, ...payload];
      state.currentPage += 1;
      state.hasMore = payload.length === DEFAULT_FILTERS.per_page;
    });
    builder.addCase(fetchDeals.rejected, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }

      state.isLoading = false;
      state.error = ApiError.serialize(payload);
      state.hasMore = false;
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearDeals, setFilters } = slice.actions;
export default slice;
