import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from 'lib/Assert';

import { CLIENTS, CLIENTS_PER_PAGE } from './constants';
import { makeSelectFilters, makeSelectNextClientsPage } from './selectors';
import { AppStateSubset } from './slice';

export const fetchClients = createAsyncThunk(
  `${CLIENTS}/FETCH`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const filters = makeSelectFilters()(state);
    const page = makeSelectNextClientsPage()(state);

    try {
      const newFilters = {
        ...filters,
        page,
        per_page: CLIENTS_PER_PAGE,
      };

      const { body } = await request(Api.V1.Clients.index(newFilters));
      assert(body !== null);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
