// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandSelect-module__thumbnail--_Bj9U{width:32px;height:auto}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Sections/Home/BrandSelect/BrandSelect.module.scss"],"names":[],"mappings":"AAAA,sCACE,UAAA,CACA,WAAA","sourcesContent":[".thumbnail {\n  width: 32px;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": `BrandSelect-module__thumbnail--_Bj9U`
};
export default ___CSS_LOADER_EXPORT___;
