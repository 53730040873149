import { updateDeal } from '../slice.ts';

/**
 * Function to set products in store
 *
 * @param {array} products products to update
 * @param {object} funeral funeral object
 * @returns {object}
 */
export function setProducts(products, deal, dealType) {
  return updateDeal({
    [dealType]: {
      ...deal,
      products,
    },
  });
}
