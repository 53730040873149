import { FormattedMessage } from 'react-intl';
import { ResourceList } from '@advitam/ui';
import crudMessages from 'messages/crud.js';
import messages from '../messages';
import style from '../Suppliers.module.scss';

export default function SuppliersHeader(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell>
        <FormattedMessage id={messages.status.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={crudMessages.name.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.phone.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.email.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={crudMessages.department.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.negociatedFilter.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
