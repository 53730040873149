import type { CSSProperties } from 'react'

import { GUTTER_SIZE } from './constants'
import { Column, SizeUnit } from './types'

export function getColumnWidths(containerWidth: number, columns: Column[]): number[] {
  let totalSize = 0
  const growIndices: number[] = []

  const widths = columns.map((column, index) => {
    const sizeUnit = column.sizeUnit || SizeUnit.PIXEL
    let size = 0

    if (!column.size) {
      growIndices.push(index)
    } else if (sizeUnit === SizeUnit.PIXEL) {
      size = column.size
    } else {
      size = (column.size * containerWidth) / 100
    }
    totalSize += size
    return size
  })

  if (growIndices.length !== 0) {
    const growSize = (containerWidth - totalSize) / growIndices.length
    growIndices.forEach(index => {
      widths[index] = growSize
    })
  }

  return widths
}

export function applyGutter(style: CSSProperties): CSSProperties {
  const height = typeof style.height === 'number' ? style.height - GUTTER_SIZE : style.height

  return { ...style, height }
}
