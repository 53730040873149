import PropTypes from 'prop-types';
import { Role } from 'models/User';

/**
 *
 *
 * @param {*} allowedRoles
 */
const Authorization = allowedRoles => WrappedComponent => {
  const WithAuthorization = props => {
    const { userPermission } = props;
    if (allowedRoles.includes(userPermission)) {
      return WrappedComponent;
    }
    return null;
  };

  WithAuthorization.propTypes = {
    userPermission: PropTypes.number.isRequired,
  };

  return WithAuthorization;
};

export const SuperAdminAuth = Authorization([Role.ADMIN]);

export const FuneralDirectorAuth = Authorization([
  Role.ADMIN,
  Role.FUNERAL_DIRECTOR,
]);

export const FuneralAdvisorAuth = Authorization([
  Role.ADMIN,
  Role.FUNERAL_DIRECTOR,
  Role.FUNERAL_ADVISOR,
]);

export default Authorization;
