import { get, uniq } from 'lodash'
import { ChangeEvent, ReactNode, useCallback } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'

import type { AutocompleteResult } from '@advitam/api/v1/Autocompletes'
import { FormUI, useValidators, isRequired, ValidatorReturnType } from '@advitam/ui'

interface AutocompleteProps<TSuggestion> {
  placeholder?: string
  required?: boolean
  label: ReactNode
  tooltip?: ReactNode
  name: string
  endpoint: string
  getDisplayValue: (data: TSuggestion) => string
  buildNewValue?: (value: string) => Omit<TSuggestion, 'id'>
  keys: ReadonlyArray<keyof TSuggestion>
  transformKeys?: Record<string, string>
  requestHeaders?: Record<string, string>
  searchParams?: Record<string, string | string[]>
  className?: string
  disabled?: boolean
  expandOnTop?: boolean
  minCharsRequest?: number
}

export default function ApiAutocomplete<TSuggestion extends AutocompleteResult>({
  placeholder,
  name,
  label,
  tooltip,
  endpoint,
  required,
  getDisplayValue,
  buildNewValue,
  keys,
  transformKeys = {},
  requestHeaders,
  searchParams,
  className,
  disabled,
  expandOnTop,
  minCharsRequest,
}: AutocompleteProps<TSuggestion>): JSX.Element {
  const keysWithId = uniq([...keys, 'id' as const])

  const form = useForm()
  const { values } = useFormState()
  const value = get(values, name) as TSuggestion

  const onChange = useCallback(
    (r: TSuggestion | undefined): void => {
      keysWithId.forEach(key => {
        const formKey = transformKeys[key as string] || key
        form.change([name, formKey].join('.'), r?.[key])
      })
    },
    [name, form, keysWithId, transformKeys],
  )

  const onInput = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      if (buildNewValue) {
        onChange(buildNewValue(ev.target.value) as TSuggestion)
      }
    },
    [onChange, buildNewValue],
  )

  const validateRequired = useCallback(
    (id: number) => {
      if (!buildNewValue) {
        return isRequired(id)
      }

      const { values: formValues } = form.getState()
      return keys.reduce((prev, key) => {
        if (prev) {
          return prev
        }

        if (key === 'id') {
          return undefined
        }

        const formValue = get(formValues, [name, key].join('.')) as unknown
        return isRequired(formValue)
      }, undefined as ValidatorReturnType)
    },
    [form, name, buildNewValue, keys],
  )

  const validate = useValidators(required && validateRequired)

  return (
    <div className={className}>
      <Field name={`${name}.id`} validate={validate}>
        {({ meta }): JSX.Element => (
          <>
            <FormUI.Autosuggest
              label={label}
              tooltip={tooltip}
              placeholder={placeholder}
              endpoint={endpoint}
              value={value}
              getDisplayValue={getDisplayValue}
              requestHeaders={requestHeaders}
              searchParams={searchParams}
              onChange={onChange}
              onInput={onInput}
              error={meta.touched && !meta.valid}
              disabled={disabled}
              minCharsRequest={minCharsRequest}
              expandOnTop={expandOnTop}
            />
          </>
        )}
      </Field>
    </div>
  )
}
