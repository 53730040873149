import { DealDocumentBatchRecipientJSON } from '@advitam/api/models/Deal/Document/Batch';
import { RecipientType } from '@advitam/api/models/RecipientType';
import { composeValidators, ValidatorReturnType } from '@advitam/ui';
import { BatchEntityRecipient } from '@advitam/api/v1/Deals/Documents/types';

import { getRecipientUniqueId } from './utils';

export const enum ErrorCode {
  MISSING_VARIABLES = 'missing_variables',
  RECIPIENTS_MISSING_EMAIL = 'recipients_missing_email',
  RECIPIENTS_MISSING_METHOD_VALUES = 'recipients_missing_method_values',
  RECIPIENTS_NOT_CLIENT = 'recipients_not_client',
}

function findMissingVariables(text: string): string[] {
  const missingMatches = text.matchAll(/{{(.*?)}}/g);
  return Array.from(missingMatches).map(match => match[1]);
}

export function validateMissingVariables(
  text: string | null,
): ValidatorReturnType {
  const missingVariables = text && findMissingVariables(text);

  if (!Array.isArray(missingVariables) || missingVariables.length === 0) {
    return undefined;
  }

  return {
    message: ErrorCode.MISSING_VARIABLES,
    values: { variables: missingVariables.join(', ') },
  };
}

function isRecipientClient(recipient: DealDocumentBatchRecipientJSON): boolean {
  return recipient.type === RecipientType.CLIENT;
}

function hasRecipientEmail(recipient: DealDocumentBatchRecipientJSON): boolean {
  return Boolean(recipient.email);
}

const RECIPIENT_VALIDATION = {
  [ErrorCode.RECIPIENTS_NOT_CLIENT]: isRecipientClient,
  [ErrorCode.RECIPIENTS_MISSING_EMAIL]: hasRecipientEmail,
};

function validateRecipients(
  recipients: DealDocumentBatchRecipientJSON[],
  message: keyof typeof RECIPIENT_VALIDATION,
): (recipientEntities: BatchEntityRecipient[]) => ValidatorReturnType {
  return recipientEntities => {
    const erroredRecipients: string[] = [];

    recipientEntities.forEach(entity => {
      const recipient = recipients.find(
        r => getRecipientUniqueId(entity) === getRecipientUniqueId(r),
      );

      if (recipient && !RECIPIENT_VALIDATION[message](recipient)) {
        erroredRecipients.push(recipient.name);
      }
    });

    if (erroredRecipients.length === 0) {
      return undefined;
    }

    return {
      message,
      values: { recipients: erroredRecipients.join(', ') },
    };
  };
}

export function validateToSignRecipients(
  recipients: DealDocumentBatchRecipientJSON[],
): (entities: BatchEntityRecipient[]) => ValidatorReturnType {
  return composeValidators(
    validateRecipients(recipients, ErrorCode.RECIPIENTS_NOT_CLIENT),
    validateRecipients(recipients, ErrorCode.RECIPIENTS_MISSING_EMAIL),
  );
}

export function validateMissingEntity(
  recipients: DealDocumentBatchRecipientJSON[],
): (entities: BatchEntityRecipient[]) => ValidatorReturnType {
  return entities => {
    const erroredRecipients: string[] = [];

    recipients.forEach(recipient => {
      const isEntityMissing = !entities.some(
        entity =>
          getRecipientUniqueId(recipient) === getRecipientUniqueId(entity),
      );

      if (isEntityMissing) {
        erroredRecipients.push(recipient.name);
      }
    });

    if (erroredRecipients.length > 0) {
      return {
        message: ErrorCode.RECIPIENTS_MISSING_METHOD_VALUES,
        values: { recipients: erroredRecipients.join(', ') },
      };
    }

    return undefined;
  };
}
