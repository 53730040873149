import { defineMessages } from 'react-intl'

export default defineMessages<string>({
  administrative_procedure__defunct__blank: {
    id: 'ui.components.Format.ApiError.administrative_procedure__defunct__blank',
  },
  booking_transaction__work__refused: {
    id: 'ui.components.Format.ApiError.booking_transaction__work__refused',
  },
  chatbots__quota_exceeded: {
    id: 'ui.components.Format.ApiError.chatbots__quota_exceeded',
  },
  cityhall__insee_code__changed: {
    id: 'ui.components.Format.ApiError.cityhall__insee_code__changed',
  },
  crematorium__base__restrict_dependent_destroy__has_many: {
    id: 'ui.components.Format.ApiError.crematorium__base__restrict_dependent_destroy__has_many',
  },
  documents__sending__not_ready: {
    id: 'ui.components.Format.ApiError.documents__sending__not_ready',
  },
  entity_invoices__pending_payments__paid: {
    id: 'ui.components.Format.ApiError.entity_invoices__pending_payments__paid',
  },
  entity_invoices__pending_payments__recipient__missing_bank_information: {
    id:
      'ui.components.Format.ApiError.entity_invoices__pending_payments__recipient__missing_bank_information',
  },
  entity_invoices__pending_payments__invalid: {
    id: 'ui.components.Format.ApiError.entity_invoices__pending_payments__invalid',
  },
  supplier_warehouses__flight__base__restrict_dependent_destroy__has_many: {
    id:
      'ui.components.Format.ApiError.supplier_warehouses__flight__base__restrict_dependent_destroy__has_many',
  },
  funeral_parlor__base__restrict_dependent_destroy__has_many: {
    id: 'ui.components.Format.ApiError.funeral_parlor__base__restrict_dependent_destroy__has_many',
  },
  graveyard__base__restrict_dependent_destroy__has_many: {
    id: 'ui.components.Format.ApiError.graveyard__base__restrict_dependent_destroy__has_many',
  },
  hospital__base__restrict_dependent_destroy__has_many: {
    id: 'ui.components.Format.ApiError.hospital__base__restrict_dependent_destroy__has_many',
  },
  no_network: { id: 'ui.components.Format.ApiError.no_network' },
  supplier__email__invalid: {
    id: 'ui.components.Format.ApiError.supplier__email__invalid',
  },
  supplier__iban__invalid: {
    id: 'ui.components.Format.ApiError.supplier__iban__invalid',
  },
  supplier__name__blank: {
    id: 'ui.components.Format.ApiError.supplier__name__blank',
  },
  supplier__phone__invalid: {
    id: 'ui.components.Format.ApiError.supplier__phone__invalid',
  },
  supplier__postal_code__invalid: {
    id: 'ui.components.Format.ApiError.supplier__postal_code__invalid',
  },
  supplier__rib__invalid: {
    id: 'ui.components.Format.ApiError.supplier__rib__invalid',
  },
  supplier__siret__invalid: {
    id: 'ui.components.Format.ApiError.supplier__siret__invalid',
  },
  supplier__suppliers_dependencies__dependency__cyclic: {
    id: 'ui.components.Format.ApiError.supplier__suppliers_dependencies__dependency__cyclic',
  },
  supplier__suppliers_dependencies__group__blank: {
    id: 'ui.components.Format.ApiError.supplier__suppliers_dependencies__group__blank',
  },
  supplier__suppliers_dependencies__group_dependency__blank: {
    id: 'ui.components.Format.ApiError.supplier__suppliers_dependencies__group_dependency__blank',
  },
  supplier__vat__invalid: {
    id: 'ui.components.Format.ApiError.supplier__vat__invalid',
  },
  supplier_warehouse__base__restrict_dependent_destroy__has_many: {
    id:
      'ui.components.Format.ApiError.supplier_warehouse__base__restrict_dependent_destroy__has_many',
  },
  supplier_warehouses__zone_funeral_work__funeral_work_type__blank: {
    id:
      'ui.components.Format.ApiError.supplier_warehouses__zone_funeral_work__funeral_work_type__blank',
  },
  supplier_warehouses__zone_funeral_work__price__blank: {
    id: 'ui.components.Format.ApiError.supplier_warehouses__zone_funeral_work__price__blank',
  },
  tokens__expired: { id: 'ui.components.Format.ApiError.tokens__expired' },
  unhandled: { id: 'ui.components.Format.ApiError.unhandled' },
  worship__base__restrict_dependent_destroy__has_many: {
    id: 'ui.components.Format.ApiError.worship__base__restrict_dependent_destroy__has_many',
  },
})
