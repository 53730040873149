import { FormattedMessage } from 'react-intl'

import { Link, Text, Tooltip } from '@advitam/ui'

import { MAX_SOURCE_TITLE_LENGTH } from './constants'
import type { ChatbotAnswerSource } from '../types'
import messages from './messages'
import style from './Conversation.module.scss'

interface SourceProps {
  source: ChatbotAnswerSource
  index: number
}

export default function Source({ source, index }: SourceProps): JSX.Element {
  const displayedTitle = source.description.slice(0, MAX_SOURCE_TITLE_LENGTH)

  return (
    <Text tagName="div" className={[style.message, style.chatbot].join(' ')}>
      <FormattedMessage id={messages.source.id} values={{ index }} />
      {' : '}
      {source.description === displayedTitle ? (
        <Link newTab href={source.source}>
          {displayedTitle}
        </Link>
      ) : (
        <Tooltip content={source.description}>
          <Link newTab href={source.source}>
            {displayedTitle}
            &#x2026;
          </Link>
        </Tooltip>
      )}
    </Text>
  )
}
