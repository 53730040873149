import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { CrematoriumJSON } from '@advitam/api/models/Crematorium';
import { saveEditableDocuments } from 'containers/Crud/Documents/Editable';
import { saveSupportingDocuments } from 'containers/Crud/Documents/Supporting';
import { assert } from 'lib/support';

import { CREMATORIUM } from './constants';
import type { NewCrematorium } from './types';
import {
  createCrematorium,
  destroyCrematorium,
  fetchCrematorium,
  setIsCrematoriumDisabled,
  updateCrematorium,
  updateCrematoriumName,
} from './thunk';

export interface State {
  crematorium: CrematoriumJSON | NewCrematorium | null;
  isLoading: boolean;
  isSaving: boolean;
  isDestroying: boolean;
  isUpdatingName: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [CREMATORIUM]: State;
}

export const initialState: State = {
  crematorium: null,
  isLoading: false,
  isSaving: false,
  isDestroying: false,
  isUpdatingName: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: CREMATORIUM,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setCrematorium(
      state,
      { payload }: PayloadAction<CrematoriumJSON | NewCrematorium | null>,
    ) {
      state.crematorium = payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCrematorium.pending, state => {
      state.isLoading = true;
      state.destroyError = null;
    });
    builder.addCase(fetchCrematorium.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.crematorium = payload;
    });
    builder.addCase(fetchCrematorium.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createCrematorium.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(createCrematorium.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(createCrematorium.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateCrematorium.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateCrematorium.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.crematorium = payload;
    });
    builder.addCase(updateCrematorium.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateCrematoriumName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updateCrematoriumName.fulfilled, (state, { payload }) => {
      state.isUpdatingName = false;
      state.crematorium = payload;
    });
    builder.addCase(updateCrematoriumName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyCrematorium.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyCrematorium.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroyCrematorium.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setIsCrematoriumDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setIsCrematoriumDisabled.fulfilled, (state, { meta }) => {
      state.isSaving = false;
      assert(state.crematorium !== null);
      state.crematorium.disabled = meta.arg;
    });
    builder.addCase(setIsCrematoriumDisabled.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveEditableDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveEditableDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveEditableDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveSupportingDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveSupportingDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveSupportingDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setCrematorium } = slice.actions;
export default slice;
