import { ReactNode } from 'react'

import DoubleCheck from '../DoubleCheck'
import IconAndCtaCard from '../Card/IconAndCta'
import Container from '../Container'
import Heading from '../Heading'
import Label from '../Label'
import Text from '../Text'

import { ConfirmationCard } from './types'
import style from './Confirmation.module.scss'

interface ConfirmationProps {
  background: ReactNode
  heading: ReactNode
  title: ReactNode
  text?: ReactNode
  card?: ConfirmationCard
  className?: string
}

export default function Confirmation({
  background,
  heading,
  title,
  text,
  card,
  className,
}: ConfirmationProps): JSX.Element {
  const classes = ['col-xl-10', style.wrapper, className]
  return (
    <Container background={background} className={classes.join(' ')}>
      <div className={style.left}>
        <DoubleCheck className={style.check} />
        <Label>{heading}</Label>
        <Heading small>
          <b>{title}</b>
        </Heading>
        {text && (
          <Text tagName="div" className="col-xl-5">
            {text}
          </Text>
        )}
      </div>
      {card && (
        <IconAndCtaCard
          img={card.icon}
          title={card.title}
          text={card.extract}
          ctaText={card.cta}
          link={card.link}
          internal={card.internal}
        >
          {card.children}
        </IconAndCtaCard>
      )}
    </Container>
  )
}
