import { useSelector } from 'react-redux';

import Error from 'components/Error';

import { makeSelectErrorModal } from '../selectors.js';

/**
 * @deprecated
 *
 * TODO: It looks like the only feature using this reducer field is the booking.
 * This component should be dropped at the same time the suppliers section is refactored.
 */
export default function ErrorModal(): JSX.Element | null {
  const errorModal = useSelector(makeSelectErrorModal());

  if (!errorModal) {
    return null;
  }

  return <Error error={errorModal} />;
}
