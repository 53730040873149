import { defineMessages } from 'react-intl';

export default defineMessages({
  funeralparlor: {
    id: 'app.messages.entity.funeral_parlor',
  },
  funeral_parlor: {
    id: 'app.messages.entity.funeral_parlor',
  },
  crematorium: {
    id: 'app.messages.entity.crematorium',
  },
  cityhall: {
    id: 'app.messages.entity.cityhall',
  },
  hospital: {
    id: 'app.messages.entity.hospital',
  },
  prefecture: {
    id: 'app.messages.entity.prefecture',
  },
  worship: {
    id: 'app.messages.entity.worship',
  },
  police: {
    id: 'app.messages.entity.police',
  },
  regionalhealthauthority: {
    id: 'app.messages.entity.ars',
  },
  regional_health_authority: {
    id: 'app.messages.entity.ars',
  },
  graveyard: {
    id: 'app.messages.entity.graveyard',
  },
  supplier: {
    id: 'app.messages.entity.supplier',
  },
  supplierwarehouse: {
    id: 'app.messages.entity.supplierwarehouse',
  },
  consulate: {
    id: 'app.messages.entity.consulate',
  },
});
