import { ChangeEventHandler, ReactNode, useState } from 'react'
import Label from '../parts/Label'
import Radio from './Radio'
import style from './Rating.module.scss'

const MAX_RATE = 5

interface RatingProps {
  value: number
  name: string
  label?: ReactNode
  tooltip?: ReactNode
  error?: boolean
  disabled?: boolean
  className?: string
  onChange: ChangeEventHandler<HTMLInputElement>
}

export default function Rating({
  value,
  label,
  name,
  tooltip,
  error,
  className,
  onChange,
}: RatingProps): JSX.Element {
  const [hoveredStarIdx, setHoveredStarIdx] = useState<number | null>(null)
  return (
    <div className={className}>
      <Label value={label} tooltip={tooltip} className={style.label} />
      <div>
        {[...Array<number>(MAX_RATE)].map((_v, idx) => {
          const radioValue = idx + 1
          const isFilled = hoveredStarIdx ? hoveredStarIdx >= radioValue : value >= radioValue

          return (
            <Radio
              key={radioValue}
              name={name}
              value={radioValue}
              checked={value === radioValue}
              filled={isFilled}
              error={error}
              onMouseEnter={(): void => setHoveredStarIdx(radioValue)}
              onMouseLeave={(): void => setHoveredStarIdx(null)}
              onChange={onChange}
            />
          )
        })}
      </div>
    </div>
  )
}
