import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Layout from 'containers/Crud/Layout';
import { OpeningHours } from 'containers/Crud';

import { saveHospital } from '../thunk';
import type { HospitalForm } from './types';
import Wrapper from './Wrapper';

export default function OpeningHoursSection(): JSX.Element {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: HospitalForm): void => {
      dispatch(saveHospital(values.hospital));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <OpeningHours prefix="hospital" />
      </Layout.Container>
    </Wrapper>
  );
}
