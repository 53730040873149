import { createSelector, Selector } from 'reselect';

import { State, AppStateSubset } from './slice';
import { FLIGHTS_CREATE_MODAL } from './constants';

type CreateModalSelector<T> = Selector<AppStateSubset, T>;

export const selectCreateModalDomain = (state: AppStateSubset): State =>
  state[FLIGHTS_CREATE_MODAL];

export const makeSelectIsOpen = (): CreateModalSelector<boolean> =>
  createSelector(selectCreateModalDomain, ({ isOpen }) => isOpen);
