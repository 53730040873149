import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-final-form';
import type { FormApi } from 'final-form';
import { unwrapResult } from '@reduxjs/toolkit';

import { useThunkDispatch, withSlice } from '@advitam/react';
import {
  Button,
  SubmitButton,
  FormLayout,
  HiddenInput,
  Input,
  Modal,
  ModalTitle,
  Spinner,
  PageSpinner,
  Text,
} from '@advitam/ui';
import { FormattedApiError } from 'components/Format/ApiError';
import { OpsLogJSON, OpsLogTag } from 'models/OpsLog';

import { fetchDealsOpsLogs } from '../../thunk';
import messages from './messages';
import OpsLogCard from './Card';
import {
  makeSelectDealId,
  makeSelectError,
  makeSelectIsCreationLoading,
  makeSelectIsLoading,
  makeSelectOpsLogs,
} from './selectors';
import slice, { close } from './slice';
import { createOpsLog } from './thunk';
import style from './style.scss';

function OpsLogModal(): JSX.Element | null {
  const dispatch = useThunkDispatch();

  const dealId = useSelector(makeSelectDealId());
  const isLoading = useSelector(makeSelectIsLoading());
  const isCreationLoading = useSelector(makeSelectIsCreationLoading());
  const error = useSelector(makeSelectError());
  const opsLogs = useSelector(makeSelectOpsLogs(dealId));

  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const onSubmit = useCallback(
    async (values: OpsLogJSON, form: FormApi<OpsLogJSON>) => {
      const result = await dispatch(createOpsLog(values));
      try {
        unwrapResult(result);
        form.restart();
      } catch {
        // Avoid a console log
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (dealId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(fetchDealsOpsLogs([dealId]));
    }
  }, [dealId]);

  return (
    <Modal
      isOpen={dealId !== null}
      onRequestClose={onClose}
      className={`${style.modal} col-6`}
    >
      <ModalTitle>
        <FormattedMessage id={messages.modalTitle.id} />
      </ModalTitle>

      <Text>
        <FormattedMessage id={messages.history.id} />
      </Text>
      {isLoading && <PageSpinner />}
      <div className={style.list}>
        {opsLogs?.map(log => (
          <OpsLogCard opsLog={log} />
        ))}
      </div>

      <Form<OpsLogJSON>
        onSubmit={onSubmit}
        initialValues={{
          tag: OpsLogTag.PAYMENTS,
          deal_id: dealId || undefined,
        }}
      >
        {({ handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <FormLayout.Row>
              <Input
                required
                name="content"
                label={<FormattedMessage id={messages.addComment.id} />}
              />
              <HiddenInput name="tag" />
              <HiddenInput name="deal_id" />
            </FormLayout.Row>

            {error && (
              <Text small tagName="div" className={style.error}>
                <FormattedApiError error={error} />
              </Text>
            )}

            <div className={style.buttons}>
              <Button
                outline
                text={<FormattedMessage id={messages.cancel.id} />}
                onClick={onClose}
              />
              <SubmitButton
                primary
                fixedSize
                text={
                  isCreationLoading ? (
                    <Spinner />
                  ) : (
                    <FormattedMessage id={messages.submit.id} />
                  )
                }
              />
            </div>
          </form>
        )}
      </Form>
    </Modal>
  );
}

export default withSlice(slice)(OpsLogModal);
