import { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { useProduct } from '../../ProductListProvider';

import { getHermeticMessage } from '../utils';

interface HermeticCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function HermeticCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: HermeticCellProps): JSX.Element | null {
  const warehouseProduct = useProduct(rowIndex);

  const hermeticMessage = getHermeticMessage(warehouseProduct);

  return (
    <div style={wrapperStyle} className={className}>
      {hermeticMessage && <FormattedMessage id={hermeticMessage.id} />}
    </div>
  );
}
