import { BackgroundValue } from './constants'
import { BackgroundStyleProps, getClassList } from './Full'
import Round from './round-reverse.svg'
import style from './Background.module.scss'

interface TwoThirdsProps extends BackgroundStyleProps {
  color2: BackgroundValue
}

export default function TwoThirds({
  color,
  color2,
  textured,
  rounded,
}: TwoThirdsProps): JSX.Element {
  const classes = [style.background, style.two_thirds, style[`flat__${color}`]]
  const topClasses = [style.two_thirds__top].concat(
    getClassList({ color: color2, textured, rounded }),
  )

  return (
    <div className={classes.join(' ')}>
      <div className={topClasses.join(' ')}></div>
      {rounded && <Round className={style[`round__${color}`]} />}
    </div>
  )
}
