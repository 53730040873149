import { ReactNode, useCallback, useState } from 'react'
import { SwipeableProps, useSwipeable } from 'react-swipeable'

import CloseIcon from '../../../images/icons/times.svg'
import MenuIcon from '../../../images/icons/bars.svg'
import Container from '../../Container'
import MenuItem from './parts/Item'
import MenuLogo from './parts/Logo'
import { MenuItem as MenuItemType, MenuLogo as MenuLogoType } from './types'
import { MenuContext } from './context'
import style from './Menu.module.scss'

const SWIPE_OPTIONS: SwipeableProps = {
  preventScrollOnSwipe: true,
}

interface MenuProps {
  leftSection: MenuItemType[]
  rightSection: MenuItemType[]
  mobileSection?: ReactNode
  logo: MenuLogoType
  white?: boolean
  small?: boolean
  className?: string
  onOpenMenu?: () => void
}

export default function Menu({
  leftSection,
  rightSection,
  mobileSection,
  logo,
  white,
  small,
  className,
  onOpenMenu: parentOnOpenMenu,
}: MenuProps): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const classes = [style.wrapper, white ? style.white : style.beige, className].filter(Boolean)

  const onOpenMenu = useCallback(() => {
    setIsMenuOpen(true)
    if (parentOnOpenMenu) {
      parentOnOpenMenu()
    }
  }, [setIsMenuOpen, parentOnOpenMenu])

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [setIsMenuOpen])

  const backdropHandler = useSwipeable({
    ...SWIPE_OPTIONS,
    onSwipedRight: onCloseMenu,
  })
  const menuHandler = useSwipeable({
    ...SWIPE_OPTIONS,
    onSwipedRight: onCloseMenu,
  })
  const openerHandler = useSwipeable({
    ...SWIPE_OPTIONS,
    onSwipedLeft: onOpenMenu,
  })

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <>
      <nav className={classes.join(' ')}>
        <Container className={[style.nav, small ? style.small : ''].join(' ')}>
          <MenuLogo logo={logo} />
          <div className={style.section}>
            {leftSection.map((item, idx) => (
              <MenuItem item={item} key={idx} />
            ))}
          </div>
          {mobileSection && (
            <div className={[style.section, style.section_mobile].join(' ')}>{mobileSection}</div>
          )}
          <div className={style.section}>
            {rightSection.map((item, idx) => (
              <MenuItem item={item} key={idx} />
            ))}
          </div>
          <button className={style.menu_button} onClick={onOpenMenu}>
            <MenuIcon />
          </button>
        </Container>
      </nav>
      <MenuContext.Provider value={{ setIsMenuOpen }}>
        <div {...openerHandler} className={style.menu_opener} />
        <div
          {...backdropHandler}
          className={`${style.backdrop} ${isMenuOpen ? '' : style.closed}`}
          onClick={onCloseMenu}
        />
        <div {...menuHandler} className={`${style.menu} ${isMenuOpen ? '' : style.closed}`}>
          <button className={style.close} onClick={onCloseMenu}>
            <CloseIcon />
          </button>
          <div>
            <MenuLogo logo={logo} />
            {leftSection.map((item, idx) => (
              <MenuItem item={item} key={idx} />
            ))}
          </div>
          <div>
            {rightSection.map((item, idx) => (
              <MenuItem item={item} key={idx} />
            ))}
          </div>
        </div>
      </MenuContext.Provider>
    </>
  )
}
