import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get, post, del } from 'api/methods';

import { User } from 'models/User';

interface AuthResponse<T = undefined> {
  success: boolean;
  data: T;
}

interface Auth {
  Response: AuthResponse;
}

class Auth {
  static signIn(
    email: string,
    password: string,
  ): ApiRequestDescriptor<AuthResponse<User>> {
    return post('/api/v1/pro/auth/sign_in', { email, password });
  }

  @authenticate
  static signOut(): ApiRequestDescriptor<AuthResponse> {
    return del('/api/v1/pro/auth/sign_out');
  }

  @authenticate
  static validateToken(): ApiRequestDescriptor<AuthResponse<User>> {
    return get('/api/v1/pro/auth/validate_token');
  }
}

export default Auth;
