import { createAction } from 'lib/reactvitam/action_cable';

export interface UpdateStartPayload {
  user_id: number;
  session_id: string;
}

/**
 * A resource update started
 * Not for sending by the frontend, this is only the interface for reception of
 * backend messages.
 */
function updateStart(user_id: number, session_id: string): UpdateStartPayload {
  return { user_id, session_id };
}

export default createAction(updateStart, 'update_start');
