import { FormattedMessage, useIntl } from 'react-intl';

import { DimensionsInput, FormLayout, Input, Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import { Layout, messages as crudMessages } from 'containers/Crud';

import messages from '../messages';

const PREFIX = 'graveyard';

export function Columbarium(): JSX.Element {
  const intl = useIntl();

  const columbariumPlateOptions: SelectableItem<boolean>[] = [
    {
      name: intl.formatMessage(messages.columbariumPlateNotProvided),
      value: true,
    },
    {
      name: intl.formatMessage(messages.columbariumPlateProvided),
      value: false,
    },
  ];

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.columbarium.id} />}>
      <FormLayout.Row>
        <Select
          unselectable
          name={`${PREFIX}.should_buy_col_plate`}
          items={columbariumPlateOptions}
          label={<FormattedMessage id={messages.columbariumPlate.id} />}
        />
        <DimensionsInput
          names={[`${PREFIX}.col_plate_width`, `${PREFIX}.col_plate_height`]}
          label={<FormattedMessage id={messages.dimensions.id} />}
          tooltip={
            <FormattedMessage id={messages.columbariumDimensionsTooltip.id} />
          }
          suffix={<FormattedMessage id={crudMessages.unitCentimeter.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          name={`${PREFIX}.col_plate_color`}
          label={<FormattedMessage id={messages.columbariumPlateColor.id} />}
          tooltip={
            <FormattedMessage id={messages.columbariumPlateColorTooltip.id} />
          }
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
