import { defineMessages } from 'react-intl';

export default defineMessages({
  departure: { id: 'app.components.DatePicker.dateOfDeparture' },
  arrival: { id: 'app.components.DatePicker.dateOfArrival' },
  dateOfBirth: { id: 'app.components.DatePicker.dateOfBirth' },
  date: { id: 'app.components.DatePicker.generalDate' },
  tribunalAuthorizationDate: {
    id: 'app.components.DatePicker.tribunalAuthorizationDate',
  },
  paidAt: { id: 'app.components.DatePicker.paidAt' },
  empowermentDate: { id: 'app.components.DatePicker.empowermentDate' },
  startDate: { id: 'app.components.DatePicker.startDate' },
  endDate: { id: 'app.components.DatePicker.endDate' },
  deathDate: { id: 'app.components.DatePicker.deathDate' },
});
