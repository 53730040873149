import { ReactNode } from 'react'

import Text from '../../../Text'
import style from '../Section.module.scss'

interface TitleProps {
  message: ReactNode
  price: ReactNode
}

export default function Title({ message, price }: TitleProps): JSX.Element {
  return (
    <div className={style.title}>
      <Text>
        <b>{message}</b>
      </Text>
      <Text className={style.price}>{price}</Text>
    </div>
  )
}
