/**
 * Function to format the address
 *
 * @param {object} address data of address
 * @returns {string} readable address
 */
export function formatAddress(address) {
  let formattedAddress = '';
  if (!address) return formattedAddress;
  const fields = Object.keys(address);
  const valuesToKeep = [
    'address',
    'address_l2',
    'postal_code',
    'city',
    'country',
  ];
  const valuesNeeded = fields.filter(
    field =>
      field &&
      valuesToKeep.includes(field) &&
      address[field] !== null &&
      address[field] !== undefined,
  );
  valuesNeeded.forEach(key => {
    // We don't add a comma and a space for the last item of values needed
    if ([...valuesNeeded].pop() === key) {
      formattedAddress += address[key];
      return;
    }
    if (address[key]) {
      formattedAddress += `${address[key]}, `;
    }
  });
  return formattedAddress;
}
