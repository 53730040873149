import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  actions: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.actions',
  },
  addProduct: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.addProduct',
  },
  category: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.category',
  },

  accessoryCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.accessoryCategory',
  },
  AnnouncementCategory: {
    id:
      'app.containers.Supplier.Warehouse.sections.Products.AnnouncementCategory',
  },
  Bones_boxCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.Bones_boxCategory',
  },
  CoffinCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.CoffinCategory',
  },
  FlowerCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.FlowerCategory',
  },
  letter_sendingCategory: {
    id:
      'app.containers.Supplier.Warehouse.sections.Products.letter_sendingCategory',
  },
  MattressCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.MattressCategory',
  },
  medallionCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.medallionCategory',
  },
  monumentCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.monumentCategory',
  },
  patternCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.patternCategory',
  },
  PlateCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.PlateCategory',
  },
  PressCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.PressCategory',
  },
  repat_body_bagCategory: {
    id:
      'app.containers.Supplier.Warehouse.sections.Products.repat_body_bagCategory',
  },
  semelleCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.semelleCategory',
  },
  stonesCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.stonesCategory',
  },
  support_pillowCategory: {
    id:
      'app.containers.Supplier.Warehouse.sections.Products.support_pillowCategory',
  },
  SymbolCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.SymbolCategory',
  },
  symbol_graveyardCategory: {
    id:
      'app.containers.Supplier.Warehouse.sections.Products.symbol_graveyardCategory',
  },
  treeCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.treeCategory',
  },
  UrnCategory: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.UrnCategory',
  },

  color: { id: 'app.containers.Supplier.Warehouse.sections.Products.color' },
  cost: { id: 'app.containers.Supplier.Warehouse.sections.Products.cost' },
  defaultFunnel: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.defaultFunnel',
  },
  defaultQuote: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.defaultQuote',
  },
  details: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.details',
  },
  hermetic: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.hermetic',
  },
  material: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.material',
  },
  panoramic: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.panoramic',
  },
  photo: { id: 'app.containers.Supplier.Warehouse.sections.Products.photo' },
  price: { id: 'app.containers.Supplier.Warehouse.sections.Products.price' },
  updatedAt: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.updatedAt',
  },
  refAdvitam: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.refAdvitam',
  },
  refSupplier: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.refSupplier',
  },
  size: { id: 'app.containers.Supplier.Warehouse.sections.Products.size' },

  christianTag: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.christianTag',
  },
  classicTag: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.classicTag',
  },
  jewishTag: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.jewishTag',
  },
  muslimTag: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.muslimTag',
  },
  noneTag: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.noneTag',
  },

  window: { id: 'app.containers.Supplier.Warehouse.sections.Products.window' },
  worship: {
    id: 'app.containers.Supplier.Warehouse.sections.Products.worship',
  },
});
