import { useCallback, useEffect } from 'react'
import { assert } from '@advitam/support'

import { SIGNATURE_ENDED_MESSAGE_TYPE, Message, SignatureStatus } from './constants'

import style from './Yousign.module.scss'

assert(process.env.YOUSIGN_URL !== undefined)
assert(process.env.YOUSIGN_SUI !== undefined)

interface YousignProps {
  onSigned: () => void
  signatureId: string
  className?: string
}

export default function Yousign({
  onSigned,
  signatureId,
  className,
}: YousignProps): JSX.Element | null {
  const onMessage = useCallback(
    (msg: MessageEvent<string>) => {
      try {
        const message = JSON.parse(msg.data) as Message
        if (
          message.type === SIGNATURE_ENDED_MESSAGE_TYPE &&
          message.status === SignatureStatus.SUCCESS
        ) {
          onSigned()
        }
      } catch (err) {
        // Not a JSON message, discard it
      }
    },
    [onSigned],
  )

  const src = new URL('/procedure/sign', process.env.YOUSIGN_URL)
  const searchParams = new URLSearchParams({
    members: `/members/${signatureId}`,
    signatureUi: `/signature_uis/${process.env.YOUSIGN_SUI || ''}`,
  })
  src.search = searchParams.toString()

  useEffect(() => {
    window.addEventListener('message', onMessage)
    return (): void => window.removeEventListener('message', onMessage)
  }, [onMessage])

  return (
    <div className={[style.container, className].join(' ')}>
      <iframe className={style.frame} src={src.toString()} />
    </div>
  )
}
