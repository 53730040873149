import { createSelector } from 'reselect';
import {
  makeSelectDeal,
  makeSelectLoading as makeSelectDealTypeWrapperLoading,
} from 'containers/Deal/selectors';

import { Deal, DealParentType } from 'models/Deal/index.ts';
import { Memorial } from 'models/Memorial/index.ts';

/**
 * Direct selector to the dealItems state domain
 */
export const selectDealItemsDomain = state => state.dealItems;

export const makeSelectIsLoading = () =>
  createSelector(
    selectDealItemsDomain,
    makeSelectDealTypeWrapperLoading(),
    (substate, superIsLoading) => superIsLoading || substate.isLoading,
  );

function stepToDeliveryLocation(delivery, step) {
  return {
    ...step,
    type: step.eventType,
    source: 'deal',
    id: delivery.step_id,
    date: step.eventDate * 1000,
    location: {
      ...step.location,
      ...step.location.address,
    },
  };
}

export const makeSelectDeliveryLocations = () =>
  createSelector(
    selectDealItemsDomain,
    makeSelectDelivery(),
    makeSelectDeal(),
    (substate, delivery, deal) => {
      if (deal.item.parent_type === DealParentType.MEMORIAL) {
        return substate.deliveryLocations;
      }
      return substate.deliveryLocations.concat([
        stepToDeliveryLocation(delivery, deal.item.steps[0]),
      ]);
    },
  );

export const makeSelectDelivery = () =>
  createSelector(
    makeSelectDeal(),
    substate => (substate && substate.item && substate.item.delivery) || {},
  );

export const makeSelectMessage = () =>
  createSelector(makeSelectDelivery(), substate => substate.message);

export const makeSelectDeliveryLocationError = () =>
  createSelector(
    makeSelectDeliveryLocations(),
    makeSelectDelivery(),
    (deliveryLocations, selectedStep) => {
      if (deliveryLocations.length === 0) {
        return false;
      }

      // Check if the location checked is available in the delivery locations
      return !deliveryLocations.some(
        item =>
          item.source === selectedStep.step_type &&
          item.id === selectedStep.step_id,
      );
    },
  );

export const makeSelectSignature = () =>
  createSelector(makeSelectDelivery(), substate => substate.signature);

export const makeSelectParentType = () =>
  createSelector(selectDealItemsDomain, substate => substate.parentType);

export const makeSelectParent = () =>
  createSelector(
    selectDealItemsDomain,
    makeSelectParentType(),
    (substate, type) => {
      const { parent } = substate;
      if (!type || !parent) {
        return parent;
      }
      if (type === DealParentType.MEMORIAL) {
        return new Memorial(parent);
      }
      return new Deal(parent);
    },
  );
