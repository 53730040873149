import { Model } from '../Model'
import { FlightAirlineJSON } from './Airline'
import { FlightAirportJSON } from './Airport'

interface FlightIndexBase {
  id: number
  min_price: number
  min_weight: number
  airline: FlightAirlineJSON
  departure_airport: FlightAirportJSON
  arrival_airport: FlightAirportJSON
  stopover_airport: FlightAirportJSON | null
}

export interface FlightIndexJSON extends FlightIndexBase {
  updated_at: string | null
}

export interface FlightIndex extends FlightIndexBase {
  updated_at: Date | null
}

export class FlightIndex extends Model<FlightIndexJSON> {
  constructor(data: FlightIndexJSON) {
    super(data)
    this.updated_at = Model.parseDate(data.updated_at)
  }
}
