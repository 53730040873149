import { ReactNode } from 'react'

import Chevron from '../../../../images/icons/chevron-up.svg'
import Heading from '../../../Heading'
import style from '../Sticky.module.scss'

interface TitleProps {
  title: ReactNode
  subtitle: ReactNode
  action?: ReactNode
}

export default function Title({ title, subtitle, action }: TitleProps): JSX.Element {
  return (
    <div className={style.title}>
      <div>
        <Heading small className={style.heading}>
          <b>{title}</b>
          <Chevron />
        </Heading>
        {subtitle}
      </div>
      <div className={style.submit_title}>{action}</div>
    </div>
  )
}
