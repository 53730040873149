import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { ButtonV2 as Button } from 'components/Button';
import SaveIcon from 'images/icons/content-save.svg';
import CloseIcon from 'images/icons/close-circle.svg';

import style from '../style.scss';

interface TodoFormProps {
  currentTitle?: string;
  onSave: (title: string) => void;
  closeForm: () => void;
}

export default function TodoForm({
  currentTitle,
  onSave,
  closeForm,
}: TodoFormProps): JSX.Element {
  const [title, setTitle] = useState(currentTitle || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const onSubmit = (): void => {
    if (title.trim() !== '') {
      onSave(title);
      closeForm();
    }
  };

  const onKeyPress = ({
    key,
    shiftKey,
  }: KeyboardEvent<HTMLDivElement>): void => {
    if (key === 'Enter' && !shiftKey) {
      onSubmit();
    }
  };

  const onKeyDown = ({ key }: KeyboardEvent<HTMLDivElement>): void => {
    if (key === 'Escape') {
      closeForm();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      textareaRef.current.selectionEnd = textareaRef.current.value.length;
    }
  }, []);

  return (
    <div
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
      className={`${style.form} ${
        !currentTitle ? style['form--creation'] : ''
      }`}
    >
      <textarea
        value={title}
        onChange={onChange}
        ref={textareaRef}
        maxLength={200}
      />
      <div>
        <Button className="button--green button--svg" onClick={onSubmit}>
          <SaveIcon />
        </Button>
        <Button className="button--red button--svg" onClick={closeForm}>
          <CloseIcon />
        </Button>
      </div>
    </div>
  );
}
