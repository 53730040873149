import type { IThumbProps } from 'react-range/lib/types'

import style from './Range.module.scss'

interface ThumbProps {
  props: IThumbProps
}

export default function Thumb({ props }: ThumbProps): JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <span {...props} className={style.thumb} />
}
