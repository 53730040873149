import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { DealType } from '@advitam/api/models/Deal/Type';
import Input from 'components/Form/Input.js';
import { PRESTATION_PRODUCT } from 'components/PrestationDialog/constants.js';
import PrestationActions from 'components/PrestationActions/index.js';
import { findValidPrice } from 'components/Price/utils';
import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import { PrestationStatus, Product } from 'models/Deal/Product';
import { ProductUse } from 'models/Product';
import { DealPrestationComputeType } from 'models/Deal/Prestation/ComputeType';
import { assert } from 'lib/support';

import TextareaInput from 'components/Form/TextArea.js';
import ProductVariants from './ProductVariants';
import ProductInfo from './ProductInfo';
import messages from './messages';
import style from './style.scss';

interface ProductProps {
  product: Product;
  isManualMode: boolean;
  canSelectVariants: boolean;
  userRole: number;
  removeProduct: () => void;
  updateProduct: (product: Product) => void;
  onEditProduct: () => void;
  getProduct?: (id: number) => void;
  isReadonly?: boolean;
}

export default function ProductComponent({
  product,
  removeProduct,
  onEditProduct,
  updateProduct,
  isManualMode,
  userRole,
  canSelectVariants,
  getProduct,
  isReadonly,
}: ProductProps): JSX.Element {
  const intl = useIntl();
  const [isModalOpen, toggleModal] = useState(false);

  function getBackgroundColor(item: Product): string {
    if (item.status === PrestationStatus.DELETED) return 'product--deleted';
    if (item.status === PrestationStatus.EDITED) return 'product--edited';
    if (item.priceLine?.not_negociated_prices) {
      return 'product--not-negociated';
    }
    if (item.priceLine?.estimation) {
      return 'product--estimation';
    }
    if (item.compute === DealPrestationComputeType.AUTO) {
      return 'product--created-auto';
    }
    return 'product--created-manual';
  }

  const { productObject: p, error, default_keys: keys, priceLine } = product;
  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);

  if (error) {
    return (
      <div className="product product--error">
        <div className="product__img">
          {p.images.length > 0 && <img src={p.images[0]} alt="" />}
        </div>
        <div> {keys && keys.type} </div>
        <div> {keys && keys.use} </div>
        <div> {p.description} </div>
        <div> {p.supplier_ref} </div>
        <PrestationActions
          isManualMode={isManualMode}
          userRole={userRole}
          updatePrestation={updateProduct}
          onEditPrestation={onEditProduct}
          removePrestation={removeProduct}
          prestation={product}
          prestationType={PRESTATION_PRODUCT}
        />
      </div>
    );
  }

  const className = getBackgroundColor(product);
  const price = findValidPrice([
    p.price,
    priceLine && priceLine.manual_price,
    priceLine && priceLine.price,
  ]);
  const description =
    p.description ||
    (priceLine && priceLine.manual_description) ||
    (priceLine && priceLine.description);

  const choseVariants =
    canSelectVariants && product.status === PrestationStatus.NONE;

  return (
    <div className={`product ${className}`}>
      <div className="flex">
        <div className="product__img">
          {p.thumbnail && <img src={p.thumbnail} alt="" />}
        </div>
        <div className="product__container">
          <div className="product__container__title">
            <h4>{p.product_name}</h4>
            {p.use === ProductUse.UNSPECIFIED || <span>{p.use}</span>}
          </div>
          <div>{description}</div>
          <div>{p.supplier_ref}</div>
          <ProductInfo product={{ ...p, ...priceLine, price }} />
          {deal.deal_type === DealType.ITEM && (
            <>
              <Input
                message={messages.amount}
                className={style['qty-input']}
                input={{
                  type: 'number',
                  onChange: ({
                    target: { value },
                  }: ChangeEvent<HTMLInputElement>): void =>
                    updateProduct({
                      ...product,
                      amount: parseInt(value, 10),
                    }),
                  value: product.amount,
                  onBlur: undefined,
                  name: undefined,
                  placeholder: undefined,
                  disabled: isReadonly,
                  ref: undefined,
                  min: undefined,
                  max: undefined,
                }}
              />
              <TextareaInput
                className={style.message}
                message={messages.message}
                input={{
                  onChange: null,
                  onBlur: null,
                  value: product.comp_info?.message,
                  name: undefined,
                }}
                meta={{ error: undefined, touched: undefined }}
              />
              <Input
                className={style.signature}
                message={messages.signature}
                input={{
                  type: 'text',
                  onChange: null,
                  onBlur: null,
                  value: product.comp_info?.signature,
                  name: undefined,
                  placeholder: undefined,
                  disabled: true,
                  ref: undefined,
                  min: undefined,
                  max: undefined,
                }}
                meta={{ error: undefined, touched: undefined }}
              />
            </>
          )}
          {!isReadonly && (
            <div className="flex">
              {choseVariants && (
                <button
                  className="product__container__variant"
                  type="button"
                  onClick={(): void => toggleModal(!isModalOpen)}
                >
                  {intl.formatMessage(messages.selectAVariant)}
                  {isModalOpen ? <span>&#8593;</span> : <span>&#8595;</span>}
                </button>
              )}
              <PrestationActions
                prestationType={PRESTATION_PRODUCT}
                isManualMode={isManualMode}
                userRole={userRole}
                updatePrestation={updateProduct}
                onEditPrestation={onEditProduct}
                removePrestation={removeProduct}
                prestation={product}
              />
            </div>
          )}
        </div>
      </div>
      {choseVariants && (
        <ProductVariants
          isModalOpen={isModalOpen}
          toggleModal={(): void => toggleModal(!isModalOpen)}
          updateProduct={updateProduct}
          product={product}
          getProduct={getProduct}
        />
      )}
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
ProductComponent.propTypes = {
  product: PropTypes.object.isRequired,
  removeProduct: PropTypes.func.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  updateProduct: PropTypes.func.isRequired,
  onEditProduct: PropTypes.func.isRequired,
  canSelectVariants: PropTypes.bool.isRequired,
  getProduct: PropTypes.func,
  isReadonly: PropTypes.bool,
};
/* eslint-enable react/forbid-prop-types */
