import { createSelector, Selector } from 'reselect';

import {
  RequestDocumentTodoItem,
  SendBatchTodoItem,
  createTodoItem,
  TodoItem,
  TodoItemJSON,
} from '@advitam/api/models/Deal/TodoItem';

import { makeSelectDocuments } from 'containers/Documents/selectors';
import { DealDocument } from 'models/Deal/Document';

import { TODO_LIST } from './constants';
import { State, AppStateSubset } from './slice';

type TodolistSelector<T> = Selector<AppStateSubset, T>;

const selectTodoListDomain = (state: AppStateSubset): State => state[TODO_LIST];

export const makeSelectRawItems = (): TodolistSelector<TodoItemJSON[]> =>
  createSelector(selectTodoListDomain, ({ items }) => items);

export const makeSelectItems = (): TodolistSelector<TodoItem[]> =>
  createSelector(makeSelectRawItems(), items =>
    items.map(item => createTodoItem(item)),
  );

export const makeSelectItemCount = (): TodolistSelector<number> =>
  createSelector(makeSelectItems(), items => items.length);

export const makeSelectCompletedItems = (): TodolistSelector<TodoItem[]> =>
  createSelector(makeSelectItems(), items => items.filter(item => item.isDone));

export const makeSelectCompletedItemCount = (): TodolistSelector<number> =>
  createSelector(
    makeSelectCompletedItems(),
    completedTasks => completedTasks.length,
  );

export const makeSelectLoadingItemsIds = (): TodolistSelector<number[]> =>
  createSelector(
    selectTodoListDomain,
    ({ loadingItemsIds }) => loadingItemsIds,
  );

export const makeSelectIsItemLoading = (
  item: TodoItem,
): TodolistSelector<boolean> =>
  createSelector(makeSelectLoadingItemsIds(), loadingItemsIds =>
    loadingItemsIds.some(id => id === item.id),
  );

export const makeSelectIsLoading = (): TodolistSelector<boolean> =>
  createSelector(selectTodoListDomain, ({ isLoading }) => isLoading);

export const makeSelectDocumentsToUpload = (
  item: SendBatchTodoItem | RequestDocumentTodoItem,
): TodolistSelector<DealDocument[]> =>
  createSelector(
    ((makeSelectDocuments as unknown) as () => TodolistSelector<
      DealDocument[]
    >)(),
    documents =>
      documents.filter(
        doc => !doc.hasVersion && item.action?.hasDealDocument(doc.id),
      ),
  );

export const makeSelectHasBatchMissingDocs = (
  item: SendBatchTodoItem,
): TodolistSelector<boolean> =>
  createSelector(makeSelectDocumentsToUpload(item), documents =>
    documents.some(doc => !item.action.isDealDocumentOptional(doc.id)),
  );
