/*
 * StepEditors Messages
 *
 * This contains all the text for the StepEditors components.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  crematoriums: {
    id: 'app.components.DealFuneral.StepEditors.crematoriums',
    defaultMessage: 'Choose a crematorium',
  },
  defaultCeremonie: {
    id: 'app.containers.DealFuneral.StepEditors.defaultCeremonie',
    defaultMessage: 'Default ceremonie',
  },
  ceremonieHeader: {
    id: 'app.containers.DealFuneral.StepEditors.ceremonieHeader',
    defaultMessage: 'Ceremonie:',
  },
  ceremonieSelected: {
    id: 'app.containers.DealFuneral.StepEditors.ceremonieSelected',
    defaultMessage: 'Selected room:',
  },
  convivialityRoomUsage: {
    id: 'app.containers.DealFuneral.StepEditors.convivialityRoomUsage',
    defaultMessage: 'Use time of the conviviality room:',
  },
});
