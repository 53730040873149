import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { SupplierWarehouseZoneFuneralWorkJSON } from '@advitam/api/models/Supplier/Warehouse';

import { SUPPLIER_WAREHOUSE_ZONE } from './constants';
import { fetchFuneralWorks, saveFuneralWorks } from './thunk';
import { FuneralWorksFilters } from './types';

export interface State {
  funeralWorks: SupplierWarehouseZoneFuneralWorkJSON[];
  filters: FuneralWorksFilters;
  isLoading: boolean;
  isSaving: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [SUPPLIER_WAREHOUSE_ZONE]: State;
}

export const initialState: State = {
  funeralWorks: [],
  filters: {},
  isLoading: false,
  isSaving: false,
  error: null,
};

const slice = createSlice({
  name: SUPPLIER_WAREHOUSE_ZONE,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters: (state, { payload }: PayloadAction<FuneralWorksFilters>) => {
      state.filters = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFuneralWorks.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.funeralWorks = [];
    });
    builder.addCase(fetchFuneralWorks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.funeralWorks = payload;
    });
    builder.addCase(fetchFuneralWorks.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveFuneralWorks.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveFuneralWorks.fulfilled, (state, { payload }) => {
      if (payload) {
        state.funeralWorks = payload.funeralWorks;
      }
      state.isSaving = false;
    });
    builder.addCase(saveFuneralWorks.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters } = slice.actions;
export default slice;
