import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  additionalDays: {
    id: 'app.containers.Graveyard.sections.Misc.additionalDays',
  },
  additionalDaysTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.additionalDaysTooltip',
  },
  allowed: { id: 'app.containers.Graveyard.sections.Misc.allowed' },
  availableDurations: {
    id: 'app.containers.Graveyard.sections.Misc.availableDurations',
  },
  availableTypes: {
    id: 'app.containers.Graveyard.sections.Misc.availableTypes',
  },
  availableTypesPlaceholder: {
    id: 'app.containers.Graveyard.sections.Misc.availableTypesPlaceholder',
  },
  billing: { id: 'app.containers.Graveyard.sections.Misc.billing' },
  cement: { id: 'app.containers.Graveyard.sections.Misc.cement' },
  classic: { id: 'app.containers.Graveyard.sections.Misc.classic' },
  coffinBurial: { id: 'app.containers.Graveyard.sections.Misc.coffinBurial' },
  coffinBurialTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.coffinBurialTooltip',
  },
  columbarium: { id: 'app.containers.Graveyard.sections.Misc.columbarium' },
  columbariumDimensionsTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.columbariumDimensionsTooltip',
  },
  columbariumPlate: {
    id: 'app.containers.Graveyard.sections.Misc.columbariumPlate',
  },
  columbariumPlateTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.columbariumPlateTooltip',
  },
  columbariumPlateColor: {
    id: 'app.containers.Graveyard.sections.Misc.columbariumPlateColor',
  },
  columbariumPlateColorTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.columbariumPlateColorTooltip',
  },
  columbariumPlateNotProvided: {
    id: 'app.containers.Graveyard.sections.Misc.columbariumPlateNotProvided',
  },
  columbariumPlateProvided: {
    id: 'app.containers.Graveyard.sections.Misc.columbariumPlateProvided',
  },
  concessions: { id: 'app.containers.Graveyard.sections.Misc.concessions' },
  concessionsTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.concessionsTooltip',
  },
  coveredPlaces: { id: 'app.containers.Graveyard.sections.Misc.coveredPlaces' },
  coveredPlacesTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.coveredPlacesTooltip',
  },
  defaultConcessionType: {
    id: 'app.containers.Graveyard.sections.Misc.defaultConcessionType',
  },
  defaultConcessionTypeTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.defaultConcessionTypeTooltip',
  },
  dimensions: { id: 'app.containers.Graveyard.sections.Misc.dimensions' },
  dispersion: { id: 'app.containers.Graveyard.sections.Misc.dispersion' },
  dispersionTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.dispersionTooltip',
  },
  engravedGraveNumber: {
    id: 'app.containers.Graveyard.sections.Misc.engravedGraveNumber',
  },
  errorTermInUseModalTitle: {
    id: 'app.containers.Graveyard.sections.Misc.errorTermInUseModalTitle',
  },
  errorTermInUseModalText: {
    id: 'app.containers.Graveyard.sections.Misc.errorTermInUseModalText',
  },
  errorTypeInUseModalTitle: {
    id: 'app.containers.Graveyard.sections.Misc.errorTypeInUseModalTitle',
  },
  errorTypeInUseModalText: {
    id: 'app.containers.Graveyard.sections.Misc.errorTypeInUseModalText',
  },
  exhumation: { id: 'app.containers.Graveyard.sections.Misc.exhumation' },
  exhumationTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.exhumationTooltip',
  },
  fakeBox: { id: 'app.containers.Graveyard.sections.Misc.fakeBox' },
  fakeBoxTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.fakeBoxTooltip',
  },
  fakeBoxMinDurationTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.fakeBoxMinDurationTooltip',
  },
  familyBuying: { id: 'app.containers.Graveyard.sections.Misc.familyBuying' },
  familyBuyingTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.familyBuyingTooltip',
  },
  finishing: { id: 'app.containers.Graveyard.sections.Misc.finishing' },
  finishingTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.finishingTooltip',
  },
  flat: { id: 'app.containers.Graveyard.sections.Misc.flat' },
  forbidden: { id: 'app.containers.Graveyard.sections.Misc.forbidden' },
  full: { id: 'app.containers.Graveyard.sections.Misc.full' },
  fullTooltip: { id: 'app.containers.Graveyard.sections.Misc.fullTooltip' },
  functioning: { id: 'app.containers.Graveyard.sections.Misc.functioning' },
  granite: { id: 'app.containers.Graveyard.sections.Misc.granite' },
  gravel: { id: 'app.containers.Graveyard.sections.Misc.gravel' },
  gravelTooltip: { id: 'app.containers.Graveyard.sections.Misc.gravelTooltip' },
  graveyardType: { id: 'app.containers.Graveyard.sections.Misc.graveyardType' },
  includedDays: { id: 'app.containers.Graveyard.sections.Misc.includedDays' },
  includedDaysTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.includedDaysTooltip',
  },
  jewishSpace: { id: 'app.containers.Graveyard.sections.Misc.jewishSpace' },
  jewishSpaceTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.jewishSpaceTooltip',
  },
  landscapes: { id: 'app.containers.Graveyard.sections.Misc.landscapes' },
  mandatory: { id: 'app.containers.Graveyard.sections.Misc.mandatory' },
  maxDuration: { id: 'app.containers.Graveyard.sections.Misc.maxDuration' },
  memoriesGarden: {
    id: 'app.containers.Graveyard.sections.Misc.memoriesGarden',
  },
  memoriesGardenTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.memoriesGardenTooltip',
  },
  minDuration: { id: 'app.containers.Graveyard.sections.Misc.minDuration' },
  muslimSpace: { id: 'app.containers.Graveyard.sections.Misc.muslimSpace' },
  muslimSpaceTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.muslimSpaceTooltip',
  },
  openingFees: { id: 'app.containers.Graveyard.sections.Misc.openingFees' },
  openingFeesTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.openingFeesTooltip',
  },
  perpetually: { id: 'app.containers.Graveyard.sections.Misc.perpetually' },
  place: { id: 'app.containers.Graveyard.sections.Misc.place' },
  requiredMaterial: {
    id: 'app.containers.Graveyard.sections.Misc.requiredMaterial',
  },
  requiredMaterialTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.requiredMaterialTooltip',
  },
  requiredShape: { id: 'app.containers.Graveyard.sections.Misc.requiredShape' },
  requiredShapeTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.requiredShapeTooltip',
  },
  semelle: { id: 'app.containers.Graveyard.sections.Misc.semelle' },
  semelleTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.semelleTooltip',
  },
  semelleDimensionsTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.semelleDimensionsTooltip',
  },
  semelleMinDurationTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.semelleMinDurationTooltip',
  },
  stele: { id: 'app.containers.Graveyard.sections.Misc.stele' },
  steleTooltip: { id: 'app.containers.Graveyard.sections.Misc.steleTooltip' },
  temporaryVault: {
    id: 'app.containers.Graveyard.sections.Misc.temporaryVault',
  },
  urnBurial: { id: 'app.containers.Graveyard.sections.Misc.urnBurial' },
  urnBurialTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.urnBurialTooltip',
  },
  vault: { id: 'app.containers.Graveyard.sections.Misc.vault' },

  vaultTooltip: { id: 'app.containers.Graveyard.sections.Misc.vaultTooltip' },
  vaultMaxDurationTooltip: {
    id: 'app.containers.Graveyard.sections.Misc.vaultMaxDurationTooltip',
  },
  years: { id: 'app.containers.Graveyard.sections.Misc.years' },
  waterLapel: { id: 'app.containers.Graveyard.sections.Misc.waterLapel' },
  works: { id: 'app.containers.Graveyard.sections.Misc.works' },
  worship: { id: 'app.containers.Graveyard.sections.Misc.worship' },
});
