import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { NumberInput } from 'components/Input';
import messages from 'containers/Deal/DealFuneral/DefunctSection/messages';

class DefunctSize extends Component {
  /**
   * Update field
   *
   * @param {string} name key to update
   * @param {string} value value of key to update
   */
  updateField = (name, value) => {
    const { updateDefunctCompInfo, defunctCompInfo } = this.props;
    updateDefunctCompInfo({
      ...defunctCompInfo,
      [name]: value,
    });
  };

  render() {
    const { defunctCompInfo } = this.props;

    if (!defunctCompInfo) return null;

    return (
      <div className="defunct-size">
        <div className="defunct-size__field">
          <FormattedMessage id={messages.weight.id} tagName="span" />
          <NumberInput
            value={defunctCompInfo.weight || ''}
            onChange={({ target: { name, value } }) => {
              let val = value;
              if (value === '') {
                val = null;
              } else {
                val = parseInt(value, 10);
              }
              this.updateField(name, val);
            }}
            name="weight"
          />
        </div>
        <div className="defunct-size__field">
          <FormattedMessage id={messages.size.id} tagName="span" />
          <NumberInput
            value={defunctCompInfo.size || ''}
            onChange={e => this.updateField(e.target.name, e.target.value)}
            name="size"
          />
        </div>
        <div className="defunct-size__field">
          <FormattedMessage id={messages.width.id} tagName="span" />
          <NumberInput
            value={defunctCompInfo.width || ''}
            onChange={e => this.updateField(e.target.name, e.target.value)}
            name="width"
          />
        </div>
        <div className="defunct-size__field">
          <FormattedMessage id={messages.height.id} tagName="span" />
          <NumberInput
            value={defunctCompInfo.height || ''}
            onChange={e => this.updateField(e.target.name, e.target.value)}
            name="height"
          />
        </div>
      </div>
    );
  }
}

DefunctSize.propTypes = {
  /** function to update defunct comp info */
  updateDefunctCompInfo: PropTypes.func.isRequired,
  /** defunct comp info data */
  defunctCompInfo: PropTypes.object,
};

export default DefunctSize;
