import { ReactNode } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'

import style from './Modal.module.scss'

export interface ModalProps {
  isOpen: boolean
  className?: string
  overlayClassName?: string
  preventScroll?: ReactModalProps['preventScroll']
  shouldCloseOnEsc?: ReactModalProps['shouldCloseOnEsc']
  shouldCloseOnOverlayClick?: ReactModalProps['shouldCloseOnOverlayClick']
  children: ReactNode | ReactNode[]
  onAfterClose?: ReactModalProps['onAfterClose']
  onAfterOpen?: ReactModalProps['onAfterOpen']
  onRequestClose?: ReactModalProps['onRequestClose']
}

export default function Modal({
  isOpen,
  children,
  className = '',
  overlayClassName = '',
  preventScroll,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  onAfterClose,
  onAfterOpen,
  onRequestClose,
}: ModalProps): JSX.Element {
  return (
    <ReactModal
      isOpen={isOpen}
      className={[style.content, className].join(' ')}
      overlayClassName={[style.overlay, overlayClassName].join(' ')}
      preventScroll={preventScroll}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterClose={onAfterClose}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
    >
      {children}
    </ReactModal>
  )
}
