import { useState, MouseEvent, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import { Strings, isEqual } from '@advitam/support'

import MagnifyingGlass from '../../../../images/icons/man-glass.svg'
import ButtonText from '../../../ButtonText'
import Text from '../../../Text'
import TransparentButton from '../../../TransparentButton'
import Dropdown from '../Dropdown'

import uiStyle from '../UI.module.scss'
import style from './Select.module.scss'
import Label from '../parts/Label'
import ClearButton from '../parts/ClearButton'
import ClearIcon from '../../../../images/icons/times-circle.svg'
import messages from './messages'

function isSet<T>(value: T): boolean {
  if (Array.isArray(value)) {
    return value.length !== 0
  }
  return value !== undefined
}

function defaultRenderElement<Value>(item: SelectableItem<Value>, selected: boolean): JSX.Element {
  return (
    <span className={`${style.elem_inner} ${selected ? style.elem_selected : ''}`}>
      {item.name}
    </span>
  )
}

/** @deprecated This have no use anymore */
export type IValue = number | string | boolean

// TODO: Make template type required (or unknown by default)
export interface SelectableItem<Value = IValue> {
  value: Value
  name: string
  group?: string
}

interface SelectProps<Value> {
  name?: string
  items: SelectableItem<Value>[]
  groupTitles?: Record<string, string>
  value?: Value | Value[]
  placeholder?: ReactNode
  onChange: (value?: Value | Value[]) => void
  multiple?: boolean
  filter?: boolean
  contained?: boolean
  unselectable?: boolean
  className?: string
  selectClassName?: string
  label?: ReactNode
  error?: boolean
  disabled?: boolean
  tooltip?: ReactNode
  expandOnTop?: boolean
  renderPlaceholder?: (item: Value) => JSX.Element
  renderElement?: (item: SelectableItem<Value>, selected: boolean) => JSX.Element
}

// TODO: We should handle onKeyDown/up + when the user press enter
export default function Select<Value>({
  name,
  items,
  groupTitles,
  value,
  onChange,
  placeholder = '',
  multiple = false,
  filter = false,
  contained,
  className,
  selectClassName,
  unselectable,
  label,
  error,
  disabled,
  tooltip,
  expandOnTop = false,
  renderPlaceholder,
  renderElement = defaultRenderElement,
}: SelectProps<Value>): JSX.Element {
  const [filterText, setFilterText] = useState('')

  const onSelect = (item: SelectableItem<Value>): void => {
    let newValue: Value | Value[] | undefined

    if (multiple) {
      const currentValue = value as Value[] | undefined

      if (!currentValue) {
        newValue = [item.value]
      } else if (currentValue.some(v => isEqual(item.value, v))) {
        newValue = currentValue.filter(v => !isEqual(v, item.value))
      } else {
        newValue = [...currentValue, item.value]
      }
    } else if (!unselectable || value !== item.value) {
      newValue = item.value
    }

    onChange(newValue)
  }

  const isSelected = (v: Value): boolean =>
    multiple
      ? value !== undefined && (value as Value[]).some(elem => isEqual(elem, v))
      : isEqual(value, v)

  const normalizeSearch = (text: string): string => Strings.deburr(text.toLowerCase())

  const renderFilter = (): JSX.Element | null => {
    if (!filter) {
      return null
    }

    return (
      <div className={style.filter}>
        <div className={style.filter__inside_box}>
          <MagnifyingGlass />
          <input
            value={filterText}
            onChange={({ target: { value: text } }): void => setFilterText(normalizeSearch(text))}
          />
          {filterText && (
            <ClearButton
              className={style.clear}
              onClick={(): void => {
                setFilterText('')
              }}
            />
          )}
        </div>
      </div>
    )
  }

  const isFiltered = (item: SelectableItem<Value>): boolean => {
    if (!filter || filterText === '') {
      return false
    }

    return !normalizeSearch(item.name).includes(filterText)
  }

  const renderItem = (item: SelectableItem<Value>): JSX.Element | null => {
    if (isFiltered(item)) {
      return null
    }

    return (
      <TransparentButton
        key={JSON.stringify(item.value)}
        className={style.elem}
        onClick={(event: MouseEvent<HTMLElement>): void => {
          onSelect(item)
          event.preventDefault()
        }}
      >
        {renderElement(item, isSelected(item.value))}
      </TransparentButton>
    )
  }

  function getPlaceholder(): ReactNode {
    const getItem =
      renderPlaceholder ||
      ((v: Value): ReactNode => items.find(item => isEqual(item.value, v))?.name)

    if (!multiple) {
      return getItem(value as Value) || placeholder
    }

    const values = value as Value[]
    if (!values || values.length === 0) {
      return placeholder
    }

    if (values.length === 1) {
      return <ButtonText>{getItem(values[0]) || placeholder}</ButtonText>
    }

    return (
      <>
        <ButtonText>{placeholder}</ButtonText>
        <Text small className={style.selected_count}>
          <b>{values.length}</b>
        </Text>
      </>
    )
  }

  const groupedItems: Record<string, SelectableItem<Value>[]> = {}
  items.forEach(item => {
    groupedItems[item.group || ''] ||= []
    groupedItems[item.group || ''].push(item)
  })
  const renderedItems = Object.entries(groupedItems).flatMap(([group, groupItems]) => {
    const render: Array<JSX.Element | null> = []
    if (groupTitles && group) {
      render.push(
        <Text tagName="div" className={style.group_title} key={group}>
          {groupTitles[group]}
        </Text>,
      )
    }
    return render.concat(groupItems.map(item => renderItem(item)))
  })
  const hasItems = renderedItems.some(item => item !== null)

  const selectClasses = [style.select, 'thin-scrollbar', selectClassName].filter(Boolean)
  const dropdownClasses = [
    error && uiStyle.error,
    disabled && uiStyle.disabled,
    className,
    isSet(value) && uiStyle.filled,
  ].filter(Boolean)

  const showClearSelection = Boolean(filter && value && (multiple || unselectable))

  // TODO: The DropDown should not close if we click the filter's input
  // filter should not be in closeOnClick
  return (
    <div className={uiStyle.field}>
      <Label value={label} tooltip={tooltip} />
      <input type="hidden" name={name} />
      <Dropdown
        expandOnTop={expandOnTop}
        placeholder={getPlaceholder()}
        closeOnClick={!multiple && !filter}
        contained={contained}
        disabled={disabled}
        className={dropdownClasses.length !== 0 ? dropdownClasses.join(' ') : undefined}
      >
        {renderFilter()}
        {showClearSelection && (
          <TransparentButton
            className={style.delete_elem}
            onClick={(event: MouseEvent<HTMLElement>): void => {
              onChange(undefined)
              event.preventDefault()
            }}
          >
            <Text small>
              <FormattedMessage id={messages.clearSelection.id} />
              <ClearIcon />
            </Text>
          </TransparentButton>
        )}
        {hasItems && <div className={selectClasses.join(' ')}>{renderedItems}</div>}
      </Dropdown>
    </div>
  )
}
