// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Label-module__label--sdeNR{font-weight:400;font-size:14px;line-height:24px;letter-spacing:2px;text-transform:uppercase}.Label-module__label--sdeNR b{font-weight:900}`, "",{"version":3,"sources":["webpack://./../ui/components/Label/Label.module.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,wBAAA,CAEA,8BACE,eAAA","sourcesContent":[".label {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  letter-spacing: 2px;\n  text-transform: uppercase;\n\n  b {\n    font-weight: 900;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `Label-module__label--sdeNR`
};
export default ___CSS_LOADER_EXPORT___;
