import { authenticate } from '../../lib/decorators'
import { post } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { ConflictJSON } from '../../models/DocumentEditions'
import { Documents } from './Documents'
import { DocumentUpload } from './types'

interface CreationResponsePayload {
  conflicts: ConflictJSON[]
}

export class DocumentEditions {
  static readonly Documents = Documents

  @authenticate
  static create(
    documents: DocumentUpload[],
    shouldBypassConflicts?: boolean,
  ): ApiRequestDescriptor<CreationResponsePayload> {
    return post('/api/v1/document_editions', {
      documents,
      bypass_conflicts: shouldBypassConflicts,
    })
  }
}
