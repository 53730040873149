import { ReactNode, useState } from 'react'

import Collapsible, { ChevronPosition } from '../../Collapsible'
import Title from './parts/Title'
import style from './Section.module.scss'

interface SectionProps {
  title: ReactNode
  totalPrice: ReactNode
  isOpenByDefault?: boolean
  children: ReactNode[]
}

export default function Section({
  title,
  totalPrice,
  isOpenByDefault = false,
  children,
}: SectionProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)
  const classes = [style.section, isOpen && style.open].filter(Boolean)

  return (
    <Collapsible
      withTitleSeparator
      isOpen={isOpen}
      chevronPosition={ChevronPosition.LEFT}
      onToggle={(): void => setIsOpen(!isOpen)}
      title={<Title message={title} price={totalPrice} />}
      className={classes.join(' ')}
    >
      {children}
    </Collapsible>
  )
}
