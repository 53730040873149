import { useMemo } from 'react';
import { useForm } from 'react-form';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';

import CustomFormWrapper from 'components/ReactFormComponent/CustomFormWrapper';
import { Service } from 'models/Deal/Service';
import { DealPrestationComputeType } from 'models/Deal/Prestation/ComputeType';
import { assert } from 'lib/support';

import { TRIGGERABLE_TYPES } from '../constants';
import { addTriggerPingService, removeTriggerPingService } from '../actions.js';
import UpdateServiceFactory from './Factory';
import { resetFuneralWorks } from './utils';
import style from './style.scss';

interface UpdateServiceModalProps {
  isOpen: boolean;
  service: Service | null;
  updateService: (updatedService: Service) => void;
  onClose: () => void;
}

export default function UpdateServiceModal({
  isOpen,
  service,
  updateService,
  onClose,
}: UpdateServiceModalProps): JSX.Element | null {
  const dispatch = useDispatch();
  const defaultValues = useMemo(
    () => ({ ...service, funeralWorks: service?.funeralWorks || {} }),
    [service],
  );

  const onReset = (compute: DealPrestationComputeType): void => {
    assert(service !== null);
    const resetService: Service = {
      ...service,
      funeralWorks: resetFuneralWorks(service.funeralWorks),
      compute,
    };

    dispatch(removeTriggerPingService(resetService));
    updateService(resetService);
    onClose();
  };

  const handleSubmit = (values: Service): void => {
    const updatedService: Service = {
      ...values,
      compute: DealPrestationComputeType.MANUAL,
    };

    if (TRIGGERABLE_TYPES.includes(updatedService.type)) {
      dispatch(addTriggerPingService(updatedService));
    }

    updateService(updatedService);
    onClose();
  };

  const { Form } = useForm({
    onSubmit: handleSubmit,
    defaultValues,
  });

  if (!service) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="dialog"
      onRequestClose={onClose}
      ariaHideApp={false}
      className="modal"
      style={{
        overlay: {
          zIndex: 10,
        },

        content: {
          position: 'relative',
          overflow: 'visible',
          borderRadius: '3px',
          padding: '30px',
          margin: '100px auto',
          display: 'flex',
          flexFlow: 'column',
        },
      }}
    >
      <Form noFormElement>
        <CustomFormWrapper className={style.form} blockEvent>
          <UpdateServiceFactory service={service} onReset={onReset} />
        </CustomFormWrapper>
      </Form>
    </Modal>
  );
}
