import { ReactNode } from 'react'
import type { ITrackProps } from 'react-range/lib/types'

import style from './Range.module.scss'

interface TrackProps {
  props: ITrackProps
  value: number
  max: number
  children: ReactNode
}

export default function Track({ props, children, max, value }: TrackProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <span {...props} className={style.track}>
      <span className={style.track_background} />
      <span className={style.track_outline} style={{ width: `${(100 * value) / max}%` }} />
      <span className={`${style.thumb} ${style.start_thumb}`} />
      {children}
    </span>
  )
}
