import { authenticate } from '../../../lib/decorators'
import { ApiRequestDescriptor } from '../../../lib/request'
import { get, post, del } from '../../../lib/methods'
import { BusinessUserJSON } from '../../../models/User/BusinessUser'
import { Password } from './Password'

export interface AuthResponse<T = undefined> {
  success: boolean
  data: T
}

export interface Auth<T = undefined> {
  Response: AuthResponse<T>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class Auth<T = undefined> {
  static readonly Password = Password

  static signIn(
    email: string,
    password: string,
  ): ApiRequestDescriptor<AuthResponse<BusinessUserJSON>> {
    return post('/api/v1/pro/auth/sign_in', { email, password })
  }

  @authenticate
  static signOut(): ApiRequestDescriptor<AuthResponse> {
    return del('/api/v1/pro/auth/sign_out')
  }

  @authenticate
  static validateToken(): ApiRequestDescriptor<AuthResponse<BusinessUserJSON>> {
    return get('/api/v1/pro/auth/validate_token')
  }
}
