import { ApiError, SerializedApiError } from '@advitam/api';
import { createSlice } from '@reduxjs/toolkit';

import type { ClientAbilityJSON } from '@advitam/api/models/Client/Ability';

import { DEAL_IDENTITY } from './constants';
import { fetchAbilities, updateAbilities } from './thunk';

export interface State {
  abilities: ClientAbilityJSON[];
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DEAL_IDENTITY]: State;
}

const initialState: State = {
  abilities: [],
  error: null,
};

const slice = createSlice({
  name: DEAL_IDENTITY,
  initialState,
  reducers: {
    reset(state) {
      /* eslint-disable no-param-reassign */
      state.abilities = [];
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAbilities.pending, state => {
      state.error = null;
    });
    builder.addCase(fetchAbilities.fulfilled, (state, { payload }) => {
      state.abilities = payload;
    });
    builder.addCase(fetchAbilities.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateAbilities.pending, state => {
      state.error = null;
    });
    builder.addCase(updateAbilities.fulfilled, (state, { payload }) => {
      if (payload) {
        state.abilities = payload;
      }
    });
    builder.addCase(updateAbilities.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { reset } = slice.actions;
export default slice;
