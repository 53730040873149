import { DealKoReason } from '@advitam/api/models/Deal/KoReason';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

interface FormattedKoReasonProps {
  reason: DealKoReason | null;
  comment: string | null;
}

export function FormattedKoReason({
  reason,
  comment,
}: FormattedKoReasonProps): JSX.Element | null {
  if (!reason) {
    return null;
  }

  if (reason === DealKoReason.OTHER) {
    if (comment === null) {
      return null;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{comment}</>;
  }

  switch (reason) {
    case DealKoReason.ANTICIPATION:
      return <FormattedMessage id={messages.anticipation.id} />;
    case DealKoReason.DUPLICATE:
      return <FormattedMessage id={messages.duplicate.id} />;
    case DealKoReason.NOT_PROVIDED_SERVICE:
      return <FormattedMessage id={messages.notProvidedService.id} />;
    case DealKoReason.PHYSICAL_STORE:
      return <FormattedMessage id={messages.physicalStore.id} />;
    case DealKoReason.TOO_EXPENSIVE:
      return <FormattedMessage id={messages.tooExpensive.id} />;
    case DealKoReason.UNREACHABLE:
      return <FormattedMessage id={messages.unreachable.id} />;
    default:
      return <FormattedMessage id={messages.other.id} />;
  }
}
