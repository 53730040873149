// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Miscellaneous-module__dependency_row--duBaG{flex-direction:row}.Miscellaneous-module__dependency_row--duBaG>div{flex:1}.Miscellaneous-module__add_dependency--po6jQ{align-self:flex-end;margin-left:16px}.Miscellaneous-module__add_dependency--po6jQ button{width:40px;height:40px}.Miscellaneous-module__add_dependency--po6jQ svg{width:20px}.Miscellaneous-module__dependency--e_LqU{padding:4px 8px}.Miscellaneous-module__dependency--e_LqU:not(:last-child){margin-bottom:16px}.Miscellaneous-module__prestation_type--FUB0K{margin-top:12px}.Miscellaneous-module__prestation_type--FUB0K:not(:last-child){margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/containers/Supplier/sections/Miscellaneous/Miscellaneous.module.scss","webpack://./../ui/components/Form/UI/_vars.module.scss"],"names":[],"mappings":"AAGA,6CACE,kBAAA,CAEA,iDACE,MAAA,CAIJ,6CACE,mBAAA,CACA,gBAAA,CAEA,oDACE,UCZqB,CDarB,WCbqB,CDgBvB,iDACE,UAAA,CAIJ,yCACE,eAAA,CAEA,0DACE,kBAAA,CAIJ,8CACE,eAAA,CAEA,+DACE,iBAAA","sourcesContent":["@import '~@advitam/ui/scss/media';\n@import '~@advitam/ui/components/Form/UI/_vars.module';\n\n.dependency_row {\n  flex-direction: row;\n\n  > div {\n    flex: 1;\n  }\n}\n\n.add_dependency {\n  align-self: flex-end;\n  margin-left: 16px;\n\n  button {\n    width: $input__height--compact;\n    height: $input__height--compact;\n  }\n\n  svg {\n    width: 20px;\n  }\n}\n\n.dependency {\n  padding: 4px 8px;\n\n  &:not(:last-child) {\n    margin-bottom: 16px;\n  }\n}\n\n.prestation_type {\n  margin-top: 12px;\n\n  &:not(:last-child) {\n    margin-right: 12px;\n  }\n}\n","$input__padding: 16px;\n$input__height: 56px;\n\n$input__padding--compact: 8px;\n$input__height--compact: 40px;\n\n$clear-button__size: 36px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"dependency_row": `Miscellaneous-module__dependency_row--duBaG`,
	"add_dependency": `Miscellaneous-module__add_dependency--po6jQ`,
	"dependency": `Miscellaneous-module__dependency--e_LqU`,
	"prestation_type": `Miscellaneous-module__prestation_type--FUB0K`
};
export default ___CSS_LOADER_EXPORT___;
