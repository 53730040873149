import { FormattedMessage } from 'react-intl';

import { FormLayout } from '@advitam/ui';

import {
  AirlineAutocomplete,
  AirportAutocomplete,
  SupplierAutocomplete,
} from 'containers/Flight/Form';
import messages from './messages';

export default function Content(): JSX.Element {
  return (
    <>
      <FormLayout.Row>
        <AirportAutocomplete
          name="departure_airport"
          label={<FormattedMessage id={messages.departure.id} />}
          required
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <AirportAutocomplete
          name="arrival_airport"
          label={<FormattedMessage id={messages.arrival.id} />}
          required
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <SupplierAutocomplete
          label={<FormattedMessage id={messages.supplier.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <AirlineAutocomplete
          name="airline"
          label={<FormattedMessage id={messages.company.id} />}
          expandOnTop
        />
      </FormLayout.Row>
    </>
  );
}
