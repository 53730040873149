import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';

import { GRAVEYARD_MISC } from './constants';
import { save, saveConcessionTerms, saveConcessionTypes } from './thunk';

export interface State {
  isSaving: boolean;
  hasAlreadyInUseTermError: boolean;
  hasAlreadyInUseTypeError: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [GRAVEYARD_MISC]: State;
}

export const initialState: State = {
  isSaving: false,
  hasAlreadyInUseTermError: false,
  hasAlreadyInUseTypeError: false,
  error: null,
};

const slice = createSlice({
  name: GRAVEYARD_MISC,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearhasAlreadyInUseTermError: state => {
      state.hasAlreadyInUseTermError = false;
    },
    clearhasAlreadyInUseTypeError: state => {
      state.hasAlreadyInUseTypeError = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(save.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(save.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(save.rejected, state => {
      state.isSaving = false;
    });

    builder.addCase(saveConcessionTerms.fulfilled, (state, { payload }) => {
      state.hasAlreadyInUseTermError = payload.hasAlreadyInUseTermError;
    });
    builder.addCase(saveConcessionTerms.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveConcessionTypes.fulfilled, (state, { payload }) => {
      state.hasAlreadyInUseTypeError = payload.hasAlreadyInUseTypeError;
    });
    builder.addCase(saveConcessionTypes.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearhasAlreadyInUseTermError } = slice.actions;
export default slice;
