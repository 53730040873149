import { authenticate } from '../../../lib/decorators'
import { get } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { EntityGeoshapesKeyJSON } from '../../../models/Entity/GeoshapesKey'

export class Keys {
  @authenticate
  static index(): ApiRequestDescriptor<EntityGeoshapesKeyJSON[]> {
    return get('/api/v1/prestations/coverage/keys')
  }
}
