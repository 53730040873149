import { Settings } from 'luxon';
import famsFr from '@advitam/fams/src/translations/fr.json';
import uiFr from '@advitam/ui/translations/fr.json';
import appFr from './translations/fr.json';

export const enum AppLocale {
  FR = 'fr',
}

export const DEFAULT_LOCALE = AppLocale.FR;

export const appLocales = [AppLocale.FR];
Settings.defaultLocale = DEFAULT_LOCALE;

const fr: Record<string, string> = { ...appFr, ...uiFr, ...famsFr };
export const translationMessages = { fr };
