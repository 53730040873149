import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { withSlice } from '@advitam/react';
import { EditableDocumentJSON } from '@advitam/api/models/Documents/Editable';
import { LocationType } from '@advitam/api/models/LocationType';
import Layout from 'containers/Crud/Layout';
import {
  EditableDocuments,
  editableDocumentsSlice,
  makeSelectEditableDocuments,
  saveEditableDocuments,
} from 'containers/Crud';
import { assert } from 'lib/support';

import { PolymorphicEntity } from '@advitam/api/models/PolymorphicEntity';
import Wrapper from './Wrapper';
import { makeSelectRawCityhall } from '../selectors';
import { CityhallForm } from './types';
import messages from './messages';

function EditableDocumentsSection(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const cityhall = useSelector(makeSelectRawCityhall());
  assert(cityhall?.id !== undefined);
  const documents = useSelector(makeSelectEditableDocuments());

  const entity: PolymorphicEntity = {
    type: LocationType.CITYHALL,
    id: cityhall.id,
  };

  const onSubmit = useCallback(
    ({ sectionValues }: CityhallForm<EditableDocumentJSON[]>) => {
      dispatch(saveEditableDocuments({ values: sectionValues, entity }));
    },
    [entity.type, entity.id],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={documents}>
      <Layout.Container>
        <EditableDocuments
          entity={entity}
          entityRules={[
            {
              name: intl.formatMessage(messages.deathCityhall),
              value: 'id-death_cityhall',
            },
            {
              name: intl.formatMessage(messages.closingCityhall),
              value: 'id-closing_cityhall',
            },
            {
              name: intl.formatMessage(messages.cleaningCityhall),
              value: 'id-cleaning_cityhall',
            },
            {
              name: intl.formatMessage(messages.cremationCityhall),
              value: 'id-crema_cityhall',
            },
            {
              name: intl.formatMessage(messages.inhumationCityhall),
              value: 'id-inhum_cityhall',
            },
            {
              name: intl.formatMessage(messages.endTacCityhall),
              value: 'id-end_tac_cityhall',
            },
            {
              name: intl.formatMessage(messages.startTacCityhall),
              value: 'id-start_tac_cityhall',
            },
            {
              name: intl.formatMessage(messages.endTbcCityhall),
              value: 'id-end_tbc_cityhall',
            },
            {
              name: intl.formatMessage(messages.startTbcCityhall),
              value: 'id-start_tbc_cityhall',
            },
          ]}
        />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(editableDocumentsSlice)(EditableDocumentsSection);
