import { Objects } from '@advitam/support'
import { FuneralParlorJSON } from '../../models/FuneralParlor'
import { FuneralParlorBody } from './types'

type FuneralParlorPayload = Partial<
  Omit<FuneralParlorJSON, 'id' | 'country' | 'headquarter_country'> & {
    country_code: string | null
    headquarter_country_code: string | null
  }
>

export function serialize(funeralParlor: Omit<FuneralParlorBody, 'id'>): FuneralParlorPayload {
  return {
    ...Objects.omit(funeralParlor, 'country', 'headquarter_country'),
    country_code:
      funeralParlor.country === undefined ? undefined : funeralParlor.country?.code || null,
    headquarter_country_code:
      funeralParlor.headquarter_country === undefined
        ? undefined
        : funeralParlor.headquarter_country?.code || null,
  }
}
