import { Deal } from 'models/Deal';
import { assert } from 'lib/Assert';

import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { post } from 'api/methods';
import { SharingSpaceUrl } from 'utils/urls';

export class Welcome {
  @authenticate
  static create(deal: Deal): ApiRequestDescriptor<void> {
    assert(deal.uuid !== undefined);
    return post(`/api/v1/deals/${deal.uuid}/welcome`, {
      deal_url: [SharingSpaceUrl.DEAL, deal.id].join('/'),
      redirect_url: SharingSpaceUrl.CREATE_ACCOUNT,
    });
  }
}
