import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useThunkDispatch } from '@advitam/react';
import CustomModal, {
  ButtonsValidation,
  ConfirmButton,
  Button as ButtonCancel,
} from 'components/CustomModal';
import { ButtonV2 as Button } from 'components/Button';
import { ClientJSON } from 'models/Client';
import messagesAction from 'messages/actions';

import { unwrapResult } from '@reduxjs/toolkit';
import messages from './messages';
import { CollapsablePerson } from '../EditPerson/index.js';
import { duplicateDeal } from '../Sections/Home/DuplicationButton/thunk';
import {
  makeSelectDealClients,
  makeSelectRealClientCount,
  makeSelectIsDeletingRequiredClientModalOpen,
  makeSelectEmailErrors,
} from './selectors';
import {
  add,
  closeDeletingRequiredClientModal as closeDeletingRequiredClientModalAction,
} from './slice';
import { destroyAbility, updateClientsState } from './thunk';
import AdditionalFields from './AdditionalFields';
import style from './style.scss';
import { error as setError } from '../actions.js';

interface ClientsProps {
  withLink: boolean;
  withDuplication?: boolean;
  singleClient?: boolean;
}

export default function Clients({
  withLink,
  withDuplication,
  singleClient,
}: ClientsProps): JSX.Element {
  const dispatch = useThunkDispatch();
  const errors = useSelector(makeSelectEmailErrors());

  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = (): void => setModalOpen(false);

  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const closeErrorModal = (): void => setErrorModalOpen(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      setErrorModalOpen(true);
    }
  }, [errors]);

  const clients = useSelector(makeSelectDealClients());
  const clientCount = useSelector(makeSelectRealClientCount());
  const isDeletingRequiredClientModalOpen = useSelector(
    makeSelectIsDeletingRequiredClientModalOpen(),
  );

  const closeDeletingRequiredClientModal = (): void => {
    dispatch(closeDeletingRequiredClientModalAction());
  };

  const removeClient = (index: number): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(destroyAbility(index));
  };

  const addClient = (): void => {
    dispatch(add());
  };

  const updateClient = (client: ClientJSON, idx: number): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(updateClientsState({ idx, client }));
  };

  const onDuplicate = (): void => {
    closeModal();
    dispatch(duplicateDeal())
      .then(unwrapResult)
      .catch(err => {
        dispatch(setError(err));
      });
  };

  /* eslint-disable react/no-array-index-key */
  return (
    <section className={style.container}>
      <CustomModal
        isOpen={isDeletingRequiredClientModalOpen}
        onRequestClose={closeDeletingRequiredClientModal}
      >
        <FormattedMessage {...messages.requiredClient} />
        <ButtonsValidation>
          <ButtonCancel onClick={closeDeletingRequiredClientModal}>
            <FormattedMessage {...messagesAction.ok} />
          </ButtonCancel>
        </ButtonsValidation>
      </CustomModal>
      {withDuplication && (
        <CustomModal isOpen={isModalOpen} onRequestClose={closeModal}>
          <FormattedMessage {...messages.duplicateMessage} />
          <ButtonsValidation>
            <ButtonCancel onClick={closeModal}>
              <FormattedMessage {...messagesAction.cancel} />
            </ButtonCancel>
            <ConfirmButton onClick={onDuplicate}>
              <FormattedMessage {...messagesAction.confirm} />
            </ConfirmButton>
          </ButtonsValidation>
        </CustomModal>
      )}
      <CustomModal isOpen={isErrorModalOpen} onRequestClose={closeErrorModal}>
        {errors.map(error => (
          <div key={error.email}>
            <FormattedMessage
              {...messages.errorWithEmail}
              values={{ email: error.email }}
            />
            {error.errors.map(err => (
              <div key={`${error.email}-${err}`}>
                <FormattedMessage {...messages[err as keyof typeof messages]} />
              </div>
            ))}
          </div>
        ))}
        <ButtonsValidation>
          <ButtonCancel onClick={closeErrorModal}>
            <FormattedMessage {...messagesAction.ok} />
          </ButtonCancel>
        </ButtonsValidation>
      </CustomModal>
      <FormattedMessage tagName="h2" {...messages.clientsSection} />
      {clients.map(
        (client, index) =>
          !client.isDestroyed && (
            <CollapsablePerson
              key={`-${index}`}
              person={client}
              handleUpdate={(upd: ClientJSON): void => updateClient(upd, index)}
              message={messages.clientCollapse}
              defaultOpen
              withRemove={clientCount > 1}
              removePerson={(): void => removeClient(index)}
              withLink={withLink}
              withContact
              withAddress
              withBirthDate
              people={index}
            >
              <AdditionalFields client={client} idx={index} />
            </CollapsablePerson>
          ),
      )}
      <div className={style.actions}>
        {withDuplication && (
          <Button onClick={(): void => setModalOpen(true)}>
            <FormattedMessage id={messages.duplicateDeal.id} />
          </Button>
        )}
        {!singleClient && (
          <Button onClick={(): void => addClient()}>
            <FormattedMessage id={messages.addClient.id} />
          </Button>
        )}
      </div>
    </section>
  );
  /* eslint-enable react/no-array-index-key */
}

Clients.propTypes = {
  withLink: PropTypes.bool.isRequired,
  withDuplication: PropTypes.bool,
  singleClient: PropTypes.bool,
};
