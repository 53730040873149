const FONT_IDS = [
  '1475496', // Avenir® W01 35 Light
  '1475502', // Avenir® W01 35 Light Oblique
  // '1475508', // Avenir® W01 45 Book
  // '1475514', // Avenir® W01 45 Book Oblique
  '1475520', // Avenir® W01 55 Roman
  // '1475526', // Avenir® W01 55 Oblique
  // '1475532', // Avenir® W01 65 Medium
  // '1475538', // Avenir® W01 65 Medium Oblique
  '5687390', // Avenir® W01 85 Heavy
  // '1475550', // Avenir® W01 85 Heavy Oblique
  '1475556', // Avenir® W01 95 Black
  // '1475562', // Avenir® W01 95 Black Oblique
]

export default function MonotypeTracking(): JSX.Element | null {
  if (!process.env.MT_USER_ID) {
    return null
  }

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
          var MTUserId = '${process.env.MT_USER_ID}';
          var MTFontIds = [${FONT_IDS.join(',')}];
          (function() {
              var mtTracking = document.createElement('script');
              mtTracking.type = 'text/javascript';
              mtTracking.async = 'true';
              mtTracking.src = 'mtiFontTrackingCode.js';
              (document.getElementsByTagName('head')[0]||document.getElementsByTagName('body')[0]).appendChild(mtTracking);
          })();
        `,
      }}
    />
  )
}
