import { createAsyncThunk } from '@reduxjs/toolkit'

import Api, { request } from '@advitam/api'
import { assert } from '@advitam/support'

import { PROF } from './constants'
import type { ChatWebsocket } from './Conversation/ChatWebsocket'
import { Message } from './types'

interface CreateConversationPayload {
  chatbotSlug: string
  initialQuestion?: string
}

export const createConversation = createAsyncThunk(
  `${PROF}/CREATE_CONVERSATION`,
  async ({ chatbotSlug }: CreateConversationPayload, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.Chat.V1.Conversations.create(chatbotSlug))
      return body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

interface AskQuestionPayload {
  question: string
  showQuestion?: boolean
  websocket: ChatWebsocket
}

export const askQuestion = createAsyncThunk(
  `${PROF}/ASK_QUESTION`,
  async ({ question, websocket }: AskQuestionPayload, { rejectWithValue }) => {
    try {
      await websocket.sendQuestion(question)
      return undefined
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

interface SetFeedbackPayload {
  message: Message
  useful: boolean
}

export const setFeedback = createAsyncThunk(
  `${PROF}/SET_FEEDBACK`,
  async ({ message, useful }: SetFeedbackPayload, { rejectWithValue }) => {
    assert(message.message.runId !== null)

    try {
      if (message.message.useful === useful) {
        await request(Api.Chat.V1.Messages.Feedbacks.destroy(message.message.runId))
      } else if (message.message.useful === null) {
        await request(Api.Chat.V1.Messages.Feedbacks.create(message.message.runId, useful))
      } else {
        await request(Api.Chat.V1.Messages.Feedbacks.update(message.message.runId, useful))
      }
      return undefined
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const reportCopy = createAsyncThunk(
  `${PROF}/REPORT_COPY`,
  async (message: Message, { rejectWithValue }) => {
    assert(message.message.runId !== null)

    try {
      await request(Api.Chat.V1.Messages.Copies.create(message.message.runId))
      return undefined
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
