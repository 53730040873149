import { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { ResourceType } from '@advitam/api/models/DocumentEditions';
import { assert } from '@advitam/support';
import { ButtonLike, Text } from '@advitam/ui';
import UploadIcon from '@advitam/ui/images/icons/upload.svg';
import { makeSelectDealUuid } from 'containers/Deal/selectors.typed';
import type { NewUploadResource } from 'containers/DocumentsEditor/types';
import type { DealDocument } from 'models/Deal/Document';

import { DocumentGenerationType } from '@advitam/api/models/Documents/Editable/GenerationType';
import { setDocumentsGenerationQueue } from 'containers/DocumentGenerationModal/slice';
import { setFile } from 'containers/DocumentTemplateEditor/slice';
import style from './MissingDocuments.module.scss';

interface DocumentProps {
  document: DealDocument;
  uploadFiles: (
    files: File[],
    resource: NewUploadResource,
  ) => void | Promise<void>;
}

export default function Document({
  document,
  uploadFiles,
}: DocumentProps): JSX.Element | null {
  const dispatch = useDispatch();
  const uuid = useSelector(makeSelectDealUuid());
  assert(uuid !== null);

  const onDrop = useCallback(
    async (files: File[]): Promise<void> => {
      const generationType = document.document?.generation_type;
      if (generationType === DocumentGenerationType.DIFFERENT) {
        dispatch(setDocumentsGenerationQueue([document]));
        dispatch(setFile(files[0]));
        return;
      }

      await uploadFiles(files, {
        type: ResourceType.DEAL,
        id: document.id,
        deal: { uuid },
      });
    },
    [dispatch, document, uuid, uploadFiles],
  );

  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }): JSX.Element => (
        <label
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getRootProps()}
          className={style.label}
        >
          <input
            type="file"
            className="soft-hide"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getInputProps()}
          />
          <Text
            small
            className={[style.text, isDragActive && style.active]
              .filter(Boolean)
              .join(' ')}
          >
            {document.name}
          </Text>
          <ButtonLike
            outline
            icon={<UploadIcon />}
            className={[style.action, isDragActive && style.active]
              .filter(Boolean)
              .join(' ')}
          />
        </label>
      )}
    </Dropzone>
  );
}
