function parse(value: string, _name: string): number | null {
  return value ? parseFloat(value) : null
}

function format(value: number | null | undefined, _name: string): string {
  if (value === null || value === undefined || Number.isNaN(value)) {
    return ''
  }
  return value.toString()
}

export default { parse, format }
