import { FormattedMessage } from 'react-intl';

import Check from '@advitam/ui/images/icons/check.svg';
import { Tooltip } from '@advitam/ui';
import { Summary } from 'models/Deal/Summary';

import messages from '../../messages';
import style from './State.module.scss';

interface StateProps {
  deal: Summary;
}

export default function State({ deal }: StateProps): JSX.Element {
  const classes = [style.container, style[deal.state]];

  const statusMessage = messages[deal.state];
  const TooltipMessage = (
    <FormattedMessage
      id={messages.status.id}
      values={{
        status: <FormattedMessage id={statusMessage.id} />,
        paid: deal.paid,
      }}
    />
  );

  return (
    <Tooltip
      contentClassName={classes.join(' ')}
      content={TooltipMessage}
      disabled={!TooltipMessage}
    >
      {deal.paid && <Check />}
    </Tooltip>
  );
}
