import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Memorial } from 'models/Memorial';

import { FileInput } from 'components/ReactFormComponent';
import { HTMLFileInput } from 'components/ReactFormComponent/File';
import Spinner from 'components/Spinner';
import EditIcon from 'images/icons/pencil.svg';
import TrashIcon from 'images/icons/trash.svg';
import { IMAGE_MIME_TYPES } from 'utils/constants';
import { withSlice } from 'utils/injectReducer';

import { updateProfilePicture } from './thunk';
import { makeSelectIsProfilePictureLoading } from './selectors';
import slice from './slice';
import messages from './messages';

import style from './style.scss';

interface ProfilePictureProps {
  memorial: Memorial;
}

function ProfilePicture({ memorial }: ProfilePictureProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsProfilePictureLoading());
  const onChange = (ev: ChangeEvent<HTMLFileInput> | null): void => {
    dispatch(updateProfilePicture(ev?.target.value || null));
  };

  return (
    <FileInput
      field="profile_image"
      message={{
        id: messages.picture.id,
        values: { defunct: memorial.defunct.formattedName(intl) },
      }}
      className={style['picture-input']}
      onChange={onChange}
      allowedTypes={IMAGE_MIME_TYPES}
    >
      {!isLoading && memorial.p_link && (
        <button
          type="button"
          className="button button--svg"
          onClick={(): void => onChange(null)}
        >
          <TrashIcon />
        </button>
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="button button--svg">
          <EditIcon />
        </div>
      )}
    </FileInput>
  );
}

export default withSlice(slice)(ProfilePicture);
