import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { CityhallJSON } from '@advitam/api/models/Cityhall';
import { saveEditableDocuments } from 'containers/Crud/Documents/Editable';
import { saveSupportingDocuments } from 'containers/Crud/Documents/Supporting';

import { CITYHALL } from './constants';
import { fetchCityhall, updateCityhall, updateCityhallName } from './thunk';

export interface State {
  cityhall: CityhallJSON | null;
  isLoading: boolean;
  isSaving: boolean;
  isUpdatingName: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [CITYHALL]: State;
}

export const initialState: State = {
  cityhall: null,
  isLoading: false,
  isSaving: false,
  isUpdatingName: false,
  error: null,
};

const slice = createSlice({
  name: CITYHALL,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCityhall.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchCityhall.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.cityhall = payload;
    });
    builder.addCase(fetchCityhall.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateCityhall.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateCityhall.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.cityhall = payload;
    });
    builder.addCase(updateCityhall.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateCityhallName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updateCityhallName.fulfilled, (state, { payload }) => {
      state.isUpdatingName = false;
      state.cityhall = payload;
    });
    builder.addCase(updateCityhallName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveEditableDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveEditableDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveEditableDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveSupportingDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveSupportingDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveSupportingDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError } = slice.actions;
export default slice;
