// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtherFuneralsCompany-module__wrapper--e0lQ7{padding:20px;background-color:#ccc;display:none}.OtherFuneralsCompany-module__wrapper--e0lQ7.OtherFuneralsCompany-module__open--tmv2m{display:block}.OtherFuneralsCompany-module__header_button--CTKED{display:block;width:100%;padding:5px;text-align:left;outline:none;cursor:pointer;font-family:raleway,sans-serif;background-color:#999}.OtherFuneralsCompany-module__header_button--CTKED>span{font-size:.9rem;padding-left:5px}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/DealFuneral/OtherFuneralCompany/OtherFuneralsCompany.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,qBAAA,CACA,YAAA,CAEA,sFACE,aAAA,CAIJ,mDACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,cAAA,CACA,8BAAA,CACA,qBAAA,CAEA,wDACE,eAAA,CACA,gBAAA","sourcesContent":[".wrapper {\n  padding: 20px;\n  background-color: #ccc;\n  display: none;\n\n  &.open {\n    display: block;\n  }\n}\n\n.header_button {\n  display: block;\n  width: 100%;\n  padding: 5px;\n  text-align: left;\n  outline: none;\n  cursor: pointer;\n  font-family: raleway, sans-serif;\n  background-color: #999;\n\n  > span {\n    font-size: 0.9rem;\n    padding-left: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `OtherFuneralsCompany-module__wrapper--e0lQ7`,
	"open": `OtherFuneralsCompany-module__open--tmv2m`,
	"header_button": `OtherFuneralsCompany-module__header_button--CTKED`
};
export default ___CSS_LOADER_EXPORT___;
