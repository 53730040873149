// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label.style__qty-input--aRfAR,label.style__signature--cdpnZ,label.style__message--sScrm textarea{border:none}label.style__qty-input--aRfAR,label.style__signature--cdpnZ,label.style__message--sScrm{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/components/Product/style.scss"],"names":[],"mappings":"AAAA,iGAGE,WAAA,CAGF,wFAGE,eAAA","sourcesContent":["label.qty-input,\nlabel.signature,\nlabel.message textarea {\n  border: none;\n}\n\nlabel.qty-input,\nlabel.signature,\nlabel.message {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qty-input": `style__qty-input--aRfAR`,
	"signature": `style__signature--cdpnZ`,
	"message": `style__message--sScrm`
};
export default ___CSS_LOADER_EXPORT___;
