import { authenticate, formdataEncoded } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get, put } from 'api/methods';
import { serializeUpdate } from 'api/serializers/Memorials';

import { Memorial, MemorialJSON } from 'models/Memorial';
import { assert } from 'lib/Assert';

export class Memorials {
  @authenticate
  static show(id: number): ApiRequestDescriptor<MemorialJSON> {
    return get(`/api/v1/memorials/${id}`);
  }

  @formdataEncoded
  private static updateFormEncoded(
    req: ApiRequestDescriptor<MemorialJSON>,
  ): ApiRequestDescriptor<MemorialJSON> {
    return req;
  }

  @authenticate
  static update(
    memorial: Partial<Memorial>,
  ): ApiRequestDescriptor<MemorialJSON> {
    assert(memorial.id !== undefined);
    const req = put(`/api/v1/memorials/${memorial.id}`, {
      memorial: serializeUpdate(memorial),
    });
    if (memorial.profile_image) {
      return Memorials.updateFormEncoded(req);
    }
    return req;
  }
}
