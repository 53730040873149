import { useMemo } from 'react'
import { Validator, ValidatorReturnType } from './Validator'

export function composeValidators<T, All = unknown>(
  ...validators: Array<false | null | undefined | Validator<T, All>>
) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (value: T, allValues?: object): ValidatorReturnType =>
    validators.reduce<ValidatorReturnType>((error, validator) => {
      if (error) {
        return error
      }

      if (validator) {
        return validator(value, (allValues as unknown) as All)
      }

      return error
    }, undefined)
}

export function useValidators<T, All = unknown>(
  ...validators: Array<false | null | undefined | Validator<T, All>>
): // eslint-disable-next-line @typescript-eslint/ban-types
(value: T, allValues?: object) => ValidatorReturnType {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => composeValidators<T, All>(...validators), validators)
}

export { areIbanCheckDigitsValid } from './areIbanCheckDigitsValid'
export { hasFileType } from './hasFileType'
export { hasLength } from './hasLength'
export { hasMinLength } from './hasMinLength'
export { isDateAfter } from './isDateAfter'
export { isDateValid } from './isDateValid'
export { isEmail } from './isEmail'
export { isHourAfter } from './isHourAfter'
export { isHourBefore } from './isHourBefore'
export { isImage } from './isImage'
export { isNotUndefined } from './isNotUndefined'
export { isPasswordValid } from './isPasswordValid'
export { isRequired } from './isRequired'
export { maxFileSize } from './maxFileSize'
export { maxLength } from './maxLength'
export type { Validator, ValidatorReturnType }
