/*
 *  CheckboxService component to toggle simple services using a checkbox
 */

import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import CheckboxLabel from 'components/Input/CheckboxLabel';

import { formatManualService } from 'containers/Deal/DealFuneral/StepsSection/Services/utils';

function CheckboxService(props) {
  const {
    serviceType,
    addService,
    removeService,
    services,
    intl,
    stepId,
  } = props;
  const service = services.find(s => s.type === serviceType);
  const checkboxValue = !!service;
  const onChange = () => {
    if (!checkboxValue) {
      addService(
        formatManualService(stepId, serviceType, intl.formatMessage(message)),
      );
    } else {
      removeService(service);
    }
  };

  const { message } = props;
  return (
    <CheckboxLabel
      message={message}
      updateCheckbox={onChange}
      checkboxValue={checkboxValue}
    />
  );
}

CheckboxService.propTypes = {
  serviceType: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
  addService: PropTypes.func.isRequired,
  stepId: PropTypes.number.isRequired,
  removeService: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CheckboxService);
