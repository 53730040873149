// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Recipients-module__spinner--O_VbM{margin-top:8px;text-align:center}.Recipients-module__select--nwv5l{min-width:120px;max-width:120px}@media(min-width: 992px){.Recipients-module__select--nwv5l{max-width:200px}}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/SendBatchModal/Autocompletes/Recipients/Recipients.module.scss","webpack://./../ui/scss/_media.scss"],"names":[],"mappings":"AAEA,mCACE,cAAA,CACA,iBAAA,CAGF,kCACE,eAAA,CACA,eAAA,CCKE,yBDPJ,kCAKI,eAAA,CAAA","sourcesContent":["@import '~@advitam/ui/scss/media';\n\n.spinner {\n  margin-top: 8px;\n  text-align: center;\n}\n\n.select {\n  min-width: 120px;\n  max-width: 120px;\n\n  @include above(lg) {\n    max-width: 200px;\n  }\n}\n","$breakpoints: (\n  xxl: 1440px,\n  xl: 1200px,\n  lg: 992px,\n  md: 768px,\n  sm: 576px,\n  xs: 375px\n);\n\n@mixin above($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n@mixin below($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (max-width: ($breakpoint-value - 1)) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n:export {\n  /* stylelint-disable property-no-unknown */\n  xxl: 1440px;\n  xl: 1200px;\n  lg: 992px;\n  md: 768px;\n  sm: 576px;\n  xs: 375px;\n  /* stylelint-enable property-no-unknown */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"spinner": `Recipients-module__spinner--O_VbM`,
	"select": `Recipients-module__select--nwv5l`
};
export default ___CSS_LOADER_EXPORT___;
