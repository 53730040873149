export enum DealState {
  QUOTE = 'quote',
  ORDER = 'order', // In progress
  OK = 'ok', // Done
  KO = 'ko',
  FUNERAL_CONTRACT = 'funeral_contract',
  FUNERAL_CONTRACT_OK = 'funeral_contract_ok',
  STAGING = 'staging',
}

export interface DealStateJSON {
  id: number
  state: DealState
}
