import type { ReactNode } from 'react'

import { PageSpinner } from '../../Spinner'
import Text from '../../Text'
import Modal from '../Modal'
import style from './Content.module.scss'

interface MessageProps {
  isOpen: boolean
  title?: ReactNode
  message: ReactNode
  className?: string
}

export default function Loading({
  isOpen,
  title,
  message,
  className = '',
}: MessageProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} className={`col-4 col-md-6 ${style.message} ${className}`}>
      {title && (
        <Text large tagName="div" className={style.title}>
          <b>{title}</b>
        </Text>
      )}

      <Text tagName="div" className={style.content}>
        {message}
      </Text>

      <PageSpinner className={style.loading__spinner} />
    </Modal>
  )
}
