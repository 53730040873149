import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { unwrapResult } from '@reduxjs/toolkit';

import { useThunkDispatch, withSlice } from '@advitam/react';
import { DealKoReason } from '@advitam/api/models/Deal/KoReason';
import { DealState } from '@advitam/api/models/Deal/State';
import {
  Button,
  ContentModal,
  Input,
  ModalTitle,
  Radio,
  Spinner,
  SubmitButton,
  Text,
} from '@advitam/ui';
import actionsMessages from 'messages/actions';

import { updateDealState } from './thunk';

import ReasonSpy from './ReasonSpy';
import { COMMENT_FIELD_NAME, REASON_FIELD_NAME } from './constants';
import messages from './messages';
import { makeSelectIsOpen } from './selectors';
import slice, { setIsOpen } from './slice';
import { KoReasonForm } from './types';
import style from './KoReasonModal.module.scss';

function KoReasonModal(): JSX.Element {
  const intl = useIntl();
  const dispatch = useThunkDispatch();
  const isOpen = useSelector(makeSelectIsOpen());
  const [isLoading, setIsLoading] = useState(false);

  const onClose = useCallback(() => {
    dispatch(setIsOpen(false));
  }, []);

  const onSubmit = useCallback(async (values: KoReasonForm): Promise<void> => {
    setIsLoading(true);

    const result = await dispatch(
      updateDealState({ state: DealState.KO, ...values }),
    );

    try {
      unwrapResult(result);
      setIsLoading(false);
      onClose();
    } catch {
      setIsLoading(false);
      // let the deal reducer handle
    }
  }, []);

  return (
    <ContentModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`col-xl-6 ${style.modal}`}
    >
      <Form<KoReasonForm> onSubmit={onSubmit}>
        {({ values, handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <ModalTitle>
              <FormattedMessage id={messages.title.id} />
            </ModalTitle>
            <Text>
              <FormattedMessage id={messages.text.id} />
            </Text>

            <div className={style.fields}>
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.unreachable.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.UNREACHABLE}
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.duplicate.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.DUPLICATE}
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.anticipation.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.ANTICIPATION}
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.tooExpensive.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.TOO_EXPENSIVE}
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.physicalStore.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.PHYSICAL_STORE}
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.notProvidedService.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.NOT_PROVIDED_SERVICE}
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.other.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.OTHER}
              />
              {values.ko_reason === DealKoReason.OTHER && (
                <Input
                  required
                  className={style.input}
                  name={COMMENT_FIELD_NAME}
                  placeholder={intl.formatMessage(messages.toSpecify)}
                />
              )}
            </div>

            <div className={style.actions}>
              <Button
                outline
                text={<FormattedMessage id={actionsMessages.cancel.id} />}
                onClick={onClose}
              />
              <SubmitButton
                primary
                disabled={!values.ko_reason}
                text={<FormattedMessage id={actionsMessages.confirm.id} />}
                icon={isLoading ? <Spinner /> : undefined}
              />
            </div>

            <ReasonSpy />
          </form>
        )}
      </Form>
    </ContentModal>
  );
}

export default withSlice(slice)(KoReasonModal);
