import { ForwardedRef, forwardRef, ReactNode } from 'react';

import style from './Layout.module.scss';

interface GridProps {
  children: ReactNode;
}

function FieldsetGrid(
  { children }: GridProps,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const classes = [style.wrapper, style.fieldset_grid, 'col-10', 'col-xxl-11'];

  return (
    <div ref={ref} className={classes.join(' ')}>
      {children}
    </div>
  );
}

export default forwardRef(FieldsetGrid);
