import { ReactElement, ReactNode } from 'react'

import Link from '../../../Link'
import style from './Layout.module.scss'

interface CardLinkProps {
  icon: ReactElement
  children: ReactNode | ReactNode[]
  href: string
  internal?: boolean
  external?: boolean
  className?: string
}

export default function CardLink({
  icon,
  children,
  href,
  external,
  internal,
  className = '',
}: CardLinkProps): JSX.Element {
  return (
    <Link
      href={href}
      newTab={external}
      internal={internal}
      className={`${style.button} ${className}`}
    >
      <div className={style.icon}>{icon}</div>
      <div>{children}</div>
    </Link>
  )
}
