import { ReactNode } from 'react';

import { FlatButton, Text } from '@advitam/ui';
import CloseIcon from '@advitam/ui/images/icons/times.svg';

import style from './ActiveTag.module.scss';

interface ActiveTagProps {
  backgroundColor: string;
  children: ReactNode;
  onClick: () => void;
}

export default function ActiveTag({
  backgroundColor,
  children,
  onClick,
}: ActiveTagProps): JSX.Element {
  return (
    <div style={{ background: backgroundColor }} className={style.tag}>
      <Text small>{children}</Text>
      <FlatButton onClick={onClick}>
        <CloseIcon />
      </FlatButton>
    </div>
  );
}
