import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Deal, DealParentType } from 'models/Deal';
import { Memorial } from 'models/Memorial';

import ParentLink from './ParentLink';
import messages from './messages';

interface OtherSectionProps {
  parentType: DealParentType | null;
  parent: Deal | Memorial | null;
}

function OtherSection({ parent, parentType }: OtherSectionProps): JSX.Element {
  return (
    <section>
      <FormattedMessage {...messages.link} tagName="h2" />
      <div className="dealSection">
        <ParentLink parent={parent} parentType={parentType} />
      </div>
    </section>
  );
}

OtherSection.propTypes = {
  parent: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  parentType: PropTypes.string,
};

export default OtherSection;
