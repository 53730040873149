import messages from './messages';

export function isUrl(value: string | null): string | false {
  if (!value) {
    return false;
  }

  if (!value.startsWith('http://') && !value.startsWith('https://')) {
    return messages.invalidUrl.id;
  }

  try {
    if (new URL(value)) {
      return false;
    }
  } catch (err) {
    return messages.invalidUrl.id;
  }

  return false;
}
