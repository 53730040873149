import { defineMessages } from 'react-intl';

export default defineMessages({
  header: { id: 'app.components.ErrorBox.header' },
  question: { id: 'app.components.ErrorBox.question' },
  save: { id: 'app.components.ErrorBox.save' },
  cancel: { id: 'app.components.ErrorBox.cancel' },
  death: { id: 'app.components.ErrorBox.death' },
  transportAfterCasketing: {
    id: 'app.components.ErrorBox.transportAfterCasketing',
  },
  casketing: { id: 'app.components.ErrorBox.casketing' },
  closing: { id: 'app.components.ErrorBox.closing' },
  ceremony: { id: 'app.components.ErrorBox.ceremony' },
  interment: { id: 'app.components.ErrorBox.interment' },
  cremation: { id: 'app.components.ErrorBox.cremation' },
  urnPickup: { id: 'app.components.ErrorBox.urnPickup' },
  repatriation: { id: 'app.components.ErrorBox.repatriation' },
  urnSealing: { id: 'app.components.ErrorBox.urnSealing' },
  deposit: { id: 'app.components.ErrorBox.deposit' },
});
