import { StandardDefunctJSON } from 'models/Defunct';

import { ClientResource, ClientResourceJSON, ClientResourceBase } from '.';

export interface ClientResourceMemorialBase extends ClientResourceBase {
  defunct: StandardDefunctJSON;
}

export interface ClientResourceMemorialJSON
  extends ClientResourceJSON,
    ClientResourceMemorialBase {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientResourceMemorial extends ClientResourceMemorialBase {}
export class ClientResourceMemorial extends ClientResource<
  ClientResourceMemorialJSON
> {}
