import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { SupportingDocumentIndexJSON } from '@advitam/api/models/SupportingDocuments/SupportingDocumentIndex';

import { CLIENT_DOCUMENTS } from './constants';
import { fetchDocuments, saveDocuments } from './thunk';
import type { DocumentFilters } from './types';

export interface AppStateSubset {
  [CLIENT_DOCUMENTS]: State;
}

export interface State {
  documents: SupportingDocumentIndexJSON[];
  filters: DocumentFilters;
  variables: string[];
  isLoading: boolean;
  error: SerializedApiError | null;
}

export const initialState: State = {
  documents: [],
  filters: {},
  variables: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: CLIENT_DOCUMENTS,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setFilters: (state, { payload }: PayloadAction<DocumentFilters>) => {
      state.filters = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDocuments.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocuments.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.documents = payload;
    });
    builder.addCase(fetchDocuments.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload;
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { setFilters } = slice.actions;
export default slice;
