import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import Api, { Pagination, request } from '@advitam/api';
import { NearEntitiesFilters } from '@advitam/api/v1/Entities/Near';
import {
  SupplierWarehouseJSON,
  SupplierWarehouseZone,
  SupplierWarehouseZoneJSON,
} from '@advitam/api/models/Supplier/Warehouse';
import { CoverageJSON } from '@advitam/api/models/Entity/Coverage';
import { Model } from '@advitam/api/models/Model';

import { assert } from 'lib/support';

import { AppStateSubset as SupplierAppStateSubset } from '../../../slice';
import { makeSelectSupplier } from '../../../selectors';
import { makeSelectWarehouse, makeSelectWarehouseZones } from '../../selectors';
import {
  AppStateSubset as WarehouseAppStateSubset,
  setZones,
} from '../../slice';
import { updateWarehouseName } from '../../thunk';
import { SUPPLIER_WAREHOUSE_ZONES } from './constants';
import { AppStateSubset as ZonesAppStateSubset } from './slice';
import {
  UnsavedCoverage,
  UnsavedSupplierWarehouseZone,
  WarehouseZonesForm,
  ZoneSectionValue,
} from './types';
import { makeSelectInitialValues } from './selectors';

type AppStateSubset = ZonesAppStateSubset &
  WarehouseAppStateSubset &
  SupplierAppStateSubset;

function isZoneAlreadySaved(
  zone: SupplierWarehouseZoneJSON | UnsavedSupplierWarehouseZone,
): zone is SupplierWarehouseZoneJSON {
  return Boolean(zone.id);
}

async function fetchZoneCoverage(
  zone: SupplierWarehouseZone,
): Promise<CoverageJSON[]> {
  const coverage: CoverageJSON[] = [];
  let currentPage = 1;
  let hasMore = true;

  while (hasMore) {
    // eslint-disable-next-line no-await-in-loop
    const response = await request(
      Api.V1.Suppliers.Warehouses.Zones.Coverage.show(zone.id, {
        page: currentPage,
        per_page: 100,
      }),
    );

    coverage.push(...response.body);
    hasMore = Pagination.getPageCount(response) > currentPage;
    currentPage += 1;
  }

  return coverage;
}

export const fetchCoveragesPerZones = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE_ZONES}_FETCH_COVERAGE`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const zones = makeSelectWarehouseZones()(state);

    try {
      const coverage = await Promise.all(
        zones.map(async zone => ({
          [zone.id]: await fetchZoneCoverage(zone),
        })),
      );

      return Object.assign({}, ...coverage) as Record<number, CoverageJSON[]>;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchNearZones = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE_ZONES}_FETCH_NEAR_ZONES`,
  async (zonesFilters: NearEntitiesFilters, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Entities.Near.index(zonesFilters));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const synchronizeMapCoverage = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE_ZONES}_SYNCHRONIZE_MAP_COVERAGE`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const supplier = makeSelectSupplier()(state);
    assert(supplier !== null);

    try {
      await request(Api.V1.Suppliers.Coverage.Synchronize.update(supplier.id));
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

async function saveCoverage(
  zoneId: number,
  coverages: Array<CoverageJSON | UnsavedCoverage>,
): Promise<CoverageJSON[]> {
  if (coverages.length === 0) {
    return [];
  }

  const { body } = await request(
    Api.V1.Suppliers.Warehouses.Zones.Coverage.update(zoneId, coverages),
  );
  return body;
}

async function updateZone(
  zone: SupplierWarehouseZoneJSON,
): Promise<SupplierWarehouseZoneJSON> {
  const { body } = await request(
    Api.V1.Suppliers.Warehouses.Zones.update(zone),
  );
  return body;
}

async function createZone(
  warehouseId: number,
  zone: UnsavedSupplierWarehouseZone,
): Promise<SupplierWarehouseZoneJSON> {
  const { body } = await request(
    Api.V1.Suppliers.Warehouses.Zones.create(warehouseId, zone),
  );
  return body;
}

function getNewCoverage(
  existingCoverage: Array<CoverageJSON | UnsavedCoverage>,
  coverage: Array<CoverageJSON | UnsavedCoverage>,
): Array<CoverageJSON | UnsavedCoverage> {
  const coverageToUpdate = [...coverage];

  existingCoverage.forEach(existingCoveredItem => {
    const hasBeenRemoved = !coverageToUpdate.find(
      item => item.id && item.id === existingCoveredItem.id,
    );

    if (hasBeenRemoved) {
      const coveredItemToDelete = { ...existingCoveredItem };
      Model.setDestroyed(coveredItemToDelete);
      coverageToUpdate.push(coveredItemToDelete);
    }
  });

  return coverageToUpdate;
}

function mergeUpdatedCoverage(
  coverage: Array<CoverageJSON | UnsavedCoverage>,
  updatedCoverage: Array<CoverageJSON | UnsavedCoverage>,
): Array<CoverageJSON | UnsavedCoverage> {
  return coverage.map(coveredItem => {
    const updatedItem = updatedCoverage.find(
      item =>
        item.zone_id === coveredItem.zone_id &&
        item.zone_type === coveredItem.zone_type,
    );

    return updatedItem || coveredItem;
  });
}

async function updateCoverage(
  zoneId: number,
  existingCoverage: Array<CoverageJSON | UnsavedCoverage>,
  coverage: Array<CoverageJSON | UnsavedCoverage>,
): Promise<Array<CoverageJSON | UnsavedCoverage>> {
  const newCoverage = getNewCoverage(existingCoverage, coverage);
  const updatedCoverage = await saveCoverage(zoneId, newCoverage);
  return mergeUpdatedCoverage(coverage, updatedCoverage);
}

async function saveZone(
  initialValues: ZoneSectionValue[],
  { zone, coverage }: ZoneSectionValue,
  warehouseId: number,
): Promise<ZoneSectionValue> {
  if (isZoneAlreadySaved(zone)) {
    const existingZone = initialValues.find(value => value.zone.id === zone.id);
    assert(existingZone !== undefined);

    if (!isEqual(existingZone.zone, zone)) {
      existingZone.zone = await updateZone(zone);
    }

    if (!isEqual(existingZone.coverage, coverage)) {
      existingZone.coverage = await updateCoverage(
        zone.id,
        existingZone.coverage,
        coverage,
      );
    }

    return existingZone;
  }

  const newZone = await createZone(warehouseId, zone);
  const newCoverage = await saveCoverage(newZone.id, coverage);

  return { zone: newZone, coverage: newCoverage };
}

export const saveZones = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE_ZONES}_SAVE`,
  async (
    payload: WarehouseZonesForm,
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const initialValues = makeSelectInitialValues()(state);
    const warehouse = makeSelectWarehouse()(state);
    assert(warehouse !== null);

    let savedWarehouse: SupplierWarehouseJSON;
    try {
      const result = await dispatch(
        updateWarehouseName(payload.warehouse.name),
      );
      unwrapResult(result);
      savedWarehouse = result.payload as SupplierWarehouseJSON;
    } catch {
      return undefined;
    }

    try {
      const zones = await Promise.all(
        payload.sectionValues.map(zoneValue =>
          saveZone(initialValues, zoneValue, warehouse.id),
        ),
      );

      await Promise.all(
        initialValues.map(async ({ zone }) => {
          const hasBeenRemoved = payload.sectionValues.every(
            ({ zone: zonePayload }) => zonePayload.id !== zone.id,
          );

          if (hasBeenRemoved) {
            assert(zone.id !== undefined);
            await request(Api.V1.Suppliers.Warehouses.Zones.destroy(zone.id));
          }
        }),
      );

      const newZones = zones.map(({ zone }) => {
        assert(zone.id !== undefined);
        return zone;
      });

      dispatch(setZones(newZones));

      return { zones, warehouse: savedWarehouse };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
