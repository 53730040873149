import { withSessionId } from '@advitam/api';
import { Deal } from 'models/Deal';
import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { put } from 'api/methods';

export default class State {
  @authenticate
  @withSessionId
  static update(uuid: string, state: string): ApiRequestDescriptor<Deal> {
    return put(`/api/v1/deals/${uuid}/state`, {
      state,
    });
  }
}
