import { createSelector, Selector } from 'reselect';

import type { ErrorProps } from 'components/ErrorModal';

import { APP } from './constants';
import type {
  AppStateSubset as AppSliceStateSubset,
  State as SliceState,
} from './slice';

export const selectAppSliceDomain = (state: AppSliceStateSubset): SliceState =>
  state[APP];

type AppSliceSelector<T> = Selector<AppSliceStateSubset, T>;

/**
 * @deprecated Handle errors yourself
 */
export const makeSelectError = (): AppSliceSelector<ErrorProps | null> =>
  createSelector(selectAppSliceDomain, substate => substate.error);

export const makeSelectIsLoading = (): AppSliceSelector<boolean> =>
  createSelector(selectAppSliceDomain, ({ isLoading }) => isLoading);
