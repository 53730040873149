import { createSelector, Selector } from 'reselect';

import { RESOURCE_CABLE } from './constants';
import { AppStateSubset, State } from './slice';

type ResourceCableSelector<T> = Selector<AppStateSubset, T>;

const selectResourceCableDomain = (state: AppStateSubset): State =>
  state[RESOURCE_CABLE];

export const makeSelectLastActionDate = (): ResourceCableSelector<string> =>
  createSelector(selectResourceCableDomain, state => state.lastAction);

export const makeSelectIsDestroyed = (): ResourceCableSelector<boolean> =>
  createSelector(selectResourceCableDomain, state => state.isDestroyed);

export const makeSelectIsErrorBannerOpen = (): ResourceCableSelector<boolean> =>
  createSelector(selectResourceCableDomain, state => state.isErrorBannerOpen);

export const makeSelectIsUpdated = (): ResourceCableSelector<boolean> =>
  createSelector(selectResourceCableDomain, state => state?.isUpdated || false);

export const makeSelectIsUpdateModalOpen = (): ResourceCableSelector<boolean> =>
  createSelector(selectResourceCableDomain, state => state.isUpdateModalOpen);
