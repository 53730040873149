// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Steps-module__skeleton_button--MhuBM{display:inline-block;width:70px;height:70px;padding:10px;margin:0 5px;outline:none;cursor:pointer;background-color:#ccc}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/DealFuneral/StepsSection/Steps.module.scss"],"names":[],"mappings":"AAAA,sCACE,oBAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,YAAA,CACA,YAAA,CACA,cAAA,CACA,qBAAA","sourcesContent":[".skeleton_button {\n  display: inline-block;\n  width: 70px;\n  height: 70px;\n  padding: 10px;\n  margin: 0 5px;\n  outline: none;\n  cursor: pointer;\n  background-color: #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton_button": `Steps-module__skeleton_button--MhuBM`
};
export default ___CSS_LOADER_EXPORT___;
