// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CroppedText-module__container--WJucP{display:block;overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis}.CroppedText-module__hidden--aUNqx{display:inline-block;position:absolute;visibility:hidden;z-index:-1;white-space:nowrap}.CroppedText-module__tooltip--LSMoY{max-width:unset}`, "",{"version":3,"sources":["webpack://./../ui/components/CroppedText/CroppedText.module.scss"],"names":[],"mappings":"AAEA,sCACE,aAAA,CACA,iBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,mCACE,oBAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAbe,CAcf,kBAAA,CAGF,oCACE,eAAA","sourcesContent":["$z-index__below: -1;\n\n.container {\n  display: block;\n  overflow-x: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.hidden {\n  display: inline-block;\n  position: absolute;\n  visibility: hidden;\n  z-index: $z-index__below;\n  white-space: nowrap;\n}\n\n.tooltip {\n  max-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CroppedText-module__container--WJucP`,
	"hidden": `CroppedText-module__hidden--aUNqx`,
	"tooltip": `CroppedText-module__tooltip--LSMoY`
};
export default ___CSS_LOADER_EXPORT___;
