import { ReactNode } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsPopup } from 'containers/Crud';
import { assert } from 'lib/Assert';

import { updateGraveyardName } from '../thunk';
import GraveyardSidebar from '../Sidebar';
import { makeSelectRawGraveyard } from '../selectors';
import style from '../Graveyard.module.scss';
import { GraveyardForm } from './types';

interface WrapperProps<T> {
  children: ReactNode;
  initialSectionValues?: T;
  onSubmit: (values: GraveyardForm<T>) => void;
}

export default function Wrapper<T>({
  children,
  initialSectionValues,
  onSubmit,
}: WrapperProps<T>): JSX.Element {
  const dispatch = useDispatch();
  const graveyard = useSelector(makeSelectRawGraveyard());
  assert(graveyard !== null);

  const initialValues: Partial<GraveyardForm<T>> = {
    graveyard,
    sectionValues: initialSectionValues,
  };

  const handleFormSubmit = (values: GraveyardForm<T>): void => {
    const { graveyard: formGraveyard } = values;

    // update graveyard name when section does not handle graveyard save
    if (values.sectionValues && graveyard.name !== formGraveyard.name) {
      dispatch(updateGraveyardName(formGraveyard.name));
    }

    onSubmit(values);
  };

  return (
    <Form<GraveyardForm<T>>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <GraveyardSidebar />
          {(dirty || !graveyard.id) && <ActionsPopup isNew={!graveyard.id} />}
          {children}
        </form>
      )}
    </Form>
  );
}
