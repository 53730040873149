import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MaskedInput from 'react-maskedinput';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import messagesCrud from 'messages/crud';
import { objectFiltered } from 'utils/functions';

import { EMAIL_TYPE, TEL_TYPE, CHECKBOX_TYPE } from './constants';

const PROPS_NOT_PASS = [
  'messages',
  'intl',
  'isReadingMode',
  'classNameContainer',
  'classNameInput',
  'classNameLabel',
  'isLabel',
];

/**
 * Render input, maskedInput or value
 */
class InputChoice extends PureComponent {
  /**
   * Update value
   *
   * @param     {Object}      event                 Event click
   * @param     {Object}      event.target          Node targeted by user
   * @param     {String}      event.target.name     input name
   * @param     {String}      event.target.value    input value
   * @param     {String}      event.target.type     input type
   * @param     {String}      event.target.checked  input checked
   */
  onChange = ({ target: { value, name, type, checked } }) => {
    const { onChange } = this.props;
    let newValue = type === CHECKBOX_TYPE ? checked : value;
    if (newValue === '') newValue = null;
    const nextEvent = {
      target: {
        name,
        value: newValue,
      },
    };
    onChange(nextEvent);
  };

  /**
   * Add link if type is specific
   */
  valueInReadingMode = () => {
    const { value, type, intl } = this.props;
    switch (type) {
      case EMAIL_TYPE:
        return <a href={`mailto:${value}`}>{value}</a>;
      case TEL_TYPE:
        return <a href={`tel:${value}`}>{value}</a>;
      case CHECKBOX_TYPE:
        return value
          ? intl.formatMessage({ ...messagesCrud.optionYes })
          : intl.formatMessage({ ...messagesCrud.optionNo });
      default:
        return value;
    }
  };

  renderInput = () => {
    const {
      isReadingMode,
      id,
      value,
      classNameInput,
      mask,
      isLabel,
      type,
      name,
      intl,
    } = this.props;
    const propsToPass = objectFiltered(this.props, PROPS_NOT_PASS);

    const inputValue = value === 0 ? 0 : value;

    const className = isLabel ? classNameInput : 'inputChoice__input fullWidth';

    if (type === TEL_TYPE) {
      const phoneValue = value || '';
      const isPhoneValid =
        (phoneValue &&
          !isValidPhoneNumber(phoneValue) &&
          intl.formatMessage(messagesCrud.errorPhoneFormat)) ||
        undefined;
      return (
        <PhoneInput
          value={phoneValue}
          onChange={val =>
            this.onChange({ target: { value: val || '', name } })
          }
          className="col-7 col-8-lg"
          error={isPhoneValid}
          initialValueFormat="national"
          defaultCountry="FR"
        />
      );
    }
    if (mask !== undefined) {
      return (
        <MaskedInput
          {...propsToPass}
          mask={mask}
          className={className}
          id={id}
          readOnly={isReadingMode}
          value={inputValue || ''}
          onChange={this.onChange}
          disabled={isReadingMode}
        />
      );
    }
    return (
      <input
        {...propsToPass}
        className={className}
        id={id}
        readOnly={isReadingMode}
        value={inputValue || ''}
        onChange={this.onChange}
        disabled={isReadingMode}
        checked={inputValue}
      />
    );
  };

  render() {
    const {
      messages,
      intl,
      isReadingMode,
      id,
      classNameContainer,
      classNameLabel,
      isLabel,
    } = this.props;

    return (
      <div className={classNameContainer}>
        {isLabel && (
          <div className={classNameLabel}>
            {intl.formatMessage({ ...messages[id] })}
          </div>
        )}
        {isReadingMode ? (
          <div className="inputChoice__valueInReadingMode">
            {this.valueInReadingMode()}
          </div>
        ) : (
          this.renderInput()
        )}
      </div>
    );
  }
}

InputChoice.propTypes = {
  /** input id */
  id: PropTypes.string.isRequired,
  /** input editable or not */
  isReadingMode: PropTypes.bool.isRequired,
  /** object with all messages */
  messages: PropTypes.object.isRequired,
  /** input value */
  value: PropTypes.any,
  /** function to change input value */
  onChange: PropTypes.func,
  intl: PropTypes.object.isRequired,
  /** input type */
  type: PropTypes.string.isRequired,
  /** className for input */
  classNameInput: PropTypes.string,
  /** className for container */
  classNameContainer: PropTypes.string,
  /** className for label */
  classNameLabel: PropTypes.string,
  /** input mask */
  mask: PropTypes.string,
  /** the label should be displayed */
  isLabel: PropTypes.bool,
  /** name of the input */
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

InputChoice.defaultProps = {
  classNameInput: 'inputChoice__input col-7 col-8-lg',
  classNameContainer: 'inputChoice',
  classNameLabel: 'inputChoice__label col-5 col-4-lg',
  onChange: () => {},
  value: '',
  isLabel: true,
};

export default injectIntl(InputChoice);
