import { Marker } from '@react-google-maps/api'

import { Arrays } from '@advitam/support'

import Map from './index'
import type { MapStyle } from './mapStyles'

interface WaypointsMapProps {
  route: google.maps.LatLng[]
  zoom: number
  maxZoom?: number
  mapStyle?: MapStyle
  className?: string
}

export default function WaypointsMap({
  route,
  zoom,
  maxZoom,
  mapStyle,
  className,
}: WaypointsMapProps): JSX.Element {
  if (route.length === 0) {
    return <Map className={className} maxZoom={maxZoom} mapStyle={mapStyle} />
  }

  if (route.length === 1) {
    return (
      <Map
        className={className}
        zoom={zoom}
        maxZoom={maxZoom}
        center={route[0]}
        mapStyle={mapStyle}
      >
        <Marker position={route[0]} />
      </Map>
    )
  }

  const waypoints = route.slice(1, -1).map(location => ({ location, stopover: true }))
  return (
    <Map
      className={className}
      origin={route[0]}
      destination={Arrays.last(route)}
      waypoints={waypoints}
      maxZoom={maxZoom}
      mapStyle={mapStyle}
    />
  )
}
