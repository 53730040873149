import type { ReactNode } from 'react';
import { Marker } from '@react-google-maps/api';

import { RepatriationType } from '@advitam/api/models/Repatriation/Type';
import { StepType } from '@advitam/api/models/Step';

import ashesIcon from './icons/ashes.inline.svg';
import carIcon from './icons/car.inline.svg';
import casketingIcon from './icons/opened-coffin.inline.svg';
import churchIcon from './icons/church.inline.svg';
import closedCoffinIcon from './icons/closed-coffin.inline.svg';
import deathIcon from './icons/cross.inline.svg';
import planeIcon from './icons/plane.inline.svg';
import tombstoneIcon from './icons/tombstone.inline.svg';
import urnIcon from './icons/urn.inline.svg';
import urnReturnIcon from './icons/urn-return.inline.svg';
import warehouseIcon from './icons/warehouse.inline.svg';

const STEP_ICON = {
  [StepType.CASKETING]: casketingIcon,
  [StepType.CEREMONY]: churchIcon,
  [StepType.CLOSING]: closedCoffinIcon,
  [StepType.CREMATION]: urnIcon,
  [StepType.DEATH]: deathIcon,
  [StepType.DELIVERY]: undefined,
  [StepType.DEPOSIT]: warehouseIcon,
  [StepType.DISPERSION]: ashesIcon,
  [StepType.INTERMENT]: tombstoneIcon,
  [StepType.REPATRIATION]: undefined,
  [StepType.TRANSPORT]: carIcon,
  [StepType.URN_PICKUP]: urnReturnIcon,
  [StepType.URN_SEALING]: tombstoneIcon,
};

const REPATRIATION_TYPE_ICON = {
  [RepatriationType.CAR]: carIcon,
  [RepatriationType.PLANE]: planeIcon,
};

interface StepMarkerProps {
  lat: number;
  lng: number;
  type: StepType;
  repatriationType?: RepatriationType;
  onClick?: () => void;
  children?: ReactNode;
}

export default function StepMarker({
  lat,
  lng,
  type,
  repatriationType,
  onClick,
  children,
}: StepMarkerProps): JSX.Element {
  const iconSrc = repatriationType
    ? REPATRIATION_TYPE_ICON[repatriationType]
    : STEP_ICON[type];

  return (
    <Marker icon={iconSrc} position={{ lat, lng }} onClick={onClick}>
      {children}
    </Marker>
  );
}
