import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { GraveyardConcessionJSON } from '@advitam/api/models/Graveyard/Concession';

import { GRAVEYARD_CONCESSIONS } from './constants';
import { fetchConcessions, saveConcessions } from './thunk';
import type { ConcessionFilters } from './types';

export interface State {
  concessions: GraveyardConcessionJSON[];
  isLoading: boolean;
  isSaving: boolean;
  error: SerializedApiError | null;
  filters: ConcessionFilters;
}

export interface AppStateSubset {
  [GRAVEYARD_CONCESSIONS]: State;
}

export const initialState: State = {
  concessions: [],
  isLoading: false,
  isSaving: false,
  error: null,
  filters: {},
};

const slice = createSlice({
  name: GRAVEYARD_CONCESSIONS,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters(state, { payload }: PayloadAction<ConcessionFilters>) {
      state.filters = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchConcessions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConcessions.fulfilled, (state, { payload }) => {
      state.concessions = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchConcessions.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveConcessions.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveConcessions.fulfilled, (state, { payload }) => {
      state.concessions = payload;
      state.isSaving = false;
    });
    builder.addCase(saveConcessions.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters } = slice.actions;
export default slice;
