import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export function getLaterToday(): DateTime {
  return DateTime.now().plus({ hour: 3 }).set({ second: 0 });
}

export function getTomorrow(): DateTime {
  return DateTime.now().plus({ day: 1 }).set({ hour: 9, minute: 0, second: 0 });
}

export function getNextWeek(): DateTime {
  const now = DateTime.now();
  return now
    .plus({ day: 8 - now.weekday })
    .set({ hour: 9, minute: 0, second: 0 });
}

export function getNextMonth(): DateTime {
  const date = DateTime.now()
    .plus({ day: 30 })
    .set({ hour: 9, minute: 0, second: 0 });

  if (date.weekday === 6) {
    return date.plus({ day: 2 });
  }
  if (date.weekday === 7) {
    return date.plus({ day: 1 });
  }
  return date;
}

export function getPredefinedDates(): DateTime[] {
  return [getLaterToday(), getTomorrow(), getNextWeek(), getNextMonth()];
}

export function usePredefinedValues(): DateTime[] {
  const [predefinedValues, setPredefinedValues] = useState([
    getLaterToday(),
    getTomorrow(),
    getNextWeek(),
    getNextMonth(),
  ]);

  useEffect(() => {
    const now = new Date();
    const nextMinute = 60 - now.getSeconds();
    const timeout = nextMinute * 1000 + 1000 - now.getMilliseconds();

    const handle = setTimeout(() => {
      const updatedValues = [getLaterToday(), ...predefinedValues.slice(1)];
      setPredefinedValues(updatedValues);
    }, timeout);

    return () => {
      clearTimeout(handle);
    };
  }, [predefinedValues, setPredefinedValues]);

  return predefinedValues;
}
