import { get } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'

interface CallbackResponse {
  deal_uuid: string
}

export const Callback = {
  show(pid: string): ApiRequestDescriptor<CallbackResponse> {
    return get('/api/v1/payments/alma/callback', { pid })
  },
}
