import { FormattedMessage } from 'react-intl';

import Layout from 'containers/Crud/Layout';
import { SelectableCityhalls } from 'containers/Crud/Input';

import messages from './messages';

export default function ReducedPriceCityhalls(): JSX.Element {
  return (
    <Layout.Fieldset
      title={<FormattedMessage id={messages.reducedPrices.id} />}
      tooltip={<FormattedMessage id={messages.reducedPricesTooltip.id} />}
    >
      <SelectableCityhalls
        name="crematorium.reduced_price_cityhalls"
        label={<FormattedMessage id={messages.place.id} />}
      />
    </Layout.Fieldset>
  );
}
