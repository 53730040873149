import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { FullClientJSON } from '@advitam/api/models/Client/Full';
import type { DeathDeclarationJSON } from '@advitam/api/models/DeathDeclaration';

import { CLIENT } from './constants';
import {
  fetchClient,
  fetchClientData,
  fetchDeathDeclarations,
  updateClient,
} from './thunk';
import { updateDeathDeclaration } from './DeathDeclarationValidation/thunk';
import { saveDocuments } from './sections/Documents/thunk';

export interface State {
  client: FullClientJSON | null;
  deathDeclarations: DeathDeclarationJSON[];
  isLoading: boolean;
  isSaving: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [CLIENT]: State;
}

export const initialState: State = {
  client: null,
  deathDeclarations: [],
  isLoading: false,
  isSaving: false,
  error: null,
};

const slice = createSlice({
  name: CLIENT,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchClient.fulfilled, (state, { payload }) => {
      state.client = payload;
    });
    builder.addCase(fetchClient.rejected, (state, { payload }) => {
      state.error ||= ApiError.serialize(payload);
    });

    builder.addCase(fetchDeathDeclarations.fulfilled, (state, { payload }) => {
      state.deathDeclarations = payload;
    });
    builder.addCase(fetchDeathDeclarations.rejected, (state, { payload }) => {
      state.error ||= ApiError.serialize(payload);
    });

    builder.addCase(fetchClientData.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchClientData.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchClientData.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(updateClient.pending, state => {
      state.isSaving = true;
      state.error = null;
    });
    builder.addCase(updateClient.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.client = payload;
    });
    builder.addCase(updateClient.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveDocuments.pending, state => {
      state.isSaving = true;
      state.error = null;
    });
    builder.addCase(saveDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateDeathDeclaration.fulfilled, (state, { payload }) => {
      const declarationIndex = state.deathDeclarations.findIndex(
        declaration => declaration.id === payload.id,
      );
      state.deathDeclarations[declarationIndex] = payload;
    });
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
