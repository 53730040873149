import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, useForm, useFormState } from 'react-final-form';

import { FormLayout, FormUI, Input } from '@advitam/ui';
import type { Address } from '@advitam/ui/components/Form/UI/GooglePlace/types';
import crudMessages from 'containers/Crud/Fields/Contact/messages';

import type { GeneralForm } from './types';

function formatAddress(v: Address | undefined): string {
  return [v?.street_number || '', v?.street_name || ''].join(' ').trim();
}

export default function LocationField(): JSX.Element {
  const form = useForm();
  const { values } = useFormState<GeneralForm>();

  const value: Address = {
    street_name: values.client.address,
    street_number: null,
    postal_code: values.client.postal_code,
    city: values.client.city,
    country: values.client.country?.name || null,
    country_code: null,
    latitude: null,
    longitude: null,
  };

  const onLocationChange = useCallback(
    (v: Address | undefined): void => {
      form.batch(() => {
        form.change('client.address', formatAddress(v));
        form.change('client.postal_code', v?.postal_code);
        form.change('client.city', v?.city);
        form.change('client.country', {
          code: v?.country_code,
          name: v?.country,
        });
        form.change('client.insee_code', '');
      });
    },
    [form],
  );

  return (
    <>
      <FormLayout.Row>
        <Field name="client.address">
          {({ meta }): JSX.Element => (
            <FormUI.GooglePlace
              value={value}
              formatDisplayValue={formatAddress}
              error={meta.touched && !meta.valid}
              onChange={onLocationChange}
              label={<FormattedMessage id={crudMessages.address.id} />}
            />
          )}
        </Field>
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={crudMessages.addressAdditional.id} />}
          name="client.address_l2"
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          disabled
          label={<FormattedMessage id={crudMessages.addressPostalCode.id} />}
          name="client.postal_code"
        />
        <Input
          disabled
          label={<FormattedMessage id={crudMessages.addressInseeCode.id} />}
          name="client.insee_code"
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={crudMessages.addressCity.id} />}
          name="client.city"
        />
        <Input
          disabled
          label={<FormattedMessage id={crudMessages.addressCountry.id} />}
          name="client.country.name"
        />
      </FormLayout.Row>
    </>
  );
}
