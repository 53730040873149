import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { TransparentButton } from 'components/Button';

/**
 * Display a button with text and cross for remove it.
 */
export default class LabelWithAction extends PureComponent {
  /**
   * Action to execute when user click on cross button.
   */
  onCrossClick = () => {
    const { setDeleteFunction, onRemove } = this.props;
    if (setDeleteFunction) {
      setDeleteFunction(() => onRemove());
      return;
    }
    onRemove();
  };

  render() {
    const { name, onClick, className, isDoubleClick } = this.props;

    return (
      <div className={className}>
        <TransparentButton
          onClick={onClick}
          className="transparentButton label__text"
          isDoubleClick={isDoubleClick}
        >
          <strong>{name}</strong>
        </TransparentButton>
        <button
          onClick={() => this.onCrossClick()}
          type="button"
          className="label__crossButton"
        >
          ×
        </button>
      </div>
    );
  }
}

LabelWithAction.propTypes = {
  /** Function for remove the label */
  onRemove: PropTypes.func.isRequired,
  /** Dispatch function for confirm modal */
  setDeleteFunction: PropTypes.func,
  /** Name of label */
  name: PropTypes.string.isRequired,
  /** Function to execute when user click on label */
  onClick: PropTypes.func.isRequired,
  /** override default style */
  className: PropTypes.string,
  /** The function is apply on double click or not */
  isDoubleClick: PropTypes.bool,
};

LabelWithAction.defaultProps = {
  className: 'label',
  setDeleteFunction: null,
  isDoubleClick: false,
};
