import type { PDFPage } from 'pdf-lib';

import type { Input } from '../../models';
import type { BuildPDFContext } from './types';
import renderSVG from './renderSVG';

// Data extracted from images/circle.svg to avoid parsing it
const Circle = {
  width: 131.876,
  height: 78.683,
  path: `
    M80.222.308C61.465-1.227.62 7.005.132 36.754c.435 25.544 40.644 41.79
    66.139 41.79 34.866.33 64.963-12.763 65.424-33.859
    1.71-29.376-41.902-35.569-71.363-36.061 14.877 3.434 66.478 4.075 63.98
    36.457-1.382 18.145-35.194 26.845-58.98
    26.012-20.787-.744-55.619-12.59-56.74-35.944C8.699 15.85 50.233 2.91
    80.223.309z
  `,
};

export default function renderCircle(
  input: Input,
  page: PDFPage,
  _context: BuildPDFContext,
): Promise<void> {
  if (!input.value) {
    return Promise.resolve();
  }
  renderSVG(input, page, Circle);
  return Promise.resolve();
}
