import { useDispatch, useSelector } from 'react-redux';

import { ResourceType } from '@advitam/api/models/DocumentEditions';
import {
  RequestDocumentTodoItem,
  SendBatchTodoItem,
} from '@advitam/api/models/Deal/TodoItem';

import DocumentsEditor from 'containers/DocumentsEditor';
import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import { fetchDocuments } from 'containers/Documents/actions';
import UploadFiles from 'components/UploadFiles/NoModal';
import { assert } from 'lib/support';

import { fetchItems } from '../../../Sections/Todolist/thunk';
import { makeSelectDocumentsToUpload } from '../../selectors';
import style from './style.scss';

interface RequestDocumentProps {
  item: RequestDocumentTodoItem | SendBatchTodoItem;
}

export default function RequestDocument({
  item,
}: RequestDocumentProps): JSX.Element | null {
  const dispatch = useDispatch();
  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);
  const documentsToUpload = useSelector(makeSelectDocumentsToUpload(item));

  const onSave = (): void => {
    dispatch(fetchDocuments(deal.uuid));
    dispatch(fetchItems());
  };

  if (documentsToUpload.length === 0) {
    return null;
  }

  return (
    <div className={style.section}>
      <DocumentsEditor afterSaveHook={onSave}>
        {({ uploadFiles }): JSX.Element[] =>
          documentsToUpload.map(document => (
            <UploadFiles
              fileUploaded={document}
              postFiles={(filesToUpload: File[]): void | Promise<void> => {
                assert(deal.uuid !== undefined);
                return uploadFiles(filesToUpload, {
                  type: ResourceType.DEAL,
                  id: document.id,
                  deal: { uuid: deal.uuid },
                });
              }}
            >
              <div className="uploadFiles__documentMissing">
                <div className="uploadFiles__documentMissing__name">
                  {document.name}
                </div>
                <div className="uploadFiles__documentMissing__icon">
                  &#128454;
                </div>
              </div>
            </UploadFiles>
          ))
        }
      </DocumentsEditor>
    </div>
  );
}
