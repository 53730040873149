import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ReactPhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Tippy from '@tippyjs/react';

import PhoneIcon from 'images/icons/phone.svg';

export function PhoneInput({
  className,
  message,
  toolTipMessage,
  errorMessage,
  hideErrorMessage,
  placeholderMessage,
  withLink,
  input: { onChange, onBlur, value, name, disabled },
  meta: { error, touched },
}) {
  const { formatMessage } = useIntl();

  const onChangeWrapper = v => {
    onChange({ target: { value: v || '' } });
  };

  return (
    <label className={className}>
      <div className="input__message">
        <span className="input__message--text">
          {message && formatMessage(message)}
        </span>
        {toolTipMessage && (
          <Tippy content={<FormattedMessage {...toolTipMessage} />}>
            <span className="input__message--tooltip" />
          </Tippy>
        )}
      </div>
      {withLink && value && isValidPhoneNumber(value) && (
        <a href={`tel:${value}`}>
          <PhoneIcon />
        </a>
      )}
      <ReactPhoneInput
        id={name}
        onChange={onChangeWrapper}
        onBlur={onBlur}
        value={value}
        className={touched && error ? 'errored' : ''}
        placeholder={placeholderMessage && formatMessage(placeholderMessage)}
        initialValueFormat="national"
        defaultCountry="FR"
        disabled={disabled}
      />
      {touched && error && (
        <span className="error">
          {!hideErrorMessage && formatMessage(errorMessage || { id: error })}
        </span>
      )}
    </label>
  );
}

PhoneInput.defaultProps = {
  meta: {},
  className: '',
  hideErrorMessage: false,
};

PhoneInput.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object,
  toolTipMessage: PropTypes.object,
  errorMessage: PropTypes.object,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  hideErrorMessage: PropTypes.bool,
  placeholderMessage: PropTypes.object,
  withLink: PropTypes.bool,
};

export default PhoneInput;
