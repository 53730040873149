import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { HardSpinner, PageSpinner } from '@advitam/ui';

import { Path } from 'containers/App/constants';
import { useEntitySeed } from 'containers/Entities/useEntitySeed';
import ResourceCable, {
  ResourceDestroyModal,
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { assert } from 'lib/support';
import { withDatasets } from 'slices/data';

import { GRAVEYARD_SKELETON } from './constants';
import slice, { clearError, setGraveyard } from './slice';
import { fetchGraveyard } from './thunk';
import {
  makeSelectRawGraveyard,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectError,
} from './selectors';

function Container(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const graveyard = useSelector(makeSelectRawGraveyard());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());
  const seed = useEntitySeed();

  const { id } = useParams();
  const graveyardId = id && parseInt(id, 10);
  const canRender = !isLoading && graveyard;

  const fetchResource = useCallback(() => {
    assert(typeof graveyardId === 'number');
    dispatch(fetchGraveyard(graveyardId));
  }, [dispatch, graveyardId]);

  useEffect(() => {
    if (!graveyardId) {
      if (seed) {
        dispatch(setGraveyard({ ...GRAVEYARD_SKELETON, ...seed }));
      } else {
        navigate(Path.ENTITIES, { replace: true });
      }
    } else if (graveyardId !== graveyard?.id) {
      fetchResource();
    }

    return () => {
      dispatch(clearError());
    };
  }, [graveyardId]);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {graveyardId && (
        <ResourceCable
          resourceId={graveyardId}
          resourceType={ResourceRoomType.GRAVEYARD}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={fetchResource} />
          <ResourceDestroyModal path={Path.ENTITIES} />
        </ResourceCable>
      )}
    </>
  );
}

export default compose(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(Container);
