import { authenticate } from '../../lib/decorators'
import { put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { AdministrativeProcedureJSON } from '../../models/AdministrativeProcedure'
import { Archive } from './Archive'
import { OngoingFormalities } from './OngoingFormalities'
import { serialize } from './serializers'
import { SupportingDocuments } from './SupportingDocuments'

export class AdministrativeProcedures {
  static readonly Archive = Archive

  static readonly OngoingFormalities = OngoingFormalities

  static readonly SupportingDocuments = SupportingDocuments

  @authenticate
  static update(
    procedure: AdministrativeProcedureJSON,
  ): ApiRequestDescriptor<AdministrativeProcedureJSON> {
    return put(`/api/v1/administrative_procedures/${procedure.id}`, serialize(procedure))
  }
}
