import { authenticate } from '../../../lib/decorators'
import { get, post, put } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { BusinessUserJSON } from '../../../models/User/BusinessUser'
import { Auth } from '.'

type AuthResponse<T = undefined> = Auth<T>['Response']

const RESET_REDIRECT_URL = [
  process.env.API_ENDPOINT || '',
  'api/v1/users/reset_password_redirection',
].join('/')

export interface ResetPasswordResponse {
  success: boolean
  message: string
}

export class Password {
  static create(email: string, redirectUrl: string): ApiRequestDescriptor<ResetPasswordResponse> {
    return post('/api/v1/pro/auth/password', {
      email,
      redirect_url: redirectUrl,
    })
  }

  @authenticate
  static update(password: string): ApiRequestDescriptor<AuthResponse<BusinessUserJSON>> {
    return put('/api/v1/pro/auth/password', {
      password,
      password_confirmation: password,
    })
  }

  @authenticate
  static getResetToken(token: string, config: string): ApiRequestDescriptor<AuthResponse> {
    return get('/api/v1/pro/auth/password/edit', {
      config,
      redirect_url: RESET_REDIRECT_URL,
      reset_password_token: token,
    })
  }
}
