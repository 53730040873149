import { pick } from 'lodash';

import { DealJSON } from 'models/v2/Deal';

export interface ItemPayload {
  deal: Pick<DealJSON, 'commentary'>;
}

// This serializer will tend to change according to
// item deal update serialization
export function serialize(deal: DealJSON): ItemPayload {
  return { deal: pick(deal, 'commentary') };
}
