import { DocumentTypeTag } from './Tag'

export interface DocumentTypeJSON {
  id: number
  description: string
  slug: string
  pretty_name: string
  tags: DocumentTypeTag[]
}

export const enum DocumentTypeSlug {
  ACT_OF_DEATH = 'act_of_death',
  AUTHORITY_PROOF_OF_ID = 'authority_proof_of_id',
  CEREMONY_CERTIFICATE = 'ceremony_certificate',
  DEFUNCT_IBAN = 'defunct_bank_account_identity',
  DEFUNCT_PASSPORT = 'defunct_passport',
  DEFUNCT_PROOF_OF_ID = 'defunct_proof_of_id',
  FAMILY_RECORD_BOOK = 'family_record_book',
  PROOF_OF_RESIDENCE = 'proof_of_residence',
  USER_PROOF_OF_ID = 'user_proof_of_id',
  USER_SIGNATURE = 'user_signature',
}

export { DocumentTypeTag }
