import { Product } from './Product';
import { Service } from './Service';

// TODO: enum once Javascript do not need it anymore
export enum DealParentType {
  DEAL = 'Deal',
  MEMORIAL = 'Memorial',
}

export interface ItemDelivery {
  step_id: number | null;
  step_type: number | null;
  message: string;
  signature: string;
}

export interface ItemDetails {
  services: Service[];
  products: Product[];
  parent_id?: number;
  parent_type?: DealParentType;
  delivery: ItemDelivery;
}
