// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentEditor-module__error--T0T1j{display:flex;flex-direction:column;align-items:center;padding:32px 16px}.DocumentEditor-module__error--T0T1j button{margin-top:24px}`, "",{"version":3,"sources":["webpack://./src/containers/Documents/Toolbar/DocumentEditor/DocumentEditor.module.scss"],"names":[],"mappings":"AAEA,qCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CAEA,4CACE,eAAA","sourcesContent":["@import '/src/scss/mixins/vars';\n\n.error {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 32px 16px;\n\n  button {\n    margin-top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `DocumentEditor-module__error--T0T1j`
};
export default ___CSS_LOADER_EXPORT___;
