import { omit } from 'lodash';

import { Deal, DealJSON } from 'models/Deal';

export interface FuneralPayload {
  deal: Omit<Deal | DealJSON, 'clients'>;
}

export function serialize(deal: Deal | DealJSON): FuneralPayload {
  return { deal: omit(deal, 'clients') };
}
