import { ApiError, SerializedApiError } from '@advitam/api';
import { createSlice } from '@reduxjs/toolkit';

import type { FullClientJSON } from '@advitam/api/models/Client/Full';

import { DEAL_IDENTITY_CLIENT_AUTOCOMPLETION_MODAL } from './constants';
import { autocompleteClient } from './thunk';

export interface State {
  abilityIndex: number | null;
  pendingClient: FullClientJSON | null;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DEAL_IDENTITY_CLIENT_AUTOCOMPLETION_MODAL]: State;
}

const initialState: State = {
  abilityIndex: null,
  pendingClient: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: DEAL_IDENTITY_CLIENT_AUTOCOMPLETION_MODAL,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clear(state) {
      state.pendingClient = null;
      state.abilityIndex = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(autocompleteClient.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      autocompleteClient.fulfilled,
      (state, { meta, payload }) => {
        state.isLoading = false;
        state.pendingClient = payload;
        if (payload) {
          state.abilityIndex = meta.arg.index;
        }
      },
    );
    builder.addCase(autocompleteClient.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clear } = slice.actions;
export default slice;
