import { FormattedMessage } from 'react-intl';

import { SupplierSummaryJSON } from '@advitam/api/models/Supplier/Summary';
import { ResourceList } from '@advitam/ui';

import { SUPPLIERS_PATH } from 'containers/App/constants';
import { ExternalLink } from 'components/Link';
import actionMessages from 'messages/actions';

import messages from '../messages';
import style from '../Suppliers.module.scss';
import State from './State';

interface SuppliersRowProps {
  supplier: SupplierSummaryJSON;
}

export default function SuppliersRow({
  supplier,
}: SuppliersRowProps): JSX.Element | null {
  const { id, disabled, name, phone, email, department, negociated } = supplier;

  const tooltipMessage = disabled ? messages.disabled : messages.active;
  const link = {
    href: [SUPPLIERS_PATH, id].join('/'),
    internal: true,
  };

  return (
    <ResourceList.Row className={style.row} link={link}>
      <ResourceList.Cell>
        <State
          disabled={disabled}
          tooltip={<FormattedMessage id={tooltipMessage.id} />}
        />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.name}>{name}</ResourceList.Cell>
      <ResourceList.Cell className={style.phone}>
        {phone && (
          <ExternalLink
            href={`tel:${phone}`}
            onClick={(ev): void => ev.stopPropagation()}
          >
            {phone}
          </ExternalLink>
        )}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.email}>
        {email && (
          <ExternalLink
            href={`mailto:${email}`}
            onClick={(ev): void => ev.stopPropagation()}
          >
            {email}
          </ExternalLink>
        )}
      </ResourceList.Cell>
      <ResourceList.Cell>{department}</ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage
          id={negociated ? actionMessages.yes.id : actionMessages.no.id}
        />
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
