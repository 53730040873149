import { useIntl } from 'react-intl';

import { FormattedPrice } from '@advitam/ui';

import { TransparentButton } from 'components/Button';
import { Payment } from 'components/Format';
import type { PaymentJSON } from 'models/Payment';
import { safeFormatMessage } from 'utils/functions.typed';

import paymentMessages from '../Payment/messages';

interface PaymentDataProps {
  payment: PaymentJSON;
  updateDisplayedPayments: () => void;
}

export default function PaymentData({
  payment,
  updateDisplayedPayments,
}: PaymentDataProps): JSX.Element {
  const intl = useIntl();
  const status = payment.amount < 0 ? 'reimbursed' : payment.state;

  return (
    <TransparentButton
      onClick={updateDisplayedPayments}
      className={`
            payments__list__body__row
            payments__list__body__row--${status}
          `}
    >
      <div className="col-8">
        <Payment.FormattedName payment={payment} />
      </div>
      <div className="col-3">
        <FormattedPrice value={payment.amount} />
      </div>
      <div className="col-3">
        {payment.mean && safeFormatMessage(intl, paymentMessages, payment.mean)}
      </div>
    </TransparentButton>
  );
}
