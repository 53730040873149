import { createSelector } from 'reselect';

import { makeSelectRawDeal } from 'containers/Deal/selectors';

const makeSelectDefunct = () =>
  createSelector(makeSelectRawDeal(), substate =>
    substate ? substate.defunctInfo : {},
  );

const makeSelectFather = () =>
  createSelector(makeSelectRawDeal(), substate => {
    const compInfo = substate.defunctInfo.defunctCompInfo;
    if (compInfo) return compInfo.father;
    return {};
  });

const makeSelectMother = () =>
  createSelector(makeSelectRawDeal(), substate => {
    const compInfo = substate.defunctInfo.defunctCompInfo;
    if (compInfo) return compInfo.mother;
    return {};
  });

const makeSelectPartner = () =>
  createSelector(makeSelectRawDeal(), substate => {
    const compInfo = substate.defunctInfo.defunctCompInfo;
    if (compInfo) return compInfo.partner;
    return {};
  });

export {
  makeSelectDefunct,
  makeSelectFather,
  makeSelectMother,
  makeSelectPartner,
};
