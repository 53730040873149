import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { SupportingDocumentOwnerType } from '@advitam/api/models/SupportingDocuments/OwnerType';
import { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import Layout from 'containers/Crud/Layout';
import {
  SupportingDocuments,
  supportingDocumentsSlice,
  makeSelectSupportingDocuments,
  saveSupportingDocuments,
} from 'containers/Crud';
import { assert } from 'lib/support';

import Wrapper from './Wrapper';
import { makeSelectRawWorship } from '../selectors';
import { WorshipForm } from './types';

function SupportingDocumentsSection(): JSX.Element {
  const dispatch = useDispatch();

  const worship = useSelector(makeSelectRawWorship());
  assert(worship?.id !== undefined);
  const documents = useSelector(makeSelectSupportingDocuments());

  const owner = useMemo(
    () => ({ type: SupportingDocumentOwnerType.WORSHIP, id: worship.id }),
    [worship.id],
  );

  const onSubmit = useCallback(
    ({ sectionValues }: WorshipForm<SupportingDocumentJSON[]>) => {
      dispatch(saveSupportingDocuments({ values: sectionValues, owner }));
    },
    [dispatch, owner],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={documents}>
      <Layout.Container>
        <SupportingDocuments owner={owner} />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(supportingDocumentsSlice)(SupportingDocumentsSection);
