import { createSelector, Selector } from 'reselect';

import { Worship, WorshipJSON } from '@advitam/api/models/Worship';
import type { SerializedApiError } from '@advitam/api';

import { WORSHIP } from './constants';
import type { AppStateSubset, State } from './slice';
import { NewWorship } from './types';

type WorshipSelector<T> = Selector<AppStateSubset, T>;

const selectWorshipDomain = (state: AppStateSubset): State => state[WORSHIP];

export const makeSelectRawWorship = (): WorshipSelector<
  WorshipJSON | NewWorship | null
> => createSelector(selectWorshipDomain, ({ worship }) => worship);

export const makeSelectWorship = (): WorshipSelector<Worship | null> =>
  createSelector(makeSelectRawWorship(), rawWorship =>
    rawWorship?.id ? new Worship(rawWorship) : null,
  );

export const makeSelectIsLoading = (): WorshipSelector<boolean> =>
  createSelector(selectWorshipDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): WorshipSelector<boolean> =>
  createSelector(selectWorshipDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): WorshipSelector<boolean> =>
  createSelector(selectWorshipDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): WorshipSelector<boolean> =>
  createSelector(selectWorshipDomain, ({ isUpdatingName }) => isUpdatingName);

export const makeSelectError = (): WorshipSelector<SerializedApiError | null> =>
  createSelector(selectWorshipDomain, ({ error }) => error);

export const makeSelectDestroyError = (): WorshipSelector<SerializedApiError | null> =>
  createSelector(selectWorshipDomain, ({ destroyError }) => destroyError);
