import type { ComponentType, SVGAttributes } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { EntityType } from '@advitam/api/models/Entity/EntityType';
import { LocationType } from '@advitam/api/models/LocationType';
import { Tooltip } from '@advitam/ui';
import Crematorium from '@advitam/ui/images/icons/urn.svg';
import FuneralParlor from '@advitam/ui/images/icons/candle.svg';
import Graveyard from '@advitam/ui/images/icons/monument.svg';

import Airport from './icons/airport.svg';
import Cityhall from './icons/cityhall.svg';
import Consulate from './icons/consulate.svg';
import Hospital from './icons/hospital.svg';
import Police from './icons/police.svg';
import Prefecture from './icons/prefecture.svg';
import Rha from './icons/rha.svg';
import Worship from './icons/worship.svg';
import messages from './messages';

const ENTITY_ICONS: Record<
  EntityType,
  ComponentType<SVGAttributes<SVGElement>>
> = {
  [LocationType.AIRPORT]: Airport,
  [LocationType.CITYHALL]: Cityhall,
  [LocationType.CONSULATE]: Consulate,
  [LocationType.CREMATORIUM]: Crematorium,
  [LocationType.FUNERAL_PARLOR]: FuneralParlor,
  [LocationType.GRAVEYARD]: Graveyard,
  [LocationType.HOSPITAL]: Hospital,
  [LocationType.POLICE]: Police,
  [LocationType.PREFECTURE]: Prefecture,
  [LocationType.REGIONAL_HEALTH_AUTHORITY]: Rha,
  [LocationType.WORSHIP]: Worship,
};

// Sorted by alphabetical order in french
export const ENTITY_TEXTS: Record<EntityType, MessageDescriptor> = {
  [LocationType.AIRPORT]: messages.airport,
  [LocationType.REGIONAL_HEALTH_AUTHORITY]: messages.regionalHealthAuthority,
  [LocationType.GRAVEYARD]: messages.graveyard,
  [LocationType.CONSULATE]: messages.consulate,
  [LocationType.CREMATORIUM]: messages.crematorium,
  [LocationType.HOSPITAL]: messages.hospital,
  [LocationType.WORSHIP]: messages.worship,
  [LocationType.CITYHALL]: messages.cityhall,
  [LocationType.FUNERAL_PARLOR]: messages.funeralParlor,
  [LocationType.POLICE]: messages.police,
  [LocationType.PREFECTURE]: messages.prefecture,
};

interface EntityIconProps {
  entityType: EntityType;
}

export default function EntityIcon({
  entityType,
}: EntityIconProps): JSX.Element | null {
  const Icon = ENTITY_ICONS[entityType];
  const text = ENTITY_TEXTS[entityType];

  // This should not happen, but it avoids crashing if this does
  if (!text || !Icon) {
    return null;
  }

  return (
    <Tooltip content={<FormattedMessage id={text.id} />}>
      <Icon />
    </Tooltip>
  );
}
