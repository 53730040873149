import { ReactNode } from 'react'
import style from '../Step.module.scss'

interface SeparatorProps {
  icon?: ReactNode
}

export default function Separator({ icon }: SeparatorProps): JSX.Element {
  if (!icon) {
    return (
      <div className={style.separator}>
        <span className={style.icon_placeholder}></span>
      </div>
    )
  }

  return (
    <div className={style.separator}>
      <span className={style.icon}>{icon}</span>
    </div>
  )
}
