import { useCallback, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import type { RateJSON } from '@advitam/api/models/Flight/Rate';
import { Model } from '@advitam/api/models/Model';
import { Button, ConfirmationModal, ResourceList } from '@advitam/ui';
import { assert } from 'lib/support';

import { makeSelectIsLoading } from '../../selectors';
import messages from '../../messages';
import type { FlightForm } from '../types';
import Header from './Header';
import style from './Rates.module.scss';
import Row from './Row';
import type { RateForm } from './types';

function doNothing(): void {
  // As the name suggests
}

export default function List(): JSX.Element {
  const [indexOfRateToDelete, setIndexOfRateToDelete] = useState<number | null>(
    null,
  );

  const isLoading = useSelector(makeSelectIsLoading());

  const form = useForm<FlightForm & RateForm>();
  const { values } = useFormState<FlightForm>();
  const { rates } = values;

  const addRate = useCallback(() => {
    const updatedRates = [{} as RateJSON, ...rates];
    form.change('rates', updatedRates);
  }, [form, rates]);

  const onDeletionConfirmed = useCallback(() => {
    assert(indexOfRateToDelete !== null);
    if (rates[indexOfRateToDelete].id) {
      const rate = { ...rates[indexOfRateToDelete] };
      Model.setDestroyed(rate);
      form.change(`rates[${indexOfRateToDelete}]`, rate);
    } else {
      const updatedRates = [...rates];
      updatedRates.splice(indexOfRateToDelete, 1);
      form.change('rates', updatedRates);
    }
    setIndexOfRateToDelete(null);
  }, [form, rates, indexOfRateToDelete, setIndexOfRateToDelete]);

  const closeDeletionConfirmation = useCallback(() => {
    setIndexOfRateToDelete(null);
  }, [setIndexOfRateToDelete]);

  return (
    <>
      <ResourceList.Container
        filters={
          <Button
            primary
            onClick={addRate}
            text={<FormattedMessage id={messages.addRate.id} />}
            className={style.add_rate}
          />
        }
        header={<Header />}
        fetchResources={doNothing}
        hasMore={false}
        isLoading={isLoading}
      >
        <Field name="rates">
          {(): JSX.Element => (
            <>
              {rates.map((rate, index) =>
                Model.isDestroyed(rate) ? null : (
                  <Row
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${rate.id || ''}-${index}`}
                    prefix={`rates[${index}]`}
                    rate={rate}
                    onDelete={(): void => setIndexOfRateToDelete(index)}
                  />
                ),
              )}
            </>
          )}
        </Field>
      </ResourceList.Container>

      {indexOfRateToDelete !== null && (
        <ConfirmationModal
          isOpen
          text={<FormattedMessage id={messages.deletionConfirmationText.id} />}
          confirm={
            <FormattedMessage id={messages.deletionConfirmationConfirm.id} />
          }
          onConfirm={onDeletionConfirmed}
          cancel={
            <FormattedMessage id={messages.deletionConfirmationCancel.id} />
          }
          onCancel={closeDeletionConfirmation}
        />
      )}
    </>
  );
}
