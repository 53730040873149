import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@advitam/ui/components/Button';
import ErrorText from '@advitam/ui/components/ErrorText';
import {
  DateOnlyInput,
  FileInput,
  FormLayout,
} from '@advitam/ui/components/Form';
import { FormattedApiError } from '@advitam/ui/components/Format';
import Modal, { ModalTitle } from '@advitam/ui/components/Modal';
import Spinner from '@advitam/ui/components/Spinner';
import Text from '@advitam/ui/components/Text';

import messages from './messages';
import slice, { close, open } from './slice';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsOpen,
} from './selectors';
import { makeDeceased } from './thunk';
import { GeneralForm } from '../types';
import style from './DeceasedModal.module.scss';

export default function DeceasedModal(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isOpen = useSelector(makeSelectIsOpen());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const { values } = useFormState<GeneralForm>();

  const onCancel = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    dispatch(makeDeceased(values));
  }, [dispatch, values]);

  return (
    <Modal
      isOpen={isOpen}
      className={`col-4 col-md-6 compact-design ${style.modal}`}
    >
      <ModalTitle>
        <FormattedMessage id={messages.title.id} />
      </ModalTitle>

      <Text small tagName="p" className={style.text}>
        <FormattedMessage id={messages.text.id} values={{ br: <br /> }} />
      </Text>
      <FormLayout.Row>
        <DateOnlyInput
          name="client.death_date"
          required
          label={<FormattedMessage id={messages.deathDate.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <FileInput
          name="actOfDeath"
          required
          label={<FormattedMessage id={messages.actOfDeath.id} />}
          placeholder={intl.formatMessage(messages.actOfDeathPlaceholder)}
        />
      </FormLayout.Row>
      {error && (
        <ErrorText center>
          <FormattedApiError error={error} />
        </ErrorText>
      )}

      <div className={style.buttons}>
        <Button
          outline
          onClick={onCancel}
          text={<FormattedMessage id={messages.cancel.id} />}
        />
        <Button
          primary
          onClick={onConfirm}
          disabled={isLoading}
          text={
            isLoading ? (
              <Spinner />
            ) : (
              <FormattedMessage id={messages.confirm.id} />
            )
          }
        />
      </div>
    </Modal>
  );
}

export { slice, open };
