import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DealType } from '@advitam/api/models/Deal/Type';
import { SideBar as SideBarContainer } from 'components/SideBar';
import BrandSelection from 'components/BrandSelection';
import Price from 'components/Price';

import Links from './Links';
import DealState from './DealState';
import DealOwner from './Owner';
import KoChoiceModal from './KoReasonModal/index.tsx';
import {
  CLIENT,
  TODO_LIST,
  DEAL_SUMMARY,
  STEPS,
  PRODUCTS,
  SUPPLIERS,
  DOCUMENTS,
  PAYMENTS,
  OTHER,
  HISTORY,
  MEMORIAL,
} from './constants';
import { DEAL_TYPE_FUNERAL } from '../constants.ts';
import ReminderButton from '../DealMarble/ReminderButton.tsx';

/**
 * Return the value of the step or null
 *
 * @param {String} value value of one of the steps
 * @returns {String} value of one of the steps or null
 */
function valueOfStep(value, deal) {
  switch (value) {
    case TODO_LIST:
    case CLIENT:
    case DEAL_SUMMARY:
    case STEPS:
    case PRODUCTS:
    case SUPPLIERS:
    case DOCUMENTS:
    case PAYMENTS:
    case OTHER:
    case HISTORY:
    case MEMORIAL:
      return value;
    case '':
      if (deal.deal_type === DEAL_TYPE_FUNERAL) {
        return TODO_LIST;
      }
      return CLIENT;
    default:
      return null;
  }
}

class SideBar extends PureComponent {
  componentDidMount() {
    const { deal, setSection, isNewDeal, location } = this.props;
    if (deal && !isNewDeal && location) {
      const value = valueOfStep(location.hash.substr(1), deal);
      setSection(value);
      return;
    }
    setSection(CLIENT);
  }

  componentDidUpdate(prevProps) {
    const { location, deal, isNewDeal, setSection } = this.props;
    if (
      deal &&
      !isNewDeal &&
      location &&
      prevProps.location &&
      location.hash !== prevProps.location.hash
    ) {
      const value = valueOfStep(location.hash.substr(1), deal);
      setSection(value);
    }
  }

  render() {
    const {
      isNewDeal,
      user,
      brands,
      brandSelectedId,
      putDealBrand,
      deal,
      updateManualPrice,
      setSection,
      sectionOpened,
      links,
    } = this.props;

    return (
      <>
        <SideBarContainer>
          <Links
            deal={deal}
            links={links}
            setSection={setSection}
            sectionOpened={sectionOpened}
            isNewDeal={isNewDeal}
          />
          <Price
            value={deal.auto_price}
            onChange={updateManualPrice}
            manualPrice={deal.manual_price}
            userRole={user.role}
          />
          {!isNewDeal && <DealState />}
          {!isNewDeal && user.isFuneralDirectorOrAbove && <DealOwner />}
          {!isNewDeal && (
            <BrandSelection
              brands={brands}
              brandSelectedId={brandSelectedId}
              onChangeBrand={brand => putDealBrand(brand)}
            />
          )}
          {!isNewDeal && deal.deal_type === DealType.MARBLE && (
            <ReminderButton />
          )}
        </SideBarContainer>
        <KoChoiceModal />
      </>
    );
  }
}

SideBar.propTypes = {
  /** The deal is a new deal */
  isNewDeal: PropTypes.bool.isRequired,
  /** Object with data of current user */
  user: PropTypes.object.isRequired,
  /** Object which contains data of deal */
  deal: PropTypes.object.isRequired,
  /** Function to update the opened section */
  setSection: PropTypes.func.isRequired,
  /** Current section opened */
  sectionOpened: PropTypes.string.isRequired,
  /** location data */
  location: PropTypes.object,
  /** list of funeral brands */
  brands: PropTypes.array.isRequired,
  /** Funeral brand selected */
  brandSelectedId: PropTypes.number,
  /** function to update funeral brand */
  putDealBrand: PropTypes.func.isRequired,
  /** Function to update manual price */
  updateManualPrice: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
};

export default SideBar;
