import PropTypes from 'prop-types';

const EditIcon = ({ isActivated }) => (
  <svg
    viewBox="0 0 63.6 63.6"
    className={isActivated ? 'icon--on' : 'icon--off'}
  >
    <path d="M52.3.038l-4.2 4.2 11.3 11.3 4.2-4.2L52.3.038zm-7 7l-43.8 43.8-1.5 12.8 12.7-1.4 43.8-43.8-11.2-11.4z" />
  </svg>
);

EditIcon.propTypes = {
  isActivated: PropTypes.bool,
};

export default EditIcon;
