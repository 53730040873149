// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__comments--fp9hm{display:flex;flex-direction:row;flex:1;color:#fff}.style__commentary--w6Giz{display:flex;flex-direction:column;flex:1;padding-right:10px}.style__advisor--d_i0E{position:relative}.style__advisor--d_i0E textarea{margin:0;height:150px;resize:none;font-family:Raleway,Arial,sans-serif}.style__advisor--d_i0E.style__loading--VZpRH>textarea{background-color:#ccc}.style__advisor--d_i0E>div{left:50%;top:50%;transform:translate(-50%, -50%)}.style__client--1HqIn>div{height:150px;padding:2px 5px;border:1px solid #aaa;border-radius:3px;color:#333;background-color:#fafafa}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Commentaries/style.scss"],"names":[],"mappings":"AAAA,wBACE,YAAA,CACA,kBAAA,CACA,MAAA,CACA,UAAA,CAGF,0BACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,kBAAA,CAGF,uBACE,iBAAA,CAEA,gCACE,QAAA,CACA,YAAA,CACA,WAAA,CACA,oCAAA,CAGF,sDACE,qBAAA,CAIF,2BACE,QAAA,CACA,OAAA,CACA,+BAAA,CAKF,0BACE,YAAA,CACA,eAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA,CACA,wBAAA","sourcesContent":[".comments {\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  color: white;\n}\n\n.commentary {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  padding-right: 10px;\n}\n\n.advisor {\n  position: relative;\n\n  textarea {\n    margin: 0;\n    height: 150px;\n    resize: none;\n    font-family: Raleway, Arial, sans-serif;\n  }\n\n  &.loading > textarea {\n    background-color: #ccc;\n  }\n\n  // spinner\n  > div {\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  }\n}\n\n.client {\n  & > div {\n    height: 150px;\n    padding: 2px 5px;\n    border: 1px solid #aaa;\n    border-radius: 3px;\n    color: #333;\n    background-color: #fafafa;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comments": `style__comments--fp9hm`,
	"commentary": `style__commentary--w6Giz`,
	"advisor": `style__advisor--d_i0E`,
	"loading": `style__loading--VZpRH`,
	"client": `style__client--1HqIn`
};
export default ___CSS_LOADER_EXPORT___;
