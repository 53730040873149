import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { CollapsablePerson } from 'containers/Deal/EditPerson';

import messages from './messages';

const Rightholders = ({ rightHolders, updateConcession }) => {
  /**
   * Function to remove a right holder
   *
   * @param {number} index index of right holder to remove
   */
  const removeRightHolder = index => {
    const newRightHolders = [...rightHolders];
    newRightHolders.splice(index, 1);
    return newRightHolders;
  };

  /**
   * Function to add a right holder
   */
  const addRightHolder = () => {
    updateConcession({
      rightHolders: [...rightHolders, {}],
    });
  };

  /**
   * Function to update a right holder
   *
   * @param {number} index index of right holder to update
   * @param {object} update object to update with
   */
  const setRightHolder = (index, update) => {
    const newRightHolders = [...rightHolders];
    newRightHolders[index] = update;
    return newRightHolders;
  };

  return (
    <div className="rightHolder">
      <div className="rightHolder__header">
        <h5 className="rightHolder__header__title">
          <FormattedMessage {...messages.rightholders} />
        </h5>
        <button
          className="rightHolder__button"
          type="button"
          onClick={addRightHolder}
        >
          +
        </button>
      </div>
      {rightHolders.map((person, index) => (
        <CollapsablePerson
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          person={person}
          defaultOpen
          message={messages.person}
          withRemove
          removePerson={() =>
            updateConcession({ rightHolders: removeRightHolder(index) })
          }
          handleUpdate={update =>
            updateConcession({ rightHolders: setRightHolder(index, update) })
          }
        />
      ))}
    </div>
  );
};

Rightholders.propTypes = {
  /** right holders data */
  rightHolders: PropTypes.array.isRequired,
  /** function to update a concession */
  updateConcession: PropTypes.func.isRequired,
};

export default Rightholders;
