import { defineMessages } from 'react-intl';

export default defineMessages({
  _custom: {
    id: 'app.messages.families._custom',
  },
  air_repatriation: {
    id: 'app.messages.families.air_repatriation',
  },
  care: {
    id: 'app.messages.families.care',
  },
  'death-announcement': {
    id: 'app.messages.families.death-announcement',
  },
  deposit: {
    id: 'app.messages.families.deposit',
  },
  engraving: {
    id: 'app.messages.families.engraving',
  },
  flowers: {
    id: 'app.messages.families.flowers',
  },
  funeral: {
    id: 'app.messages.families.funeral',
  },
  marble: {
    id: 'app.messages.families.marble',
  },
  medallion: {
    id: 'app.messages.families.medallion',
  },
  music: {
    id: 'app.messages.families.music',
  },
  paperwork: {
    id: 'app.messages.families.paperwork',
  },
  pattern: {
    id: 'app.messages.families.pattern',
  },
  registery: {
    id: 'app.messages.families.registery',
  },
  tac: {
    id: 'app.messages.families.tac',
  },
  tbc: {
    id: 'app.messages.families.tbc',
  },
  translation: {
    id: 'app.messages.families.translation',
  },
  urn: {
    id: 'app.messages.families.urn',
  },
  delivery: {
    id: 'app.messages.families.delivery',
  },
});
