import type { ReactNode } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import Text from '../../Text'
import Label from '../../Label'
import Heading from '../../Heading'

import style from './PlanCard.module.scss'
import messages from './messages'

function small(chunks: ReactNode): ReactNode {
  return (
    <Text large>
      <b>{chunks}</b>
    </Text>
  )
}

interface DropdownCardProps {
  title: ReactNode
  subTitle: ReactNode
  price: number | null
  className?: string
  children: ReactNode
}

export default function PlanCard({
  title,
  subTitle,
  price,
  children,
  className,
}: DropdownCardProps): JSX.Element {
  const intl = useIntl()

  const cardClasses = [style.card, className].filter(Boolean)

  return (
    <div className={cardClasses.join(' ')}>
      <div className={style.head}>
        <Text large className={style.head__title}>
          <b>{title}</b>
        </Text>
        {price ? (
          <>
            <Label>{subTitle}</Label>
            <Heading tagName="div">
              <b>
                <FormattedMessage
                  id={messages.price.id}
                  values={{
                    small,
                    price: intl
                      .formatNumber(price, {
                        style: 'currency',
                        currency: 'EUR',
                        maximumFractionDigits: Number.isInteger(price) ? 0 : undefined,
                      })
                      .replace('€', '')
                      .trim(),
                  }}
                />
              </b>
            </Heading>
          </>
        ) : (
          <Text tagName="div" className={style.request_quotation}>
            <i>
              <FormattedMessage id={messages.requestQuotation.id} values={{ br: <br /> }} />
            </i>
          </Text>
        )}
      </div>
      {children}
    </div>
  )
}
