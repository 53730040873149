import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { StayJSON } from '@advitam/api/models/FuneralParlor';

import { FUNERAL_PARLOR_MISCELLANEOUS } from './constants';
import { fetchStays } from './thunk';

export interface State {
  stays: StayJSON[];
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [FUNERAL_PARLOR_MISCELLANEOUS]: State;
}

const initialState: State = {
  stays: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: FUNERAL_PARLOR_MISCELLANEOUS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchStays.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.stays = [];
    });
    builder.addCase(fetchStays.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.stays = payload;
    });
    builder.addCase(fetchStays.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
