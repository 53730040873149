import { OpeningHoursJSON } from '@advitam/api/models/Entity/OpeningHours';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

interface DayTextProps {
  value: OpeningHoursJSON[0] | undefined;
}

export default function DayText({ value }: DayTextProps): JSX.Element {
  if (!value || value.length === 0) {
    return <FormattedMessage id={messages.closed.id} />;
  }

  return (
    <>
      {value.map(([start, end], rangeIndex) => (
        <Fragment key={start}>
          <FormattedMessage id={messages.range.id} values={{ start, end }} />
          {rangeIndex + 1 < value.length ? (
            <FormattedMessage id={messages.rangeJoin.id} />
          ) : (
            ''
          )}
        </Fragment>
      ))}
    </>
  );
}
