import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import messages from './messages';

import {
  NO_BOOKING,
  REJECTED_PREBOOKING,
  CANCELED_PREBOOKING,
  ACCEPTED_PREBOOKING,
  PENDING_PREBOOKING,
} from '../constants';

function BookingButtons({
  globalBookingStatus,
  bookSuppliers,
  preBookSuppliers,
}) {
  if (
    globalBookingStatus === ACCEPTED_PREBOOKING ||
    globalBookingStatus === PENDING_PREBOOKING
  ) {
    return (
      <div className="booking__actions">
        <button type="button" onClick={() => bookSuppliers()}>
          <FormattedMessage {...messages.actionBooking} />
        </button>
      </div>
    );
  }

  if (
    globalBookingStatus === NO_BOOKING ||
    globalBookingStatus === REJECTED_PREBOOKING ||
    globalBookingStatus === CANCELED_PREBOOKING
  ) {
    return (
      <div className="booking__actions">
        <button type="button" onClick={() => preBookSuppliers()}>
          <FormattedMessage {...messages.actionPreBooking} />
        </button>
      </div>
    );
  }

  return null;
}

BookingButtons.propTypes = {
  globalBookingStatus: PropTypes.string.isRequired,
  bookSuppliers: PropTypes.func.isRequired,
  preBookSuppliers: PropTypes.func.isRequired,
};

export default BookingButtons;
