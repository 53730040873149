/*
 * The validity of the results may be checked at https://ec.europa.eu/taxation_customs/vies/vatRequest.html.
 * Note that the vies service checks the actual company behind a vat number.
 * As such, vat-free companies will not return any result.
 */
export function computeVatNumber(siret: string): string {
  // This algorithm is the courtesy of
  // https://www.l-expert-comptable.com/calculateurs/trouver-le-numero-de-tva-intracommunautaire-partir-de-votre-numero-de-siren.html
  const siren = siret.substring(0, 9);
  const sirenAsNumber = parseInt(siren, 10);

  const vatKey = (12 + 3 * (sirenAsNumber % 97)) % 97;
  const vatKeyAsString = `${vatKey}`.padStart(2, '0');

  return `FR${vatKeyAsString}${siren}`;
}
