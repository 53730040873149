import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import messages from './messages';

import SupplierStatus from './SupplierStatus';
import BookingButtons from './BookingButtons';

function SuppliersStatus({
  suppliers,
  globalBookingStatus,
  bookSuppliers,
  preBookSuppliers,
  updateSupplierBookingStatus,
  updateBookingComment,
  bookingCommentLoader,
  syncBookingComment,
}) {
  if (suppliers.length === 0) {
    return null;
  }
  return (
    <>
      <div className="booking__prestation">
        <div className="booking__prestation__header booking__prestation__column--supplier booking__prestation__header--border">
          <FormattedMessage {...messages.supplier} />
        </div>
        <div className="booking__prestation__header booking__prestation__column--comment booking__prestation__header--border">
          <FormattedMessage {...messages.comment} />
        </div>
        <div className="booking__prestation__header booking__prestation__column--prebook booking__prestation__header--border">
          <FormattedMessage {...messages.preBooking} />
        </div>
        <div className="booking__prestation__header booking__prestation__column--book">
          <FormattedMessage {...messages.booking} />
        </div>
      </div>
      {suppliers.map(supplier => (
        <SupplierStatus
          key={supplier.id}
          supplier={supplier}
          syncBookingComment={syncBookingComment}
          updateSupplierBookingStatus={updateSupplierBookingStatus}
          updateBookingComment={updateBookingComment}
          bookingCommentLoader={bookingCommentLoader}
        />
      ))}
      <BookingButtons
        preBookSuppliers={preBookSuppliers}
        bookSuppliers={bookSuppliers}
        globalBookingStatus={globalBookingStatus}
      />
    </>
  );
}

SuppliersStatus.propTypes = {
  suppliers: PropTypes.array.isRequired,
  globalBookingStatus: PropTypes.string.isRequired,
  bookSuppliers: PropTypes.func.isRequired,
  preBookSuppliers: PropTypes.func.isRequired,
  updateSupplierBookingStatus: PropTypes.func.isRequired,
  updateBookingComment: PropTypes.func.isRequired,
  bookingCommentLoader: PropTypes.array.isRequired,
  syncBookingComment: PropTypes.func.isRequired,
};

export default SuppliersStatus;
