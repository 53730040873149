// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__validation-buttons--rZu49{display:flex;justify-content:space-evenly;margin-top:20px}.style__content--j05tS{position:initial;margin:100px auto;padding:16px;width:-moz-fit-content;width:fit-content;border-radius:4px;background:#fff;text-align:center;overflow:visible}`, "",{"version":3,"sources":["webpack://./src/components/CustomModal/style.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,4BAAA,CACA,eAAA,CAGF,uBACE,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,sBAAA,CAAA,iBAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA","sourcesContent":[".validation-buttons {\n  display: flex;\n  justify-content: space-evenly;\n  margin-top: 20px;\n}\n\n.content {\n  position: initial;\n  margin: 100px auto;\n  padding: 16px;\n  width: fit-content;\n  border-radius: 4px;\n  background: white;\n  text-align: center;\n  overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"validation-buttons": `style__validation-buttons--rZu49`,
	"content": `style__content--j05tS`
};
export default ___CSS_LOADER_EXPORT___;
