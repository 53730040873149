import AgeRanges from './AgeRanges';
import Ceremonies from './Ceremonies';
import EditableDocuments from './EditableDocuments';
import General from './General';
import Misc from './Misc';
import OpeningHours from './OpeningHours';
import Rooms from './Rooms';
import SupportingDocuments from './SupportingDocuments';
import Worship from './Worship';

export default {
  AgeRanges,
  Ceremonies,
  EditableDocuments,
  General,
  Misc,
  OpeningHours,
  Rooms,
  SupportingDocuments,
  Worship,
};
