import { DateTime } from 'luxon'
import { Validator, ValidatorReturnType } from './Validator'

const ERROR = 'hour_is_before'

function withFixedDay(date: DateTime): DateTime {
  return date.set({ year: 2022, month: 10, day: 1 })
}

export function isHourAfter(comparison: DateTime): Validator<string | null> {
  return (value: string | null): ValidatorReturnType => {
    if (!value) {
      return undefined
    }

    const date = DateTime.fromISO(value)

    if (date.isValid && withFixedDay(date) >= withFixedDay(comparison)) {
      return undefined
    }

    return ERROR
  }
}
