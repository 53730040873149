import { ChangeEvent } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { cloneDeep, uniqBy } from 'lodash';

import { useThunkDispatch } from '@advitam/react';
import Cut from '@advitam/ui/images/icons/cut.svg';
import Erase from '@advitam/ui/images/icons/erase.svg';
import RotateLeft from '@advitam/ui/images/icons/rotate-left.svg';
import RotateRight from '@advitam/ui/images/icons/rotate-right.svg';
import Upload from '@advitam/ui/images/icons/upload.svg';

import { assert } from '@advitam/support';
import { getUuid } from 'utils/functions.typed';

import EditAction from '../parts/EditAction';
import {
  makeSelectCurrentResource,
  makeSelectIsLoading,
  makeSelectIsSingleDocumentUpload,
  makeSelectUploadedDocuments,
} from '../selectors';
import { uploadDocuments } from '../thunk';
import {
  DocumentEdition,
  DocumentEditionsForm,
  RotateDirection,
} from '../types';
import { rotateDocuments } from '../utils';
import messages from './messages';
import style from './Header.module.scss';

export default function EditActions(): JSX.Element {
  const dispatch = useThunkDispatch();

  const documents = useSelector(makeSelectUploadedDocuments());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSingleDocumentUpload = useSelector(
    makeSelectIsSingleDocumentUpload(),
  );
  const currentResource = useSelector(makeSelectCurrentResource());
  assert(currentResource !== null);

  const form = useForm<DocumentEditionsForm>();
  const { values } = useFormState<DocumentEditionsForm>();

  const onDocumentsReset = (): void => {
    form.change('documents', documents);
  };

  const onDocumentsUpload = async ({
    currentTarget: { files: fileList },
  }: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = fileList && Array.from(fileList).map(file => file);

    if (files) {
      const response = dispatch(uploadDocuments(files));

      try {
        const sessionDocuments = await response.unwrap();
        const existingDocuments = cloneDeep(values.documents);

        if (!isSingleDocumentUpload) {
          existingDocuments.push(...sessionDocuments);
          form.change('documents', existingDocuments);
          return;
        }

        existingDocuments[0].pages = uniqBy(
          [...existingDocuments[0].pages, ...sessionDocuments[0].pages],
          'uuid',
        );

        form.change('documents', existingDocuments);
      } catch {
        // let the slice handle
      }
    }
  };

  const onPagesRotation = (direction: RotateDirection): void => {
    form.change('documents', rotateDocuments(values.documents, direction));
  };

  const onSplit = (): void => {
    const newDocuments: DocumentEdition[] = values.documents.reduce<
      DocumentEdition[]
    >((prev, curr) => {
      if (curr.pages.length === 1) {
        return [...prev, curr];
      }

      const pagesToDocuments: DocumentEdition[] = curr.pages.map(page => ({
        error: null,
        pages: [page],
        resource: currentResource,
        uuid: getUuid(),
        sessionId: curr.sessionId,
      }));

      return [...prev, ...pagesToDocuments];
    }, []);

    form.change('documents', newDocuments);
  };

  return (
    <div className={style.edit_actions}>
      <label>
        <EditAction Icon={Upload} message={messages.addDocument} />
        <input
          multiple
          type="file"
          className={style.file_input}
          disabled={isLoading}
          onChange={onDocumentsUpload}
        />
      </label>
      {!isSingleDocumentUpload && (
        <EditAction
          Icon={Cut}
          message={messages.cutAllPages}
          onClick={onSplit}
        />
      )}
      <EditAction
        Icon={RotateLeft}
        message={messages.rotatePagesLeft}
        onClick={(): void => onPagesRotation(RotateDirection.LEFT)}
      />
      <EditAction
        Icon={RotateRight}
        message={messages.rotatePagesRight}
        onClick={(): void => onPagesRotation(RotateDirection.RIGHT)}
      />
      <EditAction
        Icon={Erase}
        message={messages.reset}
        onClick={onDocumentsReset}
      />
    </div>
  );
}
