import { Model } from '../Model';
import { Person, PersonFields } from '../Person';

export interface DefunctFields extends PersonFields {
  readonly id: number;
  birth_date: Date | null;
  death_date: Date | null;
  readonly deal_id: number;
  readonly memorial_id?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Defunct extends DefunctFields {}

type DefunctKey = keyof Defunct;
const defaultDefunct = {} as DefunctFields;

export class Defunct extends Person<DefunctFields> {
  static readonly UPDATABLE_FIELDS: DefunctKey[] = [
    'firstname',
    'lastname',
    'civility',
    'birth_name',
    'birth_date',
    'death_date',
  ];

  constructor(data: DefunctFields = defaultDefunct) {
    super(data);
    this.firstname ||= '';
    this.lastname ||= '';
    this.death_date = Model.parseDate(data.death_date);
    this.birth_date = Model.parseDate(data.birth_date);
  }
}
