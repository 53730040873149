import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import type {
  AutocompleteResult,
  AutocompleteSuppliersResult,
} from '@advitam/api/v1/Autocompletes';
import { EntityInvoiceEntityType } from '@advitam/api/models/EntityInvoice/Entity/Type';
import DownloadIcon from '@advitam/ui/images/icons/download.svg';
import PlusIcon from '@advitam/ui/images/icons/plus.svg';
import TimesCircleIcon from '@advitam/ui/images/icons/times-circle.svg';
import {
  Button,
  ConfirmationModal,
  Filter,
  FormattedPrice,
  FormUI,
  ResourceList,
  Text,
  Tooltip,
} from '@advitam/ui';

import { makeSelectFilters, makeSelectPendingPayments } from './selectors';
import messages from './messages';
import style from './Billing.module.scss';
import { Filters, setFilters } from './slice';
import {
  clearPendingPayments,
  downloadBankTransfer,
  fetchEntityInvoices,
} from './thunk';
import { createPrestation } from './List/Edit/slice';
import { useEntityTypeSelectItems } from './utils';
import { ENTITY_ENDPOINTS } from './constants';

export default function Filters(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const onRequestClear = useCallback(() => {
    setIsClearModalOpen(true);
  }, [setIsClearModalOpen]);

  const onCancelClear = useCallback(() => {
    setIsClearModalOpen(false);
  }, [setIsClearModalOpen]);

  const onClear = useCallback(() => {
    setIsClearModalOpen(false);
    dispatch(clearPendingPayments());
  }, [dispatch, setIsClearModalOpen]);

  const onRequestDownload = useCallback(() => {
    setIsDownloadModalOpen(true);
  }, [setIsDownloadModalOpen]);

  const onCancelDownload = useCallback(() => {
    setIsDownloadModalOpen(false);
  }, [setIsDownloadModalOpen]);

  const onDownload = useCallback(() => {
    setIsDownloadModalOpen(false);
    dispatch(downloadBankTransfer());
  }, [dispatch, setIsDownloadModalOpen]);

  const onChange = useCallback(
    (filters: Filters) => {
      dispatch(setFilters(filters));
      dispatch(fetchEntityInvoices(1));
    },
    [dispatch],
  );

  const onAddPrestation = useCallback(() => {
    dispatch(createPrestation());
  }, [dispatch]);

  const pendingPayments = useSelector(makeSelectPendingPayments());
  const filters = useSelector(makeSelectFilters());
  const entityTypeSelectItems = useEntityTypeSelectItems();

  const getEntityDisplayValue = useCallback(
    (result: AutocompleteResult): string => {
      if (filters.entity_type === EntityInvoiceEntityType.SUPPLIER) {
        const supplier = result as AutocompleteSuppliersResult;
        return supplier.short_name || supplier.name;
      }

      return result.description;
    },
    [filters.entity_type],
  );

  const filtersShape: Filter[] = [
    {
      type: 'Select' as const,
      name: 'entity_type',
      placeholder: <FormattedMessage id={messages.type.id} />,
      items: entityTypeSelectItems,
      initialValue: filters.entity_type,
    },
    {
      type: 'Autocomplete' as const,
      name: 'entity',
      placeholder: intl.formatMessage(messages.entity),
      initialValue: filters.entity,
      endpoint: Api.V1.absolute(
        ENTITY_ENDPOINTS[
          filters.entity_type || EntityInvoiceEntityType.CITYHALL
        ](),
      ),
      requestHeaders: { ...getTokens() },
      disabled: !filters.entity_type,
      getDisplayValue: getEntityDisplayValue,
    },
    {
      type: 'Select' as const,
      name: 'status',
      placeholder: <FormattedMessage id={messages.status.id} />,
      items: [
        { value: 'new', name: intl.formatMessage(messages.statusNew) },
        {
          value: 'validated',
          name: intl.formatMessage(messages.statusValidated),
        },
        { value: 'unset', name: intl.formatMessage(messages.statusUnset) },
        {
          value: 'not_billed',
          name: intl.formatMessage(messages.statusNotBilled),
        },
      ],
      initialValue: filters.status,
    },
    {
      type: 'DateRange' as const,
      startName: 'start_date',
      endName: 'end_date',
      initialStartValue: filters.start_date,
      initialEndValue: filters.end_date,
    },
    {
      type: 'Text' as const,
      name: 'q',
      placeholder: intl.formatMessage(messages.search),
      initialValue: filters.q,
    },
  ];

  return (
    <ResourceList.Filters filters={filtersShape} onChange={onChange}>
      {pendingPayments.length !== 0 && (
        <FormUI.Dropdown
          contained={false}
          className={style.pending_payments}
          placeholder={
            <FormattedPrice
              value={pendingPayments
                .map(payment => payment.cost_tax_incl)
                .reduce((a, b) => a + b, 0)}
            />
          }
        >
          {pendingPayments.map(payment => (
            <div className={style.pending_payment_row} key={payment.id}>
              <Text>
                {payment.entity_name}
                {' - '}
                {payment.group_name}
              </Text>
              <Text>
                <FormattedPrice value={payment.cost_tax_incl} />
              </Text>
            </div>
          ))}
        </FormUI.Dropdown>
      )}

      <div className={style.actions}>
        <Tooltip
          content={<FormattedMessage id={messages.clearPendingPayments.id} />}
        >
          <Button
            outline
            fixedSize
            icon={<TimesCircleIcon />}
            onClick={onRequestClear}
            disabled={pendingPayments.length === 0}
          />
        </Tooltip>
        <Tooltip
          content={<FormattedMessage id={messages.downloadPayments.id} />}
        >
          <Button
            outline
            fixedSize
            icon={<DownloadIcon />}
            onClick={onRequestDownload}
            disabled={pendingPayments.length === 0}
          />
        </Tooltip>
        <Tooltip content={<FormattedMessage id={messages.addPrestation.id} />}>
          <Button
            primary
            fixedSize
            icon={<PlusIcon />}
            onClick={onAddPrestation}
            className={style.add_prestation}
          />
        </Tooltip>
      </div>

      <ConfirmationModal
        isOpen={isClearModalOpen}
        title={<FormattedMessage id={messages.clearConfirmationTitle.id} />}
        text={<FormattedMessage id={messages.clearConfirmationText.id} />}
        confirm={<FormattedMessage id={messages.clearConfirmationConfirm.id} />}
        onConfirm={onClear}
        cancel={<FormattedMessage id={messages.clearConfirmationCancel.id} />}
        onCancel={onCancelClear}
      />

      <ConfirmationModal
        isOpen={isDownloadModalOpen}
        title={<FormattedMessage id={messages.downloadConfirmationTitle.id} />}
        text={<FormattedMessage id={messages.downloadConfirmationText.id} />}
        confirm={
          <FormattedMessage id={messages.downloadConfirmationConfirm.id} />
        }
        onConfirm={onDownload}
        cancel={
          <FormattedMessage id={messages.downloadConfirmationCancel.id} />
        }
        onCancel={onCancelDownload}
      />
    </ResourceList.Filters>
  );
}
