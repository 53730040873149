// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Payment-module__payment--N2Bv_{display:flex;flex-direction:column}.Payment-module__payment--N2Bv_>div:first-child{flex:2}.Payment-module__payment--N2Bv_>div:last-child{flex:1}@media(min-width: 576px){.Payment-module__payment--N2Bv_{flex-direction:row}}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Payments/Payment/Payment.module.scss","webpack://./../ui/scss/_media.scss"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,qBAAA,CAEA,gDACE,MAAA,CAGF,+CACE,MAAA,CCGA,yBDZJ,gCAaI,kBAAA,CAAA","sourcesContent":["@import '~@advitam/ui/scss/media';\n\n.payment {\n  display: flex;\n  flex-direction: column;\n\n  > div:first-child {\n    flex: 2;\n  }\n\n  > div:last-child {\n    flex: 1;\n  }\n\n  @include above(sm) {\n    flex-direction: row;\n  }\n}\n","$breakpoints: (\n  xxl: 1440px,\n  xl: 1200px,\n  lg: 992px,\n  md: 768px,\n  sm: 576px,\n  xs: 375px\n);\n\n@mixin above($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n@mixin below($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (max-width: ($breakpoint-value - 1)) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n:export {\n  /* stylelint-disable property-no-unknown */\n  xxl: 1440px;\n  xl: 1200px;\n  lg: 992px;\n  md: 768px;\n  sm: 576px;\n  xs: 375px;\n  /* stylelint-enable property-no-unknown */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"payment": `Payment-module__payment--N2Bv_`
};
export default ___CSS_LOADER_EXPORT___;
