import { updateDeal } from 'containers/Deal/slice.ts';

export function setOtherFuneralInfo(update) {
  return updateDeal(update);
}

export function updateTvaTotalPrice(tvaIndice, value, otherFuneral) {
  const tvaKey = `tva${tvaIndice}`;
  const newOtherFuneral = {
    ...otherFuneral,
  };
  if (!newOtherFuneral.services) newOtherFuneral.services = {};
  if (!newOtherFuneral.services[tvaKey]) newOtherFuneral.services[tvaKey] = {};
  newOtherFuneral.services[tvaKey] = {
    ...newOtherFuneral.services[tvaKey],
    price: value === '' || value === 0 ? null : parseFloat(value, 10),
  };

  return updateDeal({
    otherFuneral: {
      ...newOtherFuneral,
    },
  });
}

export function addOtherFuneralTvaService(
  serviceType,
  serviceDescription,
  tvaIndice,
  otherFuneral,
) {
  const tvaKey = `tva${tvaIndice}`;
  const newOtherFuneral = {
    ...otherFuneral,
  };
  if (!newOtherFuneral.services) newOtherFuneral.services = {};
  if (!newOtherFuneral.services[tvaKey]) newOtherFuneral.services[tvaKey] = {};
  if (!newOtherFuneral.services[tvaKey].list)
    newOtherFuneral.services[tvaKey].list = [];
  newOtherFuneral.services[tvaKey].list = [
    ...newOtherFuneral.services[tvaKey].list,
    {
      type: serviceType,
      priceLine: {
        description: serviceDescription.replace(/%d/, ''),
      },
    },
  ];

  return updateDeal({
    otherFuneral: {
      ...newOtherFuneral,
    },
  });
}

export function deleteOtherFuneralTvaService(
  serviceType,
  serviceDescription,
  tvaIndice,
  index,
  otherFuneral,
) {
  const newList = [...otherFuneral.services[`tva${tvaIndice}`].list];
  newList.splice(index, 1);
  return updateDeal({
    otherFuneral: {
      ...otherFuneral,
      services: {
        ...otherFuneral.services,
        [`tva${tvaIndice}`]: {
          ...otherFuneral.services[`tva${tvaIndice}`],
          list: newList,
        },
      },
    },
  });
}
