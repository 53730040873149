import { FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import {
  DealWithInvoiceAutocomplete,
  FuneralWorkTypeAutocomplete,
} from '@advitam/integration';
import { FormLayout, ModalTitle, NumberInput } from '@advitam/ui';

import messages from './messages';
import GroupInputs from './parts/GroupInputs';
import Buttons from './parts/Buttons';
import Price from './parts/Price';
import { PrestationForm } from './types';

type CreatePrestationFormValues = PrestationForm & {
  'prestation.deal_id': PrestationForm['prestation']['deal']['id'];
  'prestation.description': PrestationForm['prestation']['description'];
};

export default function CreatePrestationForm(): JSX.Element {
  return (
    <>
      <ModalTitle>
        <FormattedMessage id={messages.createPrestation.id} />
      </ModalTitle>
      <GroupInputs />
      <FormLayout.Row>
        <FuneralWorkTypeAutocomplete
          required
          label={<FormattedMessage id={messages.prestation.id} />}
          name="prestationType"
          allowNewValue
        />
        <DealWithInvoiceAutocomplete
          required
          label={<FormattedMessage id={messages.defunctOrClient.id} />}
          name="deal"
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          required
          label={<FormattedMessage id={messages.quantity.id} />}
          name="prestation.amount"
        />
        <div />
      </FormLayout.Row>
      <Price />

      <FormSpy<CreatePrestationFormValues> subscription={{ values: true }}>
        {({ values, form }): null => {
          if (
            values.prestation.description !== values.prestationType?.description
          ) {
            form.change(
              'prestation.description',
              values.prestationType?.description,
            );
          }
          return null;
        }}
      </FormSpy>

      <Buttons submitText={<FormattedMessage id={messages.add.id} />} />
    </>
  );
}
