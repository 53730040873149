import { authenticate } from '../lib/decorators'
import { get } from '../lib/methods'
import { ApiRequestDescriptor } from '../lib/request'
import { ConcessionTypeJSON } from '../models/Concession'

export class ConcessionTypes {
  @authenticate
  static index(): ApiRequestDescriptor<ConcessionTypeJSON[]> {
    return get('/api/v1/concession_types')
  }
}
