import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { AutocompleteResult } from '@advitam/api/v1/Autocompletes';

import { setActiveSupplier } from '../../slice';
import { resetActiveKeys } from '../Prestations/slice';
import { MAP_SUPPLIERS } from './constants';
import {
  makeSelectActiveSuppliers,
  makeSelectStoredCoverageIds,
} from './selectors';
import { addActiveSupplier, AppStateSubset } from './slice';

export const fetchSupplierCoverage = createAsyncThunk(
  `${MAP_SUPPLIERS}_FETCH_SUPPLIER_COVERAGE`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body: suppliersCoverage } = await request(
        Api.V1.Suppliers.Coverage.index([id]),
      );

      if (!suppliersCoverage[0]) {
        return null;
      }

      return suppliersCoverage[0];
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchCoverage = createAsyncThunk(
  `${MAP_SUPPLIERS}_FETCH_COVERAGE`,
  async (
    result: AutocompleteResult,
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const activeSuppliers = makeSelectActiveSuppliers()(state);
    const storedCoveragesIds = makeSelectStoredCoverageIds()(state);

    const isAlreadyActive = activeSuppliers.some(
      supplier => supplier.id === result.id,
    );

    dispatch(resetActiveKeys());
    dispatch(setActiveSupplier(null));

    if (isAlreadyActive) {
      return undefined;
    }

    if (storedCoveragesIds.includes(result.id)) {
      dispatch(addActiveSupplier(result));
      return undefined;
    }

    try {
      await dispatch(fetchSupplierCoverage(result.id));
      dispatch(addActiveSupplier(result));
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
