import { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { set } from 'lodash';

import { assert, isNil } from '@advitam/support';
import Modal from 'components/Modal';
import { LayerType } from 'models/Deal/Layer';

import { useDeepCompareEffect } from 'lib/reactvitam';
import { setDealFields } from '../../slice';
import { makeSelectDeal } from '../../selectors.typed';
import { makeSelectAbilities } from '../../Sections/Identity/selectors';
import LayerUpdate from './LayerUpdate';
import messages from './messages';
import { LAYERS_MAPPING } from './constants';
import { makeSelectFormattedLayers, makeSelectIsModalOpen } from './selectors';
import { setIsOpen } from './slice';
import { cleanLayers } from './thunk';
import type { LayerUpdates, UpdateLayer } from './types';
import style from './style.scss';

const DEFAULT_POSITION = 0;

function LayersModal(): JSX.Element {
  const dispatch = useDispatch();
  const form = useForm();
  const intl = useIntl();
  const [layerUpdates, setLayersUpdate] = useState<LayerUpdates>({});

  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);
  const isOpen = useSelector(makeSelectIsModalOpen());
  const formattedLayers = useSelector(makeSelectFormattedLayers());
  const abilities = useSelector(makeSelectAbilities());
  const empoweredClientIndex = abilities.findIndex(ability => ability.special);

  useDeepCompareEffect(() => {
    const defaultLayersUpdateValues: LayerUpdates = {};
    Object.keys(formattedLayers).forEach(layerAction => {
      const layer = formattedLayers[layerAction as LayerType];
      Object.keys(layer).forEach(layerKey => {
        const { id } = layer[layerKey];
        defaultLayersUpdateValues[id] = DEFAULT_POSITION;
      });
    });
    setLayersUpdate(defaultLayersUpdateValues);
  }, [formattedLayers]);

  const updateDealWithLayers = (): void => {
    const selectedIds = Object.keys(layerUpdates)
      .filter(key => layerUpdates[key] !== null)
      .map(id => parseInt(id, 10));
    const selectedLayers: UpdateLayer[] = [];
    Object.keys(formattedLayers).forEach(layerAction => {
      const layer = formattedLayers[layerAction as LayerType];
      Object.keys(layer).forEach(layerKey => {
        const { id, values } = layer[layerKey];
        if (selectedIds.includes(id)) {
          selectedLayers.push({
            action: layerAction as LayerType,
            key: layerKey,
            value: values[layerUpdates[id] as number],
          });
        }
      });
    });
    let shouldUpdateDeal = false;
    const dealUpdates = {};
    selectedLayers.forEach(layer => {
      const mapping = LAYERS_MAPPING[layer.action as LayerType];
      const target = mapping[layer.key];
      if (layer.action === LayerType.EMPOWERED_CLIENT) {
        form.change(
          `abilities[${empoweredClientIndex}].${target}`,
          layer.value,
        );
      } else {
        shouldUpdateDeal = true;
        set(dealUpdates, target, layer.value);
      }
    });
    if (shouldUpdateDeal) {
      dispatch(setDealFields({ update: dealUpdates }));
    }

    dispatch(cleanLayers());
  };

  const onChangePosition = (id: number, position: number | null): void => {
    setLayersUpdate({ ...layerUpdates, [id]: position });
  };

  const onSetIsOpen = useCallback(
    (value: boolean) => {
      dispatch(setIsOpen(value));
    },
    [dispatch],
  );

  return (
    <Modal
      title={intl.formatMessage(messages.familyUpdates)}
      isOpen={isOpen}
      setIsOpen={onSetIsOpen}
    >
      <>
        <div className={style.layers}>
          {Object.keys(formattedLayers).map(layerAction => {
            const layer = formattedLayers[layerAction as LayerType];
            return Object.keys(layer).map(layerKey => {
              const { values, id } = layer[layerKey];
              if (values.length === 0) {
                return null;
              }
              return (
                <LayerUpdate
                  key={`${layerAction}.${layerKey}`}
                  layer={{
                    id,
                    values,
                    action: layerAction as LayerType,
                    key: layerKey,
                  }}
                  onChangePosition={onChangePosition}
                  empoweredClient={abilities[empoweredClientIndex]}
                  position={layerUpdates[id]}
                  isToggled={!isNil(layerUpdates[id])}
                  deal={deal}
                />
              );
            });
          })}
        </div>
        <div className={style.button__row}>
          <button
            type="button"
            className={style['btn--terminate']}
            onClick={updateDealWithLayers}
          >
            <FormattedMessage id={messages.terminate.id} />
          </button>
        </div>
      </>
    </Modal>
  );
}

export default LayersModal;
