import { FormattedMessage } from 'react-intl';

import { DealCommunication } from '@advitam/api/models/Deal/Communication';
import { CommunicationState } from '@advitam/api/models/Deal/Communication/State';
import { CommunicationChannelType } from '@advitam/api/models/Deal/Communication/ChannelType';
import { Tooltip } from '@advitam/ui';
import Email from '@advitam/ui/images/icons/envelope.svg';
import Fax from '@advitam/ui/images/icons/fax.svg';
import Letter from '@advitam/ui/images/icons/letter.svg';
import Sms from '@advitam/ui/images/icons/sms.svg';
import Phone from '@advitam/ui/images/icons/phone.svg';

import messages from '../messages';
import style from './Row.module.scss';

interface MeanProps {
  communication: DealCommunication;
}

export default function Mean({ communication }: MeanProps): JSX.Element | null {
  const classes = [
    style.mean,
    communication.state === CommunicationState.FAILED && style.error,
  ].filter(Boolean);

  switch (communication.channel_type) {
    case CommunicationChannelType.EMAIL:
      return (
        <Tooltip content={<FormattedMessage id={messages.email.id} />}>
          <Email className={classes.join(' ')} />
        </Tooltip>
      );
    case CommunicationChannelType.FAX:
      return (
        <Tooltip content={<FormattedMessage id={messages.fax.id} />}>
          <Fax className={classes.concat([style.fax]).join(' ')} />
        </Tooltip>
      );
    case CommunicationChannelType.LETTER:
      return (
        <Tooltip content={<FormattedMessage id={messages.letter.id} />}>
          <Letter className={classes.join(' ')} />
        </Tooltip>
      );
    case CommunicationChannelType.SMS:
      return (
        <Tooltip content={<FormattedMessage id={messages.sms.id} />}>
          <Sms className={classes.concat([style.sms]).join(' ')} />
        </Tooltip>
      );
    case CommunicationChannelType.PHONE:
      return (
        <Tooltip content={<FormattedMessage id={messages.phone.id} />}>
          <Phone className={classes.concat([style.phone]).join(' ')} />
        </Tooltip>
      );
    default:
      return null;
  }
}
