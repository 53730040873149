import { Objects } from '@advitam/support'

import type { WorshipJSON } from '../../models/Worship'
import type { WorshipBody } from './types'

type WorshipPayload = Partial<
  Omit<WorshipJSON, 'id' | 'country' | 'worship_type'> & {
    country_code: string | null
    headquarter_country_code: string | null
    type: number | null
  }
>

export function serialize(worship: Omit<WorshipBody, 'id'>): WorshipPayload {
  return {
    ...Objects.omit(worship, 'country', 'headquarter_country', 'worship_type'),
    country_code: worship.country === undefined ? undefined : worship.country?.code || null,
    headquarter_country_code:
      worship.headquarter_country === undefined
        ? undefined
        : worship.headquarter_country?.code || null,
    type: worship.worship_type === undefined ? undefined : worship.worship_type?.id || null,
  }
}
