import { defineMessages } from 'react-intl';

export default defineMessages({
  advitam: { id: 'app.containers.DocumentTemplateEditor.sections.advitam' },
  casketing: { id: 'app.containers.DocumentTemplateEditor.sections.casketing' },
  ceremony: {
    id: 'app.containers.DocumentTemplateEditor.sections.ceremony',
  },
  client: { id: 'app.containers.DocumentTemplateEditor.sections.client' },
  closing: { id: 'app.containers.DocumentTemplateEditor.sections.closing' },
  coffin: { id: 'app.containers.DocumentTemplateEditor.sections.coffin' },
  concession: {
    id: 'app.containers.DocumentTemplateEditor.sections.concession',
  },
  concession_buyer: {
    id: 'app.containers.DocumentTemplateEditor.sections.concession_buyer',
  },
  cremation: { id: 'app.containers.DocumentTemplateEditor.sections.cremation' },
  death: { id: 'app.containers.DocumentTemplateEditor.sections.death' },
  defunct: { id: 'app.containers.DocumentTemplateEditor.sections.defunct' },
  defunct_father: {
    id: 'app.containers.DocumentTemplateEditor.sections.defunct_father',
  },
  defunct_mother: {
    id: 'app.containers.DocumentTemplateEditor.sections.defunct_mother',
  },
  defunct_partner: {
    id: 'app.containers.DocumentTemplateEditor.sections.defunct_partner',
  },
  dispersion: {
    id: 'app.containers.DocumentTemplateEditor.sections.dispersion',
  },
  engraving: {
    id: 'app.containers.DocumentTemplateEditor.sections.engraving',
  },
  funeral_parlor: {
    id: 'app.containers.DocumentTemplateEditor.sections.funeral_parlor',
  },
  interment: { id: 'app.containers.DocumentTemplateEditor.sections.interment' },
  power: { id: 'app.containers.DocumentTemplateEditor.sections.power' },
  repatriation: {
    id: 'app.containers.DocumentTemplateEditor.sections.repatriation',
  },
  urn: { id: 'app.containers.DocumentTemplateEditor.sections.urn' },
  urn_pickup: {
    id: 'app.containers.DocumentTemplateEditor.sections.urn_pickup',
  },
  urn_sealing: {
    id: 'app.containers.DocumentTemplateEditor.sections.urn_sealing',
  },
  urn_transfer: {
    id: 'app.containers.DocumentTemplateEditor.sections.urn_transfer',
  },
  various: { id: 'app.containers.DocumentTemplateEditor.sections.various' },
});
