export const SIGNATURE_ENDED_MESSAGE_TYPE = 'yousign.signature.ended'

export const enum SignatureStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  REFUSED = 'refused',
  FINISHED = 'finished',
}

export interface SignatureEndedMessage {
  type: typeof SIGNATURE_ENDED_MESSAGE_TYPE
  status: SignatureStatus
}

// Typing trick to enforce the check of the type
export interface AnyMessage {
  type: ''
}

export type Message = AnyMessage | SignatureEndedMessage
