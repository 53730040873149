import { Objects } from '@advitam/support'
import { authenticate, withSessionId } from '../../../lib/decorators'
import { del, get, post, put } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { SupplierWarehouseProductJSON } from '../../../models/Supplier/Warehouse/Product'
import { serializeProduct } from './serializers'
import { WarehouseProductBody } from './types'

export interface ProductsIndexFilters {
  q?: string
  page?: number
  per_page?: number
}

export const Products = {
  create: authenticate(
    withSessionId(
      (
        warehouseId: number,
        product: Omit<WarehouseProductBody, 'id'>,
      ): ApiRequestDescriptor<SupplierWarehouseProductJSON> =>
        post(`/api/v1/suppliers/warehouses/${warehouseId}/products`, serializeProduct(product)),
    ),
  ),

  index: authenticate(
    (
      warehouseId: number,
      filters?: ProductsIndexFilters,
    ): ApiRequestDescriptor<SupplierWarehouseProductJSON[]> =>
      get(`/api/v1/suppliers/warehouses/${warehouseId}/products`, filters),
  ),

  update: authenticate(
    withSessionId(
      (product: WarehouseProductBody): ApiRequestDescriptor<SupplierWarehouseProductJSON> =>
        put(
          `/api/v1/suppliers/warehouses/products/${product.id}`,
          Objects.omit(product, 'product'),
        ),
    ),
  ),

  destroy: authenticate(
    withSessionId(
      (id: number): ApiRequestDescriptor<void> =>
        del(`/api/v1/suppliers/warehouses/products/${id}`),
    ),
  ),
}
