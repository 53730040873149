import { createElement, DragEventHandler, HTMLAttributes, ReactNode } from 'react'

import style from './Text.module.scss'

interface TextProps {
  large?: boolean
  small?: boolean
  extraSmall?: boolean
  tiny?: boolean
  tagName?: 'span' | 'div' | 'p'
  children: ReactNode
  className?: string
  draggable?: boolean
  onDragStart?: DragEventHandler
  onDragEnd?: DragEventHandler
  property?: HTMLAttributes<unknown>['property']
}

export default function Text({
  large = false,
  small = false,
  extraSmall = false,
  tiny = false,
  tagName = 'span',
  children,
  className,
  draggable,
  onDragStart,
  onDragEnd,
  property,
}: TextProps): JSX.Element {
  const classes = [style.text]
  if (large) {
    classes.push(style.large)
  } else if (small) {
    classes.push(style.small)
  } else if (extraSmall) {
    classes.push(style.extra_small)
  } else if (tiny) {
    classes.push(style.tiny)
  } else {
    classes.push(style.medium)
  }
  if (className) {
    classes.push(className)
  }
  return createElement(
    tagName,
    {
      className: classes.join(' '),
      property,
      draggable,
      onDragStart,
      onDragEnd,
    },
    children,
  )
}
