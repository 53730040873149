import { useIntl } from 'react-intl';

import {
  FundingAgencyJSON,
  FundingAgencyType,
} from '@advitam/api/models/FundingAgency';
import { ContactMedia } from '@advitam/api/models/Entity/ContactMedia';
import { CroppedText, ResourceList } from '@advitam/ui';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from './messages';
import style from './FundingAgencies.modules.scss';

interface RowProps {
  agency: FundingAgencyJSON;
}

const contactMediaText: Record<ContactMedia, keyof typeof messages> = {
  [ContactMedia.EMAIL]: 'contactEmail',
  [ContactMedia.FAX]: 'contactFax',
  [ContactMedia.LETTER]: 'contactLetter',
  [ContactMedia.SMS]: 'contactSms',
  [ContactMedia.TEL]: 'contactTel',
};

const agencyTypeText: Record<FundingAgencyType, keyof typeof messages> = {
  [FundingAgencyType.BANK]: 'bank',
  [FundingAgencyType.HEALTH_INSURANCE]: 'healthInsurance',
  [FundingAgencyType.LIFE_INSURANCE]: 'lifeInsurance',
};

export default function Row({ agency }: RowProps): JSX.Element {
  const intl = useIntl();

  return (
    <ResourceList.Row className={style.row}>
      <ResourceList.Cell className={style.logo}>
        {agency.thumbnail_link && <img src={agency.thumbnail_link} alt="" />}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.name}>
        <CroppedText>{agency.name}</CroppedText>
      </ResourceList.Cell>
      <ResourceList.Cell>
        <a href={`tel:${agency.phone}`}>{agency.phone}</a>
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.email}>
        <a href={`mailto:${agency.email}`}>
          <CroppedText>{agency.email}</CroppedText>
        </a>
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        {safeFormatMessage(
          intl,
          messages,
          contactMediaText[agency.prefered_contact_media],
        )}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.type}>
        {safeFormatMessage(intl, messages, agencyTypeText[agency.agency_type])}
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
