import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { ClientAbilityIndexJSON } from '@advitam/api/models/Client/Ability/IndexAbility';

import { CLIENT_RESOURCES } from './constants';
import { fetchResources } from './thunk';
import type { Filters } from './types';

export interface State {
  filters: Filters;
  resources: ClientAbilityIndexJSON[];
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [CLIENT_RESOURCES]: State;
}

const initialState: State = {
  filters: {},
  resources: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: CLIENT_RESOURCES,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters: (state, { payload }: PayloadAction<Filters>) => {
      state.filters = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchResources.pending, state => {
      state.resources = [];
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchResources.fulfilled, (state, { payload }) => {
      state.resources = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchResources.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
      state.isLoading = false;
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters } = slice.actions;
export default slice;
