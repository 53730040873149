import { ReactNode } from 'react'
import Arrow from '../../images/icons/arrow-right.svg'
import Link from '../Link'
import Text from '../Text'
import style from './List.module.scss'

interface ListTileProps {
  content: ReactNode
  href: string
  newTab?: boolean
  internal?: boolean
  className?: string
  onClick?: () => void
}

export default function ListTile({
  content,
  href,
  newTab,
  internal,
  className = '',
  onClick,
}: ListTileProps): JSX.Element {
  return (
    <Link
      href={href}
      internal={internal}
      newTab={newTab}
      onClick={onClick}
      className={`${style.tile} ${className}`}
    >
      <Text small className={style.content}>
        {content}
      </Text>
      <div className={style.arrow_container}>
        <Arrow className={style.arrow} />
      </div>
    </Link>
  )
}
