import { ApiRequestDescriptor } from '../lib/request'
import { get } from '../lib/methods'
import { ProductJSON as FunnelProductJSON } from '../models/Funnel/Funeral/Product'
import { ProductJSON } from '../models/Product'
import { authenticate } from '../lib/decorators'

export class Products {
  static index(params: Record<string, string>): ApiRequestDescriptor<FunnelProductJSON[]> {
    return get(`/api/v1/products`, params)
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<ProductJSON> {
    return get(`/api/v1/products/${id}`)
  }
}
