import { ReactNode } from 'react'

import Heading from '../Heading'
import Arrow from '../../images/icons/arrow-right.svg'
import style from './GuideCard.module.scss'

interface GuideCardProps {
  img: ReactNode
  title: ReactNode
  link: string
}

export default function GuideCard({ img, title, link }: GuideCardProps): JSX.Element {
  return (
    <a className={style.card} href={link}>
      <span className={style.img}>{img}</span>
      <span className={style.text}>
        <Heading small tagName="span">
          <b>{title}</b>
        </Heading>
        <Arrow />
      </span>
    </a>
  )
}
