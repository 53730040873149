import moment from 'moment';

export function formattedDateFromTimeStamp(timeStampMs) {
  if (timeStampMs === undefined || timeStampMs === null) {
    return {
      date: null,
      errorDateTime: false,
      time: null,
    };
  }
  const utc = moment.unix(timeStampMs).utc();

  let time;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(utc)) {
    time = null;
  } else if (utc.format('HH:mm') === '00:00') {
    time = null;
  } else {
    time = utc.format('HH:mm');
  }

  return {
    // eslint-disable-next-line no-restricted-globals
    date: isNaN(utc) ? null : utc.format('DD/MM/YYYY'),
    time,
    errorDateTime: false,
  };
}

export function destructFormattedDate(formattedDate) {
  let date = [];
  if (formattedDate !== null) {
    date = formattedDate.replace(/_/g, '').split('/');
  }
  const year = !date[2] || date[2].length < 4 ? null : date[2];
  return {
    day: date[0] ? date[0] : null,
    month: date[1] ? date[1] - 1 : null, // We do this because moment index month like this january => 0
    year: year || null,
  };
}

export function destructFormattedTime(formattedTime) {
  let time = [];
  if (formattedTime !== null) {
    time = formattedTime.replace(/_/g, '').split(':');
  }
  // Check if time is 0
  const hour = time[0] === 0 ? 0 : null;
  const minute = time[1] === 0 ? 0 : null;
  return {
    hour: time[0] ? time[0] : hour,
    minute: time[1] ? time[1] : minute,
  };
}
