import Api, { Pagination, request } from '@advitam/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { assert } from 'lib/Assert';

import { ENTITIES } from './constants';
import { makeSelectFilters, makeSelectPage } from './selectors';
import { AppStateSubset } from './slice';

export const fetchEntities = createAsyncThunk(
  `${ENTITIES}/FETCH_ENTITIES`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const filters = makeSelectFilters()(state);
    const page = makeSelectPage()(state);

    try {
      const response = await request(
        Api.V1.Entities.index({
          q: filters.q,
          entity_type: filters.entity_type,
          department: filters.department,
          insee_code: filters.city?.insee_code,
          page,
        }),
      );
      assert(response.body !== null);
      return {
        entities: response.body,
        hasMore: Pagination.getPageCount(response) > page,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
