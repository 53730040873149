import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get, put } from 'api/methods';

import { DealDocumentVersionJSON } from 'models/Deal/Document/Version';
import { DealDocumentJSON } from 'models/Deal/Document';
import { Dictionaries } from './Dictionaries';
import { Generation } from './Generation';

export default class Documents {
  static readonly Dictionaries = Dictionaries;

  static readonly Generation = Generation;

  @authenticate
  static getVersions(
    dealDocumentID: number,
  ): ApiRequestDescriptor<DealDocumentVersionJSON[]> {
    return get(`/api/v1/deal_documents/${dealDocumentID}/versions`);
  }

  @authenticate
  static lockVersion(
    dealDocumentID: number,
    uploaded_document_id: number | null,
  ): ApiRequestDescriptor<DealDocumentVersionJSON[]> {
    return put(`/api/v1/deal_documents/${dealDocumentID}/versions`, {
      uploaded_document_id,
    });
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<DealDocumentJSON> {
    return get(`/api/v1/deals/documents/${id}`);
  }
}
