import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FLIGHTS_CREATE_MODAL } from './constants';

export interface State {
  isOpen: boolean;
}

export interface AppStateSubset {
  [FLIGHTS_CREATE_MODAL]: State;
}

export const initialState: State = {
  isOpen: false,
};

const slice = createSlice({
  name: FLIGHTS_CREATE_MODAL,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const { setIsOpen } = slice.actions;
export default slice;
