import { RepatriationType } from '@advitam/api/models/Repatriation/Type';
import { SupplierWarehousesFlightsAutocompleteResult as FlightAutocompleteResult } from 'api/v1/Autocompletes';
import { PersonAddress } from 'models/Person/Legacy';
import { PlaceType } from 'models/Place';

import { TRIP_SKELETON } from '../constants';
import { FlightWeights, RepatriationStep } from '../types';
import Car from './Car';
import Plane from './Plane';

interface DestinationProps {
  step: RepatriationStep;
  weights: FlightWeights;
  onChange: (value: Partial<RepatriationStep>) => void;
}

export default function Destination({
  step,
  weights,
  onChange,
}: DestinationProps): JSX.Element {
  const { repatriation_type: type, destination, trip, location } = step;

  const onCarChange = (value: PersonAddress): void => {
    onChange({
      location: {
        address: value,
        name: value.address || '',
        sameAsPreviousStep: false,
        type: PlaceType.PUBLIC,
      },
    });
  };

  const onPlaneChange = (value: FlightAutocompleteResult): void => {
    onChange({
      location: null,
      destination: {
        countryCode: value.arrival_country_code,
        country: value.arrival_country,
        city: value.arrival_city,
      },
      trip: {
        ...(trip || TRIP_SKELETON),
        flight_id: value.id,
      },
    });
  };

  if (type === RepatriationType.CAR) {
    return <Car location={location} onChange={onCarChange} />;
  }

  return (
    <Plane
      destination={destination}
      weights={weights}
      onChange={onPlaneChange}
    />
  );
}
