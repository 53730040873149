import Image from '../../Image'
import Tag from '../../Tag'

import style from './ProductCard.module.scss'

const MAX_DISPLAYED_VIGNETTE = 4

interface VignettesProps {
  vignetteUrls: string[]
}

export default function Vignettes({ vignetteUrls }: VignettesProps): JSX.Element {
  const itemsToRemove = vignetteUrls.length - MAX_DISPLAYED_VIGNETTE
  const additionalVignettesCount = vignetteUrls.splice(MAX_DISPLAYED_VIGNETTE, itemsToRemove).length

  return (
    <div className={style.vignettes}>
      {vignetteUrls.map((imageLink, idx) => (
        <Image key={idx} src={imageLink} className={style.vignettes__image} />
      ))}
      {additionalVignettesCount > 0 && <Tag>+ {additionalVignettesCount}</Tag>}
    </div>
  )
}
