import { useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useFormState, useForm, Field } from 'react-final-form';

import type { WorshipJSON } from '@advitam/api/models/Crematorium/Worship';
import { Button } from '@advitam/ui';
import { assert } from 'lib/support';
import Layout from 'containers/Crud/Layout';
import { makeSelectWorshipTypesNeedingLocation } from 'slices/data';

import { addCollectionItem } from 'containers/Crud';
import { makeSelectRawCrematorium } from '../../selectors';
import messages from './messages';
import { CrematoriumForm } from '../types';
import { worshipSkeleton } from './constants';
import WorshipBox from './WorshipBox';
import style from './Worship.module.scss';

const FIELD_NAME = 'crematorium.worships';

export default function WorshipList(): JSX.Element {
  const crematorium = useSelector(makeSelectRawCrematorium());
  const worshipTypes = useSelector(makeSelectWorshipTypesNeedingLocation());
  assert(crematorium !== null);

  const form = useForm<CrematoriumForm>();
  const { values } = useFormState<CrematoriumForm>();
  const { worships } = values.crematorium;

  const gridContainer = useRef<HTMLDivElement>(null);

  const deleteWorship = useCallback(
    (worshipIndex: number): void => {
      const newWorships = worships.filter((_, index) => index !== worshipIndex);
      form.change(FIELD_NAME, newWorships);
    },
    [form, worships],
  );

  const updateWorship = useCallback(
    (worshipIndex: number, newWorship: WorshipJSON): void => {
      const newWorships = [...worships];
      newWorships[worshipIndex] = newWorship;
      form.change(FIELD_NAME, newWorships);
    },
    [form, worships],
  );

  const createWorship = useCallback((): void => {
    const worshipType = worshipTypes[0];
    if (worshipType) {
      assert(gridContainer.current !== null);
      addCollectionItem(
        form,
        FIELD_NAME,
        worshipSkeleton(worshipType),
        gridContainer.current,
      );
    }
  }, [form, worshipTypes, gridContainer]);

  return (
    <Layout.Container>
      <Layout.ColumnGroup>
        <Button
          primary
          onClick={(): void => createWorship()}
          text={<FormattedMessage id={messages.new.id} />}
          className={style.add}
        />
      </Layout.ColumnGroup>
      <Layout.FieldsetGrid ref={gridContainer}>
        <Field name={FIELD_NAME}>
          {(): JSX.Element => (
            <>
              {worships.map((worship, index) => (
                <WorshipBox
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  worshipIndex={index}
                  worship={worship}
                  deleteWorship={deleteWorship}
                  updateWorship={updateWorship}
                />
              ))}
            </>
          )}
        </Field>
      </Layout.FieldsetGrid>
    </Layout.Container>
  );
}
