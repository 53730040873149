import { assert } from 'lib/Assert';
import type { PDFPage } from 'pdf-lib';

import type { Input } from '../../models';
import type { BuildPDFContext } from './types';
import { getInputValue, getScale, getY } from './utils';

export default function renderText(
  input: Input,
  page: PDFPage,
  context: BuildPDFContext,
): Promise<void> {
  const text = getInputValue(input, context.dictionary);
  if (!text) {
    return Promise.resolve();
  }
  assert(typeof text === 'string', 'buildPDF: expecxted text to be a string');

  const scale = getScale(page);
  const fontSize = (input.fontSize || context.data.defaultFontSize) * scale;
  const textSize = context.font.heightAtSize(fontSize);
  const height = (input.size?.[1] || 0) * scale;
  const paddingY = (height - textSize) / 2;
  const offset = fontSize / 4; // Where does it come from ?
  const y = input.position[1] * scale + paddingY - offset;

  page.drawText(text, {
    font: context.font,
    size: fontSize,
    x: input.position[0] * scale,
    y: getY(page, y + textSize),
  });

  return Promise.resolve();
}
