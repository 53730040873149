import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { FullClientJSON } from '@advitam/api/models/Client/Full';

import { DEAL_IDENTITY_CLIENT_AUTOCOMPLETION_MODAL } from './constants';
import type { AppStateSubset, State } from './slice';

type ClientAutocompletionModalSelector<T> = Selector<AppStateSubset, T>;

const selectClientAutocompletionDomain = (state: AppStateSubset): State =>
  state[DEAL_IDENTITY_CLIENT_AUTOCOMPLETION_MODAL];

export const makeSelectAbilityIndex = (): ClientAutocompletionModalSelector<
  number | null
> =>
  createSelector(
    selectClientAutocompletionDomain,
    substate => substate.abilityIndex,
  );

export const makeSelectPendingClient = (): ClientAutocompletionModalSelector<FullClientJSON | null> =>
  createSelector(
    selectClientAutocompletionDomain,
    substate => substate.pendingClient,
  );

export const makeSelectIsLoading = (): ClientAutocompletionModalSelector<
  boolean
> =>
  createSelector(
    selectClientAutocompletionDomain,
    substate => substate.isLoading,
  );

export const makeSelectError = (): ClientAutocompletionModalSelector<SerializedApiError | null> =>
  createSelector(selectClientAutocompletionDomain, substate => substate.error);
