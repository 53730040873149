import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';

import { assert } from 'lib/support';

import { TODO_LIST_DELETION_MODAL } from './constants';
import { makeSelectDeletingItem } from './selectors';
import { AppStateSubset } from './slice';

export const deleteItem = createAsyncThunk(
  `${TODO_LIST_DELETION_MODAL}/DELETE_ITEM`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deletingItem = makeSelectDeletingItem()(state);
    assert(deletingItem !== null);

    try {
      await request(Api.V1.Deals.TodoItems.destroy(deletingItem.id));
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
