import { Addressable, Country } from './types'

function getCountryName(country: Country | string | null | undefined): string | null {
  if (!country) return null
  if (typeof country === 'string') return country
  return country.name
}

export function formatAddress(address: Addressable): string {
  return [
    address.address,
    `${address.postal_code || ''} ${address.city || ''}`.trim(),
    getCountryName(address.country),
  ]
    .filter(Boolean)
    .join(', ')
}

interface FormattedAddressProps {
  address: Addressable
}

export function FormattedAddress({ address }: FormattedAddressProps): JSX.Element {
  return <>{formatAddress(address)}</>
}
