import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  delete: {
    id: 'app.containers.DocumentsEditor.DocumentList.Page.delete',
  },
  mergeDocuments: {
    id: 'app.containers.DocumentsEditor.DocumentList.Page.mergeDocuments',
  },
  placeholder: {
    id:
      'app.containers.DocumentsEditor.DocumentList.ResourceFields.placeholder',
  },
  splitPages: {
    id: 'app.containers.DocumentsEditor.DocumentList.Page.splitPages',
  },
  recover: {
    id: 'app.containers.DocumentsEditor.DocumentList.Page.recover',
  },
  rotateLeft: {
    id: 'app.containers.DocumentsEditor.DocumentList.Page.rotateLeft',
  },
  rotateRight: {
    id: 'app.containers.DocumentsEditor.DocumentList.Page.rotateRight',
  },
});
