import { ConfigureStoreOptions } from '@reduxjs/toolkit';
import { Middleware, Reducer } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import handleThunkErrorMiddleware from './handleThunkErrorMiddleware';

import { configureReduxStore, Store } from './Store';

export default function configureStore(
  reducer: Reducer,
  preloadedState = {},
  middlewares: Middleware[] = [],
): Store {
  const saga = createSagaMiddleware({});
  const additionalMiddlewares: Middleware[] = middlewares.concat(
    saga,
    handleThunkErrorMiddleware,
  );

  const devTools =
    !process.env.NODE_ENV ||
    !['production', 'test'].includes(process.env.NODE_ENV);

  if (devTools) {
    additionalMiddlewares.push(
      createLogger({
        collapsed: true,
      }),
    );
  }
  const middleware: ConfigureStoreOptions['middleware'] = getDefaultMiddlewares =>
    // TODO: Use default values
    getDefaultMiddlewares({
      // Dangerous to arbitrarely enable
      immutableCheck: false,
      // Deal and auth stores are not
      serializableCheck: false,
    }).concat(additionalMiddlewares);

  const store = configureReduxStore({
    reducer,
    middleware,
    devTools,
    preloadedState,
  });

  // Extensions
  store.runSaga = saga.run.bind(saga);
  store.injectedReducers = {};
  store.injectedSagas = {};

  return store;
}
