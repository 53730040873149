import { authenticate } from '../../lib/decorators'
import { post } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { DealPaymentJSON } from '../../models/Payment/Deal'

export const Refund = {
  create: authenticate(
    (paymentUuid: string, amount: number): ApiRequestDescriptor<DealPaymentJSON> =>
      post(`/api/v1/payments/${paymentUuid}/refund`, { amount }),
  ),
}
