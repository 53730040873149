import { DateTime } from 'luxon';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { assert } from '@advitam/support';

import { RESOURCE_CABLE } from './constants';

export interface State {
  lastAction: string;
  isDestroyed: boolean;
  isErrorBannerOpen: boolean;
  isUpdated: boolean;
  isUpdateModalOpen: boolean;
}

export interface AppStateSubset {
  [RESOURCE_CABLE]: State;
}

function now(): string {
  // TODO: Settings.throwOnInvalid = true;
  const time = DateTime.now().toISO();
  assert(time !== null);
  return time;
}

const initialState: State = {
  lastAction: now(),
  isDestroyed: false,
  isErrorBannerOpen: false,
  isUpdated: false,
  isUpdateModalOpen: false,
};

const slice = createSlice({
  name: RESOURCE_CABLE,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setLastAction: state => {
      state.lastAction = now();
    },
    setIsDestroyed: (state, { payload }: PayloadAction<boolean>) => {
      state.isDestroyed = payload;
    },
    setIsErrorBannerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isErrorBannerOpen = payload;
    },
    setIsUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdated = payload;
      state.isUpdateModalOpen = payload;
    },
    setIsUpdateModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdateModalOpen = payload;
    },
    resetUpdated: state => {
      state.isErrorBannerOpen = initialState.isErrorBannerOpen;
      state.isUpdated = initialState.isUpdated;
      state.isUpdateModalOpen = initialState.isUpdateModalOpen;
    },
  },
  extraReducers: {},
  /* eslint-enable no-param-reassign */
});

export const {
  setLastAction,
  setIsDestroyed,
  setIsErrorBannerOpen,
  setIsUpdated,
  setIsUpdateModalOpen,
  resetUpdated,
} = slice.actions;
export default slice;
