import style from './EmptyProduct.module.scss'

export default function EmptyProduct(): JSX.Element {
  return (
    <div className={style.card}>
      <span className={style.image_placeholder} />
      <div className={style.infos_placeholder}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
