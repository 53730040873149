import General from './General';
import Misc from './Miscellaneous';
import Documents from './Documents';
import Warehouses from './Warehouses';

export default {
  General,
  Misc,
  Documents,
  Warehouses,
};
