import { useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';

import { PageSpinner } from 'components/Spinner';
import { ButtonV2 as Button } from 'components/Button';
import Check from 'images/check.svg';
import CropIcon from 'images/icons/crop.svg';
import RotateIcon from 'images/icons/rotate-right.svg';
import UndoIcon from 'images/icons/undo.svg';
import { useElementBoundingBox } from 'lib/reactvitam';

import RotateOverlay from './RotateOverlay';
import { BACKGROUND_MIME_TYPE, BACKGROUND_QUALITY } from '../../constants';
import style from './ImageEditor.module.scss';
import { A4_ASPECT_RATIO, BACKGROUND_IMAGE_WIDTH } from '../constants';

const enum EditionMode {
  CROP,
  ROTATE,
}

interface ImageEditorProps {
  src: string;
  onSave: (blob: Blob) => void;
}

export default function ImageEditor({
  src,
  onSave,
}: ImageEditorProps): JSX.Element {
  const cropperRef = useRef<ReactCropperElement>(null);
  const imageBox = useElementBoundingBox(cropperRef);
  const [isLoading, setIsLoading] = useState(false);
  const [editionMode, setEditionMode] = useState(EditionMode.CROP);

  const reset = (): void => {
    if (cropperRef.current) {
      cropperRef.current.cropper.reset();
    }
  };

  const setAngle = (angle: number): void => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotateTo(angle);
    }
  };

  const validate = (): void => {
    if (!cropperRef.current) {
      return;
    }
    setIsLoading(true);
    cropperRef.current.cropper
      .getCroppedCanvas({ fillColor: 'white', width: BACKGROUND_IMAGE_WIDTH })
      .toBlob(
        (blob): void => {
          setIsLoading(false);
          if (blob) {
            onSave(blob);
          }
        },
        BACKGROUND_MIME_TYPE,
        BACKGROUND_QUALITY,
      );
  };

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Cropper
        src={src}
        guides={false}
        autoCropArea={1}
        ref={cropperRef}
        initialAspectRatio={A4_ASPECT_RATIO}
        aspectRatio={A4_ASPECT_RATIO}
        wheelZoomRatio={0.01}
      />
      {editionMode === EditionMode.ROTATE && cropperRef.current && (
        <RotateOverlay
          imageOrigin={[imageBox.left, imageBox.top]}
          setAngle={setAngle}
        />
      )}
      <div
        className={style.buttons}
        onDragOver={(ev): void => ev.stopPropagation()}
      >
        <Button className="button button--primary button--icon" onClick={reset}>
          <UndoIcon />
        </Button>
        <div className={style.button_spacer} />
        <Button
          className={[
            'button button--primary button--icon',
            editionMode === EditionMode.CROP ? style.current_mode : '',
          ].join(' ')}
          onClick={(): void => setEditionMode(EditionMode.CROP)}
        >
          <CropIcon />
        </Button>
        <Button
          className={[
            'button button--primary button--icon',
            editionMode === EditionMode.ROTATE ? style.current_mode : '',
          ].join(' ')}
          onClick={(): void => setEditionMode(EditionMode.ROTATE)}
        >
          <RotateIcon />
        </Button>
        <div className={style.button_spacer} />
        <Button
          className="button button--primary button--icon"
          onClick={validate}
        >
          <Check />
        </Button>
      </div>
    </>
  );
}
