import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { FlightJSON } from '@advitam/api/models/Flight';
import { assert } from 'lib/support';

import { FLIGHT } from './constants';
import {
  createFlight,
  destroyFlight,
  fetchFlight,
  setIsFlightDisabled,
  updateFlight,
} from './thunk';
import type { NewFlight } from './types';

export interface State {
  flight: FlightJSON | NewFlight | null;
  isLoading: boolean;
  isSaving: boolean;
  isDestroying: boolean;
  isDefaultOverrideConfirmationNeeded: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [FLIGHT]: State;
}

export const initialState: State = {
  flight: null,
  isLoading: false,
  isSaving: false,
  isDestroying: false,
  isDefaultOverrideConfirmationNeeded: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: FLIGHT,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFlight(
      state,
      { payload }: PayloadAction<FlightJSON | NewFlight | null>,
    ) {
      state.flight = payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFlight.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchFlight.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.flight = payload;
    });
    builder.addCase(fetchFlight.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createFlight.pending, state => {
      state.isSaving = true;
      state.error = null;
    });
    builder.addCase(createFlight.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(createFlight.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateFlight.pending, state => {
      state.isSaving = true;
      state.error = null;
    });
    builder.addCase(updateFlight.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.flight = payload;
    });
    builder.addCase(updateFlight.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyFlight.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyFlight.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroyFlight.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setIsFlightDisabled.pending, state => {
      state.isSaving = true;
      state.error = null;
    });
    builder.addCase(setIsFlightDisabled.fulfilled, (state, { meta }) => {
      state.isSaving = false;
      assert(state.flight !== null);
      state.flight.disabled = meta.arg;
    });
    builder.addCase(setIsFlightDisabled.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setFlight } = slice.actions;
export default slice;
