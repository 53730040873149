import defaultTheme from './default'

interface CssThemeProps {
  theme?: Record<string, string>
  nonce?: string
}

export default function CssTheme({ theme = defaultTheme, nonce = '' }: CssThemeProps): JSX.Element {
  const vars = Object.entries(theme)
    .map(([name, value]) => `--${name}:${value}`)
    .join(';')

  return <style nonce={nonce}>{`:root{${vars}}`}</style>
}
