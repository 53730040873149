import { useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import Api from '@advitam/api';
import { AgreementLocationType } from '@advitam/api/models/Hospital/AgreementLocationType';
import { getTokens } from '@advitam/api/lib/tokens';
import { FormUI, Input, isRequired } from '@advitam/ui';
import type { EntityAutocompleteResult } from '@advitam/ui/components/Form/Autocomplete/EntityAutocompleteWithFilters/types';
import { assert, presence } from 'lib/support';

import { HospitalForm } from '../types';
import messages from './messages';

const ENDPOINTS = {
  [AgreementLocationType.HOSPITAL]: Api.V1.Autocompletes.Path.hospitals,
  [AgreementLocationType.FUNERAL_PARLOR]:
    Api.V1.Autocompletes.Path.funeralParlors,
};

export default function FormLayoutLocationId(): JSX.Element {
  const form = useForm();
  const { values } = useFormState<HospitalForm>();

  const onChange = useCallback(
    (suggestion: EntityAutocompleteResult | undefined): void => {
      form.change('hospital.agreement_location.id', suggestion?.id);
      form.change('hospital.agreement_location.name', suggestion?.name);
    },
    [form],
  );

  if (!presence(values.hospital.agreement_location?.type)) {
    return (
      <Input
        disabled
        name="hospital.agreement_location.id"
        label={<FormattedMessage id={messages.agreementLocationId.id} />}
        tooltip={
          <FormattedMessage id={messages.agreementLocationIdTooltip.id} />
        }
      />
    );
  }

  const value = (values.hospital
    .agreement_location as unknown) as EntityAutocompleteResult;
  return (
    <Field name="hospital.agreement_location.id" validate={isRequired}>
      {({ meta }): JSX.Element => {
        assert(presence(values.hospital.agreement_location));
        return (
          <FormUI.Autosuggest<EntityAutocompleteResult>
            key={values.hospital.agreement_location.type}
            label={<FormattedMessage id={messages.agreementLocationId.id} />}
            tooltip={
              <FormattedMessage id={messages.agreementLocationIdTooltip.id} />
            }
            endpoint={Api.V1.absolute(
              ENDPOINTS[values.hospital.agreement_location.type],
            )}
            value={value}
            getDisplayValue={(suggestion): string => suggestion?.name || ''}
            requestHeaders={{ ...getTokens() }}
            onChange={onChange}
            error={meta.touched && !meta.valid}
          />
        );
      }}
    </Field>
  );
}
