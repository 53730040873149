import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from '@advitam/support';

import { HISTORY } from './constants';
import { makeSelectDeal } from '../../selectors.typed';
import type { AppStateSubset } from '../../slice';

export const fetchCommunications = createAsyncThunk(
  `${HISTORY}_FETCH_COMMUNICATIONS`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Deals.Communications.index(deal.id),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
