import { authenticate } from '../../../lib/decorators'
import { ApiRequestDescriptor } from '../../../lib/request'
import { get } from '../../../lib/methods'
import { CompanyFormalityJSON } from '../../../models/Company'
import { Categories } from './Categories'

interface FormalitiesIndexFilters {
  q?: string
  company_id_eq?: number
  category_id_eq?: number
}

export class Formalities {
  static readonly Categories = Categories

  @authenticate
  static index(filters: FormalitiesIndexFilters): ApiRequestDescriptor<CompanyFormalityJSON[]> {
    return get('/api/v1/companies/formalities', filters)
  }
}
