// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Reassurance-module__reassurance--qmbGF{display:grid;grid-template-columns:50px auto;align-items:center;gap:16px 12px;margin-top:32px}.Reassurance-module__reassurance--qmbGF>:nth-child(odd){justify-self:center;max-width:32px;height:32px}`, "",{"version":3,"sources":["webpack://./../ui/components/Reassurance/Reassurance.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CAGA,+BAAA,CACA,kBAAA,CACA,aAAA,CACA,eAAA,CAEA,wDACE,mBAAA,CACA,cAAA,CACA,WAAA","sourcesContent":[".reassurance {\n  display: grid;\n  // multicolumn: Partial support only for break-after, break-before, and break-inside\n  // stylelint-disable-next-line plugin/no-unsupported-browser-features\n  grid-template-columns: 50px auto;\n  align-items: center;\n  gap: 16px 12px;\n  margin-top: 32px;\n\n  > :nth-child(odd) {\n    justify-self: center;\n    max-width: 32px;\n    height: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reassurance": `Reassurance-module__reassurance--qmbGF`
};
export default ___CSS_LOADER_EXPORT___;
