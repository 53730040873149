import { defineMessages } from 'react-intl';

export default defineMessages({
  toolbarTitle: {
    id: 'app.containers.Documents.toolbarTitle',
  },
  documents: {
    id: 'app.containers.Documents.documents',
  },
  cellDocName: {
    id: 'app.containers.Documents.cellDocName',
  },
  cellDocUuid: {
    id: 'app.containers.Documents.cellDocUuid',
  },
  cellAction: {
    id: 'app.containers.Documents.cellAction',
  },
  cellEntityType: {
    id: 'app.containers.Documents.cellEntityType',
  },
  cellEntityName: {
    id: 'app.containers.Documents.cellEntityName',
  },
  generate: {
    id: 'app.containers.Documents.generate',
  },
  edit: {
    id: 'app.containers.Documents.edit',
  },
  tooltipDownload: {
    id: 'app.containers.Documents.tooltipDownload',
  },
  tooltipSend: {
    id: 'app.containers.Documents.tooltipSend',
  },
  tooltipSendForSignature: {
    id: 'app.containers.Documents.tooltipSendForSignature',
  },
  tooltipPreview: {
    id: 'app.containers.Documents.tooltipPreview',
  },
  tooltipVersions: {
    id: 'app.containers.Documents.tooltipVersions',
  },
  tooltipMerge: {
    id: 'app.containers.Documents.tooltipMerge',
  },
  tooltipEdit: {
    id: 'app.containers.Documents.tooltipEdit',
  },
  tooltipRefresh: {
    id: 'app.containers.Documents.tooltipRefresh',
  },
  titleDlModal: {
    id: 'app.containers.Documents.titleDlModal',
  },
  subtitleDlModal: {
    id: 'app.containers.Documents.subtitleDlModal',
  },
  headerWho: {
    id: 'app.containers.Documents.headerWho',
  },
  headerWhen: {
    id: 'app.containers.Documents.headerWhen',
  },
  headerNumber: {
    id: 'app.containers.Documents.headerNumber',
  },
  nbFiles: {
    id: 'app.containers.Documents.nbFiles',
  },
  search: {
    id: 'app.containers.Documents.search',
  },
  mergedFilename: {
    id: 'app.containers.Documents.mergedFilename',
  },
  versionLockFailed: {
    id: 'app.containers.Documents.versionLockFailed',
  },
  versionUnlockFailed: {
    id: 'app.containers.Documents.versionUnlockFailed',
  },
});
