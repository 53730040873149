import {
  ERROR,
  ERROR_MODAL,
  SET_SECTION_OPENED,
  UPDATE_MANUAL_MODE,
  GET_GRAVEYARD_CONCESSION_TYPES,
  GET_GRAVEYARD_CONCESSIONS,
  SET_GRAVEYARD_CONCESSION_TYPES,
  SET_GRAVEYARD_CONCESSIONS,
  GET_GRAVEYARD_BASIC_INFOS,
  SET_GRAVEYARD_BASIC_INFOS,
} from './constants.ts';
import { setDealFields as setDealFieldsSlice } from './slice.ts';

export function error(err) {
  return {
    type: ERROR,
    err,
  };
}

export function errorModal(err) {
  return {
    type: ERROR_MODAL,
    err,
  };
}

/**
 * Update the section to display in deal
 *
 * @param   {String}    section   the name of the section to open
 * @returns {{section: *, type: *}}
 */
export function setSectionOpened(section) {
  return {
    type: SET_SECTION_OPENED,
    section,
  };
}

/**
 * Function to update manual mode
 *
 * @param {boolean} isManualMode is manual mode on ?
 * @returns {object}
 */
export function updateManualMode(isManualMode) {
  return {
    type: UPDATE_MANUAL_MODE,
    isManualMode,
  };
}

export function getGraveyardConcessionTypes(id) {
  return {
    type: GET_GRAVEYARD_CONCESSION_TYPES,
    id,
  };
}

export function setGraveyardConcessionTypes(graveyardConcessionTypes) {
  return {
    type: SET_GRAVEYARD_CONCESSION_TYPES,
    graveyardConcessionTypes,
  };
}

export function getGraveyardConcessions(id) {
  return {
    type: GET_GRAVEYARD_CONCESSIONS,
    id,
  };
}

export function setGraveyardConcessions(graveyardConcessions) {
  return {
    type: SET_GRAVEYARD_CONCESSIONS,
    graveyardConcessions,
  };
}

export function getGraveyardBasicInfos(graveyardId) {
  return {
    type: GET_GRAVEYARD_BASIC_INFOS,
    graveyardId,
  };
}

export function setGraveyardBasicInfos(graveyardBasicInfos) {
  return {
    type: SET_GRAVEYARD_BASIC_INFOS,
    graveyardBasicInfos,
  };
}

export function setDealFields(update, isTouched = true) {
  return setDealFieldsSlice({ update, isTouched });
}
