import { FormattedDate, FormattedNumber } from 'react-intl';

import { FlightIndex } from '@advitam/api/models/Flight/FlightIndex';
import {
  CroppedText,
  ResourceList,
  Tooltip,
  FormattedPrice,
} from '@advitam/ui';

import { Path } from 'containers/App/constants';

import style from '../Flights.module.scss';
import AirportDetails from './AirportDetails';

interface FlightsRowProps {
  flight: FlightIndex;
}

export default function FlightsRow({ flight }: FlightsRowProps): JSX.Element {
  const {
    id,
    airline,
    arrival_airport: arrivalAirport,
    departure_airport: departureAirport,
    stopover_airport: stopoverAirport,
    min_price: minPrice,
    min_weight: minWeight,
    updated_at: updatedAt,
  } = flight;

  const link = { href: `${Path.FLIGHTS}/${id}`, internal: true };

  return (
    <ResourceList.Row className={style.row} link={link}>
      <ResourceList.Cell className={style.logo}>
        {airline && (
          <Tooltip content={airline.name}>
            {airline.thumbnail_link ? (
              <img src={airline.thumbnail_link} alt={airline.name} />
            ) : (
              <CroppedText>{airline.name}</CroppedText>
            )}
          </Tooltip>
        )}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.airport}>
        <CroppedText>
          <AirportDetails airport={arrivalAirport} />
        </CroppedText>
      </ResourceList.Cell>
      <ResourceList.Cell className={style.airport}>
        <CroppedText>
          <AirportDetails airport={departureAirport} />
        </CroppedText>
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile>
        <AirportDetails airport={stopoverAirport} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile>
        <FormattedNumber style="unit" unit="kilogram" value={minWeight} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.price}>
        <FormattedPrice value={minPrice} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        {updatedAt && <FormattedDate value={updatedAt} />}
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
