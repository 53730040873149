import type { FieldMetaState } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { ERROR_MESSAGES } from './constants'
import type { ErrorMessages, ValidationError } from './types'

function isValidationError<T>(error: FieldMetaState<T>['error']): error is ValidationError {
  return Boolean((error as ValidationError).message)
}

function renderMessage(
  message: string,
  messages?: ErrorMessages,
  values?: Record<string, string>,
): JSX.Element | boolean {
  const errorMessage = messages?.[message] || ERROR_MESSAGES[message]

  if (!errorMessage) {
    return true
  }

  return <FormattedMessage id={errorMessage.id} values={values} />
}

export function getFormError<T>(
  { error, submitError, touched, valid }: FieldMetaState<T>,
  messages?: ErrorMessages,
  hideErrorMessage?: boolean,
): JSX.Element | boolean {
  if (!error || !touched || valid) {
    return false
  }

  if (hideErrorMessage) {
    return true
  }

  const formError: unknown = error || submitError

  if (isValidationError(formError)) {
    return renderMessage(formError.message, messages, formError.values)
  }

  if (typeof formError !== 'string') {
    return true
  }

  return renderMessage(formError, messages)
}
