import { createSelector, Selector } from 'reselect';

import { PaymentState } from '@advitam/api/models/Payment/State';
import type { PaymentJSON } from 'models/Payment';
import { PaymentProblem } from 'models/Deal/PaymentProblem';

import { makeSelectDeal } from '../selectors.typed';
import type { AppStateSubset as DealAppStateSubset } from '../slice';
import type { State, AppStateSubset } from './slice';
import {
  COUNTED_PAYMENT_STATES,
  PAYMENTS_SPACE,
  REFUNDABLE_PAYMENT_MEANS,
} from './constants';
import { SortedPayment } from './types';

type PaymentsSelector<T> = Selector<AppStateSubset & DealAppStateSubset, T>;

const selectDealPaymentsDomain = (state: AppStateSubset): State =>
  state && state[PAYMENTS_SPACE];

export const makeSelectPayments = (): PaymentsSelector<PaymentJSON[]> =>
  createSelector(selectDealPaymentsDomain, state => state.payments);

export const makeSelectSortedPaymentsByParent = (): PaymentsSelector<
  SortedPayment[]
> =>
  createSelector(makeSelectPayments(), payments => {
    const sortedPayments: SortedPayment[] = payments.map((payment, index) => ({
      originalIndex: index,
      payment,
    }));

    const parentPayments = sortedPayments.filter(
      ({ payment }) => !payment.parent_uuid,
    );

    return parentPayments.reduce<SortedPayment[]>((result, parentPayment) => {
      const childPayments = sortedPayments.filter(
        ({ payment }) => payment.parent_uuid === parentPayment.payment.uuid,
      );
      return [...result, parentPayment, ...childPayments];
    }, []);
  });

export const makeSelectAcceptedPayments = (): PaymentsSelector<PaymentJSON[]> =>
  createSelector(makeSelectPayments(), payments =>
    payments.filter(payment => payment.state === PaymentState.ACCEPTED),
  );

const makeSelectPaymentRefunds = (
  paymentUuid?: string,
): PaymentsSelector<PaymentJSON[]> =>
  createSelector(makeSelectPayments(), payments =>
    payments.filter(
      payment =>
        payment.amount < 0 &&
        payment.parent_uuid === paymentUuid &&
        COUNTED_PAYMENT_STATES.includes(payment.state),
    ),
  );

export const makeSelectPaymentRefundableMaxAmount = (
  payment: PaymentJSON,
): PaymentsSelector<number> =>
  createSelector(makeSelectPaymentRefunds(payment.uuid), refunds => {
    const refundsAmount = refunds.reduce(
      (amount, refund) => amount + refund.amount,
      0,
    );
    return payment.amount + refundsAmount;
  });

export const makeSelectIsPaymentRefundable = (
  payment: PaymentJSON,
): PaymentsSelector<boolean> =>
  createSelector(
    makeSelectPaymentRefundableMaxAmount(payment),
    refundableMaxAmount =>
      refundableMaxAmount > 0 &&
      payment.state === PaymentState.ACCEPTED &&
      payment.mean !== null &&
      REFUNDABLE_PAYMENT_MEANS.includes(payment.mean),
  );

export const makeSelectPaymentsToRemove = (): PaymentsSelector<
  State['paymentsToRemove']
> => createSelector(selectDealPaymentsDomain, state => state.paymentsToRemove);

export const makeSelectPaymentProblem = (): PaymentsSelector<PaymentProblem> =>
  createSelector(
    makeSelectDeal(),
    deal => deal?.payment_problem || PaymentProblem.NONE,
  );

export const makeSelectIsLoading = (): PaymentsSelector<State['isLoading']> =>
  createSelector(selectDealPaymentsDomain, state => state.isLoading);

export const makeSelectIsDirty = (): PaymentsSelector<State['isDirty']> =>
  createSelector(selectDealPaymentsDomain, state => state.isDirty);

export const makeSelectError = (): PaymentsSelector<State['error']> =>
  createSelector(selectDealPaymentsDomain, state => state.error);
