import { ReactNode } from 'react';

import { ErrorText, FlatButton } from '@advitam/ui';
import Times from '@advitam/ui/images/icons/times.svg';

import style from './ErrorBanner.module.scss';

interface ErrorBannerBaseProps {
  isOpen: boolean;
  children: ReactNode | ReactNode[];
  onClose: () => void;
}

export default function ErrorBannerBase({
  isOpen,
  children,
  onClose,
}: ErrorBannerBaseProps): JSX.Element | null {
  if (!isOpen) {
    return null;
  }

  return (
    <ErrorText tagName="div" className={style.banner}>
      {children}
      <FlatButton onClick={onClose}>
        <Times />
      </FlatButton>
    </ErrorText>
  );
}
