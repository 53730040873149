// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.DealMarble-module__reminder_button--sQyMW{display:flex;align-items:center;justify-content:center;margin:10px;width:calc(100% - 20px)}button.DealMarble-module__reminder_button--sQyMW svg{margin-right:8px;height:22px}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/DealMarble/DealMarble.module.scss"],"names":[],"mappings":"AAAA,iDACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,uBAAA,CAEA,qDACE,gBAAA,CACA,WAAA","sourcesContent":["button.reminder_button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 10px;\n  width: calc(100% - 20px);\n\n  svg {\n    margin-right: 8px;\n    height: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reminder_button": `DealMarble-module__reminder_button--sQyMW`
};
export default ___CSS_LOADER_EXPORT___;
