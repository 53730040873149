import { BillingType } from '../BillingType'
import { CheckoutType } from '../CheckoutType'
import { MinimalCityhallJSON } from '../Cityhall'
import { Country } from '../Country'
import { Model } from '../Model'
import { OpeningHoursJSON } from '../Entity/OpeningHours'
import { ContactMedia } from '../Entity/ContactMedia'
import { ProvidesCeremonyMaster } from './ProvidesCeremonyMaster'
import { WorshipJSON } from './Worship'

interface CrematoriumBase {
  id: number
  name: string
  disabled: boolean
  address: string | null
  address_l2: string | null
  postal_code: string | null
  insee_code: string | null
  city: string | null
  department: string | null
  country: Country | null
  latitude: number | null
  longitude: number | null
  manual_address: boolean
  phone: string | null
  email: string | null
  prefered_contact_media: ContactMedia | null
  opening_hours: OpeningHoursJSON | null
  fax: string | null
  phone_2: string | null
  phone_3: string | null
  email_2: string | null
  email_3: string | null
  email_4: string | null
  comment: string | null
  current_update_user_id: number | null
  last_update_user_id: number | null
  billing_type: BillingType
  ceremony: number | null
  exhumation_lt_5_price: number | null
  exhumation_gt_5_price: number | null
  scellement_urn_fee: number | null
  dispersion_jds_fee: number | null
  length_limit: number | null
  width_limit: number | null
  height_limit: number | null
  cheque_order: string | null
  cheque_address: string | null
  urn_returning_schedule: number | null
  catholic_price: number | null
  protestant_price: number | null
  family_has_to_contact: boolean | null
  conviviality_room: boolean | null
  conviviality_room_cost: number | null
  urn_conservation_free_period: number | null
  urn_conservation_fee: number | null
  is_live_default: boolean | null
  rib: string | null
  iban: string | null
  vat: string | null
  checkout_type: CheckoutType | null
  provides_ceremony_master: ProvidesCeremonyMaster | null
  reduced_price_cityhalls: MinimalCityhallJSON[]
  authentic_document_needed: boolean | null
  siret: string | null
  headquarter_name: string | null
  headquarter_address: string | null
  headquarter_postal_code: string | null
  headquarter_country: Country | null
  headquarter_city: string | null
  website: string | null
  worships: WorshipJSON[]
}

export interface CrematoriumJSON extends CrematoriumBase {
  current_update_dt: string | null
  last_update_dt: string | null
}

export interface Crematorium extends CrematoriumBase {
  current_update_dt: Date | null
  last_update_dt: Date | null
}

export class Crematorium extends Model<CrematoriumJSON> {
  constructor(data: CrematoriumJSON) {
    super(data)
    this.current_update_dt = Model.parseDate(data.current_update_dt)
    this.last_update_dt = Model.parseDate(data.last_update_dt)
  }
}

export { ProvidesCeremonyMaster }
