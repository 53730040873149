import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ErrorText,
  FormattedApiError,
  FormUI,
  Modal,
  ModalTitle,
  Spinner,
  Text,
} from '@advitam/ui';

import {
  makeSelectError,
  makeSelectInputError,
  makeSelectIsLoading,
  makeSelectIsModalOpen,
  makeSelectOption,
  makeSelectValue,
} from './selectors';
import { closeModal, setOption, setValue } from './slice';
import messages from './messages';
import style from './ReminderButton.module.scss';
import { usePredefinedValues } from './dates';
import { PREDEFINED_DATE_FORMAT } from './constants';
import { createReminder } from './thunk';

export default function SchedulingModal(): JSX.Element {
  const dispatch = useDispatch();

  const option = useSelector(makeSelectOption());
  const value = useSelector(makeSelectValue());
  const inputError = useSelector(makeSelectInputError());
  const isOpen = useSelector(makeSelectIsModalOpen());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const predefinedValues = usePredefinedValues();

  const onSubmit = useCallback(() => {
    dispatch(createReminder());
  }, [dispatch]);

  const onCheck1 = useCallback(() => {
    dispatch(setOption(1));
  }, [dispatch]);

  const onCheck2 = useCallback(() => {
    dispatch(setOption(2));
  }, [dispatch]);

  const onCheck3 = useCallback(() => {
    dispatch(setOption(3));
  }, [dispatch]);

  const onCheck4 = useCallback(() => {
    dispatch(setOption(4));
  }, [dispatch]);

  const onCheck0 = useCallback(() => {
    dispatch(setOption(0));
  }, [dispatch]);

  const onCustomInput = useCallback(
    (v: string) => {
      dispatch(setValue(v));
    },
    [dispatch],
  );

  const onClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`new-design col-6 ${style.modal}`}
    >
      <ModalTitle>
        <FormattedMessage id={messages.modalTitle.id} />
      </ModalTitle>

      <div className={style.radio}>
        <FormUI.Radio.Default
          borderless
          value={1}
          checked={option === 1}
          onChange={onCheck1}
          label={<FormattedMessage id={messages.laterThisDay.id} />}
        />
        <Text>{predefinedValues[0].toLocaleString(DateTime.TIME_SIMPLE)}</Text>
      </div>
      <div className={style.radio}>
        <FormUI.Radio.Default
          borderless
          value={2}
          checked={option === 2}
          onChange={onCheck2}
          label={<FormattedMessage id={messages.tomorrow.id} />}
        />
        <Text>
          {predefinedValues[1].toLocaleString(PREDEFINED_DATE_FORMAT)}
        </Text>
      </div>
      <div className={style.radio}>
        <FormUI.Radio.Default
          borderless
          value={3}
          checked={option === 3}
          onChange={onCheck3}
          label={<FormattedMessage id={messages.nextWeek.id} />}
        />
        <Text>
          {predefinedValues[2].toLocaleString(PREDEFINED_DATE_FORMAT)}
        </Text>
      </div>
      <div className={style.radio}>
        <FormUI.Radio.Default
          borderless
          value={4}
          checked={option === 4}
          onChange={onCheck4}
          label={<FormattedMessage id={messages.nextMonth.id} />}
        />
        <Text>
          {predefinedValues[3].toLocaleString(PREDEFINED_DATE_FORMAT)}
        </Text>
      </div>

      <FormUI.Radio.Default
        borderless
        value={0}
        checked={option === 0}
        onChange={onCheck0}
        label={<FormattedMessage id={messages.anotherDate.id} />}
      />
      <FormUI.DateInput
        className={style.date_input}
        value={value || ''}
        error={
          inputError && (
            <ErrorText>
              <FormattedMessage id={messages[inputError].id} />
            </ErrorText>
          )
        }
        disabled={option !== 0}
        onChange={onCustomInput}
      />

      {error && (
        <ErrorText center className={style.error}>
          <FormattedApiError error={error} />
        </ErrorText>
      )}

      <div className={style.modal_buttons}>
        <Button
          outline
          text={<FormattedMessage id={messages.cancel.id} />}
          onClick={onClose}
        />
        <Button
          primary
          fixedSize
          text={<FormattedMessage id={messages.submit.id} />}
          onClick={onSubmit}
          icon={isLoading ? <Spinner /> : undefined}
          disabled={!value || isLoading}
        />
      </div>
    </Modal>
  );
}
