import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FormattedDuration, ResourceList } from '@advitam/ui';
import Phone from '@advitam/ui/images/icons/phone.svg';

import messages from './messages';
import { makeSelectTotalCallDuration } from './selectors';
import style from './History.module.scss';
import { setSort } from './slice';

export default function Header(): JSX.Element {
  const dispatch = useDispatch();

  const duration = useSelector(makeSelectTotalCallDuration());

  const onSortEntity = useCallback(
    (descending: boolean) => {
      dispatch(setSort({ column: 'entity_name', descending }));
    },
    [dispatch],
  );

  const onSortDate = useCallback(
    (descending: boolean) => {
      dispatch(setSort({ column: 'date', descending }));
    },
    [dispatch],
  );

  return (
    <ResourceList.Header>
      <ResourceList.Cell />
      <ResourceList.Cell onSort={onSortEntity}>
        <FormattedMessage id={messages.recipient.id} />
      </ResourceList.Cell>
      <ResourceList.Cell />
      <ResourceList.Cell hiddenOnTablet onSort={onSortDate}>
        <FormattedMessage id={messages.date.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.wait_time} />
      <ResourceList.Cell hiddenOnTablet className={style.duration}>
        {duration && (
          <>
            <Phone />
            <FormattedDuration value={duration} />
          </>
        )}
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.summary.id} />
      </ResourceList.Cell>
      <ResourceList.Cell />
    </ResourceList.Header>
  );
}
