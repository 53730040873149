import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { withSlice } from '@advitam/react';
import cable, { ResourceRoomType } from 'cables/Resource';
import { makeSelectSessionId } from 'cables/Resource/selectors';
import focus from 'cables/Resource/actions/Focus';
import unfocus from 'cables/Resource/actions/Unfocus';
import ping from 'cables/Resource/actions/Ping';
import { makeSelectUser } from 'slices/auth';
import { ErrorBannerBase } from 'components/ErrorBanner';
import { useInterval, useIsPageVisible, useIsUserActive } from 'lib/reactvitam';
import { useCable } from 'lib/reactvitam/redux';

import slice, { setIsErrorBannerOpen } from './slice';
import { makeSelectIsErrorBannerOpen } from './selectors';
import messages from './messages';

// Time after which an user is considered inactive.
// Note that this only affects whether the ping event is sent,
// receivers are free to use another duration
const ACTIVITY_SECONDS = 60;

interface ResourceCableContainerProps {
  resourceType: ResourceRoomType;
  resourceId: number;
  children: ReactNode;
}

function ResourceCableContainer({
  resourceId,
  resourceType,
  children,
}: ResourceCableContainerProps): JSX.Element {
  const dispatch = useDispatch();

  const user = useSelector(makeSelectUser());
  const sessionId = useSelector(makeSelectSessionId());
  const isErrorBannerOpen = useSelector(makeSelectIsErrorBannerOpen());

  const isActive = useIsUserActive(ACTIVITY_SECONDS);
  const isVisible = useIsPageVisible();

  const subscription = useCable(cable, {
    resource_id: resourceId,
    resource_type: resourceType,
  });

  useEffect(() => {
    if (!subscription || !sessionId || !user) {
      // Not initialized yet
      return;
    }

    if (isVisible) {
      subscription.send(focus(user, sessionId));
    } else {
      subscription.send(unfocus(user, sessionId));
    }
  }, [isVisible]);

  useInterval(() => {
    if (subscription && isActive && user) {
      subscription.send(ping(user));
    }
  }, ACTIVITY_SECONDS * 1000);

  useEffect(() => {
    // User did just become active again, send a ping now.
    if (subscription && isActive && user) {
      subscription.send(ping(user));
    }
  }, [isActive]);

  return (
    <>
      {children}
      <ErrorBannerBase
        isOpen={isErrorBannerOpen}
        onClose={(): void => {
          dispatch(setIsErrorBannerOpen(false));
        }}
      >
        <p>
          <FormattedMessage id={messages.updated.id} />
        </p>
      </ErrorBannerBase>
    </>
  );
}

export default withSlice<ResourceCableContainerProps>(slice)(
  ResourceCableContainer,
);
