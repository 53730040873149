function buildLink(subject: string, body?: string): string {
  const params = [`subject=${encodeURIComponent(subject)}`]
  if (body !== undefined) {
    params.push(`body=${encodeURIComponent(body)}`)
  }

  return `mailto:?${params.join('&')}`
}

export const Email = {
  buildLink,
}
