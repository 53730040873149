import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { makeSelectUser } from 'slices/auth';
import { Path as CrudPath, Sidebar, usePreventLeaving } from 'containers/Crud';
import crudMessages from 'containers/Crud/messages';

import { Path } from './constants';
import messages from './messages';
import {
  makeSelectRegionalHealthAuthority,
  makeSelectIsUpdatingName,
} from './selectors';

export default function RegionalHealthAuthoritySidebar(): JSX.Element {
  const intl = useIntl();

  const user = useSelector(makeSelectUser());
  const isUpdatingName = useSelector(makeSelectIsUpdatingName());
  const regionalHealthAuthority = useSelector(
    makeSelectRegionalHealthAuthority(),
  );

  const { dirty } = useFormState();
  usePreventLeaving(!regionalHealthAuthority?.id || dirty);

  return (
    <Sidebar.Menu>
      <Sidebar.Title
        isCreation={!regionalHealthAuthority?.id}
        isLoading={isUpdatingName}
        fieldName="regionalHealthAuthority.name"
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
      />
      {regionalHealthAuthority?.current_update_dt &&
        regionalHealthAuthority?.current_update_user_id && (
          <Sidebar.LastUpdate
            date={regionalHealthAuthority.current_update_dt}
            userId={regionalHealthAuthority.current_update_user_id}
          />
        )}
      <Sidebar.LinksSection>
        <Sidebar.Link
          to={Path.GENERAL}
          message={<FormattedMessage id={messages.general.id} />}
        />
        {regionalHealthAuthority?.id && (
          <>
            {user?.isFuneralDirectorOrAbove && (
              <Sidebar.LinkGroup
                placeholder={
                  <FormattedMessage id={crudMessages.documents.id} />
                }
              >
                <Sidebar.Link
                  to={CrudPath.EDITABLE_DOCUMENTS}
                  message={
                    <FormattedMessage id={crudMessages.editableDocuments.id} />
                  }
                />
                <Sidebar.Link
                  to={CrudPath.SUPPORTING_DOCUMENTS}
                  message={
                    <FormattedMessage
                      id={crudMessages.supportingDocuments.id}
                    />
                  }
                />
              </Sidebar.LinkGroup>
            )}{' '}
            <Sidebar.Link
              to={CrudPath.OPENING_HOURS}
              message={<FormattedMessage id={crudMessages.openingHours.id} />}
            />
            <Sidebar.Link
              to={Path.MISCELLANEOUS}
              message={<FormattedMessage id={messages.miscellaneous.id} />}
            />
          </>
        )}
      </Sidebar.LinksSection>
    </Sidebar.Menu>
  );
}
