import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { Select } from '@advitam/ui';
import { Layout } from 'containers/Crud';
import { Coverage } from 'containers/Crud/Input';
import actionMessages from 'messages/actions';

import { savePrefecture } from '../thunk';
import Wrapper from './Wrapper';
import { PrefectureForm } from './types';
import messages from './messages';

function Miscellaneous(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onSubmit = useCallback(
    (values: PrefectureForm): void => {
      dispatch(savePrefecture(values.prefecture));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <Layout.ColumnGroup>
          <Layout.Column>
            <Layout.Fieldset
              title={<FormattedMessage id={messages.managedPlaces.id} />}
              tooltip={
                <FormattedMessage id={messages.managedPlacesTooltip.id} />
              }
            >
              <Coverage name="prefecture.prefecture_coverages" />
            </Layout.Fieldset>
          </Layout.Column>
          <Layout.Column>
            <Layout.Fieldset
              title={<FormattedMessage id={messages.functioning.id} />}
            >
              <Select
                unselectable
                name="prefecture.has_rha"
                label={<FormattedMessage id={messages.rha.id} />}
                tooltip={<FormattedMessage id={messages.rhaTooltip.id} />}
                items={[
                  { name: intl.formatMessage(actionMessages.yes), value: true },
                  { name: intl.formatMessage(actionMessages.no), value: false },
                ]}
              />
            </Layout.Fieldset>
          </Layout.Column>
        </Layout.ColumnGroup>
      </Layout.Container>
    </Wrapper>
  );
}

export default Miscellaneous;
