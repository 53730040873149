import {
  DealDocumentBatchDocumentJSON,
  DealDocumentBatchRecipientJSON,
} from '@advitam/api/models/Deal/Document/Batch';
import { ContactMedia } from '@advitam/api/models/Entity/ContactMedia';
import { SendingMethod } from '@advitam/api/models/SendingMethod';
import { PartialBatchDocument, SendingMethodKey } from './types';

type IdentifiableRecipient = Pick<
  DealDocumentBatchRecipientJSON,
  'id' | 'type'
>;

export function getRecipientUniqueId(recipient: IdentifiableRecipient): string {
  return `${recipient.id}-${recipient.type}`;
}

const CONTACT_MEDIA_TO_SENDING_METHOD: Record<
  ContactMedia,
  SendingMethod | null
> = {
  [ContactMedia.EMAIL]: ContactMedia.EMAIL,
  [ContactMedia.LETTER]: ContactMedia.LETTER,
  [ContactMedia.FAX]: ContactMedia.FAX,
  [ContactMedia.SMS]: null,
  [ContactMedia.TEL]: null,
};

export function getRecipientSendingMethodKey({
  prefered_contact_media: contactMedia,
}: DealDocumentBatchRecipientJSON): SendingMethod | null {
  if (!contactMedia) {
    return null;
  }
  return CONTACT_MEDIA_TO_SENDING_METHOD[contactMedia];
}

const SENDING_METHOD_TO_CONTACT_MEDIA: Record<
  SendingMethodKey,
  SendingMethod
> = {
  email: ContactMedia.EMAIL,
  email_2: ContactMedia.EMAIL,
  email_3: ContactMedia.EMAIL,
  email_4: ContactMedia.EMAIL,
  fax: ContactMedia.FAX,
  letter: ContactMedia.LETTER,
};

export function parseSendingKeyMethod(key: SendingMethodKey): SendingMethod {
  return SENDING_METHOD_TO_CONTACT_MEDIA[key];
}

export function isValidBatchDocument(
  doc: DealDocumentBatchDocumentJSON | PartialBatchDocument,
): doc is DealDocumentBatchDocumentJSON {
  return (doc as DealDocumentBatchDocumentJSON).link !== undefined;
}
