import { useState, useEffect, RefObject } from 'react';

export interface WindowSize {
  width: number;
  height: number;
}

export function useElementBoundingBox(el: RefObject<Element>): DOMRect {
  const [bBox, setBBox] = useState(new DOMRect());

  useEffect(() => {
    setBBox(el.current ? el.current.getBoundingClientRect() : new DOMRect());
  }, []);

  return bBox;
}
