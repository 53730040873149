import { createSlice } from '@reduxjs/toolkit';

import { MEMORIAL_SPACE_SETTINGS } from './constants';
import { updateProfilePicture } from './thunk';

export interface State {
  isProfilePictureLoading: boolean;
}

export interface AppStateSubset {
  [MEMORIAL_SPACE_SETTINGS]: State;
}

export const initialState: State = {
  isProfilePictureLoading: false,
};

const slice = createSlice({
  name: MEMORIAL_SPACE_SETTINGS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(updateProfilePicture.pending, state => {
      state.isProfilePictureLoading = true;
    });
    builder.addCase(updateProfilePicture.fulfilled, state => {
      state.isProfilePictureLoading = false;
    });
    // updateProfilePicture is a sinple dispatch, it never fails
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
