import { ReactNode } from 'react';

import style from './style.scss';

interface ModalButtonProps {
  children: ReactNode | ReactNode[];
}

export function ModalButtons({ children }: ModalButtonProps): JSX.Element {
  return <div className={style.modal__buttons}>{children}</div>;
}
