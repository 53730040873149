import { authenticate } from '../../lib/decorators'
import { get } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { FundingAgencyJSON } from '../../models/FundingAgency'

export interface FundingAgencyFilters {
  name_unaccent_cont?: string
  agency_type_eq?: string
}

export class FundingAgencies {
  @authenticate
  static index(
    q: FundingAgencyFilters,
    page = 1,
    per_page = 50,
  ): ApiRequestDescriptor<FundingAgencyJSON[]> {
    return get('/api/v1/funding_agencies', { q, page, per_page })
  }
}
