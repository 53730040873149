import { Objects } from '@advitam/support'
import { Model } from '../../../../models/Model'
import { ZoneCoverageBody, ZoneFuneralWorkBody } from './types'

type ZoneFuneralWorkPayload = Omit<ZoneFuneralWorkBody, 'id' | 'funeral_work_type'> & {
  funeral_work_type_id: number
}

export function serializeFuneralWork(
  funeralWork: Omit<ZoneFuneralWorkBody, 'id'>,
): ZoneFuneralWorkPayload {
  return {
    ...Objects.omit(funeralWork, 'funeral_work_type'),
    funeral_work_type_id: funeralWork.funeral_work_type.id,
  }
}

interface DestroyedCoverage {
  id: number
  _destroy: true
}

type CoveragePayload = ZoneCoverageBody | DestroyedCoverage

function serializeCoverage(zone: ZoneCoverageBody): CoveragePayload {
  if (zone.id && Model.isDestroyed(zone)) {
    return { id: zone.id, _destroy: true }
  }
  return zone
}

export function serializeCoverageForUpdate(coverage: ZoneCoverageBody[]): CoveragePayload[] {
  return coverage.map(serializeCoverage)
}
