import { SerializedApiError } from '@advitam/api';
import { createSelector, Selector } from 'reselect';

import { DOCUMENTS_EDITOR } from './constants';
import type { AppStateSubset, State } from './slice';
import {
  DocumentConflict,
  DocumentEdition,
  NewUploadResource,
  UploadError,
} from './types';

type DocumentsEditorSelector<T> = Selector<AppStateSubset, T>;

const selectDocumentsEditorDomain = (state: AppStateSubset): State =>
  state[DOCUMENTS_EDITOR];

export const makeSelectCurrentSessionId = (): DocumentsEditorSelector<
  string | null
> =>
  createSelector(
    selectDocumentsEditorDomain,
    ({ currentSessionId }) => currentSessionId,
  );

export const makeSelectCurrentResource = (): DocumentsEditorSelector<NewUploadResource | null> =>
  createSelector(
    selectDocumentsEditorDomain,
    ({ currentResource }) => currentResource,
  );

export const makeSelectIsSingleDocumentUpload = (): DocumentsEditorSelector<
  boolean
> =>
  createSelector(makeSelectCurrentResource(), currentResource =>
    Boolean(currentResource?.id),
  );

export const makeSelectUploadedDocuments = (): DocumentsEditorSelector<
  DocumentEdition[]
> =>
  createSelector(
    selectDocumentsEditorDomain,
    ({ uploadedDocuments }) => uploadedDocuments,
  );

export const makeSelectCurrentSessionUploadedDocuments = (): DocumentsEditorSelector<
  DocumentEdition[]
> =>
  createSelector(
    makeSelectCurrentSessionId(),
    makeSelectUploadedDocuments(),
    (currentSessionId, uploadedDocuments) =>
      uploadedDocuments.filter(
        document => document.sessionId === currentSessionId,
      ),
  );

export const makeSelectUploadErrors = (): DocumentsEditorSelector<
  UploadError[]
> =>
  createSelector(
    selectDocumentsEditorDomain,
    ({ uploadErrors }) => uploadErrors,
  );

export const makeSelectDocumentsConflicts = (): DocumentsEditorSelector<
  DocumentConflict[]
> =>
  createSelector(
    selectDocumentsEditorDomain,
    ({ documentsConflicts }) => documentsConflicts,
  );

export const makeSelectProcessedUploadCount = (): DocumentsEditorSelector<
  number
> =>
  createSelector(
    selectDocumentsEditorDomain,
    ({ processedUploadCount }) => processedUploadCount,
  );

export const makeSelectTotalFilesCount = (): DocumentsEditorSelector<number> =>
  createSelector(
    selectDocumentsEditorDomain,
    ({ totalFilesCount }) => totalFilesCount,
  );

export const makeSelectIsLoading = (): DocumentsEditorSelector<boolean> =>
  createSelector(selectDocumentsEditorDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): DocumentsEditorSelector<boolean> =>
  createSelector(selectDocumentsEditorDomain, ({ isSaving }) => isSaving);

export const makeSelectError = (): DocumentsEditorSelector<SerializedApiError | null> =>
  createSelector(selectDocumentsEditorDomain, ({ error }) => error);
