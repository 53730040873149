import { ReactNode } from 'react'

import Label from '../Label'
import Text from '../Text'
import style from './Highlight.module.scss'

interface HighlightTileProps {
  title: ReactNode
  subtitle?: ReactNode
  description?: ReactNode
  subdescription?: ReactNode
  card?: boolean
  className?: string
}

export default function HighlightTile({
  title,
  subtitle,
  description,
  subdescription,
  card,
  className,
}: HighlightTileProps): JSX.Element {
  const classes = [style.tile]
  if (className) {
    classes.push(className)
  }
  if (subtitle) {
    classes.push(style.secondary)
  }
  if (card) {
    classes.push(style.card)
  }

  return (
    <div className={classes.join(' ')}>
      <Text large={!subtitle}>
        <b>{title}</b>
      </Text>
      {subtitle && (
        <div className={style.subtitle}>
          <Text large>
            <b>{subtitle}</b>
          </Text>
        </div>
      )}
      {description && <Text small>{description}</Text>}
      {subdescription &&
        (card ? (
          <Label>{subdescription}</Label>
        ) : (
          <div className={style.subdescription}>
            <Text small>{subdescription}</Text>
          </div>
        ))}
    </div>
  )
}
