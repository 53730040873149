import { BookingStatus } from 'models/BookingStatus.ts';

export const GET_BOOKING_INFO =
  'app/DealTypeWrapper/BookingSection/GET_BOOKING_INFO';
export const SET_BOOKING_INFO =
  'app/DealTypeWrapper/BookingSection/SET_BOOKING_INFO';

export const NO_BOOKING = BookingStatus.NONE;
export const PENDING_PREBOOKING = BookingStatus.PENDING;
export const ACCEPTED_PREBOOKING = BookingStatus.ACCEPTED;
export const REJECTED_PREBOOKING = BookingStatus.REJECTED;
export const CANCELED_PREBOOKING = BookingStatus.CANCELLED;
export const { BOOKED } = BookingStatus;

export const BOOK_SUPPLIERS =
  'app/DealTypeWrapper/BookingSection/BOOK_SUPPLIERS';
export const SUCCESS_BOOK_SUPPLIERS =
  'app/DealTypeWrapper/BookingSection/SUCCESS_BOOK_SUPPLIERS';
export const PRE_BOOK_SUPPLIERS =
  'app/DealTypeWrapper/BookingSection/PRE_BOOK_SUPPLIERS';
export const SUCCESS_PRE_BOOK_SUPPLIERS =
  'app/DealTypeWrapper/BookingSection/SUCCESS_PRE_BOOK_SUPPLIERS';

export const UPDATE_SUPPLIER_BOOKING_STATUS =
  'app/DealTypeWrapper/BookingSection/UPDATE_SUPPLIER_BOOKING_STATUS';
export const SUCCESS_UPDATE_SUPPLIER_BOOKING_STATUS =
  'app/DealTypeWrapper/BookingSection/SUCCESS_UPDATE_SUPPLIER_BOOKING_STATUS';

export const UPDATE_BOOKING_COMMENT =
  'app/DealTypeWrapper/BookingSection/UPDATE_BOOKING_COMMENT';

export const SUCCESS_UPDATE_BOOKING_COMMENT =
  'app/DealTypeWrapper/BookingSection/SUCCESS_UPDATE_BOOKING_COMMENT';

export const SYNC_BOOKING_COMMENT =
  'app/DealTypeWrapper/BookingSection/SYNC_BOOKING_COMMENT';

export const ERROR = 'app/DealTypeWrapper/BookingSection/ERROR';

export const UPDATE_SUPPLIER_SELECTION =
  'app/DealTypeWrapper/BookingSection/UPDATE_SUPPLIER_SELECTION';

export const PRE_CASKETING_BOOKING =
  'app/DealTypeWrapper/BookingSection/PRE_CASKETING_BOOKING';
