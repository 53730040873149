import { ChangeEvent, FocusEvent, ReactElement, ReactNode } from 'react'
import { BlurEvent } from 'react-autosuggest'

import { assert, SearchParams } from '@advitam/support'

import { AutocompleteResult } from './types'
import BaseAutosuggest from './Base'

const BASE_REQUEST_HEADERS = {
  Accept: 'application/json',
}

function defaultGetDisplayValue<TSuggestion extends AutocompleteResult>(r: TSuggestion): string {
  return r.description
}

interface BaseAutocompleteProps<TSuggestion> {
  label?: ReactNode
  value?: TSuggestion
  name?: string
  tooltip?: ReactNode
  /**
   * Get the value to be displayed in the input. This does not affect the
   * display of search results. Defaults to the `description` field.
   */
  getDisplayValue?: (data: TSuggestion) => string
  onChange: (data: TSuggestion | undefined) => void
  onInput?: (ev: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLElement>, params?: BlurEvent<TSuggestion>) => void
  disabled?: boolean
  expandOnTop?: boolean
  error?: ReactElement | boolean
  className?: string
  placeholder?: string
  minCharsRequest?: number
}

interface EndpointACProps<T> extends BaseAutocompleteProps<T> {
  endpoint: string
  requestHeaders?: Record<string, string>
  searchParams?: Record<string, string | string[]>
  fetchRequest?: undefined
  searchTerm?: string
}

interface FetchRequestACProps<T> extends BaseAutocompleteProps<T> {
  endpoint?: undefined
  requestHeaders?: undefined
  searchParams?: undefined
  searchTerm?: undefined
  fetchRequest: (value: string) => Promise<Response>
}

type AutocompleteProps<TSuggestion> =
  | FetchRequestACProps<TSuggestion>
  | EndpointACProps<TSuggestion>

export default function Autocomplete<TSuggestion extends AutocompleteResult = AutocompleteResult>({
  label,
  endpoint,
  name,
  tooltip,
  fetchRequest,
  searchTerm = 'q',
  searchParams = {},
  requestHeaders = {},
  value,
  getDisplayValue = defaultGetDisplayValue,
  onChange,
  onInput,
  onBlur,
  disabled = false,
  expandOnTop = false,
  error,
  className = '',
  placeholder,
  minCharsRequest,
}: AutocompleteProps<TSuggestion>): JSX.Element {
  const renderSuggestion = (s: TSuggestion): string => s.description

  async function fetchSuggestions(v: string): Promise<Response> {
    if (fetchRequest) return fetchRequest(v)
    assert(endpoint !== undefined)

    const qs = SearchParams.create({ ...searchParams, [searchTerm]: v })

    return fetch(`${endpoint}?${qs.toString()}`, {
      headers: { ...requestHeaders, ...BASE_REQUEST_HEADERS },
    })
  }

  async function fetchResults(v: string): Promise<TSuggestion[]> {
    try {
      const res = await fetchSuggestions(v)
      return (await res.json()) as TSuggestion[]
    } catch (err) {
      if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
        // eslint-disable-next-line no-console
        console.error(err)
      }

      return []
    }
  }

  return (
    <BaseAutosuggest<TSuggestion>
      label={label}
      name={name}
      tooltip={tooltip}
      error={error}
      disabled={disabled}
      expandOnTop={expandOnTop}
      className={className}
      placeholder={placeholder}
      value={value}
      minCharsRequest={minCharsRequest}
      getSuggestionValue={getDisplayValue}
      getDisplayValue={getDisplayValue}
      renderSuggestion={renderSuggestion}
      fetchSuggestions={fetchResults}
      onChange={onChange}
      onInput={onInput}
      onBlur={onBlur}
    />
  )
}
