import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LabelWithAction from './LabelWithAction';

/**
 * Render a text with a transparent cross button.
 */
export default class Label extends PureComponent {
  /**
   * Action to execute when user click on cross button.
   */
  onCrossClick = () => {
    const { setDeleteFunction, onRemove } = this.props;
    if (setDeleteFunction) {
      setDeleteFunction(() => onRemove());
      return;
    }
    onRemove();
  };

  render() {
    const { name, className } = this.props;

    return (
      <div className={className}>
        <b className="label__text">{name}</b>
        <button
          onClick={() => this.onCrossClick()}
          type="button"
          className="label__crossButton"
        >
          ×
        </button>
      </div>
    );
  }
}

Label.propTypes = {
  /** Function for remove the label */
  onRemove: PropTypes.func.isRequired,
  /** Dispatch function for confirm modal */
  setDeleteFunction: PropTypes.func,
  /** Name of label */
  name: PropTypes.string.isRequired,
  /** Override className */
  className: PropTypes.string,
};

Label.defaultProps = {
  className: 'label',
  setDeleteFunction: null,
};

export { LabelWithAction };
