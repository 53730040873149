import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { Helmet } from 'react-helmet';

import { Person } from '@advitam/ui/components/Format';
import Text from '@advitam/ui/components/Text';
import { Sidebar, usePreventLeaving } from 'containers/Crud';
import { assert } from 'lib/support';
import style from 'containers/Crud/Sidebar/Title/Title.module.scss';

import { Path } from './constants';
import messages from './messages';
import { makeSelectClient } from './selectors';

export default function ClientSidebar(): JSX.Element {
  const intl = useIntl();

  const client = useSelector(makeSelectClient());
  assert(client !== null);

  const { dirty } = useFormState();
  usePreventLeaving(dirty);

  const title = Person.formatName(client, intl);

  return (
    <Sidebar.Menu>
      <div className={style.container}>
        <Helmet title={`${title} - Advitam`} />
        <h2>
          <Text>
            <b>{title}</b>
          </Text>
        </h2>
      </div>
      <Text extraSmall tagName="div">
        <i>
          <FormattedMessage
            id={messages.insertedAt.id}
            values={{ date: <FormattedDate value={client.inserted_at} /> }}
          />
        </i>
      </Text>

      <Sidebar.LinksSection>
        <Sidebar.Link
          to={Path.GENERAL}
          message={<FormattedMessage id={messages.general.id} />}
        />
        <Sidebar.Link
          to={Path.DOCUMENTS}
          message={<FormattedMessage id={messages.documents.id} />}
        />
        <Sidebar.Link
          to={Path.RESOURCES}
          message={<FormattedMessage id={messages.resources.id} />}
        />
      </Sidebar.LinksSection>
    </Sidebar.Menu>
  );
}
