import { ComponentType, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorText, FormattedApiError, PageSpinner } from '@advitam/ui';

import type { Dataset } from './constants';
import {
  makeSelectAreDatasetsLoaded,
  makeSelectError,
  makeSelectIsLoading,
} from './selectors';
import { clearError } from './slice';
import { fetchDatasets } from './thunk';
import style from './dataSlice.module.scss';

export default function withDatasets<T extends JSX.IntrinsicAttributes>(
  ...datasets: Dataset[]
) {
  return (WrappedComponent: ComponentType<T>): ComponentType<T> =>
    function WithDatasetsInner(
      props: T & JSX.IntrinsicAttributes,
    ): ReactElement | null {
      const dispatch = useDispatch();

      const isLoading = useSelector(makeSelectIsLoading());
      const error = useSelector(makeSelectError());
      const areDatasetsLoaded = useSelector(
        makeSelectAreDatasetsLoaded(datasets),
      );

      useEffect(() => {
        if (!error && !isLoading && !areDatasetsLoaded) {
          dispatch(fetchDatasets(datasets));
        }
      }, [dispatch, error, isLoading, areDatasetsLoaded]);

      useEffect(
        () => () => {
          dispatch(clearError());
        },
        [dispatch],
      );

      if (areDatasetsLoaded) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <WrappedComponent {...props} />;
      }

      if (error) {
        return (
          <ErrorText center className={style.wrapper}>
            <FormattedApiError error={error} />
          </ErrorText>
        );
      }

      return (
        <div className={style.wrapper}>
          <PageSpinner />
        </div>
      );
    };
}
