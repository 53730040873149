import { useDispatch, useSelector } from 'react-redux';

import { DealDefunct } from 'models/Deal/Defunct';

import { updateDefunctCompInfo as updateDefunctCompInfoDispatch } from 'containers/Deal/DealFuneral/DefunctSection/actions';
import { makeSelectDefunctInfo } from 'containers/Deal/selectors';
import DefunctSizeInput from 'components/DefunctSize';

export default function DefunctSize(): JSX.Element {
  const dispatch = useDispatch();
  const defunctInfo = useSelector(makeSelectDefunctInfo()) as DealDefunct;

  const updateDefunctCompInfo = (updatedInfos: Partial<DealDefunct>): void => {
    dispatch(updateDefunctCompInfoDispatch(updatedInfos, defunctInfo));
  };

  return (
    <div className="todoList__defunct">
      <DefunctSizeInput
        defunctCompInfo={defunctInfo?.defunctCompInfo}
        updateDefunctCompInfo={updateDefunctCompInfo}
      />
    </div>
  );
}
