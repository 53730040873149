import { Model } from '../../Model'
import { EntityInvoiceDeal, EntityInvoiceDealJSON } from '../Deal'
import type { EntityInvoicePrestationStatus } from './Status'

interface EntityInvoicePrestationBase {
  id: number
  description: string
  status: EntityInvoicePrestationStatus
  amount: number
  cost_ht: number
  cost_ttc: number
}

export interface EntityInvoicePrestationJSON extends EntityInvoicePrestationBase {
  deal: EntityInvoiceDealJSON
  validated_at: string | null
}

export interface EntityInvoicePrestation extends EntityInvoicePrestationBase {
  deal: EntityInvoiceDeal
  validated_at: Date | null
}

export class EntityInvoicePrestation extends Model<EntityInvoicePrestationJSON> {
  constructor(data: EntityInvoicePrestationJSON) {
    super(data)
    this.deal = new EntityInvoiceDeal(data.deal)
    this.validated_at = Model.parseDate(data.validated_at)
  }
}
