import { BillingType } from '@advitam/api/models/BillingType';
import type { NewWorship } from './types';

export const WORSHIP = 'Worship';

export const Path = {
  GENERAL: '',
  MISCELLANEOUS: 'divers',
};

export const WORSHIP_SKELETON: NewWorship = {
  name: '',
  disabled: false,
  address: null,
  address_l2: null,
  postal_code: null,
  insee_code: null,
  city: null,
  department: null,
  country: null,
  latitude: null,
  longitude: null,
  manual_address: false,
  phone: null,
  email: null,
  prefered_contact_media: null,
  opening_hours: null,
  fax: null,
  phone_2: null,
  phone_3: null,
  email_2: null,
  email_3: null,
  email_4: null,
  comment: null,
  current_update_user_id: null,
  last_update_user_id: null,
  billing_type: BillingType.DEAL,
  cheque_order: null,
  cheque_address: null,
  rib: null,
  iban: null,
  vat: null,
  checkout_type: null,
  authentic_document_needed: null,
  siret: null,
  headquarter_name: null,
  headquarter_address: null,
  headquarter_postal_code: null,
  headquarter_country: null,
  headquarter_city: null,
  website: null,
  worship_type: null,
  capacity: null,
  nb_holders: null,
  price: null,
  current_update_dt: null,
  last_update_dt: null,
};
