import type { Address } from './types'

export const ADDRESS_SKELETON: Address = {
  street_name: null,
  street_number: null,
  postal_code: null,
  city: null,
  country: null,
  latitude: null,
  longitude: null,
  country_code: null,
}
