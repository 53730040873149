import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupplierCoverageJSON } from '@advitam/api/models/Supplier';
import { AutocompleteResult } from '@advitam/api/v1/Autocompletes';
import { MAP_SUPPLIERS } from './constants';
import { fetchSupplierCoverage } from './thunk';

export interface State {
  activeSuppliers: AutocompleteResult[];
  suppliersCoverageById: Record<number, SupplierCoverageJSON | null>;
}

export interface AppStateSubset {
  [MAP_SUPPLIERS]: State;
}

export const initialState: State = {
  activeSuppliers: [],
  suppliersCoverageById: {},
};

const slice = createSlice({
  name: MAP_SUPPLIERS,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    addActiveSupplier(state, { payload }: PayloadAction<AutocompleteResult>) {
      state.activeSuppliers.push(payload);
    },
    removeActiveSupplier(state, { payload }: PayloadAction<number>) {
      state.activeSuppliers = state.activeSuppliers.filter(
        supplier => supplier.id !== payload,
      );
    },
    resetActiveSuppliers(state) {
      state.activeSuppliers = initialState.activeSuppliers;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchSupplierCoverage.fulfilled,
      (state, { payload, meta: { arg: supplierId } }) => {
        state.suppliersCoverageById[supplierId] = payload;
      },
    );
  },
  /* eslint-enable no-param-reassign */
});

export const {
  addActiveSupplier,
  removeActiveSupplier,
  resetActiveSuppliers,
} = slice.actions;
export default slice;
