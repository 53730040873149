import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { DocumentTypeJSON } from '@advitam/api/models/DocumentType';
import type { SupportingDocumentFilters } from '@advitam/api/v1/SupportingDocuments/Versions';
import type { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import type { SupportingDocumentOwner } from '@advitam/api/models/SupportingDocuments/Owner';
import { assert, isEqual } from '@advitam/support';

import { CRUD_SUPPORTING_DOCUMENTS, PER_PAGE } from './constants';
import type { AppStateSubset } from './slice';
import { makeSelectFilters, makeSelectSupportingDocuments } from './selectors';
import type { SectionValues, UnsavedSupportingDocument } from './types';

/**
 * We can not lazyly fetch pages as this is the default value for the form.
 * A page load after initialization would reset the form.
 */
async function fetchAllDocuments(
  filters: SupportingDocumentFilters,
  owner: SupportingDocumentOwner,
): Promise<SupportingDocumentJSON[]> {
  const documents: SupportingDocumentJSON[] = [];
  let page = 1;
  let hasMore = true;

  while (hasMore) {
    // eslint-disable-next-line no-await-in-loop
    const { body } = await request(
      Api.V1.SupportingDocuments.Versions.index({
        ...filters,
        owner_type: owner.type,
        owner_id: owner.id,
        page,
        per_page: PER_PAGE,
      }),
    );
    documents.push(...body);
    hasMore = body.length === PER_PAGE;
    page += 1;
  }

  return documents;
}

export const fetchDocuments = createAsyncThunk(
  `${CRUD_SUPPORTING_DOCUMENTS}/FETCH`,
  async (owner: SupportingDocumentOwner, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const filters = makeSelectFilters()(state);

    try {
      return fetchAllDocuments(filters, owner);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

type ValidDocument = UnsavedSupportingDocument & {
  document_type: DocumentTypeJSON;
  file: File;
};

function isValidSupportingDocument(
  document: UnsavedSupportingDocument | ValidDocument,
): document is ValidDocument {
  return document.document_type !== null && document.file !== null;
}

async function saveDocument(
  document: SupportingDocumentJSON | UnsavedSupportingDocument,
  existingDocuments: SupportingDocumentJSON[],
  owner: SupportingDocumentOwner,
): Promise<void> {
  if (document.id === undefined) {
    assert(isValidSupportingDocument(document));
    await request(
      Api.V1.SupportingDocuments.Versions.create(
        document,
        document.file,
        owner,
      ),
    );
    return;
  }

  const existingDocument = existingDocuments.find(
    doc => doc.id === document.id,
  );
  assert(existingDocument !== null);
  if (isEqual(document, existingDocument)) {
    return;
  }

  await request(
    Api.V1.SupportingDocuments.Versions.update({
      ...document,
      uploaded_document: {
        ...document.uploaded_document,
        // Workaround for fields being completely removed from the object when erased
        reference: document.uploaded_document.reference || null,
        issue_date: document.uploaded_document.issue_date || null,
        expiry_date: document.uploaded_document.expiry_date || null,
      },
    }),
  );
}

interface SaveDocumentsPayload {
  values: SectionValues;
  owner: SupportingDocumentOwner;
}

export const saveDocuments = createAsyncThunk(
  `${CRUD_SUPPORTING_DOCUMENTS}/SAVE`,
  async (
    { values, owner }: SaveDocumentsPayload,
    { getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const existingDocuments = makeSelectSupportingDocuments()(state);
    const filters = makeSelectFilters()(state);

    try {
      await Promise.all(
        values.map(document =>
          saveDocument(document, existingDocuments, owner),
        ),
      );
      await Promise.all(
        existingDocuments.map(async document => {
          if (
            !values.some(
              value =>
                value.uploaded_document?.id === document.uploaded_document.id,
            )
          ) {
            await request(
              Api.V1.SupportingDocuments.Versions.destroy(
                document.uploaded_document.id,
              ),
            );
          }
        }),
      );

      return fetchAllDocuments(filters, owner);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
