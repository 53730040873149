import type { ApiRequestDescriptor } from '../../lib/request'
import { get, post, put, del } from '../../lib/methods'
import { authenticate, withSessionId } from '../../lib/decorators'
import type { PrefectureJSON } from '../../models/Prefecture'
import type { PrefectureBody } from './types'
import { serialize } from './serializers'
import { Disabled } from './Disabled'

export class Prefectures {
  static readonly Disabled = Disabled

  @authenticate
  static show(id: number): ApiRequestDescriptor<PrefectureJSON> {
    return get(`/api/v1/prefectures/${id}`)
  }

  @authenticate
  static create(prefecture: Omit<PrefectureBody, 'id'>): ApiRequestDescriptor<PrefectureJSON> {
    return post('/api/v1/prefectures', serialize(prefecture))
  }

  @authenticate
  @withSessionId
  static update(prefecture: PrefectureBody): ApiRequestDescriptor<PrefectureJSON> {
    return put(`/api/v1/prefectures/${prefecture.id}`, serialize(prefecture))
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<PrefectureJSON> {
    return del(`/api/v1/prefectures/${id}`)
  }
}
