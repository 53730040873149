import { useIntl } from 'react-intl';

import { Tooltip } from '@advitam/ui';
import { PaymentProblem } from 'models/Deal/PaymentProblem';
import { Summary } from 'models/Deal/Summary';
import { safeFormatMessage } from 'utils/functions.typed';

import Warning from './warning.svg';
import messages from './messages';
import style from './Risk.module.scss';

interface RiskProps {
  deal: Summary;
}

export default function Risk({ deal }: RiskProps): JSX.Element | null {
  const intl = useIntl();

  if (deal.payment_problem === PaymentProblem.NONE) {
    return null;
  }

  const className = [style.risk, style[deal.payment_problem]].join(' ');
  return (
    <Tooltip content={safeFormatMessage(intl, messages, deal.payment_problem)}>
      <Warning className={className} />
    </Tooltip>
  );
}
