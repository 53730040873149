import { createSelector, Selector } from 'reselect';

import { MEMORIAL_SPACE_SETTINGS } from './constants';
import { State, AppStateSubset } from './slice';

type MemorialPageSelector<T> = Selector<AppStateSubset, T>;

export const selectMemorialsSpaceSettingsDomain = (
  state: AppStateSubset,
): State => state && state[MEMORIAL_SPACE_SETTINGS];

export const makeSelectIsProfilePictureLoading = (): MemorialPageSelector<
  boolean
> =>
  createSelector(
    selectMemorialsSpaceSettingsDomain,
    ({ isProfilePictureLoading }) => isProfilePictureLoading,
  );
