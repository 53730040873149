/*
 * DealFuneral Messages
 *
 * This contains all the text for the DealFuneral component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.DealFuneral.header',
    defaultMessage: 'This is DealFuneral container !',
  },
  // Clients Section Messages
  clientsSection: {
    id: 'app.containers.DealFuneral.clientsSection',
    defaultMessage: 'Clients',
  },
  addClient: {
    id: 'app.containers.DealFuneral.addClient',
    defaultMessage: 'Add a client',
  },
  clientCollapse: {
    id: 'app.containers.DealFuneral.clientCollapse',
    defaultMessage: 'Client : {name}',
  },
  // Steps Section Messages
  stepsSection: {
    id: 'app.containers.DealFuneral.stepsSection',
    defaultMessage: 'Steps',
  },
  confirmDeleteStep: {
    id: 'app.containers.DealFuneral.confirmDeleteStep',
    defaultMessage: 'Do you really want to delete this step ?',
  },
  warningTribunalAuthorizationDate: {
    id: 'app.containers.DealFuneral.warningTribunalAuthorizationDate',
    defaultMessage:
      "Vous êtes sur le point de supprimer la date de levée d'obstacle",
  },
  // Products Section Messages
  productsSection: {
    id: 'app.containers.DealFuneral.productsSection',
    defaultMessage: 'Products',
  },
  // Services Section Messages
  servicesSection: {
    id: 'app.containers.DealFuneral.servicesSection',
    defaultMessage: 'Services',
  },
  // Generic messages
  remove: {
    id: 'app.containers.DealFuneral.remove',
    defaultMessage: 'Remove',
  },
  yes: {
    id: 'app.containers.DealFuneral.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'app.containers.DealFuneral.no',
    defaultMessage: 'No',
  },
  // Steps skeletonButton
  interment: {
    id: 'app.containers.Steps.interment',
    defaultMessage: 'Interment skeleton creation',
  },
  cremation: {
    id: 'app.containers.Steps.cremation',
    defaultMessage: 'Cremation skeleton creation',
  },
  repatriation: {
    id: 'app.containers.Steps.repatriation',
    defaultMessage: 'Repatriation skeleton creation',
  },
  casketing: {
    id: 'app.containers.Steps.casketing',
    defaultMessage: 'Delegated casketing skeleton creation',
  },
  custom: {
    id: 'app.containers.Steps.custom',
    defaultMessage: 'Custom skeleton creation',
  },
});
