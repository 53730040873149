import type { FlightJSON } from '@advitam/api/models/Flight';

export const FLIGHTS_CREATE_MODAL = 'Flights/CreateModal';

// @note this skeleton must be valid enough to not crash the Flight container
export const FLIGHT_SKELETON = {
  departure_airport: {},
  stopover_airport: {},
  arrival_airport: {},
} as FlightJSON;
