import { Control } from './types'

export const LANGUAGES_ROOT_URL = `${process.env.APP_PATH_PREFIX || ''}/assets/tinymce/langs/`
export const SKIN_URL = `${process.env.APP_PATH_PREFIX || ''}/assets/tinymce/skins/ui/oxide`
export const MODEL_URL = `${
  process.env.APP_PATH_PREFIX || ''
}/assets/tinymce/models/dom/model.min.js`

const ASSETS_URL = `${process.env.APP_PATH_PREFIX || ''}/assets`
const PLUGINS_BASE_URL = `${ASSETS_URL}/tinymce/plugins`

export const CONTROL_PLUGIN_PATHS: Record<Control, string> = {
  [Control.LINK]: `${PLUGINS_BASE_URL}/link/plugin.min.js`,
  [Control.LISTS]: `${PLUGINS_BASE_URL}/lists/plugin.min.js`,
  [Control.AI]: `${ASSETS_URL}/tinymce-ai-plugin/ai.js`,
}

export const CONTROL_NAMES: Record<Control, string> = {
  [Control.LINK]: 'link',
  [Control.LISTS]: 'numlist bullist',
  [Control.AI]: 'ai',
}

export const BASE_CONTROLS = [
  'undo redo',
  'bold italic underline',
  'alignleft aligncenter alignright alignjustify',
].join(' | ')
