import { StandardDefunctJSON } from 'models/Defunct';

import { ClientResource, ClientResourceJSON, ClientResourceBase } from '.';

export interface ClientResourceAdministrativeProcedureBase
  extends ClientResourceBase {
  defunct: StandardDefunctJSON;
}

export interface ClientResourceAdministrativeProcedureJSON
  extends ClientResourceJSON,
    ClientResourceAdministrativeProcedureBase {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientResourceAdministrativeProcedure
  extends ClientResourceAdministrativeProcedureBase {}
export class ClientResourceAdministrativeProcedure extends ClientResource<
  ClientResourceAdministrativeProcedureJSON
> {}
