import { BackgroundValue } from './constants'
import { BackgroundStyleProps, getClassList } from './Full'
import Round from './round-reverse.svg'
import style from './Background.module.scss'

interface OneQuarterProps extends BackgroundStyleProps {
  color2: BackgroundValue
}

export default function OneQuarter({ color, color2, textured }: OneQuarterProps): JSX.Element {
  const classes = [style.background, style[`flat__${color}`]]
  const topClasses = [style.one_quarter__top, style.rounded].concat(
    getClassList({ color: color2, textured }),
  )

  return (
    <div className={classes.join(' ')}>
      <div className={topClasses.join(' ')}>
        <Round className={style[`round__${color}`]} />
      </div>
    </div>
  )
}
