import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { CityhallJSON } from '@advitam/api/models/Cityhall';

import { assert } from 'lib/support';

import { CITYHALL } from './constants';
import type { AppStateSubset } from './slice';
import { makeSelectRawCityhall } from './selectors';

export const fetchCityhall = createAsyncThunk(
  `${CITYHALL}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Cityhalls.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCityhall = createAsyncThunk(
  `${CITYHALL}_UPDATE`,
  async (cityhall: CityhallJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Cityhalls.update(cityhall));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCityhallName = createAsyncThunk(
  `${CITYHALL}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const cityhall = makeSelectRawCityhall()(state);
    assert(cityhall?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Cityhalls.update({ id: cityhall.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
