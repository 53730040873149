import ChevronRight from '../../images/icons/chevron-right.svg'
import Link from '../Link'
import Text from '../Text'
import { BreadcrumbItem } from './models'
import style from './Breadcrumbs.module.scss'

interface BreadcrumbsProps {
  items: BreadcrumbItem[]
}

export default function Breadcrumbs({ items }: BreadcrumbsProps): JSX.Element {
  return (
    <ol vocab="http://schema.org/" typeof="BreadcrumbList" className={style.breadcrumbs}>
      {items.map((item, i) => (
        <li key={item.href} property="itemListElement" typeof="ListItem">
          <meta property="position" content={(i + 1).toString()} />
          <Link internal href={item.href} property="item" typeof="WebPage">
            <Text property="name">{item.text}</Text>
            {i < items.length - 1 && <ChevronRight />}
          </Link>
        </li>
      ))}
    </ol>
  )
}
