import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import { IndexEntity, IndexEntityJSON } from '@advitam/api/models/Entity/Index';

import { ENTITIES } from './constants';
import type { AppStateSubset, State } from './slice';
import type { Filters } from './types';

type EntitiesSelector<T> = Selector<AppStateSubset, T>;

const selectEntitiesDomain = (state: AppStateSubset): State => state[ENTITIES];

const makeSelectRawEntities = (): EntitiesSelector<IndexEntityJSON[]> =>
  createSelector(selectEntitiesDomain, state => state.entities);

export const makeSelectEntities = (): EntitiesSelector<IndexEntity[]> =>
  createSelector(makeSelectRawEntities(), entities =>
    entities.map(entity => new IndexEntity(entity)),
  );

export const makeSelectFilters = (): EntitiesSelector<Filters> =>
  createSelector(selectEntitiesDomain, state => state.filters);

export const makeSelectPage = (): EntitiesSelector<number> =>
  createSelector(selectEntitiesDomain, state => state.page);

export const makeSelectHasMore = (): EntitiesSelector<boolean> =>
  createSelector(selectEntitiesDomain, state => state.hasMore);

export const makeSelectIsLoading = (): EntitiesSelector<boolean> =>
  createSelector(selectEntitiesDomain, state => state.isLoading);

export const makeSelectError = (): EntitiesSelector<SerializedApiError | null> =>
  createSelector(selectEntitiesDomain, state => state.error);

export const makeSelectIsCreationModalOpen = (): EntitiesSelector<boolean> =>
  createSelector(selectEntitiesDomain, state => state.isCreationModalOpen);
