import ImageEditor from '../ImageEditor';
import Page, { connected } from '.';

interface ImagePageProps {
  src: string;
  isEditing: boolean;
  onImageSave: (blob: Blob | null) => void;
}

class ImagePage extends Page<ImagePageProps> {
  renderBase(): JSX.Element | null {
    const { src, isEditing, onImageSave } = this.props;

    if (!isEditing) {
      return <img src={src} alt="" />;
    }
    return <ImageEditor src={src} onSave={onImageSave} />;
  }
}

export default connected(ImagePage);
