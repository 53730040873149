import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import { BatchLog as BatchLogModel } from '@advitam/api/models/Deal/Document/Batch/Log';

import { makeSelectFuneralTeam } from 'slices/data';
import { getFuneralMember, safeTranslation } from 'utils/functions.js';
import messagesState from 'messages/state.js';

import messages from '../messages';
import ProcedureState from './ProcedureState';

interface BatchLogProps {
  log: BatchLogModel;
}

export default function BatchLog({ log }: BatchLogProps): JSX.Element {
  const intl = useIntl();
  const funeralTeam = useSelector(makeSelectFuneralTeam());

  const funeralMember = getFuneralMember(funeralTeam, log.user_id);
  const translatedState = safeTranslation(messagesState, log.state, intl);
  const lastTryDateTime =
    log.last_try_at && DateTime.fromJSDate(log.last_try_at);
  const formattedLastTry = lastTryDateTime?.toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS,
  );

  return (
    <div className="history__log" key={log.id}>
      <div
        className={`history__log__header history__log__header--${log.state}`}
      >
        <div>
          <span>{`${log.batch.name || log.subject} - ${translatedState}`}</span>
          <ProcedureState log={log} />
        </div>
        <div className="history__log__header__time">{formattedLastTry}</div>
      </div>
      <div className="space--10 history__log__body">
        <FormattedMessage
          id={messages.subject.id}
          values={{ value: log.subject }}
          tagName="span"
        />
        <FormattedMessage
          id={messages.recipients.id}
          values={{
            sentBy: log.sent_by,
            sentTo: log.sent_to,
            from: funeralMember?.email,
          }}
          tagName="span"
        />
      </div>
      {log.comment && (
        <div className="space--10 history__log__body">
          <FormattedMessage id={messages.comment.id} tagName="h4" />
          {log.comment}
        </div>
      )}
    </div>
  );
}
