import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import { AutocompleteResult } from '@advitam/api/v1/Autocompletes';
import { FormUI } from '@advitam/ui';

import actionsMessages from 'messages/actions';

import messages from '../../messages';
import { setActiveSupplier } from '../../slice';
import ActiveTag from '../parts/ActiveTag';
import style from '../Content.module.scss';
import { makeSelectActiveSuppliers } from './selectors';
import { removeActiveSupplier } from './slice';
import { fetchCoverage } from './thunk';
import { getSupplierColor } from './utils';

export default function SuppliersAutocomplete(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const activeSuppliers = useSelector(makeSelectActiveSuppliers());

  const onChange = (result?: AutocompleteResult): void => {
    if (!result) {
      return;
    }

    dispatch(fetchCoverage(result));
  };

  const deactivateCoverage = (id: number): void => {
    dispatch(removeActiveSupplier(id));
    dispatch(setActiveSupplier(null));
  };

  return (
    <div>
      <FormUI.Autosuggest
        // permits autocomplete reset on change
        value={{ id: -1, description: '' }}
        label={<FormattedMessage id={messages.suppliers.id} />}
        placeholder={intl.formatMessage(actionsMessages.search)}
        onChange={onChange}
        endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.suppliers)}
        requestHeaders={{ ...getTokens() }}
        className={style.autocomplete}
      />
      <div>
        {activeSuppliers.map(supplier => (
          <ActiveTag
            key={supplier.id}
            backgroundColor={getSupplierColor(supplier.id)}
            onClick={(): void => deactivateCoverage(supplier.id)}
          >
            {supplier.description}
          </ActiveTag>
        ))}
      </div>
    </div>
  );
}
