import { ReactNode, useCallback } from 'react'

import ApiAutocomplete from '../ApiAutocomplete'
import type { ApiCountry, CountryAutocompleteResult } from './types'

interface CountryAutocompleteProps {
  placeholder?: string
  required?: boolean
  label?: ReactNode
  tooltip?: ReactNode
  name: string
  endpoint: string
  className?: string
  tokens: Record<string, string>
  searchParams?: Record<string, string>
  disabled?: boolean
}

export default function CountryAutocomplete({
  placeholder,
  name,
  label,
  tooltip,
  endpoint,
  required,
  tokens,
  searchParams,
  disabled,
  className,
}: CountryAutocompleteProps): JSX.Element {
  const getDisplayValue = useCallback(
    (result: CountryAutocompleteResult | ApiCountry): string =>
      `${result.name} (${
        (result as CountryAutocompleteResult).country_code || (result as ApiCountry).code
      })`,
    [],
  )

  return (
    <ApiAutocomplete<CountryAutocompleteResult>
      placeholder={placeholder}
      name={name}
      label={label}
      tooltip={tooltip}
      endpoint={endpoint}
      required={required}
      className={className}
      disabled={disabled}
      getDisplayValue={getDisplayValue}
      keys={['country_code', 'name']}
      transformKeys={{ country_code: 'code' }}
      requestHeaders={tokens}
      searchParams={searchParams}
      minCharsRequest={2}
    />
  )
}
