import { withSessionId } from '@advitam/api';

import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { post, put } from 'api/methods';
import { Deals } from 'api/serializers/Deals';

import { assert } from 'lib/Assert';
import { Deal, DealJSON } from 'models/Deal';

export default class Items {
  @authenticate
  static create(deal: Deal | DealJSON): ApiRequestDescriptor<DealJSON> {
    return post('/api/v1/deals/items', Deals.Item.serialize(deal));
  }

  @authenticate
  @withSessionId
  static update(deal: Deal | DealJSON): ApiRequestDescriptor<DealJSON> {
    assert(deal.id !== undefined);
    return put(`/api/v1/deals/items/${deal.id}`, Deals.Item.serialize(deal));
  }

  static updateOrCreate(deal: Deal | DealJSON): ApiRequestDescriptor<DealJSON> {
    if (deal.uuid) {
      return Items.update(deal);
    }
    return Items.create(deal);
  }
}
