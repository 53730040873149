import { FormattedMessage } from 'react-intl';

import { ResourceList } from '@advitam/ui';

import messages from '../../messages';

export default function Header(): JSX.Element {
  return (
    <ResourceList.Header>
      <ResourceList.Cell>
        <FormattedMessage id={messages.maxWeight.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.price.id} />
      </ResourceList.Cell>
      <ResourceList.Cell />
      <ResourceList.Cell>
        <FormattedMessage id={messages.actions.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
