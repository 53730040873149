import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Layout } from 'containers/Crud';

import { saveCrematorium } from '../../thunk';
import Wrapper from '../Wrapper';
import { CrematoriumForm } from '../types';
import Functioning from './Functioning';
import Billing from './Billing';
import ReducedPricesCityhalls from './ReducedPriceCityhalls';

export default function Misc(): JSX.Element {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: CrematoriumForm): void => {
      dispatch(saveCrematorium(values.crematorium));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <Layout.ColumnGroup>
          <Layout.Column>
            <Functioning />
            <ReducedPricesCityhalls />
          </Layout.Column>
          <Layout.Column>
            <Billing />
          </Layout.Column>
        </Layout.ColumnGroup>
      </Layout.Container>
    </Wrapper>
  );
}
