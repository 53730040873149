import { useState, MouseEvent, ReactNode } from 'react'

import ChevronDown from '../../../../images/icons/chevron-down.svg'
import Popup from '../../../Popup'
import TransparentButton from '../../../TransparentButton'

import uiStyle from '../UI.module.scss'
import style from './Dropdown.module.scss'

interface DropdownProps {
  children: ReactNode | ReactNode[]
  placeholder: ReactNode
  contained?: boolean
  openOnHover?: boolean
  closeOnClick?: boolean
  disabled?: boolean
  expandOnTop?: boolean
  className?: string
}

export default function Dropdown({
  children,
  placeholder,
  contained = true,
  openOnHover = false,
  closeOnClick = true,
  disabled,
  expandOnTop = false,
  className,
}: DropdownProps): JSX.Element {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const toggleDropDown = (event: MouseEvent<HTMLElement>): void => {
    if (!disabled) {
      setDropDownOpen(!dropDownOpen)

      // The click event is useless for upper elements. However, stopping the
      // event automatically prevents them to take an action, when we are
      // embedded in a link for example.
      event.stopPropagation()
      event.preventDefault()
    }
  }

  const [isTitleHovered, setTitleHovered] = useState(false)
  const [areChildrenHovered, setChildrenHovered] = useState(false)
  const shallShowChildren = openOnHover ? areChildrenHovered || isTitleHovered : dropDownOpen

  const onClick = (event: MouseEvent): void => {
    if (dropDownOpen && closeOnClick) {
      setDropDownOpen(false)
    }
    event.stopPropagation()
  }

  const inputClasses = [uiStyle.input, style.input, dropDownOpen && style.open, className].filter(
    Boolean,
  )

  const childrenClasses = [
    style.children_wrapper,
    contained && style.contained,
    expandOnTop && style.top_direction,
  ].filter(Boolean)

  const chevronClasses = [style.chevron, expandOnTop && style.reversed].filter(Boolean)

  return (
    <Popup
      onFocusOut={(): void => setDropDownOpen(false)}
      onClick={onClick}
      onMouseEnter={(): void => setTitleHovered(true)}
      onMouseLeave={(): void => setTitleHovered(false)}
    >
      <TransparentButton className={inputClasses.join(' ')} onClick={toggleDropDown}>
        <div className={style.placeholder}>{placeholder}</div>
        <ChevronDown className={chevronClasses.join(' ')} />
      </TransparentButton>
      <div className={style.dropdown}>
        {shallShowChildren && (
          <div
            className={childrenClasses.join(' ')}
            onMouseEnter={(): void => setChildrenHovered(true)}
            onMouseLeave={(): void => setChildrenHovered(false)}
          >
            {children}
          </div>
        )}
      </div>
    </Popup>
  )
}
