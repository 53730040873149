import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import type { NewUploadResource } from 'containers/DocumentsEditor/types';
import type { DealDocument } from 'models/Deal/Document';
import { safeTranslation } from 'utils/functions';

import { makeSelectDocumentList } from '../selectors';
import SectionDocument from './SectionDocument';
import messages from './messages';

type UploadFiles = (
  files: File[],
  resource: NewUploadResource,
) => void | Promise<void>;

interface SectionProps {
  section: string;
  uploadFiles: UploadFiles;
}

export default function Section({
  section,
  uploadFiles,
}: SectionProps): JSX.Element | null {
  const intl = useIntl();
  const documents = useSelector(makeSelectDocumentList()) as Record<
    string,
    DealDocument[]
  >;

  if (documents[section].length === 0) {
    return null;
  }

  return (
    <div>
      <h2 className="margin--10-0">
        {safeTranslation(messages, section, intl)}
      </h2>
      <div className="flex">
        {documents[section].map(doc => (
          <SectionDocument key={doc.id} doc={doc} uploadFiles={uploadFiles} />
        ))}
      </div>
    </div>
  );
}
