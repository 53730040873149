import { FormattedMessage } from 'react-intl';

import { DealPrestationComputeType } from 'models/Deal/Prestation/ComputeType';
import { NumberInput } from 'components/ReactFormComponent';

import { MIN_DAYS } from '../constants';
import Actions from './Actions';
import messages from './messages';
import style from './style.scss';

interface DaysProps {
  onReset: (compute: DealPrestationComputeType) => void;
}

export default function Days({ onReset }: DaysProps): JSX.Element {
  return (
    <>
      <div className={style.row}>
        <FormattedMessage id={messages.daysText.id} />
        <NumberInput field="funeralWorks.days" min={MIN_DAYS} />
      </div>
      <Actions onReset={(): void => onReset(DealPrestationComputeType.AUTO)} />
    </>
  );
}
