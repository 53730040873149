import { Objects } from '@advitam/support'
import type { EntityInvoiceJSON } from '../../models/EntityInvoice'

interface PrestationsUpdatePayload {
  id: number
  cost_ht: number
  cost_ttc: number
  description: string
  deal_id: number
}

interface UpdatePayload {
  group_name: string
  bank_transfer_label: string | null
  cost_tax_excl: number | null
  cost_tax_incl: number | null
  prestations: PrestationsUpdatePayload[]
}

export function serializeUpdate(invoice: EntityInvoiceJSON): UpdatePayload {
  return {
    ...Objects.pick(invoice, 'bank_transfer_label', 'cost_tax_excl', 'cost_tax_incl'),
    group_name: invoice.group.name,
    prestations: invoice.prestations.map(prestation => ({
      ...Objects.pick(prestation, 'id', 'cost_ht', 'cost_ttc', 'description'),
      deal_id: prestation.deal.id,
    })),
  }
}
