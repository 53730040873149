import { Component } from 'react';
import PropTypes from 'prop-types';
import CloseCross from 'components/CloseCross';
import Modal from 'react-modal';
import { safeTranslation, snakeCaseToCamelCase } from 'utils/functions';
import { injectIntl, FormattedMessage } from 'react-intl';
import apiMessages from 'api/messages.ts';
import H2 from 'components/H2';
import messagesCrud from 'messages/crud';

import messagesError from './messages.ts';

/**
 * ErrorModal
 *
 * Display a Modal showing errors
 */
class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.error && true,
    };
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { isOpen } = this.state;
    if (error && !isOpen && error !== prevProps.error) {
      this.openModal();
    }
  }

  /**
   * Open modal
   */
  openModal = () => this.setState({ isOpen: true });

  /**
   * Close modal
   */
  closeModal = () => this.setState({ isOpen: false });

  /**
   * Get message object by Id
   *
   * @param   {String} id id of message object
   * @returns {Object} return object containing the message
   */
  getMessageObject = id => {
    const { messages } = this.props;
    if (messages && messages[id]) return messages;
    if (messagesCrud[id]) return messagesCrud;
    return messagesError;
  };

  /**
   * Render error message
   *
   * @param {Object} error error to display
   */
  renderMessage = error => {
    const { intl } = this.props;
    if (!error || !error.vars) return null;

    if (!apiMessages[error.message]) {
      return (
        <div>
          <p>{error.message}</p>
          <p>{error.vars[0]}</p>
        </div>
      );
    }

    // convert api (snake case) to react camelcase
    const valueErrorMessage = error.vars[0]
      ? snakeCaseToCamelCase(error.vars[0])
      : '';
    const messagesForValue = this.getMessageObject(valueErrorMessage);
    return (
      <FormattedMessage
        id={apiMessages[error.message].id}
        values={{
          value: (
            <b>{safeTranslation(messagesForValue, valueErrorMessage, intl)}</b>
          ),
        }}
        key={`${error.message}_${error.vars[0]}`}
        tagName="span"
      />
    );
  };

  /**
   * Check if there is an error
   *
   * @returns {Boolean} is there an error
   */
  checkError = () => {
    const { error } = this.props;
    return (
      error &&
      error.response &&
      error.response.errors &&
      error.response.errors.length > 0
    );
  };

  render() {
    const { error, intl } = this.props;
    const { isOpen } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        contentLabel="dialog"
        ariaHideApp={false}
        className="modal error"
        overlayClassName="modal__overlay error__overlay"
      >
        <div className="error__container">
          <H2 dark>
            <FormattedMessage id={messagesError.header.id} />
          </H2>
          {this.checkError() ? (
            <div className="error__container__errors">
              {(error.response.errors || []).map(item =>
                this.renderMessage(item),
              )}
            </div>
          ) : (
            <b>{intl.formatMessage({ ...apiMessages.errorNotKnow })}</b>
          )}
          <CloseCross handleClose={this.closeModal} />
        </div>
      </Modal>
    );
  }
}

ErrorModal.propTypes = {
  /** error object from api */
  error: PropTypes.object,
  intl: PropTypes.object.isRequired,
  /** messages object */
  messages: PropTypes.object,
};

export default injectIntl(ErrorModal);
