import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AutoComplete from 'containers/AutoComplete';
import DialogBox from 'containers/DialogBox';
import messagesAction from 'messages/actions';
import {
  SKELETON_PRESTATION,
  PRESTATION_STATUS_NONE,
  PRESTATION_COMPUTE_MANUAL,
} from 'components/PrestationDialog/constants';

import messages from './messages';

class ServicesDialogAutoComplete extends PureComponent {
  /**
   * add a service from autocomplete
   * @param {object} item item data
   */
  onSelect = item => {
    const { addService, toggleDialog } = this.props;
    const service = {
      ...SKELETON_PRESTATION,
      compute: PRESTATION_COMPUTE_MANUAL,
      type: item.type,
      status: PRESTATION_STATUS_NONE,
      priceLine: {
        ...SKELETON_PRESTATION.priceLine,
        description: item.description,
      },
    };
    addService(service);
    toggleDialog();
  };

  render() {
    const { isDialogOpen, toggleDialog, services } = this.props;
    return (
      <DialogBox
        isOpen={isDialogOpen}
        headerMessage={messages.addService}
        options={[
          { msg: messagesAction.cancel, cb: toggleDialog },
          {
            msg: messagesAction.ok,
            cb: toggleDialog,
          },
        ]}
        onRequestClose={toggleDialog}
        className="addPrestationsDialog"
      >
        <>
          <div className="addPrestationsDialog__addPrestation">
            <AutoComplete
              url="/api/v1/autocompletes/funeral_work_types?q="
              getKey={item => item.id}
              getValue={item => item.description}
              onSelect={(_, item) => this.onSelect(item)}
              fullWidth
              auth
            />
          </div>
          <div className="addPrestationsDialog__servicesContainer">
            {services.map((s, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="addPrestationsDialog__servicesContainer__service"
              >
                <h4>
                  {s.priceLine.manual_description || s.priceLine.description}
                </h4>
              </div>
            ))}
          </div>
        </>
      </DialogBox>
    );
  }
}

ServicesDialogAutoComplete.propTypes = {
  /** Function to add an service */
  addService: PropTypes.func.isRequired,
  /** list of services */
  services: PropTypes.array.isRequired,
  /** function to open or close dialog */
  toggleDialog: PropTypes.func.isRequired,
  /** is the dialog open */
  isDialogOpen: PropTypes.bool.isRequired,
};

export default ServicesDialogAutoComplete;
