// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound-module__container--hNPTZ{display:flex;align-items:center;justify-content:center;flex:1;margin:0 8px;max-height:calc(100% - 56px)}.NotFound-module__image_wrapper--_w2t_{max-height:100%;display:flex;flex-direction:column}.NotFound-module__image_wrapper--_w2t_ img{width:100%;height:100%;object-fit:contain}`, "",{"version":3,"sources":["webpack://./src/containers/NotFoundPage/NotFound.module.scss"],"names":[],"mappings":"AAEA,mCACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,MAAA,CACA,YAAA,CACA,4BAAA,CAGF,uCACE,eAAA,CACA,YAAA,CACA,qBAAA,CAEA,2CACE,UAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":["@import '~@advitam/ui/scss/media';\n\n.container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n  margin: 0 8px;\n  max-height: calc(100% - 56px);\n}\n\n.image_wrapper {\n  max-height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"container": `NotFound-module__container--hNPTZ`,
	"image_wrapper": `NotFound-module__image_wrapper--_w2t_`
};
export default ___CSS_LOADER_EXPORT___;
