import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SupportingDocumentOwnerType } from '@advitam/api/models/SupportingDocuments/OwnerType';
import { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import Layout from 'containers/Crud/Layout';
import {
  SupportingDocuments,
  supportingDocumentsSlice,
  makeSelectSupportingDocuments,
  saveSupportingDocuments,
} from 'containers/Crud';

import Wrapper from './Wrapper';
import { makeSelectRawCityhall } from '../selectors';
import { CityhallForm } from './types';

function SupportingDocumentsSection(): JSX.Element {
  const dispatch = useDispatch();

  const cityhall = useSelector(makeSelectRawCityhall());
  assert(cityhall?.id !== undefined);
  const documents = useSelector(makeSelectSupportingDocuments());

  const owner = useMemo(
    () => ({ type: SupportingDocumentOwnerType.CITYHALL, id: cityhall.id }),
    [cityhall.id],
  );

  const onSubmit = useCallback(
    ({ sectionValues }: CityhallForm<SupportingDocumentJSON[]>) => {
      dispatch(saveSupportingDocuments({ values: sectionValues, owner }));
    },
    [dispatch, owner],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={documents}>
      <Layout.Container>
        <SupportingDocuments owner={owner} />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(supportingDocumentsSlice)(SupportingDocumentsSection);
