import { ReactNode, useCallback, useState } from 'react'
import { SwipeableProps, useSwipeable } from 'react-swipeable'

import TransparentButton from '../TransparentButton'
import Arrow from './arrow.svg'
import style from './Sidebar.module.scss'

const SWIPE_OPTIONS: SwipeableProps = {
  preventScrollOnSwipe: true,
}

interface SideBarProps {
  children: ReactNode | ReactNode[]
  className?: string
}

export default function Sidebar({ children, className }: SideBarProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const sidebarClasses = [style.sidebar, !isOpen && style.closed, className].filter(Boolean)

  const onSwipedRight = useCallback((): void => {
    setIsOpen(true)
  }, [setIsOpen])

  const onSwipedLeft = useCallback((): void => {
    setIsOpen(false)
  }, [setIsOpen])

  const toggle = useCallback((): void => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  const backdropHandler = useSwipeable({ ...SWIPE_OPTIONS, onSwipedLeft })
  const menuHandler = useSwipeable({ ...SWIPE_OPTIONS, onSwipedLeft })
  const openerHandler = useSwipeable({ ...SWIPE_OPTIONS, onSwipedRight })

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      <div {...openerHandler} className={style.swiper} />
      <div {...backdropHandler}>
        <TransparentButton
          onClick={onSwipedLeft}
          className={[style.backdrop, !isOpen && style.closed].filter(Boolean).join(' ')}
        />
      </div>
      <aside {...menuHandler} className={sidebarClasses.join(' ')}>
        <div className={`thin-scrollbar ${style.content}`}>{children}</div>
        <button
          type="button"
          onClick={toggle}
          className={[style.opener, isOpen && style.opened].filter(Boolean).join(' ')}
        >
          <Arrow />
        </button>
      </aside>
    </>
    /* eslint-enable react/jsx-props-no-spreading */
  )
}
