import { Model } from 'models/Model';

interface DealDocumentVersionBase {
  id: number | null;
  file: string | null;
  miniature: string | null;
}

export interface DealDocumentVersionJSON extends DealDocumentVersionBase {
  created_at: string | null;
}

export interface DealDocumentVersion extends DealDocumentVersionBase {
  created_at: Date | null;
}

export class DealDocumentVersion extends Model<DealDocumentVersionJSON> {
  constructor(data: DealDocumentVersionJSON) {
    super(data);
    this.created_at = Model.parseDate(data.created_at);
  }
}
