import Documents from './Documents';
import Recipients from './Recipients';
import style from './Autocompletes.module.scss';

export default function SendBatchModalAutocompletes(): JSX.Element {
  return (
    <div className={style.container}>
      <Recipients />
      <Documents />
    </div>
  );
}
