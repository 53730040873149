import { defineMessages } from 'react-intl';

export default defineMessages({
  actionBooking: {
    id:
      'app.messages.DealTypeWrapper.BookingSection.SuppliersStatus.actionBooking',
    defaultMessage: 'Réserver',
  },
  actionPreBooking: {
    id:
      'app.messages.DealTypeWrapper.BookingSection.SuppliersStatus.actionPreBooking',
    defaultMessage: 'Pré-Réserver',
  },
  booking: {
    id: 'app.messages.DealTypeWrapper.BookingSection.SuppliersStatus.booking',
    defaultMessage: 'Réservation',
  },
  preBooking: {
    id:
      'app.messages.DealTypeWrapper.BookingSection.SuppliersStatus.preBooking',
    defaultMessage: 'Pré-réservation',
  },
  comment: {
    id: 'app.messages.DealTypeWrapper.BookingSection.SuppliersStatus.comment',
    defaultMessage: 'Commentaire',
  },
  supplier: {
    id: 'app.messages.DealTypeWrapper.BookingSection.SuppliersStatus.supplier',
    defaultMessage: 'Partenaire',
  },
});
