import { ChangeEventHandler, useState } from 'react'
import StarIcon from '../../../../images/icons/star.svg'
import style from './Rating.module.scss'

interface RadioProps {
  value: number
  name: string
  checked: boolean
  filled: boolean
  error?: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  onChange: ChangeEventHandler
}

export default function Radio({
  value,
  name,
  checked,
  filled,
  error = false,
  onMouseEnter,
  onMouseLeave,
  onChange,
}: RadioProps): JSX.Element {
  const [isAnimating, setIsAnimating] = useState(false)
  const starClasses = [
    style.star,
    filled && style.filled,
    error && style.error,
    isAnimating && style.animate,
  ].filter(Boolean)

  return (
    <label className={style.radio}>
      <StarIcon
        className={starClasses.join(' ')}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={(): void => setIsAnimating(true)}
        onAnimationEnd={(): void => setIsAnimating(false)}
      />
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={style.input}
      />
    </label>
  )
}
