import { authenticate } from '../../../../lib/decorators'
import { ApiRequestDescriptor } from '../../../../lib/request'
import { post } from '../../../../lib/methods'

export class Files {
  // The answer format is quite hard to handle, and not model-friendly.
  // It is better to fetch the document/batch index waiting for a fix api-side.
  @authenticate
  static update(
    dealUuid: string,
    documentId: number,
    ressource_uuid: string,
  ): ApiRequestDescriptor<unknown> {
    return post(`/api/v1/deals/${dealUuid}/documents/upload/files`, {
      documents: [
        {
          id: documentId,
          ressource_uuid,
        },
      ],
    })
  }
}
