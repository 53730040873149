import { createAction } from 'lib/reactvitam/action_cable';

interface Document {
  id: number;
  name: string;
}

export interface DocumentsDisabledPayload {
  documents: Document[];
}

function documentsDisabled(documents: Document[]): DocumentsDisabledPayload {
  return { documents };
}

export default createAction(documentsDisabled, 'documents_disabled');
