import { authenticate } from '../../../lib/decorators'
import { post } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'

export const BankTransfers = {
  create: authenticate(
    (): ApiRequestDescriptor<string> =>
      post('/api/v1/entity_invoices/pending_payments/bank_transfers'),
  ),
}
