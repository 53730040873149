export const enum TodoType {
  SEND_BATCH = 'send-batch',
  MANUAL = 'manual',
  REQUEST_DOCUMENT = 'request-document',
  INFO_DEFUNCT = 'info-defunct',
  INFO_CEREMONY = 'info-ceremony',
  BOOK_SUPPLIERS = 'book-suppliers',
  PRE_BOOK_SUPPLIERS = 'prebook-suppliers',
  INFO_MARBLE_WORK = 'info-marble-work',
  BOOK_PRE_CASKETING = 'book-pre-casketing-suppliers',
  ACCOUNT_CREATION = 'account-creation',
  FIND_DEFUNCT_IN_CLIENTS = 'find-defunct-in-clients',
}
