import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.DealFuneral.MemorialSection.Customization.title',
  },
  message: {
    id: 'app.containers.DealFuneral.MemorialSection.Customization.message',
  },
});
