import type { GraveyardConcessionJSON } from '@advitam/api/models/Graveyard/Concession';
import { isNil } from '@advitam/support';

import type { ConcessionFilters, UnsavedConcession } from './types';

export function isFiltered(
  concession: GraveyardConcessionJSON | UnsavedConcession,
  filters: ConcessionFilters,
): boolean {
  return Object.entries(filters).some(
    ([key, filter]) =>
      !isNil(filter) && concession[key as keyof ConcessionFilters] !== filter,
  );
}
