import { Objects, assert } from '@advitam/support'

import type { PaymentJSON } from '../../models/Payment'
import type { PaymentPayload } from './types'

export default function serialize(payment: PaymentJSON): PaymentPayload {
  assert(payment.country.code !== undefined)

  return {
    ...Objects.omit(payment, 'country'),
    country_code: payment.country.code,
  }
}
