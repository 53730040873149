import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from '@advitam/support';

import { PAYMENTS_SPACE_REFUND_MODAL } from './constants';
import { AppStateSubset } from './slice';
import { makeSelectPaymentToRefund } from './selectors';

export const refundPayment = createAsyncThunk(
  `${PAYMENTS_SPACE_REFUND_MODAL}/REFUND`,
  async (amount: number, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const paymentToRefund = makeSelectPaymentToRefund()(state);
    assert(paymentToRefund?.uuid !== undefined);

    try {
      const { body } = await request(
        Api.V1.Payments.Refund.create(paymentToRefund.uuid, amount),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
