import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { makeSelectUser } from 'slices/auth';
import { Path as CrudPath, Sidebar, usePreventLeaving } from 'containers/Crud';
import crudMessages from 'containers/Crud/messages';

import { Path } from './constants';
import messages from './messages';
import { makeSelectCrematorium, makeSelectIsUpdatingName } from './selectors';

export default function CrematoriumSidebar(): JSX.Element {
  const intl = useIntl();

  const user = useSelector(makeSelectUser());
  const isUpdatingName = useSelector(makeSelectIsUpdatingName());
  const crematorium = useSelector(makeSelectCrematorium());
  const isCreation = !crematorium;

  const { dirty } = useFormState();
  usePreventLeaving(isCreation || dirty);

  return (
    <Sidebar.Menu>
      <Sidebar.Title
        isCreation={isCreation}
        isLoading={isUpdatingName}
        fieldName="crematorium.name"
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
      />
      {crematorium?.current_update_dt &&
        crematorium?.current_update_user_id && (
          <Sidebar.LastUpdate
            date={crematorium.current_update_dt}
            userId={crematorium.current_update_user_id}
          />
        )}
      <Sidebar.LinksSection>
        <Sidebar.Link
          to={Path.GENERAL}
          message={<FormattedMessage id={messages.general.id} />}
        />
        {!isCreation && (
          <Sidebar.LinkGroup
            placeholder={<FormattedMessage id={crudMessages.documents.id} />}
          >
            {user?.isFuneralDirectorOrAbove ? (
              <Sidebar.Link
                to={CrudPath.EDITABLE_DOCUMENTS}
                message={
                  <FormattedMessage id={crudMessages.editableDocuments.id} />
                }
              />
            ) : undefined}
            <Sidebar.Link
              to={CrudPath.SUPPORTING_DOCUMENTS}
              message={
                <FormattedMessage id={crudMessages.supportingDocuments.id} />
              }
            />
          </Sidebar.LinkGroup>
        )}
        {!isCreation && (
          <Sidebar.Link
            to={CrudPath.OPENING_HOURS}
            message={<FormattedMessage id={crudMessages.openingHours.id} />}
          />
        )}
        {!isCreation && (
          <Sidebar.Link
            to={Path.MISCELLANEOUS}
            message={<FormattedMessage id={messages.miscellaneous.id} />}
          />
        )}
      </Sidebar.LinksSection>
      {!isCreation && (
        <Sidebar.LinksSection>
          <Sidebar.Link
            to={Path.CEREMONIES}
            message={<FormattedMessage id={messages.ceremonies.id} />}
          />
          <Sidebar.Link
            to={Path.ROOMS}
            message={<FormattedMessage id={messages.rooms.id} />}
          />
          <Sidebar.Link
            to={Path.AGES}
            message={<FormattedMessage id={messages.ages.id} />}
          />
          <Sidebar.Link
            to={Path.WORSHIP}
            message={<FormattedMessage id={messages.worship.id} />}
          />
        </Sidebar.LinksSection>
      )}
    </Sidebar.Menu>
  );
}
