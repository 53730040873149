import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Field } from 'react-final-form';

import { withSlice } from '@advitam/react';
import { HardSpinner } from '@advitam/ui';

import { Layout } from 'containers/Crud';
import { assert } from 'lib/support';

import ErrorBanner from 'components/ErrorBanner';
import Wrapper from '../Wrapper';
import slice from './slice';
import {
  makeSelectError,
  makeSelectFuneralWorks,
  makeSelectIsSaving,
} from './selectors';
import { fetchFuneralWorks, saveFuneralWorks } from './thunk';
import { WarehouseZoneForm } from './types';
import ZoneList from './List';

function Zone(): JSX.Element {
  const dispatch = useDispatch();

  const { zoneId } = useParams();
  assert(zoneId !== undefined);
  const parsedId = parseInt(zoneId, 10);

  const funeralWorks = useSelector(makeSelectFuneralWorks());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());

  const onSubmit = useCallback(
    (values: WarehouseZoneForm) => {
      dispatch(
        saveFuneralWorks({
          zoneId: parsedId,
          values,
        }),
      );
    },
    [dispatch, parsedId],
  );

  const fetchResources = useCallback(() => {
    dispatch(fetchFuneralWorks(parsedId));
  }, [dispatch, parsedId]);

  useEffect(fetchResources, [parsedId]);

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={funeralWorks}>
      <Layout.Container>
        <Field name="sectionValues">{(): JSX.Element => <ZoneList />}</Field>
      </Layout.Container>

      {isSaving && <HardSpinner />}

      {error && <ErrorBanner errors={error} />}
    </Wrapper>
  );
}

export default withSlice(slice)(Zone);
