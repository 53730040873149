import type { ReactNode } from 'react'

import GoogleRate from '../../../GoogleRate'

import style from './LogoColumn.module.scss'

interface LogoColumnPropsBase {
  logo: ReactNode
}

interface LogoColumnPropsWithGoogleRate extends LogoColumnPropsBase {
  googleRate: number
  reviewsLink: string
  reviewsLinkInternal?: boolean
}

interface LogoColumnPropsWithoutGoogleRate extends LogoColumnPropsBase {
  googleRate?: undefined
  reviewsLink?: undefined
  reviewsLinkInternal?: undefined
}

type LogoColumnProps = LogoColumnPropsWithGoogleRate | LogoColumnPropsWithoutGoogleRate

export default function LogoColumn({
  logo,
  googleRate,
  reviewsLink,
  reviewsLinkInternal,
}: LogoColumnProps): JSX.Element {
  return (
    <div className={`col-4 col-md-2 col-xl-3 ${style.column}`}>
      <div className={style.logo}>{logo}</div>
      {googleRate && (
        <GoogleRate
          rate={googleRate}
          internal={reviewsLinkInternal}
          href={reviewsLink}
          horizontal
        />
      )}
    </div>
  )
}
