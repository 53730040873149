import { assert } from 'lib/Assert';
import { last } from 'lodash';
import type { PDFDocument, PDFImage, PDFPage } from 'pdf-lib';

import type { Input } from '../../models';
import type { BuildPDFContext } from './types';
import { getInputValue, getScale, getY } from './utils';

async function embedImage(
  url: string,
  document: PDFDocument,
): Promise<PDFImage> {
  const extension = last(url.split('.'));
  assert(extension !== undefined);

  const image = await fetch(url);
  const bytes = await image.arrayBuffer();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return document.embedJpg(bytes);
    case 'png':
      return document.embedPng(bytes);
    default:
      throw new Error(`buildPDF: ${extension}: unsupported image extension`);
  }
}

export default async function renderImage(
  input: Input,
  page: PDFPage,
  context: BuildPDFContext,
): Promise<void> {
  const url = getInputValue(input, context.dictionary);
  if (!url) {
    return;
  }
  assert(
    typeof url === 'string',
    'buildPDF: expexcted image value to be a string',
  );

  const image = await embedImage(url, context.document);

  const scale = getScale(page);
  const width = (input.size?.[0] || 0) * scale;
  const height = (input.size?.[1] || 0) * scale;
  const sx = width / image.width;
  const sy = height / image.height;
  const imageScale = Math.min(1, sx, sy);
  const imageSize = image.scale(imageScale);
  const leftOffset = (width - imageSize.width) / 2;
  const topOffset = (height - imageSize.height) / 2;

  page.drawImage(image, {
    x: input.position[0] * scale + leftOffset,
    y: getY(page, input.position[1] * scale) - imageSize.height - topOffset,
    width: imageSize.width,
    height: imageSize.height,
  });
}
