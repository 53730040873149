import { ReactNode, useCallback } from 'react';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import { FlightAirportJSON } from '@advitam/api/models/Flight/Airport';
import { ApiAutocomplete } from '@advitam/ui';
import type { AutocompleteResult } from '@advitam/ui/components/Form/UI/Autosuggest/types';

interface AirportAutocompleteProps {
  name: string;
  label: ReactNode;
  disabled?: boolean;
  required?: boolean;
  expandOnTop?: boolean;
}

export default function AirportAutocomplete({
  name,
  label,
  required,
  disabled,
  expandOnTop,
}: AirportAutocompleteProps): JSX.Element {
  const getAirportDisplayValue = useCallback(
    (item: FlightAirportJSON): string =>
      item.id ? `${item.country_name}, ${item.city} (${item.iata})` : '',
    [],
  );

  return (
    <ApiAutocomplete<FlightAirportJSON & AutocompleteResult>
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      expandOnTop={expandOnTop}
      getDisplayValue={getAirportDisplayValue}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.airports.index)}
      keys={['country_name', 'city', 'iata']}
      requestHeaders={{ ...getTokens() }}
    />
  );
}
