import { authenticate } from '../../lib/decorators'
import type { ApiRequestDescriptor } from '../../lib/request'
import { get } from '../../lib/methods'
import type { ClientAbilityIndexJSON } from '../../models/Client/Ability/IndexAbility'

export class Abilities {
  @authenticate
  static index(clientId: number): ApiRequestDescriptor<ClientAbilityIndexJSON[]> {
    return get(`/api/v1/clients/${clientId}/abilities`)
  }
}
