import type { ReactNode } from 'react'

import Collapsible from '../../../Collapsible'
import style from './Section.module.scss'

interface SectionProps {
  isOpen?: boolean
  title: ReactNode
  className?: string
  children: ReactNode
  onToggle?: () => void
}

export default function Section({
  isOpen,
  title,
  className,
  children,
  onToggle,
}: SectionProps): JSX.Element {
  const classes = [style.section, className]

  return (
    <Collapsible isOpen={isOpen} className={classes.join(' ')} onToggle={onToggle} title={title}>
      {children}
    </Collapsible>
  )
}
