const SEND_URL = 'https://api.whatsapp.com/send'

function buildMessageLink(text: string): string {
  const params = [`text=${text}`]
  return `${SEND_URL}?${params.join('&')}`
}

export const WhatsApp = {
  buildMessageLink,
}
