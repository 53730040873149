import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

import DatePicker from 'components/DatePicker';
import { SelectList } from 'components/Input';

import SelectConcessionDuration from './SelectConcessionDuration';
import messages from './messages';

const VALUE_UNLIMITED_TERM = -1;

function Dates({
  concession,
  updateConcession,
  graveyardConcessions,
  handleChange,
  isFamilyBuyingConcession,
  intl,
}) {
  const { exists: concessionExists } = concession;

  const termIsNotUnlimited = concession.term !== VALUE_UNLIMITED_TERM;

  /**
   * Function to calculate the expiration date of a concession
   *
   * @param {number} buyingDate timestamp
   * @param {number} renewDate timestamp
   * @param {number} duration in year
   * @returns {number} expiration timestamp of a concession
   */
  const getConcessionExpirationDate = (buyingDate, renewDate, duration) => {
    if (duration === VALUE_UNLIMITED_TERM || (!buyingDate && !renewDate))
      return null;
    const expiryDate = renewDate
      ? moment.unix(renewDate)
      : moment.unix(buyingDate);
    return expiryDate.add(duration, 'y').utc().unix();
  };

  return (
    <div className="concession__field">
      <h5>
        <FormattedMessage {...messages.dates} />
      </h5>
      <div>
        <FormattedMessage {...messages.buy} />
        <DatePicker
          name="buyDate"
          onChange={value => updateConcession({ buyDate: value })}
          date={concession.buyDate || null}
          noLabel
        />
      </div>
      {concessionExists && (
        <>
          <div>
            <FormattedMessage {...messages.renew} />
            <DatePicker
              name="renewal_date"
              onChange={value => updateConcession({ renewal_date: value })}
              date={concession.renewal_date || null}
              noLabel
            />
          </div>
          <div>
            <FormattedMessage {...messages.timeLeft} />
            <SelectList
              value={concession.term}
              name="timeLeft"
              options={[
                {
                  name: intl.formatMessage(messages.yearsLeft),
                  value: true,
                },
                {
                  name: intl.formatMessage(messages.unlimited),
                  value: VALUE_UNLIMITED_TERM,
                },
              ]}
              onChange={({ target: { value } }) =>
                updateConcession({
                  term:
                    value === String(VALUE_UNLIMITED_TERM)
                      ? VALUE_UNLIMITED_TERM
                      : null,
                })
              }
            />
          </div>
        </>
      )}
      <SelectConcessionDuration
        concession={concession}
        updateConcession={updateConcession}
        graveyardConcessions={graveyardConcessions}
        handleChange={handleChange}
        isFamilyBuyingConcession={isFamilyBuyingConcession}
      />
      {termIsNotUnlimited && (
        <div>
          <FormattedMessage {...messages.expiry} />
          <DatePicker
            name="expiryDate"
            date={getConcessionExpirationDate(
              concession.buyDate,
              concession.renewal_date,
              concession.term,
            )}
            readOnly
            noLabel
          />
        </div>
      )}
    </div>
  );
}

Dates.propTypes = {
  /** concession data */
  concession: PropTypes.object.isRequired,
  /** function to update a concession */
  updateConcession: PropTypes.func.isRequired,
  /** concessions available in graveyard selected */
  graveyardConcessions: PropTypes.object,
  /** Function to update the step */
  handleChange: PropTypes.func.isRequired,
  /** family want to buy the concession */
  isFamilyBuyingConcession: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Dates);
