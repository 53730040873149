import { injectIntl } from 'react-intl';

import Circle from 'images/circle.svg';

import ResizableInput from './Resizable';
import style from '../Engine.module.scss';

const COMMON_STYLE = {
  display: 'block',
  width: '100%',
  height: '100%',
};

class CircleInput extends ResizableInput<boolean> {
  renderInput(): JSX.Element | null {
    const { isEditMode, isPreviewMode, target, value, onChange } = this.props;

    const isReadonly = !isEditMode || isPreviewMode;
    if (isReadonly && !value) {
      return null;
    }
    return (
      <Circle
        tabIndex={-1}
        onClick={isReadonly ? undefined : (): void => onChange(target, !value)}
        style={COMMON_STYLE}
        className={!value ? style.circle__unset : undefined}
      />
    );
  }
}

export default injectIntl(CircleInput);
