import { authenticate } from '../../../lib/decorators'
import { get } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { CompanyFormalityCategoryJSON } from '../../../models/Company'

export class Categories {
  @authenticate
  static index(): ApiRequestDescriptor<CompanyFormalityCategoryJSON[]> {
    return get('/api/v1/companies/formalities/categories')
  }
}
