import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  functioning: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.functioning',
  },
  defaultStay: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.defaultStay',
  },
  defaultStayTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.defaultStayTooltip',
  },
  acceptsCoffinDelivery: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.acceptsCoffinDelivery',
  },
  acceptsCoffinDeliveryTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.acceptsCoffinDeliveryTooltip',
  },
  depository: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.depository',
  },
  depositoryTooltip: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.depositoryTooltip',
  },

  billing: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.billing',
  },
  billingTooltip: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.billingTooltip',
  },
  admissionPrice: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.admissionPrice',
  },
  admissionPriceTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.admissionPriceTooltip',
  },
  admissionOutsideOpeningHoursPrice: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.admissionOutsideOpeningHoursPrice',
  },
  admissionOutsideOpeningHoursPriceTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.admissionOutsideOpeningHoursPriceTooltip',
  },
  cleaningLabPrice: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.cleaningLabPrice',
  },
  cleaningLabPriceTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.cleaningLabPriceTooltip',
  },
  ritualCleaningLabPrice: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.ritualCleaningLabPrice',
  },
  ritualCleaningLabPriceTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.ritualCleaningLabPriceTooltip',
  },
  embalmingCleaningLabPrice: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.embalmingCleaningLabPrice',
  },
  embalmingCleaningLabPriceTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.embalmingCleaningLabPriceTooltip',
  },
  ceremonyRoom: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.ceremonyRoom',
  },
  ceremonyRoomTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.ceremonyRoomTooltip',
  },
  departureRoomPrice: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.departureRoomPrice',
  },
  departureRoomPriceTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.departureRoomPriceTooltip',
  },
  casketingPrice: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.casketingPrice',
  },
  casketingPriceTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.casketingPriceTooltip',
  },
  coolerSetupPrice: {
    id: 'app.containers.FuneralParlor.sections.Miscellaneous.coolerSetupPrice',
  },
  coolerSetupPriceTooltip: {
    id:
      'app.containers.FuneralParlor.sections.Miscellaneous.coolerSetupPriceTooltip',
  },
});
