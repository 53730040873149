import { ReactNode } from 'react'

import Check from '../../images/icons/check.svg'
import Info from '../../images/icons/info-circle.svg'
import Circled from '../Circled'
import Tooltip from '../Tooltip'
import Text from '../Text'

import style from './Step.module.scss'

interface StepTitleProps {
  /** The index of the step to be displayed. Use `null` when the step is done. */
  index: number | null
  title: ReactNode
  tooltip?: ReactNode
  className?: string
}

export default function StepTitle({
  index,
  title,
  tooltip,
  className,
}: StepTitleProps): JSX.Element {
  return (
    <div className={[style.title, className].filter(Boolean).join(' ')}>
      <Circled>{index === null ? <Check /> : index}</Circled>
      <Text large className={style.title_text}>
        {title}
      </Text>
      {tooltip && (
        <Tooltip content={tooltip}>
          <div className={style.tooltip_icon}>
            <Info />
          </div>
        </Tooltip>
      )}
    </div>
  )
}
