import { createSelector, Selector } from 'reselect';
import { isEqual } from 'lodash';

import type { LegacyStep } from 'models/Deal/Step';

import type { AppStateSubset } from '../../../slice';
import { makeSelectSteps } from '../../../selectors.js';
import { StepMarkerData } from './type';

type MapSelector<T> = Selector<AppStateSubset, T>;

const makeSelectSummaryRouteSteps = (): MapSelector<LegacyStep[]> =>
  createSelector(
    ((makeSelectSteps as unknown) as () => MapSelector<LegacyStep[] | null>)(),
    steps =>
      steps?.filter(step =>
        Boolean(
          step.location?.address?.latitude && step.location?.address?.longitude,
        ),
      ) || [],
  );

function uniqueByAddress(steps: LegacyStep[]): LegacyStep[] {
  return steps.filter(
    (step, idx) =>
      idx === 0 ||
      !isEqual(steps[idx - 1].location?.address, step.location?.address),
  );
}

export const makeSelectSummaryRoute = (): MapSelector<
  google.maps.LatLngLiteral[]
> =>
  createSelector(makeSelectSummaryRouteSteps(), steps =>
    uniqueByAddress(steps).map(step => ({
      lat: step.location?.address.latitude || 0,
      lng: step.location?.address.longitude || 0,
    })),
  );

export const makeSelectSummaryMarkers = (): MapSelector<StepMarkerData[]> =>
  createSelector(makeSelectSummaryRouteSteps(), steps =>
    uniqueByAddress(steps).map(step => ({
      latitude: step.location?.address.latitude || 0,
      longitude: step.location?.address.longitude || 0,
      type: step.eventType,
      repatriationType: step.repatriation_type,
    })),
  );
