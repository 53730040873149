import { ChangeEventHandler, InputHTMLAttributes, ReactElement, ReactNode } from 'react'

import CheckIcon from '../../../../../images/icons/check.svg'
import { FieldCard } from '../../../../Card'
import radioStyle from '../Radio.module.scss'
import style from './Card.module.scss'

interface CardProps {
  icon: ReactElement
  text: ReactNode
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  onChange: ChangeEventHandler
  checked?: boolean
  error?: boolean
  className?: string
}

export default function Card({
  icon,
  text,
  name,
  value,
  onChange,
  checked = false,
  error = false,
  className = '',
}: CardProps): JSX.Element {
  const classes = [radioStyle.tile]
  const checkClasses = [radioStyle.check, style.check]

  if (checked) {
    classes.push(radioStyle.checked)
    checkClasses.push(radioStyle.check__active)
  }
  if (error) {
    classes.push(radioStyle.error)
  }
  if (className) {
    classes.push(className)
  }

  return (
    <label className={radioStyle.container}>
      <FieldCard.Base icon={icon} text={text} className={classes.join(' ')} />
      <CheckIcon className={checkClasses.join(' ')} />
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={radioStyle.radio}
      />
    </label>
  )
}
