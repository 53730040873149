import { createElement, ReactNode } from 'react'

import { HeadingTag } from './types'
import style from './Heading.module.scss'

interface HeadingProps {
  xlarge?: boolean
  large?: boolean
  small?: boolean
  tagName?: HeadingTag
  className?: string
  children: ReactNode
  // RDFa
  property?: string
}

export default function Heading({
  xlarge = false,
  large = false,
  small = false,
  tagName = 'h2',
  className,
  children,
  property,
}: HeadingProps): JSX.Element {
  const classes: string[] = [style.heading]
  if (xlarge) {
    classes.push(style.xlarge)
  } else if (large) {
    classes.push(style.large)
  } else if (small) {
    classes.push(style.small)
  } else {
    classes.push(style.medium)
  }
  if (className) {
    classes.push(className)
  }
  return createElement(tagName, { className: classes.join(' '), property }, children)
}
