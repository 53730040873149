import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from 'lib/Assert';

import { AppStateSubset } from '../../slice';
import { makeSelectRawFuneralParlor } from '../../selectors';
import { FUNERAL_PARLOR_MISCELLANEOUS } from './constants';

export const fetchStays = createAsyncThunk(
  `${FUNERAL_PARLOR_MISCELLANEOUS}/FETCH_STAYS`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const funeralParlor = makeSelectRawFuneralParlor()(state);
    assert(funeralParlor?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.FuneralParlors.Stays.index(funeralParlor.id),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
