import { PriceCategory } from '../PriceCategory'
import { StepType } from '../Step'

import { ProductCeremony } from './Ceremony'
import { ProductGraveType } from './GraveType'
import { ProductGroup } from './Group'
import { ProductType } from './Type'
import { ProductMaterialStrength } from './MaterialStrength'
import { ProductTag } from './Tag'
import { ProductWindow } from './Window'

export interface ProductJSON {
  id: number
  advitam_name: string
  description: string | null
  type: ProductType
  ceremony: ProductCeremony
  use: string | null
  hermetic: boolean | null
  window: ProductWindow | null
  material: string | null
  shape: string | null
  material_strength: ProductMaterialStrength | null
  color: string | null
  size_model: string | null
  thickness: number | null
  inside_length: number | null
  inside_width: number | null
  inside_height: number | null
  outside_length: number | null
  outside_width: number | null
  outside_height: number | null
  diameters: number | null
  weight: number | null
  step_type: StepType | null
  with_stele: boolean | null
  grave_type: ProductGraveType | null
  tags: ProductTag[]
  group_name: ProductGroup | null
  name: string
  images: string[]
  thumbnail: string
  price_category: PriceCategory
}

export {
  ProductCeremony,
  ProductGraveType,
  ProductGroup,
  ProductType,
  ProductMaterialStrength,
  ProductTag,
  ProductWindow,
}
