import { defineMessages, MessageDescriptor } from 'react-intl';

const messages: Record<string, MessageDescriptor> = defineMessages({
  400: { id: 'app.containers.ErrorModal.400' },
  403: { id: 'app.containers.ErrorModal.403' },
  404: { id: 'app.containers.ErrorModal.404' },
  413: { id: 'app.containers.ErrorModal.413' },
  422: { id: 'app.containers.ErrorModal.422' },
  500: { id: 'app.containers.ErrorModal.500' },
  deliveryLocationError: {
    id: 'app.containers.ErrorModal.deliveryLocationError',
  },
  consolidationError: { id: 'app.containers.ErrorModal.consolidationError' },
  dealIncomplete: { id: 'app.containers.ErrorModal.dealIncomplete' },
  missingClientPhone: { id: 'app.containers.ErrorModal.missingClientPhone' },
});

export default messages;
