import { createSelector, Selector } from 'reselect';

import { Memorial, MemorialSettings } from 'models/Memorial';
import { Step } from 'models/Memorial/Step';

import { MEMORIAL_SPACE } from './constants';
import { State, AppStateSubset } from './slice';

type MemorialSectionSelector<T> = Selector<AppStateSubset, T>;

export const selectMemorialSectionDomain = (state: AppStateSubset): State =>
  state && state[MEMORIAL_SPACE];

export const selectMemorial = (): MemorialSectionSelector<State['memorial']> =>
  createSelector(selectMemorialSectionDomain, state => state.memorial);

export const makeSelectMemorial = (): MemorialSectionSelector<Memorial | null> =>
  createSelector(selectMemorial(), memorial =>
    memorial ? new Memorial(memorial) : null,
  );

export const makeSelectMemorialSettings = (): MemorialSectionSelector<MemorialSettings | null> =>
  createSelector(makeSelectMemorial(), memorial =>
    memorial ? memorial.settings : null,
  );

export const makeSelectIsOpen = (): MemorialSectionSelector<boolean> =>
  createSelector(makeSelectMemorial(), memorial =>
    memorial ? memorial.open : false,
  );

export const makeSelectMemorialSteps = (): MemorialSectionSelector<Step[]> =>
  createSelector(makeSelectMemorial(), memorial =>
    memorial ? memorial.steps : [],
  );

export const makeSelectIsLoading = (): MemorialSectionSelector<
  State['isLoading']
> => createSelector(selectMemorialSectionDomain, state => state.isLoading);

export const makeSelectError = (): MemorialSectionSelector<State['error']> =>
  createSelector(selectMemorialSectionDomain, state => state.error);
