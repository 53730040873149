import type { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ButtonText } from '@advitam/ui';

import style from 'containers/Crud/Sidebar/LinksSection/LinksSection.module.scss';

export interface SidebarLinkProps {
  to: string;
  message: ReactNode;
}

export default function SidebarLink({
  message,
  to,
}: SidebarLinkProps): JSX.Element {
  const location = useLocation();

  const href = `#${to}`;
  const isActive = location.hash === href || (!location.hash && !to);
  const className = [style.link, isActive && style.active]
    .filter(Boolean)
    .join(' ');

  return (
    <Link to={href} className={className}>
      <ButtonText small>{message}</ButtonText>
    </Link>
  );
}
