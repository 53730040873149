import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { TodoItem } from '@advitam/api/models/Deal/TodoItem';

import { TODO_LIST_DELETION_MODAL } from './constants';
import { State, AppStateSubset } from './slice';

type TodolistDeletionModalSelector<T> = Selector<AppStateSubset, T>;

const selectTodoListDeletionModalDomain = (state: AppStateSubset): State =>
  state[TODO_LIST_DELETION_MODAL];

export const makeSelectDeletingItem = (): TodolistDeletionModalSelector<TodoItem | null> =>
  createSelector(
    selectTodoListDeletionModalDomain,
    ({ deletingItem }) => deletingItem,
  );

export const makeSelectIsLoading = (): TodolistDeletionModalSelector<boolean> =>
  createSelector(
    selectTodoListDeletionModalDomain,
    ({ isLoading }) => isLoading,
  );

export const makeSelectError = (): TodolistDeletionModalSelector<SerializedApiError | null> =>
  createSelector(selectTodoListDeletionModalDomain, ({ error }) => error);
