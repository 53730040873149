import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Toggle from 'components/Toggle/index.js';
import { Deal } from 'models/Deal';
import { LayerType } from 'models/Deal/Layer';
import { ClientAbilityJSON } from '@advitam/api/models/Client/Ability';

import { LAYERS_MAPPING } from './constants';
import LayerValue from './LayerValue';
import messages from './messages';
import style from './style.scss';

export interface LayerDiff {
  action: LayerType;
  key: string;
  id: number;
  values: Array<string | number | boolean | null>;
}

interface LayerUpdateProps {
  onChangePosition: (id: number, position: number | null) => void;
  isToggled: boolean;
  layer: LayerDiff;
  deal: Deal;
  empoweredClient: ClientAbilityJSON;
  position: number | null;
}

function LayerUpdate({
  layer,
  isToggled,
  onChangePosition,
  deal,
  position,
  empoweredClient,
}: LayerUpdateProps): JSX.Element {
  const { id, values, action } = layer;

  let dealValue = '';
  const mapping = LAYERS_MAPPING[action as LayerType];
  const target = mapping[layer.key];
  if (layer.action === LayerType.EMPOWERED_CLIENT) {
    dealValue = get(empoweredClient, target);
  } else {
    dealValue = get(deal, target);
  }

  return (
    <div className={style['layer-update']}>
      <span className={style['layer--description']}>
        <FormattedMessage {...messages[layer.action]} />
        &nbsp;-&nbsp;
        <FormattedMessage {...messages[layer.key]} />
      </span>
      <LayerValue
        values={[dealValue]}
        position={position}
        type={layer.key}
        styleNameClass="layer-update--actual"
      />
      <span>-&gt;</span>
      <LayerValue
        position={position}
        values={values}
        type={layer.key}
        styleNameClass="layer-update--new"
        onChangePosition={(pos: number): void => onChangePosition(id, pos)}
      />
      <Toggle
        onToggle={(): void => {
          const newPos = isToggled ? null : 0;
          onChangePosition(id, newPos);
        }}
        isToggled={isToggled}
      />
    </div>
  );
}

export default LayerUpdate;
