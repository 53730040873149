import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { TransparentButton } from 'components/Button';
import LockIcon from 'components/Icons/LockIcon';
import { PageSpinner } from 'components/Spinner';
import { DealDocument } from 'models/Deal/Document';
import { DealDocumentVersion } from 'models/Deal/Document/Version';

import {
  fetchDocumentVersions,
  clearDocumentVersions,
  toggleLockedVersion,
} from '../../actions.js';
import {
  makeSelectAreVersionsLoading,
  makeSelectVersions,
} from '../../selectors.js';

import style from './style.scss';

interface VersionsProps {
  document: DealDocument;
}

export default function Versions({
  document,
}: VersionsProps): JSX.Element | null {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDocumentVersions(document.id));

    return (): void => {
      dispatch(clearDocumentVersions());
    };
  }, [document.id]);

  const isLoading = useSelector(makeSelectAreVersionsLoading());
  const versions = useSelector(makeSelectVersions());

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!versions) {
    return null;
  }

  return (
    <>
      <h3>{document.name}</h3>
      {versions.map((version: DealDocumentVersion) => (
        <div key={version.id} className={style['version-row']}>
          <div>{moment(version.created_at).format('DD/MM/YYYY - HH:mm')}</div>
          <a
            href={version.file || undefined}
            target="_blank"
            rel="noreferrer"
            className="link--reset material-icons-outlined"
          >
            remove_red_eye
          </a>
          <TransparentButton
            onClick={(): void => {
              dispatch(toggleLockedVersion(document, version));
            }}
          >
            <LockIcon
              isActivated={
                document.locked && document.version.id === version.id
              }
            />
          </TransparentButton>
        </div>
      ))}
    </>
  );
}
