import { createSelector, Selector } from 'reselect';

import {
  createTodoItem,
  RequestDocumentTodoItem,
  SendBatchTodoItem,
  TodoItem,
  TodoItemJSON,
} from '@advitam/api/models/Deal/TodoItem';
import { DealDocument } from 'models/Deal/Document';

import { makeSelectDocuments } from 'containers/Documents/selectors';

import { TODO_LIST } from './constants';
import { State, AppStateSubset } from './slice';

type TodoListSelector<T> = Selector<AppStateSubset, T>;

const selectTodoListDomain = (state: AppStateSubset): State => state[TODO_LIST];

export const makeSelectRawItems = (): TodoListSelector<TodoItemJSON[]> =>
  createSelector(selectTodoListDomain, state => state.items);

export const makeSelectItems = (): TodoListSelector<TodoItem[]> =>
  createSelector(makeSelectRawItems(), items =>
    items.map(item => createTodoItem(item)),
  );

export const makeSelectIsLoading = (): TodoListSelector<State['isLoading']> =>
  createSelector(selectTodoListDomain, state => state.isLoading);

export const makeSelectError = (): TodoListSelector<State['error']> =>
  createSelector(selectTodoListDomain, state => state.error);

export const makeSelectProgression = (): TodoListSelector<number> =>
  createSelector(makeSelectItems(), items => {
    if (items.length === 0) {
      return 0;
    }
    const doneItemsCount = items.filter(item => item.done).length;
    return (doneItemsCount / items.length) * 100;
  });

export const makeSelectLoadingItems = (): TodoListSelector<
  State['loadingItems']
> => createSelector(selectTodoListDomain, state => state.loadingItems);

export const makeSelectIsItemLoading = (
  item: TodoItem,
): TodoListSelector<boolean> =>
  createSelector(makeSelectLoadingItems(), loadingItems =>
    loadingItems.some(id => id === item.id),
  );

export const makeSelectDocumentsToUpload = (
  item: SendBatchTodoItem | RequestDocumentTodoItem,
): TodoListSelector<DealDocument[]> =>
  createSelector(
    ((makeSelectDocuments as unknown) as () => TodoListSelector<
      DealDocument[]
    >)(),
    (documents: DealDocument[]) =>
      documents.filter(
        doc => !doc.hasVersion && item.action?.hasDealDocument(doc.id),
      ),
  );

export const makeSelectHasBatchMissingDocs = (
  item: SendBatchTodoItem,
): TodoListSelector<boolean> =>
  createSelector(makeSelectDocumentsToUpload(item), documents =>
    documents.some(doc => !item.action.isDealDocumentOptional(doc.id)),
  );
