import {
  VAULT_TYPE_ENFEU,
  COLUMBARIUM_CASE,
} from 'containers/Deal/DealFuneral/StepsSection/StepEditors/Interment/constants';
import {
  STEP_URN_SEALING,
  STEP_INTERMENT,
} from 'containers/Deal/DealFuneral/constants';

/**
 * Function to check if we can build a monument
 *
 * @param {string} concessionTypeName concession to check
 * @returns {boolean} can we build a monument
 */
export const isMonument = concessionTypeName => {
  const concessionWithoutMonument = [COLUMBARIUM_CASE, VAULT_TYPE_ENFEU];
  return !concessionWithoutMonument.includes(concessionTypeName);
};

export const getStepForConcession = steps =>
  steps.find(
    item =>
      item.eventType === STEP_INTERMENT || item.eventType === STEP_URN_SEALING,
  );
