import { BASE_CONTROLS, CONTROL_NAMES, CONTROL_PLUGIN_PATHS } from './constants'
import { Control } from './types'

interface EditorControls {
  externalPlugins?: Record<string, string>
  plugins?: string
  toolbar: string
}

function getControlNames(controls: Control[]): string {
  return controls.map(control => CONTROL_NAMES[control]).join(' ')
}

function buildToolbar(controls: Control[]): string {
  const controlNames = getControlNames(controls)
  return [BASE_CONTROLS, controlNames].join(' | ')
}

function buildExternalPlugins(controls: Control[]): Record<string, string> {
  return controls.reduce(
    (res, control) => ({ ...res, [control]: CONTROL_PLUGIN_PATHS[control] }),
    {},
  )
}

/**
 * @note Plugins are referenced as external plugins, and should be prefixed by `-`
 */
function formatPluginName(control: Control): string {
  return `-${control}`
}

function buildPlugins(controls: Control[]): string {
  return controls.map(formatPluginName).join(',')
}

export function useControls(controls: boolean | Control[]): EditorControls {
  if (Array.isArray(controls)) {
    return {
      externalPlugins: buildExternalPlugins(controls),
      plugins: buildPlugins(controls),
      toolbar: buildToolbar(controls),
    }
  }

  return {
    toolbar: controls ? BASE_CONTROLS : '',
  }
}
