import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, ErrorCodes, SerializedApiError } from 'api';

import {
  fetchData,
  uploadDocument,
} from 'containers/DocumentTemplateEditor/thunk';
import { DOCUMENT_EDITOR } from './constants';
import {
  fetchDictionary,
  fetchDocument,
  fetchFile,
  fetchVariables,
  saveDocument,
} from './thunk';

export interface State {
  isLoading: boolean;
  error: SerializedApiError | null;
  blob: Blob | null;
  dealDocumentId: number | null;
  variables: string[];
}

export interface AppStateSubset {
  [DOCUMENT_EDITOR]: State;
}

const initialState: State = {
  isLoading: true,
  error: null,
  blob: null,
  dealDocumentId: null,
  variables: [],
};

const slice = createSlice({
  name: DOCUMENT_EDITOR,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    reset: state => {
      state.variables = [];
      state.dealDocumentId = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDocument.pending, (state, { meta }) => {
      state.error = null;
      state.isLoading = true;
      state.dealDocumentId = meta.arg.dealDocument.id;
    });
    builder.addCase(fetchDocument.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchDocument.rejected, state => {
      state.isLoading = false;
      state.error = {
        body: null,
        errorCodes: [ErrorCodes.UNHANDLED],
        status: 0,
      };
    });

    builder.addCase(fetchData.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
    builder.addCase(fetchFile.fulfilled, (state, { payload }) => {
      state.blob = payload;
    });
    builder.addCase(fetchFile.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
    builder.addCase(fetchDictionary.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(fetchVariables.fulfilled, (state, { payload }) => {
      state.variables = payload;
    });
    builder.addCase(fetchVariables.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveDocument.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(saveDocument.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(saveDocument.rejected, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.error = ApiError.serialize(payload);
      }
    });
    builder.addCase(uploadDocument.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { reset, setIsLoading } = slice.actions;
export default slice;
