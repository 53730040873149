import { assert } from '@advitam/support'

import { ApiRequestDescriptor } from '../../lib/request'
import { del, get, post, put } from '../../lib/methods'
import { GraveyardConcessionTypeJSON } from '../../models/Graveyard'
import { authenticate, withSessionId } from '../../lib/decorators'
import { serializeConcessionType } from './serializers'
import { ConcessionTypePayload } from './types'

export class ConcessionTypes {
  @authenticate
  @withSessionId
  static create(
    graveyardId: number,
    concessionType: ConcessionTypePayload,
  ): ApiRequestDescriptor<GraveyardConcessionTypeJSON> {
    return post(
      `/api/v1/graveyards/${graveyardId}/concession_types`,
      serializeConcessionType(concessionType),
    )
  }

  @authenticate
  static index(graveyardId: number): ApiRequestDescriptor<GraveyardConcessionTypeJSON[]> {
    return get(`/api/v1/graveyards/${graveyardId}/concession_types`)
  }

  @authenticate
  @withSessionId
  static update(
    concessionType: ConcessionTypePayload,
  ): ApiRequestDescriptor<GraveyardConcessionTypeJSON> {
    assert(concessionType?.id !== undefined)
    return put(
      `/api/v1/graveyards/concession_types/${concessionType?.id}`,
      serializeConcessionType(concessionType),
    )
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/graveyards/concession_types/${id}`)
  }
}
