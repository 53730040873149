import type { ApiRequestDescriptor } from '../../lib/request'
import { get, post, put, del } from '../../lib/methods'
import { authenticate, withSessionId } from '../../lib/decorators'
import type { WorshipJSON } from '../../models/Worship'
import type { WorshipBody } from './types'
import { serialize } from './serializers'
import { Disabled } from './Disabled'

export class Worships {
  static readonly Disabled = Disabled

  @authenticate
  static show(id: number): ApiRequestDescriptor<WorshipJSON> {
    return get(`/api/v1/worships/${id}`)
  }

  @authenticate
  static create(worship: Omit<WorshipBody, 'id'>): ApiRequestDescriptor<WorshipJSON> {
    return post('/api/v1/worships', serialize(worship))
  }

  @authenticate
  @withSessionId
  static update(worship: WorshipBody): ApiRequestDescriptor<WorshipJSON> {
    return put(`/api/v1/worships/${worship.id}`, serialize(worship))
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<WorshipJSON> {
    return del(`/api/v1/worships/${id}`)
  }
}
