import Link from '../../Link'
import Text from '../../Text'
import { FooterLink as FooterLinkProps } from '../types'

export default function FooterLink({
  text,
  href,
  internal,
  newTab,
  small,
}: FooterLinkProps): JSX.Element {
  return (
    <Link internal={internal} href={href} newTab={newTab}>
      {small ? <Text small>{text}</Text> : text}
    </Link>
  )
}
