import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  documentName: { id: 'app.containers.Crud.Documents.Supporting.documentName' },
  issuanceDate: { id: 'app.containers.Crud.Documents.Supporting.issuanceDate' },
  expirationDate: {
    id: 'app.containers.Crud.Documents.Supporting.expirationDate',
  },
  reference: { id: 'app.containers.Crud.Documents.Supporting.reference' },
  actions: { id: 'app.containers.Crud.Documents.Supporting.actions' },

  add: { id: 'app.containers.Crud.Documents.Supporting.add' },
  currentVersion: {
    id: 'app.containers.Crud.Documents.Supporting.currentVersion',
  },
  documentTypePlaceholder: {
    id: 'app.containers.Crud.Documents.Supporting.documentTypePlaceholder',
  },
  referencePlaceholder: {
    id: 'app.containers.Crud.Documents.Supporting.referencePlaceholder',
  },

  deletionConfirmationText: {
    id: 'app.containers.Crud.Documents.Supporting.deletionConfirmationText',
  },
  deletionConfirmationConfirm: {
    id: 'app.containers.Crud.Documents.Supporting.deletionConfirmationConfirm',
  },
  deletionConfirmationCancel: {
    id: 'app.containers.Crud.Documents.Supporting.deletionConfirmationCancel',
  },
});
