import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TodoItem } from '@advitam/api/models/Deal/TodoItem';
import { TODO_LIST_BOOKING_MODAL } from './constants';

export interface State {
  bookingItem: TodoItem | null;
}

export interface AppStateSubset {
  [TODO_LIST_BOOKING_MODAL]: State;
}

export const initialState: State = {
  bookingItem: null,
};

const slice = createSlice({
  name: TODO_LIST_BOOKING_MODAL,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setBookingItem(state, { payload }: PayloadAction<TodoItem | null>) {
      state.bookingItem = payload;
    },
  },
  /* eslint-enable no-param-reassign */
});

export const { setBookingItem } = slice.actions;
export default slice;
