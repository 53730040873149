/*
 * Step editors
 * Maybe move them in separate files in the future
 * (and keep this file for grouped export)
 */

import { FormattedMessage } from 'react-intl';

import SelectAddress from 'components/SelectAddress';
import AutoComplete from 'containers/AutoComplete';
import { BulletList } from 'components/Input';
import DatePicker from 'components/DatePicker';
import CheckboxLabel from 'components/Input/CheckboxLabel';
import LinkToMap from 'components/LinkToMap';
import {
  STEP_CASKETING,
  STEP_DEPOSIT,
  ENTITY_TYPE_TO_PATH,
} from 'containers/Deal/DealFuneral/constants';
import { SUPPLIER_WAREHOUSE } from 'containers/Deal/DealFuneral/StepsSection/skeletons';
import PhoneIcon from 'images/phone-black.svg';

import messages from './messages';

function renderDate(step, handleChange) {
  if (step.eventType.includes('transport') || step.eventType === STEP_DEPOSIT) {
    return (
      <div>
        <DatePicker
          fieldName="departure"
          displayHours
          date={step.startDate}
          onChange={value => handleChange({ startDate: value })}
        />
        <DatePicker
          fieldName="arrival"
          displayHours
          date={step.endDate}
          onChange={value => handleChange({ endDate: value })}
        />
      </div>
    );
  }
  return (
    <DatePicker
      displayHours
      date={step.eventDate}
      onChange={value => handleChange({ eventDate: value })}
      message={messages.date}
    />
  );
}

export function renderStepDefaults(step, handleChange) {
  return <div>{renderDate(step, handleChange)}</div>;
}

export function renderLocation(location, message, prevStep) {
  if (location.sameAsPreviousStep && prevStep) {
    return (
      <div className="step__header__location">
        <div className="step__header__location__label">
          <FormattedMessage {...message} />
        </div>
        <div>
          {prevStep.eventType && (
            <span className="margin--0-0-0-10">
              <FormattedMessage {...messages.sameAsPlace} />
              <FormattedMessage {...messages[prevStep.eventType]} />
            </span>
          )}
        </div>
      </div>
    );
  }

  /**
   * Display location link or not
   *
   * @param {object} loc location object
   */
  const locationDisplay = loc => {
    if (!loc.type || !loc.id || !loc.name) return null;
    if (loc.type !== SUPPLIER_WAREHOUSE) {
      return (
        <a href={`${ENTITY_TYPE_TO_PATH[loc.type]}/${loc.id}`} target="_blank">
          <span>
            {loc.name}
            {loc.address && loc.address.city && ','}
          </span>
        </a>
      );
    }
    return (
      <span className="margin--0-0-0-10">
        {loc.name}
        {loc.address && loc.address.city && ','}
      </span>
    );
  };

  return (
    <div className="step__header__location">
      <div className="step__header__location__label">
        <FormattedMessage {...message} />
      </div>
      {locationDisplay(location)}
      {location.address && (
        <span>
          {location.address.city}
          {location.address.city &&
            location.address.postal_code &&
            `, ${location.address.postal_code}`}
        </span>
      )}
      {location.contact && location.contact.phone && (
        <a
          className="step__header__location__icon"
          href={`tel:${location.contact.phone}`}
        >
          <PhoneIcon />
        </a>
      )}
      {location.address && (
        <LinkToMap
          address={location.address}
          className="dealSummary__step__body__places__other__place__icon"
          name={location.name}
        />
      )}
    </div>
  );
}

export function renderAutocomplete(
  locationType,
  handleChange,
  paramsToAddToQuery = {},
) {
  return (
    <AutoComplete
      url={`/api/v1/autocompletes/${locationType}?q=`}
      getKey={item => item.id}
      getValue={item => item.description}
      onSelect={(_, item) =>
        handleChange(item.id, item.description, item.postal_code)
      }
      paramsToAddToQuery={paramsToAddToQuery}
      auth
    />
  );
}

export function renderEditLocation(step, handleChange, locationTypes) {
  const setResponse = (id, name, postalCode) => {
    handleChange({
      location: {
        ...step.location,
        id,
        name,
        type: step.location.type,
        address: { postal_code: postalCode },
      },
    });
  };

  const handleChangeBulletList = value => {
    const newLocation = {
      location: {
        type: value,
        sameAsPreviousStep: false,
      },
    };
    if (step.eventType !== STEP_CASKETING) {
      handleChange({ ...newLocation });
      return;
    }
    handleChange({
      ...newLocation,
      funeral_parlor_stay_id: '',
    });
  };

  const autoFindValue = step.location.autoFind || false;
  const filterDepository =
    step.eventType === STEP_CASKETING && step.location.type === 'FuneralParlor'
      ? { depository_eq: false }
      : {};
  return (
    <div className="step__editLocation">
      <BulletList
        items={locationTypes}
        checked={step.location.type}
        onChange={value => handleChangeBulletList(value)}
      />
      {step.location.type === 'HealthFacility' &&
        renderAutocomplete('hospitals', setResponse)}
      {step.location.type === 'FuneralParlor' && (
        <div>
          <CheckboxLabel
            message={messages.autoFind}
            updateCheckbox={() =>
              handleChange({
                location: { ...step.location, autoFind: !autoFindValue },
              })
            }
            messageValues={{ type: 'funérarium' }}
            checkboxValue={autoFindValue}
          />
          {!step.location.autoFind && (
            <div>
              {renderAutocomplete(
                'funeral_parlors',
                setResponse,
                filterDepository,
              )}
            </div>
          )}
        </div>
      )}
      {step.location.type === 'Public' && (
        <SelectAddress
          address={step.location.address || {}}
          onChange={value =>
            handleChange({
              location: {
                ...step.location,
                address: value,
              },
            })
          }
        />
      )}
      {step.location.type === 'Graveyard' &&
        renderAutocomplete('graveyards', setResponse)}
      {step.location.type === 'Crematorium' &&
        renderAutocomplete('crematoriums', setResponse)}
    </div>
  );
}
