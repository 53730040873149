import { uniq } from 'lodash';
import moment from 'moment';
import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from 'api';
import type { Layer } from 'models/Deal';
import { LayerType } from 'models/Deal/Layer';
import type { DealDefunctJSON } from 'models/Deal/Defunct';
import type { AbilityBase } from 'models/Ability';

import { LAYERS_MODAL } from './constants';
import type { State, AppStateSubset } from './slice';
import type { FormattedLayersProps, FormattedLayerValue } from './types';

type LayersSelector<T> = Selector<AppStateSubset, T>;

const selectLayersModalDomain = (state: AppStateSubset): State =>
  state[LAYERS_MODAL];

/* TODO: Clean the formatting mess */
interface Addressable {
  address?: string | null;
  city?: string | null;
  postal_code?: string | null;
  country?: string | null;
}

function formatAddress<T extends Addressable>(value: T): T {
  const { address, city, postal_code: postalCode, country, ...rest } = value;
  if (address !== undefined) {
    return ({
      address: {
        address,
        city,
        postal_code: postalCode,
        country,
      },
      ...rest,
    } as unknown) as T;
  }
  return value;
}

function formatDefunct(value: Partial<DealDefunctJSON>): FormattedLayerValue {
  const defunct = formatAddress(value as Addressable) as DealDefunctJSON & {
    birth_date: string;
  };
  if (defunct.birth_date) {
    const [day, m, year] = defunct.birth_date.split('/');
    const month = m ? parseInt(m, 10) - 1 : m;
    return ({
      ...defunct,
      birth_date: moment.utc([year, month, day]).unix(),
    } as unknown) as FormattedLayerValue;
  }
  return (defunct as unknown) as FormattedLayerValue;
}

function formatEmpoweredClientLayer(
  value: Partial<AbilityBase>,
): FormattedLayerValue {
  const { client, ...abilityInfo } = value;

  return {
    ...client,
    ...abilityInfo,
  } as FormattedLayerValue;
}

export const makeSelectLayers = (): LayersSelector<Layer[]> =>
  createSelector(selectLayersModalDomain, substate => substate.layers);

function formatLayer(layer: Layer): FormattedLayerValue {
  switch (layer.action) {
    case LayerType.EMPOWERED_CLIENT:
      return formatEmpoweredClientLayer(layer.value);
    case LayerType.DEFUNCT_FATHER:
    case LayerType.DEFUNCT_MOTHER:
    case LayerType.DEFUNCT_PARTNER:
      return (formatAddress(
        layer.value as Addressable,
      ) as unknown) as FormattedLayerValue;
    case LayerType.DEFUNCT:
      return formatDefunct(layer.value);
    default:
      return (layer as Layer).value as FormattedLayerValue;
  }
}

export const makeSelectFormattedLayers = (): LayersSelector<
  FormattedLayersProps
> =>
  createSelector(makeSelectLayers(), layers => {
    const actionLayers: FormattedLayersProps = {} as FormattedLayersProps;
    let index = 0;
    layers.forEach(layer => {
      const values = formatLayer(layer);
      Object.entries(values).forEach(([layerKey, value]) => {
        actionLayers[layer.action] ||= {};
        actionLayers[layer.action][layerKey] ||= {
          values: [],
          id: index,
          position: 0,
        };
        actionLayers[layer.action][layerKey].values.push(value);
        uniq(actionLayers[layer.action][layerKey].values);
        index += 1;
      });
    });
    return actionLayers;
  });

export const makeSelectIsModalOpen = (): LayersSelector<boolean> =>
  createSelector(selectLayersModalDomain, substate => substate.isOpen);

export const makeSelectError = (): LayersSelector<SerializedApiError | null> =>
  createSelector(selectLayersModalDomain, substate => substate.error);
