import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ResourceList from '@advitam/ui/components/ResourceList';
import { withSlice } from '@advitam/react';
import { FormattedApiError } from 'components/Format/ApiError';

import Wrapper from '../Wrapper';
import {
  makeSelectError,
  makeSelectFilteredResources,
  makeSelectIsLoading,
} from './selectors';
import { fetchResources } from './thunk';
import Filters from './List/Filters';
import Header from './List/Header';
import Row from './List/Row';
import slice from './slice';
import style from './Resources.module.scss';

function doNothing(): void {
  // As the name suggests
}

function Resources(): JSX.Element {
  const dispatch = useDispatch();

  const error = useSelector(makeSelectError());
  const isLoading = useSelector(makeSelectIsLoading());
  const resources = useSelector(makeSelectFilteredResources());

  useEffect(() => {
    dispatch(fetchResources());
  }, []);

  return (
    <Wrapper>
      <div className={style.wrapper}>
        <ResourceList.Container
          filters={<Filters />}
          header={<Header />}
          hasMore={false}
          isLoading={isLoading}
          error={error && <FormattedApiError error={error} />}
          fetchResources={doNothing}
        >
          {resources.map(resource => (
            <Row
              // eslint-disable-next-line react/no-array-index-key
              key={resource.id}
              resource={resource}
            />
          ))}
        </ResourceList.Container>
      </div>
    </Wrapper>
  );
}

export default withSlice(slice)(Resources);
