import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { MessageModal } from '@advitam/ui';

import messages from './messages';
import { makeSelectIsDestroyed } from './selectors';
import { setIsDestroyed } from './slice';

interface DestroyModalProps {
  path: string;
}

export default function DestroyModal({ path }: DestroyModalProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDestroyed = useSelector(makeSelectIsDestroyed());

  const onRedirect = (): void => {
    navigate(path, { replace: true });
    dispatch(setIsDestroyed(false));
  };

  return (
    <MessageModal
      isOpen={isDestroyed}
      title={<FormattedMessage id={messages.destroyModalTitle.id} />}
      message={<FormattedMessage id={messages.destroyModalMessage.id} />}
      ctaText={<FormattedMessage id={messages.destroyModalCta.id} />}
      onClose={onRedirect}
    />
  );
}
