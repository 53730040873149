import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get } from 'api/methods';

export interface AutocompleteResult {
  id: number;
  description: string;
}

export interface DealAutocompleteResult extends AutocompleteResult {
  uuid: string;
}

export interface DocumentTypeAutocompleteResult extends AutocompleteResult {
  pretty_name: string;
  slug: string;
}

export interface ProductAutocompleteResult extends AutocompleteResult {
  thumbnail: string;
  advitam_name: string;
}

export interface RuleSetAutocompleteResult extends AutocompleteResult {
  tag: string;
}

export interface SupplierWarehousesFlightsAutocompleteResult
  extends AutocompleteResult {
  air_company: string;
  arrival_city: string;
  arrival_country: string;
  arrival_country_code: string;
}

export default class Autocompletes {
  static readonly Path = {
    airlines: '/api/v1/autocompletes/airlines',
    airports: {
      index: '/api/v1/autocompletes/airports',
      warehouses: (id: number): string =>
        `/api/v1/autocompletes/airports/${id}/warehouses`,
    },
    clients: '/api/v1/autocompletes/clients',
    countries: '/api/v1/autocompletes/countries',
    crematoriums: '/api/v1/autocompletes/crematoriums',
    deals: '/api/v1/autocompletes/deals',
    defuncts: '/api/v1/autocompletes/defuncts',
    departments: '/api/v1/autocompletes/departments',
    documentTypes: '/api/v1/autocompletes/document_types',
    funeralParlors: '/api/v1/autocompletes/funeral_parlors',
    graveyards: '/api/v1/autocompletes/graveyards',
    hospitals: '/api/v1/autocompletes/hospitals',
    ruleSets: '/api/v1/autocompletes/rule_sets',
    products: '/api/v1/autocompletes/products',
    supplierWarehousesFlights:
      '/api/v1/autocompletes/supplier_warehouses_flights',
    worships: '/api/v1/autocompletes/worships',
  };

  static crematoriums(q?: string): ApiRequestDescriptor<AutocompleteResult> {
    return get(Autocompletes.Path.crematoriums, { q });
  }

  @authenticate
  static defuncts(q?: string): ApiRequestDescriptor<AutocompleteResult> {
    return get(Autocompletes.Path.defuncts, { q });
  }

  @authenticate
  static departments(q?: string): ApiRequestDescriptor<AutocompleteResult> {
    return get(Autocompletes.Path.departments, { q });
  }

  @authenticate
  static documentTypes(
    q?: string,
    editable?: boolean,
  ): ApiRequestDescriptor<DocumentTypeAutocompleteResult> {
    return get(Autocompletes.Path.documentTypes, { editable, q });
  }

  static funeralParlors(q?: string): ApiRequestDescriptor<AutocompleteResult> {
    return get(Autocompletes.Path.funeralParlors, { q });
  }

  static graveyards(q?: string): ApiRequestDescriptor<AutocompleteResult> {
    return get(Autocompletes.Path.graveyards, { q });
  }

  static hospitals(q?: string): ApiRequestDescriptor<AutocompleteResult> {
    return get(Autocompletes.Path.hospitals, { q });
  }

  static ruleSets(q?: string): ApiRequestDescriptor<RuleSetAutocompleteResult> {
    return get(Autocompletes.Path.ruleSets, { q });
  }
}
