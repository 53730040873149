import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { DealState } from '@advitam/api/models/Deal/State';
import { DealKoReason } from '@advitam/api/models/Deal/KoReason';
import { assert } from 'lib/support';

import { DEAL } from '../../constants';
import { makeSelectDeal } from '../../selectors.typed';
import { AppStateSubset } from '../../slice';

interface UpdateDealStateArgs {
  ko_comment: string | null;
  ko_reason: DealKoReason | null;
  state: DealState;
}

export const updateDealState = createAsyncThunk(
  `${DEAL}/SIDEBAR/UPDATE_DEAL_STATE`,
  async (newState: UpdateDealStateArgs, { rejectWithValue, getState }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      const { body } = await request(
        Api.V1.Deals.State.update(deal.uuid, newState),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
