import { intToHexColor } from './intToHexColor';

function stringToHashCode(str: string): number {
  const stringCharList = Array.from(str);
  return stringCharList.reduce(
    (hashCode, char) => Math.imul(31, hashCode) + char.charCodeAt(0),
    0,
  );
}

export function stringToHexColor(str: string): string {
  const strHashCode = stringToHashCode(str);
  return intToHexColor(strHashCode);
}
