import { Service } from 'models/Deal/Service';
import { ServiceType } from 'models/Deal/Service/Type';
import { DealPrestationComputeType } from 'models/Deal/Prestation/ComputeType';

import Engraving from './Engraving';
import Days from './Days';
import Holders from './Holders';

interface UpdateServiceFactoryProps {
  service: Service;
  onReset: (compute: DealPrestationComputeType) => void;
}

export default function UpdateServiceFactory({
  service,
  onReset,
}: UpdateServiceFactoryProps): JSX.Element {
  switch (service.type) {
    case ServiceType.ENGRAVING:
      return <Engraving onReset={onReset} />;
    case ServiceType.FUNERAL_PARLOR_TAX:
    case ServiceType.DEPOSIT_TAX:
    case ServiceType.HOSPITAL_FEES:
      return <Days onReset={onReset} />;
    case ServiceType.ADD_HOLDER_AC:
    case ServiceType.ADD_HOLDER_BC:
      return <Holders onReset={onReset} />;
    default:
      return <></>;
  }
}
