import { useCallback, useEffect, useState } from 'react';
import type { SerializedApiError } from '@advitam/api';
import { FormattedApiError } from '@advitam/ui';
import ErrorBannerBase from './Base';

interface ApiErrorBannerProps {
  errors: SerializedApiError | SerializedApiError[];
  onClose?: () => void;
}

export default function ApiErrorBanner({
  errors,
  onClose: superOnClose,
}: ApiErrorBannerProps): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(true);

  const onClose = useCallback(() => {
    setIsOpen(false);
    if (superOnClose) {
      superOnClose();
    }
  }, [setIsOpen, superOnClose]);

  useEffect(() => {
    setIsOpen(true);
  }, [errors]);

  return (
    <ErrorBannerBase isOpen={isOpen} onClose={onClose}>
      {[errors].flat().map(error => (
        <p>
          <FormattedApiError error={error} />
        </p>
      ))}
    </ErrorBannerBase>
  );
}
