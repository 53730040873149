import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { ClientJSON } from '@advitam/api/models/Client';

import { CLIENTS } from './constants';
import { State, AppStateSubset } from './slice';
import { ClientsFilters } from './types';

type ClientSelector<T> = Selector<AppStateSubset, T>;

export const selectClientsDomain = (state: AppStateSubset): State =>
  state[CLIENTS];

export const makeSelectClients = (): ClientSelector<ClientJSON[]> =>
  createSelector(selectClientsDomain, substate => substate.clients);

export const makeSelectLoading = (): ClientSelector<boolean> =>
  createSelector(selectClientsDomain, substate => substate.loading);

export const makeSelectError = (): ClientSelector<SerializedApiError | null> =>
  createSelector(selectClientsDomain, substate => substate.error);

export const makeSelectHasMorePages = (): ClientSelector<boolean> =>
  createSelector(selectClientsDomain, substate => substate.hasMorePages);

export const makeSelectNextClientsPage = (): ClientSelector<number> =>
  createSelector(selectClientsDomain, substate => substate.nextClientsPage);

export const makeSelectFilters = (): ClientSelector<ClientsFilters> =>
  createSelector(selectClientsDomain, ({ filters }) => filters);
