import { ReactNode } from 'react'

import Link from '../../Link'
import style from '../Pagination.module.scss'

interface NavigationProps {
  icon: ReactNode
  index: number
  onClick?: (i: number) => void
  getHref?: (i: number) => string
}

export default function Navigation({
  icon,
  index,
  onClick,
  getHref,
}: NavigationProps): JSX.Element | null {
  const classes = [style.navigation]

  if (onClick) {
    classes.push(style.button)
    return (
      <button type="button" className={classes.join(' ')} onClick={(): void => onClick(index)}>
        {icon}
      </button>
    )
  }

  if (getHref) {
    classes.push(style.link)
    return (
      <Link internal className={classes.join(' ')} href={getHref(index)}>
        {icon}
      </Link>
    )
  }

  return null
}
