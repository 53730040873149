import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { ApiResponse, pollUntil, request } from '@advitam/api';
import {
  ConflictJSON,
  DocumentJSON,
  ResourceType,
} from '@advitam/api/models/DocumentEditions';
import {
  DocumentUpload,
  DocumentUploadResource,
} from '@advitam/api/v1/DocumentEditions/types';

import { assert } from '@advitam/support';

import { DOCUMENTS_EDITOR } from './constants';
import { AppStateSubset } from './slice';
import {
  makeSelectCurrentSessionId,
  makeSelectCurrentSessionUploadedDocuments,
} from './selectors';
import { DocumentConflict, DocumentEdition, NewUploadResource } from './types';

async function createDocument(file: Blob): Promise<DocumentJSON> {
  const { body } = await request(
    Api.V1.DocumentEditions.Documents.create(file),
  );
  return body;
}

function isDocumentValid(res: ApiResponse<DocumentJSON>): boolean {
  const { pages, error } = res.body;
  return pages.length > 0 || Boolean(error);
}

async function fetchDocument(uuid: string): Promise<DocumentJSON> {
  const { body } = await pollUntil(
    Api.V1.DocumentEditions.Documents.show(uuid),
    isDocumentValid,
  );
  return body;
}

export const uploadDocument = createAsyncThunk(
  `${DOCUMENTS_EDITOR}_UPLOAD_DOCUMENT`,
  async (file: File, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const currentSessionId = makeSelectCurrentSessionId()(state);
    assert(currentSessionId !== null);

    try {
      const createdDocument = await createDocument(file);
      const savedDocument = await fetchDocument(createdDocument.uuid);
      return { document: savedDocument, currentSessionId };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const uploadDocuments = createAsyncThunk(
  `${DOCUMENTS_EDITOR}_UPLOAD_DOCUMENTS`,
  async (files: File[], { dispatch, getState }) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      await dispatch(uploadDocument(file));
    }

    const state = getState() as AppStateSubset;
    const uploadedDocuments = makeSelectCurrentSessionUploadedDocuments()(
      state,
    );

    return uploadedDocuments;
  },
);

function isValidUploadResource(
  resource: NewUploadResource,
): resource is DocumentUploadResource {
  if (resource.id) {
    return true;
  }

  if (resource.type === ResourceType.DEAL) {
    return Boolean(resource.deal.document_name);
  }

  return false;
}

function isValidDocumentUpload(
  document: DocumentEdition,
): document is DocumentUpload & DocumentEdition {
  return isValidUploadResource(document.resource);
}

function getDocumentsToUpload(documents: DocumentEdition[]): DocumentUpload[] {
  return documents.reduce<DocumentUpload[]>((prev, curr) => {
    const validPages = curr.pages.filter(page => !page.isRemoved);
    if (validPages.length === 0) {
      return prev;
    }

    assert(isValidDocumentUpload(curr));
    const document: DocumentUpload = {
      ...curr,
      pages: validPages,
    };

    return [...prev, document];
  }, []);
}

function getConflictedDocuments(
  documents: DocumentEdition[],
  conflicts: ConflictJSON[],
): DocumentConflict[] {
  return conflicts.reduce<DocumentConflict[]>((prev, curr) => {
    const conflictedDocument = documents.find(
      document => document.uuid === curr.uuid,
    );

    if (!conflictedDocument) {
      return prev;
    }

    return [...prev, { document: conflictedDocument, documentName: curr.name }];
  }, []);
}

interface SaveDocumentsArgs {
  documents: DocumentEdition[];
  shouldBypassConflicts?: true;
}

export const saveDocuments = createAsyncThunk(
  `${DOCUMENTS_EDITOR}_SAVE_DOCUMENTS`,
  async (
    { documents, shouldBypassConflicts }: SaveDocumentsArgs,
    { rejectWithValue },
  ) => {
    const documentsToUpload = getDocumentsToUpload(documents);
    if (documentsToUpload.length === 0) {
      return undefined;
    }

    try {
      const {
        body: { conflicts },
      } = await request(
        Api.V1.DocumentEditions.create(
          documentsToUpload,
          shouldBypassConflicts,
        ),
      );

      if (conflicts.length > 0) {
        return getConflictedDocuments(documents, conflicts);
      }

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
