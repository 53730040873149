import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { makeSelectUnreadNotificationCount } from './NotificationCenter/selectors';

export default function Favicon(): JSX.Element {
  const unreadNotificationCount = useSelector(
    makeSelectUnreadNotificationCount(),
  );

  const base = [
    // This is only a fallback on modern browsers, we should not need to override it.
    <link
      rel="icon"
      type="image/vnd.microsoft.icon"
      href="/favicon.ico"
      key="ico"
    />,
    // We do not want to have badges on it, this is only for the browser tab.
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/favicon/180x180.png"
      key="180"
    />,
  ];

  if (unreadNotificationCount > 0) {
    return (
      <Helmet>
        {base}
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/32x32-with-notifications.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/16x16-with-notifications.png"
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      {base}
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/16x16.png"
      />
    </Helmet>
  );
}
