import { ReactNode, useCallback } from 'react'

import { useBreakpoint } from '@advitam/react'

import Text from '../Text'
import ArrowUp from '../../images/icons/chevron-up.svg'
import ArrowDown from '../../images/icons/chevron-down.svg'
import style from './ResourceList.module.scss'
import { FlatButton } from '../Button'

const MD_BREAKPOINT = parseInt(style.md, 10)
const XL_BREAKPOINT = parseInt(style.xl, 10)

interface CellProps {
  children?: ReactNode
  className?: string
  hiddenOnMobile?: boolean
  hiddenOnTablet?: boolean
  onSort?: (descending: boolean) => void
}

export default function Cell({
  children,
  className = '',
  hiddenOnMobile = false,
  hiddenOnTablet = false,
  onSort,
}: CellProps): JSX.Element | null {
  /** @TODO Handle hidden cells in css
   *
   * Table layout require each cell to have it's own border.
   * Row's edge border and radius disapear when hiding first or last cell from css,
   * as :first-child/:last-child still find them in the DOM event if it's hidden
   */
  const isAboveTablet = useBreakpoint(MD_BREAKPOINT)
  const isAboveDesktop = useBreakpoint(XL_BREAKPOINT)
  const isHidden = (hiddenOnMobile && !isAboveTablet) || (hiddenOnTablet && !isAboveDesktop)

  const onSortAscending = useCallback(() => {
    if (onSort) {
      onSort(false)
    }
  }, [onSort])

  const onSortDescending = useCallback(() => {
    if (onSort) {
      onSort(true)
    }
  }, [onSort])

  if (isHidden) {
    return null
  }

  const classes = [style.cell, className].filter(Boolean)
  return (
    <Text small tagName="div" className={classes.join(' ')}>
      {children}
      {onSort && (
        <>
          <FlatButton onClick={onSortAscending} className={style.sort_arrow}>
            <ArrowUp />
          </FlatButton>
          <FlatButton onClick={onSortDescending} className={style.sort_arrow}>
            <ArrowDown />
          </FlatButton>
        </>
      )}
    </Text>
  )
}
