export enum MaterialValues {
  ADD = 'add',
  CANCEL = 'cancel',
  CHECK = 'check',
  CLOSE = 'close',
  DELETE = 'delete',
  EDIT = 'edit',
  FILE_UPLOAD = 'file_upload',
  FILE_DOWNLOAD_DONE = 'file_download_done',
  MENU = 'menu',
  OPEN_IN_NEW = 'open_in_new',
  REMOVE_RED_EYE = 'remove_red_eye',
  SAVE = 'save',
  UNDO = 'undo',
}
