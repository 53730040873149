import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { DealCommunicationJSON } from '@advitam/api/models/Deal/Communication';

import { HISTORY } from './constants';
import { fetchCommunications } from './thunk';
import type { CommunicationFilters } from './types';

export interface State {
  communications: DealCommunicationJSON[];
  filters: CommunicationFilters;
  isLoading: boolean;
  error: SerializedApiError | null;
  contentModalRecordIndex: number | null;
  mailContentModalRecordIndex: number | null;
  sort: SortOrder;
}

export interface AppStateSubset {
  [HISTORY]: State;
}

export interface SortOrder {
  column: keyof DealCommunicationJSON;
  descending: boolean;
}

export const initialState: State = {
  communications: [],
  filters: {},
  isLoading: false,
  error: null,
  contentModalRecordIndex: null,
  mailContentModalRecordIndex: null,
  sort: {
    column: 'date',
    descending: true,
  },
};

const slice = createSlice({
  name: HISTORY,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    resetFilters(state) {
      state.filters = {};
    },
    setFilters(state, { payload }: PayloadAction<CommunicationFilters>) {
      state.filters = payload;
    },

    openMailContentModal(state, { payload }: PayloadAction<number>) {
      state.mailContentModalRecordIndex = payload;
    },
    closeMailContentModal(state) {
      state.mailContentModalRecordIndex = null;
    },

    openContentModal(state, { payload }: PayloadAction<number>) {
      state.contentModalRecordIndex = payload;
    },
    closeContentModal(state) {
      state.contentModalRecordIndex = null;
    },

    setSort(state, { payload }: PayloadAction<SortOrder>) {
      state.sort = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCommunications.pending, state => {
      state.isLoading = true;
      state.communications = [];
    });
    builder.addCase(fetchCommunications.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.communications = payload;
    });
    builder.addCase(fetchCommunications.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const {
  resetFilters,
  setFilters,
  openMailContentModal,
  closeMailContentModal,
  openContentModal,
  closeContentModal,
  setSort,
} = slice.actions;
export default slice;
