import { authenticate, withSessionId } from '../../lib/decorators'
import { put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'

export class Disabled {
  @authenticate
  @withSessionId
  static update(supplierId: number, value: boolean): ApiRequestDescriptor<void> {
    return put(`/api/v1/suppliers/${supplierId}/disabled`, { value })
  }
}
