import { ReactElement, ReactNode } from 'react'
import FieldCardContent from './Content'
import style from './Field.module.scss'

interface FieldCardButtonProps {
  icon: ReactElement
  text: ReactNode
  className?: string
  onClick: () => void
}

export default function FieldCardButton({
  icon,
  text,
  onClick,
  className,
}: FieldCardButtonProps): JSX.Element {
  return (
    <button type="button" onClick={onClick} className={[style.card, className].join(' ')}>
      <FieldCardContent icon={icon} text={text} />
    </button>
  )
}
