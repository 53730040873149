import { Model } from '../../../Model'
import { ActionType } from './Type'

export interface DocumentRequestTodoActionJSON {
  id: number
  type: ActionType.DEAL_DOCUMENT_REQUEST
  deal_document_ids: number[] | null
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DocumentRequestTodoAction extends DocumentRequestTodoActionJSON {}

export class DocumentRequestTodoAction extends Model<DocumentRequestTodoActionJSON> {
  hasDealDocument(id: number): boolean {
    return this.deal_document_ids?.includes(id) || false
  }
}
