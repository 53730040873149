import PropTypes from 'prop-types';

import Arrow from 'images/icons/arrow.svg';

/**
 * Button to display or hide side bar.
 * This button appear for small and medium screens (tablet/smartphone)
 */
export default function ToggleSideBar({ toggleSideBarIsOpen }) {
  return (
    <button
      type="button"
      onClick={toggleSideBarIsOpen}
      className="sideBar__arrowButton"
    >
      <Arrow />
    </button>
  );
}

ToggleSideBar.propTypes = {
  /** function to toggle sidebar */
  toggleSideBarIsOpen: PropTypes.func.isRequired,
};
