import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import { Flight, FlightJSON } from '@advitam/api/models/Flight';

import { FLIGHT } from './constants';
import type { AppStateSubset, State } from './slice';
import type { NewFlight } from './types';

type FlightSelector<T> = Selector<AppStateSubset, T>;

const selectFlightDomain = (state: AppStateSubset): State => state[FLIGHT];

export const makeSelectRawFlight = (): FlightSelector<
  FlightJSON | NewFlight | null
> => createSelector(selectFlightDomain, ({ flight }) => flight);

export const makeSelectFlight = (): FlightSelector<Flight | null> =>
  createSelector(makeSelectRawFlight(), rawFlight =>
    rawFlight?.id ? new Flight(rawFlight) : null,
  );

export const makeSelectIsLoading = (): FlightSelector<boolean> =>
  createSelector(selectFlightDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): FlightSelector<boolean> =>
  createSelector(selectFlightDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): FlightSelector<boolean> =>
  createSelector(selectFlightDomain, ({ isDestroying }) => isDestroying);

export const makeSelectError = (): FlightSelector<SerializedApiError | null> =>
  createSelector(selectFlightDomain, ({ error }) => error);

export const makeSelectDestroyError = (): FlightSelector<SerializedApiError | null> =>
  createSelector(selectFlightDomain, ({ destroyError }) => destroyError);
