import { authenticate } from '../../../../lib/decorators'
import { del } from '../../../../lib/methods'
import { ApiRequestDescriptor } from '../../../../lib/request'

export class RemoveDocument {
  @authenticate
  static destroy(
    dealUuid: string,
    batchId: number,
    documentIds: number[],
  ): ApiRequestDescriptor<void> {
    return del(`/api/v1/deals/${dealUuid}/documents/batches/${batchId}/remove_document`, {
      documents: documentIds.map(id => id.toString()),
    })
  }
}
