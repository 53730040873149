import { StandardDefunctJSON } from 'models/Defunct';
import { DealState } from 'models/Deal/State';
import { DealType } from 'models/Deal/Type';

import { ClientResource, ClientResourceJSON, ClientResourceBase } from '.';

export interface ClientResourceDealBase extends ClientResourceBase {
  uuid: string;
  business_id: string;
  date: Date;
  final_price: number;
  type: DealType;
  invoice_id: string | null;
  defunct?: StandardDefunctJSON;
  state: DealState;
}

export interface ClientResourceDealJSON
  extends ClientResourceJSON,
    ClientResourceDealBase {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientResourceDeal extends ClientResourceDealBase {}
export class ClientResourceDeal extends ClientResource<
  ClientResourceDealJSON
> {}
