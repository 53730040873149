// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__version-row--L2JUK{display:flex;align-items:center;margin-top:4px;border-bottom:1px solid #ccc}.style__version-row--L2JUK>:first-child{flex-grow:1;margin-left:4px}.style__version-row--L2JUK>:not(:first-child){margin:6px 8px 4px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/containers/Documents/Toolbar/Versions/style.scss"],"names":[],"mappings":"AAEA,2BACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,4BAAA,CAEA,wCACE,WAAA,CACA,eAAA,CAGF,8CACE,kBAAA,CACA,cAAA","sourcesContent":["@import 'src/scss/mixins/vars';\n\n.version-row {\n  display: flex;\n  align-items: center;\n  margin-top: 4px;\n  border-bottom: 1px solid $border--dark;\n\n  > :first-child {\n    flex-grow: 1;\n    margin-left: 4px;\n  }\n\n  > :not(:first-child) {\n    margin: 6px 8px 4px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"version-row": `style__version-row--L2JUK`
};
export default ___CSS_LOADER_EXPORT___;
