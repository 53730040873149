export const enum PaymentMean {
  CHECK = 'check',
  TRANSFER = 'transfer',
  DEFUNCT_ACCOUNT = 'defunct-account',
  CASH = 'cash',
  FUNERAL_CONTRACT = 'funeral-contract',
  // Ingenico
  CREDIT_CARD = 'CreditCard',
  // Pledg
  THREE_TIMES = 'ThreeTimes',
  FOUR_TIMES = 'FourTimes',
  TEN_TIMES = 'TenTimes',
  TWELVE_TIMES = 'TwelveTimes',
  // Fintecture
  SEPA_TRANSFER = 'SepaTransfer',
  // Alma
  ALMA_TWO_TIMES = 'Alma2x',
  ALMA_THREE_TIMES = 'Alma3x',
  ALMA_FOUR_TIMES = 'Alma4x',
  ALMA_FIVE_TIMES = 'Alma5x',
  ALMA_SIX_TIMES = 'Alma6x',
  ALMA_SEVEN_TIMES = 'Alma7x',
  ALMA_EIGHT_TIMES = 'Alma8x',
  ALMA_NINE_TIMES = 'Alma9x',
  ALMA_TEN_TIMES = 'Alma10x',
  ALMA_ELEVEN_TIMES = 'Alma11x',
  ALMA_TWELVE_TIMES = 'Alma12x',
}
