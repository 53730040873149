import type { ApiRequestDescriptor } from '../../lib/request'
import { get, put } from '../../lib/methods'
import { authenticate, withSessionId } from '../../lib/decorators'
import type { CityhallJSON } from '../../models/Cityhall'
import type { CityhallBody } from './types'
import { serialize } from './serializers'

export class Cityhalls {
  @authenticate
  static show(id: number): ApiRequestDescriptor<CityhallJSON> {
    return get(`/api/v1/cityhalls/${id}`)
  }

  @authenticate
  @withSessionId
  static update(cityhall: CityhallBody): ApiRequestDescriptor<CityhallJSON> {
    return put(`/api/v1/cityhalls/${cityhall.id}`, serialize(cityhall))
  }
}
