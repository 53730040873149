import { SupplierJSON } from '@advitam/api/models/Supplier';
import { createSlice } from '@reduxjs/toolkit';
import { PRESTATION_FORM } from './constants';
import { fetchEntity } from './thunk';
import { Entity } from './types';

export interface State {
  entity: Entity | null;
  supplier: SupplierJSON | null;
  isLoading: boolean;
}

export interface AppStateSubset {
  [PRESTATION_FORM]: State;
}

export const initialState: State = {
  entity: null,
  supplier: null,
  isLoading: false,
};

const slice = createSlice({
  name: PRESTATION_FORM,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    reset(state) {
      state.entity = null;
      state.supplier = null;
      state.isLoading = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchEntity.pending.type, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchEntity.fulfilled, (state, { payload }) => {
      state.entity = payload.entity;
      state.supplier = payload.supplier;
      state.isLoading = false;
    });
    builder.addCase(fetchEntity.rejected.type, state => {
      state.isLoading = false;
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { reset } = slice.actions;
export default slice;
