import { defineMessages } from 'react-intl';

export default defineMessages({
  cellDocName: { id: 'app.components.DocumentList.cellDocName' },
  cellDocUuid: { id: 'app.components.DocumentList.cellDocUuid' },
  cellAction: { id: 'app.components.DocumentList.cellAction' },
  cellEntityType: { id: 'app.components.DocumentList.cellEntityType' },
  cellEntityName: { id: 'app.components.DocumentList.cellEntityName' },
  others: { id: 'app.components.DocumentList.others' },
  family: { id: 'app.components.DocumentList.family' },
  admin: { id: 'app.components.DocumentList.admin' },
  supplier: { id: 'app.components.DocumentList.supplier' },
  advitam: { id: 'app.components.DocumentList.advitam' },
  unexpectedDocumentUpload: {
    id: 'app.components.DocumentList.unexpectedDocumentUpload',
  },
  ressources: { id: 'app.components.DocumentList.ressources' },
});
