import style from './ProgressBar.module.scss'

interface ProgressBarProps {
  current: number
  total: number
  className?: string
}

export default function ProgressBar({ current, total, className }: ProgressBarProps): JSX.Element {
  const progressionPercentage = (current / total) * 100
  const isDone = current === total

  const classes = [style.container, className].filter(Boolean)
  const progressionClasses = [style.progression, isDone && style.done].filter(Boolean)

  return (
    <div className={classes.join(' ')}>
      <div
        className={progressionClasses.join(' ')}
        style={{ width: `${progressionPercentage}%` }}
      />
    </div>
  )
}
