import { defineMessages } from 'react-intl';

export default defineMessages({
  concession: { id: 'app.components.ConcessionSelection.concession' },
  rightholders: { id: 'app.components.ConcessionSelection.rightholders' },
  buyer: { id: 'app.components.ConcessionSelection.buyer' },
  person: { id: 'app.components.ConcessionSelection.person' },
  defaultSelect: { id: 'app.components.ConcessionSelection.defaultSelect' },
  concessionExists: {
    id: 'app.components.ConcessionSelection.concessionExists',
  },
  sepulture: { id: 'app.components.ConcessionSelection.sepulture' },
});
