import { DealState } from '@advitam/api/models/Deal/State';
import messages from './messages';

export const DEAL_STATE_QUOTE = DealState.QUOTE;
export const DEAL_STATE_ORDER = DealState.ORDER;
export const DEAL_STATE_OK = DealState.OK;
export const DEAL_STATE_KO = DealState.KO;
export const DEAL_STATE_FUNERAL_CONTRACT = DealState.FUNERAL_CONTRACT;
export const DEAL_STATE_FUNERAL_CONTRACT_OK = DealState.FUNERAL_CONTRACT_OK;
export const DEAL_STATE_STAGING = DealState.STAGING;

export const TODO_LIST = 'taches';
export const CLIENT = 'client';
export const DEAL_SUMMARY = 'resume';
export const STEPS = 'etapes';
export const PRODUCTS = 'produits';
export const SUPPLIERS = 'fournisseurs';
export const DOCUMENTS = 'documents';
export const PAYMENTS = 'paiements';
export const OTHER = 'autres';
export const HISTORY = 'historique';
export const MEMORIAL = 'espace-memoire';

export const FUNERAL_LINKS = [
  { hash: TODO_LIST, message: messages.todoList, onlyExisting: true },
  { hash: DEAL_SUMMARY, message: messages.dealSummary, onlyExisting: true },
  { hash: CLIENT, message: messages.client },
  { hash: STEPS, message: messages.steps },
  { hash: SUPPLIERS, message: messages.suppliers, onlyExisting: true },
  { hash: DOCUMENTS, message: messages.documents, onlyExisting: true },
  { hash: PAYMENTS, message: messages.payments, onlyExisting: true },
  { hash: HISTORY, message: messages.history, onlyExisting: true },
  { hash: MEMORIAL, message: messages.memorial, onlyExisting: true },
  { hash: OTHER, message: messages.others, onlyExisting: true },
];
export const ITEM_LINKS = [
  { hash: CLIENT, message: messages.client },
  { hash: STEPS, message: messages.steps },
  { hash: PRODUCTS, message: messages.products },
  { hash: SUPPLIERS, message: messages.suppliers, onlyExisting: true },
  { hash: DOCUMENTS, message: messages.documents, onlyExisting: true },
  { hash: PAYMENTS, message: messages.payments, onlyExisting: true },
  { hash: HISTORY, message: messages.history, onlyExisting: true },
  { hash: OTHER, message: messages.others, onlyExisting: true },
];
export const MARBLE_LINKS = [
  { hash: TODO_LIST, message: messages.todoList, onlyExisting: true },
  { hash: CLIENT, message: messages.client },
  { hash: STEPS, message: messages.prestations },
  { hash: SUPPLIERS, message: messages.suppliers, onlyExisting: true },
  { hash: DOCUMENTS, message: messages.documents, onlyExisting: true },
  { hash: PAYMENTS, message: messages.payments, onlyExisting: true },
  { hash: HISTORY, message: messages.history, onlyExisting: true },
];
