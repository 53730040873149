import { CoverageZoneType } from '@advitam/api/models/Entity/Coverage';

export const FRANCE_COUNTRY_CODE = 'FR';

export const enum ZoneType {
  CITYHALL,
  COUNTRY,
  DEPARTMENT,
}

export const COVERAGE_TYPE_ZONE_MAP = {
  [ZoneType.CITYHALL]: CoverageZoneType.CITYHALL,
  [ZoneType.DEPARTMENT]: CoverageZoneType.DEPARMENT,
  [ZoneType.COUNTRY]: CoverageZoneType.DEPARMENT,
};
