import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Api, { DealAutocompleteResult as Result } from 'api';
import { ButtonV2 as Button } from 'components/Button';
import Modal, { ButtonsValidation } from 'components/CustomModal/index.js';
import Autocomplete from 'containers/AutoComplete';
import actionMessages from 'messages/actions';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from './messages';
import { setParentDeal } from './actions.js';

import style from './style.scss';

export default function ParentModal(): JSX.Element {
  const intl = useIntl();
  const [parent, setParent] = useState<Result | null>(null);
  const dispatch = useDispatch();
  const dispatchParent = (definitiveParent: Result | null): void => {
    dispatch(setParentDeal(definitiveParent));
  };

  return (
    <Modal
      isOpen
      onRequestClose={(): void => dispatchParent(null)}
      className={style['parent-modal']}
    >
      <FormattedMessage {...messages.parentAssociation} tagName="h3" />
      <Autocomplete
        url={`${Api.V1.Autocompletes.Path.deals}?deal_type_eq_any[]=funeral&q=`}
        getKey={(item: Result): number => item.id}
        getValue={(item: Result): string => item.description}
        onChange={(): void => setParent(null)}
        onSelect={(_: unknown, item: Result): void => setParent(item)}
        placeholder={safeFormatMessage(intl, messages, 'defunctName')}
        saveValueOnSelect
        auth
      />

      <ButtonsValidation>
        <Button
          className="button button--red"
          onClick={(): void => dispatchParent(null)}
        >
          <FormattedMessage {...messages.doNotAssociate} />
        </Button>
        <Button
          className="button"
          disabled={parent === null}
          onClick={(): void => dispatchParent(parent)}
        >
          <FormattedMessage {...actionMessages.validate} />
        </Button>
      </ButtonsValidation>
    </Modal>
  );
}
