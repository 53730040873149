import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { withSlice } from '@advitam/react';
import { CompactDesign, ErrorText, ResourceList } from '@advitam/ui';
import { FormattedApiError } from 'components/Format/ApiError';

import { useSearchParams } from 'react-router-dom';
import ClientList from './ClientList';
import Filters from './Filters';
import Header from './Header';
import slice, { setFilters } from './slice';
import { fetchClients } from './thunk';
import {
  makeSelectHasMorePages,
  makeSelectLoading,
  makeSelectError,
} from './selectors';
import type { ClientsFilters } from './types';

function Clients(): JSX.Element {
  const dispatch = useDispatch();
  const hasMorePages = useSelector(makeSelectHasMorePages());
  const error = useSelector(makeSelectError());
  const isLoading = useSelector(makeSelectLoading());
  const [searchParams] = useSearchParams();

  const fetch = useCallback(() => {
    dispatch(fetchClients());
  }, [dispatch, fetchClients]);

  const onFilterChange = useCallback(
    (filters: ClientsFilters): void => {
      dispatch(setFilters(filters));
      fetch();
    },
    [dispatch, fetch],
  );

  useEffect(() => {
    const q = searchParams.get('q');
    if (q) {
      dispatch(setFilters({ q }));
    }
    fetch();
  }, []);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      <CompactDesign>
        <main>
          <ResourceList.Container
            error={
              error && (
                <ErrorText>
                  <FormattedApiError error={error} />
                </ErrorText>
              )
            }
            filters={<Filters onChange={onFilterChange} />}
            hasMore={hasMorePages}
            header={<Header />}
            isLoading={isLoading}
            useWindow={false}
            fetchResources={fetch}
          >
            <ClientList />
          </ResourceList.Container>
        </main>
      </CompactDesign>
    </>
  );
}

export default withSlice(slice)(Clients);
