import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';

import { PaymentJSON } from 'models/Payment';

import { PAYMENTS_SPACE_REFUND_MODAL } from './constants';
import { AppStateSubset, State } from './slice';

type PaymentsRefundSelector<T> = Selector<AppStateSubset, T>;

const selectPaymentsRefundDomain = (state: AppStateSubset): State =>
  state[PAYMENTS_SPACE_REFUND_MODAL];

export const makeSelectPaymentToRefund = (): PaymentsRefundSelector<PaymentJSON | null> =>
  createSelector(
    selectPaymentsRefundDomain,
    ({ paymentToRefund }) => paymentToRefund,
  );

export const makeSelectIsLoading = (): PaymentsRefundSelector<boolean> =>
  createSelector(selectPaymentsRefundDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): PaymentsRefundSelector<SerializedApiError | null> =>
  createSelector(selectPaymentsRefundDomain, ({ error }) => error);
