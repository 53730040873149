import type { DealCommunication } from '@advitam/api/models/Deal/Communication';
import { CommunicationChannelType } from '@advitam/api/models/Deal/Communication/ChannelType';
import {
  CroppedText,
  FormattedDate,
  FormattedDuration,
  ResourceList,
  Tooltip,
} from '@advitam/ui';
import Hourglass from '@advitam/ui/images/icons/hourglass.svg';
import Phone from '@advitam/ui/images/icons/phone.svg';
import BusinessUserBadge from 'components/BusinessUserBadge';

import Attachments from './Details/Attachments';
import Audio from './Details/Audio';
import Content from './Details/Content';
import Download from './Details/Download';
import Mean from './Mean';
import Status from './Status';
import { formatPhoneNumber } from './utils';
import style from './Row.module.scss';

interface RowProps {
  communication: DealCommunication;
  index: number;
}

export default function Row({ communication, index }: RowProps): JSX.Element {
  let recipientTooltip = communication.entity_name;
  switch (communication.channel_type) {
    case CommunicationChannelType.EMAIL:
    case CommunicationChannelType.LETTER:
      recipientTooltip = `${communication.entity_name} <${communication.channel_value}>`;
      break;
    case CommunicationChannelType.SMS:
    case CommunicationChannelType.FAX:
    case CommunicationChannelType.PHONE:
      recipientTooltip = `${communication.entity_name} <${formatPhoneNumber(
        communication.channel_value,
      )}>`;
      break;
    default:
      break;
  }

  return (
    <ResourceList.Row className={style.row}>
      <ResourceList.Cell>
        <div className={style.state}>
          <Mean communication={communication} />
          <Status communication={communication} />
        </div>
      </ResourceList.Cell>
      <ResourceList.Cell className={style.recipient}>
        <Tooltip content={recipientTooltip}>
          <span className={style.recipient_text}>
            {communication.entity_name}
          </span>
        </Tooltip>
      </ResourceList.Cell>
      <ResourceList.Cell>
        {communication.user_id && (
          <BusinessUserBadge userId={communication.user_id} />
        )}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.date}>
        {communication.date && <FormattedDate value={communication.date} />}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.wait_time}>
        {communication.wait_time && (
          <>
            <Hourglass />
            <FormattedDuration value={communication.wait_time} />
          </>
        )}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.duration}>
        {communication.duration && (
          <>
            <Phone />
            <FormattedDuration value={communication.duration} />
          </>
        )}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.summary}>
        <CroppedText tooltipClassName={style.summary_text}>
          {communication.subject}
        </CroppedText>
      </ResourceList.Cell>
      <ResourceList.Cell>
        <div className={style.details}>
          <Content communication={communication} index={index} />
          <Audio communication={communication} />
          <Download communication={communication} />
          <Attachments communication={communication} />
        </div>
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
