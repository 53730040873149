import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { withSlice } from '@advitam/react'
import { FlatButton, Text } from '@advitam/ui'
import Times from '@advitam/ui/images/icons/times.svg'
import MobileLogo from '@advitam/ui/images/mobile_logo.svg'

import Conversation from './Conversation'
import slice from './slice'
import OpenChat from './images/open-chat.svg'
import messages from './messages'
import { makeSelectConversationUuid } from './selectors'
import { createConversation } from './thunk'
import style from './Prof.module.scss'

interface ProfModalProps {
  chatbotSlug: string
}

function ProfModal({ chatbotSlug }: ProfModalProps): JSX.Element {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const sessionId = useSelector(makeSelectConversationUuid())

  const onOpen = useCallback(() => {
    if (!sessionId) {
      dispatch(createConversation({ chatbotSlug }))
    }
    setIsOpen(true)
  }, [chatbotSlug, sessionId, setIsOpen])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <div className={style.wrapper}>
      {isOpen ? (
        <div className={style.frame}>
          <div className={style.header}>
            <div className={style.title}>
              <MobileLogo className={style.logo} />
              <Text large tagName="div">
                <FormattedMessage id={messages.modalTitle.id} />
              </Text>
            </div>
            <FlatButton className={style.close} onClick={onClose}>
              <Times />
            </FlatButton>
          </div>
          <div className={style.chat}>
            <Conversation />
          </div>
        </div>
      ) : (
        <button className={style.opener} onClick={onOpen}>
          <OpenChat />
        </button>
      )}
    </div>
  )
}

export default withSlice<ProfModalProps>(slice)(ProfModal)
