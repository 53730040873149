import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { Sidebar, usePreventLeaving } from 'containers/Crud';

import { Path } from './constants';
import { makeSelectFlight } from './selectors';
import messages from './messages';

export default function FlightSidebar(): JSX.Element {
  const flight = useSelector(makeSelectFlight());

  const { dirty } = useFormState();
  usePreventLeaving(!flight?.id || dirty);

  return (
    <Sidebar.Menu>
      <Sidebar.Title isReadonly fieldName="auto_name" />
      {flight?.current_update_dt && flight?.current_update_user_id && (
        <Sidebar.LastUpdate
          date={flight.current_update_dt}
          userId={flight.current_update_user_id}
        />
      )}
      <Sidebar.LinksSection>
        <Sidebar.Link
          to={Path.GENERAL}
          message={<FormattedMessage id={messages.general.id} />}
        />
        {flight?.id && (
          <Sidebar.Link
            to={Path.RATES}
            message={<FormattedMessage id={messages.rates.id} />}
          />
        )}
      </Sidebar.LinksSection>
    </Sidebar.Menu>
  );
}
