import { defineMessages } from 'react-intl';

export default defineMessages({
  mr: { id: 'app.containers.Deal.Payments.Payment.mr' },
  mrs: { id: 'app.containers.Deal.Payments.Payment.mrs' },
  civ: { id: 'app.containers.Deal.Payments.Payment.civ' },
  invoices: { id: 'app.containers.Deal.Payments.Payment.invoices' },
  addPayment: { id: 'app.containers.Deal.Payments.Payment.addPayment' },
  savePayments: { id: 'app.containers.Deal.Payments.Payment.savePayments' },
  amount: { id: 'app.containers.Deal.Payments.Payment.amount' },
  method: { id: 'app.containers.Deal.Payments.Payment.method' },
  withdrawal: { id: 'app.containers.Deal.Payments.Payment.withdrawal' },
  cash: { id: 'app.containers.Deal.Payments.Payment.cash' },
  check: { id: 'app.containers.Deal.Payments.Payment.check' },
  transfer: { id: 'app.containers.Deal.Payments.Payment.transfer' },
  'defunct-account': {
    id: 'app.containers.Deal.Payments.Payment.defunct-account',
  },
  card: { id: 'app.containers.Deal.Payments.Payment.card' },
  firstName: { id: 'app.containers.Deal.Payments.Payment.firstName' },
  lastName: { id: 'app.containers.Deal.Payments.Payment.lastName' },
  state: { id: 'app.containers.Deal.Payments.Payment.state' },
  waiting: { id: 'app.containers.Deal.Payments.Payment.waiting' },
  error: { id: 'app.containers.Deal.Payments.Payment.error' },
  accepted: { id: 'app.containers.Deal.Payments.Payment.accepted' },
  cancel: { id: 'app.containers.Deal.Payments.Payment.cancel' },
  reimbursed: { id: 'app.containers.Deal.Payments.Payment.reimbursed' },
  guarantee: { id: 'app.containers.Deal.Payments.Payment.guarantee' },
  staging: { id: 'app.containers.Deal.Payments.Payment.staging' },
  unknown: { id: 'app.containers.Deal.Payments.Payment.unknown' },
  checkNumber: { id: 'app.containers.Deal.Payments.Payment.checkNumber' },
  isCompany: { id: 'app.containers.Deal.Payments.Payment.isCompany' },
  isCompanyValue: { id: 'app.containers.Deal.Payments.Payment.isCompanyValue' },
  companyName: { id: 'app.containers.Deal.Payments.Payment.companyName' },
  paid: { id: 'app.containers.Deal.Payments.Payment.paid' },
  titlePayment: { id: 'app.containers.Deal.Payments.Payment.titlePayment' },
  CreditCard: { id: 'app.containers.Deal.Payments.Payment.CreditCard' },
  SepaTransfer: { id: 'app.containers.Deal.Payments.Payment.SepaTransfer' },
  FACILYPAY3X: { id: 'app.containers.Deal.Payments.Payment.FACILYPAY3X' },
  FACILYPAY4X: { id: 'app.containers.Deal.Payments.Payment.FACILYPAY4X' },
  ThreeTimes: { id: 'app.containers.Deal.Payments.Payment.ThreeTimes' },
  FourTimes: { id: 'app.containers.Deal.Payments.Payment.FourTimes' },
  TenTimes: { id: 'app.containers.Deal.Payments.Payment.TenTimes' },
  TwelveTimes: { id: 'app.containers.Deal.Payments.Payment.TwelveTimes' },
  Alma2x: { id: 'app.containers.Deal.Payments.Payment.Alma2x' },
  Alma3x: { id: 'app.containers.Deal.Payments.Payment.Alma3x' },
  Alma4x: { id: 'app.containers.Deal.Payments.Payment.Alma4x' },
  Alma5x: { id: 'app.containers.Deal.Payments.Payment.Alma5x' },
  Alma6x: { id: 'app.containers.Deal.Payments.Payment.Alma6x' },
  Alma7x: { id: 'app.containers.Deal.Payments.Payment.Alma7x' },
  Alma8x: { id: 'app.containers.Deal.Payments.Payment.Alma8x' },
  Alma9x: { id: 'app.containers.Deal.Payments.Payment.Alma9x' },
  Alma10x: { id: 'app.containers.Deal.Payments.Payment.Alma10x' },
  Alma11x: { id: 'app.containers.Deal.Payments.Payment.Alma11x' },
  Alma12x: { id: 'app.containers.Deal.Payments.Payment.Alma12x' },
  Deffered: { id: 'app.containers.Deal.Payments.Payment.Deffered' },
  noPaymentDate: { id: 'app.containers.Deal.Payments.Payment.noPaymentDate' },
  paymentDate: { id: 'app.containers.Deal.Payments.Payment.paymentDate' },
  fundingAgency: { id: 'app.containers.Deal.Payments.Payment.fundingAgency' },
  fundingAgencyCheck: {
    id: 'app.containers.Deal.Payments.Payment.fundingAgencyCheck',
  },
  funeralContactNumber: {
    id: 'app.containers.Deal.Payments.Payment.funeralContactNumber',
  },
  'funeral-contract': {
    id: 'app.containers.Deal.Payments.Payment.funeral-contract',
  },
  isReceived: { id: 'app.containers.Deal.Payments.Payment.isReceived' },
  refund: { id: 'app.containers.Deal.Payments.Payment.refund' },
  receivedNo: { id: 'app.containers.Deal.Payments.Payment.receivedNo' },
  receivedYes: { id: 'app.containers.Deal.Payments.Payment.receivedYes' },
  receivedLost: { id: 'app.containers.Deal.Payments.Payment.receivedLost' },
});
