import { defineMessages } from 'react-intl';

export default defineMessages({
  edit: { id: 'app.containers.Billing.List.edit' },
  invoiceUploaded: { id: 'app.containers.Billing.List.invoiceUploaded' },
  noPaymentInfo: { id: 'app.containers.Billing.List.noPaymentInfo' },
  paidOn: { id: 'app.containers.Billing.List.paidOn' },
  pay: { id: 'app.containers.Billing.List.pay' },
  openDeal: { id: 'app.containers.Billing.List.openDeal' },
  openInvoice: { id: 'app.containers.Billing.List.openInvoice' },
  removePayment: { id: 'app.containers.Billing.List.removePayment' },
  validate: { id: 'app.containers.Billing.List.validate' },
  validated: { id: 'app.containers.Billing.List.validated' },
});
