import * as V1 from './v1';
import * as V2 from './v2';
import { ApiError } from './Error/ApiError';

export {
  ApiPayload,
  ApiResponse,
  ApiRequestDescriptor,
  request,
  requestAsync,
} from './request';
export { authenticate } from './decorators';
export { get, post, put, del } from './methods';

export type AutocompleteResult = V1.AutocompleteResult;
export type DealAutocompleteResult = V1.DealAutocompleteResult;
export { ApiError, SerializedApiError, isApiError } from './Error/ApiError';
export type ProductAutocompleteResult = V1.ProductAutocompleteResult;
export { ErrorCodes } from './Error/codes';
export { V1 };
export { V2 };
export default { ApiError, V1, V2 };
