import { useCallback, useRef } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { assert } from '@advitam/support';
import { Button, HardSpinner, Tooltip } from '@advitam/ui';
import { Layout, addCollectionItem } from 'containers/Crud';
import { makeSelectUser } from 'slices/auth';

import Zone from './Zone';
import {
  AUTO_ADDITION_SKELETON,
  SUPPLIER_WAREHOUSE_ZONE_SKELETON,
} from './constants';
import messages from './messages';
import {
  makeSelectHasMapSynchronized,
  makeSelectIsSaving,
  makeSelectWarehouseAddress,
} from './selectors';
import { synchronizeMapCoverage } from './thunk';
import type { WarehouseZonesForm } from './types';
import style from './Zones.module.scss';
import { makeSelectCanEdit } from '../../../selectors';
import { makeSelectIsDisabled } from '../../selectors';

const SECTION_PREFIX = 'sectionValues';

export default function Content(): JSX.Element {
  const dispatch = useDispatch();
  const warehouseAddress = useSelector(makeSelectWarehouseAddress());
  const isSaving = useSelector(makeSelectIsSaving());
  const hasAlreadySynchronized = useSelector(makeSelectHasMapSynchronized());
  const isWarehouseDisabled = useSelector(makeSelectIsDisabled());
  const gridContainer = useRef<HTMLDivElement>(null);

  const form = useForm<WarehouseZonesForm>();
  const { sectionValues } = useFormState<WarehouseZonesForm>().values;

  const user = useSelector(makeSelectUser());
  const canUserEdit = useSelector(makeSelectCanEdit());

  const onAddZone = useCallback(() => {
    assert(gridContainer.current !== null);
    addCollectionItem(
      form,
      SECTION_PREFIX,
      {
        coverage: [],
        zone: SUPPLIER_WAREHOUSE_ZONE_SKELETON,
        autoAddition: {
          ...AUTO_ADDITION_SKELETON,
          centerAddress: warehouseAddress,
        },
      },
      gridContainer.current,
    );
  }, [form, gridContainer, warehouseAddress]);

  const onSynchronizeMap = useCallback(() => {
    dispatch(synchronizeMapCoverage());
  }, [dispatch]);

  const onZoneDeletion = useCallback(
    (zoneIndex: number): void => {
      const newZones = [...sectionValues];
      newZones.splice(zoneIndex, 1);
      form.change(SECTION_PREFIX, newZones);
    },
    [form, sectionValues],
  );

  const onZoneDuplication = useCallback(
    (zoneIndex: number): void => {
      assert(gridContainer.current !== null);
      addCollectionItem(
        form,
        SECTION_PREFIX,
        {
          coverage: [
            sectionValues[zoneIndex].coverage.map(coverage => ({
              ...coverage,
              id: undefined,
            })),
          ],
          zone: { ...sectionValues[zoneIndex].zone, id: undefined },
          autoAddition: {
            ...AUTO_ADDITION_SKELETON,
            centerAddress: warehouseAddress,
          },
        },
        gridContainer.current,
      );
    },
    [form, sectionValues, warehouseAddress],
  );

  return (
    <Layout.Container>
      <Layout.ColumnGroup className={style.actions}>
        {user?.isAdmin && (
          <Tooltip
            content={<FormattedMessage id={messages.warehouseDisabled.id} />}
            disabled={!isWarehouseDisabled}
          >
            <Button
              outline
              disabled={hasAlreadySynchronized || isWarehouseDisabled}
              onClick={onSynchronizeMap}
              text={<FormattedMessage id={messages.synchronizeMap.id} />}
            />
          </Tooltip>
        )}
        <Button
          primary
          disabled={!canUserEdit}
          className={style.add}
          onClick={onAddZone}
          text={<FormattedMessage id={messages.addZone.id} />}
        />
      </Layout.ColumnGroup>

      <Field name={SECTION_PREFIX}>
        {(): null =>
          // Declare the field so final-form picks up array changes
          null
        }
      </Field>

      <Layout.FieldsetGrid ref={gridContainer}>
        {sectionValues.map((_, idx) => (
          <Zone
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            zoneIndex={idx}
            onZoneDeletion={(): void => onZoneDeletion(idx)}
            onZoneDuplication={(): void => onZoneDuplication(idx)}
          />
        ))}
      </Layout.FieldsetGrid>

      {isSaving && <HardSpinner />}
    </Layout.Container>
  );
}
