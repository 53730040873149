import type { ComponentType, SVGAttributes } from 'react';
import type { IntlShape, MessageDescriptor } from 'react-intl';

import type { BatchSignatureNotificationJSON } from '@advitam/api/models/Notification';
import { SignatureState } from '@advitam/api/models/Signature/State';
import { Path } from 'containers/App/constants';
import { Path as FuneralPath } from 'containers/Deal/Funeral/constants';
import Signature from 'images/icons/signature.svg';

import SignatureFailed from './icons/signature-fail.svg';
import SignatureFailedStandalone from './icons/signature-fail.standalone.png.bin';
import SignatureStandalone from './icons/signature.standalone.png.bin';
import type { TileProps } from './TileProps';
import messages from './messages';
import style from './NotificationTile.module.scss';

export default function getBatchSignatureProps(
  notification: BatchSignatureNotificationJSON,
  intl: IntlShape,
): TileProps {
  const isFuneralDeal = notification.comp_info.deal_type === 'funeral';

  let bodyMessage: MessageDescriptor;
  let titleMessage: MessageDescriptor;
  let IconComponent: ComponentType<SVGAttributes<SVGElement>>;
  let icon: string;
  let link: string;

  switch (notification.comp_info.signature_state) {
    case SignatureState.FINISHED:
      titleMessage = messages.finishedBatchSignatureTitle;
      bodyMessage = messages.finishedBatchSignatureBody;
      IconComponent = Signature;
      icon = SignatureStandalone;
      link = Path.DEAL(notification.comp_info.deal_uuid, 'taches');
      break;
    case SignatureState.REFUSED:
      titleMessage = messages.canceledBatchSignatureTitle;
      bodyMessage = messages.canceledBatchSignatureBody;
      link = Path.DEAL(
        notification.comp_info.deal_uuid,
        isFuneralDeal ? FuneralPath.IDENTITY : 'client',
      );
      IconComponent = function SignatureFailedIcon(): JSX.Element {
        return <SignatureFailed className={style.signature_refused} />;
      };
      icon = SignatureFailedStandalone;
      break;
    default:
      // Should not happen, but handle gracefully if it does
      titleMessage = messages.batchSignatureTitle;
      bodyMessage = messages.batchSignatureBody;
      link = Path.DEAL(notification.comp_info.deal_uuid, 'taches');
      IconComponent = Signature;
      icon = SignatureStandalone;
      break;
  }

  return {
    link,
    IconComponent,
    icon,
    title: intl.formatMessage(titleMessage),
    body: intl.formatMessage(bodyMessage, {
      clientName: notification.comp_info.owner_full_name,
      signatureState: notification.comp_info.signature_state,
    }),
  };
}
