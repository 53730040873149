import type { CSSProperties } from 'react';

import { useProduct } from '../../ProductListProvider';
import style from '../List.module.scss';

interface PhotoCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function PhotoCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: PhotoCellProps): JSX.Element | null {
  const { product } = useProduct(rowIndex);

  return (
    <div
      style={wrapperStyle}
      className={[className, style.photo_cell].join(' ')}
    >
      {product?.thumbnail && <img src={product.thumbnail} alt="" />}
    </div>
  );
}
