import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import { injectIntl } from 'react-intl';
import style from './style.scss';

const PLACEHOLDER_CHAR = '_';

export function MaskedInputField({
  intl,
  className,
  mask,
  message,
  errorMessage,
  hideErrorMessage,
  input: { onChange, onBlur, value, name, placeholder, disabled },
  meta: { error, touched },
}) {
  const { formatMessage } = intl;
  const ref = useRef();

  useEffect(() => {
    const { input } = ref.current;
    const pos = input.value.indexOf(PLACEHOLDER_CHAR);
    const current = input.selectionStart;

    if (
      pos >= 0 &&
      current > pos &&
      input.setSelectionRange &&
      input === document.activeElement
    ) {
      input.setSelectionRange(pos, pos);
    }
  }, [value, ref.current && ref.current.selectionStart]);

  return (
    <label className={`wizard--input ${className}`} htmlFor={name}>
      <span>{message && formatMessage(message)}</span>
      <MaskedInput
        id={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        mask={mask}
        className={touched && error ? style.errored : ''}
        disabled={disabled}
        ref={ref}
      />
      {!hideErrorMessage && touched && error && (
        <span className="error">
          {formatMessage(errorMessage || { id: error })}
        </span>
      )}
    </label>
  );
}

MaskedInputField.defaultProps = {
  meta: {},
  className: '',
  hideErrorMessage: false,
};

MaskedInputField.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object,
  mask: PropTypes.string.isRequired,
  errorMessage: PropTypes.object,
  intl: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  hideErrorMessage: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default injectIntl(MaskedInputField);
