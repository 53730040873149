import { AsyncThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { TodoItemJSON } from '@advitam/api/models/Deal/TodoItem';
import { DealType } from '@advitam/api/models/Deal/Type';
import { assert } from '@advitam/support';

import { SharingSpaceUrl } from 'utils/urls';

import { fetchBatchesLogs } from '../../DealMarble/HistorySection/thunk';
import { fetchCommunications } from '../History/thunk';
import type { AppStateSubset as DealAppStateSubset } from '../../slice';
import { makeSelectDeal } from '../../selectors.typed';
import { TODO_LIST } from './constants';
import { makeSelectItems } from './selectors';
import type { AppStateSubset as TodolistAppStateSubset } from './slice';

type AppStateSubset = DealAppStateSubset & TodolistAppStateSubset;

export const fetchItems = createAsyncThunk(
  `${TODO_LIST}/FETCH_ITEMS`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      const { body } = await request(Api.V1.Deals.TodoItems.index(deal.uuid));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

interface FetchItemArgs {
  itemId: number;
  force?: boolean;
}

export const fetchItem = createAsyncThunk(
  `${TODO_LIST}/FETCH_ITEM`,
  async ({ itemId, force }: FetchItemArgs, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const items = makeSelectItems()(state);
    const existingItem = items.find(item => item.id === itemId);

    /** TODO: Remove this check and handle it in cable resource
     * It is mandatory atm as cable does not have session_id
     * and dispatch the fetch even if we are the dispatcher
     */
    if (existingItem && !force) {
      return existingItem;
    }

    try {
      const { body } = await request(Api.V1.Deals.TodoItems.show(itemId));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateItem = createAsyncThunk(
  `${TODO_LIST}/UPDATE_ITEM`,
  async (item: TodoItemJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Deals.TodoItems.update(item));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createAccount = createAsyncThunk(
  `${TODO_LIST}/CREATE_ACCOUNT`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    const dealUrl = [SharingSpaceUrl.DEAL, deal.id].join('/');
    const redirectUrl = SharingSpaceUrl.CREATE_ACCOUNT;

    try {
      await request(
        Api.V1.Deals.Welcome.create(deal.uuid, dealUrl, redirectUrl),
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const historyThunk: AsyncThunkAction<unknown, void, any> =
        deal.deal_type === DealType.FUNERAL
          ? fetchCommunications()
          : fetchBatchesLogs();
      await Promise.all([dispatch(fetchItems()), dispatch(historyThunk)]);

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(historyThunk);
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
