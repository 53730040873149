import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: {
    id: 'app.containers.Deal.Sections.Todolist.SupplierBookingModal.title',
  },
  confirmBooking: {
    id:
      'app.containers.Deal.Sections.Todolist.SupplierBookingModal.confirmBooking',
  },
  confirmPreBooking: {
    id:
      'app.containers.Deal.Sections.Todolist.SupplierBookingModal.confirmPreBooking',
  },
});
