import { ADDRESS_SKELETON } from './constants'
import type { Address } from './types'

export function formatAddressName(streetNumber: string | null, streetName: string | null): string {
  return [streetNumber || '', streetName || ''].join(' ').trim()
}

export function format(address: Address): string {
  const result: string[] = []

  const street = formatAddressName(address.street_number, address.street_name)
  if (street !== '') {
    result.push(street)
  }
  const city = [address.postal_code || '', address.city || ''].join(' ').trim()
  if (city !== '') {
    result.push(city)
  }
  if (address.country) {
    result.push(address.country)
  }

  return result.join(', ')
}

export function serialize(place: google.maps.GeocoderResult): Address {
  const address = { ...ADDRESS_SKELETON }

  place.address_components.forEach(component => {
    if (component.types[0] === 'locality') {
      address.city = component.long_name
    } else if (component.types[0] === 'postal_code') {
      address.postal_code = component.long_name
    } else if (component.types[0] === 'country') {
      address.country = component.long_name
      address.country_code = component.short_name
    } else if (component.types[0] === 'colloquial_area') {
      address.street_name = component.long_name
    } else if (component.types[0] === 'street_number') {
      address.street_number = component.long_name
    } else if (component.types[0] === 'route') {
      address.street_name = component.long_name
    }
  })

  address.latitude = place.geometry.location.lat()
  address.longitude = place.geometry.location.lng()
  return address
}
