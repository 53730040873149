import { TodoItem, TodoType } from '@advitam/api/models/Deal/TodoItem';
import {
  DefunctSizeAction,
  MarbleWorkAction,
  RequestDocumentAction,
} from './Action';
import {
  AccountCreationTask,
  DefaultTask,
  PreCasketingTask,
  SendBatchTask,
  SupplierBookingTask,
} from './Task';

interface TodoProps {
  item: TodoItem;
}

export default function Todo({ item }: TodoProps): JSX.Element {
  if (item.isDone) {
    return <DefaultTask item={item} />;
  }

  switch (item.todo_type) {
    case TodoType.SEND_BATCH:
      return (
        <>
          <SendBatchTask item={item} />
          <RequestDocumentAction item={item} />
        </>
      );
    case TodoType.ACCOUNT_CREATION:
      return <AccountCreationTask item={item} />;
    case TodoType.BOOK_PRE_CASKETING:
      return <PreCasketingTask item={item} />;
    case TodoType.INFO_DEFUNCT:
      return (
        <>
          <DefaultTask item={item} />
          <DefunctSizeAction />
        </>
      );
    case TodoType.INFO_MARBLE_WORK:
      return (
        <>
          <DefaultTask item={item} />
          <MarbleWorkAction />
        </>
      );
    case TodoType.REQUEST_DOCUMENT:
      return (
        <>
          <DefaultTask item={item} />
          <RequestDocumentAction item={item} />
        </>
      );
    case TodoType.BOOK_SUPPLIERS:
    case TodoType.PRE_BOOK_SUPPLIERS:
      return <SupplierBookingTask item={item} />;
    default:
      return <DefaultTask item={item} />;
  }
}
