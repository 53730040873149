import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import { useFixedBody } from '@advitam/react'

import Collapsible from '../../Collapsible'
import cartStyle from '../ShoppingCart.module.scss'
import Title from './parts/Title'
import Subtitle from './parts/Subtitle'
import messages from '../messages'
import style from './Sticky.module.scss'

interface StickyProps {
  title: ReactNode
  withSubtitle?: boolean
  totals: ReactNode
  totalPrice: ReactNode
  children: ReactNode | ReactNode[]
  className?: string
  action?: ReactNode
  showOnXl?: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export default function Sticky({
  title,
  withSubtitle,
  totals,
  totalPrice,
  children,
  className = '',
  action,
  showOnXl = false,
  isOpen,
  setIsOpen,
}: StickyProps): JSX.Element {
  useFixedBody(isOpen)

  let subtitle: ReactNode | undefined
  if (withSubtitle) {
    if (isOpen) {
      subtitle = <FormattedMessage id={messages.seeLess.id} />
    } else {
      subtitle = <FormattedMessage id={messages.seeMore.id} />
    }
  }

  const formattedTitle = (
    <Title
      title={title}
      subtitle={<Subtitle totalPrice={totalPrice} text={subtitle} />}
      action={action}
    />
  )

  const classes = [
    cartStyle.cart,
    style.sticky,
    className,
    isOpen ? style.open : style.closed,
    showOnXl && style.show_on_xl,
    className,
  ].filter(Boolean)

  return (
    <Collapsible
      hideChevron
      isOpen={isOpen}
      onToggle={(): void => setIsOpen(!isOpen)}
      className={classes.join(' ')}
      title={formattedTitle}
    >
      <div className={style.children}>{children}</div>
      <div>
        {totals}
        <div className={style.submit_total}>{action}</div>
      </div>
    </Collapsible>
  )
}
