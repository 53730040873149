import { authenticate, withSessionId } from '../../lib/decorators'
import { put } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'

export class Brand {
  @withSessionId
  @authenticate
  static update(dealUuid: string, brand_id: number): ApiRequestDescriptor<void> {
    return put(`/api/v1/deals/${dealUuid}/brand`, { brand_id })
  }
}
