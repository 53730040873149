import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { HardSpinner, PageSpinner } from '@advitam/ui';

import { Path as AppPath } from 'containers/App/constants';
import ResourceCable, {
  ResourceDestroyModal,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { withDatasets } from 'slices/data';

import { Path as SupplierPath } from '../constants';
import { makeSelectSupplier } from '../selectors';
import slice, { clearError, setWarehouse } from './slice';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectRawWarehouse,
} from './selectors';
import { fetchWarehouse } from './thunk';
import { WAREHOUSE_SKELETON } from './constants';

function Container(): JSX.Element {
  const dispatch = useDispatch();

  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());
  const warehouse = useSelector(makeSelectRawWarehouse());
  const supplier = useSelector(makeSelectSupplier());
  assert(supplier !== null);

  const { warehouseId } = useParams();
  const parsedId = warehouseId && parseInt(warehouseId, 10);
  const canRender = !isLoading && warehouse;

  const fetchResource = useCallback(() => {
    assert(typeof parsedId === 'number');
    dispatch(fetchWarehouse(parsedId));
  }, [dispatch, parsedId]);

  useEffect(() => {
    if (!parsedId) {
      dispatch(setWarehouse(WAREHOUSE_SKELETON));
    } else if (parsedId !== warehouse?.id) {
      fetchResource();
    }

    return () => {
      dispatch(clearError());
    };
  }, [dispatch, parsedId, fetchResource]);

  return (
    <>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {parsedId && (
        <ResourceCable
          resourceId={parsedId}
          resourceType={ResourceRoomType.SUPPLIER_WAREHOUSE}
        >
          <ResourceUpdateModal onRefresh={fetchResource} />
          <ResourceDestroyModal
            path={AppPath.SUPPLIER(supplier.id, SupplierPath.WAREHOUSES)}
          />
        </ResourceCable>
      )}
    </>
  );
}

export default compose(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(Container);
