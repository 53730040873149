import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useBreakpoint } from '@advitam/react';
import Api from '@advitam/api';
import { EntitiesIndexFilters } from '@advitam/api/v1/Entities';
import { Button, ResourceList } from '@advitam/ui';
import type { Filter } from '@advitam/ui/components/ResourceList/Filters/types';
import { makeSelectDepartments } from 'slices/data';

import { ENTITY_TEXTS } from './EntityIcon';
import messages from '../messages';
import { makeSelectFilters } from '../selectors';
import { setFilters, setIsCreationModalOpen } from '../slice';
import type { Filters } from '../types';
import { fetchEntities } from '../thunk';
import style from '../Entities.module.scss';

const LG_BREAKPOINT = parseInt(style.lg, 10);
const MD_BREAKPOINT = parseInt(style.md, 10);

export default function Filters(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isAboveTablet = useBreakpoint(LG_BREAKPOINT);
  const isAboveMobile = useBreakpoint(MD_BREAKPOINT);
  const filters = useSelector(makeSelectFilters());
  const departments = useSelector(makeSelectDepartments());

  const filtersShape: Filter[] = [
    {
      type: 'Select',
      name: 'entity_type',
      placeholder: intl.formatMessage(messages.type),
      items: Object.entries(ENTITY_TEXTS).map(([value, message]) => ({
        value,
        name: intl.formatMessage(message),
      })),
      initialValue: filters.entity_type,
    },
    {
      type: 'Select',
      name: 'department',
      placeholder: intl.formatMessage(messages.department),
      filter: true,
      items: departments.map(department => ({
        name: department.description,
        value: department.department_code,
      })),
      initialValue: filters.department,
      isHidden: isAboveMobile && !isAboveTablet,
    },
    {
      type: 'Autocomplete',
      name: 'city',
      endpoint: Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls),
      placeholder: intl.formatMessage(messages.city),
      initialValue: filters.city,
    },
  ];

  const onChange = useCallback(
    (newFilters: EntitiesIndexFilters): void => {
      dispatch(setFilters(newFilters));
      dispatch(fetchEntities());
    },
    [dispatch],
  );

  const onCreate = useCallback(() => {
    dispatch(setIsCreationModalOpen(true));
  }, [dispatch]);

  return (
    <ResourceList.Filters
      className={style.filters}
      filters={filtersShape}
      searchFilter={{
        name: 'q',
        initialValue: filters.q,
        placeholder: intl.formatMessage(messages.search),
      }}
      onChange={onChange}
    >
      <Button
        primary
        onClick={onCreate}
        text={<FormattedMessage id={messages.createPlace.id} />}
      />
    </ResourceList.Filters>
  );
}
