import { IntlShape, useIntl } from 'react-intl';

import type { PaymentJSON } from 'models/Payment';

import * as Person from '../Person';

export function formatName(payment: PaymentJSON, intl: IntlShape): string {
  if (payment.is_company) {
    return payment.company_name || '';
  }

  return Person.formatName(
    {
      civility: payment.civ,
      firstname: payment.first_name,
      lastname: payment.last_name,
    },
    intl,
  );
}

interface FormattedNameProps {
  payment: PaymentJSON;
}

export function FormattedName({ payment }: FormattedNameProps): JSX.Element {
  const intl = useIntl();
  return <>{formatName(payment, intl)}</>;
}
