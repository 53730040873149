// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApiError-module__link--I2a9x{padding:0;text-decoration:underline;font:inherit}`, "",{"version":3,"sources":["webpack://./../ui/components/Format/ApiError/ApiError.module.scss"],"names":[],"mappings":"AAAA,8BACE,SAAA,CACA,yBAAA,CACA,YAAA","sourcesContent":[".link {\n  padding: 0;\n  text-decoration: underline;\n  font: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `ApiError-module__link--I2a9x`
};
export default ___CSS_LOADER_EXPORT___;
