import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  worshipType: {
    id: 'app.containers.Worship.sections.worshipType',
  },
  worship: {
    id: 'app.containers.Worship.sections.worship',
  },
});
