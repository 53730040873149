import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import { SupplierWarehouseZoneFuneralWorkJSON } from '@advitam/api/models/Supplier/Warehouse';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import { SUPPLIER_WAREHOUSE_ZONE } from './constants';
import type { AppStateSubset, State } from './slice';
import { FuneralWorksFilters } from './types';

type ZoneSelector<T> = Selector<AppStateSubset, T>;

const selectWarehouseZoneDomain = (state: AppStateSubset): State =>
  state[SUPPLIER_WAREHOUSE_ZONE];

export const makeSelectFuneralWorks = (): ZoneSelector<
  SupplierWarehouseZoneFuneralWorkJSON[]
> =>
  createSelector(selectWarehouseZoneDomain, ({ funeralWorks }) => funeralWorks);

export const makeSelectCategoryOptions = (): ZoneSelector<SelectableItem[]> =>
  createSelector(makeSelectFuneralWorks(), funeralWorks => {
    const filters: SelectableItem[] = [];

    funeralWorks.forEach(funeralWork => {
      const { id, description } = funeralWork.funeral_work_type.category;

      if (id && !filters.some(filter => filter.value === id)) {
        filters.push({
          value: id,
          name: description,
        });
      }
    });

    return filters;
  });

export const makeSelectFilters = (): ZoneSelector<FuneralWorksFilters> =>
  createSelector(selectWarehouseZoneDomain, ({ filters }) => filters);

export const makeSelectIsLoading = (): ZoneSelector<boolean> =>
  createSelector(selectWarehouseZoneDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): ZoneSelector<boolean> =>
  createSelector(selectWarehouseZoneDomain, ({ isSaving }) => isSaving);

export const makeSelectError = (): ZoneSelector<SerializedApiError | null> =>
  createSelector(selectWarehouseZoneDomain, ({ error }) => error);
