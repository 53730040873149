import { authenticate } from '../../../../lib/decorators'
import { get, post } from '../../../../lib/methods'
import { ApiRequestDescriptor } from '../../../../lib/request'
import { DealDocumentBatchJSON } from '../../../../models/Deal/Document/Batch'
import { AddDocument } from './AddDocument'
import { AddRecipient } from './AddRecipient'
import { Logs } from './Logs'
import { RemoveDocument } from './RemoveDocument'
import { RemoveRecipient } from './RemoveRecipient'

export class Batches {
  static readonly AddDocument = AddDocument

  static readonly AddRecipient = AddRecipient

  static readonly Logs = Logs

  static readonly RemoveDocument = RemoveDocument

  static readonly RemoveRecipient = RemoveRecipient

  @authenticate
  static create(
    dealUuid: string,
    to_sign: boolean,
    generator_name: string,
    documents?: number[],
  ): ApiRequestDescriptor<DealDocumentBatchJSON> {
    return post(`/api/v1/deals/${dealUuid}/documents/batches`, {
      generator_name,
      to_sign,
      documents,
    })
  }

  @authenticate
  static show(dealUuid: string, id: number): ApiRequestDescriptor<DealDocumentBatchJSON> {
    return get(`/api/v1/deals/${dealUuid}/documents/batches/${id}`)
  }
}
