import { Helmet } from 'react-helmet';

import NotFoundImg from './404.png';
import style from './NotFound.module.scss';

export default function NotFound(): JSX.Element {
  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      <div className={style.container}>
        <div className={`${style.image_wrapper} col-8`}>
          <img src={NotFoundImg} alt="" />
        </div>
      </div>
    </>
  );
}
