import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  tooltip: {
    id: 'app.containers.Deal.Sections.Home.DuplicationButton.tooltip',
  },
  title: { id: 'app.containers.Deal.Sections.Home.DuplicationButton.title' },
  text: { id: 'app.containers.Deal.Sections.Home.DuplicationButton.text' },
  confirm: {
    id: 'app.containers.Deal.Sections.Home.DuplicationButton.confirm',
  },
  cancel: { id: 'app.containers.Deal.Sections.Home.DuplicationButton.cancel' },
});
