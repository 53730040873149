import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { useWindowSize } from '@advitam/react';
import { FormattedApiError, PageSpinner, Text } from '@advitam/ui';

import messages from './messages';
import {
  makeSelectError,
  makeSelectHasMore,
  makeSelectIsInitialized,
  makeSelectIsLoading,
  makeSelectNotifications,
} from './selectors';
import { fetchNotificationsPage } from './thunk';
import style from './NotificationCenter.module.scss';
import NotificationTile from './NotificationTile';

export default function NotificationList(): JSX.Element {
  const dispatch = useDispatch();

  const notifications = useSelector(makeSelectNotifications());
  const isInitialized = useSelector(makeSelectIsInitialized());
  const isLoading = useSelector(makeSelectIsLoading());
  const hasMore = useSelector(makeSelectHasMore());
  const error = useSelector(makeSelectError());
  const windowSize = useWindowSize();

  const loadNotifications = useCallback((): void => {
    if (!isLoading) {
      dispatch(fetchNotificationsPage());
    }
  }, [dispatch, isLoading]);

  if (!isInitialized) {
    return <PageSpinner />;
  }

  if (notifications.length === 0 && !hasMore) {
    return (
      <Text tagName="p" className={style.no_notification}>
        <FormattedMessage id={messages.noNotification.id} />
      </Text>
    );
  }

  return (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadNotifications}
      threshold={windowSize.height}
      useWindow={false}
    >
      {notifications.map(notification => (
        <NotificationTile key={notification.id} notification={notification} />
      ))}
      {isLoading && <PageSpinner />}
      {error && (
        <Text className={style.error}>
          <FormattedApiError error={error} />
        </Text>
      )}
    </InfiniteScroll>
  );
}
