import { useEffect, useRef } from 'react'

export function usePreviousValue<T>(value?: T): T | undefined {
  const prevValue = useRef<T>()

  useEffect(() => {
    prevValue.current = value

    return (): void => {
      prevValue.current = undefined
    }
  })

  return prevValue.current
}
