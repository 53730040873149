import {
  GET_DEAL_SUMMARY,
  SET_DEAL_SUMMARY,
} from 'containers/Deal/DealFuneral/constants';

/**
 * Gets the deal summary from the API.
 *
 * @param      {string}  uuid    The uuid of the deal.
 * @return     {Object}  An action object asking a saga to get the deal summary.
 */
export function getDealSummary(uuid) {
  return {
    type: GET_DEAL_SUMMARY,
    uuid,
  };
}

/**
 * Sets the deal summary received from the API.
 *
 * @param   {array}      dealSummary  The deal summary array.
 * @return  {AnyAction}  An action object asking the reducer to store the deal
 *                       summary
 */
export function setDealSummary(dealSummary) {
  return {
    type: SET_DEAL_SUMMARY,
    dealSummary,
  };
}
