import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { TextArea } from '@advitam/ui';
import { AdministrationActions, Fields, Layout } from 'containers/Crud';
import { getFieldName } from 'containers/Crud/Fields/utils';
import { assert } from 'lib/support';

import {
  makeSelectDestroyError,
  makeSelectIsDestroying,
  makeSelectRawPolice,
} from '../selectors';
import { destroyPolice, savePolice, setIsPoliceDisabled } from '../thunk';
import messages from '../messages';
import Wrapper from './Wrapper';
import type { PoliceForm } from './types';

const FIELD_PREFIX = 'police';

export default function General(): JSX.Element {
  const dispatch = useDispatch();
  const police = useSelector(makeSelectRawPolice());
  assert(police !== null);
  const isDestroying = useSelector(makeSelectIsDestroying());
  const destroyError = useSelector(makeSelectDestroyError());

  const onSubmit = useCallback(
    (values: PoliceForm): void => {
      dispatch(savePolice(values.police));
    },
    [dispatch],
  );

  const onDelete = useCallback(() => {
    dispatch(destroyPolice());
  }, [dispatch]);

  const setIsDisabled = useCallback(
    (value: boolean) => {
      dispatch(setIsPoliceDisabled(value));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        {police.id && (
          <AdministrationActions
            isDisabled={police.disabled}
            setIsDisabled={setIsDisabled}
            entityName={police.name}
            onDelete={onDelete}
            isDeletionLoading={isDestroying}
            destroyError={destroyError}
          />
        )}
        <Layout.ColumnGroup>
          <Layout.Column>
            <Layout.Fieldset
              title={<FormattedMessage id={messages.commentary.id} />}
            >
              <TextArea name={getFieldName(FIELD_PREFIX, 'comment')} />
            </Layout.Fieldset>
          </Layout.Column>
        </Layout.ColumnGroup>
        <Layout.ColumnGroup>
          <Layout.Column>
            <Fields.Details prefix={FIELD_PREFIX} />
            <Fields.Billing prefix={FIELD_PREFIX} />
          </Layout.Column>
          <Layout.Column>
            <Fields.Contact
              addressPrefix={FIELD_PREFIX}
              contactPrefix={FIELD_PREFIX}
            />
          </Layout.Column>
        </Layout.ColumnGroup>
      </Layout.Container>
    </Wrapper>
  );
}
