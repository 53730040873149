import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'app.containers.DealFuneral.MemorialSection.Donation.title' },
  subtitle: {
    id: 'app.containers.DealFuneral.MemorialSection.Donation.subtitle',
  },
  inputPlaceholder: {
    id: 'app.containers.DealFuneral.MemorialSection.Donation.inputPlaceholder',
  },
});
