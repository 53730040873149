import { ReactNode } from 'react';
import { Frame } from '@advitam/ui';
import listStyle from '../List.module.scss';
import style from './ActionFrame.module.scss';

interface ActionFrameProps {
  children: ReactNode | ReactNode[];
}

export default function ActionFrame({
  children,
}: ActionFrameProps): JSX.Element {
  return (
    <div className={listStyle.row}>
      <Frame darker className={style.frame}>
        {children}
      </Frame>
    </div>
  );
}
