import { FormattedMessage } from 'react-intl';

import { ResourceList } from '@advitam/ui';

import messages from '../messages';
import style from '../style.scss';

export default function Header(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell className={style.status}>
        <FormattedMessage id={messages.status.id} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.name}>
        <FormattedMessage id={messages.name.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.invoice.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.date.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.advisor.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.stillToPay.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.fundingAgency.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.payments}>
        <FormattedMessage id={messages.payments.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.comment.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
