function keysOfNonArray<T>(object: T | null | undefined): Array<keyof T> {
  return object ? (Object.getOwnPropertyNames(object) as Array<keyof T>) : []
}

export function isEqual(value: unknown, other: unknown): boolean {
  if (Object.is(value, other)) {
    // covers e.g. NaN === NaN
    return true
  }

  if (!(value instanceof Object && other instanceof Object)) {
    return false
  }

  if (keysOfNonArray(value).some(key => !Object.prototype.hasOwnProperty.call(other, key))) {
    return false
  }

  return keysOfNonArray(other).every(key => isEqual(value[key], other[key]))
}
