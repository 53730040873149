import { defineMessages } from 'react-intl';

export default defineMessages({
  addon: {
    id: 'app.containers.DocumentTemplateEditor.variables.addon',
  },
  address: {
    id: 'app.containers.DocumentTemplateEditor.variables.address',
  },
  advisor_name: {
    id: 'app.containers.DocumentTemplateEditor.variables.advisor_name',
  },
  advisor_lastname: {
    id: 'app.containers.DocumentTemplateEditor.variables.advisor_lastname',
  },
  age: {
    id: 'app.containers.DocumentTemplateEditor.variables.age',
  },
  arrival_date: {
    id: 'app.containers.DocumentTemplateEditor.variables.arrival_date',
  },
  arrival_time: {
    id: 'app.containers.DocumentTemplateEditor.variables.arrival_time',
  },
  birth_date: {
    id: 'app.containers.DocumentTemplateEditor.variables.birth_date',
  },
  birth_day: {
    id: 'app.containers.DocumentTemplateEditor.variables.birth_day',
  },
  birth_location: {
    id: 'app.containers.DocumentTemplateEditor.variables.birth_location',
  },
  birth_month: {
    id: 'app.containers.DocumentTemplateEditor.variables.birth_month',
  },
  birth_name: {
    id: 'app.containers.DocumentTemplateEditor.variables.birth_name',
  },
  birth_year: {
    id: 'app.containers.DocumentTemplateEditor.variables.birth_year',
  },
  buy_date: {
    id: 'app.containers.DocumentTemplateEditor.variables.buy_date',
  },
  ceremony: {
    id: 'app.containers.DocumentTemplateEditor.variables.ceremony',
  },
  city: {
    id: 'app.containers.DocumentTemplateEditor.variables.city',
  },
  civility: {
    id: 'app.containers.DocumentTemplateEditor.variables.civility',
  },
  color: {
    id: 'app.containers.DocumentTemplateEditor.variables.color',
  },
  contact_address: {
    id: 'app.containers.DocumentTemplateEditor.variables.contact_address',
  },
  contact_civility: {
    id: 'app.containers.DocumentTemplateEditor.variables.contact_civility',
  },
  contact_email: {
    id: 'app.containers.DocumentTemplateEditor.variables.contact_email',
  },
  contact_firstname: {
    id: 'app.containers.DocumentTemplateEditor.variables.contact_firstname',
  },
  contact_lastname: {
    id: 'app.containers.DocumentTemplateEditor.variables.contact_lastname',
  },
  contact_phone: {
    id: 'app.containers.DocumentTemplateEditor.variables.contact_phone',
  },
  country: {
    id: 'app.containers.DocumentTemplateEditor.variables.country',
  },
  crossed_borders: {
    id: 'app.containers.DocumentTemplateEditor.variables.crossed_borders',
  },
  current_date: {
    id: 'app.containers.DocumentTemplateEditor.currentDate',
  },
  date: {
    id: 'app.containers.DocumentTemplateEditor.variables.date',
  },
  day: {
    id: 'app.containers.DocumentTemplateEditor.variables.day',
  },
  decoration: {
    id: 'app.containers.DocumentTemplateEditor.variables.decoration',
  },
  departure_date: {
    id: 'app.containers.DocumentTemplateEditor.variables.departure_date',
  },
  departure_time: {
    id: 'app.containers.DocumentTemplateEditor.variables.departure_time',
  },
  depth: {
    id: 'app.containers.DocumentTemplateEditor.variables.depth',
  },
  destination: {
    id: 'app.containers.DocumentTemplateEditor.variables.destination',
  },
  destination_city: {
    id: 'app.containers.DocumentTemplateEditor.variables.destination_city',
  },
  destination_country: {
    id: 'app.containers.DocumentTemplateEditor.variables.destination_country',
  },
  division: {
    id: 'app.containers.DocumentTemplateEditor.variables.division',
  },
  email: {
    id: 'app.containers.DocumentTemplateEditor.variables.email',
  },
  empowerment: {
    id: 'app.containers.DocumentTemplateEditor.variables.empowerment',
  },
  fax: {
    id: 'app.containers.DocumentTemplateEditor.variables.fax',
  },
  firstname: {
    id: 'app.containers.DocumentTemplateEditor.variables.firstname',
  },
  flight1_arrival_airport: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight1_arrival_airport',
  },
  flight1_arrival_date: {
    id: 'app.containers.DocumentTemplateEditor.variables.flight1_arrival_date',
  },
  flight1_arrival_time: {
    id: 'app.containers.DocumentTemplateEditor.variables.flight1_arrival_time',
  },
  flight1_departure_airport: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight1_departure_airport',
  },
  flight1_departure_date: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight1_departure_date',
  },
  flight1_departure_time: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight1_departure_time',
  },
  flight1_number: {
    id: 'app.containers.DocumentTemplateEditor.variables.flight1_number',
  },
  flight2_arrival_airport: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight2_arrival_airport',
  },
  flight2_arrival_date: {
    id: 'app.containers.DocumentTemplateEditor.variables.flight2_arrival_date',
  },
  flight2_arrival_time: {
    id: 'app.containers.DocumentTemplateEditor.variables.flight2_arrival_time',
  },
  flight2_departure_airport: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight2_departure_airport',
  },
  flight2_departure_date: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight2_departure_date',
  },
  flight2_departure_time: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight2_departure_time',
  },
  flight2_number: {
    id: 'app.containers.DocumentTemplateEditor.variables.flight2_number',
  },
  flight3_arrival_airport: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight3_arrival_airport',
  },
  flight3_arrival_date: {
    id: 'app.containers.DocumentTemplateEditor.variables.flight3_arrival_date',
  },
  flight3_arrival_time: {
    id: 'app.containers.DocumentTemplateEditor.variables.flight3_arrival_time',
  },
  flight3_departure_airport: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight3_departure_airport',
  },
  flight3_departure_date: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight3_departure_date',
  },
  flight3_departure_time: {
    id:
      'app.containers.DocumentTemplateEditor.variables.flight3_departure_time',
  },
  flight3_number: {
    id: 'app.containers.DocumentTemplateEditor.variables.flight3_number',
  },

  full_address: {
    id: 'app.containers.DocumentTemplateEditor.variables.full_address',
  },
  full_name: {
    id: 'app.containers.DocumentTemplateEditor.variables.full_name',
  },
  height: {
    id: 'app.containers.DocumentTemplateEditor.variables.height',
  },
  id: {
    id: 'app.containers.DocumentTemplateEditor.variables.id',
  },
  interment_place: {
    id: 'app.containers.DocumentTemplateEditor.variables.interment_place',
  },
  lastname: {
    id: 'app.containers.DocumentTemplateEditor.variables.lastname',
  },
  length: {
    id: 'app.containers.DocumentTemplateEditor.variables.length',
  },
  line: {
    id: 'app.containers.DocumentTemplateEditor.variables.line',
  },
  link_with_defunct: {
    id: 'app.containers.DocumentTemplateEditor.variables.link_with_defunct',
  },
  location: {
    id: 'app.containers.DocumentTemplateEditor.variables.location',
  },
  lta: {
    id: 'app.containers.DocumentTemplateEditor.variables.lta',
  },
  material: {
    id: 'app.containers.DocumentTemplateEditor.variables.material',
  },
  month: {
    id: 'app.containers.DocumentTemplateEditor.variables.month',
  },
  name: {
    id: 'app.containers.DocumentTemplateEditor.variables.name',
  },
  nationality: {
    id: 'app.containers.DocumentTemplateEditor.variables.nationality',
  },
  phone: {
    id: 'app.containers.DocumentTemplateEditor.variables.phone',
  },
  postal_code: {
    id: 'app.containers.DocumentTemplateEditor.variables.postal_code',
  },
  prefecture_exemption: {
    id: 'app.containers.DocumentTemplateEditor.variables.prefecture_exemption',
  },
  price: {
    id: 'app.containers.DocumentTemplateEditor.variables.price',
  },
  pro_email: {
    id: 'app.containers.DocumentTemplateEditor.variables.pro_email',
  },
  size: {
    id: 'app.containers.DocumentTemplateEditor.variables.size',
  },
  signature: {
    id: 'app.containers.DocumentTemplateEditor.variables.signature',
  },
  stamp: {
    id: 'app.containers.DocumentTemplateEditor.variables.stamp',
  },
  supplier_address: {
    id: 'app.containers.DocumentTemplateEditor.variables.supplier_address',
  },
  supplier_empowerment: {
    id: 'app.containers.DocumentTemplateEditor.variables.supplier_empowerment',
  },
  term: {
    id: 'app.containers.DocumentTemplateEditor.variables.term',
  },
  text: {
    id: 'app.containers.DocumentTemplateEditor.variables.text',
  },
  time: {
    id: 'app.containers.DocumentTemplateEditor.variables.time',
  },
  total_places: {
    id: 'app.containers.DocumentTemplateEditor.variables.total_places',
  },
  type: {
    id: 'app.containers.DocumentTemplateEditor.variables.type',
  },
  weight: {
    id: 'app.containers.DocumentTemplateEditor.variables.weight',
  },
  width: {
    id: 'app.containers.DocumentTemplateEditor.variables.width',
  },
  work: {
    id: 'app.containers.DocumentTemplateEditor.variables.work',
  },
  worship: {
    id: 'app.containers.DocumentTemplateEditor.variables.worship',
  },
  year: {
    id: 'app.containers.DocumentTemplateEditor.variables.year',
  },
});
