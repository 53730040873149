import { useCallback } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import LaterIcon from '@advitam/ui/images/icons/later.svg';
import { ButtonV2 as Button } from 'components/Button/index.js';

import {
  makeSelectHasReminder,
  makeSelectReminderDate,
} from '../Sections/Home/ReminderButton/selectors';
import slice, { openModal } from '../Sections/Home/ReminderButton/slice';
import { destroyReminder } from '../Sections/Home/ReminderButton/thunk';
import SchedulingModal from '../Sections/Home/ReminderButton/SchedulingModal';
import { PREDEFINED_DATE_FORMAT } from '../Sections/Home/ReminderButton/constants';
import messages from './messages';
import style from './DealMarble.module.scss';

function ReminderButton(): JSX.Element {
  const dispatch = useDispatch();
  const hasReminder = useSelector(makeSelectHasReminder());
  const reminderDate = useSelector(makeSelectReminderDate());

  const onClick = useCallback(() => {
    if (hasReminder) {
      dispatch(destroyReminder());
    } else {
      dispatch(openModal());
    }
  }, [dispatch, hasReminder]);

  return (
    <>
      <Button onClick={onClick} className={style.reminder_button}>
        <LaterIcon />
        {hasReminder ? (
          <FormattedDate
            value={reminderDate || undefined}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...PREDEFINED_DATE_FORMAT}
          />
        ) : (
          <FormattedMessage id={messages.reminder.id} />
        )}
      </Button>

      <SchedulingModal />
    </>
  );
}

export default withSlice(slice)(ReminderButton);
