import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { DealState } from '@advitam/api/models/Deal/State';
import { ConfirmationModal } from '@advitam/ui';

import actionMessages from 'messages/actions';
import { safeFormatMessage } from 'utils/functions.typed';
import { assert } from 'lib/support';

import { updateState } from './thunk';
import messages from './messages';

interface UpdateConfirmationModalProps {
  state: DealState | null;
  onClose: () => void;
}

export default function UpdateConfirmationModal({
  state,
  onClose,
}: UpdateConfirmationModalProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onConfirm = useCallback(() => {
    assert(state !== null);
    dispatch(updateState({ state }));
    onClose();
  }, [dispatch, state]);

  return (
    <ConfirmationModal
      className="new-design col-sm-6"
      isOpen={Boolean(state)}
      cancel={<FormattedMessage id={actionMessages.cancel.id} />}
      onCancel={onClose}
      confirm={<FormattedMessage id={actionMessages.confirm.id} />}
      onConfirm={onConfirm}
      title={<FormattedMessage id={messages.confirmationTitle.id} />}
      text={
        state && (
          <FormattedMessage
            id={messages.confirmationText.id}
            values={{
              state: <b>{safeFormatMessage(intl, messages, state)}</b>,
            }}
          />
        )
      }
    />
  );
}
