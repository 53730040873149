import { ReactNode } from 'react'

import Text from '../Text'
import style from './TextBloc.module.scss'

interface ParagraphTextBlocProps {
  text: ReactNode
  subtext: ReactNode
}

export default function ParagraphTextBloc({ text, subtext }: ParagraphTextBlocProps): JSX.Element {
  return (
    <div className={`col-md-8 col-xl-10 ${style.paragraph}`}>
      <Text large tagName="div" className={style.column}>
        {text}
      </Text>
      <span className={style.separator} />
      <Text large tagName="div" className={style.column}>
        {subtext}
      </Text>
    </div>
  )
}
