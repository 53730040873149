import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  managedPlaces: {
    id: 'app.containers.RegionalHealthAuthority.sections.managedPlaces',
  },
  managedPlacesTooltip: {
    id: 'app.containers.RegionalHealthAuthority.sections.managedPlacesTooltip',
  },
});
