import { FormattedMessage } from 'react-intl'
import messages from './messages'

interface FormattedDateProps {
  value: number // seconds
}

export default function FormattedDuration({ value }: FormattedDateProps): JSX.Element {
  const roundedValue = Math.round(value)
  const seconds = roundedValue % 60
  const minutes = Math.floor(value / 60) % 60
  const hours = Math.floor(value / 3600)

  if (hours > 0) {
    return (
      <FormattedMessage
        id={messages.formattedDurationFromHours.id}
        values={{ hours, minutes, seconds }}
      />
    )
  }

  if (minutes > 0) {
    return (
      <FormattedMessage
        id={messages.formattedDurationFromMinutes.id}
        values={{ minutes, seconds }}
      />
    )
  }

  return <FormattedMessage id={messages.formattedDurationFromSeconds.id} values={{ seconds }} />
}
