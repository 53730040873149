import { NewCrematoriumRoom } from './types';

export const CREMATORIUM_ROOMS = 'Crematorium/Rooms';

export const ROOM_SKELETON: NewCrematoriumRoom = {
  capacity: null,
  media_formats: [],
  media_supports: [],
  media_types: [],
  name: '',
};
