import { ReactNode } from 'react'
import { Field, UseFieldConfig } from 'react-final-form'

import NumberUI from '../UI/Number'
import { Number } from '../converters'
import { composeValidators, isRequired, Validator } from '../validators'

interface InputProps {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  required?: boolean
  noscroll?: boolean
  withoutSpinner?: boolean
  readOnly?: boolean
  step?: number
  min?: number
  max?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: UseFieldConfig<any>['format']
  validate?: Validator<number>
  className?: string
  tooltip?: ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
}

export default function Input({
  name,
  label,
  placeholder,
  disabled,
  required,
  noscroll,
  withoutSpinner,
  readOnly,
  step,
  min,
  max,
  parse = Number.parse,
  format = Number.format,
  validate,
  className,
  tooltip,
  prefix,
  suffix,
}: InputProps): JSX.Element {
  return (
    <Field
      name={name}
      validate={composeValidators(required && isRequired, validate)}
      parse={parse}
      format={format}
    >
      {(props): JSX.Element => (
        <NumberUI
          label={label}
          onChange={props.input.onChange}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly as false}
          value={props.input.value as number}
          step={step}
          min={min}
          max={max}
          error={!!(props.meta.touched && props.meta.error)}
          className={className}
          tooltip={tooltip}
          prefix={prefix}
          suffix={suffix}
          noscroll={noscroll}
          withoutSpinner={withoutSpinner}
        />
      )}
    </Field>
  )
}
