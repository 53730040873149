import { Objects } from '@advitam/support'
import { authenticate } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { TodoItemJSON } from '../../models/Deal/TodoItem'

export class TodoItems {
  @authenticate
  static index(dealUuid: string): ApiRequestDescriptor<TodoItemJSON[]> {
    return get(`/api/v1/deals/${dealUuid}/todo_items`)
  }

  @authenticate
  static create(dealUuid: string, title: string): ApiRequestDescriptor<TodoItemJSON> {
    return post(`/api/v1/deals/${dealUuid}/todo_items`, { title })
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<TodoItemJSON> {
    return get(`/api/v1/deals/todo_items/${id}`)
  }

  @authenticate
  static update(item: TodoItemJSON): ApiRequestDescriptor<TodoItemJSON> {
    return put(`/api/v1/deals/todo_items/${item.id}`, Objects.pick(item, 'title', 'force_done'))
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/deals/todo_items/${id}`)
  }
}
