import { ValidatorReturnType } from './Validator'
import { ValueType } from './ValueType'

const ERROR = 'undefined'

export function isNotUndefined<T extends ValueType>(value: T): ValidatorReturnType {
  if (value === undefined) {
    return ERROR
  }

  return undefined
}
