import { defineMessages } from 'react-intl';

export default defineMessages({
  graveState: {
    id: 'app.components.ConcessionSelection.Concession.graveState',
  },
  type: { id: 'app.components.ConcessionSelection.Concession.type' },
  semelle: { id: 'app.components.ConcessionSelection.Concession.semelle' },
  monument: { id: 'app.components.ConcessionSelection.Concession.monument' },
  placesLeft: {
    id: 'app.components.ConcessionSelection.Concession.placesLeft',
  },
  places: { id: 'app.components.ConcessionSelection.Concession.places' },
  disposition: {
    id: 'app.components.ConcessionSelection.Concession.disposition',
  },
  simple: { id: 'app.components.ConcessionSelection.Concession.simple' },
  double: { id: 'app.components.ConcessionSelection.Concession.double' },
  headToTail: {
    id: 'app.components.ConcessionSelection.Concession.headToTail',
  },
  plainearth: {
    id: 'app.components.ConcessionSelection.Concession.plainearth',
  },
  vault: { id: 'app.components.ConcessionSelection.Concession.vault' },
  vault_front_door: {
    id: 'app.components.ConcessionSelection.Concession.vault_front_door',
  },
  vault_front_door_underground: {
    id:
      'app.components.ConcessionSelection.Concession.vault_front_door_underground',
  },
  enfeu: { id: 'app.components.ConcessionSelection.Concession.enfeu' },
  urnvault: { id: 'app.components.ConcessionSelection.Concession.urnvault' },
  columbarium_case: {
    id: 'app.components.ConcessionSelection.Concession.columbarium_case',
  },
  unknown: { id: 'app.components.ConcessionSelection.Concession.unknown' },
  fausseCase: {
    id: 'app.components.ConcessionSelection.Concession.fausseCase',
  },
  granite: { id: 'app.components.ConcessionSelection.Concession.granite' },
  cement: { id: 'app.components.ConcessionSelection.Concession.cement' },
});
