import { ApiError, SerializedApiError } from '@advitam/api';
import { ClientJSON } from '@advitam/api/models/Client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CLIENTS, CLIENTS_PER_PAGE } from './constants';
import { fetchClients } from './thunk';
import { ClientsFilters } from './types';

export interface State {
  clients: ClientJSON[];
  loading: boolean;
  nextClientsPage: number;
  hasMorePages: boolean;
  error: SerializedApiError | null;
  lastRequestId: string | null;
  filters: ClientsFilters;
}

export interface AppStateSubset {
  [CLIENTS]: State;
}

export const initialState: State = {
  clients: [],
  nextClientsPage: 1,
  hasMorePages: true,
  loading: false,
  error: null,
  lastRequestId: null,
  filters: {},
};

const slice = createSlice({
  name: CLIENTS,
  initialState,
  reducers: {
    setFilters(state, { payload }: PayloadAction<ClientsFilters>): State {
      return {
        ...state,
        clients: [],
        nextClientsPage: 1,
        hasMorePages: true,
        filters: payload,
      };
    },
  },

  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchClients.pending, (state, { meta }): void => {
      state.loading = true;
      state.error = null;
      state.lastRequestId = meta.requestId;
    });
    builder.addCase(fetchClients.rejected, (state, { meta, payload }): void => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }
      state.loading = false;
      state.error = ApiError.serialize(payload);
    });
    builder.addCase(
      fetchClients.fulfilled,
      (state, { meta, payload }): void => {
        if (meta.requestId !== state.lastRequestId) {
          return;
        }
        state.clients = state.clients.concat(payload);
        state.nextClientsPage += 1;
        state.hasMorePages = payload.length >= CLIENTS_PER_PAGE;
        state.loading = false;
      },
    );
    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters } = slice.actions;
export default slice;
