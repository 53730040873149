import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type { FuneralParlorJSON } from '@advitam/api/models/FuneralParlor';

import { Path } from 'containers/App/constants';
import { assert, nextTick } from 'lib/support';

import { FUNERAL_PARLOR } from './constants';
import { AppStateSubset, setFuneralParlor } from './slice';
import { makeSelectRawFuneralParlor } from './selectors';
import { NewFuneralParlor } from './types';

export const fetchFuneralParlor = createAsyncThunk(
  `${FUNERAL_PARLOR}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.FuneralParlors.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createFuneralParlor = createAsyncThunk(
  `${FUNERAL_PARLOR}_CREATE`,
  async (funeralParlor: NewFuneralParlor, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(
        Api.V1.FuneralParlors.create(funeralParlor),
      );

      dispatch(setFuneralParlor(body));
      nextTick(() => dispatch(replace(`./${body.id}`)));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateFuneralParlor = createAsyncThunk(
  `${FUNERAL_PARLOR}_UPDATE`,
  async (funeralParlor: FuneralParlorJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.FuneralParlors.update(funeralParlor),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateFuneralParlorName = createAsyncThunk(
  `${FUNERAL_PARLOR}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const funeralParlor = makeSelectRawFuneralParlor()(state);
    assert(funeralParlor?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.FuneralParlors.update({ id: funeralParlor.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const saveFuneralParlor = createAsyncThunk(
  `${FUNERAL_PARLOR}_SAVE`,
  async (funeralParlor: FuneralParlorJSON | NewFuneralParlor, { dispatch }) => {
    if (funeralParlor.id === undefined) {
      await dispatch(createFuneralParlor(funeralParlor));
    } else {
      await dispatch(updateFuneralParlor(funeralParlor));
    }
  },
);

export const destroyFuneralParlor = createAsyncThunk(
  `${FUNERAL_PARLOR}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const funeralParlor = makeSelectRawFuneralParlor()(state);
    assert(funeralParlor?.id !== undefined);

    try {
      await request(Api.V1.FuneralParlors.destroy(funeralParlor.id));
      dispatch(push(Path.ENTITIES));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsFuneralParlorDisabled = createAsyncThunk(
  `${FUNERAL_PARLOR}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const funeralParlor = makeSelectRawFuneralParlor()(state);
    assert(funeralParlor?.id !== undefined);

    try {
      await request(
        Api.V1.FuneralParlors.Disabled.update(funeralParlor.id, disabled),
      );
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
