import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AutoComplete from 'containers/AutoComplete';
import { TextInput } from 'components/Input';
import ModalWarning from 'components/ModalWarning';
import messages from './messages';

const Place = ({
  concession,
  updateConcession,
  getDefunctConcession,
  graveyardId,
}) => {
  const [concessionStaged, setConcessionStaged] = useState(null);

  /**
   * Function to update concession
   *
   * @param {string} name key to update
   * @param {value} value updated value
   */
  const onChange = ({ target: { name, value } }) => {
    updateConcession({
      address: {
        ...concession.address,
        [name]: value,
      },
    });
  };

  /**
   * Function to update concession after selection
   *
   * @param {string} name key to update
   * @param {value} value updated value
   */
  const onSelectConcession = (item, overrideWarning) => {
    if (!overrideWarning) {
      setConcessionStaged(item);
      return;
    }
    setConcessionStaged(null);
    getDefunctConcession(item.id);
  };

  return (
    <div className="concession__field">
      <h5>
        <FormattedMessage {...messages.place} />
      </h5>
      <div>
        <FormattedMessage {...messages.id} />
        {graveyardId && (
          <AutoComplete
            url={`/api/v1/autocompletes/graveyards/${graveyardId}/defunct_concessions?q=`}
            getKey={item => item.id}
            getValue={item => item.description}
            onSelect={(_, item) => onSelectConcession(item)}
            onChange={({ target: { value } }) =>
              onChange({ target: { name: 'id', value } })
            }
            auth
            initialValue={concession.address.id || ''}
            keepValueAfterSelect
          />
        )}
        {concessionStaged && (
          <ModalWarning
            isModalOpen={!!concessionStaged}
            closeModal={() => setConcessionStaged(null)}
            setResponseWithState={() =>
              onSelectConcession(concessionStaged, true)
            }
          />
        )}
      </div>
      <div>
        <FormattedMessage {...messages.division} />
        <TextInput
          onChange={onChange}
          name="division"
          value={concession.address.division || ''}
        />
      </div>
      <div>
        <FormattedMessage {...messages.line} />
        <TextInput
          onChange={onChange}
          name="line"
          value={concession.address.line || ''}
        />
      </div>
      <div>
        <FormattedMessage {...messages.addon} />
        <TextInput
          onChange={onChange}
          name="addon"
          value={concession.address.addon || ''}
        />
      </div>
    </div>
  );
};

Place.propTypes = {
  /** concession data */
  concession: PropTypes.object.isRequired,
  /** function to update a concession */
  updateConcession: PropTypes.func.isRequired,
  /** function to get a concession from an id */
  getDefunctConcession: PropTypes.func.isRequired,
  /** id of graveyard */
  graveyardId: PropTypes.number,
};

export default Place;
