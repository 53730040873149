import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  checkoutType: {
    id:
      'app.containers.DealFuneral.DealSummarySection.CheckoutInfos.checkoutType',
  },
  checkAddress: {
    id:
      'app.containers.DealFuneral.DealSummarySection.CheckoutInfos.checkAddress',
  },
  checkOrder: {
    id:
      'app.containers.DealFuneral.DealSummarySection.CheckoutInfos.checkOrder',
  },
  transferIban: {
    id:
      'app.containers.DealFuneral.DealSummarySection.CheckoutInfos.transferIban',
  },
  transferBic: {
    id:
      'app.containers.DealFuneral.DealSummarySection.CheckoutInfos.transferBic',
  },
});
