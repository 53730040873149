/*
 *  Closing step editor
 */

import PropTypes from 'prop-types';

import CheckboxLabel from 'components/Input/CheckboxLabel';
import Services from '../Services';

import CheckboxDelegate from './CheckboxDelegate';
import { renderStepDefaults, renderLocation } from './stepEditorsUtils';
import messages from './messages';

export default function Closing({
  step,
  handleChange,
  stepServices,
  addService,
  removeService,
  isManualMode,
  userRole,
  updateService,
  updateDelegate,
}) {
  return (
    <div className="step">
      <div className="step__header">
        {renderStepDefaults(step, handleChange)}
        <CheckboxDelegate
          isDelegate={!!step.doneByAnotherFuneral}
          updateDelegate={updateDelegate}
        />
      </div>
      <CheckboxLabel
        message={messages.withFamily}
        updateCheckbox={() =>
          handleChange({ ...step, withFamily: !step.withFamily })
        }
        checkboxValue={step.withFamily}
      />
      {!step.location.sameAsPreviousStep &&
        renderLocation(step.location, messages.location)}
      <Services
        stepId={step.id}
        services={stepServices}
        addService={addService}
        removeService={removeService}
        isManualMode={isManualMode}
        userRole={userRole}
        updateService={updateService}
      />
    </div>
  );
}

Closing.propTypes = {
  step: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  stepServices: PropTypes.array.isRequired,
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
  updateDelegate: PropTypes.func.isRequired,
};
