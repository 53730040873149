import { ChangeEventHandler, InputHTMLAttributes, ReactNode } from 'react'

import Check from '../../../Checkbox'
import BoolField from '../Boolean'
import style from './Checkbox.module.scss'

interface CheckboxProps {
  value: InputHTMLAttributes<HTMLInputElement>['value']
  borderless?: boolean
  checked?: boolean
  label: ReactNode
  name?: string
  error?: boolean
  className?: string
  onChange: ChangeEventHandler
}

export default function Checkbox({
  value,
  label,
  name,
  onChange,
  borderless = false,
  checked = false,
  error = false,
  className = '',
}: CheckboxProps): JSX.Element {
  const classes = [style.field, checked && style.checked, error && style.error, className].filter(
    Boolean,
  )

  return (
    <BoolField
      error={error}
      borderless={borderless}
      checked={checked}
      label={label}
      className={classes.join(' ')}
    >
      <Check checked={checked} className={style.checkbox} />
      <input type="checkbox" name={name} value={value} onChange={onChange} checked={checked} />
    </BoolField>
  )
}
