import type { ApiRequestDescriptor } from '../../lib/request'
import { get } from '../../lib/methods'
import type { GoogleReviewJSON } from '../../models/Google/Review'
import { authenticateAsAdvitamService } from '../../lib/decorators'

export const Reviews = {
  index: authenticateAsAdvitamService(
    (): ApiRequestDescriptor<GoogleReviewJSON[]> => get('/api/v1/google/reviews'),
  ),
}
