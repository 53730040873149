import { defineMessages } from 'react-intl';

export default defineMessages({
  ordersLinkedSection: {
    id: 'app.containers.DealTypeWrapper.OrdersLinked.ordersLinkedSection',
    defaultMessage: 'Orders Linked',
  },
  noOrdersLinked: {
    id: 'app.containers.DealTypeWrapper.OrdersLinked.noOrdersLinked',
    defaultMessage: "Il n'y a pas de commandes liées",
  },
});
