import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import type { SupportingDocumentFilters } from '@advitam/api/v1/SupportingDocuments/Versions';

import { CRUD_SUPPORTING_DOCUMENTS, INITIAL_FILTERS } from './constants';
import { fetchDocuments, saveDocuments } from './thunk';

export interface AppStateSubset {
  [CRUD_SUPPORTING_DOCUMENTS]: State;
}

export interface State {
  documents: SupportingDocumentJSON[];
  filters: SupportingDocumentFilters;
  variables: string[];
  isLoading: boolean;
  error: SerializedApiError | null;
}

export const initialState: State = {
  documents: [],
  filters: INITIAL_FILTERS,
  variables: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: CRUD_SUPPORTING_DOCUMENTS,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setFilters: (
      state,
      { payload }: PayloadAction<SupportingDocumentFilters>,
    ) => {
      state.filters = payload;
      state.documents = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDocuments.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocuments.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.documents = payload;
    });
    builder.addCase(fetchDocuments.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload;
    });
    builder.addCase(saveDocuments.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { setFilters } = slice.actions;
export default slice;
