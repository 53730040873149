import type { IntlShape } from 'react-intl';
import { PDFDocument, PDFPage } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

import { assert } from '@advitam/support';

import { DocumentTemplateData, Input, Page } from '../../models';
import renderCircle from './renderCircle';
import renderText from './renderText';
import renderCheck from './renderCheck';
import renderSignature from './renderSignature';
import renderImage from './renderImage';
import { BuildPDFContext } from './types';
import raleway from './raleway.ttf.bin';

type Renderer = (
  input: Input,
  page: PDFPage,
  context: BuildPDFContext,
) => Promise<void>;
const RENDER_FUNCTIONS: Record<string, Renderer | undefined> = {
  text: renderText,
  circle: renderCircle,
  check: renderCheck,
  signature: renderSignature,
  currentDate: renderText,
  image: renderImage,
};

async function buildPage(
  pdfPage: PDFPage,
  templatePage: Page,
  context: BuildPDFContext,
): Promise<void> {
  for (let i = 0; i < templatePage.inputs.length; i += 1) {
    const input = templatePage.inputs[i];
    const renderer = RENDER_FUNCTIONS[input.type];
    assert(
      renderer !== undefined,
      `buildPDF: missing renderer for ${input.type}`,
    );
    // eslint-disable-next-line no-await-in-loop
    await renderer(input, pdfPage, context);
  }
}

/*
TODO: How can we make a PDFFont from this PDFRef ?
TODO: The fetching is quite dirty, we should use dict.get/lookup
Feature requested upstream : https://github.com/Hopding/pdf-lib/issues/971

function findAvenir(file: PDFDocument): PDFRef | null {
  const object = file.context.enumerateIndirectObjects().find(obj => {
    const dict = obj[1] as PDFDict;
    if (!('entries' in dict)) {
      return false;
    }
    return dict
      .entries()
      .some(
        ([key, value]) =>
          key.asString() === '/FontName' &&
          (value as PDFName).asString().match(/.*Avenir.*Roman.* /),
      );
  });
  if (!object) {
    return null;
  }

  const dict = object[1] as PDFDict;
  const fontRefEntry = dict
    .entries()
    .find(([name]) => name.asString() === '/FontFile2');
  if (!fontRefEntry) {
    return null;
  }
  return fontRefEntry[1] as PDFRef;
}
*/

export default async function buildPDF(
  file: Uint8Array | ArrayBuffer,
  data: DocumentTemplateData,
  dictionary: Record<string, unknown>,
  intl: IntlShape,
): Promise<Uint8Array> {
  const document = await PDFDocument.load(file);
  document.registerFontkit(fontkit);

  const context: BuildPDFContext = {
    dictionary,
    data,
    document,
    font: await document.embedFont(raleway, { customName: 'Raleway' }),
    intl,
  };

  const pages = document.getPages();
  for (let i = 0; i < data.pages.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    await buildPage(pages[i], data.pages[i], context);
  }

  return document.save();
}
