import { createSelector, Selector } from 'reselect';

import { State, AppStateSubset } from './slice';
import { DOCUMENT_GENERATION_MODAL } from './constants';

type DocumentGenerationSelector<T> = Selector<AppStateSubset, T>;

const selectDocumentGenerationModalDomain = (state: AppStateSubset): State =>
  state[DOCUMENT_GENERATION_MODAL];

export const makeSelectDocumentsGenerationQueue = (): DocumentGenerationSelector<
  State['documentsGenerationQueue']
> =>
  createSelector(
    selectDocumentGenerationModalDomain,
    state => state.documentsGenerationQueue,
  );

export const makeSelectVariables = (): DocumentGenerationSelector<
  State['variables']
> =>
  createSelector(selectDocumentGenerationModalDomain, state => state.variables);

export const makeSelectDictionary = (): DocumentGenerationSelector<
  State['dictionary']
> =>
  createSelector(
    selectDocumentGenerationModalDomain,
    state => state.dictionary,
  );

export const makeSelectIsLoading = (): DocumentGenerationSelector<
  State['isLoading']
> =>
  createSelector(selectDocumentGenerationModalDomain, state => state.isLoading);

export const makeSelectIsGenerationLoading = (): DocumentGenerationSelector<
  State['isGenerationLoading']
> =>
  createSelector(
    selectDocumentGenerationModalDomain,
    state => state.isGenerationLoading,
  );

export const makeSelectError = (): DocumentGenerationSelector<State['error']> =>
  createSelector(selectDocumentGenerationModalDomain, state => state.error);
