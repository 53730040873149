import { FormattedMessage, useIntl } from 'react-intl';

import {
  SemelleGraniteType,
  SemelleShape,
} from '@advitam/api/models/Graveyard';
import type { ConcessionSemelleTypeJSON } from '@advitam/api/models/Concession';
import { DimensionsInput, FormLayout, Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import { Layout, messages as crudMessages } from 'containers/Crud';

import { useSelector } from 'react-redux';
import { makeSelectGraveyardsSemelles } from 'slices/data/selectors';
import messages from '../messages';
import YearPermissionSelect from './parts/YearPermissionSelect';

const PREFIX = 'graveyard';

function getConcessionSemelleOptions(
  concessionSemelles: ConcessionSemelleTypeJSON[],
): SelectableItem<ConcessionSemelleTypeJSON>[] {
  return concessionSemelles.map(semelle => ({
    name: semelle.name,
    value: semelle,
  }));
}

export function Semelle(): JSX.Element {
  const intl = useIntl();
  const availableSemelles = useSelector(makeSelectGraveyardsSemelles());

  const materialOptions: SelectableItem<SemelleGraniteType>[] = [
    {
      name: intl.formatMessage(messages.granite),
      value: SemelleGraniteType.GRANITE,
    },
    {
      name: intl.formatMessage(messages.cement),
      value: SemelleGraniteType.CEMENT,
    },
  ];

  const shapeOptions: SelectableItem<SemelleShape>[] = [
    {
      name: intl.formatMessage(messages.flat),
      value: SemelleShape.FLAT,
    },
    {
      name: intl.formatMessage(messages.waterLapel),
      value: SemelleShape.WATER_LAPEL,
    },
  ];

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.semelle.id} />}>
      <FormLayout.Row>
        <YearPermissionSelect
          name="semelle"
          permissionInputLabel={<FormattedMessage id={messages.semelle.id} />}
          permissionInputTooltip={
            <FormattedMessage id={messages.semelleTooltip.id} />
          }
          yearInputLabel={<FormattedMessage id={messages.minDuration.id} />}
          yearInputTooltip={
            <FormattedMessage id={messages.semelleMinDurationTooltip.id} />
          }
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <DimensionsInput
          names={[`${PREFIX}.semelle_width`, `${PREFIX}.semelle_length`]}
          label={<FormattedMessage id={messages.dimensions.id} />}
          tooltip={
            <FormattedMessage id={messages.semelleDimensionsTooltip.id} />
          }
          suffix={<FormattedMessage id={crudMessages.unitCentimeter.id} />}
        />
        <Select
          unselectable
          name={`${PREFIX}.required_semelle_granite_type`}
          items={materialOptions}
          label={<FormattedMessage id={messages.requiredMaterial.id} />}
          tooltip={
            <FormattedMessage id={messages.requiredMaterialTooltip.id} />
          }
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          unselectable
          name={`${PREFIX}.required_semelle_shape`}
          items={shapeOptions}
          label={<FormattedMessage id={messages.requiredShape.id} />}
          tooltip={<FormattedMessage id={messages.requiredShapeTooltip.id} />}
        />
        <Select
          name={`${PREFIX}.concession_semelle`}
          unselectable
          items={getConcessionSemelleOptions(availableSemelles)}
          label={<FormattedMessage id={messages.finishing.id} />}
          tooltip={<FormattedMessage id={messages.finishingTooltip.id} />}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
