import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { SuppliersIndexFilters } from '@advitam/api/v1/Suppliers';
import { SupplierSummaryJSON } from '@advitam/api/models/Supplier/Summary';

import { DEFAULT_FILTERS, SUPPLIERS } from './constants';
import { fetchSuppliers } from './thunk';

export interface State {
  suppliers: SupplierSummaryJSON[];
  filters: SuppliersIndexFilters;
  currentPage: number;
  hasMore: boolean;
  lastRequestId: string | null;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [SUPPLIERS]: State;
}

export const initialState: State = {
  suppliers: [],
  filters: DEFAULT_FILTERS,
  currentPage: 1,
  hasMore: true,
  lastRequestId: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: SUPPLIERS,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters(state, { payload }: PayloadAction<SuppliersIndexFilters>) {
      const { suppliers, currentPage, hasMore } = initialState;
      state.suppliers = suppliers;
      state.currentPage = currentPage;
      state.hasMore = hasMore;
      state.filters = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSuppliers.pending, (state, { meta }) => {
      state.isLoading = true;
      state.lastRequestId = meta.requestId;
    });
    builder.addCase(fetchSuppliers.fulfilled, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }

      state.isLoading = false;
      state.suppliers = [...state.suppliers, ...payload];
      state.currentPage += 1;
      state.hasMore = payload.length === DEFAULT_FILTERS.per_page;
    });
    builder.addCase(fetchSuppliers.rejected, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }

      state.isLoading = false;
      state.error = ApiError.serialize(payload);
      state.hasMore = false;
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters } = slice.actions;
export default slice;
