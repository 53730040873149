import { defineMessages } from 'react-intl'

export default defineMessages({
  arrange: { id: 'ui.components.Footer.arrange' },
  funerals: { id: 'ui.components.Footer.funerals' },
  tombstone: { id: 'ui.components.Footer.tombstone' },
  repatriation: { id: 'ui.components.Footer.repatriation' },
  flowers: { id: 'ui.components.Footer.flowers' },
  assistGPT: { id: 'ui.components.Footer.assistGPT' },
  moreInfos: { id: 'ui.components.Footer.moreInfos' },
  commitments: { id: 'ui.components.Footer.commitments' },
  reviews: { id: 'ui.components.Footer.reviews' },
  blog: { id: 'ui.components.Footer.blog' },
  press: { id: 'ui.components.Footer.press' },
  glossary: { id: 'ui.components.Footer.glossary' },
  terms: { id: 'ui.components.Footer.terms' },
  privacyPolicy: { id: 'ui.components.Footer.privacyPolicy' },
  cookies: { id: 'ui.components.Footer.cookies' },
  legalNotice: { id: 'ui.components.Footer.legalNotice' },
  generalDocumentation: { id: 'ui.components.Footer.generalDocumentation' },
  weAreHiring: { id: 'ui.components.Footer.weAreHiring' },
  copyright: { id: 'ui.components.Footer.copyright' },
  description: { id: 'ui.components.Footer.description' },
})
