import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import Frame from '@advitam/ui/components/Frame';
import Heading from '@advitam/ui/components/Heading';

import style from './Layout.module.scss';

interface LayoutProps {
  title: ReactNode;
  children: ReactNode | ReactNode[];
}

export default function Layout({ title, children }: LayoutProps): JSX.Element {
  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      <main className={style.main}>
        <Frame darker className={`col-6 ${style.content}`}>
          <Heading small>
            <b>{title}</b>
          </Heading>
          {children}
        </Frame>
      </main>
    </>
  );
}
