import { useState, useEffect } from 'react';

export function useIsPageVisible(): boolean {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = (): void =>
      setIsVisible(document.visibilityState === 'visible');
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return (): void =>
      window.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return isVisible;
}
