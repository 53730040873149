import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { useThunkDispatch, withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { Button, ErrorText, PageSpinner } from '@advitam/ui';
import { FormattedApiError } from 'components/Format/ApiError';
import documentTemplateEditorSlice, {
  setPageCount,
} from 'containers/DocumentTemplateEditor/slice';
import Editor from 'containers/DocumentTemplateEditor/Editor';
import type { DealDocument } from 'models/Deal/Document';

import slice, { reset } from './slice';
import messages from './messages';
import { fetchDocument, saveDocument } from './thunk';
import {
  makeSelectIsLoading,
  makeSelectError,
  makeSelectVariables,
} from './selectors';
import style from './DocumentEditor.module.scss';

interface DocumentEditorProps {
  document: DealDocument | undefined;
  onEditionDone: () => void;
}

function DocumentEditor({
  document,
  onEditionDone,
}: DocumentEditorProps): JSX.Element | null {
  const dispatch = useThunkDispatch();
  const intl = useIntl();

  const variables = useSelector(makeSelectVariables());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const onSave = useCallback(async (): Promise<void> => {
    assert(document !== undefined);
    const result = await dispatch(saveDocument({ document, intl }));
    if (result.meta.requestStatus === 'fulfilled') {
      onEditionDone();
    }
  }, [dispatch, onEditionDone, document, intl]);

  useEffect(() => {
    if (document) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(fetchDocument({ dealDocument: document, intl }));
    }

    return (): void => {
      dispatch(reset());
      dispatch(setPageCount(0));
    };
  }, [dispatch, document, intl]);

  if (isLoading) {
    return <PageSpinner />;
  }

  if (error) {
    return (
      <ErrorText center className={style.error}>
        <b>{document?.name}</b>
        <FormattedApiError error={error} />
        <Button
          primary
          onClick={onEditionDone}
          text={<FormattedMessage id={messages.nextFile.id} />}
        />
      </ErrorText>
    );
  }

  return (
    <Editor onSave={onSave} onCancel={onEditionDone} variables={variables} />
  );
}

export default withSlice<DocumentEditorProps>(
  slice,
  documentTemplateEditorSlice,
)(DocumentEditor);
