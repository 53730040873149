import { ReactNode, useEffect, useRef, useState } from 'react'
import Tooltip from '../Tooltip'
import style from './CroppedText.module.scss'

interface CroppedTextProps {
  children: ReactNode
  tooltipClassName?: string
}

export default function CroppedText({ children, tooltipClassName }: CroppedTextProps): JSX.Element {
  const [isCropped, setIsCropped] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const hiddenRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!containerRef.current || !hiddenRef.current) {
      return
    }

    const { clientWidth: containerWidth } = containerRef.current
    const { clientWidth: elementWidth } = hiddenRef.current
    setIsCropped(containerWidth < elementWidth)
  }, [containerRef.current?.clientWidth, hiddenRef.current?.clientWidth])

  return (
    <>
      <div ref={hiddenRef} className={style.hidden}>
        {children}
      </div>
      <Tooltip
        containerRef={containerRef}
        contentClassName={style.container}
        className={[style.tooltip, tooltipClassName].filter(Boolean).join(' ')}
        content={children}
        disabled={!isCropped}
      >
        {children}
      </Tooltip>
    </>
  )
}
