import { Polygon } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import { setActiveSupplier } from 'containers/Map/slice';
import { getPolygonsLatLng } from '../../utils';
import { getPrestationColor, isNegociatedKey } from '../utils';
import { ActivePrestationCoverage } from '../types';

interface CoverageItemProps {
  coverage: ActivePrestationCoverage;
  zIndex: number;
}

export default function CoverageItem({
  coverage,
  zIndex,
}: CoverageItemProps): JSX.Element {
  const dispatch = useDispatch();

  const { coverage: geometry, entity, key } = coverage;
  const color = getPrestationColor(key);
  const isNegociated = isNegociatedKey(key);

  const onClick = (e: google.maps.MapMouseEvent): void => {
    if (!entity || !e.latLng) {
      return;
    }

    dispatch(
      setActiveSupplier({
        ...entity,
        latitude: e.latLng?.lat(),
        longitude: e.latLng?.lng(),
      }),
    );
  };

  return (
    <>
      {geometry.coordinates.map(polygonsCoordinates => (
        <Polygon
          paths={getPolygonsLatLng(polygonsCoordinates)}
          options={{
            strokeColor: color,
            fillColor: color,
            strokeOpacity: isNegociated ? 1.0 : 0.5,
            fillOpacity: isNegociated ? 0.5 : 0.1,
            zIndex,
          }}
          onClick={onClick}
        />
      ))}
    </>
  );
}
