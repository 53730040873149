import { ReactNode, useCallback } from 'react';
import { Field, FormSpy, useForm, useFormState } from 'react-final-form';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import type { FlightJSON } from '@advitam/api/models/Flight';
import type { AutocompleteResult } from '@advitam/ui/components/Form/UI/Autosuggest/types';
import { FormUI, isRequired } from '@advitam/ui';
import { usePreviousValue } from '@advitam/react';

interface SupplierAutocompleteResult extends AutocompleteResult {
  supplier_name: string;
}

interface SupplierAutocompleteProps {
  label: ReactNode;
  disabled?: boolean;
}

export default function SupplierAutocomplete({
  label,
  disabled,
}: SupplierAutocompleteProps): JSX.Element {
  const form = useForm();
  const { values } = useFormState<FlightJSON>();
  const previousDepartureAirport = usePreviousValue(
    values.departure_airport.id,
  );

  const onChange = useCallback(
    (r: SupplierAutocompleteResult | undefined): void => {
      form.change('supplier_warehouse_id', r?.id || null);
      form.change('supplier_name', r?.supplier_name || null);
    },
    [form],
  );

  const getDisplayValue = useCallback(
    (item: FlightJSON): string => item.supplier_name || '',
    [],
  );

  return (
    <div>
      <FormSpy<FlightJSON> subscription={{ values: true }}>
        {({ values: spyValues }): null => {
          if (
            previousDepartureAirport !== undefined &&
            previousDepartureAirport !== spyValues.departure_airport.id
          ) {
            onChange(undefined);
          }
          return null;
        }}
      </FormSpy>

      <Field name="supplier_warehouse_id" validate={isRequired}>
        {({ meta }): JSX.Element => (
          <FormUI.Autosuggest<FlightJSON & AutocompleteResult>
            label={label}
            endpoint={Api.V1.absolute(
              Api.V1.Autocompletes.Path.airports.warehouses(
                values.departure_airport.id,
              ),
            )}
            value={values as FlightJSON & AutocompleteResult}
            getDisplayValue={getDisplayValue}
            requestHeaders={{ ...getTokens() }}
            onChange={onChange}
            error={meta.touched && !meta.valid}
            disabled={disabled || !values.departure_airport.id}
          />
        )}
      </Field>
    </div>
  );
}
