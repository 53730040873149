import { ReactNode } from 'react';
import { Tooltip } from '@advitam/ui';
import style from './State.module.scss';

interface StateProps {
  disabled: boolean;
  tooltip: ReactNode;
}

export default function State({ disabled, tooltip }: StateProps): JSX.Element {
  const classes = [style.container, disabled && style.disabled].filter(Boolean);
  return (
    <Tooltip contentClassName={classes.join(' ')} content={tooltip}>
      <span />
    </Tooltip>
  );
}
