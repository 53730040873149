// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalWarning-module__modal--p1o7j{position:relative;overflow:visible;border-radius:3px;padding:30px;margin:100px auto;display:flex;flex-direction:column}.ModalWarning-module__overlay--vGIiQ{z-index:20}.ModalWarning-module__modal_warning--mhVDw{display:flex;justify-content:center;margin-top:15px}`, "",{"version":3,"sources":["webpack://./src/components/ModalWarning/ModalWarning.module.scss","webpack://./../ui/scss/_z_index.scss"],"names":[],"mappings":"AAEA,mCACE,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CAGF,qCACE,UCPe,CDUjB,2CACE,YAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":["@import '~@advitam/ui/scss/z_index';\n\n.modal {\n  position: relative;\n  overflow: visible;\n  border-radius: 3px;\n  padding: 30px;\n  margin: 100px auto;\n  display: flex;\n  flex-direction: column;\n}\n\n.overlay {\n  z-index: $z-index__modal;\n}\n\n.modal_warning {\n  display: flex;\n  justify-content: center;\n  margin-top: 15px;\n}\n","$z-index__background: -1;\n$z-index__content: 0;\n$z-index__content--over: $z-index__content + 1;\n$z-index__dropdown: 5;\n$z-index__navbar: 10;\n$z-index__popup: $z-index__navbar + 5;\n$z-index__modal: $z-index__popup + 5;\n$z-index__foreground: $z-index__modal + 5;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `ModalWarning-module__modal--p1o7j`,
	"overlay": `ModalWarning-module__overlay--vGIiQ`,
	"modal_warning": `ModalWarning-module__modal_warning--mhVDw`
};
export default ___CSS_LOADER_EXPORT___;
