import { CSSProperties, DragEvent } from 'react';

import Input from '.';

import style from '../Engine.module.scss';
import { Edge, InputDragActionType, onInputDragStart } from '../utils';

interface ResizableInputProps {
  size: [number, number];
}

export default abstract class ResizableInput<
  ValueType extends string | boolean | undefined,
  T = Record<string, never> | undefined
> extends Input<ValueType, ResizableInputProps & T> {
  protected getStyle(): CSSProperties {
    const {
      size: [width, height],
    } = this.props;

    return {
      ...super.getStyle(),
      width: `${width}px`,
      height: `${height}px`,
    };
  }

  private onResizeStart(ev: DragEvent<HTMLDivElement>, edge: Edge): void {
    const { target } = this.props;

    onInputDragStart(ev, target, { type: InputDragActionType.RESIZE, edge });
    // Prevent the parent to override the drag action with move
    ev.stopPropagation();
  }

  protected renderInner(): JSX.Element | null {
    const { isFocused } = this.state;

    if (!isFocused) {
      return null;
    }

    return (
      <div className={style.resize_box} tabIndex={-1}>
        <div
          draggable
          className={[style.resize_edge, style.resize_top].join(' ')}
          onDragStart={(ev): void => this.onResizeStart(ev, Edge.TOP)}
        />
        <div
          draggable
          className={[style.resize_edge, style.resize_right].join(' ')}
          onDragStart={(ev): void => this.onResizeStart(ev, Edge.RIGHT)}
        />
        <div
          draggable
          className={[style.resize_edge, style.resize_bottom].join(' ')}
          onDragStart={(ev): void => this.onResizeStart(ev, Edge.BOTTOM)}
        />
        <div
          draggable
          className={[style.resize_edge, style.resize_left].join(' ')}
          onDragStart={(ev): void => this.onResizeStart(ev, Edge.LEFT)}
        />
        <div
          draggable
          className={[style.resize_edge, style.resize_top_right].join(' ')}
          onDragStart={(ev): void =>
            // eslint-disable-next-line no-bitwise
            this.onResizeStart(ev, Edge.TOP | Edge.RIGHT)
          }
        />
        <div
          draggable
          className={[style.resize_edge, style.resize_bottom_right].join(' ')}
          onDragStart={(ev): void =>
            // eslint-disable-next-line no-bitwise
            this.onResizeStart(ev, Edge.BOTTOM | Edge.RIGHT)
          }
        />
        <div
          draggable
          className={[style.resize_edge, style.resize_bottom_left].join(' ')}
          onDragStart={(ev): void =>
            // eslint-disable-next-line no-bitwise
            this.onResizeStart(ev, Edge.BOTTOM | Edge.LEFT)
          }
        />
        <div
          draggable
          className={[style.resize_edge, style.resize_top_left].join(' ')}
          onDragStart={(ev): void =>
            // eslint-disable-next-line no-bitwise
            this.onResizeStart(ev, Edge.TOP | Edge.LEFT)
          }
        />
      </div>
    );
  }
}
