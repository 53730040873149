import { ReactElement, ReactNode } from 'react'

import { ButtonStyleProps, getClassList } from './Button'
import Contents from './parts/Contents'

interface SubmitButtonProps extends ButtonStyleProps {
  text?: ReactNode
  icon?: ReactElement
  onClick?: () => void
}

export default function SubmitButton({
  text,
  icon,
  onClick,
  ...styleProps
}: SubmitButtonProps): JSX.Element {
  const classes = getClassList(styleProps)
  const { disabled, outline, fixedSize } = styleProps

  return (
    <button type="submit" className={classes.join(' ')} disabled={disabled} onClick={onClick}>
      <Contents fixedSize={fixedSize} outline={outline} icon={icon} text={text} />
    </button>
  )
}
