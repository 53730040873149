import { authenticate } from '../../../lib/decorators'
import { post } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { All } from './All'

export class Viewed {
  static readonly All = All

  @authenticate
  static create(ids: number[]): ApiRequestDescriptor<void> {
    return post('/api/v1/notifications/viewed', { ids })
  }
}
