import { call, put, takeLatest } from 'redux-saga/effects';
import request, { requestWithAuth } from 'utils/request';
import { QUERY_ENTITIES_LIST } from './constants';
import { updateEntitiesList, errorEntitiesList } from './actions';

export function* getEntities(action) {
  // eslint-disable-line no-unused-vars
  try {
    const requestURL = action.url;
    const requestMethod = action.auth ? requestWithAuth : request;
    let entities = yield call(requestMethod, requestURL);
    if (action.parseResponse) {
      entities = action.parseResponse(entities);
    }

    yield put(updateEntitiesList(entities));
  } catch (err) {
    yield put(errorEntitiesList(err));
  }
}

export default function* entitiesData() {
  yield takeLatest(QUERY_ENTITIES_LIST, getEntities);
}
