import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  add: { id: 'app.containers.Client.sections.Documents.add' },
  search: { id: 'app.containers.Client.sections.Documents.search' },

  documentName: {
    id: 'app.containers.Client.sections.Documents.documentName',
  },
  creationDate: {
    id: 'app.containers.Client.sections.Documents.creationDate',
  },
  actions: { id: 'app.containers.Client.sections.Documents.actions' },

  documentTypePlaceholder: {
    id: 'app.containers.Client.sections.Documents.documentTypePlaceholder',
  },

  deletionConfirmationText: {
    id: 'app.containers.Client.sections.Documents.deletionConfirmationText',
  },
  deletionConfirmationConfirm: {
    id: 'app.containers.Client.sections.Documents.deletionConfirmationConfirm',
  },
  deletionConfirmationCancel: {
    id: 'app.containers.Client.sections.Documents.deletionConfirmationCancel',
  },
});
