import type { ReactNode } from 'react'
import { Field } from 'react-final-form'
import { AiFeature, Control, MailRedactionVariables } from '../../TinyMCE/types'
import { ErrorMessages, getFormError } from '../errors'

import HtmlUI from '../UI/Html'
import { isRequired, useValidators, Validator } from '../validators'

interface InputProps {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  required?: boolean
  hideErrorMessage?: boolean
  errorMessages?: ErrorMessages
  validate?: Validator<string>
  className?: string
  tooltip?: ReactNode
  withControls?: boolean | Control[]
  controlsPosition?: 'top' | 'bottom'
  aiFeatures?: AiFeature[]
  aiRedactionVariables?: () => MailRedactionVariables
}

export default function Input({
  name,
  label,
  placeholder,
  disabled,
  required,
  hideErrorMessage,
  errorMessages,
  validate,
  className,
  tooltip,
  withControls,
  controlsPosition,
  aiFeatures,
  aiRedactionVariables,
}: InputProps): JSX.Element {
  const validators = useValidators(required && isRequired, validate)

  return (
    <Field name={name} validate={validators}>
      {(props): JSX.Element => (
        <HtmlUI
          label={label}
          onChange={props.input.onChange}
          onBlur={props.input.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          value={props.input.value}
          error={getFormError(props.meta, errorMessages, hideErrorMessage)}
          className={className}
          tooltip={tooltip}
          withControls={withControls}
          controlsPosition={controlsPosition}
          aiFeatures={aiFeatures}
          aiRedactionVariables={aiRedactionVariables}
        />
      )}
    </Field>
  )
}
