import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { Sidebar, usePreventLeaving } from 'containers/Crud';

import { Path } from '../constants';
import { makeSelectWarehouse } from '../selectors';
import messages from '../messages';
import SidebarTitle from './Title';
import ZonesLink from './ZonesLink';

export default function WarehouseSidebar(): JSX.Element {
  const warehouse = useSelector(makeSelectWarehouse());
  const isCreation = !warehouse;

  const { dirty } = useFormState();
  usePreventLeaving(isCreation || dirty);

  return (
    <Sidebar.Menu>
      <SidebarTitle />
      {warehouse?.current_update_dt && warehouse?.current_update_user_id && (
        <Sidebar.LastUpdate
          date={warehouse.current_update_dt}
          userId={warehouse.current_update_user_id}
        />
      )}
      <Sidebar.LinksSection>
        <Sidebar.Link
          to={Path.GENERAL}
          message={<FormattedMessage id={messages.general.id} />}
        />
        {!isCreation && (
          <Sidebar.Link
            to={Path.MISCELLANEOUS}
            message={<FormattedMessage id={messages.miscellaneous.id} />}
          />
        )}
        {!isCreation && <ZonesLink />}
        {!isCreation && (
          <Sidebar.Link
            to={Path.PRODUCTS}
            message={<FormattedMessage id={messages.products.id} />}
          />
        )}
      </Sidebar.LinksSection>
    </Sidebar.Menu>
  );
}
