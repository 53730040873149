import Api from 'api';
import { SupplierWarehousesFlightsAutocompleteResult as FlightAutocompleteResult } from 'api/v1/Autocompletes';
import AutoComplete from 'containers/AutoComplete/index.js';

import { FlightWeights, RepatriationDestination } from '../types';
import Infos from './Infos';

interface PlaneProps {
  weights: FlightWeights;
  destination: RepatriationDestination | null;
  onChange: (value: FlightAutocompleteResult) => void;
}

export default function Plane({
  weights,
  destination,
  onChange,
}: PlaneProps): JSX.Element {
  return (
    <div>
      <AutoComplete
        url={`${Api.V1.Autocompletes.Path.supplierWarehousesFlights}?q=`}
        getKey={(item: FlightAutocompleteResult): number => item.id}
        getValue={(item: FlightAutocompleteResult): string => item.description}
        onSelect={(_: unknown, item: FlightAutocompleteResult): void =>
          onChange(item)
        }
        paramsToAddToQuery={weights}
        auth
      />
      {destination && (
        <Infos city={destination.city} country={destination.country} />
      )}
    </div>
  );
}
