import { createSelector } from 'reselect';

/**
 * Direct selector to the dialogBox state domain
 */
const selectDialogBoxDomain = state => state.dialogBox;

/**
 * Other specific selectors
 */

const makeSelectDialogIsOpen = () =>
  createSelector(selectDialogBoxDomain, substate => substate.isOpen);

const selectProps = () =>
  createSelector(
    selectDialogBoxDomain,
    substate => (substate && substate.props) || {},
  );

const makeSelectChildren = () =>
  createSelector(selectProps(), substate => substate.children);

const makeSelectHeaderMessage = () =>
  createSelector(selectProps(), substate => substate.headerMessage);

const makeSelectOptions = () =>
  createSelector(selectProps(), substate => substate.options);

const makeSelectOnRequestClose = () =>
  createSelector(selectProps(), substate => substate.onRequestClose);

export {
  selectDialogBoxDomain,
  makeSelectDialogIsOpen,
  makeSelectChildren,
  makeSelectHeaderMessage,
  makeSelectOptions,
  makeSelectOnRequestClose,
};
