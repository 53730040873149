import { Objects } from '@advitam/support'

import { authenticate } from '../../lib/decorators'
import type { ApiRequestDescriptor } from '../../lib/request'
import { put } from '../../lib/methods'
import type { DeathDeclarationJSON } from '../../models/DeathDeclaration'

export class State {
  @authenticate
  static update(declaration: DeathDeclarationJSON): ApiRequestDescriptor<DeathDeclarationJSON> {
    return put(
      `/api/v1/death_declarations/${declaration.id}/state`,
      Objects.pick(declaration, 'state', 'comment'),
    )
  }
}
