import { FormattedMessage } from 'react-intl';

import MemorialContent from './Content';
import messages from './messages';

interface MemorialSectionProps {
  dealUUID: string;
}

export default function MemorialSection({
  dealUUID,
}: MemorialSectionProps): JSX.Element {
  return (
    <section id="memorial">
      <FormattedMessage id={messages.memorialSection.id} tagName="h2" />
      <MemorialContent uuid={dealUUID} />
    </section>
  );
}
