import { authenticate } from '../../lib/decorators'
import { get } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { NotificationJSON } from '../../models/Notification'
import { Count } from './Count'
import { Viewed } from './Viewed'

export interface NotificationIndexFilters {
  viewed?: boolean
  page?: number
  offset?: number
  per_page?: number
}

export class Notifications {
  static readonly Count = Count

  static readonly Viewed = Viewed

  @authenticate
  static index(filters: NotificationIndexFilters): ApiRequestDescriptor<NotificationJSON[]> {
    return get('/api/v1/notifications', filters)
  }
}
