import BaseSpinner from './Spinner'
import style from './Spinner.module.scss'

export default function HardSpinner(): JSX.Element {
  return (
    <div className={style.hard_container}>
      <BaseSpinner />
    </div>
  )
}
