import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import DatePicker from 'components/DatePicker/index.js';
import { TextInput } from 'components/Input/index.js';
import { ButtonV2 as Button } from 'components/Button/index.js';
import actionMessages from 'messages/actions';

import messages from '../../messages';
import { RepatriationTripFlight } from '../../types';
import style from '../../Repatriation.module.scss';

interface TripFlightProps {
  position: number;
  flight: RepatriationTripFlight;
  onDelete: () => void;
  onUpdate: (update: RepatriationTripFlight) => void;
}

export default function TripFlight({
  position,
  flight,
  onDelete,
  onUpdate,
}: TripFlightProps): JSX.Element {
  return (
    <div className="margin--0-10">
      <FormattedMessage
        id={messages.flight.id}
        values={{ nb: position }}
        tagName="h4"
      />
      <Button onClick={onDelete}>
        <FormattedMessage id={actionMessages.delete.id} />
      </Button>
      <div className={style.input_line}>
        <FormattedMessage id={messages.flightNumber.id} />
        <TextInput
          value={flight.flightNumber || ''}
          onChange={({
            target: { value },
          }: ChangeEvent<HTMLInputElement>): void =>
            onUpdate({ ...flight, flightNumber: value })
          }
        />
      </div>
      <FormattedMessage id={messages.departure.id} tagName="h5" />
      <div className={style.input_line}>
        <FormattedMessage id={messages.airport.id} />
        <TextInput
          value={flight.departureInfo.airport || ''}
          onChange={({
            target: { value },
          }: ChangeEvent<HTMLInputElement>): void =>
            onUpdate({
              ...flight,
              departureInfo: { ...flight.departureInfo, airport: value },
            })
          }
        />
      </div>
      <DatePicker
        fieldName="departure"
        displayHours
        date={flight.departureInfo.date}
        onChange={(value: number): void =>
          onUpdate({
            ...flight,
            departureInfo: { ...flight.departureInfo, date: value },
          })
        }
      />
      <FormattedMessage id={messages.arrival.id} tagName="h5" />
      <div className={style.input_line}>
        <FormattedMessage id={messages.airport.id} />
        <TextInput
          value={flight.arrivalInfo.airport || ''}
          onChange={({
            target: { value },
          }: ChangeEvent<HTMLInputElement>): void =>
            onUpdate({
              ...flight,
              arrivalInfo: { ...flight.arrivalInfo, airport: value },
            })
          }
        />
      </div>
      <DatePicker
        fieldName="arrival"
        displayHours
        date={flight.arrivalInfo.date}
        onChange={(value: number): void =>
          onUpdate({
            ...flight,
            arrivalInfo: { ...flight.arrivalInfo, date: value },
          })
        }
      />
    </div>
  );
}
