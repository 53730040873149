import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PrestationDialog from 'components/PrestationDialog';
import { SKELETON_PRESTATION } from 'components/PrestationDialog/constants';
import { isUserFuneralDirectorOrAbove } from 'components/Authorization/utils';

import ServiceWrapper from './ServiceWrapper.tsx';
import Service from './Service';
import ServicesDialogAutoComplete from './ServicesDialogAutoComplete';
import UpdateServiceModal from './UpdateService/Modal.tsx';

class Services extends PureComponent {
  state = {
    /** is dialog services opened */
    isDialogOpen: false,
    /** service to edit in dialog */
    serviceToEdit: null,
    /** is editing */
    isEditing: false,
    serviceToUpdate: null,
    isUpdateModalOpen: false,
  };

  /**
   * Function to toggle state
   */
  toggleIsDialogOpen = () =>
    this.setState(prevSate => ({ isDialogOpen: !prevSate.isDialogOpen }));

  /**
   * Function to edit service
   *
   * @param {string} serviceToEdit
   */
  onEditService = serviceToEdit => {
    this.toggleIsDialogOpen();
    this.setState({ serviceToEdit, isEditing: true });
  };

  onOpenUpdateServiceModal = serviceToUpdate => {
    this.setState({ serviceToUpdate, isUpdateModalOpen: true });
  };

  onCloseUpdateServiceModal = () => {
    this.setState({ serviceToUpdate: null, isUpdateModalOpen: false });
  };

  /**
   * Function to clear servieToEdit state
   */
  clearServiceToEdit = () =>
    this.setState({ serviceToEdit: null, isEditing: false });

  /**
   * Function to add extra data before adding service
   *
   * @param {object} serviceToAdd service to add
   */
  addService = serviceToAdd => {
    const { addService, stepId } = this.props;
    const updatedService = {
      ...serviceToAdd,
      step_id: stepId,
      amount: 1,
    };
    addService(updatedService);
  };

  render() {
    const {
      services,
      removeService,
      isManualMode,
      userRole,
      updateService,
      stepId,
    } = this.props;

    const {
      isDialogOpen,
      serviceToEdit,
      isEditing,
      serviceToUpdate,
      isUpdateModalOpen,
    } = this.state;

    let displayedServices = [...services];
    if (!isUserFuneralDirectorOrAbove(userRole)) {
      displayedServices = displayedServices.filter(
        service =>
          service.error ||
          service.id < 1 ||
          service.priceLine.price ||
          service.priceLine.manual_price,
      );
    }

    return (
      <div className="services-section">
        <div className="services-section__services">
          {displayedServices.map((service, index) => (
            <ServiceWrapper
              // eslint-disable-next-line react/no-array-index-key
              key={`${service.type}.${service.stepId}.${index}`}
              service={service}
              onOpenUpdateServiceModal={() =>
                this.onOpenUpdateServiceModal(service)
              }
            >
              <Service
                service={service}
                removeService={removeService}
                updateService={updateService}
                onEditService={this.onEditService}
                userRole={userRole}
                isManualMode={isManualMode}
              />
            </ServiceWrapper>
          ))}
        </div>
        <button
          className="services-section__add"
          onClick={this.toggleIsDialogOpen}
          type="button"
        >
          +
        </button>
        {isEditing || isManualMode ? (
          <PrestationDialog
            addPrestation={this.addService}
            toggleDialog={this.toggleIsDialogOpen}
            isDialogOpen={isDialogOpen}
            prestationToEdit={serviceToEdit}
            clearPrestationToEdit={this.clearServiceToEdit}
            updatePrestation={updateService}
            stepId={stepId}
            prestationSkeleton={SKELETON_PRESTATION}
          />
        ) : (
          <ServicesDialogAutoComplete
            addService={this.addService}
            services={services}
            toggleDialog={this.toggleIsDialogOpen}
            isDialogOpen={isDialogOpen}
          />
        )}
        <UpdateServiceModal
          isOpen={isUpdateModalOpen}
          service={serviceToUpdate}
          updateService={updateService}
          onClose={this.onCloseUpdateServiceModal}
        />
      </div>
    );
  }
}

Services.propTypes = {
  /** current stepId */
  stepId: PropTypes.number.isRequired,
  /** list of services */
  services: PropTypes.array.isRequired,
  /** function to add a service */
  addService: PropTypes.func.isRequired,
  /** function to remove a service */
  removeService: PropTypes.func.isRequired,
  /** is it a manual mode */
  isManualMode: PropTypes.bool.isRequired,
  /** user role */
  userRole: PropTypes.number.isRequired,
  /** function to update service */
  updateService: PropTypes.func.isRequired,
};

export default Services;
