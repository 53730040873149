import { ReactNode } from 'react'
import Text from '../Text'
import style from './Profile.module.scss'

interface ProfileCardProps {
  img: ReactNode
  name: ReactNode
  className?: string
}

export default function ProfileCard({ img, name, className }: ProfileCardProps): JSX.Element {
  return (
    <div className={[style.card, className].join(' ')}>
      <div className={style.image}>{img}</div>
      <Text small className={style.name} tagName="div">
        {name}
      </Text>
    </div>
  )
}
