import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TrashIcon from 'components/Icons/TrashIcon';
import EditPerson from './EditPerson';

const CollapsablePerson = props => {
  const {
    defaultOpen,
    withRemove,
    removePerson,
    message,
    person,
    className,
  } = props;
  const [isOpen, toggleIsOpen] = useState(defaultOpen || false);
  const onRemove = ev => {
    ev.stopPropagation();
    removePerson();
  };

  return (
    <div className={`collapsablePerson ${className || ''}`}>
      <div
        role="button"
        tabIndex={0}
        className="collapsablePerson__head"
        onClick={() => toggleIsOpen(!isOpen)}
      >
        <FormattedMessage
          {...message}
          values={{
            name: `${person.firstName || ''} ${person.lastName || ''}`,
          }}
        />
        <div className="collapsablePerson__head__buttons">
          <button type="button">
            {isOpen ? <span>&#8593;</span> : <span>&#8595;</span>}
          </button>
          {withRemove && (
            <button type="button" onClick={onRemove}>
              <TrashIcon />
            </button>
          )}
        </div>
      </div>
      <div
        className={`collapsablePerson__collapse--${isOpen ? 'open' : 'close'}`}
      >
        <EditPerson {...props} />
      </div>
    </div>
  );
};

CollapsablePerson.propTypes = {
  defaultOpen: PropTypes.bool,
  withContact: PropTypes.bool,
  withAddress: PropTypes.bool,
  withNationality: PropTypes.bool,
  withBirthPlace: PropTypes.bool,
  withBirthDate: PropTypes.bool,
  withLink: PropTypes.bool,
  withWork: PropTypes.bool,
  withoutFirstname: PropTypes.bool,
  withoutCiv: PropTypes.bool,
  withRemove: PropTypes.bool,
  removePerson: PropTypes.func,
  message: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  handleUpdate: PropTypes.func.isRequired,
  people: PropTypes.number,
};

export default CollapsablePerson;
