/*
 *  CheckboxDelegate trigger the delegation of a perticular step
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CheckboxLabel, { LEFT_POSITION } from 'components/Input/CheckboxLabel';
import messages from 'containers/Deal/DealFuneral/StepsSection/StepEditors/messages';

class CheckboxDelegate extends PureComponent {
  render() {
    const { isDelegate, updateDelegate } = this.props;
    return (
      <div className="checkboxDelegate">
        <CheckboxLabel
          updateCheckbox={() => updateDelegate(!isDelegate)}
          message={messages.delegate}
          checkboxValue={isDelegate}
          position={LEFT_POSITION}
        />
      </div>
    );
  }
}

CheckboxDelegate.propTypes = {
  isDelegate: PropTypes.bool.isRequired,
  updateDelegate: PropTypes.func.isRequired,
};

export default CheckboxDelegate;
