import { FormattedDate, useIntl } from 'react-intl';

import Api from '@advitam/api';
import {
  SupportingDocumentIndex,
  SupportingDocumentIndexJSON,
} from '@advitam/api/models/SupportingDocuments/SupportingDocumentIndex';
import Trash from '@advitam/ui/images/icons/trash.svg';
import Button from '@advitam/ui/components/Button';
import { DocumentTypeAutocomplete } from '@advitam/ui/components/Form';
import ResourceList from '@advitam/ui/components/ResourceList';

import messages from '../messages';
import type { UnsavedSupportingDocument } from '../types';
import FileButton from './FileButton';
import style from '../SupportingDocuments.module.scss';

interface RowProps {
  document: SupportingDocumentIndexJSON | UnsavedSupportingDocument;
  isFiltered: boolean;
  prefix: string;
  tokens: Record<string, string>;
  onDelete: () => void;
}

export default function Row({
  document,
  isFiltered,
  prefix,
  tokens,
  onDelete,
}: RowProps): JSX.Element {
  const intl = useIntl();
  const instanciatedDocument = document.id
    ? new SupportingDocumentIndex(document)
    : null;

  return (
    <ResourceList.Row
      className={[style.row, isFiltered && style.hidden]
        .filter(Boolean)
        .join(' ')}
    >
      <ResourceList.Cell className={style.name_cell}>
        {document.id ? (
          document.document_type.pretty_name
        ) : (
          <DocumentTypeAutocomplete
            required
            name={`${prefix}.document_type`}
            endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.documentTypes)}
            tokens={tokens}
            className={style.autocomplete}
            placeholder={intl.formatMessage(messages.documentTypePlaceholder)}
          />
        )}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.date_cell}>
        {instanciatedDocument?.created_at && (
          <FormattedDate value={instanciatedDocument.created_at} />
        )}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.actions}>
        <FileButton document={document} prefix={prefix} />
        <Button outline onClick={onDelete} icon={<Trash />} />
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
