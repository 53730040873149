import { get, isEqual } from 'lodash';
import type { AnyObject } from 'react-final-form';
import { NavigateFunction } from 'react-router';

import { HTMLElements, sleep } from '@advitam/support';
import { isClosedCollapsible, toggleCollapsible } from '@advitam/ui';

import { FORM_KEYS } from './constants';
import type { FuneralForm } from './types';

export function areInitialValuesEqual(
  objectA?: AnyObject,
  objectB?: AnyObject,
): boolean {
  if (!objectA || !objectB) {
    return objectA === objectB;
  }

  const a = objectA as FuneralForm;
  const b = objectB as FuneralForm;
  const isDealEqual = FORM_KEYS.every(key =>
    isEqual(get(a.deal, key), get(b.deal, key)),
  );
  const isRestEqual = Object.entries(a).every(
    ([key, value]) => key === 'deal' || b[key as keyof FuneralForm] === value,
  );
  return isDealEqual && isRestEqual;
}

export async function focusField(
  name: string,
  navigate: NavigateFunction,
): Promise<void> {
  const input = document.querySelector(`[name='${name}']`);
  if (!input) {
    return;
  }

  let section: string | null = null;
  let collapsible: HTMLElement | null = null;
  for (
    let parent = input.parentElement;
    parent && parent.tagName !== 'FORM';
    parent = parent.parentElement
  ) {
    if (parent.dataset.sectionPath !== undefined) {
      section = parent.dataset.sectionPath;
    }

    if (isClosedCollapsible(parent)) {
      collapsible = parent;
    }
  }

  if (
    section !== null &&
    window.location.hash !== section &&
    (section || window.location.hash === '')
  ) {
    navigate(`#${section}`);
  }

  if (collapsible) {
    await HTMLElements.waitForVisibility(collapsible);
    toggleCollapsible(collapsible);
  }

  await sleep(0.5);
  input.scrollIntoView({ behavior: 'smooth' });
}
