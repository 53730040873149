import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { CrematoriumCeremonyFilters } from '@advitam/api/v1/Crematoriums/Ceremonies';
import type { AgeRangeJSON } from '@advitam/api/models/Crematorium/AgeRange';
import type { CeremonyJSON } from '@advitam/api/models/Crematorium/Ceremony';
import type { RoomJSON } from '@advitam/api/models/Crematorium/Room';

import { CREMATORIUM_CEREMONIES } from './constants';
import type { AppStateSubset, State } from './slice';

type CeremoniesSelector<T> = Selector<AppStateSubset, T>;

const selectCeremoniesDomain = (state: AppStateSubset): State =>
  state[CREMATORIUM_CEREMONIES];

export const makeSelectIsLoading = (): CeremoniesSelector<boolean> =>
  createSelector(selectCeremoniesDomain, substate => substate.isLoading);

export const makeSelectIsSaving = (): CeremoniesSelector<boolean> =>
  createSelector(selectCeremoniesDomain, substate => substate.isSaving);

export const makeSelectFilters = (): CeremoniesSelector<
  CrematoriumCeremonyFilters
> => createSelector(selectCeremoniesDomain, substate => substate.filters);

export const makeSelectAgeRanges = (): CeremoniesSelector<AgeRangeJSON[]> =>
  createSelector(selectCeremoniesDomain, substate => substate.ageRanges);

export const makeSelectRooms = (): CeremoniesSelector<RoomJSON[]> =>
  createSelector(selectCeremoniesDomain, substate => substate.rooms);

export const makeSelectCeremonies = (): CeremoniesSelector<CeremonyJSON[]> =>
  createSelector(selectCeremoniesDomain, substate => substate.ceremonies);

export const makeSelectError = (): CeremoniesSelector<SerializedApiError | null> =>
  createSelector(selectCeremoniesDomain, substate => substate.error);
