import { createSelector, Selector } from 'reselect';

import {
  RegionalHealthAuthority,
  RegionalHealthAuthorityJSON,
} from '@advitam/api/models/RegionalHealthAuthority';
import type { SerializedApiError } from '@advitam/api';

import { REGIONAL_HEALTH_AUTHORITY } from './constants';
import type { AppStateSubset, State } from './slice';
import { NewRegionalHealthAuthority } from './types';

type RegionalHealthAuthoritySelector<T> = Selector<AppStateSubset, T>;

const selectRegionalHealthAuthorityDomain = (state: AppStateSubset): State =>
  state[REGIONAL_HEALTH_AUTHORITY];

export const makeSelectRawRegionalHealthAuthority = (): RegionalHealthAuthoritySelector<
  RegionalHealthAuthorityJSON | NewRegionalHealthAuthority | null
> =>
  createSelector(
    selectRegionalHealthAuthorityDomain,
    state => state.regionalHealthAuthority,
  );

export const makeSelectRegionalHealthAuthority = (): RegionalHealthAuthoritySelector<RegionalHealthAuthority | null> =>
  createSelector(
    makeSelectRawRegionalHealthAuthority(),
    rawRegionalHealthAuthority =>
      rawRegionalHealthAuthority?.id
        ? new RegionalHealthAuthority(rawRegionalHealthAuthority)
        : null,
  );

export const makeSelectIsLoading = (): RegionalHealthAuthoritySelector<
  boolean
> =>
  createSelector(
    selectRegionalHealthAuthorityDomain,
    ({ isLoading }) => isLoading,
  );

export const makeSelectIsSaving = (): RegionalHealthAuthoritySelector<
  boolean
> =>
  createSelector(
    selectRegionalHealthAuthorityDomain,
    ({ isSaving }) => isSaving,
  );

export const makeSelectIsDestroying = (): RegionalHealthAuthoritySelector<
  boolean
> =>
  createSelector(
    selectRegionalHealthAuthorityDomain,
    ({ isDestroying }) => isDestroying,
  );

export const makeSelectIsUpdatingName = (): RegionalHealthAuthoritySelector<
  boolean
> =>
  createSelector(
    selectRegionalHealthAuthorityDomain,
    ({ isUpdatingName }) => isUpdatingName,
  );

export const makeSelectError = (): RegionalHealthAuthoritySelector<SerializedApiError | null> =>
  createSelector(selectRegionalHealthAuthorityDomain, ({ error }) => error);

export const makeSelectDestroyError = (): RegionalHealthAuthoritySelector<SerializedApiError | null> =>
  createSelector(
    selectRegionalHealthAuthorityDomain,
    ({ destroyError }) => destroyError,
  );
