import { call, put, takeLatest } from 'redux-saga/effects';
import download from 'downloadjs';

import { requestWithAuth } from 'utils/request';
import { MimeTypes } from 'utils/constants';

import Api, { request } from 'api';
import { Pdf } from 'lib';

import {
  FETCH_DOCUMENTS,
  DOWNLOAD_FILES,
  MERGE_FILES,
  FETCH_DOCUMENT_VERSIONS,
  TOGGLE_LOCKED_VERSION,
} from './constants';
import {
  setDocuments,
  errorDocumentList,
  setLoading,
  fetchDocumentVersionsSuccess,
  versionLockFailure,
} from './actions';

export function* fetchDocuments({ dealUUID }) {
  try {
    const requestURL = `/api/v1/deals/${dealUUID}/documents`;
    const documents = yield call(requestWithAuth, requestURL);
    yield put(setDocuments(documents));
  } catch (err) {
    yield put(errorDocumentList(err));
  }
}

export function* fetchDocumentVersions({ documentId }) {
  try {
    const { body } = yield request(
      Api.V1.Deals.Documents.getVersions(documentId),
    );
    yield put(fetchDocumentVersionsSuccess(body));
  } catch (err) {
    yield put(errorDocumentList(err.body));
  }
}

export function* lockDocumentVersion({
  documentId,
  lockedVersion,
  previousState,
}) {
  try {
    yield request(
      Api.V1.Deals.Documents.lockVersion(
        documentId,
        lockedVersion ? lockedVersion.id : null,
      ),
    );
  } catch (err) {
    yield put(versionLockFailure(documentId, previousState));
  }
}

export function* downloadFiles({ files, dealUUID }) {
  try {
    const requestURL = `/api/v1/deals/${dealUUID}/documents/downloads/email`;
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ documents: files }),
    };
    yield call(requestWithAuth, requestURL, params);
    yield put(setLoading(false));
  } catch (err) {
    yield put(errorDocumentList(err));
  }
}

export function* mergeFiles({ files, filename }) {
  try {
    const doc = yield call(Pdf.merge, files);
    yield call(download, doc, filename, MimeTypes.PDF);
    yield put(setLoading(false));
  } catch (err) {
    yield put(errorDocumentList({ status: 500, message: 'Unexpected' }));
  }
}

export default function* documentsData() {
  yield takeLatest(FETCH_DOCUMENTS, fetchDocuments);
  yield takeLatest(FETCH_DOCUMENT_VERSIONS, fetchDocumentVersions);
  yield takeLatest(TOGGLE_LOCKED_VERSION, lockDocumentVersion);
  yield takeLatest(DOWNLOAD_FILES, downloadFiles);
  yield takeLatest(MERGE_FILES, mergeFiles);
}
