import { ReactNode } from 'react'

import Label from '../Label'
import style from './Tag.module.scss'

interface TagProps {
  children: ReactNode
}

export default function Tag({ children }: TagProps): JSX.Element {
  return <Label className={style.product}>{children}</Label>
}
