export const enum NotificationType {
  BATCH_SIGNATURE = 'batch_signature',
  BOOKING_ANSWERED = 'booking_answered',
  CLIENT_REVIEW = 'client_review',
  FAILED_SENDING = 'failed_sending',
  FUNNEL_CREATED = 'funnel_created',
  LAYER_CREATED = 'layer_created',
  PAYMENT_UPDATED = 'payment_updated',
  REMINDER = 'reminder',
}
