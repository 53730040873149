import { assert, isNil } from '@advitam/support'

import type { Validator, ValidatorReturnType } from './Validator'
import { ValueType } from './ValueType'

const ERROR = 'file_is_too_big'
const DEFAULT_MAX_FILE_SIZE = 8 * 1000 * 1000

export function maxFileSize(size = DEFAULT_MAX_FILE_SIZE): Validator<Blob> {
  return (value: Blob | ValueType): ValidatorReturnType => {
    assert(
      isNil(value) || value instanceof Blob,
      'fileMaxSize validator may only be used on file inputs',
    )

    if (!value || value.size <= size) {
      return undefined
    }

    return ERROR
  }
}
