import { Role } from 'models/User';

/**
 * Determine if user is an admin
 *
 * @param   role    {Number}    role of user
 * @returns {boolean}
 */
export const isUserAdmin = role => role === Role.ADMIN;

/**
 * Determine if user is a funeral director or above
 *
 * @param   role    {Number}    role of user
 * @returns {boolean}
 */
export const isUserFuneralDirectorOrAbove = role =>
  role <= Role.FUNERAL_DIRECTOR;

/**
 * Check if user is allowed or not
 *
 * @param {Number} roleNeeded role needed to access to the content
 * @param {Number} role current user role
 * @returns {Boolean} is access granted or not
 */
export function isAccessGranted(roleNeeded, role) {
  if (roleNeeded === null) return true;
  if (role > roleNeeded) return false;
  return true;
}
