import { ReactNode, useState, useMemo, useCallback, ChangeEvent, KeyboardEvent } from 'react'
import { debounce } from 'throttle-debounce'

import { assert } from '@advitam/support'

import { FormUI } from '../../Form'
import Filter from './Filter'
import { Filter as FilterModel, FilterSelection, isDateRangeFilter, SearchFilter } from './types'
import style from './Filters.module.scss'

type OnChangeFunc = (filters: Record<string, FilterSelection>) => void

interface FiltersProps {
  filters: FilterModel[]
  onChange: OnChangeFunc
  children?: ReactNode
  className?: string
  searchFilter?: SearchFilter
}

function buildInitialState(
  filters: FilterModel[],
  searchFilter?: SearchFilter,
): Record<string, FilterSelection> {
  const initialState: Record<string, FilterSelection> = {}
  filters.forEach((filter): void => {
    if (filter.type === 'DateRange') {
      initialState[filter.startName] = filter.initialStartValue
      initialState[filter.endName] = filter.initialEndValue
    } else if (filter.initialValue) {
      initialState[filter.name] = filter.initialValue
    }
  })

  if (searchFilter) {
    initialState[searchFilter.name] = searchFilter.initialValue
  }

  return initialState
}

export default function Filters({
  filters,
  onChange,
  children,
  className = '',
  searchFilter,
}: FiltersProps): JSX.Element {
  const classes = [style.filters, className].filter(Boolean)
  const [selectedFilters, setSelectedFilters] = useState(buildInitialState(filters, searchFilter))
  const debouncedOnChange = useMemo(() => debounce(300, onChange), [onChange])

  const onFilterChange = useCallback(
    (changes: Record<string, FilterSelection>): void => {
      const newSelection = {
        ...selectedFilters,
        ...changes,
      }

      setSelectedFilters(newSelection)
      debouncedOnChange(newSelection)
    },
    [setSelectedFilters, selectedFilters, debouncedOnChange],
  )

  const onSearchFilterChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
      assert(searchFilter !== undefined)
      onFilterChange({ [searchFilter.name]: value })
    },
    [onFilterChange, searchFilter],
  )

  const onSearchFilterKey = useCallback((event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
    }
  }, [])

  return (
    <div className={classes.join(' ')}>
      {filters.map(filter => (
        <Filter
          filter={filter}
          onChange={onFilterChange}
          selectedFilters={selectedFilters}
          key={isDateRangeFilter(filter) ? filter.startName : filter.name}
        />
      ))}
      <div className={style.spacer} />
      {searchFilter && (
        <FormUI.Input
          value={selectedFilters[searchFilter.name] as string}
          name={searchFilter.name}
          placeholder={searchFilter.placeholder}
          onChange={onSearchFilterChange}
          onKeyUp={onSearchFilterKey}
          onKeyDown={onSearchFilterKey}
        />
      )}
      {children}
    </div>
  )
}
