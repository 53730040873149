import { FormattedMessage } from 'react-intl';

import { Button, Tooltip } from '@advitam/ui';
import Pen from '@advitam/ui/images/icons/pen.svg';

import messages from '../messages';
import style from '../List.module.scss';

interface EditIconProps {
  onClick: () => void;
}

export default function EditIcon({ onClick }: EditIconProps): JSX.Element {
  return (
    <Tooltip content={<FormattedMessage id={messages.edit.id} />}>
      <Button
        outline
        icon={<Pen />}
        className={style.action}
        onClick={onClick}
      />
    </Tooltip>
  );
}
