import { ReactNode } from 'react'
import { Field } from 'react-final-form'
import { NullableString } from '../converters'

import TextAreaUI from '../UI/TextArea'
import { composeValidators, isRequired, Validator } from '../validators'

interface TextAreaProps {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  required?: boolean
  maxLength?: number
  renderBelow?: (value: string) => ReactNode
  validate?: Validator<string | null>
  className?: string
}

export default function TextArea({
  name,
  label,
  placeholder,
  disabled,
  required,
  maxLength,
  renderBelow,
  validate,
  className,
}: TextAreaProps): JSX.Element {
  const validator = composeValidators<string | null>(required && isRequired, validate)

  return (
    <Field
      name={name}
      validate={validator}
      parse={NullableString.parse}
      format={NullableString.format}
      formatOnBlur
    >
      {(props): JSX.Element => (
        <TextAreaUI
          label={label}
          onChange={props.input.onChange}
          onBlur={props.input.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          value={props.input.value}
          maxLength={maxLength}
          renderBelow={renderBelow}
          error={props.meta.touched && !props.meta.valid}
          className={className}
        />
      )}
    </Field>
  )
}
