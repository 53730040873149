import { createAsyncThunk } from '@reduxjs/toolkit';

import { assert } from '@advitam/support';
import Api, { requestAsync as request } from 'api';
import type { DocumentTemplateJSON } from 'models/DocumentTemplate';
import { DocumentTemplateType } from 'models/DocumentTemplate/Type';

import {
  DOCUMENT_TEMPLATE_EDITOR,
  BACKGROUND_MIME_TYPE,
  BACKGROUND_QUALITY,
} from './constants';
import { DocumentTemplateData, Resource } from './models';
import {
  makeSelectDefaultFontSize,
  makeSelectDictionary,
  makeSelectPages,
  makeSelectTemplate,
} from './selectors';
import { AppStateSubset } from './slice';
import buildHTML from './utils/buildHTML';

export const fetchData = createAsyncThunk(
  `${DOCUMENT_TEMPLATE_EDITOR}/FETCH_DATA`,
  async (templateId: number, { rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.DocumentTemplates.show<DocumentTemplateData>(templateId),
      );
      assert(body !== null);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

function fetchNodes(target: 'img'): Array<HTMLImageElement>;
function fetchNodes(target: 'div > canvas'): Array<HTMLCanvasElement>;
function fetchNodes(
  target: 'img' | 'div > canvas',
): Array<HTMLImageElement | HTMLCanvasElement> {
  return Array.from(
    document.querySelectorAll(
      `#document-template-editor__document .page_wrapper > ${target}`,
    ),
  );
}

function toBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error());
        }
      },
      BACKGROUND_MIME_TYPE,
      BACKGROUND_QUALITY,
    );
  });
}

function getBackgroundBlobs(): Promise<Blob[]> {
  const images = fetchNodes('img');
  if (images.length > 0) {
    return Promise.all(
      images.map(img => fetch(img.src).then(res => res.blob())),
    );
  }
  return Promise.all(fetchNodes('div > canvas').map(toBlob));
}

export const uploadDocument = createAsyncThunk(
  `${DOCUMENT_TEMPLATE_EDITOR}/UPLOAD_DOCUMENT`,
  async ({ type, id }: Resource, { getState, rejectWithValue }) => {
    const document: DocumentTemplateJSON<DocumentTemplateData> = {
      public_uploads: [],
      liquid_template: '',
      data: { pages: [], defaultFontSize: 16 },
      resource_type: type,
      resource_id: id,
      type: DocumentTemplateType.HTML,
      id: 0,
      created_at: '',
      updated_at: '',
    };

    const blobs = await getBackgroundBlobs();
    try {
      const publicUploads = [];
      // We are using a loop because we want the uploads to be synchronously uploaded
      for (let i = 0; i < blobs.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const { body } = await request(
          Api.V1.PublicUploads.create(blobs[i], type, id),
        );
        assert(body !== null);
        publicUploads.push(body);
      }

      document.public_uploads = publicUploads;
    } catch (err) {
      return rejectWithValue(err);
    }

    const state = getState() as AppStateSubset;
    document.data.pages = makeSelectPages()(state);
    document.data.defaultFontSize = makeSelectDefaultFontSize()(state);
    const dictionary = makeSelectDictionary()(state);
    document.liquid_template = buildHTML(document, dictionary);

    try {
      const { body } = await request(Api.V1.DocumentTemplates.create(document));
      assert(body !== null);

      const tpl = makeSelectTemplate()(state);
      if (tpl && tpl.resource_id === id && tpl.resource_type === type) {
        await request(Api.V1.DocumentTemplates.destroy(tpl.id));
      }

      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
