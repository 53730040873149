import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Draggable } from 'react-beautiful-dnd';
import DatePicker from 'components/DatePicker';

import { CollapsablePerson } from 'containers/Deal/EditPerson';
import otherMessages from 'components/ConcessionSelection/messages';
import { SelectList } from 'components/Input';

import {
  CONTAINER_TYPE_COFFIN,
  CONTAINER_TYPE_URN,
  CONTAINER_TYPE_BONES_BOX,
} from './constants';
import messages from './messages';

const Buried = ({ buried, removeBuried, updateBuried, index, intl }) => {
  /**
   * Function to update buried
   *
   * @param {string} name key to update
   * @param {string} value value to update
   */
  const onChange = ({ target: { name, value } }) => {
    updateBuried({ ...buried, [name]: value });
  };

  return (
    <Draggable
      key={String(buried.id)}
      draggableId={String(buried.id)}
      index={index}
    >
      {provided => (
        <div
          className="buried"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <CollapsablePerson
            person={buried}
            defaultOpen
            message={otherMessages.person}
            withRemove
            removePerson={() => removeBuried(buried.id)}
            handleUpdate={updatedBuried => updateBuried(updatedBuried)}
          >
            <div className="buried__content">
              <div className="buried__content__intermentDate">
                <FormattedMessage {...messages.intermentDate} />
                <DatePicker
                  noLabel
                  fieldName="intermentDate"
                  date={buried.intermentDate}
                  onChange={value =>
                    onChange({ target: { name: 'intermentDate', value } })
                  }
                />
              </div>
              <div>
                <FormattedMessage {...messages.container} />
                <SelectList
                  value={buried.container}
                  options={[
                    {
                      name: intl.formatMessage(messages.coffin),
                      value: CONTAINER_TYPE_COFFIN,
                    },
                    {
                      name: intl.formatMessage(messages.urn),
                      value: CONTAINER_TYPE_URN,
                    },
                    {
                      name: intl.formatMessage(messages.bonesBox),
                      value: CONTAINER_TYPE_BONES_BOX,
                    },
                  ]}
                  name="container"
                  onChange={onChange}
                  emptyOption
                />
              </div>
            </div>
          </CollapsablePerson>
        </div>
      )}
    </Draggable>
  );
};

Buried.propTypes = {
  /** buried person */
  buried: PropTypes.object.isRequired,
  /** function to remove a buried */
  removeBuried: PropTypes.func.isRequired,
  /** function to update a buried */
  updateBuried: PropTypes.func.isRequired,
  /** index of buried */
  index: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Buried);
