import type { ApiRequestDescriptor } from '../../lib/request'
import { get } from '../../lib/methods'
import type { CityhallJSON } from '../../models/Website/Cityhall'
import { authenticateAsAdvitamService } from '../../lib/decorators'

export const Cityhalls = {
  show: authenticateAsAdvitamService(
    (slug: string): ApiRequestDescriptor<CityhallJSON> => get(`/api/v1/website/cityhalls/${slug}`),
  ),
}
