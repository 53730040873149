import { assert } from '@advitam/support'

import style from './Collapsible.module.scss'

export function isCollapsible(element: HTMLElement): boolean {
  return element.classList.contains(style.wrapper)
}

export function isOpen(element: HTMLElement): boolean {
  return element.classList.contains(style.open)
}

export function isOpenCollapsible(element: HTMLElement): boolean {
  return isCollapsible(element) && isOpen(element)
}

export function isClosedCollapsible(element: HTMLElement): boolean {
  return isCollapsible(element) && !isOpen(element)
}

export function toggleCollapsible(element: HTMLElement): void {
  const title = element.children[0]
  assert(title instanceof HTMLElement)
  title.click()
}
