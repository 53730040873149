import style from './Switch.module.scss'

interface SwitchProps {
  value: boolean
  onChange?: () => void
  className?: string
}

export default function Switch({ className, value, onChange }: SwitchProps): JSX.Element {
  const classes = [style.switch, value && style.active, className].filter(Boolean)

  return (
    <span onClick={onChange} className={classes.join(' ')}>
      <span />
    </span>
  )
}
