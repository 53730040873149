import { FormattedMessage } from 'react-intl'

import PhoneIcon from '../../../../../images/icons/phone-alt.svg'
import { LinkButton } from '../../../../Button'
import Text from '../../../../Text'
import style from './CTA.module.scss'
import messages from './messages'

interface PhoneCTAProps {
  number: string
  formattedNumber: string
}

export default function PhoneCTA({ number, formattedNumber }: PhoneCTAProps): JSX.Element {
  return (
    <LinkButton
      primary
      href={`tel:${number}`}
      className={style.phone_button}
      text={
        <>
          <PhoneIcon />
          <span>
            {formattedNumber}
            <Text tiny className={style.free}>
              <FormattedMessage id={messages.freeText.id} />
            </Text>
          </span>
        </>
      }
    />
  )
}
