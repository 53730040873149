import { IntlShape, useIntl } from 'react-intl';

import { Civility } from 'models/Person';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from './messages';

interface Nameable {
  civility: Civility | null;
  firstname: string | null;
  lastname: string | null;
}

export function formatCivility(
  civility: Civility | null,
  intl: IntlShape,
): string {
  if (!civility) {
    return '';
  }
  return safeFormatMessage(intl, messages, civility);
}

export function formatName(person: Nameable, intl: IntlShape): string {
  return [
    person.civility && formatCivility(person.civility, intl),
    person.firstname,
    person.lastname,
  ]
    .filter(part => part && part !== '')
    .join(' ');
}

interface FormattedNameProps {
  person: Nameable;
}

export function FormattedName({ person }: FormattedNameProps): JSX.Element {
  const intl = useIntl();
  return <>{formatName(person, intl)}</>;
}
