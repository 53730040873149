import { BackgroundValue } from './constants'
import style from './Background.module.scss'

export interface BackgroundStyleProps {
  color: BackgroundValue
  textured?: boolean
  rounded?: boolean
}

export function getClassList({
  color,
  textured = false,
  rounded = false,
}: BackgroundStyleProps): string[] {
  const classes = []
  if (rounded) {
    classes.push(style.rounded)
  }
  if (textured) {
    classes.push(style.texture)
    classes.push(style[`texture__${color}`])
  } else {
    classes.push(style[`flat__${color}`])
  }
  return classes
}

export default function Full({ color, textured }: BackgroundStyleProps): JSX.Element {
  const classes = [style.background].concat(getClassList({ color, textured }))
  return <div className={classes.join(' ')}></div>
}
