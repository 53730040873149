import Text from '../../../Text'
import { Bottom as BottomProps } from '../../types'
import style from './Bottom.module.scss'

export default function Bottom({ copyright, links }: BottomProps): JSX.Element {
  return (
    <div className={style.bottom}>
      <Text small>{copyright}</Text>
      <ul>
        {links.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
    </div>
  )
}
