import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import messagesCrud from 'messages/crud';

import SelectChoice from './SelectChoice';

const OPTION_YES_VALUE = 'true';
const OPTION_NO_VALUE = 'false';

class SelectBoolean extends PureComponent {
  update = ({ target: { name, value } }) => {
    const { onChange } = this.props;
    const valueInBool = this.textToBoolean(value);
    onChange({
      target: {
        name,
        value: valueInBool,
      },
    });
  };

  booleanToText(value) {
    switch (value) {
      case true:
        return 'true';
      case false:
        return 'false';
      default:
        return '';
    }
  }

  textToBoolean(value) {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return null;
    }
  }

  render() {
    const { intl, ...props } = this.props;
    const { value } = this.props;
    const OPTION_YES_NAME = intl.formatMessage({ ...messagesCrud.optionYes });
    const OPTION_NO_NAME = intl.formatMessage({ ...messagesCrud.optionNo });

    const options = [
      {
        name: OPTION_YES_NAME,
        value: OPTION_YES_VALUE,
      },
      {
        name: OPTION_NO_NAME,
        value: OPTION_NO_VALUE,
      },
    ];

    return (
      <SelectChoice
        {...props}
        options={options}
        onChange={this.update}
        value={this.booleanToText(value)}
      />
    );
  }
}

SelectBoolean.propTypes = {
  isReadingMode: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  emptyOption: PropTypes.bool,
};

SelectBoolean.defaultProps = {
  emptyOption: true,
};

export default injectIntl(SelectBoolean);
