import { Model } from '@advitam/api/models/Model';
import { Validator } from '@advitam/ui';
import { FuneralForm } from 'containers/Deal/Funeral/types';

export const EMAIL_DUPLICATED_ERROR = 'email_duplicated';

export function isEmailUnique(
  index: number,
): Validator<string | null, FuneralForm> {
  return (value: string | null, allValues: FuneralForm): string | undefined => {
    if (!value) {
      return undefined;
    }

    const otherEmails = allValues.abilities
      .filter(
        (ability, abilityIndex) =>
          abilityIndex !== index && !Model.isDestroyed(ability),
      )
      .map(ability => ability.client.email);
    if (otherEmails.includes(value)) {
      return EMAIL_DUPLICATED_ERROR;
    }

    return undefined;
  };
}
