import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { Pagination, request } from '@advitam/api';
import { assert } from 'lib/Assert';

import { FUNDING_AGENCIES, ITEMS_PER_PAGE } from './constants';
import { AppStateSubset } from './slice';
import { makeSelectFilters, makeSelectPage } from './selectors';

export const fetchFundingAgencies = createAsyncThunk(
  `${FUNDING_AGENCIES}/FETCH`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const filters = makeSelectFilters()(state);
    const page = makeSelectPage()(state);

    try {
      const response = await request(
        Api.V1.FundingAgencies.index(filters, page, ITEMS_PER_PAGE),
      );
      assert(response.body !== null);
      return {
        agencies: response.body,
        hasMore: Pagination.getPageCount(response) > page,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
