import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { LinkButton, SubmitButton } from '@advitam/ui/components/Button';
import ErrorText from '@advitam/ui/components/ErrorText';
import {
  ErrorMessages,
  FormLayout,
  isPasswordValid,
  PasswordInput,
} from '@advitam/ui/components/Form';
import Spinner from '@advitam/ui/components/Spinner';
import { withSlice } from '@advitam/react';

import { Path } from 'containers/App/constants';
import { makeSelectIsAuthenticated } from 'slices/auth';
import actionsMessages from 'messages/actions';
import { assert } from 'lib/support';

import Layout from '../parts/Layout';
import style from '../Auth.module.scss';
import messages from './messages';
import { makeSelectError, makeSelectIsLoading } from './selectors';
import slice from './slice';
import { updatePassword } from './thunk';

const PASSWORD_FIELD = 'password';
const PASSWORD_CONFIRMATION_FIELD = 'passwordConfirmation';

const CONFIG_QUERY_KEY = 'config';
const TOKEN_QUERY_KEY = 'token';

const PASSWORD_NOT_EQUAL_ERROR = 'password_not_equal';
const ERROR_MESSAGES: ErrorMessages = {
  [PASSWORD_NOT_EQUAL_ERROR]: messages.passwordNotEqual,
};

function validateValues(values: UpdatePasswordForm): ValidationErrors {
  if (values.password === values.passwordConfirmation) {
    return undefined;
  }

  return {
    [PASSWORD_CONFIRMATION_FIELD]: PASSWORD_NOT_EQUAL_ERROR,
  };
}

interface UpdatePasswordForm {
  [PASSWORD_FIELD]: string;
  [PASSWORD_CONFIRMATION_FIELD]: string;
}

function UpdatePassword(): JSX.Element {
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const isAuthenticated = useSelector(makeSelectIsAuthenticated());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const config = params.get(CONFIG_QUERY_KEY);
  const token = params.get(TOKEN_QUERY_KEY);

  const onSubmit = ({ password }: UpdatePasswordForm): void => {
    assert(config !== null && token !== null);
    dispatch(updatePassword({ config, token, password }));
  };

  if (isAuthenticated) {
    return <Navigate replace to={Path.INDEX} />;
  }

  if (!config || !token) {
    return <Navigate replace to={Path.INDEX} />;
  }

  if (error) {
    return (
      <Layout title={<FormattedMessage id={messages.title.id} />}>
        <ErrorText>
          <FormattedMessage id={messages.tokenExpired.id} />
        </ErrorText>
      </Layout>
    );
  }

  return (
    <Layout title={<FormattedMessage id={messages.title.id} />}>
      <Form<UpdatePasswordForm> onSubmit={onSubmit} validate={validateValues}>
        {({ handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <FormLayout.Row className={style.compact}>
              <PasswordInput
                required
                name={PASSWORD_FIELD}
                label={<FormattedMessage id={messages.newPassword.id} />}
                validate={isPasswordValid}
              />
            </FormLayout.Row>
            <FormLayout.Row className={style.compact}>
              <PasswordInput
                name={PASSWORD_CONFIRMATION_FIELD}
                label={<FormattedMessage id={messages.confirmPassword.id} />}
                errorMessages={ERROR_MESSAGES}
              />
            </FormLayout.Row>

            <div className={style.actions}>
              <SubmitButton
                primary
                icon={isLoading ? <Spinner /> : undefined}
                className={style.action}
                text={<FormattedMessage id={actionsMessages.validate.id} />}
              />
              <LinkButton
                outline
                internal
                href={Path.LOGIN}
                className={style.action}
                text={<FormattedMessage id={actionsMessages.cancel.id} />}
              />
            </div>
          </form>
        )}
      </Form>
    </Layout>
  );
}

export default withSlice(slice)(UpdatePassword);
