// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavbarUsers-module__users--fxBOp{margin-left:8px}.NavbarUsers-module__users--fxBOp>span{margin-left:-8px}`, "",{"version":3,"sources":["webpack://./src/containers/ResourceCable/NavbarUsers/NavbarUsers.module.scss"],"names":[],"mappings":"AAAA,kCACE,eAAA,CAEA,uCACE,gBAAA","sourcesContent":[".users {\n  margin-left: 8px;\n\n  > span {\n    margin-left: -8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"users": `NavbarUsers-module__users--fxBOp`
};
export default ___CSS_LOADER_EXPORT___;
