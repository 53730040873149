import { defineMessages } from 'react-intl';

export default defineMessages({
  clientsSection: {
    id: 'app.containers.Deal.Clients.clientsSection',
    defaultMessage: 'Clients',
  },
  addClient: {
    id: 'app.containers.Deal.Clients.addClient',
    defaultMessage: 'Add a client',
  },
  clientCollapse: {
    id: 'app.containers.Deal.Clients.clientCollapse',
    defaultMessage: 'Client : {name}',
  },
  duplicateDeal: {
    id: 'app.containers.Deal.Clients.duplicateDeal',
    defaultMessage: 'Duplicate Deal',
  },
  role: {
    id: 'app.containers.Deal.Clients.role',
    defaultMessage: 'Role',
  },
  owner: {
    id: 'app.containers.Deal.Clients.owner',
    defaultMessage: 'Owner',
  },
  editor: {
    id: 'app.containers.Deal.Clients.editor',
    defaultMessage: 'Editor',
  },
  reader: {
    id: 'app.containers.Deal.Clients.reader',
    defaultMessage: 'Reader',
  },
  notary: {
    id: 'app.containers.Deal.Clients.notary',
  },
  empowered: {
    id: 'app.containers.Deal.Clients.empowered',
    defaultMessage: 'Empowered',
  },
  goToClient: {
    id: 'app.containers.Deal.Clients.goToClient',
    defaultMessage: 'Go to client page',
  },
  duplicateMessage: {
    id: 'app.containers.Deal.Clients.duplicateMessage',
    defaultMessage: 'Do you really want to duplicate this deal ?',
  },
  requiredClient: {
    id: 'app.containers.Deal.Clients.requiredClient',
    defaultMessage: 'This client is required',
  },
  errorWithEmail: {
    id: 'app.containers.Deal.Clients.errorWithEmail',
  },
  duplicated_local_client: {
    id: 'app.containers.Deal.Clients.duplicated_local_client',
  },
  client_already_exists: {
    id: 'app.containers.Deal.Clients.client_already_exists',
  },
});
