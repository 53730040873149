import { ReactNode, useCallback } from 'react'
import { Field, useForm } from 'react-final-form'

import { useFormValue } from '@advitam/react'
import { Arrays } from '@advitam/support'

import Autosuggest from '../UI/Autosuggest'
import { AutocompleteResult } from '../UI/Autosuggest/types'
import { composeValidators, isRequired } from '../validators'

interface AutocompleteProps<TSuggestion> {
  placeholder?: string
  required?: boolean
  label: ReactNode
  tooltip?: ReactNode
  name: string
  endpoint: string
  getDisplayValue?: (data: TSuggestion) => string
  keys: Array<keyof TSuggestion>
  transformKeys?: Record<string, string>
  requestHeaders?: Record<string, string>
  searchParams?: Record<string, string | string[]>
  className?: string
  disabled?: boolean
  expandOnTop?: boolean
  minCharsRequest?: number
}

export default function ApiAutocomplete<TSuggestion extends AutocompleteResult>({
  placeholder,
  name,
  label,
  tooltip,
  endpoint,
  required,
  getDisplayValue,
  keys,
  transformKeys = {},
  requestHeaders,
  searchParams,
  className,
  disabled,
  expandOnTop,
  minCharsRequest,
}: AutocompleteProps<TSuggestion>): JSX.Element {
  const keysWithId = Arrays.uniq([...keys, 'id' as keyof TSuggestion])

  const form = useForm()
  const value = useFormValue<TSuggestion>(name)

  const onChange = useCallback(
    (r: TSuggestion | undefined): void => {
      keysWithId.forEach(key => {
        const formKey = transformKeys[key as string] || key
        form.change([name, formKey].join('.'), r?.[key])
      })
    },
    [name, form, keysWithId, transformKeys],
  )

  return (
    <div className={className}>
      <Field name={`${name}.id`} validate={composeValidators(required && isRequired)}>
        {({ meta }): JSX.Element => (
          <>
            <Autosuggest
              label={label}
              tooltip={tooltip}
              placeholder={placeholder}
              endpoint={endpoint}
              value={value}
              getDisplayValue={getDisplayValue}
              requestHeaders={requestHeaders}
              searchParams={searchParams}
              onChange={onChange}
              error={meta.touched && !meta.valid}
              disabled={disabled}
              minCharsRequest={minCharsRequest}
              expandOnTop={expandOnTop}
            />
          </>
        )}
      </Field>
    </div>
  )
}
