import { authenticateAsAdvitamService } from '../../lib/decorators'
import { get } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { DepartmentJSON } from '../../models/Website/Department'
import type { DepartmentDetailsJSON } from '../../models/Website/Department/Details'

export const Departments = {
  index: (): ApiRequestDescriptor<DepartmentJSON[]> => get(`/api/v1/website/departments`),

  show: authenticateAsAdvitamService(
    (slug: string): ApiRequestDescriptor<DepartmentDetailsJSON> =>
      get(`/api/v1/website/departments/${slug}`),
  ),
}
