import { createAction } from 'lib/reactvitam/action_cable';

export interface UpdateFailedPayload {
  user_id: number;
  session_id: string;
}

/**
 * The resource updated failed
 * Not for sending by the frontend, this is only the interface for reception of
 * backend messages.
 */
function updateFailed(
  user_id: number,
  session_id: string,
): UpdateFailedPayload {
  return { user_id, session_id };
}

export default createAction(updateFailed, 'update_failed');
