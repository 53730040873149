import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import type { DealCommunication } from '@advitam/api/models/Deal/Communication';
import { CommunicationChannelType } from '@advitam/api/models/Deal/Communication/ChannelType';
import { Button, Tooltip } from '@advitam/ui';
import Expand from '@advitam/ui/images/icons/expand.svg';

import { openContentModal, openMailContentModal } from '../../slice';
import messages from '../../messages';
import style from './Details.module.scss';

interface ContentProps {
  communication: DealCommunication;
  index: number;
}

export default function Content({
  communication,
  index,
}: ContentProps): JSX.Element | null {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    if (communication.channel_type === CommunicationChannelType.EMAIL) {
      dispatch(openMailContentModal(index));
    } else {
      dispatch(openContentModal(index));
    }
  }, [communication.channel_type, index, dispatch]);

  if (!communication.content) {
    return null;
  }

  return (
    <Tooltip content={<FormattedMessage id={messages.seeContent.id} />}>
      <Button
        className={style.button}
        outline
        icon={<Expand />}
        onClick={onClick}
      />
    </Tooltip>
  );
}
