import PageBase, { connected } from '.';

interface TemplatePageProps {
  src: string;
}

class PdfPage extends PageBase<TemplatePageProps> {
  renderBase(): JSX.Element | null {
    const { src } = this.props;
    return <img src={src} alt="" crossOrigin="anonymous" />;
  }
}

export default connected(PdfPage);
