import { Layout } from 'containers/Crud';

import {
  Billing,
  Columbarium,
  Concessions,
  CoveredPlaces,
  Functioning,
  Semelle,
  TemporaryVault,
  Works,
  Worships,
} from './fields';

export default function MiscContainer(): JSX.Element {
  return (
    <Layout.Container>
      <Layout.ColumnGroup>
        <Layout.Column>
          <Concessions />
          <Functioning />
          <Columbarium />
          <Worships />
          <TemporaryVault />
        </Layout.Column>
        <Layout.Column>
          <CoveredPlaces />
          <Works />
          <Semelle />
          <Billing />
        </Layout.Column>
      </Layout.ColumnGroup>
    </Layout.Container>
  );
}
