import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  editWorship: { id: 'app.containers.Crematorium.Worship.editWorship' },
  new: { id: 'app.containers.Crematorium.Worship.new' },
  newTitle: { id: 'app.containers.Crematorium.Worship.newTitle' },
  worshipType: { id: 'app.containers.Crematorium.Worship.worshipType' },
  worshipAutocomplete: {
    id: 'app.containers.Crematorium.Worship.worshipAutocomplete',
  },
  comment: {
    id: 'app.containers.Crematorium.Worship.comment',
  },
  address: {
    id: 'app.containers.Crematorium.Worship.address',
  },
  postal_code: {
    id: 'app.containers.Crematorium.Worship.postal_code',
  },
  city: {
    id: 'app.containers.Crematorium.Worship.city',
  },
  prefered_contact_media: {
    id: 'app.containers.Crematorium.Worship.prefered_contact_media',
  },
  price: {
    id: 'app.containers.Crematorium.Worship.price',
  },
  phone: {
    id: 'app.containers.Crematorium.Worship.phone',
  },
  phone_2: {
    id: 'app.containers.Crematorium.Worship.phone_2',
  },
  fax: {
    id: 'app.containers.Crematorium.Worship.fax',
  },
  email: {
    id: 'app.containers.Crematorium.Worship.email',
  },
  delete: {
    id: 'app.containers.Crematorium.Worship.delete',
  },
});
