// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Circled-module__index--DAid5{display:flex;align-items:center;justify-content:center;width:24px;height:24px;border:1px solid #0f4447;border-radius:100%}.Circled-module__index--DAid5 b{display:block}.Circled-module__index--DAid5 svg{display:block;width:18px;padding:4px}`, "",{"version":3,"sources":["webpack://./../ui/components/Circled/Circled.module.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,wBAAA,CACA,kBAAA,CAEA,gCACE,aAAA,CAGF,kCACE,aAAA,CACA,UAAA,CACA,WAAA","sourcesContent":["@import '../../scss/colors';\n\n.index {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 24px;\n  height: 24px;\n  border: 1px solid $color__dark-green;\n  border-radius: 100%;\n\n  b {\n    display: block;\n  }\n\n  svg {\n    display: block;\n    width: 18px;\n    padding: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": `Circled-module__index--DAid5`
};
export default ___CSS_LOADER_EXPORT___;
