import DocumentToUpload from 'components/DocumentToUpload';
import type { DealDocument } from 'models/Deal/Document';

import DocumentUploaded from './DocumentUploaded';

interface DocumentProps {
  doc: DealDocument;
  isFileSelected?: boolean;
  onClick?: () => void;
}

/**
 * Wrapper to render a DocumentNotUpload or DocumentToUpload
 */
export default function Document({
  doc,
  isFileSelected,
  onClick = (): void => {},
}: DocumentProps): JSX.Element {
  if (!doc.isUpload || (doc.version && doc.version.id !== null)) {
    return (
      <DocumentUploaded
        doc={doc}
        onClick={onClick}
        isFileSelected={isFileSelected}
      />
    );
  }
  return <DocumentToUpload name={doc.name} />;
}
