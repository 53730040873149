import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSpy, useForm, useFormState } from 'react-final-form';

import { GraveyardJSON, Permission } from '@advitam/api/models/Graveyard';
import { NumberInput, Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import { messages as crudMessages } from 'containers/Crud';

import { GraveyardMiscForm } from '../../types';
import { PERMISSION_VALUE } from '../constants';
import messages from '../../messages';

interface YearPermissionSelectProps {
  name: keyof Pick<GraveyardJSON, 'fausse_case' | 'semelle'>;
  permissionInputLabel?: ReactNode;
  permissionInputTooltip?: ReactNode;
  yearInputLabel?: ReactNode;
  yearInputTooltip?: ReactNode;
  minYear?: number;
}

export default function YearPermissionSelect({
  name,
  permissionInputLabel,
  permissionInputTooltip,
  yearInputLabel,
  yearInputTooltip,
  minYear = 1,
}: YearPermissionSelectProps): JSX.Element {
  const intl = useIntl();

  const form = useForm<GraveyardMiscForm>();
  const { values } = useFormState<GraveyardMiscForm>();
  const permissionValue = values.sectionValues[name];

  const permissionOptions: SelectableItem<Permission>[] = [
    {
      name: intl.formatMessage(messages.allowed),
      value: Permission.ALLOWED,
    },
    {
      name: intl.formatMessage(messages.mandatory),
      value: Permission.REQUIRED,
    },
    {
      name: intl.formatMessage(messages.forbidden),
      value: Permission.FORBIDDEN,
    },
  ];

  return (
    <>
      <Select
        name={`sectionValues.${name}`}
        label={permissionInputLabel}
        tooltip={permissionInputTooltip}
        items={permissionOptions}
      />
      <NumberInput
        min={minYear}
        name={`graveyard.${name}`}
        disabled={permissionValue !== Permission.REQUIRED}
        label={yearInputLabel}
        tooltip={yearInputTooltip}
        format={(value: number): string | number => (value < 1 ? '' : value)}
        suffix={<FormattedMessage id={crudMessages.unitYear.id} />}
      />
      <FormSpy<GraveyardMiscForm>
        subscription={{ values: true }}
        onChange={({ values: spyValues }): void => {
          const newPermissionValue = spyValues.sectionValues[name];
          if (newPermissionValue && permissionValue !== newPermissionValue) {
            form.change(
              `graveyard.${name}`,
              PERMISSION_VALUE[newPermissionValue],
            );
          }
        }}
      />
    </>
  );
}
