import BaseSpinner from 'components/Spinner';
import style from './HardSpinner.module.scss';

export default function HardSpinner() {
  return (
    <div className={style.container}>
      <BaseSpinner />
    </div>
  );
}
