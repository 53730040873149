import { TodoItemBase, TodoItemBaseJSON } from './Base'
import { TodoType } from './Type'

export type StandardTodoType = Exclude<TodoType, TodoType.SEND_BATCH | TodoType.REQUEST_DOCUMENT>

export interface StandardTodoItemJSON extends TodoItemBaseJSON {
  todo_type: StandardTodoType
  action: null
}

export interface StandardTodoItem extends TodoItemBaseJSON {
  todo_type: StandardTodoType
  action: null
}

export class StandardTodoItem extends TodoItemBase<StandardTodoItemJSON> {
  // eslint-disable-next-line no-useless-constructor
  constructor(data: StandardTodoItemJSON) {
    super(data)
  }
}
