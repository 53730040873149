import { isEqual } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { isApiError, request } from '@advitam/api';
import { RoomJSON } from '@advitam/api/models/Crematorium/Room';

import { makeSelectCrematorium } from 'containers/Crematorium/selectors';
import { AppStateSubset as CrematoriumAppStateSubset } from 'containers/Crematorium/slice';
import { assert } from 'lib/Assert';

import { CREMATORIUM_ROOMS } from './constants';
import { NewCrematoriumRoom } from './types';
import { AppStateSubset as RoomsAppStateSubset } from './slice';
import { makeSelectRooms } from './selectors';

type AppStateSubset = CrematoriumAppStateSubset & RoomsAppStateSubset;

const ERROR_ROOM_IN_USE =
  'crematoriums__room__base__restrict_dependent_destroy__has_many';

export const fetchRooms = createAsyncThunk(
  `${CREMATORIUM_ROOMS}_FETCH`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const crematorium = makeSelectCrematorium()(state);
    assert(crematorium?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Crematoriums.Rooms.index(crematorium?.id),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateRooms = createAsyncThunk(
  `${CREMATORIUM_ROOMS}_UPDATE_ROOMS`,
  async (rooms: RoomJSON[], { rejectWithValue }) => {
    try {
      const responses = await Promise.all(
        rooms.map(room => request(Api.V1.Crematoriums.Rooms.update(room))),
      );

      return responses.map(response => response.body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createRooms = createAsyncThunk(
  `${CREMATORIUM_ROOMS}_CREATE_ROOMS`,
  async (rooms: NewCrematoriumRoom[], { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const crematorium = makeSelectCrematorium()(state);
    assert(crematorium?.id !== undefined);

    try {
      const responses = await Promise.all(
        rooms.map(room =>
          request(Api.V1.Crematoriums.Rooms.create(crematorium.id, room)),
        ),
      );

      return responses.map(response => response.body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteRooms = createAsyncThunk(
  `${CREMATORIUM_ROOMS}_DELETE_ROOMS`,
  async (rooms: RoomJSON[], { rejectWithValue }) => {
    const deletedRooms: RoomJSON[] = [];

    try {
      const errors = await Promise.all(
        rooms.map(async room => {
          try {
            await request(Api.V1.Crematoriums.Rooms.destroy(room.id));
            deletedRooms.push(room);
            return undefined;
          } catch (err) {
            if (!isApiError(err) || err.errorCodes[0] !== ERROR_ROOM_IN_USE) {
              throw err;
            }
            return err;
          }
        }),
      );

      const hasAlreadyInUseError = errors.some(Boolean);
      return { deletedRooms, hasAlreadyInUseError };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

function filterRoomsToDelete(
  stateRooms: RoomJSON[],
  updates: RoomJSON[],
): RoomJSON[] {
  return stateRooms.filter(room => !updates.some(r => r.id === room.id));
}

function filterRoomsToUpdate(
  stateRooms: RoomJSON[],
  updates: RoomJSON[],
): RoomJSON[] {
  return updates.filter((room, idx) => !isEqual(room, stateRooms[idx]));
}

function isNewCrematoriumRoom(
  room: RoomJSON | NewCrematoriumRoom,
): room is NewCrematoriumRoom {
  return room.id === undefined;
}

function isRoomJSON(room: RoomJSON | NewCrematoriumRoom): room is RoomJSON {
  return Boolean(room.id);
}

export const saveRooms = createAsyncThunk(
  `${CREMATORIUM_ROOMS}_SAVE`,
  async (
    rooms: Array<RoomJSON | NewCrematoriumRoom>,
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const stateRooms = makeSelectRooms()(state);

    const editedRooms = rooms.filter(isRoomJSON);
    const roomsToDelete = filterRoomsToDelete(stateRooms, editedRooms);
    const roomsToUpdate = filterRoomsToUpdate(stateRooms, editedRooms);
    const roomsToCreate = rooms.filter(isNewCrematoriumRoom);

    try {
      const [
        { deletedRooms, hasAlreadyInUseError },
        updatedRooms,
        createdRooms,
      ] = await Promise.all([
        await dispatch(deleteRooms(roomsToDelete)).unwrap(),
        await dispatch(updateRooms(roomsToUpdate)).unwrap(),
        await dispatch(createRooms(roomsToCreate)).unwrap(),
      ]);

      const finalRooms = stateRooms
        .concat(createdRooms)
        .filter(room => !deletedRooms.some(r => r.id === room.id))
        .map(room => {
          const updatedRoom = updatedRooms.find(r => r.id === room.id);
          if (updatedRoom) {
            return updatedRoom;
          }
          return room;
        });

      return { finalRooms, hasAlreadyInUseError };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
