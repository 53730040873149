import { DealType } from '@advitam/api/models/Deal/Type';

export const DEAL = 'Deal';

export const UPDATE_MANUAL_MODE = 'app/DealTypeWrapper/UPDATE_MANUAL_MODE';

export const ERROR = 'app/DealTypeWrapper/ERROR';
export const ERROR_MODAL = 'app/DealTypeWrapper/ERROR_MODAL';

export const DEAL_TYPE_FUNERAL = DealType.FUNERAL;
export const DEAL_TYPE_ITEM = DealType.ITEM;
export const DEAL_TYPE_MARBLE = DealType.MARBLE;

// SideBar
export const QUERY_UPDATE_DEAL_STATE =
  'app/DealTypeWrapper/QUERY_UPDATE_DEAL_STATE';
export const QUERY_UPDATE_DEAL_OWNER =
  'app/DealTypeWrapper/QUERY_UPDATE_DEAL_OWNER';

// Clients section
export const POST_NEW_ACCOUNT = 'app/DealTypeWrapper/POST_NEW_ACCOUNT';

// EditPerson
export const GET_CLIENT_DATA = 'app/DealTypeWrapper/GET_CLIENT_DATA';

// OrdersLinked
export const GET_ORDERS_LINKED = 'app/DealTypeWrapper/GET_ORDERS_LINKED';
export const SET_ORDERS_LINKED = 'app/DealTypeWrapper/SET_ORDERS_LINKED';

// Suppliers
export const GET_SELECTED_SUPPLIERS =
  'app/DealTypeWrapper/GET_SELECTED_SUPPLIERS';
export const SET_SELECTED_SUPPLIERS =
  'app/DealTypeWrapper/SET_SELECTED_SUPPLIERS';
export const POST_SELECTED_SUPPLIERS =
  'app/DealTypeWrapper/POST_SELECTED_SUPPLIERS';
export const UNBOOK_SUPPLIERS = 'app/DealTypeWrapper/UNBOOK_SUPPLIERS';
export const BOOK_SUPPLIERS = 'app/DealTypeWrapper/BOOK_SUPPLIERS';
export const SUCCESS_BOOK_SUPPLIERS =
  'app/DealTypeWrapper/SUCCESS_BOOK_SUPPLIERS';
export const PRE_BOOK_SUPPLIERS = 'app/DealTypeWrapper/PRE_BOOK_SUPPLIERS';
export const SUCCESS_UNBOOK_SUPPLIERS =
  'app/DealTypeWrapper/SUCCESS_UNBOOK_SUPPLIERS';
export const SUCCESS_PRE_BOOK_SUPPLIERS =
  'app/DealTypeWrapper/SUCCESS_PRE_BOOK_SUPPLIERS';
export const UPDATE_PRESTATION_BOOKING_STATUS =
  'app/DealTypeWrapper/UPDATE_PRESTATION_BOOKING_STATUS';
export const SUCCESS_UPDATE_PRESTATION_BOOKING_STATUS =
  'app/DealTypeWrapper/SUCCESS_UPDATE_PRESTATION_BOOKING_STATUS';
export const SET_SECTION_OPENED = 'app/DealTypeWrapper/SET_SECTION_OPENED';

export const PUT_DEAL_BRAND = 'app/DealTypeWrapper/PUT_DEAL_BRAND';

export const ORDER_STATUS_OK = 'ok';
export const ORDER_STATUS_PENDING = 'pending';
export const ORDER_STATUS_CANCELLED = 'cancelled';

export const GET_GRAVEYARD_CONCESSION_TYPES =
  'app/DealTypeWrapper/GET_GRAVEYARD_CONCESSION_TYPES';
export const GET_GRAVEYARD_CONCESSIONS =
  'app/DealTypeWrapper/GET_GRAVEYARD_CONCESSIONS';
export const SET_GRAVEYARD_CONCESSION_TYPES =
  'app/DealTypeWrapper/SET_GRAVEYARD_CONCESSION_TYPES';
export const SET_GRAVEYARD_CONCESSIONS =
  'app/DealTypeWrapper/SET_GRAVEYARD_CONCESSIONS';
export const GET_GRAVEYARD_BASIC_INFOS =
  'app/DealTypeWrapper/GET_GRAVEYARD_BASIC_INFOS';
export const SET_GRAVEYARD_BASIC_INFOS =
  'app/DealTypeWrapper/SET_GRAVEYARD_BASIC_INFOS';
export const GET_ESTIMATION = 'app/DealTypeWrapper/GET_ESTIMATION';

export const ADD_TRIGGER_PING_SERVICE =
  'app/DealTypeWrapper/ADD_TRIGGER_PING_SERVICE';
export const REMOVE_TRIGGER_PING_SERVICE =
  'app/DealTypeWrapper/REMOVE_TRIGGER_PING_SERVICE';

// Families constant add

export const GROUP_FUNERAL = 'funeral';
export const GROUP_URN = 'urn';
export const GROUP_FLOWERS = 'flowers';
export const GROUP_MARBLE = 'marble';
