import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { SendBatchTodoItem } from '@advitam/api/models/Deal/TodoItem';
import SendIcon from '@advitam/ui/images/icons/send.svg';

import { openWithFetch } from 'containers/Deal/SendBatchModal/thunk';

import { makeSelectHasBatchMissingDocs } from '../../../selectors';
import Action from '../../parts/Action';
import MissingDocuments from '../MissingDocuments';
import Task from '../../parts/Task';
import messages from '../../messages';
import style from './SendBatch.module.scss';

interface SendBatchProps {
  item: SendBatchTodoItem;
}

export default function SendBatch({ item }: SendBatchProps): JSX.Element {
  const dispatch = useDispatch();
  const hasMissingDocs = useSelector(makeSelectHasBatchMissingDocs(item));
  const canSendBatch = item.action?.id && !hasMissingDocs && !item.isDone;

  const onSendBatch = (): void => {
    dispatch(openWithFetch(item.action.id));
  };

  return (
    <>
      <Task
        item={item}
        checkboxClassName={style[item.action.status]}
        action={
          canSendBatch && (
            <Action
              icon={<SendIcon />}
              message={<FormattedMessage id={messages.send.id} />}
              onClick={onSendBatch}
            />
          )
        }
      />
      {!item.isDone && <MissingDocuments item={item} />}
    </>
  );
}
