import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';

import { Button, ResourceList } from '@advitam/ui';
import type { Filter } from '@advitam/ui/components/ResourceList';

import actionMessages from 'messages/actions';

import { makeSelectCanEdit } from '../../../../selectors';
import messages from '../messages';
import { makeSelectCategoryOptions, makeSelectFilters } from '../selectors';
import { setFilters } from '../slice';
import { FUNERAL_WORK_SKELETON } from '../constants';
import { FuneralWorksFilters, WarehouseZoneForm } from '../types';
import style from './List.module.scss';

export default function ZoneFilters(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const canUserEdit = useSelector(makeSelectCanEdit());

  const filters = useSelector(makeSelectFilters());
  const categoryOptions = useSelector(makeSelectCategoryOptions());

  const form = useForm<WarehouseZoneForm>();
  const { values } = useFormState<WarehouseZoneForm>();

  const onAddFuneralWork = (): void => {
    form.change('sectionValues', [
      FUNERAL_WORK_SKELETON,
      ...values.sectionValues,
    ]);
  };

  const filtersShape: Filter[] = [
    {
      type: 'Select',
      name: 'category_id_eq',
      placeholder: intl.formatMessage(messages.category),
      items: categoryOptions,
      initialValue: filters.category_id_eq,
    },
  ];

  const onChange = useCallback(
    (newFilters: FuneralWorksFilters): void => {
      dispatch(setFilters(newFilters));
    },
    [dispatch],
  );

  return (
    <ResourceList.Filters
      className={style.filters}
      filters={filtersShape}
      onChange={onChange}
      searchFilter={{
        name: 'q',
        initialValue: filters.q,
        placeholder: intl.formatMessage(actionMessages.search),
      }}
    >
      {canUserEdit && (
        <Button
          primary
          text={<FormattedMessage id={messages.add.id} />}
          onClick={onAddFuneralWork}
          className={style.add}
        />
      )}
    </ResourceList.Filters>
  );
}
