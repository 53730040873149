import { DealDefunctJSON } from 'models/Deal/Defunct';
import { AbilityBase } from 'models/Ability';
import { PersonFields } from '../Person';

export enum LayerType {
  EMPOWERED_CLIENT = 'empowered_client',
  DEFUNCT = 'defunct',
  DEFUNCT_FATHER = 'defunct_father',
  DEFUNCT_MOTHER = 'defunct_mother',
  DEFUNCT_PARTNER = 'defunct_partner',
}

export interface EmpoweredClientLayer {
  id?: number;
  client_id?: number;
  action: LayerType.EMPOWERED_CLIENT;
  value: Partial<AbilityBase>;
}

export interface DefunctLayer {
  id?: number;
  client_id?: number;
  action: LayerType.DEFUNCT;
  value: Partial<DealDefunctJSON>;
}

export interface DefunctFatherLayer {
  id?: number;
  client_id?: number;
  action: LayerType.DEFUNCT_FATHER;
  value: Partial<PersonFields>;
}

export interface DefunctMotherLayer {
  id?: number;
  client_id?: number;
  action: LayerType.DEFUNCT_MOTHER;
  value: Partial<PersonFields>;
}

export interface DefunctPartnerLayer {
  id?: number;
  client_id?: number;
  action: LayerType.DEFUNCT_PARTNER;
  value: Partial<PersonFields>;
}

export type Layer =
  | EmpoweredClientLayer
  | DefunctLayer
  | DefunctFatherLayer
  | DefunctMotherLayer
  | DefunctPartnerLayer;
