import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { SupportingDocumentIndexJSON } from '@advitam/api/models/SupportingDocuments/SupportingDocumentIndex';

import { CLIENT_DOCUMENTS } from './constants';
import type { AppStateSubset, State } from './slice';
import type { DocumentFilters } from './types';

type DocumentsSelector<T> = Selector<AppStateSubset, T>;

const selectDocumentsDomain = (state: AppStateSubset): State =>
  state[CLIENT_DOCUMENTS];

export const makeSelectIsLoading = (): DocumentsSelector<boolean> =>
  createSelector(selectDocumentsDomain, substate => substate.isLoading);

export const makeSelectFilters = (): DocumentsSelector<DocumentFilters> =>
  createSelector(selectDocumentsDomain, substate => substate.filters);

export const makeSelectSupportingDocuments = (): DocumentsSelector<
  SupportingDocumentIndexJSON[]
> => createSelector(selectDocumentsDomain, substate => substate.documents);

export const makeSelectError = (): DocumentsSelector<SerializedApiError | null> =>
  createSelector(selectDocumentsDomain, substate => substate.error);
