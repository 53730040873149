import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { assert } from 'lib/support';

import { SUPPLIER_WAREHOUSE_ZONES } from './constants';
import {
  fetchCoveragesPerZones,
  fetchNearZones,
  saveZones,
  synchronizeMapCoverage,
} from './thunk';
import { CoveragesPerZone } from './types';

export interface State {
  zonesCoverages: CoveragesPerZone;
  isLoading: boolean;
  isSaving: boolean;
  hasMapSynchronized: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [SUPPLIER_WAREHOUSE_ZONES]: State;
}

export const initialState: State = {
  zonesCoverages: {},
  isLoading: false,
  isSaving: false,
  hasMapSynchronized: false,
  error: null,
};

const slice = createSlice({
  name: SUPPLIER_WAREHOUSE_ZONES,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    resetHasMapSynchronized: state => {
      state.hasMapSynchronized = initialState.hasMapSynchronized;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCoveragesPerZones.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.zonesCoverages = {};
    });
    builder.addCase(fetchCoveragesPerZones.fulfilled, (state, { payload }) => {
      state.zonesCoverages = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchCoveragesPerZones.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
      state.isLoading = false;
    });

    builder.addCase(fetchNearZones.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveZones.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveZones.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      if (!payload) {
        return;
      }
      state.zonesCoverages = payload.zones.reduce(
        (prev, { zone, coverage }) => {
          assert(zone.id !== undefined);
          return {
            ...prev,
            [zone.id]: coverage,
          };
        },
        {},
      );
    });
    builder.addCase(saveZones.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
      state.isSaving = false;
    });

    builder.addCase(synchronizeMapCoverage.pending, state => {
      state.isSaving = true;
      state.hasMapSynchronized = true;
    });
    builder.addCase(synchronizeMapCoverage.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(synchronizeMapCoverage.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { resetHasMapSynchronized } = slice.actions;
export default slice;
