// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.Dropdown-module__dropdown--TWuZU>div{padding-bottom:16px}@media(max-width: 1199px){div.Dropdown-module__dropdown--TWuZU{position:relative;left:50%;margin-top:0}}@media(min-width: 1200px){div.Dropdown-module__dropdown--TWuZU{min-width:215px}div.Dropdown-module__dropdown--TWuZU>div{padding-top:35px}}`, "",{"version":3,"sources":["webpack://./../ui/components/Navigation/Menu/parts/Dropdown/Dropdown.module.scss","webpack://./../ui/scss/_media.scss"],"names":[],"mappings":"AAGE,yCACE,mBAAA,CCyBA,0BD3BJ,qCAMI,iBAAA,CACA,QAAA,CACA,YAAA,CAAA,CCIA,0BDZJ,qCAYI,eAAA,CAEA,yCACE,gBAAA,CAAA","sourcesContent":["@import '../../../../../scss/media';\n\ndiv.dropdown {\n  > div {\n    padding-bottom: 16px;\n  }\n\n  @include below(xl) {\n    position: relative;\n    left: 50%;\n    margin-top: 0;\n  }\n\n  @include above(xl) {\n    min-width: 215px;\n\n    > div {\n      padding-top: 35px;\n    }\n  }\n}\n","$breakpoints: (\n  xxl: 1440px,\n  xl: 1200px,\n  lg: 992px,\n  md: 768px,\n  sm: 576px,\n  xs: 375px\n);\n\n@mixin above($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n@mixin below($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (max-width: ($breakpoint-value - 1)) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n:export {\n  /* stylelint-disable property-no-unknown */\n  xxl: 1440px;\n  xl: 1200px;\n  lg: 992px;\n  md: 768px;\n  sm: 576px;\n  xs: 375px;\n  /* stylelint-enable property-no-unknown */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"dropdown": `Dropdown-module__dropdown--TWuZU`
};
export default ___CSS_LOADER_EXPORT___;
