// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageEditor-module__buttons--ihYWe{position:absolute;inset:auto 8px 8px 8px;display:flex;flex-direction:row;pointer-events:none}.ImageEditor-module__buttons--ihYWe button{padding:6px;pointer-events:initial}.ImageEditor-module__buttons--ihYWe button>svg{width:24px;padding:0}.ImageEditor-module__drag_overlay--DHfta{position:absolute;inset:-3px -3px -3px -3px}.ImageEditor-module__rotate_overlay--nXZHr{cursor:grab}.ImageEditor-module__button_spacer--tuShc{flex-grow:1}button.ImageEditor-module__current_mode--CNv3R{box-shadow:inset 0 0 3px 1px rgba(0,0,0,.6)}`, "",{"version":3,"sources":["webpack://./src/containers/DocumentTemplateEditor/Engine/ImageEditor/ImageEditor.module.scss"],"names":[],"mappings":"AAAA,oCACE,iBAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,mBAAA,CAEA,2CACE,WAAA,CACA,sBAAA,CAEA,+CACE,UAAA,CACA,SAAA,CAKN,yCACE,iBAAA,CAEA,yBAAA,CAGF,2CACE,WAAA,CAGF,0CACE,WAAA,CAGF,+CACE,2CAAA","sourcesContent":[".buttons {\n  position: absolute;\n  inset: auto 8px 8px 8px;\n  display: flex;\n  flex-direction: row;\n  pointer-events: none;\n\n  button {\n    padding: 6px;\n    pointer-events: initial;\n\n    > svg {\n      width: 24px;\n      padding: 0;\n    }\n  }\n}\n\n.drag_overlay {\n  position: absolute;\n  // This is almost un-noticeable and prevents cropperjs' crop box to be catched\n  inset: -3px -3px -3px -3px;\n}\n\n.rotate_overlay {\n  cursor: grab;\n}\n\n.button_spacer {\n  flex-grow: 1;\n}\n\nbutton.current_mode {\n  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `ImageEditor-module__buttons--ihYWe`,
	"drag_overlay": `ImageEditor-module__drag_overlay--DHfta`,
	"rotate_overlay": `ImageEditor-module__rotate_overlay--nXZHr`,
	"button_spacer": `ImageEditor-module__button_spacer--tuShc`,
	"current_mode": `ImageEditor-module__current_mode--CNv3R`
};
export default ___CSS_LOADER_EXPORT___;
