/*
 * ErrorModal Messages
 *
 * This contains all the text for the ErrorModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  brand: { id: 'app.components.BrandSelection.brand' },
});
