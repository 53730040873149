import { ReactNode } from 'react';
import Modal from 'react-modal';
import Close from 'images/close.svg';

import style from './style.scss';

interface ModalProps {
  children: ReactNode | ReactNode[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onRequestClose?: () => void;
  title: string;
}

function DefaultModal({
  isOpen,
  children,
  setIsOpen,
  onRequestClose,
  title,
}: ModalProps): JSX.Element {
  const closeModal = (): void => {
    setIsOpen(false);
    if (onRequestClose) {
      onRequestClose();
    }
  };

  return (
    <Modal
      className={style.modal__position}
      style={{
        overlay: {
          zIndex: 10,
        },
      }}
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className={style.modal__body}>
        <button
          type="button"
          onClick={closeModal}
          className={style['modal__action--close']}
        >
          <Close />
        </button>
        <div className={style['modal__body--inside']}>
          <div className={style.modal__title}>
            <span className="text__big"> {title} </span>
          </div>
          <div className={style.modal__content}>{children}</div>
        </div>
      </div>
    </Modal>
  );
}

export default DefaultModal;
export { MessageModal } from './Message';
export { FullscreenModal } from './Fullscreen';
export { ModalButtons } from './Buttons';
