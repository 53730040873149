import { ErrorCode } from '../errors'
import type { ValidatorReturnType } from './Validator'

function numerify(value: string): string {
  return (value.slice(4) + value.slice(0, 4)).replace(/[A-Z]/g, char =>
    (char.charCodeAt(0) - 55).toString(),
  )
}

export function areIbanCheckDigitsValid(value: string | null): ValidatorReturnType {
  /* It is not the role of this validator to check the format. If it is invalid, we just skip it. */
  if (!value || /[^A-Z0-9]/.test(value)) {
    return undefined
  }

  if (BigInt(numerify(value)) % 97n !== 1n) {
    return ErrorCode.INVALID_IBAN_CHECK_DIGITS
  }

  return undefined
}
