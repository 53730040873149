import PropTypes from 'prop-types';

import { WANT_CEREMONY_MASTER, ENGRAVING } from 'utils/constants';
import Services from 'containers/Deal/DealFuneral/StepsSection/Services';
import CheckboxWishes from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxWishes';
import CheckboxService from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxService';
import IntermentLocation from 'components/IntermentLocation';
import messages from 'messages/wishes';
import servicesMessages from 'messages/services';

const Interment = ({
  handleChange,
  step,
  stepServices,
  addService,
  removeService,
  wishes,
  findWish,
  updateWish,
  isManualMode,
  userRole,
  updateService,
  intl,
  getGraveyardConcessionTypes,
  getGraveyardConcessions,
  updateDelegate,
  getGraveyardBasicInfos,
  graveyardBasicInfos,
}) => (
  <div className="step">
    <IntermentLocation
      step={step}
      handleChange={handleChange}
      getGraveyardConcessionTypes={getGraveyardConcessionTypes}
      getGraveyardConcessions={getGraveyardConcessions}
      intl={intl}
      isDelegate
      isDates
      isWishes
      updateDelegate={updateDelegate}
      getGraveyardBasicInfos={getGraveyardBasicInfos}
      graveyardBasicInfos={graveyardBasicInfos}
    />
    <CheckboxService
      serviceType={ENGRAVING}
      addService={addService}
      message={servicesMessages.engraving}
      removeService={removeService}
      services={stepServices}
      stepId={step.id}
      intl={intl}
    />
    <CheckboxWishes
      wishType={WANT_CEREMONY_MASTER}
      message={messages.ceremonyMaster}
      wishes={wishes}
      findWish={findWish}
      updateWish={updateWish}
    />
    <Services
      stepId={step.id}
      services={stepServices}
      addService={addService}
      removeService={removeService}
      isManualMode={isManualMode}
      userRole={userRole}
      updateService={updateService}
    />
  </div>
);

Interment.propTypes = {
  /** step infos */
  step: PropTypes.object.isRequired,
  /** services of current step */
  stepServices: PropTypes.array.isRequired,
  /** function to add a service */
  addService: PropTypes.func.isRequired,
  /** function to remove a service */
  removeService: PropTypes.func.isRequired,
  /** function to update interment */
  handleChange: PropTypes.func.isRequired,
  /** list of wishes */
  wishes: PropTypes.array.isRequired,
  /** function to find a wish */
  findWish: PropTypes.func.isRequired,
  /** function to update a wish */
  updateWish: PropTypes.func.isRequired,
  /** function to get graveyard concession types */
  getGraveyardConcessionTypes: PropTypes.func.isRequired,
  /** function to get graveyard conessions */
  getGraveyardConcessions: PropTypes.func.isRequired,
  /** is manual mode on or off */
  isManualMode: PropTypes.bool.isRequired,
  /** user permission */
  userRole: PropTypes.number.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  /** function to update is delegate */
  updateDelegate: PropTypes.func.isRequired,
  /** function to get graveyard summary data */
  getGraveyardBasicInfos: PropTypes.func.isRequired,
  /** Basic infos of the selected graveyard */
  graveyardBasicInfos: PropTypes.object,
};

export default Interment;
