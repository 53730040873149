import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Api from '@advitam/api';
import { Button, ResourceList } from '@advitam/ui';
import type { Filter } from '@advitam/ui/components/ResourceList/Filters/types';
import { getTokens } from '@advitam/api/lib/tokens';

import { makeSelectFilters } from '../selectors';
import messages from '../messages';
import { fetchFlights } from '../thunk';
import { FlightsFilters } from '../types';
import { setFilters } from '../slice';
import { setIsOpen } from '../CreateModal/slice';
import style from '../Flights.module.scss';

export default function FlightsFilters(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const filtersValues = useSelector(makeSelectFilters());

  const authTokens = { ...getTokens() };
  const filtersShape: Filter[] = [
    {
      type: 'Autocomplete',
      name: 'country',
      endpoint: Api.V1.absolute(Api.V1.Autocompletes.Path.countries),
      initialValue: filtersValues.country,
      placeholder: intl.formatMessage(messages.arrivalFilter),
    },
    {
      type: 'Autocomplete',
      name: 'departure_airport',
      endpoint: Api.V1.absolute(Api.V1.Autocompletes.Path.airports.index),
      initialValue: filtersValues.departure_airport,
      placeholder: intl.formatMessage(messages.departureFilter),
      requestHeaders: authTokens,
    },
    {
      type: 'Autocomplete',
      name: 'airline',
      endpoint: Api.V1.absolute(Api.V1.Autocompletes.Path.airlines),
      initialValue: filtersValues.airline,
      placeholder: intl.formatMessage(messages.company),
      requestHeaders: authTokens,
    },
  ];

  const onChange = useCallback(
    (value: Partial<FlightsFilters>): void => {
      dispatch(setFilters(value));
      dispatch(fetchFlights());
    },
    [dispatch],
  );

  return (
    <ResourceList.Filters
      className={style.filters}
      filters={filtersShape}
      onChange={onChange}
      searchFilter={{
        name: 'q',
        placeholder: intl.formatMessage(messages.search),
        initialValue: filtersValues.q,
      }}
    >
      <Button
        primary
        text={<FormattedMessage id={messages.newFlight.id} />}
        onClick={(): void => {
          dispatch(setIsOpen(true));
        }}
      />
    </ResourceList.Filters>
  );
}
