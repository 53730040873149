import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { AgeRangeJSON } from '@advitam/api/models/Crematorium/AgeRange';

import { CREMATORIUM_AGE_RANGES } from './constants';
import { fetchAgeRanges, saveAgeRanges } from './thunk';

export interface AppStateSubset {
  [CREMATORIUM_AGE_RANGES]: State;
}

export interface State {
  ageRanges: AgeRangeJSON[];
  isLoading: boolean;
  isSaving: boolean;
  hasAlreadyInUseError: boolean;
  error: SerializedApiError | null;
}

export const initialState: State = {
  ageRanges: [],
  isLoading: false,
  isSaving: false,
  hasAlreadyInUseError: false,
  error: null,
};

const slice = createSlice({
  name: CREMATORIUM_AGE_RANGES,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    clearHasAlreadyInUseError: state => {
      state.hasAlreadyInUseError = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAgeRanges.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchAgeRanges.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ageRanges = payload;
    });
    builder.addCase(fetchAgeRanges.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveAgeRanges.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveAgeRanges.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.ageRanges = payload.result;
      state.hasAlreadyInUseError = payload.hasAlreadyInUseError;
    });
    builder.addCase(saveAgeRanges.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { clearHasAlreadyInUseError } = slice.actions;
export default slice;
