/*
 *  DatePicker component to select dates
 */

import PropTypes from 'prop-types';

import { Component } from 'react';
import MaskedInput from 'react-maskedinput';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import {
  formattedDateFromTimeStamp,
  destructFormattedDate,
  destructFormattedTime,
} from './utils';
import style from './DatePicker.module.scss';

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = { ...formattedDateFromTimeStamp(props.date) };
  }

  static getDerivedStateFromProps(props, state) {
    if (formattedDateFromTimeStamp(props.date) !== state) {
      return formattedDateFromTimeStamp(props.date);
    }
    return null;
  }

  udpateDateTime(formattedDate, formattedTime) {
    const { onChange } = this.props;
    const date = destructFormattedDate(formattedDate);
    const time = destructFormattedTime(formattedTime);
    let momentObject = moment.utc([
      date.year,
      date.month,
      date.day,
      time.hour,
      time.minute,
    ]);
    // If the date doesn't have the time field (birthday field) make momentObject valid
    if (
      time.hour === null &&
      time.minute === null &&
      date.year &&
      (date.month || date.month === 0) &&
      date.day
    ) {
      momentObject = moment.utc([date.year, date.month, date.day]);
    }
    this.setState({
      errorDateTime: false,
      date: formattedDate,
      time: formattedTime,
    });
    // If it's not a valid date and at least one field is not null
    if (
      !momentObject.isValid() &&
      !(
        date.year === null &&
        date.month === null &&
        date.day === null &&
        time.hour === null &&
        time.minute === null
      )
    ) {
      this.setState({ errorDateTime: true });
      return;
    }
    onChange(momentObject.unix());
  }

  renderInputOrDate = () => {
    const { readOnly, displayHours } = this.props;
    const { date, errorDateTime, time } = this.state;

    if (readOnly) return date;
    return (
      <span
        className={`${style.wrapper} ${errorDateTime ? style.errored : ''}`}
      >
        <MaskedInput
          onBlur={({ target: { value } }) => this.udpateDateTime(value, time)}
          mask="11/11/1111"
          type="tel"
          value={date}
          size="12"
          placeholder="jj/mm/aaaa"
        />
        {displayHours && (
          <MaskedInput
            onBlur={({ target: { value } }) => this.udpateDateTime(date, value)}
            mask="11:11"
            type="tel"
            placeholder="hh:mm"
            size="5"
            value={time}
          />
        )}
      </span>
    );
  };

  render() {
    const { fieldName, noLabel } = this.props;
    const inputName = fieldName || 'date';
    return (
      <div className={`datePicker ${style.time_box}`}>
        {!noLabel && <FormattedMessage {...messages[inputName]} />}
        {this.renderInputOrDate()}
      </div>
    );
  }
}

CustomDatePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  displayHours: PropTypes.bool,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  noLabel: PropTypes.bool,
};

export default CustomDatePicker;
