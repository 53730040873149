import { useForm, useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import type { PrestationType } from '@advitam/api/models/Supplier';
import CrossIcon from '@advitam/ui/images/icons/times.svg';
import { ActionTag, Select } from '@advitam/ui';
import { Layout } from 'containers/Crud';
import { safeFormatMessage } from 'utils/functions.typed';

import type { SupplierForm } from '../types';
import messages from './messages';
import { ALL_PRESTATION_TYPES } from './constants';
import style from './Miscellaneous.module.scss';

const FIELD_NAME = 'supplier.prestation_types';

export default function Misc(): JSX.Element {
  const intl = useIntl();

  const form = useForm<SupplierForm>();
  const { values } = useFormState<SupplierForm>();

  const remove = (prestation: PrestationType): void =>
    form.change(
      FIELD_NAME,
      values.supplier.prestation_types.filter(type => type !== prestation),
    );

  return (
    <Layout.Fieldset
      title={<FormattedMessage id={messages.prestationTypes.id} />}
    >
      <Select
        label={<FormattedMessage id={messages.prestationTypesLabel.id} />}
        tooltip={<FormattedMessage id={messages.prestationTypesTooltip.id} />}
        placeholder={
          <FormattedMessage id={messages.prestationTypesPlaceholder.id} />
        }
        name="supplier.prestation_types"
        items={ALL_PRESTATION_TYPES.map(value => ({
          value,
          name: safeFormatMessage(intl, messages, `${value}PrestationType`),
        }))}
        multiple
      />
      <div>
        {values.supplier.prestation_types.map(prestation => (
          <ActionTag
            key={prestation}
            icon={<CrossIcon />}
            onClick={(): void => remove(prestation)}
            className={style.prestation_type}
          >
            {safeFormatMessage(intl, messages, `${prestation}PrestationType`)}
          </ActionTag>
        ))}
      </div>
    </Layout.Fieldset>
  );
}
