import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type { FlightIndexJSON } from '@advitam/api/models/Flight/FlightIndex';
import type { FlightsIndexFilters } from '@advitam/api/v1/Suppliers/Warehouses/Flights';
import type { FlightJSON } from '@advitam/api/models/Flight';

import { Path } from 'containers/App/constants';

import { FLIGHTS_CREATE_MODAL } from './constants';

async function fetchFlights(
  filters: FlightsIndexFilters,
): Promise<FlightIndexJSON[]> {
  const { body } = await request(
    Api.V1.Suppliers.Warehouses.Flights.index(filters),
  );
  return body;
}

export const createFlight = createAsyncThunk(
  `${FLIGHTS_CREATE_MODAL}_CREATE_FLIGHT`,
  async (form: FlightJSON, { rejectWithValue, dispatch }) => {
    try {
      const flights = await fetchFlights({
        airline_id: form.airline?.id,
        arrival_airport_id: form.arrival_airport?.id,
        departure_airport_id: form.departure_airport?.id,
        supplier_warehouse_id: form.supplier_warehouse_id,
      });

      if (flights.length > 0) {
        dispatch(push(`${Path.FLIGHTS}/${flights[0].id}`));
      } else {
        dispatch(push(`${Path.FLIGHTS}/${Path.NEW}`, form));
      }

      return null;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
