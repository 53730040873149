import { FormattedMessage } from 'react-intl';

import { FormLayout, Input } from '@advitam/ui';

import messages from '../messages';

export default function BankTransferLabel(): JSX.Element {
  return (
    <FormLayout.Row>
      <Input
        label={<FormattedMessage id={messages.bankTransferLabel.id} />}
        name="invoice.bank_transfer_label"
      />
    </FormLayout.Row>
  );
}
