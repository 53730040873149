import { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonV2 as Button } from 'components/Button';
import messagesActions from 'messages/actions';
import { Product, ProductVariant } from 'models/Deal/Product';
import { DealPrestationComputeType } from 'models/Deal/Prestation/ComputeType';

import ProductInfo from './ProductInfo';
import ProductImage from './Image';

interface ProductVariantsProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  product: Product;
  getProduct?: (productId: number) => void;
  updateProduct: (product: Product) => void;
}

export default function ProductVariants({
  isModalOpen,
  toggleModal,
  product,
  getProduct,
  updateProduct,
}: ProductVariantsProps): JSX.Element | null {
  useEffect(() => {
    if (!isModalOpen || !getProduct) {
      return;
    }

    const { variants, productObject } = product;
    if (!variants || variants.length === 1) {
      getProduct(productObject.id);
    }
  }, [isModalOpen]);

  const setProductVariant = (variant: ProductVariant): void => {
    const updatedProduct: Product = {
      ...product,
      compute: DealPrestationComputeType.MANUAL,
      default: false,
      productObject: {
        ...product.productObject,
        ...variant,
        id: product.productObject.id,
      },
    };
    updateProduct(updatedProduct);
    toggleModal();
  };

  if (!isModalOpen || !product.variants || product.variants.length === 0)
    return null;

  // TODO: It looks like the current product itself is appended to the variants
  const filteredVariants = product.variants.filter(
    item => item.product_id !== product.productObject.product_id,
  );

  return (
    <>
      {filteredVariants.map(variant => (
        <Fragment key={variant.id || 'default'}>
          <div className="flex product__variant">
            <div className="product__img">
              <ProductImage product={product.productObject} variant={variant} />
            </div>
            <div className="product__container product__container--variant">
              <ProductInfo
                product={{
                  ...product.productObject,
                  ...variant,
                }}
              />
              <div className="product__container__buttons">
                <Button onClick={(): void => setProductVariant(variant)}>
                  <FormattedMessage id={messagesActions.select.id} />
                </Button>
              </div>
            </div>
          </div>
          <hr />
        </Fragment>
      ))}
    </>
  );
}
