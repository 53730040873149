import type { DateTimeFormatOptions } from 'luxon';

export const DEAL_REMINDER = 'Deal/Reminder';

export const PREDEFINED_DATE_FORMAT: DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  hour: '2-digit',
  minute: '2-digit',
};

export const NO_REMINDER_ERROR = 'deal__reminder_job_id__blank';
