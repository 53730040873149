const SHARER_URL = 'https://www.facebook.com/sharer/sharer.php'

function buildShareLink(url: string): string {
  const params = [`u=${url}`]
  return `${SHARER_URL}?${params.join('&')}`
}

export const Facebook = {
  buildShareLink,
}
