const HEX_MASK = 0x00ffffff;
const HEX_COLOR_CHARS_COUNT = 6;

function maskIntToHex(int: number): string {
  // eslint-disable-next-line no-bitwise
  return (int & HEX_MASK).toString(16).toUpperCase();
}

export function intToHexColor(int: number): string {
  const hexMaskedInt = maskIntToHex(int);
  const hexColor = hexMaskedInt.padStart(HEX_COLOR_CHARS_COUNT, '0');
  return `#${hexColor}`;
}
