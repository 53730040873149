import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  airport: { id: 'app.containers.Entities.parts.LocationIcon.airport' },
  cityhall: { id: 'app.containers.Entities.parts.LocationIcon.cityhall' },
  consulate: { id: 'app.containers.Entities.parts.LocationIcon.consulate' },
  crematorium: { id: 'app.containers.Entities.parts.LocationIcon.crematorium' },
  funeralParlor: {
    id: 'app.containers.Entities.parts.LocationIcon.funeralParlor',
  },
  graveyard: { id: 'app.containers.Entities.parts.LocationIcon.graveyard' },
  hospital: { id: 'app.containers.Entities.parts.LocationIcon.hospital' },
  police: { id: 'app.containers.Entities.parts.LocationIcon.police' },
  prefecture: { id: 'app.containers.Entities.parts.LocationIcon.prefecture' },
  regionalHealthAuthority: {
    id: 'app.containers.Entities.parts.LocationIcon.regionalHealthAuthority',
  },
  worship: { id: 'app.containers.Entities.parts.LocationIcon.worship' },
});
