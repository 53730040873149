import { FormattedMessage } from 'react-intl';
import { ResourceList } from '@advitam/ui';
import messages from '../messages';
import style from '../Flights.module.scss';

export default function FlightsHeader(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell>
        <FormattedMessage id={messages.company.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.arrival.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.departure.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile>
        <FormattedMessage id={messages.stopover.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile>
        <FormattedMessage id={messages.weight.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.price.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.lastUpdate.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
