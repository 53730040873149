import { authenticate, formdataEncoded } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get, post, put, del } from 'api/methods';

import {
  SupportingDocument,
  SupportingDocumentResponse,
} from 'models/SupportingDocument';
import { assert, presence } from 'lib/Assert';

export class SupportingDocuments {
  @authenticate
  static index(defunctId: number): ApiRequestDescriptor<SupportingDocument[]> {
    return get(`/api/v1/defuncts/${defunctId}/supporting_documents`);
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<SupportingDocument> {
    return get(`/api/v1/defuncts/supporting_documents/${id}`);
  }

  @authenticate
  @formdataEncoded
  static create(
    defunctId: number,
    documentTypeId: number | string,
    file: Blob,
  ): ApiRequestDescriptor<SupportingDocumentResponse> {
    return post(`/api/v1/defuncts/${defunctId}/supporting_documents`, {
      supporting_document: {
        document_type: documentTypeId,
      },
      file,
    });
  }

  @authenticate
  @formdataEncoded
  static update(
    id: number,
    file: Blob,
  ): ApiRequestDescriptor<SupportingDocumentResponse> {
    return put(`/api/v1/defuncts/supporting_documents/${id}`, {
      file,
    });
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<undefined> {
    return del(`/api/v1/defuncts/supporting_documents/${id}`);
  }

  static updateOrCreate(
    documentTypeId: number | string,
    file: Blob,
    id: number | null,
    defunctId?: number,
  ): ApiRequestDescriptor<SupportingDocumentResponse> {
    if (id) {
      return SupportingDocuments.update(id, file);
    }

    assert(presence(defunctId));
    return SupportingDocuments.create(defunctId, documentTypeId, file);
  }
}
