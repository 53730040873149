import type { ReactNode } from 'react'

import { Company } from '../../constants'
import ButtonText from '../ButtonText'
import Text from '../Text'
import style from './PhoneLink.module.scss'

interface PhoneLinkProps {
  text?: ReactNode
  isInternationalFormat?: boolean
  className?: string
}

export default function PhoneLink({
  className = '',
  isInternationalFormat = false,
  text,
}: PhoneLinkProps): JSX.Element {
  const formattedPhone = isInternationalFormat
    ? Company.FORMATTED_FOREIGN_PHONE_NUMBER
    : Company.FORMATTED_PHONE_NUMBER

  return (
    <a href={`tel:${Company.PHONE_NUMBER}`} className={[className, style.link].join(' ')}>
      {text && (
        <Text className={style.text}>
          <b>{text}</b>
        </Text>
      )}
      <ButtonText className={style.phone}>{formattedPhone}</ButtonText>
    </a>
  )
}
