import { assert, Objects } from '@advitam/support'

import type { FullClientJSON } from '../../models/Client/Full'
import type { DefunctJSON } from '../../models/Defunct'
import type { Civility } from '../../models/Person/Civility'

interface DefunctPayload {
  firstname: string
  lastname: string
  civility: Civility
  birth_name: string | null
  birth_date: string | null
  death_date: string | null
}

interface DefunctRequestDetails {
  defunctId: number
  payload: DefunctPayload
}

function isDefunct(body: DefunctJSON | FullClientJSON): body is DefunctJSON {
  return (body as FullClientJSON).defunct_id === undefined
}

export function serializeForUpdate(body: DefunctJSON | FullClientJSON): DefunctRequestDetails {
  let defunctId: number
  if (isDefunct(body)) {
    defunctId = body.id
  } else {
    assert(body.defunct_id !== null)
    assert(body.firstname !== null && body.lastname !== null, 'client is incomplete')
    defunctId = body.defunct_id
  }

  const payload = Objects.pick(
    body,
    'firstname',
    'lastname',
    'civility',
    'birth_name',
    'birth_date',
    'death_date',
  )

  return { defunctId, payload: payload as DefunctPayload }
}
