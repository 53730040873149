import { defineMessages } from 'react-intl';

export default defineMessages({
  link: {
    id: 'app.containers.DealItems.OtherSection.link',
    defaultMessage: 'Linked deal / memorial',
  },
  noLink: {
    id: 'app.containers.DealItems.OtherSection.noLink',
    defaultMessage: 'This deal is not linked to any other or to a memorial',
  },
  linkedToDealText: {
    id: 'app.containers.DealItems.OtherSection.linkedToDealText',
    defaultMessage: 'Linked to ',
  },
  linkedToDealLink: {
    id: 'app.containers.DealItems.OtherSection.linkedToDealLink',
    defaultMessage: 'deal {defunct}',
  },
  linkedToMemorialText: {
    id: 'app.containers.DealItems.OtherSection.linkedToMemorialText',
    defaultMessage: 'Linked to ',
  },
  linkedToMemorialLink: {
    id: 'app.containers.DealItems.OtherSection.linkedToMemorialLink',
    defaultMessage: 'memorial page of {defunct}',
  },
});
