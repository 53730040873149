import type { CommunicationChannelType } from '@advitam/api/models/Deal/Communication/ChannelType';

export enum RecipientType {
  FAMILY,
  SUPPLIER,
  ADMINISTRATION,
  THIRD_PARTY,
}

export interface CommunicationFilters {
  q?: string;
  recipientType?: RecipientType;
  channelType?: CommunicationChannelType;
  user?: number;
  from?: Date;
  to?: Date;
}
