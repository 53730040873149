import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  managedPlaces: {
    id: 'app.containers.Prefecture.sections.managedPlaces',
  },
  managedPlacesTooltip: {
    id: 'app.containers.Prefecture.sections.managedPlacesTooltip',
  },
  functioning: { id: 'app.containers.Prefecture.sections.functioning' },
  rha: { id: 'app.containers.Prefecture.sections.rha' },
  rhaTooltip: { id: 'app.containers.Prefecture.sections.rhaTooltip' },
  deathPrefecture: { id: 'app.containers.Prefecture.sections.deathPrefecture' },
  cremationPrefecture: {
    id: 'app.containers.Prefecture.sections.cremationPrefecture',
  },
  inhumationPrefecture: {
    id: 'app.containers.Prefecture.sections.inhumationPrefecture',
  },
  closingPrefecture: {
    id: 'app.containers.Prefecture.sections.closingPrefecture',
  },
});
