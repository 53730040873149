import { ChangeEventHandler, InputHTMLAttributes, ReactNode } from 'react'

import CheckIcon from '../../../../../images/icons/check.svg'
import Image from '../../../../Image'
import Text from '../../../../Text'
import radioStyle from '../Radio.module.scss'
import style from './CardWithImage.module.scss'

interface CardWithImageProps {
  imageSrc: string
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  title: ReactNode
  ctaText?: ReactNode
  ctaAction?: () => void
  onChange: ChangeEventHandler
  checked?: boolean
  error?: boolean
  className?: string
}

export default function CardWithImage({
  imageSrc,
  name,
  value,
  title,
  ctaText,
  ctaAction,
  onChange,
  checked = false,
  error = false,
  className = '',
}: CardWithImageProps): JSX.Element {
  const classes = [radioStyle.tile, style.product]
  const checkClasses = [radioStyle.check, style.check]

  if (checked) {
    classes.push(radioStyle.checked, style.checked)
    checkClasses.push(radioStyle.check__active)
  }
  if (error) {
    classes.push(radioStyle.error)
  }
  if (className) {
    classes.push(className)
  }

  return (
    <label className={radioStyle.container}>
      <div className={classes.join(' ')}>
        <div className={style.image}>
          <Image src={imageSrc} />
        </div>
        <div className={style.details}>
          <Text small tagName="div" className={style.title}>
            <b>{title}</b>
          </Text>
          {ctaText && ctaAction && (
            <button type="button" onClick={ctaAction} className={style.cta}>
              <Text small>{ctaText}</Text>
            </button>
          )}
        </div>
      </div>
      <CheckIcon className={checkClasses.join(' ')} />
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={radioStyle.radio}
      />
    </label>
  )
}
