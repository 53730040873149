import { defineMessages } from 'react-intl';

export default defineMessages({
  type: { id: 'app.containers.Entities.type' },
  name: { id: 'app.containers.Entities.name' },
  city: { id: 'app.containers.Entities.city' },
  postalCode: { id: 'app.containers.Entities.postalCode' },
  phone: { id: 'app.containers.Entities.phone' },
  email: { id: 'app.containers.Entities.email' },
  lastUpdate: { id: 'app.containers.Entities.lastUpdate' },
  department: { id: 'app.containers.Entities.department' },
  search: { id: 'app.containers.Entities.search' },
  createPlace: { id: 'app.containers.Entities.createPlace' },
});
