import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type { RegionalHealthAuthorityJSON } from '@advitam/api/models/RegionalHealthAuthority';

import { Path } from 'containers/App/constants';
import { assert, nextTick } from 'lib/support';

import { REGIONAL_HEALTH_AUTHORITY } from './constants';
import { AppStateSubset, setRegionalHealthAuthority } from './slice';
import { makeSelectRawRegionalHealthAuthority } from './selectors';
import type { NewRegionalHealthAuthority } from './types';

export const fetchRegionalHealthAuthority = createAsyncThunk(
  `${REGIONAL_HEALTH_AUTHORITY}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Rha.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createRegionalHealthAuthority = createAsyncThunk(
  `${REGIONAL_HEALTH_AUTHORITY}_CREATE`,
  async (
    regionalHealthAuthority: NewRegionalHealthAuthority,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { body } = await request(
        Api.V1.Rha.create(regionalHealthAuthority),
      );

      dispatch(setRegionalHealthAuthority(body));
      nextTick(() => dispatch(replace(`./${body.id}`)));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateRegionalHealthAuthority = createAsyncThunk(
  `${REGIONAL_HEALTH_AUTHORITY}_UPDATE`,
  async (
    regionalHealthAuthority: RegionalHealthAuthorityJSON,
    { rejectWithValue },
  ) => {
    try {
      const { body } = await request(
        Api.V1.Rha.update(regionalHealthAuthority),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateRegionalHealthAuthorityName = createAsyncThunk(
  `${REGIONAL_HEALTH_AUTHORITY}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const regionalHealthAuthority = makeSelectRawRegionalHealthAuthority()(
      state,
    );
    assert(regionalHealthAuthority?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Rha.update({ id: regionalHealthAuthority.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const saveRegionalHealthAuthority = createAsyncThunk(
  `${REGIONAL_HEALTH_AUTHORITY}_SAVE`,
  async (
    regionalHealthAuthority:
      | RegionalHealthAuthorityJSON
      | NewRegionalHealthAuthority,
    { dispatch },
  ) => {
    if (regionalHealthAuthority.id === undefined) {
      await dispatch(createRegionalHealthAuthority(regionalHealthAuthority));
    } else {
      await dispatch(updateRegionalHealthAuthority(regionalHealthAuthority));
    }
  },
);

export const destroyRegionalHealthAuthority = createAsyncThunk(
  `${REGIONAL_HEALTH_AUTHORITY}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const regionalHealthAuthority = makeSelectRawRegionalHealthAuthority()(
      state,
    );
    assert(regionalHealthAuthority?.id !== undefined);

    try {
      await request(Api.V1.Rha.destroy(regionalHealthAuthority.id));
      dispatch(push(Path.ENTITIES));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsRegionalHealthAuthorityDisabled = createAsyncThunk(
  `${REGIONAL_HEALTH_AUTHORITY}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const regionalHealthAuthority = makeSelectRawRegionalHealthAuthority()(
      state,
    );
    assert(regionalHealthAuthority?.id !== undefined);

    try {
      await request(
        Api.V1.Rha.Disabled.update(regionalHealthAuthority.id, disabled),
      );
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
