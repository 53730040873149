import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';

import { DEATH_DECLARATION_VALIDATION } from './constants';
import type { AppStateSubset, State } from './slice';

type DeathDeclarationSelector<T> = Selector<AppStateSubset, T>;

const selectDeathDeclarationValidationDomain = (state: AppStateSubset): State =>
  state[DEATH_DECLARATION_VALIDATION];

export const makeSelectIsLoading = (): DeathDeclarationSelector<boolean> =>
  createSelector(
    selectDeathDeclarationValidationDomain,
    ({ isLoading }) => isLoading,
  );

export const makeSelectError = (): DeathDeclarationSelector<SerializedApiError | null> =>
  createSelector(selectDeathDeclarationValidationDomain, ({ error }) => error);
