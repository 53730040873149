import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';
import { LinkButton, Tooltip } from '@advitam/ui';
import SearchIcon from '@advitam/ui/images/icons/man-glass.svg';
import { Path } from 'containers/App/constants';
import type { DealDefunctJSON } from 'models/Deal/Defunct';

import { makeSelectDefunct } from '../../../../DealFuneral/DefunctSection/selectors.js';
import style from '../parts/Action.module.scss';
import Task from '../parts/Task';
import messages from '../messages';

interface FindDefunctInClientsProps {
  item: TodoItem;
}

export default function FindDefunctInClients({
  item,
}: FindDefunctInClientsProps): JSX.Element {
  const { person } = useSelector(makeSelectDefunct()) as DealDefunctJSON;
  const q = [person.firstName, person.lastName].filter(Boolean).join(' ');

  if (!q) {
    return <Task item={item} />;
  }

  const tooltipMessage = messages.searchForDefunct;
  return (
    <Task
      item={item}
      action={
        <Tooltip content={<FormattedMessage id={tooltipMessage.id} />}>
          <LinkButton
            className={style.action}
            outline
            newTab
            href={`${Path.CLIENTS}?q=${q}`}
            icon={<SearchIcon />}
          />
        </Tooltip>
      }
    />
  );
}
