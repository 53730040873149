import { authenticate, formdataEncoded } from '../../lib/decorators'
import { ApiRequestDescriptor } from '../../lib/request'
import { get, post, put, del } from '../../lib/methods'
import type { SupportingDocumentJSON } from '../../models/SupportingDocuments'
import type { SupportingDocumentIndexJSON } from '../../models/SupportingDocuments/SupportingDocumentIndex'

export class SupportingDocuments {
  @authenticate
  static index(clientId: number): ApiRequestDescriptor<SupportingDocumentIndexJSON[]> {
    return get(`/api/v1/clients/${clientId}/supporting_documents`)
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<SupportingDocumentJSON> {
    return get(`/api/v1/clients/supporting_documents/${id}`)
  }

  @authenticate
  @formdataEncoded
  static create(
    clientId: number,
    document_type: number | string,
    file: Blob,
  ): ApiRequestDescriptor<SupportingDocumentJSON> {
    return post(`/api/v1/clients/${clientId}/supporting_documents`, {
      file,
      supporting_document: { document_type },
    })
  }

  @authenticate
  @formdataEncoded
  static update(id: number, file: Blob): ApiRequestDescriptor<SupportingDocumentJSON> {
    return put(`/api/v1/clients/supporting_documents/${id}`, {
      file,
    })
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<undefined> {
    return del(`/api/v1/clients/supporting_documents/${id}`)
  }
}
