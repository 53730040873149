import Spinner from './Spinner'

import style from './Spinner.module.scss'

interface PageSpinnerProps {
  className?: string
}

export default function PageSpinner({ className = '' }: PageSpinnerProps): JSX.Element {
  return (
    <div className={[style.page, className].join(' ').trim()}>
      <Spinner />
    </div>
  )
}
