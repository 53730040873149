/* eslint-disable max-classes-per-file */
import { Defunct, DefunctFields, LegacyDefunctPerson } from 'models/Defunct';
import { LegacyPerson, LegacyPersonFields } from 'models/Person';
import { Model } from '@advitam/api/models/Model';
import { DateTime } from 'luxon';

interface DefunctCompInfoBase {
  poweredDevice?: boolean;
  on_guardianship: boolean;
  decoration?: string;
  weight?: number;
  worship_type_id?: number;
  size?: number;
  width?: number;
  height?: number;
}

interface DealDefunctFamilyBase {
  dead: boolean | null;
}

interface DealDefunctFamilyJSON
  extends LegacyPersonFields,
    DealDefunctFamilyBase {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DealDefunctFamily extends DealDefunctFamilyBase {}
class DealDefunctFamily extends LegacyPerson<DealDefunctFamilyJSON> {}

interface DefunctCompInfoJSON extends DefunctCompInfoBase {
  father: DealDefunctFamilyJSON;
  mother: DealDefunctFamilyJSON;
  partner: DealDefunctFamilyJSON;
}

interface DefunctCompInfo extends DefunctCompInfoBase {
  father: DealDefunctFamily;
  mother: DealDefunctFamily;
  partner: DealDefunctFamily;
}

export interface DealDefunctJSON extends DefunctFields {
  death_date: number | null;
  defunctCompInfo: DefunctCompInfoJSON;
}

export interface DealDefunct extends Defunct<DealDefunctJSON> {
  death_date: Date | null;
  defunctCompInfo: DefunctCompInfo;
}

export class DealDefunct extends Defunct<DealDefunctJSON> {
  constructor(data: DealDefunctJSON) {
    super(data);
    this.person = new LegacyDefunctPerson(data.person);
    this.death_date =
      typeof data.death_date === 'number'
        ? Model.parseTimestamp(data.death_date)
        : data.death_date;
    this.defunctCompInfo = { ...this.defunctCompInfo };
    this.defunctCompInfo.father =
      data.defunctCompInfo.father &&
      new DealDefunctFamily(data.defunctCompInfo.father);
    this.defunctCompInfo.mother =
      data.defunctCompInfo.mother &&
      new DealDefunctFamily(data.defunctCompInfo.mother);
    this.defunctCompInfo.partner =
      data.defunctCompInfo.partner &&
      new DealDefunctFamily(data.defunctCompInfo.partner);
  }

  get age(): number | null {
    if (!this.person.birthDate || !this.death_date) {
      return null;
    }

    return Math.floor(
      DateTime.fromJSDate(this.death_date).diff(
        DateTime.fromSeconds(this.person.birthDate).startOf('day'),
        'year',
      ).years,
    );
  }
}
