export const enum StepType {
  CASKETING = 'casketing',
  CEREMONY = 'ceremony',
  CLOSING = 'closing',
  CREMATION = 'cremation',
  DEATH = 'death',
  DELIVERY = 'delivery',
  DEPOSIT = 'deposit',
  DISPERSION = 'dispersion',
  INTERMENT = 'interment',
  REPATRIATION = 'repatriation',
  TRANSPORT = 'transport',
  URN_PICKUP = 'urn_pickup',
  URN_SEALING = 'urn_sealing',
}
