import type { Action, Middleware, SerializedError } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import ApiError from '@advitam/api/lib/Error/ApiError';

interface ThunkRejection {
  type: string;
  payload: unknown;
  error: SerializedError;
  meta: {
    rejectedWithValue: boolean;
  };
}

function isThunkRejection(action: Action<string>): action is ThunkRejection {
  return (
    action.type.endsWith('/rejected') &&
    (action as ThunkRejection).error !== undefined &&
    typeof (action as ThunkRejection).meta?.rejectedWithValue === 'boolean'
  );
}

const handleThunkErrorMiddleware: Middleware = _ => next => (
  action,
): unknown => {
  if (isThunkRejection(action) && action.payload === undefined) {
    /* eslint-disable no-param-reassign */
    action.payload = new ApiError({ status: 1, statusText: '' }, action.error);
    action.meta.rejectedWithValue = true;
    /* eslint-enable no-param-reassign */

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(action.error);
    }
    Sentry.captureMessage(
      [action.error.message || '', action.error.stack || ''].join('\n\n'),
    );
  }

  const result = next(action) as unknown;
  return result;
};

export default handleThunkErrorMiddleware;
