// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Action-module__action--x_uBX{width:36px;height:36px}.Action-module__action--x_uBX svg{margin:auto;width:16px}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Sections/Todolist/List/parts/Action.module.scss"],"names":[],"mappings":"AAAA,8BACE,UAAA,CACA,WAAA,CAEA,kCACE,WAAA,CACA,UAAA","sourcesContent":[".action {\n  width: 36px;\n  height: 36px;\n\n  svg {\n    margin: auto;\n    width: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action": `Action-module__action--x_uBX`
};
export default ___CSS_LOADER_EXPORT___;
