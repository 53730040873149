import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormUI } from '@advitam/ui';
import BusinessUserBadge from 'components/BusinessUserBadge';
import { assert } from 'lib/support';
import { makeSelectUser } from 'slices/auth';

import { makeSelectDeal } from '../../../selectors.typed';
import { makeSelectFuneralTeamItems } from './selectors';
import style from '../Home.module.scss';
import { updateOwner } from './thunk';

export default function OwnerSelect(): JSX.Element {
  const dispatch = useDispatch();

  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);
  const user = useSelector(makeSelectUser());
  assert(user !== null);
  const items = useSelector(makeSelectFuneralTeamItems());

  const renderPlaceholder = useCallback(
    (value: number): JSX.Element => <BusinessUserBadge userId={value} />,
    [],
  );

  const onChange = useCallback(
    (value: number | number[] | undefined): void => {
      if (!value) {
        return;
      }

      assert(!Array.isArray(value));
      dispatch(updateOwner(value));
    },
    [dispatch],
  );

  return (
    <FormUI.Select
      items={items}
      renderPlaceholder={renderPlaceholder}
      value={deal.user_id || undefined}
      disabled={!user.isFuneralDirectorOrAbove}
      onChange={onChange}
      contained={false}
      className={style.borderless_select}
    />
  );
}
