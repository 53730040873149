import { Objects } from '@advitam/support'
import { SupplierBody, SupplierDependencyBody } from './types'

interface DestroyedSupplierDependency {
  id: number
  _destroy: true
}

type SupplierDependencyAttribute = SupplierDependencyBody | DestroyedSupplierDependency

type SupplierPayload = Omit<
  SupplierBody,
  'id' | 'country' | 'headquarter_country' | 'suppliers_dependencies'
> & {
  country_code?: string | null
  headquarter_country_code?: string | null
  suppliers_dependencies_attributes?: SupplierDependencyAttribute[]
}

export function serialize(supplier: Omit<SupplierBody, 'id'>): SupplierPayload {
  return {
    ...Objects.omit(supplier, 'suppliers_dependencies'),
    suppliers_dependencies_attributes: supplier.suppliers_dependencies,
    country_code: supplier.country === undefined ? undefined : supplier.country?.code || null,
    headquarter_country_code:
      supplier.headquarter_country === undefined
        ? undefined
        : supplier.headquarter_country?.code || null,
  }
}
