import { authenticate, formdataEncoded } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import type { SupportingDocumentJSON } from '../../models/SupportingDocuments'
import type { SupportingDocumentIndexJSON } from '../../models/SupportingDocuments/SupportingDocumentIndex'

export class SupportingDocuments {
  @authenticate
  static index(
    administrativeProcedureId: number,
  ): ApiRequestDescriptor<SupportingDocumentIndexJSON[]> {
    return get(
      `/api/v1/administrative_procedures/${administrativeProcedureId}/supporting_documents`,
    )
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<SupportingDocumentJSON> {
    return get(`/api/v1/administrative_procedures/supporting_documents/${id}`)
  }

  @authenticate
  @formdataEncoded
  static create(
    administrativeProcedureId: number,
    document_type: number | string,
    file: Blob,
  ): ApiRequestDescriptor<SupportingDocumentJSON> {
    return post(
      `/api/v1/administrative_procedures/${administrativeProcedureId}/supporting_documents`,
      {
        file,
        supporting_document: { document_type },
      },
    )
  }

  @authenticate
  @formdataEncoded
  static update(id: number, file: Blob): ApiRequestDescriptor<SupportingDocumentJSON> {
    return put(`/api/v1/administrative_procedures/supporting_documents/${id}`, {
      file,
    })
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<undefined> {
    return del(`/api/v1/administrative_procedures/supporting_documents/${id}`)
  }
}
