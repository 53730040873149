import { FormattedMessage } from 'react-intl';

import { FormLayout, NumberInput } from '@advitam/ui';

import messages from '../messages';

export default function Price(): JSX.Element {
  return (
    <FormLayout.Row>
      <NumberInput
        required
        label={<FormattedMessage id={messages.priceTaxExcl.id} />}
        name="prestation.cost_ht"
        suffix="€"
        step={0.01}
      />
      <NumberInput
        required
        label={<FormattedMessage id={messages.priceTaxIncl.id} />}
        name="prestation.cost_ttc"
        suffix="€"
        step={0.01}
      />
    </FormLayout.Row>
  );
}
