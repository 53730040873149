import { chunk } from 'lodash';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormLayout, Select } from '@advitam/ui/components/Form';
import { Layout } from 'containers/Crud';
import { makeSelectRawClient } from 'containers/Client/selectors';
import { assert } from 'lib/support';
import actionMessages from 'messages/actions';

import messages from './messages';

const COMPANY_PER_ROW = 2;

function getFieldName(row: number, column: number): string {
  return `client.clients_companies[${row * COMPANY_PER_ROW + column}].enabled`;
}

export default function Companies(): JSX.Element {
  const intl = useIntl();

  const client = useSelector(makeSelectRawClient());
  assert(client !== null);
  const rows = chunk(client.clients_companies, COMPANY_PER_ROW);

  const items = [
    { value: true, name: intl.formatMessage(actionMessages.yes) },
    { value: false, name: intl.formatMessage(actionMessages.no) },
  ];

  return (
    <Layout.Fieldset
      title={<FormattedMessage id={messages.companies.id} />}
      tooltip={<FormattedMessage id={messages.companiesTooltip.id} />}
    >
      {rows.map(
        (row, rowIndex): JSX.Element => (
          // eslint-disable-next-line react/no-array-index-key
          <FormLayout.Row key={rowIndex}>
            {row.map((company, columnIndex) => (
              <Select
                key={company.company_id}
                name={getFieldName(rowIndex, columnIndex)}
                label={company.company_name}
                items={items}
              />
            ))}
            {Array(COMPANY_PER_ROW - row.length).fill(<div />)}
          </FormLayout.Row>
        ),
      )}
    </Layout.Fieldset>
  );
}
