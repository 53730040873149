import { authenticate, withSessionId } from '../../../../lib/decorators'
import { get, put } from '../../../../lib/methods'
import { ApiRequestDescriptor } from '../../../../lib/request'
import { CoverageJSON } from '../../../../models/Entity/Coverage'
import { serializeCoverageForUpdate } from './serializers'
import { ZoneCoverageBody } from './types'

interface CoverageShowFilter {
  page?: number
  per_page?: number
}

export class Coverage {
  @authenticate
  static show(zoneId: number, filters?: CoverageShowFilter): ApiRequestDescriptor<CoverageJSON[]> {
    return get(`/api/v1/suppliers/warehouses/zones/${zoneId}/coverage`, filters)
  }

  @authenticate
  @withSessionId
  static update(
    zoneId: number,
    coverage: ZoneCoverageBody[],
  ): ApiRequestDescriptor<CoverageJSON[]> {
    return put(`/api/v1/suppliers/warehouses/zones/${zoneId}/coverage`, {
      coverage: serializeCoverageForUpdate(coverage),
    })
  }
}
