import { ReactNode, useCallback } from 'react'

import { Strings, isEqual } from '@advitam/support'

import CrossIcon from '../../../../images/icons/times.svg'
import ActionTag from '../../../Tag/Action'
import BaseAutosuggest from './Base'

import style from './Autosuggest.module.scss'

export interface Result<T> {
  name: string
  value: T
}

interface SelectableAutosuggestProps<T> {
  label?: ReactNode
  placeholder?: string
  suggestions: Result<T>[]
  fetchSuggestions?: (query: string) => Promise<Result<T>[]>
  value: T[]
  onChange: (value: T[]) => void
  onBlur?: () => void
  minCharsRequest?: number
}

export default function SelectableAutosuggest<T>({
  placeholder,
  label,
  suggestions,
  fetchSuggestions,
  value,
  onChange,
  onBlur,
  minCharsRequest = 2,
}: SelectableAutosuggestProps<T>): JSX.Element {
  const removeSuggestion = useCallback(
    (suggestion: T): void => {
      const newSuggestions = value.filter(s => s !== suggestion)
      onChange(newSuggestions)
    },
    [onChange, value],
  )

  const addSuggestion = useCallback(
    (result: Result<T>): void => {
      const newSuggestions = [...value, result.value]
      onChange(newSuggestions)
    },
    [onChange, value],
  )

  const onAdd = useCallback(
    (result: Result<T> | undefined): void => {
      if (result) {
        addSuggestion(result)
      }
    },
    [addSuggestion],
  )

  const defaultFetchSuggestions = useCallback(
    (query: string): Promise<Result<T>[]> => {
      const availableSuggestions = suggestions.filter(s => !value.includes(s.value))

      const results = availableSuggestions.filter(s =>
        Strings.deburr(s.name).toLowerCase().includes(Strings.deburr(query).toLowerCase()),
      )

      return Promise.resolve(results)
    },
    [suggestions, value],
  )

  return (
    <div>
      <BaseAutosuggest<Result<T>>
        label={label}
        placeholder={placeholder}
        getDisplayValue={(data): string => data.name}
        renderSuggestion={(data): string => data.name}
        getSuggestionValue={(data): string => data.name}
        onChange={onAdd}
        onBlur={onBlur}
        minCharsRequest={minCharsRequest}
        fetchSuggestions={fetchSuggestions || defaultFetchSuggestions}
      />

      <div>
        {value.map((s, idx) => (
          <ActionTag
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            onClick={(): void => removeSuggestion(s)}
            icon={<CrossIcon />}
            className={style.suggestion_tag}
          >
            {suggestions.find(suggestion => isEqual(suggestion.value, s))?.name}
          </ActionTag>
        ))}
      </div>
    </div>
  )
}
