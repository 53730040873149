import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { determineIdForNewObject } from 'utils/functions';

import Buried from './Buried';
import messages from './messages';

const Burieds = ({
  burieds,
  setBurieds,
  intl,
  updateConcession,
  concession,
}) => {
  /**
   * Function to add a buried person
   * @param {number} level level of buried
   */
  const addBuried = level => {
    const updatedBurieds = [
      ...burieds,
      { level, id: determineIdForNewObject(burieds, 'id') },
    ];
    setBurieds(updatedBurieds);
  };

  /**
   * Function to update a buried person
   *
   * @param {object} updatedBuried update a buried person
   */
  const updateBuried = updatedBuried => {
    const updatedBurieds = [...burieds].map(buried => {
      if (buried.id !== updatedBuried.id) return buried;
      return updatedBuried;
    });
    setBurieds(updatedBurieds);
  };

  /**
   * Function to remove a buried person
   *
   * @param {number} buriedId id of the buried to remove
   */
  const removeBuried = buriedId => {
    const updatedBurieds = [...burieds].filter(
      buried => buried.id !== buriedId,
    );
    setBurieds(updatedBurieds);
  };

  /**
   * Function to remove a buried person
   *
   * @param {number} levels places in concession
   * @param {array} buriedsToSort array of buried
   */
  const sortBuriedsIntoLevels = (levels, buriedsToSort) =>
    new Array(levels)
      .fill([], 0, levels)
      .map((level, index) => [
        ...buriedsToSort.filter(buried => buried.level === index),
      ]);

  /**
   * Function to move buried in concession
   *
   * @param {object} info data from drag end
   */
  const moveBuriedIntoLevel = info => {
    if (!info.destination || info.destination.droppableId === UNASSIGNED)
      return;
    const updatedBurieds = [...burieds].map(buried => {
      if (String(buried.id) !== info.draggableId) return buried;
      return {
        ...buried,
        level: parseInt(info.destination.droppableId, 10),
      };
    });
    setBurieds(updatedBurieds);
  };

  /**
   * Function to update places in concession
   *
   * @param {string} name key to update
   * @param {string} value value to update
   */
  const updatePlaces = (name, value) => {
    const updatedPlaces = {
      ...concession.places,
      [name]: value,
    };
    updateConcession({ places: updatedPlaces });
  };

  /**
   * Function to add a place
   */
  const addAPlace = () => {
    const updatedTotalPlaces =
      concession.places.total || concession.places.total === 0
        ? concession.places.total + 1
        : 2;
    updatePlaces('total', updatedTotalPlaces);
  };

  /**
   * Function to remove a place
   */
  const removeAPlace = () => {
    const updatedTotalPlaces =
      concession.places.total || concession.places.total === 0
        ? concession.places.total - 1
        : 0;
    updatePlaces('total', updatedTotalPlaces);
  };

  const levels = sortBuriedsIntoLevels(concession.places.total, burieds);
  const levelUnassigned = burieds.filter(
    buried =>
      (!buried.level && buried.level !== 0) ||
      buried.level > concession.places.total,
  );
  const lastLevel = [...levels].pop();

  const UNASSIGNED = 'unassigned';

  return (
    <div className="rightHolder">
      <div className="rightHolder__header">
        <h5 className="rightHolder__header__title">
          {intl.formatMessage(messages.buried)}
        </h5>
      </div>
      <DragDropContext onDragEnd={moveBuriedIntoLevel}>
        {levelUnassigned.length > 0 && (
          <Droppable key={UNASSIGNED} droppableId={UNASSIGNED}>
            {dropProvided => (
              <div className="margin--10-0">
                {intl.formatMessage(messages.unassigned)}
                <div
                  {...dropProvided.droppableProps}
                  ref={dropProvided.innerRef}
                >
                  {levelUnassigned.map((buried, buriedIndex) => (
                    <Buried
                      key={buried.id}
                      buried={buried}
                      index={buriedIndex}
                      removeBuried={() => removeBuried(buried.id)}
                      updateBuried={updatedBuried =>
                        updateBuried(updatedBuried)
                      }
                    />
                  ))}
                  {dropProvided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        )}
        {levels.map((level, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Droppable key={index} droppableId={String(index)}>
            {dropProvided => (
              <div className="margin--10-0">
                {intl.formatMessage(messages.level, { value: index })}
                <div
                  {...dropProvided.droppableProps}
                  ref={dropProvided.innerRef}
                >
                  {level.map((buried, buriedIndex) => (
                    <Buried
                      key={buried.id}
                      buried={buried}
                      index={buriedIndex}
                      removeBuried={() => removeBuried(buried.id)}
                      updateBuried={updatedBuried =>
                        updateBuried(updatedBuried)
                      }
                    />
                  ))}
                  <button
                    className="rightHolder__button"
                    type="button"
                    onClick={() => addBuried(index)}
                  >
                    +
                  </button>
                  {dropProvided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        ))}
        <button
          className="button space--10-20"
          type="button"
          onClick={addAPlace}
        >
          {intl.formatMessage(messages.addPlace)}
        </button>
        {lastLevel && lastLevel.length === 0 && concession.places.total > 1 && (
          <button
            className="button button--red space--10-20 margin--0-0-10-10"
            type="button"
            onClick={removeAPlace}
          >
            {intl.formatMessage(messages.removeAPlace)}
          </button>
        )}
      </DragDropContext>
    </div>
  );
};

Burieds.propTypes = {
  /** persons buried */
  burieds: PropTypes.array.isRequired,
  /** function to update buried */
  setBurieds: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  /** function to update concession */
  updateConcession: PropTypes.func.isRequired,
  /** concession data */
  concession: PropTypes.object,
};

export default injectIntl(Burieds);
