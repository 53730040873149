import { ReactNode } from 'react';

import Link from './Link';
import LinkGroup from './LinkGroup';
import style from './LinksSection.module.scss';

interface LinksSectionProps {
  children: ReactNode | ReactNode[];
}

export default function LinksSection({
  children,
}: LinksSectionProps): JSX.Element {
  return <div className={style.links}>{children}</div>;
}

export { Link, LinkGroup };
