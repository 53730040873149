import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  funeralAdvisorLabel: {
    id: 'app.containers.Deal.Sections.Home.Reviews.funeralAdvisorLabel',
  },
  ceremonyLabel: {
    id: 'app.containers.Deal.Sections.Home.Reviews.ceremonyLabel',
  },
});
