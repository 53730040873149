import { createSlice } from '@reduxjs/toolkit';

import { MEMORIAL_SPACE_DONATION } from './constants';
import { toggleDonation, updateUrl } from './thunk';

export interface State {
  isLoading: boolean;
}

export interface AppStateSubset {
  [MEMORIAL_SPACE_DONATION]: State;
}

export const initialState: State = {
  isLoading: false,
};

const slice = createSlice({
  name: MEMORIAL_SPACE_DONATION,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(updateUrl.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateUrl.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(toggleDonation.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(toggleDonation.fulfilled, state => {
      state.isLoading = false;
    });
    // these thunks are a sinple dispatch, they never fails
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
