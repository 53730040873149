import { ProductObject, ProductVariant } from 'models/Deal/Product';

interface ImageProps {
  product: ProductObject;
  variant: ProductVariant;
}

export default function Image({
  product,
  variant,
}: ImageProps): JSX.Element | null {
  if (variant.thumbnail) {
    return <img src={variant.thumbnail} alt="" />;
  }
  if (product.thumbnail) {
    return <img src={product.thumbnail} alt="" />;
  }
  return null;
}
