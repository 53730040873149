import { authenticate } from '../../lib/decorators'
import { ApiRequestDescriptor } from '../../lib/request'
import { get, post, put } from '../../lib/methods'
import type { FullClientJSON } from '../../models/Client/Full'
import type { ClientJSON } from '../../models/Client'

import { serialize } from './serializers'
import { Abilities } from './Abilities'
import { Companies } from './Companies'
import { Deceased } from './Deceased'
import { OptOut } from './OptOut'
import { SupportingDocuments } from './SupportingDocuments'

interface ClientsFilters {
  q?: string
  companies_id_in?: number[]
  defunct_id_not_null?: boolean
  page?: number
  per_page?: number
}

export class Clients {
  static readonly Abilities = Abilities

  static readonly Companies = Companies

  static readonly Deceased = Deceased

  static readonly OptOut = OptOut

  static readonly SupportingDocuments = SupportingDocuments

  @authenticate
  static index(filters?: ClientsFilters): ApiRequestDescriptor<ClientJSON[]> {
    return get('/api/v1/clients', filters)
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<FullClientJSON> {
    return get(`/api/v1/clients/${id}`)
  }

  @authenticate
  static create(
    client: Omit<FullClientJSON | ClientJSON, 'id' | 'inserted_at' | 'updated_at'>,
  ): ApiRequestDescriptor<ClientJSON> {
    return post('/api/v1/clients', serialize(client))
  }

  @authenticate
  static update(
    client: Partial<FullClientJSON | ClientJSON>,
  ): ApiRequestDescriptor<FullClientJSON> {
    return put(`/api/v1/clients/${client.id}`, serialize(client))
  }
}
