import StarIcon from '../../images/icons/star.svg'
import style from './Stars.module.scss'

interface StarProps {
  fillValue: number
}

export default function Star({ fillValue }: StarProps): JSX.Element {
  const width = fillValue > 0 ? `${Math.min(fillValue * 100, 100)}%` : 0

  return (
    <div className={style.star}>
      <span style={{ width }}>
        <StarIcon />
      </span>
      <span>
        <StarIcon />
      </span>
    </div>
  )
}
