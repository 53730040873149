import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ErrorProps } from 'components/ErrorModal';
import { validateToken } from 'slices/auth';

import { APP } from './constants';

export interface State {
  isLoading: boolean;
  error: ErrorProps | null;
}

export interface AppStateSubset {
  [APP]: State;
}

const initialState: State = {
  isLoading: true,
  error: null,
};

const slice = createSlice({
  name: APP,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    /**
     * @deprecated Handle errors yourself
     */
    error(state, { payload }: PayloadAction<ErrorProps | null>) {
      state.error = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(validateToken.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(validateToken.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(validateToken.rejected, state => {
      state.isLoading = false;
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { error } = slice.actions;
export default slice;
