import { Model } from '../Model'

import { EntityType } from './EntityType'

interface IndexEntityBase {
  id: number
  entity_type: EntityType
  name: string
  city: string
  postal_code: string
  phone: string
  email: string
}

export interface IndexEntityJSON extends IndexEntityBase {
  updated_at: string | null
}

export interface IndexEntity extends IndexEntityBase {
  updated_at: Date | null
}

export class IndexEntity extends Model<IndexEntityJSON> {
  constructor(data: IndexEntityJSON) {
    super(data)
    this.updated_at = Model.parseDate(data.updated_at)
  }
}
