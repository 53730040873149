import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { ConcessionTermJSON } from '@advitam/api/models/Concession';
import { GraveyardConcessionTypeJSON } from '@advitam/api/models/Graveyard';
import { assert } from 'lib/support';

import { makeSelectRawGraveyard } from '../selectors';
import { AppStateSubset } from '../slice';
import { GRAVEYARD_CONCESSION_INFOS } from './constants';

async function fetchConcessionTerms(
  graveyardId: number,
): Promise<ConcessionTermJSON[]> {
  const { body } = await request(
    Api.V1.Graveyards.ConcessionTerms.index(graveyardId),
  );
  return body;
}

async function fetchConcessionTypes(
  graveyardId: number,
): Promise<GraveyardConcessionTypeJSON[]> {
  const { body } = await request(
    Api.V1.Graveyards.ConcessionTypes.index(graveyardId),
  );
  return body;
}

export const fetchConcessionInfos = createAsyncThunk(
  `${GRAVEYARD_CONCESSION_INFOS}_FETCH`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const graveyard = makeSelectRawGraveyard()(state);
    assert(graveyard?.id !== undefined);

    try {
      const [concessionTerms, concessionTypes] = await Promise.all([
        fetchConcessionTerms(graveyard.id),
        fetchConcessionTypes(graveyard.id),
      ]);

      return { concessionTerms, concessionTypes };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
