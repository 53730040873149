import { Spinner } from '@advitam/ui';
import { ReactEventHandler, useCallback, useEffect, useState } from 'react';

interface RetryableImageProps {
  src: string;
  alt: string;
  crossOrigin?: 'anonymous' | 'use-credentials' | '' | undefined;
  onLoad?: ReactEventHandler<HTMLImageElement> | undefined;
}

export default function RetryableImage({
  src,
  alt,
  crossOrigin,
  onLoad,
}: RetryableImageProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [timeout, setTimeoutState] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => () => {
    if (timeout) {
      clearTimeout(timeout);
    }
  });

  const onError = useCallback(() => {
    if (retryCount >= 5) {
      return;
    }
    setRetryCount(retryCount + 1);
    setIsLoading(true);
    setTimeoutState(setTimeout(() => setIsLoading(false), 500));
  }, [retryCount, setRetryCount, setIsLoading, setTimeoutState]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <img
      src={src}
      alt={alt}
      crossOrigin={crossOrigin}
      onLoad={onLoad}
      onError={onError}
    />
  );
}
