export const enum EntityInvoiceEntityType {
  CITYHALL = 'Cityhall',
  CONSULATE = 'Consulate',
  CREMATORIUM = 'Crematorium',
  FUNERAL_PARLOR = 'FuneralParlor',
  GRAVEYARD = 'Graveyard',
  HOSPITAL = 'Hospital',
  POLICE = 'Police',
  PREFECTURE = 'Prefecture',
  RHA = 'RegionalHealthAuthority',
  SUPPLIER = 'Supplier',
  WORSHIP = 'Worship',
}
