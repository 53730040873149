import { ReactNode } from 'react'

import Text from '../Text'
import style from './Highlight.module.scss'

interface HighlightCardProps {
  img: ReactNode
  title: ReactNode
}

export default function HighlightCard({ img, title }: HighlightCardProps): JSX.Element {
  return (
    <div className={style.card}>
      <div className={style.img}>{img}</div>
      <Text large>
        <b>{title}</b>
      </Text>
    </div>
  )
}
