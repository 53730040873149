/**
 * Re-implement (and type) the now removed hook in 'react-router-dom'.
 *
 * @see https://github.com/remix-run/react-router/issues/8139 for details
 */

import { useCallback } from 'react';
import type { Transition } from 'history';

import { useBlocker } from './useBlocker';

/**
 * Prompts the user with an Alert before they leave the current screen.
 */
export function usePrompt(message: string, when = true): void {
  const blocker = useCallback(
    (tx: Transition) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  useBlocker(blocker, when);
}
