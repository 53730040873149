import type { ReactNode } from 'react'

import ArrowIcon from '../../../images/icons/arrow-right.svg'
import Button from '../../Button'
import Heading from '../../Heading'
import Spinner from '../../Spinner'
import Text from '../../Text'
import Modal from '../Modal'
import style from './Content.module.scss'

interface ConfirmationProps {
  isOpen: boolean
  title?: ReactNode
  text: ReactNode
  confirm: ReactNode
  onConfirm: () => void
  isConfirmationLoading?: boolean
  cancel: ReactNode
  onCancel: () => void
  withArrow?: boolean
  className?: string
}

export default function Confimation({
  isOpen,
  title,
  text,
  confirm,
  onConfirm,
  isConfirmationLoading = false,
  cancel,
  onCancel,
  withArrow = false,
  className = '',
}: ConfirmationProps): JSX.Element {
  return (
    <Modal
      className={`col-4 col-md-6 ${style.message} ${className}`}
      isOpen={isOpen}
      onRequestClose={onCancel}
    >
      {title && (
        <Heading small tagName="h5" className={style.title}>
          <b>{title}</b>
        </Heading>
      )}
      <Text tagName="div" className={style.content}>
        {text}
      </Text>
      <div className={style.buttons}>
        <Button outline onClick={onCancel} text={cancel} className={style.button} />
        <Button
          primary
          fixedSize
          onClick={onConfirm}
          disabled={isConfirmationLoading}
          className={style.button}
          text={isConfirmationLoading ? <Spinner /> : confirm}
          icon={withArrow ? <ArrowIcon /> : undefined}
        />
      </div>
    </Modal>
  )
}
