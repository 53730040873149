import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { ClientAbilityJSON } from '@advitam/api/models/Client/Ability';

import { DEAL_IDENTITY } from './constants';
import type { AppStateSubset, State } from './slice';

type IdentitySelector<T> = Selector<AppStateSubset, T>;

const selectIdentityDomain = (state: AppStateSubset): State =>
  state[DEAL_IDENTITY];

export const makeSelectAbilities = (): IdentitySelector<ClientAbilityJSON[]> =>
  createSelector(selectIdentityDomain, substate => substate.abilities);

export const makeSelectError = (): IdentitySelector<SerializedApiError | null> =>
  createSelector(selectIdentityDomain, substate => substate.error);
