import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { SupplierMinimalWarehouseJSON } from '@advitam/api/models/Supplier/Warehouse';

import { SUPPLIER_WAREHOUSES, WAREHOUSE_IN_USE_ERROR } from './constants';
import { AppStateSubset, State } from './slice';
import type { Filters } from './types';

type SupplierSelectors<T> = Selector<AppStateSubset, T>;

const selectSupplierDomain = (state: AppStateSubset): State =>
  state[SUPPLIER_WAREHOUSES];

export const makeSelectWarehouses = (): SupplierSelectors<
  SupplierMinimalWarehouseJSON[]
> => createSelector(selectSupplierDomain, ({ warehouses }) => warehouses);

export const makeSelectFilters = (): SupplierSelectors<Filters> =>
  createSelector(selectSupplierDomain, ({ filters }) => filters);

export const makeSelectCurrentPage = (): SupplierSelectors<number> =>
  createSelector(selectSupplierDomain, ({ currentPage }) => currentPage);

export const makeSelectHasMore = (): SupplierSelectors<boolean> =>
  createSelector(selectSupplierDomain, ({ hasMore }) => hasMore);

export const makeSelectIsDeleting = (): SupplierSelectors<boolean> =>
  createSelector(selectSupplierDomain, ({ isDeleting }) => isDeleting);

export const makeSelectIsLoading = (): SupplierSelectors<boolean> =>
  createSelector(selectSupplierDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): SupplierSelectors<boolean> =>
  createSelector(selectSupplierDomain, ({ isSaving }) => isSaving);

export const makeSelectDestroyError = (): SupplierSelectors<SerializedApiError | null> =>
  createSelector(selectSupplierDomain, ({ destroyError }) => destroyError);

export const makeSelectDisableError = (): SupplierSelectors<SerializedApiError | null> =>
  createSelector(selectSupplierDomain, ({ disableError }) => disableError);

export const makeSelectError = (): SupplierSelectors<SerializedApiError | null> =>
  createSelector(selectSupplierDomain, ({ error }) => error);

export const makeSelectHasWarehouseInUseError = (): SupplierSelectors<
  boolean
> =>
  createSelector(
    makeSelectDestroyError(),
    destroyError => destroyError?.errorCodes[0] === WAREHOUSE_IN_USE_ERROR,
  );
