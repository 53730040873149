import PropTypes from 'prop-types';

import BulletList from './BulletList';
import CivInput from './CivInput';
import MultiSelectDisplay from './MultiSelectDisplay';
import SelectList from './SelectList';
import style from './Input.module.scss';

export default function Input({ className = '', ...props }) {
  return <input className={`${style.input} ${className}`} {...props} />;
}

Input.propTypes = {
  className: PropTypes.string,
};

export function TextArea({ className = '', ...props }) {
  return <textarea className={`${style.input} ${className}`} {...props} />;
}

TextArea.propTypes = {
  className: PropTypes.string,
};

export function TextInput(props) {
  const { className } = props;
  return <input {...props} type="text" className={`input ${className}`} />;
}

TextInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

TextInput.defaultProps = {
  className: '',
};

export function NumberInput(props) {
  const { className } = props;
  return (
    <input {...props} type="number" className={`input ${className || ''}`} />
  );
}

NumberInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export function EmailInput(props) {
  return <input {...props} type="email" className="input" />;
}

export function PhoneInput(props) {
  return (
    <input
      {...props}
      type="tel"
      pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
      className="input"
    />
  );
}

export function Checkbox(props) {
  return <input {...props} type="checkbox" />;
}

export function CheckboxV2({ children, name, checked, onChange }) {
  return (
    <label className="checkbox-container" htmlFor={name}>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span>{children}</span>
    </label>
  );
}

CheckboxV2.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export { BulletList };
export { SelectList };
export { CivInput };
export { MultiSelectDisplay };
