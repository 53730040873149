import type { PDFPage } from 'pdf-lib';

import type { Input } from '../../models';
import messages from '../../messages';
import type { BuildPDFContext } from './types';
import { getScale, getY } from './utils';

const FONT_SIZE = 16;

export default function renderSignature(
  input: Input,
  page: PDFPage,
  context: BuildPDFContext,
): Promise<void> {
  const text = context.intl.formatMessage(messages.inputSignature);
  const scale = getScale(page);
  const textSize = context.font.heightAtSize(FONT_SIZE * scale);
  const y = input.position[1] * scale;
  const offset = (FONT_SIZE * scale) / 4; // Where does it come from ?

  const width = input.size?.[0] || 0;
  const textWidth = context.font.widthOfTextAtSize(text, FONT_SIZE);
  const leftOffset = (width - textWidth) / 2;

  page.drawText(text, {
    font: context.font,
    size: FONT_SIZE * scale,
    x: (input.position[0] + leftOffset) * scale,
    y: getY(page, y + textSize - offset),
  });

  return Promise.resolve();
}
