import { InputHTMLAttributes, ReactElement, ReactNode } from 'react'
import { Field } from 'react-final-form'

import Radio from '../../UI/Radio'
import { composeValidators, isRequired, Validator } from '../../validators'

interface CardProps {
  icon: ReactElement
  text: ReactNode
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  required?: boolean
  validate?: Validator<InputHTMLAttributes<HTMLInputElement>['value']>
  className?: string
}

export default function Card({
  icon,
  text,
  name,
  value,
  required,
  validate,
  className,
}: CardProps): JSX.Element {
  const validates = []
  if (required) validates.push(isRequired)
  if (validate) validates.push(validate)
  return (
    <Field
      type="radio"
      name={name}
      value={value}
      validate={composeValidators<InputHTMLAttributes<HTMLInputElement>['value']>(...validates)}
    >
      {({ input, meta }): JSX.Element => (
        <Radio.Card
          icon={icon}
          text={text}
          onChange={input.onChange}
          name={name}
          value={value}
          checked={input.checked}
          error={!!(meta.touched && meta.error)}
          className={className}
        />
      )}
    </Field>
  )
}
