import { defineMessages, MessageDescriptor } from 'react-intl';

const messages: {
  [key: string]: MessageDescriptor;
} = defineMessages({
  familyUpdates: {
    id: 'app.containers.Deal.LayersModal.familyUpdates',
  },
  true: {
    id: 'app.containers.Deal.LayersModal.true',
  },
  false: {
    id: 'app.containers.Deal.LayersModal.false',
  },
  civility: {
    id: 'app.containers.Deal.LayersModal.civility',
  },
  firstname: {
    id: 'app.containers.Deal.LayersModal.firstname',
  },
  lastname: {
    id: 'app.containers.Deal.LayersModal.lastname',
  },
  birth_name: {
    id: 'app.containers.Deal.LayersModal.birth_name',
  },
  address: {
    id: 'app.containers.Deal.LayersModal.address',
  },
  postal_code: {
    id: 'app.containers.Deal.LayersModal.postal_code',
  },
  city: {
    id: 'app.containers.Deal.LayersModal.city',
  },
  country: {
    id: 'app.containers.Deal.LayersModal.country',
  },
  nationality: {
    id: 'app.containers.Deal.LayersModal.nationality',
  },
  birth_date: {
    id: 'app.containers.Deal.LayersModal.birth_date',
  },
  birth_place: {
    id: 'app.containers.Deal.LayersModal.birth_place',
  },
  work: {
    id: 'app.containers.Deal.LayersModal.work',
  },
  marital_status: {
    id: 'app.containers.Deal.LayersModal.marital_status',
  },
  pacemaker: {
    id: 'app.containers.Deal.LayersModal.pacemaker',
  },
  weight: {
    id: 'app.containers.Deal.LayersModal.weight',
  },
  size: {
    id: 'app.containers.Deal.LayersModal.size',
  },
  phone: {
    id: 'app.containers.Deal.LayersModal.phone',
  },
  phone_2: {
    id: 'app.containers.Deal.LayersModal.phone_2',
  },
  empowered_client: {
    id: 'app.containers.Deal.LayersModal.empowered_client',
  },
  defunct: {
    id: 'app.containers.Deal.LayersModal.defunct',
  },
  defunct_father: {
    id: 'app.containers.Deal.LayersModal.defunct_father',
  },
  defunct_mother: {
    id: 'app.containers.Deal.LayersModal.defunct_mother',
  },
  defunct_partner: {
    id: 'app.containers.Deal.LayersModal.defunct_partner',
  },
  terminate: {
    id: 'app.containers.Deal.LayersModal.terminate',
  },
  link: {
    id: 'app.containers.Deal.LayersModal.link',
  },
  dead: {
    id: 'app.containers.Deal.LayersModal.dead',
  },
});

export default messages;
