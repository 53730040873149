import type { ReactNode } from 'react'

import Link from '../../../../Link'
import Tooltip from '../../../../Tooltip'
import style from './MyAccount.module.scss'

interface MyAccountProps {
  href: string
  internal?: boolean
  icon: ReactNode
  tooltip?: ReactNode
}

export default function MyAccount({ href, internal, icon, tooltip }: MyAccountProps): JSX.Element {
  return (
    <Tooltip content={tooltip} placement="bottom" offsetY={15}>
      <Link internal={internal} className={style.icon} href={href}>
        {icon}
      </Link>
    </Tooltip>
  )
}
