export const enum ProductType {
  COFFIN = 'Coffin',
  MATTRESS = 'Mattress',
  URN = 'Urn',
  SYMBOL = 'Symbol',
  ANNOUNCEMENT = 'Announcement',
  BONES_BOX = 'Bones_box',
  PLATE = 'Plate',
  SEMELLE = 'semelle',
  MONUMENT = 'monument',
  PATTERN = 'pattern',
  MEDALLION = 'medallion',
  SYMBOL_GRAVEYARD = 'symbol_graveyard',
  STONES = 'stones',
  ACCESSORY = 'accessory',
  PRESS = 'Press',
  FLOWER = 'Flower',
  TREE = 'tree',
  LETTER_SENDING = 'letter_sending',
}
