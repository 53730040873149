import type { ReactNode } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import Text from '../../Text'

import messages from './messages'
import style from './Testimonials.module.scss'
import Stars from '../../Stars'

interface ReviewItemV2Props {
  rate: number
  commentary: string
  name: string
  place?: string
  icon?: ReactNode
  date: Date
  className?: string
}

export default function ReviewItemV2({
  rate,
  commentary,
  name,
  place,
  icon,
  date,
  className,
}: ReviewItemV2Props): JSX.Element {
  const classes = [style.item_v2, className, 'col-3'].filter(Boolean)

  return (
    <div className={classes.join(' ')}>
      <div className={style.rate}>
        <Stars rate={rate} />
        <Text extraSmall className={style.date}>
          <FormattedMessage
            id={messages.reviewDate.id}
            values={{ date: <FormattedDate value={date} year="numeric" month="short" /> }}
          />
        </Text>
      </div>
      <Text small tagName="div" className={style.commentary}>
        {commentary}
      </Text>
      <div className={style.footer}>
        <Text tagName="div">
          <b>{name}</b>
          {place && <>- {place}</>}
        </Text>
        {icon}
      </div>
    </div>
  )
}
