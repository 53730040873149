import { FormattedMessage } from 'react-intl';

import { FormLayout, NumberInput } from '@advitam/ui';

import { Layout, messages as crudMessages } from 'containers/Crud';

import messages from '../messages';

const PREFIX = 'graveyard';

export function TemporaryVault(): JSX.Element {
  return (
    <Layout.Fieldset
      title={<FormattedMessage id={messages.temporaryVault.id} />}
    >
      <FormLayout.Row>
        <NumberInput
          label={<FormattedMessage id={messages.openingFees.id} />}
          step={0.1}
          name={`${PREFIX}.temporary_vault_oneoff`}
          tooltip={<FormattedMessage id={messages.openingFeesTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
        <NumberInput
          label={<FormattedMessage id={messages.maxDuration.id} />}
          name={`${PREFIX}.temporary_vault_max_length`}
          tooltip={
            <FormattedMessage id={messages.vaultMaxDurationTooltip.id} />
          }
          suffix={<FormattedMessage id={crudMessages.unitDay.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          label={<FormattedMessage id={messages.includedDays.id} />}
          name={`${PREFIX}.temporary_vault_length`}
          tooltip={<FormattedMessage id={messages.includedDaysTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitDay.id} />}
        />
        <NumberInput
          label={<FormattedMessage id={messages.additionalDays.id} />}
          step={0.1}
          name={`${PREFIX}.temporary_vault_price_per_day`}
          tooltip={<FormattedMessage id={messages.additionalDaysTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
