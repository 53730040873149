import { useCallback, useRef, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { Button, ConfirmationModal } from '@advitam/ui';
import { addCollectionItem, Layout } from 'containers/Crud';
import actionsMessages from 'messages/actions';

import { assert } from 'lib/support';
import Room from './parts/Room';
import { CrematoriumRoomsForm } from './types';
import { ROOM_SKELETON } from './constants';
import messages from './messages';
import style from './Rooms.module.scss';

export default function RoomsContainer(): JSX.Element {
  const [roomToDeleteIdx, setRoomToDeleteIdx] = useState<number | null>(null);
  const gridContainer = useRef<HTMLDivElement>(null);

  const form = useForm<CrematoriumRoomsForm>();
  const { values } = useFormState<CrematoriumRoomsForm>();
  const { sectionValues: rooms } = values;

  const closeDeleteConfirmation = useCallback(() => {
    setRoomToDeleteIdx(null);
  }, [setRoomToDeleteIdx]);

  const onAddRoom = useCallback(() => {
    assert(gridContainer.current !== null);
    addCollectionItem(
      form,
      'sectionValues',
      ROOM_SKELETON,
      gridContainer.current,
    );
  }, [form, gridContainer]);

  const onDeleteRoomConfirm = useCallback(() => {
    const newRooms = rooms.filter((_, index) => roomToDeleteIdx !== index);
    form.change('sectionValues', newRooms);
    closeDeleteConfirmation();
  }, [form, closeDeleteConfirmation, rooms, roomToDeleteIdx]);

  return (
    <>
      <Layout.Container>
        <Layout.ColumnGroup>
          <Button
            primary
            className={style.add}
            onClick={onAddRoom}
            text={<FormattedMessage id={messages.addRoom.id} />}
          />
        </Layout.ColumnGroup>
        <Layout.FieldsetGrid ref={gridContainer}>
          <Field name="sectionValues">
            {(): JSX.Element[] =>
              rooms.map((room, idx) => (
                <Room
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${idx}-${room.id || -1}`}
                  idx={idx}
                  deleteRoom={(): void => setRoomToDeleteIdx(idx)}
                />
              ))
            }
          </Field>
        </Layout.FieldsetGrid>
      </Layout.Container>

      {roomToDeleteIdx !== null && (
        <ConfirmationModal
          isOpen
          cancel={<FormattedMessage id={actionsMessages.cancel.id} />}
          confirm={<FormattedMessage id={actionsMessages.confirm.id} />}
          onCancel={closeDeleteConfirmation}
          onConfirm={onDeleteRoomConfirm}
          text={
            <FormattedMessage
              id={messages.deleteConfirmation.id}
              values={{ name: rooms[roomToDeleteIdx]?.name }}
            />
          }
        />
      )}
    </>
  );
}
