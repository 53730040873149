import { MenuItem as MenuItemType } from '../types'
import style from '../Menu.module.scss'

interface MenuItemProps {
  item: MenuItemType
}

export default function MenuItem({ item }: MenuItemProps): JSX.Element {
  const classes = [style.item]
  if (item.displayOnMobile) {
    classes.push(style.item__mobile)
  }
  if (item.displayOnTablet) {
    classes.push(style.item__tablet)
  }
  if (item.displayOnDesktop) {
    classes.push(style.item__desktop)
  }
  if (item.hideInSidebar) {
    classes.push(style.hide)
  }

  return <div className={classes.join(' ')}>{item.children}</div>
}
