import { FormattedMessage, useIntl } from 'react-intl';
import { useFormState } from 'react-final-form';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import type { SupplierWarehouseAirportJSON } from '@advitam/api/models/Supplier/Warehouse';
import { ContactMedia } from '@advitam/api/models/Entity/ContactMedia';
import { ApiAutocomplete, FormLayout, Select } from '@advitam/ui';
import { Layout } from 'containers/Crud';
import actionMessages from 'messages/actions';

import type { MiscellaneousForm } from './types';
import messages from './messages';

export default function Content(): JSX.Element {
  const intl = useIntl();

  const { values } = useFormState<MiscellaneousForm>();

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.functioning.id} />}>
      <FormLayout.Row>
        <Select
          name="warehouse.prebooking_enabled"
          label={<FormattedMessage id={messages.prebooking.id} />}
          tooltip={<FormattedMessage id={messages.prebookingTooltip.id} />}
          items={[
            {
              value: true,
              name: intl.formatMessage(actionMessages.yes),
            },
            { value: false, name: intl.formatMessage(actionMessages.no) },
          ]}
        />
        <Select
          name="warehouse.prebooking_prefered_contact_media"
          label={<FormattedMessage id={messages.prebookingContactMedia.id} />}
          tooltip={
            <FormattedMessage id={messages.prebookingContactMediaTooltip.id} />
          }
          items={[
            {
              value: ContactMedia.EMAIL,
              name: intl.formatMessage(messages.emailMedia),
            },
            {
              value: ContactMedia.SMS,
              name: intl.formatMessage(messages.smsMedia),
            },
          ]}
        />
      </FormLayout.Row>

      <FormLayout.Row>
        <Select
          name="warehouse.booking_enabled"
          label={<FormattedMessage id={messages.booking.id} />}
          tooltip={<FormattedMessage id={messages.bookingTooltip.id} />}
          items={[
            {
              value: true,
              name: intl.formatMessage(actionMessages.yes),
            },
            { value: false, name: intl.formatMessage(actionMessages.no) },
          ]}
        />
        <Select
          name="warehouse.booking_prefered_contact_media"
          label={<FormattedMessage id={messages.bookingContactMedia.id} />}
          tooltip={
            <FormattedMessage id={messages.bookingContactMediaTooltip.id} />
          }
          items={[
            {
              value: ContactMedia.EMAIL,
              name: intl.formatMessage(messages.emailMedia),
            },
            {
              value: ContactMedia.FAX,
              name: intl.formatMessage(messages.faxMedia),
            },
            {
              value: ContactMedia.SMS,
              name: intl.formatMessage(messages.smsMedia),
            },
          ]}
        />
      </FormLayout.Row>

      <FormLayout.Row>
        <Select
          name="warehouse.accept_coffin_delivery"
          label={<FormattedMessage id={messages.coffinDelivery.id} />}
          tooltip={<FormattedMessage id={messages.coffinDeliveryTooltip.id} />}
          items={[
            {
              value: true,
              name: intl.formatMessage(messages.coffinDeliveryYes),
            },
            {
              value: false,
              name: intl.formatMessage(messages.coffinDeliveryNo),
            },
          ]}
          unselectable
        />
        <Select
          name="warehouse.has_delivery_fees"
          label={<FormattedMessage id={messages.deliveryFees.id} />}
          tooltip={<FormattedMessage id={messages.deliveryFeesTooltip.id} />}
          items={[
            {
              value: true,
              name: intl.formatMessage(actionMessages.yes),
            },
            {
              value: false,
              name: intl.formatMessage(actionMessages.no),
            },
          ]}
          unselectable
        />
      </FormLayout.Row>

      <FormLayout.Row>
        <Select
          name="sectionValues.repatriation"
          label={<FormattedMessage id={messages.repatriationByPlane.id} />}
          items={[
            {
              value: true,
              name: intl.formatMessage(actionMessages.yes),
            },
            {
              value: false,
              name: intl.formatMessage(actionMessages.no),
            },
          ]}
          unselectable
        />
        <Select
          name="warehouse.advitam_grade"
          label={<FormattedMessage id={messages.grade.id} />}
          tooltip={<FormattedMessage id={messages.gradeTooltip.id} />}
          items={[
            { value: 1, name: '1' },
            { value: 2, name: '2' },
            { value: 3, name: '3' },
            { value: 4, name: '4' },
            { value: 5, name: '5' },
          ]}
        />
      </FormLayout.Row>

      {values.sectionValues.repatriation && (
        <FormLayout.Row>
          <ApiAutocomplete<
            SupplierWarehouseAirportJSON & { description: string }
          >
            name="warehouse.airport"
            label={<FormattedMessage id={messages.departureAirport.id} />}
            tooltip={
              <FormattedMessage id={messages.departureAirportTooltip.id} />
            }
            endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.airports.index)}
            requestHeaders={{ ...getTokens() }}
            keys={['name', 'iata']}
            getDisplayValue={(value): string =>
              [value.iata, value.name].filter(Boolean).join(' - ')
            }
          />
        </FormLayout.Row>
      )}
    </Layout.Fieldset>
  );
}
