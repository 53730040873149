import Layout from 'containers/Crud/Layout';

import Wrapper from '../Wrapper';
import List from './List';

export default function RatesSection(): JSX.Element {
  return (
    <Wrapper>
      <Layout.Container>
        <List />
      </Layout.Container>
    </Wrapper>
  );
}
