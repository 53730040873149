import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  400: { id: 'app.containers.DocumentsEditor.ErrorList.400' },
  413: { id: 'app.containers.DocumentsEditor.ErrorList.413' },
  404: { id: 'app.containers.DocumentsEditor.ErrorList.404' },
  422: { id: 'app.containers.DocumentsEditor.ErrorList.422' },
  500: { id: 'app.containers.DocumentsEditor.ErrorList.500' },
  deliveryLocationError: {
    id: 'app.containers.DocumentsEditor.ErrorList.deliveryLocationError',
  },
  document_editions__document__file__too_big: {
    id:
      'app.containers.DocumentsEditor.ErrorList.document_editions__document__file__too_big',
  },
  document_editions__document__file__unsupported_type: {
    id:
      'app.containers.DocumentsEditor.ErrorList.document_editions__document__file__unsupported_type',
  },
  document_editions__document__file__corrupted: {
    id:
      'app.containers.DocumentsEditor.ErrorList.document_editions__document__file__corrupted',
  },
  document_editions__document__file__unexpected: {
    id:
      'app.containers.DocumentsEditor.ErrorList.document_editions__document__file__unexpected',
  },
  document_editions__document__target__unknown: {
    id:
      'app.containers.DocumentsEditor.ErrorList.document_editions__document__target__unknown',
  },
  document_type__id__not_found: {
    id: 'app.containers.DocumentsEditor.ErrorList.document_type__id__not_found',
  },
});
