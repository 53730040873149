import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ResourceType } from '@advitam/api/models/DocumentEditions';
import { assert } from '@advitam/support';
import { makeSelectDeal } from 'containers/Deal/selectors';
import type { NewUploadResource } from 'containers/DocumentsEditor/types';
import { DocumentGenerationType } from 'models/Document';
import type { DealDocument } from 'models/Deal/Document';
import { setFile } from 'containers/DocumentTemplateEditor/slice';
import { setDocumentsGenerationQueue } from 'containers/DocumentGenerationModal/slice';
import UploadFiles from 'components/UploadFiles/NoModal';

import { makeSelectSelectedDocuments } from '../selectors';
import { toggleDocumentSelection } from '../actions';
import Document from './Document';
import style from './DocumentList.module.scss';

type UploadFiles = (
  files: File[],
  resource: NewUploadResource,
) => void | Promise<void>;

interface SectionDocumentProps {
  doc: DealDocument;
  uploadFiles: UploadFiles;
}

export default function SectionDocument({
  doc,
  uploadFiles,
}: SectionDocumentProps): JSX.Element {
  const dispatch = useDispatch();

  const deal = useSelector(makeSelectDeal());
  const filesSelected = useSelector(
    makeSelectSelectedDocuments(),
  ) as DealDocument[];

  const isFileSelected = filesSelected.some(file => file.id === doc.id);
  const onClick = useCallback(() => dispatch(toggleDocumentSelection(doc)), [
    dispatch,
    doc,
  ]);

  if (doc.locked) {
    return (
      <div className={style.document}>
        <Document doc={doc} onClick={onClick} isFileSelected={isFileSelected} />
      </div>
    );
  }

  if (doc.document?.generation_type === DocumentGenerationType.DIFFERENT) {
    return (
      <UploadFiles
        postFiles={(filesToUpload: File[]) => {
          dispatch(setDocumentsGenerationQueue([doc]));
          dispatch(setFile(filesToUpload[0]));
        }}
        fileUploaded={doc}
        noClick={!doc.isUpload || doc.hasVersion}
      >
        <Document doc={doc} onClick={onClick} isFileSelected={isFileSelected} />
      </UploadFiles>
    );
  }

  const uuid = deal?.uuid;
  assert(uuid !== undefined);
  return (
    <UploadFiles
      fileUploaded={doc}
      noClick={!doc.isUpload || doc.hasVersion}
      postFiles={(files: File[]) =>
        uploadFiles(files, {
          type: ResourceType.DEAL,
          id: doc.id,
          deal: { uuid },
        })
      }
    >
      <Document doc={doc} onClick={onClick} isFileSelected={isFileSelected} />
    </UploadFiles>
  );
}
