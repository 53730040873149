import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';

import { PASSWORD_RESET_MODAL } from './constants';
import type { AppStateSubset, State } from './slice';

type CrematoriumSelectors<T> = Selector<AppStateSubset, T>;

const selectClientDomain = (state: AppStateSubset): State =>
  state[PASSWORD_RESET_MODAL];

export const makeSelectIsOpen = (): CrematoriumSelectors<boolean> =>
  createSelector(selectClientDomain, ({ isOpen }) => isOpen);

export const makeSelectIsLoading = (): CrematoriumSelectors<boolean> =>
  createSelector(selectClientDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): CrematoriumSelectors<SerializedApiError | null> =>
  createSelector(selectClientDomain, ({ error }) => error);
