import { assert } from '@advitam/support'

import { authenticate, withSessionId } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { CrematoriumJSON } from '../../models/Crematorium'
import { AgeRanges } from './AgeRanges'
import { Ceremonies } from './Ceremonies'
import { Disabled } from './Disabled'
import { Rooms } from './Rooms'
import { serialize } from './serializers'
import { CrematoriumPayload } from './types'

export class Crematoriums {
  static readonly AgeRanges = AgeRanges

  static readonly Ceremonies = Ceremonies

  static readonly Disabled = Disabled

  static readonly Rooms = Rooms

  @authenticate
  static create(crematorium: CrematoriumPayload): ApiRequestDescriptor<CrematoriumJSON> {
    return post('/api/v1/crematoriums', serialize(crematorium))
  }

  @authenticate
  @withSessionId
  static update(crematorium: CrematoriumPayload): ApiRequestDescriptor<CrematoriumJSON> {
    assert(crematorium.id !== undefined)
    return put(`/api/v1/crematoriums/${crematorium.id}`, serialize(crematorium))
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<CrematoriumJSON> {
    return get(`/api/v1/crematoriums/${id}`)
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/crematoriums/${id}`)
  }
}
