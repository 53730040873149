import * as Sentry from '@sentry/core';

import { ErrorCodes } from './codes';
import { UnprocessableEntity } from './UnprocessableEntity';

const ERROR_NAME = 'ApiError';

export interface SerializedApiError {
  body: unknown;
  errorCodes: string[];
  status: number;
}

type NeededResponse = Pick<Response, 'statusText' | 'status'>;

export function isApiError(error: unknown): error is ApiError {
  return error instanceof Error && error.name === ERROR_NAME;
}

const Status = {
  NO_NETWORK: 0,
} as const;

function extractErrorCodes(err: ApiError): string[] {
  if (err.status === Status.NO_NETWORK) {
    return [ErrorCodes.NO_NETWORK];
  }

  if (err.status !== 422 || !err.body) {
    return [ErrorCodes.UNHANDLED];
  }
  const { errors } = (err as UnprocessableEntity).body;
  if (!errors) {
    return [ErrorCodes.UNHANDLED];
  }
  if (!Array.isArray(errors)) {
    return [errors];
  }
  return errors;
}

export class ApiError extends Error {
  static readonly Status = Status;

  readonly body: string | null;

  readonly status: number;

  constructor(response: NeededResponse, body: string | null) {
    super(response.statusText);

    this.name = ERROR_NAME;
    this.body = body;
    this.status = response.status;
  }

  get errorCodes(): string[] {
    return extractErrorCodes(this);
  }

  static assertCode(err: unknown, code: string): asserts err is ApiError {
    if (!isApiError(err) || err.errorCodes[0] !== code) {
      throw err;
    }
  }

  static getErrorCodes(err: unknown): string[] {
    if (!isApiError(err)) {
      return [ErrorCodes.UNHANDLED];
    }
    return err.errorCodes;
  }

  static getErrorCode(err: unknown): string {
    return ApiError.getErrorCodes(err)[0];
  }

  static serialize(err: unknown): SerializedApiError | null {
    if (isApiError(err)) {
      return {
        body: err.body,
        status: err.status,
        errorCodes: extractErrorCodes(err),
      };
    }

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    if (err instanceof Error) {
      Sentry.captureException(err);
    } else if (typeof err === 'string') {
      Sentry.captureMessage(err);
    } else {
      Sentry.captureMessage(`unknown error type "${typeof err}"`);
    }

    return {
      body: null,
      status: 0,
      errorCodes: [ErrorCodes.UNHANDLED],
    };
  }
}
