import type { FormApi } from 'final-form';
import { nextTick } from 'lib/support';
import { get } from 'lodash';

type Unarray<T> = T extends Array<infer U> ? U : T;

export function addCollectionItem<
  FormType,
  KeyType extends keyof FormType,
  ItemType = Unarray<FormType[KeyType]>
>(
  form: FormApi<FormType>,
  key: KeyType,
  item: ItemType,
  container: HTMLElement,
): void {
  const { values } = form.getState();
  const array = get(values, key) as ItemType[];
  form.change(key, ([...array, item] as unknown) as FormType[KeyType]);
  nextTick((): void => {
    container.lastElementChild?.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
    });
  });
}
