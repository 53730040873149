import { get } from 'lodash';
import { useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { getFieldName } from '../utils';

const MIN_ITEMS_COUNT = 1;

interface DynamicFieldsHook {
  fields: string[];
  canAddFields: boolean;
  canDeleteFields: boolean;
  addField: () => void;
  removeField: (index: number) => void;
}

export function useDynamicFields(
  baseName: string,
  fieldsCount: number,
  prefix = '',
): DynamicFieldsHook {
  const form = useForm();
  const { values } = useFormState();

  const getIndexedFieldName = useCallback(
    (idx: number): string =>
      getFieldName(prefix, idx === 0 ? baseName : `${baseName}_${idx + 1}`),
    [prefix, baseName],
  );

  const allFields = Array(fieldsCount)
    .fill(0)
    .map((_, idx) => getIndexedFieldName(idx));

  const fields = allFields.filter(
    (field, idx) => get(values, field) !== null || idx < MIN_ITEMS_COUNT,
  );

  const addField = useCallback((): void => {
    if (allFields.length === fields.length) {
      return;
    }
    form.change(getIndexedFieldName(fields.length), '');
  }, [allFields, fields, form, getIndexedFieldName]);

  const removeField = useCallback(
    (indexToRemove: number): void => {
      if (fields.length === MIN_ITEMS_COUNT) {
        return;
      }

      fields.forEach((field, index) => {
        if (index < indexToRemove) {
          return;
        }

        if (index === fields.length - 1) {
          form.change(field, null);
          return;
        }

        const nextFieldValue = get(
          values,
          getIndexedFieldName(index + 1),
        ) as string;
        form.change(field, nextFieldValue);
      });
    },
    [fields, form, getIndexedFieldName, values],
  );

  return {
    fields,
    canAddFields: fields.length < fieldsCount,
    canDeleteFields: fields.length > MIN_ITEMS_COUNT,
    addField,
    removeField,
  };
}
