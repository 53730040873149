import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ResourceList } from '@advitam/ui';

import messages from './messages';
import { makeSelectFilters } from './selectors';
import { ClientsFilters } from './types';
import style from './Clients.module.scss';

interface FiltersProps {
  onChange: (filters: ClientsFilters) => void;
}

export default function Filters({ onChange }: FiltersProps): JSX.Element {
  const intl = useIntl();
  const filters = useSelector(makeSelectFilters());
  const [searchParams] = useSearchParams();

  const filtersShape = [
    {
      type: 'Select' as const,
      name: 'defunct_id_not_null',
      placeholder: <FormattedMessage id={messages.status.id} />,
      items: [
        { value: false, name: intl.formatMessage(messages.alive) },
        { value: true, name: intl.formatMessage(messages.deceased) },
      ],
      initialValue: filters.defunct_id_not_null,
    },
  ];
  const searchFilter = {
    name: 'q',
    placeholder: intl.formatMessage(messages.search),
    initialValue: searchParams.get('q') || filters.q,
  };

  return (
    <ResourceList.Filters
      className={style.filters}
      filters={filtersShape}
      searchFilter={searchFilter}
      onChange={onChange}
    />
  );
}
