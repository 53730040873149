import { throttle } from 'throttle-debounce'
import { useState, useEffect } from 'react'

export interface WindowSize {
  width: number
  height: number
}

// Have a realistic size when rendering server side
// This is (more or less) a full screen window on a HD (1280x720) desktop
// screen, minus the window frame and the taskbar.
const SSR_WINDOW_SIZE = { width: 1270, height: 600 }

/**
 * @deprecated Use an alternative, see full documentation.
 *
 * @warning This hook is a last resort, it triggers an update each frame when
 * resizing the window. Prefer one of the above :
 * * for drawing conditionally depending on the window size, use `useBreakpoint`
 *   instead.
 * * for any other purpose, create a more specific and performant hook and
 *   document it here.
 */
export function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>(
    typeof window === 'undefined' || window.innerWidth === 0
      ? SSR_WINDOW_SIZE
      : { width: window.innerWidth, height: window.innerHeight },
  )

  useEffect(() => {
    const handleResize = (): void => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    const callback = throttle(100, handleResize)

    window.addEventListener('resize', callback)
    handleResize()

    return (): void => window.removeEventListener('resize', callback)
  }, [])

  return windowSize
}
