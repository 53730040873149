import { RequestDocumentTodoItem, RequestDocumentTodoItemJSON } from './RequestDocument'
import { SendBatchTodoItem, SendBatchTodoItemJSON } from './SendBatch'
import { StandardTodoItem, StandardTodoItemJSON, StandardTodoType } from './Standard'
import { TodoType } from './Type'

type TodoItemJSON = StandardTodoItemJSON | RequestDocumentTodoItemJSON | SendBatchTodoItemJSON

type TodoItem = StandardTodoItem | RequestDocumentTodoItem | SendBatchTodoItem

export function createTodoItem(data: TodoItemJSON): TodoItem {
  switch (data.todo_type) {
    case TodoType.SEND_BATCH:
      return new SendBatchTodoItem(data)
    case TodoType.REQUEST_DOCUMENT:
      return new RequestDocumentTodoItem(data)
    default:
      return new StandardTodoItem(data)
  }
}

export * from './Action'
export { TodoType }
export type {
  StandardTodoType,
  RequestDocumentTodoItemJSON,
  RequestDocumentTodoItem,
  SendBatchTodoItemJSON,
  SendBatchTodoItem,
  StandardTodoItemJSON,
  StandardTodoItem,
  TodoItemJSON,
  TodoItem,
}
