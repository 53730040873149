/**
 * Get concessions types by selected type
 *
 * @param      {Object}  prevLocation previous step's location
 * @param      {Object}  location current step's location
 * @return     {Bool} True if the location has not changed
 */
export function sameLocation(prevLocation, location) {
  const prevLocationType = prevLocation && prevLocation.type;
  const prevLocationID = prevLocation && prevLocation.id;
  const locationType = location && location.type;
  const loctionTypeID = location && location.id;
  return prevLocationType === locationType && prevLocationID === loctionTypeID;
}
