import { chatbotApiEndpoint } from '../../lib/decorators'
import { post } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { TextCompletionJSON } from '../../models/Chatbot/TextCompletion'
import type { TextCompletionTool } from '../../models/Chatbot/TextCompletion/Tool'

export const TextCompletions = {
  create: chatbotApiEndpoint(
    (tool: TextCompletionTool, content: string): ApiRequestDescriptor<TextCompletionJSON> =>
      post('/v1/text_completions', { tool, content }),
  ),
}
