import { createSelector, Selector } from 'reselect';
import { SerializedApiError } from '@advitam/api';
import { RoomJSON } from '@advitam/api/models/Crematorium/Room';
import { CREMATORIUM_ROOMS } from './constants';
import { AppStateSubset, State } from './slice';

type CrematoriumRoomsSelectors<T> = Selector<AppStateSubset, T>;

const selectRoomsDomain = (state: AppStateSubset): State =>
  state[CREMATORIUM_ROOMS];

export const makeSelectRooms = (): CrematoriumRoomsSelectors<RoomJSON[]> =>
  createSelector(selectRoomsDomain, ({ rooms }) => rooms);

export const makeSelectIsSaving = (): CrematoriumRoomsSelectors<boolean> =>
  createSelector(selectRoomsDomain, ({ isSaving }) => isSaving);

export const makeSelectIsLoading = (): CrematoriumRoomsSelectors<boolean> =>
  createSelector(selectRoomsDomain, ({ isLoading }) => isLoading);

export const makeSelectHasAlreadyInUseError = (): CrematoriumRoomsSelectors<
  boolean
> =>
  createSelector(
    selectRoomsDomain,
    ({ hasAlreadyInUseError }) => hasAlreadyInUseError,
  );

export const makeSelectError = (): CrematoriumRoomsSelectors<SerializedApiError | null> =>
  createSelector(selectRoomsDomain, ({ error }) => error);
