import Content from './Content'
import Global from './Global'
import Phone from './Phone'
import Paragraph from './Paragraph'
import Highlight from './Highlight'

const TextBloc = {
  Content,
  Global,
  Phone,
  Paragraph,
  Highlight,
}

export default TextBloc
