import { defineMessages } from 'react-intl';

export default defineMessages({
  mr: {
    id: 'app.models.Person.Civility.mr',
  },
  mrs: {
    id: 'app.models.Person.Civility.mrs',
  },
  businessId: {
    id: 'app.models.Deal.businessId',
  },
  risky: {
    id: 'app.models.Deal.PaymentProblem.risky',
  },
  overdue: {
    id: 'app.models.Deal.PaymentProblem.overdue',
  },
  litigation: {
    id: 'app.models.Deal.PaymentProblem.litigation',
  },
  none: {
    id: 'app.models.Deal.PaymentProblem.none',
  },
  Wish: {
    id: 'app.models.Relative.Wish',
  },
  Safe: {
    id: 'app.models.Relative.Safe',
  },
  funeral: {
    id: 'app.models.Relative.funeral',
  },
  Memorial: {
    id: 'app.models.Relative.Memorial',
  },
  marble: {
    id: 'app.models.Relative.marble',
  },
  editor: {
    id: 'app.models.Ability.editor',
  },
  reader: {
    id: 'app.models.Ability.reader',
  },
  owner: {
    id: 'app.models.Ability.owner',
  },
});
