import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EditPerson from 'containers/Deal/EditPerson';

import messages from './messages';

const Buyer = ({ buyer, updateConcession }) => (
  <div className="concession__field">
    <FormattedMessage {...messages.buyer} tagName="h5" />
    <EditPerson
      person={buyer}
      handleUpdate={update => updateConcession({ buyer: update })}
    />
  </div>
);

Buyer.propTypes = {
  buyer: PropTypes.object.isRequired,
  updateConcession: PropTypes.func.isRequired,
};

export default Buyer;
