import { pick } from 'lodash';

import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get, put } from 'api/methods';
import { StandardDefunctJSON } from 'models/Defunct';

import { SupportingDocuments } from './SupportingDocuments';

type DefunctKey = keyof StandardDefunctJSON;
const UPDATABLE_FIELDS: DefunctKey[] = [
  'firstname',
  'lastname',
  'civility',
  'birth_name',
  'birth_date',
  'death_date',
];

export class Defuncts {
  static readonly SupportingDocuments = SupportingDocuments;

  @authenticate
  static show(id: number): ApiRequestDescriptor<StandardDefunctJSON> {
    return get(`/api/v1/defuncts/${id}`);
  }

  @authenticate
  static update(
    defunct: StandardDefunctJSON,
  ): ApiRequestDescriptor<StandardDefunctJSON> {
    return put(
      `/api/v1/defuncts/${defunct.id}`,
      pick(defunct, ...UPDATABLE_FIELDS),
    );
  }
}
