/*
 *  Casketing step editor
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  WANT_EMBALMING,
  WANT_CLEANING,
  WANT_REFREGIRATED_TABLE,
  WANT_PRE_CASKETING,
} from 'utils/constants';
import {
  STEP_TBC,
  POLICE,
  PUBLIC,
  HEALTH_FACILITY,
  FUNERAL_PARLOR,
} from 'containers/Deal/DealFuneral/constants';
import DatePicker from 'components/DatePicker';
import { TextInput, SelectList } from 'components/Input';
import CheckboxLabel from 'components/Input/CheckboxLabel';
import Services from 'containers/Deal/DealFuneral/StepsSection/Services';
import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';

import {
  renderStepDefaults,
  renderLocation,
  renderEditLocation,
} from './stepEditorsUtils';
import CheckboxWishes from './CheckboxWishes';
import messages from './messages';

const locationTypes = [
  { value: PUBLIC, label: messages.public },
  { value: HEALTH_FACILITY, label: messages.healthFacility },
  { value: FUNERAL_PARLOR, label: messages.funeralParlor },
];

class Casketing extends Component {
  componentDidMount() {
    const {
      getFuneralParlorStayTypes,
      step,
      setFuneralParlorStayTypes,
    } = this.props;
    if (!step.location.id) setFuneralParlorStayTypes([]);
    if (step.location.id && step.location.type === FUNERAL_PARLOR) {
      getFuneralParlorStayTypes(step.location.id);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getFuneralParlorStayTypes,
      step,
      handleChange,
      steps,
      setFuneralParlorStayTypes,
    } = this.props;
    const tbcStep = steps.find(item => item.eventType === STEP_TBC);
    if (!step.location.id && prevProps.step.location.id)
      setFuneralParlorStayTypes([]);
    if (
      step.tribunal_authorization_date &&
      tbcStep &&
      tbcStep.doneBy !== POLICE
    ) {
      handleChange({ ...step, tribunal_authorization_date: null });
    }
    if (
      step.location.id &&
      step.location.id !== prevProps.step.location.id &&
      step.location.type === FUNERAL_PARLOR
    ) {
      getFuneralParlorStayTypes(step.location.id);
    }
  }

  render() {
    const {
      stepServices,
      addService,
      removeService,
      wishes,
      updateWish,
      findWish,
      step,
      steps,
      getStep,
      handleChange,
      funeralParlorStayTypes,
      updateDelegate,
      isManualMode,
      userRole,
      updateService,
    } = this.props;
    const prevStep = getStep(step.id - 1);
    const tbcStep = steps.find(stepData => stepData.eventType === STEP_TBC);

    const onChange = value => {
      if (value && prevStep) {
        handleChange({
          ...step,
          location: {
            ...prevStep.location,
            ...step.location,
            sameAsPreviousStep: value,
          },
        });
        return;
      }

      handleChange({
        ...step,
        location: {
          ...step.location,
          sameAsPreviousStep: value,
        },
      });
    };

    const defaultOption = { name: '', value: '' };
    const funeralParlorStayTypesOptions = funeralParlorStayTypes.map(type => ({
      ...type,
      value: type.id,
    }));
    funeralParlorStayTypesOptions.push(defaultOption);

    return (
      <div className="step">
        <div className="step__header">
          {renderStepDefaults(step, handleChange)}
          {renderLocation(step.location, messages.location, prevStep)}
          <CheckboxDelegate
            isDelegate={!!step.doneByAnotherFuneral}
            updateDelegate={updateDelegate}
          />
        </div>
        <div>
          <div>
            {tbcStep && tbcStep.doneBy === POLICE && (
              <div className="flex">
                <DatePicker
                  fieldName="tribunalAuthorizationDate"
                  date={step.tribunal_authorization_date}
                  onChange={value =>
                    handleChange({
                      ...step,
                      tribunal_authorization_date: value,
                    })
                  }
                />
              </div>
            )}
            <div>
              <CheckboxLabel
                message={messages.sameAsDeathPlace}
                updateCheckbox={() =>
                  onChange(!step.location.sameAsPreviousStep)
                }
                checkboxValue={step.location.sameAsPreviousStep}
              />
            </div>
            {!step.location.sameAsPreviousStep &&
              renderEditLocation(step, handleChange, locationTypes)}
            <div>
              {step.needPrefectureExemption && (
                <div>
                  <FormattedMessage {...messages.prefectureExemptionReason} />
                  <br />
                  <div>
                    <TextInput
                      value={step.prefectureExemption || ''}
                      onChange={({ target: { value } }) =>
                        handleChange({ ...step, prefectureExemption: value })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            {step.location.type === FUNERAL_PARLOR &&
              funeralParlorStayTypes.length > 0 && (
                <div>
                  <FormattedMessage {...messages.funeralParlorStayTypes} />
                  <SelectList
                    name="funeralParlorStay"
                    options={funeralParlorStayTypesOptions}
                    value={String(step.funeral_parlor_stay_id) || ''}
                    onChange={({ target: { value } }) =>
                      handleChange({ ...step, funeral_parlor_stay_id: value })
                    }
                  />
                </div>
              )}
          </div>
          <div>
            <CheckboxWishes
              wishType={WANT_EMBALMING}
              message={messages.embalming}
              wishes={wishes}
              findWish={findWish}
              updateWish={updateWish}
            />
            <CheckboxWishes
              wishType={WANT_REFREGIRATED_TABLE}
              message={messages.table}
              wishes={wishes}
              findWish={findWish}
              updateWish={updateWish}
            />
            <CheckboxWishes
              wishType={WANT_CLEANING}
              message={messages.cleaning}
              wishes={wishes}
              findWish={findWish}
              updateWish={updateWish}
              inversed
            />
            <CheckboxWishes
              wishType={WANT_PRE_CASKETING}
              message={messages.pre_casketing}
              wishes={wishes}
              findWish={findWish}
              updateWish={updateWish}
            />
          </div>
        </div>
        <Services
          stepId={step.id}
          services={stepServices}
          addService={addService}
          updateService={updateService}
          removeService={removeService}
          isManualMode={isManualMode}
          userRole={userRole}
        />
      </div>
    );
  }
}

Casketing.propTypes = {
  step: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  wishes: PropTypes.array.isRequired,
  stepServices: PropTypes.array.isRequired,
  addService: PropTypes.func.isRequired,
  updateWish: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  findWish: PropTypes.func.isRequired,
  getStep: PropTypes.func.isRequired,
  getFuneralParlorStayTypes: PropTypes.func.isRequired,
  setFuneralParlorStayTypes: PropTypes.func.isRequired,
  funeralParlorStayTypes: PropTypes.array,
  updateDelegate: PropTypes.func.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  updateService: PropTypes.func.isRequired,
};

export default Casketing;
