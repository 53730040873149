import { authenticate } from '../../../lib/decorators'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { post, put, del } from '../../../lib/methods'
import type { ClientAbilityJSON } from '../../../models/Client/Ability'

import { serializeForCreation, serializeForUpdate } from './serializers'

export class Abilities {
  @authenticate
  static create(
    dealId: number,
    client: Omit<ClientAbilityJSON, 'id' | 'resource'>,
  ): ApiRequestDescriptor<ClientAbilityJSON> {
    return post(`/api/v1/deals/${dealId}/abilities`, serializeForCreation(client))
  }

  @authenticate
  static update(ability: ClientAbilityJSON): ApiRequestDescriptor<ClientAbilityJSON> {
    return put(`/api/v1/deals/abilities/${ability.id}`, serializeForUpdate(ability))
  }

  @authenticate
  static destroy(abilityId: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/deals/abilities/${abilityId}`)
  }
}
