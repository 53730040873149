import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  arrival: { id: 'app.containers.Flights.arrival' },
  arrivalFilter: { id: 'app.containers.Flights.arrivalFilter' },
  company: { id: 'app.containers.Flights.company' },
  departure: { id: 'app.containers.Flights.departure' },
  departureFilter: { id: 'app.containers.Flights.departureFilter' },
  lastUpdate: { id: 'app.containers.Flights.lastUpdate' },
  newFlight: { id: 'app.containers.Flights.newFlight' },
  price: { id: 'app.containers.Flights.price' },
  search: { id: 'app.containers.Flights.search' },
  stopover: { id: 'app.containers.Flights.stopover' },
  weight: { id: 'app.containers.Flights.weight' },
});
