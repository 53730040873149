import { useForm } from 'react-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Memorial } from 'models/Memorial';

import appMessages from 'messages/actions';
import { HTMLInput } from 'components/ReactFormComponent';
import { ButtonV2 } from 'components/Button';
import Spinner from 'components/Spinner';
import { withSlice } from 'utils/injectReducer';

import SectionWrapper from '../SectionWrapper';
import { updateMessage } from './thunk';
import { makeSelectIsLoading } from './selectors';
import messages from './messages';
import slice from './slice';

interface CustomizationProps {
  memorial: Memorial;
}

function Customization({ memorial }: CustomizationProps): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsLoading());

  const { Form, handleSubmit } = useForm({
    onSubmit: ({ message }: Partial<Memorial>): void => {
      dispatch(updateMessage(message || ''));
    },
    defaultValues: memorial,
  });

  return (
    <SectionWrapper title={messages.title}>
      <Form>
        <HTMLInput field="message" message={messages.message} />
        {isLoading ? (
          <Spinner />
        ) : (
          <ButtonV2 onClick={handleSubmit}>
            <FormattedMessage id={appMessages.save.id} />
          </ButtonV2>
        )}
      </Form>
    </SectionWrapper>
  );
}

export default withSlice(slice)(Customization);
