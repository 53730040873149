import { createSlice } from '@reduxjs/toolkit';

import { MEMORIAL_SPACE_CUSTOMIZATION } from './constants';
import { updateMessage } from './thunk';

export interface State {
  isLoading: boolean;
}

export interface AppStateSubset {
  [MEMORIAL_SPACE_CUSTOMIZATION]: State;
}

export const initialState: State = {
  isLoading: false,
};

const slice = createSlice({
  name: MEMORIAL_SPACE_CUSTOMIZATION,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(updateMessage.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateMessage.fulfilled, state => {
      state.isLoading = false;
    });
    // these thunks are a sinple dispatch, they never fails
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
