import { CSSProperties } from 'react';

import { useProduct } from '../../ProductListProvider';
import { getDetails } from '../utils';

interface DetailsCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function DetailsCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: DetailsCellProps): JSX.Element | null {
  const warehouseProduct = useProduct(rowIndex);
  const details = getDetails(warehouseProduct);

  return (
    <div style={wrapperStyle} className={className}>
      {details}
    </div>
  );
}
