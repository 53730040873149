import { ReactNode, useCallback, useState } from 'react'

import ButtonText from '../ButtonText'
import { DropdownPosition } from './types'
import ArrowShape from './arrow-shape.svg'
import style from './Dropdown.module.scss'
import { getClassForPosition, useOpenState } from './utils'

export interface DropdownProps {
  placeholder: ReactNode
  position?: DropdownPosition
  children: ReactNode[]
  className?: string
  keepOpen?: boolean
}

export default function Dropdown({
  placeholder,
  position = DropdownPosition.CENTER,
  children,
  className = '',
  keepOpen = false,
}: DropdownProps): JSX.Element {
  const [isOpen, setIsOpen, height, containerRef, childrenRef] = useOpenState(keepOpen)
  const [isTouching, setIsTouching] = useState(false)

  const classes = [style.wrapper, getClassForPosition(position)]
  if (className) {
    classes.push(className)
  }

  const open = useCallback(
    (isTouchEvent: boolean): void => {
      if (!isTouchEvent && isTouching) {
        return
      }
      setIsTouching(isTouching)
      setIsOpen(true)
    },
    [isTouching, setIsTouching, setIsOpen],
  )

  const close = useCallback((): void => {
    setIsTouching(false)
    setIsOpen(false)
  }, [setIsTouching, setIsOpen])

  const toggle = useCallback((): void => {
    if (isOpen) {
      close()
    } else {
      open(true)
    }
  }, [isOpen, close, open])

  return (
    <div
      className={`${style.dropdown} ${isOpen ? style.dropdown__opened : ''}`}
      onMouseEnter={(): void => open(false)}
      onMouseLeave={close}
      ref={containerRef}
    >
      <div
        role="button"
        className={style.placeholder}
        onTouchStart={(): void => setIsTouching(true)}
        onTouchEnd={toggle}
      >
        {placeholder}
      </div>
      <div style={{ height }} className={classes.join(' ')} data-is-open={isOpen}>
        <div ref={childrenRef}>
          <ArrowShape className={style.arrow} />
          <div className={`${style.content}`}>
            {children.map((child, index) => (
              <div key={index} className={style.item} onClick={(): void => setIsOpen(false)}>
                <ButtonText small>{child}</ButtonText>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export { DropdownPosition }
