// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumbs-module__breadcrumbs--Vpe1x{margin:0;padding:0;list-style-type:none}.Breadcrumbs-module__breadcrumbs--Vpe1x li{display:inline-block;white-space:nowrap}.Breadcrumbs-module__breadcrumbs--Vpe1x a{display:inline-block;color:inherit}.Breadcrumbs-module__breadcrumbs--Vpe1x span{display:inline-block;max-width:245px;overflow:hidden;text-overflow:".."}.Breadcrumbs-module__breadcrumbs--Vpe1x svg{margin:0 16px;width:8px;vertical-align:6px}`, "",{"version":3,"sources":["webpack://./../ui/components/Breadcrumbs/Breadcrumbs.module.scss"],"names":[],"mappings":"AAAA,wCACE,QAAA,CACA,SAAA,CACA,oBAAA,CAEA,2CACE,oBAAA,CACA,kBAAA,CAGF,0CACE,oBAAA,CACA,aAAA,CAGF,6CACE,oBAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CAGF,4CACE,aAAA,CACA,SAAA,CACA,kBAAA","sourcesContent":[".breadcrumbs {\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n\n  li {\n    display: inline-block;\n    white-space: nowrap;\n  }\n\n  a {\n    display: inline-block;\n    color: inherit;\n  }\n\n  span {\n    display: inline-block;\n    max-width: 245px;\n    overflow: hidden;\n    text-overflow: '..';\n  }\n\n  svg {\n    margin: 0 16px;\n    width: 8px;\n    vertical-align: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `Breadcrumbs-module__breadcrumbs--Vpe1x`
};
export default ___CSS_LOADER_EXPORT___;
