// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Number-module__without_spinner--zzgil{appearance:textfield}.Number-module__without_spinner--zzgil::-webkit-inner-spin-button,.Number-module__without_spinner--zzgil::-webkit-outer-spin-button{appearance:none;margin:0}`, "",{"version":3,"sources":["webpack://./../ui/components/Form/UI/Number/Number.module.scss"],"names":[],"mappings":"AAAA,uCACE,oBAAA,CAEA,oIAEE,eAAA,CACA,QAAA","sourcesContent":[".without_spinner {\n  appearance: textfield;\n\n  &::-webkit-inner-spin-button,\n  &::-webkit-outer-spin-button {\n    appearance: none;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"without_spinner": `Number-module__without_spinner--zzgil`
};
export default ___CSS_LOADER_EXPORT___;
