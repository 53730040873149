import { post } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { FlowerDealJSON } from '../../../models/Funnel/Flower'
import type { ClientJSON } from '../../../models/Funnel/Client'
import { serialize } from './serializer'
import { TrackingAttributes } from '../../types/Tracking'

export class Deals {
  static create(
    this: void,
    deal: FlowerDealJSON,
    client: ClientJSON,
    tracking?: TrackingAttributes,
  ): ApiRequestDescriptor<FlowerDealJSON> {
    return post(`/api/v1/flower_funnel/deals`, serialize(deal, client, tracking))
  }
}
