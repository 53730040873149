import PropTypes from 'prop-types';

import style from './CloseCross.module.scss';

function CloseCross({ handleClose }) {
  return (
    <button type="button" className={style.cross} onClick={handleClose}>
      ×
    </button>
  );
}

CloseCross.propTypes = {
  handleClose: PropTypes.func,
};

export default CloseCross;
