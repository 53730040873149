import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { NotificationJSON } from '@advitam/api/models/Notification';

import { APP_NOTIFICATION_CENTER, NOTIFICATION_PER_PAGE } from './constants';
import {
  fetchNotificationsPage,
  fetchUnreadNotificationCount,
  markAllAsRead,
  markAsRead,
  pushNotification,
  resetState,
} from './thunk';

export interface State {
  isPanelOpen: boolean;
  unreadNotificationCount: number;
  notifications: NotificationJSON[];
  hasMore: boolean;
  isInitialized: boolean;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [APP_NOTIFICATION_CENTER]: State;
}

function markManyAsRead(state: State, ids: number[]): void {
  /* eslint-disable no-param-reassign */
  state.notifications.forEach(notification => {
    if (ids.includes(notification.id) && !notification.viewed) {
      notification.viewed = true;
      state.unreadNotificationCount -= 1;
    }
  });
  /* eslint-enable no-param-reassign */
}

const initialState: State = {
  isPanelOpen: false,
  unreadNotificationCount: 0,
  notifications: [],
  hasMore: true,
  isInitialized: false,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: APP_NOTIFICATION_CENTER,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setIsNotificationPanelOpen: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isPanelOpen = payload;
    },
    markLocalAsRead: (state, { payload }: PayloadAction<number[]>) => {
      markManyAsRead(state, payload);
    },
    /* eslint-enable no-param-reassign */
  },
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(
      fetchUnreadNotificationCount.fulfilled,
      (state, { payload }) => {
        state.unreadNotificationCount = payload;
        state.isInitialized = true;
      },
    );
    builder.addCase(
      fetchUnreadNotificationCount.rejected,
      (state, { payload }) => {
        state.error = ApiError.serialize(payload);
      },
    );

    builder.addCase(fetchNotificationsPage.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchNotificationsPage.fulfilled, (state, { payload }) => {
      state.notifications.push(...payload.notifications);
      state.isLoading = false;
      const currentPageCount =
        Math.floor((state.notifications.length - 1) / NOTIFICATION_PER_PAGE) +
        1;
      state.hasMore = payload.pageCount > currentPageCount;
    });
    builder.addCase(fetchNotificationsPage.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
      state.isLoading = false;
    });

    builder.addCase(markAsRead.fulfilled, (state, { meta }) => {
      const notificationIndex = state.notifications.findIndex(
        notification => notification.id === meta.arg.id,
      );

      const notification = state.notifications[notificationIndex];
      // There is no loading state on this call, it is possible to dispatch
      // multiple calls until this reducer is executed
      if (!notification.viewed) {
        state.unreadNotificationCount -= 1;
      }
      notification.viewed = true;
    });
    builder.addCase(markAsRead.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(markAllAsRead.fulfilled, (state, { payload }) => {
      markManyAsRead(state, payload);
      state.isPanelOpen = false;
    });
    builder.addCase(markAllAsRead.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(pushNotification.pending, (state, { meta }) => {
      state.unreadNotificationCount += 1;
      state.notifications.unshift(meta.arg);
    });

    builder.addCase(resetState.pending, state => {
      state.notifications = [];
      state.hasMore = true;
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { markLocalAsRead, setIsNotificationPanelOpen } = slice.actions;
export default slice;
