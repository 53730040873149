import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  age: { id: 'app.containers.Crematorium.sections.Ceremonies.age' },
  room: { id: 'app.containers.Crematorium.sections.Ceremonies.room' },
  ceremony: {
    id: 'app.containers.Crematorium.sections.Ceremonies.ceremony',
  },
  ceremonyType: {
    id: 'app.containers.Crematorium.sections.Ceremonies.ceremonyType',
  },
  ceremonyTypeStandard: {
    id: 'app.containers.Crematorium.sections.Ceremonies.ceremonyTypeStandard',
  },
  ceremonyTypePremium: {
    id: 'app.containers.Crematorium.sections.Ceremonies.ceremonyTypePremium',
  },
  ceremonyTypeNoFamily: {
    id: 'app.containers.Crematorium.sections.Ceremonies.ceremonyTypeNoFamily',
  },
  coffinWoodType: {
    id: 'app.containers.Crematorium.sections.Ceremonies.coffinWoodType',
  },
  coffinWoodAny: {
    id: 'app.containers.Crematorium.sections.Ceremonies.coffinWoodAny',
  },
  coffinWoodHard: {
    id: 'app.containers.Crematorium.sections.Ceremonies.coffinWoodHard',
  },
  coffinWoodSoft: {
    id: 'app.containers.Crematorium.sections.Ceremonies.coffinWoodSoft',
  },
  coffinWoodCardboard: {
    id: 'app.containers.Crematorium.sections.Ceremonies.coffinWoodCardboard',
  },
  add: { id: 'app.containers.Crematorium.sections.Ceremonies.add' },

  newCeremony: {
    id: 'app.containers.Crematorium.sections.Ceremonies.newCeremony',
  },

  title: {
    id: 'app.containers.Crematorium.sections.Ceremonies.title',
  },
  titlePlaceholder: {
    id: 'app.containers.Crematorium.sections.Ceremonies.titlePlaceholder',
  },
  default: {
    id: 'app.containers.Crematorium.sections.Ceremonies.default',
  },
  defaultTooltip: {
    id: 'app.containers.Crematorium.sections.Ceremonies.defaultTooltip',
  },
  price: {
    id: 'app.containers.Crematorium.sections.Ceremonies.price',
  },
  reducedPrice: {
    id: 'app.containers.Crematorium.sections.Ceremonies.reducedPrice',
  },
  reducedPriceTooltip: {
    id: 'app.containers.Crematorium.sections.Ceremonies.reducedPriceTooltip',
  },
  ageRange: {
    id: 'app.containers.Crematorium.sections.Ceremonies.ageRange',
  },
  isLive: {
    id: 'app.containers.Crematorium.sections.Ceremonies.isLive',
  },
  isLiveTooltip: {
    id: 'app.containers.Crematorium.sections.Ceremonies.isLiveTooltip',
  },
  withCeremonyMaster: {
    id: 'app.containers.Crematorium.sections.Ceremonies.withCeremonyMaster',
  },
  withCeremonyMasterTooltip: {
    id:
      'app.containers.Crematorium.sections.Ceremonies.withCeremonyMasterTooltip',
  },
  coffinWoodTypeTooltip: {
    id: 'app.containers.Crematorium.sections.Ceremonies.coffinWoodTypeTooltip',
  },
  duration: {
    id: 'app.containers.Crematorium.sections.Ceremonies.duration',
  },
  durationTooltip: {
    id: 'app.containers.Crematorium.sections.Ceremonies.durationTooltip',
  },

  duplicate: {
    id: 'app.containers.Crematorium.sections.Ceremonies.duplicate',
  },
  delete: {
    id: 'app.containers.Crematorium.sections.Ceremonies.delete',
  },

  deletionConfirmationText: {
    id:
      'app.containers.Crematorium.sections.Ceremonies.deletionConfirmationText',
  },
  deletionConfirmationConfirm: {
    id:
      'app.containers.Crematorium.sections.Ceremonies.deletionConfirmationConfirm',
  },
  deletionConfirmationCancel: {
    id:
      'app.containers.Crematorium.sections.Ceremonies.deletionConfirmationCancel',
  },
});
