import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';
import { ResourceType } from '@advitam/api/models/DocumentEditions';
import { ButtonLike, LinkButton, Spinner, Tooltip } from '@advitam/ui';
import Paperclip from '@advitam/ui/images/icons/paperclip.svg';
import Upload from '@advitam/ui/images/icons/upload.svg';
import DocumentsEditor from 'containers/DocumentsEditor';
import UploadFiles from 'components/UploadFiles/NoModal/index.js';

import {
  makeSelectIsInvoiceLoading,
  makeSelectIsInvoiceUploaded,
} from '../selectors';
import messages from './messages';
import style from './List.module.scss';

interface InvoiceIconProps {
  invoice: EntityInvoiceJSON;
}

export default function InvoiceIcon({
  invoice,
}: InvoiceIconProps): JSX.Element {
  const isLoading = useSelector(makeSelectIsInvoiceLoading(invoice.id));
  const isUploaded = useSelector(makeSelectIsInvoiceUploaded(invoice.id));

  if (invoice.file) {
    return (
      <Tooltip content={<FormattedMessage id={messages.openInvoice.id} />}>
        <LinkButton
          outline
          className={style.action}
          href={invoice.file}
          newTab
          icon={<Paperclip />}
        />
      </Tooltip>
    );
  }

  if (isLoading) {
    return <ButtonLike outline icon={<Spinner />} className={style.action} />;
  }

  if (isUploaded) {
    return (
      <Tooltip content={<FormattedMessage id={messages.invoiceUploaded.id} />}>
        <ButtonLike
          outline
          reverse
          icon={<Paperclip />}
          className={style.action}
        />
      </Tooltip>
    );
  }

  return (
    <DocumentsEditor>
      {({ uploadFiles }): JSX.Element => (
        <UploadFiles
          className={style.upload_invoice_icon}
          fileUploaded={document}
          postFiles={async (filesToUpload: File[]): Promise<void> => {
            await uploadFiles(filesToUpload, {
              type: ResourceType.INVOICE,
              id: invoice.id,
            });
          }}
        >
          <ButtonLike outline icon={<Upload />} className={style.action} />
        </UploadFiles>
      )}
    </DocumentsEditor>
  );
}
