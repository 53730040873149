import { pick } from 'lodash';

import { DealJSON } from 'models/v2/Deal';

export interface FuneralPayload {
  deal: Pick<DealJSON, 'commentary'>;
}

// This serializer will tend to change according to
// funeral deal update serialization
export function serialize(deal: DealJSON): FuneralPayload {
  return { deal: pick(deal, 'commentary') };
}
