import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { ContentModal } from '@advitam/ui';

import Content from './Content';
import { makeSelectIsLoading, makeSelectPaymentToRefund } from './selectors';
import slice, { setPaymentToRefund } from './slice';
import style from './RefundModal.module.scss';

function RefundModal(): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsLoading());
  const paymentToRefund = useSelector(makeSelectPaymentToRefund());

  const onCancel = useCallback(() => {
    if (!isLoading) {
      dispatch(setPaymentToRefund(null));
    }
  }, [dispatch, isLoading]);

  return (
    <ContentModal
      isOpen={Boolean(paymentToRefund)}
      className={`new-design col-sm-6 ${style.modal}`}
      onRequestClose={onCancel}
    >
      {paymentToRefund && <Content onCancel={onCancel} />}
    </ContentModal>
  );
}

export default withSlice(slice)(RefundModal);
