import { Model } from '../../Model'

export interface TodoItemBaseJSON {
  id: number
  todo_item_id: number | null
  title: string
  done: boolean
  force_done: boolean | null
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TodoItemBase extends TodoItemBaseJSON {}

export class TodoItemBase<T extends TodoItemBaseJSON = TodoItemBaseJSON> extends Model<T> {
  // eslint-disable-next-line no-useless-constructor
  protected constructor(data: T) {
    super(data)
  }

  get isDone(): boolean {
    if (this.force_done !== null) {
      return this.force_done
    }
    return this.done
  }
}
