import { DateTime } from 'luxon'
import { Validator, ValidatorReturnType } from './Validator'

const ERROR = 'date_is_not_valid'

export function isDateValid(format?: string): Validator<string | null> {
  return (value: string | null): ValidatorReturnType => {
    if (!value) {
      return undefined
    }

    if (format && !DateTime.fromFormat(value, format).isValid) {
      return ERROR
    }

    if (!DateTime.fromISO(value).isValid) {
      return ERROR
    }

    return undefined
  }
}
