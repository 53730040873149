import { authenticate, withSessionId } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { HospitalJSON } from '../../models/Hospital'
import { Disabled } from './Disabled'
import { serialize } from './serializers'
import { HospitalBody } from './types'

export class Hospitals {
  static readonly Disabled = Disabled

  @authenticate
  static show(id: number): ApiRequestDescriptor<HospitalJSON> {
    return get(`/api/v1/hospitals/${id}`)
  }

  @authenticate
  static create(hospital: Omit<HospitalBody, 'id'>): ApiRequestDescriptor<HospitalJSON> {
    return post('/api/v1/hospitals', serialize(hospital))
  }

  @authenticate
  @withSessionId
  static update(hospital: HospitalBody): ApiRequestDescriptor<HospitalJSON> {
    return put(`/api/v1/hospitals/${hospital.id}`, serialize(hospital))
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<HospitalJSON> {
    return del(`/api/v1/hospitals/${id}`)
  }
}
