import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';

import { DealType } from '@advitam/api/models/Deal/Type';
import { assert } from '@advitam/support';
import Api, { requestAsync as request } from 'api';
import { ProductsSuppliersBody } from 'api/v1/ProductsSuppliers';
import { PrestationStatus, ProductVariant } from 'models/Deal/Product';
import { LegacyStep } from 'models/Deal/Step';
import { SKELETON_PRESTATION } from 'components/PrestationDialog/constants.js';
import { DealPrestationComputeType } from 'models/Deal/Prestation/ComputeType';

import { DEAL_PRODUCTS } from './constants';
import { error as setError, setDealFields } from '../../actions.js';
import {
  makeSelectProducts,
  makeSelectSteps,
  makeSelectDealType,
} from '../../selectors.js';
import type { AppStateSubset } from '../../slice';
import { getFuneralStepId } from './utils';

async function fetchProduct(
  productId: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<ProductsSuppliersBody> {
  try {
    const { body } = await request(Api.V1.ProductsSuppliers.show(productId));
    assert(body !== null);
    return body;
  } catch (err) {
    dispatch(setError(err));
    throw err;
  }
}

export const addProduct = createAsyncThunk(
  `${DEAL_PRODUCTS}/ADD`,
  async (productId: number, { dispatch, getState }) => {
    const product = await fetchProduct(productId, dispatch);

    const state = getState() as AppStateSubset;
    const steps = makeSelectSteps()(state as never) as LegacyStep[];
    const dealType = makeSelectDealType()(state) as DealType;
    const dealProducts = makeSelectProducts()(state as never);

    const products = [...dealProducts];

    const selectedProduct = {
      ...SKELETON_PRESTATION,
      amount: 1,
      type: product.productObject.type,
      priceLine: {
        ...SKELETON_PRESTATION.priceLine,
        ...(product.priceLine || {}),
      },
      group_name: product.productObject.group_name,
      step_type: product.productObject.step_type,
      productObject: {
        ...product.productObject,
      },
      step_id:
        dealType === DealType.MARBLE
          ? 0
          : getFuneralStepId(steps, product.productObject.step_type),
      item_id: product.productObject.id,
      compute: DealPrestationComputeType.MANUAL,
      default: false,
      default_keys: {},
      comp_info: null,
      variants: [
        ...product.variants,
        {
          ...product.productObject,
          ...product.priceLine,
        },
      ],
      status: PrestationStatus.NONE,
    };

    let cheaperVariant: ProductVariant | null = null;
    let cheapestProductVariant = selectedProduct.priceLine.cost;

    product.variants.forEach(variant => {
      if (
        variant.cost &&
        cheapestProductVariant &&
        variant.cost < cheapestProductVariant
      ) {
        cheaperVariant = variant;
        cheapestProductVariant = variant.cost;
      }
    });

    if (cheaperVariant) {
      products.unshift({
        ...selectedProduct,
        productObject: {
          ...selectedProduct.productObject,
          ...(cheaperVariant as ProductVariant),
          id: selectedProduct.productObject.id,
        },
      });
    } else {
      products.unshift(selectedProduct);
    }

    dispatch(
      setDealFields({
        [dealType]: { products },
      }),
    );
  },
);

export const updateProduct = createAsyncThunk(
  `${DEAL_PRODUCTS}/UPDATE`,
  async (productId: number, { dispatch, getState }) => {
    const updatedProduct = await fetchProduct(productId, dispatch);

    const state = getState() as AppStateSubset;
    const dealType = makeSelectDealType()(state) as DealType;
    const dealProducts = makeSelectProducts()(state as never);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const products = dealProducts.map((product: any) => {
      if (
        product.productObject.product_id !==
        updatedProduct.productObject.product_id
      )
        return product;
      const updatedVariants = [
        ...updatedProduct.variants,
        {
          ...updatedProduct.productObject,
          id: null,
        },
      ];
      return {
        ...product,
        variants: updatedVariants,
      };
    });

    dispatch(
      setDealFields({
        [dealType]: { products },
      }),
    );
  },
);
