import { authenticate, withSessionId } from '../../lib/decorators'
import { put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'

export class Default {
  @authenticate
  @withSessionId
  static update(id: number, value: boolean, force = false): ApiRequestDescriptor<void> {
    return put(`/api/v1/consulates/${id}/default`, { value, force })
  }
}
