import { ReactNode } from 'react';
import style from './Layout.module.scss';

interface ColumnGroupProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export default function ColumnGroup({
  children,
  className = '',
}: ColumnGroupProps): JSX.Element {
  const classes = [
    style.wrapper,
    style.group,
    'col-10',
    'col-xxl-11',
    className,
  ].filter(Boolean);

  return <div className={classes.join(' ')}>{children}</div>;
}
