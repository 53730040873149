import Spinner from './Spinner.js';
import style from './style.scss';

/**
 * Full page spinner
 */
export default function PageSpinner(): JSX.Element {
  return (
    <div className={style.page}>
      <Spinner />
    </div>
  );
}
