import { createSelector, Selector } from 'reselect';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';

import { TODO_LIST_BOOKING_MODAL } from './constants';
import { State, AppStateSubset } from './slice';

type TodolistSupplierBookingModalSelector<T> = Selector<AppStateSubset, T>;

const selectTodoListSupplierBookingModalDomain = (
  state: AppStateSubset,
): State => state[TODO_LIST_BOOKING_MODAL];

export const makeSelectBookingItem = (): TodolistSupplierBookingModalSelector<TodoItem | null> =>
  createSelector(
    selectTodoListSupplierBookingModalDomain,
    ({ bookingItem }) => bookingItem,
  );
