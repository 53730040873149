import { FormattedMessage } from 'react-intl';

import { Button, HiddenInput, FormUI, PhoneInput, Tooltip } from '@advitam/ui';
import PlusIcon from '@advitam/ui/images/icons/plus.svg';
import TrashIcon from '@advitam/ui/images/icons/trash.svg';

import { useDynamicFields } from './useDynamicFields';
import messages from './messages';
import style from './Contact.module.scss';
import { getFieldName } from '../utils';

const PHONE_FIELD = 'phone';
const FIELDS_COUNT = 3;

interface PhoneFieldsProps {
  prefix?: string;
}

export default function PhoneFields({
  prefix = '',
}: PhoneFieldsProps): JSX.Element {
  const {
    fields,
    canAddFields,
    canDeleteFields,
    addField,
    removeField,
  } = useDynamicFields(PHONE_FIELD, FIELDS_COUNT, prefix);

  // hiddenFields allow to keep the all fields subscribed
  const hiddenFieldsCount = FIELDS_COUNT - fields.length;

  return (
    <div className={style.dynamic_fields}>
      <FormUI.Label value={<FormattedMessage id={messages.phone.id} />} />
      {fields.map((field, idx) => (
        <div key={field} className={style.field}>
          <PhoneInput required={idx > 0} name={field} />
          <Tooltip
            contentClassName={`${style.action} ${style.delete}`}
            content={<FormattedMessage id={messages.phoneTooltipDelete.id} />}
          >
            <Button
              outline
              icon={<TrashIcon />}
              onClick={(): void => removeField(idx)}
              disabled={!canDeleteFields}
            />
          </Tooltip>
        </div>
      ))}
      {hiddenFieldsCount > 0 &&
        Array(hiddenFieldsCount)
          .fill(0)
          .map((_, idx) => {
            const name = `${getFieldName(prefix, PHONE_FIELD)}_${
              idx + fields.length + 1
            }`;
            return <HiddenInput name={name} key={name} />;
          })}
      {canAddFields && (
        <Tooltip
          contentClassName={`${style.action} ${style.add}`}
          content={<FormattedMessage id={messages.phoneTooltipAdd.id} />}
        >
          <Button outline icon={<PlusIcon />} onClick={addField} />
        </Tooltip>
      )}
    </div>
  );
}
