import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { del, get, post } from 'api/methods';
import { serialize } from 'api/serializers/DocumentTemplates';

import {
  DocumentTemplate,
  DocumentTemplateJSON,
} from 'models/DocumentTemplate';

export class DocumentTemplates {
  @authenticate
  static show<DataType = unknown>(
    id: number,
  ): ApiRequestDescriptor<DocumentTemplateJSON<DataType>> {
    return get(`/api/v1/document_templates/${id}`);
  }

  @authenticate
  static create<DataType = unknown>(
    tpl: DocumentTemplateJSON<DataType> | DocumentTemplate<DataType>,
  ): ApiRequestDescriptor<DocumentTemplateJSON<DataType>> {
    return post('/api/v1/document_templates', serialize<DataType>(tpl));
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/document_templates/${id}`);
  }
}
