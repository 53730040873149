import { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { MessageDescriptor } from 'react-intl';
import { useField } from 'react-form';

import { Switch } from 'components/Form';
import { SwitchElement } from 'components/Form/Switch';

interface SwitchInputProps {
  field: string;
  message: MessageDescriptor;
  tooltipMessage?: MessageDescriptor;
  onChange?: (ev: ChangeEvent<SwitchElement>) => void;
  className?: string;
}

export default function SwitchInput({
  field,
  className,
  message,
  tooltipMessage,
  onChange: superChange,
}: SwitchInputProps): JSX.Element {
  const { getInputProps } = useField(field);
  const { value, onChange: reactChange } = getInputProps();

  const onChange = async (ev: ChangeEvent<SwitchElement>): Promise<void> => {
    await reactChange(ev);
    if (superChange) {
      superChange(ev);
    }
  };

  return (
    <Switch
      onChange={onChange}
      value={value}
      name={field}
      message={message}
      tooltipMessage={tooltipMessage}
      className={className}
    />
  );
}

SwitchInput.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }).isRequired,
  tooltipMessage: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
};
