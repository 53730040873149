import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { CeremonyJSON } from '@advitam/api/models/Crematorium/Ceremony';
import { Layout } from 'containers/Crud';
import { assert } from 'lib/Assert';

import { makeSelectRawCrematorium } from '../../selectors';
import Wrapper from '../Wrapper';
import { CrematoriumForm } from '../types';
import { makeSelectCeremonies } from './selectors';
import { saveCeremonies } from './thunk';
import Contents from './Contents';
import slice from './slice';

function CeremoniesSection(): JSX.Element {
  const dispatch = useDispatch();

  const crematorium = useSelector(makeSelectRawCrematorium());
  assert(crematorium?.id !== undefined);
  const ceremonies = useSelector(makeSelectCeremonies());

  const onSubmit = useCallback(
    ({ sectionValues }: CrematoriumForm<CeremonyJSON[]>) => {
      dispatch(saveCeremonies(sectionValues));
    },
    [],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={ceremonies}>
      <Layout.Container>
        <Contents />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(slice)(CeremoniesSection);
