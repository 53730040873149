import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { request } from '@advitam/api';
import { UPDATE_PASSWORD } from './constants';
import { validateToken } from '../../../slices/auth/thunk';

interface UpdatePasswordArgs {
  config: string;
  password: string;
  token: string;
}

export const updatePassword = createAsyncThunk(
  `${UPDATE_PASSWORD}_UPDATE`,
  async (
    { config, password, token }: UpdatePasswordArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      await request(Api.V1.Pro.Auth.Password.getResetToken(token, config));
      await request(Api.V1.Pro.Auth.Password.update(password));

      await dispatch(validateToken());

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
