import { ReactNode, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsPopup } from 'containers/Crud';
import { assert } from 'lib/support';

import Sidebar from '../Sidebar';
import { makeSelectRawSupplier } from '../selectors';
import style from '../Supplier.module.scss';
import { SupplierForm } from './types';

interface WrapperProps<T> {
  children: ReactNode;
  initialSectionValues?: T;
  onSubmit: (values: SupplierForm<T>) => void;
}

export default function Wrapper<T>({
  children,
  initialSectionValues,
  onSubmit,
}: WrapperProps<T>): JSX.Element {
  const dispatch = useDispatch();

  const supplier = useSelector(makeSelectRawSupplier());
  assert(supplier !== null);

  const initialValues: Partial<SupplierForm<T>> = {
    supplier,
    sectionValues: initialSectionValues,
  };

  const handleFormSubmit = useCallback(
    (values: SupplierForm<T>): void => {
      onSubmit(values);
    },
    [onSubmit, dispatch],
  );

  return (
    <Form<SupplierForm<T>>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <Sidebar />
          {(dirty || !supplier.id) && <ActionsPopup isNew={!supplier.id} />}
          {children}
        </form>
      )}
    </Form>
  );
}
