import PropTypes from 'prop-types';
import MapIcon from 'components/Icons/MapIcon';
import { formatAddress } from 'components/GoogleAutocomplete/utils';

const LinkToMap = ({ address, className, name }) => {
  const formattedAddress = formatAddress(address);
  const query = encodeURIComponent(`${name || ''} ${formattedAddress}`);
  return (
    <a
      className={className}
      target="_blank"
      href={`https://www.google.com/maps/search/?api=1&query=${query}`}
    >
      <MapIcon />
    </a>
  );
};

LinkToMap.propTypes = {
  /** object containing latitude and longitude */
  address: PropTypes.object.isRequired,
  /** class name of component */
  className: PropTypes.string.isRequired,
  /** location name */
  name: PropTypes.string,
};

export default LinkToMap;
