import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { openWithCreation } from 'containers/Deal/SendBatchModal/thunk';
import { FullscreenModal } from 'components/Modal/index';
import Popup from 'components/Popup';
import { DealDocument } from 'models/Deal/Document';
import EditIcon from 'images/icons/pencil-outline.svg';

import messages from '../messages.js';
import { makeSelectSelectedDocuments } from '../selectors.js';
import {
  mergeFiles as mergeFilesAction,
  downloadFiles as downloadFilesAction,
  setSelectedDocuments,
  toggleDocumentSelection,
} from '../actions.js';

import DownloadModal from './DownloadModal/index.js';
import DocumentEditor from './DocumentEditor';
import { setIsLoading } from './DocumentEditor/slice';
import Icon from './Icon';
import Header from './Header';
import FileList from './FileList';
import Versions from './Versions';
import { MergeIcon, SendIcon, SignIcon } from './img';
import style from './style.scss';

interface ToolbarProps {
  dealUUID: string;
}

export default function Toolbar({ dealUUID }: ToolbarProps): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const toggleDownloadModal = (): void =>
    setDownloadModalIsOpen(!downloadModalIsOpen);

  const [isContentOpen, setisContentOpen] = useState(false);
  const toggleBox = (): void => setisContentOpen(!isContentOpen);

  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const toggleHistoryOpen = (): void => setHistoryOpen(!isHistoryOpen);

  const [documentsToEdit, setDocumentsToEdit] = useState<DealDocument[]>([]);
  const onDocumentEditionDone = useCallback(() => {
    setDocumentsToEdit(documentsToEdit.slice(1));
    if (documentsToEdit.length > 0) {
      dispatch(setIsLoading(true));
    }
  }, [dispatch, documentsToEdit, setDocumentsToEdit]);

  const files = useSelector(makeSelectSelectedDocuments()) as DealDocument[];
  const isHistoryPanelOpen = isHistoryOpen && files.length > 0;
  useEffect(() => {
    if (files.length === 0) {
      setHistoryOpen(false);
    }
  }, [files]);

  const downloadFile = (): void => {
    const filesId = [...files.map(file => file.id)];
    dispatch(downloadFilesAction(dealUUID, filesId));
    dispatch(setSelectedDocuments([]));
    toggleDownloadModal();
  };

  const openFile = (link: string | null): void => {
    if (link) {
      window.open(link);
    }
  };

  const mergeFiles = (filesToMerge: DealDocument[]): void => {
    dispatch(
      mergeFilesAction(
        filesToMerge.map(file => file.file),
        intl.formatMessage(messages.mergedFilename),
      ),
    );
  };

  const openBatchModal = (toSign: boolean): void => {
    dispatch(
      openWithCreation({ toSign, initialDocIds: files.map(doc => doc.id) }),
    );
  };

  const disableIcon = files.length !== 1;
  const noSelection = files.length === 0;
  const notSignableSelection =
    noSelection || files.some(f => !f.signed_document_type);

  return (
    <>
      <DownloadModal
        isOpen={downloadModalIsOpen}
        files={files}
        toggleModal={toggleDownloadModal}
        downloadFile={downloadFile}
      />
      <FullscreenModal isOpen={documentsToEdit.length !== 0}>
        <DocumentEditor
          onEditionDone={onDocumentEditionDone}
          document={documentsToEdit[0]}
        />
      </FullscreenModal>

      <div className={style.toolbar}>
        <Header
          isFileListOpen={isContentOpen}
          toggleFileListOpen={toggleBox}
          isHistoryOpen={isHistoryPanelOpen}
          fileCount={files.length}
        />
        <FileList
          isOpen={isContentOpen}
          documents={files}
          deselect={(doc: DealDocument): void => {
            dispatch(toggleDocumentSelection(doc));
          }}
          open={openFile}
        />
        {isHistoryPanelOpen && (
          <Popup
            onFocusOut={(): void => setHistoryOpen(false)}
            className={style['versions-container']}
            autoFocus
          >
            <Versions document={files[0]} />
          </Popup>
        )}

        <div className={style.actions}>
          <Icon
            onClick={toggleDownloadModal}
            disabled={noSelection}
            tooltipMessage={
              <FormattedMessage id={messages.tooltipDownload.id} />
            }
          >
            <i className="material-icons-outlined">save_alt</i>
          </Icon>

          <Icon
            onClick={(): void => openFile(files[0].file)}
            disabled={disableIcon || !files[0].file}
            tooltipMessage={
              <FormattedMessage id={messages.tooltipPreview.id} />
            }
          >
            <i className="material-icons-outlined">remove_red_eye</i>
          </Icon>

          <Icon
            onClick={(): void => toggleHistoryOpen()}
            disabled={disableIcon}
            tooltipMessage={
              <FormattedMessage id={messages.tooltipVersions.id} />
            }
          >
            <i className="material-icons">history</i>
          </Icon>

          <Icon
            onClick={(): void => mergeFiles(files)}
            disabled={noSelection}
            tooltipMessage={<FormattedMessage id={messages.tooltipMerge.id} />}
          >
            <MergeIcon />
          </Icon>

          <Icon
            onClick={(): void => openBatchModal(false)}
            disabled={noSelection}
            tooltipMessage={<FormattedMessage id={messages.tooltipSend.id} />}
          >
            <SendIcon />
          </Icon>

          <Icon
            onClick={(): void => setDocumentsToEdit(files)}
            disabled={noSelection}
            tooltipMessage={<FormattedMessage id={messages.tooltipEdit.id} />}
          >
            <EditIcon />
          </Icon>

          <Icon
            onClick={(): void => openBatchModal(true)}
            disabled={notSignableSelection}
            tooltipMessage={
              <FormattedMessage id={messages.tooltipSendForSignature.id} />
            }
          >
            <SignIcon />
          </Icon>
        </div>
      </div>
    </>
  );
}

Toolbar.propTypes = {
  dealUUID: PropTypes.string.isRequired,
};
