// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Graveyard-module__container--y6GKf{position:relative;width:100%;display:flex;flex-grow:1;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/containers/Graveyard/Graveyard.module.scss"],"names":[],"mappings":"AAAA,oCACE,iBAAA,CACA,UAAA,CACA,YAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".container {\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex-grow: 1;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Graveyard-module__container--y6GKf`
};
export default ___CSS_LOADER_EXPORT___;
