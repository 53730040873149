import { useSelector } from 'react-redux';

import { makeSelectWorshipTypes } from 'slices/data';
import { FormLayout, Select } from '@advitam/ui';
import { Layout } from 'containers/Crud';

import { FormattedMessage } from 'react-intl';

import messages from './messages';

export default function WorshipType(): JSX.Element {
  const worshipTypes = useSelector(makeSelectWorshipTypes());

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.worship.id} />}>
      <FormLayout.Row>
        <Select
          required
          label={<FormattedMessage id={messages.worshipType.id} />}
          name="worship.worship_type.id"
          items={worshipTypes.map(worshipType => ({
            value: worshipType.id,
            name: worshipType.name,
          }))}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
