import { CoffinWoodType } from './CoffinWoodType'
import { CeremonyType } from './Type'

export interface CeremonyJSON {
  id: number
  age_range_id: number
  room_id: number
  price: number
  reduced_price: number | null
  is_live: boolean
  default: boolean
  ceremony_type: CeremonyType
  coffin_wood_type: CoffinWoodType
  with_ceremony_master: boolean
  duration: number
  title: string
}

export { CeremonyType, CoffinWoodType }
