import type { ReactNode } from 'react'

import Text from '../Text'
import style from './Tag.module.scss'

interface TagProps {
  children: ReactNode
  className?: string
}

export default function Tag({ children, className = '' }: TagProps): JSX.Element {
  return (
    <Text small className={[style.tag, className].filter(Boolean).join(' ')}>
      {children}
    </Text>
  )
}
