import Api, { request } from '@advitam/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DEAL_REVIEWS } from './constants';

export const fetchReviews = createAsyncThunk(
  `${DEAL_REVIEWS}/FETCH`,
  async (dealUuid: string, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Deals.Reviews.index(dealUuid));
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
