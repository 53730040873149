import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ResourceListBreakpoint, WindowedResourceList } from '@advitam/ui';

import { makeSelectIsLoading } from '../selectors';
import messages from '../messages';
import ProductsFilters from './Filters';
import PhotoCell from './Cells/Photo';
import CategoryCell from './Cells/Category';
import AdvitamRefCell from './Cells/AdvitamRef';
import SupplierRefCell from './Cells/SupplierRef';
import DetailsCell from './Cells/Details';
import HermeticCell from './Cells/Hermetic';
import WindowCell from './Cells/Window';
import FunnelDefaultCell from './Cells/FunnelDefault';
import DefaultCell from './Cells/Default';
import CostCell from './Cells/Cost';
import UpdatedAtCell from './Cells/UpdatedAt';
import PriceCell from './Cells/Price';
import ActionsCell from './Cells/Actions';
import style from './List.module.scss';
import {
  useProductCount,
  useProductItemKeyGetter,
} from '../ProductListProvider';

const columns = [
  {
    size: 80,
    Cell: PhotoCell,
    Header: (): JSX.Element => <FormattedMessage id={messages.photo.id} />,
  },
  {
    onlyAbove: ResourceListBreakpoint.FHD,
    Cell: CategoryCell,
    Header: (): JSX.Element => <FormattedMessage id={messages.category.id} />,
  },
  {
    size: 136,
    Cell: AdvitamRefCell,
    Header: (): JSX.Element => <FormattedMessage id={messages.refAdvitam.id} />,
  },
  {
    size: 158,
    onlyAbove: ResourceListBreakpoint.DESKTOP,
    Cell: SupplierRefCell,
    Header: (): JSX.Element => (
      <FormattedMessage id={messages.refSupplier.id} />
    ),
  },
  {
    Cell: DetailsCell,
    onlyAbove: ResourceListBreakpoint.TABLET,
    Header: (): JSX.Element => <FormattedMessage id={messages.details.id} />,
  },
  {
    size: 128,
    onlyAbove: ResourceListBreakpoint.FHD,
    Cell: HermeticCell,
    Header: (): JSX.Element => <FormattedMessage id={messages.hermetic.id} />,
  },
  {
    size: 138,
    onlyAbove: ResourceListBreakpoint.FHD,
    Cell: WindowCell,
    Header: (): JSX.Element => <FormattedMessage id={messages.window.id} />,
  },
  {
    size: 170,
    onlyAbove: ResourceListBreakpoint.FHD,
    Cell: FunnelDefaultCell,
    Header: (): JSX.Element => (
      <FormattedMessage id={messages.defaultFunnel.id} />
    ),
  },
  {
    size: 164,
    onlyAbove: ResourceListBreakpoint.FHD,
    Cell: DefaultCell,
    Header: (): JSX.Element => (
      <FormattedMessage id={messages.defaultQuote.id} />
    ),
  },
  {
    size: 126,
    Cell: CostCell,
    Header: (): JSX.Element => <FormattedMessage id={messages.cost.id} />,
  },
  {
    size: 126,
    Cell: PriceCell,
    Header: (): JSX.Element => <FormattedMessage id={messages.price.id} />,
  },
  {
    size: 126,
    Cell: UpdatedAtCell,
    Header: (): JSX.Element => <FormattedMessage id={messages.updatedAt.id} />,
  },
  {
    size: 92,
    Cell: ActionsCell,
    Header: (): JSX.Element => <FormattedMessage id={messages.actions.id} />,
  },
];

export default function ProductList(): JSX.Element {
  const isLoading = useSelector(makeSelectIsLoading());
  const itemCount = useProductCount();
  const getItemKey = useProductItemKeyGetter();

  return (
    <WindowedResourceList.Container
      filters={<ProductsFilters />}
      itemCount={itemCount}
      desktopRowHeight={60}
      mobileRowHeight={88}
      isLoading={isLoading}
      columns={columns}
      className={style.container}
      itemKey={getItemKey}
      overscanCount={10}
    />
  );
}
