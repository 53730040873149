import { useEffect } from 'react';
import { useDeepCompareMemoize } from './useDeepCompareMemoize';

type UseEffectParams = Parameters<typeof useEffect>;
type UseEffectResult = ReturnType<typeof useEffect>;
type Effect = UseEffectParams[0];
type DependencyList = UseEffectParams[1];

export function useDeepCompareEffect(
  effect: Effect,
  deps: DependencyList,
): UseEffectResult {
  return useEffect(effect, useDeepCompareMemoize(deps));
}
