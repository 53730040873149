import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FormUI } from '@advitam/ui';

import { makeSelectCommentary } from './selectors';
import { updateCommentary } from './thunk';
import messages from './messages';

export default function StandaloneCommentaries(): JSX.Element {
  const dispatch = useDispatch();
  const commentary = useSelector(makeSelectCommentary());
  const [commentaryValue, setCommentaryValue] = useState(commentary);

  useEffect(() => {
    setCommentaryValue(commentary);
  }, [commentary]);

  const onChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>): void => {
      setCommentaryValue(value);
    },
    [setCommentaryValue],
  );

  const onBlur = useCallback((): void => {
    if (commentary !== commentaryValue) {
      dispatch(updateCommentary(commentaryValue));
    }
  }, [dispatch, commentary, commentaryValue]);

  return (
    <FormUI.TextArea
      label={<FormattedMessage id={messages.advitam.id} />}
      value={commentaryValue || ''}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}
