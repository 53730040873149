import { ReactNode, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, useForm, useFormState } from 'react-final-form';
import { get } from 'lodash';

import Api from '@advitam/api';
import { AutocompleteResult } from '@advitam/api/v1/Autocompletes';
import { MinimalCityhallJSON } from '@advitam/api/models/Cityhall';
import { Button, FormUI, ActionTag } from '@advitam/ui';
import CrossIcon from '@advitam/ui/images/icons/times.svg';

import actionMessages from 'messages/actions';

import messages from './messages';
import style from './Inputs.module.scss';

const CITYHALLS_PER_PAGE = 20;

function serializeResult(result: AutocompleteResult): MinimalCityhallJSON {
  const { id, description } = result;
  return { id, name: description };
}

interface SelectableCityhallsProps {
  name: string;
  label?: ReactNode;
  tooltip?: ReactNode;
}

export default function SelectableCityhalls({
  name,
  label,
  tooltip,
}: SelectableCityhallsProps): JSX.Element {
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);

  const form = useForm();
  const { values } = useFormState();
  const cityhalls = get(values, name) as MinimalCityhallJSON[];

  const displayedCityhalls = cityhalls.slice(
    0,
    CITYHALLS_PER_PAGE * currentPage,
  );

  const resetCityhalls = useCallback(() => {
    form.change(name, []);
  }, [form]);

  const removeCityhall = useCallback(
    (cityhall: MinimalCityhallJSON) => {
      const newCityhalls = cityhalls.filter(ch => ch.id !== cityhall.id);
      form.change(name, newCityhalls);
    },
    [form, cityhalls],
  );

  const addCityhall = useCallback(
    (cityhall: MinimalCityhallJSON) => {
      const newCityhalls = [...cityhalls, cityhall];
      form.change(name, newCityhalls);
    },
    [form, cityhalls],
  );

  const onCityhallSelect = useCallback(
    (result: AutocompleteResult | undefined) => {
      const existingCityhall = cityhalls.find(ch => ch.id === result?.id);
      if (result && !existingCityhall) {
        addCityhall(serializeResult(result));
      }
    },
    [addCityhall, serializeResult, cityhalls],
  );

  return (
    <Field name={name}>
      {({ input }): JSX.Element => (
        <>
          <input type="hidden" name={input.name} />
          <FormUI.Autosuggest
            // permits search value reset on suggestion selection
            value={{ id: -1, description: '' }}
            label={label}
            tooltip={tooltip}
            onChange={onCityhallSelect}
            endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls)}
            placeholder={intl.formatMessage(actionMessages.search)}
          />
          <div>
            {displayedCityhalls.map(cityhall => (
              <ActionTag
                key={cityhall.id}
                icon={<CrossIcon />}
                onClick={(): void => removeCityhall(cityhall)}
                className={style.selectable_cityhalls_item}
              >
                {cityhall.name}
              </ActionTag>
            ))}
          </div>
          <div className={style.selectable_cityhalls_actions}>
            <Button
              outline
              onClick={resetCityhalls}
              text={<FormattedMessage id={actionMessages.reset.id} />}
              disabled={cityhalls.length === 0}
            />
            <Button
              outline
              onClick={(): void => setCurrentPage(currentPage + 1)}
              text={<FormattedMessage id={messages.displayMore.id} />}
              disabled={displayedCityhalls.length >= cityhalls.length}
            />
          </div>
        </>
      )}
    </Field>
  );
}
