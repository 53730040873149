import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@advitam/ui';
import Ring from '@advitam/ui/images/illustrations/ring.svg';

import DefunctParent from './DefunctParent';
import messages from './messages';
import style from './Form.module.scss';

export default function DefunctPartner(): JSX.Element {
  return (
    <DefunctParent
      prefix="partner"
      pictogram={
        <Tooltip
          contentClassName={style.section_pictogram}
          content={<FormattedMessage id={messages.partnerTooltip.id} />}
        >
          <Ring />
        </Tooltip>
      }
    />
  );
}
