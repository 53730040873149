import { InputHTMLAttributes, ReactNode } from 'react'
import { Field, UseFieldConfig } from 'react-final-form'

import Radio from '../UI/Radio'
import { composeValidators, isRequired, Validator } from '../validators'

interface DefaultProps {
  label: ReactNode
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: UseFieldConfig<any>['format']
  required?: boolean
  validate?: Validator<InputHTMLAttributes<HTMLInputElement>['value']>
  borderless?: boolean
  className?: string
}

export default function Default({
  label,
  name,
  value,
  parse,
  format,
  required,
  validate,
  borderless,
  className,
}: DefaultProps): JSX.Element {
  const validates = []
  if (required) validates.push(isRequired)
  if (validate) validates.push(validate)
  return (
    <Field
      name={name}
      type="radio"
      value={value}
      parse={parse}
      format={format}
      validate={composeValidators<InputHTMLAttributes<HTMLInputElement>['value']>(...validates)}
    >
      {({ input: { onChange, checked }, meta: { touched, error } }): JSX.Element => (
        <Radio.Default
          name={name}
          label={label}
          onChange={onChange}
          value={value}
          checked={checked}
          error={Boolean(touched && error)}
          borderless={borderless}
          className={className}
        />
      )}
    </Field>
  )
}
