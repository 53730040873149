/*
 *
 * AutoComplete actions
 *
 */

import {
  QUERY_ENTITIES_LIST,
  ERASE_ENTITIES_LIST,
  UPDATE_ENTITIES_LIST,
  ERROR_ENTITIES_LIST,
} from './constants';

/**
 * Query entities list
 *
 * @param      {string}    url            The url to fetch data
 * @param      {function}  parseResponse  A function to format the result of the
 *                                        query before it is displayed
 * @param      {boolean}   auth           Does the request need authorization
 *                                        headers to be sent
 * @return     {object}    An action object with a type of QUERY_ENTITIES_LIST
 */
export function getEntitiesList(url, parseResponse, auth) {
  return {
    type: QUERY_ENTITIES_LIST,
    url,
    parseResponse,
    auth,
  };
}

/**
 * Erase entities list
 *
 * @return     {object}  Asks the reducer to delete predictions
 */
export function eraseEntitiesList() {
  return {
    type: ERASE_ENTITIES_LIST,
  };
}

/**
 * Updates entities value in store
 *
 * @param      {array}   entities  An array of prediction (formatted by the
 *                                 parseResponse function if any)
 * @return     {object}  An action object with a type of UPDATE_ENTITIES_LIST
 */
export function updateEntitiesList(entities) {
  return {
    type: UPDATE_ENTITIES_LIST,
    entities,
  };
}

/**
 * Set error value if get entities failed
 *
 * @param      {error}   error   Contains the value of the error and the status
 *                               code returned by the API
 * @return     {object}  An action object with a type of ERROR_ENTITIES_LIST
 */
export function errorEntitiesList(error) {
  return {
    type: ERROR_ENTITIES_LIST,
    error,
  };
}
