import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiError, SerializedApiError } from '@advitam/api'
import { assert } from '@advitam/support'

import { PROF } from './constants'
import { MessageAuthor, Message, ChatbotAnswerSource } from './types'
import { askQuestion, createConversation, setFeedback } from './thunk'
import { lastChatbotMessage, messageByRunId } from './utils'

export interface State {
  conversationUuid: string | null
  messages: Message[]
  hasUserMessage: boolean
  questionSuggestions: string[]
  isAnswerLoading: boolean
  isTokenLoading: boolean
  error: SerializedApiError | null
  loadingFeedbacks: string[]
}

export interface AppStateSubset {
  [PROF]: State
}

const initialState: State = {
  conversationUuid: null,
  messages: [],
  hasUserMessage: false,
  questionSuggestions: [],
  isAnswerLoading: false,
  isTokenLoading: false,
  error: null,
  loadingFeedbacks: [],
}

const slice = createSlice({
  name: PROF,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    newToken: (state, { payload }: PayloadAction<string>) => {
      lastChatbotMessage(state.messages).message.answer += payload
      state.isTokenLoading = true
    },
    setSources: (state, { payload }: PayloadAction<ChatbotAnswerSource[]>) => {
      lastChatbotMessage(state.messages).message.sources = payload
    },
    setRunId: (state, { payload }: PayloadAction<string | null>) => {
      lastChatbotMessage(state.messages).message.runId = payload
    },
    setError: (state, { payload }: PayloadAction<string[]>) => {
      state.error = {
        body: { errors: payload },
        errorCodes: payload,
        status: 422,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(createConversation.pending, state => {
      state.messages = []
      state.hasUserMessage = false
      state.isAnswerLoading = true
      state.error = null
    })
    builder.addCase(createConversation.fulfilled, (state, { payload, meta }) => {
      if (!meta.arg.initialQuestion) {
        state.isAnswerLoading = false
      }
      state.conversationUuid = payload.uuid
      if (payload.welcome_message) {
        state.messages.push({
          author: MessageAuthor.CHATBOT,
          message: {
            answer: payload.welcome_message,
            runId: null,
            useful: null,
            sources: [],
          },
        })
      }
      state.questionSuggestions = payload.question_suggestions
    })
    builder.addCase(createConversation.rejected, (state, { payload }) => {
      state.isAnswerLoading = false
      state.error = ApiError.serialize(payload)
    })

    builder.addCase(askQuestion.pending, (state, { meta }) => {
      if (meta.arg.showQuestion !== false) {
        state.messages.push({
          author: MessageAuthor.USER,
          message: {
            answer: meta.arg.question,
            runId: null,
            useful: null,
            sources: [],
          },
        })
      }
      state.hasUserMessage = true
      state.isAnswerLoading = true
      state.error = null
      state.questionSuggestions = []
    })
    builder.addCase(askQuestion.fulfilled, state => {
      state.isAnswerLoading = false
      state.isTokenLoading = false
    })
    builder.addCase(askQuestion.rejected, (state, { payload }) => {
      state.isAnswerLoading = false
      state.isTokenLoading = false
      state.error = ApiError.serialize(payload)
    })

    builder.addCase(setFeedback.pending, (state, { meta }) => {
      assert(meta.arg.message.message.runId !== null)
      state.loadingFeedbacks.push(meta.arg.message.message.runId)
    })
    builder.addCase(setFeedback.fulfilled, (state, { meta }) => {
      assert(meta.arg.message.message.runId !== null)
      const message = messageByRunId(state.messages, meta.arg.message.message.runId)
      if (message.message.useful === meta.arg.useful) {
        message.message.useful = null
      } else {
        message.message.useful = meta.arg.useful
      }
      state.loadingFeedbacks = state.loadingFeedbacks.filter(
        runId => runId !== meta.arg.message.message.runId,
      )
    })
    builder.addCase(setFeedback.rejected, (state, { meta, payload }) => {
      state.error = ApiError.serialize(payload)
      state.loadingFeedbacks = state.loadingFeedbacks.filter(
        runId => runId !== meta.arg.message.message.runId,
      )
    })
    /* eslint-enable no-param-reassign */
  },
})

export const { newToken, setError, setRunId, setSources } = slice.actions
export default slice
