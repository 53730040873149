import { defineMessages } from 'react-intl';

export default defineMessages({
  outstandingsTotal: {
    id: 'app.containers.Payments.outstandingsTotal',
  },
  risk: { id: 'app.containers.Payments.risk' },
  status: { id: 'app.containers.Payments.status' },
  name: { id: 'app.containers.Payments.name' },
  invoice: { id: 'app.containers.Payments.invoice' },
  date: { id: 'app.containers.Payments.date' },
  advisor: { id: 'app.containers.Payments.advisor' },
  stillToPay: { id: 'app.containers.Payments.stillToPay' },
  fundingAgency: { id: 'app.containers.Payments.fundingAgency' },
  payments: { id: 'app.containers.Payments.payments' },
  comment: { id: 'app.containers.Payments.comment' },
  paidAt: { id: 'app.containers.Payments.paidAt' },
  paid: { id: 'app.containers.Payments.paid' },
  notPaid: { id: 'app.containers.Payments.notPaid' },
  reimbursed: { id: 'app.containers.Payments.reimbursed' },
  reimbursedAt: { id: 'app.containers.Payments.reimbursedAt' },
  rejected: { id: 'app.containers.Payments.rejected' },
  cancelled: { id: 'app.containers.Payments.cancelled' },
  caution: { id: 'app.containers.Payments.caution' },
  waiting: { id: 'app.containers.Payments.waiting' },
  receivedStatus: { id: 'app.containers.Payments.receivedStatus' },
  received: { id: 'app.containers.Payments.received' },
  notReceived: { id: 'app.containers.Payments.notReceived' },
  lost: { id: 'app.containers.Payments.lost' },
});
