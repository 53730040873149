import { useDispatch } from 'react-redux';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';

import { ButtonV2 as Button } from 'components/Button';

import { createAccount } from '../../thunk';
import TaskRow from './Row';
import Label from './Label';

interface AccountCreationProps {
  item: TodoItem;
}

export default function AccountCreationTask({
  item,
}: AccountCreationProps): JSX.Element {
  const dispatch = useDispatch();

  return (
    <TaskRow item={item}>
      <Button
        className="button--nothing"
        onClick={(): void => {
          dispatch(createAccount());
        }}
      >
        <Label title={item.title} />
      </Button>
    </TaskRow>
  );
}
