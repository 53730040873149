import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, useForm, useFormState } from 'react-final-form';
import { get } from 'lodash';

import Api from '@advitam/api';
import {
  CoverageJSON,
  CoverageZoneType,
} from '@advitam/api/models/Entity/Coverage';
import { Model } from '@advitam/api/models/Model';
import { getTokens } from '@advitam/api/lib/tokens';
import type {
  AutocompleteDepartmentsResult,
  AutocompleteResult,
} from '@advitam/api/v1/Autocompletes';
import { Button, FormLayout, FormUI, ActionTag } from '@advitam/ui';
import CrossIcon from '@advitam/ui/images/icons/times.svg';
import { assert } from 'lib/support';
import actionMessages from 'messages/actions';

import messages from './messages';
import style from './Inputs.module.scss';

const ZONE_PER_PAGE = 20;
type NewCoverage = Omit<CoverageJSON, 'id'> & { id?: undefined };

function serializeCityhallResult(result: AutocompleteResult): NewCoverage {
  return {
    zone_id: result.id,
    zone_name: result.description,
    zone_type: CoverageZoneType.CITYHALL,
  };
}

function serializeDepartmentResult(
  result: AutocompleteDepartmentsResult,
): NewCoverage {
  return {
    zone_id: result.id,
    zone_name: `${result.description} (${result.department_code})`,
    zone_type: CoverageZoneType.DEPARMENT,
  };
}

interface CoverageProps {
  name: string;
}

export default function Coverage({ name }: CoverageProps): JSX.Element {
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const [zoneType, setZoneType] = useState(CoverageZoneType.CITYHALL);

  const form = useForm();
  const { values } = useFormState();
  const zones = get(values, name) as Array<CoverageJSON | NewCoverage>;

  const displayedZones = zones
    .filter(zone => !Model.isDestroyed(zone))
    .slice(0, ZONE_PER_PAGE * currentPage);

  const onZoneTypeChange = useCallback(
    (value: undefined | CoverageZoneType | CoverageZoneType[]) => {
      assert(!Array.isArray(value) && value !== undefined);
      setZoneType(value);
    },
    [setZoneType],
  );

  const reset = useCallback(() => {
    form.change(name, []);
  }, [form, name]);

  const showMore = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [setCurrentPage, currentPage]);

  const remove = useCallback(
    (zone: CoverageJSON | NewCoverage) => {
      let newZones = [...zones];
      if (zone.id === undefined) {
        newZones = zones.filter(z => z.id !== zone.id);
      } else {
        const zoneIndex = newZones.findIndex(
          z => z.zone_id === zone.zone_id && z.zone_type === zone.zone_type,
        );
        const duplicate = { ...zones[zoneIndex] };
        Model.setDestroyed(duplicate);
        newZones[zoneIndex] = duplicate;
      }
      form.change(name, newZones);
    },
    [form, name, zones],
  );

  const add = useCallback(
    (zone: CoverageJSON | NewCoverage) => {
      form.change(name, [...zones, zone]);
    },
    [form, name, zones],
  );

  const onCityhallSelect = useCallback(
    (result: AutocompleteResult | undefined) => {
      const existing = zones.find(zone => zone.id === result?.id);
      if (result && !existing) {
        add(serializeCityhallResult(result));
      }
    },
    [add, zones],
  );

  const onDepartmentSelect = useCallback(
    (result: AutocompleteDepartmentsResult | undefined) => {
      const existing = zones.find(zone => zone.id === result?.id);
      if (result && !existing) {
        add(serializeDepartmentResult(result));
      }
    },
    [add, zones],
  );

  return (
    <Field name={name}>
      {(): JSX.Element => (
        <>
          <FormLayout.Row className={style.coverage_inputs}>
            <FormUI.Select
              label={<FormattedMessage id={messages.zoneType.id} />}
              value={zoneType}
              onChange={onZoneTypeChange}
              items={[
                {
                  name: intl.formatMessage(messages.zoneTypeCityhall),
                  value: CoverageZoneType.CITYHALL,
                },
                {
                  name: intl.formatMessage(messages.zoneTypeDepartment),
                  value: CoverageZoneType.DEPARMENT,
                },
              ]}
            />
            {zoneType === CoverageZoneType.CITYHALL && (
              <FormUI.Autosuggest
                label={<FormattedMessage id={messages.zone.id} />}
                onChange={onCityhallSelect}
                endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls)}
                placeholder={intl.formatMessage(actionMessages.search)}
              />
            )}
            {zoneType === CoverageZoneType.DEPARMENT && (
              <FormUI.Autosuggest
                label={<FormattedMessage id={messages.zone.id} />}
                onChange={onDepartmentSelect}
                endpoint={Api.V1.absolute(
                  Api.V1.Autocompletes.Path.departments,
                )}
                requestHeaders={{ ...getTokens() }}
                placeholder={intl.formatMessage(actionMessages.search)}
              />
            )}
          </FormLayout.Row>
          <div>
            {displayedZones.map(zone => (
              <ActionTag
                key={zone.id}
                icon={<CrossIcon />}
                onClick={(): void => remove(zone)}
                className={style.selectable_cityhalls_item}
              >
                {zone.zone_name}
              </ActionTag>
            ))}
          </div>
          <div className={style.selectable_cityhalls_actions}>
            <Button
              outline
              onClick={reset}
              text={<FormattedMessage id={actionMessages.reset.id} />}
              disabled={zones.length === 0}
            />
            <Button
              outline
              onClick={showMore}
              text={<FormattedMessage id={messages.displayMore.id} />}
              disabled={displayedZones.length >= zones.length}
            />
          </div>
        </>
      )}
    </Field>
  );
}
