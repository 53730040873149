// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__errored--b6CDa{box-shadow:inset 0 0 2px 1px #dc3545}`, "",{"version":3,"sources":["webpack://./src/containers/AutoComplete/style.scss"],"names":[],"mappings":"AAEA,uBACE,oCAAA","sourcesContent":["@import 'src/scss/mixins/vars';\n\n.errored {\n  box-shadow: inset 0 0 2px 1px $failure;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errored": `style__errored--b6CDa`
};
export default ___CSS_LOADER_EXPORT___;
