import { ReactNode, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsPopup } from 'containers/Crud';
import { assert } from 'lib/support';

import Sidebar from '../Sidebar';
import { makeSelectRawPolice } from '../selectors';
import { updatePoliceName } from '../thunk';
import type { PoliceForm } from './types';
import style from '../Police.module.scss';

interface WrapperProps<T> {
  children: ReactNode;
  initialSectionValues?: T;
  onSubmit: (values: PoliceForm<T>) => void;
}

export default function Wrapper<T>({
  children,
  initialSectionValues,
  onSubmit,
}: WrapperProps<T>): JSX.Element {
  const dispatch = useDispatch();

  const police = useSelector(makeSelectRawPolice());
  assert(police !== null);

  const initialValues: Partial<PoliceForm<T>> = {
    police,
    sectionValues: initialSectionValues,
  };

  const handleFormSubmit = useCallback(
    (values: PoliceForm<T>): void => {
      if (values.sectionValues && police.name !== values.police.name) {
        dispatch(updatePoliceName(values.police.name));
      }

      onSubmit(values);
    },
    [onSubmit, police, dispatch],
  );

  return (
    <Form<PoliceForm<T>>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <Sidebar />
          {(dirty || !police.id) && <ActionsPopup isNew={!police.id} />}
          {children}
        </form>
      )}
    </Form>
  );
}
