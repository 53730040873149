import { ChangeEvent, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';

import { CoverageJSON } from '@advitam/api/models/Entity/Coverage';
import {
  Button,
  Collapsible,
  FormLayout,
  FormUI,
  Tag,
  ActionTag,
  Text,
} from '@advitam/ui';
import CrossIcon from '@advitam/ui/images/icons/times.svg';
import actionsMessages from 'messages/actions';

import { makeSelectCanEdit } from '../../../../selectors';
import messages from '../messages';
import { UnsavedCoverage, WarehouseZonesForm } from '../types';
import style from './Zone.module.scss';

const COVERED_PER_PAGE = 20;
const INITIAL_PAGE = 1;

interface CoveredZoneProps {
  zoneIndex: number;
}

export default function CoveredZone({
  zoneIndex,
}: CoveredZoneProps): JSX.Element {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
  const canUserEdit = useSelector(makeSelectCanEdit());

  const form = useForm<Record<string, Array<CoverageJSON | UnsavedCoverage>>>();
  const { values } = useFormState<WarehouseZonesForm>();
  const { coverage } = values.sectionValues[zoneIndex];
  const coveragePrefix = `sectionValues[${zoneIndex}].coverage`;

  const fuse = new Fuse(coverage, { keys: ['zone_name'] });
  const filteredCoverage = searchValue
    ? fuse.search(searchValue).map(result => result.item)
    : coverage;

  const displayedItemsCount = COVERED_PER_PAGE * currentPage;
  const paginatedCoverage = filteredCoverage.slice(0, displayedItemsCount);
  const hasMorePages = paginatedCoverage.length < filteredCoverage.length;

  const onSearchChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(value);
    setCurrentPage(INITIAL_PAGE);
  };

  const onCoverageReset = (): void => {
    form.change(coveragePrefix, []);
    setSearchValue('');
    setCurrentPage(INITIAL_PAGE);
  };

  const onZoneRemove = (zone: CoverageJSON | UnsavedCoverage): void => {
    const updatedCoverage = coverage.filter(c => c.zone_id !== zone.zone_id);
    form.change(coveragePrefix, updatedCoverage);
  };

  return (
    <Collapsible
      borderless
      title={
        <Text>
          <FormattedMessage id={messages.coveredZone.id} />
        </Text>
      }
    >
      <FormLayout.Row>
        <FormUI.Input
          value={searchValue}
          onChange={onSearchChange}
          placeholder={intl.formatMessage(actionsMessages.search)}
        />
      </FormLayout.Row>
      <FormLayout.Row className={style.actions_row}>
        <Button
          outline
          onClick={onCoverageReset}
          text={<FormattedMessage id={actionsMessages.reset.id} />}
          disabled={!canUserEdit || coverage.length === 0}
        />
        <Button
          outline
          onClick={(): void => setCurrentPage(currentPage + 1)}
          text={<FormattedMessage id={messages.displayMore.id} />}
          disabled={!hasMorePages}
        />
      </FormLayout.Row>
      <div>
        {paginatedCoverage.map(zone =>
          canUserEdit ? (
            <ActionTag
              key={zone.zone_id}
              icon={<CrossIcon />}
              className={style.coverage_item}
              onClick={(): void => onZoneRemove(zone)}
            >
              {zone.zone_name}
            </ActionTag>
          ) : (
            <Tag key={zone.zone_id} className={style.coverage_item}>
              {zone.zone_name}
            </Tag>
          ),
        )}
        {hasMorePages && (
          <Tag className={style.coverage_item}>
            <b>+ {filteredCoverage.length - paginatedCoverage.length}</b>
          </Tag>
        )}
      </div>
    </Collapsible>
  );
}
