import PropTypes from 'prop-types';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ErrorModal from 'components/ErrorModal';
import { TextArea, TextInput } from 'components/Input';
import { makeSelectDealState } from 'containers/Deal/selectors';
import { DealState } from 'models/Deal/State.ts';

import {
  makeSelectDeliveryLocations,
  makeSelectDelivery,
  makeSelectDeliveryLocationError,
} from '../selectors';

import { setDeliveryLocation, setDeliveryField } from './actions';
import messages from './messages';

import style from './style.scss';

export default function DeliverySection({ delivery }) {
  const dispatch = useDispatch();

  const dealState = useSelector(makeSelectDealState());
  const deliveryLocations = useSelector(makeSelectDeliveryLocations());
  const selectedStep = useSelector(makeSelectDelivery());
  const deliveryLocationError = useSelector(makeSelectDeliveryLocationError());

  const setField = (key, { target: { value } }) =>
    dispatch(setDeliveryField(key, value));

  const isReadonly = dealState === DealState.DONE;
  const computeDeliveryAddress = deliveryToCompute =>
    deliveryToCompute &&
    deliveryToCompute.location &&
    deliveryToCompute.location.name &&
    deliveryToCompute.location.address &&
    [
      deliveryToCompute.location.name,
      deliveryToCompute.location.address,
      deliveryToCompute.location.city,
      deliveryToCompute.location.postal_code,
      deliveryToCompute.date
        ? moment.utc(deliveryToCompute.date).format('[le] DD.MM.YYYY à HH[h]mm')
        : '',
    ].join(' ');

  const deliveryStep = deliveryLocations.find(
    event =>
      delivery.step_type === event.source && delivery.step_id === event.id,
  );

  return (
    <section>
      {deliveryLocationError && (
        <ErrorModal error={{ status: 'deliveryLocationError' }} />
      )}
      <FormattedMessage id={messages.deliverySteps.id} tagName="h2" />
      {(!isReadonly || deliveryLocationError) &&
        deliveryLocations.map(event => (
          <div key={`${event.source}-${event.id}`}>
            <label
              htmlFor={`${event.source}-${event.id}`}
              className={style.label}
            >
              <input
                type="radio"
                name="deliverySteps"
                id={`${event.source}-${event.id}`}
                checked={
                  event.source === selectedStep.step_type &&
                  event.id === selectedStep.step_id
                }
                onChange={() => dispatch(setDeliveryLocation(event))}
              />
              {messages[`${event.type}-${event.location.type}`] ? (
                <FormattedMessage
                  {...messages[`${event.type}-${event.location.type}`]}
                  values={{ formattedAddress: computeDeliveryAddress(event) }}
                />
              ) : (
                computeDeliveryAddress(event)
              )}
            </label>
          </div>
        ))}
      <div className={style['delivery-message']}>
        {deliveryStep &&
          (messages[`${deliveryStep.type}-${deliveryStep.location.type}`] ? (
            <FormattedMessage
              tagName="span"
              {...messages[
                `${deliveryStep.type}-${deliveryStep.location.type}`
              ]}
              values={{
                formattedAddress: computeDeliveryAddress(deliveryStep),
              }}
            />
          ) : (
            <span>{computeDeliveryAddress(deliveryStep)}</span>
          ))}

        <FormattedMessage {...messages.deliveryMessage} />
        <TextArea
          value={(delivery && delivery.message) || ''}
          maxLength="250"
          rows={5}
          onChange={ev => setField('message', ev)}
          readOnly={isReadonly}
        />

        <FormattedMessage {...messages.signature} />
        <TextInput
          value={(delivery && delivery.signature) || ''}
          maxLength="250"
          onChange={ev => setField('signature', ev)}
          readOnly={isReadonly}
        />
      </div>
    </section>
  );
}

DeliverySection.propTypes = {
  delivery: PropTypes.object,
};
