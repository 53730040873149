import { authenticate, withSessionId } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { SupplierJSON } from '../../models/Supplier'
import type { SupplierSummaryJSON } from '../../models/Supplier/Summary'
import { Coverage } from './Coverage'
import { Disabled } from './Disabled'
import { Warehouses } from './Warehouses'
import type { SupplierBody } from './types'
import { serialize } from './serializers'

export interface SuppliersIndexFilters {
  q?: string
  department_eq?: string
  negociated_eq?: boolean
  page?: number
  per_page?: number
  prestation_types_overlaps?: string[]
}

export const Suppliers = {
  Coverage,
  Disabled,
  Warehouses,

  create: authenticate(
    (supplier: Omit<SupplierBody, 'id'>): ApiRequestDescriptor<SupplierJSON> =>
      post('/api/v1/suppliers', serialize(supplier)),
  ),

  index: authenticate(
    (filters: SuppliersIndexFilters): ApiRequestDescriptor<SupplierSummaryJSON[]> =>
      get('/api/v1/suppliers', filters),
  ),

  show: authenticate(
    (id: number): ApiRequestDescriptor<SupplierJSON> => get(`/api/v1/suppliers/${id}`),
  ),

  update: authenticate(
    withSessionId(
      (supplier: SupplierBody): ApiRequestDescriptor<SupplierJSON> =>
        put(`/api/v1/suppliers/${supplier.id}`, serialize(supplier)),
    ),
  ),

  destroy: authenticate(
    withSessionId((id: number): ApiRequestDescriptor<void> => del(`/api/v1/suppliers/${id}`)),
  ),
}
