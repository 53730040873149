import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { requestAsync } from 'api';
import { PaymentProblem } from 'models/Deal/PaymentProblem';
import { PaymentJSON } from 'models/Payment';

import { fetchItems } from '../Sections/Todolist/thunk';
import { AppStateSubset as DealAppStateSubset } from '../slice';
import { AppStateSubset as PaymentsAppStateSubset } from './slice';
import { PAYMENTS_SPACE } from './constants';
import { makeSelectPayments, makeSelectPaymentsToRemove } from './selectors';

type AppStateSubset = DealAppStateSubset & PaymentsAppStateSubset;

export function consolidatePayment(payment: PaymentJSON): PaymentJSON {
  if (payment.is_company) {
    return {
      ...payment,
      first_name: null,
      last_name: null,
      civ: null,
    };
  }

  return {
    ...payment,
    company_name: null,
  };
}

export const fetchPayments = createAsyncThunk(
  `${PAYMENTS_SPACE}/FETCH_PAYMENTS`,
  async (uuid: string, { rejectWithValue }) => {
    try {
      const { body } = await requestAsync(Api.V1.Deals.Payments.show(uuid));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchPaymentsDetails = createAsyncThunk(
  `${PAYMENTS_SPACE}/FETCH_PAYMENTS_DETAILS`,
  async (uuid: string, { dispatch }) => {
    await dispatch(fetchPayments(uuid));
  },
);

export const postPayments = createAsyncThunk(
  `${PAYMENTS_SPACE}/POST_PAYMENTS`,
  async (uuid: string, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const payments = makeSelectPayments()(state);
    const paymentsToRemove = makeSelectPaymentsToRemove()(state);

    try {
      const { body } = await requestAsync(
        Api.V1.Deals.Payments.update(uuid, {
          payments: payments.map(consolidatePayment),
          deleted: paymentsToRemove,
        }),
      );

      await dispatch(fetchItems());

      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

interface UpdatePaymentProblemArgs {
  uuid: string;
  paymentProblem: PaymentProblem;
}

export const updatePaymentProblem = createAsyncThunk(
  `${PAYMENTS_SPACE}/UPDATE_PAYMENT_PROBLEM`,
  async (payload: UpdatePaymentProblemArgs, { rejectWithValue }) => {
    try {
      const { uuid, paymentProblem } = payload;

      await requestAsync(
        Api.V1.Deals.PaymentProblem.update(uuid, paymentProblem),
      );

      return payload.paymentProblem;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
