import { injectIntl } from 'react-intl';

import Image from 'images/image.svg';

import ResizableInput from './Resizable';
import style from '../Engine.module.scss';

class SignatureInput extends ResizableInput<undefined> {
  // eslint-disable-next-line class-methods-use-this
  renderInput(): JSX.Element | null {
    const { isEditMode, isPreviewMode } = this.props;
    if (!isEditMode || isPreviewMode) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${this.value})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      );
    }

    return (
      <div tabIndex={-1} className={style.image}>
        {this.value ? (
          <img src={this.value} alt="" crossOrigin="anonymous" />
        ) : (
          <>
            <Image />
            <p>{this.getPlaceholder()}</p>
          </>
        )}
      </div>
    );
  }
}

export default injectIntl(SignatureInput);
