import { FlightAirportJSON } from '@advitam/api/models/Flight/Airport';

export function formatAirport(airport: FlightAirportJSON): string {
  const { city, country_name: country, iata } = airport;
  return `${country}, ${city} (${iata})`;
}

interface FormattedAirportProps {
  airport: FlightAirportJSON;
}

export function FormattedAirport({
  airport,
}: FormattedAirportProps): JSX.Element {
  return <>{formatAirport(airport)}</>;
}
