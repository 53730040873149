import { getTokens } from '@advitam/api/lib/tokens';
import { presence } from 'lib/Assert';

import { ApiPayload, ApiRequestDescriptor, JSONValue } from './request';

type ApiMethod<T, U extends Array<T>, ResponseType, PayloadType> = (
  ...args: U
) => ApiRequestDescriptor<ResponseType, PayloadType>;

export function authenticate<T, U extends Array<T>, ResponseType, PayloadType>(
  _target: Function,
  _propertyKey: string,
  descriptor: TypedPropertyDescriptor<
    ApiMethod<T, U, ResponseType, PayloadType>
  >,
): TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>> {
  const original = descriptor.value;
  if (!original) {
    return descriptor;
  }

  return {
    ...descriptor,
    value: (...args: U): ApiRequestDescriptor<ResponseType, PayloadType> => {
      const req: ApiRequestDescriptor<ResponseType, PayloadType> = original(
        ...args,
      );
      return {
        ...req,
        headers: {
          ...req.headers,
          ...getTokens(),
        },
      };
    },
  };
}

function formdataEncode(
  payload: ApiPayload,
  formdata: FormData,
  prefix?: string,
): void {
  const withPrefix = (value: string): string =>
    prefix ? `${prefix}[${value}]` : value;

  Object.entries(payload).forEach(([key, value]: [string, JSONValue]) => {
    if (!presence(value)) {
      return;
    }
    if (value instanceof Blob) {
      // Blobs are also an object
      formdata.set(withPrefix(key), value);
    } else if (value instanceof Date) {
      formdata.set(withPrefix(key), value.toISOString());
    } else if (typeof value === 'object') {
      formdataEncode(value, formdata, withPrefix(key));
    } else if (presence(value)) {
      formdata.set(withPrefix(key), value.toString());
    }
  });
}

export function formdataEncoded<
  T,
  U extends Array<T>,
  ResponseType,
  PayloadType
>(
  _target: Function,
  _propertyKey: string,
  descriptor: TypedPropertyDescriptor<
    ApiMethod<T, U, ResponseType, PayloadType>
  >,
): TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>> {
  const original = descriptor.value;
  if (!original) {
    return descriptor;
  }

  return {
    ...descriptor,
    value: (...args: U): ApiRequestDescriptor<ResponseType, PayloadType> => {
      const req: ApiRequestDescriptor<ResponseType, PayloadType> = original(
        ...args,
      );
      const encoded = new FormData();
      if (req.payload) {
        formdataEncode(req.payload, encoded);
      }

      return {
        ...req,
        payload: encoded,
      };
    },
  };
}
