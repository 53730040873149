import type { ReactNode } from 'react'

import Text from '../Text'
import style from './ErrorText.module.scss'

interface ErrorTextProps {
  center?: boolean
  tagName?: 'span' | 'div' | 'p'
  className?: string
  children: ReactNode
}

export default function ErrorText({
  center,
  tagName = 'div',
  children,
  className,
}: ErrorTextProps): JSX.Element {
  const classes = [style.error, center && style.center, className].filter(Boolean)
  return (
    <Text className={classes.join(' ')} tagName={tagName}>
      {children}
    </Text>
  )
}
