import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get } from 'api/methods';

import { Product } from 'models/Deal/Product';

export type ProductsSuppliersBody = Pick<
  Product,
  'productObject' | 'priceLine' | 'variants'
>;

export class ProductsSuppliers {
  @authenticate
  static show(productId: number): ApiRequestDescriptor<ProductsSuppliersBody> {
    return get(`/api/v1/products_suppliers/${productId}`);
  }
}
