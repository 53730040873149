import { assert } from 'lib/Assert';

import { InputType } from '.';
import CheckInput from './Check';
import CircleInput from './Circle';
import TextInput from './Text';
import ManualZone from './ManualZone';
import Signature from './Signature';
import Image from './Image';

const INPUTS: Record<string, InputType> = {
  text: (TextInput as unknown) as InputType,
  check: (CheckInput as unknown) as InputType,
  circle: (CircleInput as unknown) as InputType,
  manualZone: (ManualZone as unknown) as InputType,
  signature: (Signature as unknown) as InputType,
  image: (Image as unknown) as InputType,
};

export function getInputClass(type: string): InputType {
  const input = INPUTS[type];
  assert(input !== undefined);
  return input;
}
