import { authenticate, chatbotApiEndpoint } from '../../../lib/decorators'
import { post } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { ChatbotConversationJSON } from '../../../models/Chatbot/Conversation'

export const Conversations = {
  show: chatbotApiEndpoint(
    (conversationUuid: string): ApiRequestDescriptor<ChatbotConversationJSON> =>
      post(`/v1/conversations/${conversationUuid}`),
  ),
  create: chatbotApiEndpoint(
    authenticate(
      (ai_agent_slug: string): ApiRequestDescriptor<ChatbotConversationJSON> =>
        post('/v1/conversations', { ai_agent_slug }),
    ),
  ),
}
