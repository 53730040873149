import { FlightAirportJSON } from '@advitam/api/models/Flight/Airport';
import { Tooltip } from '@advitam/ui';
import { FormattedAirport } from 'components/Format/Flight';

interface AirportDetailsProps {
  airport: FlightAirportJSON | null;
}

export default function AirportDetails({
  airport,
}: AirportDetailsProps): JSX.Element | null {
  if (!airport) {
    return null;
  }

  return (
    <Tooltip content={airport.name}>
      <FormattedAirport airport={airport} />
    </Tooltip>
  );
}
