import { ReactNode, useState } from 'react'

import Chevron from '../../images/icons/chevron-down.svg'
import TransparentButton from '../TransparentButton'
import { ChevronPosition } from './models'
import style from './Collapsible.module.scss'

interface CollapsibleProps {
  title: ReactNode
  children: ReactNode | ReactNode[]
  chevronPosition?: ChevronPosition
  isOpen?: boolean
  onToggle?: () => void
  hideChevron?: boolean
  withTitleSeparator?: boolean
  borderless?: boolean
  className?: string
  // RDFa
  property?: string
  typeof?: string
}

export default function Collapsible({
  title,
  children,
  chevronPosition = ChevronPosition.RIGHT,
  hideChevron,
  className = '',
  isOpen: isOpenProps,
  withTitleSeparator,
  borderless,
  onToggle,
  property,
  typeof: rdfaTypeof,
}: CollapsibleProps): JSX.Element {
  const [isOpenState, setIsOpen] = useState(isOpenProps)
  const isOpen = onToggle ? isOpenProps : isOpenState
  const onClick = onToggle || ((): void => setIsOpen(!isOpen))

  const classes = [!borderless && style.wrapper, borderless && style.borderless, className].filter(
    Boolean,
  )
  classes.push(isOpen ? style.open : style.closed)
  const contentClasses = [
    style.content,
    withTitleSeparator && !borderless && style.separated,
  ].filter(Boolean)

  return (
    <div className={classes.join(' ')} property={property} typeof={rdfaTypeof}>
      <TransparentButton
        onClick={onClick}
        className={`${style.title} ${
          chevronPosition === ChevronPosition.LEFT ? style.chevron_left : style.chevron_right
        }`}
      >
        <div>{title}</div>
        {!hideChevron && <Chevron />}
      </TransparentButton>
      <div className={contentClasses.join(' ')}>{children}</div>
    </div>
  )
}
