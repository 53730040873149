import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-final-form';

import { LinkButton, SubmitButton } from '@advitam/ui/components/Button';
import ErrorText from '@advitam/ui/components/ErrorText';
import { EmailInput, FormLayout } from '@advitam/ui/components/Form';
import { FormattedApiError } from '@advitam/ui/components/Format';
import Spinner from '@advitam/ui/components/Spinner';
import Text from '@advitam/ui/components/Text';
import { withSlice } from '@advitam/react';

import { Path } from 'containers/App/constants';
import { makeSelectIsAuthenticated } from 'slices/auth';
import actionsMessages from 'messages/actions';

import Layout from '../parts/Layout';
import authMessages from '../messages';
import style from '../Auth.module.scss';
import messages from './messages';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectSuccessMessage,
} from './selectors';
import slice from './slice';
import { requestPasswordReset } from './thunk';

const EMAIL_FIELD = 'email';

interface ForgottenPasswordForm {
  [EMAIL_FIELD]: string;
}

function ForgottenPassword(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(makeSelectIsAuthenticated());
  const isLoading = useSelector(makeSelectIsLoading());
  const successMessage = useSelector(makeSelectSuccessMessage());
  const error = useSelector(makeSelectError());

  const onSubmit = ({ email }: ForgottenPasswordForm): void => {
    dispatch(requestPasswordReset(email));
  };

  if (isAuthenticated) {
    return <Navigate replace to={Path.INDEX} />;
  }

  if (successMessage) {
    return (
      <Layout title={<FormattedMessage id={messages.title.id} />}>
        <Text tagName="p">{successMessage}</Text>
      </Layout>
    );
  }

  return (
    <Layout title={<FormattedMessage id={messages.title.id} />}>
      <Form<ForgottenPasswordForm> onSubmit={onSubmit}>
        {({ handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <FormLayout.Row className={style.compact}>
              <EmailInput
                required
                name={EMAIL_FIELD}
                label={<FormattedMessage id={authMessages.email.id} />}
                placeholder={intl.formatMessage(authMessages.emailPlaceholder)}
              />
            </FormLayout.Row>

            {error && (
              <ErrorText className={style.compact}>
                {error.status === 404 ? (
                  <FormattedMessage id={messages.notFound.id} />
                ) : (
                  <FormattedApiError error={error} />
                )}
              </ErrorText>
            )}

            <div className={style.actions}>
              <SubmitButton
                primary
                icon={isLoading ? <Spinner /> : undefined}
                className={style.action}
                text={<FormattedMessage id={actionsMessages.validate.id} />}
              />
              <LinkButton
                outline
                internal
                href={Path.LOGIN}
                className={style.action}
                text={<FormattedMessage id={actionsMessages.cancel.id} />}
              />
            </div>
          </form>
        )}
      </Form>
    </Layout>
  );
}

export default withSlice(slice)(ForgottenPassword);
