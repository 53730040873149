import { createSelector, Selector } from 'reselect';

import type { StayJSON } from '@advitam/api/models/FuneralParlor';
import type { SerializedApiError } from '@advitam/api';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import { FUNERAL_PARLOR_MISCELLANEOUS } from './constants';
import type { AppStateSubset, State } from './slice';

type MiscellaneousSelector<T> = Selector<AppStateSubset, T>;

const selectMiscellaneousDomain = (state: AppStateSubset): State =>
  state[FUNERAL_PARLOR_MISCELLANEOUS];

export const makeSelectStays = (): MiscellaneousSelector<StayJSON[]> =>
  createSelector(selectMiscellaneousDomain, state => state.stays);

export const makeSelectStaySelectItems = (): MiscellaneousSelector<
  SelectableItem<number>[]
> =>
  createSelector(makeSelectStays(), stays =>
    stays.map(stay => ({ value: stay.id, name: stay.name })),
  );

export const makeSelectIsLoading = (): MiscellaneousSelector<boolean> =>
  createSelector(selectMiscellaneousDomain, state => state.isLoading);

export const makeSelectError = (): MiscellaneousSelector<SerializedApiError | null> =>
  createSelector(selectMiscellaneousDomain, state => state.error);
