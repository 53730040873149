import { AdministrativeProcedureJSON } from '../../models/AdministrativeProcedure'
import { Civility } from '../../models/Person/Civility'

interface UpdatePayload {
  procedure: {
    defunct_death_city_id?: number
    defunct_family_link: string | null
    defunct_attributes: {
      firstname: string
      lastname: string
      birth_date: string
      death_date: string
      civility: Civility
    }
  }
}

export function serialize(admProcedure: AdministrativeProcedureJSON): UpdatePayload {
  const payload: UpdatePayload = {
    procedure: {
      defunct_family_link: admProcedure.defunct_family_link,
      defunct_attributes: {
        firstname: admProcedure.defunct.firstname || '',
        lastname: admProcedure.defunct.lastname || '',
        birth_date: admProcedure.defunct.birth_date || '',
        death_date: admProcedure.defunct.death_date || '',
        civility: admProcedure.defunct.civility,
      },
    },
  }

  if (admProcedure.defunct_death_city) {
    payload.procedure.defunct_death_city_id = admProcedure.defunct_death_city.id
  }

  return payload
}
