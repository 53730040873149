import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  documents: { id: 'app.containers.Crud.documents' },
  editableDocuments: { id: 'app.containers.Crud.editableDocuments' },
  supportingDocuments: { id: 'app.containers.Crud.supportingDocuments' },
  openingHours: { id: 'app.containers.Crud.openingHours' },
  unsavedChanges: { id: 'app.containers.Crud.unsavedChanges' },

  unitCentimeter: { id: 'app.containers.Crud.unitCentimeter' },
  unitDay: { id: 'app.containers.Crud.unitDay' },
  unitCurrency: { id: 'app.containers.Crud.unitCurrency' },
  unitKilogram: { id: 'app.containers.Crud.unitKilogram' },
  unitMinute: { id: 'app.containers.Crud.unitMinute' },
  unitMonth: { id: 'app.containers.Crud.unitMonth' },
  unitPerson: { id: 'app.containers.Crud.unitPerson' },
  unitPlace: { id: 'app.containers.Crud.unitPlace' },
  unitSquareMeter: { id: 'app.containers.Crud.unitSquareMeter' },
  unitYear: { id: 'app.containers.Crud.unitYear' },
});
