import { ReactNode } from 'react';
import { useLocation, useResolvedPath } from 'react-router';

import Arrow from '@advitam/ui/images/icons/chevron-down.svg';
import { ButtonText, Dropdown } from '@advitam/ui';

import { makeHref, isLinkElement } from './utils';
import style from './LinksSection.module.scss';

interface LinkGroupProps {
  children: ReactNode[];
  placeholder: ReactNode;
}

/**
 * @warning While not actually tested, it is very likely that conditionally
 * rendered children will break this component because of the hook in a loop.
 */
export default function LinkGroup({
  placeholder,
  children,
}: LinkGroupProps): JSX.Element {
  const location = useLocation();
  const links = children.map(child =>
    // TODO: fix and remove exclusion
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isLinkElement(child) ? useResolvedPath(makeHref(child.props.to)) : null,
  );
  const isActive = links.some(
    link => link && location.pathname === link.pathname,
  );

  /*
   * Dropdowns in the middle of a link list can not be clicked on Chrome.
   *
   * It looks like Chrome is making a touch area bigger than anchor elements as
   * long as this does not cover another interactive element. We are wrapping
   * the dropdown in a button to avoid other items to cover it.
   *
   * TODO: Is there a better way ?
   */
  return (
    <button type="button" className={style.link_fix}>
      <Dropdown
        className={style.group}
        keepOpen={isActive}
        placeholder={
          <ButtonText small className={style.group_title}>
            {placeholder}
            <Arrow />
          </ButtonText>
        }
      >
        {children}
      </Dropdown>
    </button>
  );
}
