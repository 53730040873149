import { InfoWindow } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';

import { Link, Text } from '@advitam/ui';
import { Path } from 'containers/App/constants';
import { makeSelectUser } from 'slices/auth';

import { makeSelectActiveSupplier } from '../selectors';
import style from './Content.module.scss';
import { setActiveSupplier } from '../slice';

export default function SupplierWindow(): JSX.Element | null {
  const dispatch = useDispatch();
  const activeSupplier = useSelector(makeSelectActiveSupplier());
  const user = useSelector(makeSelectUser());

  const onClose = (): void => {
    dispatch(setActiveSupplier(null));
  };

  if (!activeSupplier) {
    return null;
  }

  if (!user?.isFuneralDirectorOrAbove) {
    return (
      <InfoWindow
        position={{
          lat: activeSupplier.latitude,
          lng: activeSupplier.longitude,
        }}
        onCloseClick={onClose}
      >
        <Text>
          <b>{activeSupplier.name}</b>
        </Text>
      </InfoWindow>
    );
  }

  return (
    <InfoWindow
      position={{ lat: activeSupplier.latitude, lng: activeSupplier.longitude }}
      onCloseClick={onClose}
    >
      <>
        <Text>
          <Link
            newTab
            internal
            href={[Path.SUPPLIERS, activeSupplier.id].join('/')}
            className={style.link}
          >
            <b>{activeSupplier.name}</b>
          </Link>
        </Text>
        <br />
        <Text tagName="div" extraSmall className={style.links}>
          {activeSupplier.phone && (
            <Link href={`tel:${activeSupplier.phone}`} className={style.link}>
              {activeSupplier.phone}
            </Link>
          )}
          <br />
          {activeSupplier.email && (
            <Link href={`mail:${activeSupplier.email}`} className={style.link}>
              {activeSupplier.email}
            </Link>
          )}
        </Text>
      </>
    </InfoWindow>
  );
}
