import { BackgroundValue } from './constants'
import Full from './Full'
import OneQuarter from './OneQuarter'
import OneThirds from './OneThirds'
import TwoThirds from './TwoThirds'
import Transition from './Transition'

const Background = {
  Full,
  OneQuarter,
  OneThirds,
  TwoThirds,
  Transition,
}

export default Background
export { BackgroundValue }
