import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import {
  DealDocumentBatchDocumentJSON,
  DealDocumentBatchRecipientJSON,
} from '@advitam/api/models/Deal/Document/Batch';
import { DocumentGenerationType } from '@advitam/api/models/Documents/Editable/GenerationType';
import { BatchEntityRecipient } from '@advitam/api/v1/Deals/Documents/types';

import { makeSelectDocuments as makeSelectDealDocuments } from 'containers/Documents/selectors.js';
import { DealDocument } from 'models/Deal/Document';

import { SEND_BATCH_MODAL } from './constants';
import { AppStateSubset, State } from './slice';
import { PartialBatchDocument, SendBatchForm, StateBatch } from './types';
import { getRecipientSendingMethodKey } from './utils';

type SendBatchModalSelector<T> = Selector<AppStateSubset, T>;

const selectSendBatchModalDomain = (state: AppStateSubset): State =>
  state[SEND_BATCH_MODAL];

export const makeSelectBatch = (): SendBatchModalSelector<StateBatch | null> =>
  createSelector(selectSendBatchModalDomain, ({ batch }) => batch);

export const makeSelectIsOpen = (): SendBatchModalSelector<boolean> =>
  createSelector(selectSendBatchModalDomain, ({ isOpen }) => isOpen);

export const makeSelectIsLoading = (): SendBatchModalSelector<boolean> =>
  createSelector(selectSendBatchModalDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSending = (): SendBatchModalSelector<boolean> =>
  createSelector(selectSendBatchModalDomain, ({ isSending }) => isSending);

export const makeSelectError = (): SendBatchModalSelector<SerializedApiError | null> =>
  createSelector(selectSendBatchModalDomain, ({ error }) => error);

export const makeSelectDocuments = (): SendBatchModalSelector<
  Array<DealDocumentBatchDocumentJSON | PartialBatchDocument>
> => createSelector(makeSelectBatch(), batch => batch?.documents || []);

export const makeSelectDocumentsToGenerate = (): SendBatchModalSelector<
  DealDocument[]
> =>
  createSelector(
    makeSelectDealDocuments(),
    makeSelectDocuments(),
    (dealDocuments, batchDocuments) =>
      (dealDocuments as DealDocument[]).filter(dealDocument => {
        const isInBatch = batchDocuments.some(
          batchDocument => batchDocument.deal_document_id === dealDocument.id,
        );

        return (
          isInBatch &&
          !dealDocument.locked &&
          !dealDocument.template_id &&
          dealDocument.document &&
          dealDocument.document.generation_type !==
            DocumentGenerationType.IDENTICAL
        );
      }),
  );

export const makeSelectRecipients = (): SendBatchModalSelector<
  DealDocumentBatchRecipientJSON[]
> => createSelector(makeSelectBatch(), batch => batch?.recipients || []);

const makeSelectSubject = (): SendBatchModalSelector<string> =>
  createSelector(makeSelectBatch(), batch => batch?.subject || '');

const makeSelectContent = (): SendBatchModalSelector<string> =>
  createSelector(makeSelectBatch(), batch => batch?.content || '');

const makeSelectRecipientEntities = (): SendBatchModalSelector<
  BatchEntityRecipient[]
> =>
  createSelector(makeSelectRecipients(), recipients => {
    const entities: BatchEntityRecipient[] = [];

    recipients.forEach(recipient => {
      const methodKey = getRecipientSendingMethodKey(recipient);
      const methodValue = methodKey ? recipient[methodKey] : null;

      if (methodKey && methodValue) {
        entities.push({
          ...recipient,
          method: methodKey,
          method_value: methodValue,
        });
      }
    });

    return entities;
  });

export const makeSelectFormInitialValues = (): SendBatchModalSelector<
  SendBatchForm
> =>
  createSelector(
    makeSelectContent(),
    makeSelectRecipientEntities(),
    makeSelectSubject(),
    (content, recipientEntities, subject) => ({
      content,
      recipientEntities,
      subject,
    }),
  );
