import type { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Role } from '@advitam/api/models/BusinessUser/Role';
import { PaymentReceived } from '@advitam/api/models/Payment/Received';
import { PaymentMean } from '@advitam/api/models/Payment/Mean';
import { PaymentState } from '@advitam/api/models/Payment/State';
import { assert } from '@advitam/support';
import { FormattedPrice } from '@advitam/ui';
import { TextInput, NumberInput, SelectList, Checkbox } from 'components/Input';
import { ButtonV2 as Button, CrossButton } from 'components/Button';
import DatePicker from 'components/DatePicker';
import FormattedDate from 'components/FormattedDate';
import CivInput from 'components/Input/CivInput';
import messagesAction from 'messages/actions';
import type { PaymentJSON } from 'models/Payment';
import { makeSelectUser } from 'slices/auth';

import { setPaymentToRefund } from '../RefundModal/slice';
import { PAYMENT_MEANS } from '../constants';
import { makeSelectIsPaymentRefundable } from '../selectors';
import { canUserEditPayment } from '../utils';
import FundingAgency from './FundingAgency/index.js';
import StateInput from './StateInput';
import messages from './messages';
import style from './Payment.module.scss';

const PAYMENT_REQUIRE_FUNDING_AGENCY = [
  PaymentMean.DEFUNCT_ACCOUNT,
  PaymentMean.CHECK,
  PaymentMean.FUNERAL_CONTRACT,
];

interface PaymentProps {
  handleChange: (payment: PaymentJSON) => void;
  addPayment?: () => void;
  payment: PaymentJSON;
  newPayment?: boolean;
  setDeleteFunction: (callback: () => void) => void;
  removePayment?: () => void;
}

export default function Payment({
  handleChange,
  addPayment,
  payment,
  newPayment = false,
  setDeleteFunction,
  removePayment,
}: PaymentProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(makeSelectUser());
  assert(user !== null);
  const isRefundable = useSelector(makeSelectIsPaymentRefundable(payment));

  const canRefund = user?.isAdmin && isRefundable;
  const canEdit = payment.received !== PaymentReceived.YES;

  const onRefundClick = (): void => {
    dispatch(setPaymentToRefund(payment));
  };

  const handleChangePaymentMethod = (mean: PaymentMean): void => {
    let received = null;
    if (mean === PaymentMean.CHECK || mean === PaymentMean.CASH) {
      received = payment.received || PaymentReceived.NO;
    }

    handleChange({
      ...payment,
      mean,
      received,
      check_no: mean !== PaymentMean.CHECK ? null : payment.check_no,
      funding_agency: !PAYMENT_REQUIRE_FUNDING_AGENCY.includes(mean)
        ? null
        : payment.funding_agency,
    });
  };

  const renderEditablePaymentMethod = (): JSX.Element => (
    <div>
      <div className="payment">
        {removePayment &&
          canUserEditPayment(payment.state, newPayment, user.role) && (
            <CrossButton
              onClick={() => setDeleteFunction(removePayment)}
              classNameContainer="button button--red payment__removeBtn"
            />
          )}
        <div className="payment__row">
          <FormattedMessage id={messages.amount.id} />
          <NumberInput
            placeholder="Ex: 500.03"
            name="amount"
            value={payment.amount || ''}
            onChange={({ target: { value } }) =>
              handleChange({ ...payment, amount: value })
            }
            disabled={payment.received === PaymentReceived.YES || !canEdit}
          />
        </div>
        <div className="payment__row">
          <FormattedMessage id={messages.method.id} />
          <SelectList
            name="method"
            options={PAYMENT_MEANS.map(mean => ({
              value: mean,
              message: messages[mean],
            }))}
            value={payment.mean || ''}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              handleChangePaymentMethod(value as PaymentMean)
            }
            disabled={!canEdit}
            emptyOption={false}
          />
        </div>
        {payment.mean === PaymentMean.CHECK && (
          <div className="payment__row">
            <FormattedMessage id={messages.checkNumber.id} />
            <NumberInput
              placeholder="Ex : 0001234"
              name="check_no"
              value={payment.check_no || ''}
              onChange={({ target: { value } }) =>
                handleChange({ ...payment, check_no: value })
              }
              disabled={!canEdit}
            />
          </div>
        )}
        <div className="payment__row">
          <FormattedMessage id={messages.state.id} />
          <StateInput payment={payment} onChange={handleChange} />
        </div>
        {(payment.mean === PaymentMean.CHECK ||
          payment.mean === PaymentMean.CASH) && (
          <div className="payment__row">
            <FormattedMessage id={messages.isReceived.id} />
            <SelectList
              name="received"
              options={[
                { value: PaymentReceived.NO, message: messages.receivedNo },
                { value: PaymentReceived.YES, message: messages.receivedYes },
                { value: PaymentReceived.LOST, message: messages.receivedLost },
              ]}
              value={payment.received}
              disabled={user.role !== Role.ADMIN}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) =>
                handleChange({ ...payment, received: value as PaymentReceived })
              }
              emptyOption={false}
            />
          </div>
        )}
        {!payment.is_company && (
          <>
            <div className="payment__row">
              <FormattedMessage id={messages.civ.id} />
              <CivInput
                name="civ"
                value={payment.civ}
                onChange={({ target: { value } }) =>
                  handleChange({ ...payment, civ: value })
                }
                disabled={!canEdit}
              />
            </div>
            <div className="payment__row">
              <FormattedMessage id={messages.firstName.id} />
              <TextInput
                placeholder="Ex : Georges"
                name="first_name"
                value={payment.first_name || ''}
                onChange={({ target: { value } }) =>
                  handleChange({ ...payment, first_name: value })
                }
                disabled={!canEdit}
              />
            </div>
            <div className="payment__row">
              <FormattedMessage id={messages.lastName.id} />
              <TextInput
                placeholder="Ex : Dupont"
                name="last_name"
                value={payment.last_name || ''}
                onChange={({ target: { value } }) =>
                  handleChange({ ...payment, last_name: value })
                }
                disabled={!canEdit}
              />
            </div>
          </>
        )}
        {payment.state === PaymentState.ACCEPTED && (
          <div className="payment__row payment__row__date">
            <DatePicker
              fieldName="paidAt"
              date={payment.paid_at}
              onChange={(value: number) =>
                handleChange({ ...payment, paid_at: value })
              }
            />
          </div>
        )}
        {payment.mean !== PaymentMean.DEFUNCT_ACCOUNT && (
          <>
            <div className="payment__row payment__row__checkbox">
              <FormattedMessage id={messages.isCompany.id} />
              <Checkbox
                name="isCompany"
                checked={payment.is_company}
                onChange={({
                  target: { checked },
                }: ChangeEvent<HTMLInputElement>) =>
                  handleChange({ ...payment, is_company: checked })
                }
                disabled={!canEdit}
              />
            </div>
            {payment.is_company && (
              <div className="payment__row">
                <FormattedMessage id={messages.companyName.id} />
                <TextInput
                  placeholder="Ex : ACME Corp."
                  name="company_name"
                  value={payment.company_name || ''}
                  onChange={({ target: { value } }) =>
                    handleChange({ ...payment, company_name: value })
                  }
                  disabled={!canEdit}
                />
              </div>
            )}
          </>
        )}
        {PAYMENT_REQUIRE_FUNDING_AGENCY.includes(payment.mean) && (
          <FundingAgency
            payment={payment}
            userRole={user.role}
            handleChange={handleChange}
            isNewPayment={newPayment}
            disabled={!canEdit}
          />
        )}
        {payment.mean === PaymentMean.FUNERAL_CONTRACT && (
          <div className="payment__row">
            <FormattedMessage id={messages.funeralContactNumber.id} />
            <TextInput
              placeholder="1234"
              name="contract_no"
              value={payment.contract_no || ''}
              onChange={({ target: { value } }) =>
                handleChange({ ...payment, contract_no: value })
              }
            />
          </div>
        )}
        <div className="payment__row--hidden">{payment.uuid}</div>
      </div>
      <div className="payment__row__button">
        {addPayment && (
          <Button
            className="space--10"
            onClick={addPayment}
            disabled={!payment.amount}
          >
            {intl.formatMessage({ id: messagesAction.add.id })}
          </Button>
        )}
      </div>
    </div>
  );

  const renderFundingAgency = (): JSX.Element => {
    const label =
      payment.mean === PaymentMean.CHECK
        ? { id: messages.fundingAgencyCheck.id }
        : { id: messages.fundingAgency.id };

    return (
      <div>
        {intl.formatMessage(label, {
          value: payment.funding_agency && payment.funding_agency.name,
        })}
      </div>
    );
  };

  // We check that the payment method is one that we can add/modify etc
  const editablePaymentMethod =
    payment.mean !== null && PAYMENT_MEANS.includes(payment.mean);

  if (
    editablePaymentMethod &&
    canUserEditPayment(payment.state, newPayment, user.role)
  ) {
    return renderEditablePaymentMethod();
  }

  /* eslint-disable react/style-prop-object */
  return (
    <div className={style.payment}>
      <div>
        {!payment.is_company && (
          <>
            <div>
              <FormattedMessage id={messages.firstName.id} />{' '}
              {payment.first_name}
            </div>
            <div>
              <FormattedMessage id={messages.lastName.id} /> {payment.last_name}
            </div>
          </>
        )}
        <div>
          <FormattedMessage id={messages.amount.id} />
          <FormattedPrice value={payment.amount} />
        </div>
        <div>
          <FormattedMessage
            id={messages.isCompanyValue.id}
            values={{ value: payment.is_company }}
          />
        </div>
        {payment.is_company && (
          <div>
            <FormattedMessage id={messages.companyName.id} />{' '}
            {payment.company_name}
          </div>
        )}
        <div>
          {payment.paid_at ? (
            <>
              <FormattedMessage id={messages.paymentDate.id} />
              <FormattedDate timestamp={payment.paid_at} dateOnly />
            </>
          ) : (
            <FormattedMessage id={messages.noPaymentDate.id} />
          )}
        </div>
        <div>
          <FormattedMessage id={messages.method.id} />
          {messages[payment.mean] ? (
            <FormattedMessage id={messages[payment.mean].id} />
          ) : (
            payment.mean
          )}
        </div>
        {payment.mean === PaymentMean.CHECK && (
          <div>
            <FormattedMessage id={messages.checkNumber.id} /> {payment.check_no}
          </div>
        )}
        <div>
          <FormattedMessage id={messages.state.id} />
          <FormattedMessage id={messages[payment.state].id} />
        </div>
        {PAYMENT_REQUIRE_FUNDING_AGENCY.includes(payment.mean) &&
          renderFundingAgency()}
        <div className="payment__row--hidden">{payment.uuid}</div>
      </div>
      {canRefund && (
        <div>
          <button type="button" className="button" onClick={onRefundClick}>
            <FormattedMessage id={messages.refund.id} />
          </button>
        </div>
      )}
    </div>
  );
}
/* eslint-enable react/style-prop-object */
