import type { ReactNode } from 'react'

import Text from '../Text'
import tabsStyle from './Tabs.module.scss'

export type TabStyle = 'normal' | 'hover' | 'selected'

interface TabProps {
  children: ReactNode
  style?: TabStyle
}

export function Tab({ children, style }: TabProps): JSX.Element {
  const classes = [tabsStyle.tab, style && tabsStyle[style]].filter(Boolean)
  return (
    <Text small tagName="div" className={classes.join(' ')}>
      {children}
    </Text>
  )
}
