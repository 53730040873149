import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ErrorText } from '@advitam/ui';
import { MimeTypes } from 'utils/constants';

import { DOCUMENT_TEMPLATE_TYPE } from './constants';
import PdfFile from './File/Pdf';
import ImageFile from './File/Image';
import TemplateFile from './File/Template';
import messages from '../messages';
import { makeSelectBlobType, makeSelectZoom } from '../selectors';

import style from './Engine.module.scss';

const FileTypes: Record<string, ComponentType<EngineProps> | undefined> = {
  [MimeTypes.PDF]: PdfFile as ComponentType<EngineProps>,
  [MimeTypes.JPG]: ImageFile as ComponentType<EngineProps>,
  [MimeTypes.PNG]: ImageFile as ComponentType<EngineProps>,
  [DOCUMENT_TEMPLATE_TYPE]: TemplateFile as ComponentType<EngineProps>,
};

interface EngineProps {
  miniatures?: boolean;
  isDocumentCentered?: boolean;
}

export default function Engine({
  miniatures = false,
  isDocumentCentered = false,
}: EngineProps): JSX.Element | null {
  const zoom = useSelector(makeSelectZoom());
  const type = useSelector(makeSelectBlobType());
  if (type === null) {
    return null;
  }

  const classes = [
    style.document_wrapper,
    isDocumentCentered && style.centered,
  ].filter(Boolean);

  const FileType = FileTypes[type];
  if (!FileType) {
    return (
      <ErrorText center>
        <FormattedMessage id={messages.unsupportedMimeType.id} />
      </ErrorText>
    );
  }

  return (
    <div
      className={classes.join(' ')}
      style={miniatures ? undefined : { transform: `scale(${zoom})` }}
    >
      <FileType miniatures={miniatures} />
    </div>
  );
}

export function registerFileType(
  mimeType: string,
  component: ComponentType<EngineProps>,
): void {
  FileTypes[mimeType] = component;
}
