import type { ComponentType, SVGAttributes } from 'react';
import type { IntlShape, MessageDescriptor } from 'react-intl';

import type { PaymentUpdatedNotificationJSON } from '@advitam/api/models/Notification';
import { PaymentState } from '@advitam/api/models/Payment/State';
import { Path } from 'containers/App/constants';
import { Path as FuneralPath } from 'containers/Deal/Funeral/constants';
import { safeFormatMessage } from 'utils/functions.typed';

import Euro from './icons/euro.svg';
import EuroStandalone from './icons/euro.standalone.png.bin';
import NoEuro from './icons/no-euro.svg';
import NoEuroStandalone from './icons/no-euro.standalone.png.bin';
import Question from './icons/question.svg';
import QuestionStandalone from './icons/question.standalone.png.bin';
import type { TileProps } from './TileProps';
import messages from './messages';
import style from './NotificationTile.module.scss';

export default function getPaymentUpdatedProps(
  notification: PaymentUpdatedNotificationJSON,
  intl: IntlShape,
): TileProps {
  const isFuneralDeal = notification.comp_info.deal_type === 'funeral';

  let titleMessage: MessageDescriptor;
  let bodyMessage: MessageDescriptor;
  let IconComponent: ComponentType<SVGAttributes<SVGElement>>;
  let icon: string;

  switch (notification.comp_info.state) {
    case PaymentState.ACCEPTED:
    case PaymentState.WAITING:
      titleMessage = messages.paymentCreatedTitle;
      bodyMessage = messages.paymentCreatedText;
      IconComponent = Euro;
      icon = EuroStandalone;
      break;
    case PaymentState.ERROR:
    case PaymentState.CANCEL:
      titleMessage = messages.paymentFailedTitle;
      bodyMessage = messages.paymentFailedText;
      IconComponent = function PaymentFailureIcon(): JSX.Element {
        return <NoEuro className={style.failed_payment} />;
      };
      icon = NoEuroStandalone;
      break;
    default:
      titleMessage = messages.unknownPaymentUpdatedTitle;
      bodyMessage = messages.unknownPaymentUpdatedText;
      IconComponent = Question;
      icon = QuestionStandalone;
      break;
  }

  return {
    link: Path.DEAL(
      notification.comp_info.deal_uuid,
      isFuneralDeal ? FuneralPath.BILLING : 'paiements',
    ),
    IconComponent,
    icon,
    title: intl.formatMessage(titleMessage),
    body: intl.formatMessage(bodyMessage, {
      payerName: notification.comp_info.payer_full_name,
      amount: notification.comp_info.amount,
      mean: safeFormatMessage(intl, messages, notification.comp_info.mean),
      state: notification.comp_info.state,
    }),
  };
}
