import { ApiRequestDescriptor } from '../../../lib/request'
import { get, put } from '../../../lib/methods'
import { BookingTransactionJSON } from '../../../models/Deal/BookingTransaction'
import { BookingStatus } from '../../../models/Deal/BookingTransaction/BookingStatus'
import { serializeUpdate } from './serializer'

export interface UpdatePayload {
  booking_status: BookingStatus
}

export class BookingTransactions {
  static show(uuid: string): ApiRequestDescriptor<BookingTransactionJSON> {
    return get(`/api/v1/deals/booking_transactions/${uuid}`)
  }

  static update(uuid: string, accepted: boolean): ApiRequestDescriptor<void> {
    return put(`/api/v1/deals/booking_transactions/${uuid}`, serializeUpdate(accepted))
  }
}
