import style from './NotificationCount.module.scss'

interface NotificationCountProps {
  count: number
}

export default function NotificationCount({ count }: NotificationCountProps): JSX.Element | null {
  if (count === 0) {
    return null
  }

  const displayCount = count > 10 ? '10+' : count.toString()
  return <span className={style.notification_count}>{displayCount}</span>
}
