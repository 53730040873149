import { createSelector, Selector } from 'reselect';
import { DEAL_KO_REASON_MODAL } from './constants';
import { AppStateSubset, State } from './slice';

type KoReasonModalSelectors<T> = Selector<AppStateSubset, T>;

const selectKoReasonModalDomain = (state: AppStateSubset): State =>
  state[DEAL_KO_REASON_MODAL];

export const makeSelectIsOpen = (): KoReasonModalSelectors<boolean> =>
  createSelector(selectKoReasonModalDomain, ({ isOpen }) => isOpen);
