import { defineMessages } from 'react-intl'

export default defineMessages({
  placeholder: { id: 'prof.ChatWindow.placeholder' },
  source: { id: 'prof.ChatWindow.source' },
  copied: { id: 'prof.ChatWindow.copied' },
  wait1: { id: 'prof.ChatWindow.wait1' },
  wait2: { id: 'prof.ChatWindow.wait2' },
  wait3: { id: 'prof.ChatWindow.wait3' },
  wait4: { id: 'prof.ChatWindow.wait4' },
  wait5: { id: 'prof.ChatWindow.wait5' },
  wait6: { id: 'prof.ChatWindow.wait6' },
  wait7: { id: 'prof.ChatWindow.wait7' },
  wait8: { id: 'prof.ChatWindow.wait8' },
  wait9: { id: 'prof.ChatWindow.wait9' },
  wait10: { id: 'prof.ChatWindow.wait10' },
  wait11: { id: 'prof.ChatWindow.wait11' },
  wait12: { id: 'prof.ChatWindow.wait12' },
  wait13: { id: 'prof.ChatWindow.wait13' },
  wait14: { id: 'prof.ChatWindow.wait14' },
  wait15: { id: 'prof.ChatWindow.wait15' },
  wait16: { id: 'prof.ChatWindow.wait16' },
  wait17: { id: 'prof.ChatWindow.wait17' },
  wait18: { id: 'prof.ChatWindow.wait18' },
})
