import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import { ReducersMapObject } from 'redux'

type BaseStore = ReturnType<typeof configureStore>

export interface Store extends BaseStore {
  // Reducer registry
  injectedReducers: ReducersMapObject
  getState: () => Record<string, unknown>
}

export function configureReduxStore(options: ConfigureStoreOptions): Store {
  return configureStore(options) as Store
}
