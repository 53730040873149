import propType from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import messages from './messages';

/**
 * Simple component displaying a date in the form "on DD/MM/YYYY at 00h00"
 */
export default function FormattedDate({ timestamp, dateOnly }) {
  const date = moment.unix(timestamp).utc();

  return (
    <>
      <FormattedMessage {...messages.on} />
      {date.format(' DD/MM/YYYY ')}
      {!dateOnly && (
        <>
          <FormattedMessage {...messages.at} />
          {date.format(' HH[h]mm')}
        </>
      )}
    </>
  );
}

FormattedDate.propTypes = {
  timestamp: propType.number.isRequired,
  dateOnly: propType.bool,
};
