import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { makeSelectUser } from 'slices/auth';
import { Sidebar, usePreventLeaving } from 'containers/Crud';
import crudMessages from 'containers/Crud/messages';

import { Path } from './constants';
import { makeSelectIsUpdatingName, makeSelectSupplier } from './selectors';
import messages from './messages';

export default function CrematoriumSidebar(): JSX.Element {
  const intl = useIntl();

  const user = useSelector(makeSelectUser());
  const isUpdatingName = useSelector(makeSelectIsUpdatingName());
  const supplier = useSelector(makeSelectSupplier());
  const isCreation = !supplier;

  const { dirty } = useFormState();
  usePreventLeaving(isCreation || dirty);

  return (
    <Sidebar.Menu>
      <Sidebar.Title
        isCreation={isCreation}
        isLoading={isUpdatingName}
        fieldName="supplier.name"
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
      />
      {supplier?.current_update_dt && supplier?.current_update_user_id && (
        <Sidebar.LastUpdate
          date={supplier.current_update_dt}
          userId={supplier.current_update_user_id}
        />
      )}
      <Sidebar.LinksSection>
        <Sidebar.Link
          to={Path.GENERAL}
          message={<FormattedMessage id={messages.general.id} />}
        />
        {!isCreation && user?.isFuneralDirectorOrAbove && (
          <Sidebar.Link
            to={Path.DOCUMENTS}
            message={<FormattedMessage id={crudMessages.documents.id} />}
          />
        )}
        {!isCreation && (
          <>
            <Sidebar.Link
              to={Path.MISCELLANEOUS}
              message={<FormattedMessage id={messages.miscellaneous.id} />}
            />
            <Sidebar.Link
              to={Path.WAREHOUSES}
              message={<FormattedMessage id={messages.warehouses.id} />}
            />
          </>
        )}
      </Sidebar.LinksSection>
    </Sidebar.Menu>
  );
}
