import { ChangeEventHandler, InputHTMLAttributes, ReactNode } from 'react'

import BoolField from '../Boolean'
import style from './Radio.module.scss'

interface DefaultProps {
  value: InputHTMLAttributes<HTMLInputElement>['value']
  borderless?: boolean
  checked?: boolean
  label: ReactNode
  name?: string
  error?: boolean
  className?: string
  onChange: ChangeEventHandler
}

export default function Default({
  value,
  label,
  name,
  onChange,
  borderless = false,
  checked = false,
  error = false,
  className = '',
}: DefaultProps): JSX.Element {
  const classes = [style.default]
  if (className) {
    classes.push(className)
  }

  const bulletClasses = [style.indicator]
  if (checked) {
    bulletClasses.push(style.checked)
  }

  return (
    <BoolField
      error={error}
      borderless={borderless}
      checked={checked}
      label={label}
      className={classes.join(' ')}
    >
      <span className={bulletClasses.join(' ')} />
      <input type="radio" name={name} value={value} onChange={onChange} checked={checked} />
    </BoolField>
  )
}
