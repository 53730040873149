import { ReactNode } from 'react'
import style from './ShoppingCart.module.scss'

interface TotalsProps {
  children: ReactNode | ReactNode[]
}

export default function Totals({ children }: TotalsProps): JSX.Element {
  return <div className={style.totals}>{children}</div>
}
