// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupInputs-module__tooltip--salhc ul{padding-left:16px;margin:0}`, "",{"version":3,"sources":["webpack://./src/containers/Billing/List/Edit/parts/GroupInputs.module.scss"],"names":[],"mappings":"AACE,uCACE,iBAAA,CACA,QAAA","sourcesContent":[".tooltip {\n  ul {\n    padding-left: 16px;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `GroupInputs-module__tooltip--salhc`
};
export default ___CSS_LOADER_EXPORT___;
