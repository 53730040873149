import { RefObject, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, ResourceList } from '@advitam/ui';
import { Filter } from '@advitam/ui/components/ResourceList';
import { addCollectionItem } from 'containers/Crud';
import { assert } from 'lib/support';

import { ConcessionDuration } from '../../constants';
import {
  makeSelectConcessionTerms,
  makeSelectConcessionTypes,
} from '../../concessionInfosSlice/selectors';
import { CONCESSION_SKELETON } from './constants';
import type { ConcessionFilters, GraveyardConcessionsForm } from './types';
import messages from './messages';
import { setFilters } from './slice';
import { makeSelectFilters } from './selectors';
import style from './Concessions.module.scss';

interface FiltersProps {
  gridContainer: RefObject<HTMLDivElement>;
}

export default function Filters({ gridContainer }: FiltersProps): JSX.Element {
  const dispatch = useDispatch();
  const form = useForm<GraveyardConcessionsForm>();
  const { values } = useFormState<GraveyardConcessionsForm>();
  const { sectionValues } = values;
  const intl = useIntl();

  const currentFilters = useSelector(makeSelectFilters());
  const concessionTerms = useSelector(makeSelectConcessionTerms());
  const concessionTypes = useSelector(makeSelectConcessionTypes());

  const onAddConcession = useCallback(() => {
    assert(gridContainer.current !== null);
    addCollectionItem(
      form,
      'sectionValues',
      CONCESSION_SKELETON,
      gridContainer.current,
    );
  }, [form, sectionValues, gridContainer]);

  const onChange = useCallback(
    (filters: ConcessionFilters) => {
      dispatch(setFilters(filters));
    },
    [dispatch],
  );

  const filters: Filter[] = [
    {
      type: 'Select',
      name: 'graveyards_concession_type_id',
      items: concessionTypes.map(type => ({
        name: type.concession_type.name,
        value: type.id,
      })),
      placeholder: intl.formatMessage(messages.typeFilter),
      initialValue: currentFilters.graveyards_concession_type_id,
    },
    {
      type: 'Select',
      name: 'graveyard_concession_term_id',
      items: concessionTerms.map(term => ({
        name:
          term.term === ConcessionDuration.PERPETUAL
            ? intl.formatMessage(messages.perpetually)
            : intl.formatMessage(messages.year, { year: term.term }),
        value: term.id,
      })),
      placeholder: intl.formatMessage(messages.termFilter),
      initialValue: currentFilters.graveyards_concession_type_id,
    },
  ];

  return (
    <ResourceList.Filters
      filters={filters}
      onChange={onChange}
      className={style.filters}
    >
      <Button
        primary
        onClick={onAddConcession}
        text={<FormattedMessage id={messages.addConcession.id} />}
      />
    </ResourceList.Filters>
  );
}
