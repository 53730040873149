import { createAsyncThunk } from '@reduxjs/toolkit';

import { DealType } from '@advitam/api/models/Deal/Type';
import Api, { request } from '@advitam/api';
import { assert } from 'lib/support';

import { makeSelectRawDeal } from '../../../selectors.typed';
import type { AppStateSubset } from '../../../slice';
import { DEAL } from '../../../constants';

const API_METHOD_V2 = {
  [DealType.FUNERAL]: Api.V2.Deals.Funeral.update.bind(null),
  [DealType.ITEM]: Api.V2.Deals.Item.update.bind(null),
  [DealType.MARBLE]: Api.V2.Deals.Marble.update.bind(null),
};

export const updateCommentary = createAsyncThunk(
  `${DEAL}/UPDATE_COMMENTARY`,
  async (commentary: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectRawDeal()(state);
    assert(deal?.id !== undefined);
    const apiMethod = API_METHOD_V2[deal.deal_type];

    try {
      await request(apiMethod(deal.id, commentary));
    } catch (err) {
      return rejectWithValue(err);
    }

    return undefined;
  },
);
