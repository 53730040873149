import { injectIntl } from 'react-intl';

import Check from 'images/check.svg';

import Input from '.';
import style from '../Engine.module.scss';

const COMMON_STYLE = {
  display: 'block',
  width: '20px',
  height: '20px',
};

class CheckInput extends Input<boolean> {
  // eslint-disable-next-line class-methods-use-this
  renderInput(): JSX.Element | null {
    const { isEditMode, isPreviewMode, target, value, onChange } = this.props;

    const isReadonly = !isEditMode || isPreviewMode;
    if (isReadonly && !value) {
      return null;
    }
    return (
      <Check
        tabIndex={-1}
        onClick={isReadonly ? undefined : (): void => onChange(target, !value)}
        style={COMMON_STYLE}
        className={!value ? style.check__unset : undefined}
      />
    );
  }
}

export default injectIntl(CheckInput);
