import { useState } from 'react'

import Text from '../../../Text'
import style from './WordCloudSelect.module.scss'

export type Value = number | string | boolean

export interface SelectableItem {
  value: Value
  name: string
}

interface WordCloudSelectProps {
  items: SelectableItem[]
  initialValue?: Value | Value[]
  onChange: (value?: Value | Value[]) => void
  multiple?: boolean
  className?: string
}

export default function WordCloudSelect({
  items,
  initialValue,
  onChange,
  multiple,
  className,
}: WordCloudSelectProps): JSX.Element {
  const [value, setValue] = useState<Value | Value[] | undefined>(initialValue)

  const onSelect = (item: SelectableItem): void => {
    let newValue

    if (multiple) {
      const currentValue = value as Value[] | undefined

      if (!currentValue) {
        newValue = [item.value]
      } else if (currentValue.includes(item.value)) {
        newValue = currentValue.filter(v => v !== item.value)
      } else {
        newValue = [...currentValue, item.value]
      }
    } else {
      newValue = item.value
    }

    setValue(newValue)
    onChange(newValue)
  }

  const isSelected = (v: Value): boolean =>
    multiple ? value !== undefined && (value as Value[]).includes(v) : value === v

  const tabsClasses = [style.nav]
  if (className) tabsClasses.push(className)

  return (
    <div className={style.nav}>
      {items.map(item => (
        <button
          key={item.value.toString()}
          type="button"
          onClick={(): void => onSelect(item)}
          className={isSelected(item.value) ? style.active : undefined}
        >
          <Text small>{item.name}</Text>
        </button>
      ))}
    </div>
  )
}
