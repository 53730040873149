// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Map-module__container--dj5Cy{position:relative;flex-grow:1;display:flex;align-items:stretch}`, "",{"version":3,"sources":["webpack://./src/containers/Map/Map.module.scss"],"names":[],"mappings":"AAAA,8BACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,mBAAA","sourcesContent":[".container {\n  position: relative;\n  flex-grow: 1;\n  display: flex;\n  align-items: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Map-module__container--dj5Cy`
};
export default ___CSS_LOADER_EXPORT___;
