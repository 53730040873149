import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@advitam/ui';
import Male from '@advitam/ui/images/illustrations/male.svg';

import DefunctParent from './DefunctParent';
import style from './Form.module.scss';
import messages from './messages';

export default function DefunctFather(): JSX.Element {
  return (
    <DefunctParent
      prefix="father"
      pictogram={
        <Tooltip
          contentClassName={style.section_pictogram}
          content={<FormattedMessage id={messages.fatherTooltip.id} />}
        >
          <Male />
        </Tooltip>
      }
    />
  );
}
