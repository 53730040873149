// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__page--KjO0q{display:flex;align-items:center;justify-content:center;width:100%;min-height:100%}.style__relative--uUZo7{position:absolute;left:50%}`, "",{"version":3,"sources":["webpack://./src/components/Spinner/style.scss"],"names":[],"mappings":"AAAA,oBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,eAAA,CAGF,wBACE,iBAAA,CACA,QAAA","sourcesContent":[".page {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  min-height: 100%;\n}\n\n.relative {\n  position: absolute;\n  left: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `style__page--KjO0q`,
	"relative": `style__relative--uUZo7`
};
export default ___CSS_LOADER_EXPORT___;
