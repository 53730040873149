/*
 * DealSummary Messages
 *
 * This contains all the text for the DealSummary component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  phone: {
    id: 'app.containers.DealFuneral.DealSummarySection.phone',
    defaultMessage: 'Tel : ',
  },
  fax: {
    id: 'app.containers.DealFuneral.DealSummarySection.fax',
    defaultMessage: 'Fax : ',
  },
  taxes: {
    id: 'app.containers.DealFuneral.DealSummarySection.taxes',
    defaultMessage: 'Taxes',
  },
  cheque_order: {
    id: 'app.containers.DealFuneral.DealSummarySection.cheque_order',
    defaultMessage: 'Chèque : ',
  },
  otherFuneral: {
    id: 'app.containers.DealFuneral.DealSummarySection.otherFuneral',
    defaultMessage: 'Pompes Funèbres',
  },
});
