import { MouseEvent, ReactNode } from 'react';

interface ExternalLinkProps {
  children: ReactNode | ReactNode[];
  href: string;
  className?: string;
  onClick?: (ev: MouseEvent<HTMLAnchorElement>) => void;
}

export default function ExternalLink({
  children,
  href,
  className,
  onClick,
}: ExternalLinkProps): JSX.Element {
  return (
    <a
      href={href}
      className={className}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
    >
      {children}
    </a>
  );
}
