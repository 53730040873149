import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import { AgeRangeJSON } from '@advitam/api/models/Crematorium/AgeRange';

import { CREMATORIUM_AGE_RANGES } from './constants';
import type { AppStateSubset, State } from './slice';

type AgeRangesSelector<T> = Selector<AppStateSubset, T>;

const selectAgeRangesDomain = (state: AppStateSubset): State =>
  state[CREMATORIUM_AGE_RANGES];

export const makeSelectIsLoading = (): AgeRangesSelector<boolean> =>
  createSelector(selectAgeRangesDomain, substate => substate.isLoading);

export const makeSelectIsSaving = (): AgeRangesSelector<boolean> =>
  createSelector(selectAgeRangesDomain, substate => substate.isSaving);

export const makeSelectHasAlreadyInUseError = (): AgeRangesSelector<boolean> =>
  createSelector(
    selectAgeRangesDomain,
    substate => substate.hasAlreadyInUseError,
  );

export const makeSelectAgeRanges = (): AgeRangesSelector<AgeRangeJSON[]> =>
  createSelector(selectAgeRangesDomain, substate => substate.ageRanges);

export const makeSelectError = (): AgeRangesSelector<SerializedApiError | null> =>
  createSelector(selectAgeRangesDomain, substate => substate.error);
