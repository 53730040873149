import moment from 'moment';

export enum ModelType {
  RHA = 'RegionalHealthAuthority',
  POLICE = 'Police',
  CREMATORIUM = 'Crematorium',
  CITYHALL = 'Cityhall',
  WORSHIP = 'Worship',
  PREFECTURE = 'Prefecture',
  FUNERAL_PARLOR = 'FuneralParlor',
  GRAVEYARD = 'Graveyard',
  HOSPITAL = 'Hospital',
  SUPPLIER = 'Supplier',
  SUPPLIER_WAREHOUSE = 'SupplierWarehouse',
  CONSULATE = 'Consulate',
}

export interface ModelWithDestroy extends Model<unknown> {
  _destroy?: boolean;
}

export class Model<T> {
  constructor(properties: T) {
    Object.assign(this, properties);
  }

  get isDestroyed(): boolean {
    return Model.isDestroyed(this);
  }

  static isDestroyed(model: unknown): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return Boolean((model as ModelWithDestroy)._destroy);
  }

  static setDestroyed(model: unknown): void {
    // eslint-disable-next-line
    (model as ModelWithDestroy)._destroy = true;
  }

  static parseDate(dt: Date | string, fixTimezone?: boolean): Date;

  static parseDate(
    dt: Date | string | null | undefined,
    fixTimezone?: boolean,
  ): Date | null;

  static parseDate(
    dt?: Date | string | null | undefined,
    fixTimezone = true,
  ): Date | null {
    if (!dt) {
      return null;
    }
    if (fixTimezone) {
      moment.utc(dt).toDate();
    }
    return moment(dt).toDate();
  }
}
