import { FunctionComponent } from 'react';
import { ProductType } from 'models/Product';
import { ProductObject } from 'models/Deal/Product';

import Default from './Default';
import Monument from './Monument';

const FACTORY: Record<string, FunctionComponent<ProductInfosProps>> = {
  [ProductType.MONUMENT]: Monument,
};

interface ProductInfosProps {
  product: ProductObject;
}

export default function ProductInfo({
  product,
}: ProductInfosProps): JSX.Element {
  const Infos = FACTORY[product.type] || Default;

  return <Infos product={product} />;
}
