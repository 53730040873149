import { assert } from '@advitam/support'

import { authenticate, withSessionId } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { RoomJSON } from '../../models/Crematorium/Room'
import { RoomPayload } from './types'

export class Rooms {
  @authenticate
  @withSessionId
  static create(crematoriumId: number, room: RoomPayload): ApiRequestDescriptor<RoomJSON> {
    return post(`/api/v1/crematoriums/${crematoriumId}/rooms`, room)
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/crematoriums/rooms/${id}`)
  }

  @authenticate
  static index(crematoriumId: number): ApiRequestDescriptor<RoomJSON[]> {
    return get(`/api/v1/crematoriums/${crematoriumId}/rooms`)
  }

  @authenticate
  @withSessionId
  static update(room: RoomPayload): ApiRequestDescriptor<RoomJSON> {
    assert(room?.id !== undefined)
    return put(`/api/v1/crematoriums/rooms/${room.id}`, room)
  }
}
