import type { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';

import { useProduct } from '../../ProductListProvider';
import { getWindowMessage } from '../utils';

interface WindowCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function WindowCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: WindowCellProps): JSX.Element | null {
  const warehouseProduct = useProduct(rowIndex);

  const windowMessage = getWindowMessage(warehouseProduct);

  return (
    <div style={wrapperStyle} className={className}>
      {windowMessage && <FormattedMessage id={windowMessage.id} />}
    </div>
  );
}
