import { defineMessages } from 'react-intl';

export default defineMessages({
  lastUpdate: {
    id: 'app.containers.DealTypeWrapper.UpdateBanner.lastUpdate',
  },
  save: {
    id: 'app.containers.DealTypeWrapper.UpdateBanner.save',
  },
  manualSelectionUpdate: {
    id: 'app.containers.DealTypeWrapper.UpdateBanner.manualSelectionUpdate',
  },
});
