import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  single: { id: 'app.components.Format.Defunct.single' },
  cohabitee: { id: 'app.components.Format.Defunct.cohabitee' },
  married: { id: 'app.components.Format.Defunct.married' },
  pacsed: { id: 'app.components.Format.Defunct.pacsed' },
  divorced: { id: 'app.components.Format.Defunct.divorced' },
  widow: { id: 'app.components.Format.Defunct.widow' },
});
