import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';

import Spinner from 'components/Spinner';

import { makeSelectIsItemLoading } from '../../selectors';
import { updateItem } from '../../thunk';
import style from './style.scss';

interface TaskRowProps {
  item: TodoItem;
  children: ReactNode | ReactNode[];
}

export default function TaskRow({ item, children }: TaskRowProps): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsItemLoading(item));

  const onChange = (): void => {
    const forceDone = !item.isDone !== item.done ? !item.isDone : null;
    dispatch(updateItem({ ...item, force_done: forceDone }));
  };

  if (isLoading) {
    return (
      <div className={style.item}>
        <Spinner />
        {item.title}
      </div>
    );
  }

  return (
    <div className={style.item}>
      <input type="checkbox" checked={item.isDone} onChange={onChange} />
      {children}
    </div>
  );
}
