import { FormSpy, useForm, useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { AgreementLocationType } from '@advitam/api/models/Hospital/AgreementLocationType';
import { FormLayout, Select } from '@advitam/ui';

import { usePreviousValue } from '@advitam/react';
import { HospitalForm } from '../types';
import messages from './messages';
import AgreementLocationId from './AgreementLocationId';

export default function AgreementLocation(): JSX.Element | null {
  const intl = useIntl();
  const form = useForm();
  const { values } = useFormState<HospitalForm>();

  const previousType = usePreviousValue(
    values.hospital.agreement_location?.type || null,
  );

  if (values.hospital.mortuary !== false) {
    return null;
  }

  return (
    <FormLayout.Row>
      <FormSpy<HospitalForm> subscription={{ values: true }}>
        {({ values: spyValues }): null => {
          if (
            previousType !== undefined &&
            (spyValues.hospital.agreement_location?.type || null) !==
              previousType
          ) {
            form.change('hospital.agreement_location.id', null);
            form.change('hospital.agreement_location.name', null);
          }
          return null;
        }}
      </FormSpy>

      <Select<string>
        label={<FormattedMessage id={messages.agreementLocationType.id} />}
        tooltip={
          <FormattedMessage id={messages.agreementLocationTypeTooltip.id} />
        }
        name="hospital.agreement_location.type"
        items={[
          {
            value: AgreementLocationType.HOSPITAL,
            name: intl.formatMessage(messages.agreementLocationTypeHospital),
          },
          {
            value: AgreementLocationType.FUNERAL_PARLOR,
            name: intl.formatMessage(
              messages.agreementLocationTypeFuneralParlor,
            ),
          },
        ]}
        unselectable
      />
      <AgreementLocationId />
    </FormLayout.Row>
  );
}
