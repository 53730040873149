import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { PaymentState } from '@advitam/api/models/Payment/State';
import messages from 'components/Format/Payment/messages';
import { makeSelectUser } from 'slices/auth';
import { PaymentJSON } from 'models/Payment';
import { SelectList } from 'components/Input';

import { FUNERAL_ADVISOR_ALLOWED_STATES, PAYMENT_STATUS } from '../constants';

interface StateInputProps {
  payment: PaymentJSON;
  onChange: (payment: PaymentJSON) => void;
}

export default function StateInput({
  onChange,
  payment,
}: StateInputProps): JSX.Element {
  const user = useSelector(makeSelectUser());

  if (user?.isFuneralDirectorOrAbove) {
    return (
      <SelectList
        name="state"
        options={PAYMENT_STATUS.map(state => ({
          value: state,
          message: messages[state as keyof typeof messages],
        }))}
        value={payment.state || ''}
        onChange={({
          target: { value },
        }: ChangeEvent<HTMLSelectElement>): void =>
          onChange({ ...payment, state: value as PaymentState })
        }
        emptyOption={false}
      />
    );
  }

  return (
    <SelectList
      name="state"
      options={FUNERAL_ADVISOR_ALLOWED_STATES.map(state => ({
        value: state,
        message: messages[state as keyof typeof messages],
      }))}
      value={payment.state || ''}
      onChange={({ target: { value } }: ChangeEvent<HTMLSelectElement>): void =>
        onChange({ ...payment, state: value as PaymentState })
      }
      emptyOption={false}
    />
  );
}
