import { MouseEvent } from 'react';
import { FormattedDate } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  CroppedText,
  FormattedPrice,
  NotificationCount,
  ResourceList,
  Spinner,
  Tooltip,
} from '@advitam/ui';
import BusinessUserBadge from 'components/BusinessUserBadge';
import { Path } from 'containers/App/constants';
import DealState from 'containers/DealsList/parts/State';
import { PAYMENTS } from 'containers/Deal/SideBar/constants.js';
import { Summary } from 'models/Deal/Summary';

import Comment from './icons/comment.svg';
import PaymentCard from './PaymentCard';
import DealRisk from './Risk';
import {
  makeSelectDealFundingAgencyNames,
  makeSelectDealOpsLogCount,
  makeSelectDealOpsLogs,
  makeSelectDealPayments,
} from '../selectors';
import style from '../style.scss';
import { openFor } from './OpsLogs/slice';
import OpsLogCard from './OpsLogs/Card';

interface RowProps {
  deal: Summary;
}

export default function Row({ deal }: RowProps): JSX.Element {
  const dispatch = useDispatch();
  const payments = useSelector(makeSelectDealPayments(deal.id));
  const fundingAgencies = useSelector(
    makeSelectDealFundingAgencyNames(deal.id),
  );
  const opsLogs = useSelector(makeSelectDealOpsLogs(deal.id));
  const lastOpsLog = opsLogs?.at(-1);
  const opsLogCount = useSelector(makeSelectDealOpsLogCount(deal.id));

  return (
    <ResourceList.Row
      key={deal.uuid}
      link={{
        href: `${Path.DEALS}/${deal.uuid}#${PAYMENTS}`,
        internal: true,
      }}
      className={style.row}
    >
      <ResourceList.Cell className={style.status}>
        <span>
          <DealRisk deal={deal} />
          <DealState deal={deal} />
        </span>
      </ResourceList.Cell>
      <ResourceList.Cell className={style.name}>
        <CroppedText>{deal.defunct?.name || deal.client?.name}</CroppedText>
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        {deal.invoice_business_id}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        {deal.ceremony_dt && <FormattedDate value={deal.ceremony_dt} />}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.advisor}>
        <BusinessUserBadge userId={deal.user_id} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.remaining}>
        <FormattedPrice value={deal.payments.remaining_to_pay} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        {fundingAgencies ? fundingAgencies.join(', ') : <Spinner />}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.payments} hiddenOnMobile>
        {payments ? (
          payments.map(pay => <PaymentCard payment={pay} key={pay.id} />)
        ) : (
          <Spinner />
        )}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.comments}>
        {opsLogs ? (
          <Tooltip
            content={lastOpsLog && <OpsLogCard preview opsLog={lastOpsLog} />}
            disabled={!opsLogCount}
          >
            <Button
              outline
              icon={<Comment />}
              onClick={(ev: MouseEvent): void => {
                ev.preventDefault();
                dispatch(openFor(deal.id));
              }}
            />
            <NotificationCount count={opsLogCount} />
          </Tooltip>
        ) : (
          <Spinner />
        )}
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
