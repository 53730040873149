import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { NumberInput } from '@advitam/ui';
import { Layout, messages as crudMessages } from 'containers/Crud';

import { updateCityhall } from '../thunk';
import Wrapper from './Wrapper';
import { CityhallForm } from './types';
import messages from './messages';

export default function Miscellaneous(): JSX.Element {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: CityhallForm): void => {
      dispatch(updateCityhall(values.cityhall));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <Layout.FieldsetGrid>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.billing.id} />}
          >
            <NumberInput
              name="cityhall.cop_tax"
              label={<FormattedMessage id={messages.copTax.id} />}
              step={0.01}
              suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
            />
          </Layout.Fieldset>
        </Layout.FieldsetGrid>
      </Layout.Container>
    </Wrapper>
  );
}
