import { DateTime } from 'luxon'
import { Validator, ValidatorReturnType } from './Validator'

const ERROR = 'date_is_before'

export function isDateAfter(futureDate: DateTime): Validator<string | null> {
  return (value: string | null): ValidatorReturnType => {
    if (!value) {
      return undefined
    }

    const date = DateTime.fromISO(value)

    if (date.isValid && date >= futureDate) {
      return undefined
    }

    return { message: ERROR }
  }
}
