import { throttle } from 'lodash';
import { Component, createRef, DragEvent, RefObject } from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Text, TransparentButton } from '@advitam/ui';
import {
  makeSelectInputs,
  makeSelectNewInput,
  makeSelectDictionary,
  makeSelectZoom,
  makeSelectIsPreviewMode,
} from 'containers/DocumentTemplateEditor/selectors';

import slice, { AppStateSubset, InputTarget } from '../../slice';
import { Input as InputProps } from '../../models';
import { getInputClass } from '../Input/factory';
import {
  Edge,
  getDragData,
  InputDragActionType,
  setDragDataTarget,
} from '../utils';

import style from '../Engine.module.scss';

interface PageDispatchProps {
  confirmNewInput: () => void;
  setInputPosition: (target: InputTarget, position: [number, number]) => void;
  setInputPage: (target: InputTarget, newPage: number) => void;
  resizeInputTop: (target: InputTarget, position: [number, number]) => void;
  resizeInputRight: (target: InputTarget, position: [number, number]) => void;
  resizeInputBottom: (target: InputTarget, position: [number, number]) => void;
  resizeInputLeft: (target: InputTarget, position: [number, number]) => void;
  setInputValue: (
    target: InputTarget,
    value: string | boolean | undefined,
  ) => void;
  setFontSize: (target: InputTarget, fontSize: number) => void;
  deleteInput: (target: InputTarget) => void;
}

interface PageStateProps {
  inputs: InputProps[];
  newInput: InputProps | null;
  dictionary: Record<string, unknown>;
  isPreviewMode: boolean;
  zoom: number;
}

interface PageOwnProps {
  pageNumber: number;
  miniature: boolean;
}

type PageProps = PageDispatchProps & PageStateProps & PageOwnProps;

export default abstract class Page<
  Props = Record<string, unknown>
> extends Component<Props & PageProps> {
  private wrapper: RefObject<HTMLDivElement>;

  constructor(props: Props & PageProps) {
    super(props);
    this.wrapper = createRef();
  }

  private onMove(position: [number, number]): void {
    const { target } = getDragData();
    const { setInputPosition } = this.props;
    setInputPosition(target, position);
  }

  private onResize(position: [number, number], edge: Edge): void {
    const {
      resizeInputTop,
      resizeInputRight,
      resizeInputBottom,
      resizeInputLeft,
    } = this.props;
    const { target } = getDragData();

    /* eslint-disable no-bitwise */
    if (edge & Edge.TOP) {
      resizeInputTop(target, position);
    }
    if (edge & Edge.RIGHT) {
      resizeInputRight(target, position);
    }
    if (edge & Edge.BOTTOM) {
      resizeInputBottom(target, position);
    }
    if (edge & Edge.LEFT) {
      resizeInputLeft(target, position);
    }
    /* eslint-enable no-bitwise */
  }

  private onDragOver({ pageX, pageY }: DragEvent): void {
    if (!this.wrapper.current) {
      return;
    }

    const { action, offset, target } = getDragData();
    const { top, bottom, left } = this.wrapper.current.getBoundingClientRect();
    if (target.page !== -1 && (pageY < top || pageY > bottom)) {
      return;
    }

    const position: [number, number] = [
      pageX - left - offset[0],
      pageY - top - offset[1],
    ];

    switch (action.type) {
      case InputDragActionType.MOVE:
        this.onMove(position);
        break;
      case InputDragActionType.RESIZE:
        this.onResize(position, action.edge);
        break;
      default:
        break;
    }

    const { inputs, pageNumber, setInputPage } = this.props;
    if (target.page !== -1 && target.page !== pageNumber) {
      setInputPage(target, pageNumber);
      setDragDataTarget(pageNumber, inputs.length);
    }
  }

  abstract renderBase(): JSX.Element | null;

  render(): JSX.Element {
    const {
      miniature,
      inputs,
      dictionary,
      confirmNewInput,
      pageNumber,
      newInput,
      isPreviewMode,
      setInputValue,
      setFontSize,
      deleteInput,
    } = this.props;
    const NewInputElement = newInput && getInputClass(newInput.type);

    if (miniature) {
      return (
        <TransparentButton
          className={style.miniature}
          onClick={(): void => {
            const doc = document.getElementById(
              'document-template-editor__document',
            );
            if (doc) {
              doc.scrollTo({ top: pageNumber * (825 + 32) });
            }
          }}
        >
          {this.renderBase()}
          <Text small>{pageNumber + 1}</Text>
        </TransparentButton>
      );
    }

    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <div
        ref={this.wrapper}
        className={style.page_wrapper}
        onDragOver={(event): void => {
          event.preventDefault();
          throttle(() => this.onDragOver(event), 100, { trailing: true })();
        }}
        onDrop={confirmNewInput}
      >
        <div className="page_wrapper">{this.renderBase()}</div>
        {inputs.map((input, i) => {
          const El = getInputClass(input.type);
          return (
            <El
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              {...input}
              dictionary={dictionary}
              target={{ page: pageNumber, input: i }}
              isEditMode
              isPreviewMode={isPreviewMode}
              onChange={setInputValue}
              onDelete={deleteInput}
              setFontSize={setFontSize}
            />
          );
        })}
        {newInput && NewInputElement && (
          <NewInputElement
            {...newInput}
            dictionary={dictionary}
            target={{ page: -1, input: -1 }}
            isEditMode
            isPreviewMode={isPreviewMode}
            onChange={setInputValue}
            onDelete={deleteInput}
            setFontSize={setFontSize}
          />
        )}
      </div>
    );
  }
  /* eslint-enable react/jsx-props-no-spreading */
}

const mapStateToProps: MapStateToPropsParam<
  PageStateProps,
  PageOwnProps,
  AppStateSubset
> = (state, { pageNumber }) => ({
  inputs: makeSelectInputs(pageNumber)(state),
  dictionary: makeSelectDictionary()(state),
  newInput: makeSelectNewInput()(state),
  isPreviewMode: makeSelectIsPreviewMode()(state),
  zoom: makeSelectZoom()(state),
});

const { actions } = slice;
const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  { pageNumber }: PageOwnProps,
): PageDispatchProps => ({
  confirmNewInput: (): void => {
    dispatch(actions.confirmNewInput(pageNumber));
  },
  setInputPosition: (target: InputTarget, position: [number, number]): void => {
    dispatch(actions.setInputPosition({ ...target, position }));
  },
  setInputPage: (target: InputTarget, newPage: number): void => {
    dispatch(actions.setInputPage({ ...target, newPage }));
  },
  resizeInputTop: (target: InputTarget, position: [number, number]): void => {
    dispatch(actions.resizeInputTop({ ...target, position }));
  },
  resizeInputRight: (target: InputTarget, position: [number, number]): void => {
    dispatch(actions.resizeInputRight({ ...target, position }));
  },
  resizeInputBottom: (
    target: InputTarget,
    position: [number, number],
  ): void => {
    dispatch(actions.resizeInputBottom({ ...target, position }));
  },
  resizeInputLeft: (target: InputTarget, position: [number, number]): void => {
    dispatch(actions.resizeInputLeft({ ...target, position }));
  },
  setInputValue: (
    target: InputTarget,
    value: string | boolean | undefined,
  ): void => {
    dispatch(actions.setInputValue({ ...target, value }));
  },
  setFontSize: (target: InputTarget, fontSize: number): void => {
    dispatch(actions.setInputFontSize({ ...target, fontSize }));
  },
  deleteInput: (target: InputTarget): void => {
    dispatch(actions.deleteInput(target));
  },
});

export const connected = connect(mapStateToProps, mapDispatchToProps);
