/**
 * Converter for using radio inputs as boolean values
 *
 * Usage :
 * ```
 * <Radio
 *   name="myField"
 *   value={BoolRadio.value}
 *   parse={BoolRadio.parse}
 *   format={BoolRadio.format}
 * />
 * ```
 */

const value = '1'

function parse(v: string, _name: string): boolean {
  return v === value
}

function format(v: boolean, _name: string): string {
  return v ? value : '0'
}

export const BoolRadio = { format, parse, value }
