import { FormattedMessage } from 'react-intl';

import { DealPrestationComputeType } from 'models/Deal/Prestation/ComputeType';
import { NumberInput } from 'components/ReactFormComponent';

import { MIN_HOLDERS } from '../constants';
import Actions from './Actions';
import messages from './messages';
import style from './style.scss';

interface HoldersProps {
  onReset: (compute: DealPrestationComputeType) => void;
}

export default function Holders({ onReset }: HoldersProps): JSX.Element {
  return (
    <>
      <div className={style.row}>
        <FormattedMessage id={messages.holdersText.id} />
        <NumberInput field="amount" min={MIN_HOLDERS} />
      </div>
      <Actions onReset={(): void => onReset(DealPrestationComputeType.AUTO)} />
    </>
  );
}
