import { useEffect } from 'react';
import type { History } from 'history';
import { assert, unsafeContext } from 'lib/support';

async function checkForServiceWorkerUpdates(
  serviceWorker: ServiceWorkerRegistration,
): Promise<void> {
  assert(serviceWorker !== undefined);

  try {
    await serviceWorker.update();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to update service worker:', err);
  }
}

async function register(history: History): Promise<void> {
  let registration: ServiceWorkerRegistration;
  const { serviceWorker } = unsafeContext();
  if (!serviceWorker) {
    return;
  }

  try {
    registration = await serviceWorker.register('/service-worker.js');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to register service worker:', err);
  }

  history.listen(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkForServiceWorkerUpdates(registration);
  });
}

export default function useServiceWorker(history: History): void {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    register(history);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
  }, []);
}
