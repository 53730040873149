import { Model } from '../../../Model'
import type { ContactMedia } from '../../../Entity/ContactMedia'
import type { SendingState } from '../../../SendingState'
import type { SignatureState } from '../../../Signature/State'
import type { PartialBatchJSON } from './PartialBatch'

interface BatchLogBase {
  id: number
  comment: string | null
  batch: PartialBatchJSON
  state: SendingState
  signature_state: SignatureState
  subject: string
  contents: string
  recipient: string
  procedure_url: string
  tries: number
  sent_by: ContactMedia
  sent_to: string
  user_id: number
}

export interface BatchLogJSON extends BatchLogBase {
  dispatched_at: string | null
  last_try_at: string | null
  sent_at: string | null
  received_at: string | null
}

export interface BatchLog extends BatchLogBase {
  dispatched_at: Date | null
  last_try_at: Date | null
  sent_at: Date | null
  received_at: Date | null
}

export class BatchLog extends Model<BatchLogJSON> {
  constructor(data: BatchLogJSON) {
    super(data)

    /**
     * Not parsing dates to locale zone is intended, due to database zone mismatch.
     * This should be replaced once it's get fixed database side.
     */
    this.dispatched_at = data.dispatched_at ? new Date(data.dispatched_at) : null
    this.last_try_at = data.last_try_at ? new Date(data.last_try_at) : null
    this.sent_at = data.sent_at ? new Date(data.sent_at) : null
    this.received_at = data.received_at ? new Date(data.received_at) : null
  }
}
