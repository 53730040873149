import { defineMessages } from 'react-intl';

export default defineMessages({
  dates: { id: 'app.components.ConcessionSelection.Dates.dates' },
  buy: { id: 'app.components.ConcessionSelection.Dates.buy' },
  renew: { id: 'app.components.ConcessionSelection.Dates.renew' },
  duration: { id: 'app.components.ConcessionSelection.Dates.duration' },
  expiry: { id: 'app.components.ConcessionSelection.Dates.expiry' },
  concessionType: {
    id: 'app.components.ConcessionSelection.Dates.concessionType',
  },
  timeLeft: { id: 'app.components.ConcessionSelection.Dates.timeLeft' },
  yearsLeft: { id: 'app.components.ConcessionSelection.Dates.yearsLeft' },
  unlimited: { id: 'app.components.ConcessionSelection.Dates.unlimited' },
});
