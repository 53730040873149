import { assert } from 'lib/Assert';

export const APP = 'App';

export const Path = {
  ACCOUNT: '/account',
  AGENCIES: '/funding_agencies',
  BILLING: '/facturation',
  CITYHALLS: '/mairies',
  CLIENTS: '/clients',
  CLIENT: (id: number | string): string => `/clients/${id}`,
  CONSULATES: '/consulats',
  CREMATORIUMS: '/crematoriums',
  DEAL: (uuid: string, section?: string): string =>
    `/deals/${uuid}${section ? `#${section}` : ''}`,
  DEALS: '/deals',
  ENTITIES: '/lieux',
  FLIGHTS: '/vols',
  FORGOTTEN_PASSWORD: '/mot-de-passe-oublie',
  FUNERAL_PARLORS: '/funerariums',
  GRAVEYARDS: '/cimetieres',
  HOSPITALS: '/hopitaux',
  INDEX: '/',
  LOGIN: '/login',
  MAP: '/map',
  NEW: 'nouveau',
  NOT_FOUND: '/non-trouve',
  PAYMENTS: '/paiements',
  POLICE: '/polices',
  PREFECTURES: '/prefectures',
  RHAS: '/ars',
  SUPPLIER: (id: number | string, section?: string): string =>
    [Path.SUPPLIERS, id, section].filter(Boolean).join('/'),
  SUPPLIERS: '/fournisseurs',
  UPDATE_PASSWORD: '/changer-mot-de-passe',
  WORSHIPS: '/lieux-de-culte',
};

export const Route = {
  DEAL: `${Path.DEALS}/:dealUUID`,
};

/* eslint-disable prefer-destructuring */
const ADVITAM_WEBSITE = process.env.ADVITAM_WEBSITE;
const ADVITAM_ADMIN = process.env.ADVITAM_ADMIN;
const HOST = process.env.HOST || '';
const S3 = process.env.S3;
const SHARING_SPACE = process.env.SHARING_SPACE;
/* eslint-enable prefer-destructuring */

assert(ADVITAM_WEBSITE !== undefined);
assert(ADVITAM_ADMIN !== undefined);
assert(S3 !== undefined);
assert(SHARING_SPACE !== undefined);

export const Url = {
  ADVITAM_ADMIN,
  ADVITAM_WEBSITE,
  APP: `${ADVITAM_WEBSITE}/app`,
  MEMORIAL: `${ADVITAM_WEBSITE}/app/memoire`,
  PAYMENT_APP: (dealUuid: string): string =>
    `${ADVITAM_WEBSITE}/paiement?deal=${dealUuid}`,
  SELF: HOST,
  STAMP_IMAGE: `${S3}images/illustrations/tampon.png`,
};

export const NEW_PATH = 'nouveau';
export const DEALS_PATH = Path.DEALS;
export const AGENCIES_PATH = Path.AGENCIES;
export const SUPPLIERS_PATH = Path.SUPPLIERS;
export const ACCOUNT_PATH = Path.ACCOUNT;
export const CLIENTS_PATH = Path.CLIENTS;
export const CREMATORIUMS_PATH = Path.CREMATORIUMS;
export const MAP_PATH = Path.MAP;
export const CITYHALLS_PATH = Path.CITYHALLS;
export const WORSHIPS_PATH = Path.WORSHIPS;
export const LOGIN_PATH = Path.LOGIN;
export const PREFECTURE_PATH = Path.PREFECTURES;
export const HRA_PATH = Path.RHAS;
export const HOSPITAL_PATH = Path.HOSPITALS;
export const INDEX_PATH = Path.INDEX;
export const GRAVEYARD_PATH = Path.GRAVEYARDS;
export const FUNERAL_PARLOR_PATH = Path.FUNERAL_PARLORS;
export const POLICES_PATH = Path.POLICE;
export const BILLING_PATH = Path.BILLING;

export const ERROR = 'app/App/ERROR';

export const Responsive = {
  Breakpoint: {
    MOBILE: 647,
    TABLET: 1000,
    HD: 1280,
    LARGE: 1440,
  },
};

export const FILE_SIZE_UPLOAD_LIMIT = 8 * 1000 * 1000;

export const Advitam = {
  CITY: 'Paris',
};
