// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloseCross-module__cross--WVlPG{padding:0;display:inline-block;width:35px;height:auto;font-size:20px;position:absolute;top:0;right:0;cursor:pointer}.CloseCross-module__cross--WVlPG:hover{background:rgba(0,0,0,.1)}`, "",{"version":3,"sources":["webpack://./src/components/CloseCross/CloseCross.module.scss"],"names":[],"mappings":"AAAA,iCACE,SAAA,CACA,oBAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,cAAA,CAEA,uCACE,yBAAA","sourcesContent":[".cross {\n  padding: 0;\n  display: inline-block;\n  width: 35px;\n  height: auto;\n  font-size: 20px;\n  position: absolute;\n  top: 0;\n  right: 0;\n  cursor: pointer;\n\n  &:hover {\n    background: rgba(0, 0, 0, 0.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cross": `CloseCross-module__cross--WVlPG`
};
export default ___CSS_LOADER_EXPORT___;
