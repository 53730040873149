import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from 'lib/support';
import {
  makeSelectFuneralTeamUser,
  AppStateSubset as DataAppStateSubset,
} from 'slices/data';

import type { AppStateSubset } from '../../../slice';
import { makeSelectDeal } from '../../../selectors.typed';
import { DEAL } from '../../../constants';

export const updateOwner = createAsyncThunk(
  `${DEAL}/UPDATE_OWNER`,
  async (newOwnerId: number, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset & DataAppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);
    const newOwner = makeSelectFuneralTeamUser(newOwnerId)(state);
    assert(newOwner !== null);

    try {
      await request(Api.V1.Deals.User.update(deal.uuid, newOwner.email));
      return newOwner;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
