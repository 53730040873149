type Vector2 = [number, number];

function sub(lhs: Vector2, rhs: Vector2): Vector2 {
  return [lhs[0] - rhs[0], lhs[1] - rhs[1]];
}

function length(v: Vector2): number {
  return Math.sqrt(v[0] ** 2 + v[1] ** 2);
}

function distanceToPoint(lhs: Vector2, rhs: Vector2): number {
  return length(sub(lhs, rhs));
}

function toDegrees(radians: number): number {
  return radians / (Math.PI / 180.0);
}

export function getRotation(
  origin: Vector2,
  start: Vector2,
  end: Vector2,
): number {
  const originToStart = distanceToPoint(origin, start);
  const originToEnd = distanceToPoint(origin, end);
  const startToEnd = distanceToPoint(start, end);

  const ratio =
    (-(startToEnd ** 2) + originToStart ** 2 + originToEnd ** 2) /
    (2 * originToStart * originToEnd);
  let angle = Math.acos(ratio);

  const a = sub(start, origin);
  const b = sub(end, origin);
  const sign = a[0] * b[1] - a[1] * b[0];
  if (sign < 0) {
    angle *= -1;
  }

  return toDegrees(angle);
}
