import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';

import { getRecipientUniqueId } from '../../utils';
import { SEND_BATCH_MODAL_RECIPIENTS } from './constants';
import { createRecipient, destroyRecipient } from './thunk';

export interface State {
  isLoading: boolean;
  loadingRecipientIds: string[];
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [SEND_BATCH_MODAL_RECIPIENTS]: State;
}

export const initialState: State = {
  isLoading: false,
  loadingRecipientIds: [],
  error: null,
};

const slice = createSlice({
  name: SEND_BATCH_MODAL_RECIPIENTS,
  initialState,
  reducers: {},
  /* eslint-disable no-param-reassign */
  extraReducers: builder => {
    builder.addCase(createRecipient.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createRecipient.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createRecipient.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyRecipient.pending, (state, { meta }) => {
      state.loadingRecipientIds.push(getRecipientUniqueId(meta.arg));
      state.error = null;
    });
    builder.addCase(destroyRecipient.fulfilled, (state, { meta }) => {
      state.loadingRecipientIds = state.loadingRecipientIds.filter(
        id => id !== getRecipientUniqueId(meta.arg),
      );
    });
    builder.addCase(destroyRecipient.rejected, (state, { payload, meta }) => {
      state.loadingRecipientIds = state.loadingRecipientIds.filter(
        id => id !== getRecipientUniqueId(meta.arg),
      );
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export default slice;
