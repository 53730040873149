/**
 * Removes elements from array corresponding to indexes and returns an array of removed elements.
 *
 * *Note:* This function mutates `array`.
 */
export function pullAt<T>(array: T[], ...indexes: Array<number[] | number>): T[] {
  const flattenedIndexes = indexes.flat()
  const result = flattenedIndexes.map(i => array[i])

  let lastI: number | undefined
  flattenedIndexes
    .sort((a, b) => b - a)
    .forEach(i => {
      if (i !== lastI) {
        array.splice(i, 1)
      }
      lastI = i
    })

  return result
}
