import type { ReactNode } from 'react';

import './Tinymce';

interface WithTinyProps {
  children: ReactNode;
}

export default function WithTiny({ children }: WithTinyProps): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
