import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, Text } from '@advitam/ui';
import RefreshIcon from '@advitam/ui/images/icons/arrow-rotate-right.svg';

import {
  makeSelectCompletedItemCount,
  makeSelectItemCount,
} from '../selectors';
import { fetchItems } from '../thunk';
import { setIsOpen } from '../CreationModal/slice';
import messages from './messages';
import style from './Header.module.scss';

export default function Header(): JSX.Element {
  const dispatch = useDispatch();
  const itemCount = useSelector(makeSelectItemCount());
  const completedItemCount = useSelector(makeSelectCompletedItemCount());

  const onItemsRefresh = useCallback((): void => {
    dispatch(fetchItems());
  }, [dispatch]);

  const onCreationModalOpen = useCallback((): void => {
    dispatch(setIsOpen(true));
  }, [dispatch]);

  return (
    <div className={style.header}>
      <Text>
        <FormattedMessage
          id={messages.completedTasks.id}
          values={{ progress: completedItemCount, total: itemCount }}
        />
      </Text>
      <Button
        outline
        icon={<RefreshIcon />}
        className={style.refresh}
        onClick={onItemsRefresh}
      />
      <Button
        primary
        text={<FormattedMessage id={messages.add.id} />}
        className={style.add}
        onClick={onCreationModalOpen}
      />
    </div>
  );
}
