import { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { Outlet, useParams, useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { HardSpinner, PageSpinner } from '@advitam/ui';

import { Path } from 'containers/App/constants';
import { useEntitySeed } from 'containers/Entities/useEntitySeed';
import ResourceCable, {
  ResourceDestroyModal,
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { withDatasets } from 'slices/data';
import { assert } from 'lib/support';

import {
  makeSelectRawCrematorium,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectError,
} from './selectors';
import { fetchCrematorium } from './thunk';
import slice, { clearError, setCrematorium } from './slice';
import { CREMATORIUM_SKELETON } from './constants';

function Container(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const crematorium = useSelector(makeSelectRawCrematorium());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());
  const seed = useEntitySeed();

  const { id } = useParams();
  const crematoriumId = id && parseInt(id, 10);
  const canRender = !isLoading && crematorium;

  const fetchResource = useCallback(() => {
    assert(typeof crematoriumId === 'number');
    dispatch(fetchCrematorium(crematoriumId));
  }, [dispatch, crematoriumId]);

  useEffect(() => {
    if (!crematoriumId) {
      if (seed) {
        dispatch(setCrematorium({ ...CREMATORIUM_SKELETON, ...seed }));
      } else {
        navigate(Path.ENTITIES, { replace: true });
      }
    } else if (crematoriumId !== crematorium?.id) {
      fetchResource();
    }

    return () => {
      dispatch(clearError());
    };
  }, [crematoriumId]);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {crematoriumId && (
        <ResourceCable
          resourceId={crematoriumId}
          resourceType={ResourceRoomType.CREMATORIUM}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={fetchResource} />
          <ResourceDestroyModal path={Path.ENTITIES} />
        </ResourceCable>
      )}
    </>
  );
}

export default compose<typeof Container>(
  withSlice(slice),
  withDatasets('funeralTeam', 'worshipTypes'),
)(Container);
