import {
  FETCH_DOCUMENTS,
  ERROR_DOCUMENT_LIST,
  DOWNLOAD_FILES,
  MERGE_FILES,
  SET_LOADING,
  SET_DOCUMENTS,
  FETCH_DOCUMENT_VERSIONS,
  FETCH_DOCUMENT_VERSIONS_SUCCESS,
  CLEAR_DOCUMENT_VERSIONS,
  TOGGLE_LOCKED_VERSION,
  VERSION_LOCK_FAILURE,
  SET_SELECTED_DOCUMENTS,
  TOGGLE_DOCUMENT_SELECTION,
  RESET_DOCUMENTS,
  REMOVE_DOCUMENTS,
} from './constants';

export function fetchDocuments(dealUUID) {
  return {
    type: FETCH_DOCUMENTS,
    dealUUID,
  };
}

export function setDocuments(documents) {
  return {
    type: SET_DOCUMENTS,
    documents: documents || [],
  };
}

export function resetDocuments() {
  return {
    type: RESET_DOCUMENTS,
  };
}

/**
 * Set error value if get document value fail
 *
 * @param  {error} error Contain the value of the error returned by the API
 *
 * @return {object}    An action object with a type of ERROR_DOCUMENT_LIST
 */
export function errorDocumentList(error) {
  return {
    type: ERROR_DOCUMENT_LIST,
    error,
  };
}

/**
 * Download files
 *
 * @param  {String} dealUUID uuid of the deal
 * @param  {Array} files ids fo the files to download
 *
 * @returns {object}    An action object with a type of DOWNLOAD_FILES
 */
export function downloadFiles(dealUUID, files) {
  return {
    type: DOWNLOAD_FILES,
    files,
    dealUUID,
  };
}

/**
 * Merge files and download the result
 *
 * @param  {Array} files urls of the files to merge
 *
 * @returns {object}    An action object with a type of MERGE_FILES
 */
export function mergeFiles(files, filename) {
  return {
    type: MERGE_FILES,
    files,
    filename,
  };
}

/**
 * Set loading in store
 *
 * @param  {boolean} loading is loading
 */
export function setLoading(loading) {
  return {
    type: SET_LOADING,
    loading,
  };
}

export function fetchDocumentVersions(documentId) {
  return {
    type: FETCH_DOCUMENT_VERSIONS,
    documentId,
  };
}

export function fetchDocumentVersionsSuccess(versions) {
  return {
    type: FETCH_DOCUMENT_VERSIONS_SUCCESS,
    versions,
  };
}

export function clearDocumentVersions() {
  return {
    type: CLEAR_DOCUMENT_VERSIONS,
  };
}

export function toggleLockedVersion(document, version) {
  const isUnlock = document.locked && document.version.id === version.id;
  const lockedVersion = isUnlock ? null : version;

  return {
    type: TOGGLE_LOCKED_VERSION,
    documentId: document.id,
    lockedVersion,
    previousState: {
      locked: document.locked,
      version: {
        ...document.version,
      },
    },
  };
}

export function versionLockFailure(documentId, previousState) {
  return {
    type: VERSION_LOCK_FAILURE,
    documentId,
    previousState,
  };
}

export function setSelectedDocuments(documentIds) {
  return {
    type: SET_SELECTED_DOCUMENTS,
    documentIds,
  };
}

export function toggleDocumentSelection(doc) {
  return {
    type: TOGGLE_DOCUMENT_SELECTION,
    doc,
  };
}

export function removeDocuments(documentIds) {
  return {
    type: REMOVE_DOCUMENTS,
    documentIds,
  };
}
