import { defineMessages } from 'react-intl';

export default defineMessages({
  active: { id: 'app.containers.Suppliers.active' },
  createSupplier: { id: 'app.containers.Suppliers.createSupplier' },
  coffinsType: { id: 'app.containers.Suppliers.coffinsType' },
  departmentFilter: { id: 'app.containers.Suppliers.departmentFilter' },
  disabled: { id: 'app.containers.Suppliers.disabled' },
  email: { id: 'app.containers.Suppliers.email' },
  marbleType: { id: 'app.containers.Suppliers.marbleType' },
  negociatedFilter: { id: 'app.containers.Suppliers.negociatedFilter' },
  paperworksType: { id: 'app.containers.Suppliers.paperworksType' },
  phone: { id: 'app.containers.Suppliers.phone' },
  search: { id: 'app.containers.Suppliers.search' },
  status: { id: 'app.containers.Suppliers.status' },
  typeFilter: { id: 'app.containers.Suppliers.typeFilter' },
  transportType: { id: 'app.containers.Suppliers.transportType' },
});
