import { FormattedMessage } from 'react-intl';
import { ResourceList } from '@advitam/ui';
import messages from '../messages';
import style from '../Warehouses.module.scss';

export default function WarehousesHeader(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell>
        <FormattedMessage id={messages.status.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.name.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.department.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.actions.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
