import { ReactNode } from 'react'
import { Field } from 'react-final-form'
import SelectableAutosuggest, { Result } from '../../UI/Autosuggest/Selectable'

interface SelectableAutocompleteProps<T> {
  name: string
  placeholder?: string
  label?: ReactNode
  suggestions: Result<T>[]
  fetchSuggestions?: (query: string) => Promise<Result<T>[]>
  minCharsRequest?: number
}

export default function SelectableAutocomplete<T>({
  name,
  label,
  placeholder,
  suggestions,
  fetchSuggestions,
  minCharsRequest,
}: SelectableAutocompleteProps<T>): JSX.Element {
  return (
    <Field<T[]> name={name}>
      {({ input }): JSX.Element => (
        <SelectableAutosuggest<T>
          value={input.value || []}
          placeholder={placeholder}
          label={label}
          suggestions={suggestions}
          fetchSuggestions={fetchSuggestions}
          onChange={input.onChange}
          onBlur={input.onBlur}
          minCharsRequest={minCharsRequest}
        />
      )}
    </Field>
  )
}
