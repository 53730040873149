import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import messages from './messages';
import style from './Input.module.scss';

export function Select({ className = '', children, ...props }) {
  return (
    <select {...props} className={`${style.select} ${className}`}>
      {children}
    </select>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export function SelectList({ options, value, emptyOption, ...props }) {
  const intl = useIntl();
  const formattedValue = value !== undefined && value !== null ? value : '';

  return (
    <Select {...props} value={formattedValue}>
      {emptyOption && (
        <option key="_default" value="">
          {intl.formatMessage({ ...messages.missingInformation })}
        </option>
      )}
      {options.map(option => (
        <option key={`${option.value}_${props.name}`} value={option.value}>
          {option.message ? intl.formatMessage(option.message) : option.name}
        </option>
      ))}
    </Select>
  );
}

SelectList.propTypes = {
  options: PropTypes.array.isRequired,
  emptyOption: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default SelectList;
