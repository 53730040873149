import { useEffect, useRef } from 'react'

import { isEqual } from '@advitam/support'

type UseEffectParams = Parameters<typeof useEffect>
type DependencyList = UseEffectParams[1]

export function useDeepCompareMemoize(value: DependencyList): [number] {
  const ref = useRef<DependencyList>()
  const signalRef = useRef<number>(0)

  if (!isEqual(value, ref.current)) {
    ref.current = value
    signalRef.current += 1
  }

  return [signalRef.current]
}
