import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import AutoComplete from 'containers/AutoComplete';
import { ButtonV2 as Button, CrossButton } from 'components/Button';
import Input from 'components/Input';
import messagesAction from 'messages/actions';
import { isEmpty } from 'utils/functions';
import messages from 'containers/Deal/DealFuneral/StepsSection/Services/messages';

const SERVICE_TYPE = 'service';

class AddServicesDialog extends PureComponent {
  state = {
    /** new service to add */
    service: {
      type: SERVICE_TYPE,
      priceLine: {
        description: '',
      },
    },
  };

  /**
   * Update the value of description
   *
   * @param     {String}    value     New value for description
   */

  onInputChange = ({ target: { value } }) => {
    if (isEmpty(value)) return;
    const { service } = this.state;
    this.setState({
      service: {
        ...service,
        priceLine: {
          description: value,
        },
      },
    });
  };

  /**
   * Clear the value of Input
   */

  clearInput = () => {
    const { service } = this.state;
    this.setState({
      service: {
        ...service,
        priceLine: {
          description: '',
        },
      },
    });
  };

  /**
   * Check if service is already exist in array
   *
   * @returns {Boolean}
   */

  isServiceAlreadyExist = () => {
    const { services } = this.props;
    const { service } = this.state;
    return services.find(
      serviceCurrent =>
        serviceCurrent.priceLine.description === service.priceLine.description,
    );
  };

  /**
   * Add a new service
   */

  addService = () => {
    const { service } = this.state;
    if (isEmpty(service.priceLine.description)) return;
    if (this.isServiceAlreadyExist()) return;

    const { onSelect } = this.props;
    onSelect(service.type, service.priceLine.description);
    this.clearInput();
  };

  /**
   * When user only press Enter key, execute addService
   *
   * @param key         Key pressed by user, String
   */

  onKeyPress = ({ key }) => {
    const isEnter = key === 'Enter';
    if (isEnter) this.addService();
  };

  render() {
    const { onSelect, services, deleteItem, intl } = this.props;

    const { service } = this.state;

    return (
      <div className="otherFuneralServices">
        <div className="otherFuneralServices__addService">
          <div className="otherFuneralServices__label">
            <FormattedMessage {...messages.searchHeader} />
          </div>

          <AutoComplete
            url="/api/v1/autocompletes/funeral_work_types?q="
            getKey={item => item.id}
            getValue={item => item.description}
            onSelect={(_, item) => onSelect(item.type, item.description)}
            fullWidth
            auth
          />
        </div>

        <div className="otherFuneralServices__addService">
          <div className="otherFuneralServices__label">
            <FormattedMessage {...messages.addService} />
          </div>

          <div
            onKeyPress={this.onKeyPress}
            role="button"
            tabIndex={0}
            className="otherFuneralServices__addService__inputContainer"
          >
            <Input
              value={service.priceLine.description}
              onChange={this.onInputChange}
              className="otherFuneralServices__addService__inputContainer__input"
              placeholder={intl.formatMessage({ ...messages.addService })}
            />
          </div>

          <Button
            onClick={this.addService}
            className="otherFuneralServices__addService__button"
          >
            <FormattedMessage {...messagesAction.add} />
          </Button>
        </div>

        <div className="otherFuneralServices__servicesContainer">
          {services.map((s, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="otherFuneralServices__servicesContainer__service"
            >
              <h4>{s.priceLine.description}</h4>
              {deleteItem && (
                <CrossButton
                  onClick={() =>
                    deleteItem(s.type, s.priceLine.description, index)
                  }
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

AddServicesDialog.propTypes = {
  /** function to select a service */
  onSelect: PropTypes.func.isRequired,
  /** function to delete a service */
  deleteItem: PropTypes.func,
  /** list of services */
  services: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AddServicesDialog);
