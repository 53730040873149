import ImagePage from '../Page/Image';
import File, { connected, FileProps } from '.';

interface ImageFileState {
  src: string;
  isEditingImage: boolean;
}

class ImageFile extends File<ImageFileState> {
  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate({ blob }: FileProps): void {
    if (blob !== this.props.blob) {
      this.cleanup();
      this.init();
    }
  }

  componentWillUnmount(): void {
    this.cleanup();
  }

  private onImageSave(blob: Blob | null): void {
    const { setFile } = this.props;

    setFile(blob);
    this.setState(state => ({ ...state, isEditingImage: false }));
  }

  render(): JSX.Element | null {
    const { miniatures } = this.props;
    const { src, isEditingImage } = this.state;

    return (
      <ImagePage
        miniature={miniatures}
        src={src}
        isEditing={isEditingImage}
        onImageSave={(blob: Blob | null): void => this.onImageSave(blob)}
        pageNumber={0}
      />
    );
  }

  private init(): void {
    const { blob, setPageCount } = this.props;
    this.setState({
      ...this.state,
      src: blob ? URL.createObjectURL(blob) : '',
      isEditingImage: !this.props.miniatures,
    });
    setPageCount(1);
  }

  private cleanup(): void {
    const { src } = this.state;
    URL.revokeObjectURL(src);
  }
}

export default connected(ImageFile);
