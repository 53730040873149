import { FormattedMessage } from 'react-intl'

import GoogleLogo from '../../images/logos/google.svg'
import Text from '../Text'
import Heading from '../Heading'
import messages from './messages'
import style from './GoogleRate.module.scss'
import Stars from '../Stars'
import Link from '../Link'

interface GoogleRateProps {
  rate: number
  href: string
  internal?: boolean
  horizontal?: boolean
  className?: string
}

export default function GoogleRate({
  rate,
  horizontal,
  href,
  internal,
  className = '',
}: GoogleRateProps): JSX.Element {
  const classes = [style.rating, className]
  if (horizontal) {
    classes.push(style.horizontal)
  }

  return (
    <div className={classes.join(' ')}>
      <Heading className={style.title} tagName="span">
        <b>{rate}</b>
      </Heading>
      <Stars rate={rate} className={style.stars} />
      <GoogleLogo className={style.logo} />
      <Link href={href} internal={internal} newTab={!internal} className={style.link}>
        <Text small>
          <FormattedMessage id={messages.readReviews.id} />
        </Text>
      </Link>
    </div>
  )
}
