import Text from '../../Text'
import Thumbnail from './parts/Thumbnail'
import style from './Testimonials.module.scss'

const MAX_RATE = 5

interface ReviewItemProps {
  rate?: number
  commentary: string
  firstname: string
  lastname: string
  city: string
  squaredThumbnail?: boolean
  imageSrc?: string | null
}

export default function ReviewItem({
  rate,
  commentary,
  firstname,
  lastname,
  city,
  squaredThumbnail,
  imageSrc,
}: ReviewItemProps): JSX.Element {
  return (
    <div className={style.item}>
      <Thumbnail
        imageSrc={imageSrc}
        firstname={firstname}
        lastname={lastname}
        squaredThumbnail={squaredThumbnail}
      />
      {rate && (
        <div className={style.rate}>
          <Text large>
            <b>{rate}</b>
          </Text>
          <Text>
            <b> /{MAX_RATE}</b>
          </Text>
        </div>
      )}
      <div>
        <Text small>{commentary}</Text>
      </div>
      <div>
        <Text>
          <b>
            {firstname} {lastname}
          </b>
        </Text>
        <Text> - {city}</Text>
      </div>
    </div>
  )
}
