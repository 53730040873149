import { omit } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { replace } from 'redux-first-history';

import Api, { Pagination, request } from '@advitam/api';

import { assert } from 'lib/support';

import { Path } from '../../constants';
import { makeSelectRawSupplier } from '../../selectors';
import { AppStateSubset as SupplierAppStateSubset } from '../../slice';
import { SUPPLIER_WAREHOUSES } from './constants';
import { makeSelectCurrentPage, makeSelectFilters } from './selectors';
import { AppStateSubset as WarehousesAppStateSubset } from './slice';

type AppStateSubset = SupplierAppStateSubset & WarehousesAppStateSubset;

export const fetchWarehouses = createAsyncThunk(
  `${SUPPLIER_WAREHOUSES}_SHOW`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;

    const filters = makeSelectFilters()(state);
    const currentPage = makeSelectCurrentPage()(state);

    const supplier = makeSelectRawSupplier()(state);
    assert(supplier?.id !== undefined);

    try {
      const response = await request(
        Api.V1.Suppliers.Warehouses.index(supplier.id, {
          ...omit(filters, 'cityhall'),
          covered_cityhall_id: filters.cityhall?.id,
          page: currentPage,
        }),
      );

      const hasMore = Pagination.getPageCount(response) > currentPage;
      return { warehouses: response.body, hasMore };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const duplicateWarehouse = createAsyncThunk(
  `${SUPPLIER_WAREHOUSES}_DUPLICATE`,
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.Suppliers.Warehouses.Duplicate.create(id),
      );

      // TODO: seed with route state or warehouse crud slice action
      // warning: warehouse slice could not be mount
      dispatch(replace(`./${Path.WAREHOUSES}/${body.id}`, { warehouse: body }));

      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const destroyWarehouse = createAsyncThunk(
  `${SUPPLIER_WAREHOUSES}_DESTROY`,
  async (id: number, { rejectWithValue }) => {
    try {
      await request(Api.V1.Suppliers.Warehouses.destroy(id));
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const disableWarehouse = createAsyncThunk(
  `${SUPPLIER_WAREHOUSES}_DISABLE`,
  async (id: number, { rejectWithValue }) => {
    try {
      await request(Api.V1.Suppliers.Warehouses.Disabled.update(id, true));
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
