import { defineMessages } from 'react-intl';

export default defineMessages({
  pending: {
    id: 'app.messages.state.pending',
  },
  failed: {
    id: 'app.messages.state.failed',
  },
  received: {
    id: 'app.messages.state.received',
  },
  nothing: {
    id: 'app.messages.state.nothing',
  },
  dispatched: {
    id: 'app.messages.state.dispatched',
  },
  sent: {
    id: 'app.messages.state.sent',
  },
});
