import { authenticate, formdataEncoded } from '../../lib/decorators'
import { ApiRequestDescriptor } from '../../lib/request'
import { post } from '../../lib/methods'
import { UploadFileJSON } from '../../models/Upload/File'

export class Files {
  @authenticate
  @formdataEncoded
  static create(data: Blob): ApiRequestDescriptor<UploadFileJSON> {
    return post('/api/v1/upload/files', { data })
  }
}
