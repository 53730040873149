// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Edit-module__modal--hW3Pf{padding:8px 16px 16px}`, "",{"version":3,"sources":["webpack://./src/containers/Billing/List/Edit/Edit.module.scss"],"names":[],"mappings":"AAAA,2BACE,qBAAA","sourcesContent":[".modal {\n  padding: 8px 16px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Edit-module__modal--hW3Pf`
};
export default ___CSS_LOADER_EXPORT___;
