import { ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { CONTENT_CONTAINER_ID } from './constants';

interface NavbarContentProps {
  children: ReactNode;
}

export default function NavbarContent({
  children,
}: NavbarContentProps): JSX.Element | null {
  const el = useMemo(() => document.getElementById(CONTENT_CONTAINER_ID), []);
  if (!el) {
    return null;
  }
  return createPortal(children, el);
}
