import { EntityInvoiceGroupType } from '@advitam/api/models/EntityInvoice/Group/Type';
import { EntityInvoicePrestationStatus } from '@advitam/api/models/EntityInvoice/Prestation/Status';
import type { NewInvoice, NewPrestation } from './types';

export const BILLING_EDIT_MODAL = 'Billing/EditModal';

export const INVOICE_SKELETON: NewInvoice = {
  id: undefined,
  group: {
    type: EntityInvoiceGroupType.PRESTATION,
    id: null,
    year: null,
    name: '',
  },
  entity: {
    type: null,
    id: null,
    name: null,
  },
  deal: null,
  invoice_id: null,
  paid_on: null,
  bank_transfer_label: null,
  cost_tax_excl: null,
  cost_tax_incl: null,
  file: null,
  prestations: [],
};

export const PRESTATION_SKELETON: NewPrestation = {
  id: undefined,
  description: '',
  status: EntityInvoicePrestationStatus.NOT_BILLED,
  validated_at: null,
  amount: 1,
  cost_ht: null,
  cost_ttc: null,
  deal: {
    id: null,
    uuid: null,
    description: '',
  },
};
