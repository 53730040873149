import type { ReactNode } from 'react';
import { Form } from 'react-final-form';

import { ActionsPopup } from 'containers/Crud';

import Sidebar from '../Sidebar';
import style from '../Client.module.scss';

interface WrapperProps<T> {
  children: ReactNode;
  initialValues?: T;
  onSubmit?: (values: T) => void;
}

export default function Wrapper<T>({
  children,
  initialValues,
  onSubmit = (): void => undefined,
}: WrapperProps<T>): JSX.Element {
  return (
    <Form<T> onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <Sidebar />
          {dirty && <ActionsPopup isNew={false} />}
          {children}
        </form>
      )}
    </Form>
  );
}
