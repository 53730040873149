import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { DealType } from '@advitam/api/models/Deal/Type';
import { Checkbox } from 'components/Input';
import Select from 'components/Select';
import { CLIENTS_PATH } from 'containers/App/constants';
import { assert } from 'lib/support';
import { Client, ClientRole } from 'models/Client';

import { makeSelectDeal } from '../../selectors.typed';
import { setRole, setEmpowered } from '../slice';
import messages from '../messages';
import style from './style.scss';

interface AdditionalFieldsProps {
  client: Client;
  idx: number;
}

export default function AdditionalFields({
  client,
  idx,
}: AdditionalFieldsProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);

  const roleOptions = [
    { value: ClientRole.OWNER, name: intl.formatMessage(messages.owner) },
    { value: ClientRole.EDITOR, name: intl.formatMessage(messages.editor) },
    { value: ClientRole.READER, name: intl.formatMessage(messages.reader) },
    { value: ClientRole.NOTARY, name: intl.formatMessage(messages.notary) },
  ];
  const rolePlaceholder =
    roleOptions.find(r => r.value === client.role)?.name ||
    intl.formatMessage(messages.role);

  return (
    <>
      <div className={style['select-field']}>
        <FormattedMessage {...messages.role} />
        {client.role === ClientRole.OWNER ? (
          <span>
            <FormattedMessage id={messages.owner.id} />
          </span>
        ) : (
          <Select
            items={roleOptions}
            initialValue={client.role}
            placeholder={rolePlaceholder}
            onChange={(value): void => {
              dispatch(setRole({ idx, role: value as ClientRole }));
              if (!deal.isFuneral && value === ClientRole.OWNER) {
                dispatch(setEmpowered(idx));
              }
            }}
          />
        )}
      </div>
      {deal.deal_type === DealType.FUNERAL && (
        <div>
          <FormattedMessage {...messages.empowered} />
          <Checkbox
            checked={client.special === true}
            onChange={(): void => {
              dispatch(setEmpowered(idx));
            }}
            name="special"
          />
        </div>
      )}
      {client.id && (
        <Link
          to={`${CLIENTS_PATH}/${client.id}`}
          target="_blank"
          className={`button ${style.action}`}
        >
          <FormattedMessage id={messages.goToClient.id} />
        </Link>
      )}
    </>
  );
}
