import { ReactNode } from 'react'

import Tag from '../Tag'
import Text from '../Text'
import Arrow from '../../images/icons/arrow-right.svg'
import style from './NewsCard.module.scss'
import Link from '../Link'

interface NewsCardProps {
  img: ReactNode
  tags?: ReactNode[]
  title?: ReactNode
  text: ReactNode
  link: string
  internal?: boolean
  className?: string
}

export default function NewsCard({
  img,
  tags,
  title,
  text,
  link,
  internal,
  className,
}: NewsCardProps): JSX.Element {
  const cardClasses = [style.card, !link && style.disabled, className].filter(Boolean)

  return (
    <Link className={cardClasses.join(' ')} href={link} internal={internal}>
      <span className={style.content}>
        {img}
        {tags && (
          <span className={style.tags}>
            {tags.map((tag, i) => (
              <Tag key={i}>{tag}</Tag>
            ))}
          </span>
        )}
        {title && (
          <Text>
            <b>{title}</b>
          </Text>
        )}
        <Text small>{text}</Text>
      </span>
      <span className={style.arrow}>
        <Arrow />
      </span>
    </Link>
  )
}
