import { Model } from 'models/Model';
import { PaymentProblem } from '../PaymentProblem';
import { DealState } from '../State';
import { DealType } from '../Type';
import { SummaryPaymentsJSON } from './Payments';

interface PartialPerson {
  id: number;
  name: string;
}

interface SummaryBase {
  id: number;
  uuid: string;
  deal_type: DealType;
  business_id: string;
  invoice_business_id: string | null;
  user_id: number | null;
  paid: boolean;
  price: number;
  payments: SummaryPaymentsJSON;
  payment_problem: PaymentProblem;
  state: DealState;
  defunct: PartialPerson | null;
  client: PartialPerson | null;
}

export interface SummaryJSON extends SummaryBase {
  ceremony_dt: string;
  remind_at: string | null;
}

export interface Summary extends SummaryBase {
  ceremony_dt: Date;
  remind_at: Date | null;
}

export class Summary extends Model<SummaryJSON> {
  constructor(data: SummaryJSON) {
    super(data);
    this.ceremony_dt = Model.parseDate(data.ceremony_dt);
    this.remind_at = Model.parseDate(data.remind_at, false);
  }
}
