import { ChangeEvent } from 'react'

/**
 * Sets the value of an input programatically
 *
 * Setting the input value with this helper (rather than just `input.value =`)
 * will fire a change event like regular typing would.
 *
 * @note While the input parameter is an HTMLInputElement, this should work
 * with at least HTMLTextareaElement as well, but this have not been tested.
 */
export function setValueWithEvent(input: HTMLInputElement, value: string | number): void {
  const setValue = Object.getOwnPropertyDescriptor(
    Object.getPrototypeOf(input),
    'value',
  )?.set?.bind(input)
  if (!setValue) {
    return
  }

  setValue(value)
  const ev = new Event('change', { bubbles: true })
  input.dispatchEvent(ev)
}

type ChangeHandler<T, ReturnType> = (ev: ChangeEvent<T>) => ReturnType

/**
 * Compose change handlers
 *
 * This chains them one by one. This helper is designed for sync change handlers
 * (or promises not needing to be awaited). Not supporting async handlers
 * called sequentially allows it to be perfectly reactive, and so a different
 * composer would be needed.
 */
export function composeOnChange<T>(
  ...onChanges: Array<ChangeHandler<T, void> | undefined>
): ChangeHandler<T, void> {
  return (ev: ChangeEvent<T>): void => {
    onChanges.forEach(onChange => {
      if (onChange) {
        onChange(ev)
      }
    })
  }
}
