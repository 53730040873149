import { isNaN } from 'lodash';

export function priceFromString(value?: string | null): number | null {
  if (value) {
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) ? parsedValue : null;
  }
  return null;
}

export function priceToString(price?: number | null): string {
  if (!price && price !== 0) {
    return '';
  }
  return String(price);
}

function isNumber(price?: number | string | null): price is number {
  return typeof price === 'number';
}

export function findValidPrice(
  prices: Array<number | null | undefined | string>,
): number | null {
  const validPrice = prices.find(isNumber);
  if (!validPrice && validPrice !== 0) {
    return null;
  }
  return validPrice;
}
