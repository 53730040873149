import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { SupportingDocumentOwnerType } from '@advitam/api/models/SupportingDocuments/OwnerType';
import { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import { assert } from '@advitam/support';
import Layout from 'containers/Crud/Layout';
import {
  SupportingDocuments,
  supportingDocumentsSlice,
  makeSelectSupportingDocuments,
  saveSupportingDocuments,
} from 'containers/Crud';

import Wrapper from './Wrapper';
import { makeSelectRawPolice } from '../selectors';
import type { PoliceForm } from './types';

function SupportingDocumentsSection(): JSX.Element {
  const dispatch = useDispatch();

  const police = useSelector(makeSelectRawPolice());
  assert(police?.id !== undefined);
  const documents = useSelector(makeSelectSupportingDocuments());

  const owner = useMemo(
    () => ({ type: SupportingDocumentOwnerType.POLICE, id: police.id }),
    [police.id],
  );

  const onSubmit = useCallback(
    ({ sectionValues }: PoliceForm<SupportingDocumentJSON[]>) => {
      dispatch(saveSupportingDocuments({ values: sectionValues, owner }));
    },
    [dispatch, owner],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={documents}>
      <Layout.Container>
        <SupportingDocuments owner={owner} />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(supportingDocumentsSlice)(SupportingDocumentsSection);
