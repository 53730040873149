import moment from 'moment';
import { BusinessUser } from '@advitam/api/models/User/BusinessUser';
import { createAction } from 'lib/reactvitam/action_cable';

export interface PoloPayload {
  user_id: number;
  session_id: string;
  date: string;
  last_action_dt: string;
}

function polo(
  session_id: string,
  user: BusinessUser,
  last_action_dt: string,
): PoloPayload {
  return {
    user_id: user.id,
    session_id,
    date: moment(Date.now()).toISOString(),
    last_action_dt,
  };
}

export default createAction(polo, 'polo');
