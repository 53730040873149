import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { makeSelectUser } from 'slices/auth';
import { Path as CrudPath, Sidebar, usePreventLeaving } from 'containers/Crud';
import crudMessages from 'containers/Crud/messages';

import { Path } from './constants';
import messages from './messages';
import { makeSelectPolice, makeSelectIsUpdatingName } from './selectors';

export default function PoliceSidebar(): JSX.Element {
  const intl = useIntl();

  const user = useSelector(makeSelectUser());
  const isUpdatingName = useSelector(makeSelectIsUpdatingName());
  const police = useSelector(makeSelectPolice());

  const { dirty } = useFormState();
  usePreventLeaving(!police?.id || dirty);

  return (
    <Sidebar.Menu>
      <Sidebar.Title
        isCreation={!police?.id}
        isLoading={isUpdatingName}
        fieldName="police.name"
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
      />
      {police?.current_update_dt && police?.current_update_user_id && (
        <Sidebar.LastUpdate
          date={police.current_update_dt}
          userId={police.current_update_user_id}
        />
      )}
      <Sidebar.LinksSection>
        <Sidebar.Link
          to={Path.GENERAL}
          message={<FormattedMessage id={messages.general.id} />}
        />
        {police?.id && (
          <>
            {user?.isFuneralDirectorOrAbove && (
              <Sidebar.LinkGroup
                placeholder={
                  <FormattedMessage id={crudMessages.documents.id} />
                }
              >
                <Sidebar.Link
                  to={CrudPath.EDITABLE_DOCUMENTS}
                  message={
                    <FormattedMessage id={crudMessages.editableDocuments.id} />
                  }
                />
                <Sidebar.Link
                  to={CrudPath.SUPPORTING_DOCUMENTS}
                  message={
                    <FormattedMessage
                      id={crudMessages.supportingDocuments.id}
                    />
                  }
                />
              </Sidebar.LinkGroup>
            )}{' '}
            <Sidebar.Link
              to={CrudPath.OPENING_HOURS}
              message={<FormattedMessage id={crudMessages.openingHours.id} />}
            />
            <Sidebar.Link
              to={Path.MISCELLANEOUS}
              message={<FormattedMessage id={messages.miscellaneous.id} />}
            />
          </>
        )}
      </Sidebar.LinksSection>
    </Sidebar.Menu>
  );
}
