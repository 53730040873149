import { ReactNode, FocusEvent, MouseEvent, useEffect, useRef } from 'react'

interface PopupProps {
  children: ReactNode | ReactNode[]
  className?: string
  onFocusOut: () => void
  onClick?: (event: MouseEvent) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  autoFocus?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  styleName?: string
}

/**
 * Popup may operate in two different ways :
 * 1. Always displayed and containing the trigger, in which case you do not want to set autoFocus
 * 2. Displayed only when opened, in which case you want to set autoFocus
 */
export default function Popup({
  children,
  className,
  onFocusOut,
  onClick,
  onMouseEnter,
  onMouseLeave,
  autoFocus,
}: PopupProps): JSX.Element {
  const container = useRef(null)
  useEffect(() => {
    const parent = container.current as HTMLElement | null
    if (autoFocus && parent) {
      parent.focus()
    }
  }, [autoFocus])

  const onBlur = (event: FocusEvent<HTMLDivElement>): void => {
    const { currentTarget } = event

    // This prevent the onBlur event to occur before the children onClick event,
    // which was causing dropdown closure before the children event trigger.
    // see: https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue

    // Check the newly focused element in the next tick of the event loop
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        onFocusOut()
      }
    }, 0)
  }

  return (
    <div
      role="button"
      tabIndex={-1}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onKeyDown={(): void => {
        /* ignore */
      }}
      ref={container}
      className={className}
    >
      {children}
    </div>
  )
}
