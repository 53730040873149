import { TodoItemBase, TodoItemBaseJSON } from './Base'
import { TodoType } from './Type'
import { DocumentBatchTodoAction, DocumentBatchTodoActionJSON } from './Action/DocumentBatch'

interface SendBatchTodoItemBase extends TodoItemBaseJSON {
  todo_type: TodoType.SEND_BATCH
}

export interface SendBatchTodoItemJSON extends SendBatchTodoItemBase {
  action: DocumentBatchTodoActionJSON
}

export interface SendBatchTodoItem extends SendBatchTodoItemBase {
  action: DocumentBatchTodoAction
}

export class SendBatchTodoItem extends TodoItemBase<SendBatchTodoItemJSON> {
  constructor(data: SendBatchTodoItemJSON) {
    super(data)
    this.action = new DocumentBatchTodoAction(data.action)
  }
}

export { DocumentBatchTodoAction }
export type { DocumentBatchTodoActionJSON }
