/*
 *
 * AutoComplete reducer
 *
 */

import {
  QUERY_ENTITIES_LIST,
  ERASE_ENTITIES_LIST,
  ERROR_ENTITIES_LIST,
  UPDATE_ENTITIES_LIST,
} from './constants';

const initialState = {
  entities: [],
  error: null,
};

function autoCompleteReducer(state = initialState, action) {
  switch (action.type) {
    case QUERY_ENTITIES_LIST:
      return {
        ...state,
        error: null,
      };
    case ERASE_ENTITIES_LIST:
      return {
        ...state,
        entities: [],
        error: null,
      };
    case UPDATE_ENTITIES_LIST:
      return {
        ...state,
        entities: action.entities,
        error: null,
      };
    case ERROR_ENTITIES_LIST:
      return {
        ...state,
        entities: [],
        error: action.error,
      };
    default:
      return state;
  }
}

export default autoCompleteReducer;
