import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EntityGeoshapesKeyJSON } from '@advitam/api/models/Entity/GeoshapesKey';
import { PrestationCoverageJSON } from '@advitam/api/models/Prestation';

import { MAP_PRESTATIONS } from './constants';
import {
  fetchPrestationCoverages,
  fetchPrestationsCoverageKeys,
} from './thunk';

export interface State {
  activeKeys: string[];
  prestationsCoverageKeys: EntityGeoshapesKeyJSON[];
  prestationsCoveragesByKey: Record<string, PrestationCoverageJSON[]>;
}

export interface AppStateSubset {
  [MAP_PRESTATIONS]: State;
}

export const initialState: State = {
  activeKeys: [],
  prestationsCoverageKeys: [],
  prestationsCoveragesByKey: {},
};

const slice = createSlice({
  name: MAP_PRESTATIONS,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    addActiveKey(state, { payload }: PayloadAction<string>) {
      state.activeKeys.push(payload);
    },
    removeActiveKey(state, { payload }: PayloadAction<string>) {
      state.activeKeys = state.activeKeys.filter(key => key !== payload);
    },
    resetActiveKeys(state) {
      state.activeKeys = initialState.activeKeys;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchPrestationsCoverageKeys.fulfilled,
      (state, { payload }) => {
        state.prestationsCoverageKeys = payload;
      },
    );
    builder.addCase(
      fetchPrestationCoverages.fulfilled,
      (state, { payload, meta: { arg: prestationKey } }) => {
        state.prestationsCoveragesByKey[prestationKey] = payload;
      },
    );
  },
  /* eslint-enable no-param-reassign */
});

export const { addActiveKey, removeActiveKey, resetActiveKeys } = slice.actions;
export default slice;
