import { authenticate, withSessionId } from '../../lib/decorators'
import { put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { DealKoReason } from '../../models/Deal/KoReason'
import { DealState, DealStateJSON } from '../../models/Deal/State'

interface UpdatePayload {
  ko_comment: string | null
  ko_reason: DealKoReason | null
  state: DealState
}

export class State {
  @withSessionId
  @authenticate
  static update(dealUuid: string, state: UpdatePayload): ApiRequestDescriptor<DealStateJSON> {
    return put(`/api/v1/deals/${dealUuid}/state`, state)
  }
}
