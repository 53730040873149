import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { ConcessionTermJSON } from '@advitam/api/models/Concession';
import type { GraveyardConcessionTypeJSON } from '@advitam/api/models/Graveyard';

import { GRAVEYARD_CONCESSION_INFOS } from './constants';
import type { AppStateSubset, State } from './slice';

type GraveyardSelectors<T> = Selector<AppStateSubset, T>;

const selectGraveyardDomain = (state: AppStateSubset): State =>
  state[GRAVEYARD_CONCESSION_INFOS];

export const makeSelectConcessionTerms = (): GraveyardSelectors<
  ConcessionTermJSON[]
> =>
  createSelector(
    selectGraveyardDomain,
    ({ concessionTerms }) => concessionTerms,
  );

export const makeSelectConcessionTypes = (): GraveyardSelectors<
  GraveyardConcessionTypeJSON[]
> =>
  createSelector(
    selectGraveyardDomain,
    ({ concessionTypes }) => concessionTypes,
  );

export const makeSelectIsLoading = (): GraveyardSelectors<boolean> =>
  createSelector(selectGraveyardDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): GraveyardSelectors<SerializedApiError | null> =>
  createSelector(selectGraveyardDomain, ({ error }) => error);
