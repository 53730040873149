import { ReactNode } from 'react'

import ProductCard from '../ProductCard'

import PlanCard from './PlanCard'
import type { Product } from './types'
import { FormattedPrice } from '../../Format'
import Link from '../../Link'
import style from './PlanCard.module.scss'

interface DropdownCardProps {
  title: ReactNode
  subTitle: ReactNode
  price: number
  products: Array<Product>
  className?: string
}

export default function ProductPlanCard({
  title,
  subTitle,
  price,
  products,
  className,
}: DropdownCardProps): JSX.Element {
  return (
    <PlanCard title={title} subTitle={subTitle} price={price} className={className}>
      {products.map(product => (
        <Link href={product.link} internal={product.internalLink} className={style.product_card}>
          <ProductCard
            key={product.name}
            title={product.name}
            image={product.image}
            price={<FormattedPrice value={product.price} />}
          />
        </Link>
      ))}
    </PlanCard>
  )
}
