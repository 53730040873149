import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { put } from 'api/methods';

import { PaymentProblem as PaymentProblemEnum } from 'models/Deal/PaymentProblem';

export class PaymentProblem {
  @authenticate
  static update(
    uuid: string,
    payload: PaymentProblemEnum,
  ): ApiRequestDescriptor<void> {
    return put(`/api/v1/deals/${uuid}/payment_problem`, {
      payment_problem: payload,
    });
  }
}
