import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import { Input } from '@advitam/ui';

import { makeSelectCanEdit } from '../../../../../selectors';
import style from '../List.module.scss';
import { useProductPrefix } from '../../ProductListProvider';

const FIELD_PLACEHOLDER = '__';

interface SupplierRefCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function SupplierRefCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: SupplierRefCellProps): JSX.Element | null {
  const canUserEdit = useSelector(makeSelectCanEdit());

  const prefix = useProductPrefix(rowIndex);

  return (
    <div
      style={wrapperStyle}
      className={[className, style.supplier_cell].join(' ')}
    >
      <Input
        name={`${prefix}.supplier_ref`}
        disabled={!canUserEdit}
        placeholder={FIELD_PLACEHOLDER}
        className={style.input}
      />
    </div>
  );
}
