import { ComponentType, SVGAttributes } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button, ButtonLike, Tooltip } from '@advitam/ui';

import { makeSelectIsLoading } from '../selectors';
import style from './EditAction.module.scss';

interface EditActionProps {
  Icon: ComponentType<SVGAttributes<SVGElement>>;
  message: MessageDescriptor;
  onClick?: () => void;
}

export default function EditAction({
  Icon,
  message,
  onClick,
}: EditActionProps): JSX.Element {
  const isLoading = useSelector(makeSelectIsLoading());

  return (
    <Tooltip
      disabled={isLoading}
      content={<FormattedMessage id={message.id} />}
      contentClassName={style.action}
    >
      {onClick ? (
        <Button
          outline
          disabled={isLoading}
          icon={<Icon />}
          onClick={onClick}
        />
      ) : (
        <ButtonLike outline disabled={isLoading} icon={<Icon />} />
      )}
    </Tooltip>
  );
}
