import { createAction } from 'lib/reactvitam/action_cable';

export interface DisconnectPayload {
  user_id: number;
}

/**
 * An user disconnected
 * Not for sending by the frontend, this is only the interface for reception of
 * backend messages.
 */
function disconnect(user_id: number): DisconnectPayload {
  return { user_id };
}

export default createAction(disconnect, 'disconnect');
