/*
 * Wrapper for react-big-calendar, hiding the mess made by the typing fixes and
 * the localizer initialization
 */

import { DateTime } from 'luxon'
import { Calendar, CalendarProps, DateLocalizer, Event, luxonLocalizer } from 'react-big-calendar'
import withDragAndDropBuggy, {
  withDragAndDropProps,
} from 'react-big-calendar/lib/addons/dragAndDrop'
import { useIntl } from 'react-intl'

type WithDragAndDrop = (calendar: typeof Calendar) => typeof Calendar
const withDragAndDrop = withDragAndDropBuggy as WithDragAndDrop
const DragAndDropCalendar = withDragAndDrop(Calendar)

interface LuxonLocalizerConfig {
  firstDayOfWeek: number
}
type LuxonLocalizer = (
  LuxonDateTime: typeof DateTime,
  config: LuxonLocalizerConfig,
) => DateLocalizer
const localizer = (luxonLocalizer as LuxonLocalizer)(DateTime, {
  firstDayOfWeek: 1,
})

interface ReactBigCalendarProps<TEvent extends Event>
  extends Omit<CalendarProps<TEvent>, 'localizer' | 'culture'>,
    withDragAndDropProps<TEvent> {
  /* Min time of the day to display, in hours */
  minTime?: number
  /* Max time of the day to display, in hours */
  maxTime?: number
}

export default function ReactBigCalendar<TEvent extends Event = Event>(
  props: ReactBigCalendarProps<TEvent>,
): JSX.Element {
  const intl = useIntl()

  return (
    <DragAndDropCalendar<TEvent>
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      culture={intl.locale}
      localizer={localizer}
    />
  )
}
