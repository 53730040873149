import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import PhoneIcon from 'components/PhoneIcon';
import AutoComplete from 'containers/AutoComplete';
import SelectAddress from 'components/SelectAddress';
import DatePicker from 'components/DatePicker';
import { TextInput, CivInput, EmailInput } from 'components/Input';
import { validateEmail } from 'utils/functions';
import ModalWarning from 'components/ModalWarning';
import messagesCrud from 'messages/crud';

import { makeSelectDealClients } from '../Clients/selectors.ts';
import * as actions from './actions';
import messages from './messages';

class EditPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /** is it an error */
      isError: false,
      /** is the modal open */
      isModalOpen: false,
      /** item selected */
      item: {},
    };
  }

  closeModal = () => this.setState({ isModalOpen: false, item: {} });

  openModal = item => this.setState({ isModalOpen: true, item });

  setResponse = item => {
    const { people, getClientData } = this.props;
    if (item.id) {
      getClientData(item.id, people);
    }
    this.setState({ isModalOpen: false, isError: false, item: {} });
  };

  setResponseWithState = () => {
    const { item } = this.state;
    this.setResponse(item);
  };

  checkExistingData = item => {
    const { person } = this.props;
    this.setState({ item });
    // We check if data is empty
    if (
      !person.birthDate &&
      !person.birthLocation &&
      !person.firstName &&
      !person.lastName &&
      !person.birthName &&
      !person.nationality &&
      person.contact &&
      !person.contact.phone &&
      !person.contact.phone_2 &&
      !person.contact.phone_3
    ) {
      // There is no data so we set the data
      this.setResponse(item);
    } else {
      // There is data so we show a warning modal
      this.openModal(item);
    }
  };

  onChangeEmail = (value, updateObj) => {
    const { handleUpdate } = this.props;
    // Email has been updated, we remove the client id
    const { id, ...newCLient } = updateObj;
    handleUpdate(newCLient);

    if (value && !validateEmail(value)) {
      // We check if value isn't valid
      this.setState({ isError: true });
      return;
    }
    this.setState({ isError: false });
  };

  /**
   * Update the civilization of person
   *
   * @param   {Object}    event                 Object received when user change the value of select
   * @param   {Object}    event.target          Object with data of select
   * @param   {String}    event.target.value    New civilization of person
   */
  onChangeCiv = ({ target: { value } }) => {
    const { person, handleUpdate } = this.props;
    const updatedPerson = {
      ...person,
      type: value,
    };
    handleUpdate(updatedPerson);
  };

  render() {
    const {
      person,
      handleUpdate,
      withoutCiv,
      withoutFirstname,
      withoutBirthname,
      withContact,
      noEmailAutoComplete,
      withLink,
      withAddress,
      withWork,
      withBirthDate,
      withBirthPlace,
      withNationality,
      readonlyMail,
      intl,
      withIconBlack,
      children,
      isDeceased,
    } = this.props;
    const { isModalOpen, isError } = this.state;

    const phone = (person.contact && person.contact.phone) || '';
    const isPhoneValid =
      (phone &&
        !isValidPhoneNumber(phone) &&
        intl.formatMessage(messagesCrud.errorPhoneFormat)) ||
      undefined;
    const phone2 = (person.contact && person.contact.phone_2) || '';
    const isPhone2Valid =
      (phone2 &&
        !isValidPhoneNumber(phone2) &&
        intl.formatMessage(messagesCrud.errorPhoneFormat)) ||
      undefined;

    return (
      <div className="editPerson">
        <ModalWarning
          isModalOpen={isModalOpen}
          closeModal={() => this.closeModal()}
          setResponseWithState={() => this.setResponseWithState()}
        />
        {!withoutCiv && (
          <div>
            <FormattedMessage {...messages.type} />
            <CivInput
              onChange={this.onChangeCiv}
              name="type"
              value={person.type || ''}
            />
          </div>
        )}
        {!withoutFirstname && (
          <div>
            <FormattedMessage {...messages.firstName} />
            <TextInput
              onChange={({ target: { value } }) =>
                handleUpdate({ ...person, firstName: value })
              }
              name="firstName"
              value={person.firstName || ''}
            />
          </div>
        )}
        <div>
          <FormattedMessage {...messages.lastName} />
          <TextInput
            onChange={({ target: { value } }) =>
              handleUpdate({ ...person, lastName: value })
            }
            name="lastName"
            value={person.lastName || ''}
          />
        </div>
        {!withoutBirthname && (
          <div>
            <FormattedMessage {...messages.birthName} />
            <TextInput
              onChange={({ target: { value } }) =>
                handleUpdate({ ...person, birthName: value })
              }
              name="birthName"
              value={person.birthName || ''}
            />
          </div>
        )}
        {withContact && !noEmailAutoComplete && (
          <div className="editPerson__email">
            <div className="editPerson__email__field">
              <FormattedMessage {...messages.email} />
              <AutoComplete
                url="/api/v1/autocompletes/clients?q="
                paramsToAddToQuery={{
                  email_present: true,
                }}
                getKey={item => item.id}
                getValue={item => item.description}
                onSelect={(_, item) => this.checkExistingData(item)}
                onChange={(e, value) =>
                  this.onChangeEmail(value, {
                    ...person,
                    contact: {
                      ...person.contact,
                      email: value,
                    },
                  })
                }
                auth
                initialValue={(person.contact && person.contact.email) || ''}
                saveValueOnSelect
                isEncodingQuery
              />
            </div>
            <div className="editPerson__email--error">
              {isError && <FormattedMessage {...messages.emailIsNotValid} />}
            </div>
          </div>
        )}
        {withContact && noEmailAutoComplete && (
          <div>
            <FormattedMessage {...messages.email} />
            <EmailInput
              onChange={({ target: { value } }) =>
                handleUpdate({
                  ...person,
                  contact: { ...person.contact, email: value },
                })
              }
              name="email"
              disabled={readonlyMail}
              value={(person.contact && person.contact.email) || ''}
            />
          </div>
        )}
        {withContact && !isDeceased && (
          <>
            <div>
              <FormattedMessage {...messages.mobilePhone} />
              {phone && isValidPhoneNumber(phone) && (
                <a
                  href={`tel:${phone}`}
                  className={`editPerson__phone__icon ${
                    withIconBlack ? 'editPerson__phone__icon--black' : ''
                  }`}
                >
                  <PhoneIcon />
                </a>
              )}
              <PhoneInput
                onChange={value =>
                  handleUpdate({
                    ...person,
                    contact: { ...person.contact, phone: value || null },
                  })
                }
                value={phone}
                error={isPhoneValid}
                initialValueFormat="national"
                defaultCountry="FR"
              />
            </div>
            <div>
              <FormattedMessage {...messages.homePhone} />
              {phone2 && isValidPhoneNumber(phone2) && (
                <a href={`tel:${phone2}`} className="editPerson__phone__icon">
                  <PhoneIcon />
                </a>
              )}
              <PhoneInput
                onChange={value =>
                  handleUpdate({
                    ...person,
                    contact: { ...person.contact, phone_2: value || null },
                  })
                }
                value={phone2}
                error={isPhone2Valid}
                initialValueFormat="national"
                defaultCountry="FR"
              />
            </div>
          </>
        )}
        {withLink && (
          <div>
            <FormattedMessage {...messages.link} />
            <TextInput
              onChange={({ target: { value } }) =>
                handleUpdate({ ...person, link: value })
              }
              name="link"
              value={person.link || ''}
            />
          </div>
        )}
        {withAddress && (
          <div className="editPerson__address">
            <FormattedMessage {...messages.address} />
            <SelectAddress
              address={person.address}
              onChange={address =>
                handleUpdate({
                  ...person,
                  address,
                })
              }
            />
          </div>
        )}
        {withNationality && (
          <div>
            <FormattedMessage {...messages.nationality} />
            <TextInput
              onChange={({ target: { value } }) =>
                handleUpdate({ ...person, nationality: value })
              }
              name="nationality"
              value={person.nationality || ''}
            />
          </div>
        )}
        {withBirthDate && (
          <DatePicker
            onChange={value => handleUpdate({ ...person, birthDate: value })}
            fieldName="dateOfBirth"
            date={person.birthDate}
          />
        )}
        {withBirthPlace && (
          <div>
            <FormattedMessage {...messages.birthLocation} />
            <TextInput
              onChange={({ target: { value } }) =>
                handleUpdate({ ...person, birthLocation: value })
              }
              name="birthLocation"
              value={person.birthLocation || ''}
            />
          </div>
        )}
        {withWork && (
          <div>
            <FormattedMessage {...messages.work} />
            <TextInput
              onChange={({ target: { value } }) =>
                handleUpdate({ ...person, work: value })
              }
              name="work"
              value={person.work || ''}
            />
          </div>
        )}
        {children}
      </div>
    );
  }
}

EditPerson.propTypes = {
  /** person data */
  person: PropTypes.object.isRequired,
  /** function to update person */
  handleUpdate: PropTypes.func.isRequired,
  /** show contact */
  withContact: PropTypes.bool,
  /** show address */
  withAddress: PropTypes.bool,
  /** show nationality */
  withNationality: PropTypes.bool,
  /** show birth place */
  withBirthPlace: PropTypes.bool,
  /** show birth date */
  withBirthDate: PropTypes.bool,
  /** show link */
  withLink: PropTypes.bool,
  /** show work */
  withWork: PropTypes.bool,
  /** show firstname */
  withoutFirstname: PropTypes.bool,
  /** show birthName */
  withoutBirthname: PropTypes.bool,
  /** show civ */
  withoutCiv: PropTypes.bool,
  /** hide email autocomplete */
  noEmailAutoComplete: PropTypes.bool,
  /** hide email autocomplete */
  readonlyMail: PropTypes.bool,
  /** get client data from DB */
  getClientData: PropTypes.func,
  /** people data */
  people: PropTypes.number,
  intl: PropTypes.object.isRequired,
  /** color of icons */
  withIconBlack: PropTypes.bool,
  // Additional markup to put at the end
  children: PropTypes.node,
  isDeceased: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  clients: makeSelectDealClients(),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { clients } = stateProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getClientData: (id, index) =>
      dispatch(actions.getClientData(id, index, clients)),
  };
};

const withConnect = connect(mapStateToProps, null, mergeProps);

export default compose(withConnect, injectIntl)(EditPerson);
