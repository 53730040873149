import { createSelector, Selector } from 'reselect';

import type { ErrorProps } from 'components/ErrorModal';

import {
  makeSelectError as makeSelectLegacyDealError,
  makeSelectLoading as makeSelectDealIsLoading,
} from '../selectors.js';
import {
  makeSelectError as makeSelectDealError,
  makeSelectIsSaving as makeSelectDealIsSaving,
  makeSelectRawDeal,
} from '../selectors.typed';
import {
  makeSelectError as makeSelectDealFuneralError,
  makeSelectLoading as makeSelectDealFuneralIsLoading,
} from '../DealFuneral/selectors.js';
import type { AppStateSubset as DealAppStateSubset } from '../slice';
import {
  makeSelectAbilities,
  makeSelectError as makeSelectIdentityError,
} from '../Sections/Identity/selectors';
import { AppStateSubset as IdentityAppStateSubset } from '../Sections/Identity/slice';
import { makeSelectError as makeSelectLayersError } from './LayersModal/selectors';
import type { AppStateSubset as LayersAppStateSubset } from './LayersModal/slice';
import { FuneralForm } from './types';

type AppStateSubset = IdentityAppStateSubset &
  DealAppStateSubset &
  LayersAppStateSubset;
type FuneralSelector<T> = Selector<AppStateSubset, T>;

export const makeSelectIsSaving = (): FuneralSelector<boolean> =>
  createSelector(
    makeSelectDealIsLoading(),
    makeSelectDealIsSaving(),
    makeSelectDealFuneralIsLoading(),
    (dealIsLoading, dealIsSaving, dealFuneralIsLoading) =>
      dealFuneralIsLoading || dealIsSaving || dealIsLoading,
  );

export const makeSelectError = (): FuneralSelector<ErrorProps> =>
  createSelector(
    makeSelectDealError(),
    makeSelectLegacyDealError(),
    makeSelectDealFuneralError(),
    makeSelectLayersError(),
    makeSelectIdentityError(),
    (dealError, legacyDealError, dealFuneralError, layersError) =>
      dealFuneralError || legacyDealError || dealError || layersError,
  );

export const makeSelectFormInitialValues = (): FuneralSelector<FuneralForm> =>
  createSelector(
    makeSelectRawDeal(),
    makeSelectAbilities(),
    (deal, abilities) => ({ deal, abilities } as FuneralForm),
  );
