import { assert } from '@advitam/support'

export interface ImageProviderProps {
  src: unknown
  alt?: string
  width?: number
  height?: number
  className?: string
}

export default function ImageProvider({
  src,
  alt,
  width,
  height,
  className,
}: ImageProviderProps): JSX.Element {
  assert(
    typeof src === 'string',
    'Expected Image.src to be a string. Do you need a custom provider ?',
  )

  return <img src={src} alt={alt} width={width} height={height} className={className} />
}
