import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';

import { DUPLICATION_BUTTON } from './constants';
import { duplicateDeal } from './thunk';

export interface State {
  isConfirmationModalOpen: boolean;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DUPLICATION_BUTTON]: State;
}

const initialState: State = {
  isConfirmationModalOpen: false,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: DUPLICATION_BUTTON,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setIsConfirmationModalOpen: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isConfirmationModalOpen = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(duplicateDeal.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(duplicateDeal.fulfilled, state => {
      state.isLoading = false;
      state.isConfirmationModalOpen = false;
    });
    builder.addCase(duplicateDeal.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setIsConfirmationModalOpen } = slice.actions;
export default slice;
