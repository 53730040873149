import { ReactElement, ReactNode, useCallback } from 'react'
import Dropzone from 'react-dropzone'

import { Arrays } from '@advitam/support'

import UploadIcon from '../../../../images/icons/upload.svg'
import Text from '../../../Text'
import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'

import style from './File.module.scss'

function getFileName(value: File | string | undefined): string {
  if (!value) {
    return ''
  }

  if (typeof value === 'string') {
    return Arrays.last(value.split('/')) || ''
  }

  return value.name
}

interface FileInputProps {
  value?: HTMLInputElement['value']
  label?: ReactNode
  name?: string
  placeholder?: string
  error?: ReactElement | boolean
  disabled?: boolean
  tooltip?: ReactNode
  onChange: (value: File) => void
  onBlur?: () => void
  className?: string
  allowedTypes?: string[]
  renderBelow?: (value: string) => ReactNode
  prefix?: ReactNode
}

export default function FileInput({
  className,
  name,
  value,
  label,
  tooltip,
  placeholder,
  onChange,
  onBlur,
  error,
  allowedTypes,
  disabled,
  renderBelow,
  prefix,
}: FileInputProps): JSX.Element {
  const wrapperClasses = [
    uiStyle.input,
    style.wrapper,
    error && uiStyle.error,
    disabled && uiStyle.disabled,
    className,
  ].filter(Boolean)

  const elementClasses = [
    uiStyle.input_element,
    value && value.length > 0 && uiStyle.filled,
    prefix && uiStyle.with_prefix,
  ].filter(Boolean)

  const onDrop = useCallback(
    (files: File[]): void => {
      onChange(files[0])
      if (onBlur) {
        onBlur()
      }
    },
    [onChange, onBlur],
  )

  return (
    <Dropzone accept={allowedTypes} multiple={false} onDrop={onDrop}>
      {({ getRootProps, getInputProps }): JSX.Element => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...getRootProps()}>
          <Label value={label} tooltip={tooltip} error={error} className={uiStyle.field}>
            <span className={wrapperClasses.join(' ')}>
              {prefix}
              <input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getInputProps()}
                name={name}
              />
              <span className={style.input_wrapper}>
                <input
                  value={getFileName(value)}
                  type="text"
                  placeholder={placeholder}
                  className={elementClasses.join(' ')}
                  readOnly
                />
                <UploadIcon className={style.icon} />
              </span>
            </span>
            {renderBelow && (
              <Text small className={uiStyle.below}>
                {renderBelow(value || '')}
              </Text>
            )}
          </Label>
        </div>
      )}
    </Dropzone>
  )
}
