import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { useThunkDispatch } from '@advitam/react';
import { ActionFactory, AnyFunc } from './createAction';

export interface CableThunkApi<Payload> {
  payload: Payload;
  send<Action extends { action: string }>(action: Action): void;
  dispatch: ReturnType<typeof useThunkDispatch>;
  getState: () => unknown;
}

export type MessageThunk<State, Payload> = (
  api: CableThunkApi<Payload>,
) => State | void;

export interface DisconnectedArguments {
  willAttemptReconnect: boolean;
}

export interface Events {
  initialized: never;
  connected: never;
  appear: unknown;
  away: unknown;
  install: string;
  rejected: unknown;
  uninstall: unknown;
  update: unknown;
  disconnected: DisconnectedArguments;
}

export const CableEvents = {
  INITIALIZED: 'initialized' as const,
  CONNECTED: 'connected' as const,
  DISCONNECTED: 'disconnected' as const,
  APPEAR: 'appear' as const,
  AWAY: 'away' as const,
  INSTALL: 'install' as const,
  REJECTED: 'rejected' as const,
  UNINSTALL: 'uninstall' as const,
  UPDATE: 'update' as const,
};

export interface EventHandlersBuilder<State> {
  addCase<K extends keyof Events>(
    action: K,
    reducer: CaseReducer<State, PayloadAction<Events[K]>> | null | undefined,
    thunk?: MessageThunk<State, Events[K]>,
  ): void;
}

export interface MessageHandlersBuilder<State> {
  addCase<ActionType extends AnyFunc>(
    action: ActionFactory<ActionType>,
    reducer:
      | CaseReducer<State, PayloadAction<ReturnType<typeof action>>>
      | null
      | undefined,
    thunk?: MessageThunk<State, ReturnType<typeof action>>,
  ): void;
}

export interface CableConfig<State> {
  name: string;
  initialState: State;
  events?: (builder: EventHandlersBuilder<State>) => void;
  messages?: (builder: MessageHandlersBuilder<State>) => void;
}
