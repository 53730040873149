function parse(value: string | null): string | null {
  if (value === '' || value === undefined) {
    return null
  }
  return value
}

function format(value: string | null): string | null {
  if (!value) {
    return null
  }
  return value.trim()
}

export const NullableString = { format, parse }
