import { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmationModal, HardSpinner } from '@advitam/ui';
import { assert } from 'lib/support';

import { cloneClient } from '../utils';
import messages from './messages';
import {
  makeSelectAbilityIndex,
  makeSelectIsLoading,
  makeSelectPendingClient,
} from './selectors';
import { clear } from './slice';
import { CLIENT_FORM_KEYS } from './constants';

export default function ClientAutocompletion(): JSX.Element {
  const dispatch = useDispatch();
  const form = useForm();

  const isLoading = useSelector(makeSelectIsLoading());
  const abilityIndex = useSelector(makeSelectAbilityIndex());
  const pendingClient = useSelector(makeSelectPendingClient());

  const onConfirm = useCallback(() => {
    assert(pendingClient !== null);
    assert(abilityIndex !== null);

    form.batch(() => {
      CLIENT_FORM_KEYS.forEach(key => {
        form.change(
          `abilities[${abilityIndex}].client.${key}`,
          pendingClient[key],
        );
      });
    });
    dispatch(clear());
  }, [dispatch, form, abilityIndex, pendingClient]);

  const onCancel = useCallback(() => {
    assert(abilityIndex !== null);
    cloneClient(form, abilityIndex);
    dispatch(clear());
  }, [abilityIndex, dispatch, form]);

  return (
    <>
      {isLoading && <HardSpinner />}
      <ConfirmationModal
        className="new-design"
        isOpen={abilityIndex !== null}
        title={<FormattedMessage id={messages.title.id} />}
        text={<FormattedMessage id={messages.text.id} />}
        confirm={<FormattedMessage id={messages.confirm.id} />}
        onConfirm={onConfirm}
        cancel={<FormattedMessage id={messages.cancel.id} />}
        onCancel={onCancel}
      />
    </>
  );
}
