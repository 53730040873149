import { chatbotApiEndpoint } from '../../../lib/decorators'
import { del, post, put } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'

export const Feedbacks = {
  create: chatbotApiEndpoint(
    (runId: string, useful: boolean): ApiRequestDescriptor<void> =>
      post(`/v1/messages/${runId}/feedbacks`, { useful }),
  ),
  update: chatbotApiEndpoint(
    (runId: string, useful: boolean): ApiRequestDescriptor<void> =>
      put(`/v1/messages/${runId}/feedbacks`, { useful }),
  ),
  destroy: chatbotApiEndpoint(
    (runId: string): ApiRequestDescriptor<void> => del(`/v1/messages/${runId}/feedbacks`),
  ),
}
