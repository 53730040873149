import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { CrematoriumCeremonyFilters } from '@advitam/api/v1/Crematoriums/Ceremonies';
import type { AgeRangeJSON } from '@advitam/api/models/Crematorium/AgeRange';
import type { RoomJSON } from '@advitam/api/models/Crematorium/Room';
import type { CeremonyJSON } from '@advitam/api/models/Crematorium/Ceremony';

import { CREMATORIUM_CEREMONIES } from './constants';
import { fetchCeremonies, initialize, saveCeremonies } from './thunk';

export interface AppStateSubset {
  [CREMATORIUM_CEREMONIES]: State;
}

export interface State {
  ageRanges: AgeRangeJSON[];
  rooms: RoomJSON[];
  ceremonies: CeremonyJSON[];
  filters: CrematoriumCeremonyFilters;
  isLoading: boolean;
  isSaving: boolean;
  error: SerializedApiError | null;
}

export const initialState: State = {
  ageRanges: [],
  rooms: [],
  ceremonies: [],
  filters: {},
  isLoading: false,
  isSaving: false,
  error: null,
};

const slice = createSlice({
  name: CREMATORIUM_CEREMONIES,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setFilters: (
      state,
      { payload }: PayloadAction<CrematoriumCeremonyFilters>,
    ) => {
      state.filters = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCeremonies.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchCeremonies.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ceremonies = payload;
    });
    builder.addCase(fetchCeremonies.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(initialize.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(initialize.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ceremonies = payload.ceremonies;
      state.ageRanges = payload.ageRanges;
      state.rooms = payload.rooms;
    });
    builder.addCase(initialize.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveCeremonies.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveCeremonies.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.ceremonies = payload;
    });
    builder.addCase(saveCeremonies.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { setFilters } = slice.actions;
export default slice;
