import { ResourceType } from '@advitam/api/models/DocumentEditions';
import { DocumentEdition } from '../types';

function hasDocumentError(doc: DocumentEdition): boolean {
  if (doc.resource.id || doc.pages.every(page => page.isRemoved)) {
    return false;
  }

  if (
    doc.resource.type === ResourceType.DEAL &&
    doc.resource.deal.document_name
  ) {
    return false;
  }

  return true;
}

export function validateDocuments(
  docs: DocumentEdition[],
): string[] | undefined {
  const errors = docs.filter(hasDocumentError).map(doc => doc.uuid);

  if (errors.length > 0) {
    return errors;
  }

  return undefined;
}
