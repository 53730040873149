import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { MailRedactionVariablesJSON } from '@advitam/api/models/Chatbot/MailRedaction/Variables';
import { assert } from '@advitam/support';
import {
  Button,
  ErrorText,
  FormattedApiError,
  FormLayout,
  HtmlInput,
  Input,
  PageSpinner,
  Spinner,
  SubmitButton,
} from '@advitam/ui';
import { Control } from '@advitam/ui/components/TinyMCE/types';
import { setDocumentsGenerationQueue } from 'containers/DocumentGenerationModal/slice';
import WithTiny from 'components/WithTiny';
import actionsMessages from 'messages/actions';

import SendBatchModalAutocompletes from './Autocompletes';
import messages from './messages';
import {
  makeSelectBatch,
  makeSelectDocumentsToGenerate,
  makeSelectError,
  makeSelectFormInitialValues,
  makeSelectIsLoading,
  makeSelectIsSending,
} from './selectors';
import { sendBatch } from './thunk';
import { SendBatchForm } from './types';
import { validateMissingVariables } from './validators';
import style from './SendBatchModal.module.scss';

interface ContentProps {
  onClose: () => void;
}

export default function Content({ onClose }: ContentProps): JSX.Element {
  const dispatch = useDispatch();

  const batch = useSelector(makeSelectBatch());
  const error = useSelector(makeSelectError());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSending = useSelector(makeSelectIsSending());
  const initialValues = useSelector(makeSelectFormInitialValues());
  const documentsToGenerate = useSelector(makeSelectDocumentsToGenerate());
  const shouldGenerateDocuments = documentsToGenerate.length > 0;

  const onSubmit = useCallback(
    (values: SendBatchForm): void => {
      if (shouldGenerateDocuments) {
        dispatch(setDocumentsGenerationQueue(documentsToGenerate));
        return;
      }

      dispatch(sendBatch(values));
    },
    [dispatch, documentsToGenerate, shouldGenerateDocuments],
  );

  const getRedactionVariables = useCallback((): MailRedactionVariablesJSON => {
    assert(batch !== null);
    return {
      subject: batch.subject,
      recipient_type: batch.recipients[0]?.name || '',
      recipient_name: batch.recipients[0]?.type || '',
      documents: batch.documents.map(document => document.name),
    };
  }, [batch]);

  if (isLoading) {
    return <PageSpinner />;
  }

  if (error && !batch) {
    return (
      <ErrorText>
        <FormattedApiError error={error} />
      </ErrorText>
    );
  }

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit }): JSX.Element => (
        <form onSubmit={handleSubmit}>
          <SendBatchModalAutocompletes />

          <FormLayout.Row>
            <Input
              required
              disabled={isSending}
              name="subject"
              label={<FormattedMessage id={messages.subject.id} />}
              errorMessages={messages}
              validate={validateMissingVariables}
            />
          </FormLayout.Row>
          <FormLayout.Row>
            <WithTiny>
              <HtmlInput
                required
                withControls={[Control.LINK, Control.LISTS, Control.AI]}
                disabled={isSending}
                name="content"
                label={<FormattedMessage id={messages.message.id} />}
                className={style.editor}
                errorMessages={messages}
                validate={validateMissingVariables}
                aiRedactionVariables={getRedactionVariables}
              />
            </WithTiny>
          </FormLayout.Row>

          {error && (
            <ErrorText>
              <FormattedApiError error={error} />
            </ErrorText>
          )}

          <div className={style.actions}>
            <SubmitButton
              primary
              icon={isSending ? <Spinner /> : undefined}
              disabled={isSending}
              text={
                <FormattedMessage
                  id={
                    shouldGenerateDocuments
                      ? messages.validateDocuments.id
                      : messages.send.id
                  }
                />
              }
            />
            <Button
              outline
              onClick={onClose}
              text={<FormattedMessage id={actionsMessages.cancel.id} />}
              className={style.cancel}
            />
          </div>
        </form>
      )}
    </Form>
  );
}
