import { DateTime } from 'luxon'

function parse(value: string, _name: string): number {
  return DateTime.fromISO(value).setZone('utc', { keepLocalTime: true }).toUnixInteger()
}

function format(value: number | null, _name: string): string | null {
  if (value === null) {
    return null
  }
  return DateTime.fromSeconds(value)
    .setZone('utc')
    .setZone('local', { keepLocalTime: true })
    .toISO()
}

export const Timestamp = { parse, format }
