import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { AgeRangeJSON } from '@advitam/api/models/Crematorium/AgeRange';
import {
  Button,
  ConfirmationModal,
  HardSpinner,
  MessageModal,
  ResourceList,
} from '@advitam/ui';
import ErrorBanner from 'components/ErrorBanner';

import Header from './List/Header';
import Row from './List/Row';
import { fetchAgeRanges } from './thunk';
import {
  makeSelectError,
  makeSelectHasAlreadyInUseError,
  makeSelectIsLoading,
  makeSelectIsSaving,
} from './selectors';
import messages from './messages';
import { clearHasAlreadyInUseError } from './slice';
import { CrematoriumForm } from '../types';
import style from './AgeRanges.module.scss';

export default function AgeRanges(): JSX.Element {
  const dispatch = useDispatch();

  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const hasAlreadyInUseError = useSelector(makeSelectHasAlreadyInUseError());
  const error = useSelector(makeSelectError());

  const [indexOfAgeRangeToDelete, setIndexOfAgeRangeToDelete] = useState<
    number | null
  >(null);

  const form = useForm<CrematoriumForm<AgeRangeJSON[]>>();
  const { values } = useFormState<CrematoriumForm<AgeRangeJSON[]>>();
  const ageRanges = values.sectionValues;

  const fetchResources = useCallback(() => {
    dispatch(fetchAgeRanges());
  }, [dispatch]);

  const onCreate = useCallback(() => {
    form.change('sectionValues', [{} as AgeRangeJSON, ...ageRanges]);
  }, [form, ageRanges]);

  const onCloseErrorAgeRangeInUse = useCallback(() => {
    dispatch(clearHasAlreadyInUseError());
  }, [setIndexOfAgeRangeToDelete]);

  const closeDeletionConfirmation = useCallback(() => {
    setIndexOfAgeRangeToDelete(null);
  }, [setIndexOfAgeRangeToDelete]);

  const onDeletionConfirmed = useCallback(() => {
    form.change(
      'sectionValues',
      ageRanges.filter((_, index) => index !== indexOfAgeRangeToDelete),
    );
    closeDeletionConfirmation();
  }, [closeDeletionConfirmation, form, ageRanges, indexOfAgeRangeToDelete]);

  useEffect(fetchResources, []);

  return (
    <>
      <ResourceList.Container
        filters={
          <div className={style.add}>
            <Button
              primary
              onClick={onCreate}
              text={<FormattedMessage id={messages.add.id} />}
            />
          </div>
        }
        header={<Header />}
        fetchResources={fetchResources}
        hasMore={false}
        isLoading={isLoading}
      >
        <Field name="sectionValues">
          {(): null =>
            // Declare the field so final-form picks up array changes
            null
          }
        </Field>
        {ageRanges.map((ageRange, index) => (
          <Row
            // eslint-disable-next-line react/no-array-index-key
            key={`${ageRange.id || ''}-${index}`}
            prefix={`sectionValues[${index}]`}
            onDelete={(): void => setIndexOfAgeRangeToDelete(index)}
          />
        ))}
      </ResourceList.Container>

      {isSaving && <HardSpinner />}
      {indexOfAgeRangeToDelete !== null && (
        <ConfirmationModal
          isOpen
          text={
            <FormattedMessage
              id={messages.deletionConfirmationText.id}
              values={{
                title: ageRanges[indexOfAgeRangeToDelete]?.title,
              }}
            />
          }
          confirm={
            <FormattedMessage id={messages.deletionConfirmationConfirm.id} />
          }
          onConfirm={onDeletionConfirmed}
          cancel={
            <FormattedMessage id={messages.deletionConfirmationCancel.id} />
          }
          onCancel={closeDeletionConfirmation}
        />
      )}
      {hasAlreadyInUseError && (
        <MessageModal
          isOpen
          title={
            <FormattedMessage id={messages.errorAgeRangeInUseModalTitle.id} />
          }
          message={
            <FormattedMessage id={messages.errorAgeRangeInUseModalText.id} />
          }
          ctaText={
            <FormattedMessage id={messages.errorAgeRangeInUseModalAction.id} />
          }
          onClose={onCloseErrorAgeRangeInUse}
        />
      )}

      {error && <ErrorBanner errors={error} />}
    </>
  );
}
