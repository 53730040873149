// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HardSpinner-module__container--ElwY4{position:fixed;inset:0;z-index:25;justify-content:center;display:flex;align-items:center;background-color:rgba(255,255,255,.9);pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/HardSpinner/HardSpinner.module.scss"],"names":[],"mappings":"AAEA,sCACE,cAAA,CACA,OAAA,CACA,UAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,qCAAA,CACA,mBAAA","sourcesContent":["@import 'src/scss/mixins/vars';\n\n.container {\n  position: fixed;\n  inset: 0;\n  z-index: $z-index__modal + 5;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  background-color: rgb(255 255 255 / 90%);\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HardSpinner-module__container--ElwY4`
};
export default ___CSS_LOADER_EXPORT___;
