import Dots from './Dots'
import Item from './Item'

interface ListProps {
  currentPage: number
  totalPages: number
  onClick?: (i: number) => void
  getHref?: (i: number) => string
}

const VISIBLE_ITEMS = 3

export default function List({
  currentPage,
  totalPages,
  onClick,
  getHref,
}: ListProps): JSX.Element {
  const endRangeStart = totalPages - VISIBLE_ITEMS
  const inStartRange = currentPage < VISIBLE_ITEMS
  const inEndRange = currentPage - 1 > endRangeStart

  const getIsHidden = (idx: number): boolean => {
    if (idx === 1 || idx === totalPages) return false

    if (!inStartRange && !inEndRange) {
      const visibleRangeStart = currentPage - Math.floor(VISIBLE_ITEMS / 2)
      const visibleRangeEnd = currentPage + Math.floor(VISIBLE_ITEMS / 2)

      return idx < visibleRangeStart || idx > visibleRangeEnd
    }

    if (inStartRange && idx > VISIBLE_ITEMS) {
      return true
    }

    return inEndRange && idx <= endRangeStart
  }

  const shouldAddDots = totalPages > VISIBLE_ITEMS + 1
  const items = [...Array<number>(totalPages)].map((_e, idx) => {
    const pageNumber = idx + 1
    if (getIsHidden(pageNumber)) return null
    return (
      <Item
        key={pageNumber}
        index={pageNumber}
        isSelected={currentPage === pageNumber}
        onClick={onClick}
        getHref={getHref}
      />
    )
  })

  if (shouldAddDots) {
    if (!inStartRange) {
      items.splice(1, 0, <Dots key="dots-start" />)
    }
    if (!inEndRange) {
      items.splice(items.length - 1, 0, <Dots key="dots-end" />)
    }
  }

  return <>{items}</>
}
