import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { assert } from '@advitam/support';
import { Button, NumberInput, Tooltip } from '@advitam/ui';
import LockIcon from '@advitam/ui/images/icons/lock.svg';
import { messages as crudMessages } from 'containers/Crud';
import { makeSelectUser } from 'slices/auth';

import { FuneralForm } from '../../Funeral/types';
import messages from './messages';
import style from './Home.module.scss';

const FIELD_NAME = 'deal.manual_price';

export default function PriceInput(): JSX.Element {
  const user = useSelector(makeSelectUser());
  assert(user !== null);

  const form = useForm<FuneralForm>();
  const {
    values: {
      deal: { manual_price: manualPrice, auto_price: autoPrice },
    },
  } = useFormState<FuneralForm>();

  const onPriceLockToggle = useCallback(() => {
    if (manualPrice === null) {
      form.change(FIELD_NAME, autoPrice);
    } else {
      form.change(FIELD_NAME, null);
    }
  }, [form, autoPrice, manualPrice]);

  const isLockDisabled = !user.isAdmin;
  const buttonClasses = [
    style.price_input__button,
    manualPrice && style.active,
  ].filter(Boolean);

  return (
    <div className={style.price_input}>
      <NumberInput
        name={FIELD_NAME}
        placeholder={autoPrice?.toString()}
        suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        readOnly={isLockDisabled}
      />
      <Tooltip
        disabled={isLockDisabled}
        content={
          <FormattedMessage
            id={
              manualPrice === null
                ? messages.lockPrice.id
                : messages.unlockPrice.id
            }
          />
        }
      >
        <Button
          outline
          className={buttonClasses.join(' ')}
          icon={<LockIcon />}
          onClick={onPriceLockToggle}
          disabled={isLockDisabled}
        />
      </Tooltip>
    </div>
  );
}
