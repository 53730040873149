import { useEffect, useRef } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Subscription } from '@rails/actioncable';

import type { Store } from '@advitam/react/redux';
import { useDeepCompareEffect } from 'lib/reactvitam';
import { Cable, disconnectIfUnused } from 'lib/reactvitam/action_cable';

import getInjectors from './reducerInjectors';

export function useCable<InitParams>(
  cable: Cable<InitParams>,
  cableParams: InitParams,
): Subscription | null {
  const dispatch = useDispatch();
  const store = useStore() as Store;
  const subscription = useRef<Subscription | null>(null);

  useEffect(() => {
    getInjectors(store).injectReducer(cable.slice.name, cable.slice.reducer);
  }, []);

  useDeepCompareEffect(() => {
    subscription.current = cable.subscribe(cableParams, { dispatch });
    return (): void => {
      if (subscription.current) {
        subscription.current.unsubscribe();
        disconnectIfUnused();
      }
    };
  }, [cableParams]);

  return subscription.current;
}
