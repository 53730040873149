import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { DealDocumentBatchRecipientJSON } from '@advitam/api/models/Deal/Document/Batch';

import { getRecipientUniqueId } from '../../utils';
import { SEND_BATCH_MODAL_RECIPIENTS } from './constants';
import { AppStateSubset, State } from './slice';

type RecipientsSelector<T> = Selector<AppStateSubset, T>;

const selectRecipientsDomain = (state: AppStateSubset): State =>
  state[SEND_BATCH_MODAL_RECIPIENTS];

export const makeSelectIsLoading = (): RecipientsSelector<boolean> =>
  createSelector(selectRecipientsDomain, ({ isLoading }) => isLoading);

export const makeSelectLoadingRecipientIds = (): RecipientsSelector<string[]> =>
  createSelector(
    selectRecipientsDomain,
    ({ loadingRecipientIds }) => loadingRecipientIds,
  );

export const makeSelectIsRecipientLoading = (
  recipient: DealDocumentBatchRecipientJSON,
): RecipientsSelector<boolean> =>
  createSelector(makeSelectLoadingRecipientIds(), ids =>
    ids.includes(getRecipientUniqueId(recipient)),
  );

export const makeSelectError = (): RecipientsSelector<SerializedApiError | null> =>
  createSelector(selectRecipientsDomain, ({ error }) => error);
