import type { PDFPage } from 'pdf-lib';

import { PAGE_WIDTH } from 'containers/DocumentTemplateEditor/Engine/constants';

import type { Input } from '../../models';

export function getInputValue(
  input: Input,
  dictionary: Record<string, unknown>,
): unknown {
  return input.value || (input.variable && dictionary[input.variable]);
}

export function getScale(page: PDFPage): number {
  return page.getWidth() / PAGE_WIDTH;
}

export function getY(page: PDFPage, y: number): number {
  return page.getHeight() - y;
}
