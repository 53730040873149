import { FormattedMessage } from 'react-intl';

import { FormLayout, Input, MaskedInput, hasLength } from '@advitam/ui';

import Layout from '../../Layout';
import { getFieldName } from '../utils';
import messages from './messages';
import SiretAutocomplete from './SiretAutocomplete';

const VAT_MASK = 'FR00 000 000 000';

interface DetailsProps {
  prefix?: string;
  withShortName?: boolean;
}

export default function Details({
  prefix = '',
  withShortName,
}: DetailsProps): JSX.Element {
  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.title.id} />}>
      <SiretAutocomplete prefix={prefix} />
      <FormLayout.Row>
        <MaskedInput
          mask={VAT_MASK}
          unmask
          label={<FormattedMessage id={messages.vatNumber.id} />}
          name={getFieldName(prefix, 'vat')}
          validate={hasLength(13)}
          parse={(v: string | null): string | null => (v ? `FR${v}` : null)}
          format={(v: string | null): string | null =>
            v?.replace(/^FR/, '') || null
          }
        />
      </FormLayout.Row>
      {withShortName && (
        <FormLayout.Row>
          <Input
            label={<FormattedMessage id={messages.shortName.id} />}
            name={getFieldName(prefix, 'short_name')}
          />
        </FormLayout.Row>
      )}
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.website.id} />}
          name={getFieldName(prefix, 'website')}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
