import { useCallback } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import type { EntityInvoice } from '@advitam/api/models/EntityInvoice';
import {
  Button,
  ButtonLike,
  FormattedIban,
  Spinner,
  Tooltip,
} from '@advitam/ui';

import {
  makeSelectIsInvoicePaymentLoading,
  makeSelectPendingPayment,
} from '../selectors';
import { createPendingPayment, deletePendingPayment } from '../thunk';
import messages from './messages';
import style from './List.module.scss';

interface InvoiceIconProps {
  invoice: EntityInvoice;
}

export default function InvoiceIcon({
  invoice,
}: InvoiceIconProps): JSX.Element {
  const dispatch = useDispatch();

  const payment = useSelector(makeSelectPendingPayment(invoice.id));
  const isLoading = useSelector(makeSelectIsInvoicePaymentLoading(invoice.id));

  const deletePayment = useCallback(() => {
    if (payment?.id) {
      dispatch(deletePendingPayment(payment?.id));
    }
  }, [dispatch, payment?.id]);

  const createPayment = useCallback(() => {
    dispatch(createPendingPayment(invoice.id));
  }, [dispatch, invoice.id]);

  const className = [
    style.action,
    style.payment_icon,
    (invoice.paid_on || payment) && style.validated,
  ]
    .filter(Boolean)
    .join(' ');

  if (invoice.paid_on) {
    return (
      <Tooltip
        content={
          <FormattedMessage
            id={messages.paidOn.id}
            values={{ date: <FormattedDate value={invoice.paid_on} /> }}
          />
        }
      >
        <ButtonLike outline className={className} icon="€" />
      </Tooltip>
    );
  }

  if (isLoading) {
    return <ButtonLike outline icon={<Spinner />} className={style.action} />;
  }

  if (!invoice.entity.iban) {
    return (
      <Tooltip content={<FormattedMessage id={messages.noPaymentInfo.id} />}>
        <ButtonLike outline disabled className={className} icon="€" />
      </Tooltip>
    );
  }

  if (payment) {
    return (
      <Tooltip
        content={
          <FormattedMessage
            id={messages.removePayment.id}
            values={{ iban: <FormattedIban iban={invoice.entity.iban} /> }}
          />
        }
      >
        <Button
          outline
          icon="€"
          className={className}
          onClick={deletePayment}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      content={
        <FormattedMessage
          id={messages.pay.id}
          values={{ iban: <FormattedIban iban={invoice.entity.iban} /> }}
        />
      }
    >
      <Button outline icon="€" className={className} onClick={createPayment} />
    </Tooltip>
  );
}
