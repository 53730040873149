import { IntlShape, useIntl } from 'react-intl';
import { PaymentState } from '@advitam/api/models/Payment/State';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from './messages';

export function formatState(state: PaymentState, intl: IntlShape): string {
  return safeFormatMessage(intl, messages, state);
}

interface FormattedAddressProps {
  state: PaymentState;
}

export function FormattedState({ state }: FormattedAddressProps): JSX.Element {
  const intl = useIntl();
  return <>{formatState(state, intl)}</>;
}
