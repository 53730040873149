import { AsyncThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { DealDocumentBatchJSON } from '@advitam/api/models/Deal/Document/Batch';
import { DealType } from '@advitam/api/models/Deal/Type';
import { assert } from '@advitam/support';

import type { AppStateSubset as DealAppStateSubset } from '../slice';
import { makeSelectDeal } from '../selectors.typed';
import { fetchCommunications } from '../Sections/History/thunk';
import { fetchBatchesLogs } from '../DealMarble/HistorySection/thunk';
import { fetchItems } from '../Sections/Todolist/thunk';
import { SEND_BATCH_MODAL } from './constants';
import { makeSelectBatch } from './selectors';
import type { AppStateSubset as SendBatchAppStateSubset } from './slice';
import type { SendBatchForm, StateBatch } from './types';
import { isValidBatchDocument } from './utils';

type AppStateSubset = DealAppStateSubset & SendBatchAppStateSubset;

export const openWithFetch = createAsyncThunk(
  `${SEND_BATCH_MODAL}/OPEN_WITH_FETCH`,
  async (id: number, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      const { body } = await request(
        Api.V1.Deals.Documents.Batches.show(deal.uuid, id),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

interface CreationArgs {
  initialDocIds: number[];
  toSign: boolean;
}

export const openWithCreation = createAsyncThunk(
  `${SEND_BATCH_MODAL}/OPEN_WITH_CREATION`,
  async (
    { toSign, initialDocIds }: CreationArgs,
    { getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    const generatorName = toSign ? 'signed' : 'default';

    try {
      const { body } = await request(
        Api.V1.Deals.Documents.Batches.create(
          deal.uuid,
          toSign,
          generatorName,
          initialDocIds,
        ),
      );

      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

function isValidBatch(batch: StateBatch): batch is DealDocumentBatchJSON {
  return batch.documents.every(isValidBatchDocument);
}

export const sendBatch = createAsyncThunk(
  `${SEND_BATCH_MODAL}/SEND_BATCH`,
  async (
    { content, subject, recipientEntities }: SendBatchForm,
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const batch = makeSelectBatch()(state);
    assert(batch !== null);
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    const batchToSend: StateBatch = { ...batch, content, subject };
    assert(isValidBatch(batchToSend));

    try {
      await request(
        Api.V1.Deals.Documents.SendBatch.create(
          deal.uuid,
          batchToSend,
          recipientEntities,
        ),
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const historyThunk: AsyncThunkAction<unknown, void, any> =
        deal.deal_type === DealType.FUNERAL
          ? fetchCommunications()
          : fetchBatchesLogs();
      await Promise.all([dispatch(fetchItems()), dispatch(historyThunk)]);

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
