import { authenticate } from '../../lib/decorators'
import { ApiRequestDescriptor } from '../../lib/request'
import { put } from '../../lib/methods'

export class Marble {
  @authenticate
  static update(dealId: number, commentary: string): ApiRequestDescriptor<void> {
    return put(`/api/v2/deals/marble/${dealId}`, { deal: { commentary } })
  }
}
