import { FormattedMessage } from 'react-intl';

import { ResourceList } from '@advitam/ui';

import messages from '../messages';
import style from '../AgeRanges.module.scss';

export default function Header(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell className={style.title_cell}>
        <FormattedMessage id={messages.title.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.age_cell}>
        <FormattedMessage id={messages.from.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.age_cell}>
        <FormattedMessage id={messages.to.id} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.actions_header}>
        <FormattedMessage id={messages.actions.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
