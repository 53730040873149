import {
  FocusEvent,
  ReactNode,
  useState,
  KeyboardEvent,
  useCallback,
  useRef,
  ChangeEvent,
  ReactElement,
} from 'react'
import { useIntl } from 'react-intl'
import { DateTime } from 'luxon'

import uiStyle from '../UI.module.scss'
import ErrorMessage from '../parts/ErrorMessage'
import Label from '../parts/Label'
import MaskedInput from '../Masked'
import {
  getInitialDateValue,
  getInitialHourValue,
  isDateValueValid,
  isHourValueValid,
} from './utils'
import { CHARACTER_PLACEHOLDER, DATE_FORMAT, EMPTY_HOUR_VALUE, HOUR_FORMAT } from './constants'
import messages from './messages'
import style from './Date.module.scss'

type ISO8601DateString = string

interface DateInputProps {
  value?: ISO8601DateString
  name?: string
  label?: ReactNode
  error?: ReactElement | boolean | null
  disabled?: boolean
  tooltip?: ReactNode
  className?: string
  containerClassName?: string
  onChange: (v: string) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

export default function DateInput({
  value,
  name,
  label,
  error,
  disabled,
  tooltip,
  className,
  containerClassName,
  onChange: superChange,
  onBlur: superBlur,
}: DateInputProps): JSX.Element {
  const intl = useIntl()

  const [dateValue, setDateValue] = useState(getInitialDateValue(value))
  const [hourValue, setHourValue] = useState(getInitialHourValue(value))

  const dayInputElement = useRef<HTMLInputElement>()
  const hourInputElement = useRef<HTMLInputElement>()

  const containerClasses = [uiStyle.field, containerClassName].filter(Boolean)
  const classes = [style.container, className].filter(Boolean)
  const showErrorMessage = Boolean(error) && error !== true

  const onDayKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement
      if (
        !hourInputElement.current ||
        event.key !== 'ArrowRight' ||
        target.selectionStart !== DATE_FORMAT.length
      ) {
        return
      }

      event.preventDefault()
      hourInputElement.current.focus()
      hourInputElement.current.selectionStart = 0
    },
    [hourInputElement],
  )

  const onHourKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement

      if (!dayInputElement.current || event.key !== 'ArrowLeft' || target.selectionStart !== 0) {
        return
      }

      event.preventDefault()
      dayInputElement.current.focus()
      dayInputElement.current.selectionStart = DATE_FORMAT.length
    },
    [dayInputElement],
  )

  const onChange = useCallback(
    (day: string | undefined, hour: string | undefined) => {
      if (!day || !hour) {
        return
      }

      const date = DateTime.fromFormat(`${day} ${hour}`, `${DATE_FORMAT} ${HOUR_FORMAT}`)
      superChange(date.isValid ? date.toISO() || '' : '')
    },
    [superChange],
  )

  const onDayChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value: newValue } = event.target

      if (newValue.length === DATE_FORMAT.length && !newValue.includes(CHARACTER_PLACEHOLDER)) {
        hourInputElement.current?.focus()
      }

      setDateValue(newValue)
      if (isDateValueValid(newValue) || value) {
        onChange(newValue, hourValue)
      }
    },
    [value, hourInputElement, hourValue, setDateValue, onChange],
  )

  const onHourChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value: newValue } = event.target

      if (!newValue || newValue === EMPTY_HOUR_VALUE) {
        dayInputElement.current?.focus()
      }

      setHourValue(newValue)
      if (isHourValueValid(newValue) || value) {
        onChange(dateValue, newValue)
      }
    },
    [value, dayInputElement, dateValue, setHourValue, onChange],
  )

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setTimeout(() => {
        const isInputFocused =
          document.activeElement &&
          (document.activeElement === dayInputElement.current ||
            document.activeElement === hourInputElement.current)

        if (superBlur && !isInputFocused) {
          superBlur(event)
        }
      })
    },
    [superBlur],
  )

  return (
    <div className={containerClasses.join(' ')}>
      <Label value={label} tooltip={tooltip} />
      <div className={classes.join(' ')}>
        <input type="hidden" name={name} value={value} />
        <MaskedInput
          value={dateValue}
          className={style.date}
          onKeyDown={onDayKeyDown}
          onChange={onDayChange}
          onBlur={onBlur}
          mask="00 / 00 / 0000"
          error={Boolean(error)}
          disabled={disabled}
          placeholder={intl.formatMessage(messages.dayPlaceholder)}
          inputRef={(element): void => {
            dayInputElement.current = element || undefined
          }}
        />
        <MaskedInput
          value={hourValue}
          className={style.hour}
          onKeyDown={onHourKeyDown}
          onChange={onHourChange}
          onBlur={onBlur}
          mask="00 : 00"
          error={Boolean(error)}
          disabled={disabled}
          placeholder={intl.formatMessage(messages.hourPlaceholder)}
          inputRef={(element): void => {
            hourInputElement.current = element || undefined
          }}
        />
      </div>
      {showErrorMessage && <ErrorMessage error={error} />}
    </div>
  )
}
