import { last } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DirectionsRenderer } from '@react-google-maps/api';
import { useLocation } from 'react-router';

import { Map, MapStyle } from '@advitam/ui';
import { getSectionHash } from 'containers/Deal/utils';
import { StepMarker } from 'components/Map';
import { assert } from 'lib/support';
import { useDeepCompareEffect } from 'lib/reactvitam';

import { Path } from '../../../Funeral/constants';
import { makeSelectSummaryMarkers, makeSelectSummaryRoute } from './selectors';

export default function DealMap(): JSX.Element | null {
  const location = useLocation();
  const isOnHomeSection = !location.hash || !getSectionHash(Path.HOME);

  const [
    directions,
    setDirections,
  ] = useState<null | google.maps.DirectionsResult>(null);

  const route = useSelector(makeSelectSummaryRoute());
  const markers = useSelector(makeSelectSummaryMarkers());

  useDeepCompareEffect(() => {
    if (route.length < 2) {
      return;
    }

    const destination = last(route);
    assert(destination !== undefined);
    const service = new google.maps.DirectionsService();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    service.route(
      {
        origin: route[0],
        destination,
        waypoints: route
          .slice(1, -1)
          .map(routeLocation => ({ location: routeLocation, stopover: true })),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }
      },
    );
  }, [route]);

  if (!isOnHomeSection) {
    return null;
  }

  return (
    <Map mapStyle={MapStyle.LIGHT_BEIGE} zoom={10}>
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{ suppressMarkers: true }}
        />
      )}
      {markers.map((marker, index) => (
        <StepMarker
          // eslint-disable-next-line react/no-array-index-key
          key={`${marker.latitude}__${marker.longitude}__${index}`}
          lat={marker.latitude}
          lng={marker.longitude}
          type={marker.type}
          repatriationType={marker.repatriationType}
        />
      ))}
    </Map>
  );
}
