import { Column } from './types'
import style from './Windowed.module.scss'

interface HeaderProps {
  columns: Column[]
  getColumnWidth: (index: number) => number
}

export default function HeaderComponent({ columns, getColumnWidth }: HeaderProps): JSX.Element {
  return (
    <div className={style.header_wrapper}>
      <div className={style.header}>
        {columns.map(
          ({ Header }, index): JSX.Element => (
            <div style={{ width: getColumnWidth(index) }} key={index}>
              <Header />
            </div>
          ),
        )}
      </div>
    </div>
  )
}
