/**
 * Documentation : https://developers.google.com/maps/documentation/urls/get-started#search-action
 */

const GOOGLE_MAPS_URL = 'https://www.google.com/maps/search/'

export interface Country {
  name: string
}

export interface GMapsLinkable {
  address: string | null
  // eslint-disable-next-line camelcase
  postal_code: string | null
  city: string | null
  country?: Country | null
  latitude: number | null
  longitude: number | null
}

function formatAddress(address: GMapsLinkable): string {
  return [
    address.address,
    `${address.postal_code || ''} ${address.city || ''}`.trim(),
    address.country?.name,
  ]
    .filter(Boolean)
    .join(', ')
}

function buildLink(place: GMapsLinkable): string {
  const url = new URL(GOOGLE_MAPS_URL)
  url.searchParams.append('api', '1')

  const query =
    place.latitude && place.longitude
      ? [place.latitude.toString(), place.longitude.toString()].join(',')
      : formatAddress(place)
  url.searchParams.append('query', query)

  return url.toString()
}

export const GMaps = {
  buildLink,
}
