import { DateTime } from 'luxon';

import type { ClientAbilityJSON } from '@advitam/api/models/Client/Ability';
import type { ClientJSON } from '@advitam/api/models/Client';
import type { ClientJSON as LegacyClientJSON } from 'models/Client';
import type { NewAbility, NewClient } from '../Sections/Identity/types';

function clientToLegacy(client: ClientJSON | NewClient): LegacyClientJSON {
  return {
    id: client.id,
    email: client.email || undefined,
    birthLocation: client.birth_location || undefined,
    type: client.civility,
    firstName: client.firstname || '',
    lastName: client.lastname || '',
    birthName: client.birth_name || undefined,
    birthDate: client.birth_date
      ? DateTime.fromISO(client.birth_date)
          .setZone('utc', { keepLocalTime: true })
          .toUnixInteger()
      : undefined,
    contact: {
      email: client.email || undefined,
      phone: client.phone || undefined,
      phone_2: client.phone_2 || undefined,
      phone_3: client.phone_3 || undefined,
    },
    address: {
      address: client.address || undefined,
      address_l2: client.address_l2 || undefined,
      postal_code: client.postal_code || undefined,
      insee_code: client.insee_code || undefined,
      city: client.city || undefined,
      country: client.country?.name,
      country_code: client.country?.code,
    },
  };
}

export function abilityToLegacyClient(
  ability: ClientAbilityJSON | NewAbility,
): LegacyClientJSON {
  return {
    ...clientToLegacy(ability.client),
    link: ability.link || '',
    role: ability.role,
    special: ability.special,
    ability_id: ability.id,
  };
}
