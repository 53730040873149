import { useSelector } from 'react-redux';

import { makeSelectDefunctName } from '../../selectors.js';
import { DealSummarySection } from '../../DealFuneral/sections.js';

export default function Summary(): JSX.Element {
  const defunctName = useSelector(makeSelectDefunctName());

  return <DealSummarySection defunctName={defunctName} />;
}
