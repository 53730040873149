import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Layout from 'containers/Crud/Layout';
import { OpeningHours } from 'containers/Crud';

import { saveConsulate } from '../thunk';
import type { ConsulateForm } from './types';
import Wrapper from './Wrapper';

export default function OpeningHoursSection(): JSX.Element {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: ConsulateForm): void => {
      dispatch(saveConsulate(values.consulate));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <OpeningHours prefix="consulate" />
      </Layout.Container>
    </Wrapper>
  );
}
