import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { isApiError, request } from '@advitam/api';
import {
  deleteTokens,
  getTokens,
  getTokensExpiration,
  hasTokens,
} from '@advitam/api/lib/tokens';
import { BusinessUserJSON } from '@advitam/api/models/User/BusinessUser';
import { assert, Browsers } from '@advitam/support';

import { Path } from 'containers/App/constants';
import { createConsumer } from 'lib/reactvitam/action_cable';

import { AUTH } from './constants';

async function postServiceWorkerAuthTokens(): Promise<void> {
  const serviceWorker = await Browsers.context.serviceWorker?.ready;
  serviceWorker?.active?.postMessage({
    type: 'setTokens',
    tokens: getTokens(),
  });
}

function createCableConsumer(): void {
  assert(process.env.API_ENDPOINT !== undefined);
  createConsumer(`${process.env.API_ENDPOINT}/cable`);
}

export const onLoginSuccess = createAsyncThunk(
  `${AUTH}/ON_LOGIN_SUCCESS`,
  async (user: BusinessUserJSON) => {
    await request(Api.V1.Auth.setCookie());
    createCableConsumer();
    await postServiceWorkerAuthTokens();

    return user;
  },
);

export const onLogout = createAsyncThunk(`${AUTH}/ON_LOGOUT`, async _ => {
  deleteTokens();
  await postServiceWorkerAuthTokens();
});

export const validateToken = createAsyncThunk(
  `${AUTH}/VALIDATE_TOKEN`,
  async (_: void, { dispatch, rejectWithValue }) => {
    if (!hasTokens()) {
      return rejectWithValue(null);
    }

    if (getTokensExpiration().hours < 12) {
      await dispatch(onLogout());
      replace(Path.LOGIN, { from: window.location.pathname });
      return rejectWithValue(null);
    }

    try {
      const { body } = await request(Api.V1.Pro.Auth.validateToken());
      assert(body !== null);

      await dispatch(onLoginSuccess(body.data));

      return undefined;
    } catch (err) {
      if (isApiError(err) && err.status === 401) {
        await dispatch(onLogout());
        replace(Path.LOGIN, { from: window.location.pathname });
      }

      // We are not sending the response from the backoffice because its message
      // (invalid password or username) is misleading.
      return rejectWithValue(null);
    }
  },
);

export const logout = createAsyncThunk(
  `${AUTH}/LOGOUT`,
  async (_: void, { dispatch, rejectWithValue }) => {
    try {
      await request(Api.V1.Pro.Auth.signOut());
      await dispatch(onLogout());

      push(Path.LOGIN);

      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
