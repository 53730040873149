import { ReactElement, ReactNode } from 'react';

import Link, { LinkProps } from './Link';

type LinkElement = ReactElement<LinkProps, typeof Link>;

export function isLinkElement(node: ReactNode): node is LinkElement {
  return Boolean(node) && (node as ReactElement).type === Link;
}

export function makeHref(to: string): string {
  return ['..', to].filter(Boolean).join('/');
}
