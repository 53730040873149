import { FormattedMessage } from 'react-intl';

import { FormLayout, NumberInput } from '@advitam/ui';
import { Layout, messages as crudMessages } from 'containers/Crud';

import messages from './messages';
import style from './Miscellaneous.module.scss';

export default function Contents(): JSX.Element {
  return (
    <Layout.Container>
      <Layout.ColumnGroup>
        <Layout.Column>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.functioning.id} />}
          >
            <FormLayout.Row className={style.row}>
              <NumberInput
                label={<FormattedMessage id={messages.capacity.id} />}
                name="worship.capacity"
                suffix={<FormattedMessage id={crudMessages.unitPerson.id} />}
              />
              <NumberInput
                label={<FormattedMessage id={messages.nbHolders.id} />}
                tooltip={<FormattedMessage id={messages.nbHoldersTooltip.id} />}
                name="worship.nb_holders"
                suffix={<FormattedMessage id={crudMessages.unitPerson.id} />}
              />
            </FormLayout.Row>
          </Layout.Fieldset>
        </Layout.Column>

        <Layout.Column>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.billing.id} />}
          >
            <FormLayout.Row className={style.row}>
              <NumberInput
                label={<FormattedMessage id={messages.price.id} />}
                name="worship.price"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
          </Layout.Fieldset>
        </Layout.Column>
      </Layout.ColumnGroup>
    </Layout.Container>
  );
}
