import { createAsyncThunk } from '@reduxjs/toolkit';

import { patchMemorial } from '../thunk';
import { MEMORIAL_SPACE_CUSTOMIZATION } from './constants';

export const updateMessage = createAsyncThunk(
  `${MEMORIAL_SPACE_CUSTOMIZATION}/UPDATE_MESSAGE`,
  (message: string | undefined, { dispatch }) => {
    return dispatch(patchMemorial({ message }));
  },
);
