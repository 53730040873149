import { Objects } from '@advitam/support'
import { DefunctJSON } from '../../../models/Defunct'
import { Civility } from '../../../models/Person/Civility'
import { AutocompleteResult } from '../../Autocompletes'

interface DefunctAttibutes {
  firstname: string
  lastname: string
  civility: Civility
  birth_date?: string
  death_date?: string
}

interface Procedure {
  defunct_attributes: DefunctAttibutes
  defunct_death_city_id?: number
  defunct_family_link?: string
}

interface CreationPayload {
  procedure: Procedure
}

export function serialize(
  defunct: DefunctJSON,
  deathCity?: AutocompleteResult,
  familyLink?: string,
): CreationPayload {
  return {
    procedure: {
      defunct_death_city_id: deathCity?.id,
      defunct_family_link: familyLink,
      defunct_attributes: {
        ...Objects.pick(defunct, 'firstname', 'lastname', 'civility'),
        birth_date: defunct.birth_date || undefined,
        death_date: defunct.birth_date || undefined,
      },
    },
  }
}
