import { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { ErrorText, ResourceList } from '@advitam/ui';
import type { FilterSelection } from '@advitam/ui/components/ResourceList';

import { FormattedApiError } from 'components/Format/ApiError';
import { withDatasets } from 'slices/data';

import Filters from './parts/Filters';
import Header from './parts/Header';
import Row from './parts/Row';
import {
  makeSelectAllowedSuppliers,
  makeSelectIsLoading,
  makeSelectHasMore,
  makeSelectFilters,
  makeSelectError,
} from './selectors';
import slice, { setFilters } from './slice';
import { fetchSuppliers } from './thunk';

function Suppliers(): JSX.Element {
  const dispatch = useDispatch();
  const suppliers = useSelector(makeSelectAllowedSuppliers());
  const isLoading = useSelector(makeSelectIsLoading());
  const hasMore = useSelector(makeSelectHasMore());
  const filters = useSelector(makeSelectFilters());
  const error = useSelector(makeSelectError());

  const onFilterChange = (
    newFilters: Record<string, FilterSelection>,
  ): void => {
    const filtersUpdate = {
      ...filters,
      ...newFilters,
    };

    dispatch(setFilters(filtersUpdate));
    dispatch(fetchSuppliers());
  };

  useEffect(() => {
    dispatch(fetchSuppliers());
  }, []);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      <main>
        <ResourceList.Container
          hasMore={hasMore}
          isLoading={isLoading}
          filters={<Filters onChange={onFilterChange} />}
          header={<Header />}
          fetchResources={(): void => {
            dispatch(fetchSuppliers());
          }}
          error={
            error && (
              <ErrorText center tagName="p">
                <FormattedApiError error={error} />
              </ErrorText>
            )
          }
        >
          {suppliers.map(supplier => (
            <Row supplier={supplier} key={supplier.id} />
          ))}
        </ResourceList.Container>
      </main>
    </>
  );
}

export default compose<FC<Record<string, never>>>(
  withSlice(slice),
  withDatasets('departments'),
)(Suppliers);
