import { authenticate } from '../lib/decorators'
import { ApiRequestDescriptor } from '../lib/request'
import { get } from '../lib/methods'
import { RecipientType } from '../models/RecipientType'

export interface AutocompleteResult {
  id: number
  description: string
}

export interface AutocompleteAirportResult extends AutocompleteResult {
  name: string
  iata: string
}

export interface AutocompleteCityhallResult extends AutocompleteResult {
  name: string
  insee_code: string
  postal_code: string
}

export interface AutocompleteCountriesResult extends AutocompleteResult {
  name: string
  country_code: string
}

export interface AutocompleteDealEntitiesResult extends AutocompleteResult {
  entity_id: number
  entity_type: RecipientType
}

export interface AutocompleteDealWithInvoiceResult extends AutocompleteResult {
  uuid: string
}

export interface AutocompleteDepartmentsResult extends AutocompleteResult {
  department_code: string
}

export interface AutocompletePlaceResult extends AutocompleteResult {
  postal_code: string
  address: string
  name?: string
  city?: string
  insee_code?: string
}

export interface AutocompleteFlightsResult extends AutocompleteResult {
  arrival_city: string
  arrival_country: string
  arrival_country_code: string
}

export interface AutocompleteSuppliersResult extends AutocompleteResult {
  name: string
  short_name: string | null
}

export class Autocompletes {
  static readonly Path = {
    airlines: '/api/v1/autocompletes/airlines',
    airports: {
      index: '/api/v1/autocompletes/airports',
      warehouses: (id: number): string => `/api/v1/autocompletes/airports/${id}/warehouses`,
    },
    companies: '/api/v1/autocompletes/companies',
    consulates: '/api/v1/autocompletes/consulates',
    countries: '/api/v1/autocompletes/countries',
    cityhalls: '/api/v1/autocompletes/cityhalls',
    crematoriums: '/api/v1/autocompletes/crematoriums',
    deals: {
      documents: (dealUuid: string): string => `/api/v1/autocompletes/deals/${dealUuid}/documents`,
      entities: (dealUuid: string): string => `/api/v1/deals/${dealUuid}/entities`,
      withInvoices: '/api/v1/autocompletes/deals_with_invoices',
    },
    defuncts: '/api/v1/autocompletes/defuncts',
    departments: '/api/v1/autocompletes/departments',
    documentTypes: '/api/v1/autocompletes/document_types',
    entityInvoices: '/api/v1/autocompletes/entity_invoices',
    flights: '/api/v1/autocompletes/flights',
    funeralParlors: '/api/v1/autocompletes/funeral_parlors',
    funeralWorkTypes: '/api/v1/autocompletes/funeral_work_types',
    graveyards: '/api/v1/autocompletes/graveyards',
    hospitals: '/api/v1/autocompletes/hospitals',
    police: '/api/v1/autocompletes/polices',
    prefectures: '/api/v1/autocompletes/prefectures',
    products: '/api/v1/autocompletes/products',
    rha: '/api/v1/autocompletes/rha',
    ruleSets: '/api/v1/autocompletes/rule_sets',
    suppliers: '/api/v1/autocompletes/suppliers',
    worships: '/api/v1/autocompletes/worships',
  }

  static cityhalls(q?: string): ApiRequestDescriptor<AutocompleteCityhallResult[]> {
    return get(Autocompletes.Path.cityhalls, { q })
  }

  @authenticate
  static companies(q?: string): ApiRequestDescriptor<AutocompleteResult> {
    return get(Autocompletes.Path.companies, { q })
  }

  static countries(
    q?: string,
    withFlightEstimation?: boolean,
  ): ApiRequestDescriptor<AutocompleteCountriesResult[]> {
    return get(Autocompletes.Path.countries, {
      q,
      flight_estimation: withFlightEstimation,
    })
  }

  static crematoriums(q?: string): ApiRequestDescriptor<AutocompletePlaceResult> {
    return get(Autocompletes.Path.crematoriums, { q })
  }

  @authenticate
  static defuncts(q?: string): ApiRequestDescriptor<AutocompleteResult> {
    return get(Autocompletes.Path.defuncts, { q })
  }

  @authenticate
  static departments(q?: string): ApiRequestDescriptor<AutocompleteDepartmentsResult[]> {
    return get(Autocompletes.Path.departments, { q })
  }

  static flights(q?: string): ApiRequestDescriptor<AutocompleteFlightsResult[]> {
    return get(Autocompletes.Path.flights, { q })
  }

  static funeralParlors(q?: string): ApiRequestDescriptor<AutocompletePlaceResult> {
    return get(Autocompletes.Path.funeralParlors, { q })
  }

  static graveyards(q?: string): ApiRequestDescriptor<AutocompletePlaceResult> {
    return get(Autocompletes.Path.graveyards, { q })
  }

  static hospitals(q?: string): ApiRequestDescriptor<AutocompletePlaceResult> {
    return get(Autocompletes.Path.hospitals, { q })
  }

  static worships(q?: string, tag?: string): ApiRequestDescriptor<AutocompletePlaceResult> {
    let path = Autocompletes.Path.worships
    if (tag) {
      path += `/${tag}`
    }
    return get(path, { q })
  }
}
