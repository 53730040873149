import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: {
    id: 'app.containers.Deal.Sections.Identity.ClientAutocompletionModal.title',
  },
  text: {
    id: 'app.containers.Deal.Sections.Identity.ClientAutocompletionModal.text',
  },
  confirm: {
    id:
      'app.containers.Deal.Sections.Identity.ClientAutocompletionModal.confirm',
  },
  cancel: {
    id:
      'app.containers.Deal.Sections.Identity.ClientAutocompletionModal.cancel',
  },
});
