import { MessageDescriptor, useIntl } from 'react-intl';
import { useField, Validator } from 'react-form';
import { Editor, IAllProps as EditorProps } from '@tinymce/tinymce-react';

import {
  LANGUAGES_ROOT_URL,
  MODEL_URL,
  SKIN_URL,
} from '@advitam/ui/components/TinyMCE/constants';
import WithTiny from 'components/WithTiny';

import messages from './messages';

function validator(required?: boolean): Validator {
  return (value): string | false => {
    if (required && (!value || value === '')) {
      return messages.requiredField.id;
    }
    return false;
  };
}

interface HtmlFieldProps {
  field: string;
  className?: string;
  message?: MessageDescriptor;
  required?: boolean;
  hideErrorMessage?: boolean;
  options?: EditorProps['init'];
  customBodyCss?: string;
}

export default function HtmlField({
  field,
  className,
  hideErrorMessage,
  message,
  required,
  options,
  customBodyCss,
}: HtmlFieldProps): JSX.Element {
  const intl = useIntl();
  const {
    meta: { error, touched },
    getInputProps,
  } = useField(field, {
    validate: validator(required),
  });
  const { value, onChange, onBlur } = getInputProps();

  return (
    <label
      className={`wizard--input wizard--input__html ${className || ''}`}
      htmlFor={field}
    >
      <span>{message && intl.formatMessage(message)}</span>
      <WithTiny>
        <Editor
          value={value}
          onEditorChange={(v): void => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onChange({ target: { value: v } });
          }}
          onBlur={onBlur}
          textareaName={field}
          init={{
            menubar: false,
            toolbar: false,
            branding: false,
            resize: false,
            statusbar: false,
            height: '250px',
            skin_url: SKIN_URL,
            model_url: MODEL_URL,
            language: intl.locale === 'en' ? undefined : intl.locale,
            language_url: `${LANGUAGES_ROOT_URL}${intl.locale}.js`,
            content_style: `@import url('https://fonts.googleapis.com/css?family=Raleway:700,600,400,500,300,200'); body { font-family: Raleway, sans-serif; ${
              customBodyCss || ''
            } }`,
            ...options,
          }}
        />
      </WithTiny>
      {touched && error && (
        <span className="error">
          {hideErrorMessage !== true && intl.formatMessage({ id: error })}
        </span>
      )}
    </label>
  );
}
