import { authenticate } from '../../lib/decorators'
import type { ApiRequestDescriptor } from '../../lib/request'
import { get, put } from '../../lib/methods'
import type { GradedDealReviewJSON } from '../../models/Deal/GradedReview'
import type { ReviewJSON } from '../../models/Deal/Review'

export interface ReviewUpdatePayload {
  funeral_advisor_grade: number
  funeral_advisor_commentary?: string
  ceremony_grade: number
  ceremony_commentary?: string
}

export class Reviews {
  @authenticate
  static index(dealUuid: string): ApiRequestDescriptor<GradedDealReviewJSON[]> {
    return get(`/api/v1/deals/${dealUuid}/reviews`)
  }

  static show(uuid: string): ApiRequestDescriptor<ReviewJSON> {
    return get(`/api/v1/deals/reviews/${uuid}`)
  }

  static update(uuid: string, payload: ReviewUpdatePayload): ApiRequestDescriptor<void> {
    return put(`/api/v1/deals/reviews/${uuid}`, payload)
  }
}
