import { call, put, takeLatest } from 'redux-saga/effects';
import { requestWithAuth } from 'utils/request';
import { GET_STATS, GET_STATS_PER_MONTH } from './constants';
import { setStats, setStatsPerMonth, error } from './actions';

export function* getStats(action) {
  try {
    const requestURL = `/api/v1/users/${action.uid}/stats`;
    const stats = yield call(requestWithAuth, requestURL);
    yield put(setStats(stats));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getStatsPerMonth(action) {
  try {
    const requestURL = `/api/v1/users/${action.uid}/stats/${action.month}`;
    const statsPerMonth = yield call(requestWithAuth, requestURL);
    yield put(setStatsPerMonth(statsPerMonth));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export default function* accountData() {
  yield takeLatest(GET_STATS, getStats);
  yield takeLatest(GET_STATS_PER_MONTH, getStatsPerMonth);
}
