import { Objects } from '@advitam/support'

import { CoverageZoneType } from '../../models/Entity/Coverage'
import { Model } from '../../models/Model'
import { PrefectureJSON } from '../../models/Prefecture'
import { PrefectureBody } from './types'

interface CoveragePayload {
  id?: number
  zone_type: CoverageZoneType
  zone_id: number
}

interface DestroyedCoverage {
  id: number
  _destroy: true
}

type PrefecturePayload = Partial<
  Omit<PrefectureJSON, 'id' | 'country'> & {
    country_code: string | null
    headquarter_country_code: string | null
    prefecture_coverages_attributes: Array<CoveragePayload | DestroyedCoverage>
  }
>

export function serialize(prefecture: Omit<PrefectureBody, 'id'>): PrefecturePayload {
  const coverages = prefecture.prefecture_coverages?.map((coverage):
    | CoveragePayload
    | DestroyedCoverage => {
    if (Model.isDestroyed(coverage)) {
      return { id: coverage.id, _destroy: true }
    }
    return Objects.pick(coverage, 'id', 'zone_type', 'zone_id')
  })

  return {
    ...Objects.omit(prefecture, 'country', 'headquarter_country', 'prefecture_coverages'),
    country_code: prefecture.country === undefined ? undefined : prefecture.country?.code || null,
    headquarter_country_code:
      prefecture.headquarter_country === undefined
        ? undefined
        : prefecture.headquarter_country?.code || null,
    prefecture_coverages_attributes: coverages,
  }
}
