import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { Saga, SagaMiddleware } from 'redux-saga';

import type { Store as BaseStore } from '@advitam/react/redux';

export interface Store extends BaseStore {
  runSaga: SagaMiddleware['run'];
  injectedSagas: Record<string, Saga>;
}

export function configureReduxStore(options: ConfigureStoreOptions): Store {
  return configureStore(options) as Store;
}
