import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ToggleSideBar from './ToggleSideBar';

/**
 * SideBar with children
 */
export class SideBar extends PureComponent {
  state = {
    /** tell if sidebar is open or not */
    isOpen: false,
  };

  /**
   * toggle isOpen
   */
  toggleSideBarIsOpen = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;
    const classes = ['sideBar', isOpen && 'sideBar--isOpen'].filter(Boolean);

    return (
      <div className={classes.join(' ')}>
        <div className="sideBar--content">{children}</div>
        <ToggleSideBar toggleSideBarIsOpen={this.toggleSideBarIsOpen} />
      </div>
    );
  }
}

SideBar.propTypes = {
  /** Components to display inside the sidebar */
  children: PropTypes.any,
};
