import { CSSProperties } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Logo from 'images/logo-black.svg';

import ResizableInput from './Resizable';
import messages from '../../messages';
import style from '../Engine.module.scss';

const COMMON_STYLE: CSSProperties = {
  textAlign: 'center',
};

class SignatureInput extends ResizableInput<undefined> {
  // eslint-disable-next-line class-methods-use-this
  renderInput(): JSX.Element | null {
    const { isEditMode, isPreviewMode } = this.props;
    if (!isEditMode || isPreviewMode) {
      return (
        <div style={COMMON_STYLE}>
          <FormattedMessage id={messages.inputSignature.id} />
        </div>
      );
    }

    return (
      <div tabIndex={-1} style={COMMON_STYLE} className={style.signature}>
        <FormattedMessage id={messages.inputSignature.id} />
        <Logo />
      </div>
    );
  }
}

export default injectIntl(SignatureInput);
