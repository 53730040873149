import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { DealState as DealStateValue } from '@advitam/api/models/Deal/State';
import { DealKoReason } from '@advitam/api/models/Deal/KoReason';
import { CroppedText } from '@advitam/ui';

import { SelectList } from 'components/Input';
import dealListMessages from 'containers/DealsList/messages.ts';
import { ButtonV2 as Button } from 'components/Button';
import CustomModal, { ButtonsValidation } from 'components/CustomModal';
import messagesActions from 'messages/actions';

import { makeSelectUser } from 'slices/auth';
import { makeSelectDeal } from '../selectors';
import { updateDealState } from './KoReasonModal/thunk.ts';
import { setIsOpen as setIsKoModalOpen } from './KoReasonModal/slice.ts';
import koMessages from './KoReasonModal/messages.ts';
import messages from './messages';

const KO_REASON_MESSAGES = {
  [DealKoReason.ANTICIPATION]: koMessages.anticipation,
  [DealKoReason.DUPLICATE]: koMessages.duplicate,
  [DealKoReason.NOT_PROVIDED_SERVICE]: koMessages.notProvidedService,
  [DealKoReason.PHYSICAL_STORE]: koMessages.physicalStore,
  [DealKoReason.TOO_EXPENSIVE]: koMessages.tooExpensive,
  [DealKoReason.UNREACHABLE]: koMessages.unreachable,
};

export default function DealState() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const deal = useSelector(makeSelectDeal());
  const user = useSelector(makeSelectUser());

  const [pendingDealState, setPendingDealState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = newPendingState => {
    setIsModalOpen(!isModalOpen);
    if (typeof newPendingState === 'string') {
      setPendingDealState(newPendingState);
    }
  };

  const onSelect = newPendingState => {
    if (newPendingState !== DealStateValue.KO) {
      toggleModal(newPendingState);
    } else {
      dispatch(setIsKoModalOpen(true));
    }
  };

  const onModalConfirm = () => {
    dispatch(
      updateDealState({
        state: pendingDealState,
        ko_comment: null,
        ko_reason: null,
      }),
    );
    setIsModalOpen(false);
  };

  return (
    <div>
      {pendingDealState && (
        <CustomModal isOpen={isModalOpen} onRequestClose={toggleModal}>
          <FormattedMessage
            id={messages.dealStateConfirmation.id}
            values={{
              state: intl.formatMessage({
                ...dealListMessages[pendingDealState],
              }),
            }}
          />
          <ButtonsValidation>
            <Button className="button button--grey" onClick={toggleModal}>
              <FormattedMessage id={messagesActions.cancel.id} />
            </Button>
            <Button className="button" onClick={onModalConfirm}>
              <FormattedMessage id={messagesActions.confirm.id} />
            </Button>
          </ButtonsValidation>
        </CustomModal>
      )}
      <FormattedMessage id={messages.dealState.id} />
      {deal.state !== DealStateValue.OK || (user && user.isAdmin) ? (
        <SelectList
          name="dealState"
          options={[
            { value: DealStateValue.QUOTE, message: dealListMessages.quote },
            { value: DealStateValue.ORDER, message: dealListMessages.order },
            { value: DealStateValue.OK, message: dealListMessages.ok },
            { value: DealStateValue.KO, message: dealListMessages.ko },
            {
              value: DealStateValue.FUNERAL_CONTRACT,
              message: dealListMessages.funeral_contract,
            },
            {
              value: DealStateValue.FUNERAL_CONTRACT_OK,
              message: dealListMessages.funeral_contract_ok,
            },
          ]}
          value={deal.state || ''}
          onChange={({ target: { value } }) => onSelect(value)}
        />
      ) : (
        <>
          <br />
          <FormattedMessage {...dealListMessages[deal.state]} />
        </>
      )}
      {deal.state === DealStateValue.KO && deal.ko_reason && (
        <div className="margin--10-0-0-0">
          <FormattedMessage id={messages.dealKoReason.id} />
          <CroppedText>
            {deal.ko_reason === DealKoReason.OTHER ? (
              deal.ko_comment
            ) : (
              <FormattedMessage id={KO_REASON_MESSAGES[deal.ko_reason].id} />
            )}
          </CroppedText>
        </div>
      )}
    </div>
  );
}
