import { authenticate } from '../../../lib/decorators'
import { put } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'

export class Synchronize {
  @authenticate
  static update(supplierId: number): ApiRequestDescriptor<void> {
    return put(`/api/v1/suppliers/${supplierId}/coverage/synchronize`, {})
  }
}
