import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import PageSpinner from 'components/Spinner/PageSpinner';
import ProgressBar from 'components/ProgressBar';
import { ButtonV2 as Button } from 'components/Button';
import RefreshIcon from 'images/icons/refresh.svg';

import { fetchItems } from '../Sections/Todolist/thunk';
import Todo from './Todo';
import TodoForm from './Todo/Form';
import {
  makeSelectItems,
  makeSelectIsLoading,
  makeSelectProgression,
} from './selectors';
import { createItem } from './thunk';
import messages from './messages';
import style from './style.scss';

export default function Content(): JSX.Element {
  const dispatch = useDispatch();
  const items = useSelector(makeSelectItems());
  const isLoading = useSelector(makeSelectIsLoading());
  const progression = useSelector(makeSelectProgression());
  const [isFormOpen, setIsFormOpen] = useState(false);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <div className={`dealSection ${style.container}`}>
      <ProgressBar
        progress={progression}
        classNameContainer="progressBar progressBar--thin"
      />
      <Button
        className={`button--svg ${style.refresh}`}
        onClick={(): void => {
          dispatch(fetchItems());
        }}
      >
        <RefreshIcon />
      </Button>
      <div className={style.todos}>
        {items.map(item => (
          <Todo key={item.id} item={item} />
        ))}
      </div>
      {isFormOpen ? (
        <TodoForm
          closeForm={(): void => setIsFormOpen(false)}
          onSave={(title: string): void => {
            dispatch(createItem(title));
          }}
        />
      ) : (
        <Button
          onClick={(): void => setIsFormOpen(true)}
          className="margin--10-0"
        >
          <FormattedMessage id={messages.addItem.id} />
        </Button>
      )}
    </div>
  );
}
