import { Objects } from '@advitam/support'

import type { SupportingDocumentOwner } from '../../../models/SupportingDocuments/Owner'
import type { SupportingDocumentOwnerType } from '../../../models/SupportingDocuments/OwnerType'
import type { SupportingDocumentJSON } from '../../../models/SupportingDocuments/Version'
import { NewSupportingDocument } from './types'

interface UploadedDocumentUpdatePayload {
  expiry_date: string | null
  issue_date: string | null
  reference: string | null
}

interface UpdatePayload {
  uploaded_document: UploadedDocumentUpdatePayload
}

export function serializeUpdate(document: SupportingDocumentJSON): UpdatePayload {
  return {
    uploaded_document: {
      ...Objects.pick(document.uploaded_document, 'expiry_date', 'issue_date', 'reference'),
    },
  }
}

interface SupportingDocumentCreationPayload {
  document_type_id: number
  owner_type: SupportingDocumentOwnerType
  owner_id: number
}

interface CreationPayload {
  file: Blob
  expiry_date: string | null
  issue_date: string | null
  reference: string | null
  supporting_document: SupportingDocumentCreationPayload
}

export function serializeCreation(
  document: NewSupportingDocument,
  file: Blob,
  owner: SupportingDocumentOwner,
): CreationPayload {
  return {
    file,
    ...Objects.pick(document.uploaded_document, 'expiry_date', 'issue_date', 'reference'),
    supporting_document: {
      document_type_id: document.document_type.id,
      owner_type: owner.type,
      owner_id: owner.id,
    },
  }
}
