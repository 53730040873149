import { Country } from '../../Country'
import { ContactMedia } from '../../Entity/ContactMedia'
import { Model } from '../../Model'
import { SupplierWarehouseAirportJSON } from './Airport'

interface SupplierWarehouseBase {
  id: number
  name: string
  address: string | null
  address_l2: string | null
  postal_code: string | null
  insee_code: string | null
  city: string | null
  department: string | null
  country: Country | null
  latitude: number | null
  longitude: number | null
  manual_address: boolean
  phone: string | null
  phone_2: string | null
  phone_3: string | null
  fax: string | null
  email: string | null
  email_2: string | null
  email_3: string | null
  email_4: string | null
  comment: string | null
  email_feedback: string | null
  contact_phone: string | null
  prebooking_enabled: boolean
  prebooking_prefered_contact_media: ContactMedia
  booking_enabled: boolean
  booking_prefered_contact_media: ContactMedia
  accept_coffin_delivery: boolean | null
  has_delivery_fees: boolean | null
  advitam_grade: number
  airport: SupplierWarehouseAirportJSON | null
  supplier_id: number
  disabled: boolean
  last_update_user_id: number | null
  current_update_user_id: number | null
}

export interface SupplierWarehouseJSON extends SupplierWarehouseBase {
  current_update_dt: string | null
  last_update_dt: string | null
}

export interface SupplierWarehouse extends SupplierWarehouseBase {
  current_update_dt: Date | null
  last_update_dt: Date | null
}

export class SupplierWarehouse extends Model<SupplierWarehouseJSON> {
  constructor(data: SupplierWarehouseJSON) {
    super(data)
    this.current_update_dt = Model.parseDate(data.current_update_dt)
    this.last_update_dt = Model.parseDate(data.last_update_dt)
  }
}

export type {
  SupplierWarehouseZone,
  SupplierWarehouseZoneJSON,
  SupplierWarehouseZoneFuneralWorkJSON,
  SupplierWarehouseZoneFuneralWorkTypeJSON,
} from './Zone'

export type { SupplierMinimalWarehouseJSON } from './Minimal'
export type { SupplierWarehouseProductJSON } from './Product'
export type { SupplierWarehouseAirportJSON }
