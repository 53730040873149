import { createContext } from 'react'

interface MenuContextType {
  setIsMenuOpen: (value: boolean) => void
}

export const MenuContext = createContext<MenuContextType>({
  setIsMenuOpen: () => {
    // stub
  },
})
