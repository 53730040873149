import type { ReactNode } from 'react'

import style from './Table.module.scss'

interface TableProps {
  thead?: Array<ReactNode>
  tbody: Array<ReactNode[] | false>
  className?: string
}

export default function Table({ thead = [], tbody, className = '' }: TableProps): JSX.Element {
  return (
    <table className={`${style.table} ${className}`}>
      {Boolean(thead.length) && (
        <thead>
          <tr>
            {thead.map((head, col) => (
              // eslint-disable-next-line react/no-array-index-key
              <th key={col}>{head}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {tbody.map(
          (bodyRow, row) =>
            bodyRow && (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={row}>
                {bodyRow.map((body, col) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <td key={col}>{body}</td>
                ))}
              </tr>
            ),
        )}
      </tbody>
    </table>
  )
}
