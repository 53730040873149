import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { TextArea } from 'components/Input';
import RelativeSpinner from 'components/Spinner/RelativeSpinner.js';
import { withSlice } from 'utils/injectReducer';

import {
  makeSelectClientCommentary,
  makeSelectCommentary,
  makeSelectIsLoading,
} from './selectors';
import { updateCommentary } from './thunk';
import slice from './slice';
import messages from './messages';
import style from './style.scss';

function Commentaries(): JSX.Element {
  const dispatch = useDispatch();
  const commentary = useSelector(makeSelectCommentary());
  const clientCommentary = useSelector(makeSelectClientCommentary());
  const isLoading = useSelector(makeSelectIsLoading());
  const [commentaryValue, setCommentaryValue] = useState(commentary);

  const advisorCommentaryClasses = [style.commentary, style.advisor];
  if (isLoading) {
    advisorCommentaryClasses.push(style.loading);
  }

  useEffect(() => {
    setCommentaryValue(commentary);
  }, [commentary]);

  return (
    <section className={style.comments}>
      <div className={advisorCommentaryClasses.join(' ')}>
        {isLoading && <RelativeSpinner />}
        <FormattedMessage id={messages.commentary.id} />
        <TextArea
          disabled={isLoading}
          value={commentaryValue || ''}
          onChange={({
            target: { value },
          }: ChangeEvent<HTMLInputElement>): void => {
            setCommentaryValue(value);
          }}
          onBlur={(): void => {
            if (commentary !== commentaryValue) {
              dispatch(updateCommentary(commentaryValue));
            }
          }}
        />
      </div>
      <div className={[style.commentary, style.client].join(' ')}>
        <FormattedMessage id={messages.clientCommentary.id} />
        <div>{clientCommentary}</div>
      </div>
    </section>
  );
}

export default withSlice(slice)(Commentaries);
