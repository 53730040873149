import { assert } from '../assert'

export function chunk(string: string, size: number): string[] {
  assert(size > 0, 'Strings.chunk size must be greater than 0')

  const chunks = []
  for (let i = 0; i < string.length; i += size) {
    chunks.push(string.substring(i, i + size))
  }
  return chunks
}
