const enum PlaceType {
  CREMATORIUM = 'Crematorium',
  FUNERAL_PARLOR = 'FuneralParlor',
  GRAVEYARD = 'Graveyard',
  HOSPITAL = 'Hospital',
  PUBLIC = 'PublicLocation',
  SUPPLIER_WAREHOUSE = 'SupplierWarehouse',
  WORSHIP = 'Worship',
}

interface Place {
  id: number;
  type: PlaceType;
  name: string;
  address: string;
  city: string;
  postal_code: string;
  country: string;
  latitude: number | null;
  longitude: number | null;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Place {
  export const UPDATABLE_FIELDS: (keyof Place)[] = [
    'id',
    'name',
    'address',
    'city',
    'postal_code',
    'country',
  ];
  export type Type = PlaceType;
}

export { Place, PlaceType };
