import { ChangeEventHandler, FocusEvent, ReactElement, ReactNode } from 'react'
import Text from '../../../Text'
import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'
import style from './TextArea.module.scss'

interface BaseTextAreaProps {
  value: string | null
  label?: ReactNode
  name?: string
  placeholder?: string
  tooltip?: ReactNode
  error?: ReactElement | boolean
  disabled?: boolean
  maxLength?: number
  renderBelow?: (value: string) => ReactNode
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void
  className?: string
}

interface ReadOnlyTextAreaProps extends BaseTextAreaProps {
  readOnly: true
  onChange?: undefined
}

interface EditableTextAreaProps extends BaseTextAreaProps {
  readOnly?: false
  onChange: ChangeEventHandler<HTMLTextAreaElement>
}

type TextAreaProps = ReadOnlyTextAreaProps | EditableTextAreaProps

export default function TextArea({
  value,
  label,
  name,
  placeholder,
  error,
  tooltip,
  disabled,
  readOnly,
  maxLength,
  renderBelow,
  onChange,
  onBlur,
  className = '',
}: TextAreaProps): JSX.Element {
  const classes = [
    uiStyle.input,
    style.text_area,
    value && value.length > 0 && uiStyle.filled,
    error && uiStyle.error,
    disabled && uiStyle.disabled,
    className,
  ].filter(Boolean)

  return (
    <Label value={label} className={uiStyle.field} error={error} tooltip={tooltip}>
      <textarea
        value={value || ''}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        readOnly={readOnly}
        onChange={onChange}
        onBlur={onBlur}
        className={classes.join(' ')}
      />
      {renderBelow && (
        <Text small className={uiStyle.below}>
          {renderBelow(value || '')}
        </Text>
      )}
    </Label>
  )
}
