import { AnyAction, combineReducers, Reducer } from 'redux'

import { Arrays } from '@advitam/support'

import { HydrateAction, isHydrate } from './actions'

export default function createReducer(
  state: Record<string, unknown>,
  injectedReducers = {},
): Reducer {
  const mountedReducers = Object.keys(injectedReducers)
  const additionalReducers = Arrays.difference(Object.keys(state), mountedReducers)
  const stubReducers = Object.fromEntries(
    additionalReducers.map(key => [
      key,
      (_s: Record<string, unknown>, _action: AnyAction): unknown => state[key],
    ]),
  )

  const reducers = {
    ...stubReducers,
    ...injectedReducers,
  }
  const rootReducer =
    Object.keys(reducers).length > 0 ? combineReducers(reducers) : (s: unknown): unknown => s

  return (prevState: Record<string, unknown>, action: HydrateAction | AnyAction): unknown => {
    if (!isHydrate(action)) {
      return rootReducer(prevState, action)
    }

    return {
      ...prevState,
      ...action.payload,
    }
  }
}
