import type { ReactNode } from 'react'

import Dropdown, { DropdownPosition } from '../../../../Dropdown'

import style from './MyAccount.module.scss'

interface MyAccountMenuProps {
  icon: ReactNode
  menu: ReactNode[]
}

export default function MyAccountMenu({ icon, menu }: MyAccountMenuProps): JSX.Element {
  return (
    <Dropdown
      position={DropdownPosition.RIGHT}
      placeholder={<div className={style.icon}>{icon}</div>}
      className={style.dropdown}
    >
      {menu.filter(Boolean)}
    </Dropdown>
  )
}
