import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  avi: { id: 'app.containers.Crematorium.Rooms.avi' },
  acceptedMedias: { id: 'app.containers.Crematorium.Rooms.acceptedMedias' },
  addRoom: { id: 'app.containers.Crematorium.Rooms.addRoom' },
  capacity: { id: 'app.containers.Crematorium.Rooms.capacity' },
  cd: { id: 'app.containers.Crematorium.Rooms.cd' },
  deleteConfirmation: {
    id: 'app.containers.Crematorium.Rooms.deleteConfirmation',
  },
  dvd: { id: 'app.containers.Crematorium.Rooms.dvd' },
  errorRoomInUseModalTitle: {
    id: 'app.containers.Crematorium.Rooms.errorRoomInUseModalTitle',
  },
  errorRoomInUseModalText: {
    id: 'app.containers.Crematorium.Rooms.errorRoomInUseModalText',
  },
  jpeg: { id: 'app.containers.Crematorium.Rooms.jpeg' },
  mediaFormats: { id: 'app.containers.Crematorium.Rooms.mediaFormats' },
  mediaSupports: { id: 'app.containers.Crematorium.Rooms.mediaSupports' },
  mp3: { id: 'app.containers.Crematorium.Rooms.mp3' },
  mp4: { id: 'app.containers.Crematorium.Rooms.mp4' },
  music: { id: 'app.containers.Crematorium.Rooms.music' },
  photo: { id: 'app.containers.Crematorium.Rooms.photo' },
  png: { id: 'app.containers.Crematorium.Rooms.png' },
  title: { id: 'app.containers.Crematorium.Rooms.title' },
  usb: { id: 'app.containers.Crematorium.Rooms.usb' },
  vhs: { id: 'app.containers.Crematorium.Rooms.vhs' },
  video: { id: 'app.containers.Crematorium.Rooms.video' },
  vinyl: { id: 'app.containers.Crematorium.Rooms.vinyl' },
});
