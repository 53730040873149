import type { IntlShape } from 'react-intl';

import type { ClientReviewNotificationJSON } from '@advitam/api/models/Notification';
import Star from '@advitam/ui/images/icons/star.svg';
import { Path } from 'containers/App/constants';

import ClientReview1Standalone from './icons/client-review-1.standalone.png.bin';
import ClientReview2Standalone from './icons/client-review-2.standalone.png.bin';
import ClientReview3Standalone from './icons/client-review-3.standalone.png.bin';
import ClientReview4Standalone from './icons/client-review-4.standalone.png.bin';
import ClientReview5Standalone from './icons/client-review-5.standalone.png.bin';
import type { TileProps } from './TileProps';
import messages from './messages';
import style from './NotificationTile.module.scss';

const gradeIcons = [
  ClientReview1Standalone,
  ClientReview2Standalone,
  ClientReview3Standalone,
  ClientReview4Standalone,
  ClientReview5Standalone,
];

export default function getClientReviewProps(
  notification: ClientReviewNotificationJSON,
  intl: IntlShape,
): TileProps {
  const grade = Math.min(
    notification.comp_info.ceremony_grade,
    notification.comp_info.funeral_advisor_grade,
  );
  const isGradeGood = grade > 2;

  return {
    link: Path.DEAL(notification.comp_info.deal_uuid, 'taches'),
    IconComponent: (): JSX.Element => (
      <div className={style.grade_stars}>
        {Array(grade)
          .fill(0)
          .map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Star key={i} className={isGradeGood ? style.good : style.bad} />
          ))}
        {Array(5 - grade)
          .fill(0)
          .map((_, i) => (
            <Star
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={`${style.star__empty} ${
                isGradeGood ? style.good : style.bad
              }`}
            />
          ))}
      </div>
    ),
    icon: gradeIcons[grade],
    title: intl.formatMessage(messages.clientReviewTitle),
    body: intl.formatMessage(messages.clientReviewText, {
      clientName: notification.comp_info.client_full_name,
      funeralAdvisorGrade: notification.comp_info.funeral_advisor_grade,
      ceremonyGrade: notification.comp_info.ceremony_grade,
    }),
  };
}
