import { DateTime } from 'luxon';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { AutocompleteResult } from '@advitam/api/v1/Autocompletes';
import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';
import type { EntityInvoiceEntityType } from '@advitam/api/models/EntityInvoice/Entity/Type';
import type { EntityInvoicePendingPaymentJSON } from '@advitam/api/models/EntityInvoice/PendingPayment';
import { ResourceType } from '@advitam/api/models/DocumentEditions';
import { EntityInvoicePrestationStatus } from '@advitam/api/models/EntityInvoice/Prestation/Status';
import { assert } from '@advitam/support';
import { saveDocuments } from 'containers/DocumentsEditor/thunk';

import { BILLING } from './constants';
import {
  clearPendingPayments,
  createPendingPayment,
  deletePendingPayment,
  downloadBankTransfer,
  fetchEntityInvoices,
  fetchPendingPayments,
  validateInvoice,
} from './thunk';
import { savePrestation } from './List/Edit/thunk';

export interface Filters {
  q?: string;
  start_date?: Date;
  end_date?: Date;
  entity_type?: EntityInvoiceEntityType;
  entity?: AutocompleteResult;
  status?: EntityInvoicePrestationStatus;
}

export interface State {
  invoices: EntityInvoiceJSON[] | null;
  loadingInvoices: number[];
  uploadedInvoices: number[];
  loadingValidations: number[];
  isLoading: boolean;
  error: SerializedApiError | null;
  filters: Filters;
  page: number;
  pageCount: number | null;

  pendingPayments: EntityInvoicePendingPaymentJSON[];
  paymentError: SerializedApiError | null;
  arePaymentsLoading: boolean;
  arePaymentsSaving: boolean;
  loadingPayments: number[];

  currentFetchRequestId: string | null;
}

export interface AppStateSubset {
  [BILLING]: State;
}

const initialState: State = {
  invoices: null,
  loadingInvoices: [],
  uploadedInvoices: [],
  loadingValidations: [],
  isLoading: false,
  error: null,
  filters: {},
  page: 1,
  pageCount: null,
  pendingPayments: [],
  paymentError: null,
  arePaymentsLoading: false,
  arePaymentsSaving: false,
  loadingPayments: [],
  currentFetchRequestId: null,
};

const slice = createSlice({
  name: BILLING,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    reset(state) {
      state.invoices = null;
      state.loadingInvoices = [];
      state.uploadedInvoices = [];
      state.loadingValidations = [];
      state.page = 1;
      state.pageCount = 0;
      state.isLoading = false;
      state.error = null;
    },
    setFilters(state, { payload }: PayloadAction<Filters>) {
      state.filters = payload;
      if (!state.filters.entity_type) {
        state.filters.entity = undefined;
      }
      state.invoices = null;
      state.loadingInvoices = [];
      state.uploadedInvoices = [];
      state.loadingValidations = [];
      state.page = 1;
      state.pageCount = 0;
    },
    clearPaymentError(state) {
      state.paymentError = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchEntityInvoices.pending, (state, { meta }) => {
      state.isLoading = true;
      state.error = null;
      state.currentFetchRequestId = meta.requestId;
    });
    builder.addCase(
      fetchEntityInvoices.fulfilled,
      (state, { meta, payload }) => {
        if (meta.requestId !== state.currentFetchRequestId) {
          return;
        }
        state.isLoading = false;
        state.invoices ||= [];
        state.invoices.push(...payload.list);
        state.pageCount = payload.page_count;
        state.page = meta.arg;
        state.currentFetchRequestId = null;
      },
    );
    builder.addCase(
      fetchEntityInvoices.rejected,
      (state, { meta, payload }) => {
        if (meta.requestId !== state.currentFetchRequestId) {
          return;
        }
        state.isLoading = false;
        state.error = ApiError.serialize(payload);
      },
    );

    builder.addCase(saveDocuments.pending, (state, { meta }) => {
      const { resource } = meta.arg.documents[0];
      if (resource.type === ResourceType.INVOICE && resource.id) {
        state.loadingInvoices.push(resource.id);
      }
    });
    builder.addCase(saveDocuments.fulfilled, (state, { meta }) => {
      const { resource } = meta.arg.documents[0];
      if (resource.type === ResourceType.INVOICE && resource.id) {
        state.uploadedInvoices.push(resource.id);
        state.loadingInvoices = state.loadingInvoices.filter(
          id => id !== resource.id,
        );
      }
    });
    builder.addCase(saveDocuments.rejected, (state, { meta }) => {
      const { resource } = meta.arg.documents[0];
      if (resource.type === ResourceType.INVOICE && resource.id) {
        state.loadingInvoices = state.loadingInvoices.filter(
          id => id !== resource.id,
        );
      }
    });

    builder.addCase(validateInvoice.pending, (state, { meta }) => {
      state.loadingValidations.push(meta.arg);
    });
    builder.addCase(validateInvoice.fulfilled, (state, { meta }) => {
      state.loadingValidations.filter(id => id !== meta.arg);
      assert(state.invoices !== null);
      const invoice = state.invoices.find(i => i.id === meta.arg);
      if (!invoice) {
        return;
      }
      invoice.prestations.forEach(prestation => {
        prestation.status = EntityInvoicePrestationStatus.VALIDATED;
        prestation.validated_at = DateTime.now().toISO();
      });
    });
    builder.addCase(validateInvoice.rejected, (state, { meta }) => {
      state.loadingValidations.filter(id => id !== meta.arg);
    });

    builder.addCase(savePrestation.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      assert(state.invoices !== null);
      const index = state.invoices.findIndex(
        invoice => invoice.id === payload.id,
      );
      state.invoices[index] = payload;
    });

    builder.addCase(fetchPendingPayments.pending, state => {
      state.arePaymentsLoading = true;
    });
    builder.addCase(fetchPendingPayments.fulfilled, (state, { payload }) => {
      state.arePaymentsLoading = false;
      state.pendingPayments = payload;
    });
    builder.addCase(fetchPendingPayments.rejected, (state, { payload }) => {
      state.arePaymentsLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createPendingPayment.pending, (state, { meta }) => {
      state.loadingPayments.push(meta.arg);
    });
    builder.addCase(
      createPendingPayment.fulfilled,
      (state, { meta, payload }) => {
        state.loadingPayments = state.loadingPayments.filter(
          id => id !== meta.arg,
        );
        state.pendingPayments.push(payload);
      },
    );
    builder.addCase(
      createPendingPayment.rejected,
      (state, { meta, payload }) => {
        state.loadingPayments = state.loadingPayments.filter(
          id => id !== meta.arg,
        );
        state.paymentError = ApiError.serialize(payload);
      },
    );

    builder.addCase(deletePendingPayment.pending, (state, { meta }) => {
      state.loadingPayments.push(meta.arg);
    });
    builder.addCase(deletePendingPayment.fulfilled, (state, { meta }) => {
      state.loadingPayments = state.loadingPayments.filter(
        id => id !== meta.arg,
      );
      state.pendingPayments = state.pendingPayments.filter(
        payment => payment.id !== meta.arg,
      );
    });
    builder.addCase(
      deletePendingPayment.rejected,
      (state, { meta, payload }) => {
        state.loadingPayments = state.loadingPayments.filter(
          id => id !== meta.arg,
        );
        state.paymentError = ApiError.serialize(payload);
      },
    );

    builder.addCase(clearPendingPayments.pending, state => {
      state.arePaymentsSaving = true;
    });
    builder.addCase(clearPendingPayments.fulfilled, state => {
      state.arePaymentsSaving = false;
      state.pendingPayments = [];
    });
    builder.addCase(clearPendingPayments.rejected, (state, { payload }) => {
      state.arePaymentsSaving = false;
      state.paymentError = ApiError.serialize(payload);
    });

    builder.addCase(downloadBankTransfer.pending, state => {
      state.arePaymentsSaving = true;
    });
    builder.addCase(downloadBankTransfer.fulfilled, state => {
      state.arePaymentsSaving = false;
      state.pendingPayments.forEach(payment => {
        assert(state.invoices !== null);
        const invoice = state.invoices.find(
          i => i.id === payment.entity_invoice_id,
        );
        if (invoice) {
          invoice.paid_on = DateTime.now().toISO();
        }
      });
      state.pendingPayments = [];
    });
    builder.addCase(downloadBankTransfer.rejected, (state, { payload }) => {
      state.arePaymentsSaving = false;
      state.paymentError = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { clearPaymentError, reset, setFilters } = slice.actions;

export default slice;
