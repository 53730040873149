import type { CountryJSON } from '../../../Country'

export const enum LocationType {
  SUPPLIER_WAREHOUSE = 'SupplierWarehouse',
  FUNERAL_PARLOR = 'FuneralParlor',
  HOSPITAL = 'Hospital',
  WORSHIP = 'Worship',
  PUBLIC_LOCATION = 'PublicLocation',
  CREMATORIUM = 'Crematorium',
  GRAVEYARD = 'Graveyard',
}

export const enum PublicLocationType {
  HOME = 'Home',
  PUBLIC = 'Public',
}

export interface PublicLocation {
  id: number
  type: LocationType.PUBLIC_LOCATION
  name: string | null
  address: string | null
  postal_code: string | null
  city: string
  department: string | null
  longitude: number
  latitude: number
  insee_code: string | null
  location_type: PublicLocationType
  country: CountryJSON
}

export type EntityLocationType =
  | LocationType.SUPPLIER_WAREHOUSE
  | LocationType.FUNERAL_PARLOR
  | LocationType.HOSPITAL
  | LocationType.WORSHIP
  | LocationType.CREMATORIUM
  | LocationType.GRAVEYARD

export interface EntityLocation {
  id: number
  name: string | null
  type: EntityLocationType
  address: string | null
  postal_code: string | null
  city: string | null
}

export type Location = EntityLocation | PublicLocation
