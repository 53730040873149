import { createAction } from 'lib/reactvitam/action_cable';

export interface UpdatePayload {
  user_id: number;
  session_id: string;
}

/**
 * The resource have been updated
 * Not for sending by the frontend, this is only the interface for reception of
 * backend messages.
 */
function update(user_id: number, session_id: string): UpdatePayload {
  return { user_id, session_id };
}

export default createAction(update, 'update');
