import { defineMessages } from 'react-intl';

export default defineMessages({
  createPrestation: { id: 'app.containers.Billing.List.Edit.createPrestation' },
  editPrestation: { id: 'app.containers.Billing.List.Edit.editPrestation' },
  entityType: { id: 'app.containers.Billing.List.Edit.entityType' },
  entityId: { id: 'app.containers.Billing.List.Edit.entityId' },
  billingType: { id: 'app.containers.Billing.List.Edit.billingType' },
  billingTypeTooltipText: {
    id: 'app.containers.Billing.List.Edit.billingTypeTooltipText',
  },
  billingTypeTooltipItem1: {
    id: 'app.containers.Billing.List.Edit.billingTypeTooltipItem1',
  },
  billingTypeTooltipItem2: {
    id: 'app.containers.Billing.List.Edit.billingTypeTooltipItem2',
  },
  billingTypeTooltipItem3: {
    id: 'app.containers.Billing.List.Edit.billingTypeTooltipItem3',
  },
  date: { id: 'app.containers.Billing.List.Edit.date' },
  prestation: { id: 'app.containers.Billing.List.Edit.prestation' },
  defunctOrClient: { id: 'app.containers.Billing.List.Edit.defunctOrClient' },
  quantity: { id: 'app.containers.Billing.List.Edit.quantity' },
  billedByEntity: { id: 'app.containers.Billing.List.Edit.billedByEntity' },
  billedByEntityTooltip: {
    id: 'app.containers.Billing.List.Edit.billedByEntityTooltip',
  },
  billedByBob: { id: 'app.containers.Billing.List.Edit.billedByBob' },
  billedByBobTooltip: {
    id: 'app.containers.Billing.List.Edit.billedByBobTooltip',
  },
  priceTaxExcl: { id: 'app.containers.Billing.List.Edit.priceTaxExcl' },
  priceTaxIncl: { id: 'app.containers.Billing.List.Edit.priceTaxIncl' },
  bankTransferLabel: {
    id: 'app.containers.Billing.List.Edit.bankTransferLabel',
  },
  cancel: { id: 'app.containers.Billing.List.Edit.cancel' },
  submit: { id: 'app.containers.Billing.List.Edit.submit' },
  add: { id: 'app.containers.Billing.List.Edit.add' },
  billingTypeDeal: { id: 'app.containers.Billing.List.Edit.billingTypeDeal' },
  billingTypePrestation: {
    id: 'app.containers.Billing.List.Edit.billingTypePrestation',
  },
  billingTypeMonth: { id: 'app.containers.Billing.List.Edit.billingTypeMonth' },
  billingTypeQuarter: {
    id: 'app.containers.Billing.List.Edit.billingTypeQuarter',
  },
});
