import Billing from './Billing';
import Documents from './Documents';
import History from './History';
import Home from './Home';
import Identity from './Identity';
import Miscellaneous from './Miscellaneous';
import Steps from './Steps';
import Summary from './Summary';
import Suppliers from './Suppliers';
import Todolist from './Todolist';

export const Sections = {
  Billing,
  Documents,
  History,
  Home,
  Identity,
  Miscellaneous,
  Steps,
  Summary,
  Suppliers,
  Todolist,
};
