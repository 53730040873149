import { defineMessages } from 'react-intl';

export default defineMessages({
  finishedBatchSignatureTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.finishedBatchSignatureTitle',
  },
  finishedBatchSignatureBody: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.finishedBatchSignatureBody',
  },
  canceledBatchSignatureTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.canceledBatchSignatureTitle',
  },
  canceledBatchSignatureBody: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.canceledBatchSignatureBody',
  },
  batchSignatureTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.batchSignatureTitle',
  },
  batchSignatureBody: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.batchSignatureBody',
  },

  bookingAcceptedTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.bookingAcceptedTitle',
  },
  bookingAcceptedText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.bookingAcceptedText',
  },
  bookingRefusedTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.bookingRefusedTitle',
  },
  bookingRefusedText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.bookingRefusedText',
  },

  clientReviewTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.clientReviewTitle',
  },
  clientReviewText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.clientReviewText',
  },

  failedSendingTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.failedSendingTitle',
  },
  failedSendingText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.failedSendingText',
  },

  funnelCreatedTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.funnelCreatedTitle',
  },
  funeralFunnelCreatedText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.funeralFunnelCreatedText',
  },
  marbleFunnelCreatedText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.marbleFunnelCreatedText',
  },

  layerCreatedTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.layerCreatedTitle',
  },
  layerCreatedText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.layerCreatedText',
  },

  paymentCreatedTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.paymentCreatedTitle',
  },
  paymentCreatedText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.paymentCreatedText',
  },
  paymentFailedTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.paymentFailedTitle',
  },
  paymentFailedText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.paymentFailedText',
  },
  unknownPaymentUpdatedTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.unknownPaymentUpdatedTitle',
  },
  unknownPaymentUpdatedText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.unknownPaymentUpdatedText',
  },
  CreditCard: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.CreditCard',
  },
  ThreeTimes: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.ThreeTimes',
  },
  FourTimes: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.FourTimes',
  },
  TenTimes: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.TenTimes',
  },
  TwelveTimes: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.TwelveTimes',
  },
  Alma2x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma2x',
  },
  Alma3x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma3x',
  },
  Alma4x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma4x',
  },
  Alma5x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma5x',
  },
  Alma6x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma6x',
  },
  Alma7x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma7x',
  },
  Alma8x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma8x',
  },
  Alma9x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma9x',
  },
  Alma10x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma10x',
  },
  Alma11x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma11x',
  },
  Alma12x: {
    id: 'app.containers.App.NavBar.NotificationCenter.NotificationTile.Alma12x',
  },
  SepaTransfer: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.SepaTransfer',
  },

  reminderTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.reminderTitle',
  },
  reminderText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.reminderText',
  },

  unsupportedNotificationTitle: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.unsupportedNotificationTitle',
  },
  unsupportedNotificationText: {
    id:
      'app.containers.App.NavBar.NotificationCenter.NotificationTile.unsupportedNotificationText',
  },
});
