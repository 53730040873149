import { ReactNode } from 'react'
import { Field, UseFieldConfig } from 'react-final-form'

import DateUI from '../UI/Date'
import { composeValidators, isRequired, Validator } from '../validators'

interface DateProps<FormValues> {
  name: string
  label?: ReactNode
  disabled?: boolean
  required?: boolean
  validate?: Validator<string, FormValues>
  tooltip?: ReactNode
  className?: string
  containerClassName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: UseFieldConfig<any>['format']
}

export default function Date<FormValues = unknown>({
  name,
  label,
  disabled,
  required,
  validate,
  parse,
  format,
  tooltip,
  className,
  containerClassName,
}: DateProps<FormValues>): JSX.Element {
  return (
    <Field
      name={name}
      parse={parse}
      format={format}
      validate={composeValidators(required && isRequired, validate)}
    >
      {({ input, meta }): JSX.Element => (
        <DateUI
          label={label}
          name={input.name}
          onChange={input.onChange}
          onBlur={input.onBlur}
          disabled={disabled}
          value={input.value as string}
          error={meta.touched && !meta.valid}
          className={className}
          containerClassName={containerClassName}
          tooltip={tooltip}
        />
      )}
    </Field>
  )
}
