import { ReactNode, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsPopup } from 'containers/Crud';
import { assert } from 'lib/support';

import Sidebar from '../Sidebar';
import { makeSelectRawHospital } from '../selectors';
import { updateHospitalName } from '../thunk';
import type { HospitalForm } from './types';
import style from '../Hospital.module.scss';

interface WrapperProps<T> {
  children: ReactNode;
  initialSectionValues?: T;
  onSubmit: (values: HospitalForm<T>) => void;
}

export default function Wrapper<T>({
  children,
  initialSectionValues,
  onSubmit,
}: WrapperProps<T>): JSX.Element {
  const dispatch = useDispatch();

  const hospital = useSelector(makeSelectRawHospital());
  assert(hospital !== null);

  const initialValues: Partial<HospitalForm<T>> = {
    hospital,
    sectionValues: initialSectionValues,
  };

  const handleFormSubmit = useCallback(
    (values: HospitalForm<T>): void => {
      if (values.sectionValues && hospital.name !== values.hospital.name) {
        dispatch(updateHospitalName(values.hospital.name));
      }

      onSubmit(values);
    },
    [onSubmit, hospital, dispatch],
  );

  return (
    <Form<HospitalForm<T>>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <Sidebar />
          {(dirty || !hospital.id) && <ActionsPopup isNew={!hospital.id} />}
          {children}
        </form>
      )}
    </Form>
  );
}
