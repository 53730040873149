import { MessageDescriptor } from 'react-intl'

export const enum ErrorCode {
  EMAIL_INVALID = 'email_invalid',
  INVALID_IBAN_CHECK_DIGITS = 'invalid_iban_check_digits',
  INVALID = 'invalid',
  PASSWORD_INVALID = 'password_invalid',
  PHONE_INVALID = 'phone_invalid',
  REQUIRED = 'required',
}

export type ErrorMessages = Record<string, MessageDescriptor | undefined>

export interface ValidationError {
  message: string
  values?: Record<string, string>
}
