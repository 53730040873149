/*
 *
 * DialogBox actions
 *
 */

import { OPEN_DIALOG, CLOSE_DIALOG } from './constants';

export function openDialog(props) {
  return {
    type: OPEN_DIALOG,
    props,
  };
}

export function closeDialog() {
  return {
    type: CLOSE_DIALOG,
  };
}
