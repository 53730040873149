import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { ConsulateJSON } from '@advitam/api/models/Consulate';
import { saveEditableDocuments } from 'containers/Crud/Documents/Editable';
import { saveSupportingDocuments } from 'containers/Crud/Documents/Supporting';
import { assert } from 'lib/support';

import { CONSULATE } from './constants';
import type { NewConsulate } from './types';
import {
  createConsulate,
  destroyConsulate,
  fetchConsulate,
  saveConsulate,
  setConsulateDefault,
  setIsConsulateDisabled,
  updateConsulate,
  updateConsulateName,
} from './thunk';

export interface State {
  consulate: ConsulateJSON | NewConsulate | null;
  isLoading: boolean;
  isSaving: boolean;
  isDestroying: boolean;
  isUpdatingName: boolean;
  isUpdatingDefault: boolean;
  isDefaultOverrideConfirmationNeeded: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [CONSULATE]: State;
}

export const initialState: State = {
  consulate: null,
  isLoading: false,
  isSaving: false,
  isDestroying: false,
  isUpdatingName: false,
  isUpdatingDefault: false,
  isDefaultOverrideConfirmationNeeded: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: CONSULATE,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearError(state) {
      state.error = null;
    },
    setConsulate(
      state,
      { payload }: PayloadAction<ConsulateJSON | NewConsulate | null>,
    ) {
      state.consulate = payload;
    },
    cancelDefaultOverride(state) {
      assert(state.consulate !== null);
      state.isDefaultOverrideConfirmationNeeded = false;
      state.consulate.default = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchConsulate.pending, state => {
      state.isLoading = true;
      state.destroyError = null;
    });
    builder.addCase(fetchConsulate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.consulate = payload;
    });
    builder.addCase(fetchConsulate.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveConsulate.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveConsulate.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveConsulate.rejected, state => {
      state.isSaving = false;
    });

    builder.addCase(createConsulate.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateConsulate.fulfilled, (state, { payload }) => {
      state.consulate = payload;
    });
    builder.addCase(updateConsulate.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateConsulateName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updateConsulateName.fulfilled, (state, { payload }) => {
      state.isUpdatingName = false;
      state.consulate = payload;
    });
    builder.addCase(updateConsulateName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyConsulate.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyConsulate.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroyConsulate.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setConsulateDefault.pending, state => {
      state.isUpdatingDefault = true;
    });
    builder.addCase(
      setConsulateDefault.fulfilled,
      (state, { meta, payload }) => {
        assert(state.consulate !== null);
        state.isUpdatingDefault = false;
        state.isDefaultOverrideConfirmationNeeded = payload.needsConfiramtion;
        state.consulate.default = meta.arg.consulate.default;
      },
    );
    builder.addCase(setConsulateDefault.rejected, (state, { payload }) => {
      state.isUpdatingDefault = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(setIsConsulateDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setIsConsulateDisabled.fulfilled, (state, { meta }) => {
      state.isSaving = false;
      assert(state.consulate !== null);
      state.consulate.disabled = meta.arg;
    });
    builder.addCase(setIsConsulateDisabled.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveEditableDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveEditableDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveEditableDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveSupportingDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveSupportingDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveSupportingDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const {
  cancelDefaultOverride,
  clearError,
  setConsulate,
} = slice.actions;
export default slice;
