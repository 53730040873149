import { useCallback, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import { useThunkDispatch, withSlice } from '@advitam/react';
import type { DocumentTemplateJSON } from 'models/DocumentTemplate';

import Editor from './Editor';
import slice, { setUploads } from './slice';
import { fetchData, uploadDocument } from './thunk';
import { Resource, DocumentTemplateData } from './models';

interface DocumentTemplateEditorProps {
  isIntegrationMode?: boolean;
  variables?: string[];
  templateId?: number | null;
  resource: Resource;
  onSave: (doc: DocumentTemplateJSON<DocumentTemplateData>) => void;
  onCancel: () => void;
}

function DocumentTemplateEditor({
  isIntegrationMode = false,
  resource,
  templateId = null,
  variables = [],
  onSave,
  onCancel,
}: DocumentTemplateEditorProps): JSX.Element {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    if (templateId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(fetchData(templateId));
    } else {
      dispatch(setUploads([]));
    }
  }, [dispatch, templateId]);

  const save = useCallback(async (): Promise<void> => {
    const result = await dispatch(uploadDocument(resource));
    const payload = unwrapResult(result);
    onSave(payload);
  }, [resource, onSave, dispatch]);

  return (
    <Editor
      isIntegrationMode={isIntegrationMode}
      variables={variables}
      onSave={save}
      onCancel={onCancel}
    />
  );
}

export default withSlice<DocumentTemplateEditorProps>(slice)(
  DocumentTemplateEditor,
);
