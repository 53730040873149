import type { ReactNode } from 'react'

import Heading from '../../Heading'

import style from './Testimonials.module.scss'
import GoogleRate from '../../GoogleRate'

interface ReviewsV2Props {
  content: ReactNode
  rate: number
  reviewsLink: string
  reviewsLinkInternal?: boolean
  children: ReactNode[]
}

export default function ReviewsV2({
  content,
  rate,
  children,
  reviewsLink,
  reviewsLinkInternal,
}: ReviewsV2Props): JSX.Element {
  return (
    <div className={[style.card, style.transparent, style.reviews_v2].join(' ')}>
      <div className={style.title}>
        <Heading tagName="div" className={style.title}>
          {content}
        </Heading>
        <GoogleRate rate={rate} internal={reviewsLinkInternal} href={reviewsLink} horizontal />
      </div>
      <div className={style.items}>{children}</div>
    </div>
  )
}
