import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';

import { assert } from 'lib/support';

import {
  createDocument,
  destroyDocument,
} from './Autocompletes/Documents/thunk';
import {
  createRecipient,
  destroyRecipient,
} from './Autocompletes/Recipients/thunk';
import { SEND_BATCH_MODAL } from './constants';
import { openWithCreation, openWithFetch, sendBatch } from './thunk';
import { StateBatch } from './types';
import { getRecipientUniqueId } from './utils';

export interface State {
  batch: StateBatch | null;
  isOpen: boolean;
  isLoading: boolean;
  isSending: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [SEND_BATCH_MODAL]: State;
}

export const initialState: State = {
  batch: null,
  isOpen: false,
  isLoading: false,
  isSending: false,
  error: null,
};

const slice = createSlice({
  name: SEND_BATCH_MODAL,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    closeModal(state) {
      state.isOpen = false;
    },
    removeDocumentsByIds(state, { payload }: PayloadAction<number[]>) {
      if (state.batch) {
        state.batch.documents = state.batch.documents.filter(
          doc => !payload.includes(doc.deal_document_id),
        );
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(openWithFetch.pending, state => {
      state.isOpen = true;
      state.isLoading = true;
    });
    builder.addCase(openWithFetch.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.batch = payload;
    });
    builder.addCase(openWithFetch.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(openWithCreation.pending, state => {
      state.isOpen = true;
      state.isLoading = true;
    });
    builder.addCase(openWithCreation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.batch = payload;
    });
    builder.addCase(openWithCreation.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createDocument.pending, (state, { meta: { arg } }) => {
      assert(state.batch !== null);
      state.batch.documents.push({
        name: arg.description,
        deal_document_id: arg.id,
      });
    });
    builder.addCase(createDocument.fulfilled, (state, { payload }) => {
      assert(state.batch !== null);

      const documentIdx = state.batch.documents.findIndex(
        doc => doc.deal_document_id === payload.deal_document_id,
      );

      state.batch.documents[documentIdx] = payload;
    });
    builder.addCase(createDocument.rejected, (state, { meta }) => {
      assert(state.batch !== null);

      state.batch.documents = state.batch.documents.filter(
        doc => doc.deal_document_id !== meta.arg.id,
      );
    });

    builder.addCase(destroyDocument.fulfilled, (state, { meta }) => {
      assert(state.batch !== null);

      state.batch.documents = state.batch.documents.filter(
        document => document.deal_document_id !== meta.arg,
      );
    });

    builder.addCase(createRecipient.fulfilled, (state, { payload }) => {
      assert(state.batch !== null);
      state.batch.recipients.push(payload);
    });

    builder.addCase(destroyRecipient.fulfilled, (state, { meta }) => {
      assert(state.batch !== null);

      state.batch.recipients = state.batch.recipients.filter(
        recipient =>
          getRecipientUniqueId(recipient) !== getRecipientUniqueId(meta.arg),
      );
    });

    builder.addCase(sendBatch.pending, state => {
      state.isSending = true;
    });
    builder.addCase(sendBatch.fulfilled, state => {
      state.isSending = false;
      state.isOpen = false;
    });
    builder.addCase(sendBatch.rejected, (state, { payload }) => {
      state.isSending = false;
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { closeModal, removeDocumentsByIds } = slice.actions;
export default slice;
