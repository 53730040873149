import PropTypes from 'prop-types';

import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';

import { FUNERAL_PARLOR, PUBLIC } from 'containers/Deal/DealFuneral/constants';

import Services from '../Services';
import {
  renderStepDefaults,
  renderLocation,
  renderEditLocation,
} from './stepEditorsUtils';

import messages from './messages';

const locationTypes = [
  { value: FUNERAL_PARLOR, label: messages.funeralParlor },
  { value: PUBLIC, label: messages.public },
];

const Deposit = ({
  step,
  stepServices,
  handleChange,
  addService,
  removeService,
  updateDelegate,
  isManualMode,
  userRole,
  getStep,
  updateService,
}) => {
  const prevStep = getStep(step.id - 1);

  return (
    <div className="step">
      <div className="step__header">
        {renderStepDefaults(step, handleChange)}
        {renderLocation(step.location, messages.location, prevStep)}
        <CheckboxDelegate
          isDelegate={!!step.doneByAnotherFuneral}
          updateDelegate={updateDelegate}
        />
      </div>
      <div>
        {renderEditLocation(step, handleChange, locationTypes)}
        <Services
          stepId={step.id}
          services={stepServices}
          addService={addService}
          removeService={removeService}
          isManualMode={isManualMode}
          userRole={userRole}
          updateService={updateService}
        />
      </div>
    </div>
  );
};

Deposit.propTypes = {
  step: PropTypes.object.isRequired,
  stepServices: PropTypes.array.isRequired,
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  updateDelegate: PropTypes.func.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
  getStep: PropTypes.func.isRequired,
};

export default Deposit;
