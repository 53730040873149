import type { ReactNode } from 'react'

import Text from '../../Text'

import Vignettes from './Vignettes'
import style from './ProductCard.module.scss'

interface ProductCardProps {
  title: ReactNode
  price: ReactNode | null
  image: ReactNode
  vignetteUrls?: string[]
  ctaText?: ReactNode
  ctaAction?: () => void
  className?: string
}

export default function ProductCard({
  title,
  price,
  image,
  vignetteUrls,
  ctaText,
  ctaAction,
  className,
}: ProductCardProps): JSX.Element {
  const classes = [style.product, className].filter(Boolean)

  return (
    <div className={classes.join(' ')}>
      <div className={style.image}>
        {image}
        {vignetteUrls && <Vignettes vignetteUrls={vignetteUrls} />}
      </div>
      <div className={style.details}>
        <div className={style.title}>
          <Text>
            <b>{title}</b>
          </Text>
          {price && (
            <Text>
              <b>{price}</b>
            </Text>
          )}
        </div>
        {ctaText && ctaAction && (
          <button type="button" onClick={ctaAction} className={style.cta}>
            <Text small>{ctaText}</Text>
          </button>
        )}
      </div>
    </div>
  )
}
