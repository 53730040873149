export const enum Control {
  AI = 'ai',
  LINK = 'link',
  LISTS = 'lists',
}

export enum AiFeature {
  REDACT_EMAIL = 'redactemail',
  FIX = 'fix',
  REFORMULATE = 'reformulate',
  TRANSLATE = 'translate',
}

export interface MailRedactionVariables {
  /* eslint-disable camelcase */
  subject: string
  recipient_type: string
  recipient_name: string
  documents?: string[]
  /* eslint-enable camelcase */
}
