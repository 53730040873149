import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from 'api';
import { Deal, DealJSON } from 'models/Deal';
import type { AppStateSubset, State } from './slice';

import type { AppStateSubset as ClientsAppStateSubset } from './Clients/slice';
import { makeSelectIsDirty as makeSelectAreClientsDirty } from './Clients/selectors';
import { DEAL } from './constants';
import { AppStateSubset as IdentityAppStateSubset } from './Sections/Identity/slice';
import { makeSelectError as makeSelectIdentityError } from './Sections/Identity/selectors';

type DealSelector<T> = Selector<AppStateSubset, T>;

const selectDealDomain = (state: AppStateSubset): State => state[DEAL];

export const makeSelectRawDeal = (): DealSelector<DealJSON | null> =>
  createSelector(selectDealDomain, state => state.deal);

export const makeSelectDeal = (): DealSelector<Deal | null> =>
  createSelector(makeSelectRawDeal(), rawDeal => rawDeal && new Deal(rawDeal));

export const makeSelectDealUuid = (): DealSelector<string | null> =>
  createSelector(makeSelectRawDeal(), rawDeal => rawDeal?.uuid || null);

export const makeSelectIsLoading = (): DealSelector<boolean> =>
  createSelector(selectDealDomain, state => state.isLoading);

export const makeSelectIsSaving = (): DealSelector<boolean> =>
  createSelector(selectDealDomain, state => state.isSaving);

export const makeSelectFetchError = (): DealSelector<SerializedApiError | null> =>
  createSelector(selectDealDomain, state => state.fetchError);

export const makeSelectError = (): Selector<
  AppStateSubset & IdentityAppStateSubset,
  SerializedApiError | null
> =>
  createSelector(
    selectDealDomain,
    makeSelectIdentityError(),
    (state, abilitiesError) => state.error || abilitiesError,
  );

type DealAndClientSelector<T> = Selector<
  AppStateSubset & ClientsAppStateSubset,
  T
>;

export const makeSelectIsDirty = (): DealAndClientSelector<boolean> =>
  createSelector(
    selectDealDomain,
    makeSelectAreClientsDirty(),
    (state, areClientsDirty) => state.isDirty || areClientsDirty,
  );
