import { defineMessages } from 'react-intl';

export default defineMessages({
  semelle: { id: 'app.components.ConcessionSelection.Semelle.semelle' },
  dimensions: { id: 'app.components.ConcessionSelection.Semelle.dimensions' },
  dimensionsPlaceholder: {
    id: 'app.components.ConcessionSelection.Semelle.dimensionsPlaceholder',
  },
  placeholder: { id: 'app.components.ConcessionSelection.Semelle.placeholder' },
  slope: { id: 'app.components.ConcessionSelection.Semelle.slope' },
  leftHead: { id: 'app.components.ConcessionSelection.Semelle.leftHead' },
  rightHead: { id: 'app.components.ConcessionSelection.Semelle.rightHead' },
  leftFoot: { id: 'app.components.ConcessionSelection.Semelle.leftFoot' },
  rightFoot: { id: 'app.components.ConcessionSelection.Semelle.rightFoot' },
});
