import { throttle } from 'lodash';
import { useEffect, useState } from 'react';
import { useInterval } from './useInterval';

const activityEvents = [
  'mousedown',
  'mousemove',
  'keydown',
  'scroll',
  'touchstart',
];

export function useIsUserActive(maxInactivitySeconds = 60): boolean {
  const [secondsSinceLastActivity, setSecondsSinceLastActivity] = useState(0);
  const inactivity = (): void =>
    setSecondsSinceLastActivity(secondsSinceLastActivity + 1);
  const activity = throttle((): void => setSecondsSinceLastActivity(0), 1000);

  useInterval(inactivity, 1000);

  useEffect(() => {
    activityEvents.forEach(eventName => {
      document.addEventListener(eventName, activity);
    });
    return (): void => {
      activityEvents.forEach(eventName => {
        document.removeEventListener(eventName, activity);
      });
    };
  }, []);

  return secondsSinceLastActivity < maxInactivitySeconds;
}
