import type { FormApi } from 'final-form';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { FullClientJSON } from '@advitam/api/models/Client/Full';
import { assert } from 'lib/support';

import type { FuneralForm } from '../../../Funeral/types';
import {
  CLIENT_FORM_KEYS,
  DEAL_IDENTITY_CLIENT_AUTOCOMPLETION_MODAL,
} from './constants';

interface AutocompleteClientPayload {
  index: number;
  form: FormApi<FuneralForm>;
}

export const autocompleteClient = createAsyncThunk(
  `${DEAL_IDENTITY_CLIENT_AUTOCOMPLETION_MODAL}/AUTOCOMPLETE_CLIENT`,
  async ({ index, form }: AutocompleteClientPayload, { rejectWithValue }) => {
    const { values } = form.getState();
    const { client } = values.abilities[index];
    assert(client.id !== undefined);

    let existingClient: FullClientJSON;
    try {
      const response = await request(Api.V1.Clients.show(client.id));
      existingClient = response.body;
    } catch (error) {
      return rejectWithValue(error);
    }

    const hasChange = CLIENT_FORM_KEYS.some(
      key => Boolean(client[key]) && client[key] !== existingClient[key],
    );

    if (hasChange) {
      return existingClient;
    }

    form.batch(() => {
      const retypedForm = (form as unknown) as FormApi<Record<string, unknown>>;
      CLIENT_FORM_KEYS.forEach(key => {
        retypedForm.change(
          `abilities[${index}].client.${key}`,
          existingClient[key],
        );
      });
    });
    return null;
  },
);
