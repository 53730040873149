import { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RepatriationType } from '@advitam/api/models/Repatriation/Type';
import { SelectList } from 'components/Input/index.js';

import messages from './messages';
import style from './Repatriation.module.scss';

interface TypeSelectProps {
  value: RepatriationType;
  onChange: (value: RepatriationType) => void;
}

export default function TypeSelect({
  value,
  onChange,
}: TypeSelectProps): JSX.Element {
  const intl = useIntl();
  return (
    <>
      <FormattedMessage id={messages.repatriationType.id} />
      <SelectList
        className={style.select}
        name="repatriationType"
        emptyOption={false}
        value={value}
        options={[
          {
            value: RepatriationType.PLANE,
            name: intl.formatMessage(messages.plane),
          },
          {
            value: RepatriationType.CAR,
            name: intl.formatMessage(messages.car),
          },
        ]}
        onChange={({
          target: { value: updateValue },
        }: ChangeEvent<HTMLInputElement>): void => {
          onChange(updateValue as RepatriationType);
        }}
      />
    </>
  );
}
