import { ContactMedia } from '../Entity/ContactMedia'

export const enum FundingAgencyType {
  BANK = 'bank',
  HEALTH_INSURANCE = 'health_insurance',
  LIFE_INSURANCE = 'life_insurance',
}

export interface FundingAgencyJSON {
  id: number
  name: string
  agency_type: FundingAgencyType
  phone: string
  email: string
  prefered_contact_media: ContactMedia
  image_link: string
  thumbnail_link: string
}

export type { MinimalFundingAgencyJSON } from './Minimal'
