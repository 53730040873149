import { useState } from 'react'
import { useIntl, IntlShape } from 'react-intl'

import { useInterval } from '@advitam/react'

import messages from './messages'

const CHARACTER_DURATION = 100
const MESSAGE_DURATION = 2000

function selectMessage(intl: IntlShape): string {
  const waitingMessages = [
    intl.formatMessage(messages.wait1),
    intl.formatMessage(messages.wait2),
    intl.formatMessage(messages.wait3),
    intl.formatMessage(messages.wait4),
    intl.formatMessage(messages.wait5),
    intl.formatMessage(messages.wait6),
    intl.formatMessage(messages.wait7),
    intl.formatMessage(messages.wait8),
    intl.formatMessage(messages.wait9),
    intl.formatMessage(messages.wait10),
    intl.formatMessage(messages.wait11),
    intl.formatMessage(messages.wait12),
    intl.formatMessage(messages.wait13),
    intl.formatMessage(messages.wait14),
    intl.formatMessage(messages.wait15),
    intl.formatMessage(messages.wait16),
    intl.formatMessage(messages.wait17),
    intl.formatMessage(messages.wait18),
  ]

  const index = Math.floor(Math.random() * waitingMessages.length)
  return waitingMessages[index]
}

export default function LoadingMessage(): JSX.Element {
  const intl = useIntl()

  const [currentMessage, setCurrentMessage] = useState(selectMessage(intl))
  const [currentChar, setCurrentChar] = useState(0)
  const [messageDisplayedFor, setMessageDisplayedFor] = useState(0)

  useInterval(
    () => {
      if (currentChar < currentMessage.length) {
        let offset = 1
        while (currentMessage[currentChar + offset] === ' ') {
          offset += 1
        }
        setCurrentChar(currentChar + offset)
      } else if (messageDisplayedFor < MESSAGE_DURATION) {
        setMessageDisplayedFor(messageDisplayedFor + CHARACTER_DURATION)
      } else {
        setCurrentMessage(selectMessage(intl))
        setCurrentChar(1)
        setMessageDisplayedFor(0)
      }
    },
    CHARACTER_DURATION,
    [currentChar, currentMessage, messageDisplayedFor, intl],
  )

  return <>{currentMessage.slice(0, currentChar + 1)}</>
}
