import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import {
  FlightIndex,
  FlightIndexJSON,
} from '@advitam/api/models/Flight/FlightIndex';

import { State, AppStateSubset } from './slice';
import { FLIGHTS } from './constants';
import { FlightsFilters } from './types';

type FlightsSelector<T> = Selector<AppStateSubset, T>;

export const selectFlightsDomain = (state: AppStateSubset): State =>
  state[FLIGHTS];

export const makeSelectFilters = (): FlightsSelector<FlightsFilters> =>
  createSelector(selectFlightsDomain, ({ filters }) => filters);

const makeSelectRawFlights = (): FlightsSelector<FlightIndexJSON[]> =>
  createSelector(selectFlightsDomain, ({ flights }) => flights);

export const makeSelectFlights = (): FlightsSelector<FlightIndex[]> =>
  createSelector(makeSelectRawFlights(), rawFlights =>
    rawFlights.map(flight => new FlightIndex(flight)),
  );

export const makeSelectCurrentPage = (): FlightsSelector<number> =>
  createSelector(selectFlightsDomain, ({ currentPage }) => currentPage);

export const makeSelectHasMore = (): FlightsSelector<boolean> =>
  createSelector(selectFlightsDomain, ({ hasMore }) => hasMore);

export const makeSelectIsLoading = (): FlightsSelector<boolean> =>
  createSelector(selectFlightsDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): FlightsSelector<SerializedApiError | null> =>
  createSelector(selectFlightsDomain, ({ error }) => error);
