import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@advitam/ui';

import { Checkout, Tax } from './types';
import messages from './messages';
import Details from './Details';
import style from './CheckoutInfos.module.scss';

interface CheckoutInfosProps {
  checkout: Checkout;
  taxes: Tax[];
}

export default function CheckoutInfos({
  checkout,
  taxes,
}: CheckoutInfosProps): JSX.Element {
  return (
    <div>
      {checkout.type && taxes.length > 0 && (
        <div className={style.checkout}>
          <FormattedMessage
            id={messages.checkoutType.id}
            values={{ type: checkout.type }}
            tagName="span"
          />
          <Details checkout={checkout} />
        </div>
      )}
      {taxes.map(tax => (
        <div key={tax.description}>
          {tax.description} {tax.price && <FormattedPrice value={tax.price} />}
        </div>
      ))}
    </div>
  );
}
