import { defineMessages } from 'react-intl';

export default defineMessages({
  buried: { id: 'app.components.ConcessionSelection.Buried.buried' },
  level: { id: 'app.components.ConcessionSelection.Buried.level' },
  unassigned: { id: 'app.components.ConcessionSelection.Buried.unassigned' },
  container: { id: 'app.components.ConcessionSelection.Buried.container' },
  coffin: { id: 'app.components.ConcessionSelection.Buried.coffin' },
  urn: { id: 'app.components.ConcessionSelection.Buried.urn' },
  intermentDate: {
    id: 'app.components.ConcessionSelection.Buried.intermentDate',
  },
  addPlace: { id: 'app.components.ConcessionSelection.Buried.addPlace' },
  removeAPlace: {
    id: 'app.components.ConcessionSelection.Buried.removeAPlace',
  },
  bonesBox: { id: 'app.components.ConcessionSelection.Buried.bonesBox' },
});
