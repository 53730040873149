import { ReactNode, useCallback } from 'react';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import type { AirlineJSON } from '@advitam/api/models/Airline';
import { ApiAutocomplete } from '@advitam/ui';
import type { AutocompleteResult } from '@advitam/ui/components/Form/UI/Autosuggest/types';

interface AirlineAutocompleteProps {
  name: string;
  label: ReactNode;
  disabled?: boolean;
  expandOnTop?: boolean;
}

export default function AirlineAutocomplete({
  name,
  label,
  disabled,
  expandOnTop,
}: AirlineAutocompleteProps): JSX.Element {
  const getAirlineDisplayValue = useCallback(
    (item: AirlineJSON): string => `${item.name} (${item.iata})`,
    [],
  );

  return (
    <ApiAutocomplete<AirlineJSON & AutocompleteResult>
      name={name}
      label={label}
      getDisplayValue={getAirlineDisplayValue}
      required
      disabled={disabled}
      expandOnTop={expandOnTop}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.airlines)}
      keys={['name', 'iata']}
      requestHeaders={{ ...getTokens() }}
    />
  );
}
