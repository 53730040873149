import { authenticate } from '../../../lib/decorators'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { post } from '../../../lib/methods'
import type { ClientJSON } from '../../../models/Client'

import { DeathDeclarations } from './DeathDeclarations'

export class Deceased {
  static readonly DeathDeclarations = DeathDeclarations

  @authenticate
  static create(clientId: number): ApiRequestDescriptor<ClientJSON> {
    return post(`/api/v1/clients/${clientId}/deceased`)
  }
}
