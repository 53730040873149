import { ApiError, SerializedApiError } from '@advitam/api';
import {
  SupplierWarehouseJSON,
  SupplierWarehouseZoneJSON,
} from '@advitam/api/models/Supplier/Warehouse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assert } from 'lib/support';
import { SUPPLIER_WAREHOUSE } from './constants';
import { saveProducts } from './sections/Products/thunk';
import { saveFuneralWorks } from './sections/Zone/thunk';
import { saveZones } from './sections/Zones/thunk';
import {
  createWarehouse,
  destroyWarehouse,
  fetchWarehouse,
  setIsWarehouseDisabled,
  updateWarehouse,
  updateWarehouseName,
} from './thunk';
import { NewSupplierWarehouse } from './types';

export interface State {
  warehouse: SupplierWarehouseJSON | NewSupplierWarehouse | null;
  zones: SupplierWarehouseZoneJSON[];
  isLoading: boolean;
  isSaving: boolean;
  isUpdatingName: boolean;
  isDestroying: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [SUPPLIER_WAREHOUSE]: State;
}

export const initialState: State = {
  warehouse: null,
  zones: [],
  isLoading: false,
  isSaving: false,
  isUpdatingName: false,
  isDestroying: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: SUPPLIER_WAREHOUSE,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearError(state) {
      state.error = null;
    },
    setWarehouse: (
      state,
      { payload }: PayloadAction<SupplierWarehouseJSON | NewSupplierWarehouse>,
    ) => {
      state.warehouse = payload;
    },
    setZones: (
      state,
      { payload }: PayloadAction<SupplierWarehouseZoneJSON[]>,
    ) => {
      state.zones = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchWarehouse.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.warehouse = null;
      state.zones = [];
    });
    builder.addCase(fetchWarehouse.fulfilled, (state, { payload }) => {
      state.warehouse = payload.warehouse;
      state.zones = payload.zones;
      state.isLoading = false;
    });
    builder.addCase(fetchWarehouse.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createWarehouse.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(createWarehouse.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(createWarehouse.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateWarehouse.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateWarehouse.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.warehouse = payload;
    });
    builder.addCase(updateWarehouse.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateWarehouseName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updateWarehouseName.fulfilled, state => {
      state.isUpdatingName = false;
    });
    builder.addCase(updateWarehouseName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyWarehouse.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyWarehouse.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroyWarehouse.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setIsWarehouseDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setIsWarehouseDisabled.fulfilled, (state, { meta }) => {
      assert(state.warehouse !== null);
      state.isSaving = false;
      state.warehouse.disabled = meta.arg;
    });
    builder.addCase(setIsWarehouseDisabled.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveZones.fulfilled, (state, { payload }) => {
      if (payload) {
        state.warehouse = payload.warehouse;
      }
    });
    builder.addCase(saveFuneralWorks.fulfilled, (state, { payload }) => {
      if (payload) {
        state.warehouse = payload.warehouse;
      }
    });
    builder.addCase(saveProducts.fulfilled, (state, { payload }) => {
      if (payload) {
        state.warehouse = payload.warehouse;
      }
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setWarehouse, setZones } = slice.actions;

export default slice;
