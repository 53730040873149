import { FormattedMessage } from 'react-intl';

import { ResourceList } from '@advitam/ui';

import messages from './messages';

export default function Header(): JSX.Element {
  return (
    <ResourceList.Header>
      <ResourceList.Cell>
        <FormattedMessage id={messages.name.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.email.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.phone.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.address.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
