import { ReactElement, ReactNode } from 'react'
import Link from '../../Link'
import FieldCardContent from './Content'
import style from './Field.module.scss'

interface FieldCardLinkProps {
  href: string
  icon: ReactElement
  text: ReactNode
  className?: string
  internal?: boolean
  newTab?: boolean
}

export default function FieldCardLink({
  href,
  icon,
  text,
  className,
  internal,
  newTab,
}: FieldCardLinkProps): JSX.Element {
  return (
    <Link
      href={href}
      internal={internal}
      newTab={newTab}
      className={[style.card, className].join(' ')}
    >
      <FieldCardContent icon={icon} text={text} />
    </Link>
  )
}
