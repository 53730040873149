import PropTypes from 'prop-types';

/**
 * Display a document which must be upload by user
 */
const DocumentToUpload = ({ name }) => (
  <div className="documentToUpload">
    <div className="documentToUpload__icon">+</div>
    <div className="documentToUpload__banner">
      <div>{name}</div>
    </div>
  </div>
);

DocumentToUpload.propTypes = {
  /** name of the doc to upload */
  name: PropTypes.string.isRequired,
};

export default DocumentToUpload;
