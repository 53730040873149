import { FormattedMessage } from 'react-intl';

import { ButtonV2 as Button } from 'components/Button/index.js';

import { TRIP_FLIGHT_SKELETON } from '../../constants';
import messages from '../../messages';
import { RepatriationTrip, RepatriationTripFlight } from '../../types';
import TripFlight from './Flight';

interface TripFlightsProps {
  flights: RepatriationTripFlight[];
  onTripChange: (value: Partial<RepatriationTrip>) => void;
}

export default function TripFlights({
  flights,
  onTripChange,
}: TripFlightsProps): JSX.Element {
  const deleteFlight = (index: number): void => {
    const newFlights = [...flights];
    newFlights.splice(index, 1);
    onTripChange({ flights: newFlights });
  };

  const addFlight = (): void => {
    onTripChange({ flights: [...flights, TRIP_FLIGHT_SKELETON] });
  };

  const updateFlight = (
    update: RepatriationTripFlight,
    index: number,
  ): void => {
    onTripChange({
      flights: flights.map((flight, idx) => {
        if (index === idx) {
          return update;
        }
        return flight;
      }),
    });
  };

  return (
    <>
      <div className="trip__wrapper__flights">
        {flights.map((flight, index) => (
          <TripFlight
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            position={index + 1}
            flight={flight}
            onDelete={(): void => deleteFlight(index)}
            onUpdate={(update: RepatriationTripFlight): void =>
              updateFlight(update, index)
            }
          />
        ))}
      </div>
      <Button onClick={addFlight}>
        <FormattedMessage id={messages.addFlight.id} />
      </Button>
    </>
  );
}
