import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Api from '@advitam/api';
import type { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import { DocumentTypeTag } from '@advitam/api/models/DocumentType';
import Trash from '@advitam/ui/images/icons/trash.svg';
import {
  Button,
  DateOnlyInput,
  DocumentTypeAutocomplete,
  Input,
  ResourceList,
} from '@advitam/ui';

import { makeSelectUser } from 'slices/auth';

import messages from '../messages';
import type { UnsavedSupportingDocument } from '../types';
import FileButton from './FileButton';
import style from '../SupportingDocuments.module.scss';

const RESTRICTED_SEARCH_PARAMS = {
  tags_contains: [DocumentTypeTag.GENERIC],
};

interface RowProps {
  document: SupportingDocumentJSON | UnsavedSupportingDocument;
  prefix: string;
  tokens: Record<string, string>;
  onDelete: () => void;
  isMobile: boolean;
}

export default function Row({
  document,
  prefix,
  tokens,
  onDelete,
  isMobile,
}: RowProps): JSX.Element {
  const intl = useIntl();
  const user = useSelector(makeSelectUser());

  const canEditDocument =
    user?.isFuneralDirectorOrAbove ||
    document.document_type?.tags.includes(DocumentTypeTag.GENERIC);

  return (
    <ResourceList.Row className={style.row}>
      <ResourceList.Cell className={style.name_cell}>
        {document.id ? (
          document.document_type.pretty_name
        ) : (
          <DocumentTypeAutocomplete
            required
            name={`${prefix}.document_type`}
            endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.documentTypes)}
            tokens={tokens}
            className={style.autocomplete}
            placeholder={intl.formatMessage(messages.documentTypePlaceholder)}
            searchParams={
              !user?.isFuneralDirectorOrAbove
                ? RESTRICTED_SEARCH_PARAMS
                : undefined
            }
          />
        )}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.date_cell}>
        <DateOnlyInput
          name={`${prefix}.uploaded_document.issue_date`}
          disabled={!canEditDocument}
          className={style.input}
        />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.date_cell}>
        <DateOnlyInput
          name={`${prefix}.uploaded_document.expiry_date`}
          readOnly={isMobile}
          disabled={!canEditDocument}
          className={style.input}
        />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.ref_cell}>
        <Input
          name={`${prefix}.uploaded_document.reference`}
          disabled={!canEditDocument}
          className={style.input}
          placeholder={intl.formatMessage(messages.referencePlaceholder)}
        />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.actions}>
        <FileButton document={document} prefix={prefix} />
        <Button
          outline
          disabled={!canEditDocument}
          onClick={onDelete}
          icon={<Trash />}
        />
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
