import { DragEvent, Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormUI, Text } from '@advitam/ui';

import messages from '../messages';
import { setNewInput } from '../slice';
import {
  clearDragData,
  InputDragActionType,
  onInputDragStart,
} from '../Engine/utils';
import { getInputs, getVariableInput, getVariables } from './utils';
import sectionMessages from '../sectionMessages';
import style from '../DocumentTemplateEditor.module.scss';

interface InputsProps {
  isIntegrationMode?: boolean;
  variables?: string[];
}

export default function Inputs({
  isIntegrationMode = false,
  variables = [],
}: InputsProps): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [filter, setFilter] = useState('');

  const sortedVariables = useMemo(() => getVariables(variables, filter, intl), [
    variables,
    filter,
    intl,
  ]);

  return (
    <>
      <FormUI.Input
        value={filter}
        onChange={({ target: { value } }): void => setFilter(value)}
        label={<FormattedMessage id={messages.inputSearchLabel.id} />}
        tooltip={<FormattedMessage id={messages.inputSearchTooltip.id} />}
        placeholder={intl.formatMessage(messages.inputSearchPlaceholder)}
      />

      <Text small className={style.input_section_title}>
        <b>
          <FormattedMessage id={sectionMessages.various.id} />
        </b>
      </Text>
      {Object.entries(getInputs(isIntegrationMode)).map(([label, props]) => (
        <Text
          key={label}
          small
          tagName="div"
          draggable
          onDragStart={(ev: DragEvent<HTMLDivElement>): void => {
            onInputDragStart(
              ev,
              { page: -1, input: -1 },
              { type: InputDragActionType.MOVE },
            );
            dispatch(
              setNewInput({
                type: label,
                position: [ev.clientX, ev.clientY],
                value: undefined,
                ...props,
              }),
            );
          }}
          onDragEnd={(): void => clearDragData()}
        >
          <FormattedMessage id={messages[label as keyof typeof messages].id} />
        </Text>
      ))}

      {sortedVariables.map(section => (
        <Fragment key={section.key}>
          <Text small className={style.input_section_title}>
            <b>{section.name}</b>
          </Text>
          {section.variables.map(variable => (
            <Text
              key={variable.key}
              small
              tagName="div"
              draggable
              onDragStart={(ev: DragEvent<HTMLDivElement>): void => {
                onInputDragStart(
                  ev,
                  { page: -1, input: -1 },
                  { type: InputDragActionType.MOVE },
                );
                dispatch(
                  setNewInput(
                    getVariableInput(`${section.key}__${variable.key}`, [
                      ev.clientX,
                      ev.clientY,
                    ]),
                  ),
                );
              }}
              onDragEnd={(): void => clearDragData()}
            >
              {variable.name}
            </Text>
          ))}
        </Fragment>
      ))}
    </>
  );
}
