import { ClientRole } from '@advitam/api/models/Client/Role';
import { Country } from '../Country';
import {
  Person,
  LegacyPersonFields,
  LegacyPerson,
  PersonFields,
} from '../Person';
import { Company } from '../Company';

export interface ClientJSON extends LegacyPersonFields {
  id?: number;
  ability_id?: number;
  role?: ClientRole;
  special?: boolean;
  email?: string;
  birthLocation?: string | null;
  nationality?: string | null;
  has_an_account?: boolean;
  defunct_id?: number | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Client extends ClientJSON {}

export class Client extends LegacyPerson<ClientJSON> {}

export interface ClientSummary {
  id: number | null;
  civility: Person['civility'] | null;
  firstname: string | null;
  lastname: string | null;
  email: string | null;
  phone: string | null;
  address: string | null;
  postal_code: string | null;
  city: string | null;
  country: Country | null;
  latitude: number | null;
  longitude: number | null;
  defunct_id: number | null;
}

export interface StandardClient extends PersonFields {
  id?: number;
  email?: string | null;
  phone_2?: string | null;
  birth_date?: string | null;
  inserted_at?: string;
  updated_at?: string;
  companies?: Company[];
  address_l2?: string | null;
  insee_code?: string | null;
  department?: string | null;
  defunct_id?: number | null;
}

export { ClientRole };
