import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NumberInput } from 'components/Input';
import DialogBox from 'containers/DialogBox';
import { ButtonV2 as Button } from 'components/Button';
import ServicesDialog from './ServicesDialog';
import messages from './messages';
import style from './OtherFuneralsServices.module.scss';

class OtherFuneralServices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { dialogIsOpen: false, tvaIndice: 0 };
  }

  setModalAndDisplay(tvaIndice) {
    this.setState({
      tvaIndice,
      dialogIsOpen: true,
    });
  }

  toggleState = prop => () =>
    this.setState(prevSate => ({ [prop]: !prevSate[prop] }));

  renderTvaService(tvaIndice) {
    const { selectTvaPrice, updateTvaTotalPrice } = this.props;
    return (
      <div>
        <span>Tva : {tvaIndice * 10}%</span>
        <NumberInput
          onChange={({ target: { value } }) =>
            updateTvaTotalPrice(tvaIndice, value)
          }
          name={`price${tvaIndice}`}
          value={selectTvaPrice(tvaIndice) || ''}
        />
        <Button onClick={() => this.setModalAndDisplay(tvaIndice)}>
          <FormattedMessage {...messages.tvaService} />
        </Button>
      </div>
    );
  }

  render() {
    const { selectTvaService, addService, removeService } = this.props;
    const { tvaIndice, dialogIsOpen } = this.state;

    return (
      <div className={style.container}>
        {this.renderTvaService(0)}
        {this.renderTvaService(1)}
        {this.renderTvaService(2)}
        {dialogIsOpen && (
          <DialogBox
            headerMessage={{
              ...messages.addServiceTva,
              values: { tvaIndice: tvaIndice * 10 },
            }}
            onRequestClose={this.toggleState('dialogIsOpen')}
          >
            <ServicesDialog
              services={selectTvaService(tvaIndice)}
              onSelect={addService(tvaIndice)}
              deleteItem={removeService(tvaIndice)}
            />
          </DialogBox>
        )}
      </div>
    );
  }
}

OtherFuneralServices.propTypes = {
  selectTvaService: PropTypes.func.isRequired,
  selectTvaPrice: PropTypes.func.isRequired,
  addService: PropTypes.func.isRequired,
  updateTvaTotalPrice: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
};

export default OtherFuneralServices;
