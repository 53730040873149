import { assert } from '../assert'

export function findExtreme<T, U>(
  array: readonly T[],
  iteratee: (value: T) => U,
  shouldReplace: (candidate: U, current: U) => boolean,
): T {
  let current: T | undefined
  let currentCriterion: U

  array.forEach(candidate => {
    const candidateCriterion = iteratee(candidate)
    if (current === undefined || shouldReplace(candidateCriterion, currentCriterion)) {
      current = candidate
      currentCriterion = candidateCriterion
    }
  })

  assert(current !== undefined)
  return current
}
