import { createAsyncThunk } from '@reduxjs/toolkit';

import { patchMemorial } from '../thunk';
import { MEMORIAL_SPACE_DONATION } from './constants';

export const toggleDonation = createAsyncThunk(
  `${MEMORIAL_SPACE_DONATION}/TOGGLE_DONATION`,
  (value: boolean, { dispatch }) =>
    dispatch(patchMemorial({ associations_enabled: value })),
);

export const updateUrl = createAsyncThunk(
  `${MEMORIAL_SPACE_DONATION}/UPDATE_URL`,
  (url: string | undefined, { dispatch }) =>
    dispatch(patchMemorial({ association_url: url })),
);
