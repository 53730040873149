import {
  GET_BOOKING_INFO,
  SET_BOOKING_INFO,
  BOOK_SUPPLIERS,
  SUCCESS_BOOK_SUPPLIERS,
  PRE_BOOK_SUPPLIERS,
  SUCCESS_PRE_BOOK_SUPPLIERS,
  UPDATE_SUPPLIER_BOOKING_STATUS,
  SUCCESS_UPDATE_SUPPLIER_BOOKING_STATUS,
  ERROR,
  UPDATE_BOOKING_COMMENT,
  SUCCESS_UPDATE_BOOKING_COMMENT,
  SYNC_BOOKING_COMMENT,
  UPDATE_SUPPLIER_SELECTION,
  PRE_CASKETING_BOOKING,
} from './constants';

export function getBookingInfo(dealUUID) {
  return {
    type: GET_BOOKING_INFO,
    dealUUID,
  };
}

export function setBookingInfo(bookingInfo) {
  return {
    type: SET_BOOKING_INFO,
    bookingInfo,
  };
}

export function bookSuppliers(dealUUID) {
  return {
    type: BOOK_SUPPLIERS,
    dealUUID,
  };
}

export function successBookSuppliers() {
  return {
    type: SUCCESS_BOOK_SUPPLIERS,
  };
}

export function preBookSuppliers(dealUUID) {
  return {
    type: PRE_BOOK_SUPPLIERS,
    dealUUID,
  };
}

export function successPreBookSuppliers() {
  return {
    type: SUCCESS_PRE_BOOK_SUPPLIERS,
  };
}

export function updateSupplierBookingStatus(
  supplierID,
  bookingStatus,
  dealUUID,
) {
  return {
    type: UPDATE_SUPPLIER_BOOKING_STATUS,
    supplierID,
    bookingStatus,
    dealUUID,
  };
}

export function successUpdateSupplierBookingStatus(
  supplierID,
  bookingStatus,
  dealUUID,
) {
  return {
    type: SUCCESS_UPDATE_SUPPLIER_BOOKING_STATUS,
    supplierID,
    bookingStatus,
    dealUUID,
  };
}

export function error(err) {
  return {
    type: ERROR,
    err,
  };
}

export function updateSupplierBookingComment(supplierID, comment, dealUUID) {
  return {
    type: UPDATE_BOOKING_COMMENT,
    comment,
    supplierID,
    dealUUID,
  };
}

export function setBookingComment(supplierID, comment) {
  return {
    type: SUCCESS_UPDATE_BOOKING_COMMENT,
    comment,
    supplierID,
  };
}

export function syncBookingComment(supplierID, comment) {
  return {
    type: SYNC_BOOKING_COMMENT,
    comment,
    supplierID,
  };
}

export function updateSupplierSelection(solutions, dealUUID) {
  return {
    type: UPDATE_SUPPLIER_SELECTION,
    solutions,
    dealUUID,
  };
}

export function preCasketingBooking(dealUUID) {
  return {
    type: PRE_CASKETING_BOOKING,
    dealUUID,
  };
}
