import { createSelector, Selector } from 'reselect';
import { SerializedApiError } from '@advitam/api';
import { LOGIN } from './constants';
import type { AppStateSubset, State } from './slice';

interface AuthenticationErrorBody {
  success: false;
  errors: string[];
}

type LoginSelector<T> = Selector<AppStateSubset, T>;

const selectLoginDomain = (state: AppStateSubset): State => state[LOGIN];

export const makeSelectIsLoading = (): LoginSelector<boolean> =>
  createSelector(selectLoginDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): LoginSelector<SerializedApiError | null> =>
  createSelector(selectLoginDomain, ({ error }) => error);

export const makeSelectErrorMessage = (): LoginSelector<string | null> =>
  createSelector(makeSelectError(), error =>
    error?.status === 401
      ? (error.body as AuthenticationErrorBody).errors[0]
      : null,
  );
