import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { SupportingDocumentOwnerType } from '@advitam/api/models/SupportingDocuments/OwnerType';
import { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import { assert } from '@advitam/support';
import {
  SupportingDocuments,
  supportingDocumentsSlice,
  makeSelectSupportingDocuments,
  saveSupportingDocuments,
} from 'containers/Crud';
import Layout from 'containers/Crud/Layout';

import { makeSelectRawGraveyard } from '../selectors';
import Wrapper from './Wrapper';
import { GraveyardForm } from './types';

function SupportingDocumentsSection(): JSX.Element {
  const dispatch = useDispatch();

  const documents = useSelector(makeSelectSupportingDocuments());
  const graveyard = useSelector(makeSelectRawGraveyard());
  assert(graveyard?.id !== undefined);

  const owner = useMemo(
    () => ({
      type: SupportingDocumentOwnerType.GRAVEYARD,
      id: graveyard.id,
    }),
    [graveyard.id],
  );

  const onSubmit = useCallback(
    ({ sectionValues }: GraveyardForm<SupportingDocumentJSON[]>) => {
      dispatch(saveSupportingDocuments({ values: sectionValues, owner }));
    },
    [dispatch, owner],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={documents}>
      <Layout.Container>
        <SupportingDocuments owner={owner} />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(supportingDocumentsSlice)(SupportingDocumentsSection);
