import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  CeremonyJSON,
  CeremonyType,
  CoffinWoodType,
} from '@advitam/api/models/Crematorium/Ceremony';
import { Button, FormLayout, Input, NumberInput, Select } from '@advitam/ui';
import { Layout } from 'containers/Crud';
import crudMessages from 'containers/Crud/messages';
import actionMessages from 'messages/actions';

import { makeSelectAgeRanges, makeSelectRooms } from '../selectors';
import messages from '../messages';
import style from '../Ceremonies.module.scss';

interface CeremonyProps {
  ceremony: CeremonyJSON;
  prefix: string;
  onDelete: () => void;
  onDuplicate: () => void;
}

export default function Ceremony({
  ceremony,
  onDelete,
  onDuplicate,
  prefix,
}: CeremonyProps): JSX.Element {
  const intl = useIntl();

  const ageRanges = useSelector(makeSelectAgeRanges());
  const rooms = useSelector(makeSelectRooms());

  return (
    <Layout.Fieldset
      title={
        ceremony.id ? (
          ceremony.title
        ) : (
          <FormattedMessage id={messages.newCeremony.id} />
        )
      }
    >
      <FormLayout.Row>
        <Input
          required
          label={<FormattedMessage id={messages.title.id} />}
          placeholder={intl.formatMessage(messages.titlePlaceholder)}
          name={`${prefix}.title`}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.default.id} />}
          tooltip={<FormattedMessage id={messages.defaultTooltip.id} />}
          name={`${prefix}.default`}
          items={[
            {
              name: intl.formatMessage(actionMessages.yes),
              value: true,
            },
            {
              name: intl.formatMessage(actionMessages.no),
              value: false,
            },
          ]}
        />
        <Select
          required
          label={<FormattedMessage id={messages.ceremonyType.id} />}
          name={`${prefix}.ceremony_type`}
          items={[
            {
              name: intl.formatMessage(messages.ceremonyTypeStandard),
              value: CeremonyType.STANDARD,
            },
            {
              name: intl.formatMessage(messages.ceremonyTypePremium),
              value: CeremonyType.PREMIUM,
            },
            {
              name: intl.formatMessage(messages.ceremonyTypeNoFamily),
              value: CeremonyType.NO_FAMILY,
            },
          ]}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          label={<FormattedMessage id={messages.price.id} />}
          step={0.01}
          name={`${prefix}.price`}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
        <NumberInput
          label={<FormattedMessage id={messages.reducedPrice.id} />}
          tooltip={<FormattedMessage id={messages.reducedPriceTooltip.id} />}
          step={0.01}
          name={`${prefix}.reduced_price`}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.ageRange.id} />}
          name={`${prefix}.age_range_id`}
          items={ageRanges.map(ageRange => ({
            value: ageRange.id,
            name: ageRange.title,
          }))}
        />
        <Select
          unselectable
          label={<FormattedMessage id={messages.room.id} />}
          name={`${prefix}.room_id`}
          items={rooms.map(room => ({
            value: room.id,
            name: room.name,
          }))}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.isLive.id} />}
          tooltip={<FormattedMessage id={messages.isLiveTooltip.id} />}
          name={`${prefix}.is_live`}
          items={[
            {
              name: intl.formatMessage(actionMessages.yes),
              value: true,
            },
            {
              name: intl.formatMessage(actionMessages.no),
              value: false,
            },
          ]}
        />
        <Select
          label={<FormattedMessage id={messages.withCeremonyMaster.id} />}
          tooltip={
            <FormattedMessage id={messages.withCeremonyMasterTooltip.id} />
          }
          name={`${prefix}.with_ceremony_master`}
          items={[
            {
              name: intl.formatMessage(actionMessages.yes),
              value: true,
            },
            {
              name: intl.formatMessage(actionMessages.no),
              value: false,
            },
          ]}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.coffinWoodType.id} />}
          tooltip={<FormattedMessage id={messages.coffinWoodTypeTooltip.id} />}
          name={`${prefix}.coffin_wood_type`}
          items={[
            {
              name: intl.formatMessage(messages.coffinWoodAny),
              value: CoffinWoodType.ANY,
            },
            {
              name: intl.formatMessage(messages.coffinWoodSoft),
              value: CoffinWoodType.SOFT,
            },
            {
              name: intl.formatMessage(messages.coffinWoodHard),
              value: CoffinWoodType.HARD,
            },
            {
              name: intl.formatMessage(messages.coffinWoodCardboard),
              value: CoffinWoodType.CARTON,
            },
          ]}
        />
        <NumberInput
          label={<FormattedMessage id={messages.duration.id} />}
          tooltip={<FormattedMessage id={messages.durationTooltip.id} />}
          name={`${prefix}.duration`}
          suffix={<FormattedMessage id={crudMessages.unitMinute.id} />}
        />
      </FormLayout.Row>

      <div className={style.actions}>
        <Button
          outline
          text={<FormattedMessage id={messages.duplicate.id} />}
          onClick={onDuplicate}
        />
        <Button
          outline
          text={<FormattedMessage id={messages.delete.id} />}
          onClick={onDelete}
        />
      </div>
    </Layout.Fieldset>
  );
}
