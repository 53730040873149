import { setDealFields } from 'containers/Deal/slice.ts';
import {
  FETCH_DEAL_DETAILS,
  SET_PARENT_DEAL,
  SET_PARENT,
} from './constants.ts';

/**
 * Get deal from DB
 *
 * @param {String} uuid uuid of deal item
 * @param {String} funeralUuid uuid of deal funeral
 * @returns {AnyAction}
 */
export function fetchDealDetails(deal) {
  return {
    type: FETCH_DEAL_DETAILS,
    deal,
  };
}

/**
 * Function to set field in store
 *
 * @param {string} key key of field to update
 * @param {any} value value to update
 * @returns {Object}
 */
export function setField(key, value) {
  return setDealFields({
    update: {
      item: {
        delivery: { [key]: value },
      },
    },
  });
}

export function setParentDeal(deal) {
  const id = deal && deal.id;

  return {
    type: SET_PARENT_DEAL,
    id,
  };
}

export function setParentInDeal(type, id = null) {
  return setDealFields({
    isTouched: false,
    update: {
      item: {
        parent_type: type,
        parent_id: id,
      },
    },
  });
}

export function setParent(parentType, parent = null) {
  return {
    type: SET_PARENT,
    parentType,
    parent,
  };
}
