import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { CroppedText, FlatButton, Spinner } from '@advitam/ui';
import CrossIcon from '@advitam/ui/images/icons/times.svg';

import { makeSelectIsSending } from '../selectors';
import style from './Autocompletes.module.scss';

interface AutocompletesItemProps {
  isLoading: boolean;
  children: ReactNode;
  actions?: ReactNode;
  onRemove: () => void;
}

export default function AutocompletesItem({
  isLoading,
  actions,
  children,
  onRemove,
}: AutocompletesItemProps): JSX.Element {
  const isSending = useSelector(makeSelectIsSending());

  return (
    <div className={style.item}>
      <CroppedText>{children}</CroppedText>
      <div className={style.item__actions}>
        {actions}
        {isLoading && <Spinner />}
        <FlatButton
          disabled={isLoading || isSending}
          className={style.item__remove}
          onClick={onRemove}
        >
          <CrossIcon />
        </FlatButton>
      </div>
    </div>
  );
}
