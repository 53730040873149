import { ReactElement, ReactNode } from 'react'
import FieldCardContent from './Content'
import style from './Field.module.scss'

interface FieldCardBaseProps {
  icon: ReactElement
  text: ReactNode
  className?: string
}

export default function FieldCardBase({ icon, text, className }: FieldCardBaseProps): JSX.Element {
  return (
    <div className={[style.card, className].join(' ')}>
      <FieldCardContent icon={icon} text={text} />
    </div>
  )
}
