import { ReactNode } from 'react'
import { Field } from 'react-final-form'

import InputUI from '../UI/Input'
import { NullableString } from '../converters'
import { ErrorMessages, getFormError } from '../errors'
import { composeValidators, isRequired, Validator } from '../validators'

interface InputProps {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  required?: boolean
  maxLength?: number
  hideErrorMessage?: boolean
  errorMessages?: ErrorMessages
  validate?: Validator<string | null>
  renderBelow?: (value: string) => ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
  className?: string
  type?: HTMLInputElement['type']
  tooltip?: ReactNode
}

export default function Input({
  name,
  label,
  placeholder,
  disabled,
  required,
  maxLength,
  hideErrorMessage,
  errorMessages,
  validate,
  renderBelow,
  prefix,
  suffix,
  className,
  type,
  tooltip,
}: InputProps): JSX.Element {
  return (
    <Field
      name={name}
      validate={composeValidators<string | null>(required && isRequired, validate)}
      parse={NullableString.parse}
      format={NullableString.format}
      formatOnBlur
    >
      {({ input, meta }): JSX.Element => (
        <InputUI
          type={type}
          name={input.name}
          label={label}
          onChange={input.onChange}
          onBlur={input.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          value={input.value}
          error={getFormError(meta, errorMessages, hideErrorMessage)}
          className={className}
          tooltip={tooltip}
          renderBelow={renderBelow}
          prefix={prefix}
          suffix={suffix}
        />
      )}
    </Field>
  )
}
