import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  prestationTypes: {
    id: 'app.containers.Supplier.sections.Miscellaneous.prestationTypes',
  },
  prestationTypesLabel: {
    id: 'app.containers.Supplier.sections.Miscellaneous.prestationTypesLabel',
  },
  prestationTypesTooltip: {
    id: 'app.containers.Supplier.sections.Miscellaneous.prestationTypesTooltip',
  },
  prestationTypesPlaceholder: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.prestationTypesPlaceholder',
  },

  dependencies: {
    id: 'app.containers.Supplier.sections.Miscellaneous.dependencies',
  },
  dependenciesTooltip: {
    id: 'app.containers.Supplier.sections.Miscellaneous.dependenciesTooltip',
  },
  wantedPrestation: {
    id: 'app.containers.Supplier.sections.Miscellaneous.wantedPrestation',
  },
  requiredPrestation: {
    id: 'app.containers.Supplier.sections.Miscellaneous.requiredPrestation',
  },
  functioning: {
    id: 'app.containers.Supplier.sections.Miscellaneous.functioning',
  },
  functioningTooltip: {
    id: 'app.containers.Supplier.sections.Miscellaneous.functioningTooltip',
  },
  generalCase: {
    id: 'app.containers.Supplier.sections.Miscellaneous.generalCase',
  },
  ifPrestationInDeal: {
    id: 'app.containers.Supplier.sections.Miscellaneous.ifPrestationInDeal',
  },
  addDependency: {
    id: 'app.containers.Supplier.sections.Miscellaneous.addDependency',
  },

  transportPrestationType: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.transportPrestationType',
  },
  marblePrestationType: {
    id: 'app.containers.Supplier.sections.Miscellaneous.marblePrestationType',
  },
  paperworksPrestationType: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.paperworksPrestationType',
  },
  coffinsPrestationType: {
    id: 'app.containers.Supplier.sections.Miscellaneous.coffinsPrestationType',
  },
  engravingPrestationType: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.engravingPrestationType',
  },
  embalmingPrestationType: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.embalmingPrestationType',
  },
  flightPrestationType: {
    id: 'app.containers.Supplier.sections.Miscellaneous.flightPrestationType',
  },
  flowersPrestationType: {
    id: 'app.containers.Supplier.sections.Miscellaneous.flowersPrestationType',
  },
  tombstonePrestationType: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.tombstonePrestationType',
  },
  pressPrestationType: {
    id: 'app.containers.Supplier.sections.Miscellaneous.pressPrestationType',
  },
  translationPrestationType: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.translationPrestationType',
  },
  ceremonyPrestationType: {
    id: 'app.containers.Supplier.sections.Miscellaneous.ceremonyPrestationType',
  },

  requiredDependencySentence: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.requiredDependencySentence',
  },
  optionalDependencySentence: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.optionalDependencySentence',
  },
  paperworkDependencyOption: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.paperworkDependencyOption',
  },
  paperworkDependencyText: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.paperworkDependencyText',
  },
  funeralDependencyOption: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.funeralDependencyOption',
  },
  funeralDependencyText: {
    id: 'app.containers.Supplier.sections.Miscellaneous.funeralDependencyText',
  },
  tacDependencyOption: {
    id: 'app.containers.Supplier.sections.Miscellaneous.tacDependencyOption',
  },
  tacDependencyText: {
    id: 'app.containers.Supplier.sections.Miscellaneous.tacDependencyText',
  },
  careDependencyOption: {
    id: 'app.containers.Supplier.sections.Miscellaneous.careDependencyOption',
  },
  careDependencyText: {
    id: 'app.containers.Supplier.sections.Miscellaneous.careDependencyText',
  },
  musicDependencyOption: {
    id: 'app.containers.Supplier.sections.Miscellaneous.musicDependencyOption',
  },
  musicDependencyText: {
    id: 'app.containers.Supplier.sections.Miscellaneous.musicDependencyText',
  },
  urnDependencyOption: {
    id: 'app.containers.Supplier.sections.Miscellaneous.urnDependencyOption',
  },
  urnDependencyText: {
    id: 'app.containers.Supplier.sections.Miscellaneous.urnDependencyText',
  },
  patternDependencyOption: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.patternDependencyOption',
  },
  patternDependencyText: {
    id: 'app.containers.Supplier.sections.Miscellaneous.patternDependencyText',
  },
  tbcDependencyOption: {
    id: 'app.containers.Supplier.sections.Miscellaneous.tbcDependencyOption',
  },
  tbcDependencyText: {
    id: 'app.containers.Supplier.sections.Miscellaneous.tbcDependencyText',
  },
  engravingDependencyOption: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.engravingDependencyOption',
  },
  engravingDependencyText: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.engravingDependencyText',
  },
  marbleDependencyOption: {
    id: 'app.containers.Supplier.sections.Miscellaneous.marbleDependencyOption',
  },
  marbleDependencyText: {
    id: 'app.containers.Supplier.sections.Miscellaneous.marbleDependencyText',
  },
  translationDependencyOption: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.translationDependencyOption',
  },
  translationDependencyText: {
    id:
      'app.containers.Supplier.sections.Miscellaneous.translationDependencyText',
  },
});
