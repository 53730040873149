import { assert } from '@advitam/support'

import { post, put } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { ClientJSON } from '../../../models/Funnel/Client'
import type { DealJSON } from '../../../models/Funnel/Marble'
import { TrackingAttributes } from '../../types/Tracking'

import serialize from './serialize'

export class Deals {
  static create(
    this: void,
    deal: DealJSON,
    client: ClientJSON,
    tracking?: TrackingAttributes,
  ): ApiRequestDescriptor<DealJSON> {
    return post('/api/v1/marble_funnel/deals', {
      funnel: serialize(deal, client, tracking),
    })
  }

  static update(this: void, deal: DealJSON, client: ClientJSON): ApiRequestDescriptor<DealJSON> {
    assert(deal.uuid !== undefined)
    return put(`/api/v1/marble_funnel/deals/${deal.uuid}`, {
      funnel: serialize(deal, client),
    })
  }
}
