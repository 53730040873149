import { createSelector } from 'reselect';
import { selectDealFuneralDomain } from 'containers/Deal/DealFuneral/selectors';

const makeSelectDealSummary = () =>
  createSelector(selectDealFuneralDomain, dealFuneral => {
    if (!dealFuneral.dealSummary) return null;
    return dealFuneral.dealSummary.summary;
  });

const makeSelectDealSummaryOtherFuneral = () =>
  createSelector(selectDealFuneralDomain, dealFuneral => {
    if (!dealFuneral.dealSummary) return null;
    return dealFuneral.dealSummary.otherFuneral;
  });

export { makeSelectDealSummary, makeSelectDealSummaryOtherFuneral };
