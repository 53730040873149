import { Component } from 'react';
import PropTypes from 'prop-types';

import { determineIdForNewObject } from 'utils/functions';
import Product from 'components/Product';

import PrestationDialog from 'components/PrestationDialog';
import { SKELETON_PRODUCT } from 'components/Product/constants';

class Products extends Component {
  state = {
    /** product to edit in modal */
    productToEdit: null,
    /** index of product being edited */
    productToEditIndex: null,
  };

  /**
   * Function to toggle dialog for edition
   */
  onEditProduct = (product, index) => {
    const { toggleDialog } = this.props;
    toggleDialog();
    this.setState({ productToEdit: product, productToEditIndex: index });
  };

  /**
   * Function to clear product to edit
   */
  clearProductToEdit = () =>
    this.setState({ productToEdit: null, productToEditIndex: null });

  /**
   * Function to delete a product from products
   *
   * @param {number} index index of product to remove
   */
  onDeleteProduct = index => {
    const { products, setProducts } = this.props;
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  /**
   * Function to add a product in products
   *
   * @memberof Products
   */
  onAddProduct = newProduct => {
    const { products, setProducts } = this.props;

    const updatedProducts = [
      ...products,
      { ...newProduct, id: determineIdForNewObject(products, 'id') },
    ];
    setProducts(updatedProducts);
  };

  /**
   * Function to update a product in produtcs
   *
   * @param {object} updatedProduct product to update
   * @param {number} index index of number to update
   */
  onUpdateProduct = (updatedProduct, index) => {
    const { products, setProducts } = this.props;
    const { productToEditIndex } = this.state;

    const indexToEdit = typeof index === 'number' ? index : productToEditIndex;
    const updatedProducts = [...products];
    updatedProducts[indexToEdit] = updatedProduct;
    setProducts(updatedProducts);
  };

  render() {
    const {
      products,
      isDialogOpen,
      toggleDialog,
      isManualMode,
      userRole,
      getProduct,
      isReadonly,
    } = this.props;
    const { productToEdit } = this.state;

    return (
      <div>
        {products.map((p, index) => (
          <Product
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            product={p}
            removeProduct={() => this.onDeleteProduct(index)}
            onEditProduct={() => this.onEditProduct(p, index)}
            updateProduct={updatedProduct =>
              this.onUpdateProduct(updatedProduct, index)
            }
            isManualMode={isManualMode}
            userRole={userRole}
            canSelectVariants
            getProduct={getProduct}
            isReadonly={isReadonly}
          />
        ))}
        <PrestationDialog
          addPrestation={this.onAddProduct}
          toggleDialog={toggleDialog}
          isDialogOpen={isDialogOpen}
          prestationToEdit={productToEdit}
          clearPrestationToEdit={this.clearProductToEdit}
          updatePrestation={this.onUpdateProduct}
          stepId={0}
          prestationSkeleton={SKELETON_PRODUCT}
        />
      </div>
    );
  }
}

Products.propTypes = {
  /** list of products */
  products: PropTypes.array.isRequired,
  /** is the dialog open */
  isDialogOpen: PropTypes.bool.isRequired,
  /** function to toggle dialog */
  toggleDialog: PropTypes.func.isRequired,
  /** is it the manual mode */
  isManualMode: PropTypes.bool.isRequired,
  /** function to set products in store */
  setProducts: PropTypes.func.isRequired,
  /** current user role */
  userRole: PropTypes.number.isRequired,
  getProduct: PropTypes.func,
  isReadonly: PropTypes.bool,
};

export default Products;
