import { useCallback, useEffect, useState } from 'react'

import { Browsers } from '@advitam/support'

/**
 * Copy contents to clipboard
 *
 * This also handles a timeout after copying, which may be used to display a
 * notification.
 *
 * @param timeout The notification timeout, in seconds. Defaults to 2.
 * @returns A tuple whose first element is the copy function, the second whether
 * the notification should be displayed
 */
export function useClipboardCopy(timeout = 2): [(text: string) => void, boolean] {
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (!isCopied) {
      return undefined
    }
    const timeoutHandle = setTimeout(() => setIsCopied(false), timeout * 1000)
    return (): void => clearTimeout(timeoutHandle)
  }, [isCopied])

  const copyToClipboard = useCallback(
    async (text: string): Promise<void> => {
      await Browsers.context.clipboard?.writeText(text)
      setIsCopied(true)
    },
    [setIsCopied],
  )

  return [copyToClipboard, isCopied]
}
