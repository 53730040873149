// API documentation : https://api.gouv.fr/documentation/api-recherche-entreprises

import qs from 'qs'
import { Company } from './types'

const BASE_URL = 'https://recherche-entreprises.api.gouv.fr/search'

type Freelance = 'yes' | 'no'
type MainActivitySection =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'

interface SearchQueryParams {
  /* eslint-disable camelcase */
  q: string
  code_postal?: string
  departement?: string
  activite_principale?: string
  section_activite_principale?: MainActivitySection
  is_entrepreneur_individuel?: Freelance
  tranche_effectif_salarie?: string
  page?: number
  per_page?: number
  /* eslint-enable camelcase */
}

interface IndexPayload {
  /* eslint-disable camelcase */
  results: Company[]
  total_results: number
  page: number
  per_page: number
  total_pages: number
  /* eslint-enable camelcase */
}

export async function fetchCompanies(params: SearchQueryParams): Promise<IndexPayload> {
  const querySearch = qs.stringify(params, { arrayFormat: 'brackets' })
  const endpoint = `${BASE_URL}?${querySearch}`

  const response = await fetch(endpoint, {
    headers: { Accept: 'application/json' },
  })

  return response.json() as Promise<IndexPayload>
}
