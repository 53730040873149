import { authenticate } from '../../lib/decorators'
import type { ApiRequestDescriptor } from '../../lib/request'
import { put } from '../../lib/methods'

export class OptOut {
  @authenticate
  static update(clientId: number, value: boolean): ApiRequestDescriptor<void> {
    return put(`/api/v1/clients/${clientId}/opt_out`, { value })
  }
}
