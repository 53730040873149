import React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { ClientAbility, ResourceType } from 'models/Client/Ability';
import { ClientRole } from 'models/Client';

import messages from './messages';

export function formatRole(ability: ClientAbility, intl: IntlShape): string {
  if (ability.role === ClientRole.READER) {
    if (
      ability.resource_type === ResourceType.FUNERAL_WILL ||
      ability.resource_type === ResourceType.SAFE_KEEPER
    ) {
      return intl.formatMessage(messages.relative);
    }
  }

  return intl.formatMessage(messages[ability.role]);
}

interface FormattedRoleProps {
  ability: ClientAbility;
}

export function FormattedRole({ ability }: FormattedRoleProps): JSX.Element {
  const intl = useIntl();
  return <>{formatRole(ability, intl)}</>;
}
