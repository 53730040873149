import type { ReactNode } from 'react'

import Text from '../Text'

interface ReassuranceItemProps {
  icon: ReactNode
  text: ReactNode
}

export default function ReassuranceItem({ icon, text }: ReassuranceItemProps): JSX.Element {
  return (
    <>
      {icon}
      <Text>{text}</Text>
    </>
  )
}
