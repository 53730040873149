import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { ResourceList } from '@advitam/ui';
import { FundingAgencyFilters } from '@advitam/api/v1/FundingAgencies';
import { FundingAgencyType } from '@advitam/api/models/FundingAgency';

import messages from './messages';
import { setFilters } from './slice';
import { makeSelectFilters } from './selectors';
import { fetchFundingAgencies } from './thunk';
import style from './FundingAgencies.modules.scss';

export default function Filters(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const filterValues = useSelector(makeSelectFilters());

  const onChange = useCallback(
    (value: Partial<FundingAgencyFilters>): void => {
      dispatch(setFilters(value));
      dispatch(fetchFundingAgencies());
    },
    [dispatch],
  );

  const filters = [
    {
      type: 'Select' as const,
      name: 'agency_type_eq',
      placeholder: intl.formatMessage(messages.type),
      initialValue: filterValues.agency_type_eq,
      items: [
        {
          name: intl.formatMessage(messages.bank),
          value: FundingAgencyType.BANK,
        },
        {
          name: intl.formatMessage(messages.healthInsurance),
          value: FundingAgencyType.HEALTH_INSURANCE,
        },
        {
          name: intl.formatMessage(messages.lifeInsurance),
          value: FundingAgencyType.LIFE_INSURANCE,
        },
      ],
    },
  ];

  return (
    <ResourceList.Filters
      className={style.filters}
      filters={filters}
      searchFilter={{
        name: 'name_unaccent_cont',
        placeholder: intl.formatMessage(messages.search),
        initialValue: filterValues.name_unaccent_cont,
      }}
      onChange={onChange}
    />
  );
}
