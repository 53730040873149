import { ChangeEvent, Fragment } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { assert } from 'lib/support';

import Api from '@advitam/api';
import { ResourceType } from '@advitam/api/models/DocumentEditions';
import { getTokens } from '@advitam/api/lib/tokens';
import { AutocompleteResult } from '@advitam/api/v1/Autocompletes';
import { FormUI } from '@advitam/ui';

import messages from '../messages';
import {
  makeSelectCurrentResource,
  makeSelectIsLoading,
  makeSelectIsSingleDocumentUpload,
} from '../../selectors';
import {
  DocumentEdition,
  DocumentEditionPage,
  NewUploadResource,
} from '../../types';
import Splitter from '../Splitter';
import style from './Document.module.scss';
import DocumentPage from './Page';

interface DocumentProps {
  index: number;
  document: DocumentEdition;
  hasError: boolean;
  splitPages: (splitIdx: number) => void;
  updateDocument: (document: DocumentEdition) => void;
}

export default function Document({
  document,
  hasError,
  splitPages,
  updateDocument,
}: DocumentProps): JSX.Element {
  const intl = useIntl();
  const requestTokens = { ...getTokens() };

  const isLoading = useSelector(makeSelectIsLoading());
  const isSingleDocumentUpload = useSelector(
    makeSelectIsSingleDocumentUpload(),
  );

  const currentResource = useSelector(makeSelectCurrentResource());
  assert(currentResource !== null);

  const updateResource = (resource: NewUploadResource): void => {
    updateDocument({ ...document, resource });
  };

  const onAutocompleteSelect = (result?: AutocompleteResult): void => {
    const newResource: NewUploadResource = {
      ...document.resource,
      id: result?.id,
    };

    if (newResource.type === ResourceType.DEAL) {
      newResource.deal.document_name = undefined;
    }

    updateResource(newResource);
  };

  const onDealDocumentInputChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    if (document.resource.type !== ResourceType.DEAL) {
      return;
    }

    updateResource({
      ...document.resource,
      id: undefined,
      deal: {
        ...document.resource.deal,
        document_name: event.target.value,
      },
    });
  };

  const updatePage = (pageIndex: number, page: DocumentEditionPage): void => {
    const newPages = [...document.pages];
    newPages[pageIndex] = page;
    updateDocument({ ...document, pages: newPages });
  };

  return (
    <div className={style.container}>
      <Droppable droppableId={document.uuid} direction="horizontal">
        {({ innerRef, droppableProps, placeholder }): JSX.Element => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...droppableProps}
            ref={innerRef}
            className={style.pages}
          >
            {document.pages.map((page, pageIndex) => (
              <Fragment key={page.uuid}>
                {!isSingleDocumentUpload && pageIndex > 0 && (
                  <Splitter
                    isPageSplitter
                    message={messages.splitPages}
                    onClick={(): void => splitPages(pageIndex)}
                  />
                )}
                <DocumentPage
                  index={pageIndex}
                  page={page}
                  updatePage={(newPage): void => updatePage(pageIndex, newPage)}
                />
              </Fragment>
            ))}
            {placeholder}
          </div>
        )}
      </Droppable>

      {!isSingleDocumentUpload &&
        (document.resource.type === ResourceType.DEAL ? (
          <FormUI.Autosuggest
            disabled={isLoading}
            className={style.autocomplete}
            placeholder={intl.formatMessage(messages.placeholder)}
            error={hasError}
            endpoint={Api.V1.absolute(
              Api.V1.Autocompletes.Path.deals.documents(
                document.resource.deal.uuid,
              ),
            )}
            searchParams={{ generated: 'false' }}
            requestHeaders={requestTokens}
            onChange={onAutocompleteSelect}
            onInput={onDealDocumentInputChange}
          />
        ) : (
          <FormUI.Autosuggest
            disabled={isLoading}
            className={style.autocomplete}
            placeholder={intl.formatMessage(messages.placeholder)}
            error={hasError}
            endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.entityInvoices)}
            requestHeaders={requestTokens}
            onChange={onAutocompleteSelect}
          />
        ))}
    </div>
  );
}
