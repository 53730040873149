import {
  GET_STATS,
  SET_STATS,
  GET_STATS_PER_MONTH,
  SET_STATS_PER_MONTH,
  ERROR,
} from './constants';

const initialState = {
  stats: {},
  statsPerMonth: {},
  error: null,
  loadingStats: true,
  loadingStatsPerMonth: true,
};

function accountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STATS_PER_MONTH:
      return {
        ...state,
        loadingStatsPerMonth: true,
        error: null,
      };
    case GET_STATS:
      return {
        ...state,
        loadingStats: true,
        error: null,
      };
    case SET_STATS_PER_MONTH:
      return {
        ...state,
        statsPerMonth: action.statsPerMonth,
        loadingStatsPerMonth: false,
      };
    case SET_STATS:
      return {
        ...state,
        stats: action.stats,
        loadingStats: false,
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        error: action.err,
      };
    default:
      return state;
  }
}

export default accountReducer;
