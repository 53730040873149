import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ButtonV2 as Button } from 'components/Button';
import AutoCompleteByEntity from 'components/AutoCompleteByEntity';

export class SelectionEntity extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      /** entities selected */
      entitiesSelected: props.defaultEntities || [],
    };
  }

  /**
   * Function to add an entity
   *
   * @param {object} entity entity data
   */
  addEntity = entity => {
    const { addEntity } = this.props;
    this.setState(prevState => ({
      entitiesSelected: [
        ...prevState.entitiesSelected,
        { id: entity.id, name: entity.description, type: entity.type },
      ],
    }));
    addEntity(entity);
  };

  /**
   * Function to remove an entity
   *
   * @param {string} entityId id of entity to remove
   */
  removeEntity = entityId => {
    const { removeEntity } = this.props;
    const { entitiesSelected } = this.state;
    const updatedEntitiesSelected = entitiesSelected.filter(
      entity => entity.id !== entityId,
    );
    this.setState({ entitiesSelected: updatedEntitiesSelected });
    removeEntity(entityId);
  };

  render() {
    const {
      intl,
      entityType,
      onChangeEntityType,
      multiSelection,
      entities,
      containerClassName,
    } = this.props;
    const { entitiesSelected } = this.state;

    const areEntitiesSelected = entitiesSelected.length > 0;

    return (
      <div className={containerClassName}>
        {((areEntitiesSelected && multiSelection) || !areEntitiesSelected) && (
          <AutoCompleteByEntity
            intl={intl}
            entityType={entityType}
            addEntity={this.addEntity}
            onChangeEntityType={onChangeEntityType}
            entities={entities}
          />
        )}
        {areEntitiesSelected &&
          entitiesSelected.map(entity => (
            <div key={entity.id}>
              {entity.name}
              <Button
                className="button--red"
                onClick={() => this.removeEntity(entity.id)}
              >
                ×
              </Button>
            </div>
          ))}
      </div>
    );
  }
}

SelectionEntity.propTypes = {
  intl: PropTypes.object.isRequired,
  /** entity type */
  entityType: PropTypes.string,
  /** Function when changing entity */
  addEntity: PropTypes.func.isRequired,
  /** Function when changing entity type */
  onChangeEntityType: PropTypes.func.isRequired,
  /** Function to remove an entity */
  removeEntity: PropTypes.func.isRequired,
  /** is it a multiSelection selection */
  multiSelection: PropTypes.bool.isRequired,
  /** default entities */
  defaultEntities: PropTypes.array,
  /** list of entities to autocomplete on */
  entities: PropTypes.array.isRequired,
  /** Class name(s) to apply to the container */
  containerClassName: PropTypes.string,
};

export default SelectionEntity;
