import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { DealDocumentBatchDocumentJSON } from '@advitam/api/models/Deal/Document/Batch';

import { PartialBatchDocument } from '../../types';
import { SEND_BATCH_MODAL_DOCUMENTS } from './constants';
import { AppStateSubset, State } from './slice';

type DocumentsSelector<T> = Selector<AppStateSubset, T>;

const selectDocumentsDomain = (state: AppStateSubset): State =>
  state[SEND_BATCH_MODAL_DOCUMENTS];

export const makeSelectIsLoading = (): DocumentsSelector<boolean> =>
  createSelector(selectDocumentsDomain, ({ isLoading }) => isLoading);

export const makeSelectLoadingDocumentIds = (): DocumentsSelector<number[]> =>
  createSelector(
    selectDocumentsDomain,
    ({ loadingDocumentIds }) => loadingDocumentIds,
  );

export const makeSelectIsDocumentLoading = (
  document: DealDocumentBatchDocumentJSON | PartialBatchDocument,
): DocumentsSelector<boolean> =>
  createSelector(makeSelectLoadingDocumentIds(), ids =>
    ids.includes(document.deal_document_id),
  );

export const makeSelectError = (): DocumentsSelector<SerializedApiError | null> =>
  createSelector(selectDocumentsDomain, ({ error }) => error);
