import { createSelector, Selector } from 'reselect';

import {
  makeSelectActiveFuneralTeam,
  AppStateSubset as DataAppStateSubset,
} from 'slices/data';
import { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

export const makeSelectFuneralTeamItems = (): Selector<
  DataAppStateSubset,
  SelectableItem<number>[]
> =>
  createSelector(makeSelectActiveFuneralTeam(), team =>
    team.map(user => ({
      value: user.id,
      name: user.name || user.email,
    })),
  );
