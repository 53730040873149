import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { useBreakpoint } from '@advitam/react';
import { getTokens } from '@advitam/api/lib/tokens';
import type { SupportingDocumentOwner } from '@advitam/api/models/SupportingDocuments/Owner';
import {
  ConfirmationModal,
  FormattedApiError,
  ResourceList,
  Text,
} from '@advitam/ui';

import Filters from './List/Filters';
import Header from './List/Header';
import Row from './List/Row';
import slice, { setFilters } from './slice';
import type { SupportingDocumentsForm } from './types';
import { fetchDocuments } from './thunk';
import { INITIAL_FILTERS } from './constants';
import { makeSelectError, makeSelectIsLoading } from './selectors';
import messages from './messages';
import style from './SupportingDocuments.module.scss';

const MD_BREAKPOINT = parseInt(style.md, 10);

interface SupportingDocumentsProps {
  owner: SupportingDocumentOwner;
}

export default function SupportingDocuments({
  owner,
}: SupportingDocumentsProps): JSX.Element {
  const dispatch = useDispatch();

  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const [indexOfDocumentToDelete, setIndexOfDocumentToDelete] = useState<
    number | null
  >(null);

  const form = useForm<SupportingDocumentsForm>();
  const { values } = useFormState<SupportingDocumentsForm>();
  const documents = values.sectionValues;

  const tokens = { ...getTokens() };
  const isAboveMd = useBreakpoint(MD_BREAKPOINT);

  const fetchResources = useCallback(() => {
    dispatch(fetchDocuments(owner));
  }, [dispatch, owner]);

  const closeDeletionConfirmation = useCallback(() => {
    setIndexOfDocumentToDelete(null);
  }, [setIndexOfDocumentToDelete]);

  const onDeletionConfirmed = useCallback(() => {
    form.change(
      'sectionValues',
      documents.filter((_, index) => index !== indexOfDocumentToDelete),
    );
    closeDeletionConfirmation();
  }, [closeDeletionConfirmation, form, documents, indexOfDocumentToDelete]);

  useEffect(() => {
    dispatch(setFilters(INITIAL_FILTERS));
    fetchResources();
  }, [dispatch, fetchResources]);

  if (error) {
    return (
      <Text tagName="p" className={style.error_text}>
        <FormattedApiError error={error} />
      </Text>
    );
  }

  return (
    <>
      <ResourceList.Container
        filters={<Filters owner={owner} isMobile={!isAboveMd} />}
        header={<Header />}
        fetchResources={fetchResources}
        hasMore={false}
        isLoading={isLoading}
      >
        <Field name="sectionValues">
          {(): null =>
            // Declare the field so final-form picks up array changes
            null
          }
        </Field>
        {documents.map((document, index) => (
          <Row
            // eslint-disable-next-line react/no-array-index-key
            key={`${document.id || ''}-${index}`}
            document={document}
            prefix={`sectionValues[${index}]`}
            onDelete={(): void => setIndexOfDocumentToDelete(index)}
            isMobile={!isAboveMd}
            // avoid accessing the LS once per row
            tokens={tokens}
          />
        ))}
      </ResourceList.Container>

      {indexOfDocumentToDelete !== null && (
        <ConfirmationModal
          isOpen
          text={
            <FormattedMessage
              id={messages.deletionConfirmationText.id}
              values={{
                documentName:
                  documents[indexOfDocumentToDelete]?.document_type
                    ?.pretty_name,
              }}
            />
          }
          confirm={
            <FormattedMessage id={messages.deletionConfirmationConfirm.id} />
          }
          onConfirm={onDeletionConfirmed}
          cancel={
            <FormattedMessage id={messages.deletionConfirmationCancel.id} />
          }
          onCancel={closeDeletionConfirmation}
        />
      )}
    </>
  );
}

export { slice };
export { makeSelectSupportingDocuments } from './selectors';
export { saveDocuments as saveSupportingDocuments } from './thunk';
