import type { ReactNode } from 'react'
import { Props as ReactModalProps } from 'react-modal'

import Modal from '../Modal'
import style from './Content.module.scss'

interface MessageProps {
  isOpen: boolean
  children: ReactNode
  onRequestClose?: ReactModalProps['onRequestClose']
  overlayClassName?: string
  className?: string
}

export default function Content({
  isOpen,
  children,
  onRequestClose,
  overlayClassName = '',
  className = '',
}: MessageProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={overlayClassName}
      className={`${style.content_modal} ${className}`}
      onRequestClose={onRequestClose}
    >
      {children}
    </Modal>
  )
}
