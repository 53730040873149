import { get } from 'lodash';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormLayout, FormUI, Input, hasLength } from '@advitam/ui';
import type { Address } from '@advitam/ui/components/Form/UI/GooglePlace/types';
import type { Headquarter } from '@advitam/ui/components/Form/UI/SiretAutocomplete';
import { Country } from '@advitam/api/models/Country';

import { makeSelectUser } from 'slices/auth';

import { getFieldName } from '../../utils';
import messages from '../messages';
import { MinimalDetails } from '../types';
import { serializeCompanyAddress, serializeHeadquarter } from './serializers';
import { computeVatNumber } from './utils';

const COUNTRY_SKELETON: Partial<Country> = {
  name: 'France',
  code: 'FR',
};

function formatAddress(address: Address | undefined): string {
  return [address?.street_number, address?.street_name]
    .filter(Boolean)
    .join(' ')
    .trim();
}

interface SiretAutocompleteProps {
  prefix?: string;
}

export default function SiretAutocomplete({
  prefix = '',
}: SiretAutocompleteProps): JSX.Element {
  const form = useForm();
  const { values } = useFormState();
  const user = useSelector(makeSelectUser());

  const siretField = getFieldName(prefix, 'siret');
  const addressField = getFieldName(prefix, 'headquarter_address');
  const countryField = getFieldName(prefix, 'headquarter_country');
  const cityField = getFieldName(prefix, 'headquarter_city');
  const nameField = getFieldName(prefix, 'headquarter_name');
  const postalCodeField = getFieldName(prefix, 'headquarter_postal_code');
  const vatField = getFieldName(prefix, 'vat');

  const onCompanyChange = (hq: Headquarter | undefined): void => {
    form.change(siretField, hq?.siret || null);
    form.change(nameField, hq?.name || null);
    form.change(addressField, hq?.address || null);
    form.change(postalCodeField, hq?.postal_code || null);
    form.change(cityField, hq?.city || null);
    form.change(countryField, hq ? COUNTRY_SKELETON : null);

    if (hq?.siret) {
      form.change(vatField, computeVatNumber(hq.siret));
    }
  };

  const onLocationChange = (v: Address | undefined): void => {
    form.change(addressField, formatAddress(v));
    form.change(postalCodeField, v?.postal_code || null);
    form.change(cityField, v?.city || null);
    form.change(
      countryField,
      v
        ? {
            code: v.country_code,
            name: v.country,
          }
        : null,
    );
  };

  const entity = get(values, prefix) as MinimalDetails;
  const value = serializeHeadquarter(entity);
  const canEdit = user?.isFuneralDirectorOrAbove;

  return (
    <>
      <FormLayout.Row>
        <Field<string> name={siretField} validate={hasLength(14)}>
          {({ meta, input }): JSX.Element => (
            <>
              <FormUI.SiretAutocomplete
                value={value}
                label={<FormattedMessage id={messages.siret.id} />}
                error={meta.touched && !meta.valid}
                onChange={onCompanyChange}
                onInput={(ev): void => form.change(siretField, ev.target.value)}
                onBlur={input.onBlur}
              />
              <input type="hidden" name={input.name} value={input.value} />
            </>
          )}
        </Field>
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          disabled={!canEdit}
          label={<FormattedMessage id={messages.administrativeName.id} />}
          name={nameField}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Field name={addressField}>
          {({ meta }): JSX.Element => (
            <FormUI.GooglePlace
              value={serializeCompanyAddress(entity)}
              formatDisplayValue={formatAddress}
              disabled={!canEdit}
              error={meta.touched && !meta.valid}
              onChange={onLocationChange}
              label={
                <FormattedMessage id={messages.administrativeAddress.id} />
              }
            />
          )}
        </Field>
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          disabled
          label={<FormattedMessage id={messages.postalCode.id} />}
          name={postalCodeField}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          disabled
          label={<FormattedMessage id={messages.city.id} />}
          name={cityField}
        />
        <Input
          disabled
          label={<FormattedMessage id={messages.country.id} />}
          name={`${countryField}.name`}
        />
      </FormLayout.Row>
    </>
  );
}
