import type { IntlShape } from 'react-intl';

import type { FailedSendingNotificationJSON } from '@advitam/api/models/Notification';
import { RecipientType } from '@advitam/api/models/RecipientType';
import { LocationType } from '@advitam/api/models/LocationType';

import { Path } from 'containers/App/constants';
import { Path as FuneralPath } from 'containers/Deal/Funeral/constants';

import type { TileProps } from './TileProps';
import LetterFail from './icons/letter-fail.svg';
import LetterFailStandalone from './icons/letter-fail.standalone.png.bin';
import messages from './messages';
import style from './NotificationTile.module.scss';

const ENTITY_TYPE_MAP: Record<string, string | undefined> = {
  [LocationType.AIRPORT]: Path.FLIGHTS,
  [LocationType.CITYHALL]: Path.CITYHALLS,
  [LocationType.CONSULATE]: Path.CONSULATES,
  [LocationType.CREMATORIUM]: Path.CREMATORIUMS,
  [LocationType.FUNERAL_PARLOR]: Path.FUNERAL_PARLORS,
  [LocationType.GRAVEYARD]: Path.GRAVEYARDS,
  [LocationType.HOSPITAL]: Path.HOSPITALS,
  [LocationType.POLICE]: Path.POLICE,
  [LocationType.PREFECTURE]: Path.PREFECTURES,
  [LocationType.REGIONAL_HEALTH_AUTHORITY]: Path.RHAS,
  [LocationType.WORSHIP]: Path.WORSHIPS,
};

function getRedirectionLink(
  notification: FailedSendingNotificationJSON,
): string | null {
  const {
    deal_uuid: dealUuid,
    deal_type: dealType,
    recipient_id: recipientId,
    recipient_type: recipientType,
  } = notification.comp_info;
  const isFuneralDeal = dealType === 'funeral';

  if (recipientType === RecipientType.CLIENT) {
    return Path.DEAL(dealUuid, isFuneralDeal ? FuneralPath.IDENTITY : 'client');
  }

  if (recipientType === RecipientType.SUPPLIER) {
    return Path.SUPPLIER(recipientId);
  }

  const entityPath = ENTITY_TYPE_MAP[recipientType];
  if (!entityPath) {
    return null;
  }

  return [entityPath, recipientId].join('/');
}

function getEntityName(notification: FailedSendingNotificationJSON): string {
  const {
    owner_full_name: ownerFullname,
    recipient_name: recipientName,
    recipient_type: recipientType,
  } = notification.comp_info;

  if (recipientType === RecipientType.CLIENT) {
    return ownerFullname;
  }

  return recipientName;
}

export default function getFailedSendingProps(
  notification: FailedSendingNotificationJSON,
  intl: IntlShape,
): TileProps {
  const link = getRedirectionLink(notification);
  const entityName = getEntityName(notification);

  return {
    link,
    IconComponent: (): JSX.Element => (
      <LetterFail className={style.failed_sending} />
    ),
    icon: LetterFailStandalone,
    title: intl.formatMessage(messages.failedSendingTitle, {
      sendingMethod: notification.comp_info.sent_by,
    }),
    body: intl.formatMessage(messages.failedSendingText, {
      entityName,
    }),
  };
}
