import { ReactNode, useState } from 'react'

import Illustration from '../../../images/illustrations/leaf-grave.svg'
import Text from '../../Text'
import ShoppingCart from '../ShoppingCart'
import style from './Error.module.scss'

interface ErrorProps {
  title: ReactNode
  message: ReactNode
  action: ReactNode
}

export default function Error({ title, message, action }: ErrorProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <ShoppingCart
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={<span className={style.title}>{title}</span>}
      totals={null}
      totalPrice={null}
      action={action}
      className={style.cart}
    >
      <Text large className={style.message}>
        {message}
      </Text>
      <Illustration className={style.icon} />
    </ShoppingCart>
  )
}
