import { useSelector } from 'react-redux';
import { makeSelectActiveCoveragesByAscLength } from '../selectors';
import CoverageItem from './Item';

export default function Coverages(): JSX.Element {
  const activeCoverages = useSelector(makeSelectActiveCoveragesByAscLength());

  return (
    <>
      {activeCoverages.map((coverage, idx) => (
        <CoverageItem key={coverage.id} coverage={coverage} zIndex={idx} />
      ))}
    </>
  );
}
