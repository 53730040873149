import { authenticate } from 'api/decorators';
import { get } from 'api/methods';
import { ApiRequestDescriptor } from 'api/request';

export class Variables {
  @authenticate
  static show(documentTypeSlug: string): ApiRequestDescriptor<string[]> {
    return get(`/api/v1/document_types/${documentTypeSlug}/variables`);
  }
}
