import type { FormApi } from 'final-form';
import { cloneDeep } from 'lodash';

import { ClientRole } from '@advitam/api/models/Client/Role';
import { Model } from '@advitam/api/models/Model';

import type { FuneralForm } from '../../Funeral/types';

export function cloneClient(form: FormApi, index: number): void {
  const { values } = ((form as unknown) as FormApi<FuneralForm>).getState();
  const { abilities } = values;

  const destroyed = cloneDeep(abilities[index]);
  Model.setDestroyed(destroyed);
  destroyed.special = false;
  if (destroyed.role === ClientRole.OWNER) {
    destroyed.role = ClientRole.EDITOR;
  }
  form.change('abilities', [...abilities, destroyed]);
  form.change(`abilities[${index}].id`, undefined);
}
