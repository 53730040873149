import { ReactNode } from 'react'

import style from './ButtonText.module.scss'

interface ButtonTextProps {
  small?: boolean
  fixedSize?: boolean
  children: ReactNode
  className?: string
}

export default function ButtonText({
  fixedSize = false,
  small = false,
  children,
  className,
}: ButtonTextProps): JSX.Element {
  const classes = []
  if (small) {
    classes.push(style.small)
  } else if (fixedSize) {
    classes.push(style.fixed_size)
  } else {
    classes.push(style.medium)
  }
  if (className) {
    classes.push(className)
  }

  return <span className={classes.join(' ')}>{children}</span>
}
