import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get } from 'api/methods';
import { ProductJSON } from 'models/Product';

export class Products {
  @authenticate
  static show(id: number): ApiRequestDescriptor<ProductJSON> {
    return get(`/api/v1/products/${id}`);
  }
}
