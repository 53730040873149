import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { FundingAgencyFilters } from '@advitam/api/v1/FundingAgencies';
import { FundingAgencyJSON } from '@advitam/api/models/FundingAgency';

import { FUNDING_AGENCIES } from './constants';
import { AppStateSubset, State } from './slice';

type FASelector<T> = Selector<AppStateSubset, T>;

const selectFADomain = (state: AppStateSubset): State =>
  state[FUNDING_AGENCIES];

export const makeSelectFundingAgencies = (): FASelector<FundingAgencyJSON[]> =>
  createSelector(selectFADomain, substate => substate.fundingAgencies);

export const makeSelectIsLoading = (): FASelector<boolean> =>
  createSelector(selectFADomain, substate => substate.isLoading);

export const makeSelectError = (): FASelector<SerializedApiError | null> =>
  createSelector(selectFADomain, substate => substate.error);

export const makeSelectFilters = (): FASelector<FundingAgencyFilters> =>
  createSelector(selectFADomain, substate => substate.filters);

export const makeSelectPage = (): FASelector<number> =>
  createSelector(selectFADomain, substate => substate.page);

export const makeSelectHasMore = (): FASelector<boolean> =>
  createSelector(selectFADomain, substate => substate.hasMore);
