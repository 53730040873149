import { post } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { PaymentJSON } from '../../models/Payment'

import serialize from './serializers'

interface CreationBody {
  url: string
}

export const Fintecture = {
  create: (payment: PaymentJSON, redirect_url: string): ApiRequestDescriptor<CreationBody> =>
    post('/api/v1/payments/fintecture', {
      ...serialize(payment),
      redirect_url,
    }),
}
