import { Objects } from '@advitam/support'

import {
  DealStepJSON as PayloadDealStepJSON,
  DeathStepJSON as PayloadDeathStepJSON,
  ClosingStepJSON as PayloadClosingStepJSON,
  CasketingStepJSON as PayloadCasketingStepJSON,
  CremationStepJSON as PayloadCremationStepJSON,
  CeremonyStepJSON as PayloadCeremonyStepJSON,
  IntermentStepJSON as PayloadIntermentStepJSON,
  RepatriationStepJSON as PayloadRepatriationStepJSON,
  PayloadJSON,
  StepLocationJSON as PayloadStepLocationJSON,
  OwnerAbilityJSON as PayloadOwnerAbilityJSON,
  DealProductJSON as PayloadDealProductJSON,
  UrnPickupStepJSON as PayloadUrnPickupStepJSON,
  StepPublicLocation,
  StepLocationJSON,
} from './types'
import { DealJSON } from '../../../models/Funnel/Funeral'
import { StepType } from '../../../models/Step/Type'
import { DealProductJSON } from '../../../models/Funnel/Deals/Product'
import {
  DealStepJSON,
  DeathStepJSON,
  ClosingStepJSON,
  CasketingStepJSON,
  CremationStepJSON,
  CeremonyStepJSON,
  IntermentStepJSON,
  RepatriationStepJSON,
  UrnPickupStepJSON,
} from '../../../models/Funnel/Funeral/Step'
import {
  LocationType,
  Location,
  PublicLocationType,
  PublicLocation,
} from '../../../models/Funnel/Deals/Step/Location'
import { ClientJSON } from '../../../models/Funnel/Client'
import { OwnerAbilityJSON } from '../../../models/Funnel/Funeral/OwnerAbility'
import { Model } from '../../../models/Model'
import { TrackingAttributes } from '../../types/Tracking'
import { RepatriationType } from '../../../models/Repatriation/Type'

function serializePublicLocation(location: PublicLocation): StepPublicLocation {
  return {
    location_type: LocationType.PUBLIC_LOCATION,
    location_attributes: {
      ...Objects.omit(location, 'country'),
      country_code: location.country.code,
      location_type: PublicLocationType.PUBLIC,
    },
  }
}

function serializeLocation(location: Location): PayloadStepLocationJSON {
  if (location.type === LocationType.PUBLIC_LOCATION) {
    return serializePublicLocation(location)
  }

  return {
    location_type: location.type,
    location_id: location.id,
  }
}

function serializeDeath(step: DeathStepJSON): PayloadDeathStepJSON {
  const result = {
    id: step.id,
    step_id: step.step_id,
    step_type: step.step_type,
  }
  return result
}

function serializeClosing(step: ClosingStepJSON): PayloadClosingStepJSON {
  const result = {
    id: step.id,
    step_id: step.step_id,
    step_type: step.step_type,
    same_location_as_previous: true as const,
    closing_details_attributes: {
      id: step.details.id,
      with_family: true as const,
    },
  }
  if (Model.isDestroyed(step)) {
    Model.setDestroyed(result)
  }
  return result
}

function serializeCasketing(step: CasketingStepJSON): PayloadCasketingStepJSON {
  const result = {
    id: step.id,
    step_id: step.step_id,
    step_type: step.step_type,
    ...serializeLocation(step.location),
  }
  if (Model.isDestroyed(step)) {
    Model.setDestroyed(result)
  }
  return result
}

function serializeCeremony(step: CeremonyStepJSON): PayloadCeremonyStepJSON {
  const result = {
    id: step.id,
    step_id: step.step_id,
    step_type: step.step_type,
    location_type: step.location.type,
    location_id: step.location.id,
    ceremony_details_attributes: {
      id: step.details.id,
      worship_type_id: step.details.worship_type_id,
    },
  }
  if (Model.isDestroyed(step)) {
    Model.setDestroyed(result)
  }
  return result
}

function serializeCremation(step: CremationStepJSON): PayloadCremationStepJSON {
  const result = {
    id: step.id,
    step_id: step.step_id,
    step_type: step.step_type,
    location_type: step.location.type,
    auto_find_location: true as const,
  }
  if (Model.isDestroyed(step)) {
    Model.setDestroyed(result)
  }
  return result
}

function serializeUrnPickup(step: UrnPickupStepJSON): PayloadUrnPickupStepJSON {
  const result = {
    id: step.id,
    step_id: step.step_id,
    step_type: step.step_type,
    same_location_as_previous: true as const,
  }
  if (Model.isDestroyed(step)) {
    Model.setDestroyed(result)
  }
  return result
}

function serializeInterment(step: IntermentStepJSON): PayloadIntermentStepJSON {
  const { concession } = step.details.defunct_concessions_version

  const result = {
    id: step.id,
    step_id: step.step_id,
    step_type: step.step_type,
    location_type: step.location.type,
    location_id: step.location.id,
    interment_details_attributes: {
      id: step.details.id,
      defunct_concessions_version_attributes: {
        id: step.details.defunct_concessions_version.id,
        remaining_places: 1,
        concession_attributes: {
          id: concession.id,
          total_places: 1,
          grave_type: concession.grave_type,
          exists: concession.exists,
        },
      },
    },
  }
  if (Model.isDestroyed(step)) {
    Model.setDestroyed(result)
  }
  return result
}

function serializeRepatriation(step: RepatriationStepJSON): PayloadRepatriationStepJSON {
  let location: StepLocationJSON | Record<string, never> = {}
  if (step.details.repatriation_type === RepatriationType.CAR) {
    location = serializeLocation(step.location)
  }

  const result = {
    id: step.id,
    step_id: step.step_id,
    step_type: step.step_type,
    ...location,
    repatriation_details_attributes: {
      id: step.details.id,
      destination_country_code: step.details.destination_country_code,
      destination_city: step.details.destination_city,
      repatriation_type: step.details.repatriation_type,
    },
  }
  if (Model.isDestroyed(step)) {
    Model.setDestroyed(result)
  }
  return result
}

function serializeStepFactory(step: DealStepJSON): PayloadDealStepJSON {
  switch (step.step_type) {
    case StepType.DEATH: {
      return serializeDeath(step)
    }
    case StepType.CLOSING: {
      return serializeClosing(step)
    }
    case StepType.CASKETING: {
      return serializeCasketing(step)
    }
    case StepType.CREMATION: {
      return serializeCremation(step)
    }
    case StepType.INTERMENT: {
      return serializeInterment(step)
    }
    case StepType.REPATRIATION: {
      return serializeRepatriation(step)
    }
    case StepType.CEREMONY: {
      return serializeCeremony(step)
    }
    case StepType.URN_PICKUP: {
      return serializeUrnPickup(step)
    }
    default: {
      throw new Error(`serializeStepFactory Unhandled step type ${step.step_type || ''}`)
    }
  }
}

function serializeSteps(steps: DealStepJSON[]): PayloadDealStepJSON[] {
  const stepToSerialize = steps.filter(s => s.step_type !== StepType.TRANSPORT)
  return stepToSerialize.map(step => serializeStepFactory(step))
}

function serializeOwner(
  ownerAbility: OwnerAbilityJSON,
  client: ClientJSON,
): PayloadOwnerAbilityJSON {
  return {
    link_with_defunct: ownerAbility.link_with_defunct,
    id: ownerAbility.id,
    client_attributes: client,
  }
}

function serializeProducts(deal: DealJSON): PayloadDealProductJSON[] {
  return deal.deals_products.map(
    (dealProduct: DealProductJSON): PayloadDealProductJSON => {
      const step = deal.deal_steps.find(s => s.step_type === dealProduct.step_type)
      const step_id = step?.step_id || -1
      const result = {
        id: dealProduct.id,
        step_id,
        step_type: dealProduct.step_type,
        product_id: dealProduct.product_id,
        model_id: dealProduct.model_id,
        product_type: dealProduct.product_type,
        use: dealProduct.use,
        ceremony: dealProduct.ceremony,
        flag: dealProduct.flag,
      }
      if (Model.isDestroyed(dealProduct)) {
        Model.setDestroyed(result)
      }
      return result
    },
  )
}

export default function serialize(
  deal: DealJSON,
  client: ClientJSON,
  tracking?: TrackingAttributes,
): PayloadJSON {
  return {
    funnel: {
      client_commentary: deal.client_commentary,
      defunct_attributes: deal.defunct,
      deal_settings_attributes: deal.deal_settings,
      deals_products_attributes: serializeProducts(deal),
      deal_steps_attributes: serializeSteps(deal.deal_steps),
      owner_ability_attributes: serializeOwner(deal.owner_ability, client),
      tracking_attributes: tracking,
    },
  }
}
