import PropTypes from 'prop-types';

import InnerBar from 'components/InnerBar';

function ProgressBar({ progress, goal, classNameContainer }) {
  let goalCleared = progress === 100;
  if (goal) goalCleared = progress >= goal;
  return (
    <div className={classNameContainer}>
      {goal && !goalCleared && (
        <InnerBar
          width={goal}
          className="progressBar__innerBar progressBar__innerBar--goal"
        />
      )}
      <InnerBar
        width={progress}
        className={
          goalCleared
            ? 'progressBar__innerBar progressBar__innerBar--goalCleared'
            : 'progressBar__innerBar'
        }
      />
    </div>
  );
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
  goal: PropTypes.number,
  classNameContainer: PropTypes.string,
};

ProgressBar.defaultProps = {
  classNameContainer: 'progressBar',
};

export default ProgressBar;
