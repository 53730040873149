export enum CommunicationState {
  FAILED = 'failed',
  PENDING = 'pending',
  NOTHING = 'nothing',
  DISPATCHED = 'dispatched',
  RECEIVED = 'received',
  SENT = 'sent',

  CANCELLED = 'cancelled',
  ANSWERED = 'answered',
  MISSED = 'missed',
  QUEUE_TIMEOUT = 'queue_timeout',
  BLIND_TRANSFERED = 'blind_transfered',
  VOICEMAIL = 'voicemail',
  PERMANENT_TRANSFERED = 'permanent_transfered',
  NOANSWER_TRANSFERED = 'noanswer_transfered',
  FAX_RECEIVED = 'fax_received',
  FAX_FAILED = 'fax_failed',
  FAX_OUT_SENT = 'fax_out_sent',
  INCORRECT_PINCODE = 'incorrect_pincode',
  FAX_OUT_NOT_SENT = 'fax_out_not_sent',
  ANNOUNCE = 'announce',
}
