import type { Address } from '@advitam/ui/components/Form/UI/GooglePlace/types';
import type { Headquarter } from '@advitam/ui/components/Form/UI/SiretAutocomplete';
import { MinimalDetails } from '../types';

export function serializeHeadquarter(
  values: MinimalDetails | undefined,
): Headquarter {
  return {
    siret: values?.siret || '',
    name: values?.headquarter_name || '',
    address: values?.headquarter_address || '',
    postal_code: values?.headquarter_postal_code || '',
    city: values?.headquarter_city || '',
    country: {
      name: values?.headquarter_country?.name || '',
      code: values?.headquarter_country?.code || '',
    },
  };
}

export function serializeCompanyAddress(
  values: MinimalDetails | undefined,
): Address {
  return {
    street_name: values?.headquarter_address || '',
    street_number: null,
    postal_code: values?.headquarter_postal_code || '',
    city: values?.headquarter_city || '',
    country: values?.headquarter_country?.name || '',
    country_code: null,
    latitude: null,
    longitude: null,
  };
}
