import { InputHTMLAttributes, ReactNode } from 'react'
import { Field, UseFieldConfig } from 'react-final-form'

import { Switch } from '../UI'
import { composeValidators, isRequired, Validator } from '../validators'

interface SwitchFieldProps {
  label: ReactNode
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: UseFieldConfig<any>['format']
  required?: boolean
  validate?: Validator<InputHTMLAttributes<HTMLInputElement>['value']>
  className?: string
}

export default function SwitchField({
  label,
  name,
  value,
  parse,
  format,
  required,
  validate,
  className,
}: SwitchFieldProps): JSX.Element {
  const validators = composeValidators(required && isRequired, validate)
  return (
    <Field
      name={name}
      type="checkbox"
      value={value}
      parse={parse}
      format={format}
      validate={validators}
    >
      {({ input: { onChange, checked }, meta: { touched, error } }): JSX.Element => (
        <Switch
          name={name}
          label={label}
          onChange={onChange}
          value={value}
          checked={checked}
          error={Boolean(touched && error)}
          className={className}
        />
      )}
    </Field>
  )
}
