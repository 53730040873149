import type { CoverageJSON } from '@advitam/api/models/Entity/Coverage';
import type { SupplierWarehouseZoneJSON } from '@advitam/api/models/Supplier/Warehouse';
import type { Address } from '@advitam/ui/components/Form/GooglePlace/types';
import type { SupplierWarehouseForm } from '../types';

export type CoveragesPerZone = Record<number, CoverageJSON[]>;

export type UnsavedSupplierWarehouseZone = Omit<
  SupplierWarehouseZoneJSON,
  'id'
> & {
  id?: undefined;
};

export type UnsavedCoverage = Omit<CoverageJSON, 'id'> & {
  id?: undefined;
};

export const enum CenterType {
  WAREHOUSE,
  ADDRESS,
}

export interface AutoAdditionFields {
  centerType: CenterType;
  centerAddress: Address | null;
  radius: number | null;
}

export interface ZoneSectionValue {
  zone: SupplierWarehouseZoneJSON | UnsavedSupplierWarehouseZone;
  coverage: Array<CoverageJSON | UnsavedCoverage>;
  autoAddition?: AutoAdditionFields;
}

export type WarehouseZonesForm = SupplierWarehouseForm<ZoneSectionValue[]>;
