import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { TextArea } from '@advitam/ui';
import { Fields, Layout } from 'containers/Crud';
import { getFieldName } from 'containers/Crud/Fields/utils';

import { updateCityhall } from '../thunk';
import messages from '../messages';
import Wrapper from './Wrapper';
import type { CityhallForm } from './types';

const FIELD_PREFIX = 'cityhall';

export default function General(): JSX.Element {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: CityhallForm): void => {
      dispatch(updateCityhall(values.cityhall));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <Layout.ColumnGroup>
          <Layout.Column>
            <Layout.Fieldset
              title={<FormattedMessage id={messages.commentary.id} />}
            >
              <TextArea name={getFieldName(FIELD_PREFIX, 'comment')} />
            </Layout.Fieldset>
          </Layout.Column>
        </Layout.ColumnGroup>
        <Layout.ColumnGroup>
          <Layout.Column>
            <Fields.Details prefix={FIELD_PREFIX} />
            <Fields.Billing prefix={FIELD_PREFIX} />
          </Layout.Column>
          <Layout.Column>
            <Fields.Contact
              addressPrefix={FIELD_PREFIX}
              withoutCity
              contactPrefix={FIELD_PREFIX}
            />
          </Layout.Column>
        </Layout.ColumnGroup>
      </Layout.Container>
    </Wrapper>
  );
}
