import { useEffect, useState, ReactNode } from 'react'
import { GoogleMap, DirectionsRenderer, GoogleMapProps } from '@react-google-maps/api'

import style from './Map.module.scss'
import { getMapStyle, MapStyle } from './mapStyles'

const DEFAULT_ZOOM = 4
const DEFAULT_CENTER = { lat: 47.062725, lng: 2.287592000000018 }

type LatLng = google.maps.LatLng
type LatLngLiteral = google.maps.LatLngLiteral
type DirectionsWaypoint = google.maps.DirectionsWaypoint
type DirectionsResult = google.maps.DirectionsResult

interface MapPropsBase {
  children?: ReactNode | ReactNode[]
  zoom?: GoogleMapProps['zoom']
  center?: GoogleMapProps['center']
  maxZoom?: google.maps.MapOptions['maxZoom']
  scrollwheel?: google.maps.MapOptions['scrollwheel']
  className?: string
  mapStyle?: MapStyle
  onClick?: (e: google.maps.MapMouseEvent) => void
}

interface MapPropsWithoutRoute extends MapPropsBase {
  origin?: undefined
  destination?: undefined
  waypoints?: undefined
}

interface MapPropsWithRoute extends MapPropsBase {
  // undefined is necessary for instanciation of MapWrapper, but shouldn't
  origin: LatLng | LatLngLiteral | undefined
  destination: LatLng | LatLngLiteral | undefined
  waypoints: DirectionsWaypoint[] | undefined
}

type MapProps = MapPropsWithoutRoute | MapPropsWithRoute

function MapImpl({
  origin,
  destination,
  waypoints,
  children,
  zoom = DEFAULT_ZOOM,
  center = DEFAULT_CENTER,
  maxZoom,
  scrollwheel = false,
  className = '',
  mapStyle,
  onClick,
}: MapProps): JSX.Element {
  const [directions, setDirections] = useState<null | DirectionsResult>(null)

  useEffect(() => {
    if (!origin || !destination) {
      return
    }
    const service = new google.maps.DirectionsService()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    service.route(
      {
        origin,
        destination,
        waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result)
        }
      },
    )
  }, [destination, origin, waypoints])

  return (
    <GoogleMap
      zoom={zoom}
      center={center}
      onClick={onClick}
      options={{
        maxZoom,
        scrollwheel,
        fullscreenControl: false,
        mapTypeControl: false,
        panControl: false,
        streetViewControl: false,
        styles: getMapStyle(mapStyle),
      }}
      mapContainerClassName={[style.container, className].join(' ')}
    >
      {directions && <DirectionsRenderer directions={directions} />}
      {children}
    </GoogleMap>
  )
}

export default function Map({
  origin,
  destination,
  waypoints,
  children,
  zoom,
  maxZoom,
  scrollwheel,
  center,
  className,
  mapStyle,
  onClick,
}: MapProps): JSX.Element {
  return (
    <MapImpl
      onClick={onClick}
      mapStyle={mapStyle}
      origin={origin}
      destination={destination}
      waypoints={waypoints}
      zoom={zoom}
      maxZoom={maxZoom}
      scrollwheel={scrollwheel}
      center={center}
      className={className}
    >
      {children}
    </MapImpl>
  )
}
