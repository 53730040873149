import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useBreakpoint } from '@advitam/react';
import { LinkButton, ResourceList } from '@advitam/ui';
import type {
  FilterSelection,
  Filter,
} from '@advitam/ui/components/ResourceList/Filters/types';

import { DEALS_PATH, NEW_PATH } from 'containers/App/constants';
import { makeSelectFuneralBrands, makeSelectFuneralTeam } from 'slices/data';

import { FILTERS } from '../constants';
import messages from '../messages';
import { makeSelectFilters } from '../selectors';
import style from '../DealsList.module.scss';

interface FiltersProps {
  onChange: (filters: Record<string, FilterSelection>) => void;
}

export default function DealFilters({ onChange }: FiltersProps): JSX.Element {
  const intl = useIntl();
  const isAboveDesktop = useBreakpoint(parseInt(style.xl, 10));
  const isAboveLargeDesktop = useBreakpoint(parseInt(style.xxl, 10));
  const brands = useSelector(makeSelectFuneralBrands());
  const team = useSelector(makeSelectFuneralTeam());
  const filters = useSelector(makeSelectFilters());

  const filtersShapes: Filter[] = [
    FILTERS.STATE(intl, filters.deals_state_key_in),
    FILTERS.TYPE(intl, filters.deal_type_eq),
    {
      ...FILTERS.FUNERAL_BRAND(intl, brands, filters.funeral_brand_id_eq),
      isHidden: !isAboveDesktop,
    },
    FILTERS.USER(intl, team, filters.user_id_eq),
    {
      ...FILTERS.DATE(intl, filters.deal_date_gteq, filters.deal_date_lteq),
      isHidden: !isAboveLargeDesktop,
    },
  ];

  return (
    <ResourceList.Filters
      className={style.filters}
      filters={filtersShapes}
      searchFilter={FILTERS.SEARCH(intl, filters.q)}
      onChange={onChange}
    >
      <LinkButton
        primary
        internal
        href={`${DEALS_PATH}/${NEW_PATH}`}
        text={<FormattedMessage id={messages.createDeal.id} />}
        className={style.new}
      />
    </ResourceList.Filters>
  );
}
