import type { ReactNode } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import { assert } from '@advitam/support';
import { ActionsPopup } from 'containers/Crud';

import Sidebar from '../Sidebar';
import { makeSelectRawWarehouse } from '../selectors';
import type { SupplierWarehouseForm } from './types';
import style from '../Warehouse.module.scss';

interface WrapperProps<T> {
  children: ReactNode;
  initialSectionValues?: T;
  onSubmit: (values: SupplierWarehouseForm<T>) => void;
}

export default function Wrapper<T>({
  children,
  initialSectionValues,
  onSubmit,
}: WrapperProps<T>): JSX.Element {
  const warehouse = useSelector(makeSelectRawWarehouse());
  assert(warehouse !== null);

  const initialValues: Partial<SupplierWarehouseForm<T>> = {
    warehouse,
    sectionValues: initialSectionValues,
  };

  return (
    <Form<SupplierWarehouseForm<T>>
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <Sidebar />
          {(dirty || !warehouse.id) && <ActionsPopup isNew={!warehouse.id} />}
          {children}
        </form>
      )}
    </Form>
  );
}
