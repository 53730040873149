import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

import Api, { Pagination, request } from '@advitam/api';
import { assert, isEqual } from '@advitam/support';

import {
  SupplierWarehouseJSON,
  SupplierWarehouseProductJSON,
} from '@advitam/api/models/Supplier/Warehouse';
import { makeSelectWarehouse } from '../../selectors';
import { AppStateSubset as WarehouseAppStateSubset } from '../../slice';
import { SUPPLIER_WAREHOUSE_PRODUCTS } from './constants';
import { AppStateSubset as ProductsAppStateSubset } from './slice';
import {
  UnsavedSupplierWarehouseProduct,
  WarehouseProductsForm,
} from './types';
import { updateWarehouseName } from '../../thunk';
import { makeSelectProducts } from './selectors';

type AppStateSubset = WarehouseAppStateSubset & ProductsAppStateSubset;

export const fetchWarehouseProducts = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE_PRODUCTS}_FETCH`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const warehouse = makeSelectWarehouse()(state);
    assert(warehouse !== null);

    try {
      const products: SupplierWarehouseProductJSON[] = [];
      let currentPage = 1;
      let hasMore = true;

      while (hasMore) {
        // eslint-disable-next-line no-await-in-loop
        const response = await request(
          Api.V1.Suppliers.Warehouses.Products.index(warehouse.id, {
            page: currentPage,
            per_page: 100,
          }),
        );

        products.push(...response.body);
        hasMore = Pagination.getPageCount(response) > currentPage;
        currentPage += 1;
      }

      return products;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

function isSavedProduct(
  product: SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct,
): product is SupplierWarehouseProductJSON {
  return product.id !== undefined;
}

function isValidCreation(
  product: UnsavedSupplierWarehouseProduct,
): product is Omit<SupplierWarehouseProductJSON, 'id'> {
  return product.product !== null;
}

async function saveProduct(
  product: SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct,
  existingProducts: SupplierWarehouseProductJSON[],
  warehouseId: number,
): Promise<SupplierWarehouseProductJSON> {
  if (isSavedProduct(product)) {
    const existingProduct = existingProducts.find(c => c.id === product.id);
    if (existingProduct && isEqual(product, existingProduct)) {
      return existingProduct;
    }

    const { body } = await request(
      Api.V1.Suppliers.Warehouses.Products.update(product),
    );

    return body;
  }

  assert(isValidCreation(product));
  const { body } = await request(
    Api.V1.Suppliers.Warehouses.Products.create(warehouseId, product),
  );

  return body;
}

interface SaveProductsPayload {
  warehouseId: number;
  values: WarehouseProductsForm;
}

export const saveProducts = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE_PRODUCTS}_SAVE`,
  async (
    { warehouseId, values }: SaveProductsPayload,
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const existingProducts = makeSelectProducts()(state);

    let warehouse: SupplierWarehouseJSON;
    try {
      const result = await dispatch(updateWarehouseName(values.warehouse.name));
      unwrapResult(result);
      warehouse = result.payload as SupplierWarehouseJSON;
    } catch {
      return undefined;
    }

    try {
      const products = await Promise.all(
        values.sectionValues.map(product =>
          saveProduct(product, existingProducts, warehouseId),
        ),
      );

      await Promise.all(
        existingProducts.map(async product => {
          const hasBeenRemoved = !values.sectionValues.some(
            value => value.id === product.id,
          );

          if (hasBeenRemoved) {
            await request(
              Api.V1.Suppliers.Warehouses.Products.destroy(product.id),
            );
          }
        }),
      );

      return { products, warehouse };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
