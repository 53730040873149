import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { DealKoReason } from '@advitam/api/models/Deal/KoReason';
import { DealState } from '@advitam/api/models/Deal/State';
import {
  Button,
  ContentModal,
  Input,
  ModalTitle,
  Radio,
  SubmitButton,
  Text,
} from '@advitam/ui';
import actionsMessages from 'messages/actions';

import { updateState } from './thunk';

import ReasonSpy from './ReasonSpy';
import messages from './messages';
import { KoReasonForm } from './types';
import style from './StateSelect.module.scss';

const COMMENT_FIELD_NAME = 'ko_comment';
const REASON_FIELD_NAME = 'ko_reason';

interface KoReasonModalProps {
  onClose: () => void;
}

export default function KoReasonModal({
  onClose,
}: KoReasonModalProps): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onSubmit = useCallback(
    (values: KoReasonForm): void => {
      onClose();
      dispatch(updateState({ state: DealState.KO, ...values }));
    },
    [dispatch, onClose],
  );

  return (
    <ContentModal
      isOpen
      onRequestClose={onClose}
      className={`col-xl-6 ${style.modal}`}
    >
      <Form<KoReasonForm> onSubmit={onSubmit}>
        {({ values, handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <ModalTitle>
              <FormattedMessage id={messages.title.id} />
            </ModalTitle>
            <Text>
              <FormattedMessage id={messages.text.id} />
            </Text>

            <div className={style.fields}>
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.unreachable.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.UNREACHABLE}
                required
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.duplicate.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.DUPLICATE}
                required
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.anticipation.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.ANTICIPATION}
                required
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.tooExpensive.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.TOO_EXPENSIVE}
                required
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.physicalStore.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.PHYSICAL_STORE}
                required
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.notProvidedService.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.NOT_PROVIDED_SERVICE}
                required
              />
              <Radio
                borderless
                className={style.input}
                label={<FormattedMessage id={messages.other.id} />}
                name={REASON_FIELD_NAME}
                value={DealKoReason.OTHER}
                required
              />
              {values.ko_reason === DealKoReason.OTHER && (
                <Input
                  required
                  className={style.input}
                  name={COMMENT_FIELD_NAME}
                  placeholder={intl.formatMessage(messages.toSpecify)}
                />
              )}
            </div>

            <div className={style.actions}>
              <Button
                outline
                text={<FormattedMessage id={actionsMessages.cancel.id} />}
                onClick={onClose}
              />
              <SubmitButton
                primary
                text={<FormattedMessage id={actionsMessages.confirm.id} />}
              />
            </div>

            <ReasonSpy />
          </form>
        )}
      </Form>
    </ContentModal>
  );
}
