import { LinksGroup } from '../../types'
import Group from './Group'
import style from './LinksGroups.module.scss'

interface GroupsColumnsProps {
  groups: LinksGroup[]
}

export default function GroupsColumns({ groups }: GroupsColumnsProps): JSX.Element {
  return (
    <>
      {groups.map((group, idx) => (
        <div key={idx} className={`col-4 col-md-2 col-xl-3 ${style.column}`}>
          <Group title={group.title} links={group.links} />
        </div>
      ))}
    </>
  )
}
