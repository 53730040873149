import { createSelector, Selector } from 'reselect';

import { SupplierCoverageJSON } from '@advitam/api/models/Supplier/Coverage';
import { AutocompleteResult } from '@advitam/api/v1/Autocompletes';

import { assert } from 'lib/support';

import { getPolygonLength } from '../utils';
import { MAP_SUPPLIERS } from './constants';
import { AppStateSubset, State } from './slice';
import { ActiveSupplierCoverage } from './types';

type MapSuppliersSelector<T> = Selector<AppStateSubset, T>;

const selectMapPrestationDomain = (state: AppStateSubset): State =>
  state[MAP_SUPPLIERS];

export const makeSelectActiveSuppliers = (): MapSuppliersSelector<
  AutocompleteResult[]
> =>
  createSelector(
    selectMapPrestationDomain,
    ({ activeSuppliers }) => activeSuppliers,
  );

export const makeSelectSuppliersCoverageById = (): MapSuppliersSelector<
  Record<number, SupplierCoverageJSON | null>
> =>
  createSelector(
    selectMapPrestationDomain,
    ({ suppliersCoverageById }) => suppliersCoverageById,
  );

export const makeSelectStoredCoverageIds = (): MapSuppliersSelector<number[]> =>
  createSelector(makeSelectSuppliersCoverageById(), suppliersCoverageById =>
    Object.keys(suppliersCoverageById).map(id => parseInt(id, 10)),
  );

function isValidActiveCoverage(
  coverage: SupplierCoverageJSON | null,
): coverage is ActiveSupplierCoverage {
  if (!coverage?.coverage) {
    return false;
  }

  assert(coverage.coverage.type === 'MultiPolygon');
  return coverage.coverage.type === 'MultiPolygon';
}

export const makeSelectActiveCoverages = (): MapSuppliersSelector<
  ActiveSupplierCoverage[]
> =>
  createSelector(
    makeSelectActiveSuppliers(),
    makeSelectSuppliersCoverageById(),
    (activeSuppliers, coveragesById) =>
      activeSuppliers
        .map(supplier => coveragesById[supplier.id])
        .filter(isValidActiveCoverage),
  );

export const makeSelectActiveCoveragesByAscLength = (): MapSuppliersSelector<
  ActiveSupplierCoverage[]
> =>
  createSelector(makeSelectActiveCoverages(), coverages =>
    coverages.sort(
      (a, b) => getPolygonLength(b.coverage) - getPolygonLength(a.coverage),
    ),
  );
