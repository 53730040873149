// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentList-module__container--qyMON{flex:1;overflow-y:auto}.DocumentList-module__list--xht1k{display:flex;flex-wrap:wrap;padding:0 24px 24px}`, "",{"version":3,"sources":["webpack://./src/containers/DocumentsEditor/DocumentList/DocumentList.module.scss"],"names":[],"mappings":"AAEA,uCACE,MAAA,CACA,eAAA,CAGF,kCACE,YAAA,CACA,cAAA,CACA,mBAAA","sourcesContent":["@import './vars.module';\n\n.container {\n  flex: 1;\n  overflow-y: auto;\n}\n\n.list {\n  display: flex;\n  flex-wrap: wrap;\n  padding: 0 24px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DocumentList-module__container--qyMON`,
	"list": `DocumentList-module__list--xht1k`
};
export default ___CSS_LOADER_EXPORT___;
