import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Toggle from 'components/Toggle';
import { isUserFuneralDirectorOrAbove } from 'components/Authorization/utils';

import messages from './messages';

class ManualMode extends PureComponent {
  render() {
    const { isManualMode, onChangeManualMode, intl, userRole } = this.props;
    if (!isUserFuneralDirectorOrAbove(userRole)) return null;

    return (
      <div className="manual-mode">
        <div className="text--white margin--0-10-0-0">
          {intl.formatMessage(messages.manualMode)}
        </div>
        <Toggle isToggled={isManualMode} onToggle={onChangeManualMode} />
      </div>
    );
  }
}

ManualMode.propTypes = {
  /** is manual mode on */
  isManualMode: PropTypes.bool.isRequired,
  /** function to change manual mode */
  onChangeManualMode: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  /** role of user */
  userRole: PropTypes.number.isRequired,
};

export default injectIntl(ManualMode);
