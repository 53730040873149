import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalTitle } from '@advitam/ui';

import { FormattedMessage } from 'react-intl';
import { makeSelectContentModalRecord } from './selectors';
import { closeContentModal } from './slice';
import { MODAL_TITLE } from './constants';
import messages from './messages';
import style from './History.module.scss';

export default function ContentModal(): JSX.Element | null {
  const dispatch = useDispatch();

  const communication = useSelector(makeSelectContentModalRecord());

  const onClose = useCallback(() => {
    dispatch(closeContentModal());
  }, [dispatch]);

  if (communication === null) {
    return null;
  }

  return (
    <Modal
      isOpen={communication !== null}
      onRequestClose={onClose}
      className={`new-design ${style.modal}`}
    >
      <ModalTitle>
        <FormattedMessage
          id={MODAL_TITLE[communication.channel_type] || messages.modalTitle.id}
        />
      </ModalTitle>
      <div
        className={`${style.modal_content} thin-scrollbar`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: communication.content || '' }}
      />
      <div className={style.modal_buttons}>
        <Button
          fixedSize
          primary
          onClick={onClose}
          text={<FormattedMessage id={messages.closeModal.id} />}
        />
      </div>
    </Modal>
  );
}
