import { authenticate } from '../../../lib/decorators'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { put } from '../../../lib/methods'
import type { ClientCompanyJSON } from '../../../models/Client/Company'

import { serialize } from './serializers'

export class Companies {
  @authenticate
  static update(
    clientId: number,
    companies: ClientCompanyJSON[],
  ): ApiRequestDescriptor<ClientCompanyJSON[]> {
    return put(`/api/v1/clients/${clientId}/companies`, serialize(companies))
  }
}
