import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { SupplierMinimalWarehouseJSON } from '@advitam/api/models/Supplier/Warehouse';

import { SUPPLIER_WAREHOUSES } from './constants';
import {
  destroyWarehouse,
  disableWarehouse,
  duplicateWarehouse,
  fetchWarehouses,
} from './thunk';
import type { Filters } from './types';

export interface State {
  warehouses: SupplierMinimalWarehouseJSON[];
  filters: Filters;
  currentPage: number;
  hasMore: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  destroyError: SerializedApiError | null;
  disableError: SerializedApiError | null;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [SUPPLIER_WAREHOUSES]: State;
}

export const initialState: State = {
  warehouses: [],
  filters: {},
  currentPage: 1,
  hasMore: true,
  isDeleting: false,
  isLoading: false,
  isSaving: false,
  destroyError: null,
  disableError: null,
  error: null,
};

const slice = createSlice({
  name: SUPPLIER_WAREHOUSES,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters(state, { payload }: PayloadAction<Filters>) {
      const { warehouses, currentPage, hasMore } = initialState;
      state.warehouses = warehouses;
      state.currentPage = currentPage;
      state.hasMore = hasMore;
      state.filters = payload;
    },
    resetDeletionErrors(state) {
      state.destroyError = null;
      state.disableError = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchWarehouses.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchWarehouses.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.warehouses.push(...payload.warehouses);
      state.currentPage += 1;
      state.hasMore = payload.hasMore;
    });
    builder.addCase(fetchWarehouses.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.hasMore = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(duplicateWarehouse.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(duplicateWarehouse.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.warehouses.push({
        id: payload.id,
        name: payload.name,
        department: payload.department || '',
        disabled: payload.disabled,
      });
    });
    builder.addCase(duplicateWarehouse.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyWarehouse.pending, state => {
      state.isDeleting = true;
    });
    builder.addCase(
      destroyWarehouse.fulfilled,
      (state, { meta: { arg: deletedId } }) => {
        state.isDeleting = false;
        state.warehouses = state.warehouses.filter(
          warehouse => warehouse.id !== deletedId,
        );
      },
    );
    builder.addCase(destroyWarehouse.rejected, (state, { payload }) => {
      state.isDeleting = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(disableWarehouse.pending, state => {
      state.isDeleting = true;
    });
    builder.addCase(
      disableWarehouse.fulfilled,
      (state, { meta: { arg: disabledId } }) => {
        const disabledWarehouseIdx = state.warehouses.findIndex(
          warehouse => warehouse.id === disabledId,
        );

        state.isDeleting = false;
        state.warehouses[disabledWarehouseIdx].disabled = true;
      },
    );
    builder.addCase(disableWarehouse.rejected, (state, { payload }) => {
      state.isDeleting = false;
      state.disableError = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters, resetDeletionErrors } = slice.actions;
export default slice;
