import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  ages: { id: 'app.containers.Crematorium.ages' },
  ceremonies: { id: 'app.containers.Crematorium.ceremonies' },
  commentary: { id: 'app.containers.Crematorium.commentary' },
  miscellaneous: { id: 'app.containers.Crematorium.miscellaneous' },
  general: { id: 'app.containers.Crematorium.general' },
  rooms: { id: 'app.containers.Crematorium.rooms' },
  titlePlaceholder: { id: 'app.containers.Crematorium.titlePlaceholder' },
  worship: { id: 'app.containers.Crematorium.worship' },
});
