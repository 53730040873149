import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';

import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';

import {
  renderStepDefaults,
  renderLocation,
  renderEditLocation,
} from './stepEditorsUtils';
import messages from './messages';
import Services from '../Services';

const locationTypes = [
  { value: 'Home', label: messages.home },
  { value: 'HealthFacility', label: messages.healthFacility },
  { value: 'Public', label: messages.public },
];

export default function Death({
  step,
  handleChange,
  stepServices,
  addService,
  removeService,
  updateDelegate,
  isManualMode,
  userRole,
  updateService,
}) {
  const form = useForm();
  const onDateChange = useCallback(
    update => {
      form.change('deal.defunctInfo.death_date', update.eventDate);
      handleChange(update);
    },
    [form, handleChange],
  );

  return (
    <div className="step">
      <div className="step__header">
        {renderStepDefaults(step, onDateChange)}
        {renderLocation(step.location, messages.location)}
        <CheckboxDelegate
          isDelegate={!!step.doneByAnotherFuneral}
          updateDelegate={updateDelegate}
        />
      </div>
      {renderEditLocation(step, handleChange, locationTypes)}
      <Services
        stepId={step.id}
        services={stepServices}
        addService={addService}
        removeService={removeService}
        isManualMode={isManualMode}
        userRole={userRole}
        updateService={updateService}
      />
    </div>
  );
}

Death.propTypes = {
  step: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  stepServices: PropTypes.array.isRequired,
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  updateDelegate: PropTypes.func.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
};
