import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { PrefectureJSON } from '@advitam/api/models/Prefecture';
import { saveEditableDocuments } from 'containers/Crud/Documents/Editable';
import { saveSupportingDocuments } from 'containers/Crud/Documents/Supporting';
import { assert } from 'lib/support';

import { PREFECTURE } from './constants';
import type { NewPrefecture } from './types';
import {
  createPrefecture,
  destroyPrefecture,
  fetchPrefecture,
  setIsPrefectureDisabled,
  updatePrefecture,
  updatePrefectureName,
} from './thunk';

export interface State {
  prefecture: PrefectureJSON | NewPrefecture | null;
  isLoading: boolean;
  isSaving: boolean;
  isDestroying: boolean;
  isUpdatingName: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [PREFECTURE]: State;
}

export const initialState: State = {
  prefecture: null,
  isLoading: false,
  isSaving: false,
  isDestroying: false,
  isUpdatingName: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: PREFECTURE,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearError(state) {
      state.error = null;
    },
    setPrefecture(
      state,
      { payload }: PayloadAction<PrefectureJSON | NewPrefecture | null>,
    ) {
      state.prefecture = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPrefecture.pending, state => {
      state.isLoading = true;
      state.destroyError = null;
    });
    builder.addCase(fetchPrefecture.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.prefecture = payload;
    });
    builder.addCase(fetchPrefecture.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createPrefecture.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(createPrefecture.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(createPrefecture.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updatePrefecture.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updatePrefecture.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.prefecture = payload;
    });
    builder.addCase(updatePrefecture.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updatePrefectureName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updatePrefectureName.fulfilled, (state, { payload }) => {
      state.isUpdatingName = false;
      state.prefecture = payload;
    });
    builder.addCase(updatePrefectureName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyPrefecture.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyPrefecture.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroyPrefecture.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setIsPrefectureDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setIsPrefectureDisabled.fulfilled, (state, { meta }) => {
      state.isSaving = false;
      assert(state.prefecture !== null);
      state.prefecture.disabled = meta.arg;
    });
    builder.addCase(setIsPrefectureDisabled.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveEditableDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveEditableDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveEditableDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveSupportingDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveSupportingDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveSupportingDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setPrefecture } = slice.actions;
export default slice;
