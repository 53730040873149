import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';

import { ButtonV2 as Button } from 'components/Button';
import messagesActions from 'messages/actions';

import messages from './messages';
import style from './ModalWarning.module.scss';

function ModalWarning({
  isModalOpen,
  closeModal,
  setResponseWithState,
  message,
  dismissMessage = messagesActions.cancel,
  acceptMessage = messagesActions.continue,
}) {
  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="dialog"
      onRequestClose={closeModal}
      ariaHideApp={false}
      className={`modal ${style.modal}`}
      overlayClassName={`modal__overlay ${style.overlay}`}
    >
      <FormattedMessage {...{ ...messages.warning, ...message }} />
      <div className={style.modal_warning}>
        <Button onClick={closeModal}>
          <FormattedMessage {...dismissMessage} />
        </Button>
        <Button onClick={setResponseWithState}>
          <FormattedMessage {...acceptMessage} />
        </Button>
      </div>
    </Modal>
  );
}

ModalWarning.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  setResponseWithState: PropTypes.func.isRequired,
  message: PropTypes.object,
  /** Message to be displayed on the dismiss button. Default: "cancel" */
  dismissMessage: PropTypes.object,
  /** Message to be displayed on the accept button. Default: "continue" */
  acceptMessage: PropTypes.object,
};

export default ModalWarning;
