import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';

import { DUPLICATION_BUTTON } from './constants';
import type { AppStateSubset, State } from './slice';

type DuplicationButtonSelector<T> = Selector<AppStateSubset, T>;

const selectDuplicationButtonDomain = (state: AppStateSubset): State =>
  state[DUPLICATION_BUTTON];

export const makeSelectIsConfirmationModalOpen = (): DuplicationButtonSelector<
  boolean
> =>
  createSelector(
    selectDuplicationButtonDomain,
    substate => substate.isConfirmationModalOpen,
  );

export const makeSelectIsLoading = (): DuplicationButtonSelector<boolean> =>
  createSelector(selectDuplicationButtonDomain, substate => substate.isLoading);

export const makeSelectError = (): DuplicationButtonSelector<SerializedApiError | null> =>
  createSelector(selectDuplicationButtonDomain, substate => substate.error);
