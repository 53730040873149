export function inCity(name: string): string {
  const downcasedName = name.toLowerCase()
  if (downcasedName.startsWith('le ')) {
    return name.replace(/^Le /i, 'au ')
  }

  if (downcasedName.startsWith("l'")) {
    return name.replace(/^L'/i, 'au ')
  }

  if (downcasedName.startsWith('les')) {
    return name.replace(/^Les /i, 'aux ')
  }

  return `à ${name}`
}
