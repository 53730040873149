import { authenticate } from '../../../../lib/decorators'
import { post } from '../../../../lib/methods'
import { ApiRequestDescriptor } from '../../../../lib/request'
import { DealDocumentBatchJSON } from '../../../../models/Deal/Document/Batch'

type AddDocumentBatch = Omit<DealDocumentBatchJSON, 'subject' | 'content'>

interface AddDocumentPayload {
  documents: unknown[]
  batches: AddDocumentBatch[]
}

export class AddDocument {
  @authenticate
  static create(
    dealUuid: string,
    batchId: number,
    documents: number[],
  ): ApiRequestDescriptor<AddDocumentPayload> {
    return post(`/api/v1/deals/${dealUuid}/documents/batches/${batchId}/add_document`, {
      documents,
    })
  }
}
