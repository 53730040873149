import CssTheme from './CssTheme'
import FontTheme from './FontTheme'

const Head = {
  CssTheme,
  FontTheme,
}

export default Head
export { CssTheme, FontTheme }
