import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { FuneralParlorJSON } from '@advitam/api/models/FuneralParlor';
import { saveEditableDocuments } from 'containers/Crud/Documents/Editable';
import { saveSupportingDocuments } from 'containers/Crud/Documents/Supporting';
import { assert } from 'lib/Assert';

import { FUNERAL_PARLOR } from './constants';
import type { NewFuneralParlor } from './types';
import {
  createFuneralParlor,
  destroyFuneralParlor,
  fetchFuneralParlor,
  setIsFuneralParlorDisabled,
  updateFuneralParlor,
  updateFuneralParlorName,
} from './thunk';

export interface State {
  funeralParlor: FuneralParlorJSON | NewFuneralParlor | null;
  isLoading: boolean;
  isSaving: boolean;
  isDestroying: boolean;
  isUpdatingName: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [FUNERAL_PARLOR]: State;
}

export const initialState: State = {
  funeralParlor: null,
  isLoading: false,
  isSaving: false,
  isDestroying: false,
  isUpdatingName: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: FUNERAL_PARLOR,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFuneralParlor(
      state,
      { payload }: PayloadAction<FuneralParlorJSON | NewFuneralParlor | null>,
    ) {
      state.funeralParlor = payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFuneralParlor.pending, state => {
      state.isLoading = true;
      state.destroyError = null;
    });
    builder.addCase(fetchFuneralParlor.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.funeralParlor = payload;
    });
    builder.addCase(fetchFuneralParlor.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createFuneralParlor.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(createFuneralParlor.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(createFuneralParlor.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateFuneralParlor.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateFuneralParlor.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.funeralParlor = payload;
    });
    builder.addCase(updateFuneralParlor.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateFuneralParlorName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updateFuneralParlorName.fulfilled, (state, { payload }) => {
      state.isUpdatingName = false;
      state.funeralParlor = payload;
    });
    builder.addCase(updateFuneralParlorName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyFuneralParlor.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyFuneralParlor.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroyFuneralParlor.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setIsFuneralParlorDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setIsFuneralParlorDisabled.fulfilled, (state, { meta }) => {
      state.isSaving = false;
      assert(state.funeralParlor !== null);
      state.funeralParlor.disabled = meta.arg;
    });
    builder.addCase(
      setIsFuneralParlorDisabled.rejected,
      (state, { payload }) => {
        state.isSaving = false;
        state.error = ApiError.serialize(payload);
      },
    );

    builder.addCase(saveEditableDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveEditableDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveEditableDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveSupportingDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveSupportingDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveSupportingDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setFuneralParlor } = slice.actions;
export default slice;
