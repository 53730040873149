import { get } from 'object-path'
import { useFormState } from 'react-final-form'
import { useMemo } from 'react'

import { Strings } from '@advitam/support'
import { useDeepCompareMemo } from '../../hooks/useDeepCompareMemo'

export function useFormValues<T>(names: string[]): T[] {
  const { values } = useFormState()

  const paths = useDeepCompareMemo(() => names.map(name => Strings.tokenizeJavascriptPath(name)), [
    names,
  ])
  const resolvedValues = useMemo(() => paths.map(path => get(values, path, null) as T), [
    values,
    paths,
  ])

  return resolvedValues
}
