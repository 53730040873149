import { createSlice } from '@reduxjs/toolkit';
import { DEAL_COMMENTARIES } from './constants';
import { updateCommentary } from './thunk';

export interface State {
  isLoading: boolean;
}

export interface AppStateSubset {
  [DEAL_COMMENTARIES]: State;
}

export const initialState: State = {
  isLoading: false,
};

const slice = createSlice({
  name: DEAL_COMMENTARIES,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(updateCommentary.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateCommentary.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateCommentary.rejected, state => {
      state.isLoading = false;
    });
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
