import { createSlice } from '@reduxjs/toolkit';
import { ApiError, SerializedApiError } from '@advitam/api';
import { LOGIN } from './constants';
import { login } from './thunk';

export interface State {
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [LOGIN]: State;
}

export const initialState: State = {
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: LOGIN,
  initialState,
  reducers: {},
  /* eslint-disable no-param-reassign */
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, state => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export default slice;
