import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  height: {
    id: 'app.containers.Deal.Sections.Todolist.List.DefunctSize.height',
  },
  size: {
    id: 'app.containers.Deal.Sections.Todolist.List.DefunctSize.size',
  },
  weight: {
    id: 'app.containers.Deal.Sections.Todolist.List.DefunctSize.weight',
  },
  width: {
    id: 'app.containers.Deal.Sections.Todolist.List.DefunctSize.width',
  },
});
