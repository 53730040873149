import { cloneDeep } from 'lodash';

import { PaymentMean } from '@advitam/api/models/Payment/Mean';
import type { MinimalFundingAgencyJSON } from '@advitam/api/models/FundingAgency';
import { PaymentReceived } from '@advitam/api/models/Payment/Received';
import { PaymentState } from '@advitam/api/models/Payment/State';

import { Civility } from '../Person';

export interface PaymentJSON {
  uuid?: string;
  mean: PaymentMean;
  amount: number;
  paid_at: number | null;
  first_name: string | null;
  last_name: string | null;
  company_name: string | null;
  is_company: boolean;
  state: PaymentState;
  pay_id: string;
  email: string | null;
  civ: Civility | null;
  street_name: string | null;
  street_number: string | null;
  city: string | null;
  postal_code: string | null;
  country: string | null;
  check_no: string | null;
  funding_agency: MinimalFundingAgencyJSON | null;
  contract_no: string | null;
  received: PaymentReceived | null;
  parent_uuid: string | null;
}

const skeleton: PaymentJSON = {
  mean: PaymentMean.CHECK,
  amount: 0,
  paid_at: null,
  first_name: '',
  last_name: '',
  company_name: null,
  is_company: false,
  state: PaymentState.WAITING,
  pay_id: '',
  email: null,
  civ: null,
  street_name: null,
  street_number: null,
  city: null,
  postal_code: null,
  country: null,
  check_no: null,
  funding_agency: null,
  contract_no: null,
  received: PaymentReceived.NO,
  parent_uuid: null,
};

export class Payment {
  static get skeleton(): PaymentJSON {
    return cloneDeep(skeleton);
  }
}
