import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import { makeSelectUser } from 'slices/auth';
import AutoComplete from 'containers/AutoComplete';
import { SelectList } from 'components/Input';
import {
  makeSelectProducts,
  makeSelectDealByType,
  makeSelectDealType,
} from 'containers/Deal/selectors';
import { setProducts as setProductsDispatch } from 'containers/Deal/Products/actions';
import Products from 'components/Products';
import messages from 'components/Products/messages';
import messagesFamily from 'messages/families';

import {
  getEstimationData as getEstimationDataAction,
  addProductEstimation,
} from './actions';
import { addProduct, updateProduct } from './thunk.ts';

function ProductsSection({
  products,
  getEstimationData,
  postalCodes,
  dealUUID,
  isManualMode,
  setProducts,
  availableCategories,
}) {
  const dispatch = useDispatch();

  /** Is dialog opened or closed */
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [filteredCategory, setFilteredCategory] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const user = useSelector(makeSelectUser());

  const onSelect = item => {
    if (item.estimation) {
      getEstimationData(item.id);
    } else {
      dispatch(addProduct(item.id));
    }
  };

  const createQueryParamsProductAutocomplete = () => {
    if (Object.keys(postalCodes).length === 0) return {};
    const itemGroups = {};
    products.forEach(product => {
      itemGroups[product.group_name] = itemGroups[product.group_name] || [];
      if (
        Object.keys(product.productObject).length !== 0 &&
        product.productObject.product_id !== null &&
        product.productObject.product_id !== undefined
      ) {
        itemGroups[product.group_name].push(product.productObject.product_id);
      }
    });
    return {
      group_items: itemGroups,
      postal_codes: postalCodes,
    };
  };

  useEffect(() => {
    const locationFilters = createQueryParamsProductAutocomplete();
    setQueryParams({ ...queryParams, ...locationFilters });
  }, [postalCodes, products]);

  const updateFilterCategory = value => {
    setFilteredCategory(value);
    setQueryParams({ ...queryParams, product_group_name_eq: value });
  };

  /**
   * Function to toggle Dialog
   */
  const toggleDialog = () => setDialogOpen(!isDialogOpen);
  // Category type we want
  const productsCategory = ['funeral', 'marble', 'flowers'].map(group => ({
    value: group,
    message: messagesFamily[group],
  }));

  productsCategory.unshift({
    value: '',
    message: messages.noFilter,
  });

  const formattedCategories = () => {
    const productCategories = availableCategories.map(group => ({
      value: group,
      message: messagesFamily[group],
    }));

    productCategories.unshift({
      value: '',
      message: messages.noFilter,
    });

    return productCategories;
  };

  return (
    <section id="products" className="products">
      <div className="products__header">
        <h2>
          <FormattedMessage {...messages.productsSection} />
        </h2>
        <div className="flex margin--10-0">
          <AutoComplete
            url="/api/v1/autocompletes/suppliers/warehouses/products?q="
            dealUUID={dealUUID}
            getKey={item => item.id}
            getValue={item => `${item.description}`}
            onSelect={(_, item) => onSelect(item)}
            paramsToAddToQuery={queryParams}
            auth
          />
          {availableCategories && (
            <SelectList
              name="filter"
              options={formattedCategories()}
              value={filteredCategory}
              onChange={({ target: { value } }) => updateFilterCategory(value)}
            />
          )}
          {isManualMode && (
            <button
              className="products__header__add"
              onClick={toggleDialog}
              type="button"
            >
              +
            </button>
          )}
        </div>
      </div>
      <div className="products__list">
        <Products
          products={products}
          isDialogOpen={isDialogOpen}
          toggleDialog={toggleDialog}
          isManualMode={isManualMode}
          setProducts={setProducts}
          userRole={user.role}
          getProduct={id => {
            dispatch(updateProduct(id));
          }}
        />
      </div>
    </section>
  );
}

ProductsSection.propTypes = {
  // Is set add a list families categories to filter with
  availableCategories: PropTypes.array,
  /** list of products */
  products: PropTypes.array.isRequired,
  /** Get details of a product estimation */
  getEstimationData: PropTypes.func.isRequired,
  /** postal codes in deal to get products */
  postalCodes: PropTypes.object,
  /** uuid of deal */
  dealUUID: PropTypes.string.isRequired,
  /** is the manual mode on */
  isManualMode: PropTypes.bool.isRequired,
  /** Function to set products in store */
  setProducts: PropTypes.func.isRequired,
  /** deal type */
  dealType: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  products: makeSelectProducts(),
  deal: makeSelectDealByType(),
  dealType: makeSelectDealType(),
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { deal, products, dealType } = stateProps;
  const { dispatch } = dispatchProps;

  const addProductEstimationAction = product =>
    addProductEstimation(products, product, deal, dealType);
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getEstimationData: estimationId =>
      dispatch(
        getEstimationDataAction(estimationId, addProductEstimationAction),
      ),
    setProducts: productsUpdated =>
      dispatch(setProductsDispatch(productsUpdated, deal, dealType)),
  };
}

export default connect(mapStateToProps, null, mergeProps)(ProductsSection);
