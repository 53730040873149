import { ReactNode } from 'react'
import { FlatButton } from '../Button'
import Tag from './Tag'
import style from './Tag.module.scss'

interface ActionTagProps {
  children: ReactNode | ReactNode[]
  icon: ReactNode
  className?: string
  onClick: () => void
}

export default function ActionTag({
  children,
  icon,
  className,
  onClick,
}: ActionTagProps): JSX.Element {
  return (
    <Tag className={[style.action, className].filter(Boolean).join(' ')}>
      {children}
      <FlatButton onClick={onClick}>{icon}</FlatButton>
    </Tag>
  )
}
