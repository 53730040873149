import type { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import messages from './messages'
import style from './Map.module.scss'

function setCookiesLink(chunk: ReactNode): ReactNode {
  return <a href="#cookies">{chunk}</a>
}

export default function RestrictedMap(): JSX.Element {
  const classes = [style.container, style.restricted]
  return (
    <div className={classes.join(' ')}>
      <FormattedMessage
        id={messages.cookiesRequired.id}
        values={{ a: setCookiesLink }}
        tagName="span"
      />
    </div>
  )
}
