import { ConsulateJSON } from '@advitam/api/models/Consulate';
import { formatEntity } from '../Entity';

export function formatName(consulate: ConsulateJSON): string {
  return formatEntity(consulate);
}

export function FormattedName(consulate: ConsulateJSON): JSX.Element {
  return <>{formatName(consulate)}</>;
}
