import { createSelector, Selector } from 'reselect';
import {
  makeSelectUser,
  AppStateSubset as AuthAppStateSubset,
} from 'slices/auth';
import { Role } from '@advitam/api/models/BusinessUser/Role';

import { makeSelectFlight } from '../../selectors';
import type { AppStateSubset as FlightAppStateSubset } from '../../slice';

type AppStateSubset = FlightAppStateSubset & AuthAppStateSubset;

export const makeSelectCanEdit = (
  actionRole?: Role,
): Selector<AppStateSubset, boolean> =>
  createSelector(makeSelectFlight(), makeSelectUser(), (flight, user) => {
    if (!flight?.id) return true;

    switch (actionRole) {
      case Role.FUNERAL_DIRECTOR:
        return Boolean(user?.isFuneralDirectorOrAbove);
      case Role.ADMIN:
        return Boolean(user?.isAdmin);
      default:
        return false;
    }
  });
