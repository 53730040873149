import { createSelector, Selector } from 'reselect';

import { Prefecture, PrefectureJSON } from '@advitam/api/models/Prefecture';
import type { SerializedApiError } from '@advitam/api';

import { PREFECTURE } from './constants';
import type { AppStateSubset, State } from './slice';
import { NewPrefecture } from './types';

type PrefectureSelector<T> = Selector<AppStateSubset, T>;

const selectPrefectureDomain = (state: AppStateSubset): State =>
  state[PREFECTURE];

export const makeSelectRawPrefecture = (): PrefectureSelector<
  PrefectureJSON | NewPrefecture | null
> => createSelector(selectPrefectureDomain, ({ prefecture }) => prefecture);

export const makeSelectPrefecture = (): PrefectureSelector<Prefecture | null> =>
  createSelector(makeSelectRawPrefecture(), rawPrefecture =>
    rawPrefecture?.id ? new Prefecture(rawPrefecture) : null,
  );

export const makeSelectIsLoading = (): PrefectureSelector<boolean> =>
  createSelector(selectPrefectureDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): PrefectureSelector<boolean> =>
  createSelector(selectPrefectureDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): PrefectureSelector<boolean> =>
  createSelector(selectPrefectureDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): PrefectureSelector<boolean> =>
  createSelector(
    selectPrefectureDomain,
    ({ isUpdatingName }) => isUpdatingName,
  );

export const makeSelectError = (): PrefectureSelector<SerializedApiError | null> =>
  createSelector(selectPrefectureDomain, ({ error }) => error);

export const makeSelectDestroyError = (): PrefectureSelector<SerializedApiError | null> =>
  createSelector(selectPrefectureDomain, ({ destroyError }) => destroyError);
