import { Browsers } from '@advitam/support'
import type { CompanyMinimalJSON } from '../models/Company/Minimal'

const COMPANY_STORE = '_company'
export const HEADER_NAME = 'X-Company-Id'

export function destroy(): void {
  if (process.browser) {
    Browsers.storage.removeItem(COMPANY_STORE)
  }
}

export function set(company: CompanyMinimalJSON): void {
  if (process.browser) {
    Browsers.storage.setItem(COMPANY_STORE, JSON.stringify(company))
  }
}

export function get(): CompanyMinimalJSON | undefined {
  if (!process.browser) {
    return undefined
  }

  const company = Browsers.storage.getItem(COMPANY_STORE)
  if (!company) {
    return undefined
  }
  return JSON.parse(company) as CompanyMinimalJSON
}

export function isSet(): boolean {
  return get() !== undefined
}
