import { createSlice } from '@reduxjs/toolkit';
import { BusinessUserJSON } from '@advitam/api/models/User/BusinessUser';
import { AUTH } from './constants';
import { logout, onLoginSuccess } from './thunk';

export interface State {
  user: BusinessUserJSON | null;
  isAuthenticated: boolean;
}

export interface AppStateSubset {
  [AUTH]: State;
}

export const initialState: State = {
  user: null,
  isAuthenticated: false,
};

const slice = createSlice({
  name: AUTH,
  initialState,
  reducers: {},
  /* eslint-disable no-param-reassign */
  extraReducers: builder => {
    builder.addCase(onLoginSuccess.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isAuthenticated = true;
    });

    builder.addCase(logout.fulfilled, state => {
      state.isAuthenticated = false;
    });
  },
  /* eslint-enable no-param-reassign */
});

export default slice;
