import qs from 'qs'

import { ApiRequestDescriptor, ApiPayload } from './request'

function formatEndpointWithQuery(endpoint: string, query?: unknown): string {
  const queryString = query ? qs.stringify(query, { arrayFormat: 'brackets' }) : null

  return [endpoint, queryString].filter(Boolean).join('?')
}

export function get<ResponseType, QueryType = unknown>(
  endpoint: string,
  query?: QueryType,
): ApiRequestDescriptor<ResponseType, undefined> {
  return {
    method: 'GET',
    endpoint: formatEndpointWithQuery(endpoint, query),
    headers: {},
  }
}

export function post<ResponseType, PayloadType extends ApiPayload>(
  endpoint: string,
  payload?: PayloadType,
): ApiRequestDescriptor<ResponseType, PayloadType> {
  return { method: 'POST', endpoint, payload, headers: {} }
}

export function put<ResponseType, PayloadType extends ApiPayload>(
  endpoint: string,
  payload?: PayloadType,
): ApiRequestDescriptor<ResponseType, PayloadType> {
  return { method: 'PUT', endpoint, payload, headers: {} }
}

export function del<ResponseType, QueryType = unknown>(
  endpoint: string,
  query?: QueryType,
): ApiRequestDescriptor<ResponseType, undefined> {
  return {
    method: 'DELETE',
    endpoint: formatEndpointWithQuery(endpoint, query),
    headers: {},
  }
}
