import { ReactNode, HTMLAttributes, MouseEvent } from 'react'

export interface DefaultWrapperProps {
  children: ReactNode | ReactNode[]
  target?: HTMLAnchorElement['target']
  rel?: HTMLAnchorElement['rel']
  href: string
  className?: string
  property?: HTMLAttributes<unknown>['property']
  typeof?: HTMLAttributes<unknown>['typeof']
  onClick?: (event: MouseEvent<HTMLElement>) => void
  state?: unknown
}

export default function DefaultWrapper({
  href,
  target,
  rel,
  className,
  typeof: attrTypeof,
  property,
  children,
  onClick,
}: DefaultWrapperProps): JSX.Element {
  return (
    <a
      tabIndex={0}
      role="link"
      href={href}
      rel={rel}
      property={property}
      typeof={attrTypeof}
      className={className}
      target={target}
      onClick={onClick}
    >
      {children}
    </a>
  )
}
