type SafeContextApis = 'clipboard' | 'serviceWorker'

export interface UnsafeContextNavigator extends Omit<Navigator, SafeContextApis> {
  readonly serviceWorker: ServiceWorkerContainer | undefined
  readonly clipboard: Clipboard | undefined
}

// TODO: Should be impleted with a type override
export function unsafeContext(): UnsafeContextNavigator {
  // eslint-disable-next-line no-restricted-globals
  return navigator as UnsafeContextNavigator
}
