import { DateTime } from 'luxon'
import { DATE_FORMAT, HOUR_FORMAT } from './constants'

function formatValue(format: string, date: string | undefined): string {
  const parsedDate = date && DateTime.fromISO(date)

  if (parsedDate && parsedDate?.isValid) {
    return parsedDate.toFormat(format)
  }

  return ''
}

export function getInitialDateValue(date: string | undefined): string {
  return formatValue(DATE_FORMAT, date)
}

export function getInitialHourValue(date: string | undefined): string {
  return formatValue(HOUR_FORMAT, date)
}

export function isDateValueValid(dateValue: string): boolean {
  return !dateValue || dateValue.length === DATE_FORMAT.length
}

export function isHourValueValid(hourValue: string): boolean {
  return !hourValue || hourValue.length === HOUR_FORMAT.length
}
