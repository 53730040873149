import { assert, isNil } from '@advitam/support'

import { ValidatorReturnType } from './Validator'
import { ValueType } from './ValueType'

const ERROR = 'not_image'
const IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/webp']

export function isImage(value: ValueType | Blob): ValidatorReturnType {
  assert(isNil(value) || value instanceof Blob, 'isImage validator may only be used on file inputs')

  if (!value || IMAGE_MIME_TYPES.includes(value.type)) {
    return undefined
  }

  return ERROR
}
