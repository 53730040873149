import { ReactNode, useCallback } from 'react'

import ApiAutocomplete from '../ApiAutocomplete'
import type { DocumentTypeAutocompleteResult } from './types'

interface DocumentTypeAutocompleteProps {
  placeholder?: string
  required?: boolean
  label?: ReactNode
  tooltip?: ReactNode
  name: string
  endpoint: string
  className?: string
  tokens: Record<string, string>
  searchParams?: Record<string, string | string[]>
  disabled?: boolean
}

export default function DocumentTypeAutocomplete({
  placeholder,
  name,
  label,
  tooltip,
  endpoint,
  required,
  tokens,
  searchParams,
  disabled,
  className,
}: DocumentTypeAutocompleteProps): JSX.Element {
  const getDisplayValue = useCallback(
    (result: DocumentTypeAutocompleteResult): string => result.pretty_name,
    [],
  )

  return (
    <ApiAutocomplete<DocumentTypeAutocompleteResult>
      placeholder={placeholder}
      name={name}
      label={label}
      tooltip={tooltip}
      endpoint={endpoint}
      required={required}
      className={className}
      disabled={disabled}
      getDisplayValue={getDisplayValue}
      keys={['description', 'pretty_name', 'slug', 'tags']}
      requestHeaders={tokens}
      searchParams={searchParams}
    />
  )
}
