import { BookingStatus } from 'models/BookingStatus';

/** @TODO replace with real prestation model once it's defined */
export interface PartialPrestation {
  booking_status: BookingStatus;
}

const STATUS_IMPORTANCE_ORDER = [
  BookingStatus.REJECTED,
  BookingStatus.CANCELLED,
  BookingStatus.NONE,
  BookingStatus.PENDING,
  BookingStatus.ACCEPTED,
  BookingStatus.BOOKED,
];

function sortPrestations(
  prestations: PartialPrestation[],
): PartialPrestation[] {
  return prestations.sort(
    (a, b) =>
      STATUS_IMPORTANCE_ORDER.indexOf(a.booking_status) -
      STATUS_IMPORTANCE_ORDER.indexOf(b.booking_status),
  );
}

export function computeBookingStatus(
  prestations: PartialPrestation[],
): BookingStatus {
  if (prestations.length === 0) {
    return BookingStatus.BOOKED;
  }

  const sortedPrestations = sortPrestations(prestations);
  return sortedPrestations[0].booking_status;
}
