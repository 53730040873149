import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import Fuse from 'fuse.js';

import { SupplierWarehouseZoneFuneralWorkJSON } from '@advitam/api/models/Supplier/Warehouse';
import { ResourceList } from '@advitam/ui';

import { makeSelectFilters, makeSelectIsLoading } from '../selectors';
import {
  FuneralWorksFilters,
  UnsavedFuneralWork,
  WarehouseZoneForm,
} from '../types';
import ZoneHeader from './Header';
import ZoneRow from './Row';
import ZoneFilters from './Filters';

function getFilteredIndexes(
  funeralWorks: Array<
    SupplierWarehouseZoneFuneralWorkJSON | UnsavedFuneralWork
  >,
  filters: FuneralWorksFilters,
): number[] {
  const fuse = new Fuse(funeralWorks, {
    keys: [
      'funeral_work_type.description',
      'funeral_work_type.category.description',
    ],
  });

  const fuseItems =
    filters.q && fuse.search(filters.q).map(result => result.item);

  return funeralWorks.reduce<number[]>((prev, curr, idx) => {
    const { funeral_work_type: funeralWorkType } = curr;
    const { category_id_eq: categoryId } = filters;

    if (curr.id && categoryId && funeralWorkType?.category.id !== categoryId) {
      return prev;
    }

    if (fuseItems && !fuseItems.includes(curr)) {
      return prev;
    }

    return [...prev, idx];
  }, []);
}

export default function ZoneList(): JSX.Element {
  const filters = useSelector(makeSelectFilters());
  const isLoading = useSelector(makeSelectIsLoading());
  const { values } = useFormState<WarehouseZoneForm>();

  const funeralWorks = values.sectionValues;
  const filteredIndexes = useMemo(
    () => getFilteredIndexes(funeralWorks, filters),
    [funeralWorks, filters],
  );

  return (
    <ResourceList.Container
      hasMore={false}
      header={<ZoneHeader />}
      filters={<ZoneFilters />}
      isLoading={isLoading}
      fetchResources={(): void => {
        // stub to prevent empty error
      }}
    >
      {funeralWorks.map(
        (funeralWork, idx) =>
          filteredIndexes.includes(idx) && (
            <ZoneRow
              // eslint-disable-next-line react/no-array-index-key
              key={`${funeralWork.id || ''}-${idx}`}
              funeralWork={funeralWork}
              funeralWorkIdx={idx}
            />
          ),
      )}
    </ResourceList.Container>
  );
}
