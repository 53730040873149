import { FormattedMessage } from 'react-intl';

import { ResourceList } from '@advitam/ui';

import messages from '../messages';
import style from '../DealsList.module.scss';

export default function DealHeader(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell>
        <FormattedMessage id={messages.state.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.defunctName.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.clientName.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.businessId.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.invoiceBusinessId.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile>
        <FormattedMessage id={messages.price.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.paid.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.advisor.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
