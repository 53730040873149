// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.File-module__wrapper--pRZPd{cursor:pointer}.File-module__input_wrapper--Y7aMK{position:relative;width:100%}.File-module__input_wrapper--Y7aMK input{width:100%;padding-right:48px}.File-module__icon--fiNIg{position:absolute;top:2px;right:6px;width:20px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./../ui/components/Form/UI/File/File.module.scss"],"names":[],"mappings":"AAAA,6BACE,cAAA,CAGF,mCACE,iBAAA,CACA,UAAA,CAEA,yCACE,UAAA,CACA,kBAAA,CAIJ,0BACE,iBAAA,CACA,OAAA,CACA,SAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".wrapper {\n  cursor: pointer;\n}\n\n.input_wrapper {\n  position: relative;\n  width: 100%;\n\n  input {\n    width: 100%;\n    padding-right: 48px;\n  }\n}\n\n.icon {\n  position: absolute;\n  top: 2px;\n  right: 6px;\n  width: 20px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `File-module__wrapper--pRZPd`,
	"input_wrapper": `File-module__input_wrapper--Y7aMK`,
	"icon": `File-module__icon--fiNIg`
};
export default ___CSS_LOADER_EXPORT___;
