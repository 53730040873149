import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Tippy from '@tippyjs/react';

import { NEW_PATH } from 'containers/App/constants';
import { ButtonV2 as Button } from 'components/Button/index.js';
import Spinner from 'components/Spinner/index.js';
import ErrorModal from 'components/ErrorModal';
import RefreshIcon from 'images/icons/refresh.svg';

import DocumentList from './DocumentList';
import Toolbar from './Toolbar';
import { makeSelectLoading, makeSelectError } from './selectors.js';
import * as actions from './actions.js';
import messages from './messages.js';

interface DocumentsProps {
  dealUUID: string;
}

export default function Documents({ dealUUID }: DocumentsProps): JSX.Element {
  const dispatch = useDispatch();
  const loading = useSelector(makeSelectLoading());
  const error = useSelector(makeSelectError());

  const fetchDocumentList = useCallback((): void => {
    if (dealUUID && dealUUID !== NEW_PATH) {
      dispatch(actions.fetchDocuments(dealUUID));
    }
  }, [dealUUID, dispatch]);

  useEffect(fetchDocumentList, []);

  return (
    <section id="documents">
      {error && <ErrorModal error={error} />}
      <div className="fullWidth">
        <div className="documents__content__header">
          <div className="documents__content__header__containerRefresh">
            {loading && <Spinner />}
            <Tippy
              content={<FormattedMessage id={messages.tooltipRefresh.id} />}
            >
              <Button onClick={fetchDocumentList} className="margin--0-10">
                <RefreshIcon />
              </Button>
            </Tippy>
          </div>
        </div>
        <div className="margin--10-0">
          <Toolbar dealUUID={dealUUID} />
          <DocumentList
            loading={loading}
            error={error}
            refreshDocumentList={fetchDocumentList}
            dealUUID={dealUUID}
          />
        </div>
      </div>
    </section>
  );
}
