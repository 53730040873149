import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { SupplierSummaryJSON } from '@advitam/api/models/Supplier/Summary';
import { SuppliersIndexFilters } from '@advitam/api/v1/Suppliers';
import type { FilterItem } from '@advitam/ui/components/ResourceList/Filters/types';

import {
  makeSelectUser,
  AppStateSubset as AuthAppStateSubset,
} from 'slices/auth';

import {
  makeSelectDepartments,
  AppStateSubset as DatasetAppStateSubset,
} from 'slices/data';

import { SUPPLIERS } from './constants';
import { AppStateSubset, State } from './slice';

type SuppliersSelectors<T> = Selector<
  AppStateSubset & AuthAppStateSubset & DatasetAppStateSubset,
  T
>;

const selectSuppliersDomain = (state: AppStateSubset): State =>
  state[SUPPLIERS];

/** Dot not use this selector, it is exported for testing purpose only */
export const makeSelectSuppliers = (): SuppliersSelectors<
  SupplierSummaryJSON[]
> => createSelector(selectSuppliersDomain, ({ suppliers }) => suppliers);

export const makeSelectAllowedSuppliers = (): SuppliersSelectors<
  SupplierSummaryJSON[]
> =>
  createSelector(makeSelectSuppliers(), makeSelectUser(), (suppliers, user) =>
    suppliers.filter(
      supplier =>
        user?.isFuneralDirectorOrAbove ||
        (!supplier.disabled && !supplier.negociated),
    ),
  );

export const makeSelectDepartmentsOptions = (): SuppliersSelectors<
  FilterItem[]
> =>
  createSelector(makeSelectDepartments(), departments =>
    departments
      .map(dpt => ({
        name: dpt.description,
        value: dpt.department_code,
      }))
      .sort((dpt1, dpt2) => dpt1.name.localeCompare(dpt2.name)),
  );

export const makeSelectFilters = (): SuppliersSelectors<
  SuppliersIndexFilters
> => createSelector(selectSuppliersDomain, ({ filters }) => filters);

export const makeSelectCurrentPage = (): SuppliersSelectors<number> =>
  createSelector(selectSuppliersDomain, ({ currentPage }) => currentPage);

export const makeSelectHasMore = (): SuppliersSelectors<boolean> =>
  createSelector(selectSuppliersDomain, ({ hasMore }) => hasMore);

export const makeSelectIsLoading = (): SuppliersSelectors<boolean> =>
  createSelector(selectSuppliersDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): SuppliersSelectors<SerializedApiError | null> =>
  createSelector(selectSuppliersDomain, ({ error }) => error);
