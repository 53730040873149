import { ReactNode } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import Container from '../Container'
import { PageSpinner } from '../Spinner'
import { useThresholdSize } from './useThresholdSize'
import style from './ResourceList.module.scss'

interface ContainerProps {
  children: ReactNode | ReactNode[]
  hasMore: boolean
  header: ReactNode
  isLoading: boolean
  className?: string
  error?: ReactNode
  filters?: ReactNode
  initialLoad?: boolean
  useWindow?: boolean
  fetchResources: () => void
}

export default function ResourceListContainer({
  children,
  hasMore,
  header,
  isLoading,
  className = '',
  error,
  filters,
  initialLoad = false,
  useWindow = false,
  fetchResources,
}: ContainerProps): JSX.Element {
  const mobileBreakpoint = parseInt(style.sm, 10)
  const threshold = useThresholdSize(mobileBreakpoint)
  const classes = [style.list, className].filter(Boolean)

  const loadMore = (): void => {
    if (!isLoading) {
      fetchResources()
    }
  }

  return (
    <>
      <Container>{filters}</Container>
      <InfiniteScroll
        hasMore={hasMore}
        loadMore={loadMore}
        initialLoad={initialLoad}
        threshold={threshold}
        useWindow={useWindow}
      >
        <Container>
          <div className={classes.join(' ')}>
            {header}
            {children}
          </div>
          {isLoading && <PageSpinner />}
          {error}
        </Container>
      </InfiniteScroll>
    </>
  )
}
