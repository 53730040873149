import { SuppliersIndexFilters } from '@advitam/api/v1/Suppliers';

export const SUPPLIERS = 'Suppliers';

export const PrestationType = {
  TRANSPORT: 'transport',
  MARBLE: 'marble',
  PAPERWORKS: 'paperworks',
  COFFINS: 'coffins',
};

export const SUPPLIERS_PER_PAGE = 50;

export const DEFAULT_FILTERS: SuppliersIndexFilters = {
  per_page: SUPPLIERS_PER_PAGE,
  page: 1,
};
