import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  modalTitle: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.modalTitle',
  },
  laterThisDay: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.laterThisDay',
  },
  tomorrow: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.tomorrow',
  },
  nextWeek: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.nextWeek',
  },
  nextMonth: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.nextMonth',
  },
  anotherDate: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.anotherDate',
  },
  cancel: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.cancel',
  },
  submit: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.submit',
  },
  scheduleReminderTooltip: {
    id:
      'app.containers.Deal.Sections.Home.ReminderButton.scheduleReminderTooltip',
  },
  reminderTooltip: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.reminderTooltip',
  },
  valueMustBeFuture: {
    id: 'app.containers.Deal.Sections.Home.ReminderButton.valueMustBeFuture',
  },
});
