import { MemoryStorage } from './MemoryStorage'

let memoryStorage: MemoryStorage | null = null
let isLocalStorageEnabled: boolean | null = null

/* eslint-disable no-restricted-globals */
function detectLocalStorage(): void {
  const testKey = '__ls_storage_detection_test'

  try {
    localStorage.setItem(testKey, '1')
    localStorage.removeItem(testKey)
    isLocalStorageEnabled = true
  } catch {
    isLocalStorageEnabled = false
    memoryStorage = new MemoryStorage()
  }
}

export function getStorage(): Storage | MemoryStorage {
  if (isLocalStorageEnabled === null) {
    detectLocalStorage()
  }

  return memoryStorage || localStorage
}
/* eslint-enable no-restricted-globals */
