import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CheckIcon from 'images/check.svg';

export class Toggle extends PureComponent {
  /**
   * Function to toggle the value
   */
  handleChange = () => {
    const { isToggled, onToggle } = this.props;
    onToggle(!isToggled);
  };

  render() {
    const { isToggled } = this.props;

    return (
      <div className="toggle">
        <div
          className="toggle__switch"
          onClick={this.handleChange}
          tabIndex={0}
          role="button"
        >
          <input
            type="checkbox"
            value={isToggled}
            checked={isToggled}
            readOnly
          />
          <div className="toggle__switch__slider toggle__switch__slider--orange">
            {isToggled && (
              <div className="toggle__switch__slider__checkmark">
                <CheckIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Toggle.propTypes = {
  /** is the input toggled */
  isToggled: PropTypes.bool.isRequired,
  /** function to update the value */
  onToggle: PropTypes.func.isRequired,
};

export default Toggle;
