import { DateTime } from 'luxon';

import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';
import type { EntityInvoicePrestationJSON } from '@advitam/api/models/EntityInvoice/Prestation';
import { EntityInvoiceGroupType } from '@advitam/api/models/EntityInvoice/Group/Type';
import { EntityInvoiceDeal } from '@advitam/api/models/EntityInvoice/Deal';
import { assert } from '@advitam/support';

function prestationDate(prestation: EntityInvoicePrestationJSON): string {
  const deal = new EntityInvoiceDeal(prestation.deal);
  // TODO: Settings.throwOnInvalid = true;
  const date = DateTime.fromJSDate(deal.ceremony_dt).toISO();
  assert(date !== null);
  return date;
}

export function groupDate(
  invoice: EntityInvoiceJSON,
  prestation: EntityInvoicePrestationJSON | null,
): string {
  const { group } = invoice;
  const { year } = group;

  switch (invoice.group.type) {
    case EntityInvoiceGroupType.MONTH: {
      // TODO: Settings.throwOnInvalid = true;
      const date = DateTime.fromObject({ year, month: group.id + 1 }).toISO();
      assert(date !== null);
      return date;
    }
    case EntityInvoiceGroupType.QUARTER: {
      // TODO: Settings.throwOnInvalid = true;
      const date = DateTime.fromObject({
        year,
        month: group.id * 3 + 1,
      }).toISO();
      assert(date !== null);
      return date;
    }
    case EntityInvoiceGroupType.PRESTATION:
    case EntityInvoiceGroupType.DEAL:
      return prestationDate(prestation || invoice.prestations[0]);
    default: {
      // TODO: Settings.throwOnInvalid = true;
      const now = DateTime.now().toISO();
      assert(now !== null);
      return now;
    }
  }
}

export function groupId(
  date: string | null,
  groupType: EntityInvoiceGroupType,
): number | null {
  if (!date) {
    return null;
  }

  switch (groupType) {
    case EntityInvoiceGroupType.MONTH:
      return DateTime.fromISO(date).month - 1;
    case EntityInvoiceGroupType.QUARTER:
      return (DateTime.fromISO(date).month - 1) / 3;
    case EntityInvoiceGroupType.PRESTATION:
      return DateTime.fromISO(date).ordinal * 1000;
    default:
      return null;
  }
}
