import { call, put, takeLatest } from 'redux-saga/effects';

import Api, { request } from 'api';
import { requestWithAuth } from 'utils/request';
import { error } from 'containers/Deal/actions';

import { fetchDocuments } from 'containers/Documents/sagas';
import { getBookingInfo } from 'containers/Deal/sagas';
import { DealParentType } from 'models/Deal/index.ts';

import { fetchPaymentsDetails } from '../Payments/thunk.ts';
import {
  GET_DELIVERY_LOCATIONS,
  GET_PRODUCT,
  FETCH_DEAL_DETAILS,
  SET_PARENT_DEAL,
} from './constants.ts';
import { setDeliveryLocations } from './DeliverySection/actions';
import { setParent, setParentInDeal } from './actions';

export function* fetchDeliveryLocations({ parentType, parent }) {
  if (parentType === DealParentType.MEMORIAL) {
    yield put(setDeliveryLocations(parent.steps));
    return;
  }

  try {
    const requestURL = `/api/v1/deals/${parent.id}/deliveries`;
    const response = yield call(requestWithAuth, requestURL);
    yield put(setDeliveryLocations(response));
    yield put(error(null));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* fetchParent({ type, id }) {
  let parent;
  try {
    if (type === DealParentType.MEMORIAL) {
      const { body } = yield request(Api.V1.Memorials.show(id));
      parent = body;
    } else {
      const { body } = yield request(Api.V1.Deals.get(id));
      parent = {
        ...body.deal,
        id,
      };
    }
  } catch (err) {
    yield put(error(err));
  }

  yield call(fetchDeliveryLocations, { parentType: type, parent });
  yield put(setParent(type, parent));
}

export function* getProduct(action) {
  try {
    const requestURL = `/api/v1/products_suppliers/${action.id}`;
    const product = yield call(requestWithAuth, requestURL);
    yield put(action.callback(product));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* fetchDealDetails({ deal }) {
  const dealUUID = deal.uuid;
  yield call(fetchDocuments, { dealUUID });
  yield put(fetchPaymentsDetails(dealUUID));
  yield call(getBookingInfo, { dealUUID });
  if (!deal.item.parent_id) {
    return;
  }
  yield call(fetchParent, {
    type: deal.item.parent_type,
    id: deal.item.parent_id,
  });
}

export function* setParentDeal({ id }) {
  if (id === null) {
    yield put(setParentInDeal(null));
    return;
  }

  yield fetchParent({ type: DealParentType.DEAL, id });
  yield put(setParentInDeal(DealParentType.DEAL, id));
}

export default function* dealItemsData() {
  yield takeLatest(FETCH_DEAL_DETAILS, fetchDealDetails);
  yield takeLatest(GET_DELIVERY_LOCATIONS, fetchDeliveryLocations);
  yield takeLatest(GET_PRODUCT, getProduct);
  yield takeLatest(SET_PARENT_DEAL, setParentDeal);
}
