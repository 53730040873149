import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  name: { id: 'app.containers.FundingAgencies.name' },
  phone: { id: 'app.containers.FundingAgencies.phone' },
  email: { id: 'app.containers.FundingAgencies.email' },
  contact: { id: 'app.containers.FundingAgencies.contact' },
  type: { id: 'app.containers.FundingAgencies.type' },
  search: { id: 'app.containers.FundingAgencies.search' },
  bank: { id: 'app.containers.FundingAgencies.bank' },
  healthInsurance: { id: 'app.containers.FundingAgencies.healthInsurance' },
  lifeInsurance: { id: 'app.containers.FundingAgencies.lifeInsurance' },
  contactEmail: { id: 'app.containers.FundingAgencies.contactEmail' },
  contactFax: { id: 'app.containers.FundingAgencies.contactFax' },
  contactSms: { id: 'app.containers.FundingAgencies.contactSms' },
  contactLetter: { id: 'app.containers.FundingAgencies.contactLetter' },
  contactTel: { id: 'app.containers.FundingAgencies.contactTel' },
});
