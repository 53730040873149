import { getDefaultMiddleware } from '@reduxjs/toolkit'
import { Middleware, Reducer } from 'redux'
import { createLogger } from 'redux-logger'
import { hydrate } from './actions'

import { configureReduxStore, Store } from './Store'

type ArrayElement<
  ArrayType extends readonly unknown[]
> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never
type MiddlewareGetter = typeof getDefaultMiddleware
type MiddlewareList = ArrayElement<ReturnType<MiddlewareGetter>>[]
type ExtendStore = (store: Store) => void

// A global store allows us to keep data between pages.
let globalStore: Store | undefined

export default function configureStore(
  reducer: Reducer,
  middlewares: Middleware[] = [],
  preloadedState = {},
  extendStore: ExtendStore | undefined = undefined,
): Store {
  let additionalMiddlewares: Middleware[] = [...middlewares]
  const devTools = !process.env.NODE_ENV || !['production', 'test'].includes(process.env.NODE_ENV)

  if (devTools) {
    additionalMiddlewares = [
      createLogger({
        collapsed: true,
      }),
      ...additionalMiddlewares,
    ]
  }
  const middleware = (getDefaultMiddlewares: MiddlewareGetter): MiddlewareList =>
    getDefaultMiddlewares().concat(additionalMiddlewares)

  let store: Store | undefined
  if (globalStore) {
    globalStore.dispatch(hydrate(preloadedState))
    store = globalStore
  } else {
    store = configureReduxStore({
      reducer,
      middleware,
      devTools,
      preloadedState,
    })
    store.injectedReducers = {}
    if (extendStore) {
      extendStore(store)
    }
  }

  if (process.browser) {
    // Setting the global store on server is useless and can leak previous session data.
    globalStore = store
  }

  return store
}
