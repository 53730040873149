import { defineMessages } from 'react-intl';

export default defineMessages({
  deals: { id: 'app.components.Header.deals' },
  clients: { id: 'app.components.Header.clients' },
  payments: { id: 'app.components.Header.payments' },
  suppliers: { id: 'app.components.Header.suppliers' },
  fundingAgencies: { id: 'app.components.Header.fundingAgencies' },
  flights: { id: 'app.components.Header.flights' },
  estimations: { id: 'app.components.Header.estimations' },
  places: { id: 'app.components.Header.places' },
  repatriations: { id: 'app.components.Header.repatriations' },
  map: { id: 'app.components.Header.map' },
  admin: { id: 'app.components.Header.admin' },
  billing: { id: 'app.components.Header.billing' },
  settings: { id: 'app.components.Header.settings' },
  stats: { id: 'app.components.Header.stats' },
  logout: { id: 'app.components.Header.logout' },
});
