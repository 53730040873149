import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { HardSpinner, PageSpinner } from '@advitam/ui';

import ResourceCable, {
  ResourceDestroyModal,
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { Path } from 'containers/App/constants';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { assert } from 'lib/support';
import { withDatasets } from 'slices/data';

import { resetHasMapSynchronized } from './Warehouse/sections/Zones/slice';
import { SUPPLIER_SKELETON } from './constants';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectRawSupplier,
} from './selectors';
import { fetchSupplier } from './thunk';
import slice, { clearError, setSupplier } from './slice';

function Supplier(): JSX.Element {
  const dispatch = useDispatch();

  const supplier = useSelector(makeSelectRawSupplier());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());

  const { id, warehouseId } = useParams();
  const supplierId = id && parseInt(id, 10);
  const canRender = !isLoading && supplier;

  const fetchResource = useCallback(() => {
    assert(typeof supplierId === 'number');
    dispatch(fetchSupplier(supplierId));
  }, [dispatch, supplierId]);

  useEffect(() => {
    if (!supplierId) {
      dispatch(setSupplier(SUPPLIER_SKELETON));
    } else if (supplierId !== supplier?.id) {
      fetchResource();
    }

    return (): void => {
      dispatch(resetHasMapSynchronized());
      dispatch(clearError());
    };
  }, [id]);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {supplierId && (
        <ResourceCable
          resourceId={supplierId}
          resourceType={ResourceRoomType.SUPPLIER}
        >
          <ResourceNavbarUsers />
          {!warehouseId && <ResourceUpdateModal onRefresh={fetchResource} />}
          <ResourceDestroyModal path={Path.SUPPLIERS} />
        </ResourceCable>
      )}
    </>
  );
}

export default compose(withDatasets('funeralTeam'), withSlice(slice))(Supplier);
