import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { RegionalHealthAuthorityJSON } from '@advitam/api/models/RegionalHealthAuthority';
import { saveEditableDocuments } from 'containers/Crud/Documents/Editable';
import { saveSupportingDocuments } from 'containers/Crud/Documents/Supporting';
import { assert } from 'lib/support';

import { REGIONAL_HEALTH_AUTHORITY } from './constants';
import type { NewRegionalHealthAuthority } from './types';
import {
  createRegionalHealthAuthority,
  destroyRegionalHealthAuthority,
  fetchRegionalHealthAuthority,
  setIsRegionalHealthAuthorityDisabled,
  updateRegionalHealthAuthority,
  updateRegionalHealthAuthorityName,
} from './thunk';

export interface State {
  regionalHealthAuthority:
    | RegionalHealthAuthorityJSON
    | NewRegionalHealthAuthority
    | null;
  isLoading: boolean;
  isSaving: boolean;
  isDestroying: boolean;
  isUpdatingName: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [REGIONAL_HEALTH_AUTHORITY]: State;
}

export const initialState: State = {
  regionalHealthAuthority: null,
  isLoading: false,
  isSaving: false,
  isDestroying: false,
  isUpdatingName: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: REGIONAL_HEALTH_AUTHORITY,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearError(state) {
      state.error = null;
    },
    setRegionalHealthAuthority(
      state,
      {
        payload,
      }: PayloadAction<
        RegionalHealthAuthorityJSON | NewRegionalHealthAuthority | null
      >,
    ) {
      state.regionalHealthAuthority = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchRegionalHealthAuthority.pending, state => {
      state.isLoading = true;
      state.destroyError = null;
    });
    builder.addCase(
      fetchRegionalHealthAuthority.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.regionalHealthAuthority = payload;
      },
    );
    builder.addCase(
      fetchRegionalHealthAuthority.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = ApiError.serialize(payload);
      },
    );

    builder.addCase(createRegionalHealthAuthority.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(createRegionalHealthAuthority.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(
      createRegionalHealthAuthority.rejected,
      (state, { payload }) => {
        state.isSaving = false;
        state.error = ApiError.serialize(payload);
      },
    );

    builder.addCase(updateRegionalHealthAuthority.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(
      updateRegionalHealthAuthority.fulfilled,
      (state, { payload }) => {
        state.isSaving = false;
        state.regionalHealthAuthority = payload;
      },
    );
    builder.addCase(
      updateRegionalHealthAuthority.rejected,
      (state, { payload }) => {
        state.isSaving = false;
        state.error = ApiError.serialize(payload);
      },
    );

    builder.addCase(updateRegionalHealthAuthorityName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(
      updateRegionalHealthAuthorityName.fulfilled,
      (state, { payload }) => {
        state.isUpdatingName = false;
        state.regionalHealthAuthority = payload;
      },
    );
    builder.addCase(
      updateRegionalHealthAuthorityName.rejected,
      (state, { payload }) => {
        state.isUpdatingName = false;
        state.error = ApiError.serialize(payload);
      },
    );

    builder.addCase(destroyRegionalHealthAuthority.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyRegionalHealthAuthority.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(
      destroyRegionalHealthAuthority.rejected,
      (state, { payload }) => {
        state.isDestroying = false;
        state.destroyError = ApiError.serialize(payload);
      },
    );

    builder.addCase(setIsRegionalHealthAuthorityDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(
      setIsRegionalHealthAuthorityDisabled.fulfilled,
      (state, { meta }) => {
        state.isSaving = false;
        assert(state.regionalHealthAuthority !== null);
        state.regionalHealthAuthority.disabled = meta.arg;
      },
    );
    builder.addCase(
      setIsRegionalHealthAuthorityDisabled.rejected,
      (state, { payload }) => {
        state.isSaving = false;
        state.error = ApiError.serialize(payload);
      },
    );

    builder.addCase(saveEditableDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveEditableDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveEditableDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveSupportingDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveSupportingDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveSupportingDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setRegionalHealthAuthority } = slice.actions;
export default slice;
