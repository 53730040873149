import type { UnsavedSupplierWarehouseProduct } from './types';

export const SUPPLIER_WAREHOUSE_PRODUCTS = 'SupplierWarehouse/Products';

export const PRODUCT_SKELETON: UnsavedSupplierWarehouseProduct = {
  supplier_ref: null,
  price: null,
  cost: null,
  default: false,
  funnel_default: false,
  product: null,
  created_at: null,
  updated_at: null,
};
