import { useIntl } from 'react-intl';

import Trash from '@advitam/ui/images/icons/trash.svg';
import {
  Button,
  FormConverters,
  Input,
  MaskedInput,
  ResourceList,
} from '@advitam/ui';

import messages from '../messages';
import style from '../AgeRanges.module.scss';

interface RowProps {
  prefix: string;
  onDelete: () => void;
}

export default function Row({ prefix, onDelete }: RowProps): JSX.Element {
  const intl = useIntl();

  return (
    <ResourceList.Row className={style.row}>
      <ResourceList.Cell className={style.title_cell}>
        <Input
          required
          name={`${prefix}.title`}
          className={style.input}
          placeholder={intl.formatMessage(messages.titlePlaceholder)}
        />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.age_cell}>
        <MaskedInput
          mask="0[0] \ans"
          name={`${prefix}.from`}
          parse={FormConverters.Number.parse}
          format={FormConverters.Number.format}
          className={style.input}
        />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.age_cell}>
        <MaskedInput
          mask="0[0] \ans"
          name={`${prefix}.to`}
          parse={FormConverters.Number.parse}
          format={FormConverters.Number.format}
          className={style.input}
        />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.actions}>
        <Button outline onClick={onDelete} icon={<Trash />} />
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
