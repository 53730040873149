import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { GraveyardConcessionJSON } from '@advitam/api/models/Graveyard/Concession';

import type { AppStateSubset, State } from './slice';
import { GRAVEYARD_CONCESSIONS } from './constants';
import type { ConcessionFilters } from './types';

type GraveyardConcessionsSelectors<T> = Selector<AppStateSubset, T>;

const selectConcessionsDomain = (state: AppStateSubset): State =>
  state[GRAVEYARD_CONCESSIONS];

export const makeSelectConcessions = (): GraveyardConcessionsSelectors<
  GraveyardConcessionJSON[]
> => createSelector(selectConcessionsDomain, ({ concessions }) => concessions);

export const makeSelectIsLoading = (): GraveyardConcessionsSelectors<boolean> =>
  createSelector(selectConcessionsDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): GraveyardConcessionsSelectors<boolean> =>
  createSelector(selectConcessionsDomain, ({ isSaving }) => isSaving);

export const makeSelectError = (): GraveyardConcessionsSelectors<SerializedApiError | null> =>
  createSelector(selectConcessionsDomain, ({ error }) => error);

export const makeSelectFilters = (): GraveyardConcessionsSelectors<
  ConcessionFilters
> => createSelector(selectConcessionsDomain, ({ filters }) => filters);
