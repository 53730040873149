// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__actions--fXV9e{display:flex;justify-content:space-between;align-items:center}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Payments/style.scss"],"names":[],"mappings":"AAEA,uBACE,YAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":["@import 'src/scss/mixins/vars.scss';\n\n.actions {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `style__actions--fXV9e`
};
export default ___CSS_LOADER_EXPORT___;
