export const DOCUMENTS = 'Documents';

export const FETCH_DOCUMENTS = 'app/Documents/FETCH_DOCUMENTS';
export const SET_DOCUMENTS = 'app/Documents/SET_DOCUMENTS';
export const ERROR_DOCUMENT_LIST = 'app/Documents/ERROR_DOCUMENT_LIST';

export const DOWNLOAD_FILES = 'app/Documents/DOWNLOAD_FILES';
export const MERGE_FILES = 'app/Documents/MERGE_FILES';
export const REMOVE_DOCUMENTS = 'app/Documents/REMOVE_DOCUMENTS';

export const FETCH_DOCUMENT_VERSIONS = 'app/Documents/FETCH_DOCUMENT_VERSIONS';
export const FETCH_DOCUMENT_VERSIONS_SUCCESS =
  'app/Documents/FETCH_DOCUMENT_VERSIONS_SUCCESS';
export const CLEAR_DOCUMENT_VERSIONS = 'app/Documents/CLEAR_DOCUMENT_VERSIONS';
export const TOGGLE_LOCKED_VERSION = 'app/Documents/TOGGLE_LOCKED_VERSION';
export const VERSION_LOCK_FAILURE = 'app/Documents/VERSION_LOCK_FAILURE';

export const SET_LOADING = 'app/Documents/SET_LOADING';
export const SET_SELECTED_DOCUMENTS = 'app/Documents/SET_SELECTED_DOCUMENTS';
export const TOGGLE_DOCUMENT_SELECTION =
  'app/Documents/TOGGLE_DOCUMENT_SELECTION';

export const RESET_DOCUMENTS = 'app/Documents/RESET_DOCUMENTS';

export const FAILURE_STATE = 'failure';
