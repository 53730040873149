import { ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import { LegalisationType } from '@advitam/api/models/Consulate/LegalisationType';
import {
  ConfirmationModal,
  CountryAutocomplete,
  FormLayout,
  NumberInput,
  Select,
} from '@advitam/ui';
import { Layout, messages as crudMessages } from 'containers/Crud';
import actionMessages from 'messages/actions';
import { assert } from 'lib/support';

import {
  makeSelectIsDefaultOverrideConfirmationNeeded,
  makeSelectIsUpdatingDefault,
  makeSelectRawConsulate,
} from '../selectors';
import { saveConsulate, setConsulateDefault } from '../thunk';
import Wrapper from './Wrapper';
import { ConsulateForm } from './types';
import messages from './messages';
import { cancelDefaultOverride } from '../slice';

function p(chunk: ReactNode): ReactNode {
  return <p>{chunk}</p>;
}

export default function Miscellaneous(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const consulate = useSelector(makeSelectRawConsulate());
  const isDefaultOverrideConfirmationNeeded = useSelector(
    makeSelectIsDefaultOverrideConfirmationNeeded(),
  );
  const isUpdatingDefault = useSelector(makeSelectIsUpdatingDefault());

  const onSubmit = useCallback(
    (values: ConsulateForm): void => {
      dispatch(saveConsulate(values.consulate));
    },
    [dispatch],
  );

  const onDefaultOverrideConfirmation = useCallback(() => {
    assert(consulate?.id !== undefined);
    dispatch(setConsulateDefault({ consulate, force: true }));
  }, [consulate, dispatch]);

  const onDefaultOverrideCancel = useCallback(() => {
    dispatch(cancelDefaultOverride());
  }, [dispatch]);

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <Layout.FieldsetGrid>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.functioning.id} />}
          >
            <FormLayout.Row>
              <NumberInput
                name="consulate.tax"
                label={<FormattedMessage id={messages.tax.id} />}
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
              <CountryAutocomplete
                name="consulate.reference_country"
                label={<FormattedMessage id={messages.countryCode.id} />}
                tooltip={
                  <FormattedMessage id={messages.countryCodeTooltip.id} />
                }
                endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.countries)}
                tokens={{ ...getTokens() }}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <Select
                name="consulate.default"
                label={<FormattedMessage id={messages.default.id} />}
                tooltip={
                  <FormattedMessage
                    id={messages.defaultTooltip.id}
                    values={{ p }}
                  />
                }
                items={[
                  { name: intl.formatMessage(actionMessages.yes), value: true },
                  { name: intl.formatMessage(actionMessages.no), value: false },
                ]}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                name="consulate.nb_paper_to_trad"
                label={<FormattedMessage id={messages.nbPaperToTrad.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <Select
                unselectable
                name="consulate.legalisation"
                label={<FormattedMessage id={messages.legalisationType.id} />}
                items={[
                  {
                    name: intl.formatMessage(
                      messages.legalisationTypeLegalisation,
                    ),
                    value: LegalisationType.LEGALISATION,
                  },
                  {
                    name: intl.formatMessage(
                      messages.legalisationTypeNoLegalisation,
                    ),
                    value: LegalisationType.NO_LEGALISATION,
                  },
                  {
                    name: intl.formatMessage(messages.legalisationTypeApostil),
                    value: LegalisationType.APOSTILLE,
                  },
                ]}
              />
            </FormLayout.Row>
          </Layout.Fieldset>
        </Layout.FieldsetGrid>
      </Layout.Container>

      <ConfirmationModal
        isOpen={isDefaultOverrideConfirmationNeeded}
        title={
          <FormattedMessage id={messages.overrideDefaultConfirmationTitle.id} />
        }
        text={
          <FormattedMessage
            id={messages.overrideDefaultConfirmationText.id}
            values={{ p }}
          />
        }
        confirm={
          <FormattedMessage
            id={messages.overrideDefaultConfirmationConfirm.id}
          />
        }
        onConfirm={onDefaultOverrideConfirmation}
        cancel={
          <FormattedMessage
            id={messages.overrideDefaultConfirmationCancel.id}
          />
        }
        onCancel={onDefaultOverrideCancel}
        isConfirmationLoading={isUpdatingDefault}
      />
    </Wrapper>
  );
}
