// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Documents-module__link--Cl4i8{color:inherit}.Documents-module__link--Cl4i8>svg{width:18px;height:auto}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/SendBatchModal/Autocompletes/Documents/Documents.module.scss"],"names":[],"mappings":"AAEA,+BACE,aAAA,CAEA,mCACE,UAAA,CACA,WAAA","sourcesContent":["@import '~@advitam/ui/scss/colors';\n\n.link {\n  color: inherit;\n\n  > svg {\n    width: 18px;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `Documents-module__link--Cl4i8`
};
export default ___CSS_LOADER_EXPORT___;
