import { useMemo } from 'react'

type Vec2 = [number, number]

function vecAdd([a1, a2]: Vec2, [b1, b2]: Vec2): Vec2 {
  return [a1 + b1, a2 + b2]
}

function arcPath([cx, cy]: Vec2, [rx, ry]: Vec2, [t1, a]: Vec2): string {
  const angle = a % (2 * Math.PI)
  const [sX, sY] = vecAdd([rx * Math.cos(t1), ry * Math.sin(t1)], [cx, cy])
  const [eX, eY] = vecAdd([rx * Math.cos(t1 + angle), ry * Math.sin(t1 + angle)], [cx, cy])
  const fA = angle > Math.PI ? 1 : 0
  const fS = angle > 0 ? 1 : 0
  return `M${cx} ${cy}L${sX} ${sY}A${[rx, ry, 0, fA, fS, eX, eY].join(' ')}`
}

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180)
}

interface ArcProps {
  angle: number
  className?: string
}

export default function Arc({ angle, className }: ArcProps): JSX.Element {
  const path = useMemo(() => arcPath([20, 20], [20, 20], [toRadians(270), toRadians(angle)]), [
    angle,
  ])

  return (
    <svg className={className} viewBox="0 0 40 40">
      <path d={path} />
    </svg>
  )
}
