import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { FormattedApiError } from '@advitam/ui';
import { ErrorBannerBase } from 'components/ErrorBanner';
import { safeFormatMessage } from 'utils/functions.typed';

import { clearUploadErrors } from '../slice';
import { makeSelectUploadErrors } from '../selectors';
import messages from './messages';

export default function ErrorList(): JSX.Element | null {
  const intl = useIntl();
  const dispatch = useDispatch();
  const uploadErrors = useSelector(makeSelectUploadErrors());

  const onClose = (): void => {
    dispatch(clearUploadErrors());
  };

  return (
    <ErrorBannerBase isOpen={uploadErrors.length > 0} onClose={onClose}>
      {uploadErrors.map((uploadError, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={`${uploadError.fileName}-${idx}`}>
          <b>{uploadError.fileName}</b>
          {': '}
          {typeof uploadError.error === 'string' ? (
            safeFormatMessage(intl, messages, uploadError.error)
          ) : (
            <FormattedApiError error={uploadError.error} />
          )}
        </p>
      ))}
    </ErrorBannerBase>
  );
}
