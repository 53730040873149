// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(min-width: 450px){.style__container--a2Wt4{width:400px;max-width:500px}}.style__actions--SGJZX{display:flex;justify-content:flex-end}.style__actions--SGJZX button{width:50%}.style__actions--SGJZX button:first-child{margin-left:0}.style__actions--SGJZX button:last-child{margin-right:0}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Clients/style.scss"],"names":[],"mappings":"AACE,yBADF,yBAEI,WAAA,CACA,eAAA,CAAA,CAIJ,uBACE,YAAA,CACA,wBAAA,CAEA,8BACE,SAAA,CAEA,0CACE,aAAA,CAGF,yCACE,cAAA","sourcesContent":[".container {\n  @media (min-width: 450px) {\n    width: 400px;\n    max-width: 500px;\n  }\n}\n\n.actions {\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    width: 50%;\n\n    &:first-child {\n      margin-left: 0;\n    }\n\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style__container--a2Wt4`,
	"actions": `style__actions--SGJZX`
};
export default ___CSS_LOADER_EXPORT___;
