import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';

import { SEND_BATCH_MODAL_DOCUMENTS } from './constants';
import { createDocument, destroyDocument } from './thunk';

export interface State {
  isLoading: boolean;
  loadingDocumentIds: number[];
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [SEND_BATCH_MODAL_DOCUMENTS]: State;
}

export const initialState: State = {
  isLoading: false,
  loadingDocumentIds: [],
  error: null,
};

const slice = createSlice({
  name: SEND_BATCH_MODAL_DOCUMENTS,
  initialState,
  reducers: {},
  /* eslint-disable no-param-reassign */
  extraReducers: builder => {
    builder.addCase(createDocument.pending, (state, { meta }) => {
      state.isLoading = true;
      state.loadingDocumentIds.push(meta.arg.id);
      state.error = null;
    });
    builder.addCase(createDocument.fulfilled, (state, { meta }) => {
      state.isLoading = false;
      state.loadingDocumentIds = state.loadingDocumentIds.filter(
        id => id !== meta.arg.id,
      );
    });
    builder.addCase(createDocument.rejected, (state, { payload, meta }) => {
      state.isLoading = false;
      state.loadingDocumentIds = state.loadingDocumentIds.filter(
        id => id !== meta.arg.id,
      );
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyDocument.pending, (state, { meta }) => {
      state.loadingDocumentIds.push(meta.arg);
      state.error = null;
    });
    builder.addCase(destroyDocument.fulfilled, (state, { meta }) => {
      state.loadingDocumentIds = state.loadingDocumentIds.filter(
        id => id !== meta.arg,
      );
    });
    builder.addCase(destroyDocument.rejected, (state, { payload, meta }) => {
      state.loadingDocumentIds = state.loadingDocumentIds.filter(
        id => id !== meta.arg,
      );
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export default slice;
