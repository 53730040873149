// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DefunctSize-module__field--FGJNv{display:flex;align-items:center;margin-top:4px}.DefunctSize-module__field--FGJNv>span{margin-right:16px}.DefunctSize-module__field--FGJNv>label{margin-left:auto;width:150px}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Sections/Todolist/List/Todo/DefunctSize/DefunctSize.module.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,uCACE,iBAAA,CAGF,wCACE,gBAAA,CACA,WAAA","sourcesContent":[".field {\n  display: flex;\n  align-items: center;\n  margin-top: 4px;\n\n  > span {\n    margin-right: 16px;\n  }\n\n  > label {\n    margin-left: auto;\n    width: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `DefunctSize-module__field--FGJNv`
};
export default ___CSS_LOADER_EXPORT___;
