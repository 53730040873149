import { put } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { authenticate, withSessionId } from '../../../lib/decorators'

export class Disabled {
  @authenticate
  @withSessionId
  static update(id: number, value: boolean): ApiRequestDescriptor<void> {
    return put(`/api/v1/suppliers/warehouses/${id}/disabled`, { value })
  }
}
