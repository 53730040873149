import { SerializedApiError } from '@advitam/api';
import { createSelector, Selector } from 'reselect';
import { TODO_LIST_CREATION_MODAL } from './constants';
import { State, AppStateSubset } from './slice';

type TodolistCreationModalSelector<T> = Selector<AppStateSubset, T>;

const selectTodoListCreationModalDomain = (state: AppStateSubset): State =>
  state[TODO_LIST_CREATION_MODAL];

export const makeSelectIsOpen = (): TodolistCreationModalSelector<boolean> =>
  createSelector(selectTodoListCreationModalDomain, ({ isOpen }) => isOpen);

export const makeSelectIsLoading = (): TodolistCreationModalSelector<boolean> =>
  createSelector(
    selectTodoListCreationModalDomain,
    ({ isLoading }) => isLoading,
  );

export const makeSelectError = (): TodolistCreationModalSelector<SerializedApiError | null> =>
  createSelector(selectTodoListCreationModalDomain, ({ error }) => error);
