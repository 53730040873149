import { IntlShape, useIntl } from 'react-intl';
import { PaymentMean } from 'models/Payment/Mean';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from './messages';

export function formatMean(mean: PaymentMean, intl: IntlShape): string {
  return safeFormatMessage(intl, messages, mean);
}

interface FormattedAddressProps {
  mean: PaymentMean;
}

export function FormattedMean({ mean }: FormattedAddressProps): JSX.Element {
  const intl = useIntl();
  return <>{formatMean(mean, intl)}</>;
}
