import { call, CallEffect, PutEffect } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import {
  deleteTokens,
  hasTokens,
  updateTokensFromResponse,
} from '@advitam/api/lib/tokens';
import { assert } from 'lib/Assert';
import { LOGIN_PATH } from 'containers/App/constants';
import { Model } from 'models/Model';

import { ApiError } from './Error/ApiError';

export type JSONScalar<T = unknown> =
  | boolean
  | number
  | string
  | Date
  | Blob
  | Model<T>;
export type JSONValue<T = unknown> = JSONScalar<T> | JSONScalar<T>[];
// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-unused-vars
export interface JSONObject<T = unknown> extends Object {}
export type ApiPayload<T = unknown> = JSONObject<T> | JSONValue<T>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ApiRequestDescriptor<ResponseType, PayloadType = unknown> {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  endpoint: string;
  headers: Record<string, string | null>;
  payload?: ApiPayload<PayloadType>;
}

export interface ApiResponse<T = unknown> {
  body: T;
}

async function parseResponseBody<T = unknown>(
  res: Response,
): Promise<string | T | null> {
  if (res.status === 204) {
    return null;
  }

  const contentType = res.headers.get('content-type');
  if (contentType && contentType.startsWith('application/json')) {
    return res.json() as Promise<T>;
  }
  return res.text();
}

// eslint-disable-next-line prefer-destructuring
const COMPANY_ID = process.env.COMPANY_ID;
assert(COMPANY_ID !== undefined);
export const COMPANY_HEADER = {
  'X-Company-Id': COMPANY_ID,
};

function consolidateRequest<ResponseType, PayloadType>(
  req: ApiRequestDescriptor<ResponseType, PayloadType>,
): RequestInit {
  const params: RequestInit = {
    method: req.method,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      // TODO: Need to implement companies request...
      ...COMPANY_HEADER,
      ...req.headers,
    },
  };

  if (req.payload instanceof FormData) {
    return {
      ...params,
      body: req.payload,
    };
  }

  return {
    ...params,
    body: JSON.stringify(req.payload),
    headers: {
      'Content-Type': 'application/json',
      ...params.headers,
    },
  };
}

function isTypeError(error: unknown): error is TypeError {
  return Boolean(error) && (error as Error).name === 'TypeError';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function sendRequest<R, PayloadType>(
  req: ApiRequestDescriptor<ResponseType, PayloadType>,
): Promise<Response> {
  assert(process.env.API_ENDPOINT !== undefined);
  const route = process.env.API_ENDPOINT + req.endpoint;
  const consolidatedRequest = consolidateRequest(req);
  try {
    return fetch(route, consolidatedRequest);
  } catch (err) {
    if (!isTypeError(err)) {
      throw err;
    }
    throw new ApiError(
      {
        statusText: 'No network',
        status: ApiError.Status.NO_NETWORK,
      },
      null,
    );
  }
}

type RequestGenerator = Generator<
  CallEffect | PutEffect,
  ApiResponse,
  Response | string
>;

export function* request<ResponseType, PayloadType>(
  req: ApiRequestDescriptor<ResponseType, PayloadType>,
): RequestGenerator {
  const response: Response = (yield call(sendRequest, req)) as Response;
  updateTokensFromResponse(response);

  if (response.status === 401 && hasTokens()) {
    deleteTokens();
    push(LOGIN_PATH);
    return { body: null };
  }

  const body = (yield call(parseResponseBody, response)) as string;
  if (response.ok) {
    return { body };
  }

  throw new ApiError(response, body);
}

export async function requestAsync<ResponseType, PayloadType>(
  req: ApiRequestDescriptor<ResponseType, PayloadType>,
): Promise<ApiResponse<ResponseType | null>> {
  const response: Response = await sendRequest(req);
  updateTokensFromResponse(response);
  if (response.status === 401 && hasTokens()) {
    deleteTokens();
    push(LOGIN_PATH);
    return { body: null };
  }

  const body = await parseResponseBody<ResponseType>(response);
  if (response.ok) {
    return { body } as ApiResponse<ResponseType>;
  }

  throw new ApiError(response, body as string | null);
}
