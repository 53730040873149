import { cloneDeep } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { DocumentTypeSlug } from '@advitam/api/models/DocumentType';
import { assert } from 'lib/support';

import { makeSelectRawClient } from '../../../selectors';
import { AppStateSubset as ClientAppStateSubset } from '../../../slice';
import { DECEASED_MODAL } from './constants';
import { close } from './slice';
import { GeneralForm } from '../types';
import { saveGeneralSectionFromStep, SaveStep } from '../thunk';

export const makeDeceased = createAsyncThunk(
  `${DECEASED_MODAL}/MAKE_DECEASED`,
  async (payload: GeneralForm, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as ClientAppStateSubset;
    const client = makeSelectRawClient()(state);
    assert(client !== null);
    const values = cloneDeep(payload);

    try {
      const { body } = await request(Api.V1.Clients.Deceased.create(client.id));
      values.client.defunct_id = body.defunct_id;

      assert(body.defunct_id !== null);
      assert(values.actOfDeath instanceof Blob);
      await request(
        Api.V1.Defuncts.SupportingDocuments.create(
          body.defunct_id,
          DocumentTypeSlug.ACT_OF_DEATH,
          values.actOfDeath,
        ),
      );
    } catch (error) {
      return rejectWithValue(error);
    }

    dispatch(close());
    await dispatch(
      saveGeneralSectionFromStep({ step: SaveStep.DECEASED + 1, values }),
    );

    return undefined;
  },
);
