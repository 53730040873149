import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouteProps, useLocation } from 'react-router';

import { Role } from '@advitam/api/models/BusinessUser/Role';
import { assert } from '@advitam/support';

import { isAccessGranted } from 'components/Authorization/utils.js';
import { Path } from 'containers/App/constants';
import { makeSelectIsAuthenticated, makeSelectUser } from 'slices/auth';

interface PrivatePageProps {
  children: ReactNode;
  role?: Role;
}

export default function PrivatePage({
  children,
  role = Role.FUNERAL_ADVISOR,
}: PrivatePageProps & RouteProps): JSX.Element {
  const isAuthenticated = useSelector(makeSelectIsAuthenticated());
  const user = useSelector(makeSelectUser());
  const location = useLocation();

  if (isAuthenticated !== true) {
    return <Navigate replace to={Path.LOGIN} state={{ from: location }} />;
  }

  assert(user !== null);
  if (!isAccessGranted(role, user.role)) {
    return <Navigate replace to={Path.INDEX} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
