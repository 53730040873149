import { pick } from 'lodash';

import { Memorial } from 'models/Memorial';

interface UpdateMemorial {
  profile_image?: Blob | null;
  message?: string | null;
  pot_enabled?: boolean;
}

export function serializeUpdate(memorial: Partial<Memorial>): UpdateMemorial {
  return {
    ...pick(
      memorial,
      'profile_image',
      'message',
      'open',
      'calendar_enabled',
      'attendance_enabled',
      'flowers_enabled',
      'trees_enabled',
      'pot_enabled',
      'search_index_enabled',
      'associations_enabled',
      'association_url',
    ),
  };
}
