import { ReactNode } from 'react'

import Heading from '../Heading'
import Text from '../Text'
import style from './FlowCard.module.scss'

interface FlowCardProps {
  index: number
  title: ReactNode
  text?: ReactNode
}

export default function FlowCard({ index, title, text }: FlowCardProps): JSX.Element {
  return (
    <div className={style.card}>
      <div className={style.index}>
        <Heading small tagName="span">
          <b>{index}</b>
        </Heading>
      </div>
      <Heading small tagName="h4">
        <b>{title}</b>
      </Heading>
      {text && (
        <div className={style.text}>
          <Text small>{text}</Text>
        </div>
      )}
    </div>
  )
}
