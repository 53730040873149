import { Model } from '../../Model'
import { CommunicationChannelType } from './ChannelType'
import { CommunicationEntityType } from './EntityType'
import { CommunicationFileJSON } from './File'
import { CommunicationState } from './State'
import { CommunicationType } from './Type'

interface DealCommunicationBase {
  channel_type: CommunicationChannelType
  channel_value: string
  state: CommunicationState
  entity_name: string
  entity_type: CommunicationEntityType
  user_id: number | null
  wait_time: number | null
  duration: number | null
  files: CommunicationFileJSON[]
  type: CommunicationType
  subject: string | null
  content: string | null
  error: string | null
}

export interface DealCommunicationJSON extends DealCommunicationBase {
  date: string
  received_at: string
}

export interface DealCommunication extends DealCommunicationBase {
  date: Date
  received_at: Date
}

export class DealCommunication extends Model<DealCommunicationJSON> {
  constructor(data: DealCommunicationJSON) {
    super(data)

    this.date = Model.parseDate(data.date, false)
    this.received_at = Model.parseDate(data.received_at)
  }
}
