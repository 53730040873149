import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import AutoComplete from 'containers/AutoComplete';
import CheckboxLabel from 'components/Input/CheckboxLabel';
import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';
import messagesWishes from 'messages/wishes';

import Services from '../../Services';
import CheckboxWishes from '../CheckboxWishes';
import { renderStepDefaults, renderLocation } from '../stepEditorsUtils';
import messages from './messages';
import commonMessages from '../messages';
import Ceremonies from './Ceremonies';

const TYPE = 'Crematorium';

export default function Cremation(props) {
  const setResponse = (id, name) => {
    const { step } = props;
    handleChange({
      location: { ...step.location, id, name, type: TYPE },
    });
  };

  const {
    stepServices,
    addService,
    removeService,
    wishes,
    findWish,
    updateWish,
    defunctAge,
    step,
    getCrematorium,
    crematorium,
    handleChange,
    getCeremonies,
    ceremonies,
    coffin,
    updateDelegate,
    isManualMode,
    userRole,
    updateService,
  } = props;

  const autoFindValue = step.location.autoFind || false;

  return (
    <div className="step">
      <div className="step__header">
        {renderStepDefaults(step, handleChange)}
        {renderLocation(step.location, commonMessages.location)}
        <CheckboxDelegate
          isDelegate={!!step.doneByAnotherFuneral}
          updateDelegate={updateDelegate}
        />
      </div>
      <div>
        <CheckboxWishes
          wishType="want_ceremony_master"
          message={messagesWishes.ceremonyMaster}
          wishes={wishes}
          findWish={findWish}
          updateWish={updateWish}
        />
      </div>
      <CheckboxLabel
        message={commonMessages.autoFind}
        updateCheckbox={() =>
          handleChange({
            ...step,
            location: { ...step.location, autoFind: !autoFindValue },
          })
        }
        checkboxValue={autoFindValue}
        messageValues={{ type: 'crematorium' }}
      />
      {!step.location.autoFind && (
        <div>
          <FormattedMessage {...messages.crematoriums} />
          <AutoComplete
            url="/api/v1/autocompletes/crematoriums?q="
            getKey={item => item.id}
            getValue={item => item.description}
            onSelect={(_, item) => setResponse(item.id, item.description)}
            auth
          />
        </div>
      )}
      {step.location.id && (
        <Ceremonies
          id={step.location.id}
          getCrematorium={getCrematorium}
          getCeremonies={getCeremonies}
          crematorium={crematorium}
          ceremonies={ceremonies}
          step={step}
          handleChange={handleChange}
          defunctAge={defunctAge}
          coffin={coffin}
        />
      )}
      <Services
        stepId={step.id}
        services={stepServices}
        addService={addService}
        removeService={removeService}
        isManualMode={isManualMode}
        userRole={userRole}
        updateService={updateService}
      />
    </div>
  );
}

Cremation.propTypes = {
  /** Current step data from redux store */
  step: PropTypes.object.isRequired,
  /** Function to update a wish family */
  updateWish: PropTypes.func.isRequired,
  /** Function to find a wish family */
  findWish: PropTypes.func.isRequired,
  /** Wishes data of family */
  wishes: PropTypes.array.isRequired,
  /** Services data of current step */
  stepServices: PropTypes.array.isRequired,
  /** Function to add a service */
  addService: PropTypes.func.isRequired,
  /** Function to remove a service */
  removeService: PropTypes.func.isRequired,
  /** function to update a value */
  handleChange: PropTypes.func.isRequired,
  /** Function to get crematorium */
  getCrematorium: PropTypes.func.isRequired,
  /** Function to get ceremonies of crematorium */
  getCeremonies: PropTypes.func.isRequired,
  /** Crematorium data from redux store */
  crematorium: PropTypes.object.isRequired,
  /** Ceremonies data from redux store */
  ceremonies: PropTypes.array.isRequired,
  /** Data of coffin selected */
  coffin: PropTypes.object,
  /** Age of defunct */
  defunctAge: PropTypes.number,
  /** update step delegate */
  updateDelegate: PropTypes.func.isRequired,
  /** is it the manual mode */
  isManualMode: PropTypes.bool.isRequired,
  /** role of user */
  userRole: PropTypes.number.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
};
