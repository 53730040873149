interface PartialEntity {
  name: string;
  postal_code?: string | null;
  city?: string | null;
}

export function formatCity(entity: PartialEntity): string {
  return [entity.postal_code, entity.city].filter(Boolean).join(' ');
}

export function formatEntity(entity: PartialEntity): string {
  const formattedCity = formatCity(entity);
  return [entity.name, formattedCity].filter(Boolean).join(', ');
}

interface FormattedEntityProps {
  entity: PartialEntity;
}

export function FormattedEntity({ entity }: FormattedEntityProps): JSX.Element {
  return <>{formatEntity(entity)}</>;
}
