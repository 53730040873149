import { ERROR } from 'containers/Deal/constants.ts';
import { setDeal } from '../slice.ts';
import {
  GET_DELIVERY_LOCATIONS,
  SET_DELIVERY_LOCATIONS,
  SET_PARENT_DEAL,
  SET_PARENT,
} from './constants.ts';

const initialState = {
  isLoading: false,
  parentType: undefined,
  parent: undefined,
  deliveryLocations: [],
};

function dealItemsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DELIVERY_LOCATIONS:
      return {
        ...state,
        isLoading: true,
      };
    case setDeal.type:
      return {
        ...state,
        isLoading: false,
        parent: undefined,
        parentType: undefined,
      };
    case ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case SET_DELIVERY_LOCATIONS:
      return {
        ...state,
        isLoading: false,
        deliveryLocations: action.deliveryLocations,
      };
    case SET_PARENT_DEAL:
      return {
        ...state,
        isLoading: action.id !== null,
      };
    case SET_PARENT:
      return {
        ...state,
        parent: action.parent,
        parentType: action.parentType,
      };
    default:
      return state;
  }
}

export default dealItemsReducer;
