import { authenticate } from '../../lib/decorators'
import { post } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'

export const Validate = {
  create: authenticate(
    (entityInvoiceId: number): ApiRequestDescriptor<void> =>
      post(`/api/v1/entity_invoices/${entityInvoiceId}/validate`),
  ),
}
