import { useEffect, useState } from 'react'

import { Browsers } from '@advitam/support'

function isWindowAbove(breakpoint: number): boolean {
  if (typeof window === 'undefined') {
    return true
  }
  return window.innerWidth >= breakpoint
}

/**
 * Test whether the window size is above some breakpoint.
 *
 * @warning This hook is not SSR friendly.
 *
 * @return true if the window width is larger than `breakpoint`, false otherwise
 */
export function useBreakpoint(breakpoint: number): boolean {
  const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(isWindowAbove(breakpoint))

  useEffect(() => {
    let observer: ResizeObserver

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async (): Promise<void> => {
      observer = await Browsers.getResizeObserver(() => {
        setIsAboveBreakpoint(isWindowAbove(breakpoint))
      })

      observer.observe(document.body)
    })()

    return (): void => observer?.disconnect()
  }, [breakpoint])

  return isAboveBreakpoint
}
