import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Props } from 'react-intl/src/components/message';
import qs from 'qs';

import ExternalLink from './ExternalLink';

interface MailLinkProps {
  subject: Props;
  body: Props;
  children: ReactNode | ReactNode[];
  className?: string;
}

export default function MailLink({
  subject,
  body,
  children,
  className,
}: MailLinkProps): JSX.Element {
  const intl = useIntl();
  const query = qs.stringify({
    subject: intl.formatMessage(subject, subject.values),
    body: intl.formatMessage(body, body.values),
  });

  return (
    <ExternalLink className={className} href={`mailto:?${query}`}>
      {children}
    </ExternalLink>
  );
}
