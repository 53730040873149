import { ReactNode, useCallback } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { NullableString } from '../converters'
import MaskedInputUI from '../UI/Masked'
import { hasLength, isRequired, useValidators } from '../validators'
import { MASK, MASK_DEFINITIONS } from './constants'
import { getFormError, ErrorMessages } from '../errors'

function parse(value: string | null): string | null {
  return NullableString.parse(value && value.toUpperCase())
}

interface BICInputProps {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  maxLength?: number
  renderBelow?: (value: string) => ReactNode
  className?: string
  tooltip?: ReactNode
  hideErrorMessage?: boolean
  errorMessages?: ErrorMessages
}

export default function BICInput({
  name,
  label,
  placeholder,
  readOnly,
  disabled,
  required,
  renderBelow,
  className,
  tooltip,
  hideErrorMessage,
  errorMessages,
}: BICInputProps): JSX.Element {
  const validator = useValidators(required && isRequired, hasLength(8, 11))
  const render = useCallback(
    ({ input, meta }: FieldRenderProps<string>): JSX.Element => (
      <MaskedInputUI
        mask={MASK}
        unmask
        maskDefinitions={MASK_DEFINITIONS}
        label={label}
        onChange={input.onChange}
        onBlur={input.onBlur}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly as false}
        value={input.value}
        error={getFormError(meta, errorMessages, hideErrorMessage)}
        className={className}
        tooltip={tooltip}
        renderBelow={renderBelow}
      />
    ),
    [
      className,
      disabled,
      errorMessages,
      hideErrorMessage,
      label,
      placeholder,
      readOnly,
      renderBelow,
      tooltip,
    ],
  )

  return (
    <Field name={name} validate={validator} format={NullableString.format} parse={parse}>
      {render}
    </Field>
  )
}
