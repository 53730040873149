import { CityhallJSON } from '@advitam/api/models/Cityhall';

export function formatName(cityhall: CityhallJSON): string {
  let formattedName = cityhall.name;

  if (cityhall.postal_code) {
    formattedName += ` (${cityhall.postal_code})`;
  }

  return formattedName;
}

interface FormattedNameProps {
  cityhall: CityhallJSON;
}

export function FormattedName({ cityhall }: FormattedNameProps): JSX.Element {
  return <>{formatName(cityhall)}</>;
}
