import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { assert } from '@advitam/support';
import { DocumentTemplateJSON } from 'models/DocumentTemplate';

import {
  DEFAULT_FONT_SIZE,
  DEFAULT_ZOOM,
  DOCUMENT_TEMPLATE_EDITOR,
  MAX_ZOOM,
  MIN_INPUT_HEIGHT,
  MIN_INPUT_WIDTH,
  MIN_ZOOM,
} from './constants';
import { Page, Input } from './models';
import { fetchData } from './thunk';

export interface State {
  blob: Blob | null;
  template: DocumentTemplateJSON | null;
  newInput: Input | null;
  pages: Page[];
  uploads: string[];
  dictionary: Record<string, unknown>;
  defaultFontSize: number;
  zoom: number;
  isPreviewMode: boolean;
}

export interface AppStateSubset {
  [DOCUMENT_TEMPLATE_EDITOR]: State;
}

export const initialState: State = {
  blob: null,
  template: null,
  newInput: null,
  pages: [],
  uploads: [],
  dictionary: {},
  defaultFontSize: DEFAULT_FONT_SIZE,
  zoom: DEFAULT_ZOOM,
  isPreviewMode: false,
};

export interface InputTarget {
  page: number;
  input: number;
}

function fetchInput(state: State, target: InputTarget): Input {
  if (target.page === -1) {
    assert(state.newInput !== null);
    return state.newInput;
  }
  return state.pages[target.page].inputs[target.input];
}

function zoomFixed(values: [number, number], factor: number): [number, number] {
  return [values[0] / factor, values[1] / factor];
}

interface PageSetter extends InputTarget {
  newPage: number;
}

interface PositionSetter extends InputTarget {
  position: [number, number];
}

interface SizeSetter extends InputTarget {
  size: [number, number];
}

interface ValueSetter extends InputTarget {
  value: string | boolean | undefined;
}

interface FontSizeSetter extends InputTarget {
  fontSize: number;
}

const slice = createSlice({
  name: DOCUMENT_TEMPLATE_EDITOR,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    openFile(state, { payload }: PayloadAction<Blob | null>) {
      state.blob = payload;
      state.template = null;
      state.pages = [];
      state.isPreviewMode = false;
      state.defaultFontSize = DEFAULT_FONT_SIZE;
    },

    setFile(state, { payload }: PayloadAction<Blob | null>) {
      state.blob = payload;
      state.template = null;
    },

    setUploads(state, { payload }: PayloadAction<string[]>) {
      state.uploads = payload;
    },

    setDictionary(state, { payload }: PayloadAction<Record<string, unknown>>) {
      state.dictionary = payload;
    },

    setPageCount(state, { payload }: PayloadAction<number>) {
      const previousPageCount = state.pages.length;
      state.pages = (Array(payload) as Page[])
        .fill({ inputs: [] })
        .map((page, index) => {
          if (index >= previousPageCount) {
            return page;
          }
          return state.pages[index];
        });
    },

    setNewInput(state, { payload }: PayloadAction<Input>) {
      state.newInput = { ...payload, fontSize: state.defaultFontSize };
    },

    confirmNewInput(state, { payload = 0 }: PayloadAction<number | undefined>) {
      if (state.newInput === null) {
        return;
      }
      state.pages[payload].inputs.push(state.newInput);
      state.newInput = null;
    },

    setInputPage(state, { payload }: PayloadAction<PageSetter>) {
      const input = fetchInput(state, payload);
      delete state.pages[payload.page].inputs[payload.input];
      state.pages[payload.newPage].inputs.push(input);
    },

    setInputPosition(state, { payload }: PayloadAction<PositionSetter>) {
      const input = fetchInput(state, payload);
      input.position = zoomFixed(payload.position, state.zoom);
    },

    moveInput(state, { payload }: PayloadAction<PositionSetter>) {
      const input = fetchInput(state, payload);
      const position = zoomFixed(payload.position, state.zoom);
      input.position[0] += position[0];
      input.position[1] += position[1];
    },

    setInputSize(state, { payload }: PayloadAction<SizeSetter>) {
      const input = fetchInput(state, payload);
      assert(input.size !== undefined);
      input.size = zoomFixed(payload.size, state.zoom);
    },

    resizeInput(state, { payload }: PayloadAction<SizeSetter>) {
      const input = fetchInput(state, payload);
      assert(input.size !== undefined);
      const size = zoomFixed(payload.size, state.zoom);
      input.size[0] += size[0];
      input.size[1] += size[1];
    },

    resizeInputTop(state, { payload }: PayloadAction<PositionSetter>) {
      const input = fetchInput(state, payload);
      assert(input.size !== undefined);
      const position = zoomFixed(payload.position, state.zoom);
      const offset = input.position[1] - position[1];
      if (input.size[1] + offset < MIN_INPUT_HEIGHT) {
        return;
      }
      input.size[1] += offset;
      [, input.position[1]] = position;
    },

    resizeInputRight(state, { payload }: PayloadAction<PositionSetter>) {
      const input = fetchInput(state, payload);
      assert(input.size !== undefined);
      const position = zoomFixed(payload.position, state.zoom);
      const newSize = position[0] - input.position[0];
      if (newSize >= MIN_INPUT_WIDTH) {
        input.size[0] = newSize;
      }
    },

    resizeInputBottom(state, { payload }: PayloadAction<PositionSetter>) {
      const input = fetchInput(state, payload);
      assert(input.size !== undefined);
      const position = zoomFixed(payload.position, state.zoom);
      const newSize = position[1] - input.position[1];
      if (newSize >= MIN_INPUT_HEIGHT) {
        input.size[1] = newSize;
      }
    },

    resizeInputLeft(state, { payload }: PayloadAction<PositionSetter>) {
      const input = fetchInput(state, payload);
      assert(input.size !== undefined);
      const position = zoomFixed(payload.position, state.zoom);
      const offset = input.position[0] - position[0];
      if (input.size[0] + offset < MIN_INPUT_WIDTH) {
        return;
      }
      input.size[0] += offset;
      [input.position[0]] = position;
    },

    setInputValue(state, { payload }: PayloadAction<ValueSetter>) {
      const input = fetchInput(state, payload);
      input.value = payload.value;
    },

    setInputFontSize(state, { payload }: PayloadAction<FontSizeSetter>) {
      const input = fetchInput(state, payload);
      input.fontSize = payload.fontSize;
    },

    deleteInput(state, { payload }: PayloadAction<InputTarget>) {
      const { inputs } = state.pages[payload.page];
      state.pages[payload.page].inputs = inputs
        .slice(0, payload.input)
        .concat(inputs.slice(payload.input + 1));
    },

    increaseDefaultFontSize(state) {
      state.defaultFontSize += 1;
    },

    decreaseDefaultFontSize(state) {
      if (state.defaultFontSize <= 4) {
        return;
      }
      state.defaultFontSize -= 1;
    },

    zoom(state) {
      if (state.zoom < MAX_ZOOM) {
        state.zoom += 0.25;
      }
    },

    unzoom(state) {
      if (state.zoom > MIN_ZOOM) {
        state.zoom -= 0.25;
      }
    },

    setZoom(state, { payload }: PayloadAction<number>) {
      state.zoom = payload;
    },

    togglePreviewMode(state) {
      state.isPreviewMode = !state.isPreviewMode;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchData.fulfilled, (state, { payload }) => {
      state.blob = null;
      state.template = payload;
      state.pages = payload.data.pages;
      state.defaultFontSize = payload.data.defaultFontSize || 16;
      state.uploads = payload.public_uploads.map(up => up.link);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const {
  openFile,
  setFile,
  setPageCount,
  setNewInput,
  setUploads,
  setDictionary,
  confirmNewInput,
  setInputPage,
  setInputPosition,
  setInputFontSize,
  moveInput,
  setInputSize,
  resizeInput,
  setInputValue,
  deleteInput,
  increaseDefaultFontSize,
  decreaseDefaultFontSize,
  zoom,
  unzoom,
  setZoom,
  togglePreviewMode,
} = slice.actions;
export default slice;
