import PropTypes from 'prop-types';
import style from './RadialProgressBar.module.scss';

const CIRCUMFERENCE = parseFloat(style.circumference);

function calculateProgress(progress) {
  const data = progress / 100;
  return CIRCUMFERENCE * (1 - data);
}

const RadialProgressBar = ({ progress, extraProgress, children }) => {
  const strokeDashoffset = calculateProgress(progress);
  let extraDashoffset;
  if (extraProgress) {
    // Avoid render glitch when goal is over than 100
    extraDashoffset = calculateProgress(
      extraProgress > 100 ? 100 : extraProgress,
    );
  }
  return (
    <div className={style.bar}>
      <svg width="120" height="120" viewBox="0 0 120 120">
        <circle cx="60" cy="60" r="54" className={style.progress_meter} />
        <circle
          style={{ strokeDashoffset }}
          cx="60"
          cy="60"
          r="54"
          className={style.progress_value}
        />
        {extraProgress && (
          <circle
            style={{ strokeDashoffset: extraDashoffset }}
            cx="60"
            cy="60"
            r="54"
            className={`${style.progress_value} ${style.progress_extra}`}
          />
        )}
      </svg>
      {children}
    </div>
  );
};

RadialProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  extraProgress: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  children: PropTypes.object,
};

export default RadialProgressBar;
