/*
 * TODO: This slice is erk
 * We could improve and clarify it by using abilities instead of clients to
 * drop all the related hacks / patches.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { Model } from 'models/Model';
import { ClientJSON, ClientRole, Client } from 'models/Client';
import { Person } from 'models/Person';

import { CLIENTS_SPACE, Errors } from './constants';
import { updateClients, destroyAbility, updateClientsState } from './thunk';
import { LinksDirtyStruct } from './model';

interface EmailErrors {
  email: string;
  errors: string[];
}

export interface State {
  clients: ClientJSON[];
  dirtyClients: number[]; // As indices of `clients` array
  abilities: Record<number, number>;
  dbEmpoweredIndex: number;
  dbOwnerIndex: number;
  isDeletingRequiredClientModalOpen: boolean;
  error: Error | null;
  linksDirty: LinksDirtyStruct[];
  emailErrors: Array<EmailErrors>;
  isLoadingUpdateState: boolean;
}

export interface AppStateSubset {
  [CLIENTS_SPACE]: State;
}

export interface UpdatePayload {
  idx: number;
  client: ClientJSON;
}

export interface RoleUpdatePayload {
  idx: number;
  role: ClientRole;
}

export interface ReplacePayload {
  idx: number;
}

export interface EmailErrorsPayload {
  email: string;
  errors: Array<string>;
}

export interface EmailErrorPayload {
  email: string;
  error: string;
}

export interface SetIdOfClientProps {
  email: string;
  clientId: number;
}

export const initialState: State = {
  clients: [],
  dirtyClients: [],
  abilities: {},
  dbOwnerIndex: 0,
  dbEmpoweredIndex: 0,
  isDeletingRequiredClientModalOpen: false,
  error: null,
  linksDirty: [],
  emailErrors: [],
  isLoadingUpdateState: false,
};

function addDirtyClient(state: State, idx: number): void {
  if (!state.dirtyClients.includes(idx)) {
    state.dirtyClients.push(idx);
  }
}

function sortClients(a: ClientJSON, b: ClientJSON): number {
  if (a.special || b.special) {
    return Number(b.special) - Number(a.special);
  }
  if (a.role === ClientRole.OWNER || b.role === ClientRole.OWNER) {
    return (
      Number(b.role === ClientRole.OWNER) - Number(a.role === ClientRole.OWNER)
    );
  }
  return (a.id || 0) - (b.id || 0);
}

function formatClientStruct(
  clients: ClientJSON[],
  withSort = true,
): Partial<State> {
  const newclients = withSort ? clients.sort(sortClients) : clients;
  const abilities: Record<number, number> = {};
  clients.forEach(c => {
    if (c.id && c.ability_id) {
      abilities[c.id] = c.ability_id;
    }
  });

  return {
    dbOwnerIndex: newclients.findIndex(c => c.role === ClientRole.OWNER),
    dbEmpoweredIndex: newclients.findIndex(c => c.special),
    clients: newclients,
    linksDirty: newclients.map(client => ({
      initial: client.link,
      update: client.link,
    })),
    abilities,
  };
}

function reuseAbility(state: State, client: ClientJSON): void {
  if (!client.id || client.ability_id) {
    return;
  }

  const abilityId = state.abilities[client.id];
  if (abilityId === undefined) {
    return;
  }

  const oldAbility = state.clients.find(c => c.ability_id === abilityId);
  if (oldAbility) {
    oldAbility.ability_id = undefined;
  }
  // eslint-disable-next-line no-param-reassign
  client.ability_id = abilityId;
}

const slice = createSlice({
  name: CLIENTS_SPACE,
  initialState,
  reducers: {
    initialize(
      _state,
      { payload }: PayloadAction<ClientJSON[] | undefined>,
    ): State {
      const clients = payload || [
        { ...Person.skeleton, role: ClientRole.OWNER, special: true, link: '' },
      ];

      return {
        ...initialState,
        ...formatClientStruct([...clients]),
      };
    },

    add(state): void {
      state.dirtyClients.push(state.clients.length);
      const hasClients = state.clients.length > 0;
      state.clients.push({
        ...Person.skeleton,
        role: hasClients ? ClientRole.EDITOR : ClientRole.OWNER,
        link: '',
        special: !hasClients,
      });
      state.linksDirty.push({ initial: '', update: '' });
    },

    update(
      state,
      { payload: { idx, client } }: PayloadAction<UpdatePayload>,
    ): void {
      reuseAbility(state, client);
      /* eslint-disable no-param-reassign */
      state.clients[idx] = {
        ...client,
        ability_id: client.ability_id || state.clients[idx].ability_id,
        link: client.link !== undefined ? client.link : state.clients[idx].link,
        role: client.role || state.clients[idx].role,
        special: client.special || state.clients[idx].special,
        contact: {
          ...client.contact,
          email: client.contact.email === '' ? undefined : client.contact.email,
        },
      };
      if (client.link !== undefined) {
        state.linksDirty[idx].update = client.link;
      }
      addDirtyClient(state, idx);
      /* eslint-enable no-param-reassign */
    },

    replace(state, { payload: { idx } }: PayloadAction<ReplacePayload>): void {
      const client = state.clients[idx];

      const oldClient = new Client(cloneDeep(client));
      Model.setDestroyed(oldClient);
      state.clients.push(oldClient);
      state.linksDirty.push({ initial: '', update: '' });
      const oldClientPos = state.clients.length - 1;
      /* eslint-disable no-param-reassign */
      if (oldClient.special) {
        state.clients[oldClientPos].special = false;
        state.dbEmpoweredIndex = oldClientPos;
      }
      if (oldClient.role === ClientRole.OWNER) {
        state.clients[oldClientPos].role = ClientRole.EDITOR;
        state.dbOwnerIndex = oldClientPos;
      }
      state.clients[idx].id = undefined;
      state.clients[idx].ability_id = undefined;
      addDirtyClient(state, oldClientPos);
      /* eslint-enable no-param-reassign */
    },

    openDeletingRequiredClientModal(state): void {
      // eslint-disable-next-line no-param-reassign
      state.isDeletingRequiredClientModalOpen = true;
    },

    remove(state, { payload: idx }: PayloadAction<number>): State {
      const clients = state.clients.filter((_c, i) => i !== idx);
      const dirtyClients = state.dirtyClients
        .filter(dirty => dirty !== idx)
        .map(dirty => (dirty > idx ? dirty - 1 : dirty));

      return {
        ...state,
        ...formatClientStruct(clients, false),
        dirtyClients,
      };
    },

    removeEmailError(
      state,
      { payload: { email, error } }: PayloadAction<EmailErrorPayload>,
    ): void {
      const idx = state.emailErrors.findIndex(err => err.email === email);
      if (idx === -1) {
        return;
      }
      const errors = state.emailErrors[idx].errors.filter(msg => msg !== error);
      if (errors.length === 0) {
        state.emailErrors.splice(idx, 1);
        return;
      }
      // eslint-disable-next-line no-param-reassign
      state.emailErrors[idx] = {
        ...state.emailErrors[idx],
        errors,
      };
    },

    addEmailError(
      state,
      { payload: { email, error } }: PayloadAction<EmailErrorPayload>,
    ): void {
      const idx = state.emailErrors.findIndex(err => err.email === email);
      if (idx === -1) {
        state.emailErrors.push({ email, errors: [error] });
        return;
      }
      // eslint-disable-next-line no-param-reassign
      state.emailErrors[idx].errors.push(error);
    },

    cleanDuplicatedClientError(state): void {
      const clients = state.clients.map(client => new Client(client));
      // eslint-disable-next-line no-param-reassign
      state.emailErrors = state.emailErrors.map(emailError => {
        const { errors } = emailError;
        if (!errors.includes(Errors.DUPLICATED_LOCAL_CLIENT)) return emailError;

        const emails = clients.filter(
          client =>
            !client.isDestroyed && client.contact.email === emailError.email,
        );
        const isDuplicated = emails.length > 1;
        if (isDuplicated) {
          return emailError;
        }

        return {
          ...emailError,
          errors: emailError.errors.filter(
            err => err !== Errors.DUPLICATED_LOCAL_CLIENT,
          ),
        };
      });
    },

    softRemove(state, { payload: idx }: PayloadAction<number>): void {
      const client = state.clients[idx];
      Model.setDestroyed(client);
    },

    setIdOfClient(
      state,
      { payload: { clientId, email } }: PayloadAction<SetIdOfClientProps>,
    ): void {
      /* eslint-disable no-param-reassign */
      state.clients = state.clients.map(client => {
        if (client.contact.email !== email) {
          return client;
        }
        return {
          ...client,
          id: clientId,
        };
      });
    },

    setEmpowered(state, { payload: idx }: PayloadAction<number>): void {
      const previous = state.clients.findIndex(c => c.special);
      if (previous === idx) {
        return;
      }
      /* eslint-disable no-param-reassign */
      state.clients[idx].special = true;
      if (previous !== -1) {
        state.clients[previous].special = false;
      }
      /* eslint-enable no-param-reassign */
    },

    setRole(
      state,
      { payload: { idx, role } }: PayloadAction<RoleUpdatePayload>,
    ): void {
      if (role !== ClientRole.OWNER) {
        state.clients[idx].role = role; // eslint-disable-line no-param-reassign
        addDirtyClient(state, idx);
        return;
      }
      const previous = state.clients.findIndex(
        c => c.role === ClientRole.OWNER,
      );

      /* eslint-disable no-param-reassign */
      state.clients[idx].role = ClientRole.OWNER;
      if (previous !== -1) {
        state.clients[previous].role = ClientRole.EDITOR;
      }
      /* eslint-enable no-param-reassign */
    },

    closeDeletingRequiredClientModal(state): void {
      state.isDeletingRequiredClientModalOpen = false; // eslint-disable-line no-param-reassign
    },
  },

  extraReducers: builder => {
    // Loading state is part of the deal
    builder.addCase(updateClients.rejected, (state, { payload }): void => {
      // eslint-disable-next-line no-param-reassign
      state.error = payload as Error;
    });
    builder.addCase(
      updateClients.fulfilled,
      (state, { payload }): State => ({
        ...state,
        ...formatClientStruct([...payload]),
        dirtyClients: [],
      }),
    );
    builder.addCase(destroyAbility.rejected, (state, { payload }): void => {
      // eslint-disable-next-line no-param-reassign
      state.error = payload as Error;
    });

    builder.addCase(updateClientsState.pending, (state): void => {
      // eslint-disable-next-line no-param-reassign
      state.isLoadingUpdateState = true;
    });
    builder.addCase(updateClientsState.fulfilled, (state): void => {
      // eslint-disable-next-line no-param-reassign
      state.isLoadingUpdateState = false;
    });
    builder.addCase(updateClientsState.rejected, (state, { payload }): void => {
      // eslint-disable-next-line no-param-reassign
      state.error = payload as Error;
      // eslint-disable-next-line no-param-reassign
      state.isLoadingUpdateState = false;
    });
  },
});

export const {
  initialize,
  add,
  replace,
  remove,
  setEmpowered,
  update,
  setRole,
  softRemove,
  setIdOfClient,
  openDeletingRequiredClientModal,
  closeDeletingRequiredClientModal,
  cleanDuplicatedClientError,
  addEmailError,
  removeEmailError,
} = slice.actions;
export default slice;
