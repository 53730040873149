import { ReactNode } from 'react'
import Link from '../Link'
import style from './ResourceList.module.scss'

interface RowLink {
  href: string
  internal?: boolean
  newTab?: boolean
  state?: unknown
}

interface RowProps {
  children: ReactNode[]
  className?: string
  link?: RowLink
}

export default function Row({ children, className, link }: RowProps): JSX.Element {
  const classes = [style.row, className]

  if (!link) {
    return <div className={classes.join(' ')}>{children}</div>
  }

  return (
    <Link
      className={classes.join(' ')}
      href={link.href}
      internal={link.internal}
      newTab={link.newTab}
      state={link.state}
    >
      {children}
    </Link>
  )
}
