import { DateTime } from 'luxon';
import { Selector, createSelector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';
import type { EntityInvoicePendingPaymentJSON } from '@advitam/api/models/EntityInvoice/PendingPayment';
import { assert } from '@advitam/support';

import type { AppStateSubset, Filters, State } from './slice';
import { BILLING } from './constants';

type BillingSelector<T> = Selector<AppStateSubset, T>;

function selectBillingDomain(state: AppStateSubset): State {
  return state[BILLING];
}

export const makeSelectInvoices: () => BillingSelector<
  EntityInvoiceJSON[] | null
> = () => createSelector(selectBillingDomain, state => state.invoices);

const makeSelectLoadingInvoices: () => BillingSelector<number[]> = () =>
  createSelector(selectBillingDomain, state => state.loadingInvoices);

export const makeSelectIsInvoiceLoading: (
  id: number,
) => BillingSelector<boolean> = id =>
  createSelector(makeSelectLoadingInvoices(), loadingInvoices =>
    loadingInvoices.includes(id),
  );

const makeSelectUploadedInvoices: () => BillingSelector<number[]> = () =>
  createSelector(selectBillingDomain, state => state.uploadedInvoices);

export const makeSelectIsInvoiceUploaded: (
  id: number,
) => BillingSelector<boolean> = id =>
  createSelector(makeSelectUploadedInvoices(), uploadedInvoices =>
    uploadedInvoices.includes(id),
  );

const makeSelectLoadingValidations: () => BillingSelector<number[]> = () =>
  createSelector(selectBillingDomain, state => state.loadingValidations);

export const makeSelectIsInvoiceValidating: (
  id: number,
) => BillingSelector<boolean> = id =>
  createSelector(makeSelectLoadingValidations(), uploadedInvoices =>
    uploadedInvoices.includes(id),
  );

export const makeSelectPendingPayments: () => BillingSelector<
  EntityInvoicePendingPaymentJSON[]
> = () => createSelector(selectBillingDomain, state => state.pendingPayments);

export const makeSelectLoadingPayments: () => BillingSelector<number[]> = () =>
  createSelector(selectBillingDomain, state => state.loadingPayments);

export const makeSelectIsInvoicePaymentLoading: (
  invoiceId: number,
) => BillingSelector<boolean> = invoiceId =>
  createSelector(makeSelectLoadingPayments(), loadingPayments =>
    loadingPayments.includes(invoiceId),
  );

export const makeSelectPendingPayment: (
  invoiceId: number,
) => BillingSelector<EntityInvoicePendingPaymentJSON | null> = invoiceId =>
  createSelector(
    makeSelectPendingPayments(),
    payments =>
      payments.find(payment => payment.entity_invoice_id === invoiceId) || null,
  );

export const makeSelectArePaymentsSaving: () => BillingSelector<boolean> = () =>
  createSelector(selectBillingDomain, state => state.arePaymentsSaving);

export const makeSelectPaymentsError: () => BillingSelector<SerializedApiError | null> = () =>
  createSelector(selectBillingDomain, state => state.paymentError);

export const makeSelectIsLoading: () => BillingSelector<boolean> = () =>
  createSelector(selectBillingDomain, state => state.isLoading);

export const makeSelectError: () => BillingSelector<SerializedApiError | null> = () =>
  createSelector(selectBillingDomain, state => state.error);

export const makeSelectFilters: () => BillingSelector<Filters> = () =>
  createSelector(selectBillingDomain, state => {
    const filters = { ...state.filters };
    // TODO: Settings.throwOnInvalid = true;
    const start = DateTime.now().minus({ months: 3 }).toJSDate();
    const end = DateTime.now().toJSDate();
    assert(start !== null && end !== null);
    filters.start_date ||= start;
    filters.end_date ||= end;
    return filters;
  });

export const makeSelectPage: () => BillingSelector<number> = () =>
  createSelector(selectBillingDomain, state => state.page);

const makeSelectPageCount: () => BillingSelector<number | null> = () =>
  createSelector(selectBillingDomain, state => state.pageCount);

export const makeSelectHasMore: () => BillingSelector<boolean> = () =>
  createSelector(
    makeSelectError(),
    makeSelectPage(),
    makeSelectPageCount(),
    (error, page, pageCount) => !error && (!pageCount || page < pageCount),
  );
