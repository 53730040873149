import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { NearEntitiesFilters } from '@advitam/api/v1/Entities/Near';
import type { NearEntityJSON } from '@advitam/api/models/Entity/Near';
import { Arrays } from '@advitam/support';

import {
  DEFAULT_NEAR_RADIUS,
  MAP,
  NEAR_RADIUS_INCREASE_STEP,
} from './constants';
import { AppStateSubset } from './slice';
import { makeSelectCityhall, makeSelectEntityTypes } from './selectors';

export const fetchCityhall = createAsyncThunk(
  `${MAP}_FETCH_CITYHALL`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Cityhalls.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchNearEntities = createAsyncThunk(
  `${MAP}_FETCH_NEAR_ENTITIES`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const cityhall = makeSelectCityhall()(state);
    const entityTypes = makeSelectEntityTypes()(state);

    if (!cityhall?.latitude || !cityhall.longitude) {
      return [];
    }

    let missingEntityTypes = entityTypes;
    let radius = DEFAULT_NEAR_RADIUS;
    const entities: NearEntityJSON[] = [];

    while (missingEntityTypes.length !== 0) {
      const filters: NearEntitiesFilters = {
        latitude: cityhall.latitude,
        longitude: cityhall.longitude,
        radius,
        entity_types: missingEntityTypes,
      };

      try {
        // eslint-disable-next-line no-await-in-loop
        const { body } = await request(Api.V1.Entities.Near.index(filters));
        entities.push(...body);
      } catch (err) {
        return rejectWithValue(err);
      }

      const presentEntityTypes = entities.map(entity => entity.type);
      missingEntityTypes = Arrays.difference(entityTypes, presentEntityTypes);
      radius += NEAR_RADIUS_INCREASE_STEP;
    }

    return entities;
  },
);
