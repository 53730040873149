import { createSlice } from '@reduxjs/toolkit';

import { MemorialJSON } from 'models/Memorial';

import { fetchMemorial, updateMemorial } from './thunk';
import { MEMORIAL_SPACE } from './constants';

export interface State {
  memorial: MemorialJSON | null;
  isLoading: boolean;
  error: Error | null;
}

export interface AppStateSubset {
  [MEMORIAL_SPACE]: State;
}

const initialState: State = {
  memorial: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: MEMORIAL_SPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchMemorial.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchMemorial.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.memorial = payload;
    });
    builder.addCase(fetchMemorial.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as Error;
    });

    builder.addCase(updateMemorial.fulfilled, (state, { payload }) => {
      state.memorial = payload;
    });
    builder.addCase(updateMemorial.rejected, (state, { payload }) => {
      state.error = payload as Error;
    });
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
