import Link from '../../Link'
import Text from '../../Text'
import style from '../Pagination.module.scss'

interface ItemProps {
  index: number
  isSelected: boolean
  isHidden?: boolean
  onClick?: (i: number) => void
  getHref?: (i: number) => string
}

export default function Item({
  index,
  isSelected,
  isHidden = false,
  onClick,
  getHref,
}: ItemProps): JSX.Element | null {
  const classes = [style.item]
  const liClasses = []
  if (isSelected) {
    liClasses.push(style.selected)
  }
  if (isHidden) {
    liClasses.push(style.hidden)
  }

  if (onClick) {
    classes.push(style.button)
    return (
      <li className={liClasses.join(' ')}>
        <button type="button" className={classes.join(' ')} onClick={(): void => onClick(index)}>
          <Text small>{index}</Text>
        </button>
      </li>
    )
  }

  if (getHref) {
    classes.push(style.link)
    return (
      <li className={liClasses.join(' ')}>
        <Link internal className={classes.join(' ')} href={getHref(index)}>
          <Text small>{index}</Text>
        </Link>
      </li>
    )
  }

  return null
}
