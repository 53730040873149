export class MemoryStorage {
  private storage: Record<string, string> = {}

  getItem(key: string): string | null {
    return key in this.storage ? this.storage[key] : null
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value
  }

  removeItem(key: string): void {
    delete this.storage[key]
  }

  clear(): void {
    this.storage = {}
  }
}
