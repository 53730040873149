import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import type { StayJSON } from '@advitam/api/models/FuneralParlor';

import { makeSelectFilters } from './selectors';
import { StayFilters } from './types';

type IsFilteredFn = (stay: StayJSON) => boolean;

export function useIsFiltered(): IsFilteredFn {
  const filters = useSelector(makeSelectFilters());

  return useCallback(
    (stay: StayJSON) =>
      Object.entries(filters).some(
        ([key, value]) =>
          value !== undefined && stay[key as keyof StayFilters] !== value,
      ),
    [filters],
  );
}
