import { useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';

import { usePrompt } from 'lib/react-router';

import messages from './messages';

function preventLeaving(event: Event): string {
  event.preventDefault();
  return '';
}

export default function usePreventLeaving(isDirty = true): void {
  const intl = useIntl();

  usePrompt(intl.formatMessage(messages.unsavedChanges), isDirty);
  useLayoutEffect(() => {
    if (!isDirty) {
      return undefined;
    }

    window.addEventListener('beforeunload', preventLeaving);
    return (): void =>
      window.removeEventListener('beforeunload', preventLeaving);
  }, [isDirty]);
}
