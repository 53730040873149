import { InputHTMLAttributes, ReactNode } from 'react'
import { Field, UseFieldConfig } from 'react-final-form'

import { Rating } from '../UI'
import { Number } from '../converters'
import { composeValidators, isRequired, Validator } from '../validators'

interface RatingFieldProps {
  label: ReactNode
  name: string
  required?: boolean
  tooltip?: ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  validate?: Validator<InputHTMLAttributes<HTMLInputElement>['value']>
  className?: string
}

export default function RatingField({
  label,
  name,
  required,
  tooltip,
  parse = Number.parse,
  validate,
  className,
}: RatingFieldProps): JSX.Element {
  const validators = composeValidators(required && isRequired, validate)
  return (
    <Field name={name} type="input" parse={parse} validate={validators}>
      {({ input: { onChange, value }, meta: { touched, error } }): JSX.Element => (
        <Rating
          name={name}
          label={label}
          onChange={onChange}
          value={value as number}
          tooltip={tooltip}
          error={Boolean(touched && error)}
          className={className}
        />
      )}
    </Field>
  )
}
