import { createSelector } from 'reselect';

const selectAccountDomain = state => state.account;

const selectStats = () =>
  createSelector(selectAccountDomain, substate => substate.stats);

const selectStatsPerMonth = () =>
  createSelector(selectAccountDomain, substate => substate.statsPerMonth);

const makeSelectUserStats = () =>
  createSelector(selectStats(), substate => (substate && substate.user) || {});

const makeSelectTeamStats = () =>
  createSelector(selectStats(), substate => (substate && substate.team) || {});

const makeSelectUserStatsPerMonth = () =>
  createSelector(
    selectStatsPerMonth(),
    substate => (substate && substate.user) || {},
  );

const makeSelectTeamStatsPerMonth = () =>
  createSelector(
    selectStatsPerMonth(),
    substate => (substate && substate.team) || {},
  );

const makeSelectLoadingStats = () =>
  createSelector(selectAccountDomain, substate => substate.loadingStats);

const makeSelectLoadingStatsPerMonth = () =>
  createSelector(
    selectAccountDomain,
    substate => substate.loadingStatsPerMonth,
  );

export {
  selectAccountDomain,
  makeSelectLoadingStats,
  makeSelectLoadingStatsPerMonth,
  makeSelectUserStats,
  makeSelectTeamStats,
  makeSelectUserStatsPerMonth,
  makeSelectTeamStatsPerMonth,
};
