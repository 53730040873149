import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { SupplierJSON } from '@advitam/api/models/Supplier';
import { assert } from '@advitam/support';

import { SUPPLIER } from './constants';
import {
  createSupplier,
  destroySupplier,
  fetchSupplier,
  setIsSupplierDisabled,
  updateSupplier,
  updateSupplierName,
} from './thunk';
import type { NewSupplier } from './types';

export interface State {
  supplier: SupplierJSON | NewSupplier | null;
  isDestroying: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isUpdatingName: boolean;
  destroyError: SerializedApiError | null;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [SUPPLIER]: State;
}

export const initialState: State = {
  supplier: null,
  isDestroying: false,
  isLoading: false,
  isSaving: false,
  isUpdatingName: false,
  destroyError: null,
  error: null,
};

const slice = createSlice({
  name: SUPPLIER,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearError(state) {
      state.error = null;
    },
    setSupplier: (
      state,
      { payload }: PayloadAction<SupplierJSON | NewSupplier>,
    ) => {
      state.supplier = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSupplier.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.supplier = null;
    });
    builder.addCase(fetchSupplier.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.supplier = payload;
    });
    builder.addCase(fetchSupplier.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createSupplier.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(createSupplier.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(createSupplier.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateSupplier.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateSupplier.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.supplier = payload;
    });
    builder.addCase(updateSupplier.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateSupplierName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updateSupplierName.fulfilled, (state, { payload }) => {
      state.isUpdatingName = false;
      state.supplier = payload;
    });
    builder.addCase(updateSupplierName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroySupplier.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroySupplier.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroySupplier.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setIsSupplierDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setIsSupplierDisabled.fulfilled, (state, { meta }) => {
      assert(state.supplier !== null);
      state.isSaving = false;
      state.supplier.disabled = meta.arg;
    });
    builder.addCase(setIsSupplierDisabled.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setSupplier } = slice.actions;
export default slice;
