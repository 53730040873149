import { defineMessages } from 'react-intl';

export default defineMessages({
  type: {
    id: 'app.containers.DealTypeWrapper.EditPerson.type',
  },
  firstName: {
    id: 'app.containers.DealTypeWrapper.EditPerson.firstName',
  },
  lastName: {
    id: 'app.containers.DealTypeWrapper.EditPerson.lastName',
  },
  birthName: {
    id: 'app.containers.DealTypeWrapper.EditPerson.birthName',
  },
  email: {
    id: 'app.containers.DealTypeWrapper.EditPerson.email',
  },
  homePhone: {
    id: 'app.containers.DealTypeWrapper.EditPerson.homePhone',
  },
  mobilePhone: {
    id: 'app.containers.DealTypeWrapper.EditPerson.mobilePhone',
  },
  link: {
    id: 'app.containers.DealTypeWrapper.EditPerson.link',
  },
  address: {
    id: 'app.containers.DealTypeWrapper.EditPerson.address',
  },
  nationality: {
    id: 'app.containers.DealTypeWrapper.EditPerson.nationality',
  },
  birthLocation: {
    id: 'app.containers.DealTypeWrapper.EditPerson.birthLocation',
  },
  work: {
    id: 'app.containers.DealTypeWrapper.EditPerson.work',
  },
  emailIsNotValid: {
    id: 'app.containers.DealTypeWrapper.EditPerson.emailIsNotValid',
  },
});
