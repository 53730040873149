import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { Address } from '@advitam/ui/components/Form/GooglePlace/types';

import { assert } from 'lib/support';

import { AppStateSubset as WarehouseAppStateSubset } from '../../slice';
import {
  makeSelectRawWarehouseZones,
  makeSelectRawWarehouse,
} from '../../selectors';
import { AUTO_ADDITION_SKELETON, SUPPLIER_WAREHOUSE_ZONES } from './constants';
import { serializeWarehouseAddress } from './serializers';
import { AppStateSubset as ZonesAppStateSubset, State } from './slice';
import { ZoneSectionValue, CoveragesPerZone } from './types';

type AppStateSubset = ZonesAppStateSubset & WarehouseAppStateSubset;

type ZonesSelector<T> = Selector<AppStateSubset, T>;

const selectWarehouseZonesDomain = (state: AppStateSubset): State =>
  state[SUPPLIER_WAREHOUSE_ZONES];

export const makeSelectCoveragesPerZones = (): ZonesSelector<
  CoveragesPerZone
> =>
  createSelector(
    selectWarehouseZonesDomain,
    ({ zonesCoverages }) => zonesCoverages,
  );

export const makeSelectWarehouseAddress = (): ZonesSelector<Address> =>
  createSelector(makeSelectRawWarehouse(), warehouse => {
    assert(warehouse !== null);
    return serializeWarehouseAddress(warehouse);
  });

export const makeSelectInitialValues = (): ZonesSelector<ZoneSectionValue[]> =>
  createSelector(
    makeSelectWarehouseAddress(),
    makeSelectRawWarehouseZones(),
    makeSelectCoveragesPerZones(),
    (warehouseAddress, zones, zonesCoverages) =>
      zones.map(zone => ({
        zone,
        coverage: zonesCoverages[zone.id] || [],
        autoAddition: {
          ...AUTO_ADDITION_SKELETON,
          centerAddress: warehouseAddress,
        },
      })),
  );

export const makeSelectIsLoading = (): ZonesSelector<boolean> =>
  createSelector(selectWarehouseZonesDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): ZonesSelector<boolean> =>
  createSelector(selectWarehouseZonesDomain, ({ isSaving }) => isSaving);

export const makeSelectHasMapSynchronized = (): ZonesSelector<boolean> =>
  createSelector(
    selectWarehouseZonesDomain,
    ({ hasMapSynchronized }) => hasMapSynchronized,
  );

export const makeSelectError = (): ZonesSelector<SerializedApiError | null> =>
  createSelector(selectWarehouseZonesDomain, ({ error }) => error);
