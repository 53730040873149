export const enum MediaType {
  VIDEO = 'video',
  MUSIC = 'music',
  PHOTO = 'photo',
}

export const enum MediaSupport {
  CD = 'cd',
  DVD = 'dvd',
  USB = 'usb',
  VHS = 'vhs',
  VINYL = 'vinyl',
}

export const enum MediaFormats {
  JPEG = 'jpeg',
  PNG = 'png',
  AVI = 'avi',
  MP4 = 'mp4',
  MP3 = 'mp3',
}
