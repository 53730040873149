import { IntlShape, useIntl } from 'react-intl';

import { ClientAbility } from 'models/Client/Ability';
import { ClientResourceDeal } from 'models/Client/Resource/Deal';
import { formatBusinessId } from './Deals';

export function formatInfos(ability: ClientAbility, intl: IntlShape): string {
  if (ability.resource instanceof ClientResourceDeal) {
    return formatBusinessId(ability.resource, intl);
  }

  return intl.formatDate(new Date(ability.resource.created_at));
}

interface FormattedInfosProps {
  ability: ClientAbility;
}

export function FormattedInfos({ ability }: FormattedInfosProps): JSX.Element {
  const intl = useIntl();
  return <>{formatInfos(ability, intl)}</>;
}
