import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { requestAsync as request } from 'api';
import { OpsLogJSON } from 'models/OpsLog';
import { assert } from 'lib/Assert';

import { PAYMENTS_OPS_LOGS } from './constants';

export const createOpsLog = createAsyncThunk(
  `${PAYMENTS_OPS_LOGS}/CREATE_OPS_LOG`,
  async (opsLog: OpsLogJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.OpsLogs.create(opsLog));
      assert(body !== null);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
