import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  commentary: { id: 'app.containers.RegionalHealthAuthority.commentary' },
  miscellaneous: { id: 'app.containers.RegionalHealthAuthority.miscellaneous' },
  general: { id: 'app.containers.RegionalHealthAuthority.general' },
  titlePlaceholder: {
    id: 'app.containers.RegionalHealthAuthority.titlePlaceholder',
  },
});
