import { FormattedMessage } from 'react-intl';
import { Layout } from 'containers/Crud';
import { SelectableCityhalls } from 'containers/Crud/Input';
import messages from '../messages';

const PREFIX = 'graveyard';

export function CoveredPlaces(): JSX.Element {
  return (
    <Layout.Fieldset
      title={<FormattedMessage id={messages.coveredPlaces.id} />}
      tooltip={<FormattedMessage id={messages.coveredPlacesTooltip.id} />}
    >
      <SelectableCityhalls
        name={`${PREFIX}.cityhalls`}
        label={<FormattedMessage id={messages.place.id} />}
      />
    </Layout.Fieldset>
  );
}
