export enum DocumentBatchRecipientState {
  FAILED = 'failed',
  PENDING = 'pending',
  NOTHING = 'nothing',
  DISPATCHED = 'dispatched',
  RECEIVED = 'received',
  SENT = 'sent',
}

export interface DocumentBatchRecipientJSON {
  sent_by: string
  sent_to: string
  state: DocumentBatchRecipientState
}
