import { authenticate } from '../../lib/decorators'
import { get } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import { IndexEntityJSON } from '../../models/Entity/Index'
import { EntityType } from '../../models/Entity/EntityType'
import { Near } from './Near'

export interface EntitiesIndexFilters {
  q?: string
  entity_type?: EntityType
  department?: string
  insee_code?: string
  page?: number
  per_page?: number
}

export class Entities {
  static readonly Near = Near

  @authenticate
  static index(filters: EntitiesIndexFilters): ApiRequestDescriptor<IndexEntityJSON[]> {
    return get('/api/v1/entities', filters)
  }
}
