import type { ReactNode } from 'react'

import Link from '../../../../Link'
import { useSetIsMenuOpen } from '../../hooks'
import style from '../../Menu.module.scss'

export interface DropdownItemProps {
  text: ReactNode
  href: string
  subtext?: ReactNode
  internal?: boolean
}

export default function MenuDropdownItem({
  text,
  href,
  subtext,
  internal,
}: DropdownItemProps): JSX.Element {
  const setIsMenuOpen = useSetIsMenuOpen()
  return (
    <Link
      internal={internal}
      href={href}
      onClick={(): void => setIsMenuOpen(false)}
      className={style.link_text}
    >
      {text}
      {subtext && (
        <>
          <br />
          <span className={[style.link_text, style.sublink_text].join(' ')}>{subtext}</span>
        </>
      )}
    </Link>
  )
}
