import { ReactNode } from 'react'

import ItemWrapper from '../Item'
import style from './Empty.module.scss'

interface ItemProps {
  text?: ReactNode
  dark?: boolean
}

function getRandomWidth(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default function Item({ text, dark }: ItemProps): JSX.Element {
  const classes = [style.item]
  if (dark) {
    classes.push(style.dark)
  }

  return (
    <ItemWrapper className={classes.join(' ')}>
      {text || <span className={style.loader} style={{ width: `${getRandomWidth(20, 50)}%` }} />}
      <span className={style.loader} style={{ width: `${getRandomWidth(10, 25)}%` }} />
    </ItemWrapper>
  )
}
