import { assert } from 'lib/Assert';
import { Client, ClientJSON, ClientRole } from 'models/Client';

export interface CreationPayload {
  client_id: number;
  role: ClientRole;
  link?: string;
}

export interface UpdatePayload {
  role?: ClientRole;
  link?: string;
}

export function serializeForCreation(
  client: Client | ClientJSON,
): CreationPayload {
  assert(client.id !== undefined);
  return {
    client_id: client.id,
    role: client.role || ClientRole.EDITOR,
    link: client.link,
  };
}

export function serializeForUpdate(client: Client | ClientJSON): UpdatePayload {
  const { role, link } = client;
  if (role === ClientRole.OWNER) {
    return { link };
  }
  return { role, link };
}
