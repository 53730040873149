import { ApiRequestDescriptor } from '../../lib/request'
import { AuthResponse } from '../../next/Auth'
import * as Next from '../../next'
import { Role } from '../BusinessUser/Role'
import { Civility } from '../Person/Civility'
import { User, UserJSON, UserType } from './User'

export interface BusinessUserJSON extends UserJSON {
  image: string
  created_at: string
  updated_at: string
  role: Role
  phone: string | null
  firstname: string | null
  lastname: string
  civility: Civility
  funeral_brand_id: number
  company_id: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BusinessUser extends BusinessUserJSON {}

export class BusinessUser extends User<BusinessUserJSON> {
  get type(): UserType {
    return UserType.BUSINESS_USER
  }

  get validateTokenRequestDescriptor(): ApiRequestDescriptor<AuthResponse<BusinessUserJSON>> {
    return Next.Pro.Auth.validateToken()
  }

  get logoutRequestDescriptor(): ApiRequestDescriptor<AuthResponse> {
    return Next.Pro.Auth.signOut()
  }

  get isFuneralDirectorOrAbove(): boolean {
    return this.role <= Role.FUNERAL_DIRECTOR
  }

  get isAdmin(): boolean {
    return this.role === Role.ADMIN
  }
}
