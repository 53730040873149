import { RegionalHealthAuthorityJSON } from '@advitam/api/models/RegionalHealthAuthority';
import { formatEntity } from '../Entity';

export function formatName(rha: RegionalHealthAuthorityJSON): string {
  return formatEntity(rha);
}

export function FormattedName(rha: RegionalHealthAuthorityJSON): JSX.Element {
  return <>{formatName(rha)}</>;
}
