import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConfirmationModal,
  ErrorText,
  FormattedApiError,
  Text,
} from '@advitam/ui';
import { withSlice } from '@advitam/react';

import actionsMessages from 'messages/actions';

import messages from './messages';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectDeletingItem,
} from './selectors';
import slice, { setDeletingItem } from './slice';
import { deleteItem } from './thunk';
import style from './DeletionModal.module.scss';

function DeletionModal(): JSX.Element {
  const dispatch = useDispatch();

  const deletingItem = useSelector(makeSelectDeletingItem());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const onClose = useCallback((): void => {
    dispatch(setDeletingItem(null));
  }, [dispatch]);

  const onConfirm = useCallback((): void => {
    dispatch(deleteItem());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isOpen={Boolean(deletingItem)}
      className={`col-4 ${style.modal}`}
      confirm={<FormattedMessage id={actionsMessages.confirm.id} />}
      onConfirm={onConfirm}
      cancel={<FormattedMessage id={actionsMessages.cancel.id} />}
      onCancel={onClose}
      title={<FormattedMessage id={messages.title.id} />}
      isConfirmationLoading={isLoading}
      text={
        <>
          <Text tagName="p">
            <FormattedMessage
              id={messages.description.id}
              values={{ title: <b>{deletingItem?.title}</b> }}
            />
          </Text>

          {error && (
            <ErrorText>
              <FormattedApiError error={error} />
            </ErrorText>
          )}
        </>
      }
    />
  );
}

export default withSlice(slice)(DeletionModal);
