import type { RequestDocumentTodoItem } from '@advitam/api/models/Deal/TodoItem';

import Task from '../parts/Task';
import DocumentRequest from './MissingDocuments';

interface RequestDocumentProps {
  item: RequestDocumentTodoItem;
}

export default function RequestDocument({
  item,
}: RequestDocumentProps): JSX.Element {
  return (
    <>
      <Task item={item} />
      {!item.isDone && <DocumentRequest item={item} />}
    </>
  );
}
