import { setDealFields } from 'containers/Deal/slice.ts';
import {
  PRESTATION_COMPUTE_MANUAL,
  PRESTATION_STATUS_NONE,
  SKELETON_PRESTATION,
} from 'components/PrestationDialog/constants';
import { GET_PRODUCT } from '../constants.ts';

/**
 * Function to get product from api and set them in store
 *
 * @param {number} id id of product to fetch
 * @param {array} products list of current products
 * @returns {Object}
 */
export function fetchProduct(id, callback) {
  return {
    type: GET_PRODUCT,
    id,
    callback,
  };
}

export function setProducts(products, isTouched) {
  return setDealFields({
    isTouched,
    update: {
      item: { products },
    },
  });
}

/**
 * Adds the product payload received from the API. isNewDeal decides if we put
 * the product in the pending state or directly in the deal.
 *
 * @param      {array}   steps    Array which contains all the steps of deal.
 * @param      {object}  product  The product object.
 * @param      {object}  deal
 * @param      {object}  dealType
 * @return     {array}            The new array with new product
 */
export function addProduct(products, product) {
  const newProducts = [...products];

  newProducts.unshift({
    ...SKELETON_PRESTATION,
    amount: 1,
    type: product.productObject.type,
    priceLine: {
      ...SKELETON_PRESTATION.priceLine,
      ...product.priceLine,
      ...product.priceLine.supplier,
    },
    group_name: product.productObject.group_name,
    step_type: product.productObject.step_type,
    productObject: {
      ...product.productObject,
    },
    item_id: product.productObject.id,
    compute: PRESTATION_COMPUTE_MANUAL,
    variants: [
      ...product.variants,
      {
        ...product.productObject,
        id: null,
      },
    ],
    status: PRESTATION_STATUS_NONE,
  });

  return setProducts(newProducts, true);
}

/**
 * Function to update a product
 *
 * @param {array} products list of all products
 * @param {object} updatedProduct product updated
 * @param {object} deal
 */
export function updateProduct(products, updatedProduct, isTouched = true) {
  const updatedProducts = products.map(product => {
    if (
      product.productObject.product_id !==
      updatedProduct.productObject.product_id
    )
      return product;
    const updatedVariants = [
      ...updatedProduct.variants,
      {
        ...updatedProduct.productObject,
        id: null,
      },
    ];
    return {
      ...product,
      variants: updatedVariants,
    };
  });

  return setProducts(updatedProducts, isTouched);
}
