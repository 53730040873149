import { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Allow you to click on any element
 */
class TransparentButton extends PureComponent {
  render() {
    const { children, onClick, className, style, isDoubleClick } = this.props;
    return (
      <div
        className={className}
        tabIndex={0}
        role="button"
        onKeyDown={() => {}}
        onClick={isDoubleClick ? null : onClick}
        style={style}
        onDoubleClick={isDoubleClick ? onClick : null}
      >
        {children}
      </div>
    );
  }
}

TransparentButton.propTypes = {
  /** Function to apply when user click */
  onClick: PropTypes.func,
  /** Element clickable */
  children: PropTypes.node,
  /** set className */
  className: PropTypes.string,
  /** set style inline */
  style: PropTypes.object,
  /** The function is apply on double click or not */
  isDoubleClick: PropTypes.bool,
};

TransparentButton.defaultProps = {
  className: 'transparentButton',
  style: {},
  isDoubleClick: false,
};

export default TransparentButton;
