import { useSelector } from 'react-redux';

import { assert } from '@advitam/support';
import { DealState } from 'models/Deal/State';

import {
  UnsubscribeSection,
  MemorialSection,
} from '../../DealFuneral/sections.js';
import OrdersLinked from '../../OrdersLinked/index.js';
import { makeSelectDeal } from '../../selectors.typed';

export default function Miscellaneous(): JSX.Element | null {
  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);
  const isMemorialDisplayed =
    deal.state === DealState.IN_PROGRESS || deal.state === DealState.DONE;

  if (deal.uuid === undefined) {
    return null;
  }

  return (
    <>
      <OrdersLinked dealUUID={deal.uuid} />
      <UnsubscribeSection />
      {isMemorialDisplayed && <MemorialSection dealUUID={deal.uuid} />}
    </>
  );
}
