import { createSelector, Selector } from 'reselect';
import { DateTime } from 'luxon';

import {
  BatchLog,
  BatchLogJSON,
} from '@advitam/api/models/Deal/Document/Batch/Log';
import type { SerializedApiError } from '@advitam/api';

import { HISTORY } from './constants';
import type { AppStateSubset, State } from './slice';

type HistorySelector<T> = Selector<AppStateSubset, T>;

function selectHistoryDomain(state: AppStateSubset): State {
  return state[HISTORY];
}

function makeSelectRawBatchesLogs(): HistorySelector<BatchLogJSON[]> {
  return createSelector(selectHistoryDomain, ({ batchesLogs }) => batchesLogs);
}

export function makeSelectBatchesLogs(): HistorySelector<BatchLog[]> {
  return createSelector(makeSelectRawBatchesLogs(), rawBatchesLogs => {
    const batchesLogs = rawBatchesLogs.map(batchLog => new BatchLog(batchLog));

    return batchesLogs.sort((a, b) => {
      const aDate = a.last_try_at && DateTime.fromJSDate(a.last_try_at);
      const bDate = b.last_try_at && DateTime.fromJSDate(b.last_try_at);

      if (!aDate) {
        return -1;
      }

      if (!bDate) {
        return 1;
      }

      return bDate.toMillis() - aDate.toMillis();
    });
  });
}

export function makeSelectIsLoading(): HistorySelector<boolean> {
  return createSelector(selectHistoryDomain, ({ isLoading }) => isLoading);
}

export function makeSelectError(): HistorySelector<SerializedApiError | null> {
  return createSelector(selectHistoryDomain, ({ error }) => error);
}
