import type {
  ChangeEventHandler,
  FocusEvent,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
} from 'react'

import Text from '../../../Text'
import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'

interface BaseInputProps {
  value: string | null
  type?: HTMLInputElement['type']
  label?: ReactNode
  name?: string
  placeholder?: string
  error?: ReactElement | boolean
  disabled?: boolean
  maxLength?: number
  tooltip?: ReactNode
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>
  className?: string
  renderBelow?: (value: string) => ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
}

interface ReadOnlyInputProps extends BaseInputProps {
  readOnly: true
  onChange?: undefined
}

interface EditableInputProps extends BaseInputProps {
  readOnly?: false
  onChange: ChangeEventHandler<HTMLInputElement>
}

type InputProps = ReadOnlyInputProps | EditableInputProps

export default function Input({
  value,
  type = 'text',
  label,
  name,
  placeholder,
  error,
  disabled,
  maxLength,
  tooltip,
  onChange,
  readOnly,
  onBlur,
  onKeyDown,
  onKeyUp,
  renderBelow,
  prefix,
  suffix,
  className = '',
}: InputProps): JSX.Element {
  const wrapperClasses = [
    uiStyle.input,
    error && uiStyle.error,
    disabled && uiStyle.disabled,
    className,
  ].filter(Boolean)

  const elementClasses = [
    uiStyle.input_element,
    value && value.length > 0 && uiStyle.filled,
    prefix && uiStyle.with_prefix,
    suffix && uiStyle.with_suffix,
  ].filter(Boolean)

  return (
    <Label value={label} tooltip={tooltip} error={error} className={uiStyle.field}>
      <span className={wrapperClasses.join(' ')}>
        {prefix}
        <input
          value={value || ''}
          name={name}
          type={type}
          placeholder={placeholder}
          className={elementClasses.join(' ')}
          disabled={disabled}
          maxLength={maxLength}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        {suffix}
      </span>
      {renderBelow && (
        <Text small className={uiStyle.below}>
          {renderBelow(value || '')}
        </Text>
      )}
    </Label>
  )
}
