/*
 *  Select component to render a select input element with formatted messages labels
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { SelectList } from 'components/Input/SelectList';

class SelectChoice extends PureComponent {
  render() {
    const {
      intl,
      options,
      value,
      isReadingMode,
      messages,
      id,
      emptyOption,
      name,
      isLabel,
      ...props
    } = this.props;

    const searchedChoice = options.find(option => option.value === value);
    const formattedValue = value !== undefined && value !== null ? value : '';
    const className = `inputChoice__input ${
      isLabel ? 'col-7 col-8-lg' : 'fullWidth'
    }`;
    return (
      <div className="inputChoice">
        {isLabel && (
          <div className="inputChoice__label col-5 col-4-lg">
            {intl.formatMessage({ ...messages[id] })}
          </div>
        )}
        {isReadingMode ? (
          (searchedChoice && searchedChoice.name) || ''
        ) : (
          <SelectList
            {...props}
            name={name}
            intl={intl}
            value={formattedValue}
            disabled={isReadingMode}
            className={className}
            options={options}
            emptyOption={emptyOption}
          />
        )}
      </div>
    );
  }
}

SelectChoice.propTypes = {
  intl: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  isReadingMode: PropTypes.bool,
  emptyOption: PropTypes.bool,
  messages: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  /** the label should be displayed */
  isLabel: PropTypes.bool,
};

SelectChoice.defaultProps = {
  isLabel: true,
};

export default injectIntl(SelectChoice);
