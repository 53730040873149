import { Component, ComponentClass, ComponentType } from 'react'
import type { ReactReduxContextValue } from 'react-redux'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { ReactReduxContext } from 'react-redux'
import { compose, Slice } from '@reduxjs/toolkit'

import getInjectors from './reducerInjectors'
import { Store } from './Store'

function withSliceImpl<T>(slice: Slice) {
  return (WrappedComponent: ComponentType<T>): ComponentType<T> => {
    class ReducerInjector extends Component<T> {
      static WrappedComponent = WrappedComponent

      constructor(props: T, context: ReactReduxContextValue) {
        super(props)

        const { store } = context
        getInjectors(store as Store).injectReducer(slice.name, slice.reducer)
      }

      render(): JSX.Element {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <WrappedComponent {...this.props} />
      }
    }

    ReducerInjector.contextType = ReactReduxContext
    ;(ReducerInjector as ComponentClass<T>).displayName = `withSlice(${
      WrappedComponent.displayName || WrappedComponent.name || '<component>'
    })`

    return hoistNonReactStatics(ReducerInjector, WrappedComponent)
  }
}

export function withSlice<T>(
  ...slices: Slice[]
): (WrappedComponent: ComponentType<T>) => ComponentType<T> {
  return compose(...slices.map(slice => withSliceImpl(slice)))
}
