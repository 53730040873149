import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { saveHospital } from '../../thunk';
import Wrapper from '../Wrapper';
import { HospitalForm } from '../types';
import Contents from './Contents';

export default function Miscellaneous(): JSX.Element {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: HospitalForm): void => {
      dispatch(saveHospital(values.hospital));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Contents />
    </Wrapper>
  );
}
