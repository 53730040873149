import Zones from './Zones';
import Zone from './Zone';
import General from './General';
import Miscellaneous from './Miscellaneous';
import Products from './Products';

export default {
  Zones,
  Zone,
  General,
  Miscellaneous,
  Products,
};
