import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  addDocument: { id: 'app.containers.DocumentsEditor.Header.addDocument' },
  cancelText: { id: 'app.containers.DocumentsEditor.Header.cancelText' },
  cutAllPages: { id: 'app.containers.DocumentsEditor.Header.cutAllPages' },
  reset: { id: 'app.containers.DocumentsEditor.Header.reset' },
  rotatePagesLeft: {
    id: 'app.containers.DocumentsEditor.Header.rotatePagesLeft',
  },
  rotatePagesRight: {
    id: 'app.containers.DocumentsEditor.Header.rotatePagesRight',
  },
});
