import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';
import SendIcon from '@advitam/ui/images/icons/send.svg';

import { setBookingItem } from '../../SupplierBookingModal/slice';
import Action from '../parts/Action';
import Task from '../parts/Task';
import messages from '../messages';

interface SupplierBookingProps {
  item: TodoItem;
}

export default function SupplierBooking({
  item,
}: SupplierBookingProps): JSX.Element {
  const dispatch = useDispatch();

  const onSupplierBook = (): void => {
    dispatch(setBookingItem(item));
  };

  return (
    <Task
      item={item}
      action={
        <Action
          icon={<SendIcon />}
          message={<FormattedMessage id={messages.book.id} />}
          onClick={onSupplierBook}
        />
      }
    />
  );
}
