import { authenticate } from '../../lib/decorators'
import { put } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { FullClientJSON } from '../../models/Client/Full'
import type { DefunctJSON } from '../../models/Defunct'
import { serializeForUpdate } from './serializers'
import { SupportingDocuments } from './SupportingDocuments'

export class Defuncts {
  static readonly SupportingDocuments = SupportingDocuments

  @authenticate
  static update(defunct: DefunctJSON | FullClientJSON): ApiRequestDescriptor<DefunctJSON> {
    const { defunctId, payload } = serializeForUpdate(defunct)
    return put(`/api/v1/defuncts/${defunctId}`, payload)
  }
}
