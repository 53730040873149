import { CSSProperties } from 'react';
import { injectIntl } from 'react-intl';

import ResizableInput from './Resizable';
import style from '../Engine.module.scss';

class ManualZoneInput extends ResizableInput<undefined> {
  protected getStyle(): CSSProperties {
    return {
      ...super.getStyle(),
      zIndex: 0,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  renderInput(): JSX.Element | null {
    const { isEditMode, isPreviewMode } = this.props;
    if (!isEditMode || isPreviewMode) {
      return null;
    }

    return <span tabIndex={-1} className={style.manual_zone} />;
  }
}

export default injectIntl(ManualZoneInput);
