import download from 'downloadjs'

import { Arrays, assert, sleep } from '@advitam/support'

import { updateTokensFromResponse, hasTokens } from './tokens'
import { onTokensExpired } from './onTokensExpired'
import { Model } from '../models/Model'
import { EXTENSION_MIME_TYPES, MimeTypes } from './mime_types'

import ApiError from './Error/ApiError'
import * as Company from './company'

export type JSONScalar<T = unknown> = boolean | number | string | Date | Blob | Model<T>
export type JSONValue<T = unknown> = JSONScalar<T> | JSONScalar<T>[]
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars
export interface JSONObject<_T = unknown> extends Object {}
export type ApiPayload<T = unknown> = JSONObject<T> | JSONValue<T>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ApiRequestDescriptor<_ResponseType, PayloadType = unknown> {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  endpoint: string
  headers: Record<string, string | null>
  payload?: ApiPayload<PayloadType>
  credentials?: 'omit' | 'same-origin' | 'include'
}

export interface ApiResponse<T = unknown> {
  body: T
  headers: Response['headers']
}

async function parseResponseBody<T = unknown>(res: Response): Promise<string | Blob | T | null> {
  if (res.status === 204) {
    return null
  }

  const contentType = res.headers.get('content-type')
  if (contentType?.startsWith(MimeTypes.PDF)) {
    return res.blob()
  }
  if (contentType?.startsWith(MimeTypes.JSON)) {
    return res.json() as Promise<T>
  }
  return res.text()
}

function addCompanyHeader(headers: Record<string, string | null>): Record<string, string | null> {
  const company = Company.get()
  if (company === undefined) {
    return { ...headers }
  }
  return {
    ...headers,
    [Company.HEADER_NAME]: company.id.toString(),
  }
}

function consolidateRequest<ResponseType, PayloadType>(
  req: ApiRequestDescriptor<ResponseType, PayloadType>,
): RequestInit {
  const headers = addCompanyHeader(req.headers)
  const params: RequestInit = {
    method: req.method,
    // Allow setting cookies on Cors requests
    credentials: req.credentials || 'include',
    headers: {
      Accept: MimeTypes.JSON,
      ...headers,
    },
  }

  if (process.browser && req.payload instanceof FormData) {
    return {
      ...params,
      body: req.payload,
    }
  }

  return {
    ...params,
    body: JSON.stringify(req.payload),
    headers: {
      'Content-Type': MimeTypes.JSON,
      ...params.headers,
    },
  }
}

function isTypeError(error: unknown): error is TypeError {
  return Boolean(error) && (error as Error).name === 'TypeError'
}

const API_ENDPOINTS: Record<string, string | undefined> = {
  v1: process.env.API_ENDPOINT || process.env.HOST || '',
  v2: process.env.API_ENDPOINT || process.env.HOST || '',
  next: process.env.NEXT_API_ENDPOINT || '',
}

async function sendRequest<ResponseType, PayloadType>(
  req: ApiRequestDescriptor<ResponseType, PayloadType>,
): Promise<Response> {
  const apiType = req.endpoint.split('/')[2]
  const ep = /^https?:\/\//.test(req.endpoint) ? '' : API_ENDPOINTS[apiType]
  assert(ep !== undefined, `${apiType}: unknown API endpoint`)

  const route = ep + req.endpoint
  const consolidatedRequest = consolidateRequest(req)
  try {
    return await fetch(route, consolidatedRequest)
  } catch (err) {
    if (!isTypeError(err)) {
      throw err
    }
    throw new ApiError(
      {
        statusText: 'No network',
        status: ApiError.Status.NO_NETWORK,
      },
      null,
    )
  }
}

export async function request<ResponseType, PayloadType>(
  req: ApiRequestDescriptor<ResponseType, PayloadType>,
): Promise<ApiResponse<ResponseType>> {
  const response: Response = await sendRequest(req)
  updateTokensFromResponse(response)

  const body = await parseResponseBody<ResponseType>(response)
  if (response.ok) {
    return { body, headers: response.headers } as ApiResponse<ResponseType>
  }

  // tokens must have expired
  if (response.status === 401 && hasTokens()) {
    onTokensExpired()
  }

  throw new ApiError(response, body)
}

function parseHeader(header: string): Record<string, string> {
  let res: Record<string, string> = {}
  header.split(';').forEach(value => {
    const subvalue = value.trim().split('=')
    if (subvalue.length === 1) {
      res = { ...res, [subvalue[0]]: subvalue[0] }
    } else {
      res = { ...res, [subvalue[0]]: subvalue[1] }
    }
  })
  return res
}

function getFilenameFromHeaders(headers: Headers): string {
  const dispositionHeader = headers.get('Content-Disposition')
  if (!dispositionHeader) {
    return 'file'
  }

  return decodeURI(parseHeader(dispositionHeader).filename.slice(1, -1))
}

function getMimeTypeFromFilename(filename: string): string {
  const extension = Arrays.last(filename.split('.'))
  return EXTENSION_MIME_TYPES[extension]
}

export async function downloadFile<ResponseType, PayloadType>(
  req: ApiRequestDescriptor<ResponseType, PayloadType>,
  filename?: string,
): Promise<void> {
  const response = await request(req)
  const finalFilename = filename || getFilenameFromHeaders(response.headers)
  const mimeType = getMimeTypeFromFilename(finalFilename)

  assert(response.body instanceof Blob || typeof response.body === 'string')
  download(new Blob([response.body]), finalFilename, mimeType)
}

export async function pollUntil<ResponseType, PayloadType>(
  req: ApiRequestDescriptor<ResponseType, PayloadType>,
  validationCallback: (response: ApiResponse<ResponseType>) => boolean,
): Promise<ApiResponse<ResponseType>> {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const response = await request(req) // eslint-disable-line no-await-in-loop
    if (validationCallback(response) !== false) {
      return response
    }
    await sleep(1000) // eslint-disable-line no-await-in-loop
  }
}
