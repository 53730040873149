import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { assert } from 'lib/Assert';

import { makeSelectRawCrematorium } from '../../selectors';
import { saveCrematorium } from '../../thunk';
import Wrapper from '../Wrapper';
import { CrematoriumForm } from '../types';
import WorshipList from './WorshipList';

export default function Worship(): JSX.Element {
  const dispatch = useDispatch();
  const crematorium = useSelector(makeSelectRawCrematorium());
  assert(crematorium !== null);

  const onSubmit = useCallback(
    (values: CrematoriumForm): void => {
      dispatch(saveCrematorium(values.crematorium));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <WorshipList />
    </Wrapper>
  );
}
