import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.messages.Crud.name',
  },
  address: {
    id: 'app.messages.Crud.address',
  },
  addressL2: {
    id: 'app.messages.Crud.addressL2',
  },
  city: {
    id: 'app.messages.Crud.city',
  },
  country: {
    id: 'app.messages.Crud.country',
  },
  postalCode: {
    id: 'app.messages.Crud.postalCode',
  },
  inseeCode: {
    id: 'app.messages.Crud.inseeCode',
  },
  department: {
    id: 'app.messages.Crud.department',
  },
  phone: {
    id: 'app.messages.Crud.phone',
  },
  phone2: {
    id: 'app.messages.Crud.phone2',
  },
  phone3: {
    id: 'app.messages.Crud.phone3',
  },
  basicServicePhone: {
    id: 'app.messages.Crud.basicServicePhone',
  },
  fax: {
    id: 'app.messages.Crud.fax',
  },
  fax2: {
    id: 'app.messages.Crud.fax2',
  },
  email: {
    id: 'app.messages.Crud.email',
  },
  email2: {
    id: 'app.messages.Crud.email2',
  },
  email3: {
    id: 'app.messages.Crud.email3',
  },
  email4: {
    id: 'app.messages.Crud.email4',
  },
  respEmail: {
    id: 'app.messages.Crud.respEmail',
  },
  contactTitle: {
    id: 'app.messages.Crud.contactTitle',
  },
  contact: {
    id: 'app.messages.Crud.contact',
  },
  comment: {
    id: 'app.messages.Crud.comment',
  },
  openingHours: {
    id: 'app.messages.Crud.openingHours',
  },
  coveredCities: {
    id: 'app.messages.Crud.coveredCities',
  },
  website: {
    id: 'app.messages.Crud.website',
  },
  chequeAddress: {
    id: 'app.messages.Crud.chequeAddress',
  },
  chequeOrder: {
    id: 'app.messages.Crud.chequeOrder',
  },
  iban: {
    id: 'app.messages.Crud.iban',
  },
  rib: {
    id: 'app.messages.Crud.rib',
  },
  addressTitle: {
    id: 'app.messages.Crud.addressTitle',
  },
  infoTitle: {
    id: 'app.messages.Crud.infoTitle',
  },
  cityAlreadyExist: {
    id: 'app.messages.Crud.cityAlreadyExist',
  },
  optionYes: {
    id: 'app.messages.Crud.yes',
  },
  optionNo: {
    id: 'app.messages.Crud.no',
  },
  otherInformations: {
    id: 'app.messages.Crud.otherInformations',
  },
  coverage: {
    id: 'app.messages.Crud.coverage',
  },
  coverageDepartment: {
    id: 'app.messages.Crud.coverageDepartment',
  },
  askConfirm: {
    id: 'app.messages.Crud.askConfirm',
  },
  monday: {
    id: 'app.messages.Crud.monday',
  },
  tuesday: {
    id: 'app.messages.Crud.tuesday',
  },
  wednesday: {
    id: 'app.messages.Crud.wednesday',
  },
  thursday: {
    id: 'app.messages.Crud.thursday',
  },
  friday: {
    id: 'app.messages.Crud.friday',
  },
  saturday: {
    id: 'app.messages.Crud.saturday',
  },
  sunday: {
    id: 'app.messages.Crud.sunday',
  },
  legendRemoveOpeningHours: {
    id: 'app.messages.Crud.legendRemoveOpeningHours',
  },
  legendReadingMode: {
    id: 'app.messages.Crud.legendReadingMode',
  },
  legendEditMode: {
    id: 'app.messages.Crud.legendEditMode',
  },
  preferedContactMedia: {
    id: 'app.messages.Crud.preferedContactMedia',
  },
  contactEmail: {
    id: 'app.messages.Crud.contactEmail',
  },
  contactFax: {
    id: 'app.messages.Crud.contactFax',
  },
  contactLetter: {
    id: 'app.messages.Crud.contactLetter',
  },
  contactSms: {
    id: 'app.messages.Crud.contactSms',
  },
  lastUpdateDt: {
    id: 'app.messages.Crud.lastUpdateDt',
  },
  lastUpdateUserId: {
    id: 'app.messages.Crud.lastUpdateUserId',
  },
  billingType: {
    id: 'app.messages.Crud.billingType',
  },
  prestation: {
    id: 'app.messages.Crud.prestation',
  },
  month: {
    id: 'app.messages.Crud.month',
  },
  quarter: {
    id: 'app.messages.Crud.quarter',
  },
  errorPhoneFormat: {
    id: 'app.messages.Crud.errorPhoneFormat',
  },
  checkoutType: {
    id: 'app.messages.Crud.checkoutType',
  },
  vat: {
    id: 'app.messages.Crud.vat',
  },
});
