import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { RoomJSON } from '@advitam/api/models/Crematorium/Room';

import { CREMATORIUM_ROOMS } from './constants';
import {
  createRooms,
  deleteRooms,
  fetchRooms,
  saveRooms,
  updateRooms,
} from './thunk';

export interface State {
  rooms: RoomJSON[];
  isSaving: boolean;
  isLoading: boolean;
  hasAlreadyInUseError: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [CREMATORIUM_ROOMS]: State;
}

export const initialState: State = {
  rooms: [],
  isSaving: false,
  isLoading: false,
  hasAlreadyInUseError: false,
  error: null,
};

const slice = createSlice({
  name: CREMATORIUM_ROOMS,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearHasAlreadyInUseError: state => {
      state.hasAlreadyInUseError = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchRooms.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchRooms.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.rooms = payload;
    });
    builder.addCase(fetchRooms.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveRooms.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(
      saveRooms.fulfilled,
      (state, { payload: { finalRooms, hasAlreadyInUseError } }) => {
        state.hasAlreadyInUseError = hasAlreadyInUseError;
        state.rooms = finalRooms;
        state.isSaving = false;
      },
    );
    builder.addCase(saveRooms.rejected, state => {
      state.isSaving = false;
    });

    builder.addCase(updateRooms.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createRooms.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(deleteRooms.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearHasAlreadyInUseError } = slice.actions;
export default slice;
