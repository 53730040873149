import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';
import TrashIcon from '@advitam/ui/images/icons/trash.svg';

import { setDeletingItem } from '../../DeletionModal/slice';
import Action from '../parts/Action';
import Task from '../parts/Task';
import messages from '../messages';

interface DefaultProps {
  item: TodoItem;
}

export default function Default({ item }: DefaultProps): JSX.Element {
  const dispatch = useDispatch();

  const onDelete = (): void => {
    dispatch(setDeletingItem(item));
  };

  return (
    <Task
      item={item}
      action={
        !item.todo_item_id && (
          <Action
            icon={<TrashIcon />}
            message={<FormattedMessage id={messages.deleteTask.id} />}
            onClick={onDelete}
          />
        )
      }
    />
  );
}
