import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { DealState } from '@advitam/api/models/Deal/State';
import { DealKoReason } from '@advitam/api/models/Deal/KoReason';
import { assert } from '@advitam/support';

import type { AppStateSubset } from '../../../slice';
import { makeSelectDeal } from '../../../selectors.typed';
import { DEAL } from '../../../constants';
import { fetchPaymentsDetails } from '../../../Payments/thunk';

interface UpdateStatePayload {
  state: DealState;
  ko_comment?: string | null;
  ko_reason?: DealKoReason;
}

export const updateState = createAsyncThunk(
  `${DEAL}/UPDATE_STATE`,
  async (
    newState: UpdateStatePayload,
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      await request(
        Api.V1.Deals.State.update(deal.uuid, {
          state: newState.state,
          ko_comment: newState.ko_comment || null,
          ko_reason: newState.ko_reason || null,
        }),
      );
      await dispatch(fetchPaymentsDetails(deal.uuid));
      return newState;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
