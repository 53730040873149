import { defineMessages } from 'react-intl';

export default defineMessages({
  searchHeader: {
    id: 'app.components.Product.searchHeader',
  },
  finished: {
    id: 'app.components.Product.finished',
  },
  removeProduct: {
    id: 'app.components.Product.removeProduct',
  },
  noProducts: {
    id: 'app.components.Product.noProducts',
  },
  productDimensionsHeader: {
    id: 'app.components.Product.productDimensionsHeader',
  },
  productInside: {
    id: 'app.components.Product.productInside',
  },
  productOutside: {
    id: 'app.components.Product.productOutside',
  },
  productThickness: {
    id: 'app.components.Product.productThickness',
  },
  productWeight: {
    id: 'app.components.Product.productWeight',
  },
  productAspectHeader: {
    id: 'app.components.Product.productAspectHeader',
  },
  productShape: {
    id: 'app.components.Product.productShape',
  },
  productMaterial: {
    id: 'app.components.Product.productMaterial',
  },
  productColor: {
    id: 'app.components.Product.productColor',
  },
  productSupplierHeader: {
    id: 'app.components.Product.productSupplierHeader',
  },
  productSupplierRef: {
    id: 'app.components.Product.productSupplierRef',
  },
  productSellPrice: {
    id: 'app.components.Product.productSellPrice',
  },
  productSupplier: {
    id: 'app.components.Product.productSupplier',
  },
  productSize: {
    id: 'app.components.Product.productSize',
  },
  productDiameters: {
    id: 'app.components.Product.productDiameters',
  },
  productModelCompInfo: {
    id: 'app.components.Product.productModelCompInfo',
  },
  productGeneral: {
    id: 'app.components.Product.productGeneral',
  },
  selectAVariant: {
    id: 'app.components.Product.selectAVariant',
  },
  amount: {
    id: 'app.components.Product.amount',
  },
  message: { id: 'app.components.Product.message' },
  signature: { id: 'app.components.Product.signature' },
});
