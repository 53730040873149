import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import Steps from './Steps';
import messages from '../messages';

function StepsSection(props) {
  return (
    <section>
      <h2>
        <FormattedMessage {...messages.stepsSection} />
      </h2>
      <Steps {...props} />
    </section>
  );
}

StepsSection.propTypes = {
  isNewDeal: propTypes.bool.isRequired,
  dealUUID: propTypes.string,
};

export default StepsSection;
