import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { put } from 'api/methods';
import { Funeral as FuneralSerializer } from 'api/serializers/v2/Deals';
import { DealJSON } from 'models/v2/Deal';
import { assert, presence } from 'lib/Assert';

export class Funeral {
  @authenticate
  static update(deal: DealJSON): ApiRequestDescriptor<void> {
    assert(presence(deal.id));
    return put(
      `/api/v2/deals/funeral/${deal.id}`,
      FuneralSerializer.serialize(deal),
    );
  }
}
