import { FormattedMessage, useIntl } from 'react-intl';
import { useFormState } from 'react-final-form';

import {
  MediaFormats,
  MediaSupport,
  MediaType,
} from '@advitam/api/models/Crematorium/Room';
import {
  Button,
  FormLayout,
  Input,
  NumberInput,
  SelectableAutocomplete,
} from '@advitam/ui';
import type { Result } from '@advitam/ui/components/Form/UI/Autosuggest/Selectable';

import { Layout } from 'containers/Crud';
import crudMessages from 'containers/Crud/messages';
import actionsMessages from 'messages/actions';

import messages from '../messages';
import { CrematoriumRoomsForm } from '../types';
import style from '../Rooms.module.scss';

interface RoomProps {
  idx: number;
  deleteRoom: () => void;
}

export default function Room({ idx, deleteRoom }: RoomProps): JSX.Element {
  const intl = useIntl();
  const { values } = useFormState<CrematoriumRoomsForm>();

  const { sectionValues: rooms } = values;
  const prefix = `sectionValues[${idx}]`;

  const acceptedMediaSuggestions: Result<MediaType>[] = [
    {
      value: MediaType.MUSIC,
      name: intl.formatMessage(messages.music),
    },
    {
      value: MediaType.PHOTO,
      name: intl.formatMessage(messages.photo),
    },
    {
      value: MediaType.VIDEO,
      name: intl.formatMessage(messages.video),
    },
  ];

  const mediaSupportsSuggestions: Result<MediaSupport>[] = [
    {
      value: MediaSupport.CD,
      name: intl.formatMessage(messages.cd),
    },
    {
      value: MediaSupport.DVD,
      name: intl.formatMessage(messages.dvd),
    },
    {
      value: MediaSupport.USB,
      name: intl.formatMessage(messages.usb),
    },
    {
      value: MediaSupport.VHS,
      name: intl.formatMessage(messages.vhs),
    },
    {
      value: MediaSupport.VINYL,
      name: intl.formatMessage(messages.vinyl),
    },
  ];

  const mediaFormatsSuggestions: Result<MediaFormats>[] = [
    {
      value: MediaFormats.AVI,
      name: intl.formatMessage(messages.avi),
    },
    {
      value: MediaFormats.JPEG,
      name: intl.formatMessage(messages.jpeg),
    },
    {
      value: MediaFormats.MP3,
      name: intl.formatMessage(messages.mp3),
    },
    {
      value: MediaFormats.MP4,
      name: intl.formatMessage(messages.mp4),
    },
    {
      value: MediaFormats.PNG,
      name: intl.formatMessage(messages.png),
    },
  ];

  return (
    <Layout.Fieldset title={rooms[idx].name}>
      <FormLayout.Row>
        <Input
          required
          label={<FormattedMessage id={messages.title.id} />}
          name={`${prefix}.name`}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          label={<FormattedMessage id={messages.capacity.id} />}
          name={`${prefix}.capacity`}
          suffix={<FormattedMessage id={crudMessages.unitPerson.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <SelectableAutocomplete
          name={`${prefix}.media_types`}
          label={<FormattedMessage id={messages.acceptedMedias.id} />}
          suggestions={acceptedMediaSuggestions}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <SelectableAutocomplete
          name={`${prefix}.media_supports`}
          label={<FormattedMessage id={messages.mediaSupports.id} />}
          suggestions={mediaSupportsSuggestions}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <SelectableAutocomplete
          name={`${prefix}.media_formats`}
          label={<FormattedMessage id={messages.mediaFormats.id} />}
          suggestions={mediaFormatsSuggestions}
        />
      </FormLayout.Row>
      <Button
        outline
        onClick={deleteRoom}
        text={<FormattedMessage id={actionsMessages.delete.id} />}
        className={style.delete}
      />
    </Layout.Fieldset>
  );
}
