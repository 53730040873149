import type { ReactNode } from 'react';

import { Sidebar } from '@advitam/ui';

import style from './Menu.module.scss';

interface MenuProps {
  children: ReactNode | ReactNode[];
}

export default function Menu({ children }: MenuProps): JSX.Element {
  return <Sidebar className={style.sidebar}>{children}</Sidebar>;
}
