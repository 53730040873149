import { ChangeEvent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from 'components/Spinner';
import SelectionEntity from 'components/SelectionEntity';
import { ENTITIES_WITH_SUPPLIER_WAREHOUSE } from 'components/AutoCompleteByEntity/constants';
import { PolymorphicId } from 'models/PolymorphicId';
import PriceLine from 'models/Deal/PriceLine';

import {
  makeSelectEntity,
  makeSelectIsLoading,
  makeSelectSupplier,
} from './selectors';
import { formatPrestationName } from './utils';
import { reset } from './slice';

interface EntityInputProps {
  priceLine: PriceLine;
  onChangePrestationPriceLine: (key: string, value: unknown) => void;
}

export default function EntityInput({
  priceLine,
  onChangePrestationPriceLine,
}: EntityInputProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsLoading());
  const entity = useSelector(makeSelectEntity());
  const supplier = useSelector(makeSelectSupplier());

  useEffect(
    () => (): void => {
      dispatch(reset());
    },
    [],
  );

  const defaultEntities = entity && [
    {
      id: entity.id,
      name: formatPrestationName(entity, priceLine, supplier),
    },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <SelectionEntity
      intl={intl}
      defaultEntities={defaultEntities}
      entityType={priceLine.manual_model_type || priceLine.model_type}
      addEntity={(newEntity: PolymorphicId): void =>
        onChangePrestationPriceLine('manual_model_id', newEntity.id)
      }
      removeEntity={(): void =>
        onChangePrestationPriceLine('manual_model_id', null)
      }
      onChangeEntityType={(e: ChangeEvent<HTMLInputElement>): void =>
        onChangePrestationPriceLine('manual_model_type', e.target.value)
      }
      multiSelection={false}
      entities={ENTITIES_WITH_SUPPLIER_WAREHOUSE}
    />
  );
}
