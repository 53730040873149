import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { useThunkDispatch } from '@advitam/react';
import { EditableDocumentJSON } from '@advitam/api/models/Documents/Editable';
import { PageSpinner } from '@advitam/ui';
import Editor from 'containers/DocumentTemplateEditor/Editor';
import { uploadDocument } from 'containers/DocumentTemplateEditor/thunk';

import { initializeTemplateEditor } from './thunk';
import { makeSelectIsEditorLoading, makeSelectVariables } from './selectors';
import { setDocumentTemplate } from './slice';

interface DocumentTemplateEditorProps {
  document: EditableDocumentJSON | null;
  onClose: () => void;
}

export default function DocumentTemplateEditor({
  document,
  onClose,
}: DocumentTemplateEditorProps): JSX.Element {
  const dispatch = useThunkDispatch();

  const variables = useSelector(makeSelectVariables());
  const isLoading = useSelector(makeSelectIsEditorLoading());

  useEffect(() => {
    if (document) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(initializeTemplateEditor(document));
    }
  }, [dispatch, document]);

  const save = useCallback(async (): Promise<void> => {
    if (!document) {
      return;
    }

    const resource = { type: 'Document', id: document.id };
    const result = await dispatch(uploadDocument(resource));
    const payload = unwrapResult(result);
    dispatch(
      setDocumentTemplate({
        documentId: document.id,
        templateId: payload.id,
      }),
    );
    onClose();
  }, [document, onClose, dispatch]);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <Editor
      isIntegrationMode
      variables={variables}
      onSave={save}
      onCancel={onClose}
    />
  );
}
