import { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';

import { useThunkDispatch } from '@advitam/react';
import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import type { EditableDocumentJSON } from '@advitam/api/models/Documents/Editable';
import { DocumentDealType } from '@advitam/api/models/Documents/Editable/DealType';
import { DocumentGenerationType } from '@advitam/api/models/Documents/Editable/GenerationType';
import {
  Button,
  ButtonLike,
  DocumentTypeAutocomplete,
  FormLayout,
  HiddenInput,
  RuleSetAutocomplete,
  Select,
  Tooltip,
} from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import Layout from 'containers/Crud/Layout';
import { openFile } from 'containers/DocumentTemplateEditor/slice';

import messages from './messages';
import style from './Editable.module.scss';
import { EditableDocumentsForm } from './types';

interface DocumentProps {
  prefix: string;
  entityRules: SelectableItem<string>[];
  onEditTemplate: () => void;
  onDelete: () => void;
}

export default function Document({
  prefix,
  entityRules,
  onEditTemplate,
  onDelete,
}: DocumentProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useThunkDispatch();

  const { values } = useFormState<EditableDocumentsForm>();
  const document = get(values, prefix) as EditableDocumentJSON;
  const tokens = { ...getTokens() };

  const editTemplate = (file: Blob | null): void => {
    dispatch(openFile(file));
    onEditTemplate();
  };

  return (
    <Layout.Fieldset
      title={
        document.id ? (
          document.document_type.pretty_name
        ) : (
          <FormattedMessage id={messages.newDocument.id} />
        )
      }
    >
      <FormLayout.Row>
        <DocumentTypeAutocomplete
          required
          name={`${prefix}.document_type`}
          label={<FormattedMessage id={messages.documentType.id} />}
          placeholder={intl.formatMessage(messages.search)}
          tooltip={<FormattedMessage id={messages.documentTypeTooltip.id} />}
          tokens={tokens}
          endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.documentTypes)}
          searchParams={{ editable: 'true' }}
          disabled={Boolean(document.id)}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          required
          name={`${prefix}.deal_type`}
          label={<FormattedMessage id={messages.dealType.id} />}
          tooltip={<FormattedMessage id={messages.dealTypeTooltip.id} />}
          items={[
            {
              value: DocumentDealType.BOTH,
              name: intl.formatMessage(messages.bothDealType),
            },
            {
              value: DocumentDealType.FUNERAL,
              name: intl.formatMessage(messages.funeral),
            },
            {
              value: DocumentDealType.MARBLE,
              name: intl.formatMessage(messages.marble),
            },
          ]}
        />
        <Select
          required
          name={`${prefix}.generation_type`}
          label={<FormattedMessage id={messages.generationType.id} />}
          tooltip={
            <FormattedMessage
              id={messages.generationTypeTooltip.id}
              values={{ br: <br /> }}
            />
          }
          items={[
            {
              value: DocumentGenerationType.IDENTICAL,
              name: intl.formatMessage(messages.identical),
            },
            {
              value: DocumentGenerationType.INCOMPLETE,
              name: intl.formatMessage(messages.incomplete),
            },
            {
              value: DocumentGenerationType.DIFFERENT,
              name: intl.formatMessage(messages.different),
            },
          ]}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <RuleSetAutocomplete
          name={`${prefix}.rule`}
          label={<FormattedMessage id={messages.ruleSet.id} />}
          placeholder={intl.formatMessage(messages.search)}
          tooltip={<FormattedMessage id={messages.ruleSetTooltip.id} />}
          endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.ruleSets)}
          tokens={tokens}
        />
        {entityRules.length > 1 ? (
          <Select
            required
            name={`${prefix}.owners[0].entity_rule_tag`}
            label={<FormattedMessage id={messages.entityRule.id} />}
            tooltip={<FormattedMessage id={messages.entityRuleTooltip.id} />}
            items={entityRules}
          />
        ) : (
          <>
            <HiddenInput name={`${prefix}.owners[0].entity_rule_tag`} />
            <div />
          </>
        )}
      </FormLayout.Row>

      <div className={style.buttons_row}>
        {document.template_id ? (
          <Button
            primary
            onClick={(): void => editTemplate(null)}
            text={<FormattedMessage id={messages.changeDocumentFile.id} />}
          />
        ) : (
          <label>
            <input
              type="file"
              disabled={!document.id}
              onChange={({
                target: { files },
              }: ChangeEvent<HTMLInputElement>): void =>
                editTemplate(files && files[0])
              }
              className="soft-hide"
            />
            <Tooltip
              content={<FormattedMessage id={messages.saveBeforeEditing.id} />}
              disabled={Boolean(document.id)}
            >
              <ButtonLike
                primary
                disabled={!document.id}
                text={<FormattedMessage id={messages.addDocumentFile.id} />}
              />
            </Tooltip>
          </label>
        )}

        <Button
          outline
          onClick={onDelete}
          text={<FormattedMessage id={messages.deleteDocument.id} />}
        />
      </div>
    </Layout.Fieldset>
  );
}
