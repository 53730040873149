import { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react'
import { Field, UseFieldConfig } from 'react-final-form'
import { composeOnChange } from '../../support'

import Radio from '../../UI/Radio'
import { composeValidators, isRequired, Validator } from '../../validators'

interface CardWithImagePropsBase {
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  imageSrc: string
  title: ReactNode
  required?: boolean
  onChange?: (ev: ChangeEvent) => void
  validate?: Validator<InputHTMLAttributes<HTMLInputElement>['value']>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse: UseFieldConfig<any>['parse']
  className?: string
}

interface CardWithImagePropsWithAction extends CardWithImagePropsBase {
  ctaText: ReactNode
  ctaAction: () => void
}

interface CardWithImagePropsWithoutAction extends CardWithImagePropsBase {
  ctaText?: undefined
  ctaAction?: undefined
}

type CardWithImageProps = CardWithImagePropsWithAction | CardWithImagePropsWithoutAction

export default function CardWithImage({
  imageSrc,
  name,
  value,
  title,
  ctaText,
  ctaAction,
  required,
  validate,
  parse,
  onChange,
  className,
}: CardWithImageProps): JSX.Element {
  return (
    <Field
      type="radio"
      name={name}
      value={value}
      parse={parse}
      validate={composeValidators(required && isRequired, validate)}
    >
      {({ input, meta }): JSX.Element => (
        <Radio.CardWithImage
          imageSrc={imageSrc}
          title={title}
          ctaText={ctaText}
          ctaAction={ctaAction}
          onChange={composeOnChange(input.onChange, onChange)}
          name={name}
          value={value}
          checked={input.checked}
          error={!!(meta.touched && meta.error)}
          className={className}
        />
      )}
    </Field>
  )
}
