import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get } from 'api/methods';

import { LimitedClientFields } from 'models/Person';

export class Email {
  @authenticate
  static show(email: string): ApiRequestDescriptor<LimitedClientFields> {
    return get('/api/v1/clients/email', { email });
  }
}
