import { StepType } from '@advitam/api/models/Step';
import { Model } from 'models/Model';
import { Place } from '../Place';
import { StepSource } from '../Step';

interface StepProps {
  id?: number;
  type: StepType;
  source: StepSource;
  location: Place;
  _destroy?: boolean;
}

export interface StepJSON extends StepProps {
  date?: string | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Step extends StepProps {
  date: Date | null;
}

class Step extends Model<StepJSON> {
  constructor(props: StepJSON) {
    super(props);
    this.date = Model.parseDate(props.date);
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Step {
  export type Type = StepType;
  export type Source = StepSource;
}

export { Step };
