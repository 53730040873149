import { useParams } from 'react-router-dom';

import { assert } from '@advitam/support';
import DocumentsSection from 'containers/Documents';

export default function Documents(): JSX.Element {
  const { dealUUID } = useParams();
  assert(dealUUID !== undefined);
  return <DocumentsSection dealUUID={dealUUID} />;
}
