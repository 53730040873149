/*
 *  TransportBeforeCasketing step editor
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { POLICE, OTHER, ADVITAM } from 'containers/Deal/DealFuneral/constants';
import { SelectList, NumberInput, TextInput } from 'components/Input';
import Services from 'containers/Deal/DealFuneral/StepsSection/Services';
import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';

import { renderStepDefaults, renderLocation } from './stepEditorsUtils';
import messages from './messages';

class TransportBeforeCasketing extends PureComponent {
  render() {
    const {
      step,
      stepServices,
      handleChange,
      addService,
      removeService,
      updateDelegate,
      isManualMode,
      userRole,
      updateService,
    } = this.props;
    return (
      <div className="step">
        <div className="step__header">
          {renderStepDefaults(step, handleChange)}
          <div className="step__header__locations">
            {renderLocation(step.startLocation, messages.startLocation)}
            {renderLocation(step.endLocation, messages.endLocation)}
          </div>
          <CheckboxDelegate
            isDelegate={!!step.doneByAnotherFuneral}
            updateDelegate={updateDelegate}
          />
        </div>
        <div>
          <FormattedMessage {...messages.tbcDoneBy} />
          <br />
          <SelectList
            name="tbc"
            onChange={({ target: { value } }) =>
              handleChange({ ...step, doneBy: value })
            }
            value={step.doneBy}
            options={[
              { value: ADVITAM, message: messages.advitam },
              { value: POLICE, message: messages.police },
              { value: OTHER, message: messages.other },
            ]}
          />
          {step.doneBy === OTHER && (
            <div>
              <div>
                <FormattedMessage {...messages.otherPrice} />
                <div>
                  <NumberInput
                    value={step.otherPrice || ''}
                    onChange={({ target: { value } }) =>
                      handleChange({ ...step, otherPrice: value })
                    }
                  />
                </div>
              </div>
              <div>
                <FormattedMessage {...messages.otherName} />
                <div>
                  <TextInput
                    value={step.otherName || ''}
                    onChange={({ target: { value } }) =>
                      handleChange({ ...step, otherName: value })
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Services
          stepId={step.id}
          services={stepServices}
          addService={addService}
          removeService={removeService}
          isManualMode={isManualMode}
          userRole={userRole}
          updateService={updateService}
        />
      </div>
    );
  }
}

TransportBeforeCasketing.propTypes = {
  step: PropTypes.object.isRequired,
  stepServices: PropTypes.array.isRequired,
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  updateDelegate: PropTypes.func.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
};

export default TransportBeforeCasketing;
