import { authenticate, withSessionId } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { StayJSON } from '../../models/FuneralParlor'

export class Stays {
  @authenticate
  static index(funeralParlorId: number): ApiRequestDescriptor<StayJSON[]> {
    return get(`/api/v1/funeral_parlors/${funeralParlorId}/stays`)
  }

  @authenticate
  @withSessionId
  static create(
    stay: Omit<StayJSON, 'id'>,
    funeralParlorId: number,
  ): ApiRequestDescriptor<StayJSON> {
    return post(`/api/v1/funeral_parlors/${funeralParlorId}/stays`, stay)
  }

  @authenticate
  @withSessionId
  static update(stay: StayJSON): ApiRequestDescriptor<StayJSON> {
    return put(`/api/v1/funeral_parlors/stays/${stay.id}`, stay)
  }

  @authenticate
  @withSessionId
  static destroy(stayId: number): ApiRequestDescriptor<StayJSON> {
    return del(`/api/v1/funeral_parlors/stays/${stayId}`)
  }
}
