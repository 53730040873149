import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormLayout, Select } from '@advitam/ui/components/Form';
import { Input as InputUI } from '@advitam/ui/components/Form/UI';
import { Layout } from 'containers/Crud';
import { makeSelectClient } from 'containers/Client/selectors';
import { assert } from 'lib/support';
import actionMessages from 'messages/actions';

import messages from './messages';

export default function Data(): JSX.Element {
  const intl = useIntl();

  const client = useSelector(makeSelectClient());
  assert(client !== null);

  const formatDate = useCallback(
    (date: Date | null): string => {
      if (!date) {
        return '';
      }
      return intl.formatDate(date, {
        dateStyle: 'short',
        timeStyle: 'short',
      });
    },
    [intl],
  );

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.data.id} />}>
      <FormLayout.Row>
        <Select
          name="isOptedOut"
          label={<FormattedMessage id={messages.optOut.id} />}
          tooltip={<FormattedMessage id={messages.optOutTooltip.id} />}
          items={[
            { value: true, name: intl.formatMessage(actionMessages.yes) },
            { value: false, name: intl.formatMessage(actionMessages.no) },
          ]}
        />
        {client.opt_out ? (
          <InputUI
            label={<FormattedMessage id={messages.optOutDate.id} />}
            value={formatDate(client.opt_out)}
            readOnly
            disabled
          />
        ) : (
          <div />
        )}
      </FormLayout.Row>
      <FormLayout.Row>
        <InputUI
          label={<FormattedMessage id={messages.termsAcceptedAt.id} />}
          value={formatDate(client.cgs_accepted_at)}
          readOnly
          disabled
        />
        <InputUI
          label={<FormattedMessage id={messages.lastLogin.id} />}
          value={formatDate(client.current_sign_in_at)}
          readOnly
          disabled
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
