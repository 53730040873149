import { authenticate } from '../../../../lib/decorators'
import { get } from '../../../../lib/methods'
import { ApiRequestDescriptor } from '../../../../lib/request'
import { BatchLogJSON } from '../../../../models/Deal/Document/Batch/Log'

export class Logs {
  @authenticate
  static index(dealUuid: string): ApiRequestDescriptor<BatchLogJSON[]> {
    return get(`/api/v1/deals/${dealUuid}/documents/batches/logs`)
  }
}
