import isObjectEmpty from './isObjectEmpty';

// Recursively clean an object from empty values
export default function clean(obj: unknown): unknown {
  const newObj: unknown = Array.isArray(obj) ? [] : {};

  Object.entries(obj as Record<string, unknown>).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      const newVal = clean(value);

      if (
        !isObjectEmpty(newVal as Record<string, unknown>) &&
        (newVal as unknown[]).length !== 0
      ) {
        (newObj as Record<string, unknown>)[key] = newVal;
      }
    } else if (value !== null) {
      (newObj as Record<string, unknown>)[key] = value;
    }
  });

  return newObj;
}
