import type { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { NumberInput, FormattedPrice } from '@advitam/ui';

import { messages as crudMessages } from 'containers/Crud';

import { makeSelectCanEdit } from '../../../../../selectors';
import style from '../List.module.scss';
import { useProduct, useProductPrefix } from '../../ProductListProvider';

const FIELD_PLACEHOLDER = '__';

interface PriceCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function PriceCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: PriceCellProps): JSX.Element | null {
  const canUserEdit = useSelector(makeSelectCanEdit());

  const warehouseProduct = useProduct(rowIndex);
  const prefix = useProductPrefix(rowIndex);

  return (
    <div
      style={wrapperStyle}
      className={[className, style.price_cell].join(' ')}
    >
      <NumberInput
        required
        disabled={!canUserEdit}
        placeholder={FIELD_PLACEHOLDER}
        className={style.input}
        name={`${prefix}.price`}
        suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
      />
      <span>
        {warehouseProduct.price !== null && (
          <FormattedPrice value={warehouseProduct.price} />
        )}
      </span>
    </div>
  );
}
