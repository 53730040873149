import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  delete: { id: 'app.containers.Crud.AdministrationActions.delete' },
  deletionConfirmationTitle: {
    id: 'app.containers.Crud.AdministrationActions.deletionConfirmationTitle',
  },
  deletionConfirmationText: {
    id: 'app.containers.Crud.AdministrationActions.deletionConfirmationText',
  },
  cancelDeletion: {
    id: 'app.containers.Crud.AdministrationActions.cancelDeletion',
  },
  confirmDeletion: {
    id: 'app.containers.Crud.AdministrationActions.confirmDeletion',
  },
  active: { id: 'app.containers.Crud.AdministrationActions.active' },
});
