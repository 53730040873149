import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get, post } from 'api/methods';

import { FilterSelection } from 'components/FilterBar';
import { OpsLogJSON } from 'models/OpsLog';

export class OpsLogs {
  @authenticate
  static index(
    filters?: Record<string, FilterSelection>,
  ): ApiRequestDescriptor<OpsLogJSON[]> {
    return get('/api/v1/ops_logs', filters);
  }

  @authenticate
  static create(ops_log: OpsLogJSON): ApiRequestDescriptor<OpsLogJSON> {
    return post('/api/v1/ops_logs', ops_log);
  }
}
