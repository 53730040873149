import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  address: { id: 'app.containers.Crud.Fields.Contact.address' },
  addressAdditional: {
    id: 'app.containers.Crud.Fields.Contact.addressAdditional',
  },
  addressCity: { id: 'app.containers.Crud.Fields.Contact.addressCity' },
  addressCountry: { id: 'app.containers.Crud.Fields.Contact.addressCountry' },
  addressInseeCode: {
    id: 'app.containers.Crud.Fields.Contact.addressInseeCode',
  },
  addressPostalCode: {
    id: 'app.containers.Crud.Fields.Contact.addressPostalCode',
  },
  addressLatitude: {
    id: 'app.containers.Crud.Fields.Contact.addressLatitude',
  },
  addressLongitude: {
    id: 'app.containers.Crud.Fields.Contact.addressLongitude',
  },
  manualAddressTitle: {
    id: 'app.containers.Crud.Fields.Contact.manualAddressTitle',
  },
  email: { id: 'app.containers.Crud.Fields.Contact.email' },
  emailTooltipAdd: { id: 'app.containers.Crud.Fields.Contact.emailTooltipAdd' },
  emailTooltipDelete: {
    id: 'app.containers.Crud.Fields.Contact.emailTooltipDelete',
  },
  fax: { id: 'app.containers.Crud.Fields.Contact.fax' },
  phone: { id: 'app.containers.Crud.Fields.Contact.phone' },
  phoneTooltipAdd: { id: 'app.containers.Crud.Fields.Contact.phoneTooltipAdd' },
  phoneTooltipDelete: {
    id: 'app.containers.Crud.Fields.Contact.phoneTooltipDelete',
  },
  preferedContact: { id: 'app.containers.Crud.Fields.Contact.preferedContact' },
  preferedContactTooltip: {
    id: 'app.containers.Crud.Fields.Contact.preferedContactTooltip',
  },
  requiredDocuments: {
    id: 'app.containers.Crud.Fields.Contact.requiredDocuments',
  },
  requiredDocumentsTooltip: {
    id: 'app.containers.Crud.Fields.Contact.requiredDocumentsTooltip',
  },
  requiredDocumentsYes: {
    id: 'app.containers.Crud.Fields.Contact.requiredDocumentsYes',
  },
  requiredDocumentsNo: {
    id: 'app.containers.Crud.Fields.Contact.requiredDocumentsNo',
  },
  title: { id: 'app.containers.Crud.Fields.Contact.title' },
});
