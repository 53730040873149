import { authenticate } from '../../lib/decorators'
import { get } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'

export class Variables {
  @authenticate
  static show(documentTypeSlug: string): ApiRequestDescriptor<string[]> {
    return get(`/api/v1/document_types/${documentTypeSlug}/variables`)
  }
}
