import { HospitalJSON } from '@advitam/api/models/Hospital';
import { formatEntity } from '../Entity';

export function formatName(hospital: HospitalJSON): string {
  return formatEntity(hospital);
}

export function FormattedName(hospital: HospitalJSON): JSX.Element {
  return <>{formatName(hospital)}</>;
}
