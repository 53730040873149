// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__list--PuO64{max-height:500px;overflow:auto}div.style__label--BsbEZ{margin:0;border-top:unset;border-bottom:#333 1px solid}`, "",{"version":3,"sources":["webpack://./src/containers/Documents/Toolbar/FileList/style.scss"],"names":[],"mappings":"AAEA,oBACE,gBAAA,CACA,aAAA,CAGF,wBACE,QAAA,CACA,gBAAA,CACA,4BAAA","sourcesContent":["@import 'src/scss/mixins/vars';\n\n.list {\n  max-height: 500px;\n  overflow: auto;\n}\n\ndiv.label {\n  margin: 0;\n  border-top: unset;\n  border-bottom: $text--dark 1px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `style__list--PuO64`,
	"label": `style__label--BsbEZ`
};
export default ___CSS_LOADER_EXPORT___;
