import { assert } from 'lib/support';
import style from './style.scss';

interface DefunctProfileProps {
  pictureLink?: string;
  firstname: string | null;
  lastname: string | null;
}

export default function DefunctProfile({
  pictureLink,
  firstname,
  lastname,
}: DefunctProfileProps): JSX.Element {
  const initials = [firstname?.[0], lastname?.[0]]
    .filter(name => name !== undefined)
    .map(initial => {
      assert(initial !== undefined);
      return initial.toUpperCase();
    })
    .join('. ');

  return (
    <div className={style['profile-image']}>
      <div>
        {pictureLink ? (
          <img src={pictureLink} alt={`${firstname || ''} ${lastname || ''}`} />
        ) : (
          <span>{initials}</span>
        )}
      </div>
    </div>
  );
}
