import { I18nPath } from '../../constants'
import messages from './messages'

export const menuLinks = {
  fr: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.fr.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.fr.REPATRIATION_INDEX,
          internal: true,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.fr.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.fr.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.assistGPT.id,
          href: I18nPath.fr.ASSIST_GPT,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.fr.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.fr.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.fr.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.fr.PRESS,
          internal: true,
        },
        {
          nameKey: messages.blog.id,
          href: I18nPath.fr.BLOG,
          internal: true,
          subtextKey: messages.blogSubtext.id,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.fr.PROS_SPACE,
      internal: true,
    },
  ],
  en: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.en.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.en.REPATRIATION_INDEX,
          internal: true,
          subtextKey: messages.repatriationSubtext.id,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.en.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.en.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.en.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.en.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.en.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.en.PRESS,
          internal: true,
        },
        {
          nameKey: messages.blog.id,
          href: I18nPath.en.BLOG,
          internal: true,
          subtextKey: messages.blogSubtext.id,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.en.PROS_SPACE,
      internal: true,
    },
  ],
  es: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.es.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.es.REPATRIATION_INDEX,
          internal: true,
          subtextKey: messages.repatriationSubtext.id,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.es.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.es.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.es.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.en.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.es.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.es.PRESS,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.es.PROS_SPACE,
      internal: true,
    },
  ],
  ar: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.ar.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.ar.REPATRIATION_INDEX,
          internal: true,
          subtextKey: messages.repatriationSubtext.id,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.ar.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.ar.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.ar.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.ar.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.ar.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.ar.PRESS,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.ar.PROS_SPACE,
      internal: true,
    },
  ],
  de: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.de.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.de.REPATRIATION_INDEX,
          internal: true,
          subtextKey: messages.repatriationSubtext.id,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.de.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.de.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.de.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.de.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.de.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.de.PRESS,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.de.PROS_SPACE,
      internal: true,
    },
  ],
  he: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.he.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.he.REPATRIATION_INDEX,
          internal: true,
          subtextKey: messages.repatriationSubtext.id,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.he.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.he.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.he.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.he.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.he.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.he.PRESS,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.he.PROS_SPACE,
      internal: true,
    },
  ],
  it: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.it.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.it.REPATRIATION_INDEX,
          internal: true,
          subtextKey: messages.repatriationSubtext.id,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.it.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.it.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.it.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.it.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.it.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.it.PRESS,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.it.PROS_SPACE,
      internal: true,
    },
  ],
  pt: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.pt.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.pt.REPATRIATION_INDEX,
          internal: true,
          subtextKey: messages.repatriationSubtext.id,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.pt.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.pt.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.pt.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.pt.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.pt.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.pt.PRESS,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.pt.PROS_SPACE,
      internal: true,
    },
  ],
  tr: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.tr.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.tr.REPATRIATION_INDEX,
          internal: true,
          subtextKey: messages.repatriationSubtext.id,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.tr.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.tr.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.tr.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.tr.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.tr.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.tr.PRESS,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.tr.PROS_SPACE,
      internal: true,
    },
  ],
  zh: [
    {
      nameKey: messages.services.id,
      items: [
        {
          nameKey: messages.funerals.id,
          href: I18nPath.zh.FUNERAL_INDEX,
          internal: true,
        },
        {
          nameKey: messages.repatriation.id,
          href: I18nPath.zh.REPATRIATION_INDEX,
          internal: true,
          subtextKey: messages.repatriationSubtext.id,
        },
        {
          nameKey: messages.tombstone.id,
          href: I18nPath.zh.GRAVESTONE,
          internal: true,
        },
        {
          nameKey: messages.flowers.id,
          href: I18nPath.zh.FLOWERS_FUNNEL,
          internal: true,
        },
        {
          nameKey: messages.additionalServices.id,
          href: I18nPath.zh.ADDITIONAL_SERVICES,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.ourCompany.id,
      items: [
        {
          nameKey: messages.reviews.id,
          href: I18nPath.zh.FAMILY_REVIEWS,
          internal: true,
        },
        {
          nameKey: messages.commitments.id,
          href: I18nPath.zh.COMMITMENTS,
          internal: true,
        },
        {
          nameKey: messages.press.id,
          href: I18nPath.zh.PRESS,
          internal: true,
        },
      ],
    },
    {
      nameKey: messages.prosSpace.id,
      href: I18nPath.zh.PROS_SPACE,
      internal: true,
    },
  ],
}
