import { ReactNode } from 'react'

import Heading from '../../Heading'
import cartStyle from '../ShoppingCart.module.scss'
import style from './Desktop.module.scss'

interface DesktopProps {
  title: ReactNode
  children: ReactNode | ReactNode[]
  totals: ReactNode
  className?: string
  action?: ReactNode
  hideOnXl?: boolean
}

export default function Desktop({
  title,
  children,
  totals,
  className = '',
  action,
  hideOnXl = false,
}: DesktopProps): JSX.Element {
  const classes = [cartStyle.cart, style.desktop, className, hideOnXl && style.hide_on_xl]
    .filter(Boolean)
    .join(' ')
  const submitClassName = [style.submit, hideOnXl && style.hide_on_xl].filter(Boolean).join(' ')

  return (
    <>
      <div className={classes}>
        <Heading small className={style.title}>
          <b>{title}</b>
        </Heading>
        {children}
        {totals}
      </div>
      <div className={submitClassName}>{action}</div>
    </>
  )
}
