import { defineMessages } from 'react-intl';

export default defineMessages({
  selectAClient: {
    id: 'app.containers.DealItems.selectAClient',
    defaultMessage: 'Please select an existing client',
  },
  parentAssociation: {
    id: 'app.containers.DealItems.parentAssociation',
    defaultMessage: 'Funeral deal to associate to',
  },
  doNotAssociate: {
    id: 'app.containers.DealItems.doNotAssociate',
    defaultMessage: 'Do not associate',
  },
  defunctName: {
    id: 'app.containers.DealItems.defunctName',
    defaultMessage: 'Defunct name',
  },
});
