import { Alma } from './Alma'
import { Deals } from './Deals'
import { Fintecture } from './Fintecture'
import { Ingenico } from './Ingenico'
import { Refund } from './Refund'

export const Payments = {
  Alma,
  Deals,
  Fintecture,
  Ingenico,
  Refund,
}
