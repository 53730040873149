// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DuplicationButton-module__button--DDqVa{width:40px;height:40px}.DuplicationButton-module__button--DDqVa svg{width:22px}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Sections/Home/DuplicationButton/DuplicationButton.module.scss"],"names":[],"mappings":"AAAA,yCACE,UAAA,CACA,WAAA,CAEA,6CACE,UAAA","sourcesContent":[".button {\n  width: 40px;\n  height: 40px;\n\n  svg {\n    width: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `DuplicationButton-module__button--DDqVa`
};
export default ___CSS_LOADER_EXPORT___;
