import type { ReactNode } from 'react'
import Frame from '../Frame'
import style from './Alert.module.scss'

interface AlertProps {
  children: ReactNode
  type: 'success' | 'warning' | 'error'
  className?: string
}

export default function Alert({ children, type, className = '' }: AlertProps): JSX.Element {
  return <Frame className={`${style[type]} ${className}`}>{children}</Frame>
}
