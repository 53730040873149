import { createSelector, Selector } from 'reselect';

import { MEMORIAL_SPACE_CUSTOMIZATION } from './constants';
import { State, AppStateSubset } from './slice';

type MemorialSectionSelector<T> = Selector<AppStateSubset, T>;

export const selectMemorialSectionCustomizationDomain = (
  state: AppStateSubset,
): State => state && state[MEMORIAL_SPACE_CUSTOMIZATION];

export const makeSelectIsLoading = (): MemorialSectionSelector<boolean> =>
  createSelector(
    selectMemorialSectionCustomizationDomain,
    ({ isLoading }) => isLoading,
  );
