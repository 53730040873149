import { createAsyncThunk } from '@reduxjs/toolkit';
import { replace } from 'redux-first-history';

import Api, { request, isApiError } from '@advitam/api';
import { assert } from '@advitam/support';

import { onLoginSuccess, onLogout } from 'slices/auth/thunk';

import { LOGIN } from './constants';
import type { LoginForm } from './types';

interface LoginPayload {
  values: LoginForm;
  from: string;
}

export const login = createAsyncThunk(
  `${LOGIN}/LOGIN`,
  async ({ values, from }: LoginPayload, { dispatch, rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.Pro.Auth.signIn(values.email, values.password),
      );
      assert(body !== null);

      await dispatch(onLoginSuccess(body.data));
      dispatch(replace(from));

      return undefined;
    } catch (err) {
      if (isApiError(err) && err.status === 401) {
        await dispatch(onLogout());
      }
      return rejectWithValue(err);
    }
  },
);
