import { CSSProperties } from 'react';
import { injectIntl } from 'react-intl';

import IncreaseFontSizeIcon from 'images/icons/format-font-size-increase.svg';
import DecreaseFontSizeIcon from 'images/icons/format-font-size-decrease.svg';

import { Button } from '@advitam/ui';
import ResizableInput from './Resizable';
import style from '../Engine.module.scss';

interface TextInputProps {
  fontSize: number;
}

class TextInput extends ResizableInput<string, TextInputProps> {
  protected getStyle(): CSSProperties {
    const { fontSize } = this.props;

    return {
      ...super.getStyle(),
      fontSize: `${fontSize}px`,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  renderInput(): JSX.Element | null {
    const {
      fontSize,
      isEditMode,
      isPreviewMode,
      onChange,
      target,
      value: v,
      variable,
      size,
      setFontSize,
    } = this.props;
    const { isFocused } = this.state;

    if (!isEditMode || isPreviewMode) {
      const sizeFactor = 1.5;
      return (
        <div
          style={{
            width: '100%',
            marginTop: `${(size[1] - fontSize * sizeFactor) / 2}px`,
            padding: '0 2px',
            fontSize: `${fontSize}px`,
            lineHeight: 1.5,
          }}
        >
          {v || !variable || this.value ? this.value : `{{${variable}}}`}
        </div>
      );
    }

    return (
      <>
        <input
          type="text"
          className={style.text_input}
          value={this.value}
          placeholder={this.getPlaceholder()}
          onChange={({ target: { value } }): void => onChange(target, value)}
        />
        {isFocused && (
          <>
            <Button
              primary
              className={`${style.input_button} ${style.input_button_2}`}
              onClick={(): void => setFontSize(target, fontSize + 1)}
              icon={<IncreaseFontSizeIcon />}
            />
            <Button
              primary
              className={`${style.input_button} ${style.input_button_3}`}
              onClick={(): void => setFontSize(target, fontSize - 1)}
              icon={<DecreaseFontSizeIcon />}
            />
          </>
        )}
      </>
    );
  }
}

export default injectIntl(TextInput);
