import { authenticate } from '../../lib/decorators'
import { post } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { OngoingFormalityJSON } from '../../models/OngoingFormality'

export class OngoingFormalities {
  @authenticate
  static create(
    admProcedureId: number,
    companyFormalityId: number,
  ): ApiRequestDescriptor<OngoingFormalityJSON> {
    return post(`/api/v1/administrative_procedures/${admProcedureId}/ongoing_formalities`, {
      formality: {
        companies_formality_id: companyFormalityId,
      },
    })
  }
}
