import { useCallback, useMemo } from 'react'
import { Formats, SlotInfo, stringOrDate, Views } from 'react-big-calendar'

import { assert } from '@advitam/support'
import ReactBigCalendar from '../ReactBigCalendar'

import { OpeningEvent, OpeningHours } from './types'
import { buildEvents, YEAR, MONTH, DAY, updateEvent, removeEvent, addEvent } from './utils'
import useEventWrapper from './Event'
import style from './OpeningHours.module.scss'

function doNothing(): void {
  /* As the name suggests */
}

const formats: Formats = {
  dayFormat: (date, culture, localizer) => {
    assert(localizer !== undefined)
    return localizer.format(date, 'cccc', culture)
  },
}

interface OnEventChangeProps {
  event: OpeningEvent
  start: stringOrDate
  end: stringOrDate
}

interface OpeningHoursProps {
  value: OpeningHours
  onChange?: (value: OpeningHours) => void
  /* Min time of the day to display, in hours */
  minTime?: number
  /* Max time of the day to display, in hours */
  maxTime?: number
  className?: string
}

export default function OpeningHoursCalendar({
  value,
  onChange,
  minTime = 0,
  maxTime = 24,
  className,
}: OpeningHoursProps): JSX.Element {
  const events = useMemo(() => buildEvents(value), [value])
  const defaultDate = useMemo(() => new Date(YEAR, MONTH, DAY), [])
  const max = useMemo(
    () => (maxTime >= 24 ? new Date(0, 0, 0, 23, 59, 59) : new Date(0, 0, 0, maxTime, 0, 0)),
    [maxTime],
  )
  const min = useMemo(() => new Date(0, 0, 0, minTime, 0, 0, 0), [minTime])

  const onEventResize = useCallback(
    ({ start, end, event }: OnEventChangeProps): void => {
      if (!onChange || typeof start === 'string' || typeof end === 'string') {
        return
      }

      const updated = updateEvent(value, event, start, end)
      onChange(updated)
    },
    [value, onChange],
  )

  const onEventDrop = useCallback(
    ({ start, end, event }: OnEventChangeProps): void => {
      if (!onChange || typeof start === 'string' || typeof end === 'string') {
        return
      }

      if (event.start && event.start.getDay() === start.getDay()) {
        onEventResize({ event, start, end })
        return
      }

      const withoutPrevious = removeEvent(value, event)
      const updated = addEvent(withoutPrevious, start, end)
      onChange(updated)
    },
    [onEventResize, value, onChange],
  )

  const onSelectSlot = useCallback(
    ({ start, end }: SlotInfo): void => {
      if (!onChange) {
        return
      }
      const updated = addEvent(value, start, end)
      onChange(updated)
    },
    [value, onChange],
  )

  const editableAccessor = useCallback(() => Boolean(onChange), [onChange])
  const event = useEventWrapper(value, onChange)

  return (
    <ReactBigCalendar
      toolbar={false}
      view={Views.WEEK}
      onView={doNothing}
      drilldownView={null}
      min={min}
      max={max}
      defaultDate={defaultDate}
      formats={formats}
      events={events}
      onEventResize={onEventResize}
      onEventDrop={onEventDrop}
      onSelectSlot={onSelectSlot}
      selectable={Boolean(onChange)}
      draggableAccessor={editableAccessor}
      resizableAccessor={editableAccessor}
      components={{ event }}
      className={[style.opening_hours, className].filter(Boolean).join(' ')}
    />
  )
}
