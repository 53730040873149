interface Addressable {
  address: string | null;
  postal_code: string | null;
  city: string | null;
}

export function formatAddress({
  address,
  city,
  postal_code: postalCode,
}: Addressable): string {
  if (!address) {
    return '';
  }

  const fullCity = `${postalCode || ''} ${city || ''}`.trim();
  return [address, fullCity].filter(Boolean).join(', ');
}

interface FormattedAddressProps {
  entity: Addressable;
}

export function FormattedAddress({
  entity,
}: FormattedAddressProps): JSX.Element {
  return <>{formatAddress(entity)}</>;
}
