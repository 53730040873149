import { DateTime } from 'luxon'

import { assert, Objects } from '@advitam/support'

import type { ClientJSON } from '../../../models/Funnel/Client'
import type { FlowerDealJSON } from '../../../models/Funnel/Flower'
import type { DealFlowerProductJSON } from '../../../models/Funnel/Deals/Flower/Product'
import type { DeliveryStepJSON } from '../../../models/Funnel/Flower/Step'
import { FlowerFunnelPayload, ProductAttributes, StepAttributes } from './types'
import { TrackingAttributes } from '../../types/Tracking'

function serializeStep(step: DeliveryStepJSON): StepAttributes {
  // TODO: Settings.throwOnInvalid = true;
  const date = DateTime.fromISO(step.date).setZone('utc', { keepLocalTime: true }).toISO()
  assert(date !== null)

  return {
    ...Objects.omit(step, 'location', 'details', 'date'),
    location_type: step.location.type,
    location_attributes: {
      ...step.location,
    },
    date,
    delivery_details_attributes: step.details,
  }
}

function serializeProduct(product: DealFlowerProductJSON): ProductAttributes {
  const { comp_info } = product
  assert(comp_info !== null)

  return {
    ...product,
    comp_info,
  }
}

export function serialize(
  deal: FlowerDealJSON,
  client: ClientJSON,
  tracking?: TrackingAttributes,
): FlowerFunnelPayload {
  const { client_commentary, deals_products, deals_steps } = deal

  return {
    funnel: {
      client_commentary,
      owner_ability_attributes: {
        client_attributes: client,
      },
      deals_products_attributes: deals_products.map(product => serializeProduct(product)),
      deal_steps_attributes: [serializeStep(deals_steps[0])],
      tracking_attributes: tracking,
    },
  }
}
