import { createSelector, Selector } from 'reselect';
import { difference } from 'lodash';

import { EntityGeoshapesKeyJSON } from '@advitam/api/models/Entity/GeoshapesKey';
import { PrestationCoverageJSON } from '@advitam/api/models/Prestation';

import { assert } from 'lib/support';

import { getPolygonLength } from '../utils';
import { MAP_PRESTATIONS } from './constants';
import { AppStateSubset, State } from './slice';
import { ActivePrestationCoverage } from './types';

type MapPrestationsSelector<T> = Selector<AppStateSubset, T>;

const selectMapPrestationDomain = (state: AppStateSubset): State =>
  state[MAP_PRESTATIONS];

export const makeSelectActiveKeys = (): MapPrestationsSelector<string[]> =>
  createSelector(selectMapPrestationDomain, ({ activeKeys }) => activeKeys);

/** Dot not use this selector, it is exported for testing purpose only */
export const makeSelectCoverageKeysOptions = (): MapPrestationsSelector<
  EntityGeoshapesKeyJSON[]
> =>
  createSelector(
    selectMapPrestationDomain,
    ({ prestationsCoverageKeys }) => prestationsCoverageKeys,
  );

export const makeSelectActiveKeysOptions = (): MapPrestationsSelector<
  EntityGeoshapesKeyJSON[]
> =>
  createSelector(
    makeSelectActiveKeys(),
    makeSelectCoverageKeysOptions(),
    (activeKeys, keysOptions) =>
      keysOptions.filter(prestationCoverageKey =>
        activeKeys.includes(prestationCoverageKey.key),
      ),
  );

export const makeSelectRemainingKeysOptions = (): MapPrestationsSelector<
  EntityGeoshapesKeyJSON[]
> =>
  createSelector(
    makeSelectActiveKeysOptions(),
    makeSelectCoverageKeysOptions(),
    (activeKeysOptions, keysOptions) =>
      difference(keysOptions, activeKeysOptions),
  );

export const makeSelectCoveragesByKey = (): MapPrestationsSelector<
  Record<string, PrestationCoverageJSON[]>
> =>
  createSelector(
    selectMapPrestationDomain,
    ({ prestationsCoveragesByKey }) => prestationsCoveragesByKey,
  );

export const makeSelectStoredCoverageKeys = (): MapPrestationsSelector<
  string[]
> =>
  createSelector(makeSelectCoveragesByKey(), prestationsCoveragesByKey =>
    Object.keys(prestationsCoveragesByKey),
  );

function isValidActiveCoverage(
  coverage: PrestationCoverageJSON,
): coverage is ActivePrestationCoverage {
  if (!coverage?.coverage) {
    return false;
  }

  assert(coverage.coverage?.type === 'MultiPolygon');
  return coverage.coverage.type === 'MultiPolygon';
}

export const makeSelectActiveCoverages = (): MapPrestationsSelector<
  ActivePrestationCoverage[]
> =>
  createSelector(
    makeSelectCoveragesByKey(),
    makeSelectActiveKeys(),
    (coveragesByKey, activeKeys) => {
      const activeCoverages = activeKeys.map(key =>
        coveragesByKey[key]
          .map(coverage => ({
            ...coverage,
            key,
          }))
          .filter(isValidActiveCoverage),
      );

      return activeCoverages.flat();
    },
  );

export const makeSelectActiveCoveragesByAscLength = (): MapPrestationsSelector<
  ActivePrestationCoverage[]
> =>
  createSelector(makeSelectActiveCoverages(), coverages =>
    coverages.sort(
      (a, b) => getPolygonLength(b.coverage) - getPolygonLength(a.coverage),
    ),
  );
