import { createSelector, Selector } from 'reselect';

import { DocumentTemplateJSON } from 'models/DocumentTemplate';

import { DOCUMENT_TEMPLATE_EDITOR } from './constants';
import { AppStateSubset, State } from './slice';
import { Input, Page } from './models';
import { DOCUMENT_TEMPLATE_TYPE } from './Engine/constants';

type EditorSelector<T> = Selector<AppStateSubset, T>;

const selectDocumentTemplateEditorDomain = (state: AppStateSubset): State =>
  state[DOCUMENT_TEMPLATE_EDITOR];

export const makeSelectBlob = (): EditorSelector<Blob | null> =>
  createSelector(selectDocumentTemplateEditorDomain, state => state.blob);

export const makeSelectPages = (): EditorSelector<Page[]> =>
  createSelector(selectDocumentTemplateEditorDomain, state => state.pages);

export const makeSelectUploads = (): EditorSelector<string[]> =>
  createSelector(selectDocumentTemplateEditorDomain, state => state.uploads);

export const makeSelectTemplate = (): EditorSelector<DocumentTemplateJSON | null> =>
  createSelector(selectDocumentTemplateEditorDomain, state => state.template);

export const makeSelectTemplateId = (): EditorSelector<number | null> =>
  createSelector(makeSelectTemplate(), tpl => tpl?.id || null);

export const makeSelectBlobType = (): EditorSelector<string | null> =>
  createSelector(
    makeSelectBlob(),
    makeSelectTemplateId(),
    (blob, templateId) => {
      if (templateId) {
        return DOCUMENT_TEMPLATE_TYPE;
      }
      return blob?.type || null;
    },
  );

export const makeSelectPageCount = (): EditorSelector<number> =>
  createSelector(makeSelectPages(), pages => pages.length);

export const makeSelectInputs = (page: number): EditorSelector<Input[]> =>
  createSelector(makeSelectPages(), pages => pages[page]?.inputs || []);

export const makeSelectDictionary = (): EditorSelector<
  Record<string, unknown>
> =>
  createSelector(
    selectDocumentTemplateEditorDomain,
    substate => substate.dictionary,
  );

export const makeSelectNewInput = (): EditorSelector<Input | null> =>
  createSelector(
    selectDocumentTemplateEditorDomain,
    substate => substate.newInput,
  );

export const makeSelectZoom = (): EditorSelector<number> =>
  createSelector(selectDocumentTemplateEditorDomain, substate => substate.zoom);

export const makeSelectDefaultFontSize = (): EditorSelector<number> =>
  createSelector(
    selectDocumentTemplateEditorDomain,
    substate => substate.defaultFontSize,
  );

export const makeSelectIsPreviewMode = (): EditorSelector<boolean> =>
  createSelector(
    selectDocumentTemplateEditorDomain,
    substate => substate.isPreviewMode,
  );
