import { createSelector } from 'reselect';
import { selectDealTypeWrapperDomain } from 'containers/Deal/selectors';

import { computeBookingStatus } from './utils.ts';

// Return if the BookingSection is loading
const makeSelectisBookingLoading = () =>
  createSelector(selectDealTypeWrapperDomain, substate =>
    substate ? substate.isBookingLoading : null,
  );

const makeSelectBookingCommentLoader = () =>
  createSelector(selectDealTypeWrapperDomain, substate =>
    substate ? substate.bookingCommentLoader : [],
  );

// Return Booking info domain
const makeSelectBookingInfoDomain = () =>
  createSelector(selectDealTypeWrapperDomain, substate =>
    substate ? substate.bookingInfo : null,
  );

// Return booking info suppliers'
const makeSelectSuppliers = () =>
  createSelector(makeSelectBookingInfoDomain(), substate => {
    if (!substate) return [];
    return substate.suppliers.map(supplier => ({
      id: supplier.id,
      name: supplier.name,
      comment: supplier.comment,
      booking_status: computeBookingStatus(supplier.items),
      is_not_negociated: !supplier.items.some(
        item => !item.not_negociated_prices,
      ),
    }));
  });

// Return the global booking status
const makeSelectBookingStatus = () =>
  createSelector(makeSelectSuppliers(), substate =>
    computeBookingStatus(substate),
  );

// Return booking info solutions'
const selectSolutionsDomain = () =>
  createSelector(makeSelectBookingInfoDomain(), substate => {
    if (!substate) return null;
    return substate.solutions;
  });

const makeSelectGroupNames = () =>
  createSelector(selectSolutionsDomain(), substate => {
    if (!substate) return [];
    return Object.keys(substate);
  });

// {
//   "tac": {
//     "prestations": [
//       'Amazin description'
//     ],
//     "solutions": [
//       {
//         "id": 1,
//         "prix": 1230,
//         "selected": true,
//         "suppliers": ["ID2f", "SCG"]
//         "dependencies": {
//          "urn": [1, 2, 3]
//         }
//       }
//     ]
//   }
// }
const makeSelectSolutions = () =>
  createSelector(
    [selectSolutionsDomain(), makeSelectGroupNames()],
    (solutions, groupNames) => {
      if (!solutions) return {};
      const formattedSolutions = {};
      groupNames.forEach(groupName => {
        formattedSolutions[groupName] = {};
        // Get all prestations from first solutions
        formattedSolutions[groupName].prestations = solutions[groupName]
          .find(solution => solution.selected)
          .items.map(item => item.prestation.description);
        // Get of each solution
        formattedSolutions[groupName].solutions = solutions[groupName].map(
          solution => ({
            id: solution.id,
            selection_id: solution.selection_id,
            selected: solution.selected,
            dependencies: solution.dependencies,
            price:
              Math.round(
                solution.items.reduce((total, item) => total + item.price, 0) *
                  100,
              ) / 100,
            suppliers: [
              ...new Set(
                solution.items.map(item => item.warehouse.supplier_name),
              ),
            ],
          }),
        );
      });
      return formattedSolutions;
    },
  );

const makeSelectSelectedSolutions = () =>
  createSelector(
    [makeSelectGroupNames(), makeSelectSolutions()],
    (groupNames, solutions) => {
      if (groupNames.length === 0) {
        return {};
      }
      const selectedSolutions = {};
      groupNames.forEach(groupName => {
        selectedSolutions[groupName] = solutions[groupName].solutions.find(
          sol => sol.selected,
        );
      });
      return selectedSolutions;
    },
  );

export {
  makeSelectisBookingLoading,
  makeSelectSuppliers,
  makeSelectBookingStatus,
  makeSelectGroupNames,
  makeSelectSolutions,
  makeSelectBookingCommentLoader,
  makeSelectSelectedSolutions,
};
