import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: { id: 'app.containers.Client.DeathDeclarationValidation.title' },
  requester: {
    id: 'app.containers.Client.DeathDeclarationValidation.requester',
  },
  email: { id: 'app.containers.Client.DeathDeclarationValidation.email' },
  actOfDeath: {
    id: 'app.containers.Client.DeathDeclarationValidation.actOfDeath',
  },
  comment: { id: 'app.containers.Client.DeathDeclarationValidation.comment' },
  refuse: { id: 'app.containers.Client.DeathDeclarationValidation.refuse' },
  accept: { id: 'app.containers.Client.DeathDeclarationValidation.accept' },
});
