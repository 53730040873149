import Concessions from './Concessions';
import EditableDocuments from './EditableDocuments';
import General from './General';
import Misc from './Misc';
import OpeningHours from './OpeningHours';
import SupportingDocuments from './SupportingDocuments';

export default {
  Concessions,
  EditableDocuments,
  General,
  Misc,
  OpeningHours,
  SupportingDocuments,
};
