import { difference } from './difference'
import { intersection } from './intersection'
import { last } from './last'
import { minBy } from './minBy'
import { pullAt } from './pullAt'
import { shuffle } from './shuffle'
import { uniq } from './uniq'

export const Arrays = {
  difference,
  intersection,
  last,
  minBy,
  pullAt,
  shuffle,
  uniq,
}
