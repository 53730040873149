import { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react'
import { Field, UseFieldConfig } from 'react-final-form'
import { composeOnChange } from '../../support'

import Radio from '../../UI/Radio'
import { composeValidators, isRequired, Validator } from '../../validators'

interface ProductPropsBase {
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  image?: ReactNode
  title: ReactNode
  vignetteUrls?: string[]
  price: ReactNode | null
  required?: boolean
  onChange?: (ev: ChangeEvent) => void
  validate?: Validator<InputHTMLAttributes<HTMLInputElement>['value']>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  className?: string
}

interface ProductPropsWithAction extends ProductPropsBase {
  ctaText: ReactNode
  ctaAction: () => void
}

interface ProductPropsWithoutAction extends ProductPropsBase {
  ctaText?: undefined
  ctaAction?: undefined
}

type ProductProps = ProductPropsWithAction | ProductPropsWithoutAction

export default function Product({
  image,
  name,
  value,
  title,
  price,
  vignetteUrls,
  ctaText,
  ctaAction,
  required,
  validate,
  onChange,
  parse,
  className,
}: ProductProps): JSX.Element {
  return (
    <Field
      type="radio"
      name={name}
      value={value}
      parse={parse}
      validate={composeValidators(required && isRequired, validate)}
    >
      {({ input, meta }): JSX.Element => (
        <Radio.Product
          image={image}
          title={title}
          price={price}
          vignetteUrls={vignetteUrls}
          ctaText={ctaText}
          ctaAction={ctaAction}
          onChange={composeOnChange(input.onChange, onChange)}
          name={name}
          value={value}
          checked={input.checked}
          error={!!(meta.touched && meta.error)}
          className={className}
        />
      )}
    </Field>
  )
}
