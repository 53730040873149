import { authenticate } from '../../lib/decorators'
import type { ApiRequestDescriptor } from '../../lib/request'
import { get } from '../../lib/methods'
import type { FuneralTeamUserJSON } from '../../models/FuneralTeamUser'

export class FuneralTeam {
  @authenticate
  static index(): ApiRequestDescriptor<FuneralTeamUserJSON> {
    return get('/api/v1/users/funeral_team')
  }
}
