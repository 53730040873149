import { Deals } from './Deals'
import { Filters } from './Filters'
import { Products } from './Products'

export class FlowerFunnel {
  static readonly Deals = Deals

  static readonly Filters = Filters

  static readonly Products = Products
}
