import { GET_ORDERS_LINKED, SET_ORDERS_LINKED } from '../constants';

export function getOrdersLinked(dealUUID, dealType) {
  return {
    type: GET_ORDERS_LINKED,
    dealUUID,
    dealType,
  };
}

export function setOrdersLinked(ordersLinked) {
  return {
    type: SET_ORDERS_LINKED,
    ordersLinked,
  };
}
