import { DateTime } from 'luxon';
import type { AnyAction } from 'redux';

import { Session } from '@advitam/api';
import { assert, isNil } from '@advitam/support';

import { error } from 'containers/App/slice';
import {
  makeSelectUser,
  AppStateSubset as AuthAppStateSubset,
} from 'slices/auth';
import { makeSelectLastActionDate } from 'containers/ResourceCable/selectors';
import {
  setLastAction,
  AppStateSubset as ResourceAppStateSubset,
  setIsUpdated,
  setIsDestroyed,
} from 'containers/ResourceCable/slice';
import { fetchDocument } from 'containers/Documents/thunk';
import { fetchItem } from 'containers/Deal/Sections/Todolist/thunk';
import { removeItemById } from 'containers/Deal/Sections/Todolist/slice';
import { removeDocuments } from 'containers/Documents/actions.js';
import { setDealFields } from 'containers/Deal/actions.js';
import { removeDocumentsByIds } from 'containers/Deal/SendBatchModal/slice';
import { fetchFuneralDetails } from 'containers/Deal/DealFuneral/actions.js';
import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import {
  dealSaved,
  AppStateSubset as DealAppStateSubset,
} from 'containers/Deal/slice';
import { fetchClients, fetchDealDetailsThunk } from 'containers/Deal/thunk';
import { createCable, CableEvents } from 'lib/reactvitam/action_cable';

import marco from './actions/Marco';
import polo from './actions/Polo';
import destroy from './actions/Destroy';
import disconnect from './actions/Disconnect';
import focus from './actions/Focus';
import unfocus from './actions/Unfocus';
import update from './actions/Update';
import updateFailed from './actions/UpdateFailed';
import updateStart from './actions/UpdateStart';
import updateDeal from './actions/UpdateDeal';
import updateV2 from './actions/UpdateV2';
import ping from './actions/Ping';
import documentReady from './actions/DocumentReady';
import {
  todoItemsEnabled,
  todoItemsDisabled,
  todoItemsUpdated,
} from './actions/TodoItems';
import documentsDisabled from './actions/DocumentsDisabled';
import i18nMessages from './messages';
import { RESOURCE_CHANNEL, RESOURCE_CHANNEL_KEY } from './constants';
import { makeSelectSessionId } from './selectors';

export const enum ResourceRoomType {
  CLIENT = 'Client',
  CITYHALL = 'Cityhall',
  CONSULATE = 'Consulate',
  CREMATORIUM = 'Crematorium',
  DEAL = 'Deal',
  FLIGHT = 'Flight',
  FUNERAL_PARLOR = 'FuneralParlor',
  GRAVEYARD = 'Graveyard',
  HOSPITAL = 'Hospital',
  POLICE = 'Police',
  PREFECTURE = 'Prefecture',
  REGIONAL_HEALTH_AUTHORITY = 'RegionalHealthAuthority',
  SUPPLIER = 'Supplier',
  SUPPLIER_WAREHOUSE = 'SupplierWarehouse',
  WORSHIP = 'Worship',
}

export interface ResourceRoomParams {
  resource_type: ResourceRoomType;
  resource_id: number;
}

export interface UserSession {
  id: string;
  isFocused: boolean;
}

export interface UserInfos {
  lastAction: string;
  sessions: UserSession[];
  disconnections: number;
}

export interface AppStateSubset {
  [RESOURCE_CHANNEL_KEY]: State | undefined;
}

export interface State {
  sessionId: string | null;
  users: Record<number, UserInfos>;
  updatingUserId: number | null;
}

export const initialState: State = {
  sessionId: Session.SESSION_ID,
  users: {},
  updatingUserId: null,
};

function recordUser(
  state: State,
  userId: number,
  sessionId: string,
  lastAction: string,
): void {
  if (state.sessionId === sessionId) {
    return;
  }

  /* eslint-disable no-param-reassign */
  state.users[userId] ||= {
    lastAction,
    sessions: [],
    disconnections: 0,
  };
  state.users[userId].lastAction = lastAction;
  if (!state.users[userId].sessions.find(s => s.id === sessionId)) {
    state.users[userId].sessions.push({ id: sessionId, isFocused: true });
  }
  /* eslint-enable no-param-reassign */
}

const resourceRoom = createCable<ResourceRoomParams, State>({
  name: RESOURCE_CHANNEL,
  initialState,
  events: builder => {
    builder.addCase(CableEvents.CONNECTED, null, ({ send, getState }) => {
      const store = getState() as AppStateSubset & AuthAppStateSubset;
      const user = makeSelectUser()(store);
      const sessionId = makeSelectSessionId()(store);
      assert(!isNil(sessionId));
      assert(user !== null);
      send(marco(sessionId, user));
    });

    builder.addCase(
      CableEvents.DISCONNECTED,
      null,
      ({ dispatch, payload: { willAttemptReconnect } }) => {
        if (!willAttemptReconnect) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          dispatch(error({ message: i18nMessages.connectionBroke }));
        }
      },
    );
  },
  messages: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(
      marco,
      (state, { payload: { user_id, session_id } }) => {
        // TODO: Settings.throwOnInvalid = true;
        const now = DateTime.now().toISO();
        assert(now !== null);
        recordUser(state, user_id, session_id, now);
      },
      ({ send, getState, payload }) => {
        const store = getState() as AppStateSubset &
          ResourceAppStateSubset &
          AuthAppStateSubset;
        const user = makeSelectUser()(store);
        const lastActionDate = makeSelectLastActionDate()(store);
        const sessionId = makeSelectSessionId()(store);
        assert(!isNil(sessionId));
        assert(user !== null);

        if (payload.session_id !== sessionId) {
          send(polo(sessionId, user, lastActionDate));
        }
      },
    );

    builder.addCase(polo, (state, { payload }) => {
      recordUser(
        state,
        payload.user_id,
        payload.session_id,
        payload.last_action_dt,
      );
    });

    builder.addCase(disconnect, (state, { payload: { user_id } }) => {
      if (!state.users[user_id]) {
        return;
      }

      state.users[user_id].disconnections += 1;
      const { sessions, disconnections } = state.users[user_id];
      if (disconnections === sessions.length) {
        delete state.users[user_id];
      }
    });

    builder.addCase(focus, (state, { payload: { user_id, session_id } }) => {
      if (!state.users[user_id]) {
        return;
      }

      const session = state.users[user_id].sessions.find(
        s => s.id === session_id,
      );
      if (session) {
        session.isFocused = true;
      }
    });

    builder.addCase(unfocus, (state, { payload: { user_id, session_id } }) => {
      if (!state.users[user_id]) {
        return;
      }

      const session = state.users[user_id].sessions.find(
        s => s.id === session_id,
      );
      if (session) {
        session.isFocused = false;
      }
    });

    builder.addCase(
      ping,
      (state, { payload: { user_id } }) => {
        if (state.users[user_id]) {
          // TODO: Settings.throwOnInvalid = true;
          const now = DateTime.now().toISO();
          assert(now !== null);
          state.users[user_id].lastAction = now;
        }
      },
      ({ payload, getState, dispatch }) => {
        const store = getState() as AuthAppStateSubset;
        const user = makeSelectUser()(store);
        if (user?.id === payload.user_id) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          dispatch(setLastAction());
        }
      },
    );

    builder.addCase(destroy, null, ({ dispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(setIsDestroyed(true));
    });

    builder.addCase(updateStart, (state, { payload }) => {
      if (payload.session_id !== state.sessionId) {
        state.updatingUserId = payload.user_id;
      }
    });

    builder.addCase(updateFailed, (state, { payload }) => {
      if (payload.user_id === state.updatingUserId) {
        state.updatingUserId = null;
      }
    });

    builder.addCase(
      update,
      (state, { payload }) => {
        if (payload.user_id === state.updatingUserId) {
          state.updatingUserId = null;
        }
      },
      ({ getState, payload, dispatch }) => {
        const store = getState() as AppStateSubset & ResourceAppStateSubset;
        const sessionId = makeSelectSessionId()(store);

        if (payload.session_id !== sessionId) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          dispatch(setIsUpdated(true));
        }
      },
    );

    builder.addCase(updateV2, null, ({ getState, payload, dispatch }) => {
      const store = getState() as AppStateSubset & ResourceAppStateSubset;
      const sessionId = makeSelectSessionId()(store);

      if (payload.session_id !== sessionId) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        dispatch(setDealFields(payload.body, false));
      }
    });

    builder.addCase(documentReady, null, ({ payload, dispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(fetchDocument(payload.id));
    });

    builder.addCase(documentsDisabled, null, ({ payload, dispatch }) => {
      const documentIds = payload.documents.map(doc => doc.id);

      /* eslint-disable @typescript-eslint/no-floating-promises */
      dispatch(removeDocuments(documentIds));
      dispatch(removeDocumentsByIds(documentIds));
      /* eslint-enable @typescript-eslint/no-floating-promises */
    });

    builder.addCase(todoItemsEnabled, null, ({ payload, dispatch }) => {
      payload.deal_todo_items.forEach(item => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        dispatch(fetchItem({ itemId: item.id }));
      });
    });
    builder.addCase(todoItemsDisabled, null, ({ payload, dispatch }) => {
      payload.deal_todo_items.forEach(item => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        dispatch(removeItemById(item.id));
      });
    });
    builder.addCase(todoItemsUpdated, null, ({ payload, dispatch }) => {
      payload.deal_todo_items.forEach(item => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        dispatch(fetchItem({ itemId: item.id, force: true }));
      });
    });

    builder.addCase(
      updateDeal,
      (state, { payload }) => {
        if (payload.user_id === state.updatingUserId) {
          state.updatingUserId = null;
        }
      },
      ({ payload, dispatch, getState }) => {
        const store = getState() as AppStateSubset &
          ResourceAppStateSubset &
          DealAppStateSubset;
        const sessionId = makeSelectSessionId()(store);
        const storedDeal = makeSelectDeal()(store);
        assert(storedDeal !== null);

        /* eslint-disable @typescript-eslint/no-floating-promises */
        if (payload.session_id !== sessionId) {
          dispatch(setIsUpdated(true));
          return;
        }

        let promise: Promise<AnyAction | void> = Promise.resolve();
        if (storedDeal.clients.length === 0) {
          promise = dispatch(fetchClients(payload.deal));
        }

        promise.then(() => {
          dispatch(fetchDealDetailsThunk()).then(() => {
            dispatch(dealSaved(payload.deal));
            // Warning: this dispatches a saga, meaning that we can not await for the result.
            // As such, triggering dependent actions after it would be a race condition.
            dispatch(fetchFuneralDetails(payload.deal));
          });
        });
        /* eslint-enable @typescript-eslint/no-floating-promises */
      },
    );
    /* eslint-enable no-param-reassign */
  },
});

export default resourceRoom;
