import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { TodoItemJSON } from '@advitam/api/models/Deal/TodoItem';

import { assert } from 'lib/support';
import { TODO_LIST } from './constants';
import { fetchItem, fetchItems, updateItem } from './thunk';
import { createItem } from './CreationModal/thunk';

export interface State {
  items: TodoItemJSON[];
  loadingItemsIds: number[];
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [TODO_LIST]: State;
}

export const initialState: State = {
  items: [],
  loadingItemsIds: [],
  isLoading: true,
  error: null,
};

const slice = createSlice({
  name: TODO_LIST,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    removeItemById(state, { payload }: PayloadAction<number>) {
      state.items = state.items.filter(item => item.id !== payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchItems.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchItems.fulfilled, (state, { payload }) => {
      state.items = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchItems.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
      state.isLoading = false;
    });

    builder.addCase(createItem.fulfilled, (state, { payload }) => {
      state.items.push(payload);
    });

    builder.addCase(fetchItem.pending, (state, { meta }) => {
      const { itemId } = meta.arg;
      state.loadingItemsIds.push(itemId);
    });
    builder.addCase(fetchItem.fulfilled, (state, { payload }) => {
      state.loadingItemsIds = state.loadingItemsIds.filter(
        id => id !== payload.id,
      );

      const itemIdx = state.items.findIndex(i => i.id === payload.id);

      if (itemIdx !== -1) {
        state.items[itemIdx] = payload;
      } else {
        state.items.push(payload);
      }
    });
    builder.addCase(fetchItem.rejected, (state, { payload, meta }) => {
      const { itemId } = meta.arg;
      state.loadingItemsIds = state.loadingItemsIds.filter(id => id !== itemId);
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateItem.pending, (state, { meta }) => {
      state.loadingItemsIds.push(meta.arg.id);
    });
    builder.addCase(updateItem.fulfilled, (state, { payload }) => {
      const itemIdx = state.items.findIndex(i => i.id === payload.id);
      assert(itemIdx !== -1);

      state.items[itemIdx] = payload;
      state.loadingItemsIds = state.loadingItemsIds.filter(
        id => id !== payload.id,
      );
    });
    builder.addCase(updateItem.rejected, (state, { payload, meta }) => {
      state.loadingItemsIds = state.loadingItemsIds.filter(
        id => id !== meta.arg.id,
      );
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { removeItemById } = slice.actions;
export default slice;
