// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__label--ef1JC{color:#fff;cursor:pointer}.style__label--ef1JC input{margin-right:4px}.style__delivery-message--jP2pI{display:flex;flex-direction:column;margin:10px 0;color:#fff}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/DealItems/DeliverySection/style.scss"],"names":[],"mappings":"AAAA,qBACE,UAAA,CACA,cAAA,CAEA,2BACE,gBAAA,CAIJ,gCACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,UAAA","sourcesContent":[".label {\n  color: #fff;\n  cursor: pointer;\n\n  input {\n    margin-right: 4px;\n  }\n}\n\n.delivery-message {\n  display: flex;\n  flex-direction: column;\n  margin: 10px 0;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `style__label--ef1JC`,
	"delivery-message": `style__delivery-message--jP2pI`
};
export default ___CSS_LOADER_EXPORT___;
