import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  pageTitle: { id: 'app.containers.Deal.Funeral.Sidebar.pageTitle' },
  home: { id: 'app.containers.Deal.Funeral.Sidebar.home' },
  todolist: { id: 'app.containers.Deal.Funeral.Sidebar.todolist' },
  summary: { id: 'app.containers.Deal.Funeral.Sidebar.summary' },
  identity: { id: 'app.containers.Deal.Funeral.Sidebar.identity' },
  steps: { id: 'app.containers.Deal.Funeral.Sidebar.steps' },
  documents: { id: 'app.containers.Deal.Funeral.Sidebar.documents' },
  suppliers: { id: 'app.containers.Deal.Funeral.Sidebar.suppliers' },
  billing: { id: 'app.containers.Deal.Funeral.Sidebar.billing' },
  miscellaneous: { id: 'app.containers.Deal.Funeral.Sidebar.miscellaneous' },
  history: { id: 'app.containers.Deal.Funeral.Sidebar.history' },
});
