import { Model } from './Model';

export const enum OpsLogTag {
  OPS = 'ops',
  PAYMENTS = 'payments',
}

interface OpsLogBase {
  id: number;
  deal_id: number;
  business_user_id: number;
  tag: OpsLogTag;
  content: string;
}

export interface OpsLogJSON extends OpsLogBase {
  created_at: string;
  updated_at: string;
}

export interface OpsLog extends OpsLogBase {
  created_at: Date;
  updated_at: Date;
}

export class OpsLog extends Model<OpsLogJSON> {
  constructor(data: OpsLogJSON) {
    super(data);
    this.created_at = Model.parseDate(data.created_at);
    this.updated_at = Model.parseDate(data.updated_at);
  }
}
