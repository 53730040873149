import { BusinessUser } from '@advitam/api/models/User/BusinessUser';
import { createAction } from 'lib/reactvitam/action_cable';

export interface FocusPayload {
  user_id: number;
  session_id: string;
}

function focus(user: BusinessUser, session_id: string): FocusPayload {
  return { user_id: user.id, session_id };
}

export default createAction(focus, 'focus');
