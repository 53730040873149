import { AnyAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

type Action = AnyAction & { payload: unknown }
type AsyncDispatch = (action: Omit<Action, 'type'>) => Promise<Action>

/** @deprecated Use useThunkDispatch that infers the return type automatically */
export function useAsyncDispatch(): AsyncDispatch {
  return useDispatch<AsyncDispatch>()
}
