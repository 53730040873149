import { post } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'

export interface CallbackCreationBody {
  deal_uuid: string
  amount: number
}

export interface CallbackCreationError {
  deal_uuid: string
  errors: string[]
}

export class Payment {
  static create(session_id: string, provider: string): ApiRequestDescriptor<CallbackCreationBody> {
    return post('/api/v1/fintecture/payment', { session_id, provider })
  }
}
