import { Fragment } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import {
  DragDropContext,
  DraggableLocation,
  DropResult,
} from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';

import { assert } from '@advitam/support';
import { getUuid } from 'utils/functions.typed';

import { DOCUMENTS_FIELD_NAME } from '../constants';
import { DocumentEdition } from '../types';
import {
  makeSelectCurrentResource,
  makeSelectIsSingleDocumentUpload,
} from '../selectors';
import Document from './Document';
import Splitter from './Splitter';
import messages from './messages';
import { validateDocuments } from './validators';
import style from './DocumentList.module.scss';

export default function DocumentList(): JSX.Element {
  const isSingleDocumentUpload = useSelector(
    makeSelectIsSingleDocumentUpload(),
  );
  const currentResource = useSelector(makeSelectCurrentResource());
  assert(currentResource !== null);

  const { fields, meta } = useFieldArray<DocumentEdition>(
    DOCUMENTS_FIELD_NAME,
    {
      validate: validateDocuments,
    },
  );

  const updateDocument = (index: number, document: DocumentEdition): void => {
    fields.update(index, document);
  };

  const getDocumentIndexByUuid = (documentUuid: string): number =>
    fields.value.findIndex(doc => doc.uuid === documentUuid);

  const cloneDocumentByIdx = (index: number): DocumentEdition =>
    cloneDeep(fields.value[index]);

  const mergeDocuments = (mergeIndex: number): void => {
    const destIdx = mergeIndex - 1;
    const destDocument = cloneDocumentByIdx(destIdx);

    destDocument.pages = [
      ...destDocument.pages,
      ...fields.value[mergeIndex].pages,
    ];

    updateDocument(destIdx, destDocument);
    fields.remove(mergeIndex);
  };

  const splitDocumentPages = (documentIdx: number, splitIdx: number): void => {
    const document = cloneDocumentByIdx(documentIdx);
    const movedPages = document.pages.splice(
      splitIdx,
      document.pages.length - splitIdx,
    );

    const newDocument: DocumentEdition = {
      error: null,
      pages: movedPages,
      resource: currentResource,
      sessionId: document.uuid,
      uuid: getUuid(),
    };

    updateDocument(documentIdx, document);
    fields.insert(documentIdx + 1, newDocument);
  };

  const movePage = (
    { droppableId: sourceDocUuid, index: sourcePageIdx }: DraggableLocation,
    { droppableId: destDocUuid, index: destPageIdx }: DraggableLocation,
  ): void => {
    const sourceDocIdx = getDocumentIndexByUuid(sourceDocUuid);
    const destDocIdx = getDocumentIndexByUuid(destDocUuid);

    const sourceDocument = cloneDocumentByIdx(sourceDocIdx);
    const destDocument = cloneDocumentByIdx(destDocIdx);

    const [movedPage] = sourceDocument.pages.splice(sourcePageIdx, 1);
    destDocument.pages.splice(destPageIdx, 0, movedPage);

    updateDocument(destDocIdx, destDocument);

    if (sourceDocument.pages.length === 0) {
      fields.remove(sourceDocIdx);
    } else {
      updateDocument(sourceDocIdx, sourceDocument);
    }
  };

  const reorderDocumentPages = (
    { droppableId: sourceDocUuid, index: sourcePageIdx }: DraggableLocation,
    { index: destPageIdx }: DraggableLocation,
  ): void => {
    if (sourcePageIdx === destPageIdx) {
      return;
    }

    const sourceDocIdx = getDocumentIndexByUuid(sourceDocUuid);
    const sourceDocument = cloneDocumentByIdx(sourceDocIdx);

    const [movedPage] = sourceDocument.pages.splice(sourcePageIdx, 1);
    sourceDocument.pages.splice(destPageIdx, 0, movedPage);

    updateDocument(sourceDocIdx, sourceDocument);
  };

  const onDragEnd = ({ source, destination }: DropResult): void => {
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      reorderDocumentPages(source, destination);
      return;
    }

    movePage(source, destination);
  };

  return (
    <div className={style.container}>
      <div className={style.list}>
        <DragDropContext onDragEnd={onDragEnd}>
          {fields.value.map((document, documentIndex) => (
            <Fragment key={document.uuid}>
              {!isSingleDocumentUpload && documentIndex > 0 && (
                <Splitter
                  message={messages.mergeDocuments}
                  onClick={(): void => mergeDocuments(documentIndex)}
                />
              )}
              <Document
                index={documentIndex}
                document={document}
                hasError={meta.touched && meta.error?.includes(document.uuid)}
                splitPages={(splitIdx): void =>
                  splitDocumentPages(documentIndex, splitIdx)
                }
                updateDocument={(newDocument): void =>
                  updateDocument(documentIndex, newDocument)
                }
              />
            </Fragment>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
}
