import { Permission } from '@advitam/api/models/Graveyard';
import { PERMISSION_VALUE } from './fields/constants';

const PERMISSION_REVERSE_VALUES = {
  [PERMISSION_VALUE.allowed]: Permission.ALLOWED,
  [PERMISSION_VALUE.forbidden]: Permission.FORBIDDEN,
};

export function getPermissionAsEnum(
  permission: number | null,
): Permission | null {
  if (permission === null) {
    return null;
  }

  return PERMISSION_REVERSE_VALUES[permission] || Permission.REQUIRED;
}
