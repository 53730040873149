import { createSelector, Selector } from 'reselect';

import {
  BusinessUser,
  BusinessUserJSON,
} from '@advitam/api/models/User/BusinessUser';

import { AUTH } from './constants';
import type { AppStateSubset, State } from './slice';

type AuthSelector<T> = Selector<AppStateSubset, T>;

const selectAuthDomain = (state: AppStateSubset): State => state[AUTH];

const makeSelectRawUser = (): AuthSelector<BusinessUserJSON | null> =>
  createSelector(selectAuthDomain, ({ user }) => user);

export const makeSelectUser = (): AuthSelector<BusinessUser | null> =>
  createSelector(makeSelectRawUser(), user =>
    user ? new BusinessUser(user) : null,
  );

export const makeSelectIsAuthenticated = (): AuthSelector<boolean> =>
  createSelector(selectAuthDomain, ({ isAuthenticated }) => isAuthenticated);
