import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { SupplierMinimalWarehouseJSON } from '@advitam/api/models/Supplier/Warehouse';
import { ConfirmationModal, ErrorText, FormattedApiError } from '@advitam/ui';
import { useThunkDispatch } from '@advitam/react';

import actionsMessages from 'messages/actions';

import { makeSelectDisableError, makeSelectIsDeleting } from './selectors';
import { disableWarehouse } from './thunk';
import messages from './messages';

interface DisableModalProps {
  warehouseToDelete: SupplierMinimalWarehouseJSON;
  onClose: () => void;
}

export default function DisableModal({
  warehouseToDelete,
  onClose,
}: DisableModalProps): JSX.Element {
  const dispatch = useThunkDispatch();
  const isDeleting = useSelector(makeSelectIsDeleting());
  const disableError = useSelector(makeSelectDisableError());

  const onDisableConfirmation = useCallback(async () => {
    try {
      await dispatch(disableWarehouse(warehouseToDelete.id));
      onClose();
    } catch {
      // let the slice handle
    }
  }, [dispatch, disableWarehouse, warehouseToDelete]);

  return (
    <ConfirmationModal
      isOpen
      title={<FormattedMessage id={messages.disableConfirmationTitle.id} />}
      text={
        <>
          <FormattedMessage
            id={messages.disableConfirmationText.id}
            values={{ name: <b>{warehouseToDelete.name}</b> }}
          />
          {disableError && (
            <ErrorText>
              <FormattedApiError error={disableError} />
            </ErrorText>
          )}
        </>
      }
      confirm={<FormattedMessage id={actionsMessages.confirm.id} />}
      onConfirm={onDisableConfirmation}
      isConfirmationLoading={isDeleting}
      cancel={<FormattedMessage id={actionsMessages.cancel.id} />}
      onCancel={onClose}
    />
  );
}
