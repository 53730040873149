import { useState, useEffect } from 'react'

export function useScrollEffect<T>(effect: () => T): T {
  const [value, setValue] = useState<T>(effect())

  useEffect(() => {
    const handleScroll = (): void => {
      setValue(effect())
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return (): void => window.removeEventListener('scroll', handleScroll)
  }, [])

  return value
}
