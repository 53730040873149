import { ReactNode } from 'react'

import ArrowIcon from '../../../images/icons/arrow-right.svg'
import Button from '../../Button'
import Spinner from '../../Spinner'
import Text from '../../Text'
import Modal from '../Modal'
import Title from '../Title'
import style from './Content.module.scss'

interface MessageProps {
  isOpen: boolean
  title?: ReactNode
  message: ReactNode
  ctaText: ReactNode
  isLoading?: boolean
  withArrow?: boolean
  onClose: () => void
}

export default function Message({
  isOpen,
  title,
  message,
  ctaText,
  isLoading = false,
  withArrow = false,
  onClose,
}: MessageProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} className={`col-4 col-md-6 ${style.message}`} onRequestClose={onClose}>
      {title && <Title>{title}</Title>}
      <Text tagName="div" className={style.content}>
        {message}
      </Text>
      <Button
        primary
        fixedSize
        onClick={onClose}
        disabled={isLoading}
        text={isLoading ? <Spinner /> : ctaText}
        className={style.message__button}
        icon={withArrow ? <ArrowIcon /> : undefined}
      />
    </Modal>
  )
}
