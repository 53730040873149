import { authenticate } from '../../../lib/decorators'
import { get } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { SupplierCoverageJSON } from '../../../models/Supplier/Coverage'
import { Synchronize } from './Synchronize'

export class Coverage {
  static readonly Synchronize = Synchronize

  @authenticate
  static index(supplierIds?: number[]): ApiRequestDescriptor<SupplierCoverageJSON[]> {
    return get('/api/v1/suppliers/coverage', { suppliers: supplierIds })
  }
}
