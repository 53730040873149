// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Yousign-module__container--S1wrT{height:0;padding-bottom:calc(141.4285714286% + 260px);position:relative}.Yousign-module__container--S1wrT .Yousign-module__frame--_IsmK{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;border:none;border-radius:12px}`, "",{"version":3,"sources":["webpack://./../ui/components/Yousign/Yousign.module.scss"],"names":[],"mappings":"AAEA,kCACE,QAAA,CACA,4CAAA,CACA,iBAAA,CAEA,gEACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":["@use 'sass:math';\n\n.container {\n  height: 0;\n  padding-bottom: calc(#{math.div(29.7, 21) * 100%} + 260px);\n  position: relative;\n\n  .frame {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    border: none;\n    border-radius: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Yousign-module__container--S1wrT`,
	"frame": `Yousign-module__frame--_IsmK`
};
export default ___CSS_LOADER_EXPORT___;
