import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { CityhallJSON } from '@advitam/api/models/Cityhall';
import { NearEntityJSON } from '@advitam/api/models/Entity/Near';
import { EntityType } from '@advitam/api/models/Entity/EntityType';
import { LocationType } from '@advitam/api/models/LocationType';
import type { CityhallAutocompleteResult } from '@advitam/ui/components/Form/Autocomplete/CityhallAutocomplete/types';

import { DEFAULT_CENTER, DEFAULT_ZOOM, MAP } from './constants';
import type { AppStateSubset, State } from './slice';
import { EntityMarker, MapActiveSupplier, PositionableMarker } from './types';
import { getMarkerId } from './utils';

function isPositionableMarker(
  entity: Partial<CityhallJSON | NearEntityJSON>,
): entity is PositionableMarker {
  return Boolean(entity.latitude) && Boolean(entity.longitude);
}

type MapSelector<T> = Selector<AppStateSubset, T>;

const selectMapDomain = (state: AppStateSubset): State => state[MAP];

export const makeSelectCityhall = (): MapSelector<CityhallJSON | null> =>
  createSelector(selectMapDomain, ({ cityhall }) => cityhall);

export function makeSelectCityhallInputValue(): MapSelector<
  CityhallAutocompleteResult | undefined
> {
  return createSelector(makeSelectCityhall(), cityhall => {
    if (!cityhall) {
      return undefined;
    }
    return {
      id: cityhall.id,
      name: cityhall.name,
      postal_code: cityhall.postal_code || '',
      insee_code: cityhall.insee_code || '',
      description: `${cityhall.name} (${cityhall.postal_code || ''})`,
    };
  });
}

export const makeSelectCenter = (): MapSelector<
  google.maps.LatLng | google.maps.LatLngLiteral
> =>
  createSelector(makeSelectCityhall(), cityhall => {
    if (!cityhall?.latitude || !cityhall.longitude) {
      return DEFAULT_CENTER;
    }
    return { lat: cityhall.latitude, lng: cityhall.longitude };
  });

export const makeSelectZoom = (): MapSelector<number> =>
  createSelector(
    makeSelectCityhall(),
    cityhall => cityhall?.google_zoom || DEFAULT_ZOOM,
  );

export const makeSelectEntityTypes = (): MapSelector<EntityType[]> =>
  createSelector(selectMapDomain, ({ nearEntityTypes }) => nearEntityTypes);

export const makeSelectNearEntities = (): MapSelector<NearEntityJSON[]> =>
  createSelector(selectMapDomain, ({ nearEntities }) => nearEntities);

/** Dot not use this selector, it is exported for testing purpose only */
export const makeSelectCityhallMarker = (): MapSelector<EntityMarker | null> =>
  createSelector(makeSelectCityhall(), cityhall => {
    if (!cityhall || !isPositionableMarker(cityhall)) {
      return null;
    }

    return {
      ...cityhall,
      city: cityhall.name,
      type: LocationType.CITYHALL,
    };
  });

function isValidEntityMarker(entity: NearEntityJSON): entity is EntityMarker {
  return isPositionableMarker(entity);
}

/** Dot not use this selector, it is exported for testing purpose only */
export const makeSelectNearEntitiesMarkers = (): MapSelector<EntityMarker[]> =>
  createSelector(makeSelectNearEntities(), nearEntities =>
    nearEntities.filter(isValidEntityMarker),
  );

export const makeSelectMarkers = (): MapSelector<EntityMarker[]> =>
  createSelector(
    makeSelectCityhallMarker(),
    makeSelectNearEntitiesMarkers(),
    (cityhallMarker, nearEntityMarkers) => {
      if (!cityhallMarker) {
        return [];
      }
      return [cityhallMarker, ...nearEntityMarkers];
    },
  );

const makeSelectActiveMarkerId = (): MapSelector<string | null> =>
  createSelector(selectMapDomain, ({ activeMarkerId }) => activeMarkerId);

export const makeSelectIsActiveMarker = (
  marker: EntityMarker,
): MapSelector<boolean> =>
  createSelector(
    makeSelectActiveMarkerId(),
    activeMarkerId =>
      Boolean(activeMarkerId) && activeMarkerId === getMarkerId(marker),
  );

export const makeSelectActiveSupplier = (): MapSelector<MapActiveSupplier | null> =>
  createSelector(selectMapDomain, ({ activeSupplier }) => activeSupplier);

export const makeSelectError = (): MapSelector<SerializedApiError | null> =>
  createSelector(selectMapDomain, ({ error }) => error);
