import type { SupplierJSON } from '@advitam/api/models/Supplier';
import type { SupplierWarehouseJSON } from '@advitam/api/models/Supplier/Warehouse';

export function formatName(
  supplier: SupplierJSON,
  supplierWarehouse: SupplierWarehouseJSON,
): string {
  return `${supplier.name} - ${supplierWarehouse.name}`;
}

export function FormattedName(
  supplier: SupplierJSON,
  supplierWarehouse: SupplierWarehouseJSON,
): JSX.Element {
  return <>{formatName(supplier, supplierWarehouse)}</>;
}
