import { defineMessages } from 'react-intl';

export default defineMessages({
  no_network: { id: 'app.components.Format.ApiError.no_network' },
  unhandled: { id: 'app.components.Format.ApiError.unhandled' },
  supplier_warehouses__flight__departure_airport__blank: {
    id:
      'app.components.Format.ApiError.supplierWarehousesFlightDepartureAirportBlank',
  },
  supplier_warehouses__flight__arrival_airport__blank: {
    id:
      'app.components.Format.ApiError.supplierWarehousesFlightArrivalAirportBlank',
  },
  supplier_warehouses__flight__airline__blank: {
    id: 'app.components.Format.ApiError.supplierWarehousesFlightAirlineBlank',
  },
  supplier_warehouses__flight__supplier_warehouse__blank: {
    id:
      'app.components.Format.ApiError.supplierWarehousesFlightSupplierWarehouseBlank',
  },
});
