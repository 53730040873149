import PropTypes from 'prop-types';

import style from './Button.module.scss';
import TransparentButton from './TransparentButton';

export function ButtonV2({ children, onClick, className, disabled }) {
  return (
    <button
      type="button"
      className={`button ${disabled ? 'button--disabled' : ''} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

ButtonV2.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonV2.defaultProps = {
  children: null,
  disabled: false,
};

export function FlatButton({ className, ...props }) {
  return <ButtonV2 {...props} className={`button--flat ${className}`} />;
}

FlatButton.propTypes = {
  className: PropTypes.string,
};

export function SubmitButton({ children, className, disabled }) {
  return (
    <button
      type="submit"
      className={`button ${disabled && 'button--disabled'} ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

SubmitButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

SubmitButton.defaultProps = {
  children: null,
  disabled: false,
};

export function CrossButton({ onClick, className, classNameContainer }) {
  return (
    <TransparentButton className={classNameContainer} onClick={onClick}>
      <button type="button" className={className}>
        ×
      </button>
    </TransparentButton>
  );
}

CrossButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  classNameContainer: PropTypes.string,
};

CrossButton.defaultProps = {
  className: 'transparentButton',
  classNameContainer: 'button button--red',
};

export function IconButton({ children, onClick, className, disabled, flat }) {
  return (
    <ButtonV2
      type="button"
      className={`button ${disabled ? 'button--disabled' : ''} button--icon ${
        flat ? 'button--flat' : ''
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <i className="material-icons">{children}</i>
    </ButtonV2>
  );
}

IconButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  flat: PropTypes.bool,
};

IconButton.defaultProps = {
  children: null,
  disabled: false,
};

export function IconSubmitButton({ children, className, disabled, flat }) {
  return (
    <button
      type="submit"
      className={`button ${disabled ? 'button--disabled' : ''} button--icon ${
        flat ? 'button--flat' : ''
      } ${className}`}
      disabled={disabled}
    >
      <i className="material-icons">{children}</i>
    </button>
  );
}

IconSubmitButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  flat: PropTypes.bool,
};

IconSubmitButton.defaultProps = {
  children: null,
  disabled: false,
};

export { TransparentButton };

/**
 * @deprecated
 */
export default function Button({
  unfloat = false,
  small = false,
  className = '',
  children,
  ...props
}) {
  const classes = [style.button_v1];
  if (unfloat) {
    classes.push(style.unfloat);
  }
  if (small) {
    classes.push(style.button_v1__small);
  }
  if (className) {
    classes.push(className);
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button {...props} className={classes.join(' ')}>
      {children}
    </button>
  );
}

Button.propTypes = {
  unfloat: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};
