import { SerializedApiError } from '@advitam/api';
import { GradedDealReviewJSON } from '@advitam/api/models/Deal/GradedReview';
import { createSelector, Selector } from 'reselect';
import { DEAL_REVIEWS } from './constants';
import { AppStateSubset, State } from './slice';

type ReviewSelector<T> = Selector<AppStateSubset, T>;

const selectDealReviewsDomain = (state: AppStateSubset): State =>
  state[DEAL_REVIEWS];

export const makeSelectReviews = (): ReviewSelector<GradedDealReviewJSON[]> =>
  createSelector(selectDealReviewsDomain, state => state.reviews);

export const makeSelectIsLoading = (): ReviewSelector<boolean> =>
  createSelector(selectDealReviewsDomain, state => state.isLoading);

export const makeSelectError = (): ReviewSelector<SerializedApiError | null> =>
  createSelector(selectDealReviewsDomain, state => state.error);
