import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';

import CloseCross from 'components/CloseCross';
import { ButtonV2 as Button } from 'components/Button';
import messages from 'containers/Documents/messages';
import messagesAction from 'messages/actions';

import style from './style.scss';

function DownloadModal({ files, isOpen, toggleModal, downloadFile }) {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="dialog"
      onRequestClose={toggleModal}
      ariaHideApp={false}
      className="modal warning"
      overlayClassName="modal__overlay warning__overlay"
    >
      <CloseCross handleClose={toggleModal} />
      <div className={style.container}>
        <FormattedMessage {...messages.titleDlModal} tagName="h3" />
        <FormattedMessage {...messages.subtitleDlModal} />
        <div className="space--10-0" />
        {files.map(file => (
          <span key={file.id}>{file.name}</span>
        ))}

        <div className={style.buttons}>
          <Button onClick={toggleModal} className="button--red">
            <FormattedMessage {...messagesAction.cancel} />
          </Button>
          <Button onClick={downloadFile}>
            <FormattedMessage {...messagesAction.confirm} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

DownloadModal.propTypes = {
  /** Boolean displaying the modal */
  isOpen: PropTypes.bool.isRequired,
  /** Selected files to download */
  files: PropTypes.array.isRequired,
  /** Function to toggle the modal */
  toggleModal: PropTypes.func.isRequired,
  /** Function to download files */
  downloadFile: PropTypes.func.isRequired,
};

export default DownloadModal;
