import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { ConcessionTermJSON } from '@advitam/api/models/Concession';
import type { GraveyardConcessionTypeJSON } from '@advitam/api/models/Graveyard';

import { GRAVEYARD_CONCESSION_INFOS } from './constants';
import { fetchConcessionInfos } from './thunk';

export interface State {
  concessionTerms: ConcessionTermJSON[];
  concessionTypes: GraveyardConcessionTypeJSON[];
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [GRAVEYARD_CONCESSION_INFOS]: State;
}

export const initialState: State = {
  concessionTerms: [],
  concessionTypes: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: GRAVEYARD_CONCESSION_INFOS,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setConcessionTerms: (
      state,
      { payload }: PayloadAction<ConcessionTermJSON[]>,
    ) => {
      state.concessionTerms = payload;
    },
    setConcessionTypes: (
      state,
      { payload }: PayloadAction<GraveyardConcessionTypeJSON[]>,
    ) => {
      state.concessionTypes = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchConcessionInfos.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConcessionInfos.fulfilled, (state, { payload }) => {
      const { concessionTerms, concessionTypes } = payload;
      state.isLoading = false;
      state.concessionTerms = concessionTerms;
      state.concessionTypes = concessionTypes;
    });
    builder.addCase(fetchConcessionInfos.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setConcessionTerms, setConcessionTypes } = slice.actions;
export default slice;
