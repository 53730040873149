import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { isUserAdmin } from 'components/Authorization/utils';
import { NumberInput } from 'components/Input';
import LockIcon from 'components/Icons/LockIcon';

import { priceFromString } from './utils.ts';
import messages from './messages';

export class Price extends PureComponent {
  /**
   * Function to handle change price
   *
   * @param {string} value value of price
   */
  handleChangePrice = ({ target: { value } }) => {
    const { onChange } = this.props;
    const updatedValue = priceFromString(value);
    onChange(updatedValue);
  };

  /**
   * Function to render the price depending on user role
   */
  renderPrice = () => {
    const { value, userRole, manualPrice } = this.props;
    const priceToDisplay = manualPrice === null ? value : manualPrice;
    if (isUserAdmin(userRole)) {
      return (
        <NumberInput
          step="0.01"
          value={manualPrice || ''}
          onChange={this.handleChangePrice}
          placeholder={value}
        />
      );
    }
    return <div>{priceToDisplay}</div>;
  };

  render() {
    const { manualPrice } = this.props;

    return (
      <div className="price">
        <FormattedMessage {...messages.price} tagName="div" />
        <div className="flex">
          {this.renderPrice()}
          <LockIcon
            className="price__lock"
            isActivated={manualPrice !== null}
          />
        </div>
      </div>
    );
  }
}

Price.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  userRole: PropTypes.number.isRequired,
  manualPrice: PropTypes.number,
};

export default Price;
