import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';

import CloseCross from 'components/CloseCross';
import { FILE_SIZE_UPLOAD_LIMIT } from 'containers/App/constants';
import {
  WARNING_FILE_TOO_BIG,
  WARNING_FILE_TYPE_IS_NOT_VALID,
  DOCUMENT_MIME_TYPES,
} from 'utils/constants';

import messages from './messages';

function UploadFiles({ postFiles, children, noClick, className }) {
  const [errModalWarning, setErrModalWarning] = useState(null);

  const onDrop = files => {
    if (!files) return;
    const errors = [];
    files.forEach(file => {
      const error = {
        name: file.name,
        messages: [],
      };
      if (file.size > FILE_SIZE_UPLOAD_LIMIT) {
        error.messages.push(WARNING_FILE_TOO_BIG);
      }
      if (!DOCUMENT_MIME_TYPES.includes(file.type)) {
        error.messages.push(WARNING_FILE_TYPE_IS_NOT_VALID);
      }

      if (error.messages.length > 0) {
        errors.push(error);
      }
    });
    if (errors.length > 0) {
      setErrModalWarning(errors);
      return;
    }
    postFiles(files);
  };

  return (
    <div className={`uploadFiles ${className || ''}`}>
      <Dropzone onDrop={onDrop} multiple noClick={noClick}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div>
            <input {...getInputProps()} />
            {children ? (
              <div
                {...getRootProps({ onClick: e => e.preventDefault() })}
                className="transparentButton"
              >
                <div
                  className={`${
                    isDragActive ? 'uploadFiles__overlay--dragging' : ''
                  }`}
                />
                {children}
              </div>
            ) : (
              <div
                {...getRootProps()}
                className="uploadFiles__area uploadFiles__area--plus"
              >
                <div
                  className={`${
                    isDragActive ? 'uploadFiles__overlay--dragging' : ''
                  }`}
                />
                <h3>+</h3>
              </div>
            )}
          </div>
        )}
      </Dropzone>

      {!!errModalWarning && (
        <Modal
          isOpen={!!errModalWarning}
          contentLabel="dialog"
          ariaHideApp={false}
          className="modal warning"
          onRequestClose={() => setErrModalWarning(null)}
          overlayClassName="modal__overlay warning__overlay"
        >
          <CloseCross handleClose={() => setErrModalWarning(null)} />
          {errModalWarning.map(item => (
            <div key={`${item.name}`} className="warning__container">
              <FormattedMessage
                {...messages.fileName}
                values={{ fileName: item.name }}
              />
              <ul>
                {item.messages.map(message => (
                  <li key={`${item.name}-${message}`}>
                    <FormattedMessage {...messages[message]} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
}

UploadFiles.propTypes = {
  /** Function to post files */
  postFiles: PropTypes.func.isRequired,
  /** Children to display */
  children: PropTypes.any,
  /** File to replace */
  fileUploaded: PropTypes.object,
  /** Disable click on upload */
  noClick: PropTypes.bool,
  /** Additional class names */
  className: PropTypes.string,
};

export default UploadFiles;
