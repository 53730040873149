import { Objects } from '@advitam/support'
import { HospitalJSON } from '../../models/Hospital'
import { AgreementLocationType } from '../../models/Hospital/AgreementLocationType'
import { HospitalBody } from './types'

type HospitalPayload = Partial<
  Omit<HospitalJSON, 'id' | 'country' | 'headquarter_country' | 'agreement_location'> & {
    country_code: string | null
    headquarter_country_code: string | null
    agreement_location_type: AgreementLocationType | null
    agreement_location_id: number | null
  }
>

export function serialize(hospital: Omit<HospitalBody, 'id'>): HospitalPayload {
  return {
    ...Objects.omit(hospital, 'country', 'headquarter_country', 'agreement_location'),
    country_code: hospital.country === undefined ? undefined : hospital.country?.code || null,
    headquarter_country_code:
      hospital.headquarter_country === undefined
        ? undefined
        : hospital.headquarter_country?.code || null,
    agreement_location_type:
      hospital.agreement_location === undefined
        ? undefined
        : hospital.agreement_location?.type || null,
    agreement_location_id:
      hospital.agreement_location === undefined
        ? undefined
        : hospital.agreement_location?.id || null,
  }
}
