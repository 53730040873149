import { authenticate } from '../../../lib/decorators'
import { post } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'

export class All {
  @authenticate
  static create(): ApiRequestDescriptor<void> {
    return post('/api/v1/notifications/viewed/all')
  }
}
