import { BillingType } from '../BillingType'
import { CheckoutType } from '../CheckoutType'
import { MinimalCityhallJSON } from '../Cityhall'
import { ConcessionSemelleTypeJSON } from '../Concession/SemelleType'
import { Country } from '../Country'
import { ContactMedia } from '../Entity/ContactMedia'
import { OpeningHoursJSON } from '../Entity/OpeningHours'
import { Model } from '../Model'
import { GraveyardConcessionTypeJSON } from './Concession'
import { SemelleGraniteType, SemelleShape } from './Semelle'
import { GraveyardSpace } from './Space'
import { GraveyardType } from './Type'

const enum Permission {
  ALLOWED = 'allowed',
  REQUIRED = 'required',
  FORBIDDEN = 'forbidden',
}

interface GraveyardBase {
  id: number
  name: string
  disabled: boolean
  address: string | null
  address_l2: string | null
  postal_code: string | null
  insee_code: string | null
  city: string | null
  department: string | null
  country: Country | null
  latitude: number | null
  longitude: number | null
  manual_address: boolean
  phone: string | null
  email: string | null
  prefered_contact_media: ContactMedia | null
  opening_hours: OpeningHoursJSON | null
  fax: string | null
  phone_2: string | null
  phone_3: string | null
  email_2: string | null
  email_3: string | null
  email_4: string | null
  comment: string | null
  current_update_user_id: number | null
  last_update_user_id: number | null
  billing_type: BillingType
  dispersion_jds_fee: number | null
  cheque_order: string | null
  cheque_address: string | null
  rib: string | null
  iban: string | null
  vat: string | null
  checkout_type: CheckoutType | null
  authentic_document_needed: boolean | null
  siret: string | null
  headquarter_name: string | null
  headquarter_address: string | null
  headquarter_postal_code: string | null
  headquarter_country: Country | null
  headquarter_city: string | null
  website: string | null
  is_full: boolean | null
  graveyard_type: GraveyardType | null
  has_jds: boolean
  is_family_buying_concession: boolean
  default_concession_type: GraveyardConcessionTypeJSON | null
  jewish_space: GraveyardSpace | null
  muslim_space: GraveyardSpace | null
  cityhalls: MinimalCityhallJSON[]
  vault_mandatory: Permission | null
  should_buy_col_plate: boolean | null
  stele: Permission | null
  gravillon: boolean | null
  tomb_no_engraving: boolean | null
  fausse_case: number | null
  semelle: number | null
  semelle_length: number | null
  semelle_width: number | null
  required_semelle_granite_type: SemelleGraniteType | null
  required_semelle_shape: SemelleShape | null
  coffin_interment_tax: number | null
  urn_interment_tax: number | null
  exhumation_tax: number | null
  col_plate_tax: number | null
  temporary_vault_oneoff: number | null
  temporary_vault_max_length: number | null
  temporary_vault_length: number | null
  temporary_vault_price_per_day: number | null
  col_plate_width: number | null
  col_plate_height: number | null
  col_plate_color: string | null
  concession_semelle: ConcessionSemelleTypeJSON | null
}

export interface GraveyardJSON extends GraveyardBase {
  current_update_dt: string | null
  last_update_dt: string | null
}

export interface Graveyard extends GraveyardBase {
  current_update_dt: Date | null
  last_update_dt: Date | null
}

export class Graveyard extends Model<GraveyardJSON> {
  constructor(data: GraveyardJSON) {
    super(data)
    this.current_update_dt = Model.parseDate(data.current_update_dt)
    this.last_update_dt = Model.parseDate(data.last_update_dt)
  }
}

export type { GraveyardConcessionTypeJSON }
export { GraveyardType, SemelleGraniteType, SemelleShape, Permission }
