import { Deals } from './Deals'
import { Filters } from './Filters'
import { Products } from './Products'
import { SupplierWarehousesProducts } from './SupplierWarehousesProducts'

export class MarbleFunnel {
  static readonly Deals = Deals

  static readonly Filters = Filters

  static readonly Products = Products

  static readonly SupplierWarehousesProducts = SupplierWarehousesProducts
}
