import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormUI, Tooltip } from '@advitam/ui';
import { assert } from 'lib/support';
import { makeSelectFuneralBrands } from 'slices/data';

import { makeSelectDeal } from '../../../selectors.typed';
import homeStyle from '../Home.module.scss';
import { updateBrand } from './thunk';
import style from './BrandSelect.module.scss';

export default function OwnerSelect(): JSX.Element {
  const dispatch = useDispatch();

  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);
  const brands = useSelector(makeSelectFuneralBrands());

  const renderPlaceholder = useCallback(
    (value: number): JSX.Element => {
      assert(deal.brand !== undefined);
      const brand = brands.find(({ id }) => id === value) || deal.brand;

      if (!brand.thumbnail_link) {
        return <Tooltip content={brand.name}>{brand.name}</Tooltip>;
      }
      return (
        <Tooltip content={brand.name}>
          <img
            className={style.thumbnail}
            src={brand.thumbnail_link}
            alt={brand.name}
          />
        </Tooltip>
      );
    },
    [brands],
  );

  const onChange = useCallback(
    (value: number | number[] | undefined): void => {
      if (!value) {
        return;
      }

      assert(!Array.isArray(value));
      dispatch(updateBrand(value));
    },
    [dispatch],
  );

  return (
    <FormUI.Select
      items={brands.map(brand => ({
        value: brand.id,
        name: brand.name,
      }))}
      value={deal.brand?.id || undefined}
      onChange={onChange}
      contained={false}
      className={homeStyle.borderless_select}
      renderPlaceholder={renderPlaceholder}
    />
  );
}
