import { ReactNode } from 'react';

import { Collapsible, FormUI, Text } from '@advitam/ui';

import style from './Layout.module.scss';

interface FieldsetProps {
  title: ReactNode;
  tooltip?: ReactNode;
  children: ReactNode | ReactNode[];
  className?: string;
}

export default function Fieldset({
  title,
  tooltip,
  children,
  className,
}: FieldsetProps): JSX.Element {
  return (
    <Collapsible
      isOpen
      hideChevron
      title={
        <Text className={`${style.title} ${tooltip ? style.with_tooltip : ''}`}>
          <b>{title}</b>
          {tooltip && <FormUI.Tooltip content={tooltip} />}
        </Text>
      }
      className={[style.fields_set, className].filter(Boolean).join(' ')}
    >
      {children}
    </Collapsible>
  );
}
