// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.ConflictsModal-module__modal--GvwAv{padding:24px 32px}@media(max-width: 991px){div.ConflictsModal-module__modal--GvwAv{width:100%}}.ConflictsModal-module__conflicts--AG6Av{list-style-type:none}.ConflictsModal-module__conflicts--AG6Av>li:not(:first-child){margin-top:6px}.ConflictsModal-module__cta--cMWlJ{display:block;margin:0 auto}`, "",{"version":3,"sources":["webpack://./src/containers/DocumentsEditor/ConflictsModal/ConflictsModal.module.scss","webpack://./../ui/scss/_media.scss"],"names":[],"mappings":"AAEA,wCACE,iBAAA,CC0BE,yBD3BJ,wCAII,UAAA,CAAA,CAIJ,yCACE,oBAAA,CAEA,8DACE,cAAA,CAIJ,mCACE,aAAA,CACA,aAAA","sourcesContent":["@import '~@advitam/ui/scss/media';\n\ndiv.modal {\n  padding: 24px 32px;\n\n  @include below(lg) {\n    width: 100%;\n  }\n}\n\n.conflicts {\n  list-style-type: none;\n\n  > li:not(:first-child) {\n    margin-top: 6px;\n  }\n}\n\n.cta {\n  display: block;\n  margin: 0 auto;\n}\n","$breakpoints: (\n  xxl: 1440px,\n  xl: 1200px,\n  lg: 992px,\n  md: 768px,\n  sm: 576px,\n  xs: 375px\n);\n\n@mixin above($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n@mixin below($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (max-width: ($breakpoint-value - 1)) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n:export {\n  /* stylelint-disable property-no-unknown */\n  xxl: 1440px;\n  xl: 1200px;\n  lg: 992px;\n  md: 768px;\n  sm: 576px;\n  xs: 375px;\n  /* stylelint-enable property-no-unknown */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"modal": `ConflictsModal-module__modal--GvwAv`,
	"conflicts": `ConflictsModal-module__conflicts--AG6Av`,
	"cta": `ConflictsModal-module__cta--cMWlJ`
};
export default ___CSS_LOADER_EXPORT___;
