export const enum SupportingDocumentOwnerType {
  CREMATORIUM = 'Crematorium',
  FUNERAL_PARLOR = 'FuneralParlor',
  WORSHIP = 'Worship',
  POLICE = 'Police',
  CITYHALL = 'Cityhall',
  REGIONAL_HEALTH_AUTHORITY = 'RegionalHealthAuthority',
  GRAVEYARD = 'Graveyard',
  HOSPITAL = 'Hospital',
  PREFECTURE = 'Prefecture',
  CONSULATE = 'Consulate',
  AIRPORT = 'Airport',
  SUPPLIER = 'Supplier',
}
