import { TodoItem, TodoType } from '@advitam/api/models/Deal/TodoItem';
import AccountCreation from './AccountCreation';
import Default from './Default';
import DefunctSize from './DefunctSize';
import FindDefunctInClients from './FindDefunctInClients';
import MarbleWork from './MarbleWork';
import RequestDocument from './RequestDocuments';
import SendBatch from './SendBatch';
import SupplierBooking from './SupplierBooking';

interface TodoProps {
  item: TodoItem;
}

export default function Todo({ item }: TodoProps): JSX.Element {
  switch (item.todo_type) {
    case TodoType.ACCOUNT_CREATION:
      return <AccountCreation item={item} />;
    case TodoType.FIND_DEFUNCT_IN_CLIENTS:
      return <FindDefunctInClients item={item} />;
    case TodoType.BOOK_SUPPLIERS:
    case TodoType.PRE_BOOK_SUPPLIERS:
      return <SupplierBooking item={item} />;
    case TodoType.INFO_DEFUNCT:
      return <DefunctSize item={item} />;
    case TodoType.REQUEST_DOCUMENT:
      return <RequestDocument item={item} />;
    case TodoType.SEND_BATCH:
      return <SendBatch item={item} />;
    case TodoType.INFO_MARBLE_WORK:
      return <MarbleWork item={item} />;
    default:
      return <Default item={item} />;
  }
}
