import { PrestationType } from '@advitam/api/models/Supplier';

export const ALL_PRESTATION_TYPES = [
  PrestationType.TRANSPORT,
  PrestationType.MARBLE,
  PrestationType.PAPERWORKS,
  PrestationType.COFFINS,
  PrestationType.ENGRAVING,
  PrestationType.EMBALMING,
  PrestationType.FLIGHT,
  PrestationType.FLOWERS,
  PrestationType.TOMBSTONE,
  PrestationType.PRESS,
  PrestationType.TRANSLATION,
  PrestationType.CEREMONY,
];

export const enum DependencyGroup {
  PAPERWORK = 'paperwork',
  FUNERAL = 'funeral',
  TAC = 'tac',
  CARE = 'care',
  MUSIC = 'music',
  URN = 'urn',
  PATTERN = 'pattern',
  TBC = 'tbc',
  ENGRAVING = 'engraving',
  MARBLE = 'marble',
  TRANSLATION = 'translation',
}

export const ALL_DEPENDENCY_GROUP = [
  DependencyGroup.PAPERWORK,
  DependencyGroup.FUNERAL,
  DependencyGroup.TAC,
  DependencyGroup.CARE,
  DependencyGroup.MUSIC,
  DependencyGroup.URN,
  DependencyGroup.PATTERN,
  DependencyGroup.TBC,
  DependencyGroup.ENGRAVING,
  DependencyGroup.MARBLE,
  DependencyGroup.TRANSLATION,
];
