export const enum CoverageZoneType {
  CITYHALL = 'Cityhall',
  DEPARMENT = 'Department',
}

export interface CoverageJSON {
  id: number
  zone_type: CoverageZoneType
  zone_id: number
  zone_name: string
}
