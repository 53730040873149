import { ChangeEvent, ReactNode, useCallback } from 'react'
import { useForm } from 'react-final-form'

import { useFormValues } from '@advitam/react'

import HiddenInput from '../Hidden'
import { MaskedInput } from '../UI'

const SEPARATOR = ' x '

interface InputProps {
  label?: ReactNode
  names: [string, string] | [string, string, string]
  placeholder?: string
  disabled?: boolean
  tooltip?: ReactNode
  renderBelow?: (value: string) => ReactNode
  className?: string
  readOnly?: boolean
  prefix?: ReactNode
  suffix?: ReactNode
}

export default function DimensionsInput({
  label,
  names,
  placeholder,
  disabled,
  tooltip,
  renderBelow,
  className,
  readOnly,
  prefix,
  suffix,
}: InputProps): JSX.Element {
  const form = useForm()
  const values = useFormValues<number | null | undefined>(names)

  const value = values.map(v => (typeof v === 'number' ? v : '_')).join(SEPARATOR)
  const blankValue = names.map(() => '_').join(SEPARATOR)
  const displayedValue = value === blankValue && placeholder ? undefined : value

  const onChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>): void => {
      const [value0, value1, value2] = ev.target.value.split(SEPARATOR).map(v => parseInt(v, 10))
      form.batch(() => {
        form.change(names[0], Number.isNaN(value0) ? null : value0)
        form.change(names[1], Number.isNaN(value1) ? null : value1)
        if (names.length === 3) {
          form.change(names[2], Number.isNaN(value2) ? null : value2)
        }
      })
    },
    [form, names],
  )

  return (
    <>
      <MaskedInput
        onChange={onChange}
        value={displayedValue}
        mask={names.map(_ => '[000]').join(SEPARATOR)}
        label={label}
        tooltip={tooltip}
        placeholder={placeholder}
        readOnly={readOnly as false}
        className={className}
        renderBelow={renderBelow}
        disabled={disabled}
        prefix={prefix}
        suffix={suffix}
      />
      <HiddenInput name={names[0]} />
      <HiddenInput name={names[1]} />
      {names.length === 3 && <HiddenInput name={names[2]} />}
    </>
  )
}
