// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Link-module__link_button--XCSJC{padding:12px 16px}`, "",{"version":3,"sources":["webpack://./../ui/components/Navigation/Menu/parts/Link/Link.module.scss"],"names":[],"mappings":"AAAA,iCACE,iBAAA","sourcesContent":[".link_button {\n  padding: 12px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link_button": `Link-module__link_button--XCSJC`
};
export default ___CSS_LOADER_EXPORT___;
