import { FormattedMessage } from 'react-intl';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';
import { NumberInput, Text } from '@advitam/ui';

import crudMessages from 'containers/Crud/messages';

import ActionFrame from '../../parts/ActionFrame';
import messages from './messages';
import style from './DefunctSize.module.scss';
import Task from '../../parts/Task';

const FIELDS_PREFIX = 'deal.defunctInfo.defunctCompInfo';

interface DefunctSizeProps {
  item: TodoItem;
}

export default function DefunctSize({ item }: DefunctSizeProps): JSX.Element {
  return (
    <>
      <Task item={item} />
      <ActionFrame>
        <div className={style.field}>
          <Text small>
            <FormattedMessage id={messages.weight.id} />
          </Text>
          <NumberInput
            name={`${FIELDS_PREFIX}.weight`}
            disabled={item.isDone}
            suffix={<FormattedMessage id={crudMessages.unitKilogram.id} />}
          />
        </div>
        <div className={style.field}>
          <Text small>
            <FormattedMessage id={messages.size.id} />
          </Text>
          <NumberInput
            name={`${FIELDS_PREFIX}.size`}
            disabled={item.isDone}
            suffix={<FormattedMessage id={crudMessages.unitCentimeter.id} />}
          />
        </div>
        <div className={style.field}>
          <Text small>
            <FormattedMessage id={messages.width.id} />
          </Text>
          <NumberInput
            name={`${FIELDS_PREFIX}.width`}
            disabled={item.isDone}
            suffix={<FormattedMessage id={crudMessages.unitCentimeter.id} />}
          />
        </div>
        <div className={style.field}>
          <Text small>
            <FormattedMessage id={messages.height.id} />
          </Text>
          <NumberInput
            name={`${FIELDS_PREFIX}.height`}
            disabled={item.isDone}
            suffix={<FormattedMessage id={crudMessages.unitCentimeter.id} />}
          />
        </div>
      </ActionFrame>
    </>
  );
}
