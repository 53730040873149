import { ReactNode } from 'react'
import { Carousel, useCarousel } from 'nuka-carousel'

import ArrowRight from '../../../images/icons/arrow-right.svg'
import ArrowLeft from '../../../images/icons/arrow-left.svg'
import Heading from '../../Heading'
import { IconButton } from '../../Button'

import style from './Testimonials.module.scss'

function Arrows(): JSX.Element {
  const { goBack, goForward } = useCarousel()
  return (
    <div className={style.controls}>
      <IconButton icon={<ArrowLeft />} onClick={goBack} />
      <IconButton icon={<ArrowRight />} onClick={goForward} />
    </div>
  )
}

interface ReviewsProps {
  content: ReactNode
  transparent?: boolean
  children: ReactNode[]
}

export default function Reviews({ content, transparent, children }: ReviewsProps): JSX.Element {
  const classes = [style.card, style.reviews, transparent && style.transparent].filter(Boolean)

  return (
    <div className={classes.join(' ')}>
      <Heading tagName="div">{content}</Heading>
      <div className={style.carousel}>
        <Carousel swiping={false} showArrows arrows={<Arrows />} wrapMode="wrap">
          {children}
        </Carousel>
      </div>
    </div>
  )
}
