import { IntlShape, useIntl } from 'react-intl';

import { ResourceType } from 'models/Client/Ability';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from './messages';

export function formatSection(type: ResourceType, intl: IntlShape): string {
  return safeFormatMessage(intl, messages, type);
}

interface FormattedSectionProps {
  type: ResourceType;
}

export function FormattedSection({ type }: FormattedSectionProps): JSX.Element {
  const intl = useIntl();
  return <>{formatSection(type, intl)}</>;
}
