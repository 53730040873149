import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  zones: {
    id: 'app.containers.Supplier.Warehouse.zones',
  },
  commentary: {
    id: 'app.containers.Supplier.Warehouse.commentary',
  },
  general: {
    id: 'app.containers.Supplier.Warehouse.general',
  },
  miscellaneous: {
    id: 'app.containers.Supplier.Warehouse.miscellaneous',
  },
  products: {
    id: 'app.containers.Supplier.Warehouse.products',
  },
  titlePlaceholder: {
    id: 'app.containers.Supplier.Warehouse.titlePlaceholder',
  },
  emailFeedback: {
    id: 'app.containers.Supplier.Warehouse.emailFeedback',
  },
});
