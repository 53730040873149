import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { WorshipJSON } from '@advitam/api/models/Worship';
import { saveEditableDocuments } from 'containers/Crud/Documents/Editable';
import { saveSupportingDocuments } from 'containers/Crud/Documents/Supporting';
import { assert } from 'lib/support';

import { WORSHIP } from './constants';
import type { NewWorship } from './types';
import {
  createWorship,
  destroyWorship,
  fetchWorship,
  setIsWorshipDisabled,
  updateWorship,
  updateWorshipName,
} from './thunk';

export interface State {
  worship: WorshipJSON | NewWorship | null;
  isLoading: boolean;
  isSaving: boolean;
  isDestroying: boolean;
  isUpdatingName: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [WORSHIP]: State;
}

export const initialState: State = {
  worship: null,
  isLoading: false,
  isSaving: false,
  isDestroying: false,
  isUpdatingName: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: WORSHIP,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearError(state) {
      state.error = null;
    },
    setWorship(
      state,
      { payload }: PayloadAction<WorshipJSON | NewWorship | null>,
    ) {
      state.worship = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchWorship.pending, state => {
      state.isLoading = true;
      state.destroyError = null;
    });
    builder.addCase(fetchWorship.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.worship = payload;
    });
    builder.addCase(fetchWorship.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createWorship.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(createWorship.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(createWorship.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateWorship.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateWorship.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.worship = payload;
    });
    builder.addCase(updateWorship.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateWorshipName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updateWorshipName.fulfilled, (state, { payload }) => {
      state.isUpdatingName = false;
      state.worship = payload;
    });
    builder.addCase(updateWorshipName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyWorship.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyWorship.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroyWorship.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setIsWorshipDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setIsWorshipDisabled.fulfilled, (state, { meta }) => {
      state.isSaving = false;
      assert(state.worship !== null);
      state.worship.disabled = meta.arg;
    });
    builder.addCase(setIsWorshipDisabled.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveEditableDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveEditableDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveEditableDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveSupportingDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveSupportingDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveSupportingDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setWorship } = slice.actions;
export default slice;
