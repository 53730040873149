import { createCable } from 'lib/reactvitam/action_cable';
import { markLocalAsRead } from '../slice';

import { pushNotification } from '../thunk';
import batchSignature from './actions/BatchSignature';
import bookingAnswered from './actions/BookingAnswered';
import clientReview from './actions/ClientReview';
import failedSending from './actions/FailedSending';
import funnelCreated from './actions/FunnelCreated';
import layerCreated from './actions/LayerCreated';
import paymentUpdated from './actions/PaymentUpdated';
import reminder from './actions/Reminder';
import viewed from './actions/Viewed';
import { NOTIFICATION_CHANNEL } from './constants';

const notificationRoom = createCable<null, null>({
  name: NOTIFICATION_CHANNEL,
  initialState: null,
  // events: builder => {},
  messages: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(batchSignature, undefined, ({ dispatch, payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(
        pushNotification({
          ...payload,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          viewed: false,
        }),
      );
    });

    builder.addCase(bookingAnswered, undefined, ({ dispatch, payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(
        pushNotification({
          ...payload,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          viewed: false,
        }),
      );
    });

    builder.addCase(clientReview, undefined, ({ dispatch, payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(
        pushNotification({
          ...payload,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          viewed: false,
        }),
      );
    });

    builder.addCase(failedSending, undefined, ({ dispatch, payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(
        pushNotification({
          ...payload,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          viewed: false,
        }),
      );
    });

    builder.addCase(funnelCreated, undefined, ({ dispatch, payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(
        pushNotification({
          ...payload,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          viewed: false,
        }),
      );
    });

    builder.addCase(layerCreated, undefined, ({ dispatch, payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(
        pushNotification({
          ...payload,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          viewed: false,
        }),
      );
    });

    builder.addCase(paymentUpdated, undefined, ({ dispatch, payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(
        pushNotification({
          ...payload,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          viewed: false,
        }),
      );
    });

    builder.addCase(reminder, undefined, ({ dispatch, payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(
        pushNotification({
          ...payload,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          viewed: false,
        }),
      );
    });

    builder.addCase(viewed, undefined, ({ dispatch, payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(markLocalAsRead(payload.notification_ids));
    });
  },
});

export default notificationRoom;
