import { Objects } from '@advitam/support'
import { CrematoriumPayload } from './types'

type ApiCrematoriumPayload = Omit<CrematoriumPayload, 'country' | 'headquarter_country'> & {
  country_code?: string | null
  headquarter_country_code?: string | null
  reduced_price_cityhall_ids?: string[]
  worship_ids?: string[]
}

export function serialize(crematorium: CrematoriumPayload): ApiCrematoriumPayload {
  return {
    ...Objects.omit(
      crematorium,
      'country',
      'headquarter_country',
      'reduced_price_cityhalls',
      'worships',
    ),
    country_code:
      crematorium?.country === undefined ? undefined : crematorium?.country?.code || null,
    headquarter_country_code:
      crematorium?.headquarter_country === undefined
        ? undefined
        : crematorium?.headquarter_country?.code || null,
    reduced_price_cityhall_ids: crematorium.reduced_price_cityhalls?.map(reducedPrice =>
      reducedPrice.id.toString(),
    ),
    worship_ids: crematorium.worships?.map(worship => worship.id.toString()),
  }
}
