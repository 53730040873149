import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import Fuse from 'fuse.js';

import { withSlice } from '@advitam/react';
import { getTokens } from '@advitam/api/lib/tokens';
import { assert } from '@advitam/support';
import {
  ConfirmationModal,
  FormattedApiError,
  ResourceList,
  Text,
} from '@advitam/ui';

import { makeSelectRawClient } from '../../selectors';
import Filters from './List/Filters';
import Header from './List/Header';
import Row from './List/Row';
import slice, { setFilters } from './slice';
import type { DocumentsForm } from './types';
import { fetchDocuments } from './thunk';
import {
  makeSelectError,
  makeSelectFilters,
  makeSelectIsLoading,
} from './selectors';
import messages from './messages';
import style from './SupportingDocuments.module.scss';

function SupportingDocuments(): JSX.Element {
  const dispatch = useDispatch();

  const client = useSelector(makeSelectRawClient());
  assert(client !== null);
  const filters = useSelector(makeSelectFilters());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const [indexOfDocumentToDelete, setIndexOfDocumentToDelete] = useState<
    number | null
  >(null);

  const form = useForm<DocumentsForm>();
  const { values } = useFormState<DocumentsForm>();
  const documents = values.sectionValues;
  const filteredDocumentIds = useMemo(() => {
    if (!filters.q) {
      return documents.map(document => document.id);
    }

    const fuse = new Fuse(documents, {
      keys: ['document_type.pretty_name'],
    });
    return fuse
      .search(filters.q)
      .map(result => result.item.id)
      .filter(id => id !== undefined);
  }, [documents, filters]);

  const tokens = { ...getTokens() };

  const fetchResources = useCallback(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  const closeDeletionConfirmation = useCallback(() => {
    setIndexOfDocumentToDelete(null);
  }, [setIndexOfDocumentToDelete]);

  const onDeletionConfirmed = useCallback(() => {
    form.change(
      'sectionValues',
      documents.filter((_, index) => index !== indexOfDocumentToDelete),
    );
    closeDeletionConfirmation();
  }, [closeDeletionConfirmation, form, documents, indexOfDocumentToDelete]);

  useEffect(() => {
    dispatch(setFilters({}));
    fetchResources();
  }, [dispatch, fetchResources, client.id, client.defunct_id]);

  if (error) {
    return (
      <Text tagName="p" className={style.error_text}>
        <FormattedApiError error={error} />
      </Text>
    );
  }

  return (
    <>
      <ResourceList.Container
        filters={<Filters />}
        header={<Header />}
        fetchResources={fetchResources}
        hasMore={false}
        isLoading={isLoading}
      >
        <Field name="sectionValues">
          {(): JSX.Element => (
            <>
              {documents.map((document, index) => (
                <Row
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${document.id || ''}-${index}`}
                  document={document}
                  isFiltered={!filteredDocumentIds.includes(document.id)}
                  prefix={`sectionValues[${index}]`}
                  onDelete={(): void => setIndexOfDocumentToDelete(index)}
                  // avoid accessing the LS once per row
                  tokens={tokens}
                />
              ))}
            </>
          )}
        </Field>
      </ResourceList.Container>

      {indexOfDocumentToDelete !== null && (
        <ConfirmationModal
          isOpen
          text={
            <FormattedMessage
              id={messages.deletionConfirmationText.id}
              values={{
                documentName:
                  documents[indexOfDocumentToDelete]?.document_type
                    ?.pretty_name,
              }}
            />
          }
          confirm={
            <FormattedMessage id={messages.deletionConfirmationConfirm.id} />
          }
          onConfirm={onDeletionConfirmed}
          cancel={
            <FormattedMessage id={messages.deletionConfirmationCancel.id} />
          }
          onCancel={closeDeletionConfirmation}
        />
      )}
    </>
  );
}

export default withSlice(slice)(SupportingDocuments);
