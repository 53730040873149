// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Client-module__container--kRbHT{position:relative;width:100%;display:flex;flex-grow:1;overflow:hidden}.Client-module__section--a9DH7{flex-grow:1;padding:16px;overflow:auto}.Client-module__tooltip_p--s8rsi:first-child{margin-top:0}.Client-module__tooltip_p--s8rsi:last-child{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/containers/Client/Client.module.scss"],"names":[],"mappings":"AAAA,iCACE,iBAAA,CACA,UAAA,CACA,YAAA,CACA,WAAA,CACA,eAAA,CAGF,+BACE,WAAA,CACA,YAAA,CACA,aAAA,CAIA,6CACE,YAAA,CAGF,4CACE,eAAA","sourcesContent":[".container {\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.section {\n  flex-grow: 1;\n  padding: 16px;\n  overflow: auto;\n}\n\n.tooltip_p {\n  &:first-child {\n    margin-top: 0;\n  }\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Client-module__container--kRbHT`,
	"section": `Client-module__section--a9DH7`,
	"tooltip_p": `Client-module__tooltip_p--s8rsi`
};
export default ___CSS_LOADER_EXPORT___;
