/*
 *  AddStep component: handles the process of adding a step (e.g. selecting the type and such)
 */

import PropTypes from 'prop-types';

import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './StepEditors/messages';
import { stepSkeletons } from './skeletons';
import style from './AddStep.module.scss';

class AddStep extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = { showDropDown: false };
  }

  handleSelect = step => stepId => () => {
    const { handleUpdateDeal, funeral } = this.props;
    this.toggleDropDown();
    const newSteps = [...funeral.steps];
    newSteps.splice(stepId, 0, { id: stepId, ...step });
    handleUpdateDeal({
      steps: newSteps.map((currentStep, index) => ({
        ...currentStep,
        id: index,
      })),
      services: funeral.services.map(service =>
        service.step_id < stepId
          ? service
          : { ...service, step_id: service.step_id + 1 },
      ),
    });
  };

  eventTypeCallback = [
    { type: 'death', cb: this.handleSelect(stepSkeletons.death) },
    { type: 'casketing', cb: this.handleSelect(stepSkeletons.casketing) },
    { type: 'closing', cb: this.handleSelect(stepSkeletons.closing) },
    { type: 'deposit', cb: this.handleSelect(stepSkeletons.deposit) },
    { type: 'ceremony', cb: this.handleSelect(stepSkeletons.ceremony) },
    { type: 'interment', cb: this.handleSelect(stepSkeletons.interment) },
    { type: 'cremation', cb: this.handleSelect(stepSkeletons.cremation) },
    { type: 'urnPickup', cb: this.handleSelect(stepSkeletons.urnPickup) },
    { type: 'repatriation', cb: this.handleSelect(stepSkeletons.repatriation) },
    { type: 'urnSealing', cb: this.handleSelect(stepSkeletons.urnSealing) },
  ];

  toggleDropDown = () =>
    this.setState(prevState => ({ showDropDown: !prevState.showDropDown }));

  renderButtons = (elem, index) => {
    const { stepId } = this.props;
    return (
      <button
        type="button"
        key={index}
        onClick={elem.cb(stepId)}
        className={`${style.button} ${style.choice_button}`}
      >
        <FormattedMessage id={messages[elem.type].id} />
      </button>
    );
  };

  render() {
    const { showDropDown } = this.state;

    return (
      <div className={style.container}>
        <button
          type="button"
          onClick={this.toggleDropDown}
          className={`${style.button} ${style.add_button}`}
        >
          +
        </button>
        {showDropDown && (
          <div>{this.eventTypeCallback.map(this.renderButtons)}</div>
        )}
      </div>
    );
  }
}

AddStep.propTypes = {
  handleUpdateDeal: PropTypes.func.isRequired,
  stepId: PropTypes.number.isRequired,
  funeral: PropTypes.object.isRequired,
};

export default AddStep;
