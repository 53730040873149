import type { MessageDescriptor } from 'react-intl';

import type { ClientAbilityIndexJSON } from '@advitam/api/models/Client/Ability/IndexAbility';
import { DealType } from '@advitam/api/models/Deal/Type';
import { ResourceType } from '@advitam/api/models/Client/Ability/ResourceType';
import { Role } from '@advitam/api/models/Role';
import { Path, Url } from 'containers/App/constants';

import messages from './messages';
import { Category, CategoryValue } from './types';

const DEAL_CATEGORY: Record<DealType, CategoryValue> = {
  [DealType.FUNERAL]: Category.FUNERAL_DEAL,
  [DealType.ITEM]: Category.ITEM_DEAL,
  [DealType.MARBLE]: Category.MARBLE_DEAL,
} as const;

function getCategory(resource: ClientAbilityIndexJSON): CategoryValue {
  if (resource.resource_type === ResourceType.DEAL) {
    return DEAL_CATEGORY[resource.resource.type];
  }
  return resource.resource_type;
}

const CATEGORY_ORDER = [
  Category.ITEM_DEAL,
  Category.FUNERAL_DEAL,
  Category.MARBLE_DEAL,
  Category.MEMORIAL,
  Category.ADMINISTRATIVE_PROCEDURE,
  Category.SAFE_KEEPER,
  Category.FUNERAL_WILL,
];

function getResourceId(resource: ClientAbilityIndexJSON): string {
  if (resource.resource_type === ResourceType.DEAL) {
    return resource.resource.business_id;
  }

  return resource.resource.created_at;
}

function compareResources(
  a: ClientAbilityIndexJSON,
  b: ClientAbilityIndexJSON,
): number {
  const idA = getResourceId(a);
  const idB = getResourceId(b);
  return idA.localeCompare(idB);
}

export function sortResources(
  resources: ClientAbilityIndexJSON[],
): ClientAbilityIndexJSON[] {
  const categories: ClientAbilityIndexJSON[][] = Array(CATEGORY_ORDER.length)
    .fill(0)
    .map(() => []);

  resources.forEach(resource => {
    const category = getCategory(resource);
    const categoryIndex = CATEGORY_ORDER.indexOf(category);
    categories[categoryIndex].push(resource);
  });

  return categories.flatMap(category => category.sort(compareResources));
}

const RESOURCE_TYPE_MESSAGES = {
  [ResourceType.ADMINISTRATIVE_PROCEDURE]: messages.administrativeProcedure,
  [ResourceType.FUNERAL_WILL]: messages.funeralWills,
  [ResourceType.MEMORIAL]: messages.memorial,
  [ResourceType.SAFE_KEEPER]: messages.safeKeeper,
} as const;

const DEAL_MESSAGES = {
  [DealType.FUNERAL]: messages.funerals,
  [DealType.ITEM]: messages.item,
  [DealType.MARBLE]: messages.marble,
} as const;

export function getCategoryMessage(
  resource: ClientAbilityIndexJSON,
): MessageDescriptor {
  if (resource.resource_type === ResourceType.DEAL) {
    return DEAL_MESSAGES[resource.resource.type];
  }
  return RESOURCE_TYPE_MESSAGES[resource.resource_type];
}

const STATUS_MESSAGES: Record<Role, MessageDescriptor> = {
  [Role.OWNER]: messages.ownerRole,
  [Role.EDITOR]: messages.editor,
  [Role.READER]: messages.reader,
  [Role.NOTARY]: messages.notary,
} as const;

export function getStatusMessage(
  resource: ClientAbilityIndexJSON,
): MessageDescriptor {
  return STATUS_MESSAGES[resource.role];
}

export function getLink(resource: ClientAbilityIndexJSON): string | null {
  if (resource.resource_type === ResourceType.DEAL) {
    return Path.DEAL(resource.resource.uuid);
  }

  if (resource.resource_type === ResourceType.MEMORIAL) {
    return `${Url.MEMORIAL}${resource.resource.url}`;
  }

  return null;
}
