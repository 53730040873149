import { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import { SupplierWarehouseZoneFuneralWorkJSON } from '@advitam/api/models/Supplier/Warehouse';
import { assert } from '@advitam/support';
import {
  ApiAutocomplete,
  Button,
  CroppedText,
  Input,
  NumberInput,
  ResourceList,
  FormattedPrice,
} from '@advitam/ui';
import type { AutocompleteResult } from '@advitam/ui/components/Form/UI/Autosuggest/types';
import Trash from '@advitam/ui/images/icons/trash.svg';

import { messages as crudMessages } from 'containers/Crud';

import { makeSelectSupplier, makeSelectCanEdit } from '../../../../selectors';
import { UnsavedFuneralWork, WarehouseZoneForm } from '../types';
import style from './List.module.scss';

const FIELD_PLACEHOLDER = '__';

interface ZoneRowProps {
  funeralWork: SupplierWarehouseZoneFuneralWorkJSON | UnsavedFuneralWork;
  funeralWorkIdx: number;
}

export default function ZoneRow({
  funeralWork,
  funeralWorkIdx,
}: ZoneRowProps): JSX.Element {
  const supplier = useSelector(makeSelectSupplier());
  const canUserEdit = useSelector(makeSelectCanEdit());

  const form = useForm<WarehouseZoneForm>();
  const prefix = `sectionValues[${funeralWorkIdx}]`;

  const onDelete = useCallback((): void => {
    const { values } = form.getState();
    const newFuneralWorks = [...values.sectionValues];
    newFuneralWorks.splice(funeralWorkIdx, 1);
    form.change('sectionValues', newFuneralWorks);
  }, [form, funeralWorkIdx]);

  assert(supplier !== null);

  return (
    <ResourceList.Row className={style.row}>
      <ResourceList.Cell className={style.service_cell}>
        {funeralWork.id ? (
          <CroppedText>{funeralWork.funeral_work_type.description}</CroppedText>
        ) : (
          <ApiAutocomplete<AutocompleteResult>
            required
            disabled={!canUserEdit}
            label={undefined}
            placeholder={FIELD_PLACEHOLDER}
            className={style.autocomplete}
            name={`${prefix}.funeral_work_type`}
            requestHeaders={{ ...getTokens() }}
            keys={['id', 'description']}
            getDisplayValue={(value): string => `${value.description}`}
            endpoint={Api.V1.absolute(
              Api.V1.Autocompletes.Path.funeralWorkTypes,
            )}
          />
        )}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.category_cell}>
        <CroppedText>
          {funeralWork.funeral_work_type?.category?.description}
        </CroppedText>
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile className={style.ref_cell}>
        <Input
          disabled={!canUserEdit}
          placeholder={FIELD_PLACEHOLDER}
          className={style.input}
          name={`${prefix}.supplier_ref`}
        />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.cost_cell}>
        <NumberInput
          required={supplier.negociated}
          disabled={!supplier.negociated || !canUserEdit}
          placeholder={FIELD_PLACEHOLDER}
          className={style.input}
          name={`${prefix}.cost`}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
        <span>
          {funeralWork.cost !== null && (
            <FormattedPrice value={funeralWork.cost} />
          )}
        </span>
      </ResourceList.Cell>
      <ResourceList.Cell className={style.price_cell}>
        <NumberInput
          required
          disabled={!canUserEdit}
          placeholder={FIELD_PLACEHOLDER}
          className={style.input}
          name={`${prefix}.price`}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
        <span>
          {funeralWork.price !== null && (
            <FormattedPrice value={funeralWork.price} />
          )}
        </span>
      </ResourceList.Cell>
      <ResourceList.Cell className={style.actions}>
        <Button
          outline
          disabled={!canUserEdit}
          className={style.delete}
          onClick={onDelete}
          icon={<Trash />}
        />
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
