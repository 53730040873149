import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FormattedApiError, HardSpinner, MessageModal } from '@advitam/ui';

import {
  makeSelectArePaymentsSaving,
  makeSelectPaymentsError,
} from './selectors';
import { clearPaymentError } from './slice';
import messages from './messages';

export default function SavingLayer(): JSX.Element | null {
  const dispatch = useDispatch();

  const isSaving = useSelector(makeSelectArePaymentsSaving());
  const error = useSelector(makeSelectPaymentsError());

  const onClose = useCallback(() => {
    dispatch(clearPaymentError());
  }, [dispatch]);

  if (isSaving) {
    return <HardSpinner />;
  }

  if (!error) {
    return null;
  }

  return (
    <MessageModal
      isOpen
      title={<FormattedMessage id={messages.error.id} />}
      message={<FormattedApiError error={error} />}
      ctaText={<FormattedMessage id={messages.closeError.id} />}
      onClose={onClose}
    />
  );
}
