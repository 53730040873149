import { CSSProperties } from 'react';
import { FormattedDate } from 'react-intl';

import { useProduct } from '../../ProductListProvider';

interface DetailsCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function UpdatedAtCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: DetailsCellProps): JSX.Element | null {
  const warehouseProduct = useProduct(rowIndex);

  return (
    <div style={wrapperStyle} className={className}>
      {warehouseProduct.updated_at && (
        <FormattedDate value={warehouseProduct.updated_at} />
      )}
    </div>
  );
}
