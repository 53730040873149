import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { saveWorship } from '../../thunk';
import Wrapper from '../Wrapper';
import { WorshipForm } from '../types';
import Contents from './Contents';

export default function Miscellaneous(): JSX.Element {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: WorshipForm): void => {
      dispatch(saveWorship(values.worship));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Contents />
    </Wrapper>
  );
}
