import Abilities from './Abilities';
import Autocompletes, {
  AutocompleteResult,
  DealAutocompleteResult,
  ProductAutocompleteResult,
} from './Autocompletes';
import Deals from './Deals';
import * as Pro from './Pro';

export { Auth } from './Auth';
export { Clients } from './Clients';
export { Defuncts } from './Defuncts';
export { DocumentTemplates } from './DocumentTemplates';
export { DocumentTypes } from './DocumentTypes';
export { Memorials } from './Memorials';
export { OpsLogs } from './OpsLogs';
export { Products } from './Products';
export { ProductsSuppliers } from './ProductsSuppliers';
export { PublicUploads } from './PublicUploads';
export { SupportingDocuments } from './SupportingDocuments';

export {
  Abilities,
  Autocompletes,
  AutocompleteResult,
  DealAutocompleteResult,
  Deals,
  Pro,
  ProductAutocompleteResult,
};
