export const enum MessageAuthor {
  CHATBOT,
  USER,
}

export interface ChatbotAnswerSource {
  description: string
  source: string
  score: number
}

export interface ChatbotAnswer {
  answer: string
  runId: string | null
  useful: boolean | null
  sources: ChatbotAnswerSource[]
}

export interface Message {
  author: MessageAuthor
  message: ChatbotAnswer
}
