import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { ErrorText, FormattedApiError, ResourceList } from '@advitam/ui';
import { Layout } from 'containers/Crud';
import { withDatasets } from 'slices/data';

import {
  makeSelectError,
  makeSelectFilteredCommunications,
  makeSelectIsLoading,
} from './selectors';
import { fetchCommunications } from './thunk';
import slice, { resetFilters } from './slice';
import Header from './Header';
import Row from './Row';
import Filters from './Filters';
import ContentModal from './ContentModal';
import MailContentModal from './MailContentModal';
import style from './History.module.scss';

function doNothing(): void {
  // As the name suggests.
}

function History(): JSX.Element {
  const dispatch = useDispatch();

  const communications = useSelector(makeSelectFilteredCommunications());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  useEffect(() => {
    dispatch(resetFilters());
    dispatch(fetchCommunications());
  }, [dispatch]);

  return (
    <Layout.Container className={style.history}>
      <ResourceList.Container
        error={
          error && (
            <ErrorText center>
              <FormattedApiError error={error} />
            </ErrorText>
          )
        }
        hasMore={false}
        header={<Header />}
        filters={<Filters />}
        isLoading={isLoading}
        useWindow={false}
        fetchResources={doNothing}
      >
        {communications.map((communication, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={index} communication={communication} index={index} />
        ))}
      </ResourceList.Container>

      <ContentModal />
      <MailContentModal />
    </Layout.Container>
  );
}

export default compose<typeof History>(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(History);
