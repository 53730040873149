import { Objects } from '@advitam/support'

import { DealPrestationComputeType } from '../../../models/Deal/PrestationComputeType'
import type { ClientJSON } from '../../../models/Funnel/Client'
import type { DealProductJSON } from '../../../models/Funnel/Deals/Product'
import type { DealServiceJSON } from '../../../models/Funnel/Deals/Service'
import type { DealJSON } from '../../../models/Funnel/Marble'
import type { DefunctConcessionsVersionJSON } from '../../../models/Funnel/Marble/DefunctConcessionsVersion'
import { TrackingAttributes } from '../../types/Tracking'
import { Model } from '../../../models/Model'
import type { ConcessionVersion, MarbleFunnelPayload, Product, Service } from './types'

function serializeConcession({
  id,
  has_monument,
  has_semelle,
  semelle_material,
  fausse_case,
  concession,
}: DefunctConcessionsVersionJSON): ConcessionVersion {
  return {
    id,
    has_monument,
    has_semelle,
    semelle_material,
    fausse_case,
    concession_attributes: {
      ...Objects.omit(concession, 'graveyard'),
      graveyard_id: concession.graveyard.id,
    },
  }
}

function serializeProduct(p: DealProductJSON): Product {
  const res = { ...p }
  Model.copyDestroyed(p, res)
  return res
}

function serializeService(srv: DealServiceJSON): Service {
  const res = {
    ...srv,
    flag: srv.flag || DealPrestationComputeType.MANUAL,
  }
  Model.copyDestroyed(srv, res)
  return res
}

export default function serialize(
  deal: DealJSON,
  client: ClientJSON,
  tracking?: TrackingAttributes,
): MarbleFunnelPayload {
  const { client_commentary, owner_ability, marble_detail, deals_products, deals_services } = deal

  return {
    client_commentary,
    owner_ability_attributes: {
      id: owner_ability.id,
      client_attributes: client,
    },
    deals_products_attributes: deals_products.map(p => serializeProduct(p)),
    deals_services_attributes: deals_services.map(srv => serializeService(srv)),
    marble_detail_attributes: {
      id: marble_detail.id,
      defunct_concessions_version_attributes: serializeConcession(
        marble_detail.defunct_concessions_version,
      ),
    },
    tracking_attributes: tracking,
  }
}
