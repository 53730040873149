import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import Api, { isApiError, request } from '@advitam/api';
import { ConcessionTermJSON } from '@advitam/api/models/Concession';
import {
  GraveyardConcessionTypeJSON,
  GraveyardJSON,
} from '@advitam/api/models/Graveyard';

import { assert } from 'lib/support';

import {
  setConcessionTypes,
  setConcessionTerms,
  AppStateSubset as ConcessionInfosAppStateSubset,
} from '../../concessionInfosSlice/slice';
import {
  makeSelectConcessionTerms,
  makeSelectConcessionTypes,
} from '../../concessionInfosSlice/selectors';
import { updateGraveyard } from '../../thunk';
import type { AppStateSubset as GraveyardAppStateSubset } from '../../slice';
import { makeSelectRawGraveyard } from '../../selectors';
import type { AppStateSubset as MiscAppStateSubset } from './slice';
import { GRAVEYARD_MISC } from './constants';
import {
  GraveyardMiscForm,
  UnsavedConcessionTerm,
  UnsavedGraveyardConcessionType,
} from './types';

const ERROR_CONCESSION_TERM_IN_USE =
  'graveyards__concession_term__base__restrict_dependent_destroy__has_many';
const ERROR_CONCESSION_TYPE_IN_USE =
  'graveyards_concession_type__base__restrict_dependent_destroy__has_many';

type AppStateSubset = GraveyardAppStateSubset &
  MiscAppStateSubset &
  ConcessionInfosAppStateSubset;

async function saveConcessionTerm(
  concessionTerm: ConcessionTermJSON | UnsavedConcessionTerm,
  existingTerms: ConcessionTermJSON[],
  graveyard: GraveyardJSON,
): Promise<ConcessionTermJSON> {
  if (concessionTerm.id === undefined) {
    const { body } = await request(
      Api.V1.Graveyards.ConcessionTerms.create(graveyard.id, concessionTerm),
    );
    return body;
  }

  const existingConcessionTerm = existingTerms.find(
    term => term.id === concessionTerm.id,
  );
  assert(existingConcessionTerm !== undefined);
  if (isEqual(concessionTerm, existingConcessionTerm)) {
    return existingConcessionTerm;
  }

  const { body } = await request(
    Api.V1.Graveyards.ConcessionTerms.update(concessionTerm),
  );

  return body;
}

export const saveConcessionTerms = createAsyncThunk(
  `${GRAVEYARD_MISC}_SAVE_CONCESSION_TERMS`,
  async (
    concessionTerms: Array<ConcessionTermJSON | UnsavedConcessionTerm>,
    { getState, rejectWithValue, dispatch },
  ) => {
    const state = getState() as AppStateSubset;
    const graveyard = makeSelectRawGraveyard()(state);
    const existingTerms = makeSelectConcessionTerms()(state);
    assert(graveyard?.id !== undefined);

    try {
      const result = await Promise.all(
        concessionTerms.map(term =>
          saveConcessionTerm(term, existingTerms, graveyard),
        ),
      );
      const errors = await Promise.all(
        existingTerms.map(async term => {
          if (!concessionTerms.some(value => value.id === term.id)) {
            try {
              await request(Api.V1.Graveyards.ConcessionTerms.destroy(term.id));
            } catch (error) {
              if (
                !isApiError(error) ||
                error.errorCodes[0] !== ERROR_CONCESSION_TERM_IN_USE
              ) {
                throw error;
              }

              result.push(term);
              return error;
            }
          }
          return undefined;
        }),
      );

      dispatch(setConcessionTerms(result));

      const hasAlreadyInUseTermError = errors.some(Boolean);
      return { hasAlreadyInUseTermError };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

async function saveConcessionType(
  concessionType: GraveyardConcessionTypeJSON | UnsavedGraveyardConcessionType,
  existingTypes: GraveyardConcessionTypeJSON[],
  graveyard: GraveyardJSON,
): Promise<GraveyardConcessionTypeJSON> {
  if (concessionType.id === undefined) {
    const { body } = await request(
      Api.V1.Graveyards.ConcessionTypes.create(graveyard.id, concessionType),
    );
    return body;
  }

  const existingConcessionType = existingTypes.find(
    type => type.id === concessionType.id,
  );
  assert(existingConcessionType !== undefined);
  if (isEqual(concessionType, existingConcessionType)) {
    return existingConcessionType;
  }

  const { body } = await request(
    Api.V1.Graveyards.ConcessionTypes.update(concessionType),
  );

  return body;
}

export const saveConcessionTypes = createAsyncThunk(
  `${GRAVEYARD_MISC}_SAVE_CONCESSION_TYPES`,
  async (
    concessionTypes: Array<
      GraveyardConcessionTypeJSON | UnsavedGraveyardConcessionType
    >,
    { getState, rejectWithValue, dispatch },
  ) => {
    const state = getState() as AppStateSubset;
    const graveyard = makeSelectRawGraveyard()(state);
    const existingTypes = makeSelectConcessionTypes()(state);
    assert(graveyard?.id !== undefined);

    try {
      const result = await Promise.all(
        concessionTypes.map(type =>
          saveConcessionType(type, existingTypes, graveyard),
        ),
      );
      const errors = await Promise.all(
        existingTypes.map(async concessionType => {
          if (!concessionTypes.some(value => value.id === concessionType.id)) {
            try {
              await request(
                Api.V1.Graveyards.ConcessionTypes.destroy(concessionType.id),
              );
            } catch (error) {
              if (
                !isApiError(error) ||
                error.errorCodes[0] !== ERROR_CONCESSION_TYPE_IN_USE
              ) {
                throw error;
              }

              result.push(concessionType);
              return error;
            }
          }
          return undefined;
        }),
      );

      dispatch(setConcessionTypes(result));

      const hasAlreadyInUseTypeError = errors.some(Boolean);
      return { hasAlreadyInUseTypeError };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const save = createAsyncThunk(
  `${GRAVEYARD_MISC}_SAVE`,
  async (values: GraveyardMiscForm, { dispatch }) => {
    assert(values.graveyard.id !== undefined);
    await Promise.all([
      dispatch(updateGraveyard(values.graveyard)),
      dispatch(saveConcessionTerms(values.sectionValues.concessionTerms)),
      dispatch(saveConcessionTypes(values.sectionValues.concessionTypes)),
    ]);
  },
);
