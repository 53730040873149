import type { FormApi } from 'final-form';
import { ChangeEvent, useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import { composeValidators, FormUI, isEmail, getFormError } from '@advitam/ui';
import type { AutocompleteResult } from '@advitam/ui/components/Form/UI/Autosuggest/types';
import type { FuneralForm } from 'containers/Deal/Funeral/types';

import { autocompleteClient } from '../ClientAutocompletionModal/thunk';
import { EMAIL_TAKEN_ERROR } from '../constants';
import { cloneClient } from '../utils';
import messages from './messages';
import {
  EMAIL_DUPLICATED_ERROR,
  isEmailUnique,
} from './validators/isEmailUnique';

interface ClientEmailInputProps {
  index: number;
}

export default function ClientEmailInput({
  index,
}: ClientEmailInputProps): JSX.Element {
  const dispatch = useDispatch();
  const form = useForm();
  const { values, initialValues } = useFormState<FuneralForm>();
  const { abilities } = values;
  const { abilities: initialAbilities } = initialValues;
  const { id } = abilities[index].client;

  const onChange = useCallback(
    (value: AutocompleteResult | undefined) => {
      // Abilities are constrained to an unique deal_id/client_id pair, and we need
      // to create new abilities before destroying the previous ones to be able to
      // transfer the special flag and the owner role. The only way to avoid an api
      // rejection is to reuse destroyed abilities when they exist.
      function reuseAbility(clientId: number): void {
        const existingAbility = abilities.findIndex(
          ability => ability.id && ability.client.id === clientId,
        );
        if (existingAbility === -1) {
          return;
        }

        form.change(`abilities[${index}].id`, abilities[existingAbility].id);
        form.change(`abilities[${existingAbility}].id`, undefined);
      }

      form.batch(() => {
        const ability = abilities[index];
        if (ability.id) {
          cloneClient(form, index);
        }
        form.change(
          `abilities[${index}].client.email`,
          value?.description || null,
        );
        if (!value) {
          form.change(`abilities[${index}].client.id`, undefined);
        } else if (value.id) {
          form.change(`abilities[${index}].client.id`, value.id);
          reuseAbility(value.id);
          dispatch(
            autocompleteClient({
              form: (form as unknown) as FormApi<FuneralForm>,
              index,
            }),
          );
        }
      });
    },
    [dispatch, form, index, abilities],
  );

  const onInput = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      // Email is not updatable by < admin, and it would be error prone to allow
      // overwriting it here anyway. We just want to create a new client.
      function protectEmail(): void {
        const ability = abilities[index];
        if (!ability.id || !ability.client.id || !initialAbilities) {
          return;
        }
        const initialAbility = initialAbilities.find(
          a => a.client.id === ability.client.id,
        );
        if (!initialAbility?.client.email) {
          return;
        }
        cloneClient(form, index);
        form.change(`abilities[${index}].client.id`, undefined);
      }

      form.batch(() => {
        protectEmail();
        form.change(
          `abilities[${index}].client.email`,
          ev.target.value || null,
        );
      });
    },
    [form, index, abilities, initialAbilities],
  );

  return (
    <Field<string | null>
      name={`abilities[${index}].client.email`}
      validate={composeValidators(isEmail, isEmailUnique(index))}
    >
      {({ input, meta }): JSX.Element => (
        <FormUI.Autosuggest
          label={<FormattedMessage id={messages.email.id} />}
          endpoint={Api.V1.absolute('/api/v1/autocompletes/clients')}
          name={input.name}
          value={{ id: id || 0, description: input.value || '' }}
          requestHeaders={{ ...getTokens() }}
          searchParams={{ email_present: 'true' }}
          onChange={onChange}
          onInput={onInput}
          onBlur={input.onBlur}
          error={getFormError(
            meta,
            {
              [EMAIL_DUPLICATED_ERROR]: messages.emailDuplicated,
              [EMAIL_TAKEN_ERROR]: messages.emailTaken,
            },
            false,
          )}
        />
      )}
    </Field>
  );
}
