import { DEAL_TYPE_MARBLE, GET_ESTIMATION } from 'containers/Deal/constants.ts';
import { updateDeal } from 'containers/Deal/slice.ts';
import {
  PRESTATION_COMPUTE_MANUAL,
  PRESTATION_STATUS_NONE,
  SKELETON_PRESTATION,
} from 'components/PrestationDialog/constants';

/**
 * Get an estimation from the id selected with the autocomplete.
 *
 * @param  {number}   id                The product id returned by the autocomplete selection.
 * @param  {function} addProductAction  The action used by the saga to add a product. It is curried with the
 *                                      step id by the Products component.
 *
 * @return {Object}   An action object asking a saga to get the product data.
 */
export function getEstimationData(id, callback) {
  return {
    type: GET_ESTIMATION,
    id,
    callback,
  };
}

export function addProductEstimation(steps, product, deal, dealType) {
  const stepId =
    steps &&
    steps.findIndex(s => s.eventType === product.prestation.details.step_type);
  const newProducts = [...deal.products];

  const selectedProduct = {
    ...SKELETON_PRESTATION,
    amount: 1,
    type: product.prestation.details.type,
    priceLine: {
      ...SKELETON_PRESTATION.priceLine,
      price: product.price,
      cost: product.cost,
      tva_rate: product.tva_rate,
      estimation: true,
    },
    group_name: product.prestation.details.group_name,
    step_type: product.prestation.details.step_type,
    productObject: {
      ...product.prestation.details,
    },
    step_id: dealType === DEAL_TYPE_MARBLE ? 0 : stepId,
    item_id: product.prestation.id,
    compute: PRESTATION_COMPUTE_MANUAL,
    status: PRESTATION_STATUS_NONE,
    variants: [],
  };

  newProducts.unshift(selectedProduct);
  return updateDeal({
    [dealType]: {
      ...deal,
      products: newProducts,
    },
  });
}
