import { authenticate } from '../../lib/decorators'
import type { ApiRequestDescriptor } from '../../lib/request'
import { del, get, post } from '../../lib/methods'
import type { ClientJSON } from '../../models/User/Client'

export interface AuthResponse<T = undefined> {
  success: boolean
  data: T
}

export interface Auth<T = undefined> {
  Response: AuthResponse<T>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class Auth<T = undefined> {
  static signIn(email: string, password: string): ApiRequestDescriptor<AuthResponse<ClientJSON>> {
    return post('/api/next/auth/sign_in', { email, password })
  }

  @authenticate
  static signOut(): ApiRequestDescriptor<AuthResponse> {
    return del('/api/next/auth/sign_out')
  }

  @authenticate
  static validateToken(): ApiRequestDescriptor<AuthResponse<ClientJSON>> {
    return get('/api/next/auth/validate_token')
  }
}
