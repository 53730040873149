import { Objects } from '@advitam/support'
import { OngoingFormality } from '../../models/OngoingFormality'

interface OngoingFormalityPayload {
  formality: Pick<OngoingFormality, 'fields'>
}

export function serialize(formality: OngoingFormality): OngoingFormalityPayload {
  return { formality: Objects.pick(formality, 'fields') }
}
