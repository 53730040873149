import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { post } from 'api/methods';

export class Cookie {
  @authenticate
  static create(): ApiRequestDescriptor<never> {
    return post('/api/v1/auth/cookie');
  }
}
