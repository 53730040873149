import { defineMessages } from '@formatjs/intl';
import { ErrorCode } from '../../validators';

export default defineMessages({
  label: {
    id: 'app.containers.Deal.SendBatchModal.Recipients.label',
  },
  email: {
    id: 'app.containers.Deal.SendBatchModal.Recipients.email',
  },
  emailAll: {
    id: 'app.containers.Deal.SendBatchModal.Recipients.emailAll',
  },
  fax: {
    id: 'app.containers.Deal.SendBatchModal.Recipients.fax',
  },
  letter: {
    id: 'app.containers.Deal.SendBatchModal.Recipients.letter',
  },
  placeholder: {
    id: 'app.containers.Deal.SendBatchModal.Recipients.placeholder',
  },
  [ErrorCode.RECIPIENTS_MISSING_EMAIL]: {
    id:
      'app.containers.Deal.SendBatchModal.Recipients.recipients_missing_email',
  },
  [ErrorCode.RECIPIENTS_MISSING_METHOD_VALUES]: {
    id:
      'app.containers.Deal.SendBatchModal.Recipients.recipients_missing_method_values',
  },
  [ErrorCode.RECIPIENTS_NOT_CLIENT]: {
    id: 'app.containers.Deal.SendBatchModal.Recipients.recipients_not_client',
  },
});
