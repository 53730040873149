import { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSpy, useForm, useFormState } from 'react-final-form';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import {
  CoverageJSON,
  CoverageZoneType,
} from '@advitam/api/models/Entity/Coverage';
import { LocationType } from '@advitam/api/models/LocationType';
import { assert } from '@advitam/support';
import {
  Button,
  Collapsible,
  FormLayout,
  FormUI,
  GooglePlaceInput,
  NumberInput,
  Select,
  Spinner,
  Text,
} from '@advitam/ui';
import type { Address } from '@advitam/ui/components/Form/GooglePlace/types';
import { useThunkDispatch } from '@advitam/react';
import actionsMessages from 'messages/actions';

import { makeSelectCanEdit } from '../../../../selectors';
import messages from '../messages';
import { serializeWarehouseAddress } from '../serializers';
import { fetchNearZones } from '../thunk';
import {
  AutoAdditionFields,
  CenterType,
  UnsavedCoverage,
  WarehouseZonesForm,
} from '../types';
import style from './Zone.module.scss';

type ValidAutoAddition = AutoAdditionFields & {
  centerType: CenterType;
  centerAddress: Address & { latitude: number; longitude: number };
  radius: number;
};

function isValidAutoAddition(
  values?: AutoAdditionFields,
): values is ValidAutoAddition {
  return (
    values?.centerAddress?.latitude !== null &&
    values?.centerAddress?.longitude !== null &&
    Boolean(values?.radius)
  );
}

interface AutoAdditionProps {
  zoneIndex: number;
  addCoverageItems: (coverages: Array<CoverageJSON | UnsavedCoverage>) => void;
}

export default function AutoAddition({
  zoneIndex,
  addCoverageItems,
}: AutoAdditionProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useThunkDispatch();
  const form = useForm();
  const [isFetching, setIsFetching] = useState(false);
  const canUserEdit = useSelector(makeSelectCanEdit());

  const { values } = useFormState<WarehouseZonesForm>();
  const { autoAddition } = values.sectionValues[zoneIndex];
  const prefix = `sectionValues[${zoneIndex}].autoAddition`;
  const previousCenterType = useRef(autoAddition?.centerType);

  const onAdd = useCallback(async () => {
    assert(isValidAutoAddition(autoAddition));
    setIsFetching(true);

    const { radius, centerAddress } = autoAddition;
    const response = await dispatch(
      fetchNearZones({
        radius,
        latitude: centerAddress.latitude,
        longitude: centerAddress.longitude,
        entity_types: [LocationType.CITYHALL],
      }),
    );

    try {
      const body = unwrapResult(response);
      addCoverageItems(
        body.map(nearEntity => ({
          zone_id: nearEntity.id,
          zone_type: CoverageZoneType.CITYHALL,
          zone_name: [
            nearEntity.name,
            nearEntity.postal_code && `(${nearEntity.postal_code})`,
          ]
            .filter(Boolean)
            .join(' '),
        })),
      );

      form.change(`${prefix}.radius`, null);
      form.change(`${prefix}.centerType`, CenterType.WAREHOUSE);
      setIsFetching(false);
    } catch {
      // let the slice handle the error
      setIsFetching(false);
    }
  }, [dispatch, autoAddition, form, prefix, addCoverageItems]);

  return (
    <FormLayout.Row>
      <FormSpy<WarehouseZonesForm>
        subscription={{ values: true }}
        onChange={({ values: spyValues }): void => {
          const zone = spyValues.sectionValues[zoneIndex];
          if (!zone?.autoAddition) {
            return;
          }

          const { centerType, centerAddress } = zone.autoAddition;
          if (previousCenterType.current === centerType) {
            return;
          }

          previousCenterType.current = centerType;
          const fieldName = `${prefix}.centerAddress`;
          const warehouseAddress = serializeWarehouseAddress(values.warehouse);
          if (centerType === CenterType.ADDRESS && centerAddress !== null) {
            form.change(fieldName, null);
          } else if (
            centerType === CenterType.WAREHOUSE &&
            !isEqual(warehouseAddress, centerAddress)
          ) {
            form.change(fieldName, warehouseAddress);
          }
        }}
      />

      <Collapsible
        borderless
        isOpen
        title={
          <Text tagName="div" className={style.collapsible_title}>
            <FormattedMessage id={messages.additionAuto.id} />
            <FormUI.Tooltip
              content={
                <FormattedMessage id={messages.additionAutoTooltip.id} />
              }
            />
          </Text>
        }
      >
        <FormLayout.Row>
          <Select
            disabled={!canUserEdit}
            name={`${prefix}.centerType`}
            label={<FormattedMessage id={messages.centerType.id} />}
            tooltip={<FormattedMessage id={messages.centerTypeTooltip.id} />}
            items={[
              {
                name: intl.formatMessage(messages.warehouse),
                value: CenterType.WAREHOUSE,
              },
              {
                name: intl.formatMessage(messages.address),
                value: CenterType.ADDRESS,
              },
            ]}
          />
          <GooglePlaceInput
            name={`${prefix}.centerAddress`}
            label={<FormattedMessage id={messages.centerAddress.id} />}
            placeholder={intl.formatMessage(actionsMessages.search)}
            disabled={
              autoAddition?.centerType === CenterType.WAREHOUSE || !canUserEdit
            }
          />
        </FormLayout.Row>
        <FormLayout.Row className={style.actions_row}>
          <NumberInput
            disabled={!canUserEdit}
            name={`${prefix}.radius`}
            label={<FormattedMessage id={messages.radius.id} />}
            min={1}
          />
          <Button
            primary
            fixedSize
            disabled={
              isFetching || !isValidAutoAddition(autoAddition) || !canUserEdit
            }
            onClick={onAdd}
            text={
              isFetching ? (
                <Spinner />
              ) : (
                <FormattedMessage id={actionsMessages.add.id} />
              )
            }
          />
        </FormLayout.Row>
      </Collapsible>
    </FormLayout.Row>
  );
}
