import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  add: {
    id: 'app.containers.Supplier.Warehouse.sections.Zone.add',
  },
  actions: {
    id: 'app.containers.Supplier.Warehouse.sections.Zone.actions',
  },
  category: {
    id: 'app.containers.Supplier.Warehouse.sections.Zone.category',
  },
  cost: {
    id: 'app.containers.Supplier.Warehouse.sections.Zone.cost',
  },
  price: {
    id: 'app.containers.Supplier.Warehouse.sections.Zone.price',
  },
  serviceName: {
    id: 'app.containers.Supplier.Warehouse.sections.Zone.serviceName',
  },
  supplierRef: {
    id: 'app.containers.Supplier.Warehouse.sections.Zone.supplierRef',
  },
});
