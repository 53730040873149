import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';

import { makeSelectDeal, makeSelectRawDeal } from '../../../selectors.typed';
import type { AppStateSubset as DealAppStateSubset } from '../../../slice';
import type {
  AppStateSubset as DealReminderAppStateSubset,
  State,
} from './slice';
import { DEAL_REMINDER } from './constants';
import messages from './messages';

type AppStateSubset = DealAppStateSubset & DealReminderAppStateSubset;
type DealReminderSelector<T> = Selector<AppStateSubset, T>;

const selectDealReminderDomain = (state: AppStateSubset): State =>
  state[DEAL_REMINDER];

export const makeSelectHasReminder = (): DealReminderSelector<boolean> =>
  createSelector(makeSelectRawDeal(), deal => Boolean(deal?.remind_at));

export const makeSelectReminderDate = (): DealReminderSelector<Date | null> =>
  createSelector(makeSelectDeal(), deal => deal?.remind_at || null);

export const makeSelectIsModalOpen = (): DealReminderSelector<boolean> =>
  createSelector(selectDealReminderDomain, state => state.isModalOpen);

export const makeSelectIsLoading = (): DealReminderSelector<boolean> =>
  createSelector(selectDealReminderDomain, state => state.isLoading);

export const makeSelectError = (): DealReminderSelector<SerializedApiError | null> =>
  createSelector(selectDealReminderDomain, state => state.error);

export const makeSelectOption = (): DealReminderSelector<number | null> =>
  createSelector(selectDealReminderDomain, state => state.option);

export const makeSelectValue = (): DealReminderSelector<string | null> =>
  createSelector(selectDealReminderDomain, state => state.value);

export const makeSelectInputError = (): DealReminderSelector<
  keyof typeof messages | null
> => createSelector(selectDealReminderDomain, state => state.inputError);
