export const TEL_TYPE = 'tel';
export const EMAIL_TYPE = 'email';
export const CHECKBOX_TYPE = 'checkbox';

export const SKELETON_ADDRESS = {
  address: null,
  address_l2: null,
  postal_code: null,
  insee_code: null,
  department: null,
  city: null,
  country: null,
  latitude: null,
  longitude: null,
};
