import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get } from 'api/methods';

import { MemorialJSON } from 'models/Memorial';

export default class Memorial {
  @authenticate
  static show(uuid: string): ApiRequestDescriptor<MemorialJSON> {
    return get(`/api/v1/deals/${uuid}/memorial`);
  }
}
