import { deburr } from 'lodash'

/**
 * Convert a string to be usable as an url segment
 *
 * Example :
 * ```
 * Strings.toUrlSegment('Cimetière ou crématorium ?') // => cimetiere-ou-crematorium
 * ```
 *
 * @param text The text to be converted
 * @returns the url segment
 */
export function toUrlSegment(text: string): string {
  return deburr(text.toLowerCase())
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/--/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}
