import { renderToString } from 'react-dom/server';

import { translationMessages } from 'i18n';
import LanguageProvider from 'components/LanguageProvider';
import { assert } from 'lib/Assert';
import { DocumentTemplateJSON } from 'models/DocumentTemplate';

import { DocumentTemplateData, Input } from '../models';
import { getInputClass } from '../Engine/Input/factory';

const STYLESHEET = `
body, html {
  margin: 0;
  padding: 0;
  font-family: Raleway;
}
body {
  width: 1190px;
}
.own-page {
  position: relative;
  page-break-before: always;
}
.own-page > img {
  display: block;
  width: 100%;
}
`;

function buildInputHTML(
  input: Input,
  dictionary: Record<string, unknown>,
): string {
  const El = getInputClass(input.type);
  return renderToString(
    <LanguageProvider messages={translationMessages}>
      <El
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        dictionary={dictionary}
        isEditMode={false}
        isPreviewMode={false}
        target={{ page: -1, input: -1 }}
        onChange={(): void => {
          // Stub
        }}
        onDelete={(): void => {
          // Stub
        }}
        setFontSize={(): void => {
          // Stub
        }}
      />
    </LanguageProvider>,
  );
}

export default function buildHTML(
  document: DocumentTemplateJSON<DocumentTemplateData>,
  dictionary: Record<string, unknown>,
): string {
  assert(document.data.pages.length === document.public_uploads.length);

  return `
<!DOCTYPE html>
<html>
  <head>
    <link href="https://fonts.googleapis.com/css2?family=Raleway&display=swap" rel="stylesheet" />
    <style>${STYLESHEET}</style>
  </head>
  <body>
    ${document.data.pages
      .map(
        (page, idx) => `
    <div class="own-page">
      <img src="${document.public_uploads[idx].link}" />
      ${page.inputs.map(i => buildInputHTML(i, dictionary)).join('')}
    </div>`,
      )
      .join('')}
  </body>
</html>
  `;
}
