import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ResourceList, { Filter } from '@advitam/ui/components/ResourceList';

import messages from '../messages';
import { setFilters } from '../slice';
import { makeSelectFilters } from '../selectors';
import { Category, Filters } from '../types';

export default function Filters(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const filters = useSelector(makeSelectFilters());

  const category: Filter = {
    type: 'Select',
    name: 'category_eq',
    initialValue: filters.category_eq,
    placeholder: intl.formatMessage(messages.category),
    items: [
      { value: Category.ITEM_DEAL, name: intl.formatMessage(messages.item) },
      {
        value: Category.FUNERAL_DEAL,
        name: intl.formatMessage(messages.funerals),
      },
      {
        value: Category.MARBLE_DEAL,
        name: intl.formatMessage(messages.marble),
      },
      { value: Category.MEMORIAL, name: intl.formatMessage(messages.memorial) },
      {
        value: Category.ADMINISTRATIVE_PROCEDURE,
        name: intl.formatMessage(messages.administrativeProcedure),
      },
      {
        value: Category.SAFE_KEEPER,
        name: intl.formatMessage(messages.safeKeeper),
      },
      {
        value: Category.FUNERAL_WILL,
        name: intl.formatMessage(messages.funeralWills),
      },
    ],
  };

  const onChange = useCallback(
    (values: Filters): void => {
      dispatch(setFilters(values));
    },
    [dispatch],
  );

  return (
    <ResourceList.Filters
      filters={[category]}
      onChange={onChange}
      searchFilter={{
        name: 'q',
        placeholder: intl.formatMessage(messages.search),
        initialValue: filters.q,
      }}
    />
  );
}
