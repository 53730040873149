import { Service } from 'models/Deal/Service';

export function resetFuneralWorks(
  funeralWorks: Service['funeralWorks'],
): Service['funeralWorks'] | null {
  if (!funeralWorks) {
    return null;
  }

  return Object.keys(funeralWorks).reduce(
    (curr, key) => ({
      ...curr,
      [key]: null,
    }),
    {},
  );
}
