export const enum ProductGroup {
  AIR_REPATRIATION = 'air_repatriation',
  ANNOUNCEMENT = 'announcement',
  DEATH_ANNOUNCEMENT = 'death-announcement',
  FLOWERS = 'flowers',
  FUNERAL = 'funeral',
  ITEMS = 'items',
  MARBLE = 'marble',
  MEDALLION = 'medallion',
  REGISTRY = 'registery',
  TREE = 'tree',
  URN = 'urn',
}
