import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { RepatriationTrip } from '../../types';
import TripCrossedBorder from './CrossedBorder';

interface TripCrossedBordersProps {
  crossedBorders: string[];
  onTripChange: (value: Partial<RepatriationTrip>) => void;
}

export default function TripCrossedBorders({
  crossedBorders,
  onTripChange,
}: TripCrossedBordersProps): JSX.Element {
  const updateCrossedBorder = (value: string, index: number): void => {
    onTripChange({
      crossed_borders: crossedBorders.map((currBorder, idx) => {
        if (index === idx) {
          return value;
        }
        return currBorder;
      }),
    });
  };

  const deleteCrossedBorder = (index: number): void => {
    const newCrossedBorders = [...crossedBorders];
    newCrossedBorders.splice(index, 1);
    onTripChange({ crossed_borders: newCrossedBorders });
  };

  const insertCrossedBorder = (index: number): void => {
    const startRange = crossedBorders.slice(0, index + 1);
    const endRange = crossedBorders.slice(index + 1, crossedBorders.length);

    onTripChange({
      crossed_borders: startRange.concat('', ...endRange),
    });
  };

  return (
    <div className="margin--0-10">
      <FormattedMessage id={messages.crossedBorders.id} tagName="h4" />
      {crossedBorders.map((border, index) => (
        <TripCrossedBorder
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          canBeDeleted={crossedBorders.length > 1}
          crossedBorder={border}
          onDelete={(): void => deleteCrossedBorder(index)}
          onInsert={(): void => insertCrossedBorder(index)}
          onUpdate={(value: string): void => updateCrossedBorder(value, index)}
        />
      ))}
    </div>
  );
}
