import { InputHTMLAttributes, ReactNode } from 'react'
import { Field, UseFieldConfig } from 'react-final-form'

import { Checkbox } from '../UI'
import { composeValidators, isRequired, Validator } from '../validators'

interface CheckboxFieldProps {
  label: ReactNode
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  required?: boolean
  validate?: Validator<InputHTMLAttributes<HTMLInputElement>['value']>
  borderless?: boolean
  className?: string
}

export default function CheckboxField({
  label,
  name,
  value,
  parse,
  required,
  validate,
  borderless,
  className,
}: CheckboxFieldProps): JSX.Element {
  const validators = composeValidators(required && isRequired, validate)
  return (
    <Field name={name} type="checkbox" value={value} parse={parse} validate={validators}>
      {({ input: { onChange, checked }, meta: { touched, error } }): JSX.Element => (
        <Checkbox
          name={name}
          label={label}
          onChange={onChange}
          value={value}
          checked={checked}
          error={Boolean(touched && error)}
          borderless={borderless}
          className={className}
        />
      )}
    </Field>
  )
}
