import { get } from 'lodash';
import { FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { RateJSON } from '@advitam/api/models/Flight/Rate';
import TrashIcon from '@advitam/ui/images/icons/trash.svg';
import {
  Button,
  HiddenInput,
  NumberInput,
  ResourceList,
  FormattedPrice,
} from '@advitam/ui';
import { messages } from 'containers/Crud';

import style from './Rates.module.scss';

interface RowProps {
  prefix: string;
  rate: RateJSON;
  onDelete: () => void;
}

export default function Row({ prefix, rate, onDelete }: RowProps): JSX.Element {
  return (
    <ResourceList.Row className={style.row}>
      <ResourceList.Cell className={style.weight}>
        <NumberInput
          name={`${prefix}.weight`}
          placeholder="___"
          suffix={<FormattedMessage id={messages.unitKilogram.id} />}
          required
          className={style.input}
        />
        <span className={style.value_text}>
          {rate.weight} <FormattedMessage id={messages.unitKilogram.id} />
        </span>
      </ResourceList.Cell>
      <ResourceList.Cell className={style.price}>
        <HiddenInput name={`${prefix}.cost`} />
        <NumberInput
          name={`${prefix}.price`}
          step={0.01}
          placeholder="___"
          suffix={<FormattedMessage id={messages.unitCurrency.id} />}
          required
          className={style.input}
        />
        <span className={style.value_text}>
          <FormattedPrice value={rate.price} />
        </span>
        <FormSpy>
          {({ form, values }): null => {
            form.change(`${prefix}.cost`, get(values, `${prefix}.price`));
            return null;
          }}
        </FormSpy>
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile />
      <ResourceList.Cell className={style.actions_cell}>
        <Button
          outline
          onClick={onDelete}
          icon={<TrashIcon />}
          className={style.delete}
        />
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
