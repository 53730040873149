import { useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import type { FlightJSON } from '@advitam/api/models/Flight';
import { withSlice } from '@advitam/react';
import { HardSpinner, PageSpinner } from '@advitam/ui';
import { Path } from 'containers/App/constants';
import ResourceCable, {
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { withDatasets } from 'slices/data';

import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectRawFlight,
} from './selectors';
import { fetchFlight } from './thunk';
import slice, { clearError, setFlight } from './slice';

function Container(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const flight = useSelector(makeSelectRawFlight());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());

  const { id } = useParams();
  const flightId = id && parseInt(id, 10);
  const canRender = !isLoading && flight;

  const seed = useLocation().state as FlightJSON | undefined;

  const fetchResource = useCallback(() => {
    if (flightId) {
      dispatch(fetchFlight(flightId));
    }
  }, [dispatch, flightId]);

  useEffect(() => {
    if (!flightId) {
      if (seed) {
        dispatch(setFlight(seed));
      } else {
        navigate(Path.FLIGHTS, { replace: true });
      }
    } else if (flightId !== flight?.id) {
      fetchResource();
    }

    return (): void => {
      dispatch(clearError());
    };
  }, [flightId]);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {flightId && (
        <ResourceCable
          resourceId={flightId}
          resourceType={ResourceRoomType.FLIGHT}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={fetchResource} />
        </ResourceCable>
      )}
    </>
  );
}

export default compose(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(Container);
