import type { IntlShape } from 'react-intl';

import type { NotificationJSON } from '@advitam/api/models/Notification';
import { NotificationType } from '@advitam/api/models/Notification/NotificationType';

import type { TileProps } from './TileProps';
import getBatchSignatureProps from './getBatchSignatureProps';
import getBookingAnsweredProps from './getBookingAnsweredProps';
import getClientReviewProps from './getClientReviewProps';
import getFailedSendingProps from './getFailedSendingProps';
import getFunnelCreatedProps from './getFunnelCreatedProps';
import getLayerCreatedProps from './getLayerCreatedProps';
import getPaymentUpdatedProps from './getPaymentUpdatedProps';
import getReminderProps from './getReminderProps';
import getUnsupportedNotifiactionProps from './getUnsupportedNotifiactionProps';

type PropsGetter = (
  notification: NotificationJSON,
  intl: IntlShape,
) => TileProps;

const TILE_PROPS = {
  [NotificationType.BATCH_SIGNATURE]: getBatchSignatureProps,
  [NotificationType.BOOKING_ANSWERED]: getBookingAnsweredProps,
  [NotificationType.CLIENT_REVIEW]: getClientReviewProps,
  [NotificationType.FAILED_SENDING]: getFailedSendingProps,
  [NotificationType.FUNNEL_CREATED]: getFunnelCreatedProps,
  [NotificationType.LAYER_CREATED]: getLayerCreatedProps,
  [NotificationType.PAYMENT_UPDATED]: getPaymentUpdatedProps,
  [NotificationType.REMINDER]: getReminderProps,
};

export default function factory(
  notification: NotificationJSON,
  intl: IntlShape,
): TileProps {
  const getter = TILE_PROPS[notification.action] as PropsGetter | undefined;
  if (!getter) {
    return getUnsupportedNotifiactionProps(notification, intl);
  }

  return getter(notification, intl);
}
