import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import type { SupportingDocumentFilters } from '@advitam/api/v1/SupportingDocuments/Versions';

import { CRUD_SUPPORTING_DOCUMENTS } from './constants';
import type { AppStateSubset, State } from './slice';

type DocumentsSelector<T> = Selector<AppStateSubset, T>;

const selectSupportingDocumentsDomain = (state: AppStateSubset): State =>
  state[CRUD_SUPPORTING_DOCUMENTS];

export const makeSelectIsLoading = (): DocumentsSelector<boolean> =>
  createSelector(
    selectSupportingDocumentsDomain,
    substate => substate.isLoading,
  );

export const makeSelectFilters = (): DocumentsSelector<
  SupportingDocumentFilters
> =>
  createSelector(selectSupportingDocumentsDomain, substate => substate.filters);

export const makeSelectSupportingDocuments = (): DocumentsSelector<
  SupportingDocumentJSON[]
> =>
  createSelector(
    selectSupportingDocumentsDomain,
    substate => substate.documents,
  );

export const makeSelectError = (): DocumentsSelector<SerializedApiError | null> =>
  createSelector(selectSupportingDocumentsDomain, substate => substate.error);
