import { useCallback } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { Button, Tooltip } from '@advitam/ui';
import LaterIcon from '@advitam/ui/images/icons/later.svg';

import { makeSelectHasReminder, makeSelectReminderDate } from './selectors';
import slice, { openModal } from './slice';
import { destroyReminder } from './thunk';
import SchedulingModal from './SchedulingModal';
import messages from './messages';
import style from './ReminderButton.module.scss';
import { PREDEFINED_DATE_FORMAT } from './constants';

function ReminderButton(): JSX.Element {
  const dispatch = useDispatch();
  const hasReminder = useSelector(makeSelectHasReminder());
  const reminderDate = useSelector(makeSelectReminderDate());

  const onClick = useCallback(() => {
    if (hasReminder) {
      dispatch(destroyReminder());
    } else {
      dispatch(openModal());
    }
  }, [dispatch, hasReminder]);

  const classList = [style.reminder_button, hasReminder && style.active].filter(
    Boolean,
  );
  return (
    <>
      <Tooltip
        content={
          hasReminder ? (
            <span className={style.tooltip}>
              <FormattedDate
                value={reminderDate || undefined}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...PREDEFINED_DATE_FORMAT}
              />
              <br />
              <FormattedMessage id={messages.reminderTooltip.id} />
            </span>
          ) : (
            <FormattedMessage id={messages.scheduleReminderTooltip.id} />
          )
        }
      >
        <Button
          outline
          icon={<LaterIcon />}
          className={classList.join(' ')}
          onClick={onClick}
        />
      </Tooltip>

      <SchedulingModal />
    </>
  );
}

export default withSlice(slice)(ReminderButton);
