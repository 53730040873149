import { createSelector, Selector } from 'reselect';

import { EditableDocumentJSON } from '@advitam/api/models/Documents/Editable';
import { SerializedApiError } from '@advitam/api';

import { CRUD_DOCUMENTS } from './constants';
import { AppStateSubset, State } from './slice';

type DocumentsSelector<T> = Selector<AppStateSubset, T>;

const selectEditableDocumentsDomain = (state: AppStateSubset): State =>
  state[CRUD_DOCUMENTS];

export const makeSelectIsLoading = (): DocumentsSelector<boolean> =>
  createSelector(selectEditableDocumentsDomain, substate => substate.isLoading);

export const makeSelectIsEditorLoading = (): DocumentsSelector<boolean> =>
  createSelector(
    selectEditableDocumentsDomain,
    substate => substate.isEditorLoading,
  );

export const makeSelectEditableDocuments = (): DocumentsSelector<
  EditableDocumentJSON[]
> =>
  createSelector(selectEditableDocumentsDomain, substate => substate.documents);

export const makeSelectVariables = (): DocumentsSelector<string[]> =>
  createSelector(selectEditableDocumentsDomain, substate => substate.variables);

export const makeSelectError = (): DocumentsSelector<SerializedApiError | null> =>
  createSelector(selectEditableDocumentsDomain, substate => substate.error);
