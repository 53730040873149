import { Model } from './Model';

export enum Role {
  ADMIN = 0,
  FUNERAL_DIRECTOR = 1,
  FUNERAL_ADVISOR = 2,
}

export interface User {
  email: string;
  id: number;
  avatar_link: string | null;
  name: string | null;
  role: Role;
  disabled?: boolean;
}

export class User extends Model<User> {
  get isFuneralDirectorOrAbove(): boolean {
    return this.role <= Role.FUNERAL_DIRECTOR;
  }

  get isAdmin(): boolean {
    return this.role === Role.ADMIN;
  }
}
