import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: { id: 'app.containers.Crematorium.sections.AgeRanges.title' },
  from: { id: 'app.containers.Crematorium.sections.AgeRanges.from' },
  to: { id: 'app.containers.Crematorium.sections.AgeRanges.to' },
  actions: { id: 'app.containers.Crematorium.sections.AgeRanges.actions' },

  add: { id: 'app.containers.Crematorium.sections.AgeRanges.add' },
  titlePlaceholder: {
    id: 'app.containers.Crematorium.sections.AgeRanges.titlePlaceholder',
  },
  agePlaceholder: {
    id: 'app.containers.Crematorium.sections.AgeRanges.agePlaceholder',
  },

  deletionConfirmationText: {
    id:
      'app.containers.Crematorium.sections.AgeRanges.deletionConfirmationText',
  },
  deletionConfirmationConfirm: {
    id:
      'app.containers.Crematorium.sections.AgeRanges.deletionConfirmationConfirm',
  },
  deletionConfirmationCancel: {
    id:
      'app.containers.Crematorium.sections.AgeRanges.deletionConfirmationCancel',
  },

  errorAgeRangeInUseModalTitle: {
    id:
      'app.containers.Crematorium.sections.AgeRanges.errorAgeRangeInUseModalTitle',
  },
  errorAgeRangeInUseModalText: {
    id:
      'app.containers.Crematorium.sections.AgeRanges.errorAgeRangeInUseModalText',
  },
  errorAgeRangeInUseModalAction: {
    id:
      'app.containers.Crematorium.sections.AgeRanges.errorAgeRangeInUseModalAction',
  },
});
