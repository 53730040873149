import type { ApiRequestDescriptor } from '../../lib/request'
import { get } from '../../lib/methods'
import { authenticate } from '../../lib/decorators'
import type { DealCommunicationJSON } from '../../models/Deal/Communication'

export const Communications = {
  index: authenticate(
    (dealId: number): ApiRequestDescriptor<DealCommunicationJSON[]> =>
      get(`/api/v1/deals/${dealId}/communications`),
  ),
}
