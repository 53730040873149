import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HardSpinner, PageSpinner } from '@advitam/ui';
import { withSlice } from '@advitam/react';

import concessionInfosSlice from '../../concessionInfosSlice/slice';
import Wrapper from '../Wrapper';
import {
  makeSelectConcessions,
  makeSelectIsLoading,
  makeSelectIsSaving,
} from './selectors';
import slice from './slice';
import { initialFetch, saveConcessions } from './thunk';
import { GraveyardConcessionsForm } from './types';
import ConcessionsContainer from './Container';

function Concessions(): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const concessions = useSelector(makeSelectConcessions());

  const onSubmit = useCallback(
    (values: GraveyardConcessionsForm) => {
      dispatch(saveConcessions(values.sectionValues));
    },
    [dispatch],
  );

  const fetch = useCallback(() => {
    dispatch(initialFetch());
  }, [dispatch]);

  useEffect(fetch, []);

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={concessions}>
      {isLoading ? <PageSpinner /> : <ConcessionsContainer />}
      {isSaving && <HardSpinner />}
    </Wrapper>
  );
}

export default withSlice(slice, concessionInfosSlice)(Concessions);
