import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { FormattedMessage } from 'react-intl';

import { AutocompleteResult } from 'api';
import ManualMode from 'components/ManualMode';
import AutoComplete from 'containers/AutoComplete';
import { makeSelectUser } from 'slices/auth';
import {
  makeSelectProductsForItem,
  makeSelectIsManualMode,
  makeSelectDealState,
} from 'containers/Deal/selectors';
import { updateManualMode } from 'containers/Deal/actions';
import Products from 'components/Products';
import messages from 'components/Products/messages';
import { assert } from 'lib/Assert';

import { DealState } from 'models/Deal/State';
import { Product } from 'models/Deal/Product';

import {
  fetchProduct,
  addProduct,
  updateProduct,
  setProducts as updateProducts,
} from './actions.js';

export default function ProductsSection(): JSX.Element {
  const dispatch = useDispatch();

  const products = useSelector(makeSelectProductsForItem());
  const user = useSelector(makeSelectUser());
  assert(user !== null);

  const isManualMode = useSelector(makeSelectIsManualMode());
  const dealState = useSelector(makeSelectDealState());

  const [isDialogOpen, setDialogOpen] = useState(false);
  const toggleDialog = (): void => setDialogOpen(!isDialogOpen);

  const getProductVariant = (productId: number): void => {
    const callback = (product: Product): Action =>
      updateProduct(products, product, false);
    dispatch(fetchProduct(productId, callback));
  };

  const setProducts = (newProducts: Product[]): void => {
    dispatch(updateProducts(newProducts));
  };
  const setManualMode = (newValue: boolean): void => {
    dispatch(updateManualMode(newValue));
  };

  const onSelect = ({ id }: AutocompleteResult): void => {
    const callback = (product: Product): Action =>
      addProduct(products, product);
    dispatch(fetchProduct(id, callback));
  };

  const isReadonly = dealState === DealState.DONE;

  return (
    <section id="products" className="products">
      {isReadonly || (
        <div className="dealFuneral__manualMode">
          <ManualMode
            isManualMode={isManualMode}
            onChangeManualMode={setManualMode}
            userRole={user.role}
          />
        </div>
      )}

      <div className="products__header">
        <FormattedMessage {...messages.productsSection} tagName="h2" />
        {isReadonly || (
          <div className="flex margin--10-0">
            <AutoComplete
              url="/api/v1/autocompletes/shop?q="
              getKey={({ id }: AutocompleteResult): number => id}
              getValue={({ description }: AutocompleteResult): string =>
                `${description}`
              }
              onSelect={(_: unknown, i: AutocompleteResult): void =>
                onSelect(i)
              }
              auth
            />
            {isManualMode && (
              <button
                className="products__header__add"
                onClick={toggleDialog}
                type="button"
              >
                +
              </button>
            )}
          </div>
        )}
      </div>
      <div className="products__list">
        <Products
          products={products}
          isDialogOpen={isDialogOpen}
          toggleDialog={toggleDialog}
          isManualMode={isManualMode}
          setProducts={setProducts}
          userRole={user.role}
          getProduct={getProductVariant}
          isReadonly={isReadonly}
        />
      </div>
    </section>
  );
}
