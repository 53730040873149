import { ChangeEventHandler, InputHTMLAttributes, ReactNode } from 'react'

import CheckIcon from '../../../../../images/icons/check.svg'
import ProductCard from '../../../../Card/ProductCard'
import radioStyle from '../Radio.module.scss'
import style from './Product.module.scss'

interface ProductProps {
  image?: ReactNode | null
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  title: ReactNode
  vignetteUrls?: string[]
  price: ReactNode | null
  ctaText?: ReactNode
  ctaAction?: () => void
  onChange: ChangeEventHandler<HTMLInputElement>
  checked?: boolean
  error?: boolean
  className?: string
}

export default function Product({
  image,
  name,
  value,
  title,
  price,
  vignetteUrls,
  ctaText,
  ctaAction,
  onChange,
  checked = false,
  error = false,
  className = '',
}: ProductProps): JSX.Element {
  const classes = [radioStyle.tile]
  const checkClasses = [radioStyle.check, style.check]

  if (checked) {
    classes.push(radioStyle.checked, style.checked)
    checkClasses.push(radioStyle.check__active)
  }
  if (error) {
    classes.push(radioStyle.error)
  }
  if (className) {
    classes.push(className)
  }

  return (
    <label className={radioStyle.container}>
      <ProductCard
        image={image}
        title={title}
        price={price}
        ctaText={ctaText}
        ctaAction={ctaAction}
        vignetteUrls={vignetteUrls}
        className={classes.join(' ')}
      />
      <CheckIcon className={checkClasses.join(' ')} />
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={radioStyle.radio}
      />
    </label>
  )
}
