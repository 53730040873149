import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { ClientAbilityIndexJSON } from '@advitam/api/models/Client/Ability/IndexAbility';
import { ResourceType } from '@advitam/api/models/Client/Ability/ResourceType';
import { DealType } from '@advitam/api/models/Deal/Type';

import Fuse from 'fuse.js';
import type { AppStateSubset, State } from './slice';
import { Category, CategoryValue, Filters } from './types';
import { CLIENT_RESOURCES } from './constants';
import { sortResources } from './utils';

type ResourcesSelector<T> = Selector<AppStateSubset, T>;

export const selectResourcesDomain = (state: AppStateSubset): State =>
  state[CLIENT_RESOURCES];

const makeSelectResources = (): ResourcesSelector<ClientAbilityIndexJSON[]> =>
  createSelector(selectResourcesDomain, state =>
    sortResources(state.resources),
  );

export const makeSelectFilters = (): ResourcesSelector<Filters> =>
  createSelector(selectResourcesDomain, state => state.filters);

const DEAL_CATEGORY_MAP: Record<CategoryValue, DealType | undefined> = {
  [Category.FUNERAL_DEAL]: DealType.FUNERAL,
  [Category.ITEM_DEAL]: DealType.ITEM,
  [Category.MARBLE_DEAL]: DealType.MARBLE,
  [Category.ADMINISTRATIVE_PROCEDURE]: undefined,
  [Category.FUNERAL_WILL]: undefined,
  [Category.MEMORIAL]: undefined,
  [Category.SAFE_KEEPER]: undefined,
};

export const makeSelectFilteredResources = (): ResourcesSelector<
  ClientAbilityIndexJSON[]
> =>
  createSelector(
    makeSelectResources(),
    makeSelectFilters(),
    (resources, filters) => {
      let result = resources;
      if (filters.q) {
        const fuse = new Fuse(resources, {
          keys: [
            'resource.owner.firstname',
            'resource.owner.lastname',
            'resource.business_id',
            'resource.created_at',
          ],
          threshold: 0.1,
        });
        result = fuse.search(filters.q).map(fuseResult => fuseResult.item);
      }

      const category = filters.category_eq;
      if (category) {
        result = result.filter(resource => {
          if (resource.resource_type === ResourceType.DEAL) {
            return resource.resource.type === DEAL_CATEGORY_MAP[category];
          }
          return category === resource.resource_type;
        });
      }
      return result;
    },
  );

export const makeSelectIsLoading = (): ResourcesSelector<boolean> =>
  createSelector(selectResourcesDomain, state => state.isLoading);

export const makeSelectError = (): ResourcesSelector<SerializedApiError | null> =>
  createSelector(selectResourcesDomain, state => state.error);
