import { DealJSON } from 'models/Deal';

export const DEAL_FUNERAL = 'Deal/Funeral';

export const Path = {
  HOME: '',
  TODOLIST: 'taches',
  SUMMARY: 'itineraire',
  IDENTITY: 'identite',
  STEPS: 'organisation',
  DOCUMENTS: 'documents',
  SUPPLIERS: 'fournisseurs',
  BILLING: 'facturation',
  MISCELLANEOUS: 'divers',
  HISTORY: 'echanges',
};

export const FORM_KEYS: Array<keyof DealJSON> = [
  'defunctInfo',
  'commentary',
  'auto_price',
  'manual_price',
];
