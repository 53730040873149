import { ReactNode, useCallback } from 'react'

import ApiAutocomplete from '../ApiAutocomplete'
import type { CityhallAutocompleteResult } from './types'

interface AutocompleteProps {
  placeholder?: string
  required?: boolean
  label: ReactNode
  tooltip?: ReactNode
  name: string
  endpoint: string
  className?: string
  disabled?: boolean
}

export default function Autocomplete({
  placeholder,
  name,
  label,
  tooltip,
  endpoint,
  required,
  className,
  disabled,
}: AutocompleteProps): JSX.Element {
  const getDisplayValue = useCallback(
    (result: CityhallAutocompleteResult): string => `${result.name} (${result.postal_code})`,
    [],
  )

  return (
    <ApiAutocomplete<CityhallAutocompleteResult>
      placeholder={placeholder}
      name={name}
      label={label}
      tooltip={tooltip}
      endpoint={endpoint}
      required={required}
      className={className}
      disabled={disabled}
      getDisplayValue={getDisplayValue}
      keys={['name', 'postal_code', 'insee_code']}
    />
  )
}
