import { ReactNode } from 'react'

import Arrow from '../../images/icons/arrow-right.svg'
import { LinkButton } from '../Button'
import Heading, { HeadingTag } from '../Heading'
import Label from '../Label'
import Text from '../Text'
import style from './TextBloc.module.scss'

export interface TextBlocBase {
  smallTitle?: boolean
  largeTitle?: boolean
  xlargeTitle?: boolean
}

interface GlobalTextBlocPropsBase extends TextBlocBase {
  head?: ReactNode
  title: ReactNode
  titleTagName?: HeadingTag
  text?: ReactNode
  className?: string
}

interface GlobalTextBlocPropsWithoutCTA extends GlobalTextBlocPropsBase {
  cta?: undefined
  internal?: undefined
  href?: undefined
}

interface GlobalTextBlocPropsWithCTA extends GlobalTextBlocPropsBase {
  cta: ReactNode
  internal?: boolean
  href: string
}

type GlobalTextBlocProps = GlobalTextBlocPropsWithoutCTA | GlobalTextBlocPropsWithCTA

export default function GlobalTextBloc({
  head,
  title,
  titleTagName = 'div',
  text,
  cta,
  href,
  internal,
  smallTitle,
  largeTitle,
  xlargeTitle,
  className = '',
}: GlobalTextBlocProps): JSX.Element {
  return (
    <div className={[style.global, className].join(' ')}>
      {head && <Label>{head}</Label>}
      <Heading small={smallTitle} large={largeTitle} xlarge={xlargeTitle} tagName={titleTagName}>
        {title}
      </Heading>
      {text && <Text>{text}</Text>}
      {href && <LinkButton primary icon={<Arrow />} text={cta} internal={internal} href={href} />}
    </div>
  )
}
