import { createSelector } from 'reselect';
import { makeSelectDeal } from 'containers/Deal/selectors';

const makeSelectOtherFuneral = () =>
  createSelector(makeSelectDeal(), substate => {
    if (!substate) return null;
    return substate.otherFuneral || {};
  });

const makeSelectOtherFuneralServiceByTva = () =>
  createSelector(makeSelectOtherFuneral(), substate => tvaIndice => {
    if (
      !substate ||
      !substate.services ||
      !substate.services[`tva${tvaIndice}`]
    )
      return [];
    return substate.services[`tva${tvaIndice}`].list || [];
  });

const makeSelectOtherFuneralPriceByTva = () =>
  createSelector(makeSelectOtherFuneral(), substate => tvaIndice => {
    if (
      !substate ||
      !substate.services ||
      !substate.services[`tva${tvaIndice}`]
    )
      return null;
    return substate.services[`tva${tvaIndice}`].price || null;
  });

export {
  makeSelectOtherFuneral,
  makeSelectOtherFuneralServiceByTva,
  makeSelectOtherFuneralPriceByTva,
};
