import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';

import { Summary, SummaryJSON } from 'models/Deal/Summary';

import { DEALS_LIST } from './constants';
import { AppStateSubset, DealFilters, State } from './slice';

type DealsListSelector<T> = Selector<AppStateSubset, T>;

const selectDealsListDomain = (state: AppStateSubset): State =>
  state[DEALS_LIST];

const makeSelectRawDeals = (): DealsListSelector<SummaryJSON[]> =>
  createSelector(selectDealsListDomain, ({ deals }) => deals);

export const makeSelectDeals = (): DealsListSelector<Summary[]> =>
  createSelector(makeSelectRawDeals(), rawDeals =>
    rawDeals.map(deal => new Summary(deal)),
  );

export const makeSelectFilters = (): DealsListSelector<DealFilters> =>
  createSelector(selectDealsListDomain, ({ filters }) => filters);

export const makeSelectCurrentPage = (): DealsListSelector<number> =>
  createSelector(selectDealsListDomain, ({ currentPage }) => currentPage);

export const makeSelectHasMore = (): DealsListSelector<boolean> =>
  createSelector(selectDealsListDomain, ({ hasMore }) => hasMore);

export const makeSelectIsLoading = (): DealsListSelector<boolean> =>
  createSelector(selectDealsListDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): DealsListSelector<SerializedApiError | null> =>
  createSelector(selectDealsListDomain, ({ error }) => error);
