import { ReactElement } from 'react'

interface WithClassName {
  className: string
}

interface CompactDesignProps<T extends WithClassName> {
  children: ReactElement<T>
}

/**
 * Enable the compact design system in children
 *
 * `children` must be a single ReactElement supporting the `className` property.
 * Also note that wrapping a design system component will not render it as
 * compact itself.
 *
 * To create a compact-enabled component, its CSS should wrap the changes in the
 * `compact-design` mixin to keep the class name defined in a single point.
 */
export default function CompactDesign<Props extends WithClassName>({
  children,
}: CompactDesignProps<Props>): JSX.Element {
  const childProps = { ...children.props }
  childProps.className = [childProps.className, 'compact-design'].filter(Boolean).join(' ')
  return { ...children, props: childProps }
}
