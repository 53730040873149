import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { StayJSON } from '@advitam/api/models/FuneralParlor';

import { FUNERAL_PARLOR_STAYS } from './constants';
import { fetchStays, saveStays } from './thunk';
import { StayFilters } from './types';

export interface AppStateSubset {
  [FUNERAL_PARLOR_STAYS]: State;
}

export interface State {
  stays: StayJSON[];
  filters: StayFilters;
  isLoading: boolean;
  isSaving: boolean;
  error: SerializedApiError | null;
}

export const initialState: State = {
  stays: [],
  filters: {},
  isLoading: false,
  isSaving: false,
  error: null,
};

const slice = createSlice({
  name: FUNERAL_PARLOR_STAYS,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setFilters(state, { payload }: PayloadAction<StayFilters>) {
      state.filters = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchStays.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchStays.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.stays = payload;
    });
    builder.addCase(fetchStays.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveStays.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveStays.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.stays = payload;
    });
    builder.addCase(saveStays.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { setFilters } = slice.actions;
export default slice;
