import { FormEvent, ReactNode, useCallback } from 'react';
import { useFormContext } from 'react-form';

interface CustomFormWrapperProps {
  blockEvent?: boolean;
  className?: string;
  children: ReactNode;
}

/**
 * Custom wrapper to be used to customize react-form's Form behavior.
 *
 * Usage :
 * ```
 * <Form noFormElement>
 *   <CustomFormWrapper {...propsYouWouldPassToForm}>
 *     <input />
 *   </CustomFormWrapper />
 * </Form>
 * ```
 */
export default function CustomFormWrapper({
  blockEvent,
  className,
  children,
}: CustomFormWrapperProps): JSX.Element {
  const { handleSubmit } = useFormContext();

  const onSubmit = useCallback(
    (event: FormEvent): void => {
      if (blockEvent) {
        event.stopPropagation();
        event.preventDefault();
      }
      handleSubmit();
    },
    [handleSubmit, blockEvent],
  );

  return (
    <form onSubmit={onSubmit} className={className}>
      {children}
    </form>
  );
}
