import { Document, pdfjs } from 'react-pdf';

import { PageSpinner } from '@advitam/ui';

import File, { connected } from '.';
import PdfPage from '../Page/Pdf';

interface OnLoadSuccess {
  numPages: number;
}

class Pdf extends File {
  onLoadSuccess({ numPages }: OnLoadSuccess): void {
    const { setPageCount } = this.props;
    setPageCount(numPages);
  }

  render(): JSX.Element | null {
    pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

    const { blob, pageCount, miniatures } = this.props;

    return (
      <Document
        file={blob}
        onLoadSuccess={(p): void => this.onLoadSuccess(p)}
        loading={<PageSpinner />}
      >
        {Array(pageCount)
          .fill(0)
          .map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <PdfPage key={i} pageNumber={i} miniature={miniatures} />
          ))}
      </Document>
    );
  }
}

export default connected(Pdf);
