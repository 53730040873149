// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(max-width: 575px){.Card-module__check--ef1vV{inset:0;top:19px;left:56px}}`, "",{"version":3,"sources":["webpack://./../ui/scss/_media.scss","webpack://./../ui/components/Form/UI/Radio/Card/Card.module.scss"],"names":[],"mappings":"AA6BI,yBC3BJ,2BAEI,OAAA,CACA,QAAA,CACA,SAAA,CAAA","sourcesContent":["$breakpoints: (\n  xxl: 1440px,\n  xl: 1200px,\n  lg: 992px,\n  md: 768px,\n  sm: 576px,\n  xs: 375px\n);\n\n@mixin above($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n@mixin below($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (max-width: ($breakpoint-value - 1)) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n:export {\n  /* stylelint-disable property-no-unknown */\n  xxl: 1440px;\n  xl: 1200px;\n  lg: 992px;\n  md: 768px;\n  sm: 576px;\n  xs: 375px;\n  /* stylelint-enable property-no-unknown */\n}\n","@import '../../../../../scss/media';\n\n.check {\n  @include below(sm) {\n    inset: 0;\n    top: 19px;\n    left: 56px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"check": `Card-module__check--ef1vV`
};
export default ___CSS_LOADER_EXPORT___;
