import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, ButtonLike, Switch, Text, Tooltip } from '@advitam/ui';
import ZoomInIcon from 'images/icons/magnify-plus-outline.svg';
import ZoomOutIcon from 'images/icons/magnify-minus-outline.svg';
import IncreaseFontSizeIcon from 'images/icons/format-font-size-increase.svg';
import DecreaseFontSizeIcon from 'images/icons/format-font-size-decrease.svg';

import Engine from './Engine';
import Inputs from './Inputs';
import messages from './messages';
import {
  decreaseDefaultFontSize,
  increaseDefaultFontSize,
  setFile,
  togglePreviewMode,
  unzoom,
  zoom,
  setZoom,
} from './slice';
import {
  makeSelectDefaultFontSize,
  makeSelectZoom,
  makeSelectIsPreviewMode,
} from './selectors';
import style from './DocumentTemplateEditor.module.scss';
import { DEFAULT_ZOOM } from './constants';

const DOCUMENT_SIZE = parseInt(style.document_size, 10);
const DOCUMENT_BORDER_SIZE = parseInt(style.document_border_size, 10);
const DOCUMENT_WRAPPER_PADDING = parseInt(style.document_wrapper_padding, 10);
const DOCUMENT_AREA_SIZE = DOCUMENT_BORDER_SIZE * 2 + DOCUMENT_SIZE;
const DOCUMENT_MARGIN = DOCUMENT_WRAPPER_PADDING * 2;

interface DocumentTemplateEditorProps {
  isIntegrationMode?: boolean;
  variables?: string[];
  onSave: () => void;
  onCancel: () => void;
}

export default function DocumentTemplateEditor({
  isIntegrationMode = false,
  variables = [],
  onSave,
  onCancel,
}: DocumentTemplateEditorProps): JSX.Element {
  const dispatch = useDispatch();
  const initialZoom = useRef(DEFAULT_ZOOM);

  const currentZoom = useSelector(makeSelectZoom());
  const isPreviewMode = useSelector(makeSelectIsPreviewMode());
  const defaultFontSize = useSelector(makeSelectDefaultFontSize());

  const onWrapperReference = useCallback(
    (ref: HTMLDivElement | null) => {
      if (!ref) {
        return;
      }

      const { width } = ref.getBoundingClientRect();
      const availableWidthForDocument = width - DOCUMENT_MARGIN;
      initialZoom.current =
        Math.floor((availableWidthForDocument * 100) / DOCUMENT_AREA_SIZE) /
        100;

      dispatch(setZoom(initialZoom.current));
    },
    [dispatch],
  );

  const isDocumentCentered = currentZoom < initialZoom.current;
  const scrollWrapperClasses = [
    style.document_scroll_wrapper,
    isDocumentCentered && style.centered,
  ].filter(Boolean);

  return (
    <div className={`col-12 ${style.container}`}>
      <div className={style.toolbar}>
        <label>
          <input
            type="file"
            onChange={({ target: { files } }): void => {
              dispatch(setFile(files && files[0]));
            }}
          />
          <ButtonLike
            primary
            fixedSize
            text={<FormattedMessage id={messages.addDocument.id} />}
          />
        </label>
        <span className={style.separator} />

        <label className={style.switch}>
          <Text className={style.label}>
            <FormattedMessage id={messages.preview.id} />
          </Text>
          <Switch value={isPreviewMode} />
          <input
            type="checkbox"
            name="isPreviewMode"
            value="isPreviewMode"
            onChange={(): void => {
              dispatch(togglePreviewMode());
            }}
            checked={isPreviewMode}
          />
        </label>
        <span className={style.space} />

        <Tooltip
          content={<FormattedMessage id={messages.decreaseFontSize.id} />}
        >
          <Button
            outline
            onClick={(): void => {
              dispatch(decreaseDefaultFontSize());
            }}
            icon={<DecreaseFontSizeIcon />}
          />
        </Tooltip>
        <Text small tagName="div" className={style.zoom_text}>
          {defaultFontSize} px
        </Text>
        <Tooltip
          content={<FormattedMessage id={messages.increaseFontSize.id} />}
        >
          <Button
            outline
            onClick={(): void => {
              dispatch(increaseDefaultFontSize());
            }}
            icon={<IncreaseFontSizeIcon />}
          />
        </Tooltip>
        <span className={style.space} />
        <Tooltip content={<FormattedMessage id={messages.decreaseZoom.id} />}>
          <Button
            outline
            onClick={(): void => {
              dispatch(unzoom());
            }}
            icon={<ZoomOutIcon />}
          />
        </Tooltip>
        <Text tagName="div" className={style.zoom_text}>
          <FormattedMessage
            id={messages.zoom.id}
            values={{ zoom: Math.round(currentZoom * 100) }}
          />
        </Text>
        <Tooltip content={<FormattedMessage id={messages.increaseZoom.id} />}>
          <Button
            outline
            onClick={(): void => {
              dispatch(zoom());
            }}
            icon={<ZoomInIcon />}
          />
        </Tooltip>

        <span className={style.separator} />
        <Button
          outline
          onClick={onCancel}
          text={<FormattedMessage id={messages.cancel.id} />}
        />
        <Button
          primary
          fixedSize
          className={style.save}
          onClick={onSave}
          text={<FormattedMessage id={messages.save.id} />}
        />
      </div>

      <div className={style.panes}>
        <div className={style.pages}>
          <Engine miniatures />
        </div>
        <div
          className={scrollWrapperClasses.join(' ')}
          ref={onWrapperReference}
        >
          <div
            id="document-template-editor__document"
            className={style.document}
          >
            <Engine isDocumentCentered={isDocumentCentered} />
          </div>
        </div>
        <div className={style.inputs}>
          <Inputs isIntegrationMode={isIntegrationMode} variables={variables} />
        </div>
      </div>
    </div>
  );
}
