import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get, post } from 'api/methods';

import { FilterSelection } from 'components/FilterBar';
import { PaymentSummaryJSON } from 'models/Payment/Summary';
import { PaymentJSON } from 'models/Payment';

interface UpdatePayload {
  payments: PaymentJSON[];
  deleted: string[];
}

interface PaymentsResponse {
  payments: PaymentJSON[];
}

export class Payments {
  @authenticate
  static index(
    filters?: Record<string, FilterSelection>,
  ): ApiRequestDescriptor<PaymentSummaryJSON[]> {
    return get('/api/v1/deals/payments', filters);
  }

  @authenticate
  static show(uuid: string): ApiRequestDescriptor<PaymentsResponse> {
    return get(`/api/v1/deals/${uuid}/payments`);
  }

  @authenticate
  static update(
    uuid: string,
    { payments, deleted }: UpdatePayload,
  ): ApiRequestDescriptor<PaymentsResponse> {
    return post(`/api/v1/deals/${uuid}/payments`, {
      payments,
      deleted,
    });
  }
}
