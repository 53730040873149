import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-form';
import { pick } from 'lodash';

import { Memorial } from 'models/Memorial';
import Switch from 'components/ReactFormComponent/Switch';

import { patchMemorial } from '../thunk';
import SectionWrapper from '../SectionWrapper';
import ProfilePicture from './ProfilePicture';
import messages from './messages';

interface SettingsProps {
  memorial: Memorial;
}

export default function Settings({ memorial }: SettingsProps): JSX.Element {
  const dispatch = useDispatch();

  const { Form, handleSubmit } = useForm({
    onSubmit: (values: Partial<Memorial>): void => {
      dispatch(
        patchMemorial(
          pick(
            values,
            'open',
            'pot_enabled',
            'calendar_enabled',
            'attendance_enabled',
            'flowers_enabled',
            'trees_enabled',
            'search_index_enabled',
            'associations_enabled',
          ),
        ),
      );
    },
    defaultValues: useMemo(() => memorial, [memorial.id]),
  });

  return (
    <SectionWrapper title={messages.title}>
      <Form>
        <Switch
          message={messages.isOpen}
          tooltipMessage={messages.isOpenDetails}
          field="open"
          onChange={(): void => handleSubmit()}
        />
        <ProfilePicture memorial={memorial} />
        {memorial.search_index_enabled !== null && (
          <Switch
            message={messages.indexing}
            tooltipMessage={messages.indexingDetails}
            field="search_index_enabled"
            onChange={(): void => handleSubmit()}
          />
        )}
        <Switch
          message={messages.calendar}
          tooltipMessage={messages.calendarDetails}
          field="calendar_enabled"
          onChange={(): void => handleSubmit()}
        />
        <Switch
          message={messages.attendees}
          tooltipMessage={messages.attendeesDetails}
          field="attendance_enabled"
          onChange={(): void => handleSubmit()}
        />
        <Switch
          message={messages.flowers}
          tooltipMessage={messages.flowersDetails}
          field="flowers_enabled"
          onChange={(): void => handleSubmit()}
        />
        <Switch
          message={messages.trees}
          tooltipMessage={messages.treesDetails}
          field="trees_enabled"
          onChange={(): void => handleSubmit()}
        />
        <Switch
          message={messages.fundingPot}
          tooltipMessage={messages.fundingPotDetails}
          field="pot_enabled"
          onChange={(): void => handleSubmit()}
        />
      </Form>
    </SectionWrapper>
  );
}
