import { ClientJSON } from 'models/Client';
import { AbilityBase } from 'models/Ability';
import moment from 'moment';

export function formatToOldClient(ability: AbilityBase): ClientJSON {
  const { client } = ability;
  return {
    id: client.id,
    ability_id: ability.id,
    role: ability.role,
    special: ability.special,
    type: client.civility,
    firstName: client.firstname || '',
    lastName: client.lastname || '',
    birthName: client.birth_name || undefined,
    link: ability.link,
    birthDate: client.birth_date
      ? moment.utc(client.birth_date, 'YYYY-MM-DD').unix()
      : undefined,
    contact: {
      email: client.email || undefined,
      phone: client.phone || undefined,
      phone_2: client.phone_2 || undefined,
    },
    address: {
      address: client.address || undefined,
      address_l2: client.address_l2 || undefined,
      postal_code: client.postal_code || undefined,
      insee_code: client.insee_code || undefined,
      city: client.city || undefined,
      department: client.department || undefined,
      latitude: client.latitude || undefined,
      longitude: client.longitude || undefined,
      country: client.country?.name,
      country_code: client.country?.code,
    },
  };
}
