import type { ReactNode } from 'react';

import { CompactDesign } from '@advitam/ui';

import style from './Layout.module.scss';

interface ContainerProps {
  className?: string;
  children: ReactNode | ReactNode[];
}

export default function CrudContainer({
  className,
  children,
}: ContainerProps): JSX.Element {
  return (
    <CompactDesign>
      <main className={[style.container, className].filter(Boolean).join(' ')}>
        {children}
      </main>
    </CompactDesign>
  );
}
