/**
 * Re-implement (and type) the now removed hook in 'react-router-dom'.
 *
 * @see https://github.com/remix-run/react-router/issues/8139 for details
 */

import type { Blocker, History } from 'history';
import { useContext, useLayoutEffect } from 'react';
import {
  UNSAFE_NavigationContext as NavigationContext,
  useLocation,
} from 'react-router-dom';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 */
export function useBlocker(blocker: Blocker, when = true): void {
  const navigationContext = useContext(NavigationContext);
  const navigator = navigationContext.navigator as History;
  const location = useLocation();

  useLayoutEffect(() => {
    if (!when) {
      return undefined;
    }

    const unblock = navigator.block(tx => {
      if (tx.location.pathname === window.location.pathname) {
        // We do not want to block when we only change the hash or the query string
        unblock();
        tx.retry();
        return;
      }

      const autoUnblockingTx = {
        ...tx,
        retry(): void {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when, location.hash, location.search]);
}
