import { authenticate } from '../lib/decorators'
import { ApiRequestDescriptor } from '../lib/request'
import { get, put, del } from '../lib/methods'
import { EditableDocumentJSON } from '../models/Documents/Editable'
import { PolymorphicEntity } from '../models/PolymorphicEntity'

export class EditableDocuments {
  @authenticate
  static index(entity: PolymorphicEntity): ApiRequestDescriptor<EditableDocumentJSON[]> {
    return get('/api/v1/editable_documents', {
      entity_type: entity.type,
      entity_id: entity.id,
    })
  }

  @authenticate
  static update({
    id,
    deal_type,
    generation_type,
    rule,
    owners,
  }: EditableDocumentJSON): ApiRequestDescriptor<EditableDocumentJSON> {
    return put(`/api/v1/editable_documents/${id}`, {
      deal_type,
      generation_type,
      rule_tag: rule.tag,
      owners_attributes: owners,
    })
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/editable_documents/${id}`)
  }
}
