import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  NavLink,
  useLocation,
  useParams,
  useResolvedPath,
} from 'react-router-dom';

import { ButtonText, Dropdown } from '@advitam/ui';
import Arrow from '@advitam/ui/images/icons/chevron-down.svg';

import { Sidebar } from 'containers/Crud';
import linksStyle from 'containers/Crud/Sidebar/LinksSection/LinksSection.module.scss';
import { makeHref } from 'containers/Crud/Sidebar/LinksSection/utils';

import { Path } from '../constants';
import messages from '../messages';
import { makeSelectWarehouseZones } from '../selectors';
import style from './Sidebar.module.scss';

export default function ZonesLink(): JSX.Element {
  const { pathname } = useLocation();
  const { zoneId } = useParams();
  const zones = useSelector(makeSelectWarehouseZones());

  const zoneHref = makeHref(Path.ZONES);
  const zonePath = useResolvedPath(zoneHref);

  const isActive = zonePath.pathname === pathname;
  const isSubpathActive = Boolean(zoneId);

  const classes = [
    linksStyle.group_title,
    linksStyle.link,
    isActive && linksStyle.active,
  ].filter(Boolean);

  return (
    <Dropdown
      className={`${style.dropdown} ${linksStyle.group}`}
      keepOpen={isActive || isSubpathActive}
      placeholder={
        <NavLink end to={zoneHref} className={classes.join(' ')}>
          <ButtonText small>
            <FormattedMessage id={messages.zones.id} />
            <Arrow />
          </ButtonText>
        </NavLink>
      }
    >
      {zones.map(zone => (
        <Sidebar.Link
          key={zone.id}
          message={zone.name}
          to={`${Path.ZONES}/${zone.id}`}
        />
      ))}
    </Dropdown>
  );
}
