import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Layout from 'containers/Crud/Layout';
import { OpeningHours } from 'containers/Crud';

import type { CrematoriumForm } from './types';
import Wrapper from './Wrapper';
import { saveCrematorium } from '../thunk';

export default function OpeningHoursSection(): JSX.Element {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: CrematoriumForm): void => {
      dispatch(saveCrematorium(values.crematorium));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <OpeningHours prefix="crematorium" />
      </Layout.Container>
    </Wrapper>
  );
}
