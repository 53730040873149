/*
 *
 * DialogBox reducer
 *
 */

import { OPEN_DIALOG, CLOSE_DIALOG } from './constants';

const initialState = {
  isOpen: false,
  props: null,
};

function dialogBoxReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        isOpen: true,
        props: action.props,
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        isOpen: false,
        props: null,
      };
    default:
      return state;
  }
}

export default dialogBoxReducer;
