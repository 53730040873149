import type { ReactNode } from 'react'

import ApiAutocomplete from '../ApiAutocomplete'
import type { RuleSetAutocompleteResult } from './types'

interface DocumentTypeAutocompleteProps {
  placeholder?: string
  required?: boolean
  label: ReactNode
  tooltip?: ReactNode
  name: string
  endpoint: string
  className?: string
  tokens: Record<string, string>
  searchParams?: Record<string, string>
  disabled?: boolean
}

export default function DocumentTypeAutocomplete({
  placeholder,
  name,
  label,
  tooltip,
  endpoint,
  required,
  tokens,
  searchParams,
  disabled,
  className,
}: DocumentTypeAutocompleteProps): JSX.Element {
  return (
    <ApiAutocomplete<RuleSetAutocompleteResult>
      placeholder={placeholder}
      name={name}
      label={label}
      tooltip={tooltip}
      endpoint={endpoint}
      required={required}
      className={className}
      disabled={disabled}
      keys={['description', 'tag']}
      requestHeaders={tokens}
      searchParams={searchParams}
    />
  )
}
