import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Api from '@advitam/api';
import type { CityhallAutocompleteResult } from '@advitam/ui/components/Form/Autocomplete/CityhallAutocomplete/types';
import { FormUI } from '@advitam/ui';
import { useThunkDispatch } from '@advitam/react';

import actionsMessages from 'messages/actions';

import messages from '../messages';
import { setActiveMarker, resetCityhall, resetNearEntities } from '../slice';
import { fetchCityhall, fetchNearEntities } from '../thunk';
import {
  makeSelectCityhallInputValue,
  makeSelectEntityTypes,
} from '../selectors';
import style from './Content.module.scss';

export default function CityhallAutocomplete(): JSX.Element {
  const intl = useIntl();
  const dispatch = useThunkDispatch();
  const cityhall = useSelector(makeSelectCityhallInputValue());
  const nearEntityTypes = useSelector(makeSelectEntityTypes());

  const onSelect = useCallback(
    async (result?: CityhallAutocompleteResult): Promise<void> => {
      dispatch(setActiveMarker(null));

      if (!result) {
        dispatch(resetCityhall());
        dispatch(resetNearEntities());
        return;
      }

      await dispatch(fetchCityhall(result.id));

      if (nearEntityTypes.length > 0) {
        await dispatch(fetchNearEntities());
      }
    },
    [dispatch, nearEntityTypes],
  );

  return (
    <FormUI.Autosuggest<CityhallAutocompleteResult>
      label={<FormattedMessage id={messages.cityhall.id} />}
      placeholder={intl.formatMessage(actionsMessages.search)}
      onChange={onSelect}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls)}
      className={style.autocomplete}
      value={cityhall}
    />
  );
}
