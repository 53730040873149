import { useParams } from 'react-router-dom';

import { assert } from 'lib/support';

import Payments from '../../Payments';

export default function Billing(): JSX.Element {
  const { dealUUID } = useParams();
  assert(dealUUID !== undefined);

  return <Payments dealUUID={dealUUID} />;
}
