const TWEET_URL = 'https://x.com/intent/post'

function buildShareLink(url: string): string {
  const params = [`url=${url}`]
  return `${TWEET_URL}?${params.join('&')}`
}

export const X = {
  buildShareLink,
}
