import { authenticate } from '../../lib/decorators'
import { post } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'

export class Welcome {
  @authenticate
  static create(
    dealUuid: string,
    dealUrl: string,
    redirectUrl: string,
  ): ApiRequestDescriptor<void> {
    return post(`/api/v1/deals/${dealUuid}/welcome`, {
      deal_url: dealUrl,
      redirect_url: redirectUrl,
    })
  }
}
