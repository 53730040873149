import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';

import { FlatButton, Tooltip } from '@advitam/ui';

import { makeSelectIsLoading } from '../../selectors';
import style from './Splitter.module.scss';

interface SplitterProps {
  message: MessageDescriptor;
  isPageSplitter?: boolean;
  onClick: () => void;
}

export default function Splitter({
  message,
  isPageSplitter,
  onClick,
}: SplitterProps): JSX.Element {
  const isLoading = useSelector(makeSelectIsLoading());

  const classes = [style.container, isPageSplitter && style.page].filter(
    Boolean,
  );

  const splitterClasses = [
    style.splitter,
    isLoading && style.disabled,
    isPageSplitter && style.page,
  ].filter(Boolean);

  return (
    <Tooltip
      contentClassName={classes.join(' ')}
      content={<FormattedMessage id={message.id} />}
      disabled={isLoading}
    >
      <FlatButton disabled={isLoading} onClick={onClick}>
        <span className={splitterClasses.join(' ')} />
      </FlatButton>
    </Tooltip>
  );
}
