import Fuse from 'fuse.js';
import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import {
  DealCommunication,
  DealCommunicationJSON,
} from '@advitam/api/models/Deal/Communication';

import { EntityTypes, HISTORY } from './constants';
import type { AppStateSubset, SortOrder, State } from './slice';
import type { CommunicationFilters } from './types';

type HistorySelector<T> = Selector<AppStateSubset, T>;

function selectHistoryDomain(state: AppStateSubset): State {
  return state[HISTORY];
}

function makeSelectRawCommunications(): HistorySelector<
  DealCommunicationJSON[]
> {
  return createSelector(selectHistoryDomain, state => state.communications);
}

function makeSelectSort(): HistorySelector<SortOrder> {
  return createSelector(selectHistoryDomain, state => state.sort);
}

function makeSelectSortedCommunications(): HistorySelector<
  DealCommunicationJSON[]
> {
  return createSelector(
    makeSelectRawCommunications(),
    makeSelectSort(),
    (communications, sort) =>
      [...communications].sort((a, b) => {
        const va = a[sort.column];
        const vb = b[sort.column];
        if (!va || !vb || va === vb) {
          return a.date > b.date ? 1 : -1;
        }
        if (sort.descending) {
          return va < vb ? 1 : -1;
        }
        return va > vb ? 1 : -1;
      }),
  );
}

export function makeSelectCommunications(): HistorySelector<
  DealCommunication[]
> {
  return createSelector(makeSelectSortedCommunications(), rawCommunications =>
    rawCommunications.map(
      communication => new DealCommunication(communication),
    ),
  );
}

export function makeSelectTotalCallDuration(): HistorySelector<number | null> {
  return createSelector(
    makeSelectCommunications(),
    communications =>
      communications.reduce(
        (acc, communication) => acc + (communication.duration || 0),
        0,
      ) || null,
  );
}

export function makeSelectFilters(): HistorySelector<CommunicationFilters> {
  return createSelector(selectHistoryDomain, ({ filters }) => filters);
}

export function makeSelectFilteredCommunications(): HistorySelector<
  DealCommunication[]
> {
  return createSelector(
    makeSelectCommunications(),
    makeSelectFilters(),
    (communications, filters) => {
      const fuse = new Fuse(communications, {
        keys: ['entity_name', 'channel_value', 'subject', 'content'],
        threshold: 0.2,
      });
      const fuseItems =
        filters.q && fuse.search(filters.q).map(result => result.item);

      return communications.filter(communication => {
        if (
          filters.recipientType !== undefined &&
          !EntityTypes[filters.recipientType].includes(
            communication.entity_type,
          )
        ) {
          return false;
        }

        if (
          filters.channelType &&
          communication.channel_type !== filters.channelType
        ) {
          return false;
        }

        if (filters.user && communication.user_id !== filters.user) {
          return false;
        }

        if (filters.from && filters.to) {
          const date = communication.date || communication.received_at;
          if (!date || date < filters.from || date > filters.to) {
            return false;
          }
        }

        return !fuseItems || fuseItems.includes(communication);
      });
    },
  );
}

export function makeSelectIsLoading(): HistorySelector<boolean> {
  return createSelector(selectHistoryDomain, ({ isLoading }) => isLoading);
}

export function makeSelectError(): HistorySelector<SerializedApiError | null> {
  return createSelector(selectHistoryDomain, ({ error }) => error);
}

function makeSelectContentModalRecordIndex(): HistorySelector<number | null> {
  return createSelector(
    selectHistoryDomain,
    state => state.contentModalRecordIndex,
  );
}

export function makeSelectContentModalRecord(): HistorySelector<DealCommunication | null> {
  return createSelector(
    makeSelectContentModalRecordIndex(),
    makeSelectCommunications(),
    (index, communications) => {
      if (index === null) {
        return null;
      }
      return communications[index];
    },
  );
}

function makeSelectMailContentModalRecordIndex(): HistorySelector<
  number | null
> {
  return createSelector(
    selectHistoryDomain,
    state => state.mailContentModalRecordIndex,
  );
}

export function makeSelectMailContentModalRecord(): HistorySelector<DealCommunication | null> {
  return createSelector(
    makeSelectMailContentModalRecordIndex(),
    makeSelectCommunications(),
    (index, communications) => {
      if (index === null) {
        return null;
      }
      return communications[index];
    },
  );
}

export function makeSelectMailContentModalText(): HistorySelector<
  string | null
> {
  return createSelector(makeSelectMailContentModalRecord(), record => {
    if (record === null) {
      return null;
    }
    return record.content;
  });
}
