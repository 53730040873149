import { Company } from './api'
import { Headquarter } from './types'

export function serialize(company: Company): Headquarter {
  return {
    siret: company.siege.siret,
    name: company.nom_complet.toUpperCase(),
    address: [company.siege.numero_voie, company.siege.type_voie, company.siege.libelle_voie]
      .filter(Boolean)
      .join(' '),
    postal_code: company.siege.code_postal,
    city: company.siege.libelle_commune,
    country: {
      name: company.siege.libelle_pays_etranger || 'France',
      code: company.siege.code_pays_etranger || 'FR',
    },
  }
}
