import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import CloseCross from 'components/CloseCross';
import { FormattedMessage, injectIntl } from 'react-intl';

import { safeTranslation } from 'utils/functions';

import groupMessages from 'messages/families';

import messages from './messages';
import Solution from './Solution';

const Solutions = ({
  solutions,
  groupNames,
  updateSupplierSelection,
  selectedSolutions,
  intl,
}) => {
  if (groupNames.length === 0) {
    return null;
  }

  const [isModalOpen, setModal] = useState(false);
  const [errors, setErrors] = useState([]);

  // Wrapper
  const setDependenciesErrors = dependenciesErrors => {
    if (dependenciesErrors.length === 0) {
      setModal(false);
      setErrors([]);
      return;
    }
    setErrors(dependenciesErrors);
    setModal(true);
  };

  const checkDependencies = nextSolutions => {
    const depErrors = [];
    Object.keys(nextSolutions).forEach(groupName => {
      const { dependencies } = nextSolutions[groupName];
      const dependenciesNames = Object.keys(dependencies);
      // There is no dependency to check
      if (dependenciesNames.length === 0) {
        return;
      }
      dependenciesNames.forEach(dependencyName => {
        const solutionsIds = dependencies[dependencyName];
        const solutionToCheck = nextSolutions[dependencyName];
        const isSolutionValid = solutionsIds.includes(solutionToCheck.id);

        if (isSolutionValid) {
          return;
        }

        depErrors.push({ groupName, dependencyName });
      });
    });
    return depErrors;
  };

  const checkDependenciesAndUdpate = (groupName, newSolution) => {
    // Construct next solutions
    const nextSolutions = { ...selectedSolutions };
    nextSolutions[groupName] = newSolution;

    const errorsDependencies = checkDependencies(nextSolutions);
    if (errorsDependencies.length === 0) {
      return updateSupplierSelection(nextSolutions);
    }

    return setDependenciesErrors(errorsDependencies);
  };

  const styles = {
    overlay: {
      zIndex: '10',
    },
    content: {
      position: 'relative',
      overflow: 'visible',
      borderRadius: '3px',
      padding: '30px',
      margin: '100px auto',
      display: 'flex',
      flexFlow: 'column',
    },
  };

  return (
    <>
      <div className="booking__prestation booking__prestation__selection--header">
        <div className="booking__prestation__header booking__prestation__header--border booking__prestation__selection--prestations">
          <FormattedMessage {...messages.prestation} />
        </div>
        <div className="booking__prestation__header booking__prestation__selection--choices">
          <FormattedMessage {...messages.supplier} />
        </div>
      </div>
      {groupNames.map(groupName => (
        <Solution
          key={groupName}
          groupName={groupName}
          checkDependenciesAndUdpate={checkDependenciesAndUdpate}
          solution={solutions[groupName]}
        />
      ))}
      <Modal
        isOpen={isModalOpen}
        contentLabel="dialog"
        onRequestClose={() => setDependenciesErrors([])}
        ariaHideApp={false}
        className="modal"
        style={styles}
      >
        <CloseCross handleClose={() => setDependenciesErrors([])} />
        <h4>
          <FormattedMessage {...messages.dependencyErrorModal} />
        </h4>
        {errors.map(error => (
          <FormattedMessage
            key={`${error.groupName}-${error.dependencyName}`}
            {...messages.dependsOF}
            values={{
              groupName: safeTranslation(groupMessages, error.groupName, intl),
              dependencyName: safeTranslation(
                groupMessages,
                error.dependencyName,
                intl,
              ),
            }}
          />
        ))}
      </Modal>
    </>
  );
};

Solutions.propTypes = {
  solutions: PropTypes.object.isRequired,
  selectedSolutions: PropTypes.object.isRequired,
  updateSupplierSelection: PropTypes.func.isRequired,
  groupNames: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Solutions);
