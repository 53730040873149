import { createSelector, Selector } from 'reselect';

import {
  FuneralParlor,
  FuneralParlorJSON,
} from '@advitam/api/models/FuneralParlor';
import type { SerializedApiError } from '@advitam/api';

import { FUNERAL_PARLOR } from './constants';
import type { AppStateSubset, State } from './slice';
import { NewFuneralParlor } from './types';

type FuneralParlorSelectors<T> = Selector<AppStateSubset, T>;

const selectFuneralParlorDomain = (state: AppStateSubset): State =>
  state[FUNERAL_PARLOR];

export const makeSelectRawFuneralParlor = (): FuneralParlorSelectors<
  FuneralParlorJSON | NewFuneralParlor | null
> =>
  createSelector(
    selectFuneralParlorDomain,
    ({ funeralParlor }) => funeralParlor,
  );

export const makeSelectFuneralParlor = (): FuneralParlorSelectors<FuneralParlor | null> =>
  createSelector(makeSelectRawFuneralParlor(), rawFuneralParlor =>
    rawFuneralParlor?.id ? new FuneralParlor(rawFuneralParlor) : null,
  );

export const makeSelectIsLoading = (): FuneralParlorSelectors<boolean> =>
  createSelector(selectFuneralParlorDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): FuneralParlorSelectors<boolean> =>
  createSelector(selectFuneralParlorDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): FuneralParlorSelectors<boolean> =>
  createSelector(selectFuneralParlorDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): FuneralParlorSelectors<boolean> =>
  createSelector(
    selectFuneralParlorDomain,
    ({ isUpdatingName }) => isUpdatingName,
  );

export const makeSelectError = (): FuneralParlorSelectors<SerializedApiError | null> =>
  createSelector(selectFuneralParlorDomain, ({ error }) => error);

export const makeSelectDestroyError = (): FuneralParlorSelectors<SerializedApiError | null> =>
  createSelector(selectFuneralParlorDomain, ({ destroyError }) => destroyError);
