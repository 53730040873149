import { ReactNode } from 'react'

import Text from '../Text'
import style from './Macaron.module.scss'

interface MacaronProps {
  icon: ReactNode
  text: ReactNode
  className?: string
}

export default function Macaron({ icon, text, className = '' }: MacaronProps): JSX.Element {
  return (
    <div className={`${style.background} ${className}`}>
      <div className={style.content}>
        {icon}
        <Text>{text}</Text>
      </div>
    </div>
  )
}
