import Api, { ApiRequestDescriptor, request } from '@advitam/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { assert } from '@advitam/support';

import { DATA, Dataset, Datasets } from './constants';
import { makeSelectMissingDatasetsIn } from './selectors';
import { AppStateSubset } from './slice';

const datasetRequests: Record<Dataset, () => ApiRequestDescriptor<unknown>> = {
  departments: (): ApiRequestDescriptor<unknown> =>
    Api.V1.Autocompletes.departments(),
  worshipTypes: (): ApiRequestDescriptor<unknown> =>
    Api.V1.WorshipTypes.index(),
  concessionTypes: (): ApiRequestDescriptor<unknown> =>
    Api.V1.ConcessionTypes.index(),
  concessionSemelleTypes: (): ApiRequestDescriptor<unknown> =>
    Api.V1.ConcessionSemelleTypes.index(),
  funeralBrands: (): ApiRequestDescriptor<unknown> =>
    Api.V1.FuneralBrands.index(),
  funeralTeam: (): ApiRequestDescriptor<unknown> =>
    Api.V1.Users.FuneralTeam.index(),
};

export const fetchDatasets = createAsyncThunk(
  `${DATA}/FETCH_DATASETS`,
  async (datasets: Dataset[], { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const missingDatasets = makeSelectMissingDatasetsIn(datasets)(state);

    try {
      const entries = await Promise.all(
        missingDatasets.map(async dataset => {
          const { body } = await request(datasetRequests[dataset]());
          assert(body !== null);
          return [dataset, body];
        }),
      );
      return Object.fromEntries(entries) as Partial<Datasets>;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
