import { GET_CLIENT_DATA } from 'containers/Deal/constants.ts';
import { updateDeal } from 'containers/Deal/slice.ts';

export function getClientData(id, index, clients) {
  return {
    type: GET_CLIENT_DATA,
    id,
    index,
    clients,
  };
}

export function setClient(id, data, index, clients) {
  const newClients = [...clients];
  newClients[index] = {
    ...newClients[index],
    id,
    ...data,
  };

  return updateDeal({
    clients: newClients,
  });
}
