import { ReactNode } from 'react'
import Text from '../../Text'

export interface CookieLinkProps {
  text: ReactNode
}

export default function CookieLink({ text }: CookieLinkProps): JSX.Element {
  return (
    <a href="#cookies">
      <Text small>{text}</Text>
    </a>
  )
}
