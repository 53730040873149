import { ApiError, SerializedApiError } from '@advitam/api';
import { createSlice } from '@reduxjs/toolkit';
import { DEATH_DECLARATION_VALIDATION } from './constants';
import { updateDeathDeclaration } from './thunk';

export interface State {
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DEATH_DECLARATION_VALIDATION]: State;
}

const initialState: State = {
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: DEATH_DECLARATION_VALIDATION,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(updateDeathDeclaration.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateDeathDeclaration.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateDeathDeclaration.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
