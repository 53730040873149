// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddStep-module__container--WNz2P{position:relative;z-index:1;width:100%;height:0;text-align:center}.AddStep-module__button--YLOpr{outline:none;cursor:pointer;background-color:#fff;box-shadow:0 3px 6px rgba(1,1,1,.3)}.AddStep-module__button--YLOpr:hover{background-color:#eee}.AddStep-module__add_button--fLQJ2{position:relative;top:-15px;height:30px;width:30px;border-radius:50%}.AddStep-module__choice_button--diUyx{padding:10px;margin:5px;border-radius:3px}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/DealFuneral/StepsSection/AddStep.module.scss","webpack://./../ui/scss/_z_index.scss"],"names":[],"mappings":"AAEA,kCACE,iBAAA,CACA,SCFuB,CDGvB,UAAA,CACA,QAAA,CACA,iBAAA,CAGF,+BACE,YAAA,CACA,cAAA,CACA,qBAAA,CACA,mCAAA,CAEA,qCACE,qBAAA,CAIJ,mCACE,iBAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CAGF,sCACE,YAAA,CACA,UAAA,CACA,iBAAA","sourcesContent":["@import '~@advitam/ui/scss/z_index';\n\n.container {\n  position: relative;\n  z-index: $z-index__content--over;\n  width: 100%;\n  height: 0;\n  text-align: center;\n}\n\n.button {\n  outline: none;\n  cursor: pointer;\n  background-color: #fff;\n  box-shadow: 0 3px 6px rgba(1, 1, 1, 0.3);\n\n  &:hover {\n    background-color: #eee;\n  }\n}\n\n.add_button {\n  position: relative;\n  top: -15px;\n  height: 30px;\n  width: 30px;\n  border-radius: 50%;\n}\n\n.choice_button {\n  padding: 10px;\n  margin: 5px;\n  border-radius: 3px;\n}\n","$z-index__background: -1;\n$z-index__content: 0;\n$z-index__content--over: $z-index__content + 1;\n$z-index__dropdown: 5;\n$z-index__navbar: 10;\n$z-index__popup: $z-index__navbar + 5;\n$z-index__modal: $z-index__popup + 5;\n$z-index__foreground: $z-index__modal + 5;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AddStep-module__container--WNz2P`,
	"button": `AddStep-module__button--YLOpr`,
	"add_button": `AddStep-module__add_button--fLQJ2`,
	"choice_button": `AddStep-module__choice_button--diUyx`
};
export default ___CSS_LOADER_EXPORT___;
