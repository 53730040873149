import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import {
  CompactDesign,
  ErrorText,
  FormattedApiError,
  ResourceList,
  Text,
} from '@advitam/ui';

import {
  makeSelectError,
  makeSelectHasMore,
  makeSelectInvoices,
  makeSelectIsLoading,
  makeSelectPage,
} from './selectors';
import slice, { reset } from './slice';
import { fetchEntityInvoices, fetchPendingPayments } from './thunk';
import Filters from './Filters';
import Header from './Header';
import SavingLayer from './SavingLayer';
import Row from './List/Row';
import RowGroup from './List/RowGroup';
import modalSlice from './List/Edit/slice';
import EditModal from './List/Edit/Modal';
import messages from './messages';
import style from './Billing.module.scss';

function Billing(): JSX.Element {
  const dispatch = useDispatch();

  const invoices = useSelector(makeSelectInvoices());
  const error = useSelector(makeSelectError());
  const isLoading = useSelector(makeSelectIsLoading());
  const currentPage = useSelector(makeSelectPage());
  const hasMorePages = useSelector(makeSelectHasMore());

  const fetch = useCallback(
    (page: number) => {
      dispatch(fetchEntityInvoices(page));
    },
    [dispatch],
  );

  const fetchNextPage = useCallback(() => {
    if (hasMorePages) {
      fetch(currentPage + 1);
    }
  }, [fetch, currentPage, hasMorePages]);

  useEffect(() => {
    dispatch(fetchPendingPayments());
    fetch(1);
    return () => {
      dispatch(reset());
    };
  }, [dispatch, fetch]);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>

      <CompactDesign>
        <main>
          <ResourceList.Container
            error={
              <>
                {invoices?.length === 0 && (
                  <Text tagName="p" className={style.no_result}>
                    <FormattedMessage id={messages.noResult.id} />
                  </Text>
                )}
                {error && (
                  <ErrorText center>
                    <FormattedApiError error={error} />
                  </ErrorText>
                )}
              </>
            }
            filters={<Filters />}
            hasMore={hasMorePages}
            header={<Header />}
            isLoading={isLoading}
            useWindow={false}
            fetchResources={fetchNextPage}
          >
            {invoices?.map(invoice =>
              invoice.prestations.length === 1 ? (
                <Row invoice={invoice} key={invoice.id} />
              ) : (
                <RowGroup invoice={invoice} key={invoice.id} />
              ),
            )}
          </ResourceList.Container>

          <EditModal />
          <SavingLayer />
        </main>
      </CompactDesign>
    </>
  );
}

export default withSlice(slice, modalSlice)(Billing);
