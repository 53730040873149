import { FormattedMessage } from 'react-intl';
import { PaymentMean } from 'models/Payment/Mean';
import { Checkout } from './types';
import messages from './messages';

interface DetailsProps {
  checkout: Checkout;
}

export default function Details({
  checkout,
}: DetailsProps): JSX.Element | null {
  if (checkout.type === PaymentMean.CASH) {
    return null;
  }

  if (checkout.type === PaymentMean.CHECK) {
    const { cheque_address: address, cheque_order: order } = checkout;
    return (
      <>
        {address && (
          <FormattedMessage
            id={messages.checkAddress.id}
            values={{ address }}
            tagName="span"
          />
        )}
        {order && (
          <FormattedMessage
            id={messages.checkOrder.id}
            values={{ order }}
            tagName="span"
          />
        )}
      </>
    );
  }

  const { iban, rib } = checkout;
  return (
    <>
      {iban && (
        <FormattedMessage
          id={messages.transferIban.id}
          values={{ iban }}
          tagName="span"
        />
      )}
      {rib && (
        <FormattedMessage
          id={messages.transferBic.id}
          values={{ bic: rib }}
          tagName="span"
        />
      )}
    </>
  );
}
