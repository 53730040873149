import { FormattedMessage } from 'react-intl';

import { ResourceList } from '@advitam/ui';

import messages from './messages';
import style from './FundingAgencies.modules.scss';

export default function Header(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell className={style.logo} />
      <ResourceList.Cell className={style.name}>
        <FormattedMessage id={messages.name.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.phone.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.email.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.contact.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet className={style.type}>
        <FormattedMessage id={messages.type.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
