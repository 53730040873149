import { useCallback } from 'react';
import { Form } from 'react-final-form';
import { Navigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  EmailInput,
  ErrorText,
  FormLayout,
  LinkButton,
  PasswordInput,
  Spinner,
  SubmitButton,
} from '@advitam/ui';
import { withSlice } from '@advitam/react';

import { Path } from 'containers/App/constants';
import { makeSelectIsAuthenticated } from 'slices/auth';
import { useLocationState } from 'lib/reactvitam';

import Layout from '../parts/Layout';
import authMessages from '../messages';
import style from '../Auth.module.scss';
import { EMAIL_FIELD, PASSWORD_FIELD } from './constants';
import messages from './messages';
import { makeSelectErrorMessage, makeSelectIsLoading } from './selectors';
import slice from './slice';
import { login } from './thunk';
import { LoginForm } from './types';

interface LocationState {
  from: string;
}

function Login(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const locationState = useLocationState<LocationState>();
  const from = locationState?.from || Path.INDEX;

  const isAuthenticated = useSelector(makeSelectIsAuthenticated());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectErrorMessage());

  const onSubmit = useCallback(
    (values: LoginForm): void => {
      dispatch(login({ values, from }));
    },
    [dispatch, from],
  );

  if (isAuthenticated) {
    return <Navigate replace to={from} />;
  }

  return (
    <Layout title={<FormattedMessage id={messages.title.id} />}>
      <Form<LoginForm> onSubmit={onSubmit}>
        {({ handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <FormLayout.Row className={style.compact}>
              <EmailInput
                required
                name={EMAIL_FIELD}
                label={<FormattedMessage id={authMessages.email.id} />}
                placeholder={intl.formatMessage(authMessages.emailPlaceholder)}
              />
            </FormLayout.Row>
            <FormLayout.Row className={style.compact}>
              <PasswordInput
                required
                hideErrorMessage
                name={PASSWORD_FIELD}
                label={<FormattedMessage id={messages.password.id} />}
              />
            </FormLayout.Row>

            {error && <ErrorText className={style.compact}>{error}</ErrorText>}

            <div className={style.actions}>
              <SubmitButton
                primary
                icon={isLoading ? <Spinner /> : undefined}
                className={style.action}
                text={<FormattedMessage id={messages.login.id} />}
              />
              <LinkButton
                outline
                internal
                href={Path.FORGOTTEN_PASSWORD}
                className={style.action}
                text={<FormattedMessage id={messages.forgottenPassword.id} />}
              />
            </div>
          </form>
        )}
      </Form>
    </Layout>
  );
}

export default withSlice(slice)(Login);
