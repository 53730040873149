import { FormattedMessage } from 'react-intl';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { CheckoutType } from '@advitam/api/models/CheckoutType';
import { BICInput, FormLayout, IBANInput, Input } from '@advitam/ui';

import { makeSelectUser } from 'slices/auth';

import { getFieldName } from '../utils';
import { getCheckoutType } from './utils';
import messages from './messages';
import {
  BIC_FIELD,
  CHEQUE_ADDRESS_FIELD,
  CHEQUE_ORDER_FIELD,
  IBAN_FIELD,
} from './constants';

interface BillingDetailsProps {
  prefix: string;
}

export default function BillingDetails({
  prefix,
}: BillingDetailsProps): JSX.Element | null {
  const user = useSelector(makeSelectUser());
  const { values } = useFormState();
  const checkoutType = getCheckoutType(values, prefix);

  if (checkoutType === CheckoutType.CHECK) {
    return (
      <>
        <FormLayout.Row>
          <Input
            label={<FormattedMessage id={messages.checkAddress.id} />}
            name={getFieldName(prefix, CHEQUE_ADDRESS_FIELD)}
            disabled={!user?.isFuneralDirectorOrAbove}
          />
        </FormLayout.Row>
        <FormLayout.Row>
          <Input
            label={<FormattedMessage id={messages.checkOrder.id} />}
            name={getFieldName(prefix, CHEQUE_ORDER_FIELD)}
            disabled={!user?.isFuneralDirectorOrAbove}
          />
        </FormLayout.Row>
      </>
    );
  }

  if (checkoutType === CheckoutType.TRANSFER) {
    return (
      <>
        <FormLayout.Row>
          <IBANInput
            label={<FormattedMessage id={messages.iban.id} />}
            name={getFieldName(prefix, IBAN_FIELD)}
            disabled={!user?.isFuneralDirectorOrAbove}
          />
        </FormLayout.Row>
        <FormLayout.Row>
          <BICInput
            label={<FormattedMessage id={messages.bic.id} />}
            name={getFieldName(prefix, BIC_FIELD)}
            disabled={!user?.isFuneralDirectorOrAbove}
          />
        </FormLayout.Row>
      </>
    );
  }

  return null;
}
