import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { StayJSON } from '@advitam/api/models/FuneralParlor';

import { FUNERAL_PARLOR_STAYS } from './constants';
import type { AppStateSubset, State } from './slice';
import type { StayFilters } from './types';

type StaysSelector<T> = Selector<AppStateSubset, T>;

const selectStaysDomain = (state: AppStateSubset): State =>
  state[FUNERAL_PARLOR_STAYS];

export const makeSelectIsLoading = (): StaysSelector<boolean> =>
  createSelector(selectStaysDomain, substate => substate.isLoading);

export const makeSelectIsSaving = (): StaysSelector<boolean> =>
  createSelector(selectStaysDomain, substate => substate.isSaving);

export const makeSelectStays = (): StaysSelector<StayJSON[]> =>
  createSelector(selectStaysDomain, substate => substate.stays);

export const makeSelectFilters = (): StaysSelector<StayFilters> =>
  createSelector(selectStaysDomain, substate => substate.filters);

export const makeSelectError = (): StaysSelector<SerializedApiError | null> =>
  createSelector(selectStaysDomain, substate => substate.error);
