import { authenticate } from 'api/decorators';
import type { ApiRequestDescriptor } from 'api/request';
import { post, del } from 'api/methods';

import type { DealJSON } from 'models/Deal';

export default class Reminder {
  @authenticate
  static create(
    dealId: number,
    remind_at: string,
  ): ApiRequestDescriptor<DealJSON> {
    return post(`/api/v1/deals/${dealId}/reminder`, { remind_at });
  }

  @authenticate
  static destroy(dealId: number): ApiRequestDescriptor<DealJSON> {
    return del(`/api/v1/deals/${dealId}/reminder`);
  }
}
