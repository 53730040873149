import { defineMessages } from 'react-intl';

export default defineMessages({
  checkAll: {
    id: 'app.components.Select.checkAll',
    defaultMessage: 'Check all',
  },
  uncheckAll: {
    id: 'app.components.Select.uncheckAll',
    defaultMessage: 'Uncheck all',
  },
});
