import {
  ChangeEventHandler,
  FocusEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react'
import EyeIcon from '../../../../images/icons/eye.svg'
import EyeOffIcon from '../../../../images/icons/eye-off.svg'
import { FlatButton } from '../../../Button'
import Input from '../Input'
import style from './Password.module.scss'

interface PasswordProps {
  value: string | null
  label?: ReactNode
  name?: string
  error?: ReactElement | boolean
  disabled?: boolean
  tooltip?: ReactNode
  className?: string
  onChange: ChangeEventHandler<HTMLInputElement>
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  renderBelow?: (value: string) => ReactNode
}

export default function Password({
  value,
  label,
  name,
  error,
  disabled,
  tooltip,
  className,
  onChange,
  onBlur,
  renderBelow,
}: PasswordProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisible = useCallback((): void => {
    setIsVisible(prev => !prev)
  }, [])

  return (
    <Input
      name={name}
      type={isVisible ? 'text' : 'password'}
      label={label}
      disabled={disabled}
      value={value}
      error={error}
      className={className}
      tooltip={tooltip}
      onChange={onChange}
      onBlur={onBlur}
      renderBelow={renderBelow}
      suffix={
        value && (
          <FlatButton className={style.visible_btn} onClick={toggleVisible}>
            {isVisible ? <EyeOffIcon /> : <EyeIcon />}
          </FlatButton>
        )
      }
    />
  )
}
