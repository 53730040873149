import { ReactNode } from 'react'

import Heading from '../Heading'
import Text from '../Text'
import style from './ContentCard.module.scss'

interface ContentCardProps {
  img?: ReactNode
  title: ReactNode
  text?: ReactNode
  footer?: ReactNode
  className?: string
}

export default function ContentCard({
  img,
  title,
  text,
  footer,
  className,
}: ContentCardProps): JSX.Element {
  return (
    <div className={[style.card, className].join(' ')}>
      {img && <div className={style.img}>{img}</div>}
      <Heading small tagName="h4">
        <b>{title}</b>
      </Heading>
      {text && (
        <div className={style.text}>
          <Text small>{text}</Text>
        </div>
      )}
      {footer && (
        <div className={style.footer}>
          <Text>
            <b>{footer}</b>
          </Text>
        </div>
      )}
    </div>
  )
}
