import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep, get, isEqual, set } from 'lodash';

import { ClientAbilityJSON } from '@advitam/api/models/Client/Ability';
import { assert } from 'lib/support';

import type { AppStateSubset as ClientsAppStateSubstate } from '../Clients/slice';
import { updateAbilities } from '../Sections/Identity/thunk';
import { makeSelectAbilities } from '../Sections/Identity/selectors';
import type { AppStateSubset as IdentityAppStateSubstate } from '../Sections/Identity/slice';
import { makeSelectRawDeal, makeSelectIsDirty } from '../selectors.typed';
import { dealSaved, AppStateSubset as DealAppStateSubset } from '../slice';
import { saveDeal } from '../thunk';
import { DEAL_FUNERAL, FORM_KEYS } from './constants';
import { abilityToLegacyClient } from './converters';
import type { FuneralForm } from './types';

type AppStateSubset = DealAppStateSubset &
  IdentityAppStateSubstate &
  ClientsAppStateSubstate;

function didSpecialChanged(
  abilities: ClientAbilityJSON[],
  values: FuneralForm,
): boolean {
  const storedSpecialId = abilities.findIndex(ability => ability.special);
  const formSpecialId = values.abilities.findIndex(ability => ability.special);
  return (
    storedSpecialId !== formSpecialId ||
    !isEqual(abilities[formSpecialId], values.abilities[formSpecialId])
  );
}

interface SaveDealFormPayload {
  values: FuneralForm;
  addFormError: (field: string, error: string) => void;
}

export const saveDealForm = createAsyncThunk(
  `${DEAL_FUNERAL}/SAVE_FORM`,
  async (
    { values, addFormError }: SaveDealFormPayload,
    { dispatch, getState },
  ) => {
    const state = getState() as AppStateSubset;
    const deal = cloneDeep(makeSelectRawDeal()(state));
    assert(deal !== null);
    const isDealDirty = makeSelectIsDirty()(state);

    const hasDealChanges =
      deal.id &&
      FORM_KEYS.some(key => !isEqual(get(values.deal, key), get(deal, key)));

    FORM_KEYS.forEach(key => {
      set(deal, key, get(values.deal, key));
    });

    if (!deal.id) {
      deal.clients = values.abilities.map(ability =>
        abilityToLegacyClient(ability),
      );
      await dispatch(saveDeal(deal));
      return;
    }

    const abilities = makeSelectAbilities()(state);
    const dealNeedsSave =
      isDealDirty || hasDealChanges || didSpecialChanged(abilities, values);

    let hasFormError = false;
    const result = await dispatch(
      updateAbilities({
        values: values.abilities,
        addFormError: (field: string, error: string) => {
          hasFormError = true;
          addFormError(field, error);
        },
      }),
    );

    if (
      result.meta.requestStatus === 'fulfilled' &&
      !hasFormError &&
      dealNeedsSave
    ) {
      await dispatch(saveDeal(deal));
    } else {
      dispatch(dealSaved(deal));
    }
  },
);
