import { defineMessages } from 'react-intl';

export default defineMessages({
  AdministrativeProcedure: {
    id: 'app.components.Format.ClientAbilities.AdministrativeProcedure',
  },
  funeral: { id: 'app.components.Format.ClientAbilities.funeral' },
  marble: { id: 'app.components.Format.ClientAbilities.marble' },
  FuneralWill: { id: 'app.components.Format.ClientAbilities.FuneralWill' },
  Memorial: { id: 'app.components.Format.ClientAbilities.Memorial' },
  SafeKeeper: { id: 'app.components.Format.ClientAbilities.SafeKeeper' },
  safeKeeperOf: { id: 'app.components.Format.ClientAbilities.safeKeeperOf' },
  funeralWillsOf: {
    id: 'app.components.Format.ClientAbilities.funeralWillsOf',
  },
  item: { id: 'app.components.Format.ClientAbilities.item' },
  owner: { id: 'app.components.Format.ClientAbilities.owner' },
  editor: { id: 'app.components.Format.ClientAbilities.editor' },
  reader: { id: 'app.components.Format.ClientAbilities.reader' },
  notary: { id: 'app.components.Format.ClientAbilities.notary' },
  relative: { id: 'app.components.Format.ClientAbilities.relative' },
});
