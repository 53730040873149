import type { ReactNode } from 'react';
import { Marker } from '@react-google-maps/api';

import { LocationType } from '@advitam/api/models/LocationType';

import airportIcon from './icons/plane.inline.svg';
import cityhallIcon from './icons/cityhall.inline.svg';
import flagIcon from './icons/flag.inline.svg';
import crematoriumIcon from './icons/urn.inline.svg';
import funeralParlorIcon from './icons/candle.inline.svg';
import graveyardIcon from './icons/tombstone.inline.svg';
import hospitalIcon from './icons/heart.inline.svg';
import policeIcon from './icons/police.inline.svg';
import prefectureIcon from './icons/prefecture.inline.svg';
import rhaIcon from './icons/rha.inline.svg';
import worshipIcon from './icons/cross.inline.svg';

const LOCATION_ICON: Record<LocationType, string> = {
  [LocationType.AIRPORT]: airportIcon,
  [LocationType.CITYHALL]: cityhallIcon,
  [LocationType.CONSULATE]: flagIcon,
  [LocationType.CREMATORIUM]: crematoriumIcon,
  [LocationType.FUNERAL_PARLOR]: funeralParlorIcon,
  [LocationType.GRAVEYARD]: graveyardIcon,
  [LocationType.HEALTH_FACILITY]: hospitalIcon,
  [LocationType.HOSPITAL]: hospitalIcon,
  [LocationType.POLICE]: policeIcon,
  [LocationType.PREFECTURE]: prefectureIcon,
  [LocationType.PUBLIC_LOCATION]: flagIcon,
  [LocationType.REGIONAL_HEALTH_AUTHORITY]: rhaIcon,
  [LocationType.SUPPLIER_WAREHOUSE]: airportIcon,
  [LocationType.WORSHIP]: worshipIcon,
};

interface LocationMarkerProps {
  lat: number;
  lng: number;
  type: LocationType;
  onClick?: () => void;
  children?: ReactNode;
}

export default function LocationMarker({
  lat,
  lng,
  type,
  onClick,
  children,
}: LocationMarkerProps): JSX.Element {
  return (
    <Marker
      icon={LOCATION_ICON[type]}
      position={{ lat, lng }}
      onClick={onClick}
    >
      {children}
    </Marker>
  );
}
