import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { NotificationJSON } from '@advitam/api/models/Notification';

import { APP_NOTIFICATION_CENTER } from './constants';
import type { AppStateSubset, State } from './slice';

type NotificationCenterSelector<T> = Selector<AppStateSubset, T>;

const selectNotificationCenterDomain = (state: AppStateSubset): State =>
  state[APP_NOTIFICATION_CENTER];

export const makeSelectIsNotificationPanelOpen = (): NotificationCenterSelector<
  boolean
> => createSelector(selectNotificationCenterDomain, state => state.isPanelOpen);

export const makeSelectIsInitialized = (): NotificationCenterSelector<
  boolean
> =>
  createSelector(selectNotificationCenterDomain, state => state.isInitialized);

export const makeSelectIsLoading = (): NotificationCenterSelector<boolean> =>
  createSelector(selectNotificationCenterDomain, state => state.isLoading);

export const makeSelectHasMore = (): NotificationCenterSelector<boolean> =>
  createSelector(selectNotificationCenterDomain, state => state.hasMore);

export const makeSelectError = (): NotificationCenterSelector<SerializedApiError | null> =>
  createSelector(selectNotificationCenterDomain, state => state.error);

export const makeSelectNotifications = (): NotificationCenterSelector<
  NotificationJSON[]
> =>
  createSelector(selectNotificationCenterDomain, state => state.notifications);

export const makeSelectCurrentOffset = (): NotificationCenterSelector<number> =>
  createSelector(
    makeSelectNotifications(),
    notifications => notifications.length,
  );

export const makeSelectUnreadNotifications = (): NotificationCenterSelector<
  NotificationJSON[]
> =>
  createSelector(makeSelectNotifications(), notifications =>
    notifications.filter(notification => !notification.viewed),
  );

export const makeSelectUnreadNotificationIds = (): NotificationCenterSelector<
  number[]
> =>
  createSelector(makeSelectUnreadNotifications(), notifications =>
    notifications.map(notification => notification.id),
  );

export const makeSelectUnreadNotificationCount = (): NotificationCenterSelector<
  number
> =>
  createSelector(
    selectNotificationCenterDomain,
    state => state.unreadNotificationCount,
  );
