type RemainingKeys<T, Omits> = Exclude<keyof T, Omits> | Extract<PropertyKey, keyof T> // always include index properties

export function omit<T, O extends ReadonlyArray<keyof T>>(
  object: T,
  ...paths: O
): { [K in RemainingKeys<T, O[number]>]: T[K] } {
  const obj: T = { ...object }
  paths.forEach(path => {
    delete obj[path]
  })
  return obj
}
