import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormSpy, useForm, useFormState } from 'react-final-form';
import type { FormApi } from 'final-form';
import { isEqual } from 'lodash';

import { BillingType } from '@advitam/api/models/BillingType';
import { CheckoutType } from '@advitam/api/models/CheckoutType';
import { FormLayout, Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import { usePreviousValue } from '@advitam/react';

import { makeSelectUser } from 'slices/auth';

import Layout from '../../Layout';
import { getFieldName } from '../utils';
import { getCheckoutType } from './utils';
import BillingDetails from './Details';
import messages from './messages';
import {
  BIC_FIELD,
  CHECKOUT_TYPE_FIELD,
  CHEQUE_ADDRESS_FIELD,
  CHEQUE_ORDER_FIELD,
  IBAN_FIELD,
} from './constants';

function resetDetails(form: FormApi, prefix = ''): void {
  form.change(getFieldName(prefix, CHEQUE_ADDRESS_FIELD), undefined);
  form.change(getFieldName(prefix, CHEQUE_ORDER_FIELD), undefined);
  form.change(getFieldName(prefix, IBAN_FIELD), undefined);
  form.change(getFieldName(prefix, BIC_FIELD), undefined);
}

interface BillingProps {
  prefix?: string;
}

export default function Billing({ prefix = '' }: BillingProps): JSX.Element {
  const intl = useIntl();
  const user = useSelector(makeSelectUser());

  const form = useForm();
  const { values } = useFormState();
  const prevCheckoutType = usePreviousValue(getCheckoutType(values, prefix));

  const billingTypeOptions: SelectableItem<BillingType>[] = [
    {
      name: intl.formatMessage(messages.frequencyPrestation),
      value: BillingType.PRESTATION,
    },
    {
      name: intl.formatMessage(messages.frequencyMonth),
      value: BillingType.MONTH,
    },
    {
      name: intl.formatMessage(messages.frequencyQuarter),
      value: BillingType.QUARTER,
    },
    {
      name: intl.formatMessage(messages.frequencyDeal),
      value: BillingType.DEAL,
    },
  ];

  const checkoutTypeOptions: SelectableItem<CheckoutType>[] = [
    {
      name: intl.formatMessage(messages.checkoutTypeCash),
      value: CheckoutType.CASH,
    },
    {
      name: intl.formatMessage(messages.checkoutTypeTransfer),
      value: CheckoutType.TRANSFER,
    },
    {
      name: intl.formatMessage(messages.checkoutTypeCheck),
      value: CheckoutType.CHECK,
    },
  ];

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.title.id} />}>
      <FormLayout.Row>
        <Select
          items={billingTypeOptions}
          name={getFieldName(prefix, 'billing_type')}
          label={<FormattedMessage id={messages.frequency.id} />}
          disabled={!user?.isFuneralDirectorOrAbove}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          unselectable
          items={checkoutTypeOptions}
          name={getFieldName(prefix, CHECKOUT_TYPE_FIELD)}
          label={<FormattedMessage id={messages.checkoutType.id} />}
          disabled={!user?.isFuneralDirectorOrAbove}
        />
      </FormLayout.Row>
      <BillingDetails prefix={prefix} />
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values: spyValues }): void => {
          const newCheckoutType = getCheckoutType(spyValues, prefix);
          if (
            prevCheckoutType !== undefined &&
            !isEqual(prevCheckoutType, newCheckoutType)
          ) {
            resetDetails(form, prefix);
          }
        }}
      />
    </Layout.Fieldset>
  );
}
