import { assert } from 'lib/Assert';
import { Component, ComponentType, Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  makeSelectBlob,
  makeSelectPageCount,
  makeSelectUploads,
} from '../../selectors';
import slice, { AppStateSubset } from '../../slice';

/* eslint-disable react/no-unused-prop-types */
interface FileStateProps {
  blob: Blob | null;
  uploads: string[];
  pageCount: number;
}

interface FileDispatchProps {
  setPageCount: (cnt: number) => void;
  setFile: (blob: Blob | null) => void;
}

interface FileOwnProps {
  miniatures: boolean;
}
/* eslint-enable react/no-unused-prop-types */

export type FileProps = FileOwnProps & FileStateProps & FileDispatchProps;

export default class File<T = Record<string, never>> extends Component<
  FileProps,
  T
> {
  constructor(props: FileProps, additionalState: T) {
    super(props);
    this.state = additionalState;
  }

  render(): JSX.Element | null {
    assert(false);
    return null;
  }
}

const mapStateToProps = createStructuredSelector<
  AppStateSubset,
  FileStateProps
>({
  blob: makeSelectBlob(),
  uploads: makeSelectUploads(),
  pageCount: makeSelectPageCount(),
});

const { actions } = slice;
const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): FileDispatchProps => ({
  setFile: (blob: Blob | null): void => dispatch(actions.setFile(blob)),
  setPageCount: (cnt: number): void => dispatch(actions.setPageCount(cnt)),
});

export const connected = connect(mapStateToProps, mapDispatchToProps) as <T>(
  component: T,
) => ComponentType<T>;
