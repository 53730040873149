import { Field, useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Api, { request } from '@advitam/api';
import { ContactMedia } from '@advitam/api/models/Entity/ContactMedia';
import type { WorshipJSON } from '@advitam/api/models/Crematorium/Worship';
import {
  Button,
  FormLayout,
  TextArea,
  Input,
  PhoneInput,
  Select,
  FormUI,
  Link,
  isRequired,
  Tooltip,
} from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import LinkIcon from '@advitam/ui/images/icons/arrow-up-right-from-square.svg';

import Layout from 'containers/Crud/Layout';
import { makeSelectWorshipTypesNeedingLocation } from 'slices/data';
import { Path } from 'containers/App/constants';

import { CrematoriumForm } from '../types';
import messages from './messages';
import style from './Worship.module.scss';

interface WorshipBoxProps {
  worship: WorshipJSON;
  worshipIndex: number;
  deleteWorship: (index: number) => void;
  updateWorship: (index: number, worship: WorshipJSON) => void;
}

const PREFIX = 'crematorium.worships';

export default function WorshipBox({
  worship,
  worshipIndex,
  deleteWorship,
  updateWorship,
}: WorshipBoxProps): JSX.Element {
  const intl = useIntl();
  const { values } = useFormState<CrematoriumForm>();

  const selectedWorshipTypeId =
    values.crematorium.worships[worshipIndex].worship_type?.id;

  const worshipTypes = useSelector(makeSelectWorshipTypesNeedingLocation());

  const worshipTypesFormatted = worshipTypes.map(type => ({
    ...type,
    value: type.id,
  }));

  const worshipType = worshipTypes.find(
    type => type.id === selectedWorshipTypeId,
  );

  const preferedContactOptions: SelectableItem<ContactMedia>[] = [
    {
      name: intl.formatMessage(messages.email),
      value: ContactMedia.EMAIL,
    },
    {
      name: intl.formatMessage(messages.fax),
      value: ContactMedia.FAX,
    },
  ];

  const prefix = `${PREFIX}[${worshipIndex}]`;

  return (
    <Layout.Fieldset
      title={
        worship.worship_type?.name || (
          <FormattedMessage id={messages.newTitle.id} />
        )
      }
    >
      <FormLayout.Row>
        <Select
          name={`${prefix}.worship_type.id`}
          label={<FormattedMessage id={messages.worshipType.id} />}
          items={worshipTypesFormatted}
        />
      </FormLayout.Row>
      <Field name={`${prefix}.id`} validate={isRequired}>
        {({ meta }): JSX.Element => (
          <FormLayout.Row className={style.autosuggest}>
            <FormUI.Autosuggest
              disabled={!selectedWorshipTypeId}
              error={meta.touched && !meta.valid}
              value={{ id: worship.id, description: worship.name }}
              label={<FormattedMessage id={messages.worshipAutocomplete.id} />}
              endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.worships)}
              searchParams={{ tag: worshipType?.tag || '' }}
              onChange={async (v): Promise<void> => {
                if (!v || !worshipType) return;
                const { body } = await request(Api.V1.Worships.show(v.id));
                updateWorship(worshipIndex, body);
              }}
            />
            <Tooltip
              contentClassName={style.autosuggest_link}
              content={<FormattedMessage id={messages.editWorship.id} />}
            >
              <Link href={`${Path.WORSHIPS}/${worship.id}`}>
                <LinkIcon />
              </Link>
            </Tooltip>
          </FormLayout.Row>
        )}
      </Field>

      <FormLayout.Row>
        <TextArea
          disabled
          name={`${prefix}.comment`}
          label={<FormattedMessage id={messages.comment.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          disabled
          name={`${prefix}.address`}
          label={<FormattedMessage id={messages.address.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          disabled
          name={`${prefix}.postal_code`}
          label={<FormattedMessage id={messages.postal_code.id} />}
        />
        <Input
          disabled
          name={`${prefix}.city`}
          label={<FormattedMessage id={messages.city.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          disabled
          label={<FormattedMessage id={messages.prefered_contact_media.id} />}
          name={`${prefix}.prefered_contact_media`}
          items={preferedContactOptions}
        />
        <Input
          type="number"
          disabled
          name={`${prefix}.price`}
          label={<FormattedMessage id={messages.price.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <PhoneInput
          disabled
          name={`${prefix}.phone`}
          label={<FormattedMessage id={messages.phone.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <PhoneInput
          disabled
          name={`${prefix}.phone_2`}
          label={<FormattedMessage id={messages.phone_2.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <PhoneInput
          disabled
          name={`${prefix}.fax`}
          label={<FormattedMessage id={messages.fax.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          disabled
          name={`${prefix}.email`}
          label={<FormattedMessage id={messages.email.id} />}
        />
      </FormLayout.Row>
      <div className={style.buttons_row}>
        <Button
          outline
          onClick={(): void => deleteWorship(worshipIndex)}
          text={<FormattedMessage id={messages.delete.id} />}
        />
      </div>
    </Layout.Fieldset>
  );
}
