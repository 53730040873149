import { Component } from 'react';
import PropTypes from 'prop-types';

import Services from 'containers/Deal/DealFuneral/StepsSection/Services';
import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';
import CheckboxLabel from 'components/Input/CheckboxLabel';

import {
  renderStepDefaults,
  renderLocation,
  renderEditLocation,
} from './stepEditorsUtils';
import commonMessages from './messages';

const locationTypes = [
  { value: 'Graveyard', label: commonMessages.graveyard },
  { value: 'Crematorium', label: commonMessages.anotherCrematorium },
];

class Dispersion extends Component {
  onChange = checked => {
    const { handleChange, step } = this.props;
    handleChange({
      ...step,
      location: {
        ...step.location,
        sameAsPreviousStep: checked,
      },
    });
  };

  render() {
    const {
      step,
      handleChange,
      stepServices,
      addService,
      removeService,
      isManualMode,
      userRole,
      updateService,
      updateDelegate,
    } = this.props;

    return (
      <div className="step">
        <div className="step__header">
          {renderStepDefaults(step, handleChange)}
          {renderLocation(step.location, commonMessages.location)}
          <CheckboxDelegate
            isDelegate={!!step.doneByAnotherFuneral}
            updateDelegate={updateDelegate}
          />
        </div>
        <div>
          <CheckboxLabel
            message={commonMessages.sameCrema}
            updateCheckbox={() =>
              this.onChange(!step.location.sameAsPreviousStep)
            }
            checkboxValue={step.location.sameAsPreviousStep}
          />
          {!step.location.sameAsPreviousStep &&
            renderEditLocation(step, handleChange, locationTypes)}
        </div>
        <Services
          stepId={step.id}
          services={stepServices}
          addService={addService}
          removeService={removeService}
          isManualMode={isManualMode}
          userRole={userRole}
          updateService={updateService}
        />
      </div>
    );
  }
}

Dispersion.propTypes = {
  /** step info */
  step: PropTypes.object.isRequired,
  /** function to update step info */
  handleChange: PropTypes.func.isRequired,
  /** list of services of this step */
  stepServices: PropTypes.array.isRequired,
  /** function to add a service */
  addService: PropTypes.func.isRequired,
  /** function to remove a service */
  removeService: PropTypes.func.isRequired,
  /** is the manual mode on */
  isManualMode: PropTypes.bool.isRequired,
  /** user role */
  userRole: PropTypes.number.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
  /** function to update is delegate */
  updateDelegate: PropTypes.func.isRequired,
};

export default Dispersion;
