import { FormattedMessage } from 'react-intl';

import { openDialog, closeDialog } from 'containers/DialogBox/actions';
import { setDealFields } from 'containers/Deal/slice.ts';
import { updateDeal } from '../actions';
import {
  GET_CREMATORIUM,
  SET_CREMATORIUM,
  GET_CEREMONIES,
  SET_CEREMONIES,
  SET_WORSHIP_TYPES,
  GET_CONCESSION_PRICES,
  SET_CONCESSION_PRICES,
  GET_CONCESSION_TYPES,
  SET_CONCESSION_TYPES,
  GET_FUNERAL_PARLOR_STAY_TYPES,
  SET_FUNERAL_PARLOR_STAY_TYPES,
  STEP_TBC,
  STEP_CASKETING,
} from '../constants';
import messages from '../messages';

import { stepSkeletons } from './skeletons';
import urnPickupMessages from './StepEditors/UrnPickup/messages';

const ADVITAM = 'advitam';
const FAMILY = 'family';
const FOREIGN_LAND = 'foreignLand';
const DISPERSION = 'dispersion';
const INTERMENT = 'interment';
const URN_SEALING = 'urnSealing';

export function getCrematorium(id) {
  return {
    type: GET_CREMATORIUM,
    id,
  };
}

export function setCrematorium(crematorium) {
  return {
    type: SET_CREMATORIUM,
    crematorium,
  };
}

export function getCeremonies(id, params) {
  return {
    type: GET_CEREMONIES,
    id,
    params,
  };
}

export function setCeremonies(ceremonies) {
  return {
    type: SET_CEREMONIES,
    ceremonies,
  };
}

/**
 * Sets the worship types received from the API.
 *
 * @param      {Object}  worshipTypes  The worship types fetched.
 * @return     {Object}  An action object asking the reducer to store the
 *                       worship types
 */
export function setWorshipTypes(worshipTypes) {
  return {
    type: SET_WORSHIP_TYPES,
    worshipTypes,
  };
}

/**
 * Gets the concession prices from the db for the Interment step.
 *
 * @param      {Number}  id      The graveyard identifier.
 * @return     {Object}  An action object asking a saga to get the concession
 *                       prices.
 */
export function getConcessionPrices(id) {
  return {
    type: GET_CONCESSION_PRICES,
    id,
  };
}

/**
 * Sets the concession prices received from the API.
 *
 * @param      {Object}  concessionPrices  The concession prices fetched.
 * @return     {Object}  An action object asking the reducer to store the
 *                       concession prices.
 */
export function setConcessionPrices(concessionPrices) {
  return {
    type: SET_CONCESSION_PRICES,
    concessionPrices,
  };
}

const removeStepUpdate = (stepId, steps) => {
  const newSteps = [...steps];
  newSteps.splice(stepId, 1);
  return newSteps.map((step, index) => ({ ...step, id: index }));
};

const removeStepUpdateServices = (stepId, services) =>
  services
    .filter(service => service.step_id !== stepId)
    .map(service =>
      service.step_id < stepId
        ? service
        : { ...service, step_id: service.step_id - 1 },
    );

function deleteStep(dispatch, stepId, steps, funeral) {
  const { services } = funeral;
  return function makeDeleteStep() {
    dispatch(
      updateDeal(
        {
          steps: removeStepUpdate(stepId, steps),
          services: removeStepUpdateServices(stepId, services),
        },
        funeral,
      ),
    );
    dispatch(closeDialog());
  };
}

export function openDeleteStepDialog(
  dispatch,
  stepId,
  stepEventType,
  steps,
  funeral,
) {
  const handleCloseDialog = () => dispatch(closeDialog());
  const stepCasketing = steps.find(step => step.eventType === STEP_CASKETING);
  const dialog = {
    headerMessage: messages.confirmDeleteStep,
    options: [
      { msg: messages.yes, cb: deleteStep(dispatch, stepId, steps, funeral) },
      { msg: messages.no, cb: handleCloseDialog },
    ],
    onRequestClose: handleCloseDialog,
  };
  /**
   * Display a message if we're deleting the step TBC
   * and if there is a tribunal authorization date
   */
  if (
    stepEventType === STEP_TBC &&
    stepCasketing &&
    stepCasketing.tribunal_authorization_date
  ) {
    dialog.children = (
      <FormattedMessage {...messages.warningTribunalAuthorizationDate} />
    );
  }
  return openDialog(dialog);
}

export const familyDestinations = [FAMILY, FOREIGN_LAND];
export const stepDestinations = [DISPERSION, INTERMENT, URN_SEALING];

export function getAshesDestination(steps) {
  const pickupStep = findStepByEventType('urnPickup', steps);
  if (!pickupStep) {
    return '';
  }

  switch (pickupStep.to) {
    case ADVITAM:
      return steps
        .map(s => s.eventType)
        .find(
          e =>
            e === stepDestinations[0] ||
            e === stepDestinations[1] ||
            e === stepDestinations[2],
        );
    case FAMILY:
      return pickupStep.foreignLand
        ? familyDestinations[1]
        : familyDestinations[0];
    default:
      return '';
  }
}

const pickupStepChange = destinationType => {
  switch (destinationType) {
    case DISPERSION:
    case INTERMENT:
    case URN_SEALING:
      return { to: ADVITAM };
    case FAMILY:
      return {
        to: FAMILY,
        foreignLand: false,
      };
    case FOREIGN_LAND:
      return { to: FAMILY, foreignLand: true };
    default:
      return {};
  }
};

function findStepByEventType(eventType, steps) {
  return steps.find(s => s.eventType === eventType);
}

const changeDestination = (
  handleUpdateDeal,
  pickupStep,
  steps,
  services,
  destinationType,
  handleCloseDialog,
) => () => {
  let stepsUpdate = [...steps];
  stepsUpdate[pickupStep.id] = {
    ...stepsUpdate[pickupStep.id],
    ...pickupStepChange(destinationType),
  };
  let servicesUpdate = [];

  // If old destination has a step, delete it
  if (pickupStep.to === ADVITAM) {
    const toDeleteStep =
      findStepByEventType(INTERMENT, steps) ||
      findStepByEventType(DISPERSION, steps) ||
      findStepByEventType(URN_SEALING, steps);
    if (toDeleteStep) {
      stepsUpdate = [...removeStepUpdate(toDeleteStep.id, stepsUpdate)];
      servicesUpdate = [
        ...servicesUpdate,
        ...removeStepUpdateServices(toDeleteStep.id, services),
      ];
    }
  }

  // Now set the new destination if it has a step linked
  if (stepDestinations.includes(destinationType)) {
    stepsUpdate = [
      ...stepsUpdate,
      { id: pickupStep.id + 1, ...stepSkeletons[destinationType] },
    ];
  }

  handleUpdateDeal({
    steps: stepsUpdate,
    services: servicesUpdate,
  });
  if (handleCloseDialog) handleCloseDialog();
};

export function setAshesDestination(dispatch, steps, destinationType, funeral) {
  const handleCloseDialog = () => dispatch(closeDialog());
  const handleUpdateDeal = update => dispatch(updateDeal(update, funeral));
  const pickupStep = findStepByEventType('urnPickup', steps);
  const currentAshesDestination = getAshesDestination(steps);
  const { services } = funeral;

  if (!pickupStep.to) {
    changeDestination(
      handleUpdateDeal,
      pickupStep,
      steps,
      services,
      destinationType,
    )();
  } else if (currentAshesDestination !== destinationType) {
    if (stepDestinations.includes(currentAshesDestination)) {
      dispatch(
        openDialog({
          headerMessage: urnPickupMessages.destinationChangeDialogHeader,
          children: (
            <FormattedMessage {...urnPickupMessages.destinationChangeDialog} />
          ),
          options: [
            {
              msg: messages.yes,
              cb: changeDestination(
                handleUpdateDeal,
                pickupStep,
                steps,
                services,
                destinationType,
                handleCloseDialog,
              ),
            },
            { msg: messages.no, cb: handleCloseDialog },
          ],
          onRequestClose: handleCloseDialog,
        }),
      );
    } else {
      changeDestination(
        handleUpdateDeal,
        pickupStep,
        steps,
        services,
        destinationType,
      )();
    }
  }
}

export function getConcessionTypes() {
  return {
    type: GET_CONCESSION_TYPES,
  };
}

export function setConcessionTypes(ConcessionTypes) {
  return {
    type: SET_CONCESSION_TYPES,
    ConcessionTypes,
  };
}

export function getFuneralParlorStayTypes(funeralParlorId) {
  return {
    type: GET_FUNERAL_PARLOR_STAY_TYPES,
    funeralParlorId,
  };
}

export function setFuneralParlorStayTypes(funeralParlorStayTypes) {
  return {
    type: SET_FUNERAL_PARLOR_STAY_TYPES,
    funeralParlorStayTypes,
  };
}

/**
 * Function to update a step
 *
 * @param {array} steps list of step
 * @param {number} stepToUpdateId id of step to update
 * @param {object} update value to update in step
 * @param {function} dealUpdate function to update deal
 */
export function updateStep(steps, stepToUpdateId, update, dealUpdate) {
  const updatedSteps = [...steps].map(step => {
    if (step.id !== stepToUpdateId) return step;
    return {
      ...step,
      ...update,
    };
  });
  return dealUpdate({
    steps: updatedSteps,
  });
}

export function updateDealFromSteps(update, stepToUpdate, steps, deal) {
  const newSteps = [...steps];
  const indexOfStepToUpdate = newSteps.findIndex(
    step => step.id === stepToUpdate.id,
  );

  newSteps[indexOfStepToUpdate] = {
    ...stepToUpdate,
    ...update,
  };

  return setDealFields({
    update: {
      funeral: {
        ...deal.funeral,
        steps: newSteps,
      },
    },
  });
}
