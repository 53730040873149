import { createAsyncThunk } from '@reduxjs/toolkit';

import { assert } from '@advitam/support';
import Api, { requestAsync } from 'api';

import { DOCUMENTS } from './constants.js';

export const fetchDocument = createAsyncThunk(
  `${DOCUMENTS}/FETCH_DOCUMENT`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await requestAsync(Api.V1.Deals.Documents.show(id));
      assert(body !== null);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
