import { updateDeal } from '../slice.ts';
import { GET_DEAL_MARBLE } from './constants';

/**
 * Get deal marble from DB
 *
 * @param {String} uuid uuid of deal marble
 * @returns {AnyAction}
 */
export function getDealMarble(deal) {
  return {
    type: GET_DEAL_MARBLE,
    deal,
  };
}

/**
 * Action to dispatch to update the deal marble in the reducer
 *
 * @param {Object} marble data of marble updated
 * @returns {Object}
 */
export function updateDealMarble(marble) {
  return updateDeal({ marble });
}

/**
 * Action to dispatch to update the concession in deal marble
 *
 * @param {Object} concession data of concession updated
 * @param {Object} marble data of marble
 * @returns {Object}
 */
export function setConcession(concession, marble) {
  return updateDeal({
    marble: {
      ...marble,
      graveyard: {
        ...marble.graveyard,
        concession,
      },
    },
  });
}
