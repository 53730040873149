import { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DealCommunication } from '@advitam/api/models/Deal/Communication';
import { CommunicationChannelType } from '@advitam/api/models/Deal/Communication/ChannelType';
import { assert } from '@advitam/support';
import { Button, Tooltip } from '@advitam/ui';
import Play from '@advitam/ui/images/icons/play.svg';
import Stop from '@advitam/ui/images/icons/stop.svg';

import messages from '../../messages';
import style from './Details.module.scss';

interface AudioProps {
  communication: DealCommunication;
}

export default function Audio({
  communication,
}: AudioProps): JSX.Element | null {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const audio = useRef<HTMLAudioElement>(null);

  const onClick = useCallback(async () => {
    assert(audio.current !== null);

    if (isPlaying && !isStopped) {
      setIsStopped(true);
      audio.current.pause();
      audio.current.currentTime = 0;
      setIsPlaying(false);
    } else if (isStopped && !isPlaying) {
      setIsPlaying(true);
      await audio.current.play();
      setIsStopped(false);
    }
  }, [audio, isPlaying, isStopped, setIsPlaying, setIsStopped]);

  const onEnded = useCallback(() => {
    setIsPlaying(false);
    setIsStopped(true);
  }, [setIsPlaying, setIsStopped]);

  if (
    communication.channel_type !== CommunicationChannelType.PHONE ||
    !communication.files.length
  ) {
    return null;
  }

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <Tooltip
      content={
        <FormattedMessage
          id={isPlaying ? messages.stop.id : messages.play.id}
        />
      }
    >
      <audio ref={audio} src={communication.files[0].link} onEnded={onEnded} />
      <Button
        className={style.button}
        outline
        icon={isPlaying ? <Stop /> : <Play />}
        onClick={onClick}
      />
    </Tooltip>
  );
  /* eslint-enable jsx-a11y/media-has-caption */
}
