import { Address as AddressUI } from '../UI/GooglePlace/types'
import { Address } from './types'

export function serialize(address: Address): AddressUI {
  return {
    street_name: address.address,
    street_number: null,
    postal_code: address.postal_code,
    city: address.city,
    country: address.country,
    latitude: address.latitude,
    longitude: address.longitude,
    country_code: null,
  }
}
