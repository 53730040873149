import messages from './messages'
import { ErrorCode, ErrorMessages } from './types'

export const ERROR_MESSAGES: ErrorMessages = {
  [ErrorCode.EMAIL_INVALID]: messages.emailInvalid,
  [ErrorCode.INVALID_IBAN_CHECK_DIGITS]: messages.invalidIbanCheckDigits,
  [ErrorCode.INVALID]: messages.invalid,
  [ErrorCode.PASSWORD_INVALID]: messages.passwordInvalid,
  [ErrorCode.REQUIRED]: messages.required,
}
