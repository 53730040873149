import { useSelector } from 'react-redux';

import { makeSelectUsers } from 'cables/Resource/selectors';
import Container from 'containers/App/Navbar/NavbarContents';

import UserBadge from './UserBadge';
import style from './NavbarUsers.module.scss';

export default function NavbarUsers(): JSX.Element | null {
  const users = useSelector(makeSelectUsers());
  if (!users) {
    return null;
  }

  return (
    <Container>
      <div className={style.users}>
        {Object.entries(users).map(([id]) => (
          <UserBadge key={id} userId={parseInt(id, 10)} />
        ))}
      </div>
    </Container>
  );
}
