import { Objects } from '@advitam/support'

import type { ClientCompanyJSON } from '../../../models/Client/Company'

interface ClientCompanyPayload {
  id: number
  enabled: boolean
}

interface ClientCompaniesPayload {
  companies: ClientCompanyPayload[]
}

export function serialize(companies: ClientCompanyJSON[]): ClientCompaniesPayload {
  return {
    companies: companies.map(company => Objects.pick(company, 'id', 'enabled')),
  }
}
