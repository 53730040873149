import type { ReactNode } from 'react'
import Frame from '../Frame'

import style from './Stripped.module.scss'

interface StrippedFrameProps {
  children: ReactNode | ReactNode[]
  className?: string
}

export default function StrippedFrame({
  children,
  className = '',
}: StrippedFrameProps): JSX.Element {
  return (
    <Frame darker className={[style.stripped, className].join(' ')}>
      {children}
    </Frame>
  )
}
