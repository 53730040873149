import { IntlShape, useIntl } from 'react-intl';

import { ClientResourceDeal } from 'models/Client/Resource/Deal';

import messages from './messages';

export function formatBusinessId(
  deal: ClientResourceDeal,
  intl: IntlShape,
): string {
  const businessId = deal.invoice_id || deal.business_id;
  return intl.formatMessage(messages.businessId, { businessId });
}

interface FormattedBusinessIdProps {
  deal: ClientResourceDeal;
}

export function FormattedBusinessId({
  deal,
}: FormattedBusinessIdProps): JSX.Element {
  const intl = useIntl();
  return <>{formatBusinessId(deal, intl)}</>;
}
