import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { ButtonV2 } from 'components/Button';

import style from './style.scss';

/* eslint-disable react/jsx-props-no-spreading */
export const ButtonsValidation = ({ className = '', ...props }) => (
  <div
    {...props}
    className={[className, style['validation-buttons']].join(' ')}
  />
);

ButtonsValidation.propTypes = {
  className: PropTypes.string,
};

export const Button = props => (
  <ButtonV2 {...props} className="button button--grey" />
);

export const ConfirmButton = props => <Button {...props} className="button" />;
/* eslint-enable react/jsx-props-no-spreading */

const styles = {
  overlay: {
    zIndex: '10',
  },
};

const CustomModal = ({ isOpen, onRequestClose, className = '', children }) => (
  <Modal
    contentLabel="dialog"
    style={styles}
    ariaHideApp={false}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    className={[style.content, className].join(' ')}
  >
    {children}
  </Modal>
);

CustomModal.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
};

export default CustomModal;
