import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

import { assert } from '@advitam/support'
import { DropdownPosition } from './types'
import style from './Dropdown.module.scss'

const classes = {
  [DropdownPosition.LEFT]: style.left,
  [DropdownPosition.CENTER]: style.center,
  [DropdownPosition.RIGHT]: style.right,
} as const

export function getClassForPosition(position: DropdownPosition): string {
  return classes[position]
}

export function useOpenState(
  keepOpen: boolean,
): [
  boolean,
  (v: boolean) => void,
  number | undefined,
  RefObject<HTMLDivElement>,
  RefObject<HTMLDivElement>,
] {
  const [isStateOpen, setIsStateOpen] = useState(keepOpen)
  const [height, setHeight] = useState(keepOpen ? undefined : 0)
  const containerRef = useRef<HTMLDivElement>(null)
  const childrenRef = useRef<HTMLDivElement>(null)

  const setIsOpen = useCallback(
    (value: boolean): void => {
      assert(childrenRef.current !== null && containerRef.current !== null)
      const childrenWidth = childrenRef.current.getBoundingClientRect().width
      const containerWidth = containerRef.current.getBoundingClientRect().width
      const offset = (childrenWidth - containerWidth) / 2
      childrenRef.current.style.setProperty('--dropdown-arrow-offset', `${offset}px`)
      setHeight(value || keepOpen ? childrenRef.current.offsetHeight : 0)
      setIsStateOpen(value || keepOpen)
    },
    [setIsStateOpen, setHeight, containerRef, childrenRef, keepOpen],
  )

  useEffect(() => {
    setIsOpen(keepOpen)
  }, [setIsOpen, keepOpen])

  return [isStateOpen, setIsOpen, height, containerRef, childrenRef]
}
