import { createSlice } from '@reduxjs/toolkit';
import { ApiError, SerializedApiError } from '@advitam/api';
import { FORGOTTEN_PASSWORD } from './constants';
import { requestPasswordReset } from './thunk';

export interface State {
  successMessage: string | null;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [FORGOTTEN_PASSWORD]: State;
}

export const initialState: State = {
  successMessage: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: FORGOTTEN_PASSWORD,
  initialState,
  reducers: {},
  /* eslint-disable no-param-reassign */
  extraReducers: builder => {
    builder.addCase(requestPasswordReset.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(requestPasswordReset.fulfilled, (state, { payload }) => {
      state.successMessage = payload;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(requestPasswordReset.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export default slice;
