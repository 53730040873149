import { defineMessages } from 'react-intl';

export default defineMessages({
  add: { id: 'app.containers.Crud.Documents.Editable.add' },

  newDocument: { id: 'app.containers.Crud.Documents.Editable.newDocument' },
  addDocumentFile: {
    id: 'app.containers.Crud.Documents.Editable.addDocumentFile',
  },
  changeDocumentFile: {
    id: 'app.containers.Crud.Documents.Editable.changeDocumentFile',
  },
  deleteDocument: {
    id: 'app.containers.Crud.Documents.Editable.deleteDocument',
  },

  search: { id: 'app.containers.Crud.Documents.Editable.search' },
  documentType: { id: 'app.containers.Crud.Documents.Editable.documentType' },
  documentTypeTooltip: {
    id: 'app.containers.Crud.Documents.Editable.documentTypeTooltip',
  },
  dealType: { id: 'app.containers.Crud.Documents.Editable.dealType' },
  dealTypeTooltip: {
    id: 'app.containers.Crud.Documents.Editable.dealTypeTooltip',
  },
  generationType: {
    id: 'app.containers.Crud.Documents.Editable.generationType',
  },
  generationTypeTooltip: {
    id: 'app.containers.Crud.Documents.Editable.generationTypeTooltip',
  },
  entityRule: {
    id: 'app.containers.Crud.Documents.Editable.entityRule',
  },
  entityRuleTooltip: {
    id: 'app.containers.Crud.Documents.Editable.entityRuleTooltip',
  },
  ruleSet: { id: 'app.containers.Crud.Documents.Editable.ruleSet' },
  ruleSetTooltip: {
    id: 'app.containers.Crud.Documents.Editable.ruleSetTooltip',
  },

  identical: { id: 'app.containers.Crud.Documents.Editable.identical' },
  different: { id: 'app.containers.Crud.Documents.Editable.different' },
  incomplete: { id: 'app.containers.Crud.Documents.Editable.incomplete' },

  bothDealType: { id: 'app.containers.Crud.Documents.Editable.bothDealType' },
  funeral: { id: 'app.containers.Crud.Documents.Editable.funeral' },
  marble: { id: 'app.containers.Crud.Documents.Editable.marble' },

  confirmDeletionText: {
    id: 'app.containers.Crud.Documents.Editable.confirmDeletionText',
  },
  confirmDeletion: {
    id: 'app.containers.Crud.Documents.Editable.confirmDeletion',
  },
  cancelDeletion: {
    id: 'app.containers.Crud.Documents.Editable.cancelDeletion',
  },
  saveBeforeEditing: {
    id: 'app.containers.Crud.Documents.Editable.saveBeforeEditing',
  },
});
