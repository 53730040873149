import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  billing: { id: 'app.containers.Cityhall.sections.billing' },
  copTax: { id: 'app.containers.Cityhall.sections.copTax' },

  deathCityhall: { id: 'app.containers.Cityhall.sections.deathCityhall' },
  cleaningCityhall: { id: 'app.containers.Cityhall.sections.cleaningCityhall' },
  cremationCityhall: {
    id: 'app.containers.Cityhall.sections.cremationCityhall',
  },
  inhumationCityhall: {
    id: 'app.containers.Cityhall.sections.inhumationCityhall',
  },
  closingCityhall: {
    id: 'app.containers.Cityhall.sections.closingCityhall',
  },
  endTacCityhall: {
    id: 'app.containers.Cityhall.sections.endTacCityhall',
  },
  startTacCityhall: {
    id: 'app.containers.Cityhall.sections.startTacCityhall',
  },
  endTbcCityhall: {
    id: 'app.containers.Cityhall.sections.endTbcCityhall',
  },
  startTbcCityhall: {
    id: 'app.containers.Cityhall.sections.startTbcCityhall',
  },
});
