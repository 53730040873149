import { useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormLayout, ModalTitle, Select } from '@advitam/ui';
import actionMessages from 'messages/actions';

import messages from './messages';
import GroupInputs from './parts/GroupInputs';
import Buttons from './parts/Buttons';
import Price from './parts/Price';
import BankTransferLabel from './parts/BankTransferLabel';
import { makeSelectHasInvoiceFields } from './selectors';
import type { PrestationForm } from './types';

export default function EditPrestationForm(): JSX.Element {
  const intl = useIntl();

  const { values } = useFormState<PrestationForm>();

  const hasInvoiceFields = useSelector(makeSelectHasInvoiceFields());

  const yesNoItems = [
    { name: intl.formatMessage(actionMessages.yes), value: true },
    { name: intl.formatMessage(actionMessages.no), value: false },
  ];

  return (
    <>
      <ModalTitle>
        <FormattedMessage id={messages.editPrestation.id} />
      </ModalTitle>
      <GroupInputs />
      <FormLayout.Row>
        <Select
          required
          label={<FormattedMessage id={messages.billedByEntity.id} />}
          tooltip={<FormattedMessage id={messages.billedByEntityTooltip.id} />}
          name="billedByEntity"
          items={yesNoItems}
          disabled={!values.billedByBob}
        />
        <Select
          required
          label={<FormattedMessage id={messages.billedByBob.id} />}
          tooltip={<FormattedMessage id={messages.billedByBobTooltip.id} />}
          name="billedByBob"
          items={yesNoItems}
          disabled={!values.billedByEntity}
        />
      </FormLayout.Row>
      <Price />
      {hasInvoiceFields && <BankTransferLabel />}
      <Buttons submitText={<FormattedMessage id={messages.submit.id} />} />
    </>
  );
}
