import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Logo from '@advitam/ui/images/logo.svg';
import MobileLogo from '@advitam/ui/images/mobile_logo.svg';
import Bell from '@advitam/ui/images/icons/bell.svg';
import BellFilled from '@advitam/ui/images/icons/bell-filled.svg';
import UserIcon from '@advitam/ui/images/icons/user.svg';
import {
  ButtonText,
  FlatButton,
  Link,
  Person,
  NavigationBase,
  MenuButton,
  MenuDropdown,
  MenuItem,
  MenuLink,
  MyAccountMenu,
  NotificationCount,
  Text,
} from '@advitam/ui';
import type { DropdownItemProps } from '@advitam/ui/components/Navigation/Menu/parts/Dropdown/Item';
import { makeSelectUser, logout } from 'slices/auth';
import { assert } from 'lib/Assert';

import messages from './messages';
import { Path, Url } from '../constants';

import {
  makeSelectIsNotificationPanelOpen,
  makeSelectUnreadNotificationCount,
} from '../NotificationCenter/selectors';
import { setIsNotificationPanelOpen } from '../NotificationCenter/slice';
import style from './style.scss';
import { CONTENT_CONTAINER_ID } from './constants';

export default function Navbar(): JSX.Element | null {
  const dispatch = useDispatch();

  const user = useSelector(makeSelectUser());
  assert(user !== null);
  const isPanelOpen = useSelector(makeSelectIsNotificationPanelOpen());
  const unreadCount = useSelector(makeSelectUnreadNotificationCount());

  const onLogout = useCallback((): void => {
    dispatch(logout());
  }, [dispatch]);

  const toggleIsPanelOpen = useCallback((): void => {
    dispatch(setIsNotificationPanelOpen(!isPanelOpen));
  }, [dispatch, isPanelOpen]);

  const onOpenMenu = useCallback((): void => {
    dispatch(setIsNotificationPanelOpen(false));
  }, [dispatch]);

  return (
    <NavigationBase
      small
      className={style.navbar}
      onOpenMenu={onOpenMenu}
      logo={{
        image: <Logo />,
        mobileImage: <MobileLogo />,
        internal: true,
        href: Path.INDEX,
      }}
      leftSection={
        [
          {
            displayOnDesktop: true,
            children: (
              <MenuDropdown
                className={style.dropdown}
                placeholder={<FormattedMessage id={messages.deals.id} />}
                items={[
                  {
                    internal: true,
                    href: Path.INDEX,
                    text: <FormattedMessage id={messages.deals.id} />,
                  },
                  {
                    internal: true,
                    href: Path.CLIENTS,
                    text: <FormattedMessage id={messages.clients.id} />,
                  },
                  {
                    internal: true,
                    href: Path.PAYMENTS,
                    text: <FormattedMessage id={messages.payments.id} />,
                  },
                ]}
              />
            ),
          },
          {
            displayOnDesktop: true,
            children: (
              <MenuDropdown
                className={style.dropdown}
                placeholder={<FormattedMessage id={messages.suppliers.id} />}
                items={
                  [
                    {
                      internal: true,
                      href: Path.SUPPLIERS,
                      text: <FormattedMessage id={messages.suppliers.id} />,
                    },
                    {
                      internal: true,
                      href: Path.FLIGHTS,
                      text: <FormattedMessage id={messages.flights.id} />,
                    },
                    {
                      internal: true,
                      href: Path.AGENCIES,
                      text: (
                        <FormattedMessage id={messages.fundingAgencies.id} />
                      ),
                    },
                  ].filter(Boolean) as DropdownItemProps[]
                }
              />
            ),
          },
          {
            displayOnDesktop: true,
            children: (
              <MenuDropdown
                className={style.dropdown}
                placeholder={<FormattedMessage id={messages.places.id} />}
                items={[
                  {
                    internal: true,
                    href: Path.ENTITIES,
                    text: <FormattedMessage id={messages.places.id} />,
                  },
                  {
                    internal: true,
                    href: Path.MAP,
                    text: <FormattedMessage id={messages.map.id} />,
                  },
                ]}
              />
            ),
          },
          user.isFuneralDirectorOrAbove && {
            displayOnDesktop: true,
            children: (
              <MenuDropdown
                className={style.dropdown}
                placeholder={<FormattedMessage id={messages.admin.id} />}
                items={
                  [
                    user.isAdmin && {
                      internal: true,
                      href: Path.BILLING,
                      text: <FormattedMessage id={messages.billing.id} />,
                    },
                    {
                      internal: false,
                      href: Url.ADVITAM_ADMIN,
                      text: <FormattedMessage id={messages.settings.id} />,
                    },
                  ].filter(Boolean) as DropdownItemProps[]
                }
              />
            ),
          },
        ].filter(Boolean) as MenuItem[]
      }
      rightSection={[
        {
          displayOnDesktop: true,
          displayOnTablet: true,
          displayOnMobile: false,
          hideInSidebar: true,
          children: (
            <div
              id={CONTENT_CONTAINER_ID}
              className={style.additional_content}
            />
          ),
        },
        {
          displayOnDesktop: true,
          displayOnTablet: true,
          displayOnMobile: true,
          hideInSidebar: true,
          children: (
            <FlatButton
              onClick={toggleIsPanelOpen}
              className={style.notifications}
            >
              {isPanelOpen ? <BellFilled /> : <Bell />}
              <NotificationCount count={unreadCount} />
            </FlatButton>
          ),
        },
        {
          displayOnDesktop: true,
          hideInSidebar: true,
          children: (
            <MyAccountMenu
              icon={<UserIcon />}
              menu={[
                <Text
                  small
                  tagName="div"
                  className={style.my_account__name}
                  key="name"
                >
                  <b>
                    <Person.FormattedName person={user} />
                  </b>
                </Text>,
                <Link internal href={Path.ACCOUNT} key="stats">
                  <FormattedMessage id={messages.stats.id} />
                </Link>,
                <button type="button" onClick={onLogout} key="logout">
                  <ButtonText small>
                    <FormattedMessage id={messages.logout.id} />
                  </ButtonText>
                </button>,
              ]}
            />
          ),
        },
        {
          children: [
            <MenuLink
              internal
              href={Path.ACCOUNT}
              text={<FormattedMessage id={messages.stats.id} />}
              key="account"
            />,
            <MenuButton
              onClick={onLogout}
              text={<FormattedMessage id={messages.logout.id} />}
              key="logout"
            />,
          ],
        },
      ]}
    />
  );
}
