import { DateTime } from 'luxon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { isApiError, request } from '@advitam/api';
import LegacyApi from 'api';
import type { ClientJSON } from '@advitam/api/models/Client';
import type { ClientAbilityJSON } from '@advitam/api/models/Client/Ability';
import { ClientRole } from '@advitam/api/models/Client/Role';
import { Model } from '@advitam/api/models/Model';
import { assert, isEqual, Objects } from '@advitam/support';
import type { DealJSON } from 'models/Deal';

import { makeSelectRawDeal } from '../../selectors.typed';
import type { AppStateSubset as DealAppStateSubset } from '../../slice';
import { DEAL_IDENTITY, EMAIL_TAKEN_ERROR } from './constants';
import type { AppStateSubset } from './slice';
import { makeSelectAbilities } from './selectors';
import { NewAbility, NewClient } from './types';

export const fetchAbilities = createAsyncThunk(
  `${DEAL_IDENTITY}/FETCH_ABILITIES`,
  async (deal: DealJSON, { rejectWithValue }) => {
    try {
      const { body } = await request<ClientAbilityJSON[], unknown>(
        LegacyApi.V1.Abilities.index(deal),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

async function updateOrCreateClient(
  client: ClientJSON | NewClient,
): Promise<ClientJSON> {
  if (!client.id) {
    const { body } = await request(Api.V1.Clients.create(client));
    return body;
  }

  const { body } = await request(
    Api.V1.Clients.update(Objects.omit(client, 'birth_date', 'birth_location')),
  );
  // TODO: Settings.throwOnInvalid = true;
  const now = DateTime.now().toISO();
  assert(now !== null);
  return {
    ...body,
    ...Objects.pick(client, 'department', 'latitude', 'longitude', 'companies'),
    updated_at: now,
  };
}

async function updateClient(
  ability: ClientAbilityJSON | NewAbility,
): Promise<ClientAbilityJSON | NewAbility> {
  const client = await updateOrCreateClient(ability.client);
  return { ...ability, client };
}

async function updateClients(
  abilities: ClientAbilityJSON[],
  values: Array<ClientAbilityJSON | NewAbility>,
  addFormError: (field: string, error: string) => void,
): Promise<Array<ClientAbilityJSON | NewAbility> | null> {
  let saveError: unknown;
  let hasSaveFailure = false;

  const result = await Promise.all(
    values.map(async (ability, index) => {
      if (
        Model.isDestroyed(ability) ||
        isEqual(ability.client, abilities[index]?.client)
      ) {
        return ability;
      }

      try {
        return await updateClient(ability);
      } catch (error) {
        if (
          !isApiError(error) ||
          !error.errorCodes.includes(EMAIL_TAKEN_ERROR)
        ) {
          saveError ||= error;
          return ability;
        }

        hasSaveFailure = true;
        addFormError(`abilities[${index}].client.email`, EMAIL_TAKEN_ERROR);
        return ability;
      }
    }),
  );

  if (saveError) {
    throw saveError;
  }

  if (hasSaveFailure) {
    return null;
  }

  return result;
}

async function createAbility(
  deal: DealJSON,
  ability: NewAbility,
): Promise<ClientAbilityJSON> {
  const { client } = ability;
  assert(deal.id !== undefined);
  assert(client.id !== undefined);

  // Creating a special ability or an ability with owner role will conflict
  // with database values. We need to create them as standard abilities before
  // updating their restricted values
  const role =
    ability.role === ClientRole.OWNER ? ClientRole.EDITOR : ability.role;
  const payload = { ...ability, client, special: false, role };
  const { body } = await request(
    Api.V1.Deals.Abilities.create(deal.id, payload),
  );

  return {
    ...body,
    role: ability.role,
    special: ability.special,
  };
}

async function updateOwner(
  deal: DealJSON,
  ability: ClientAbilityJSON,
): Promise<void> {
  assert(deal.id !== undefined);
  assert(ability.client.id !== undefined);
  await request(Api.V1.Deals.Owner.update(deal.id, ability.client.id));
}

async function updateEmpoweredClient(
  deal: DealJSON,
  ability: ClientAbilityJSON,
): Promise<void> {
  assert(deal.id !== undefined);
  assert(ability.client.id !== undefined);
  await request(
    Api.V1.Deals.EmpoweredClient.update(deal.id, ability.client.id),
  );
}

async function destroyClientAbility(ability: ClientAbilityJSON): Promise<void> {
  await request(Api.V1.Deals.Abilities.destroy(ability.id));
}

async function updateAbility(
  ability: ClientAbilityJSON,
): Promise<ClientAbilityJSON> {
  const { body } = await request(Api.V1.Deals.Abilities.update(ability));
  return body;
}

interface UpdateAbilitiesPayload {
  values: Array<ClientAbilityJSON | NewAbility>;
  addFormError: (field: string, error: string) => void;
}

export const updateAbilities = createAsyncThunk(
  `${DEAL_IDENTITY}/UPDATE_ABILITIES`,
  async (
    { values, addFormError }: UpdateAbilitiesPayload,
    { getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset & DealAppStateSubset;
    const abilities = makeSelectAbilities()(state);
    const deal = makeSelectRawDeal()(state);
    assert(deal !== null);

    try {
      let result = await updateClients(abilities, values, addFormError);
      if (!result) {
        return null;
      }

      result = await Promise.all(
        result.map(ability =>
          !Model.isDestroyed(ability) && ability.id === undefined
            ? createAbility(deal, ability)
            : ability,
        ),
      );

      const owner = result.find(c => c.role === ClientRole.OWNER);
      const currentOwner = abilities.find(c => c.role === ClientRole.OWNER);
      if (owner?.id && owner.id !== currentOwner?.id) {
        await updateOwner(deal, owner);
      }

      const empowered = result.find(c => c.special);
      const currentEmpowered = abilities.find(c => c.special);
      if (empowered?.id && empowered.id !== currentEmpowered?.id) {
        await updateEmpoweredClient(deal, empowered);
      }

      result = await Promise.all(
        result.map((ability, index) => {
          if (!abilities[index]) {
            // It just have been created with the right values
            return ability;
          }

          const isOwner = ability.role === ClientRole.OWNER;
          const abilityValues = ['link'] as const;
          const areValuesUpdated = !isEqual(
            Objects.pick(ability, ...abilityValues),
            Objects.pick(abilities[index], ...abilityValues),
          );
          const isRoleUpdated = ability.role !== abilities[index].role;
          const isUpdated = areValuesUpdated || (!isOwner && isRoleUpdated);

          if (Model.isDestroyed(ability) || !isUpdated) {
            return ability;
          }
          assert(ability.id !== undefined);
          return updateAbility(ability);
        }),
      );

      await Promise.all(
        result.map(async ability => {
          if (Model.isDestroyed(ability) && ability.id) {
            await destroyClientAbility(ability);
          }
        }),
      );

      return result.filter(
        ability => !Model.isDestroyed(ability),
      ) as ClientAbilityJSON[];
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
