import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  arrival: { id: 'app.containers.Flights.CreateModal.arrival' },
  company: { id: 'app.containers.Flights.CreateModal.company' },
  departure: { id: 'app.containers.Flights.CreateModal.departure' },
  submit: { id: 'app.containers.Flights.CreateModal.submit' },
  supplier: { id: 'app.containers.Flights.CreateModal.supplier' },
  title: { id: 'app.containers.Flights.CreateModal.title' },
});
