import { ReactNode } from 'react'
import Infos from './parts/Infos'
import Details from './parts/Details'
import Separator from './parts/Separator'
import style from './Step.module.scss'

interface DefaultStepProps {
  date: Date | null
  details: ReactNode | ReactNode[]
  icon: ReactNode
  title: ReactNode
}

export default function DefaultStep({ date, details, icon, title }: DefaultStepProps): JSX.Element {
  return (
    <div className={style.step}>
      <Infos date={date} />
      <Separator icon={icon} />
      <Details title={title} details={details} />
    </div>
  )
}
