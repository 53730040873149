import { PoliceJSON } from '@advitam/api/models/Police';
import { formatEntity } from '../Entity';

export function formatName(police: PoliceJSON): string {
  return formatEntity(police);
}

export function FormattedName(police: PoliceJSON): JSX.Element {
  return <>{formatName(police)}</>;
}
