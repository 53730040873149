import { ReactNode, useCallback } from 'react'

import Api from '@advitam/api'
import { getTokens } from '@advitam/api/lib/tokens'
import type { AutocompleteDealWithInvoiceResult } from '@advitam/api/v1/Autocompletes'

import ApiAutocomplete from './ApiAutocomplete'

interface DealWithInvoiceAutocompleteProps {
  placeholder?: string
  required?: boolean
  label?: ReactNode
  tooltip?: ReactNode
  name: string
  className?: string
  searchParams?: Record<string, string>
  disabled?: boolean
}

export default function DealWithInvoiceAutocomplete({
  placeholder,
  name,
  label,
  tooltip,
  required,
  searchParams,
  disabled,
  className,
}: DealWithInvoiceAutocompleteProps): JSX.Element {
  const getDisplayValue = useCallback(
    (data: AutocompleteDealWithInvoiceResult): string => data.description || '',
    [],
  )

  return (
    <ApiAutocomplete<AutocompleteDealWithInvoiceResult>
      placeholder={placeholder}
      name={name}
      label={label}
      tooltip={tooltip}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.deals.withInvoices)}
      required={required}
      className={className}
      disabled={disabled}
      getDisplayValue={getDisplayValue}
      keys={['description', 'uuid']}
      requestHeaders={{ ...getTokens() }}
      searchParams={searchParams}
      minCharsRequest={2}
    />
  )
}
