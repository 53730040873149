import { ReactNode } from 'react'

import Arrow from '../../images/icons/arrow-right.svg'
import Link from '../Link'
import Text from '../Text'
import style from './Resource.module.scss'

interface ResourceProps {
  href: string
  img: ReactNode
  title?: ReactNode
  description: ReactNode
  date: ReactNode
  className?: string
}

export default function Resource({
  href,
  img,
  title,
  description,
  date,
  className = '',
}: ResourceProps): JSX.Element {
  const classes = [style.resource]
  if (className) {
    classes.push(className)
  }

  return (
    <Link newTab href={href} className={classes.join(' ')}>
      <div className={style.image}>{img}</div>
      <div className={style.content}>
        {title && (
          <Text small>
            <b>{title}</b>
          </Text>
        )}
        <Text small className={style.description}>
          {description}
        </Text>
        <Text small className={style.date}>
          {date}
        </Text>
      </div>
      <Arrow className={style.arrow} />
    </Link>
  )
}
