import { useState } from 'react';
import PropTypes from 'prop-types';

import { TextArea } from 'components/Input';

import RelativeSpinner from 'components/Spinner/RelativeSpinner';

function SupplierComment({
  comment,
  supplierID,
  updateBookingComment,
  bookingCommentLoader,
  syncBookingComment,
}) {
  const [hasChanged, setHasChanged] = useState(false);
  const isCommentLoading = bookingCommentLoader.includes(supplierID);
  return (
    <div
      className={`booking__prestation__column__comment ${
        isCommentLoading ? 'booking__prestation__column__comment--loading' : ''
      }`}
    >
      {isCommentLoading && <RelativeSpinner />}
      <TextArea
        name="comment"
        disabled={isCommentLoading}
        value={comment || ''}
        onChange={({ target: { value } }) => {
          setHasChanged(true);
          syncBookingComment(supplierID, value);
        }}
        onBlur={() => {
          if (hasChanged === false) {
            return;
          }
          setHasChanged(false);
          updateBookingComment(supplierID, comment);
        }}
      />
    </div>
  );
}

SupplierComment.propTypes = {
  supplierID: PropTypes.number.isRequired,
  comment: PropTypes.string,
  updateBookingComment: PropTypes.func.isRequired,
  bookingCommentLoader: PropTypes.array.isRequired,
  syncBookingComment: PropTypes.func.isRequired,
};

export default SupplierComment;
