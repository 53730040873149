import { ReactNode, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, useFormState } from 'react-final-form';

import { Civility } from '@advitam/api/models/Person/Civility';
import {
  Button,
  Collapsible,
  FormLayout,
  Input,
  LinkButton,
  Person,
  PhoneInput,
  Select,
  Tooltip,
} from '@advitam/ui';
import Crown from '@advitam/ui/images/illustrations/crown.svg';
import { Path } from 'containers/App/constants';
import actionMessages from 'messages/actions';
import { ClientJSON, ClientRole } from 'models/Client';

import type { FuneralForm } from '../../../Funeral/types';
import { hasPhoneNumber, NO_PHONE_ERROR } from './validators/hasPhoneNumber';
import messages from './messages';
import style from './Form.module.scss';
import ClientEmailInput from './ClientEmailInput';
import ClientAddress from './ClientAddress';

function p(chunk: ReactNode): ReactNode {
  return <p>{chunk}</p>;
}

function ul(chunk: ReactNode): ReactNode {
  return <ul>{chunk}</ul>;
}

function li(chunk: ReactNode): ReactNode {
  return <li>{chunk}</li>;
}

interface ClientForm {
  abilities: ClientJSON[];
  [key: string]: ClientJSON[] | boolean;
}

function getRemoveButtonTooltipMessage(
  special: boolean | undefined,
  role: ClientRole | undefined,
): string {
  if (special) {
    return messages.removeSpecialTooltip.id;
  }
  if (role === ClientRole.OWNER) {
    return messages.removeOwnerTooltip.id;
  }
  return messages.removeTooltip.id;
}

interface ClientProps {
  index: number;
}

export default function Client({ index }: ClientProps): JSX.Element {
  const intl = useIntl();

  const form = useForm<ClientForm>();
  const { values } = useFormState<FuneralForm>();
  const ability = values.abilities[index];
  const abilityId = ability.id;

  const onRemove = useCallback(() => {
    if (abilityId) {
      form.change(`abilities[${index}]._destroy`, true);
    } else {
      const abilities = [...form.getState().values.abilities];
      abilities.splice(index, 1);
      form.change('abilities', abilities);
    }
  }, [index, abilityId, form]);

  return (
    <Collapsible
      title={
        <div className={style.section_title}>
          <b>
            <Person.FormattedName person={ability.client} />
          </b>
          {ability.special && (
            <Tooltip
              contentClassName={style.section_pictogram}
              content={<FormattedMessage id={messages.empoweredTooltip.id} />}
            >
              <Crown />
            </Tooltip>
          )}
        </div>
      }
      isOpen={!ability.id}
      className={style.fieldset}
    >
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.civility.id} />}
          name={`abilities[${index}].client.civility`}
          unselectable
          items={[
            { name: intl.formatMessage(messages.mr), value: Civility.MR },
            { name: intl.formatMessage(messages.mrs), value: Civility.MRS },
          ]}
        />
        <Input
          label={<FormattedMessage id={messages.firstname.id} />}
          name={`abilities[${index}].client.firstname`}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.lastname.id} />}
          name={`abilities[${index}].client.lastname`}
        />
        <Input
          label={<FormattedMessage id={messages.birthName.id} />}
          name={`abilities[${index}].client.birth_name`}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.linkWithDefunct.id} />}
          name={`abilities[${index}].link`}
        />
        <ClientAddress index={index} />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.postalCode.id} />}
          name={`abilities[${index}].client.postal_code`}
        />
        <Input
          label={<FormattedMessage id={messages.city.id} />}
          name={`abilities[${index}].client.city`}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <ClientEmailInput index={index} />
      </FormLayout.Row>
      <FormLayout.Row>
        <PhoneInput
          validate={hasPhoneNumber}
          errorMessages={{
            [NO_PHONE_ERROR]: messages.noPhoneError,
          }}
          label={<FormattedMessage id={messages.mobilePhone.id} />}
          name={`abilities[${index}].client.phone`}
          withCallButton
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <PhoneInput
          label={<FormattedMessage id={messages.homePhone.id} />}
          name={`abilities[${index}].client.phone_2`}
          withCallButton
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.empowered.id} />}
          tooltip={<FormattedMessage id={messages.empoweredTooltip.id} />}
          name={`abilities[${index}].special`}
          disabled={ability.special === true}
          items={[
            { name: intl.formatMessage(actionMessages.yes), value: true },
            { name: intl.formatMessage(actionMessages.no), value: false },
          ]}
        />
        <Select
          label={<FormattedMessage id={messages.role.id} />}
          tooltip={
            <div className={style.role_tooltip}>
              <FormattedMessage
                id={messages.roleTooltip.id}
                values={{ ul, li, p }}
              />
            </div>
          }
          name={`abilities[${index}].role`}
          disabled={ability.role === ClientRole.OWNER}
          items={[
            {
              name: intl.formatMessage(messages.owner),
              value: ClientRole.OWNER,
            },
            {
              name: intl.formatMessage(messages.editor),
              value: ClientRole.EDITOR,
            },
            {
              name: intl.formatMessage(messages.reader),
              value: ClientRole.READER,
            },
          ]}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Tooltip
          content={
            <FormattedMessage
              id={getRemoveButtonTooltipMessage(ability.special, ability.role)}
            />
          }
        >
          <Button
            outline
            text={<FormattedMessage id={messages.remove.id} />}
            className={style.client_button}
            disabled={ability.special || ability.role === ClientRole.OWNER}
            onClick={onRemove}
          />
        </Tooltip>
        {ability.client.id ? (
          <Tooltip
            content={<FormattedMessage id={messages.profileTooltip.id} />}
          >
            <LinkButton
              outline
              text={<FormattedMessage id={messages.profile.id} />}
              newTab
              href={Path.CLIENT(ability.client.id)}
              className={style.client_button}
            />
          </Tooltip>
        ) : (
          <div />
        )}
      </FormLayout.Row>
    </Collapsible>
  );
}
