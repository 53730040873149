import { FormattedMessage } from 'react-intl';

import Button, { SubmitButton } from 'components/Button';
import messagesAction from 'messages/actions';

import style from './style.scss';

interface ActionsProps {
  onReset: () => void;
}

export default function Actions({ onReset }: ActionsProps): JSX.Element {
  return (
    <div className={style.row}>
      <Button className="button--grey" onClick={onReset}>
        <FormattedMessage id={messagesAction.reset.id} />
      </Button>
      <SubmitButton>
        <FormattedMessage id={messagesAction.confirm.id} />
      </SubmitButton>
    </div>
  );
}
