import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: {
    id: 'app.containers.Client.sections.General.DeceasedModal.title',
  },
  text: {
    id: 'app.containers.Client.sections.General.DeceasedModal.text',
  },
  deathDate: {
    id: 'app.containers.Client.sections.General.DeceasedModal.deathDate',
  },
  actOfDeath: {
    id: 'app.containers.Client.sections.General.DeceasedModal.actOfDeath',
  },
  actOfDeathPlaceholder: {
    id:
      'app.containers.Client.sections.General.DeceasedModal.actOfDeathPlaceholder',
  },
  confirm: {
    id: 'app.containers.Client.sections.General.DeceasedModal.confirm',
  },
  cancel: {
    id: 'app.containers.Client.sections.General.DeceasedModal.cancel',
  },
});
