import { useEffect } from 'react';
import { Routes, Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import Helmet from 'react-helmet';
import { replace } from 'redux-first-history';
import type { History } from 'history';

import { deleteTokens } from '@advitam/api/lib/tokens';
import { setOnTokensExpiredCallback } from '@advitam/api/lib/onTokensExpired';
import { Role } from '@advitam/api/models/BusinessUser/Role';
import ProfModal from '@advitam/fams/src/prof/Modal';
import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { CssTheme, PageSpinner } from '@advitam/ui';

import DealsList from 'containers/DealsList';
import Deal from 'containers/Deal';
import Payments from 'containers/Payments';
import NotFoundPage from 'containers/NotFoundPage';
import { ForgottenPassword, Login, UpdatePassword } from 'containers/Auth';
import authSlice, {
  validateToken,
  makeSelectIsAuthenticated,
  makeSelectUser,
} from 'slices/auth';
import FundingAgencies from 'containers/FundingAgencies';
import Suppliers from 'containers/Suppliers';
import Account from 'containers/Account/index.js';
import Client, {
  Path as ClientPath,
  Sections as ClientSections,
} from 'containers/Client';
import Clients from 'containers/Clients/index';
import Crematorium, {
  Path as CrematoriumPath,
  Sections as CrematoriumSections,
} from 'containers/Crematorium';
import { Path as CrudPath } from 'containers/Crud';
import Map from 'containers/Map';
import Flights from 'containers/Flights';
import Entities from 'containers/Entities';
import Cityhall, {
  Path as CityhallPath,
  Sections as CityhallSections,
} from 'containers/Cityhall';
import Consulate, {
  Path as ConsulatePath,
  Sections as ConsulateSections,
} from 'containers/Consulate';
import FuneralParlor, {
  Path as FuneralParlorPath,
  Sections as FuneralParlorSections,
} from 'containers/FuneralParlor';
import Hospital, {
  Path as HospitalPath,
  Sections as HospitalSections,
} from 'containers/Hospital';
import Police, {
  Path as PolicePath,
  Sections as PoliceSections,
} from 'containers/Police';
import Prefecture, {
  Path as PrefecturePath,
  Sections as PrefectureSections,
} from 'containers/Prefecture';
import RegionalHealthAuthority, {
  Path as RegionalHealthAuthorityPath,
  Sections as RegionalHealthAuthoritySections,
} from 'containers/RegionalHealthAuthority';
import Worship, {
  Path as WorshipPath,
  Sections as WorshipSections,
} from 'containers/Worship';
import Flight, {
  Path as FlightPath,
  Sections as FlightSections,
} from 'containers/Flight';
import Graveyard, {
  Path as GraveyardPath,
  Sections as GraveyardSections,
} from 'containers/Graveyard';
import Supplier, {
  Path as SupplierPath,
  Sections as SupplierSections,
} from 'containers/Supplier';
import SupplierWarehouse, {
  Path as SupplierWarehousePath,
  Sections as SupplierWarehouseSections,
} from 'containers/Supplier/Warehouse';
import Billing from 'containers/Billing';
import ErrorModal from 'components/ErrorModal';

import { dataSlice, withDatasets } from 'slices/data';

import { Path, Route as AppRoute } from './constants';
import Favicon from './Favicon';
import Navbar from './Navbar';
import NewVersion from './NewVersion';
import NotificationCenter from './NotificationCenter';
import notificationsSlice from './NotificationCenter/slice';
import PrivatePage from './PrivatePage';
import useServiceWorker from './useServiceWorker';
import { makeSelectError, makeSelectIsLoading } from './selectors';
import slice from './slice';

export interface AppProps {
  history: History;
}

export function App({ history }: AppProps): JSX.Element {
  assert(process.env.CHATBOT_SLUG !== undefined);

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(makeSelectIsAuthenticated());
  const isLoading = useSelector(makeSelectIsLoading());
  const user = useSelector(makeSelectUser());
  const error = useSelector(makeSelectError());

  useServiceWorker(history);

  useEffect(() => {
    setOnTokensExpiredCallback(() => {
      deleteTokens();
      dispatch(replace(Path.LOGIN, { from: window.location.pathname }));
    });

    dispatch(validateToken());
  }, [dispatch]);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Helmet title="Advitam" />
      <Helmet>{CssTheme({})}</Helmet>
      <Helmet>
        <body className="old-design" />
      </Helmet>
      <Favicon />

      {error && <ErrorModal error={error} />}
      {user && isAuthenticated && (
        <>
          <NotificationCenter />
          <Navbar />
          <ProfModal chatbotSlug={process.env.CHATBOT_SLUG} />
        </>
      )}
      <NewVersion />
      <Routes>
        <Route
          path={Path.INDEX}
          element={
            <PrivatePage>
              <DealsList />
            </PrivatePage>
          }
        />
        <Route
          path={AppRoute.DEAL}
          element={
            <PrivatePage>
              <Deal />
            </PrivatePage>
          }
        />
        <Route
          path={Path.PAYMENTS}
          element={
            <PrivatePage>
              <Payments />
            </PrivatePage>
          }
        />
        <Route
          path={Path.AGENCIES}
          element={
            <PrivatePage>
              <FundingAgencies />
            </PrivatePage>
          }
        />
        <Route
          path={Path.SUPPLIERS}
          element={
            <PrivatePage>
              <Suppliers />
            </PrivatePage>
          }
        />
        <Route
          path={`${Path.SUPPLIERS}/${Path.NEW}`}
          element={
            <PrivatePage>
              <Supplier />
            </PrivatePage>
          }
        >
          <Route
            path={SupplierPath.GENERAL}
            element={<SupplierSections.General />}
          />
        </Route>
        <Route
          path={`${Path.SUPPLIERS}/:id`}
          element={
            <PrivatePage>
              <Supplier />
            </PrivatePage>
          }
        >
          <Route
            path={SupplierPath.GENERAL}
            element={<SupplierSections.General />}
          />
          <Route
            path={SupplierPath.DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <SupplierSections.Documents />
              </PrivatePage>
            }
          />
          <Route
            path={SupplierPath.MISCELLANEOUS}
            element={<SupplierSections.Misc />}
          />
          <Route
            path={SupplierPath.WAREHOUSES}
            element={<SupplierSections.Warehouses />}
          />
          <Route
            path={`${SupplierPath.WAREHOUSES}/:warehouseId`}
            element={<SupplierWarehouse />}
          >
            <Route
              path={SupplierWarehousePath.ZONES}
              element={<SupplierWarehouseSections.Zones />}
            />
            <Route
              path={`${SupplierWarehousePath.ZONES}/:zoneId`}
              element={<SupplierWarehouseSections.Zone />}
            />
            <Route
              path={SupplierWarehousePath.GENERAL}
              element={<SupplierWarehouseSections.General />}
            />
            <Route
              path={SupplierWarehousePath.MISCELLANEOUS}
              element={<SupplierWarehouseSections.Miscellaneous />}
            />
            <Route
              path={SupplierWarehousePath.PRODUCTS}
              element={<SupplierWarehouseSections.Products />}
            />
          </Route>
        </Route>
        <Route
          path={Path.ACCOUNT}
          element={
            <PrivatePage>
              <Account />
            </PrivatePage>
          }
        />
        <Route
          path={`${Path.CLIENTS}/:id`}
          element={
            <PrivatePage>
              <Client />
            </PrivatePage>
          }
        >
          <Route
            path={ClientPath.GENERAL}
            element={<ClientSections.General />}
          />
          <Route
            path={ClientPath.DOCUMENTS}
            element={<ClientSections.Documents />}
          />
          <Route
            path={ClientPath.RESOURCES}
            element={<ClientSections.Resources />}
          />
        </Route>
        <Route
          path={Path.CLIENTS}
          element={
            <PrivatePage>
              <Clients />
            </PrivatePage>
          }
        />
        <Route
          path={Path.ENTITIES}
          element={
            <PrivatePage>
              <Entities />
            </PrivatePage>
          }
        />

        <Route
          path={`${Path.CITYHALLS}/:id`}
          element={
            <PrivatePage>
              <Cityhall />
            </PrivatePage>
          }
        >
          <Route
            path={CityhallPath.GENERAL}
            element={<CityhallSections.General />}
          />
          <Route
            path={CityhallPath.MISCELLANEOUS}
            element={<CityhallSections.Miscellaneous />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <CityhallSections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <CityhallSections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<CityhallSections.OpeningHours />}
          />
        </Route>

        <Route
          path={`${Path.CONSULATES}/${Path.NEW}`}
          element={
            <PrivatePage>
              <Consulate />
            </PrivatePage>
          }
        >
          <Route
            path={ConsulatePath.GENERAL}
            element={<ConsulateSections.General />}
          />
        </Route>
        <Route
          path={`${Path.CONSULATES}/:id`}
          element={
            <PrivatePage>
              <Consulate />
            </PrivatePage>
          }
        >
          <Route
            path={ConsulatePath.GENERAL}
            element={<ConsulateSections.General />}
          />
          <Route
            path={ConsulatePath.MISCELLANEOUS}
            element={<ConsulateSections.Miscellaneous />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <ConsulateSections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <ConsulateSections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<ConsulateSections.OpeningHours />}
          />
        </Route>

        <Route
          path={`${Path.CREMATORIUMS}/${Path.NEW}`}
          element={
            <PrivatePage>
              <Crematorium />
            </PrivatePage>
          }
        >
          <Route
            path={CrematoriumPath.GENERAL}
            element={<CrematoriumSections.General />}
          />
        </Route>
        <Route
          path={`${Path.CREMATORIUMS}/:id`}
          element={
            <PrivatePage>
              <Crematorium />
            </PrivatePage>
          }
        >
          <Route
            path={CrematoriumPath.GENERAL}
            element={<CrematoriumSections.General />}
          />
          <Route
            path={CrematoriumPath.AGES}
            element={<CrematoriumSections.AgeRanges />}
          />
          <Route
            path={CrematoriumPath.CEREMONIES}
            element={<CrematoriumSections.Ceremonies />}
          />
          <Route
            path={CrematoriumPath.MISCELLANEOUS}
            element={<CrematoriumSections.Misc />}
          />
          <Route
            path={CrematoriumPath.WORSHIP}
            element={<CrematoriumSections.Worship />}
          />
          <Route
            path={CrematoriumPath.ROOMS}
            element={<CrematoriumSections.Rooms />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <CrematoriumSections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <CrematoriumSections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<CrematoriumSections.OpeningHours />}
          />
        </Route>

        <Route
          path={`${Path.FLIGHTS}/${Path.NEW}`}
          element={
            <PrivatePage>
              <Flight />
            </PrivatePage>
          }
        >
          <Route
            path={FlightPath.GENERAL}
            element={<FlightSections.General />}
          />
        </Route>
        <Route
          path={`${Path.FLIGHTS}/:id`}
          element={
            <PrivatePage>
              <Flight />
            </PrivatePage>
          }
        >
          <Route
            path={FlightPath.GENERAL}
            element={<FlightSections.General />}
          />
          <Route path={FlightPath.RATES} element={<FlightSections.Rates />} />
        </Route>

        <Route
          path={`${Path.GRAVEYARDS}/${Path.NEW}`}
          element={
            <PrivatePage>
              <Graveyard />
            </PrivatePage>
          }
        >
          <Route
            path={GraveyardPath.GENERAL}
            element={<GraveyardSections.General />}
          />
        </Route>
        <Route
          path={`${Path.GRAVEYARDS}/:id`}
          element={
            <PrivatePage>
              <Graveyard />
            </PrivatePage>
          }
        >
          <Route
            path={GraveyardPath.GENERAL}
            element={<GraveyardSections.General />}
          />
          <Route
            path={GraveyardPath.MISCELLANEOUS}
            element={<GraveyardSections.Misc />}
          />
          <Route
            path={GraveyardPath.CONCESSIONS}
            element={<GraveyardSections.Concessions />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <GraveyardSections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <GraveyardSections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<GraveyardSections.OpeningHours />}
          />
        </Route>

        <Route
          path={`${Path.FUNERAL_PARLORS}/${Path.NEW}`}
          element={
            <PrivatePage>
              <FuneralParlor />
            </PrivatePage>
          }
        >
          <Route
            path={FuneralParlorPath.GENERAL}
            element={<FuneralParlorSections.General />}
          />
        </Route>
        <Route
          path={`${Path.FUNERAL_PARLORS}/:id`}
          element={
            <PrivatePage>
              <FuneralParlor />
            </PrivatePage>
          }
        >
          <Route
            path={FuneralParlorPath.GENERAL}
            element={<FuneralParlorSections.General />}
          />
          <Route
            path={FuneralParlorPath.MISCELLANEOUS}
            element={<FuneralParlorSections.Miscellaneous />}
          />
          <Route
            path={FuneralParlorPath.STAYS}
            element={<FuneralParlorSections.Stays />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <FuneralParlorSections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <FuneralParlorSections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<FuneralParlorSections.OpeningHours />}
          />
        </Route>

        <Route
          path={`${Path.HOSPITALS}/${Path.NEW}`}
          element={
            <PrivatePage>
              <Hospital />
            </PrivatePage>
          }
        >
          <Route
            path={HospitalPath.GENERAL}
            element={<HospitalSections.General />}
          />
        </Route>
        <Route
          path={`${Path.HOSPITALS}/:id`}
          element={
            <PrivatePage>
              <Hospital />
            </PrivatePage>
          }
        >
          <Route
            path={HospitalPath.GENERAL}
            element={<HospitalSections.General />}
          />
          <Route
            path={HospitalPath.MISCELLANEOUS}
            element={<HospitalSections.Miscellaneous />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <HospitalSections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <HospitalSections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<HospitalSections.OpeningHours />}
          />
        </Route>

        <Route
          path={`${Path.POLICE}/${Path.NEW}`}
          element={
            <PrivatePage>
              <Police />
            </PrivatePage>
          }
        >
          <Route
            path={PolicePath.GENERAL}
            element={<PoliceSections.General />}
          />
        </Route>
        <Route
          path={`${Path.POLICE}/:id`}
          element={
            <PrivatePage>
              <Police />
            </PrivatePage>
          }
        >
          <Route
            path={PolicePath.GENERAL}
            element={<PoliceSections.General />}
          />
          <Route
            path={PolicePath.MISCELLANEOUS}
            element={<PoliceSections.Miscellaneous />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <PoliceSections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <PoliceSections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<PoliceSections.OpeningHours />}
          />
        </Route>

        <Route
          path={`${Path.PREFECTURES}/${Path.NEW}`}
          element={
            <PrivatePage>
              <Prefecture />
            </PrivatePage>
          }
        >
          <Route
            path={PrefecturePath.GENERAL}
            element={<PrefectureSections.General />}
          />
        </Route>
        <Route
          path={`${Path.PREFECTURES}/:id`}
          element={
            <PrivatePage>
              <Prefecture />
            </PrivatePage>
          }
        >
          <Route
            path={PrefecturePath.GENERAL}
            element={<PrefectureSections.General />}
          />
          <Route
            path={PrefecturePath.MISCELLANEOUS}
            element={<PrefectureSections.Miscellaneous />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <PrefectureSections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <PrefectureSections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<PrefectureSections.OpeningHours />}
          />
        </Route>

        <Route
          path={`${Path.RHAS}/${Path.NEW}`}
          element={
            <PrivatePage>
              <RegionalHealthAuthority />
            </PrivatePage>
          }
        >
          <Route
            path={RegionalHealthAuthorityPath.GENERAL}
            element={<RegionalHealthAuthoritySections.General />}
          />
        </Route>
        <Route
          path={`${Path.RHAS}/:id`}
          element={
            <PrivatePage>
              <RegionalHealthAuthority />
            </PrivatePage>
          }
        >
          <Route
            path={RegionalHealthAuthorityPath.GENERAL}
            element={<RegionalHealthAuthoritySections.General />}
          />
          <Route
            path={RegionalHealthAuthorityPath.MISCELLANEOUS}
            element={<RegionalHealthAuthoritySections.Miscellaneous />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <RegionalHealthAuthoritySections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <RegionalHealthAuthoritySections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<RegionalHealthAuthoritySections.OpeningHours />}
          />
        </Route>

        <Route
          path={`${Path.WORSHIPS}/${Path.NEW}`}
          element={
            <PrivatePage>
              <Worship />
            </PrivatePage>
          }
        >
          <Route
            path={WorshipPath.GENERAL}
            element={<WorshipSections.General />}
          />
        </Route>
        <Route
          path={`${Path.WORSHIPS}/:id`}
          element={
            <PrivatePage>
              <Worship />
            </PrivatePage>
          }
        >
          <Route
            path={WorshipPath.GENERAL}
            element={<WorshipSections.General />}
          />
          <Route
            path={WorshipPath.MISCELLANEOUS}
            element={<WorshipSections.Miscellaneous />}
          />
          <Route
            path={CrudPath.EDITABLE_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_DIRECTOR}>
                <WorshipSections.EditableDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.SUPPORTING_DOCUMENTS}
            element={
              <PrivatePage role={Role.FUNERAL_ADVISOR}>
                <WorshipSections.SupportingDocuments />
              </PrivatePage>
            }
          />
          <Route
            path={CrudPath.OPENING_HOURS}
            element={<WorshipSections.OpeningHours />}
          />
        </Route>

        <Route
          path={Path.FLIGHTS}
          element={
            <PrivatePage>
              <Flights />
            </PrivatePage>
          }
        />
        <Route
          path={`${Path.FLIGHTS}/:id`}
          element={
            <PrivatePage>
              <Flight />
            </PrivatePage>
          }
        />
        <Route
          path={Path.MAP}
          element={
            <PrivatePage>
              <Map />
            </PrivatePage>
          }
        />
        <Route
          path={Path.BILLING}
          element={
            <PrivatePage role={Role.ADMIN}>
              <Billing />
            </PrivatePage>
          }
        />
        <Route path={Path.LOGIN} element={<Login />} />
        <Route path={Path.FORGOTTEN_PASSWORD} element={<ForgottenPassword />} />
        <Route path={Path.UPDATE_PASSWORD} element={<UpdatePassword />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default compose<typeof App>(
  withSlice(slice, authSlice, dataSlice, notificationsSlice),
  withDatasets('departments', 'worshipTypes'),
)(App);
