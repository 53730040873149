import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { put } from 'api/methods';
import { AbilityBase } from 'models/Ability';
import { Client } from 'models/Client';
import { Deal, DealJSON } from 'models/Deal';
import { assert } from 'lib/Assert';

export default class Owner {
  @authenticate
  static update(
    deal: Deal | DealJSON,
    client: Client,
  ): ApiRequestDescriptor<AbilityBase> {
    assert(deal.id !== undefined);
    assert(client.id !== undefined);
    return put(`/api/v1/deals/${deal.id}/owner`, { client_id: client.id });
  }
}
