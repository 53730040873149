import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from 'lib/support';

import type { AppStateSubset } from '../../../slice';
import { makeSelectDeal } from '../../../selectors.typed';
import { DEAL } from '../../../constants';
import { refreshDeal } from '../../../thunk';
import { fetchFuneralDetails } from '../../../DealFuneral/actions.js';

export const updateBrand = createAsyncThunk(
  `${DEAL}/UPDATE_BRAND`,
  async (brandId: number, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      await request(Api.V1.Deals.Brand.update(deal.uuid, brandId));
    } catch (error) {
      return rejectWithValue(error);
    }

    await dispatch(refreshDeal(deal.uuid));
    dispatch(fetchFuneralDetails(deal));
    return undefined;
  },
);
