import { FormattedNumber } from 'react-intl'

interface FormattedPriceProps {
  value: number
}

export default function FormattedPrice({ value }: FormattedPriceProps): JSX.Element {
  return (
    <FormattedNumber
      style="currency"
      currency="EUR"
      value={value}
      maximumFractionDigits={Number.isInteger(value) ? 0 : undefined}
    />
  )
}
