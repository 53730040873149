import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaymentReceived } from '@advitam/api/models/Payment/Received';
import { assert, Objects } from '@advitam/support';
import Api, { ApiRequestDescriptor, requestAsync as request } from 'api';
import { Filters, SortOrder } from 'api/v1/Deals';
import { FilterSelection } from 'components/FilterBar';
import { OpsLogTag } from 'models/OpsLog';

import { DEALS_PER_PAGE, PAYMENTS } from './constants';
import type { AppStateSubset } from './slice';
import { makeSelectFilters, makeSelectPage } from './selectors';

interface PaymentsDataFilters extends Record<string, FilterSelection> {
  // eslint-disable-next-line camelcase
  deal_id_in: number[];
}

interface PaymentData {
  // eslint-disable-next-line camelcase
  deal_id: number;
}

type FilteredEndpoint<T> = (
  filters: Record<string, FilterSelection>,
) => ApiRequestDescriptor<T>;

async function fetchAll<T extends PaymentData>(
  inputFilters: PaymentsDataFilters,
  method: FilteredEndpoint<T[]>,
): Promise<Record<number, T[]>> {
  const filters = {
    ...inputFilters,
    page: 1,
    per_page: DEALS_PER_PAGE,
  };

  let data: T[] = [];
  let body = [];
  do {
    // eslint-disable-next-line no-await-in-loop
    const response = await request(method(filters));
    assert(response.body !== null);
    body = response.body;
    data = data.concat(body);
    filters.page += 1;
  } while (body.length === DEALS_PER_PAGE);

  const result: Record<number, T[]> = {};
  filters.deal_id_in.forEach(id => {
    result[id] = [];
  });
  data.forEach(item => {
    result[item.deal_id].push(item);
  });

  return result;
}

export const fetchDealsPayments = createAsyncThunk(
  `${PAYMENTS}/FETCH_DEALS_PAYMENTS`,
  async (dealIds: number[], { rejectWithValue }) => {
    const filters = {
      deal_id_in: dealIds,
    };

    try {
      const { Payments } = Api.V1.Deals;
      return fetchAll(filters, Payments.index.bind(Payments));
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchDealsOpsLogs = createAsyncThunk(
  `${PAYMENTS}/FETCH_DEALS_OPS_LOGS`,
  async (dealIds: number[], { rejectWithValue }) => {
    const filters = {
      deal_id_in: dealIds,
      tag_eq: OpsLogTag.PAYMENTS,
    };

    try {
      return fetchAll(filters, Api.V1.OpsLogs.index.bind(Api.V1.OpsLogs));
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchDeals = createAsyncThunk(
  `${PAYMENTS}/FETCH_DEALS`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const page = makeSelectPage()(state);
    const userFilters = makeSelectFilters()(state);

    try {
      const filters: Filters = {
        ...Objects.omit(userFilters, 'payment_received_cont'),
        sort: SortOrder.OLD_FIRST,
        page,
        per_page: DEALS_PER_PAGE,
      };
      if (userFilters.payment_received_cont) {
        if (userFilters.payment_received_cont === PaymentReceived.YES) {
          filters.payment_received_not_cont_all = [
            PaymentReceived.NO,
            PaymentReceived.LOST,
          ];
        } else {
          filters.payment_received_cont = userFilters.payment_received_cont;
        }
      }
      const { body } = await request(Api.V1.Deals.index(filters));
      assert(body !== null);

      const dealIds = body.map(({ id }) => id);
      /* eslint-disable @typescript-eslint/no-floating-promises */
      // We do not want to block the loading
      if (dealIds.length > 0) {
        dispatch(fetchDealsPayments(dealIds));
        dispatch(fetchDealsOpsLogs(dealIds));
      }
      /* eslint-enable @typescript-eslint/no-floating-promises */

      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
