export const enum ProductType {
  ACCESSORY = 'accessory',
  ANNOUNCEMENT = 'Announcement',
  BONES_BOX = 'Bones_box',
  COFFIN = 'Coffin',
  FLOWER = 'Flower',
  LETTER_SENDING = 'letter_sending',
  MATTRESS = 'Mattress',
  MEDALLION = 'medallion',
  MONUMENT = 'monument',
  PATTERN = 'pattern',
  PLATE = 'Plate',
  PRESS = 'Press',
  REPAT_BODY_BAG = 'repat_body_bag',
  SEMELLE = 'semelle',
  STONES = 'stones',
  SUPPORT_PILLOW = 'support_pillow',
  SYMBOL = 'Symbol',
  SYMBOL_GRAVEYARD = 'symbol_graveyard',
  TREE = 'tree',
  URN = 'Urn',
}
