import Citation from './Citation'
import Reviews from './Reviews'
import ReviewsV2 from './ReviewsV2'
import ReviewItem from './ReviewItem'
import ReviewItemV2 from './ReviewItemV2'

const Testimonials = {
  Citation,
  Reviews,
  ReviewsV2,
  ReviewItem,
  ReviewItemV2,
}

export default Testimonials
