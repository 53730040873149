import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Layout } from 'containers/Crud';
import { Coverage } from 'containers/Crud/Input';

import { saveRegionalHealthAuthority } from '../thunk';
import Wrapper from './Wrapper';
import { RegionalHealthAuthorityForm } from './types';
import messages from './messages';

function Miscellaneous(): JSX.Element {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: RegionalHealthAuthorityForm): void => {
      dispatch(saveRegionalHealthAuthority(values.regionalHealthAuthority));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <Layout.FieldsetGrid>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.managedPlaces.id} />}
            tooltip={<FormattedMessage id={messages.managedPlacesTooltip.id} />}
          >
            <Coverage name="regionalHealthAuthority.regional_health_authority_coverage" />
          </Layout.Fieldset>
        </Layout.FieldsetGrid>
      </Layout.Container>
    </Wrapper>
  );
}

export default Miscellaneous;
