import { Validator } from 'react-form';

import messages from './messages';

export function maxLength(max: number): Validator {
  return (value): string | false => {
    if (value && value.length > max) {
      return messages.tooLong.id;
    }
    return false;
  };
}
