import { isEqual } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { GraveyardConcessionJSON } from '@advitam/api/models/Graveyard/Concession';
import { GraveyardJSON } from '@advitam/api/models/Graveyard';

import { assert } from 'lib/support';

import { fetchConcessionInfos } from '../../concessionInfosSlice/thunk';
import { AppStateSubset as GraveyardAppStateSubset } from '../../slice';
import { makeSelectRawGraveyard } from '../../selectors';
import { AppStateSubset as ConcessionsAppStateSubset } from './slice';
import { GRAVEYARD_CONCESSIONS } from './constants';
import { makeSelectConcessions } from './selectors';
import { UnsavedConcession } from './types';

type AppStateSubset = GraveyardAppStateSubset & ConcessionsAppStateSubset;

export const fetchConcessions = createAsyncThunk(
  `${GRAVEYARD_CONCESSIONS}_FETCH`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const graveyard = makeSelectRawGraveyard()(state);
    assert(graveyard?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Graveyards.Concessions.index(graveyard.id),
      );
      return Object.values(body).flat();
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const initialFetch = createAsyncThunk(
  `${GRAVEYARD_CONCESSIONS}_INITIAL_FETCH`,
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(fetchConcessionInfos()),
      dispatch(fetchConcessions()),
    ]);
  },
);

async function saveConcession(
  concession: GraveyardConcessionJSON | UnsavedConcession,
  existingConcessions: GraveyardConcessionJSON[],
  graveyard: GraveyardJSON,
): Promise<GraveyardConcessionJSON> {
  if (concession.id === undefined) {
    const { body } = await request(
      Api.V1.Graveyards.Concessions.create(
        graveyard.id,
        concession as Omit<GraveyardConcessionJSON, 'id'>,
      ),
    );
    return body;
  }

  const existingConcession = existingConcessions.find(
    c => c.id === concession.id,
  );
  if (existingConcession && isEqual(concession, existingConcession)) {
    return existingConcession;
  }

  const { body } = await request(
    Api.V1.Graveyards.Concessions.update(concession),
  );
  return body;
}

export const saveConcessions = createAsyncThunk(
  `${GRAVEYARD_CONCESSIONS}_SAVE`,
  async (
    payload: Array<GraveyardConcessionJSON | UnsavedConcession>,
    { getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const existingConcessions = makeSelectConcessions()(state);
    const graveyard = makeSelectRawGraveyard()(state);
    assert(graveyard?.id !== undefined);

    try {
      const result = await Promise.all(
        payload.map(term =>
          saveConcession(term, existingConcessions, graveyard),
        ),
      );

      await Promise.all(
        existingConcessions.map(async concession => {
          const hasBeenRemoved = !payload.some(
            value => value.id === concession.id,
          );

          if (hasBeenRemoved) {
            await request(Api.V1.Graveyards.Concessions.destroy(concession.id));
          }
        }),
      );

      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
