import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  confirmPassword: { id: 'app.containers.Auth.UpdatePassword.confirmPassword' },
  newPassword: { id: 'app.containers.Auth.UpdatePassword.newPassword' },
  title: { id: 'app.containers.Auth.UpdatePassword.title' },
  tokenExpired: { id: 'app.containers.Auth.UpdatePassword.tokenExpired' },
  passwordNotEqual: {
    id: 'app.containers.Auth.UpdatePassword.passwordNotEqual',
  },
});
