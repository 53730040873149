import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  ConfirmationModal,
  ErrorText,
  FormattedApiError,
  Tooltip,
} from '@advitam/ui';
import CloneIcon from '@advitam/ui/images/icons/clone.svg';
import { withSlice } from '@advitam/react';

import {
  makeSelectError,
  makeSelectIsConfirmationModalOpen,
  makeSelectIsLoading,
} from './selectors';
import slice, { setIsConfirmationModalOpen } from './slice';
import { duplicateDeal } from './thunk';
import messages from './messages';
import style from './DuplicationButton.module.scss';

function DuplicationButton(): JSX.Element {
  const dispatch = useDispatch();

  const isConfirmationModalOpen = useSelector(
    makeSelectIsConfirmationModalOpen(),
  );
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const openConfirmationModal = useCallback(() => {
    dispatch(setIsConfirmationModalOpen(true));
  }, [dispatch]);

  const closeConfirmationModal = useCallback(() => {
    if (!isLoading) {
      dispatch(setIsConfirmationModalOpen(false));
    }
  }, [dispatch, isLoading]);

  const onConfirm = useCallback(() => {
    dispatch(duplicateDeal());
  }, [dispatch]);

  return (
    <div>
      <Tooltip content={<FormattedMessage id={messages.tooltip.id} />}>
        <Button
          outline
          onClick={openConfirmationModal}
          icon={<CloneIcon />}
          className={style.button}
        />
      </Tooltip>
      <ConfirmationModal
        className="new-design"
        isOpen={isConfirmationModalOpen}
        title={<FormattedMessage id={messages.title.id} />}
        text={
          <>
            <FormattedMessage id={messages.text.id} />
            {error && (
              <ErrorText>
                <FormattedApiError error={error} />
              </ErrorText>
            )}
          </>
        }
        cancel={<FormattedMessage id={messages.cancel.id} />}
        onCancel={closeConfirmationModal}
        confirm={<FormattedMessage id={messages.confirm.id} />}
        onConfirm={onConfirm}
        isConfirmationLoading={isLoading}
      />
    </div>
  );
}

export default withSlice(slice)(DuplicationButton);
