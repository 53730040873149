import {
  AutoAdditionFields,
  CenterType,
  UnsavedSupplierWarehouseZone,
} from './types';

export const SUPPLIER_WAREHOUSE_ZONES = 'SupplierWarehouse/Zones';

export const AUTO_ADDITION_SKELETON: AutoAdditionFields = {
  centerAddress: null,
  centerType: CenterType.WAREHOUSE,
  radius: null,
};

export const SUPPLIER_WAREHOUSE_ZONE_SKELETON: UnsavedSupplierWarehouseZone = {
  name: '',
  current_update_dt: null,
  current_update_user_id: null,
  last_update_dt: null,
  last_update_user_id: null,
};
