import { authenticate } from '../../lib/decorators'
import { del, put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { OngoingFormality, OngoingFormalityJSON } from '../../models/OngoingFormality'
import { serialize } from './serializers'
import { SupportingDocuments } from './SupportingDocuments'

export class OngoingFormalities {
  static readonly SupportingDocuments = SupportingDocuments

  @authenticate
  static update(formality: OngoingFormality): ApiRequestDescriptor<OngoingFormalityJSON> {
    return put(`/api/v1/ongoing_formalities/${formality.id}`, serialize(formality))
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/ongoing_formalities/${id}`)
  }
}
