import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormLayout, NumberInput } from '@advitam/ui';
import { Layout, messages as crudMessages } from 'containers/Crud';

import SupplierAutocomplete from '../../Form/SupplierAutocomplete';
import messages from '../../messages';
import { makeSelectCanEdit } from './selectors';

function p(chunk: ReactNode): ReactNode {
  return <p>{chunk}</p>;
}

export default function Miscellaneous(): JSX.Element {
  const canEdit = useSelector(makeSelectCanEdit());

  return (
    <Layout.Fieldset
      title={<FormattedMessage id={messages.miscellaneous.id} />}
    >
      <FormLayout.Row>
        <SupplierAutocomplete
          label={<FormattedMessage id={messages.supplier.id} />}
          disabled={!canEdit}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          label={<FormattedMessage id={messages.depositDays.id} />}
          tooltip={
            <FormattedMessage
              id={messages.depositDaysTooltip.id}
              values={{ p }}
            />
          }
          name="deposit_days"
          suffix={<FormattedMessage id={crudMessages.unitDay.id} />}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
