import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { SupportingDocumentOwnerType } from '@advitam/api/models/SupportingDocuments/OwnerType';
import type { SupportingDocumentJSON } from '@advitam/api/models/SupportingDocuments/Version';
import { assert, isEqual } from '@advitam/support';
import Layout from 'containers/Crud/Layout';
import {
  SupportingDocuments,
  supportingDocumentsSlice,
  makeSelectSupportingDocuments,
  saveSupportingDocuments,
} from 'containers/Crud';

import Wrapper from './Wrapper';
import { makeSelectRawSupplier } from '../selectors';
import { SupplierForm } from './types';
import { updateSupplierName } from '../thunk';

function DocumentsSection(): JSX.Element {
  const dispatch = useDispatch();

  const documents = useSelector(makeSelectSupportingDocuments());
  const supplier = useSelector(makeSelectRawSupplier());
  assert(supplier?.id !== undefined);

  const owner = useMemo(
    () => ({ type: SupportingDocumentOwnerType.SUPPLIER, id: supplier.id }),
    [supplier.id],
  );

  const onSubmit = useCallback(
    ({
      supplier: formSupplier,
      sectionValues,
    }: SupplierForm<SupportingDocumentJSON[]>) => {
      if (!isEqual(documents, sectionValues)) {
        dispatch(saveSupportingDocuments({ values: sectionValues, owner }));
      }

      if (!isEqual(supplier, formSupplier)) {
        dispatch(updateSupplierName(formSupplier.name));
      }
    },
    [dispatch, documents, owner, supplier],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={documents}>
      <Layout.Container>
        <SupportingDocuments owner={owner} />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(supportingDocumentsSlice)(DocumentsSection);
