import { isApiError, request } from '@advitam/api';
import { assert } from '@advitam/support';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Api from 'api';
import type { DealJSON } from 'models/Deal';

import { makeSelectRawDeal } from '../../../selectors.typed';
import { AppStateSubset as DealAppStateSubset } from '../../../slice';
import { AppStateSubset as DealReminderAppStateSubset } from './slice';
import { DEAL_REMINDER, NO_REMINDER_ERROR } from './constants';
import { makeSelectValue } from './selectors';

type AppStateSubset = DealAppStateSubset & DealReminderAppStateSubset;

export const createReminder = createAsyncThunk(
  `${DEAL_REMINDER}/CREATE`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectRawDeal()(state);
    const remindAt = makeSelectValue()(state);
    assert(deal?.id !== undefined && remindAt !== null);

    try {
      const { body } = await request<DealJSON, unknown>(
        Api.V1.Deals.Reminder.create(deal.id, remindAt),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const destroyReminder = createAsyncThunk(
  `${DEAL_REMINDER}/DESTROY`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectRawDeal()(state);
    assert(deal?.id !== undefined);

    try {
      await request(Api.V1.Deals.Reminder.destroy(deal.id));
      return undefined;
    } catch (error) {
      if (
        isApiError(error) &&
        error.status === 422 &&
        error.errorCodes.length === 1 &&
        error.errorCodes[0] === NO_REMINDER_ERROR
      ) {
        return undefined;
      }
      return rejectWithValue(error);
    }
  },
);
