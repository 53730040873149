import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import Api, { request } from '@advitam/api';
import type {
  FuneralParlorJSON,
  StayJSON,
} from '@advitam/api/models/FuneralParlor';
import { assert } from 'lib/Assert';

import { makeSelectRawFuneralParlor } from '../../selectors';
import type { AppStateSubset as FuneralParlorAppStateSubset } from '../../slice';
import { FUNERAL_PARLOR_STAYS } from './constants';
import { makeSelectStays } from './selectors';
import type { AppStateSubset as StaysAppStateSubset } from './slice';
import type { UnsavedStay } from './types';

type AppStateSubset = FuneralParlorAppStateSubset & StaysAppStateSubset;

export const fetchStays = createAsyncThunk(
  `${FUNERAL_PARLOR_STAYS}/FETCH`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const funeralParlor = makeSelectRawFuneralParlor()(state);
    assert(funeralParlor?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.FuneralParlors.Stays.index(funeralParlor.id),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

async function saveStay(
  stay: StayJSON | UnsavedStay,
  existingStays: StayJSON[],
  funeralParlor: FuneralParlorJSON,
): Promise<StayJSON> {
  if (stay.id === undefined) {
    const { body } = await request(
      Api.V1.FuneralParlors.Stays.create(stay, funeralParlor.id),
    );
    return body;
  }

  const existingStay = existingStays.find(s => s.id === stay.id);
  assert(existingStay !== undefined);
  if (isEqual(stay, existingStay)) {
    return existingStay;
  }

  const { body } = await request(Api.V1.FuneralParlors.Stays.update(stay));
  return body;
}

export const saveStays = createAsyncThunk(
  `${FUNERAL_PARLOR_STAYS}/SAVE`,
  async (payload: StayJSON[], { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const funeralParlor = makeSelectRawFuneralParlor()(state);
    const existingStays = makeSelectStays()(state);
    assert(funeralParlor?.id !== undefined);

    try {
      const result = await Promise.all(
        payload.map(stay => saveStay(stay, existingStays, funeralParlor)),
      );
      await Promise.all(
        existingStays.map(async stay => {
          if (!payload.some(value => value.id === stay.id)) {
            await request(Api.V1.FuneralParlors.Stays.destroy(stay.id));
          }
        }),
      );

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
