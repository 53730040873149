import { MediaFormats, MediaSupport, MediaType } from './Media'

export interface RoomJSON {
  id: number
  name: string
  media_types: MediaType[] | null
  media_supports: MediaSupport[] | null
  media_formats: MediaFormats[] | null
  capacity: number | null
}

export { MediaFormats, MediaSupport, MediaType }
