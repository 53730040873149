import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { difference } from 'lodash';

import { EntityType } from '@advitam/api/models/Entity/EntityType';
import { LocationType } from '@advitam/api/models/LocationType';
import { FormUI } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import { useThunkDispatch } from '@advitam/react';

import messages from '../messages';
import { fetchNearEntities } from '../thunk';
import {
  removeNearEntitiesByType,
  setActiveMarker,
  resetNearEntities,
  setEntityTypes,
} from '../slice';
import { makeSelectCityhall, makeSelectEntityTypes } from '../selectors';

export default function EntityTypesSelect(): JSX.Element {
  const intl = useIntl();
  const dispatch = useThunkDispatch();

  const cityhall = useSelector(makeSelectCityhall());
  const currentTypes = useSelector(makeSelectEntityTypes());

  const ENTITY_OPTIONS: SelectableItem<EntityType>[] = [
    {
      name: intl.formatMessage(messages.airport),
      value: LocationType.AIRPORT,
    },
    {
      name: intl.formatMessage(messages.regionalHealthAuthority),
      value: LocationType.REGIONAL_HEALTH_AUTHORITY,
    },
    {
      name: intl.formatMessage(messages.graveyard),
      value: LocationType.GRAVEYARD,
    },
    {
      name: intl.formatMessage(messages.consulate),
      value: LocationType.CONSULATE,
    },
    {
      name: intl.formatMessage(messages.crematorium),
      value: LocationType.CREMATORIUM,
    },
    {
      name: intl.formatMessage(messages.hospital),
      value: LocationType.HOSPITAL,
    },
    {
      name: intl.formatMessage(messages.worship),
      value: LocationType.WORSHIP,
    },
    {
      name: intl.formatMessage(messages.funeralParlor),
      value: LocationType.FUNERAL_PARLOR,
    },
    { name: intl.formatMessage(messages.police), value: LocationType.POLICE },
    {
      name: intl.formatMessage(messages.prefecture),
      value: LocationType.PREFECTURE,
    },
  ];

  const updateEntities = async (newTypes: EntityType[]): Promise<void> => {
    if (newTypes.length === 0) {
      dispatch(resetNearEntities());
      return;
    }

    if (newTypes.length < currentTypes.length) {
      const [removedType] = difference(currentTypes, newTypes);
      dispatch(removeNearEntitiesByType(removedType));
      return;
    }

    await dispatch(fetchNearEntities());
  };

  const onSelect = async (
    result?: EntityType[] | EntityType,
  ): Promise<void> => {
    if (!Array.isArray(result)) {
      return;
    }

    dispatch(setActiveMarker(null));
    dispatch(setEntityTypes(result));

    await updateEntities(result);
  };

  return (
    <FormUI.Select
      multiple
      value={currentTypes}
      label={intl.formatMessage(messages.places)}
      placeholder={intl.formatMessage(messages.places)}
      items={ENTITY_OPTIONS}
      onChange={onSelect}
      disabled={!cityhall}
    />
  );
}
