import type { ComponentClass } from 'react'
import BaseSpinner, { SpinnerProps as BaseSpinnerProps } from 'react-spinkit'

import style from './Spinner.module.scss'

// TS2604: JSX element type 'BaseSpinner' does not have any construct or call signatures.
// This should not be needed
const ReactSpinner = BaseSpinner as ComponentClass<BaseSpinnerProps>

export default function Spinner(): JSX.Element {
  return <ReactSpinner className={style.spinner} name="ball-clip-rotate" fadeIn="none" />
}
