import type { Action } from 'redux'
import type { ThunkDispatch } from 'redux-thunk'

import Api from '@advitam/api'

import { assert } from '@advitam/support'
import { newToken, setError, setRunId, setSources } from '../slice'
import type { ChatbotAnswerSource } from '../types'

type Dispatch = ThunkDispatch<unknown, unknown, Action<unknown>>

const enum ChatWebsocketMessageType {
  QUESTION = 'question',
  NEW_TOKEN = 'new_token',
  END = 'end',
  ERROR = 'error',
}
interface ChatNewTokenMessage {
  type: ChatWebsocketMessageType.NEW_TOKEN
  token: string
}

interface ChatEndMessage {
  type: ChatWebsocketMessageType.END
  // eslint-disable-next-line camelcase
  run_id: string
  sources: ChatbotAnswerSource[]
}

interface ChatErrorMessage {
  type: ChatWebsocketMessageType.ERROR
  errors: string[]
}

type ChatMessage = ChatNewTokenMessage | ChatEndMessage | ChatErrorMessage

export class ChatWebsocket {
  private dispatch: Dispatch

  private addSources: (sources: ChatbotAnswerSource[]) => void

  private websocket: WebSocket | null = null

  private isClosed = false

  private conversationUuid: string

  private resolve: (() => void) | null = null

  constructor(
    conversationUuid: string,
    dispatch: Dispatch,
    addSources?: (sources: ChatbotAnswerSource[]) => void,
  ) {
    this.dispatch = dispatch
    this.conversationUuid = conversationUuid
    this.addSources =
      addSources ||
      ((sources: ChatbotAnswerSource[]): void => {
        dispatch(setSources(sources))
      })
  }

  sendQuestion(question: string): Promise<void> {
    return new Promise(resolve => {
      assert(this.websocket !== null)
      this.resolve = resolve
      this.websocket.send(
        JSON.stringify({
          type: ChatWebsocketMessageType.QUESTION,
          question,
        }),
      )
    })
  }

  close(): void {
    this.isClosed = true
    if (this.websocket) {
      this.websocket.close()
    }
  }

  create(): Promise<void> {
    const requestDescriptor = Api.Chat.V1.Conversations.show(this.conversationUuid)
    const websocket = new WebSocket(requestDescriptor.endpoint.replace(/^http/, 'ws'))

    websocket.addEventListener('close', () => {
      if (this.isClosed) {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(this.create.bind(this), 500)
    })

    websocket.addEventListener('message', event => {
      let data = null
      try {
        data = JSON.parse(event.data as string) as ChatMessage
      } catch (e) {
        return
      }

      this.handleMessage(data)
    })

    return new Promise(resolve => {
      websocket.addEventListener('open', () => {
        if (this.isClosed) {
          websocket.close()
        } else {
          this.websocket = websocket
        }
        resolve()
      })
    })
  }

  private handleMessage(message: ChatMessage): void {
    switch (message.type) {
      case ChatWebsocketMessageType.NEW_TOKEN:
        this.dispatch(newToken(message.token))
        break
      case ChatWebsocketMessageType.END:
        if (message.sources.length > 0) {
          this.addSources(message.sources)
        }
        this.dispatch(setRunId(message.run_id))
        if (this.resolve) {
          this.resolve()
          this.resolve = null
        }
        break
      case ChatWebsocketMessageType.ERROR:
        this.dispatch(setError(message.errors))
        if (this.resolve) {
          this.resolve()
          this.resolve = null
        }
        break
      default:
        break
    }
  }
}
