import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Layout from 'containers/Crud/Layout';
import { OpeningHours } from 'containers/Crud';

import { saveFuneralParlor } from '../../thunk';
import type { FuneralParlorForm } from '../types';
import Wrapper from '../Wrapper';
import messages from './messages';

export default function OpeningHoursSection(): JSX.Element {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: FuneralParlorForm): void => {
      dispatch(saveFuneralParlor(values.funeralParlor));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <OpeningHours
          prefix="funeralParlor"
          tooltip={<FormattedMessage id={messages.tooltip.id} />}
        />
      </Layout.Container>
    </Wrapper>
  );
}
