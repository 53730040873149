import type { ReactNode } from 'react'

import buttonStyle from '../Button.module.scss'
import style from './Flat.module.scss'

interface FlatButtonProps {
  children: ReactNode
  disabled?: boolean
  onClick: () => void
  className?: string
}

export default function FlatButton({
  children,
  disabled = false,
  onClick,
  className = '',
}: FlatButtonProps): JSX.Element {
  const classes = [style.flat, disabled && buttonStyle.disabled, className].filter(Boolean)

  return (
    <button type="button" disabled={disabled} onClick={onClick} className={classes.join(' ')}>
      {children}
    </button>
  )
}
