import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-form';

import { DealPrestationComputeType } from 'models/Deal/Prestation/ComputeType';

import { Textarea } from 'components/ReactFormComponent';

import Actions from './Actions';
import messages from './messages';

interface EngravingProps {
  onReset: (compute: DealPrestationComputeType) => void;
}

export default function Engraving({ onReset }: EngravingProps): JSX.Element {
  const { values, setFieldValue } = useFormContext();

  useEffect(() => {
    if (values?.funeralWorks?.text) {
      const amount = values.funeralWorks.text.replace(/[\t\s]/g, '').length;
      setFieldValue('amount', amount);
    }
  }, [values?.funeralWorks?.text]);

  return (
    <>
      <FormattedMessage id={messages.engravingText.id} />
      <Textarea field="funeralWorks.text" />
      <Actions
        onReset={(): void => onReset(DealPrestationComputeType.MANUAL)}
      />
    </>
  );
}
