import type { IntlShape } from 'react-intl';

import type { NotificationJSON } from '@advitam/api/models/Notification';

import Question from './icons/question.svg';
import QuestionStandalone from './icons/question.standalone.png.bin';
import type { TileProps } from './TileProps';
import messages from './messages';

export default function getUnsupportedNotifiactionProps(
  notification: NotificationJSON,
  intl: IntlShape,
): TileProps {
  return {
    link: '#',
    IconComponent: Question,
    icon: QuestionStandalone,
    title: intl.formatMessage(messages.unsupportedNotificationTitle),
    body: intl.formatMessage(messages.unsupportedNotificationText, {
      action: notification.action,
    }),
  };
}
