import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Setting from 'models/Deal/Setting';
import { Service } from 'models/Deal/Service';

import { makeSelectUser } from 'slices/auth';
import {
  makeSelectIsManualMode,
  makeSelectWishes,
} from 'containers/Deal/selectors.js';
import Services from 'containers/Deal/DealFuneral/StepsSection/Services/index.js';
import CheckboxWishes from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxWishes.js';
import { renderStepDefaults } from 'containers/Deal/DealFuneral/StepsSection/StepEditors/stepEditorsUtils.js';
import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate.js';
import messagesWishes from 'messages/wishes.js';

import {
  makeSelectCoffinWeight,
  makeSelectDefunctWeight,
} from '../../selectors.js';
import Destination from './Destination';
import Trip from './Trip';
import TypeSelect from './TypeSelect';
import messages from './messages';
import { FlightWeights, RepatriationStep } from './types';
import { TRIP_SKELETON } from './constants';

interface RepatriationProps {
  step: RepatriationStep;
  stepServices: Record<string, unknown>[];
  addService: (value: Record<string, unknown>) => void;
  findWish: (value: string) => Setting | undefined;
  handleChange: (value: Partial<RepatriationStep>) => void;
  updateDelegate: (value: boolean) => void;
  updateService: (value: Record<string, unknown>) => void;
  updateWish: (type: string, value: boolean) => void;
  removeService: (service: Service) => void;
}

export default function Repatriation({
  stepServices,
  addService,
  removeService,
  findWish,
  updateWish,
  step,
  handleChange,
  updateDelegate,
  updateService,
}: RepatriationProps): JSX.Element {
  const user = useSelector(makeSelectUser());
  const isManualMode = useSelector(makeSelectIsManualMode());
  const wishes = useSelector(makeSelectWishes());
  const defunctWeight = useSelector(makeSelectDefunctWeight());
  const coffinWeight = useSelector(makeSelectCoffinWeight());

  const weights: FlightWeights = {
    defunct_weight: defunctWeight,
    coffin_weight: coffinWeight,
  };

  return (
    <div className="stepRepatriation">
      <div className="step__header">
        {renderStepDefaults(step, handleChange)}
        <CheckboxDelegate
          isDelegate={!!step.doneByAnotherFuneral}
          updateDelegate={updateDelegate}
        />
      </div>
      <TypeSelect
        value={step.repatriation_type}
        onChange={(value): void =>
          handleChange({
            location: null,
            destination: {
              city: null,
              country: null,
              countryCode: null,
            },
            repatriation_type: value,
            trip: {
              ...TRIP_SKELETON,
              contact: step.trip?.contact || null,
              interment_place: step.trip?.interment_place || null,
            },
          })
        }
      />
      <div className="stepRepatriation__info">
        <div>
          <CheckboxWishes
            wishType="want_ceremony_master"
            message={messagesWishes.ceremonyMaster}
            wishes={wishes}
            findWish={findWish}
            updateWish={updateWish}
          />
          <div>
            <FormattedMessage id={messages.search.id} />
            <br />
            <Destination
              step={step}
              weights={weights}
              onChange={handleChange}
            />
          </div>
        </div>
        {step.trip && (
          <div className="stepRepatriation__info__flight">
            <Trip step={step} handleChange={handleChange} />
          </div>
        )}
      </div>
      <Services
        stepId={step.id}
        services={stepServices}
        addService={addService}
        removeService={removeService}
        isManualMode={isManualMode}
        userRole={user?.role}
        updateService={updateService}
      />
    </div>
  );
}
