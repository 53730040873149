import type { ReactNode, ReactElement } from 'react'

import { ButtonStyleProps, getClassList } from './Button'
import Contents from './parts/Contents'

interface LinkButtonProps extends ButtonStyleProps {
  text?: ReactNode
  icon?: ReactElement | string
}

export default function ButtonLike({ text, icon, ...styleProps }: LinkButtonProps): JSX.Element {
  const classes = getClassList(styleProps)
  const { outline } = styleProps

  return (
    <span className={classes.join(' ')}>
      <Contents outline={outline} icon={icon} text={text} />
    </span>
  )
}
