// TODO: It should be dynamically imported, but this breaks backoffice's build
import { ResizeObserver as Polyfill } from '@juggle/resize-observer'

// Should be async for dynamic import
// eslint-disable-next-line @typescript-eslint/require-await
export async function getResizeObserver(callback: ResizeObserverCallback): Promise<ResizeObserver> {
  if ('ResizeObserver' in window === false) {
    const w = window as { ResizeObserver: typeof ResizeObserver }
    w.ResizeObserver = Polyfill
  }

  // Handled by the polyfill above
  // eslint-disable-next-line compat/compat
  return new ResizeObserver(callback)
}
