import Api from '@advitam/api';
import { EntityInvoiceEntityType } from '@advitam/api/models/EntityInvoice/Entity/Type';

export const BILLING = 'Billing';

export const ENTITY_ENDPOINTS: Record<EntityInvoiceEntityType, () => string> = {
  [EntityInvoiceEntityType.CITYHALL]: () => Api.V1.Autocompletes.Path.cityhalls,
  [EntityInvoiceEntityType.CONSULATE]: () =>
    Api.V1.Autocompletes.Path.consulates,
  [EntityInvoiceEntityType.CREMATORIUM]: () =>
    Api.V1.Autocompletes.Path.crematoriums,
  [EntityInvoiceEntityType.FUNERAL_PARLOR]: () =>
    Api.V1.Autocompletes.Path.funeralParlors,
  [EntityInvoiceEntityType.GRAVEYARD]: () =>
    Api.V1.Autocompletes.Path.graveyards,
  [EntityInvoiceEntityType.HOSPITAL]: () => Api.V1.Autocompletes.Path.hospitals,
  [EntityInvoiceEntityType.POLICE]: () => Api.V1.Autocompletes.Path.police,
  [EntityInvoiceEntityType.PREFECTURE]: () =>
    Api.V1.Autocompletes.Path.prefectures,
  [EntityInvoiceEntityType.RHA]: () => Api.V1.Autocompletes.Path.rha,
  [EntityInvoiceEntityType.SUPPLIER]: () => Api.V1.Autocompletes.Path.suppliers,
  [EntityInvoiceEntityType.WORSHIP]: () => Api.V1.Autocompletes.Path.worships,
};
