import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, useForm, useFormState } from 'react-final-form';

import { useThunkDispatch, withSlice } from '@advitam/react';
import { PolymorphicEntity } from '@advitam/api/models/PolymorphicEntity';
import { assert } from '@advitam/support';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import {
  Button,
  ConfirmationModal,
  FormattedApiError,
  PageSpinner,
  Text,
} from '@advitam/ui';
import { FullscreenModal } from 'components/Modal';
import { addCollectionItem } from 'containers/Crud';
import documentTemplateEditorSlice from 'containers/DocumentTemplateEditor/slice';

import Layout from '../../Layout';
import Document from './Document';
import DocumentTemplateEditor from './DocumentTemplateEditor';
import slice from './slice';
import { fetchDocuments } from './thunk';
import { makeSelectIsLoading, makeSelectError } from './selectors';
import messages from './messages';
import { editableDocumentSkeleton } from './constants';
import type { EditableDocumentsForm } from './types';
import style from './Editable.module.scss';

interface DocumentsProps {
  entity: PolymorphicEntity;
  entityRules: SelectableItem<string>[];
}

function Documents({ entity, entityRules }: DocumentsProps): JSX.Element {
  const dispatch = useThunkDispatch();

  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const [indexOfEditedTemplate, setIndexOfEditedTemplate] = useState<
    number | null
  >(null);
  const [indexOfDocumentToDelete, setIndexOfDocumentToDelete] = useState<
    number | null
  >(null);
  const gridContainer = useRef<HTMLDivElement>(null);

  const form = useForm();
  const { values } = useFormState<EditableDocumentsForm>();
  const documents = values.sectionValues;
  const editedDocument =
    indexOfEditedTemplate === null ? null : documents[indexOfEditedTemplate];

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(fetchDocuments(entity));
  }, []);

  const deleteDocument = useCallback((): void => {
    if (indexOfDocumentToDelete === null) {
      return;
    }

    form.change(
      'sectionValues',
      documents.filter((_d, i) => i !== indexOfDocumentToDelete),
    );

    setIndexOfDocumentToDelete(null);
  }, [documents, indexOfDocumentToDelete, form, setIndexOfDocumentToDelete]);

  const createDocument = useCallback((): void => {
    assert(gridContainer.current !== null);
    addCollectionItem(
      form,
      'sectionValues',
      editableDocumentSkeleton(entityRules[0].value),
      gridContainer.current,
    );
  }, [gridContainer, entityRules, form]);

  const onCloseDocumentTemplateEditor = useCallback(() => {
    setIndexOfEditedTemplate(null);
  }, [setIndexOfEditedTemplate]);

  if (isLoading) {
    return <PageSpinner />;
  }

  if (error) {
    return (
      <Text tagName="p" className={style.error}>
        <FormattedApiError error={error} />
      </Text>
    );
  }

  return (
    <>
      <Layout.ColumnGroup>
        <Button
          primary
          onClick={createDocument}
          text={<FormattedMessage id={messages.add.id} />}
          className={style.add_button}
        />
      </Layout.ColumnGroup>

      <Layout.FieldsetGrid ref={gridContainer}>
        <Field name="sectionValues">
          {(): null =>
            // Declare sectionValues as a field so final-form understands
            // removal from the array as form edits
            null
          }
        </Field>
        {documents.map((doc, index) => (
          <Document
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            prefix={`sectionValues[${index}]`}
            entityRules={entityRules}
            onDelete={(): void => setIndexOfDocumentToDelete(index)}
            onEditTemplate={(): void => {
              if (doc.id) {
                setIndexOfEditedTemplate(index);
              }
            }}
          />
        ))}
      </Layout.FieldsetGrid>

      <FullscreenModal isOpen={editedDocument !== null}>
        <DocumentTemplateEditor
          document={editedDocument}
          onClose={onCloseDocumentTemplateEditor}
        />
      </FullscreenModal>

      {indexOfDocumentToDelete !== null && (
        <ConfirmationModal
          isOpen
          text={
            <FormattedMessage
              tagName="p"
              id={messages.confirmDeletionText.id}
              values={{
                documentName:
                  documents[indexOfDocumentToDelete].document_type?.pretty_name,
              }}
            />
          }
          cancel={<FormattedMessage id={messages.cancelDeletion.id} />}
          onCancel={(): void => setIndexOfDocumentToDelete(null)}
          confirm={<FormattedMessage id={messages.confirmDeletion.id} />}
          onConfirm={deleteDocument}
        />
      )}
    </>
  );
}

export default withSlice<DocumentsProps>(documentTemplateEditorSlice)(
  Documents,
);
export { slice };
export { makeSelectEditableDocuments } from './selectors';
export { saveDocuments as saveEditableDocuments } from './thunk';
