import { stringToHexColor } from 'lib/support';

const NOT_NEGOCIATED_KEY = 'not_negociated_';

export function getPrestationColor(prestationKey: string): string {
  return stringToHexColor(prestationKey.replace(NOT_NEGOCIATED_KEY, ''));
}

export function isNegociatedKey(key: string): boolean {
  return !key.includes(NOT_NEGOCIATED_KEY);
}
