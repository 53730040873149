import { defineMessages } from 'react-intl';

export default defineMessages({
  ceremonyMaster: {
    id: 'app.messages.wishes.ceremonyMaster',
  },
  buyConcessionFamily: {
    id: 'app.messages.wishes.buyConcessionFamily',
  },
});
