/*
 *  Ceremony step editor
 */

import PropTypes from 'prop-types';

import { useState, useEffect } from 'react';
import AutoComplete from 'containers/AutoComplete';
import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';
import { Select } from 'components/Input/SelectList';
import { WANT_FAMILY_TO_PAY_CEREMONY } from 'utils/constants';

import Services from '../Services';
import { renderStepDefaults, renderLocation } from './stepEditorsUtils';
import messages from './messages';
import CheckboxWishes from './CheckboxWishes';

const TYPE = 'Worship';

const Ceremony = ({
  step,
  handleChange,
  worshipTypes,
  wishes,
  findWish,
  updateWish,
  stepServices,
  addService,
  removeService,
  updateDelegate,
  isManualMode,
  userRole,
  updateService,
}) => {
  const [worshipTypeId, setWorshipTypeId] = useState(
    step && step.worship_type_id,
  );

  useEffect(() => {
    if (step && (worshipTypeId === null || worshipTypeId === undefined)) {
      setWorshipTypeId(worshipTypes[0].id);
    }
  }, [worshipTypes]);

  const setResponse = (id, name) => {
    handleChange({
      ...step,
      worship_type_id: worshipTypeId,
      location: { ...step.location, id, name, type: TYPE },
    });
  };

  return (
    <div className="step">
      <div className="step__header">
        {renderStepDefaults(step, handleChange)}
        {renderLocation(step.location, messages.location)}
        <CheckboxDelegate
          isDelegate={!!step.doneByAnotherFuneral}
          updateDelegate={updateDelegate}
        />
      </div>
      {worshipTypes && (
        <>
          <AutoComplete
            url={`/api/v1/autocompletes/worships?type_eq=${worshipTypeId}&q=`}
            getKey={item => item.id}
            getValue={item => item.description}
            onSelect={(_, item) => setResponse(item.id, item.description)}
            auth
          />
          <Select
            onChange={({ target: { value } }) => setWorshipTypeId(value)}
            value={worshipTypeId}
          >
            {worshipTypes
              .filter(type => type.need_location)
              .map(type => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
          </Select>
        </>
      )}
      <CheckboxWishes
        wishType={WANT_FAMILY_TO_PAY_CEREMONY}
        message={messages.wantPayCeremony}
        wishes={wishes}
        findWish={findWish}
        updateWish={updateWish}
      />
      <Services
        stepId={step.id}
        services={stepServices}
        addService={addService}
        updateService={updateService}
        removeService={removeService}
        isManualMode={isManualMode}
        userRole={userRole}
      />
    </div>
  );
};

Ceremony.propTypes = {
  step: PropTypes.object.isRequired,
  stepServices: PropTypes.array.isRequired,
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  worshipTypes: PropTypes.array,
  wishes: PropTypes.array.isRequired,
  findWish: PropTypes.func.isRequired,
  updateWish: PropTypes.func.isRequired,
  updateDelegate: PropTypes.func.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  updateService: PropTypes.func.isRequired,
};

export default Ceremony;
