import { ReactNode } from 'react'

import Arrow from '../../images/icons/arrow-right.svg'
import { LinkButton } from '../Button'
import Heading from '../Heading'
import Text from '../Text'
import EmptyProductCard from './EmptyProduct'
import ResourceCard from './Resource'
import ContentCard from './Content'
import FieldCard from './Field'
import FlowCard from './Flow'
import GuideCard from './Guide'
import NewsCard from './News'
import { CollapsiblePlanCard, ProductPlanCard } from './PlanCard'
import HighlightCard from './Highlight'
import ProductCard from './ProductCard'
import ProfileCard from './Profile'
import TestimonialsCard from './Testimonials'
import style from './Card.module.scss'

interface CardBaseProps {
  img: ReactNode
  text: ReactNode
  title?: ReactNode
  className?: string
}

interface CardWithoutButtonProps extends CardBaseProps {
  link?: undefined
  buttonText?: undefined
  internal?: undefined
}

interface CardWithButtonProps extends CardBaseProps {
  link: string
  buttonText: ReactNode
  internal?: boolean
}

type CardProps = CardWithoutButtonProps | CardWithButtonProps

export default function Card({
  img,
  title,
  text,
  buttonText,
  link,
  className,
}: CardProps): JSX.Element {
  const classes = [style.card]
  if (className) {
    classes.push(className)
  }

  return (
    <div className={classes.join(' ')}>
      {img}
      {title && (
        <Heading small tagName="h4">
          <b>{title}</b>
        </Heading>
      )}
      <Text small tagName="div">
        {text}
      </Text>
      {buttonText && link && (
        <div className={style.link}>
          <LinkButton primary text={buttonText} icon={<Arrow />} href={link} />
        </div>
      )}
    </div>
  )
}

export {
  CollapsiblePlanCard,
  ContentCard,
  EmptyProductCard,
  FieldCard,
  FlowCard,
  GuideCard,
  HighlightCard,
  NewsCard,
  ProductCard,
  ProductPlanCard,
  ProfileCard,
  TestimonialsCard,
  ResourceCard,
}
