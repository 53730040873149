import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { FullClientJSON } from '@advitam/api/models/Client/Full';

import { assert } from 'lib/support';
import { isEqual } from 'lodash';
import { ClientCompanyJSON } from '@advitam/api/models/Client/Company';
import { CLIENT } from './constants';
import { AppStateSubset } from './slice';
import { makeSelectRawClient } from './selectors';

export const fetchClient = createAsyncThunk(
  `${CLIENT}/FETCH`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Clients.show(id));
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDeathDeclarations = createAsyncThunk(
  `${CLIENT}/FETCH_DEATH_DECLARATION`,
  async (clientId: number, { rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.Clients.Deceased.DeathDeclarations.index(clientId),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchClientData = createAsyncThunk(
  `${CLIENT}/FETCH_DATA`,
  async (id: number, { dispatch }) => {
    await Promise.all([
      dispatch(fetchClient(id)),
      dispatch(fetchDeathDeclarations(id)),
    ]);
  },
);

function sortedCompanies(client: FullClientJSON): ClientCompanyJSON[] {
  return [...client.clients_companies].sort(
    (a, b) => a.company_id - b.company_id,
  );
}

interface UpdateClientPayload {
  client: FullClientJSON;
  isOptedOut: boolean;
}

export const updateClient = createAsyncThunk(
  `${CLIENT}/UPDATE`,
  async (values: UpdateClientPayload, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const client = makeSelectRawClient()(state);
    assert(client !== null);

    try {
      if (!isEqual(sortedCompanies(client), sortedCompanies(values.client))) {
        await request(
          Api.V1.Clients.Companies.update(
            client.id,
            values.client.clients_companies,
          ),
        );
      }
    } catch (error) {
      return rejectWithValue(client);
    }

    try {
      if (Boolean(values.client.opt_out) !== values.isOptedOut) {
        await request(
          Api.V1.Clients.OptOut.update(client.id, values.isOptedOut),
        );
      }

      if (values.client.deceased) {
        await request(Api.V1.Defuncts.update(values.client));
      }

      const { body } = await request(Api.V1.Clients.update(values.client));
      return body;
    } catch (error) {
      return rejectWithValue(client);
    }
  },
);
