import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import CloseCross from 'components/CloseCross';
import { ButtonV2 as Button } from 'components/Button';

import messages from './messages.ts';
import style from './style.scss';

export default function ManualSelectionUpdate({
  isModalOpen,
  closeModal,
  querySaveDeal,
}) {
  function onSave() {
    closeModal();
    querySaveDeal();
  }

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="dialog"
      ariaHideApp={false}
      className="modal warning"
      overlayClassName="modal__overlay warning__overlay"
    >
      <CloseCross handleClose={closeModal} />
      <FormattedMessage id={messages.manualSelectionUpdate.id} />
      <Button
        className={style.manual_selection_update__button}
        onClick={onSave}
      >
        <FormattedMessage id={messages.save.id} />
      </Button>
    </Modal>
  );
}

ManualSelectionUpdate.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  querySaveDeal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
