import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, useForm } from 'react-final-form';

import Api from '@advitam/api';
import { AutocompleteDealEntitiesResult } from '@advitam/api/v1/Autocompletes';
import { getTokens } from '@advitam/api/lib/tokens';
import {
  ErrorText,
  FormattedApiError,
  FormUI,
  Spinner,
  isRequired,
  getFormError,
  composeValidators,
} from '@advitam/ui';
import { withSlice } from '@advitam/react';

import { makeSelectDeal } from '../../../selectors.typed';
import {
  makeSelectBatch,
  makeSelectIsSending,
  makeSelectRecipients,
} from '../../selectors';
import { SendBatchForm } from '../../types';
import { getRecipientUniqueId } from '../../utils';
import autocompletesStyle from '../Autocompletes.module.scss';
import Recipient from './Recipient';
import messages from './messages';
import { makeSelectError, makeSelectIsLoading } from './selectors';
import slice from './slice';
import { createRecipient } from './thunk';
import { findExistingEntity } from './utils';
import style from './Recipients.module.scss';
import {
  validateMissingEntity,
  validateToSignRecipients,
} from '../../validators';

function SendBatchModalRecipients(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const formApi = useForm<SendBatchForm>();

  const batch = useSelector(makeSelectBatch());
  const recipients = useSelector(makeSelectRecipients());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSending = useSelector(makeSelectIsSending());
  const error = useSelector(makeSelectError());
  const deal = useSelector(makeSelectDeal());

  const onAutocompleteChange = useCallback(
    (result?: AutocompleteDealEntitiesResult) => {
      if (!result || findExistingEntity(result, recipients)) {
        return;
      }

      dispatch(createRecipient({ entity: result, formApi }));
    },
    [dispatch, formApi, recipients],
  );

  return (
    <div>
      <Field
        name="recipientEntities"
        validate={composeValidators(
          validateMissingEntity(recipients),
          isRequired,
          batch?.to_sign && validateToSignRecipients(recipients),
        )}
      >
        {({ input, meta }): JSX.Element => (
          <>
            <FormUI.Autosuggest<AutocompleteDealEntitiesResult>
              disabled={isLoading || isSending}
              label={<FormattedMessage id={messages.label.id} />}
              placeholder={intl.formatMessage(messages.placeholder)}
              requestHeaders={{ ...getTokens() }}
              endpoint={Api.V1.absolute(
                Api.V1.Autocompletes.Path.deals.entities(deal?.uuid || ''),
              )}
              onChange={onAutocompleteChange}
              onBlur={input.onBlur}
              error={getFormError(meta, messages)}
            />

            {recipients.map(recipient => (
              <Recipient
                key={getRecipientUniqueId(recipient)}
                recipient={recipient}
              />
            ))}
          </>
        )}
      </Field>

      {isLoading && (
        <div className={style.spinner}>
          <Spinner />
        </div>
      )}

      {error && (
        <ErrorText className={autocompletesStyle.error}>
          <FormattedApiError error={error} />
        </ErrorText>
      )}
    </div>
  );
}

export default withSlice(slice)(SendBatchModalRecipients);
