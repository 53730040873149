import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import { SupplierWarehouseProductJSON } from '@advitam/api/models/Supplier/Warehouse';

import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import { IntlShape } from 'react-intl';
import { ProductType } from '@advitam/api/models/Product';
import { SUPPLIER_WAREHOUSE_PRODUCTS } from './constants';
import { AppStateSubset, State } from './slice';
import { ProductsFilters } from './types';
import messages from './messages';

type ProductsSelector<T> = Selector<AppStateSubset, T>;

const selectWarehouseProductsDomain = (state: AppStateSubset): State =>
  state[SUPPLIER_WAREHOUSE_PRODUCTS];

export const makeSelectProducts = (): ProductsSelector<
  SupplierWarehouseProductJSON[]
> => createSelector(selectWarehouseProductsDomain, ({ products }) => products);

export const makeSelectCategoryOptions = (
  intl: IntlShape,
): ProductsSelector<SelectableItem<ProductType>[]> =>
  createSelector(makeSelectProducts(), products => {
    const options: SelectableItem<ProductType>[] = [];

    products.forEach(product => {
      const { type } = product.product;
      if (!options.some(filter => filter.value === type)) {
        options.push({
          value: type,
          name: intl.formatMessage(messages[`${type}Category`]),
        });
      }
    });

    return options;
  });

export const makeSelectFilters = (): ProductsSelector<ProductsFilters> =>
  createSelector(selectWarehouseProductsDomain, ({ filters }) => filters);

export const makeSelectIsLoading = (): ProductsSelector<boolean> =>
  createSelector(selectWarehouseProductsDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): ProductsSelector<SerializedApiError | null> =>
  createSelector(selectWarehouseProductsDomain, ({ error }) => error);

export const makeSelectIsSaving = (): ProductsSelector<boolean> =>
  createSelector(selectWarehouseProductsDomain, ({ isSaving }) => isSaving);
