export function ofCity(name: string): string {
  const downcasedName = name.toLowerCase()
  if (downcasedName.startsWith('le ')) {
    return name.replace(/^Le /i, 'du ')
  }

  if (downcasedName.startsWith("l'")) {
    return name.replace(/^L'/i, 'du ')
  }

  if (downcasedName.startsWith('les')) {
    return name.replace(/^Les /i, 'des ')
  }

  return `de ${name}`
}
