import { ReactNode, Ref, forwardRef } from 'react'

import style from './Frame.module.scss'

interface FrameProps {
  children: ReactNode
  darker?: boolean
  className?: string
}

function Frame({ children, darker, className }: FrameProps, ref: Ref<HTMLDivElement>): JSX.Element {
  const classes = [style.frame, darker ? style.darker : style.light, className].filter(Boolean)

  return (
    <div className={classes.join(' ')} ref={ref}>
      {children}
    </div>
  )
}

export default forwardRef(Frame)
