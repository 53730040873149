import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ProgressBar from 'components/ProgressBar';
import messages from './messages';
import { dealsLevels, dealsErrorsLevels } from '../constants';
import style from '../Account.module.scss';

function calculateRemaining(levels, deals) {
  let remaining = null;
  levels.forEach(level => {
    if (level >= deals && remaining == null) remaining = level - deals;
  });
  return remaining;
}

function calculateRemainingPercentage(levels, deals) {
  let remainingPercentage = null;
  levels.forEach(level => {
    if (level >= deals && remainingPercentage == null) {
      remainingPercentage = (deals * 100) / level;
    }
  });
  return remainingPercentage;
}

const Stats = ({ stats }) => {
  const dealsRemaining = calculateRemaining(dealsLevels, stats.deals_done);
  const dealsRemainingPercentage = calculateRemainingPercentage(
    dealsLevels,
    stats.deals_done,
  );
  const dealsErrorsRemaining =
    stats.deals_errors - stats.current_deals_errors + 1;
  const dealsErrorsRemainingPercentage =
    (stats.current_deals_errors / stats.deals_errors) * 100;
  return (
    <div className={style.stats_container}>
      <p className={style.stat}>
        <FormattedMessage
          id={messages.gradeGlobal.id}
          values={{ grade: stats.global_grade_average }}
        />
      </p>
      <p className={style.stat}>
        <FormattedMessage
          id={messages.gradeCeremonyGlobal.id}
          values={{ grade: stats.global_ceremony_grade_average }}
        />
      </p>
      <div className={style.stat}>
        <FormattedMessage
          id={messages.deals.id}
          values={{ deals: stats.deals_done }}
        />
        <div className={style.badges}>
          {dealsLevels.map(level => (
            <div
              key={level}
              className={`${style.badge} ${
                stats.deals_done < level ? style.locked : ''
              }`}
            >
              {level}
            </div>
          ))}
        </div>
        <div className={style.stat_text}>
          <FormattedMessage
            {...messages.dealsRemaining}
            values={{ dealsRemaining }}
          />
        </div>
        <ProgressBar progress={dealsRemainingPercentage} />
      </div>

      <div className={style.stat}>
        <FormattedMessage
          id={messages.dealsErrors.id}
          values={{ dealsErrors: stats.deals_errors }}
        />
        <div className={style.badges}>
          {dealsErrorsLevels.map(level => (
            <div
              key={level}
              className={`${style.badge} ${
                stats.deals_errors < level ? style.locked : ''
              }`}
            >
              {level}
            </div>
          ))}
        </div>
        <div className={style.stat_text}>
          <FormattedMessage
            {...messages.dealsErrorsRemaining}
            values={{ dealsErrorsRemaining }}
          />
        </div>
        <ProgressBar progress={dealsErrorsRemainingPercentage} />
      </div>
    </div>
  );
};

Stats.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default Stats;
