import { assert } from 'lib/Assert';
import { ModelType } from 'models/Model';
import { PolymorphicId } from 'models/PolymorphicId';
import { SupportingDocumentVersionJSON } from 'models/SupportingDocument/Version';

interface SupportingDocument {
  document_type_id: number;
  owner_type: ModelType;
  owner_id: number;
}

interface DocumentVersionCreationPayload {
  file?: Blob;
  expiry_date: string | null;
  issue_date: string | null;
  reference: string | null;
  supporting_document: SupportingDocument;
}

export function serializeForCreation(
  documentVersion: SupportingDocumentVersionJSON,
  file: Blob,
  owner: PolymorphicId,
): DocumentVersionCreationPayload {
  const { document_type, uploaded_document } = documentVersion;
  assert(document_type !== null && document_type.id !== null);

  return {
    file,
    expiry_date: uploaded_document.expiry_date || null,
    issue_date: uploaded_document.issue_date || null,
    reference: uploaded_document.reference,
    supporting_document: {
      document_type_id: document_type.id,
      owner_id: owner.id,
      owner_type: owner.type,
    },
  };
}
