import { assert, Arrays } from '@advitam/support'

import { Message, MessageAuthor } from './types'

export function lastChatbotMessage(messages: Message[]): Message {
  let message = Arrays.last(messages)
  if (message?.author === MessageAuthor.CHATBOT) {
    return message
  }

  message = {
    author: MessageAuthor.CHATBOT,
    message: {
      answer: '',
      useful: null,
      runId: null,
      sources: [],
    },
  }
  messages.push(message)
  return message
}

export function messageByRunId(messages: Message[], runId: string): Message {
  const message = messages.find(m => m.message.runId === runId)
  assert(message !== undefined)
  return message
}
