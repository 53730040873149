export enum MimeTypes {
  PDF = 'application/pdf',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  WEBP = 'image/webp',
}

export const IMAGE_MIME_TYPES = [MimeTypes.PNG, MimeTypes.JPG, MimeTypes.WEBP];
export const DOCUMENT_MIME_TYPES = [MimeTypes.PDF, ...IMAGE_MIME_TYPES];

/**
 * @constant {string} RESTART_ON_REMOUNT
 * @default @@saga-injector/restart-on-remount
 */
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
/**
 * @constant {string} DAEMON
 * @default @@saga-injector/daemon
 */
export const DAEMON = '@@saga-injector/daemon';
/**
 * @constant {string} ONCE_TILL_UNMOUNT
 * @default @@saga-injector/once-till-unmount
 */
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const DEFAULT_SAGA_MODE = DAEMON;

/**
 * @constant {string} ENGRAVING
 * @default  gravure
 */
export const ENGRAVING = 'gravure';

/**
 * @constant {string} WANT_CEREMONY_MASTER
 * @default  want_ceremony_master
 */
export const WANT_CEREMONY_MASTER = 'want_ceremony_master';
/**
 * @constant {string} WANT_FAMILY_TO_BUY_CONCESSION
 * @default   want_family_to_buy_concession
 */
export const WANT_FAMILY_TO_BUY_CONCESSION = 'want_family_to_buy_concession';
/**
 * @constant {string} WANT_EMBALMING
 * @default  want_embalming
 */
export const WANT_EMBALMING = 'want_embalming';
/**
 * @constant {string} WANT_REFREGIRATED_TABLE
 * @default  want_refregirated_table
 */
export const WANT_REFREGIRATED_TABLE = 'want_refregirated_table';
/**
 * @constant {string} WANT_CLEANING
 * @default   want_cleaning
 */
export const WANT_CLEANING = 'want_cleaning';

/**
 * @constant {string} WANT_PRE_CASKETING
 * @default   want_pre_casketing
 */
export const WANT_PRE_CASKETING = 'want_pre_casketing';
/**
 * @constant {string} WANT_FAMILY_TO_PAY_CEREMONY
 * @default  want_family_to_pay_ceremony
 */
export const WANT_FAMILY_TO_PAY_CEREMONY = 'want_family_to_pay_ceremony';

/**
 * @constant {string} WARNING_FILE_TOO_BIG
 * @default  warningFileTooBig
 */
export const WARNING_FILE_TOO_BIG = 'warningFileTooBig';
/**
 * @constant {string} WARNING_FILE_TYPE_IS_NOT_VALID
 * @default  warningFileTypeIsNotValid
 */
export const WARNING_FILE_TYPE_IS_NOT_VALID = 'warningFileTypeIsNotValid';
