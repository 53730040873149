import { parsePhoneNumber } from 'react-phone-number-input';

export function formatPhoneNumber(phone: string): string {
  const phoneNumber = parsePhoneNumber(phone);
  if (!phoneNumber) {
    return phone;
  }

  if (phoneNumber.country === 'FR') {
    return phoneNumber.formatNational();
  }

  return phoneNumber.formatInternational();
}
