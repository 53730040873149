import {
  logger,
  createConsumer as connect,
  ChannelNameWithParams,
  Consumer,
  Mixin,
  Subscription,
} from '@rails/actioncable';

logger.enabled = process.env.NODE_ENV === 'development';

let cableSingleton: Consumer | undefined;

export function createConsumer(endpoint?: string): Consumer {
  cableSingleton = connect(endpoint);
  return cableSingleton;
}

export function subscribe(
  channel: string | ChannelNameWithParams,
  eventHandlers: Mixin,
): Subscription {
  /* istanbul ignore if */
  if (cableSingleton === undefined) {
    throw new Error(
      'trying to subscribe a channel with an uninitialized cable !',
    );
  }
  return cableSingleton.subscriptions.create(channel, eventHandlers);
}

export function disconnectIfUnused(): void {
  if (cableSingleton?.subscriptions.subscriptions.length === 0) {
    cableSingleton.disconnect();
  }
}
