import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  bic: { id: 'app.containers.Crud.Fields.Billing.bic' },
  checkoutType: { id: 'app.containers.Crud.Fields.Billing.checkoutType' },
  checkoutTypeCash: {
    id: 'app.containers.Crud.Fields.Billing.checkoutTypeCash',
  },
  checkoutTypeTransfer: {
    id: 'app.containers.Crud.Fields.Billing.checkoutTypeTransfer',
  },
  checkoutTypeCheck: {
    id: 'app.containers.Crud.Fields.Billing.checkoutTypeCheck',
  },
  checkAddress: { id: 'app.containers.Crud.Fields.Billing.checkAddress' },
  checkOrder: { id: 'app.containers.Crud.Fields.Billing.checkOrder' },
  frequency: { id: 'app.containers.Crud.Fields.Billing.frequency' },
  frequencyDeal: { id: 'app.containers.Crud.Fields.Billing.frequencyDeal' },
  frequencyPrestation: {
    id: 'app.containers.Crud.Fields.Billing.frequencyPrestation',
  },
  frequencyMonth: { id: 'app.containers.Crud.Fields.Billing.frequencyMonth' },
  frequencyQuarter: {
    id: 'app.containers.Crud.Fields.Billing.frequencyQuarter',
  },
  iban: { id: 'app.containers.Crud.Fields.Billing.iban' },
  title: { id: 'app.containers.Crud.Fields.Billing.title' },
});
