import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import { Company } from '../../constants'
import Heading from '../Heading'
import Label from '../Label'
import Text from '../Text'
import { TextBlocBase } from './Global'
import messages from './messages'
import style from './TextBloc.module.scss'

interface PhoneTextBlocProps extends TextBlocBase {
  title?: ReactNode
  text?: ReactNode
  footer?: ReactNode
  isInternationalFormat?: boolean
  className?: string
}

export default function PhoneTextBloc({
  title,
  text,
  footer,
  isInternationalFormat,
  smallTitle,
  largeTitle,
  xlargeTitle,
  className = '',
}: PhoneTextBlocProps): JSX.Element {
  const formattedPhone = isInternationalFormat
    ? Company.FORMATTED_FOREIGN_PHONE_NUMBER
    : Company.FORMATTED_PHONE_NUMBER

  return (
    <div className={`${style.global} ${className}`}>
      {title && (
        <Heading small={smallTitle} large={largeTitle} xlarge={xlargeTitle}>
          <b>{title}</b>
        </Heading>
      )}
      {text && <Text>{text}</Text>}
      <a href={`tel:${Company.PHONE_NUMBER}`} className={style.phone}>
        <Label>
          <FormattedMessage id={messages.available77.id} />
        </Label>
        <Heading small tagName="span" className={style.phone_number}>
          <b>{formattedPhone}</b>
        </Heading>
      </a>
      {footer && <Text small>{footer}</Text>}
    </div>
  )
}
