import { createAction } from 'lib/reactvitam/action_cable';

export interface DocumentReadyPayload {
  id: number;
  name: string;
}

function documentReady(id: number, name: string): DocumentReadyPayload {
  return { id, name };
}

export default createAction(documentReady, 'document_ready');
