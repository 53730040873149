import { MessageDescriptor } from 'react-intl';
import { Validator } from 'react-form';

import Input from './Input.js';

interface NumberInputProps {
  field: string;
  className?: string;
  message?: MessageDescriptor;
  placeholderMessage?: MessageDescriptor;
  toolTipMessage?: MessageDescriptor;
  errorMessages?: Record<string, MessageDescriptor>;
  required?: boolean;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  validate?: Validator;
  min?: number;
  max?: number;
}

export default function NumberInput({
  field,
  className,
  hideErrorMessage,
  message,
  toolTipMessage,
  errorMessages,
  required,
  disabled,
  placeholderMessage,
  validate,
  min,
  max,
}: NumberInputProps): JSX.Element {
  return (
    <Input
      type="number"
      field={field}
      validate={validate}
      className={className}
      message={message}
      toolTipMessage={toolTipMessage}
      errorMessages={errorMessages}
      required={required}
      hideErrorMessage={hideErrorMessage}
      placeholderMessage={placeholderMessage}
      disabled={disabled}
      min={min}
      max={max}
    />
  );
}
