import { useEffect } from 'react'

export function useInterval(
  callback: () => void,
  duration: number,
  dependencies: ReadonlyArray<unknown>,
): void {
  useEffect(() => {
    const handle = setInterval(callback, duration)
    return (): void => clearInterval(handle)
  }, dependencies)
}
