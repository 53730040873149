import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  functioning: {
    id: 'app.containers.Worship.sections.Miscellaneous.functioning',
  },
  capacity: {
    id: 'app.containers.Worship.sections.Miscellaneous.capacity',
  },
  nbHolders: {
    id: 'app.containers.Worship.sections.Miscellaneous.nbHolders',
  },
  nbHoldersTooltip: {
    id: 'app.containers.Worship.sections.Miscellaneous.nbHoldersTooltip',
  },

  billing: {
    id: 'app.containers.Worship.sections.Miscellaneous.billing',
  },
  price: {
    id: 'app.containers.Worship.sections.Miscellaneous.price',
  },
});
