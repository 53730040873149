import { useCallback } from 'react';

import Api, { downloadFile } from '@advitam/api';
import type { CommunicationFileJSON } from '@advitam/api/models/Deal/Communication/File';
import { FlatButton } from '@advitam/ui';
import Download from '@advitam/ui/images/icons/download.svg';

import style from './Details.module.scss';

interface AttachmentsProps {
  file: CommunicationFileJSON;
}

export default function Attachments({
  file,
}: AttachmentsProps): JSX.Element | null {
  const onClick = useCallback(
    () => downloadFile(Api.S3.download(file.link), `${file.name}.pdf`),
    [file],
  );

  return (
    <div key={file.link} className={style.attachment}>
      <span>{file.name}</span>
      <FlatButton onClick={onClick}>
        <Download />
      </FlatButton>
    </div>
  );
}
