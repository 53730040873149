import { Polygon } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';

import { setActiveSupplier } from 'containers/Map/slice';

import { getPolygonsLatLng } from '../../utils';
import { ActiveSupplierCoverage } from '../types';
import { getSupplierColor } from '../utils';

interface CoverageItemProps {
  coverage: ActiveSupplierCoverage;
  zIndex: number;
}

export default function CoverageItem({
  coverage,
  zIndex,
}: CoverageItemProps): JSX.Element {
  const dispatch = useDispatch();

  const { coverage: geometry, id } = coverage;
  const color = getSupplierColor(id);

  const onClick = (e: google.maps.MapMouseEvent): void => {
    if (!e.latLng) {
      return;
    }

    dispatch(
      setActiveSupplier({
        ...coverage,
        latitude: e.latLng?.lat(),
        longitude: e.latLng?.lng(),
      }),
    );
  };

  return (
    <>
      {geometry.coordinates.map(polygonsCoordinates => (
        <Polygon
          paths={getPolygonsLatLng(polygonsCoordinates)}
          options={{
            strokeColor: color,
            fillColor: color,
            strokeOpacity: 0.5,
            fillOpacity: 0.1,
            zIndex,
          }}
          onClick={onClick}
        />
      ))}
    </>
  );
}
