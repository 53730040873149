import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { Helmet } from 'react-helmet';

import { assert } from '@advitam/support';
import { Person, Text } from '@advitam/ui';
import { Sidebar, usePreventLeaving } from 'containers/Crud';

import SidebarLink from '../../SidebarLink';
import { Path } from '../constants';
import messages from './messages';
import { makeSelectLoading } from '../../selectors.js';
import { makeSelectDeal, makeSelectIsDirty } from '../../selectors.typed';
import style from '../../Deal.module.scss';

export default function DealSidebar(): JSX.Element {
  const intl = useIntl();

  const deal = useSelector(makeSelectDeal());
  const isLoading = useSelector(makeSelectLoading());
  assert(deal !== null);
  const isCreation = !deal.id;

  const { dirty } = useFormState();
  const isDealDirty = useSelector(makeSelectIsDirty());
  usePreventLeaving(!isLoading && (dirty || isDealDirty));

  const defunct = deal.defunct && {
    civility: deal.defunct.person.type,
    firstname: deal.defunct.person.firstName,
    lastname: deal.defunct.person.lastName,
  };
  const defunctName = defunct && Person.formatName(defunct, intl);
  const pageTitle = intl.formatMessage(messages.pageTitle, { defunctName });

  return (
    <Sidebar.Menu>
      <header className={style.sidebar_title}>
        <Helmet title={pageTitle} />
        <h2>
          <Text>
            <b>{defunctName}</b>
          </Text>
        </h2>
        {deal.business_id && (
          <Text small tagName="div">
            {deal.business_id}
          </Text>
        )}
      </header>

      <Sidebar.LinksSection>
        <SidebarLink
          to={Path.HOME}
          message={<FormattedMessage id={messages.home.id} />}
        />
        {!isCreation && (
          <>
            <SidebarLink
              to={Path.TODOLIST}
              message={<FormattedMessage id={messages.todolist.id} />}
            />
            <SidebarLink
              to={Path.SUMMARY}
              message={<FormattedMessage id={messages.summary.id} />}
            />
          </>
        )}
        <SidebarLink
          to={Path.IDENTITY}
          message={<FormattedMessage id={messages.identity.id} />}
        />
        <SidebarLink
          to={Path.STEPS}
          message={<FormattedMessage id={messages.steps.id} />}
        />
        {!isCreation && (
          <>
            <SidebarLink
              to={Path.DOCUMENTS}
              message={<FormattedMessage id={messages.documents.id} />}
            />
            <SidebarLink
              to={Path.SUPPLIERS}
              message={<FormattedMessage id={messages.suppliers.id} />}
            />
            <SidebarLink
              to={Path.HISTORY}
              message={<FormattedMessage id={messages.history.id} />}
            />
            <SidebarLink
              to={Path.BILLING}
              message={<FormattedMessage id={messages.billing.id} />}
            />
            <SidebarLink
              to={Path.MISCELLANEOUS}
              message={<FormattedMessage id={messages.miscellaneous.id} />}
            />
          </>
        )}
      </Sidebar.LinksSection>
    </Sidebar.Menu>
  );
}
