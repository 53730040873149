import { defineMessages } from 'react-intl';

export default defineMessages({
  prestation: {
    id: 'app.messages.DealTypeWrapper.BookingSection.Solutions.prestation',
    defaultMessage: 'Prestation',
  },
  supplier: {
    id: 'app.messages.DealTypeWrapper.BookingSection.Solutions.supplier',
    defaultMessage: 'Partenaire',
  },
  dependencyErrorModal: {
    id:
      'app.messages.DealTypeWrapper.BookingSection.Solutions.dependencyErrorModal',
    defaultMessage:
      'Cette combinaison de fournisseurs ne peut pas être sélectionnée :',
  },
  dependsOF: {
    id: 'app.messages.DealTypeWrapper.BookingSection.Solutions.suppliers',
    defaultMessage: '"{groupName}" dépend de "{dependencyName}"',
  },
});
