import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { assert } from '@advitam/support';
import { FormattedPrice, Text } from '@advitam/ui';

import { Layout } from 'containers/Crud';
import { makeSelectDeal } from 'containers/Deal/selectors.typed';

import BrandSelect from './BrandSelect';
import DuplicationButton from './DuplicationButton';
import OwnerSelect from './OwnerSelect';
import PriceInput from './PriceInput';
import StateSelect from './StateSelect';
import messages from './messages';
import { makeSelectRemainingToPay } from './selectors';
import style from './Home.module.scss';
import Commentaries from './Commentaries';
import Map from './Map';
import Reviews from './Reviews';
import ReminderButton from './ReminderButton';

export default function Home(): JSX.Element {
  const remainingToPay = useSelector(makeSelectRemainingToPay());
  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);

  return (
    <Layout.Container>
      <Layout.ColumnGroup>
        <div className={style.row}>
          <div className={style.price_wrapper}>
            <PriceInput />
            <Text tagName="div" className={style.price_remaining}>
              <FormattedMessage
                id={messages.remainingToPay.id}
                values={{
                  amount: <FormattedPrice value={remainingToPay} />,
                }}
              />
            </Text>
          </div>
          <div className={style.actions_wrapper}>
            {deal.uuid && (
              <>
                <OwnerSelect />
                <BrandSelect />
                <StateSelect />
                <DuplicationButton />
                <ReminderButton />
              </>
            )}
          </div>
        </div>
      </Layout.ColumnGroup>
      <Layout.ColumnGroup>
        <Layout.Column>
          <Commentaries />
        </Layout.Column>
        <Layout.Column>
          <Map />
        </Layout.Column>
      </Layout.ColumnGroup>
      <Reviews />
    </Layout.Container>
  );
}
