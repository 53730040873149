import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  functioning: { id: 'app.containers.Consulate.sections.functioning' },
  tax: { id: 'app.containers.Consulate.sections.tax' },
  countryCode: { id: 'app.containers.Consulate.sections.countryCode' },
  countryCodeTooltip: {
    id: 'app.containers.Consulate.sections.countryCodeTooltip',
  },
  default: { id: 'app.containers.Consulate.sections.default' },
  defaultTooltip: { id: 'app.containers.Consulate.sections.defaultTooltip' },
  nbPaperToTrad: { id: 'app.containers.Consulate.sections.nbPaperToTrad' },
  legalisationType: {
    id: 'app.containers.Consulate.sections.legalisationType',
  },
  legalisationTypeLegalisation: {
    id: 'app.containers.Consulate.sections.legalisationTypeLegalisation',
  },
  legalisationTypeNoLegalisation: {
    id: 'app.containers.Consulate.sections.legalisationTypeNoLegalisation',
  },
  legalisationTypeApostil: {
    id: 'app.containers.Consulate.sections.legalisationTypeApostil',
  },
  overrideDefaultConfirmationTitle: {
    id: 'app.containers.Consulate.sections.overrideDefaultConfirmationTitle',
  },
  overrideDefaultConfirmationText: {
    id: 'app.containers.Consulate.sections.overrideDefaultConfirmationText',
  },
  overrideDefaultConfirmationCancel: {
    id: 'app.containers.Consulate.sections.overrideDefaultConfirmationCancel',
  },
  overrideDefaultConfirmationConfirm: {
    id: 'app.containers.Consulate.sections.overrideDefaultConfirmationConfirm',
  },
});
