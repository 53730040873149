import { ReactElement, ReactNode } from 'react'

import Arrow from '../../../../images/icons/arrow-right.svg'
import Text from '../../../Text'
import style from '../Index.module.scss'
import LayoutButton from '../Layout/Button'

interface CardButtonProps {
  icon: ReactElement
  text: ReactNode
  onClick: () => void
}

export default function CardButton({ icon, text, onClick }: CardButtonProps): JSX.Element {
  return (
    <LayoutButton icon={icon} className={style.card} onClick={onClick}>
      <div className={style.text}>
        <Text large>
          <b>{text}</b>
        </Text>
        <Arrow />
      </div>
    </LayoutButton>
  )
}
