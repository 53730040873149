import type { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import Label from '../Label'
import { LabelTag } from '../Label/types'

import messages from './messages'
import style from './PolicePrefectureApproved.module.scss'

interface PolicePrefectureApprovedProps {
  className?: string
  text?: ReactNode
  image: ReactNode
  labelTagName?: LabelTag
}

export default function PolicePrefectureApproved({
  text = <FormattedMessage id={messages.text.id} />,
  image,
  className = '',
  labelTagName = 'span',
}: PolicePrefectureApprovedProps): JSX.Element {
  return (
    <div className={`${style.text} ${className}`}>
      {image}
      <Label tagName={labelTagName}>{text}</Label>
    </div>
  )
}
