import style from '../ResourceList.module.scss'
import { Breakpoint } from './types'

export const HEADER_SIZE = 50
export const GUTTER_SIZE = 12
export const SCROLLBAR_OFFSET = 16

export const BREAKPOINT_VALUE: Record<Breakpoint, number> = {
  [Breakpoint.TABLET]: parseInt(style.md, 10),
  [Breakpoint.DESKTOP]: parseInt(style.xl, 10),
  [Breakpoint.FHD]: 1900,
}
