import { ReactNode } from 'react'

import Arrow from '../../images/icons/arrow-right.svg'
import Cross from '../../images/icons/times.svg'
import { LinkButton } from '../Button'
import Heading, { HeadingTag } from '../Heading'
import Label from '../Label'
import Text from '../Text'
import { TextBlocBase } from './Global'
import style from './TextBloc.module.scss'

interface ContentTextBlocPropsBase extends TextBlocBase {
  head?: ReactNode
  title: ReactNode
  titleTagName?: HeadingTag
  text?: ReactNode
  checkList?: ReactNode[]
  className?: string
  internal?: boolean
}

interface ContentTextBlocPropsWithoutCTA extends ContentTextBlocPropsBase {
  cta?: undefined
  href?: undefined
}

interface ContentTextBlocPropsWithCTA extends ContentTextBlocPropsBase {
  cta: ReactNode
  href: string
}

type ContentTextBlocProps = ContentTextBlocPropsWithoutCTA | ContentTextBlocPropsWithCTA

export default function ContentTextBloc({
  head,
  title,
  titleTagName = 'div',
  text,
  checkList,
  cta,
  href,
  internal,
  smallTitle,
  largeTitle,
  xlargeTitle,
  className = '',
}: ContentTextBlocProps): JSX.Element {
  let labelClass: string | undefined
  if (!checkList && text) {
    labelClass = style.small_spacing
  }

  const titleClasses = [style.text]
  if (text) {
    titleClasses.push(style.small_spacing)
  } else if (head && !checkList) {
    titleClasses.push(style.more_spacing)
  }

  return (
    <div className={[style.content, className].join(' ')}>
      {head && <Label className={labelClass}>{head}</Label>}
      <Heading
        small={smallTitle}
        large={largeTitle}
        xlarge={xlargeTitle}
        tagName={titleTagName}
        className={titleClasses.join(' ')}
      >
        {title}
      </Heading>
      {text && <Text tagName="div">{text}</Text>}
      {checkList && (
        <ul>
          {checkList?.map((item, index) => (
            <li key={index}>
              <Cross />
              <Text>
                <b>{item}</b>
              </Text>
            </li>
          ))}
        </ul>
      )}
      {href && <LinkButton primary icon={<Arrow />} text={cta} href={href} internal={internal} />}
    </div>
  )
}
