import { TodoItemBase, TodoItemBaseJSON } from './Base'
import { TodoType } from './Type'
import { DocumentRequestTodoAction, DocumentRequestTodoActionJSON } from './Action/DocumentRequest'

interface RequestDocumentTodoItemBase extends TodoItemBaseJSON {
  todo_type: TodoType.REQUEST_DOCUMENT
}

export interface RequestDocumentTodoItemJSON extends RequestDocumentTodoItemBase {
  action: DocumentRequestTodoActionJSON
}

export interface RequestDocumentTodoItem extends RequestDocumentTodoItemBase {
  action: DocumentRequestTodoAction
}

export class RequestDocumentTodoItem extends TodoItemBase<RequestDocumentTodoItemJSON> {
  constructor(data: RequestDocumentTodoItemJSON) {
    super(data)
    this.action = new DocumentRequestTodoAction(data.action)
  }
}

export { DocumentRequestTodoAction }
export type { DocumentRequestTodoActionJSON }
