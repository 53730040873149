/*
 *  CheckboxWish component to toggle simple services using a checkbox
 */

import PropTypes from 'prop-types';

import { PureComponent } from 'react';
import CheckboxLabel from 'components/Input/CheckboxLabel';

class CheckboxWishes extends PureComponent {
  onChange = checked => {
    const { inversed, updateWish, wishType } = this.props;
    const value = inversed ? !checked : checked;
    updateWish(wishType, value);
  };

  isWishSet(wishType) {
    const { findWish, inversed } = this.props;
    const wish = findWish(wishType);
    if (!wish) return false;
    if (inversed) return !wish.active;
    return wish.active;
  }

  render() {
    const { message, wishType } = this.props;
    const checkboxValue = this.isWishSet(wishType);
    return (
      <CheckboxLabel
        updateCheckbox={() => this.onChange(!checkboxValue)}
        message={message}
        checkboxValue={checkboxValue}
      />
    );
  }
}

CheckboxWishes.propTypes = {
  updateWish: PropTypes.func.isRequired,
  findWish: PropTypes.func.isRequired,
  wishType: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  inversed: PropTypes.bool,
};

export default CheckboxWishes;
