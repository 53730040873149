import { authenticate } from '../../../../lib/decorators'
import { put } from '../../../../lib/methods'
import { ApiRequestDescriptor } from '../../../../lib/request'

export class Disabled {
  @authenticate
  static update(id: number, value: boolean): ApiRequestDescriptor<void> {
    return put(`/api/v1/suppliers/warehouses/flights/${id}/disabled`, {
      value,
    })
  }
}
