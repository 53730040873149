import { RefObject, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';

import { StayJSON, StayType } from '@advitam/api/models/FuneralParlor';
import { Button, ResourceList } from '@advitam/ui';
import type { Filter } from '@advitam/ui/components/ResourceList';

import { addCollectionItem } from 'containers/Crud';
import { assert } from 'lib/support';

import type { FuneralParlorForm } from '../../types';
import messages from '../messages';
import { makeSelectFilters } from '../selectors';
import { setFilters } from '../slice';
import { StayFilters } from '../types';
import style from '../Stays.module.scss';

interface FiltersProps {
  gridContainer: RefObject<HTMLDivElement>;
}

export default function Filters({ gridContainer }: FiltersProps): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();
  const form = useForm<FuneralParlorForm<StayJSON[]>>();

  const { values } = useFormState<FuneralParlorForm<StayJSON[]>>();
  const ceremonies = values.sectionValues;

  const initialValues = useSelector(makeSelectFilters());

  const onCreate = useCallback(() => {
    assert(gridContainer.current !== null);
    addCollectionItem(form, 'sectionValues', {}, gridContainer.current);
  }, [form, ceremonies, gridContainer]);

  const onChange = useCallback((newValues: StayFilters) => {
    dispatch(setFilters(newValues));
  }, []);

  const filters: Filter[] = [
    {
      type: 'Select',
      name: 'stay_type',
      placeholder: <FormattedMessage id={messages.stayTypeFilter.id} />,
      initialValue: initialValues.stay_type,
      items: [
        {
          name: intl.formatMessage(messages.stayTypeClassic),
          value: StayType.CLASSIC,
        },
        {
          name: intl.formatMessage(messages.stayTypePremium),
          value: StayType.PREMIUM,
        },
        {
          name: intl.formatMessage(messages.stayTypeClosedCoffin),
          value: StayType.CLOSED_COFFIN,
        },
      ],
    },
  ];

  return (
    <ResourceList.Filters
      filters={filters}
      onChange={onChange}
      className={style.filters}
    >
      <Button
        primary
        onClick={onCreate}
        text={<FormattedMessage id={messages.add.id} />}
      />
    </ResourceList.Filters>
  );
}
