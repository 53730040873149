import ResourceCable from './Container';
import ResourceDestroyModal from './DestroyModal';
import ResourceNavbarUsers from './NavbarUsers';
import ResourceUpdateModal from './UpdateModal';
import ResourceUpdatingModal from './UpdatingModal';

export {
  ResourceDestroyModal,
  ResourceNavbarUsers,
  ResourceUpdateModal,
  ResourceUpdatingModal,
};

export default ResourceCable;
