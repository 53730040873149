import { FormSpy, useForm } from 'react-final-form';
import { DealKoReason } from '@advitam/api/models/Deal/KoReason';
import { KoReasonForm } from './types';

export default function ReasonSpy(): JSX.Element {
  const form = useForm<KoReasonForm>();

  return (
    <FormSpy<KoReasonForm>
      subscription={{ values: true }}
      onChange={({ values: newValues }): void => {
        if (newValues.ko_reason !== DealKoReason.OTHER) {
          form.change('ko_comment', null);
        }
      }}
    />
  );
}
