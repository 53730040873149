import { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { Outlet, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { HardSpinner, PageSpinner } from '@advitam/ui/components/Spinner';

import { Path } from 'containers/App/constants';
import ResourceCable, {
  ResourceDestroyModal,
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import { assert } from 'lib/support';
import { withDatasets } from 'slices/data';

import {
  makeSelectRawClient,
  makeSelectIsLoading,
  makeSelectIsSaving,
} from './selectors';
import { fetchClientData } from './thunk';
import slice from './slice';
import DeathDeclarationValidation from './DeathDeclarationValidation';

function Container(): JSX.Element {
  const dispatch = useDispatch();

  const client = useSelector(makeSelectRawClient());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());

  const { id } = useParams();
  assert(id !== undefined);
  const clientId = parseInt(id, 10);
  const canRender = !isLoading && client;

  const fetchResource = useCallback(() => {
    dispatch(fetchClientData(clientId));
  }, [dispatch, clientId]);

  useEffect(() => {
    fetchResource();
  }, [clientId]);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      {isSaving && <HardSpinner />}
      {canRender ? (
        <>
          <Outlet />
          <DeathDeclarationValidation />
        </>
      ) : (
        <PageSpinner />
      )}
      <ResourceCable
        resourceId={clientId}
        resourceType={ResourceRoomType.CLIENT}
      >
        <ResourceNavbarUsers />
        <ResourceUpdateModal onRefresh={fetchResource} />
        <ResourceDestroyModal path={Path.CLIENTS} />
      </ResourceCable>
    </>
  );
}

export default compose<typeof Container>(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(Container);
