import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import { Graveyard, GraveyardJSON } from '@advitam/api/models/Graveyard';

import { GRAVEYARD } from './constants';
import { AppStateSubset, State } from './slice';
import { NewGraveyard } from './types';

type GraveyardSelectors<T> = Selector<AppStateSubset, T>;

const selectGraveyardDomain = (state: AppStateSubset): State =>
  state[GRAVEYARD];

export const makeSelectRawGraveyard = (): GraveyardSelectors<
  GraveyardJSON | NewGraveyard | null
> => createSelector(selectGraveyardDomain, ({ graveyard }) => graveyard);

export const makeSelectGraveyard = (): GraveyardSelectors<Graveyard | null> =>
  createSelector(selectGraveyardDomain, ({ graveyard }) =>
    graveyard?.id ? new Graveyard(graveyard) : null,
  );

export const makeSelectIsLoading = (): GraveyardSelectors<boolean> =>
  createSelector(selectGraveyardDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): GraveyardSelectors<boolean> =>
  createSelector(selectGraveyardDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): GraveyardSelectors<boolean> =>
  createSelector(selectGraveyardDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): GraveyardSelectors<boolean> =>
  createSelector(selectGraveyardDomain, ({ isUpdatingName }) => isUpdatingName);

export const makeSelectError = (): GraveyardSelectors<SerializedApiError | null> =>
  createSelector(selectGraveyardDomain, ({ error }) => error);

export const makeSelectDestroyError = (): GraveyardSelectors<SerializedApiError | null> =>
  createSelector(selectGraveyardDomain, ({ destroyError }) => destroyError);
