import { DealDocumentBatchJSON } from '../../../models/Deal/Document/Batch'
import { RecipientType } from '../../../models/RecipientType'
import { SendingMethod } from '../../../models/SendingMethod'
import { BatchEntityRecipient } from './types'

interface BatchEntity {
  id: number
  type: RecipientType
  method: SendingMethod | null
  method_value: string | null
  subject: string
  content: string
}

interface SendBatchPayload {
  tags: string[]
  batches: Pick<DealDocumentBatchJSON, 'id'>[]
  entities: BatchEntity[]
}

export function serializeSendBatch(
  batch: DealDocumentBatchJSON,
  recipients: BatchEntityRecipient[],
): SendBatchPayload {
  return {
    tags: [batch.tag],
    batches: [{ id: batch.id }],
    entities: recipients.map(recipient => ({
      ...recipient,
      content: batch.content,
      subject: batch.subject,
    })),
  }
}
