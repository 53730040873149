import { LayerCreatedNotificationJSON } from '@advitam/api/models/Notification';
import { createAction } from 'lib/reactvitam/action_cable';

type Notification = Pick<
  LayerCreatedNotificationJSON,
  'id' | 'action' | 'comp_info' | 'entity_type' | 'entity_id'
>;

function layerCreated(notification: Notification): Notification {
  return notification;
}

export default createAction(layerCreated, 'layer_created');
