import { Objects } from '@advitam/support'
import { CoverageZoneType } from '../../models/Entity/Coverage'
import { Model } from '../../models/Model'
import { RegionalHealthAuthorityJSON } from '../../models/RegionalHealthAuthority'
import { RhaBody } from './types'

interface CoveragePayload {
  id?: number
  zone_type: CoverageZoneType
  zone_id: number
}

interface DestroyedCoverage {
  id: number
  _destroy: true
}

type RhaPayload = Partial<
  Omit<RegionalHealthAuthorityJSON, 'id' | 'country' | 'regional_health_authority_coverage'> & {
    country_code: string | null
    headquarter_country_code: string | null
    regional_health_authority_coverage_attributes: Array<CoveragePayload | DestroyedCoverage>
  }
>

export function serialize(rha: Omit<RhaBody, 'id'>): RhaPayload {
  const coverages = rha.regional_health_authority_coverage?.map((coverage):
    | CoveragePayload
    | DestroyedCoverage => {
    if (Model.isDestroyed(coverage)) {
      return { id: coverage.id, _destroy: true }
    }
    return Objects.pick(coverage, 'id', 'zone_type', 'zone_id')
  })

  return {
    ...Objects.omit(rha, 'country', 'headquarter_country', 'regional_health_authority_coverage'),
    country_code: rha.country === undefined ? undefined : rha.country?.code || null,
    headquarter_country_code:
      rha.headquarter_country === undefined ? undefined : rha.headquarter_country?.code || null,
    regional_health_authority_coverage_attributes: coverages,
  }
}
