import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { getCurrentYearAndMonth } from 'utils/functions';
import { makeSelectUser } from 'slices/auth';
import { makeSelectFuneralTeam, withDatasets } from 'slices/data/index.ts';
import { SelectList } from 'components/Input';
import Spinner from 'components/Spinner';
import { FuneralDirectorAuth } from 'components/Authorization';

import reducer from './reducer';
import saga from './saga';
import * as actions from './actions';
import {
  makeSelectUserStats,
  makeSelectTeamStats,
  makeSelectUserStatsPerMonth,
  makeSelectTeamStatsPerMonth,
  makeSelectLoadingStats,
  makeSelectLoadingStatsPerMonth,
} from './selectors';
import Stats from './Stats';
import StatsPerMonth from './StatsPerMonth';
import style from './Account.module.scss';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYearAndMonth: getCurrentYearAndMonth(),
      uid: props.user.uid,
    };
  }

  componentDidMount() {
    const {
      user: { uid },
      getStats,
      getStatsPerMonth,
    } = this.props;
    getStats(uid);
    getStatsPerMonth(uid, getCurrentYearAndMonth());
  }

  onChangeMonth(currentYearAndMonth) {
    const { getStatsPerMonth } = this.props;
    const { uid } = this.state;
    this.setState({ currentYearAndMonth });
    getStatsPerMonth(uid, currentYearAndMonth);
  }

  onChangePerson(uid) {
    const { getStats, getStatsPerMonth } = this.props;
    const { currentYearAndMonth } = this.state;
    this.setState({ uid });
    getStats(uid);
    getStatsPerMonth(uid, currentYearAndMonth);
  }

  render() {
    const {
      userStats,
      userStatsPerMonth,
      funeralTeam,
      user,
      loadingStats,
      loadingStatsPerMonth,
      teamStatsPerMonth,
    } = this.props;
    const { uid, currentYearAndMonth } = this.state;
    const options = funeralTeam.reduce((result, item) => {
      if (item.disabled === true) return result;
      return [
        ...result,
        {
          value: item.email,
          name: item.email,
        },
      ];
    }, []);

    const ProtectedSelectList = FuneralDirectorAuth(
      <SelectList
        name="userSelection"
        options={options}
        value={uid}
        onChange={({ target: { value } }) => this.onChangePerson(value)}
      />,
    );

    return (
      <section className={style.section}>
        <div className={style.inputs_wrapper}>
          <input
            className={style.input}
            type="month"
            min="2016-01"
            max={getCurrentYearAndMonth()}
            value={currentYearAndMonth}
            onChange={({ target: { value } }) => this.onChangeMonth(value)}
          />
          <ProtectedSelectList userPermission={user.role} />
        </div>
        {loadingStats || loadingStatsPerMonth ? (
          <Spinner />
        ) : (
          <div className={style.stats_wrapper}>
            <StatsPerMonth
              userStats={userStatsPerMonth}
              teamStats={teamStatsPerMonth}
            />
            <Stats stats={userStats} />
          </div>
        )}
      </section>
    );
  }
}

Account.propTypes = {
  userStats: PropTypes.object.isRequired,
  userStatsPerMonth: PropTypes.object.isRequired,
  teamStatsPerMonth: PropTypes.object.isRequired,
  getStats: PropTypes.func.isRequired,
  getStatsPerMonth: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  funeralTeam: PropTypes.array.isRequired,
  loadingStats: PropTypes.bool.isRequired,
  loadingStatsPerMonth: PropTypes.bool.isRequired,
  uid: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  userStats: makeSelectUserStats(),
  teamStats: makeSelectTeamStats(),
  userStatsPerMonth: makeSelectUserStatsPerMonth(),
  teamStatsPerMonth: makeSelectTeamStatsPerMonth(),
  user: makeSelectUser(),
  funeralTeam: makeSelectFuneralTeam(),
  loadingStats: makeSelectLoadingStats(),
  loadingStatsPerMonth: makeSelectLoadingStatsPerMonth(),
});

function mapDispatchToProps(dispatch) {
  return {
    getStats: uid => dispatch(actions.getStats(uid)),
    getStatsPerMonth: (uid, month) =>
      dispatch(actions.getStatsPerMonth(uid, month)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'account', reducer });
const withSaga = injectSaga({ key: 'account', saga });

export default compose(
  withDatasets('funeralTeam'),
  withReducer,
  withSaga,
  withConnect,
)(Account);
