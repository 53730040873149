/*
 * ServicesSelector Messages
 *
 * This contains all the text for the ServicesSelector components.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  addService: {
    id: 'app.components.DealFuneral.StepEditors.ServicesSelector.addService',
    defaultMessage: 'Add a service',
  },
  price: {
    id: 'app.components.DealFuneral.StepEditors.ServicesSelector.price',
    defaultMessage: ' {price, select, NaN { } other {{price} €}}',
  },
  searchHeader: {
    id: 'app.components.DealFuneral.StepEditors.ServicesSelector.searchHeader',
    defaultMessage: 'Search a service',
  },
});
