import { FormattedDate } from 'react-intl';

import { Text } from '@advitam/ui';
import BusinessUserBadge from 'components/BusinessUserBadge';
import { OpsLog } from 'models/OpsLog';

import style from './style.scss';

interface OpsLogCardProps {
  opsLog: OpsLog;
  preview?: boolean;
}

export default function OpsLogCard({
  opsLog,
  preview = false,
}: OpsLogCardProps): JSX.Element {
  return (
    <Text className={`${style.card} ${preview ? style.preview : ''}`}>
      <span>{opsLog.content}</span>
      <BusinessUserBadge userId={opsLog.business_user_id} />
      <FormattedDate value={opsLog.created_at} />
    </Text>
  );
}
