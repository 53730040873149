import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormUI } from '@advitam/ui';

import actionsMessages from 'messages/actions';

import { assert } from 'lib/support';
import messages from '../../messages';
import { setActiveSupplier } from '../../slice';
import ActiveTag from '../parts/ActiveTag';
import style from '../Content.module.scss';
import {
  makeSelectRemainingKeysOptions,
  makeSelectActiveKeysOptions,
} from './selectors';
import { removeActiveKey } from './slice';
import { fetchCoverages, fetchPrestationsCoverageKeys } from './thunk';
import { getPrestationColor } from './utils';

export default function PrestationsAutocomplete(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const activeKeysOptions = useSelector(makeSelectActiveKeysOptions());
  const keysOptions = useSelector(makeSelectRemainingKeysOptions());

  const onChange = (key: string | string[] | undefined): void => {
    assert(!Array.isArray(key));
    if (!key) {
      return;
    }

    dispatch(fetchCoverages(key));
  };

  const deactivatePrestation = (key: string): void => {
    dispatch(removeActiveKey(key));
    dispatch(setActiveSupplier(null));
  };

  useEffect(() => {
    dispatch(fetchPrestationsCoverageKeys());
  }, []);

  return (
    <div>
      <FormUI.Select
        placeholder={intl.formatMessage(actionsMessages.search)}
        label={<FormattedMessage id={messages.prestations.id} />}
        filter
        className={style.autocomplete}
        items={keysOptions.map(keyOption => ({
          value: keyOption.key,
          name: keyOption.name,
        }))}
        onChange={onChange}
      />
      <div>
        {activeKeysOptions.map(({ key, name }) => (
          <ActiveTag
            key={key}
            backgroundColor={getPrestationColor(key)}
            onClick={(): void => deactivatePrestation(key)}
          >
            {name}
          </ActiveTag>
        ))}
      </div>
    </div>
  );
}
