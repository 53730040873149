import { ClientRole } from '@advitam/api/models/Client/Role';

import type { NewAbility, NewClient } from './types';

export const DEAL_IDENTITY = 'Deal/Identity';

export const EMAIL_TAKEN_ERROR = 'client__email__taken';

export const CLIENT_SKELETON: NewClient = {
  civility: null,
  firstname: '',
  lastname: '',
  birth_name: null,
  email: null,
  phone: null,
  phone_2: null,
  phone_3: null,
  birth_date: null,
  address: null,
  address_l2: null,
  city: null,
  department: null,
  postal_code: null,
  insee_code: null,
  latitude: null,
  longitude: null,
  country: null,
  defunct_id: null,
  cgs_accepted_at: null,
  work: null,
  birth_location: null,
  companies: [],
};

export const ABILITY_SKELETON: NewAbility = {
  client: CLIENT_SKELETON,
  link: '',
  role: ClientRole.EDITOR,
  special: false,
};

export const ABILITIES_SKELETON: NewAbility[] = [
  {
    client: CLIENT_SKELETON,
    link: '',
    role: ClientRole.OWNER,
    special: true,
  },
];
