import Api, { request } from '@advitam/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Path, Url } from 'containers/App/constants';
import { FORGOTTEN_PASSWORD } from './constants';

export const requestPasswordReset = createAsyncThunk(
  `${FORGOTTEN_PASSWORD}_REQUEST_PASSWORD_RESET`,
  async (email: string, { rejectWithValue }) => {
    const redirectUrl = `${Url.SELF}${Path.UPDATE_PASSWORD}`;

    try {
      const { body } = await request(
        Api.V1.Pro.Auth.Password.create(email, redirectUrl),
      );

      return body.message;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
