import { ReactNode } from 'react'

import style from './Button.module.scss'

interface IconButtonProps {
  onClick: () => void
  icon: ReactNode
  className?: string
}

export default function IconButton({ icon, onClick, className }: IconButtonProps): JSX.Element {
  const classes = [style.icon_btn]
  if (className) {
    classes.push(className)
  }
  return (
    <button type="button" onClick={onClick} className={classes.join(' ')}>
      {icon}
    </button>
  )
}
