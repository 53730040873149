// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu-module__sidebar--_K40A{width:275px}`, "",{"version":3,"sources":["webpack://./src/containers/Crud/Sidebar/Menu/Menu.module.scss","webpack://./src/containers/Crud/Sidebar/vars.scss"],"names":[],"mappings":"AAOA,6BACE,WCRe","sourcesContent":["@import '~@advitam/ui/scss/animation';\n@import '~@advitam/ui/scss/colors';\n@import '~@advitam/ui/scss/media';\n@import '~@advitam/ui/scss/theme';\n@import '~@advitam/ui/scss/z_index';\n@import '../vars';\n\n.sidebar {\n  width: $sidebar__width;\n}\n","$sidebar__width: 275px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"sidebar": `Menu-module__sidebar--_K40A`
};
export default ___CSS_LOADER_EXPORT___;
