import ArrowLeft from '../../images/icons/arrow-left.svg'
import ArrowRight from '../../images/icons/arrow-right.svg'
import Navigation from './parts/Navigation'
import List from './parts/List'
import style from './Pagination.module.scss'

interface PaginationBaseProps {
  currentPage: number
  totalPages: number
  className?: string
}

interface PaginationLinkProps extends PaginationBaseProps {
  onClick?: undefined
  baseUrl: string
  pageParamName: string
  queryParams?: Record<string, string>
}

interface PaginationButtonProps extends PaginationBaseProps {
  onClick: (pageNumber: number) => void
  baseUrl?: undefined
  pageParamName?: undefined
  queryParams?: undefined
}

type PaginationProps = PaginationLinkProps | PaginationButtonProps

export default function Pagination({
  currentPage,
  totalPages,
  baseUrl,
  pageParamName,
  queryParams,
  className,
  onClick,
}: PaginationProps): JSX.Element | null {
  if (totalPages <= 1) {
    return null
  }
  let getHref
  if (baseUrl && pageParamName) {
    getHref = (pageNumber: number): string =>
      `${baseUrl}?${new URLSearchParams({
        ...queryParams,
        [pageParamName]: pageNumber.toString(),
      }).toString()}`
  }

  const classes = [style.pagination]
  if (className) {
    classes.push(className)
  }

  return (
    <div className={classes.join(' ')}>
      {currentPage > 1 && (
        <Navigation
          icon={<ArrowLeft />}
          index={currentPage - 1}
          onClick={onClick}
          getHref={getHref}
        />
      )}
      <ol>
        <List
          currentPage={currentPage}
          totalPages={totalPages}
          onClick={onClick}
          getHref={getHref}
        />
      </ol>
      {currentPage < totalPages && (
        <Navigation
          icon={<ArrowRight />}
          index={currentPage + 1}
          onClick={onClick}
          getHref={getHref}
        />
      )}
    </div>
  )
}
