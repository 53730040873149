import { ErrorCode } from '../errors'
import { ValidatorReturnType } from './Validator'

export function isRequired<T>(value: T): ValidatorReturnType {
  if (
    value === (('' as unknown) as T) ||
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return ErrorCode.REQUIRED
  }

  return undefined
}
