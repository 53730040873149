// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyFunc = (...args: any[]) => any;

export type Action<T extends AnyFunc = AnyFunc> = ReturnType<T> & {
  action: string;
};
export type ActionFactory<T extends AnyFunc = AnyFunc> = ((
  ...args: Parameters<T>
) => Action<T>) & {
  action: string;
};

export function createAction<T extends AnyFunc>(
  func: T,
  name: string,
): ActionFactory<T> {
  /* eslint-disable @typescript-eslint/no-unsafe-return */
  const factory = (...args: Parameters<T>): Action<T> => ({
    ...func(...args),
    action: name,
  });
  /* eslint-enable @typescript-eslint/no-unsafe-return */
  factory.action = name;
  return factory;
}
