import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Layout from 'containers/Crud/Layout';
import { OpeningHours } from 'containers/Crud';

import { saveRegionalHealthAuthority } from '../thunk';
import type { RegionalHealthAuthorityForm } from './types';
import Wrapper from './Wrapper';

export default function OpeningHoursSection(): JSX.Element {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: RegionalHealthAuthorityForm): void => {
      dispatch(saveRegionalHealthAuthority(values.regionalHealthAuthority));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        <OpeningHours prefix="regionalHealthAuthority" />
      </Layout.Container>
    </Wrapper>
  );
}
