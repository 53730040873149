import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

function TextareaInput(props) {
  const {
    className,
    message,
    errorMessage,
    placeholderMessage,
    input: { onChange, onBlur, value, name },
    meta: { error, touched },
    rows,
  } = props;
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <label className={`input--textarea ${className}`} htmlFor={name}>
      <span>{message && formatMessage(message)}</span>
      <textarea
        type="text"
        placeholder={placeholderMessage && formatMessage(placeholderMessage)}
        id={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value || ''}
        className={touched && error ? 'errored' : ''}
        rows={rows}
      />
      {errorMessage && touched && error && (
        <span className="error">{formatMessage(errorMessage)}</span>
      )}
    </label>
  );
}

TextareaInput.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object,
  errorMessage: PropTypes.object,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  placeholderMessage: PropTypes.object,
  rows: PropTypes.number,
};

export default TextareaInput;
