// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdatingModal-module__modal--wut5d{padding:24px 32px 32px}.UpdatingModal-module__modal--wut5d p{margin:16px 0 0;text-align:justify}`, "",{"version":3,"sources":["webpack://./src/containers/ResourceCable/UpdatingModal.module.scss"],"names":[],"mappings":"AAAA,oCACE,sBAAA,CAEA,sCACE,eAAA,CACA,kBAAA","sourcesContent":[".modal {\n  padding: 24px 32px 32px;\n\n  p {\n    margin: 16px 0 0;\n    text-align: justify;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `UpdatingModal-module__modal--wut5d`
};
export default ___CSS_LOADER_EXPORT___;
