import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ErrorText from '@advitam/ui/components/ErrorText';
import { FormattedApiError } from '@advitam/ui/components/Format';
import { ConfirmationModal } from '@advitam/ui/components/Modal';

import messages from './messages';
import slice, { close, open } from './slice';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsOpen,
} from './selectors';
import { resetPassword } from './thunk';

export default function PasswordResetModal(): JSX.Element {
  const dispatch = useDispatch();

  const isOpen = useSelector(makeSelectIsOpen());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const onCancel = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    dispatch(resetPassword());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={<FormattedMessage id={messages.title.id} />}
      text={
        <>
          <FormattedMessage id={messages.text.id} />
          {error && (
            <ErrorText center>
              <FormattedApiError error={error} />
            </ErrorText>
          )}
        </>
      }
      cancel={<FormattedMessage id={messages.cancel.id} />}
      onCancel={onCancel}
      confirm={<FormattedMessage id={messages.confirm.id} />}
      onConfirm={onConfirm}
      isConfirmationLoading={isLoading}
    />
  );
}

export { slice, open };
