import { authenticate } from '../lib/decorators'
import type { ApiRequestDescriptor } from '../lib/request'
import { get } from '../lib/methods'
import { FuneralBrandJSON } from '../models/FuneralBrand'

export class FuneralBrands {
  @authenticate
  static index(): ApiRequestDescriptor<FuneralBrandJSON> {
    return get('/api/v1/funeral_brands')
  }
}
