import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';

import type { ConcessionTypeJSON } from '@advitam/api/models/Concession';
import type { GraveyardConcessionTypeJSON } from '@advitam/api/models/Graveyard';
import CrossIcon from '@advitam/ui/images/icons/times.svg';
import { ActionTag, FormLayout, Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import { Layout } from 'containers/Crud';
import { makeSelectConcessionTypes } from 'slices/data/selectors';

import messages from '../../messages';
import TermsInput from './TermsInput';
import { GraveyardMiscForm, UnsavedGraveyardConcessionType } from '../../types';
import style from './Concessions.module.scss';

const FIELD_NAME = 'sectionValues.concessionTypes';

function getConcessionTypeItems(
  options: ConcessionTypeJSON[],
): SelectableItem<Partial<GraveyardConcessionTypeJSON>>[] {
  return options.map(option => ({
    name: option.name,
    value: { concession_type: option },
  }));
}

export function Concessions(): JSX.Element {
  const availableConcessionTypes = useSelector(makeSelectConcessionTypes());
  const form = useForm<GraveyardMiscForm>();
  const { values } = useFormState<GraveyardMiscForm>();
  const types = values.sectionValues.concessionTypes;

  const removeSuggestion = useCallback(
    (
      typeToRemove:
        | GraveyardConcessionTypeJSON
        | UnsavedGraveyardConcessionType,
    ): void => {
      const newTypes = types.filter(t => t !== typeToRemove);
      form.change(FIELD_NAME, newTypes);
    },
    [form, types],
  );

  return (
    <Layout.Fieldset
      title={<FormattedMessage id={messages.concessions.id} />}
      tooltip={<FormattedMessage id={messages.concessionsTooltip.id} />}
    >
      <FormLayout.Row>
        <div>
          <Select
            name={FIELD_NAME}
            items={getConcessionTypeItems(availableConcessionTypes)}
            label={<FormattedMessage id={messages.availableTypes.id} />}
            placeholder={
              <FormattedMessage id={messages.availableTypesPlaceholder.id} />
            }
            multiple
          />
          <div>
            {types.map((t, idx) => (
              <ActionTag
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                onClick={(): void => removeSuggestion(t)}
                icon={<CrossIcon />}
                className={style.tag}
              >
                {t.concession_type.name}
              </ActionTag>
            ))}
          </div>
        </div>
      </FormLayout.Row>
      <FormLayout.Row>
        <TermsInput />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
