import { ForwardedRef, forwardRef, ReactNode } from 'react';
import style from './Layout.module.scss';

interface ColumnProps {
  children?: ReactNode | ReactNode[];
}

function Column(
  { children }: ColumnProps,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  return (
    <div ref={ref} className={style.column}>
      {children}
    </div>
  );
}

export default forwardRef(Column);
