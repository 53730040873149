import { useRef, useEffect } from 'react';

/**
 * Define an interval
 *
 * The interval is cancellable by changing the delay to null, e.g.
 * ```ts
 *   useInterval(() => "no answer, trying again", hasAnswer ? null : 5000);
 * ```
 *
 * @param callback The function to be executed
 * @param delay The delay of the interval, or null to cancel it
 */
export function useInterval(callback: () => void, delay: number | null): void {
  // Somehow needed, without this captured variables in the callback are not
  // updated.
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return undefined;
    }

    const handle = setInterval(() => savedCallback.current(), delay);
    return (): void => clearInterval(handle);
  }, [delay]);
}
