import type { ReactNode, ReactElement, MouseEvent } from 'react'

import Link from '../Link'
import { ButtonStyleProps, getClassList } from './Button'
import Contents from './parts/Contents'

interface LinkButtonProps extends ButtonStyleProps {
  text?: ReactNode
  icon?: ReactElement
  href: string
  internal?: boolean
  newTab?: boolean
  download?: boolean
  onClick?: (event: MouseEvent<HTMLElement>) => void
}

export default function LinkButton({
  text,
  icon,
  href,
  internal,
  newTab,
  download,
  onClick,
  ...styleProps
}: LinkButtonProps): JSX.Element {
  const classes = getClassList(styleProps)
  const { outline } = styleProps

  return (
    <Link
      internal={internal}
      download={download}
      newTab={newTab}
      href={href}
      className={classes.join(' ')}
      onClick={onClick}
    >
      <Contents outline={outline} icon={icon} text={text} />
    </Link>
  )
}
