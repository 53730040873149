import { defineMessages } from 'react-intl';

export default defineMessages({
  close: {
    id: 'app.messages.actions.close',
  },
  create: {
    id: 'app.messages.actions.create',
  },
  edit: {
    id: 'app.messages.actions.edit',
  },
  delete: {
    id: 'app.messages.actions.delete',
  },
  duplicate: {
    id: 'app.messages.actions.duplicate',
  },
  save: {
    id: 'app.messages.actions.save',
  },
  add: {
    id: 'app.messages.actions.add',
  },
  cancel: {
    id: 'app.messages.actions.cancel',
  },
  back: {
    id: 'app.messages.actions.back',
  },
  confirm: {
    id: 'app.messages.actions.confirm',
  },
  upload: {
    id: 'app.messages.actions.upload',
  },
  submit: {
    id: 'app.messages.actions.submit',
  },
  search: {
    id: 'app.messages.actions.search',
  },
  validate: {
    id: 'app.messages.actions.validate',
  },
  see: {
    id: 'app.messages.actions.see',
  },
  link: {
    id: 'app.messages.actions.link',
  },
  continue: {
    id: 'app.messages.actions.continue',
  },
  select: {
    id: 'app.messages.actions.select',
  },
  ok: {
    id: 'app.messages.actions.ok',
  },
  update: {
    id: 'app.messages.actions.update',
  },
  yes: {
    id: 'app.messages.actions.yes',
  },
  no: {
    id: 'app.messages.actions.no',
  },
  reset: {
    id: 'app.messages.actions.reset',
  },
});
