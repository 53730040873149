import { FormattedMessage } from 'react-intl';
import { FormLayout, NumberInput } from '@advitam/ui';
import { Layout, messages as crudMessages } from 'containers/Crud';
import messages from '../messages';

const PREFIX = 'graveyard';
const MIN_PRICE = 0;

export function Billing(): JSX.Element {
  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.billing.id} />}>
      <FormLayout.Row>
        <NumberInput
          min={MIN_PRICE}
          label={<FormattedMessage id={messages.coffinBurial.id} />}
          step={0.1}
          name={`${PREFIX}.coffin_interment_tax`}
          tooltip={<FormattedMessage id={messages.coffinBurialTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
        <NumberInput
          min={MIN_PRICE}
          label={<FormattedMessage id={messages.urnBurial.id} />}
          step={0.1}
          name={`${PREFIX}.urn_interment_tax`}
          tooltip={<FormattedMessage id={messages.urnBurialTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          min={MIN_PRICE}
          label={<FormattedMessage id={messages.dispersion.id} />}
          step={0.1}
          name={`${PREFIX}.dispersion_jds_fee`}
          tooltip={<FormattedMessage id={messages.dispersionTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
        <NumberInput
          min={MIN_PRICE}
          label={<FormattedMessage id={messages.exhumation.id} />}
          step={0.1}
          name={`${PREFIX}.exhumation_tax`}
          tooltip={<FormattedMessage id={messages.exhumationTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          min={MIN_PRICE}
          label={<FormattedMessage id={messages.columbariumPlate.id} />}
          step={0.1}
          name={`${PREFIX}.col_plate_tax`}
          tooltip={
            <FormattedMessage id={messages.columbariumPlateTooltip.id} />
          }
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
