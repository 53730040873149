import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';
import type { EntityInvoicePrestationJSON } from '@advitam/api/models/EntityInvoice/Prestation';

import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import EditIcon from './Icon';
import { editPrestation } from './slice';

interface EditPrestationIconProps {
  invoice: EntityInvoiceJSON;
  prestation: EntityInvoicePrestationJSON;
}

export default function EditPrestationIcon({
  invoice,
  prestation,
}: EditPrestationIconProps): JSX.Element {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(editPrestation({ invoice, prestation }));
  }, [dispatch, invoice, prestation]);

  return <EditIcon onClick={onClick} />;
}
