import Check from '../../images/icons/check.svg'
import Arc from './Arc'
import style from './Progress.module.scss'

interface ProgressProps {
  current: number
  total: number
  className?: string
}

export default function Progress({ current, total, className = '' }: ProgressProps): JSX.Element {
  if (process.env.NODE_ENV !== 'production' && total === 0) {
    throw new Error('Progress: total may not be 0')
  }

  return (
    <div className={`${style.progress} ${className}`}>
      <Arc angle={(current * 360) / total} className={style.angle} />
      {current === total && <Check className={style.check} />}
    </div>
  )
}
