import { createSelector, Selector } from 'reselect';

import { assert } from 'lib/support';

import { makeSelectRawClient } from '../../selectors';
import type { AppStateSubset } from '../../slice';
import { GeneralForm } from './types';

type ClientSelectors<T> = Selector<AppStateSubset, T>;

export const makeSelectInitialFormValues = (): ClientSelectors<GeneralForm> =>
  createSelector(makeSelectRawClient(), client => {
    assert(client !== null);
    return {
      client,
      isOptedOut: client.opt_out !== null,
    };
  });
