import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type { PoliceJSON } from '@advitam/api/models/Police';

import { Path } from 'containers/App/constants';
import { assert, nextTick } from 'lib/support';

import { POLICE } from './constants';
import { AppStateSubset, setPolice } from './slice';
import { makeSelectRawPolice } from './selectors';
import { NewPolice } from './types';

export const fetchPolice = createAsyncThunk(
  `${POLICE}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Police.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createPolice = createAsyncThunk(
  `${POLICE}_CREATE`,
  async (police: NewPolice, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(Api.V1.Police.create(police));

      dispatch(setPolice(body));
      nextTick(() => dispatch(replace(`./${body.id}`)));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePolice = createAsyncThunk(
  `${POLICE}_UPDATE`,
  async (police: PoliceJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Police.update(police));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePoliceName = createAsyncThunk(
  `${POLICE}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const police = makeSelectRawPolice()(state);
    assert(police?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Police.update({ id: police.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const savePolice = createAsyncThunk(
  `${POLICE}_SAVE`,
  async (police: PoliceJSON | NewPolice, { dispatch }) => {
    if (police.id === undefined) {
      await dispatch(createPolice(police));
    } else {
      await dispatch(updatePolice(police));
    }
  },
);

export const destroyPolice = createAsyncThunk(
  `${POLICE}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const police = makeSelectRawPolice()(state);
    assert(police?.id !== undefined);

    try {
      await request(Api.V1.Police.destroy(police.id));
      dispatch(push(Path.ENTITIES));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsPoliceDisabled = createAsyncThunk(
  `${POLICE}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const police = makeSelectRawPolice()(state);
    assert(police?.id !== undefined);

    try {
      await request(Api.V1.Police.Disabled.update(police.id, disabled));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
