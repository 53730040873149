/**
 * Converter for using checkbox inputs as boolean values
 *
 * Usage :
 * ```
 * <Checkbox
 *   name="myField"
 *   value={BoolCheckbox.value}
 *   parse={BoolCheckbox.parse}
 *   format={BoolCheckbox.format}
 * />
 * ```
 */

const value = '1'

function parse(v: readonly string[], _name: string): boolean {
  return v[0] === value
}

function format(v: boolean, _name: string): readonly string[] {
  return v ? [value] : []
}

export const BoolCheckbox = { format, parse, value }
