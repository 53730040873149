import { authenticate } from '../../lib/decorators'
import type { ApiRequestDescriptor } from '../../lib/request'
import { put } from '../../lib/methods'
import type { ClientAbilityJSON } from '../../models/Client/Ability'

export class EmpoweredClient {
  @authenticate
  static update(dealId: number, client_id: number): ApiRequestDescriptor<ClientAbilityJSON> {
    return put(`/api/v1/deals/${dealId}/empowered_client`, { client_id })
  }
}
