import { ReactNode, createElement } from 'react'

import style from './Label.module.scss'
import { LabelTag } from './types'

interface LabelProps {
  className?: string
  children: ReactNode
  tagName?: LabelTag
}

export default function Label({ children, className, tagName = 'span' }: LabelProps): JSX.Element {
  const classes = [style.label]
  if (className) {
    classes.push(className)
  }
  return createElement(tagName, { className: classes.join(' ') }, children)
}
