import type { ApiRequestDescriptor } from '../../lib/request'
import { get, post, put, del } from '../../lib/methods'
import { authenticate, withSessionId } from '../../lib/decorators'
import type { PoliceJSON } from '../../models/Police'
import type { PoliceBody } from './types'
import { serialize } from './serializers'
import { Disabled } from './Disabled'

export class Police {
  static readonly Disabled = Disabled

  @authenticate
  static show(id: number): ApiRequestDescriptor<PoliceJSON> {
    return get(`/api/v1/police/${id}`)
  }

  @authenticate
  static create(police: Omit<PoliceBody, 'id'>): ApiRequestDescriptor<PoliceJSON> {
    return post('/api/v1/police', serialize(police))
  }

  @authenticate
  @withSessionId
  static update(police: PoliceBody): ApiRequestDescriptor<PoliceJSON> {
    return put(`/api/v1/police/${police.id}`, serialize(police))
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<PoliceJSON> {
    return del(`/api/v1/police/${id}`)
  }
}
