// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SavingLayer-module__modal--eKzPM{padding:16px 24px 24px;text-align:justify}.SavingLayer-module__spinner--C_5_Z{margin-top:24px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Funeral/SavingLayer.module.scss"],"names":[],"mappings":"AAAA,kCACE,sBAAA,CACA,kBAAA,CAGF,oCACE,eAAA,CACA,iBAAA","sourcesContent":[".modal {\n  padding: 16px 24px 24px;\n  text-align: justify;\n}\n\n.spinner {\n  margin-top: 24px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `SavingLayer-module__modal--eKzPM`,
	"spinner": `SavingLayer-module__spinner--C_5_Z`
};
export default ___CSS_LOADER_EXPORT___;
