import { Model } from '@advitam/api/models/Model';
import type { FuneralForm } from 'containers/Deal/Funeral/types';

export const NO_PHONE_ERROR = 'email_duplicated';

export function hasPhoneNumber(
  _value: string | null | undefined,
  allValues: FuneralForm,
): string | undefined {
  const hasPhone = allValues.abilities.some(
    ability =>
      !Model.isDestroyed(ability) &&
      (ability.client.phone || ability.client.phone_2),
  );

  if (!hasPhone) {
    return NO_PHONE_ERROR;
  }

  return undefined;
}
