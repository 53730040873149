/*
 * OtherFuneralCompany Messages
 *
 * This contains all the text for the OtherFuneralCompany component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.OtherFuneralCompany.title',
    defaultMessage: 'Autres pompes funèbres',
  },
  funeralCompanyName: {
    id: 'app.components.OtherFuneralCompany.funeralCompanyName',
    defaultMessage: 'Nom',
  },
  funeralCompanyAddress: {
    id: 'app.components.OtherFuneralCompany.funeralCompanyAddress',
    defaultMessage: 'Adresse',
  },
  displayFuneralCompanyAddress: {
    id: 'app.components.OtherFuneralCompany.displayFuneralCompanyAddress',
    defaultMessage: 'Addresse:',
  },
  funeralCompanyFax: {
    id: 'app.components.OtherFuneralCompany.funeralCompanyFax',
    defaultMessage: 'Fax',
  },
  funeralCompanyPhone1: {
    id: 'app.components.OtherFuneralCompany.funeralCompanyPhone1',
    defaultMessage: 'Téléphone 1',
  },
  funeralCompanyPhone2: {
    id: 'app.components.OtherFuneralCompany.funeralCompanyPhone2',
    defaultMessage: 'Téléphone 2',
  },
});
