import { chatbotApiEndpoint } from '../../lib/decorators'
import { post } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { TranslationJSON } from '../../models/Chatbot/Translation'

export const Translations = {
  create: chatbotApiEndpoint(
    (content: string, language_code: string): ApiRequestDescriptor<TranslationJSON> =>
      post('/v1/translations', { content, language_code }),
  ),
}
