import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormState } from 'react-final-form';
import Fuse from 'fuse.js';

import Api from '@advitam/api';
import {
  CoverageJSON,
  CoverageZoneType,
} from '@advitam/api/models/Entity/Coverage';
import type {
  AutocompleteCountriesResult,
  AutocompleteResult,
} from '@advitam/api/v1/Autocompletes';
import { Collapsible, FormUI, FormLayout, Text } from '@advitam/ui';

import { makeSelectDepartments } from 'slices/data';
import actionsMessages from 'messages/actions';

import { makeSelectCanEdit } from '../../../../selectors';
import messages from '../messages';
import { UnsavedCoverage, WarehouseZonesForm } from '../types';
import { isMainland } from './utils';
import {
  ZoneType,
  COVERAGE_TYPE_ZONE_MAP,
  FRANCE_COUNTRY_CODE,
} from './constants';
import style from './Zone.module.scss';

function isAlreadyCovered(
  coverage: Array<CoverageJSON | UnsavedCoverage>,
  value: AutocompleteResult,
  zoneType: CoverageZoneType,
): boolean {
  return Boolean(
    coverage.find(
      zone => zone.zone_id === value.id && zone.zone_type === zoneType,
    ),
  );
}

function getDepartmentDisplayValue(result: AutocompleteResult): string {
  return result.description;
}

interface ManualAdditionProps {
  zoneIndex: number;
  addCoverageItems: (values: Array<CoverageJSON | UnsavedCoverage>) => void;
}

export default function ManualAddition({
  zoneIndex,
  addCoverageItems,
}: ManualAdditionProps): JSX.Element {
  const intl = useIntl();
  const departments = useSelector(makeSelectDepartments());
  const canUserEdit = useSelector(makeSelectCanEdit());
  const [zoneType, setZoneType] = useState<ZoneType>(ZoneType.CITYHALL);

  const { values } = useFormState<WarehouseZonesForm>();
  const { coverage } = values.sectionValues[zoneIndex];

  const onZoneTypeChange = useCallback(
    (value?: ZoneType | ZoneType[]): void => {
      if (value !== undefined && !Array.isArray(value)) {
        setZoneType(value);
      }
    },
    [],
  );

  const onAutocompleteSelect = useCallback(
    (value?: AutocompleteResult): void => {
      const coverageZoneType = COVERAGE_TYPE_ZONE_MAP[zoneType];
      if (value && !isAlreadyCovered(coverage, value, coverageZoneType)) {
        addCoverageItems([
          {
            zone_id: value.id,
            zone_name: value.description,
            zone_type: coverageZoneType,
          },
        ]);
      }
    },
    [addCoverageItems, coverage, zoneType],
  );

  const onCountryAutocompleteSelect = useCallback(
    (value?: AutocompleteCountriesResult) => {
      if (value?.country_code !== FRANCE_COUNTRY_CODE) {
        return;
      }

      const validDepartments = departments.filter(
        department =>
          !isAlreadyCovered(coverage, department, CoverageZoneType.DEPARMENT) &&
          isMainland(department.department_code),
      );

      const newCoverageItems: UnsavedCoverage[] = validDepartments.map(
        department => ({
          zone_id: department.id,
          zone_name: department.description,
          zone_type: COVERAGE_TYPE_ZONE_MAP[zoneType],
        }),
      );

      addCoverageItems(newCoverageItems);
    },
    [addCoverageItems, departments, coverage, zoneType],
  );

  const fetchDepartmentSuggestions = useCallback(
    (value: string) => {
      const results = departments.filter(
        suggestion =>
          !isAlreadyCovered(coverage, suggestion, CoverageZoneType.DEPARMENT),
      );

      const fuse = new Fuse(results, {
        keys: ['description', 'department_code'],
      });
      return Promise.resolve(fuse.search(value).map(result => result.item));
    },
    [departments, coverage],
  );

  const displayedAutocomplete = {
    [ZoneType.CITYHALL]: (
      <FormUI.Autosuggest
        disabled={!canUserEdit}
        value={{ id: -1, description: '' }}
        label={<FormattedMessage id={messages.place.id} />}
        placeholder={intl.formatMessage(actionsMessages.search)}
        onChange={onAutocompleteSelect}
        endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls)}
      />
    ),
    [ZoneType.DEPARTMENT]: (
      <FormUI.AutosuggestBase
        disabled={!canUserEdit}
        value={{ id: -1, description: '', department_code: '' }}
        label={<FormattedMessage id={messages.place.id} />}
        placeholder={intl.formatMessage(actionsMessages.search)}
        onChange={onAutocompleteSelect}
        fetchSuggestions={fetchDepartmentSuggestions}
        getDisplayValue={getDepartmentDisplayValue}
        getSuggestionValue={getDepartmentDisplayValue}
        renderSuggestion={getDepartmentDisplayValue}
      />
    ),
    [ZoneType.COUNTRY]: (
      <FormUI.Autosuggest<AutocompleteCountriesResult>
        disabled={!canUserEdit}
        value={{ id: -1, description: '', name: '', country_code: '' }}
        label={<FormattedMessage id={messages.place.id} />}
        placeholder={intl.formatMessage(actionsMessages.search)}
        onChange={onCountryAutocompleteSelect}
        endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.countries)}
      />
    ),
  };

  return (
    <FormLayout.Row>
      <Collapsible
        borderless
        isOpen
        title={
          <Text tagName="div" className={style.collapsible_title}>
            <FormattedMessage id={messages.additionManual.id} />
            <FormUI.Tooltip
              content={
                <FormattedMessage id={messages.additionManualTooltip.id} />
              }
            />
          </Text>
        }
      >
        <FormLayout.Row>
          <FormUI.Select
            disabled={!canUserEdit}
            value={zoneType}
            items={[
              {
                value: ZoneType.CITYHALL,
                name: intl.formatMessage(messages.cityhall),
              },
              {
                value: ZoneType.DEPARTMENT,
                name: intl.formatMessage(messages.department),
              },
              {
                value: ZoneType.COUNTRY,
                name: intl.formatMessage(messages.country),
              },
            ]}
            label={<FormattedMessage id={messages.type.id} />}
            onChange={onZoneTypeChange}
          />
          {displayedAutocomplete[zoneType]}
        </FormLayout.Row>
      </Collapsible>
    </FormLayout.Row>
  );
}
