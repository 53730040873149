import { get } from 'lodash';
import { CheckoutType } from '@advitam/api/models/CheckoutType';
import { CHECKOUT_TYPE_FIELD } from './constants';

export function getCheckoutType(
  values: Record<string, unknown>,
  prefix = '',
): CheckoutType {
  return get(values, [prefix, CHECKOUT_TYPE_FIELD]);
}
