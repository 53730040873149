import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  commentary: { id: 'app.containers.Supplier.commentary' },
  negociated: { id: 'app.containers.Supplier.negociated' },
  negociatedTooltip: { id: 'app.containers.Supplier.negociatedTooltip' },
  general: { id: 'app.containers.Supplier.general' },
  miscellaneous: { id: 'app.containers.Supplier.miscellaneous' },
  titlePlaceholder: { id: 'app.containers.Supplier.titlePlaceholder' },
  warehouses: { id: 'app.containers.Supplier.warehouses' },
});
