import type { FormApi } from 'final-form'
import { get } from 'object-path'

import { Strings } from '@advitam/support'

export function scrollToError<T>(form: FormApi<T>): void {
  const { hasValidationErrors, errors } = form.getState()
  if (!hasValidationErrors || errors === undefined) {
    return
  }

  const errored = form
    .getRegisteredFields()
    .find(field => typeof get(errors, Strings.tokenizeJavascriptPath(field)) === 'string')
  if (!errored) {
    return
  }

  const input = document.querySelector(`[name='${errored}']`)
  if (!input) {
    return
  }

  if (input.tagName === 'INPUT' && (input as HTMLInputElement).type === 'hidden') {
    input.parentElement?.scrollIntoView({ behavior: 'smooth' })
  } else {
    input.scrollIntoView({ behavior: 'smooth' })
  }
}
