import qs from 'qs';

import { ApiRequestDescriptor, ApiPayload } from './request';

export function get<ResponseType, QueryType = unknown>(
  endpoint: string,
  query?: QueryType,
): ApiRequestDescriptor<ResponseType, undefined> {
  const queryString = query
    ? `?${qs.stringify(query, { arrayFormat: 'brackets' })}`
    : '';
  return { method: 'GET', endpoint: endpoint + queryString, headers: {} };
}

export function post<ResponseType, PayloadType extends ApiPayload>(
  endpoint: string,
  payload?: PayloadType,
): ApiRequestDescriptor<ResponseType, PayloadType> {
  return { method: 'POST', endpoint, payload, headers: {} };
}

export function put<ResponseType, PayloadType extends ApiPayload>(
  endpoint: string,
  payload: PayloadType,
): ApiRequestDescriptor<ResponseType, PayloadType> {
  return { method: 'PUT', endpoint, payload, headers: {} };
}

export function del<ResponseType>(
  endpoint: string,
): ApiRequestDescriptor<ResponseType, undefined> {
  return { method: 'DELETE', endpoint, headers: {} };
}
