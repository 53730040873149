import { ReactNode } from 'react'
import { FormattedDate } from 'react-intl'
import Text from '../../../Text'
import style from '../Step.module.scss'

interface InfosProps {
  date: Date | null
  distance?: ReactNode
}

export default function Infos({ date, distance }: InfosProps): JSX.Element | null {
  return (
    <div className={style.infos}>
      {date && (
        <div className={style.content}>
          <Text large className={style.title}>
            <b>
              <FormattedDate value={date} />
            </b>
          </Text>
          <Text className={style.text}>
            <FormattedDate value={date} hour="2-digit" minute="2-digit" />
          </Text>
          {distance && (
            <Text small className={style.distance}>
              {distance}
            </Text>
          )}
        </div>
      )}
    </div>
  )
}
