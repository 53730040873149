import PropTypes from 'prop-types';

const TrashIcon = ({ isActivated }) => (
  <svg
    id="trashIcon"
    x={0}
    y={0}
    viewBox="0 0 28 32"
    xmlSpace="preserve"
    className={`${isActivated ? 'icon--on' : 'icon--off'}`}
  >
    <path d="M2 24v6c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V10H2v14zM26 4h-6V2c0-1.1-.9-2-2-2h-8C8.9 0 8 .9 8 2v2H2C.9 4 0 4.9 0 6v2h28V6c0-1.1-.9-2-2-2z" />
  </svg>
);

TrashIcon.propTypes = {
  isActivated: PropTypes.bool,
};

export default TrashIcon;
