import { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Display a multi select
 */
export default class MultiSelectDisplay extends PureComponent {
  /**
   * Display the delete button.
   *
   * @param   {Number}    id      id of object to remove
   * @param   {Object}    value   Object to remove
   * @returns {Component}
   */
  renderDeleteButton = (id, value) => {
    const { onRemove, setDeleteFunction } = this.props;

    return (
      <button
        onClick={() => setDeleteFunction(() => onRemove(id, value))}
        type="button"
        className="CRUD__crossButton"
      >
        ×
      </button>
    );
  };

  render() {
    const { isReadingMode, values, formatName } = this.props;

    return (
      <div className="CRUD__multiSelectDisplay">
        {values.map(value => (
          <div
            className="CRUD__multiSelectDisplay__typeSelected"
            key={value.id}
          >
            {formatName ? <b>{formatName(value)}</b> : <b>{value.name}</b>}
            {!isReadingMode && this.renderDeleteButton(value.id, value)}
          </div>
        ))}
      </div>
    );
  }
}

MultiSelectDisplay.propTypes = {
  /** The state of isReadingMode */
  isReadingMode: PropTypes.bool.isRequired,
  /** Values selected by user */
  values: PropTypes.array.isRequired,
  /** Function when user want remove a value */
  onRemove: PropTypes.func.isRequired,
  /** Function to display confirm modal before remove something */
  setDeleteFunction: PropTypes.func.isRequired,
  /** Function to format name in label */
  formatName: PropTypes.func,
};

MultiSelectDisplay.defaultProps = {
  formatName: null,
};
