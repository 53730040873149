import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  stayTypeFilter: {
    id: 'app.containers.FuneralParlor.sections.Stays.stayTypeFilter',
  },
  stayTypeClassic: {
    id: 'app.containers.FuneralParlor.sections.Stays.stayTypeClassic',
  },
  stayTypePremium: {
    id: 'app.containers.FuneralParlor.sections.Stays.stayTypePremium',
  },
  stayTypeClosedCoffin: {
    id: 'app.containers.FuneralParlor.sections.Stays.stayTypeClosedCoffin',
  },
  add: {
    id: 'app.containers.FuneralParlor.sections.Stays.add',
  },

  newStay: {
    id: 'app.containers.FuneralParlor.sections.Stays.newStay',
  },
  name: {
    id: 'app.containers.FuneralParlor.sections.Stays.name',
  },
  namePlaceholder: {
    id: 'app.containers.FuneralParlor.sections.Stays.namePlaceholder',
  },
  stayType: {
    id: 'app.containers.FuneralParlor.sections.Stays.stayType',
  },
  hasDepartureRoom: {
    id: 'app.containers.FuneralParlor.sections.Stays.hasDepartureRoom',
  },
  hasDepartureRoomTooltip: {
    id: 'app.containers.FuneralParlor.sections.Stays.hasDepartureRoomTooltip',
  },
  price: {
    id: 'app.containers.FuneralParlor.sections.Stays.price',
  },
  priceTooltip: {
    id: 'app.containers.FuneralParlor.sections.Stays.priceTooltip',
  },
  baseDays: {
    id: 'app.containers.FuneralParlor.sections.Stays.baseDays',
  },
  baseDaysTooltip: {
    id: 'app.containers.FuneralParlor.sections.Stays.baseDaysTooltip',
  },
  pricePerDay: {
    id: 'app.containers.FuneralParlor.sections.Stays.pricePerDay',
  },
  pricePerDayTooltip: {
    id: 'app.containers.FuneralParlor.sections.Stays.pricePerDayTooltip',
  },
  description: {
    id: 'app.containers.FuneralParlor.sections.Stays.description',
  },
  select: {
    id: 'app.containers.FuneralParlor.sections.Stays.select',
  },
  delete: {
    id: 'app.containers.FuneralParlor.sections.Stays.delete',
  },

  deletionConfirmationText: {
    id: 'app.containers.FuneralParlor.sections.Stays.deletionConfirmationText',
  },
  deletionConfirmationConfirm: {
    id:
      'app.containers.FuneralParlor.sections.Stays.deletionConfirmationConfirm',
  },
  deletionConfirmationCancel: {
    id:
      'app.containers.FuneralParlor.sections.Stays.deletionConfirmationCancel',
  },
});
