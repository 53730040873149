import PropTypes from 'prop-types';

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import H2 from 'components/H2';
import { ButtonV2 as Button } from 'components/Button';
import {
  makeSelectChildren,
  makeSelectHeaderMessage,
  makeSelectOptions,
  makeSelectOnRequestClose,
} from './selectors';

class DialogBox extends PureComponent {
  render() {
    const {
      onRequestClose,
      headerMessage,
      children,
      options,
      isOpen,
      className,
      zIndex,
    } = this.props;

    const styles = {
      overlay: {
        zIndex: zIndex || '10',
      },
      content: {
        position: 'initial',
        overflow: 'visible',
        borderRadius: '3px',
        padding: '10px',
        margin: '100px auto',
      },
    };
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="dialog"
        style={styles}
        ariaHideApp={false}
        className={`modal ${className || ''}`}
      >
        <H2 dark>
          <FormattedMessage {...headerMessage} />
        </H2>
        {children}
        {options &&
          options.map((option, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <Button onClick={option.cb} key={key}>
              <FormattedMessage {...option.msg} />
            </Button>
          ))}
      </Modal>
    );
  }
}

DialogBox.propTypes = {
  children: PropTypes.element,
  headerMessage: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      msg: PropTypes.object.isRequired,
      cb: PropTypes.func.isRequired,
    }),
  ),
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  // String to specify the zIndex
  zIndex: PropTypes.string,
};

DialogBox.defaultProps = {
  isOpen: true,
};

const mapStateToProps = createStructuredSelector({
  children: makeSelectChildren(),
  headerMessage: makeSelectHeaderMessage(),
  options: makeSelectOptions(),
  onRequestClose: makeSelectOnRequestClose(),
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
}

const withConnect = connect(mapStateToProps, null, mergeProps);

export default compose(withConnect)(DialogBox);
