import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';

import { TransparentButton, ButtonV2 as Button } from 'components/Button';
import TrashIcon from 'images/icons/trash.svg';

import { deleteItem, updateItem } from '../../thunk';
import TodoForm from '../Form';
import TaskRow from './Row';
import Label from './Label';
import style from './style.scss';

interface DefaultProps {
  item: TodoItem;
}

export default function DefaultTask({ item }: DefaultProps): JSX.Element {
  const dispatch = useDispatch();
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  if (isEditFormOpen) {
    return (
      <TaskRow item={item}>
        <TodoForm
          currentTitle={item.title}
          closeForm={(): void => setIsEditFormOpen(false)}
          onSave={(title: string): void => {
            dispatch(updateItem({ ...item, title }));
          }}
        />
        <Button
          className={`button--red button--svg ${style.delete}`}
          onClick={(): void => {
            dispatch(deleteItem(item));
          }}
        >
          <TrashIcon />
        </Button>
      </TaskRow>
    );
  }

  if (!item.todo_item_id) {
    return (
      <TaskRow item={item}>
        <TransparentButton onClick={(): void => setIsEditFormOpen(true)}>
          <Label title={item.title} isDone={item.isDone} />
        </TransparentButton>
      </TaskRow>
    );
  }

  return (
    <TaskRow item={item}>
      <Label title={item.title} isDone={item.isDone} />
    </TaskRow>
  );
}
