import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  additionAuto: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.additionAuto',
  },
  additionAutoTooltip: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.additionAutoTooltip',
  },
  additionManual: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.additionManual',
  },
  additionManualTooltip: {
    id:
      'app.containers.Supplier.Warehouse.sections.Zones.additionManualTooltip',
  },
  address: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.address',
  },
  addZone: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.addZone',
  },
  centerAddress: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.centerAddress',
  },
  centerType: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.centerType',
  },
  centerTypeTooltip: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.centerTypeTooltip',
  },
  cityhall: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.cityhall',
  },
  country: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.country',
  },
  coveredZone: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.coveredZone',
  },
  department: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.department',
  },
  displayMore: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.displayMore',
  },
  place: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.place',
  },
  radius: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.radius',
  },
  synchronizeMap: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.synchronizeMap',
  },
  title: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.title',
  },
  type: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.type',
  },
  warehouse: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.warehouse',
  },
  warehouseDisabled: {
    id: 'app.containers.Supplier.Warehouse.sections.Zones.warehouseDisabled',
  },
});
