import { createAction } from 'lib/reactvitam/action_cable';

export interface DestroyPayload {
  user_id: number;
}

/**
 * The resource have been destroyed
 * Not for sending by the frontend, this is only the interface for reception of
 * backend messages.
 */
function destroy(user_id: number): DestroyPayload {
  return { user_id };
}

export default createAction(destroy, 'destroy');
