import EditableDocuments from './EditableDocuments';
import General from './General';
import Miscellaneous from './Miscellaneous';
import OpeningHours from './OpeningHours';
import Stays from './Stays';
import SupportingDocuments from './SupportingDocuments';

export default {
  General,
  OpeningHours,
  EditableDocuments,
  SupportingDocuments,
  Miscellaneous,
  Stays,
};
