import { ApiError, SerializedApiError } from '@advitam/api';
import { createSlice } from '@reduxjs/toolkit';
import { DATA, Datasets } from './constants';
import { fetchDatasets } from './thunk';

export interface State {
  datasets: Partial<Datasets>;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DATA]: State;
}

const initialState: State = {
  datasets: {},
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: DATA,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDatasets.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchDatasets.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.datasets = { ...state.datasets, ...payload };
    });
    builder.addCase(fetchDatasets.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError } = slice.actions;
export default slice;
