import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { withSlice } from '@advitam/react';
import { HardSpinner, MessageModal, PageSpinner } from '@advitam/ui';
import actionsMessages from 'messages/actions';

import Wrapper from '../Wrapper';
import { CrematoriumRoomsForm } from './types';
import { fetchRooms, saveRooms } from './thunk';
import {
  makeSelectHasAlreadyInUseError,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectRooms,
} from './selectors';
import slice, { clearHasAlreadyInUseError } from './slice';
import RoomsContainer from './Container';
import messages from './messages';

function Rooms(): JSX.Element {
  const dispatch = useDispatch();

  const rooms = useSelector(makeSelectRooms());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const hasAlreadyInUseError = useSelector(makeSelectHasAlreadyInUseError());

  const initialFetch = useCallback(() => {
    dispatch(fetchRooms());
  }, [dispatch]);

  const onSubmit = useCallback(
    (values: CrematoriumRoomsForm): void => {
      const { sectionValues: formRooms } = values;
      dispatch(saveRooms(formRooms));
    },
    [dispatch],
  );

  const onCloseErrorInUse = useCallback(() => {
    dispatch(clearHasAlreadyInUseError());
  }, [dispatch]);

  useEffect(() => initialFetch(), []);

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={rooms}>
      {isLoading ? <PageSpinner /> : <RoomsContainer />}
      {isSaving && <HardSpinner />}
      <MessageModal
        isOpen={hasAlreadyInUseError}
        title={<FormattedMessage id={messages.errorRoomInUseModalTitle.id} />}
        message={<FormattedMessage id={messages.errorRoomInUseModalText.id} />}
        ctaText={<FormattedMessage id={actionsMessages.close.id} />}
        onClose={onCloseErrorInUse}
      />
    </Wrapper>
  );
}

export default withSlice(slice)(Rooms);
