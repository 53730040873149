import type { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Civility } from '@advitam/api/models/Person/Civility';
import {
  Collapsible,
  FormLayout,
  GooglePlaceInput,
  Input,
  Select,
} from '@advitam/ui';
import { assert } from 'lib/support';
import actionMessages from 'messages/actions';

import { makeSelectDeal } from '../../../selectors.typed';
import messages from './messages';
import style from './Form.module.scss';

interface DefunctParentProps {
  prefix: 'father' | 'mother' | 'partner';
  pictogram: ReactElement;
}

export default function DefunctParent({
  prefix,
  pictogram,
}: DefunctParentProps): JSX.Element {
  const intl = useIntl();

  const deal = useSelector(makeSelectDeal());
  assert(deal?.defunct !== undefined);
  const person = deal.defunct?.defunctCompInfo[prefix];

  return (
    <Collapsible
      isOpen={false}
      title={
        <div className={style.section_title}>
          {person && <b>{person.formattedName(intl)}</b>}
          {pictogram}
        </div>
      }
      className={style.fieldset}
    >
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.civility.id} />}
          name={`deal.defunctInfo.defunctCompInfo.${prefix}.type`}
          unselectable
          items={[
            { name: intl.formatMessage(messages.mr), value: Civility.MR },
            { name: intl.formatMessage(messages.mrs), value: Civility.MRS },
          ]}
        />
        <Input
          label={<FormattedMessage id={messages.firstname.id} />}
          name={`deal.defunctInfo.defunctCompInfo.${prefix}.firstName`}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.lastname.id} />}
          name={`deal.defunctInfo.defunctCompInfo.${prefix}.lastName`}
        />
        <Input
          label={<FormattedMessage id={messages.birthName.id} />}
          name={`deal.defunctInfo.defunctCompInfo.${prefix}.birthName`}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <GooglePlaceInput
          label={<FormattedMessage id={messages.address.id} />}
          name={`deal.defunctInfo.defunctCompInfo.${prefix}.address`}
          onlyFrance={false}
        />
        <Input
          label={<FormattedMessage id={messages.city.id} />}
          name={`deal.defunctInfo.defunctCompInfo.${prefix}.address.city`}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.postalCode.id} />}
          name={`deal.defunctInfo.defunctCompInfo.${prefix}.address.postal_code`}
        />
        <Select
          label={<FormattedMessage id={messages.deceased.id} />}
          name={`deal.defunctInfo.defunctCompInfo.${prefix}.dead`}
          unselectable
          items={[
            { name: intl.formatMessage(actionMessages.yes), value: true },
            { name: intl.formatMessage(actionMessages.no), value: false },
          ]}
        />
      </FormLayout.Row>
      {!person.dead && (
        <FormLayout.Row>
          <Input
            label={<FormattedMessage id={messages.work.id} />}
            name={`deal.defunctInfo.defunctCompInfo.${prefix}.work`}
          />
          <div />
        </FormLayout.Row>
      )}
    </Collapsible>
  );
}
