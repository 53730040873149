import { createSelector, Selector } from 'reselect';

import type { SupplierJSON } from '@advitam/api/models/Supplier';

import { PRESTATION_FORM } from './constants';
import { AppStateSubset, State } from './slice';
import { Entity } from './types';

type PrestationFormSelector<T> = Selector<AppStateSubset, T>;

const selectPrestationFormDomain = (state: AppStateSubset): State =>
  state[PRESTATION_FORM];

export const makeSelectEntity = (): PrestationFormSelector<Entity | null> =>
  createSelector(selectPrestationFormDomain, ({ entity }) => entity);

export const makeSelectSupplier = (): PrestationFormSelector<SupplierJSON | null> =>
  createSelector(selectPrestationFormDomain, ({ supplier }) => supplier);

export const makeSelectIsLoading = (): PrestationFormSelector<boolean> =>
  createSelector(selectPrestationFormDomain, ({ isLoading }) => isLoading);
