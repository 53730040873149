import { ReactNode } from 'react'

import Card from '../Card/Flow'
import style from './FlowLayout.module.scss'

interface CardContent {
  title: ReactNode
  text?: ReactNode
}

interface FlowLayoutProps {
  contentList: CardContent[]
  className?: string
}

export default function FlowLayout({ contentList, className = '' }: FlowLayoutProps): JSX.Element {
  return (
    <div className={`col-4 col-md-8 col-xxl-10 ${style.container} ${className}`}>
      {contentList.map((content, index) => (
        <Card key={index} index={index + 1} title={content.title} text={content.text} />
      ))}
    </div>
  )
}
