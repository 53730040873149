import { FormattedMessage } from 'react-intl'

import { Objects } from '@advitam/support'

import Logo from '../../images/logo.svg'
import Facebook from '../../images/icons/facebook.svg'
import X from '../../images/icons/x.svg'
import { I18nPath, Url } from '../../constants'

import Link from './parts/Link'
import CookieLink from './parts/CookieLink'
import BaseFooter from './Footer'
import type { Bottom, Lang, SocialLink } from './types'
import { COOKIE_HASH, bottomFooterLinks, footerLinks } from './constants'
import messages from './messages'

interface FooterPropsBase {
  hideCopyright?: boolean
  internalLinks?: boolean
  lang?: Lang
  locale: keyof typeof I18nPath
  // Only display the lower part
  small?: boolean
}

interface FooterPropsWithGoogleReviews extends FooterPropsBase {
  googleRate: number
  reviewsLink: string
  reviewsLinkInternal?: boolean
}

interface FooterPropsWithoutGoogleReviews extends FooterPropsBase {
  googleRate?: undefined
  reviewsLink?: undefined
  reviewsLinkInternal?: undefined
}

type FooterProps = FooterPropsWithGoogleReviews | FooterPropsWithoutGoogleReviews

export default function Footer({
  locale,
  hideCopyright = false,
  internalLinks = true,
  small = false,
  googleRate,
  reviewsLink,
  reviewsLinkInternal,
  lang,
}: FooterProps): JSX.Element {
  const bottomLinks = bottomFooterLinks[locale] || bottomFooterLinks.en
  const bottom: Bottom = {
    copyright: !hideCopyright && (
      <b>
        <FormattedMessage id={messages.copyright.id} />
      </b>
    ),
    links: bottomLinks.map(link => {
      if (link.href === COOKIE_HASH) {
        return <CookieLink key={COOKIE_HASH} text={<FormattedMessage id={link.textKey} />} />
      }
      return (
        <Link
          key={link.href}
          small
          newTab
          text={<FormattedMessage id={link.textKey} />}
          href={link.href}
        />
      )
    }),
  }

  const rawLinks = footerLinks[locale] || footerLinks.en
  const linksGroups = rawLinks.map(group => ({
    title: (
      <b>
        <FormattedMessage id={group.titleKey} />
      </b>
    ),
    links: group.links.map(link => ({
      ...Objects.omit(link, 'textKey'),
      text: <FormattedMessage id={link.textKey} />,
      internal: link.internal && internalLinks,
    })),
  }))

  const socialLinks: SocialLink[] = [
    {
      icon: <Facebook />,
      href: Url.FACEBOOK,
    },
    {
      icon: <X />,
      href: Url.X,
    },
  ]

  return (
    <BaseFooter
      googleRate={googleRate as number}
      reviewsLink={reviewsLink as string}
      reviewsLinkInternal={reviewsLinkInternal}
      small={small}
      logo={<Logo />}
      description={<FormattedMessage id={messages.description.id} />}
      linksGroups={linksGroups}
      bottom={bottom}
      socialLinks={socialLinks}
      lang={lang}
    />
  )
}
