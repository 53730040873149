type ParamValue = string | number

export function create(params: Record<string, ParamValue | ParamValue[]>): URLSearchParams {
  const searchParams = new URLSearchParams()

  Object.entries(params).forEach(([param, values]) => {
    if (Array.isArray(values)) {
      values.forEach(paramValue => searchParams.append(`${param}[]`, paramValue.toString()))
    } else {
      searchParams.append(param, values.toString())
    }
  })

  return searchParams
}
