import { ApiError, SerializedApiError } from '@advitam/api';
import { createSlice } from '@reduxjs/toolkit';
import { DECEASED_MODAL } from './constants';
import { makeDeceased } from './thunk';

export interface State {
  isOpen: boolean;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DECEASED_MODAL]: State;
}

const initialState: State = {
  isOpen: false,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: DECEASED_MODAL,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    open: state => {
      state.isOpen = true;
    },
    close: state => {
      state.isOpen = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(makeDeceased.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(makeDeceased.fulfilled, state => {
      state.isLoading = false;
      state.isOpen = false;
    });
    builder.addCase(makeDeceased.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { open, close } = slice.actions;
export default slice;
