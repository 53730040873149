import { createSelector, Selector } from 'reselect';
import moment from 'moment';

import { FuneralTeamUserJSON } from '@advitam/api/models/FuneralTeamUser';
import {
  AppStateSubset as DataAppStateSubset,
  makeSelectFuneralTeam,
} from 'slices/data';

import { RESOURCE_CHANNEL_KEY } from './constants';
import type { AppStateSubset, State, UserInfos } from '.';

type ResourceSelector<T> = Selector<AppStateSubset, T>;

export const selectResourceDomain = (
  state: AppStateSubset,
): State | undefined => state[RESOURCE_CHANNEL_KEY];

export const makeSelectSessionId = (): ResourceSelector<
  string | null | undefined
> => createSelector(selectResourceDomain, substate => substate?.sessionId);

export const makeSelectUsers = (): ResourceSelector<
  Record<number, UserInfos> | undefined
> => createSelector(selectResourceDomain, substate => substate?.users);

export const makeSelectUser = (
  userId: number,
): ResourceSelector<UserInfos | undefined> =>
  createSelector(makeSelectUsers(), users => users && users[userId]);

export const makeSelectIsUserFocused = (
  userId: number,
): ResourceSelector<boolean> =>
  createSelector(makeSelectUser(userId), user => {
    if (!user) {
      return false;
    }
    return user.sessions.some(session => session.isFocused);
  });

export const makeSelectLastUserActivity = (
  userId: number,
): ResourceSelector<string | undefined> =>
  createSelector(makeSelectUser(userId), user => user?.lastAction);

/**
 * Whether the user is considered active on the resource
 *
 * @param userId ID of the user
 * @param _checkCount Dummy parameter that allows to bypass memoization and
 * thus recompute the result
 */
export const makeSelectIsUserActive = (
  userId: number,
  _checkCount?: number,
): ResourceSelector<boolean> =>
  createSelector(
    makeSelectLastUserActivity(userId),
    makeSelectIsUserFocused(userId),
    (lastActivity, isFocused) => {
      if (!lastActivity) {
        return false;
      }
      if (isFocused) {
        return moment().subtract(5, 'minutes').isBefore(lastActivity);
      }
      return moment().subtract(1, 'minutes').isBefore(lastActivity);
    },
  );

export const makeSelectUpdatingUserId = (): ResourceSelector<number | null> =>
  createSelector(selectResourceDomain, state => state?.updatingUserId || null);

type ResourceWithDataSelector<T> = Selector<
  AppStateSubset & DataAppStateSubset,
  T
>;

export const makeSelectUpdatingUser = (): ResourceWithDataSelector<FuneralTeamUserJSON | null> =>
  createSelector(
    makeSelectUpdatingUserId(),
    makeSelectFuneralTeam(),
    (userId, team) => {
      if (!userId) {
        return null;
      }
      return team.find(user => user.id === userId) || null;
    },
  );
