import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  administrativeAddress: {
    id: 'app.containers.Crud.Fields.Details.administrativeAddress',
  },
  administrativeName: {
    id: 'app.containers.Crud.Fields.Details.administrativeName',
  },
  city: { id: 'app.containers.Crud.Fields.Details.city' },
  country: { id: 'app.containers.Crud.Fields.Details.country' },
  inseeCode: { id: 'app.containers.Crud.Fields.Details.inseeCode' },
  postalCode: { id: 'app.containers.Crud.Fields.Details.postalCode' },
  siret: { id: 'app.containers.Crud.Fields.Details.siret' },
  title: { id: 'app.containers.Crud.Fields.Details.title' },
  vatNumber: { id: 'app.containers.Crud.Fields.Details.vatNumber' },
  shortName: { id: 'app.containers.Crud.Fields.Details.shortName' },
  website: { id: 'app.containers.Crud.Fields.Details.website' },
});
