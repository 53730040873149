import { ModelType } from 'models/Model';

export const AUTOCOMPLETE_ARS = 'rha';
export const AUTOCOMPLETE_POLICES = 'polices';
export const AUTOCOMPLETE_FUNERAL_PARLOR = 'funeral_parlors';
export const AUTOCOMPLETE_SUPPLIER_WAREHOUSE = 'supplier_warehouses';
export const AUTOCOMPLETE_CONSULATE = 'consulates';

export const ENTITIES = [
  ModelType.CREMATORIUM,
  ModelType.CITYHALL,
  ModelType.WORSHIP,
  ModelType.RHA,
  ModelType.PREFECTURE,
  ModelType.FUNERAL_PARLOR,
  ModelType.GRAVEYARD,
  ModelType.HOSPITAL,
  ModelType.POLICE,
  ModelType.CONSULATE,
];

export const ENTITIES_WITH_SUPPLIER = [...ENTITIES, ModelType.SUPPLIER];

export const ENTITIES_WITH_SUPPLIER_WAREHOUSE = [
  ...ENTITIES,
  ModelType.SUPPLIER_WAREHOUSE,
];
