import { EditableDocumentJSON } from '@advitam/api/models/Documents/Editable';
import { DocumentDealType } from '@advitam/api/models/Documents/Editable/DealType';
import { DocumentGenerationType } from '@advitam/api/models/Documents/Editable/GenerationType';

export const CRUD_DOCUMENTS = 'Crud/Documents/Editable';

export function editableDocumentSkeleton(
  ownerRuleTag: string,
): EditableDocumentJSON {
  return {
    deal_type: DocumentDealType.BOTH,
    generation_type: DocumentGenerationType.IDENTICAL,
    owners: [{ entity_rule_tag: ownerRuleTag }],
  } as EditableDocumentJSON;
}
