import { Browsers } from '@advitam/support'
import { DateTime, Duration, DurationUnit } from 'luxon'

const TOKEN_STORE = '_auth_tokens'

export interface Tokens {
  client: string
  'access-token': string
  'token-type': string
  uid: string
  expiry: string
}

export function deleteTokens(): void {
  if (process.browser) {
    Browsers.storage.removeItem(TOKEN_STORE)
  }
}

export function getTokensFromResponse(response: Response): Tokens {
  const tokenNames: (keyof Tokens)[] = ['access-token', 'client', 'expiry', 'uid']
  const tokens = {} as Tokens
  tokenNames.forEach(tokenName => {
    const tokenValue = response.headers.get(tokenName)
    if (!tokenValue) return
    tokens[tokenName] = tokenValue
  })
  return tokens
}

export function updateTokens(tokens: Tokens): void {
  if (!process.browser || !tokens) {
    return
  }
  Browsers.storage.setItem(TOKEN_STORE, JSON.stringify(tokens))
}

export function updateTokensFromResponse(response: Response): void | Tokens {
  const tokens = getTokensFromResponse(response)
  if (Object.keys(tokens).length === 0) return
  updateTokens({ ...tokens, 'token-type': 'Bearer' })
}

export function getTokens(): Tokens | Record<string, never> {
  if (!process.browser) {
    return {}
  }

  const tokens = Browsers.storage.getItem(TOKEN_STORE)
  if (!tokens) {
    return {}
  }

  return JSON.parse(tokens) as Tokens
}

export function hasTokens(): boolean {
  const tokens = getTokens()
  return Object.keys(tokens).length > 0
}

export function getTokensExpiration(unit: DurationUnit = 'hours'): Duration {
  const tokens = getTokens()
  if (!tokens.expiry) {
    return Duration.fromObject({ [unit]: 0 })
  }
  return DateTime.fromSeconds(parseInt(tokens.expiry, 10)).diff(DateTime.now(), unit)
}
