import { FormattedMessage, useIntl } from 'react-intl';

import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import { DimensionsInput, FormLayout, NumberInput, Select } from '@advitam/ui';

import { Layout } from 'containers/Crud';
import crudMessages from 'containers/Crud/messages';
import actionMessages from 'messages/actions';

import messages from './messages';

export default function Functioning(): JSX.Element {
  const intl = useIntl();

  const booleanOptions: SelectableItem[] = [
    { name: intl.formatMessage(actionMessages.yes), value: true },
    { name: intl.formatMessage(actionMessages.no), value: false },
  ];

  const ceremonyMasterOptions: SelectableItem[] = [
    { name: intl.formatMessage(actionMessages.yes), value: 'yes' },
    { name: intl.formatMessage(actionMessages.no), value: 'no' },
    { name: intl.formatMessage(messages.optional), value: 'optional' },
  ];

  const contactMakingOptions: SelectableItem[] = [
    { name: intl.formatMessage(messages.family), value: true },
    { name: intl.formatMessage(messages.crematorium), value: false },
  ];

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.functioning.id} />}>
      <FormLayout.Row>
        <Select
          unselectable
          name="crematorium.is_live_default"
          items={booleanOptions}
          label={<FormattedMessage id={messages.liveCremation.id} />}
          tooltip={<FormattedMessage id={messages.liveCremationTooltip.id} />}
        />
        <Select
          unselectable
          name="crematorium.conviviality_room"
          items={booleanOptions}
          label={<FormattedMessage id={messages.convivialityRoom.id} />}
          tooltip={
            <FormattedMessage id={messages.convivialityRoomSelectTooltip.id} />
          }
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          name="crematorium.provides_ceremony_master"
          items={ceremonyMasterOptions}
          label={<FormattedMessage id={messages.ceremonyMaster.id} />}
          tooltip={<FormattedMessage id={messages.ceremonyMasterTooltip.id} />}
        />
        <Select
          unselectable
          name="crematorium.family_has_to_contact"
          items={contactMakingOptions}
          label={<FormattedMessage id={messages.contactMaking.id} />}
          tooltip={<FormattedMessage id={messages.contactMakingTooltip.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          name="crematorium.urn_conservation_free_period"
          label={
            <FormattedMessage id={messages.urnFreeConservationDuration.id} />
          }
          tooltip={
            <FormattedMessage
              id={messages.urnFreeConservationDurationTooltip.id}
            />
          }
          suffix={<FormattedMessage id={crudMessages.unitMonth.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          name="crematorium.urn_returning_schedule"
          label={
            <FormattedMessage id={messages.urnMaxConservationDuration.id} />
          }
          tooltip={
            <FormattedMessage
              id={messages.urnMaxConservationDurationTooltip.id}
            />
          }
          suffix={<FormattedMessage id={crudMessages.unitMonth.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <DimensionsInput
          names={[
            'crematorium.length_limit',
            'crematorium.width_limit',
            'crematorium.height_limit',
          ]}
          label={<FormattedMessage id={messages.ovenDimensions.id} />}
          tooltip={<FormattedMessage id={messages.ovenDimensionsTooltip.id} />}
          placeholder={intl.formatMessage(messages.ovenDimensionsPlaceholder)}
          suffix={<FormattedMessage id={crudMessages.unitCentimeter.id} />}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
