import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Props } from 'react-intl/src/components/message';

import { ButtonV2 as Button } from 'components/Button';
import apiMessages from 'api/messages';

import messages from './messages';

export interface ErrorProps {
  message?: Array<string | Props> | string | Props;
  errorCodes?: string[];
  status?: string | number;
}

interface ErrorModalProps {
  error?: ErrorProps | null;
  isFullWidth?: boolean;
}

/**
 * The red error banner, used in deals when consolidation fails for example
 */
export default function ErrorModal({
  error,
  isFullWidth,
}: ErrorModalProps): JSX.Element {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleIsCollapsed = (): void => setIsCollapsed(!isCollapsed);

  const errorMessage = error && (error.errorCodes || error.message);
  const isCollapsible = error && Array.isArray(errorMessage);
  useEffect(() => {
    setIsOpen(!!error);
  }, [error]);

  const renderCollapse = (): JSX.Element | null => {
    if (!isCollapsible) {
      return null;
    }

    return (
      <i className="material-icons">
        {isCollapsed ? 'expand_more' : 'expand_less'}
      </i>
    );
  };

  const formatMessage = (message: Props): ReactNode =>
    intl.formatMessage(message, message.values);

  const translate = (err?: Props | string | null): ReactNode => {
    if (typeof err === 'string') {
      if (apiMessages[err as keyof typeof apiMessages]) {
        return formatMessage(apiMessages[err as keyof typeof apiMessages]);
      }
      return err;
    }
    return formatMessage(err || messages[500]);
  };

  const renderError = (): JSX.Element | null => {
    if (!error) {
      return null;
    }

    if (Array.isArray(errorMessage)) {
      if (isCollapsed) {
        return null;
      }

      /* eslint-disable react/no-array-index-key */
      return (
        <>
          {errorMessage.map((err: string | Props, index: number) => (
            <div key={index}>{translate(err)}</div>
          ))}
        </>
      );
      /* eslint-enable: react/no-array-index-key */
    }

    return <div>{translate(errorMessage)}</div>;
  };

  return (
    <div
      className={`${isFullWidth ? 'errorModal--fullWidth' : ''} ${
        isOpen ? 'errorModal errorModal--open' : 'errorModal'
      }`}
    >
      {error && (
        <div>
          <div
            className={`errorModal__title ${
              isCollapsible ? 'errorModal__title--collapsible' : ''
            }`}
            onClick={toggleIsCollapsed}
            role="button"
            tabIndex={0}
            onKeyDown={(): null => null}
          >
            {error.status && <FormattedMessage {...messages[error.status]} />}
            {renderCollapse()}
          </div>
          {renderError()}
        </div>
      )}
      <Button
        className="button--transparent button--small"
        onClick={(): void => setIsOpen(false)}
      >
        ✖
      </Button>
    </div>
  );
}
