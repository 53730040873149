import { assert } from '@advitam/support'

import { authenticate, withSessionId } from '../../../lib/decorators'
import { del, get, post, put } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { CeremonyJSON, CeremonyType, CoffinWoodType } from '../../../models/Crematorium/Ceremony'

export interface CrematoriumCeremonyFilters {
  age?: number
  age_range_id?: number
  ceremony_type?: CeremonyType
  room_id?: number
  coffin_wood_type?: CoffinWoodType
}

export class Ceremonies {
  @authenticate
  static index(
    crematoriumId: number,
    filters?: CrematoriumCeremonyFilters,
  ): ApiRequestDescriptor<CeremonyJSON[]> {
    return get(`/api/v1/crematoriums/${crematoriumId}/ceremonies`, filters)
  }

  @authenticate
  @withSessionId
  static create(
    crematoriumId: number,
    ceremony: Omit<CeremonyJSON, 'id'>,
  ): ApiRequestDescriptor<CeremonyJSON> {
    return post(`/api/v1/crematoriums/${crematoriumId}/ceremonies`, ceremony)
  }

  @authenticate
  @withSessionId
  static update(ceremony: CeremonyJSON): ApiRequestDescriptor<CeremonyJSON> {
    assert(ceremony.id !== undefined)
    return put(`/api/v1/crematoriums/ceremonies/${ceremony.id}`, ceremony)
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/crematoriums/ceremonies/${id}`)
  }
}
