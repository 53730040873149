import Text from '../../Text'
import { LinksGroup } from '../types'
import Link from './Link'

export default function FooterLinksGroup({ title, links }: LinksGroup): JSX.Element {
  return (
    <>
      <Text small>{title}</Text>
      <ul>
        {links.map((link, idx) => (
          <li key={idx}>
            <Link
              text={link.text}
              href={link.href}
              internal={link.internal}
              newTab={link.newTab}
              small={link.small}
            />
          </li>
        ))}
      </ul>
    </>
  )
}
