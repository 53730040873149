export const GET_STATS = 'app/Account/GET_STATS';
export const SET_STATS = 'app/Account/SET_STATS';

export const GET_STATS_PER_MONTH = 'app/Account/GET_STATS_PER_MONTH';
export const SET_STATS_PER_MONTH = 'app/Account/SET_STATS_PER_MONTH';

export const ERROR = 'app/Account/ERROR';

export const dealsLevels = [1, 10, 50, 100, 500, 1000];
export const dealsErrorsLevels = [1, 2, 3, 4, 5];
