import { createSelector, Selector } from 'reselect';

import { Client, ClientRole, ClientJSON } from 'models/Client';

import { CLIENTS_SPACE } from './constants';
import { State, AppStateSubset } from './slice';
import { LinksDirtyStruct } from './model';

type ClientSelector<T> = Selector<AppStateSubset, T>;

export const selectDealClientsDomain = (state: AppStateSubset): State =>
  state && state[CLIENTS_SPACE];

export const makeSelectRawDealClients = (): ClientSelector<ClientJSON[]> =>
  createSelector(selectDealClientsDomain, state => state?.clients);

export const makeSelectDealClients = (): ClientSelector<Client[]> =>
  createSelector(makeSelectRawDealClients(), clients =>
    clients?.map(c => new Client(c)),
  );

export const makeSelectRealClientCount = (): ClientSelector<number> =>
  createSelector(
    makeSelectDealClients(),
    clients => clients.filter(c => !c.isDestroyed).length,
  );

export const makeSelectDirtyClientIndices = (): ClientSelector<number[]> =>
  createSelector(selectDealClientsDomain, state => state.dirtyClients);

export const makeSelectDbEmpoweredIndex = (): ClientSelector<number> =>
  createSelector(selectDealClientsDomain, state => state.dbEmpoweredIndex);

export const makeSelectDbOwnerIndex = (): ClientSelector<number> =>
  createSelector(selectDealClientsDomain, state => state.dbOwnerIndex);

export const makeSelectisLoadingUpdateState = (): ClientSelector<boolean> =>
  createSelector(selectDealClientsDomain, state => state.isLoadingUpdateState);

export const makeSelectEmailErrors = (): ClientSelector<State['emailErrors']> =>
  createSelector(selectDealClientsDomain, state => state.emailErrors);

export const makeSelectAreClientsValid = (): ClientSelector<boolean> =>
  createSelector(makeSelectEmailErrors(), errors => errors.length > 0);

export const makeSelectisEmpowermentDirty = (): ClientSelector<boolean> =>
  createSelector(
    makeSelectDealClients(),
    makeSelectDbEmpoweredIndex(),
    (clients, dbEmpoweredIndex) =>
      ![-1, dbEmpoweredIndex].includes(clients.findIndex(c => c.special)),
  );

export const makeSelectisOwnerDirty = (): ClientSelector<boolean> =>
  createSelector(
    makeSelectDealClients(),
    makeSelectDbOwnerIndex(),
    (clients, dbOwnerIndex) =>
      ![-1, dbOwnerIndex].includes(
        clients.findIndex(c => c.role === ClientRole.OWNER),
      ),
  );

export const makeSelectIsDirty = (): ClientSelector<boolean> =>
  createSelector(
    makeSelectDirtyClientIndices(),
    makeSelectisEmpowermentDirty(),
    makeSelectisOwnerDirty(),
    (clients, isEmpoweredDirty, isOwnerDirty) =>
      clients.length > 0 || isEmpoweredDirty || isOwnerDirty,
  );

export const makeSelectLinksDirty = (): ClientSelector<LinksDirtyStruct[]> =>
  createSelector(selectDealClientsDomain, state => state.linksDirty);

export const makeSelectIsDeletingRequiredClientModalOpen = (): ClientSelector<
  boolean
> =>
  createSelector(
    selectDealClientsDomain,
    state => state.isDeletingRequiredClientModalOpen,
  );

export const makeSelectClientsError = (): ClientSelector<Error | null> =>
  createSelector(selectDealClientsDomain, state => state.error);
