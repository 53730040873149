/**
 * ShoppingCart usage
 *
 * This component is divided in three main wrappers: Default, Empty and Error.
 * It also provides Totals, that can be used inside those wrappers.
 * Each one has it's own purpose, regarding to the shopping cart state.
 *
 * ** Default **
 * This one is used when shopping cart data is valid.
 * It provides 3 components: Container, Section and Item.
 *
 * Item should only be used as children of Section and Totals.
 * Section and Totals should only be used as children of Container.
 *
 * Example of usage :
 *
 * const totals = <ShoppingCart.Totals>[<ShoppingCart.Default.Item>]</ShoppingCart.Totals>;
 *
 * <ShoppingCart.Default.Container {...props} totals={totals} >
 *   <ShoppingCart.Default.Section title="Section 1" totalPrice="300 €">
 *     <ShoppingCart.Default.Item>Hello</ShoppingCart.Default.Item>
 *     <ShoppingCart.Default.Item>World</ShoppingCart.Default.Item>
 *   </ShoppingCart.Default.Section>
 *   <ShoppingCart.Default.Section title="Section 2" totalPrice="150 €">
 *     <ShoppingCart.Default.Item>Bob</ShoppingCart.Default.Item>
 *     <ShoppingCart.Default.Item>Alice</ShoppingCart.Default.Item>
 *   </ShoppingCart.Default.Section>
 * </ShoppingCart.Default.Container>
 *
 * ** Empty **
 * This one is used as a placeholder, when no shopping cart data is available.
 * It provides 2 components: Container and Item.
 *
 * Item should only be used as children of Container or Totals.
 * As Default, Totals is passed as a Container props.
 *
 * Example of usage :
 *
 * const totals = <ShoppingCart.Totals>[<ShoppingCart.Empty.Item>]</ShoppingCart.Default.Totals>;
 *
 * <ShoppingCart.Empty.Container {...props} totals={totals} >
 *   <ShoppingCart.Empty.Item>Bob</ShoppingCart.Empty.Item>
 *   <ShoppingCart.Empty.Item>Alice</ShoppingCart.Empty.Item>
 * </ShoppingCart.Empty.Container>
 *
 * ** Error **
 * This one is used when shopping cart is on error.
 * It comes alone, needing props only.
 *
 */

import Container from './ShoppingCart'
import Empty from './Empty'
import Error from './Error'
import Item from './Item'
import Section from './Section'
import Totals from './Totals'

const Default = {
  Container,
  Item,
  Section,
}

const ShoppingCart = {
  Default,
  Empty,
  Error,
  Totals,
}

export default ShoppingCart
