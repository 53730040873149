import Check from '../../images/icons/check.svg'
import style from './Checkbox.module.scss'

interface CheckboxProps {
  checked: boolean
  className?: string
}

export default function Checkbox({ checked, className }: CheckboxProps): JSX.Element {
  const classes = [style.checkbox, checked && style.checked, className].filter(Boolean)
  return (
    <span className={classes.join(' ')}>
      <Check />
    </span>
  )
}
