import { createSelector, Selector } from 'reselect';

import { Consulate, ConsulateJSON } from '@advitam/api/models/Consulate';
import type { SerializedApiError } from '@advitam/api';

import { CONSULATE } from './constants';
import type { AppStateSubset, State } from './slice';
import { NewConsulate } from './types';

type ConsulateSelector<T> = Selector<AppStateSubset, T>;

const selectConsulateDomain = (state: AppStateSubset): State =>
  state[CONSULATE];

export const makeSelectRawConsulate = (): ConsulateSelector<
  ConsulateJSON | NewConsulate | null
> => createSelector(selectConsulateDomain, ({ consulate }) => consulate);

export const makeSelectConsulate = (): ConsulateSelector<Consulate | null> =>
  createSelector(makeSelectRawConsulate(), rawConsulate =>
    rawConsulate?.id ? new Consulate(rawConsulate) : null,
  );

export const makeSelectIsLoading = (): ConsulateSelector<boolean> =>
  createSelector(selectConsulateDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): ConsulateSelector<boolean> =>
  createSelector(selectConsulateDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): ConsulateSelector<boolean> =>
  createSelector(selectConsulateDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): ConsulateSelector<boolean> =>
  createSelector(selectConsulateDomain, ({ isUpdatingName }) => isUpdatingName);

export const makeSelectIsUpdatingDefault = (): ConsulateSelector<boolean> =>
  createSelector(
    selectConsulateDomain,
    ({ isUpdatingDefault }) => isUpdatingDefault,
  );

export const makeSelectIsDefaultOverrideConfirmationNeeded = (): ConsulateSelector<
  boolean
> =>
  createSelector(
    selectConsulateDomain,
    ({ isDefaultOverrideConfirmationNeeded }) =>
      isDefaultOverrideConfirmationNeeded,
  );

export const makeSelectError = (): ConsulateSelector<SerializedApiError | null> =>
  createSelector(selectConsulateDomain, ({ error }) => error);

export const makeSelectDestroyError = (): ConsulateSelector<SerializedApiError | null> =>
  createSelector(selectConsulateDomain, ({ destroyError }) => destroyError);
