import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';

import { setActiveSupplier } from '../../slice';
import { MAP_PRESTATIONS } from './constants';
import { makeSelectStoredCoverageKeys } from './selectors';
import { addActiveKey, AppStateSubset } from './slice';
import { resetActiveSuppliers } from '../Suppliers/slice';

export const fetchPrestationsCoverageKeys = createAsyncThunk(
  `${MAP_PRESTATIONS}_FETCH_COVERAGE_KEYS`,
  async (_, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Prestations.Coverage.Keys.index());
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchPrestationCoverages = createAsyncThunk(
  `${MAP_PRESTATIONS}_FETCH_COVERAGES`,
  async (key: string, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Prestations.Coverage.index(key));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchCoverages = createAsyncThunk(
  `${MAP_PRESTATIONS}_FETCH_COVERAGE`,
  async (key: string, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const storedKeys = makeSelectStoredCoverageKeys()(state);

    dispatch(resetActiveSuppliers());
    dispatch(setActiveSupplier(null));

    if (storedKeys.includes(key)) {
      dispatch(addActiveKey(key));
      return undefined;
    }

    try {
      await dispatch(fetchPrestationCoverages(key));
      dispatch(addActiveKey(key));
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
