import { ChangeEvent, ReactNode } from 'react';
import { useField, FieldInstance, Validator } from 'react-form';
import { useIntl } from 'react-intl';
import { Props } from 'react-intl/src/components/message';

import { FILE_SIZE_UPLOAD_LIMIT } from 'containers/App/constants';

import messages from './messages';

function validator(required: boolean, allowedTypes?: string[]): Validator {
  return (value: File | string, _instance: FieldInstance): string | false => {
    if (!value) {
      return required ? messages.requiredField.id : false;
    }

    if (typeof value === 'string') {
      return false;
    }

    if (value.size > FILE_SIZE_UPLOAD_LIMIT) {
      return messages.fileTooBig.id;
    }
    if (allowedTypes && !allowedTypes.includes(value.type)) {
      return messages.fileInvalid.id;
    }
    return false;
  };
}

export type HTMLFileInput = HTMLInputElement & {
  value: File;
};

function makeFakeChangeEvent(value: File): ChangeEvent<HTMLFileInput> {
  return {
    target: ({ value } as unknown) as HTMLFileInput,
  } as ChangeEvent<HTMLFileInput>;
}

interface FileInputProps {
  field: string;
  children: ReactNode | ReactNode[];
  className?: string;
  message?: Props;
  hideErrorMessage?: boolean;
  required?: boolean;
  allowedTypes?: string[];
  onChange?: (ev: ChangeEvent<HTMLFileInput>) => void;
}

export default function FileInput({
  field,
  children,
  className,
  message,
  hideErrorMessage,
  required,
  allowedTypes,
  onChange: superChange,
}: FileInputProps): JSX.Element {
  const intl = useIntl();
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField(field, {
    validate: validator(required || false, allowedTypes),
  });
  const { onChange: reactChange, onBlur } = getInputProps();

  const onChange = async ({
    target: { files },
  }: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!files) {
      return;
    }
    const ev = makeFakeChangeEvent(files[0]);
    await reactChange(ev);
    if (superChange) {
      superChange(ev);
    }
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`${className || ''}`}>
      <span>{message && intl.formatMessage(message, message.values)}</span>
      <input
        type="file"
        className="soft-hide"
        onChange={onChange}
        onBlur={onBlur}
        name={field}
      />
      {children}
      {!hideErrorMessage && isTouched && error && (
        <span className="error">{intl.formatMessage({ id: error })}</span>
      )}
    </label>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}
