import { useDispatch, useSelector } from 'react-redux';

import { SendBatchTodoItem } from '@advitam/api/models/Deal/TodoItem';

import { openWithFetch } from 'containers/Deal/SendBatchModal/thunk';
import { ButtonV2 as Button } from 'components/Button';

import { makeSelectHasBatchMissingDocs } from '../../selectors';
import TaskRow from './Row';
import Label from './Label';

interface SendBatchProps {
  item: SendBatchTodoItem;
}

export default function SendBatchTask({
  item,
}: SendBatchProps): JSX.Element | null {
  const dispatch = useDispatch();
  const hasMissingDocs = useSelector(makeSelectHasBatchMissingDocs(item));
  const canOpenModal = !hasMissingDocs && !item.isDone;

  const onClick = (): void => {
    dispatch(openWithFetch(item.action.id));
  };

  return (
    <TaskRow item={item}>
      {canOpenModal ? (
        <Button onClick={onClick} className={`button--${item.action.status}`}>
          <Label title={item.title} />
        </Button>
      ) : (
        <Label title={item.title} />
      )}
    </TaskRow>
  );
}
