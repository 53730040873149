import { useCallback, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { HardSpinner, PageSpinner } from '@advitam/ui';

import { Path } from 'containers/App/constants';
import { useEntitySeed } from 'containers/Entities/useEntitySeed';
import ResourceCable, {
  ResourceDestroyModal,
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { assert } from 'lib/support';
import { withDatasets } from 'slices/data';

import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectRawHospital,
} from './selectors';
import { fetchHospital } from './thunk';
import slice, { clearError, setHospital } from './slice';
import { HOSPITAL_SKELETON } from './constants';

function Container(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hospital = useSelector(makeSelectRawHospital());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());
  const seed = useEntitySeed();

  const { id } = useParams();
  const hospitalId = id && parseInt(id, 10);
  const canRender = !isLoading && hospital;

  const fetchResource = useCallback(() => {
    assert(typeof hospitalId === 'number');
    dispatch(fetchHospital(hospitalId));
  }, [dispatch, hospitalId]);

  useEffect(() => {
    if (!hospitalId) {
      if (seed) {
        dispatch(setHospital({ ...HOSPITAL_SKELETON, ...seed }));
      } else {
        navigate(Path.ENTITIES, { replace: true });
      }
    } else if (hospitalId !== hospital?.id) {
      fetchResource();
    }

    return () => {
      dispatch(clearError());
    };
  }, [hospitalId]);

  return (
    <>
      <Helmet>
        <body className="" />
      </Helmet>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {hospitalId && (
        <ResourceCable
          resourceId={hospitalId}
          resourceType={ResourceRoomType.HOSPITAL}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={fetchResource} />
          <ResourceDestroyModal path={Path.ENTITIES} />
        </ResourceCable>
      )}
    </>
  );
}

export default compose(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(Container);
