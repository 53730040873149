import { authenticate } from '../../../lib/decorators'
import { get } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { NearEntityJSON } from '../../../models/Entity/Near'
import type { NearEntitiesFilters } from './types'

export class Near {
  @authenticate
  static index(filters: NearEntitiesFilters): ApiRequestDescriptor<NearEntityJSON[]> {
    return get('/api/v1/entities/near', filters)
  }
}

export type { NearEntitiesFilters }
