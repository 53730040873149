import { FormattedMessage, useIntl } from 'react-intl';

import { StayJSON, StayType } from '@advitam/api/models/FuneralParlor';
import {
  Button,
  FormLayout,
  Input,
  NumberInput,
  Select,
  TextArea,
} from '@advitam/ui';
import { Layout, messages as crudMessages } from 'containers/Crud';
import actionMessages from 'messages/actions';

import messages from '../messages';
import { useIsFiltered } from '../useIsFiltered';
import style from '../Stays.module.scss';

interface StayProps {
  stay: StayJSON;
  prefix: string;
  onDelete: () => void;
}

export default function Stay({
  stay,
  prefix,
  onDelete,
}: StayProps): JSX.Element {
  const intl = useIntl();
  const isFiltered = useIsFiltered();

  return (
    <Layout.Fieldset
      title={
        stay.id ? stay.name : <FormattedMessage id={messages.newStay.id} />
      }
      className={isFiltered(stay) ? style.filtered : undefined}
    >
      <FormLayout.Row>
        <Input
          required
          name={`${prefix}.name`}
          label={<FormattedMessage id={messages.name.id} />}
          placeholder={intl.formatMessage(messages.namePlaceholder)}
        />
      </FormLayout.Row>
      <FormLayout.Row className={style.form_row}>
        <Select
          required
          name={`${prefix}.stay_type`}
          label={<FormattedMessage id={messages.stayType.id} />}
          placeholder={<FormattedMessage id={messages.select.id} />}
          items={[
            {
              value: StayType.CLASSIC,
              name: intl.formatMessage(messages.stayTypeClassic),
            },
            {
              value: StayType.PREMIUM,
              name: intl.formatMessage(messages.stayTypePremium),
            },
            {
              value: StayType.CLOSED_COFFIN,
              name: intl.formatMessage(messages.stayTypeClosedCoffin),
            },
          ]}
          unselectable
        />
        <Select
          name={`${prefix}.has_departure_room`}
          label={<FormattedMessage id={messages.hasDepartureRoom.id} />}
          tooltip={
            <FormattedMessage id={messages.hasDepartureRoomTooltip.id} />
          }
          placeholder={<FormattedMessage id={messages.select.id} />}
          items={[
            {
              value: true,
              name: intl.formatMessage(actionMessages.yes),
            },
            {
              value: false,
              name: intl.formatMessage(actionMessages.no),
            },
          ]}
          unselectable
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          name={`${prefix}.price`}
          label={<FormattedMessage id={messages.price.id} />}
          tooltip={intl.formatMessage(messages.priceTooltip)}
          step={0.01}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          name={`${prefix}.base_days`}
          label={<FormattedMessage id={messages.baseDays.id} />}
          tooltip={intl.formatMessage(messages.baseDaysTooltip)}
          suffix={<FormattedMessage id={crudMessages.unitDay.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          name={`${prefix}.price_per_day`}
          label={<FormattedMessage id={messages.pricePerDay.id} />}
          tooltip={intl.formatMessage(messages.pricePerDayTooltip)}
          step={0.01}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <TextArea
          name={`${prefix}.description`}
          label={<FormattedMessage id={messages.description.id} />}
        />
      </FormLayout.Row>

      <Button
        outline
        className={style.delete}
        onClick={onDelete}
        text={<FormattedMessage id={messages.delete.id} />}
      />
    </Layout.Fieldset>
  );
}
