import { Email } from './Email'
import { Facebook } from './Facebook'
import { Linkedin } from './Linkedin'
import { GMaps } from './GMaps'
import { X } from './X'
import { WhatsApp } from './WhatsApp'

export const URLs = {
  Email,
  Facebook,
  Linkedin,
  GMaps,
  WhatsApp,
  X,
}
