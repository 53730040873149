import { defineMessages } from 'react-intl';

export default defineMessages({
  booking_accepted: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.booking_accepted',
    defaultMessage: 'Accepted',
  },
  booking_none: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.booking_none',
    defaultMessage: 'Nothing',
  },
  booking_pending: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.booking_pending',
    defaultMessage: 'Pending',
  },
  booking_cancelled: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.booking_cancelled',
    defaultMessage: 'Canceled',
  },
  booking_booked: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.booking_booked',
    defaultMessage: 'Booked',
  },
  booking_rejected: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.booking_rejected',
    defaultMessage: 'Refused',
  },
  verb_booking_accepted: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.verb_booking_accepted',
    defaultMessage: 'Accepted',
  },
  verb_booking_none: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.verb_booking_none',
    defaultMessage: 'Nothing',
  },
  verb_booking_pending: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.verb_booking_pending',
    defaultMessage: 'Pending',
  },
  verb_booking_cancelled: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.verb_booking_cancelled',
    defaultMessage: 'Canceled',
  },
  verb_booking_booked: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.verb_booking_booked',
    defaultMessage: 'Booked',
  },
  verb_booking_rejected: {
    id:
      'app.containers.DealTypeWrapper.BookingSection.SuppliersStatus.verb_booking_rejected',
    defaultMessage: 'Refused',
  },
});
