import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { TransparentButton } from 'components/Button';

export default function Links({
  deal,
  sectionOpened,
  isNewDeal,
  setSection,
  links,
}) {
  const navigate = useNavigate();

  const onSetSection = value => {
    navigate(`#${value}`);
    setSection(value);
  };

  if (!deal) {
    return null;
  }

  return links.map(link => {
    if (link.onlyExisting && isNewDeal) {
      return null;
    }

    return (
      <TransparentButton
        key={link.hash}
        className={`transparentButton sideBar__link${
          link.hash === sectionOpened ? ' sideBar__link--active' : ''
        }`}
        onClick={() => onSetSection(link.hash)}
      >
        <span>
          <FormattedMessage id={link.message.id} />
        </span>
      </TransparentButton>
    );
  });
}

Links.propTypes = {
  /** Object which contains data of deal */
  deal: PropTypes.object.isRequired,
  /** Function to update the opened section */
  setSection: PropTypes.func.isRequired,
  /** Current section opened */
  sectionOpened: PropTypes.string.isRequired,
  /** The deal is a new deal */
  isNewDeal: PropTypes.bool,
  links: PropTypes.array.isRequired,
};
