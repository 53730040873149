import { DateTime } from 'luxon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { downloadFile, request } from '@advitam/api';
import type { EntityInvoicesFilters } from '@advitam/api/v1/EntityInvoices';
import { Objects, assert } from '@advitam/support';

import { makeSelectFilters } from './selectors';
import type { AppStateSubset } from './slice';
import { BILLING } from './constants';

export const fetchEntityInvoices = createAsyncThunk(
  `${BILLING}/FETCH`,
  async (page: number, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const filters = makeSelectFilters()(state);

    assert(filters.start_date !== undefined);
    assert(filters.end_date !== undefined);
    const endpointFilters: EntityInvoicesFilters = {
      ...Objects.pick(filters, 'entity_type', 'status', 'q'),
      page,
      start_date: Math.round(
        DateTime.fromJSDate(filters.start_date).toSeconds(),
      ),
      end_date: Math.round(DateTime.fromJSDate(filters.end_date).toSeconds()),
      entity_id: filters.entity?.id,
    };

    try {
      const { body } = await request(
        Api.V1.EntityInvoices.index(endpointFilters),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const validateInvoice = createAsyncThunk(
  `${BILLING}/VALIDATE`,
  async (invoiceId: number, { rejectWithValue }) => {
    try {
      await request(Api.V1.EntityInvoices.Validate.create(invoiceId));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPendingPayments = createAsyncThunk(
  `${BILLING}/FETCH_PENDING_PAYMENTS`,
  async (_: void, { rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.EntityInvoices.PendingPayments.index(),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createPendingPayment = createAsyncThunk(
  `${BILLING}/CREATE_PENDING_PAYMENTS`,
  async (entityInvoiceId: number, { rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.EntityInvoices.PendingPayments.create(entityInvoiceId),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deletePendingPayment = createAsyncThunk(
  `${BILLING}/DELETE_PENDING_PAYMENTS`,
  async (id: number, { rejectWithValue }) => {
    try {
      await request(Api.V1.EntityInvoices.PendingPayments.destroy(id));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const clearPendingPayments = createAsyncThunk(
  `${BILLING}/CLEAR_PENDING_PAYMENTS`,
  async (_: void, { rejectWithValue }) => {
    try {
      await request(Api.V1.EntityInvoices.PendingPayments.Reset.destroy());
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadBankTransfer = createAsyncThunk(
  `${BILLING}/DOWNLOAD_BANK_TRANSFER`,
  async (_: void, { rejectWithValue }) => {
    try {
      await downloadFile(
        Api.V1.EntityInvoices.PendingPayments.BankTransfers.create(),
      );
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
