import type { AutocompleteDepartmentsResult } from '@advitam/api/v1/Autocompletes';
import {
  ConcessionSemelleTypeJSON,
  ConcessionTypeJSON,
} from '@advitam/api/models/Concession';
import type { WorshipTypeJSON } from '@advitam/api/models/WorshipType';
import { FuneralTeamUserJSON } from '@advitam/api/models/FuneralTeamUser';
import { FuneralBrandJSON } from '@advitam/api/models/FuneralBrand';

export const DATA = 'Data';

export interface Datasets {
  departments: AutocompleteDepartmentsResult[];
  worshipTypes: WorshipTypeJSON[];
  concessionTypes: ConcessionTypeJSON[];
  concessionSemelleTypes: ConcessionSemelleTypeJSON[];
  funeralTeam: FuneralTeamUserJSON[];
  funeralBrands: FuneralBrandJSON[];
}

export type Dataset = keyof Datasets;
