import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { DEALS_PATH } from 'containers/App/constants';
import DealsListMessages from 'containers/DealsList/messages';
import {
  makeSelectOrdersLinked,
  makeSelectDealType,
} from 'containers/Deal/selectors';
import { getOrdersLinked } from './actions';
import messages from './messages';
import style from './OrdersLinked.module.scss';

export class OrdersLinked extends Component {
  renderContent() {
    const { ordersLinked } = this.props;

    if (ordersLinked && ordersLinked.length <= 0) {
      return <FormattedMessage {...messages.noOrdersLinked} />;
    }

    return (
      <>
        {ordersLinked.map(order => (
          <div key={order.uuid} className={style.row}>
            <div>{order.deal_type}</div>
            <div>{order.defunct_name}</div>
            <FormattedMessage
              id={DealsListMessages[order.state].id}
              tagName="div"
            />
            <a href={`${DEALS_PATH}/${order.uuid}`} className="button">
              {order.uuid}
            </a>
          </div>
        ))}
      </>
    );
  }

  render() {
    return (
      <section id="ordersLinked">
        <FormattedMessage {...messages.ordersLinkedSection} tagName="h2" />
        <div className="dealSection">{this.renderContent()}</div>
      </section>
    );
  }
}

OrdersLinked.propTypes = {
  getOrdersLinked: PropTypes.func.isRequired,
  ordersLinked: PropTypes.array.isRequired,
  dealUUID: PropTypes.string.isRequired,
  dealType: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  ordersLinked: makeSelectOrdersLinked(),
  dealType: makeSelectDealType(),
});

function mapDispatchToProps(dispatch) {
  return {
    getOrdersLinked: (dealUUID, dealType) =>
      dispatch(getOrdersLinked(dealUUID, dealType)),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(OrdersLinked);
