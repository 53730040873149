import type { CityhallJSON } from '@advitam/api/models/Cityhall';
import type { ConsulateJSON } from '@advitam/api/models/Consulate';
import type { CrematoriumJSON } from '@advitam/api/models/Crematorium';
import type { FuneralParlorJSON } from '@advitam/api/models/FuneralParlor';
import type { GraveyardJSON } from '@advitam/api/models/Graveyard';
import type { HospitalJSON } from '@advitam/api/models/Hospital';
import type { PoliceJSON } from '@advitam/api/models/Police';
import type { PrefectureJSON } from '@advitam/api/models/Prefecture';
import type { RegionalHealthAuthorityJSON } from '@advitam/api/models/RegionalHealthAuthority';
import type { SupplierJSON } from '@advitam/api/models/Supplier';
import type { SupplierWarehouseJSON } from '@advitam/api/models/Supplier/Warehouse';
import type { WorshipJSON } from '@advitam/api/models/Worship';
import {
  Cityhall,
  Crematorium,
  FuneralParlor,
  Graveyard,
  Hospital,
  Police,
  Prefecture,
  Rha,
  SupplierWarehouse,
  Worship,
  Consulate,
} from 'components/Format';
import PriceLine from 'models/Deal/PriceLine';
import { ModelType } from 'models/Model';

import type { Entity } from './types';

export function formatPrestationName(
  entity: Entity,
  priceLine: PriceLine,
  supplier: SupplierJSON | null,
): string {
  switch (priceLine.manual_model_type || priceLine.model_type) {
    case ModelType.CITYHALL:
      return Cityhall.formatName(entity as CityhallJSON);
    case ModelType.RHA:
      return Rha.formatName(entity as RegionalHealthAuthorityJSON);
    case ModelType.POLICE:
      return Police.formatName(entity as PoliceJSON);
    case ModelType.CREMATORIUM:
      return Crematorium.formatName(entity as CrematoriumJSON);
    case ModelType.WORSHIP:
      return Worship.formatName(entity as WorshipJSON);
    case ModelType.PREFECTURE:
      return Prefecture.formatName(entity as PrefectureJSON);
    case ModelType.FUNERAL_PARLOR:
      return FuneralParlor.formatName(entity as FuneralParlorJSON);
    case ModelType.GRAVEYARD:
      return Graveyard.formatName(entity as GraveyardJSON);
    case ModelType.HOSPITAL:
      return Hospital.formatName(entity as HospitalJSON);
    case ModelType.CONSULATE:
      return Consulate.formatName(entity as ConsulateJSON);
    case ModelType.SUPPLIER_WAREHOUSE:
      // If the prestation is not created or a skeleton we might have no supplier yet
      // This is an ugly fix due to the poor quality of the JSON DATA
      // TODO: Refacto the way we had service/products & states
      if (!supplier) return '';
      return SupplierWarehouse.formatName(
        supplier,
        entity as SupplierWarehouseJSON,
      );
    case ModelType.SUPPLIER:
    default:
      return '';
  }
}
