import { useSelector } from 'react-redux';

import type { DealDocument } from 'models/Deal/Document';
import DocumentsEditor from 'containers/DocumentsEditor';
import HardSpinner from 'components/HardSpinner';
import ErrorModal, { ErrorProps } from 'components/ErrorModal';
import UploadFiles from 'components/UploadFiles/NoModal';
import { arraySortedByAlphabeticalOrder } from 'utils/functions';

import { ResourceType } from '@advitam/api/models/DocumentEditions';
import { makeSelectDeal } from 'containers/Deal/selectors';
import type { NewUploadResource } from 'containers/DocumentsEditor/types';

import { makeSelectDocumentList } from '../selectors';
import Section from './Section';

const FAMILY_SECTION = 'family';

interface DocumentListProps {
  loading: boolean;
  error?: ErrorProps | null;
  dealUUID: string;
  refreshDocumentList: () => void;
}

type UploadFiles = (
  files: File[],
  resource: NewUploadResource,
) => void | Promise<void>;

export default function DocumentList({
  error,
  loading,
  dealUUID,
  refreshDocumentList,
}: DocumentListProps): JSX.Element {
  const deal = useSelector(makeSelectDeal());
  const documents = useSelector(makeSelectDocumentList()) as Record<
    string,
    DealDocument[]
  >;

  const { family, ...otherSections } = documents;
  const otherSectionsToDisplay = arraySortedByAlphabeticalOrder(
    Object.keys(otherSections),
  ) as string[];

  return (
    <div className="margin--10-0">
      {error && <ErrorModal error={error} />}
      {loading && <HardSpinner />}
      <DocumentsEditor afterSaveHook={refreshDocumentList}>
        {({ uploadFiles }) => (
          <>
            {dealUUID && (
              <UploadFiles
                postFiles={filesToUpload =>
                  uploadFiles(filesToUpload, {
                    type: ResourceType.DEAL,
                    deal: { uuid: deal?.uuid || '' },
                  })
                }
              />
            )}
            <div className="documents__content__documentsContainer">
              {family && (
                <Section section={FAMILY_SECTION} uploadFiles={uploadFiles} />
              )}
              {otherSectionsToDisplay.map(category => (
                <Section
                  key={category}
                  section={category}
                  uploadFiles={uploadFiles}
                />
              ))}
            </div>
          </>
        )}
      </DocumentsEditor>
    </div>
  );
}
