import { authenticate } from '../../../lib/decorators'
import { del, post, put } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { EntityInvoiceJSON } from '../../../models/EntityInvoice'
import { serializeCreation, serializeUpdate } from './serializers'
import type { NewEntityInvoice, NewEntityInvoicePrestation } from './types'

export const Prestations = {
  create: authenticate(
    (
      invoice: NewEntityInvoice,
      prestation: NewEntityInvoicePrestation,
    ): ApiRequestDescriptor<void> =>
      post('/api/v1/entity_invoices/prestations', serializeCreation(invoice, prestation)),
  ),
  update: authenticate(
    (prestationId: number, invoice: NewEntityInvoice): ApiRequestDescriptor<EntityInvoiceJSON> =>
      put(`/api/v1/entity_invoices/prestations/${prestationId}`, serializeUpdate(invoice)),
  ),
  delete: authenticate(
    (prestationId: number): ApiRequestDescriptor<void> =>
      del(`/api/v1/entity_invoices/prestations/${prestationId}`),
  ),
}
