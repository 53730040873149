import { authenticate } from '../../../lib/decorators'
import { del, get, post } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { EntityInvoicePendingPaymentJSON } from '../../../models/EntityInvoice/PendingPayment'

import { BankTransfers } from './BankTransfers'
import { Reset } from './Reset'

export const PendingPayments = {
  BankTransfers,
  Reset,
  index: authenticate(
    (): ApiRequestDescriptor<EntityInvoicePendingPaymentJSON[]> =>
      get('/api/v1/entity_invoices/pending_payments'),
  ),
  create: authenticate(
    (entityInvoiceId: number): ApiRequestDescriptor<EntityInvoicePendingPaymentJSON> =>
      post(`/api/v1/entity_invoices/${entityInvoiceId}/pending_payments`),
  ),
  destroy: authenticate(
    (pendingPaymentId: number): ApiRequestDescriptor<void> =>
      del(`/api/v1/entity_invoices/pending_payments/${pendingPaymentId}`),
  ),
}
