import { defineMessages } from 'react-intl';

export default defineMessages({
  recipientTypeFilter: {
    id: 'app.containers.Deal.Sections.History.recipientTypeFilter',
  },
  channelTypeFilter: {
    id: 'app.containers.Deal.Sections.History.channelTypeFilter',
  },
  userFilter: {
    id: 'app.containers.Deal.Sections.History.userFilter',
  },
  dateFilter: {
    id: 'app.containers.Deal.Sections.History.dateFilter',
  },
  search: {
    id: 'app.containers.Deal.Sections.History.search',
  },

  familyType: { id: 'app.containers.Deal.Sections.History.familyType' },
  supplierType: { id: 'app.containers.Deal.Sections.History.supplierType' },
  administrationType: {
    id: 'app.containers.Deal.Sections.History.administrationType',
  },
  thirdPartyType: { id: 'app.containers.Deal.Sections.History.thirdPartyType' },

  recipient: { id: 'app.containers.Deal.Sections.History.recipient' },
  date: { id: 'app.containers.Deal.Sections.History.date' },
  summary: { id: 'app.containers.Deal.Sections.History.summary' },

  email: { id: 'app.containers.Deal.Sections.History.email' },
  fax: { id: 'app.containers.Deal.Sections.History.fax' },
  letter: { id: 'app.containers.Deal.Sections.History.letter' },
  sms: { id: 'app.containers.Deal.Sections.History.sms' },
  phone: { id: 'app.containers.Deal.Sections.History.phone' },

  sent: { id: 'app.containers.Deal.Sections.History.sent' },
  received: { id: 'app.containers.Deal.Sections.History.received' },
  failed: { id: 'app.containers.Deal.Sections.History.failed' },
  pending: { id: 'app.containers.Deal.Sections.History.pending' },
  incoming: { id: 'app.containers.Deal.Sections.History.incoming' },
  outgoing: { id: 'app.containers.Deal.Sections.History.outgoing' },
  missed: { id: 'app.containers.Deal.Sections.History.missed' },
  voicemail: { id: 'app.containers.Deal.Sections.History.voicemail' },
  transfered: { id: 'app.containers.Deal.Sections.History.transfered' },

  seeContent: { id: 'app.containers.Deal.Sections.History.seeContent' },
  play: { id: 'app.containers.Deal.Sections.History.play' },
  stop: { id: 'app.containers.Deal.Sections.History.stop' },
  download: { id: 'app.containers.Deal.Sections.History.download' },
  attachments: { id: 'app.containers.Deal.Sections.History.attachments' },

  emailModalTitle: {
    id: 'app.containers.Deal.Sections.History.emailModalTitle',
  },
  phoneModalTitle: {
    id: 'app.containers.Deal.Sections.History.phoneModalTitle',
  },
  smsModalTitle: { id: 'app.containers.Deal.Sections.History.smsModalTitle' },
  letterModalTitle: {
    id: 'app.containers.Deal.Sections.History.letterModalTitle',
  },
  faxModalTitle: { id: 'app.containers.Deal.Sections.History.faxModalTitle' },
  modalTitle: { id: 'app.containers.Deal.Sections.History.modalTitle' },
  emailSubject: { id: 'app.containers.Deal.Sections.History.emailSubject' },
  closeModal: { id: 'app.containers.Deal.Sections.History.closeModal' },
});
