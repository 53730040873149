import { createSelector, Selector } from 'reselect';

import { makeSelectDeal } from 'containers/Deal/selectors.typed';

import type { AppStateSubset as DealAppStateSubset } from '../slice';
import type { AppStateSubset, State } from './slice';
import { DEAL_COMMENTARIES } from './constants';

type CommentariesSelector<T> = Selector<AppStateSubset & DealAppStateSubset, T>;

const selectCommentariesDomain = (state: AppStateSubset): State =>
  state[DEAL_COMMENTARIES];

export const makeSelectIsLoading = (): CommentariesSelector<boolean> =>
  createSelector(selectCommentariesDomain, state => state.isLoading);

export const makeSelectCommentary = (): CommentariesSelector<string> =>
  createSelector(makeSelectDeal(), deal => deal?.commentary || '');

export const makeSelectClientCommentary = (): CommentariesSelector<string> =>
  createSelector(makeSelectDeal(), deal => deal?.client_commentary || '');
