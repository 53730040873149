import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError } from 'api';
import { PaymentJSON } from 'models/Payment';

import { fetchPaymentsDetails, fetchPayments, postPayments } from './thunk';
import { PAYMENTS_SPACE } from './constants';
import { refundPayment } from './RefundModal/thunk';

export interface State {
  payments: PaymentJSON[];
  paymentsToRemove: string[];
  isLoading: boolean;
  isDirty: boolean;
  error: ApiError | null;
}

export interface AppStateSubset {
  [PAYMENTS_SPACE]: State;
}

export const initialState: State = {
  payments: [],
  paymentsToRemove: [],
  isLoading: false,
  isDirty: false,
  error: null,
};

interface UpdatePaymentPayload {
  paymentIndex: number;
  updatedPayment: PaymentJSON;
}

const slice = createSlice({
  name: PAYMENTS_SPACE,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    updatePayment: (
      state,
      { payload }: PayloadAction<UpdatePaymentPayload>,
    ): void => {
      state.isDirty = true;
      state.payments[payload.paymentIndex] = payload.updatedPayment;
    },
    addPayment: (state, { payload }: PayloadAction<PaymentJSON>): void => {
      state.isDirty = true;
      state.payments.push(payload);
    },
    removePayment: (state, { payload }: PayloadAction<number>): void => {
      const paymentToRemove = state.payments[payload];
      if (paymentToRemove.uuid) {
        state.paymentsToRemove.push(paymentToRemove.uuid);
      }

      state.isDirty = true;
      state.payments.splice(payload, 1);
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPaymentsDetails.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchPaymentsDetails.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchPayments.pending, (state): void => {
      state.isDirty = false;
    });
    builder.addCase(fetchPayments.fulfilled, (state, { payload }): void => {
      state.payments = payload?.payments || [];
    });
    builder.addCase(fetchPayments.rejected, (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload as ApiError;
    });
    builder.addCase(postPayments.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(postPayments.fulfilled, (state, { payload }): void => {
      state.isLoading = false;
      state.isDirty = false;
      state.payments = payload?.payments || [];
      state.paymentsToRemove = initialState.paymentsToRemove;
    });
    builder.addCase(postPayments.rejected, (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload as ApiError;
    });

    builder.addCase(refundPayment.fulfilled, (state, { payload }) => {
      state.payments.push(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { updatePayment, addPayment, removePayment } = slice.actions;
export default slice;
