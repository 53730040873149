import { ClientRole } from '../../../models/Client/Role'
import type { ClientAbilityJSON } from '../../../models/Client/Ability'

export interface CreationPayload {
  client_id: number
  role: ClientRole
  link: string | null
}

export function serializeForCreation(
  ability: Omit<ClientAbilityJSON, 'id' | 'resource'>,
): CreationPayload {
  return {
    client_id: ability.client.id,
    role: ability.role,
    link: ability.link,
  }
}

export interface UpdatePayload {
  role?: ClientRole
  link?: string | null
}

export function serializeForUpdate(ability: ClientAbilityJSON): UpdatePayload {
  const { role, link } = ability
  if (role === ClientRole.OWNER) {
    return { link }
  }
  return { role, link }
}
