import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Workbox } from 'workbox-window';

import { ConfirmationModal } from '@advitam/ui';
import { unsafeContext } from 'lib/support';

import messages from './messages';

const hasWorkbox: boolean =
  process.env.NODE_ENV === 'production' &&
  Boolean(unsafeContext().serviceWorker);
const WORKBOX = hasWorkbox ? new Workbox('/service-worker.js') : undefined;

function refreshServiceWorker(): void {
  if (!WORKBOX) {
    return;
  }

  WORKBOX.addEventListener('controlling', () => {
    window.location.reload();
  });
  WORKBOX.messageSkipWaiting();
}

export default function NewVersion(): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback((): void => {
    setIsOpen(false);
  }, [setIsOpen]);

  const openModal = useCallback((): void => {
    setIsOpen(true);
  }, [setIsOpen]);

  useEffect(() => {
    if (!WORKBOX) {
      return;
    }

    WORKBOX.addEventListener('waiting', openModal);
    WORKBOX.addEventListener('activated', closeModal);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    WORKBOX.register();
  }, []);

  if (!WORKBOX) {
    return null;
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={<FormattedMessage id={messages.title.id} />}
      text={<FormattedMessage id={messages.newVersionAvailable.id} />}
      confirm={<FormattedMessage id={messages.refreshVersion.id} />}
      onConfirm={refreshServiceWorker}
      cancel={<FormattedMessage id={messages.cancel.id} />}
      onCancel={closeModal}
      className="new-design"
    />
  );
}
