import { ReactNode } from 'react'

import Arrow from '../../../images/icons/arrow-right.svg'
import LinkButton from '../../Button/LinkButton'
import Heading from '../../Heading'
import Text from '../../Text'
import style from './IconAndCta.module.scss'

interface IconAndCtaCardProps {
  img: ReactNode
  title: ReactNode
  text: ReactNode
  children?: ReactNode
  ctaText?: ReactNode
  link?: string
  internal?: boolean
  newTab?: boolean
}

export default function IconAndCtaCard({
  img,
  title,
  text,
  children,
  ctaText,
  link,
  internal,
  newTab,
}: IconAndCtaCardProps): JSX.Element {
  return (
    <div className={`col-4 col-md-6 col-xl-4 ${style.card}`}>
      <span className={style.image}>{img}</span>
      <Heading small tagName="h4">
        <b>{title}</b>
      </Heading>
      <Text tagName="div">{text}</Text>
      {children}
      {ctaText && link && (
        <LinkButton
          primary
          href={link}
          internal={internal}
          newTab={newTab}
          text={ctaText}
          icon={<Arrow />}
        />
      )}
    </div>
  )
}
