import { createSelector } from 'reselect';

import { makeSelectActiveFuneralTeam } from 'slices/data';
import { makeSelectDeal } from '../selectors';

export const makeSelectDealOwner = () =>
  createSelector(
    makeSelectActiveFuneralTeam(),
    makeSelectDeal(),
    (team, deal) => {
      if (!deal) {
        return {};
      }

      return team.find(user => user.id === deal.user_id) || {};
    },
  );
