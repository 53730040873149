import { defineMessages } from 'react-intl';

export default defineMessages({
  userDeals: {
    id: 'app.containers.Account.StatsPerMonth.userDealsDone',
    defaultMessage: "Individual deals' done goal",
  },
  teamDeals: {
    id: 'app.containers.Account.StatsPerMonth.teamDealsDone',
    defaultMessage: "Team deals' done goal",
  },
  dealsErrors: {
    id: 'app.containers.Account.StatsPerMonth.dealsErrors',
    defaultMessage: 'Number of errors',
  },
  conversionRate: {
    id: 'app.containers.Account.StatsPerMonth.conversionRate',
    defaultMessage: 'Conversion rate',
  },
  conversionRateInfo: {
    id: 'app.containers.Account.StatsPerMonth.conversionRateInfo',
    defaultMessage: '{conversionRate}% of deals',
  },
  gradeAverage: {
    id: 'app.containers.Account.StatsPerMonth.gradeAverage',
    defaultMessage: 'Note du mois',
  },
  gradeAverageValue: {
    id: 'app.containers.Account.StatsPerMonth.gradeAverageValue',
    defaultMessage: '{grade} / 5',
  },
  gradeCeremonyAverage: {
    id: 'app.containers.Account.StatsPerMonth.gradeCeremonyAverage',
    defaultMessage: 'Note cérémonie du mois',
  },
  gradeCeremonyAverageValue: {
    id: 'app.containers.Account.StatsPerMonth.gradeCeremonyAverageValue',
    defaultMessage: '{grade} / 5',
  },
});
