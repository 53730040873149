import { FormattedMessage } from 'react-intl';

import type { DealCommunication } from '@advitam/api/models/Deal/Communication';
import { CommunicationChannelType } from '@advitam/api/models/Deal/Communication/ChannelType';
import { ButtonLike, FormUI, Tooltip } from '@advitam/ui';
import Paperclip from '@advitam/ui/images/icons/paperclip.svg';

import messages from '../../messages';
import style from './Details.module.scss';
import Attachment from './Attachment';

interface AttachmentsProps {
  communication: DealCommunication;
}

export default function Attachments({
  communication,
}: AttachmentsProps): JSX.Element | null {
  if (
    communication.channel_type === CommunicationChannelType.PHONE ||
    communication.files.length === 0
  ) {
    return null;
  }

  return (
    <FormUI.Dropdown
      placeholder={
        <Tooltip content={<FormattedMessage id={messages.attachments.id} />}>
          <ButtonLike className={style.button} outline icon={<Paperclip />} />
        </Tooltip>
      }
      contained={false}
      className={style.attachments}
    >
      {communication.files.map(file => (
        <Attachment key={file.link} file={file} />
      ))}
    </FormUI.Dropdown>
  );
}
