import { StepType } from '@advitam/api/models/Step';
import { ProductGroup, ProductType, ProductUse } from '../Product';
import { DealPrestationComputeType } from './Prestation/ComputeType';
import PriceLine from './PriceLine';

export enum PrestationStatus {
  NONE = 'none',
  EDITED = 'edited',
  DELETED = 'deleted',
}

export interface ProductVariant {
  id: number;
  price: number | null;
  cost: number | null;
  supplier_ref: string | null;
  product_id?: number;
  base_product_id?: number;
  thumbnail: string;
  images: string[];
  description: string | null;
}

export interface ProductObject {
  id: number;
  product_id?: number;
  product_name?: string;
  description: string | null;
  supplier_ref: string | null;
  thumbnail: string;
  images: string[];
  price: number | null;
  cost: number | null;
  type: ProductType;
  use: ProductUse;
  step_type: StepType;
  group_name: ProductGroup;
  size_model: string | null;
  diameters: string | null;
  inside_width: string | null;
  inside_length: string | null;
  inside_height: string | null;
  outside_width: string | null;
  outside_length: string | null;
  outside_height: string | null;
  thickness: string | null;
  shape: string | null;
  material: string | null;
  color: string | null;
  weight: string | null;
}

export interface ItemCompInfo {
  message: string | null;
  signature: string | null;
}

export interface Product {
  productObject: ProductObject;
  step_type: StepType;
  default_keys: Record<string, string>;
  priceLine: PriceLine | null;
  amount: number;
  status: PrestationStatus;
  compute: DealPrestationComputeType;
  default: boolean;
  error?: string[];
  variants: ProductVariant[];
  comp_info: ItemCompInfo | null;
}
