import { useSelector } from 'react-redux';
import moment from 'moment';

import BusinessUserBadge from 'components/BusinessUserBadge';
import {
  makeSelectIsUserActive,
  makeSelectLastUserActivity,
} from 'cables/Resource/selectors';
import { useEffect, useState } from 'react';

interface UserBadgeProps {
  userId: number;
}

export default function UserBadge({ userId }: UserBadgeProps): JSX.Element {
  const [checkCount, setCheckCount] = useState(0);
  const isActive = useSelector(makeSelectIsUserActive(userId, checkCount));
  const lastActivity = useSelector(makeSelectLastUserActivity(userId));

  useEffect(() => {
    const handle = setInterval(() => {
      if (isActive) {
        setCheckCount(checkCount + 1);
      } else {
        clearInterval(handle);
      }
    }, 60 * 1000);
    return (): void => clearInterval(handle);
  }, [lastActivity]);

  return (
    <BusinessUserBadge
      border
      dark
      fade={!isActive}
      userId={userId}
      statusText={isActive ? undefined : moment(lastActivity).fromNow()}
    />
  );
}
