/**
 * Data slice.
 *
 * Manages global datasets. It allows to load data only when needed and only
 * once in the application lifetime.
 *
 * A component using one of the datasets via one of the selectors exported by
 * this slice have to be wrapped in `withDatasets`. This ensures that the
 * datasets are fully loaded once the component is rendered.
 *
 * ```ts
 * import { useSelector } from 'react-redux';
 * import { makeSelectDepartments, withDatasets } from 'slices/data';
 *
 * function DepartmentList(): JSX.Element {
 *   const departments = useSelector(useDepartments());
 *   return <div>{department.name}</div>;
 * }
 *
 * export default withDatasets('deprtments')(DepartmentList);
 * ```
 */

import dataSlice, { AppStateSubset } from './slice';
import withDatasets from './withDatasets';

export { dataSlice, AppStateSubset, withDatasets };
export {
  makeSelectDepartments,
  makeSelectWorshipTypes,
  makeSelectWorshipTypesNeedingLocation,
  makeSelectFuneralBrands,
  makeSelectFuneralTeam,
  makeSelectActiveFuneralTeam,
  makeSelectFuneralTeamUser,
  makeSelectFuneralTeamUserInitials,
  makeSelectFuneralTeamUserImage,
  makeSelectFuneralTeamUserDisplayName,
} from './selectors';
