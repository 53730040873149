import { FormattedMessage, useIntl } from 'react-intl';

import { GraveyardSpace } from '@advitam/api/models/Graveyard/Space';
import { FormLayout, Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import { Layout } from 'containers/Crud';
import actionsMessages from 'messages/actions';

import messages from '../messages';

const PREFIX = 'graveyard';

export function Worships(): JSX.Element {
  const intl = useIntl();

  const spaceOptions: SelectableItem<GraveyardSpace>[] = [
    {
      name: intl.formatMessage(actionsMessages.yes),
      value: GraveyardSpace.YES,
    },
    {
      name: intl.formatMessage(actionsMessages.no),
      value: GraveyardSpace.NO,
    },
    {
      name: intl.formatMessage(messages.full),
      value: GraveyardSpace.FULL,
    },
  ];

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.worship.id} />}>
      <FormLayout.Row>
        <Select
          unselectable
          name={`${PREFIX}.jewish_space`}
          items={spaceOptions}
          label={<FormattedMessage id={messages.jewishSpace.id} />}
          tooltip={<FormattedMessage id={messages.jewishSpaceTooltip.id} />}
        />
        <Select
          unselectable
          name={`${PREFIX}.muslim_space`}
          items={spaceOptions}
          label={<FormattedMessage id={messages.muslimSpace.id} />}
          tooltip={<FormattedMessage id={messages.muslimSpaceTooltip.id} />}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
