import { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  DeathDeclarationJSON,
  DeathDeclarationState,
} from '@advitam/api/models/DeathDeclaration';
import { SubmitButton } from '@advitam/ui/components/Button';
import { PageSpinner } from '@advitam/ui/components/Spinner';

import { makeSelectIsLoading } from './selectors';
import messages from './messages';
import style from './DeathDeclarationValidation.module.scss';

export default function Buttons(): JSX.Element {
  const isLoading = useSelector(makeSelectIsLoading());
  const form = useForm<DeathDeclarationJSON>();

  const onRefuse = useCallback(() => {
    form.change('state', DeathDeclarationState.REFUSED);
  }, [form]);

  const onAccept = useCallback(() => {
    form.change('state', DeathDeclarationState.ACCEPTED);
  }, [form]);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <div className={style.buttons}>
      <SubmitButton
        outline
        text={<FormattedMessage id={messages.refuse.id} />}
        onClick={onRefuse}
      />
      <SubmitButton
        primary
        fixedSize
        text={<FormattedMessage id={messages.accept.id} />}
        onClick={onAccept}
      />
    </div>
  );
}
