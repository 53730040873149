import { Cityhalls } from './Cityhalls'
import { Countries } from './Countries'
import { Departments } from './Departments'
import { Reviews } from './Reviews'

export const Website = {
  Cityhalls,
  Countries,
  Departments,
  Reviews,
} as const
