import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: {
    id: 'app.containers.Client.sections.General.PasswordResetModal.title',
  },
  text: {
    id: 'app.containers.Client.sections.General.PasswordResetModal.text',
  },
  confirm: {
    id: 'app.containers.Client.sections.General.PasswordResetModal.confirm',
  },
  cancel: {
    id: 'app.containers.Client.sections.General.PasswordResetModal.cancel',
  },
});
