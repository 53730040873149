import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, useForm, useFormState } from 'react-final-form';

import { FormUI } from '@advitam/ui';
import type { Address } from '@advitam/ui/components/Form/UI/GooglePlace/types';

import { FuneralForm } from 'containers/Deal/Funeral/types';

import messages from './messages';

function formatAddress(v: Address | undefined): string {
  return [v?.street_number || '', v?.street_name || ''].join(' ').trim();
}

interface ClientAddressProps {
  index: number;
}

export default function ClientAddress({
  index,
}: ClientAddressProps): JSX.Element {
  const form = useForm();
  const { values } = useFormState<FuneralForm>();
  const { client } = values.abilities[index];

  const prefix = `abilities[${index}].client`;

  const value: Address = {
    street_name: client.address,
    street_number: null,
    postal_code: client.postal_code,
    city: client.city,
    country: client.country?.name || null,
    country_code: null,
    latitude: null,
    longitude: null,
  };

  const onLocationChange = useCallback(
    (v: Address | undefined): void => {
      form.batch(() => {
        form.change(`${prefix}.address`, formatAddress(v));
        form.change(`${prefix}.postal_code`, v?.postal_code);
        form.change(`${prefix}.city`, v?.city);
        form.change(`${prefix}.country`, {
          code: v?.country_code,
          name: v?.country,
        });
        form.change(`${prefix}.insee_code`, '');
      });
    },
    [form, prefix],
  );

  return (
    <Field name={`${prefix}.address`}>
      {({ meta }): JSX.Element => (
        <FormUI.GooglePlace
          value={value}
          error={meta.touched && !meta.valid}
          onChange={onLocationChange}
          label={<FormattedMessage id={messages.address.id} />}
          onlyFrance={false}
        />
      )}
    </Field>
  );
}
