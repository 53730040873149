import style from './style.scss';

interface LabelProps {
  title: string;
  isDone?: boolean;
}

export default function Label({ title, isDone }: LabelProps): JSX.Element {
  return (
    <span className={`${style.label} ${isDone ? style['label--done'] : ''}`}>
      {title}
    </span>
  );
}
