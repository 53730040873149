import { Objects } from '@advitam/support'

import { WarehouseBody, WarehouseProductBody } from './types'

type WarehousePayload = Omit<WarehouseBody, 'id' | 'country' | 'airport'> & {
  country_code?: string | null
  airport_id?: number | null
}

export function serialize(warehouse: Omit<WarehouseBody, 'id'>): WarehousePayload {
  return {
    ...Objects.omit(warehouse, 'country', 'airport'),
    country_code: warehouse.country === undefined ? undefined : warehouse.country?.code || null,
    airport_id: warehouse.airport === undefined ? undefined : warehouse.airport?.id || null,
  }
}

type WarehouseProductPayload = Omit<WarehouseProductBody, 'id' | 'product'> & {
  product_id: number
}

export function serializeProduct(
  warehouseProduct: Omit<WarehouseProductBody, 'id'>,
): WarehouseProductPayload {
  return {
    ...Objects.omit(warehouseProduct, 'product'),
    product_id: warehouseProduct.product.id,
  }
}
