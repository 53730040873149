import { ReactNode, useCallback } from 'react'
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form'
import { NullableString } from '../converters'

import MaskedInputUI from '../UI/Masked'
import { isRequired, useValidators, Validator } from '../validators'
import { ErrorMessages, getFormError } from '../errors'

interface MaskedInputProps<FormValues> {
  name: string
  mask: string
  unmask?: boolean
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  maxLength?: number
  validate?: Validator<string, FormValues>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: UseFieldConfig<any>['format']
  renderBelow?: (value: string) => ReactNode
  className?: string
  tooltip?: ReactNode
  hideErrorMessage?: boolean
  errorMessages?: ErrorMessages
}

export default function MaskedInput<FormValues = unknown>({
  name,
  mask,
  unmask,
  label,
  placeholder,
  readOnly,
  disabled,
  required,
  validate,
  renderBelow,
  className,
  tooltip,
  hideErrorMessage,
  errorMessages,
  format,
  parse = NullableString.parse,
}: MaskedInputProps<FormValues>): JSX.Element {
  const validator = useValidators(required && isRequired, validate)
  const render = useCallback(
    ({ meta, input }: FieldRenderProps<string>): JSX.Element => (
      <MaskedInputUI
        mask={mask}
        unmask={unmask}
        label={label}
        onChange={input.onChange}
        onBlur={input.onBlur}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly as false}
        value={input.value}
        error={getFormError(meta, errorMessages, hideErrorMessage)}
        className={className}
        tooltip={tooltip}
        renderBelow={renderBelow}
      />
    ),
    [
      className,
      disabled,
      errorMessages,
      hideErrorMessage,
      label,
      mask,
      placeholder,
      readOnly,
      renderBelow,
      tooltip,
      unmask,
    ],
  )

  return (
    <Field name={name} validate={validator} format={format} parse={parse}>
      {render}
    </Field>
  )
}
