import Link from '../../../Link'
import { MenuLogo as MenuLogoType } from '../types'
import { useSetIsMenuOpen } from '../hooks'
import menuStyle from '../Menu.module.scss'
import style from './Logo.module.scss'

interface MenuLogoProps {
  logo: MenuLogoType
}

export default function MenuLogo({ logo }: MenuLogoProps): JSX.Element {
  const setIsMenuOpen = useSetIsMenuOpen()
  const logoClasses = [menuStyle.logo, style.logo]
  const baseImageClasses = [style.image]
  if (logo.mobileImage) {
    baseImageClasses.push(style.mobile_hidden)
  }

  return (
    <Link
      internal={logo.internal}
      href={logo.href}
      className={logoClasses.join(' ')}
      onClick={(): void => setIsMenuOpen(false)}
    >
      {logo.mobileImage && <span className={style.mobile}>{logo.mobileImage}</span>}
      <span className={baseImageClasses.join(' ')}>{logo.image}</span>
    </Link>
  )
}
