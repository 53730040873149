import { FuneralParlorJSON } from '@advitam/api/models/FuneralParlor';
import { formatEntity } from '../Entity';

export function formatName(funeralParlor: FuneralParlorJSON): string {
  return formatEntity(funeralParlor);
}

export function FormattedName(funeralParlor: FuneralParlorJSON): JSX.Element {
  return <>{formatName(funeralParlor)}</>;
}
