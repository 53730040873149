import { Validator, ValidatorReturnType } from './Validator'

const ERROR = 'has_not_file_type'

export function hasFileType(allowedTypes: string[]): Validator<File | string | null> {
  return (value: File | string | null): ValidatorReturnType => {
    if (!value || typeof value === 'string') {
      return undefined
    }

    if (!allowedTypes.includes(value.type)) {
      return ERROR
    }

    return undefined
  }
}
