import { FormattedMessage } from 'react-intl';

import { FormLayout, ModalTitle, NumberInput } from '@advitam/ui';

import messages from './messages';
import GroupInputs from './parts/GroupInputs';
import Buttons from './parts/Buttons';
import BankTransferLabel from './parts/BankTransferLabel';

export default function EditGroupForm(): JSX.Element {
  return (
    <>
      <ModalTitle>
        <FormattedMessage id={messages.editPrestation.id} />
      </ModalTitle>
      <GroupInputs />
      <FormLayout.Row>
        <NumberInput
          label={<FormattedMessage id={messages.priceTaxExcl.id} />}
          name="invoice.cost_tax_excl"
          suffix="€"
          step={0.01}
        />
        <NumberInput
          label={<FormattedMessage id={messages.priceTaxIncl.id} />}
          name="invoice.cost_tax_incl"
          suffix="€"
          step={0.01}
        />
      </FormLayout.Row>
      <BankTransferLabel />
      <Buttons submitText={<FormattedMessage id={messages.submit.id} />} />
    </>
  );
}
