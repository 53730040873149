import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react'

import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'
import style from './Number.module.scss'

interface BaseInputProps {
  value?: number
  step?: number
  min?: number
  max?: number
  label?: ReactNode
  name?: string
  placeholder?: string
  error?: ReactElement | boolean
  disabled?: boolean
  noscroll?: boolean
  withoutSpinner?: boolean
  tooltip?: ReactNode
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  className?: string
  prefix?: ReactNode
  suffix?: ReactNode
}

interface ReadOnlyInputProps extends BaseInputProps {
  readOnly: true
  onChange?: undefined
}

interface EditableInputProps extends BaseInputProps {
  readOnly?: false
  onChange: ChangeEventHandler<HTMLInputElement>
}

type InputProps = ReadOnlyInputProps | EditableInputProps

function isPartial(value: string): boolean {
  return /[.,]/.test(value) && value.endsWith('0')
}

export default function Input({
  value,
  step,
  min,
  max,
  label,
  name,
  placeholder,
  error,
  disabled,
  noscroll,
  withoutSpinner,
  tooltip,
  onChange,
  readOnly,
  onBlur,
  prefix,
  suffix,
  className = '',
}: InputProps): JSX.Element {
  const [partialValue, setPartialValue] = useState<string | null>(null)

  const inputRef = useRef<HTMLInputElement>(null)
  const wrapperClasses = [
    uiStyle.input,
    error && uiStyle.error,
    disabled && uiStyle.disabled,
    className,
  ].filter(Boolean)

  const elementClasses = [
    uiStyle.input_element,
    (value || value === 0) && uiStyle.filled,
    prefix && uiStyle.with_prefix,
    suffix && uiStyle.with_suffix,
    (suffix || withoutSpinner) && style.without_spinner,
  ].filter(Boolean)

  const onWheel = useCallback((): void => {
    if (noscroll) {
      inputRef.current?.blur()
    }
  }, [noscroll, inputRef])

  const onMaybePartialChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event)
      }
      setPartialValue(isPartial(event.target.value) ? event.target.value : null)
    },
    [onChange],
  )

  return (
    <Label value={label} tooltip={tooltip} error={error} className={uiStyle.field}>
      <span className={wrapperClasses.join(' ')}>
        {prefix}
        <input
          value={partialValue || value}
          step={step}
          min={min}
          max={max}
          name={name}
          type="number"
          placeholder={placeholder}
          className={elementClasses.join(' ')}
          disabled={disabled}
          readOnly={readOnly}
          onWheel={onWheel}
          onChange={onMaybePartialChange}
          onBlur={onBlur}
          ref={inputRef}
        />
        {suffix}
      </span>
    </Label>
  )
}
