import type { ReactElement, ReactNode } from 'react'

import ButtonText from '../../ButtonText'
import Text from '../../Text'
import style from '../Button.module.scss'

interface ContentsProps {
  outline?: boolean
  fixedSize?: boolean
  text?: ReactNode
  icon?: ReactElement | string
}

export default function Contents({ outline, text, icon, fixedSize }: ContentsProps): JSX.Element {
  return (
    <>
      {text &&
        (outline ? (
          <Text>
            <b>{text}</b>
          </Text>
        ) : (
          <ButtonText fixedSize={fixedSize}>{text}</ButtonText>
        ))}
      {icon && <span className={style.icon}>{icon}</span>}
    </>
  )
}
