import { ClientResource, ClientResourceJSON, ClientResourceBase } from '.';

export interface ClientResourceFuneralWillBase extends ClientResourceBase {
  signed_yousign_id: number | null;
}

export interface ClientResourceFuneralWillJSON
  extends ClientResourceJSON,
    ClientResourceFuneralWillBase {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientResourceFuneralWill
  extends ClientResourceFuneralWillBase {}
export class ClientResourceFuneralWill extends ClientResource<
  ClientResourceFuneralWillJSON
> {}
