import { Browsers, Objects } from '@advitam/support'

import { Model } from '../Model'
import type { BusinessUser } from './BusinessUser'
import type { Client } from './Client'

const LS_KEY = '_user_data'

export enum UserType {
  CLIENT = 'client',
  BUSINESS_USER = 'business_user',
}

export interface UserJSON {
  readonly id: number
  provider: 'email'
  email: string
  disabled: boolean
  uid: string
  allow_password_change: boolean
}

interface StoredUserJSON extends UserJSON {
  type: UserType
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-unused-vars
export interface User<T extends UserJSON = UserJSON> extends UserJSON {}

export abstract class User<T extends UserJSON = UserJSON> extends Model<T> {
  abstract get type(): UserType

  isClient(): this is Client {
    return this.type === UserType.CLIENT
  }

  isBusinessUser(): this is BusinessUser {
    return this.type === UserType.BUSINESS_USER
  }

  store(): void {
    if (typeof window === 'undefined') {
      return
    }

    Browsers.storage.setItem(LS_KEY, JSON.stringify({ ...this, type: this.type }))
  }

  static loadFromStorage(): [null, null] | [UserJSON, UserType] {
    if (typeof window === 'undefined') {
      return [null, null]
    }

    const stored = Browsers.storage.getItem(LS_KEY)
    if (!stored) {
      return [null, null]
    }
    const user = JSON.parse(stored) as StoredUserJSON
    return [Objects.omit(user, 'type'), user.type]
  }

  static deleteFromStorage(): void {
    if (typeof window !== 'undefined') {
      Browsers.storage.removeItem(LS_KEY)
    }
  }
}
