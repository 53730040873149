import { FormattedMessage } from 'react-intl';

import { ProductObject } from 'models/Deal/Product';

import messages from '../messages';

interface MonumentProps {
  product: ProductObject;
}

export default function Monument({ product }: MonumentProps): JSX.Element {
  return (
    <div className="flex">
      <div className="product__category">
        <h5>
          <FormattedMessage {...messages.productGeneral} />
        </h5>
        <div>
          <FormattedMessage
            {...messages.productSellPrice}
            values={{ value: product.price }}
          />
        </div>
        <div>
          <FormattedMessage
            {...messages.productSize}
            values={{
              value: product.size_model,
            }}
          />
        </div>
        <div>
          <FormattedMessage
            {...messages.productDiameters}
            values={{
              value: product.diameters,
            }}
          />
        </div>
      </div>
      <div className="product__category">
        <h5>
          <FormattedMessage {...messages.productDimensionsHeader} />
        </h5>
        <div>
          <FormattedMessage
            {...messages.productOutside}
            values={{
              x: product.outside_length,
              y: product.outside_width,
              z: product.outside_height,
            }}
          />
        </div>
        <div>
          <FormattedMessage
            {...messages.productThickness}
            values={{ value: product.thickness }}
          />
        </div>
      </div>
      <div className="product__category">
        <h5>
          <FormattedMessage {...messages.productAspectHeader} />
        </h5>
        <div>
          <FormattedMessage
            {...messages.productShape}
            values={{ value: product.shape }}
          />
        </div>
        <div>
          <FormattedMessage
            {...messages.productMaterial}
            values={{ value: product.material }}
          />
        </div>
        <div>
          <FormattedMessage
            {...messages.productColor}
            values={{ value: product.color }}
          />
        </div>
      </div>
    </div>
  );
}
