import { defineMessages } from 'react-intl';

export default defineMessages({
  updated: { id: 'app.containers.ResourceCable.updated' },
  destroyModalCta: { id: 'app.containers.ResourceCable.destroyModalCta' },
  destroyModalMessage: {
    id: 'app.containers.ResourceCable.destroyModalMessage',
  },
  destroyModalTitle: { id: 'app.containers.ResourceCable.destroyModalTitle' },
  dismiss: { id: 'app.containers.ResourceCable.dismiss' },
  refresh: { id: 'app.containers.ResourceCable.refresh' },
  updatingModalTitle: { id: 'app.containers.ResourceCable.updatingModalTitle' },
  updatingModalText: { id: 'app.containers.ResourceCable.updatingModalText' },
});
