import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { post } from 'api/methods';

export class Generation {
  @authenticate
  static create(dealDocumentIds: number[]): ApiRequestDescriptor<void> {
    return post('/api/v1/deals/documents/generation', {
      deal_documents: dealDocumentIds,
    });
  }
}
