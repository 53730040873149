import { FormattedMessage } from 'react-intl';
import { useFormState } from 'react-final-form';

import { FormLayout, NumberInput } from '@advitam/ui';
import { Layout } from 'containers/Crud';
import crudMessages from 'containers/Crud/messages';

import messages from './messages';
import { CrematoriumForm } from '../types';

const PRICE_STEP = 0.01;

export default function Billing(): JSX.Element {
  const { values } = useFormState<CrematoriumForm>();

  return (
    <Layout.Fieldset
      title={<FormattedMessage id={messages.billing.id} />}
      tooltip={<FormattedMessage id={messages.billingTooltip.id} />}
    >
      <FormLayout.Row>
        <NumberInput
          step={PRICE_STEP}
          name="crematorium.dispersion_jds_fee"
          label={<FormattedMessage id={messages.dispersion.id} />}
          tooltip={<FormattedMessage id={messages.dispersionTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
        <NumberInput
          step={PRICE_STEP}
          name="crematorium.scellement_urn_fee"
          label={<FormattedMessage id={messages.urnSealing.id} />}
          tooltip={<FormattedMessage id={messages.urnSealingTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          step={PRICE_STEP}
          name="crematorium.exhumation_lt_5_price"
          label={
            <FormattedMessage id={messages.exhumationLessThanFiveYears.id} />
          }
          tooltip={
            <FormattedMessage
              id={messages.exhumationLessThanFiveYearsTooltip.id}
            />
          }
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
        <NumberInput
          step={PRICE_STEP}
          name="crematorium.exhumation_gt_5_price"
          label={
            <FormattedMessage id={messages.exhumationMoreThanFiveYears.id} />
          }
          tooltip={
            <FormattedMessage
              id={messages.exhumationMoreThanFiveYearsTooltip.id}
            />
          }
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          step={PRICE_STEP}
          disabled={!values.crematorium.conviviality_room}
          name="crematorium.conviviality_room_cost"
          label={<FormattedMessage id={messages.convivialityRoom.id} />}
          tooltip={
            <FormattedMessage id={messages.convivialityRoomPriceTooltip.id} />
          }
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          step={PRICE_STEP}
          name="crematorium.urn_conservation_fee"
          label={<FormattedMessage id={messages.urnMensualFees.id} />}
          tooltip={<FormattedMessage id={messages.urnMensualFeesTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
