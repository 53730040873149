// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Text-module__text--l9sCp{font-weight:300}.Text-module__text--l9sCp b{font-weight:900}.Text-module__large--U35Ly{font-size:20px;line-height:32px}.Text-module__medium--buu8G{font-size:18px;line-height:24px}.Text-module__small--of7Il{font-size:16px;line-height:24px}.Text-module__extra_small--Npr_H{font-size:14px;line-height:20px}.Text-module__tiny--hB65X{font-size:12px;line-height:18px}`, "",{"version":3,"sources":["webpack://./../ui/components/Text/Text.module.scss","webpack://./../ui/components/Text/_text.scss"],"names":[],"mappings":"AAEA,0BACE,eAAA,CAEA,4BACE,eAAA,CAIJ,2BCTE,cAAA,CACA,gBAAA,CDYF,4BCRE,cAAA,CACA,gBAAA,CDWF,2BCPE,cAAA,CACA,gBAAA,CDUF,iCCNE,cAAA,CACA,gBAAA,CDSF,0BCLE,cAAA,CACA,gBAAA","sourcesContent":["@import './text';\n\n.text {\n  font-weight: 300;\n\n  b {\n    font-weight: 900;\n  }\n}\n\n.large {\n  @include text__large;\n}\n\n.medium {\n  @include text__medium;\n}\n\n.small {\n  @include text__small;\n}\n\n.extra_small {\n  @include text__extra-small;\n}\n\n.tiny {\n  @include text__tiny;\n}\n","@mixin text__large {\n  font-size: 20px;\n  line-height: 32px;\n}\n\n@mixin text__medium {\n  font-size: 18px;\n  line-height: 24px;\n}\n\n@mixin text__small {\n  font-size: 16px;\n  line-height: 24px;\n}\n\n@mixin text__extra-small {\n  font-size: 14px;\n  line-height: 20px;\n}\n\n@mixin text__tiny {\n  font-size: 12px;\n  line-height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `Text-module__text--l9sCp`,
	"large": `Text-module__large--U35Ly`,
	"medium": `Text-module__medium--buu8G`,
	"small": `Text-module__small--of7Il`,
	"extra_small": `Text-module__extra_small--Npr_H`,
	"tiny": `Text-module__tiny--hB65X`
};
export default ___CSS_LOADER_EXPORT___;
