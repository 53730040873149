import type { ComponentType, CSSProperties } from 'react'
import type { GridChildComponentProps } from 'react-window'

export interface CellProps extends Omit<GridChildComponentProps, 'columnIndex' | 'data' | 'style'> {
  className: string
  style?: CSSProperties
}

export const enum SizeUnit {
  PIXEL,
  PERCENT,
}

export const enum Breakpoint {
  TABLET,
  DESKTOP,
  FHD,
}

export interface Column {
  // default: grow
  size?: number
  // default: pixel
  sizeUnit?: SizeUnit
  onlyAbove?: Breakpoint
  Cell: ComponentType<CellProps>
  Header: ComponentType<Record<string, never>>
}
