import { createSelector } from 'reselect';

/**
 * Direct selector to the autoComplete state domain
 */
const selectAutoCompleteDomain = state => state.autoComplete;

/**
 * Other specific selectors
 */
const makeSelectEntities = () =>
  createSelector(selectAutoCompleteDomain, autoCompleteState =>
    autoCompleteState ? autoCompleteState.entities : [],
  );

export { makeSelectEntities, selectAutoCompleteDomain };
