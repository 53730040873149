import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { put } from 'api/methods';
import { Item as ItemSerializer } from 'api/serializers/v2/Deals';
import { DealJSON } from 'models/v2/Deal';
import { assert, presence } from 'lib/Assert';

export class Item {
  @authenticate
  static update(deal: DealJSON): ApiRequestDescriptor<void> {
    assert(presence(deal.id));
    return put(`/api/v2/deals/item/${deal.id}`, ItemSerializer.serialize(deal));
  }
}
