import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { useClipboardCopy } from '@advitam/react'
import { FlatButton, Tooltip } from '@advitam/ui'
import Copy from '@advitam/ui/images/icons/copy.svg'
import ThumbsUp from '@advitam/ui/images/icons/thumbs-up.svg'

import messages from './messages'
import { makeSelectIsFeedbackLoading } from '../selectors'
import { reportCopy, setFeedback } from '../thunk'
import type { Message } from '../types'
import style from './Conversation.module.scss'

interface MessageFeedbackProps {
  message: Message
}

export default function MessageFeedback({ message }: MessageFeedbackProps): JSX.Element | null {
  const dispatch = useDispatch()
  const [copyToClipboard, isCopied] = useClipboardCopy(1)

  const isLoading = useSelector(makeSelectIsFeedbackLoading(message))

  const onCopy = useCallback(() => {
    copyToClipboard(message.message.answer)
    dispatch(reportCopy(message))
  }, [dispatch, copyToClipboard, message])

  const onThumbsUp = useCallback(() => {
    dispatch(setFeedback({ message, useful: true }))
  }, [dispatch, message])

  const onThumbsDown = useCallback(() => {
    dispatch(setFeedback({ message, useful: false }))
  }, [dispatch, message])

  if (!message.message.runId) {
    return null
  }

  return (
    <div className={style.feedback}>
      <Tooltip content={<FormattedMessage id={messages.copied.id} />} visible={isCopied}>
        <FlatButton onClick={onCopy}>
          <Copy />
        </FlatButton>
      </Tooltip>
      <FlatButton
        disabled={isLoading}
        className={message.message.useful === true ? style.active : undefined}
        onClick={onThumbsUp}
      >
        <ThumbsUp />
      </FlatButton>
      <FlatButton
        disabled={isLoading}
        className={[style.thumbs_down, message.message.useful === false && style.active]
          .filter(Boolean)
          .join(' ')}
        onClick={onThumbsDown}
      >
        <ThumbsUp />
      </FlatButton>
    </div>
  )
}
