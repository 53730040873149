import { Objects } from '@advitam/support'
import { ConsulateJSON } from '../../models/Consulate'
import { ConsulateBody } from './types'

type ConsulatePayload = Partial<
  Omit<
    ConsulateJSON,
    'id' | 'country' | 'headquarter_country' | 'reference_country' | 'default'
  > & {
    country_code: string | null
    headquarter_country_code: string | null
    reference_country_id: number | null
  }
>

export function serialize(consulate: Omit<ConsulateBody, 'id'>): ConsulatePayload {
  return {
    ...Objects.omit(consulate, 'country', 'headquarter_country', 'reference_country', 'default'),
    country_code: consulate.country === undefined ? undefined : consulate.country?.code || null,
    headquarter_country_code:
      consulate.headquarter_country === undefined
        ? undefined
        : consulate.headquarter_country?.code || null,
    reference_country_id:
      consulate.reference_country === undefined
        ? undefined
        : consulate.reference_country?.id || null,
  }
}
