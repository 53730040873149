import { createSelector, Selector } from 'reselect';

import { Police, PoliceJSON } from '@advitam/api/models/Police';
import type { SerializedApiError } from '@advitam/api';

import { POLICE } from './constants';
import type { AppStateSubset, State } from './slice';
import { NewPolice } from './types';

type PoliceSelector<T> = Selector<AppStateSubset, T>;

const selectPoliceDomain = (state: AppStateSubset): State => state[POLICE];

export const makeSelectRawPolice = (): PoliceSelector<
  PoliceJSON | NewPolice | null
> => createSelector(selectPoliceDomain, ({ police }) => police);

export const makeSelectPolice = (): PoliceSelector<Police | null> =>
  createSelector(makeSelectRawPolice(), rawPolice =>
    rawPolice?.id ? new Police(rawPolice) : null,
  );

export const makeSelectIsLoading = (): PoliceSelector<boolean> =>
  createSelector(selectPoliceDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): PoliceSelector<boolean> =>
  createSelector(selectPoliceDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): PoliceSelector<boolean> =>
  createSelector(selectPoliceDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): PoliceSelector<boolean> =>
  createSelector(selectPoliceDomain, ({ isUpdatingName }) => isUpdatingName);

export const makeSelectError = (): PoliceSelector<SerializedApiError | null> =>
  createSelector(selectPoliceDomain, ({ error }) => error);

export const makeSelectDestroyError = (): PoliceSelector<SerializedApiError | null> =>
  createSelector(selectPoliceDomain, ({ destroyError }) => destroyError);
