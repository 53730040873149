import Big from './big.svg'
import Small from './small.svg'
import style from './DoubleCheck.module.scss'

interface DoubleCheckProps {
  className?: string
}
export default function DoubleCheck({ className = '' }: DoubleCheckProps): JSX.Element {
  return (
    <span className={[style.wrapper, className].join(' ')}>
      <Small className={style.small} />
      <Big className={style.big} />
    </span>
  )
}
