import { ModelType } from 'models/Model';
import messages from './messages';

export const SKELETON_PRESTATION_PRICE_LINE = {
  category: {
    id: null,
  },
  manual_category: {
    id: null,
  },
  price: null,
  manual_price: null,
  cost: null,
  manual_cost: null,
  description: null,
  manual_description: null,
  is_tax: false,
  manual_is_tax: null,
  price_category: null,
  manual_price_category: null,
  tva_rate: null,
  manual_tva_rate: null,
  model_type: ModelType.SUPPLIER_WAREHOUSE,
  manual_model_type: null,
  model_id: null,
  manual_model_id: null,
};

export const SKELETON_PRESTATION = {
  type: '',
  amount: 1,
  compute: '',
  status: '',
  priceLine: SKELETON_PRESTATION_PRICE_LINE,
  step_id: null,
  funeralWorks: {},
};

export const PRESTATION_COMPUTE_MANUAL = 'manual';
export const PRESTATION_COMPUTE_AUTO = 'auto';

export const PRESTATION_STATUS_EDITED = 'edited';
export const PRESTATION_STATUS_NONE = 'none';
export const PRESTATION_STATUS_DELETED = 'deleted';

export const PRESTATION_PRODUCT = 'product';
export const PRESTATION_SERVICE = 'service';

export const TVA = 20;
export const TVA_REDUCED = 10;
export const TVA_REDUCED_2 = 5.5;
export const NO_TVA = 0;

export const CATEGORY_SET_UP = 1;
export const CATEGORY_TBC = 2;
export const CATEGORY_TAC = 3;
export const CATEGORY_CEREMONY = 4;
export const CATEGORY_COFFINS_PRODUCTS = 5;
export const CATEGORY_CREMATION = 6;
export const CATEGORY_INTERMENT = 7;

export function listTva(intl) {
  return [
    {
      value: TVA,
      name: intl.formatMessage(messages.tva),
    },
    {
      value: TVA_REDUCED,
      name: intl.formatMessage(messages.tvaReduced),
    },
    {
      value: TVA_REDUCED_2,
      name: intl.formatMessage(messages.tvaReduced2),
    },
    {
      value: NO_TVA,
      name: intl.formatMessage(messages.noTva),
    },
  ];
}

export function listCategory(intl) {
  return [
    {
      value: CATEGORY_SET_UP,
      name: intl.formatMessage(messages.categorySetUp),
    },
    {
      value: CATEGORY_TBC,
      name: intl.formatMessage(messages.categoryTbc),
    },
    {
      value: CATEGORY_TAC,
      name: intl.formatMessage(messages.categoryTac),
    },
    {
      value: CATEGORY_CEREMONY,
      name: intl.formatMessage(messages.categoryCeremony),
    },
    {
      value: CATEGORY_COFFINS_PRODUCTS,
      name: intl.formatMessage(messages.categoryCoffinsProducts),
    },
    {
      value: CATEGORY_CREMATION,
      name: intl.formatMessage(messages.categoryCremation),
    },
    {
      value: CATEGORY_INTERMENT,
      name: intl.formatMessage(messages.categoryInterment),
    },
  ];
}

export const PRIMARY = 'primary';
export const COMPLEMENTARY = 'complementary';
export const FAMILY = 'family';

export function listPriceCategories(intl) {
  return [
    {
      value: PRIMARY,
      name: intl.formatMessage(messages.primary),
    },
    {
      value: COMPLEMENTARY,
      name: intl.formatMessage(messages.complementary),
    },
    {
      value: FAMILY,
      name: intl.formatMessage(messages.family),
    },
  ];
}
