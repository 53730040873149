import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@advitam/ui';
import messages from '../messages';
import style from '../Warehouses.module.scss';

interface StatusProps {
  disabled: boolean;
}

export default function Status({ disabled }: StatusProps): JSX.Element {
  const message = disabled ? messages.disabled : messages.enabled;
  const classes = [style.status, disabled && style.disabled].filter(Boolean);

  return (
    <Tooltip
      contentClassName={classes.join(' ')}
      content={<FormattedMessage id={message.id} />}
    >
      <span />
    </Tooltip>
  );
}
