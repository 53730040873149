import { useDeepCompareMemo, useWindowSize } from '@advitam/react'

const DEFAULT_THRESHOLD = 1.4

export function useThresholdSize(mobileBreakpoint: number, ratio = DEFAULT_THRESHOLD): number {
  const windowSize = useWindowSize()
  return useDeepCompareMemo(
    () => (windowSize.width < mobileBreakpoint ? windowSize.height : windowSize.height / ratio),
    [windowSize],
  )
}
