import { useIntl } from 'react-intl';

import { EntityInvoiceEntityType } from '@advitam/api/models/EntityInvoice/Entity/Type';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import messages from './messages';

export function useEntityTypeSelectItems(): SelectableItem<
  EntityInvoiceEntityType
>[] {
  const intl = useIntl();

  return [
    {
      value: EntityInvoiceEntityType.RHA,
      name: intl.formatMessage(messages.entityRha),
    },
    {
      value: EntityInvoiceEntityType.GRAVEYARD,
      name: intl.formatMessage(messages.entityGraveyard),
    },
    {
      value: EntityInvoiceEntityType.POLICE,
      name: intl.formatMessage(messages.entityPolice),
    },
    {
      value: EntityInvoiceEntityType.CONSULATE,
      name: intl.formatMessage(messages.entityConsulate),
    },
    {
      value: EntityInvoiceEntityType.CREMATORIUM,
      name: intl.formatMessage(messages.entityCrematorium),
    },
    {
      value: EntityInvoiceEntityType.SUPPLIER,
      name: intl.formatMessage(messages.entitySupplier),
    },
    {
      value: EntityInvoiceEntityType.HOSPITAL,
      name: intl.formatMessage(messages.entityHospital),
    },
    {
      value: EntityInvoiceEntityType.WORSHIP,
      name: intl.formatMessage(messages.entityWorship),
    },
    {
      value: EntityInvoiceEntityType.CITYHALL,
      name: intl.formatMessage(messages.entityCityhall),
    },
    {
      value: EntityInvoiceEntityType.FUNERAL_PARLOR,
      name: intl.formatMessage(messages.entityFuneralParlor),
    },
    {
      value: EntityInvoiceEntityType.PREFECTURE,
      name: intl.formatMessage(messages.entityPrefecture),
    },
  ];
}
