import type { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import PhoneIcon from '../../../../../images/icons/phone-alt.svg'
import ButtonText from '../../../../ButtonText'
import menuStyle from '../../Menu.module.scss'
import messages from './messages'
import style from './Link.module.scss'

interface PhoneLinkProps {
  number: string
  formattedNumber: string
  text: ReactNode
  className?: string
}

export default function PhoneLink({
  number,
  formattedNumber,
  text,
  className = '',
}: PhoneLinkProps): JSX.Element {
  return (
    <a
      href={`tel:${number}`}
      className={[menuStyle.phone_link, style.phone_link, className].join(' ')}
    >
      <ButtonText className={style.free}>
        <FormattedMessage id={messages.freeText.id} />
      </ButtonText>
      <ButtonText className={style.need_help}>{text}</ButtonText>
      <ButtonText>
        <PhoneIcon />
        {formattedNumber}
      </ButtonText>
    </a>
  )
}
