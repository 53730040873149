import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  billing: { id: 'app.containers.Crematorium.Misc.billing' },
  billingTooltip: { id: 'app.containers.Crematorium.Misc.billingTooltip' },
  ceremonyMaster: { id: 'app.containers.Crematorium.Misc.ceremonyMaster' },
  ceremonyMasterTooltip: {
    id: 'app.containers.Crematorium.Misc.ceremonyMasterTooltip',
  },
  contactMaking: { id: 'app.containers.Crematorium.Misc.contactMaking' },
  contactMakingTooltip: {
    id: 'app.containers.Crematorium.Misc.contactMakingTooltip',
  },
  convivialityRoom: { id: 'app.containers.Crematorium.Misc.convivialityRoom' },
  convivialityRoomSelectTooltip: {
    id: 'app.containers.Crematorium.Misc.convivialityRoomSelectTooltip',
  },
  convivialityRoomPriceTooltip: {
    id: 'app.containers.Crematorium.Misc.convivialityRoomPriceTooltip',
  },
  crematorium: { id: 'app.containers.Crematorium.Misc.crematorium' },
  dispersion: { id: 'app.containers.Crematorium.Misc.dispersion' },
  dispersionTooltip: {
    id: 'app.containers.Crematorium.Misc.dispersionTooltip',
  },
  exhumationMoreThanFiveYears: {
    id: 'app.containers.Crematorium.Misc.exhumationMoreThanFiveYears',
  },
  exhumationMoreThanFiveYearsTooltip: {
    id: 'app.containers.Crematorium.Misc.exhumationMoreThanFiveYearsTooltip',
  },
  exhumationLessThanFiveYears: {
    id: 'app.containers.Crematorium.Misc.exhumationLessThanFiveYears',
  },
  exhumationLessThanFiveYearsTooltip: {
    id: 'app.containers.Crematorium.Misc.exhumationLessThanFiveYearsTooltip',
  },
  family: { id: 'app.containers.Crematorium.Misc.family' },
  functioning: { id: 'app.containers.Crematorium.Misc.functioning' },
  liveCremation: {
    id: 'app.containers.Crematorium.Misc.liveCremation',
  },
  liveCremationTooltip: {
    id: 'app.containers.Crematorium.Misc.liveCremationTooltip',
  },
  optional: { id: 'app.containers.Crematorium.Misc.optional' },
  ovenDimensions: { id: 'app.containers.Crematorium.Misc.ovenDimensions' },
  ovenDimensionsTooltip: {
    id: 'app.containers.Crematorium.Misc.ovenDimensionsTooltip',
  },
  ovenDimensionsPlaceholder: {
    id: 'app.containers.Crematorium.Misc.ovenDimensionsPlaceholder',
  },
  place: { id: 'app.containers.Crematorium.Misc.place' },
  reducedPrices: { id: 'app.containers.Crematorium.Misc.reducedPrices' },
  reducedPricesTooltip: {
    id: 'app.containers.Crematorium.Misc.reducedPricesTooltip',
  },
  urnFreeConservationDuration: {
    id: 'app.containers.Crematorium.Misc.urnFreeConservationDuration',
  },
  urnFreeConservationDurationTooltip: {
    id: 'app.containers.Crematorium.Misc.urnFreeConservationDurationTooltip',
  },
  urnMaxConservationDuration: {
    id: 'app.containers.Crematorium.Misc.urnMaxConservationDuration',
  },
  urnMaxConservationDurationTooltip: {
    id: 'app.containers.Crematorium.Misc.urnMaxConservationDurationTooltip',
  },
  urnMensualFees: { id: 'app.containers.Crematorium.Misc.urnMensualFees' },
  urnMensualFeesTooltip: {
    id: 'app.containers.Crematorium.Misc.urnMensualFeesTooltip',
  },
  urnSealing: { id: 'app.containers.Crematorium.Misc.urnSealing' },
  urnSealingTooltip: {
    id: 'app.containers.Crematorium.Misc.urnSealingTooltip',
  },
});
