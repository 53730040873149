// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateRange-module__input--XLBM3>span>div{z-index:20}.DateRange-module__input--XLBM3>div:first-child{border:none;padding:0}.DateRange-module__input--XLBM3>div:first-child>div{min-width:0}.DateRange-module__input--XLBM3>div:first-child>span{margin:0 8px}.DateRange-module__clear--bax1X{margin-left:8px;width:16px;height:16px}`, "",{"version":3,"sources":["webpack://./../ui/components/Form/UI/DateRange/DateRange.module.scss","webpack://./../ui/scss/_z_index.scss"],"names":[],"mappings":"AAGE,yCACE,UCEa,CDCf,gDACE,WAAA,CACA,SAAA,CAEA,oDACE,WAAA,CAGF,qDACE,YAAA,CAKN,gCACE,eAAA,CACA,UAAA,CACA,WAAA","sourcesContent":["@import '../../../../scss/z_index';\n\n.input {\n  > span > div {\n    z-index: $z-index__modal;\n  }\n\n  > div:first-child {\n    border: none;\n    padding: 0;\n\n    > div {\n      min-width: 0;\n    }\n\n    > span {\n      margin: 0 8px;\n    }\n  }\n}\n\n.clear {\n  margin-left: 8px;\n  width: 16px;\n  height: 16px;\n}\n","$z-index__background: -1;\n$z-index__content: 0;\n$z-index__content--over: $z-index__content + 1;\n$z-index__dropdown: 5;\n$z-index__navbar: 10;\n$z-index__popup: $z-index__navbar + 5;\n$z-index__modal: $z-index__popup + 5;\n$z-index__foreground: $z-index__modal + 5;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `DateRange-module__input--XLBM3`,
	"clear": `DateRange-module__clear--bax1X`
};
export default ___CSS_LOADER_EXPORT___;
