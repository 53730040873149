import { Objects } from '@advitam/support'

import type { PoliceJSON } from '../../models/Police'
import type { PoliceBody } from './types'

type PolicePayload = Partial<
  Omit<PoliceJSON, 'id' | 'country' | 'cityhalls'> & {
    country_code: string | null
    headquarter_country_code: string | null
    cityhall_ids: number[]
  }
>

export function serialize(police: Omit<PoliceBody, 'id'>): PolicePayload {
  return {
    ...Objects.omit(police, 'country', 'headquarter_country', 'cityhalls'),
    country_code: police.country === undefined ? undefined : police.country?.code || null,
    headquarter_country_code:
      police.headquarter_country === undefined
        ? undefined
        : police.headquarter_country?.code || null,
    cityhall_ids: police.cityhalls?.map(cityhall => cityhall.id),
  }
}
