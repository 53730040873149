import Card from './Card'
import CardWithImage from './CardWithImage'
import Default from './Default'
import Product from './Product'

const Radio = {
  Card,
  CardWithImage,
  Default,
  Product,
}

export default Radio
