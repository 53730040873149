import { Validator } from 'react-form';

export function composeValidators(
  ...validators: Array<Validator | false | undefined | null>
): Validator {
  return (field, value): string | false => {
    for (let i = 0; i < validators.length; i += 1) {
      const validator = validators[i];
      const res = validator && validator(field, value);
      if (res) {
        return res;
      }
    }

    return false;
  };
}

export { isUrl } from './isUrl';
export { maxLength } from './maxLength';
