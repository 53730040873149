import { ReactNode, useState } from 'react'

import { FlatButton } from '../../Button'
import Text from '../../Text'
import ChevronDown from '../../../images/icons/chevron-down.svg'
import ChevronUp from '../../../images/icons/chevron-up.svg'

import PlanCard from './PlanCard'
import style from './PlanCard.module.scss'

interface CollapsiblePlanCardProps {
  title: ReactNode
  subTitle: ReactNode
  price: number | null
  collapsibleTitle: ReactNode
  items: ReactNode[]
  isOpen?: boolean
  className?: string
}

export default function CollapsiblePlanCard({
  title,
  subTitle,
  price,
  collapsibleTitle,
  items,
  isOpen,
  className,
}: CollapsiblePlanCardProps): JSX.Element {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(isOpen || false)

  const collapsibleTitleClasses = [style.collapsible__title]
  if (isCollapsibleOpen) {
    collapsibleTitleClasses.push(style['collapsible__title--open'])
  }

  const Chevron = isCollapsibleOpen ? ChevronUp : ChevronDown

  return (
    <PlanCard title={title} subTitle={subTitle} price={price} className={className}>
      <div className={style.collapsible}>
        <FlatButton
          onClick={(): void => setIsCollapsibleOpen(!isCollapsibleOpen)}
          className={collapsibleTitleClasses.join(' ')}
        >
          <Chevron className={style.chevron} />
          <Text>
            <b>{collapsibleTitle}</b>
          </Text>
        </FlatButton>
        <ul className={isCollapsibleOpen ? undefined : style.hidden}>
          {items.map((item, index) => (
            <li key={index}>
              <i>✓</i>
              <Text>{item}</Text>
            </li>
          ))}
        </ul>
      </div>
    </PlanCard>
  )
}
