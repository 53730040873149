import { defineMessages } from '@formatjs/intl';
import { ErrorCode } from './validators';

export default defineMessages({
  [ErrorCode.MISSING_VARIABLES]: {
    id: 'app.containers.Deal.SendBatchModal.missing_variables',
  },
  send: { id: 'app.containers.Deal.SendBatchModal.send' },
  subject: { id: 'app.containers.Deal.SendBatchModal.subject' },
  message: { id: 'app.containers.Deal.SendBatchModal.message' },
  title: { id: 'app.containers.Deal.SendBatchModal.title' },
  validateDocuments: {
    id: 'app.containers.Deal.SendBatchModal.validateDocuments',
  },
});
