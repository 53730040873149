import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import HardSpinner from 'components/HardSpinner';

import SuppliersStatus from './SuppliersStatus';
import Solutions from './Solutions';

import {
  makeSelectBookingStatus,
  makeSelectSolutions,
  makeSelectSuppliers,
  makeSelectisBookingLoading,
  makeSelectBookingCommentLoader,
  makeSelectGroupNames,
  makeSelectSelectedSolutions,
} from './selectors';

import {
  bookSuppliers as bookSuppliersDispatch,
  preBookSuppliers as preBookSuppliersDispatch,
  updateSupplierBookingStatus as updateSupplierBookingStatusDispatch,
  updateSupplierBookingComment as updateSupplierBookingCommentDispatch,
  syncBookingComment as syncBookingCommentDispatch,
  updateSupplierSelection as updateSupplierSelectionDispatch,
} from './actions';

function BookingSection({
  isLoading,
  suppliers,
  solutions,
  globalBookingStatus,
  bookSuppliers,
  preBookSuppliers,
  updateSupplierBookingStatus,
  updateBookingComment,
  bookingCommentLoader,
  syncBookingComment,
  selectedSolutions,
  updateSupplierSelection,
  groupNames,
}) {
  return (
    <section id="suppliers">
      {isLoading && <HardSpinner />}
      <SuppliersStatus
        suppliers={suppliers}
        globalBookingStatus={globalBookingStatus}
        bookSuppliers={bookSuppliers}
        preBookSuppliers={preBookSuppliers}
        updateSupplierBookingStatus={updateSupplierBookingStatus}
        updateBookingComment={updateBookingComment}
        syncBookingComment={syncBookingComment}
        bookingCommentLoader={bookingCommentLoader}
      />
      <Solutions
        groupNames={groupNames}
        solutions={solutions}
        selectedSolutions={selectedSolutions}
        updateSupplierSelection={updateSupplierSelection}
      />
    </section>
  );
}

BookingSection.propTypes = {
  bookSuppliers: PropTypes.func.isRequired,
  preBookSuppliers: PropTypes.func.isRequired,
  updateSupplierBookingStatus: PropTypes.func.isRequired,
  updateBookingComment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  solutions: PropTypes.object.isRequired,
  groupNames: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  globalBookingStatus: PropTypes.string.isRequired,
  bookingCommentLoader: PropTypes.array.isRequired,
  syncBookingComment: PropTypes.func.isRequired,
  updateSupplierSelection: PropTypes.func.isRequired,
  selectedSolutions: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  globalBookingStatus: makeSelectBookingStatus(),
  solutions: makeSelectSolutions(),
  selectedSolutions: makeSelectSelectedSolutions(),
  suppliers: makeSelectSuppliers(),
  bookingCommentLoader: makeSelectBookingCommentLoader(),
  isLoading: makeSelectisBookingLoading(),
  groupNames: makeSelectGroupNames(),
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { dispatch } = dispatchProps;
  const { dealUUID } = ownProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    bookSuppliers: () => dispatch(bookSuppliersDispatch(dealUUID)),
    preBookSuppliers: () => dispatch(preBookSuppliersDispatch(dealUUID)),
    updateSupplierBookingStatus: (supplierID, bookingStatus) =>
      dispatch(
        updateSupplierBookingStatusDispatch(
          supplierID,
          bookingStatus,
          dealUUID,
        ),
      ),
    updateBookingComment: (supplierID, comment) =>
      dispatch(
        updateSupplierBookingCommentDispatch(supplierID, comment, dealUUID),
      ),
    syncBookingComment: (supplierID, comment) =>
      dispatch(syncBookingCommentDispatch(supplierID, comment, dealUUID)),
    updateSupplierSelection: solutions =>
      dispatch(updateSupplierSelectionDispatch(solutions, dealUUID)),
  };
}

const withConnect = connect(mapStateToProps, null, mergeProps);

export default compose(withConnect)(BookingSection);
