import { ReactNode, useCallback } from 'react'
import { Field } from 'react-final-form'
import { DateTime } from 'luxon'

import MaskedInputUI from '../UI/Masked'
import { composeValidators, isDateValid, isRequired, Validator } from '../validators'

const INPUT_FORMAT = 'dd/LL/yyyy'
const MASK = '00/00/0000'
const EMPTY_VALUE = '__/__/____'
const FULL_DATE_REGEX = /\d{4}-\d{2}-\d{2}/

/**
 * Support for legacy formats, this should disapear in the end
 * Value may be something other than a string
 */
interface Formatters {
  toDateTime: (value: string) => DateTime
  fromDateTime: (value: DateTime) => string
}

interface DateOnlyInputProps {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  format?: Formatters
  validate?: Validator<string | null>
  renderBelow?: (value: string) => ReactNode
  className?: string
  tooltip?: ReactNode
}

export default function DateOnlyInput({
  name,
  label,
  placeholder,
  readOnly,
  disabled,
  required,
  format: formatValue,
  validate,
  renderBelow,
  className,
  tooltip,
}: DateOnlyInputProps): JSX.Element {
  const parse = useCallback(
    (value: string, _name: string): string | null => {
      if (value === EMPTY_VALUE) {
        return null
      }

      const date = DateTime.fromFormat(value, INPUT_FORMAT)
      if (!date.isValid) {
        return value
      }
      if (formatValue) {
        return formatValue.fromDateTime(date)
      }
      return date.toISODate()
    },
    [formatValue],
  )

  const format = useCallback(
    (value: string | null, _name: string): string => {
      if (value === null) {
        return ''
      }
      if (typeof value === 'string' && !FULL_DATE_REGEX.test(value)) {
        return value
      }
      if (formatValue) {
        return formatValue.toDateTime(value).toFormat(INPUT_FORMAT)
      }
      return DateTime.fromISO(value).toFormat(INPUT_FORMAT)
    },
    [formatValue],
  )

  return (
    <Field
      name={name}
      validate={composeValidators(
        required && isRequired,
        value => (typeof value === 'string' ? isDateValid()(value, undefined) : undefined),
        validate,
      )}
      format={format}
      parse={parse}
    >
      {(props): JSX.Element => (
        <MaskedInputUI
          mask={MASK}
          label={label}
          onChange={props.input.onChange}
          onBlur={props.input.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly as false}
          value={props.input.value}
          error={props.meta.touched && !props.meta.valid}
          className={className}
          tooltip={tooltip}
          renderBelow={renderBelow}
        />
      )}
    </Field>
  )
}
