import { createSelector, Selector } from 'reselect';

import { makeSelectRawWarehouse } from '../../selectors';
import type { AppStateSubset } from '../../slice';
import type { MiscellaneousFormSectionValues } from './types';

type WarehouseSelector<T> = Selector<AppStateSubset, T>;

export const makeSelectMiscellaneousFormInitialValues = (): WarehouseSelector<
  MiscellaneousFormSectionValues
> =>
  createSelector(makeSelectRawWarehouse(), warehouse => ({
    repatriation: Boolean(warehouse?.airport),
  }));
