import PropTypes from 'prop-types';

import { SelectList } from 'components/Input';
import SupplierComment from './SupplierComment';

import messages from '../messages';

import { BOOKED, ACCEPTED_PREBOOKING, REJECTED_PREBOOKING } from '../constants';

function SupplierStatus({
  supplier,
  updateBookingComment,
  updateSupplierBookingStatus,
  bookingCommentLoader,
  syncBookingComment,
}) {
  return (
    <div className="booking__prestation">
      <div
        className={`booking__prestation__column--supplier booking__prestation__column--cell ${
          supplier.is_not_negociated
            ? ' booking__prestation__column--supplier--not-negociated'
            : ''
        }`}
      >
        {supplier.name}
      </div>
      <div className="booking__prestation__column--comment booking__prestation__column--cell">
        <SupplierComment
          key={supplier.id}
          supplierID={supplier.id}
          comment={supplier.comment}
          updateBookingComment={updateBookingComment}
          bookingCommentLoader={bookingCommentLoader}
          syncBookingComment={syncBookingComment}
        />
      </div>
      <div
        className={`booking__prestation__column--prebook booking__prestation__column__prebook booking__prestation__column--cell booking--${supplier.booking_status}`}
      >
        <SelectList
          name="bookingStatus"
          onChange={({ target: { value } }) =>
            value && updateSupplierBookingStatus(supplier.id, value)
          }
          value={supplier.booking_status}
          options={[
            { value: null, message: '' },
            {
              value: ACCEPTED_PREBOOKING,
              message: messages[ACCEPTED_PREBOOKING],
            },
            {
              value: REJECTED_PREBOOKING,
              message: messages[REJECTED_PREBOOKING],
            },
          ]}
        />
      </div>
      <div
        className={`booking__prestation__column--book booking__prestation__column--cell ${
          supplier.booking_status === BOOKED ? 'booking--booking_booked' : ''
        }`}
      ></div>
    </div>
  );
}

SupplierStatus.propTypes = {
  supplier: PropTypes.object.isRequired,
  updateBookingComment: PropTypes.func.isRequired,
  updateSupplierBookingStatus: PropTypes.func.isRequired,
  bookingCommentLoader: PropTypes.array.isRequired,
  syncBookingComment: PropTypes.func.isRequired,
};

export default SupplierStatus;
