import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import { Path } from 'containers/App/constants';
import { assert } from 'lib/support';

import { makeSelectDeal } from '../../../selectors.typed';
import type { AppStateSubset } from '../../../slice';
import { DUPLICATION_BUTTON } from './constants';

export const duplicateDeal = createAsyncThunk(
  `${DUPLICATION_BUTTON}/DUPLICATE`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      const { body } = await request(Api.V1.Deals.Duplicate.create(deal.uuid));
      dispatch(push(Path.DEAL(body.new_uuid)));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
