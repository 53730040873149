import type { ReactNode } from 'react'

import Heading from '../../Heading'
import Image from '../../Image'
import Text from '../../Text'
import style from './Testimonials.module.scss'

interface CitationProps {
  text: ReactNode
  subtext: ReactNode
  author: ReactNode
  imageSrc: string
}

export default function Citation({ text, subtext, author, imageSrc }: CitationProps): JSX.Element {
  return (
    <div className={[style.card, style.citation].join(' ')}>
      <Heading tagName="div" className={`${style.title} md-show`}>
        {text}
      </Heading>
      <Text large tagName="div" className={`${style.title} md-hide`}>
        {text}
      </Text>
      <Text large tagName="div" className={style.subtext}>
        <b>{subtext}</b>
      </Text>
      <div>
        <Image src={imageSrc} alt="Vignette citation" className={style.thumbnail} />
        <Text tagName="div" className={style.author}>
          {author}
        </Text>
      </div>
    </div>
  )
}
