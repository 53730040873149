import { defineMessages } from 'react-intl';

export default defineMessages({
  defunctSection: {
    id: 'app.containers.DealFuneral.DefunctSection.defunctSection',
  },
  father: {
    id: 'app.containers.DealFuneral.DefunctSection.father',
  },
  mother: {
    id: 'app.containers.DealFuneral.DefunctSection.mother',
  },
  partner: {
    id: 'app.containers.DealFuneral.DefunctSection.partner',
  },
  birthDate: {
    id: 'app.containers.DealFuneral.DefunctSection.birthDate',
  },
  decoration: {
    id: 'app.containers.DealFuneral.DefunctSection.decoration',
  },
  worship: {
    id: 'app.containers.DealFuneral.DefunctSection.worship',
  },
  pacemaker: {
    id: 'app.containers.DealFuneral.DefunctSection.pacemaker',
  },
  weight: {
    id: 'app.containers.DealFuneral.DefunctSection.weight',
  },
  size: {
    id: 'app.containers.DealFuneral.DefunctSection.size',
  },
  width: {
    id: 'app.containers.DealFuneral.DefunctSection.width',
  },
  height: {
    id: 'app.containers.DealFuneral.DefunctSection.height',
  },
  onGuardianship: {
    id: 'app.containers.DealFuneral.DefunctSection.onGuardianship',
  },
  age: {
    id: 'app.containers.DealFuneral.DefunctSection.age',
  },
  isDead: {
    id: 'app.containers.DealFuneral.DefunctSection.isDead',
  },
  maritalStatus: {
    id: 'app.containers.DealFuneral.DefunctSection.maritalStatus',
  },
});
