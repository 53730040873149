import { ServiceType } from 'models/Deal/Service/Type';

export const UPDATABLE_TYPES = [
  ServiceType.ADD_HOLDER_AC,
  ServiceType.ADD_HOLDER_BC,
  ServiceType.DEPOSIT_TAX,
  ServiceType.ENGRAVING,
  ServiceType.FUNERAL_PARLOR_TAX,
  ServiceType.HOSPITAL_FEES,
];

export const TRIGGERABLE_TYPES = [
  ServiceType.ADD_HOLDER_AC,
  ServiceType.ADD_HOLDER_BC,
  ServiceType.DEPOSIT_TAX,
  ServiceType.FUNERAL_PARLOR_TAX,
  ServiceType.HOSPITAL_FEES,
];

export const MIN_DAYS = 0;
export const MIN_HOLDERS = 1;
