import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Api from '@advitam/api';
import { SupplierWarehousesIndexFilters } from '@advitam/api/v1/Suppliers/Warehouses';
import { ResourceList, LinkButton } from '@advitam/ui';
import type { Filter } from '@advitam/ui/components/ResourceList';

import { Path } from 'containers/App/constants';
import { makeSelectDepartments } from 'slices/data';
import actionMessages from 'messages/actions';
import { assert } from 'lib/support';

import { makeSelectSupplier } from '../../../selectors';
import messages from '../messages';
import { makeSelectFilters } from '../selectors';
import { setFilters } from '../slice';
import { fetchWarehouses } from '../thunk';
import style from '../Warehouses.module.scss';

export default function WarehousesFilters(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const departments = useSelector(makeSelectDepartments());
  const filters = useSelector(makeSelectFilters());
  const supplier = useSelector(makeSelectSupplier());
  assert(supplier?.id !== undefined);

  const filtersShape: Filter[] = [
    {
      type: 'Select',
      name: 'disabled_eq',
      placeholder: intl.formatMessage(messages.disabled),
      items: [
        { value: true, name: intl.formatMessage(actionMessages.yes) },
        { value: false, name: intl.formatMessage(actionMessages.no) },
      ],
      initialValue: filters.disabled_eq,
    },
    {
      type: 'Select',
      name: 'department_eq',
      placeholder: intl.formatMessage(messages.department),
      filter: true,
      items: departments.map(department => ({
        name: department.description,
        value: department.department_code,
      })),
      initialValue: filters.department_eq,
    },
    {
      type: 'Autocomplete',
      name: 'cityhall',
      endpoint: Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls),
      placeholder: intl.formatMessage(messages.city),
      initialValue: filters.cityhall,
    },
  ];

  const onChange = useCallback(
    (newFilters: SupplierWarehousesIndexFilters): void => {
      dispatch(setFilters(newFilters));
      dispatch(fetchWarehouses());
    },
    [dispatch],
  );

  return (
    <ResourceList.Filters
      className={style.filters}
      filters={filtersShape}
      onChange={onChange}
      searchFilter={{
        name: 'q',
        initialValue: filters.q,
        placeholder: intl.formatMessage(actionMessages.search),
      }}
    >
      <LinkButton
        primary
        text={<FormattedMessage id={messages.add.id} />}
        internal
        href={Path.NEW}
      />
    </ResourceList.Filters>
  );
}
