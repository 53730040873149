import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { SupplierMinimalWarehouseJSON } from '@advitam/api/models/Supplier/Warehouse';
import { Button, LinkButton, ResourceList, Tooltip } from '@advitam/ui';
import CloneIcon from '@advitam/ui/images/icons/clone.svg';
import EditIcon from '@advitam/ui/images/icons/pen.svg';
import DeleteIcon from '@advitam/ui/images/icons/trash.svg';
import actionsMessages from 'messages/actions';

import { makeSelectCanEdit } from '../../../selectors';
import { duplicateWarehouse } from '../thunk';
import style from '../Warehouses.module.scss';
import Status from './Status';

interface WarehousesRowProps {
  warehouse: SupplierMinimalWarehouseJSON;
  onDelete: () => void;
}

export default function WarehousesRow({
  warehouse,
  onDelete,
}: WarehousesRowProps): JSX.Element {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const canDuplicate = useSelector(makeSelectCanEdit());

  const onDuplicate = useCallback(() => {
    dispatch(duplicateWarehouse(warehouse.id));
  }, [dispatch, duplicateWarehouse, warehouse]);

  return (
    <ResourceList.Row className={style.row}>
      <ResourceList.Cell className={style.status_cell}>
        <Status disabled={warehouse.disabled} />
      </ResourceList.Cell>
      <ResourceList.Cell>{warehouse.name}</ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile>
        {warehouse.department}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.actions}>
        {canDuplicate && (
          <Tooltip
            content={<FormattedMessage id={actionsMessages.duplicate.id} />}
          >
            <Button
              outline
              className={style.action}
              onClick={onDuplicate}
              icon={<CloneIcon />}
            />
          </Tooltip>
        )}
        <Tooltip content={<FormattedMessage id={actionsMessages.edit.id} />}>
          <LinkButton
            outline
            internal
            href={`${pathname}/${warehouse.id}`}
            className={style.action}
            icon={<EditIcon />}
          />
        </Tooltip>
        <Tooltip content={<FormattedMessage id={actionsMessages.delete.id} />}>
          <Button
            outline
            className={style.action}
            onClick={onDelete}
            icon={<DeleteIcon />}
          />
        </Tooltip>
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
