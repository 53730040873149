import { Page } from 'react-pdf';

import PageBase, { connected } from '.';
import { BACKGROUND_IMAGE_WIDTH, MINIATURE_WIDTH } from '../constants';

class PdfPage extends PageBase {
  renderBase(): JSX.Element | null {
    const { pageNumber, miniature } = this.props;
    return (
      <Page
        pageIndex={pageNumber}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        width={miniature ? MINIATURE_WIDTH : BACKGROUND_IMAGE_WIDTH}
      />
    );
  }
}

export default connected(PdfPage);
