import PropTypes from 'prop-types';
import { SelectList } from 'components/Input';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function BrandSelection({ brands, brandSelectedId, onChangeBrand }) {
  const options = brands.map(item => ({
    value: item.id,
    name: item.name,
  }));

  const onChange = e => {
    const newBrand = options.find(
      option => option.value === parseInt(e.target.value, 10),
    );

    onChangeBrand({ id: newBrand.value, name: newBrand.name });
  };

  return (
    <div>
      <FormattedMessage {...messages.brand} tagName="div" />
      <SelectList
        name="brand"
        options={options}
        value={brandSelectedId || ''}
        onChange={onChange}
        emptyOption
      />
    </div>
  );
}

BrandSelection.propTypes = {
  /** List of  brands */
  brands: PropTypes.array.isRequired,
  /**  brand selected */
  brandSelectedId: PropTypes.number,
  /** Function to change  brand */
  onChangeBrand: PropTypes.func.isRequired,
};

export default BrandSelection;
