import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get } from 'api/methods';

import { assert } from 'lib/Assert';
import { AbilityBase } from 'models/Ability';
import { Deal, DealJSON } from 'models/Deal';

export default class Abilities {
  @authenticate
  static index(deal: Deal | DealJSON): ApiRequestDescriptor<AbilityBase[]> {
    assert(deal.id !== undefined);
    return get('/api/v1/abilities', {
      resource_id: deal.id,
      resource_type: 'Deal',
    });
  }
}
