import MonotypeTracking from './MonotypeTracking'

export interface FontThemeProps {
  font?: string
  fontStylesheet?: string
  nonce?: string
}

export default function FontTheme({
  font,
  fontStylesheet,
  nonce = '',
}: FontThemeProps): JSX.Element {
  if (!font) {
    return (
      <>
        <MonotypeTracking />
        <style nonce={nonce}>{`:root{--font:Avenir}`}</style>
      </>
    )
  }

  return (
    <>
      {fontStylesheet ? (
        <link rel="stylesheet" href={fontStylesheet} />
      ) : (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
          <link
            href={`https://fonts.googleapis.com/css2?family=${font.replace(
              /\w/g,
              '+',
            )}:ital,wght@0,300;0,400;0,800;0,900;1,300&display=swap`}
            rel="stylesheet"
          />
        </>
      )}
      <style nonce={nonce}>{`:root{--font:${font}}`}</style>
    </>
  )
}
