import { authenticate } from '../../lib/decorators'
import { get } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'

export class Count {
  @authenticate
  static show(viewed?: boolean): ApiRequestDescriptor<number> {
    return get('/api/v1/notifications/count', { viewed })
  }
}
