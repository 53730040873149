import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormLayout, Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import {
  GraveyardConcessionTypeJSON,
  GraveyardType,
} from '@advitam/api/models/Graveyard';

import { Layout } from 'containers/Crud';
import actionsMessages from 'messages/actions';

import { makeSelectConcessionTypes } from '../../../concessionInfosSlice/selectors';
import messages from '../messages';

const PREFIX = 'graveyard';

function getConcessionTypesOptions(
  concessionTypes: GraveyardConcessionTypeJSON[],
): SelectableItem<GraveyardConcessionTypeJSON>[] {
  return concessionTypes.map(concession => ({
    name: concession.concession_type.name,
    value: concession,
  }));
}

export function Functioning(): JSX.Element {
  const intl = useIntl();
  const concessionTypes = useSelector(makeSelectConcessionTypes());

  const booleanOptions: SelectableItem<boolean>[] = [
    {
      name: intl.formatMessage(actionsMessages.yes),
      value: true,
    },
    {
      name: intl.formatMessage(actionsMessages.no),
      value: false,
    },
  ];

  const graveyardTypeOptions: SelectableItem<GraveyardType>[] = [
    {
      name: intl.formatMessage(messages.classic),
      value: GraveyardType.CLASSIC,
    },
    {
      name: intl.formatMessage(messages.landscapes),
      value: GraveyardType.LANDSCAPES,
    },
  ];

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.functioning.id} />}>
      <FormLayout.Row>
        <Select
          unselectable
          name={`${PREFIX}.is_full`}
          items={booleanOptions}
          label={<FormattedMessage id={messages.full.id} />}
          tooltip={<FormattedMessage id={messages.fullTooltip.id} />}
        />
        <Select
          unselectable
          name={`${PREFIX}.graveyard_type`}
          items={graveyardTypeOptions}
          label={<FormattedMessage id={messages.graveyardType.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          unselectable
          name={`${PREFIX}.has_jds`}
          items={booleanOptions}
          label={<FormattedMessage id={messages.memoriesGarden.id} />}
          tooltip={<FormattedMessage id={messages.memoriesGardenTooltip.id} />}
        />
        <Select
          unselectable
          name={`${PREFIX}.is_family_buying_concession`}
          items={booleanOptions}
          label={<FormattedMessage id={messages.familyBuying.id} />}
          tooltip={<FormattedMessage id={messages.familyBuyingTooltip.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          unselectable
          name={`${PREFIX}.default_concession_type`}
          disabled={concessionTypes.length === 0}
          items={getConcessionTypesOptions(concessionTypes)}
          label={<FormattedMessage id={messages.defaultConcessionType.id} />}
          tooltip={
            <FormattedMessage id={messages.defaultConcessionTypeTooltip.id} />
          }
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
