import { defineMessages } from 'react-intl';

export default defineMessages({
  deals: {
    id: 'app.containers.Account.Stats.deals',
    defaultMessage: 'Total deals: {deals}',
  },
  dealsErrors: {
    id: 'app.containers.Account.Stats.dealsErrors',
    defaultMessage:
      'Number max of consecutive months without errors: {dealsErrors}',
  },
  dealsRemaining: {
    id: 'app.containers.Account.Stats.dealsRemaining',
    defaultMessage: 'Only {dealsRemaining} deals remaining for the next badge',
  },
  dealsErrorsRemaining: {
    id: 'app.containers.Account.Stats.dealsErrorsRemaining',
    defaultMessage:
      'Only {dealsErrorsRemaining} month without error remaining for the next badge',
  },
  gradeGlobal: {
    id: 'app.containers.Account.Stats.gradeGlobal',
    defaultMessage: 'Average grade: {grade} / 5',
  },
  gradeCeremonyGlobal: {
    id: 'app.containers.Account.Stats.gradeCeremonyGlobal',
    defaultMessage: 'Average ceremony grade: {grade} / 5',
  },
});
