import { useState } from 'react';
import PropTypes from 'prop-types';

import GoogleAutocomplete from 'components/GoogleAutocomplete';
import { formatAddress } from 'components/GoogleAutocomplete/utils';
import { SKELETON_ADDRESS } from 'components/CRUD/constants';

function serializeAddress(values) {
  return {
    address: values.address || null,
    city: values.city || null,
    country: values.country || null,
    postal_code: values.postal_code || null,
  };
}

export default function SelectAddress({ address, onChange }) {
  const [value, setValue] = useState();
  const [isSelected, setIsSelected] = useState(true);
  const formattedAddress = formatAddress(address);
  const valueToDisplay = isSelected ? formattedAddress : value;

  const onSelect = values => {
    const updatedAddress = serializeAddress(values);
    onChange(updatedAddress);
    setIsSelected(true);
  };

  const onAutocompleteChange = values => {
    if (!values.address) {
      onChange(SKELETON_ADDRESS);
    }

    setValue(values.address);
    setIsSelected(false);
  };

  return (
    <div>
      <GoogleAutocomplete
        onChange={onAutocompleteChange}
        onSelect={onSelect}
        value={valueToDisplay}
      />
    </div>
  );
}

SelectAddress.propTypes = {
  /** entity data */
  address: PropTypes.object,
  /** set entity data */
  onChange: PropTypes.func,
};
