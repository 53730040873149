import { setDealFields } from 'containers/Deal/slice.ts';
import {
  SET_DELIVERY_LOCATIONS,
  GET_DELIVERY_LOCATIONS,
} from '../constants.ts';

/**
 * Function to get delivery locations from DB
 *
 * @param {String} funeralUuid funeral uuid
 * @returns {Object}
 */
export function fetchDeliveryLocations(parentType, parent) {
  return {
    type: GET_DELIVERY_LOCATIONS,
    parentType,
    parent,
  };
}

/**
 * Function to set delivery locations in store
 *
 * @param {Array} deliveryLocations delivery locations available
 * @returns {Object}
 */
export function setDeliveryLocations(deliveryLocations) {
  return {
    type: SET_DELIVERY_LOCATIONS,
    deliveryLocations,
  };
}

function patchDelivery(delivery) {
  return setDealFields({
    update: {
      item: { delivery },
    },
  });
}

export function setDeliveryLocation(location) {
  return patchDelivery({
    step_type: location.source,
    step_id: location.id,
  });
}

export function setDeliveryField(key, value) {
  return patchDelivery({ [key]: value });
}
