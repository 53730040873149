import PropTypes from 'prop-types';

import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';

import Services from '../Services';
import { renderStepDefaults, renderLocation } from './stepEditorsUtils';
import messages from './messages';

export default function TransportAfterCasketing(props) {
  const {
    step,
    stepServices,
    handleChange,
    addService,
    removeService,
    updateDelegate,
    isManualMode,
    userRole,
    updateService,
  } = props;
  return (
    <div className="step">
      <div className="step__header">
        {renderStepDefaults(step, handleChange)}
        <div className="step__header__locations">
          {renderLocation(step.startLocation, messages.startLocation)}
          {renderLocation(step.endLocation, messages.endLocation)}
        </div>
        <CheckboxDelegate
          isDelegate={!!step.doneByAnotherFuneral}
          updateDelegate={updateDelegate}
        />
      </div>
      <div className="step__content">
        <div className="step__content__services">
          <Services
            stepId={step.id}
            services={stepServices}
            addService={addService}
            removeService={removeService}
            isManualMode={isManualMode}
            userRole={userRole}
            updateService={updateService}
          />
        </div>
      </div>
    </div>
  );
}

TransportAfterCasketing.propTypes = {
  step: PropTypes.object.isRequired,
  stepServices: PropTypes.array.isRequired,
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  updateDelegate: PropTypes.func.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  /** function to update a service */
  updateService: PropTypes.func.isRequired,
};
