// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__wrapper--ggHzT{display:flex;width:100%;overflow:hidden}.style__container--Kl05b{position:relative;flex-grow:1;overflow-y:auto;padding:16px}.style__parent-modal--nSMlF h3{margin:0 0 16px}.style__parent-modal--nSMlF>div{justify-content:space-between;width:250px;max-width:100%}.style__parent-modal--nSMlF button{margin:0}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/DealItems/style.scss"],"names":[],"mappings":"AAEA,uBACE,YAAA,CACA,UAAA,CACA,eAAA,CAGF,yBACE,iBAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CAIA,+BACE,eAAA,CAGF,gCACE,6BAAA,CACA,WAAA,CACA,cAAA,CAGF,mCACE,QAAA","sourcesContent":["@import 'src/scss/mixins/vars';\n\n.wrapper {\n  display: flex;\n  width: 100%;\n  overflow: hidden;\n}\n\n.container {\n  position: relative;\n  flex-grow: 1;\n  overflow-y: auto;\n  padding: $container__padding;\n}\n\n.parent-modal {\n  h3 {\n    margin: 0 0 16px;\n  }\n\n  > div {\n    justify-content: space-between;\n    width: 250px;\n    max-width: 100%;\n  }\n\n  button {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style__wrapper--ggHzT`,
	"container": `style__container--Kl05b`,
	"parent-modal": `style__parent-modal--nSMlF`
};
export default ___CSS_LOADER_EXPORT___;
