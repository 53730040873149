import { DateTime } from 'luxon'

export interface ModelWithDestroy extends Model<unknown> {
  _destroy?: boolean
}

export interface ModelWithId extends Model<unknown> {
  id?: number
}

export class Model<T> {
  constructor(properties: T) {
    Object.assign(this, properties)
  }

  get isDestroyed(): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return Boolean((this as ModelWithDestroy)._destroy)
  }

  get isNew(): boolean {
    return !(this as ModelWithId).id
  }

  static isDestroyed(model: unknown): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return Boolean((model as ModelWithDestroy)._destroy)
  }

  static setDestroyed(model: unknown): void {
    const modelWithDestroy = model as ModelWithDestroy
    // eslint-disable-next-line no-underscore-dangle
    modelWithDestroy._destroy = true
  }

  static unsetDestroyed(model: unknown): void {
    if (Model.isDestroyed(model)) {
      // eslint-disable-next-line no-underscore-dangle, no-param-reassign
      delete (model as ModelWithDestroy)._destroy
    }
  }

  static copyDestroyed(from: unknown, to: unknown): void {
    if (Model.isDestroyed(from)) {
      Model.setDestroyed(to)
    } else {
      Model.unsetDestroyed(to)
    }
  }

  static parseDate(date: string, fixTimezone?: boolean): Date

  static parseDate(date: string | null | undefined, fixTimezone?: boolean): Date | null

  /**
   * Parse an ISO 8601 date
   *
   * @note When fixTimezone is true (the default), the timezone is replaced with the current one,
   * actually making all times expressed as Europe/Paris ones in any timezone.
   */
  static parseDate(date: string | null | undefined, fixTimezone = true): Date | null {
    if (!date) {
      return null
    }

    if (!fixTimezone) {
      return DateTime.fromISO(date).toJSDate()
    }
    return DateTime.fromISO(date)
      .setZone('utc')
      .setZone('local', { keepLocalTime: true })
      .toJSDate()
  }

  static parseTimestamp(ts: number): Date

  static parseTimestamp(ts: number | null | undefined): Date | null

  static parseTimestamp(ts: number | null | undefined): Date | null {
    if (!ts) {
      return null
    }
    return DateTime.fromSeconds(ts)
      .setZone('utc')
      .setZone('local', { keepLocalTime: true })
      .toJSDate()
  }

  static toTimestamp(date: Date): number {
    return DateTime.fromJSDate(date).setZone('utc', { keepLocalTime: true }).toUnixInteger()
  }
}
