import { TransparentButton } from 'components/Button';
import LockIcon from 'components/Icons/LockIcon';
import type { DealDocument } from 'models/Deal/Document';

interface DocumentUploadedProps {
  doc: DealDocument;
  isFileSelected?: boolean;
  onClick: (document: DealDocument) => void;
}

/**
 * Display a document which already upload.
 */
export default function DocumentUploaded({
  onClick,
  doc,
  isFileSelected,
}: DocumentUploadedProps): JSX.Element {
  return (
    <TransparentButton
      onClick={() => onClick(doc)}
      className={`transparentButton documents__content__file ${
        isFileSelected ? 'documents__content__file--selected' : ''
      }`}
      style={
        doc.miniature ? { backgroundImage: `url("${doc.miniature}")` } : {}
      }
    >
      <div className="documents__content__file__banner">{doc.name}</div>
      {doc.locked && (
        <LockIcon isActivated className="documents__content__file--locked" />
      )}
    </TransparentButton>
  );
}
