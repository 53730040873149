import { authenticate, formdataEncoded } from '../../lib/decorators'
import { get, post } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { DocumentJSON } from '../../models/DocumentEditions'

export class Documents {
  @authenticate
  @formdataEncoded
  static create(file: Blob): ApiRequestDescriptor<DocumentJSON> {
    return post('/api/v1/document_editions/documents', { data: file })
  }

  @authenticate
  static show(uuid: string): ApiRequestDescriptor<DocumentJSON> {
    return get(`/api/v1/document_editions/documents/${uuid}`)
  }
}
