import { useEffect, useState } from 'react'
import { Browsers } from '@advitam/support'

export function useIsOnline(): boolean {
  const [isOnline, setIsOnline] = useState(Browsers.context.onLine)

  useEffect(() => {
    const setOnline = (): void => setIsOnline(true)
    const setOffline = (): void => setIsOnline(false)

    window.addEventListener('offline', setOnline)
    window.addEventListener('online', setOffline)

    return (): void => {
      window.removeEventListener('offline', setOnline)
      window.removeEventListener('online', setOffline)
    }
  }, [])

  return isOnline
}
