import { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import type { FocusEvent, ReactNode, RefCallback, KeyboardEvent, ReactElement } from 'react'
import IMask from 'imask'

import Text from '../../../Text'
import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'

interface BaseInputProps {
  mask: string
  unmask?: boolean
  maskDefinitions?: Record<string, RegExp>
  value?: HTMLInputElement['value']
  label?: ReactNode
  name?: string
  placeholder?: string
  error?: ReactElement | boolean
  disabled?: boolean
  tooltip?: ReactNode
  inputRef?: RefCallback<HTMLInputElement>
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  className?: string
  renderBelow?: (value: string) => ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
}

interface ReadOnlyInputProps extends BaseInputProps {
  readOnly: true
  onChange?: undefined
}

interface EditableInputProps extends BaseInputProps {
  readOnly?: false
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void
}

type InputProps = ReadOnlyInputProps | EditableInputProps

export default function MaskedInput({
  mask,
  unmask = false,
  maskDefinitions,
  value = '',
  label,
  name,
  placeholder,
  error,
  disabled,
  tooltip,
  inputRef,
  onChange,
  readOnly,
  onBlur,
  onKeyDown,
  renderBelow,
  prefix,
  suffix,
  className = '',
}: InputProps): JSX.Element {
  const maskRef = useRef<IMask.InputMask<IMask.AnyMaskedOptions> | null>(null)

  const wrapperClasses = [
    uiStyle.input,
    error && uiStyle.error,
    disabled && uiStyle.disabled,
    className,
  ].filter(Boolean)

  const inputClasses = [
    uiStyle.input_element,
    value && value.length > 0 && uiStyle.filled,
    prefix && uiStyle.with_prefix,
    suffix && uiStyle.with_suffix,
  ].filter(Boolean)

  const onAccept = useCallback(
    (event?: ChangeEvent<HTMLInputElement>): void => {
      if (!maskRef.current || !onChange || !event) {
        return
      }

      const newValue = unmask
        ? maskRef.current.unmaskedValue.replaceAll('_', '')
        : maskRef.current.value
      onChange({
        ...event,
        target: {
          ...event.target,
          value: newValue,
        },
      })
    },
    [onChange, unmask],
  )

  const lazy = !value && Boolean(placeholder)

  const onRef = useCallback(
    (element: HTMLInputElement): void => {
      if (!maskRef.current) {
        maskRef.current = IMask(element, {
          mask,
          lazy,
          definitions: maskDefinitions,
        })
      }

      if (inputRef) {
        inputRef(element)
      }
    },
    [lazy, mask, maskDefinitions, inputRef],
  )

  useEffect(() => {
    if (!maskRef.current) {
      return
    }

    maskRef.current.updateOptions({ mask, lazy })
  }, [mask, lazy])

  useEffect(() => {
    if (!maskRef.current) {
      return
    }

    maskRef.current.off('accept')
    maskRef.current.on('accept', onAccept)
  }, [onAccept])

  useEffect(
    () => (): void => {
      if (maskRef.current) {
        maskRef.current.off('accept')
        maskRef.current.destroy()
      }
    },
    [],
  )

  useEffect(() => {
    if (!maskRef.current) {
      return
    }

    if (unmask) {
      if (maskRef.current.unmaskedValue === value) {
        return
      }
      maskRef.current.unmaskedValue = value || ''
    } else {
      if (maskRef.current.value === value) {
        return
      }
      maskRef.current.value = value || ''
    }
    maskRef.current.updateValue()
  }, [unmask, value])

  return (
    <Label value={label} tooltip={tooltip} error={error} className={uiStyle.field}>
      <span className={wrapperClasses.join(' ')}>
        {prefix}
        <input
          defaultValue={value || ''}
          name={name}
          placeholder={placeholder}
          className={inputClasses.join(' ')}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          ref={onRef}
        />
        {suffix}
      </span>
      {renderBelow && (
        <Text small className={uiStyle.below}>
          {renderBelow(value || '')}
        </Text>
      )}
    </Label>
  )
}
