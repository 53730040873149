import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from 'api';

import { DOCUMENT_EDITOR } from './constants';
import type { AppStateSubset, State } from './slice';

type EditorSelector<T> = Selector<AppStateSubset, T>;

const selectDocumentEditorDomain = (state: AppStateSubset): State =>
  state[DOCUMENT_EDITOR];

export const makeSelectIsLoading = (): EditorSelector<boolean> =>
  createSelector(selectDocumentEditorDomain, substate => substate.isLoading);

export const makeSelectError = (): EditorSelector<SerializedApiError | null> =>
  createSelector(selectDocumentEditorDomain, substate => substate.error);

export const makeSelectBlob = (): EditorSelector<Blob | null> =>
  createSelector(selectDocumentEditorDomain, substate => substate.blob);

export const makeSelectVariables = (): EditorSelector<string[]> =>
  createSelector(selectDocumentEditorDomain, substate => substate.variables);

export const makeSelectDealDocumentId = (): EditorSelector<number | null> =>
  createSelector(
    selectDocumentEditorDomain,
    substate => substate.dealDocumentId,
  );
