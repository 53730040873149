export const enum MimeTypes {
  JSON = 'application/json',
  PDF = 'application/pdf',
  XML = 'application/xml',
  MP3 = 'audio/mpeg',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  WEBP = 'image/webp',
}

export const EXTENSION_MIME_TYPES: Record<string, MimeTypes> = {
  jpg: MimeTypes.JPG,
  jpeg: MimeTypes.JPG,
  json: MimeTypes.JSON,
  mp3: MimeTypes.MP3,
  pdf: MimeTypes.PDF,
  png: MimeTypes.PNG,
  webp: MimeTypes.WEBP,
  xml: MimeTypes.XML,
}
