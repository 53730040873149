import { useCallback, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { URLs } from '@advitam/support';
import { Link, Person, ResourceList } from '@advitam/ui';
import { Path } from 'containers/App/constants';

import { makeSelectClients } from './selectors';
import style from './Clients.module.scss';

export default function ClientList(): JSX.Element {
  const clients = useSelector(makeSelectClients());

  const onLinkClick = useCallback((ev: MouseEvent) => {
    ev.stopPropagation();
  }, []);

  return (
    <>
      {clients.map(client => (
        <ResourceList.Row
          className={style.client_row}
          key={client.id}
          link={{ href: Path.CLIENT(client.id || ''), internal: true }}
        >
          <ResourceList.Cell>
            <Person.FormattedName person={client} />
          </ResourceList.Cell>
          <ResourceList.Cell>
            {client.email && (
              <Link
                internal
                href={`mailto:${client.email}`}
                onClick={onLinkClick}
              >
                {client.email}
              </Link>
            )}
          </ResourceList.Cell>
          <ResourceList.Cell>
            {client.phone && (
              <Link internal href={`tel:${client.phone}`} onClick={onLinkClick}>
                {client.phone}
              </Link>
            )}
          </ResourceList.Cell>
          <ResourceList.Cell>
            {client.city ? (
              <Link
                newTab
                href={URLs.GMaps.buildLink(client)}
                onClick={onLinkClick}
              >
                <Person.FormattedAddress address={client} />
              </Link>
            ) : (
              <Person.FormattedAddress address={client} />
            )}
          </ResourceList.Cell>
        </ResourceList.Row>
      ))}
    </>
  );
}
