import { assert } from '@advitam/support'

import { authenticate, withSessionId } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { GraveyardJSON } from '../../models/Graveyard'
import { Concessions } from './Concessions'
import { ConcessionTerms } from './ConcessionTerms'
import { ConcessionTypes } from './ConcessionTypes'
import { Disabled } from './Disabled'
import { serialize } from './serializers'
import { GraveyardPayload } from './types'

export class Graveyards {
  static readonly Concessions = Concessions

  static readonly ConcessionTerms = ConcessionTerms

  static readonly ConcessionTypes = ConcessionTypes

  static readonly Disabled = Disabled

  @authenticate
  static create(graveyard: GraveyardPayload): ApiRequestDescriptor<GraveyardJSON> {
    return post('/api/v1/graveyards', serialize(graveyard))
  }

  @authenticate
  @withSessionId
  static update(graveyard: GraveyardPayload): ApiRequestDescriptor<GraveyardJSON> {
    assert(graveyard.id !== undefined)
    return put(`/api/v1/graveyards/${graveyard.id}`, serialize(graveyard))
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<GraveyardJSON> {
    return get(`/api/v1/graveyards/${id}`)
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/graveyards/${id}`)
  }
}
