/*
 * SideBar Messages
 *
 * This contains all the text for the SideBar components.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  dealState: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealState',
    defaultMessage: 'État du deal ',
  },
  dealOwner: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealOwner',
    defaultMessage: 'En charge du deal ',
  },
  dealSummary: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealSummary',
    defaultMessage: 'Récapitulatif',
  },
  dealKoReason: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealKoReason',
  },
  unsubscribe: {
    id: 'app.containers.DealTypeWrapper.SideBar.unsubscribe',
    defaultMessage: 'Unsubscribe accounts',
  },
  products: {
    id: 'app.containers.DealTypeWrapper.SideBar.products',
    defaultMessage: 'Produits',
  },
  documents: {
    id: 'app.containers.DealTypeWrapper.SideBar.documents',
    defaultMessage: 'Documents',
  },
  payments: {
    id: 'app.containers.DealTypeWrapper.SideBar.payments',
    defaultMessage: 'Facturation',
  },
  ordersLinked: {
    id: 'app.containers.DealTypeWrapper.SideBar.ordersLinked',
    defaultMessage: 'Orders linked',
  },
  suppliers: {
    id: 'app.containers.DealTypeWrapper.SideBar.suppliers',
    defaultMessage: 'Suppliers',
  },
  dealStateConfirmation: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealStateConfirmation',
    defaultMessage: 'You are about to change the deal to {state} are you sure?',
  },
  memorial: {
    id: 'app.containers.DealTypeWrapper.SideBar.memorial',
    defaultMessage: 'Espace mémoire',
  },
  todoList: {
    id: 'app.containers.DealFuneral.TodoList.header',
    defaultMessage: 'Liste de choses à faire',
  },
  client: {
    id: 'app.containers.DealTypeWrapper.SideBar.client',
    defaultMessage: 'Infos Famille',
  },
  steps: {
    id: 'app.containers.DealFuneral.SideBar.steps',
    defaultMessage: 'Organisation Cérémonie',
  },
  others: {
    id: 'app.containers.DealFuneral.SideBar.others',
    defaultMessage: 'Autres services',
  },
  history: {
    id: 'app.containers.DealFuneral.SideBar.history',
    defaultMessage: 'Historique',
  },
  prestations: {
    id: 'app.containers.DealTypeWrapper.SideBar.prestations',
    defaultMessage: 'Prestations',
  },
});
