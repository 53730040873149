import type { IntlShape } from 'react-intl';

import type { FunnelCreatedNotificationJSON } from '@advitam/api/models/Notification';
import { Path } from 'containers/App/constants';
import { Path as FuneralPath } from 'containers/Deal/Funeral/constants';

import Quote from './icons/quote.svg';
import QuoteStandalone from './icons/quote.standalone.png.bin';
import type { TileProps } from './TileProps';
import messages from './messages';

export default function getFunnelCreatedProps(
  notification: FunnelCreatedNotificationJSON,
  intl: IntlShape,
): TileProps {
  const isFuneralDeal = notification.comp_info.deal_type === 'funeral';

  return {
    link: Path.DEAL(
      notification.comp_info.deal_uuid,
      isFuneralDeal ? FuneralPath.HOME : 'taches',
    ),
    IconComponent: Quote,
    icon: QuoteStandalone,
    title: intl.formatMessage(messages.funnelCreatedTitle),
    body: intl.formatMessage(
      isFuneralDeal
        ? messages.funeralFunnelCreatedText
        : messages.marbleFunnelCreatedText,
    ),
  };
}
