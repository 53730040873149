import { ReactNode } from 'react'
import style from './ShoppingCart.module.scss'

interface ItemProps {
  children: ReactNode | ReactNode[]
  className?: string
}

export default function Item({ children, className = '' }: ItemProps): JSX.Element {
  return <div className={`${style.item} ${className}`}>{children}</div>
}
