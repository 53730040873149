import { ReactNode } from 'react'

import Heading from '../Heading'
import Text from '../Text'
import { TextBlocBase } from './Global'
import Phone from './Phone'
import style from './TextBloc.module.scss'

interface HighlightTextBlocProps extends TextBlocBase {
  title: ReactNode
  text: ReactNode
  withPhone?: boolean
  footer?: ReactNode
  isInternationalFormat?: boolean
  className?: string
}

export default function HighlightTextBloc({
  title,
  text,
  withPhone,
  footer,
  isInternationalFormat,
  smallTitle,
  largeTitle,
  xlargeTitle,
  className = '',
}: HighlightTextBlocProps): JSX.Element {
  return (
    <div className={`${style.highlight} ${className}`}>
      <Heading
        small={smallTitle}
        large={largeTitle}
        xlarge={xlargeTitle}
        className={style.title}
        tagName="div"
      >
        {title}
      </Heading>
      <Text>{text}</Text>
      {withPhone && (
        <Phone
          isInternationalFormat={isInternationalFormat}
          footer={footer}
          className={style.phone}
        />
      )}
    </div>
  )
}
