import { defineMessages } from 'react-intl';

export default defineMessages({
  warningFileTooBig: {
    id: 'app.components.UploadFiles.NoModal.warningFileTooBig',
    defaultMessage:
      'Le fichier est trop volumineux. Il doit être inférieur à 8mo.',
  },
  warningFileTypeIsNotValid: {
    id: 'app.components.UploadFiles.NoModal.warningFileTypeIsNotValid',
    defaultMessage:
      "Le type de fichier n'est pas valide. Il faut un jpeg, png, webp ou pdf.",
  },
  fileName: {
    id: 'app.components.UploadFiles.NoModal.fileName',
    defaultMessage: 'Le fichier {fileName} :',
  },
});
