import { Link } from 'react-router-dom';

import type { DefaultWrapperProps } from '@advitam/ui/components/Link/DefaultWrapper';

export function ReactRouterLinkWrapper({
  children,
  target,
  href,
  className,
  rel,
  typeof: attrTypeof,
  property,
  onClick,
  state,
}: DefaultWrapperProps): JSX.Element {
  return (
    <Link
      to={href}
      target={target}
      rel={rel}
      className={className}
      typeof={attrTypeof}
      property={property}
      onClick={onClick}
      state={state}
    >
      {children}
    </Link>
  );
}
