import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { SupplierWarehouseJSON } from '@advitam/api/models/Supplier/Warehouse';
import { ModelType } from 'models/Model';
import PriceLine from 'models/Deal/PriceLine';
import { PolymorphicId } from 'models/PolymorphicId';

import { PRESTATION_FORM } from './constants';
import { Entity } from './types';

const ENTITIES_ROUTES = {
  [ModelType.RHA]: Api.V1.Rha.show.bind(null),
  [ModelType.POLICE]: Api.V1.Police.show.bind(null),
  [ModelType.CREMATORIUM]: Api.V1.Crematoriums.show.bind(null),
  [ModelType.CITYHALL]: Api.V1.Cityhalls.show.bind(null),
  [ModelType.WORSHIP]: Api.V1.Worships.show.bind(null),
  [ModelType.PREFECTURE]: Api.V1.Prefectures.show.bind(null),
  [ModelType.FUNERAL_PARLOR]: Api.V1.FuneralParlors.show.bind(null),
  [ModelType.GRAVEYARD]: Api.V1.Graveyards.show.bind(null),
  [ModelType.HOSPITAL]: Api.V1.Hospitals.show.bind(null),
  [ModelType.SUPPLIER]: null,
  [ModelType.SUPPLIER_WAREHOUSE]: Api.V1.Suppliers.Warehouses.show.bind(null),
  [ModelType.CONSULATE]: Api.V1.Consulates.show.bind(null),
};

async function fetchModelEntity(
  modelId: number,
  modelType: ModelType,
): Promise<Entity | null> {
  const entityRoute = ENTITIES_ROUTES[modelType];

  if (!entityRoute) {
    return null;
  }

  const { body } = await request(entityRoute(modelId));
  return body;
}

/** @TODO move this as PriceLine getter method once priceLine is an instance of PriceLine */
function getPriceLineModel(priceLine: PriceLine): PolymorphicId | null {
  const {
    manual_model_id: manualModelId,
    manual_model_type: manualModelType,
    model_id: modelId,
    model_type: modelType,
  } = priceLine;

  if (manualModelType && manualModelId) {
    return { type: manualModelType, id: manualModelId };
  }

  if (modelType && modelId) {
    return { type: modelType, id: modelId };
  }

  return null;
}

export const fetchEntity = createAsyncThunk(
  `${PRESTATION_FORM}_FETCH_ENTITY`,
  async (priceLine: PriceLine, { rejectWithValue }) => {
    const priceLineModel = getPriceLineModel(priceLine);
    if (!priceLineModel) {
      return { entity: null, supplier: null };
    }

    try {
      const { id, type } = priceLineModel;
      const entity = await fetchModelEntity(id, type);
      if (!entity || type !== ModelType.SUPPLIER_WAREHOUSE) {
        return { entity, supplier: null };
      }
      const { body } = await request(
        Api.V1.Suppliers.show((entity as SupplierWarehouseJSON).supplier_id),
      );
      return { entity, supplier: body };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
