import { defineMessages } from 'react-intl';

export default defineMessages({
  requiredField: {
    id: 'app.components.ReactForm.requiredField',
  },
  invalidEmail: {
    id: 'app.components.ReactForm.invalidEmail',
  },
  invalidYear: {
    id: 'app.components.ReactForm.invalidYear',
  },
  invalidDate: {
    id: 'app.components.ReactForm.invalidDate',
  },
  invalidHour: {
    id: 'app.components.ReactForm.invalidHour',
  },
  invalidPhone: {
    id: 'app.components.ReactForm.invalidPhone',
  },
  fileTooBig: {
    id: 'app.components.ReactForm.fileTooBig',
  },
  fileInvalid: {
    id: 'app.components.ReactForm.fileInvalid',
  },
  passwordTooShort: {
    id: 'app.components.ReactForm.passwordTooShort',
  },
  passwordDifferents: {
    id: 'app.components.ReactForm.passwordDifferents',
  },
  selectASuggestion: {
    id: 'app.containers.ReactForm.selectASuggestion',
  },
});
