import { ReactNode } from 'react'

import Desktop from '../Desktop'
import Item from './Item'
import style from './Empty.module.scss'

interface EmptyProps {
  title: ReactNode
  loadingLineCount?: number
  totals: ReactNode
  action?: ReactNode
}

export default function Empty({
  title,
  loadingLineCount = 6,
  totals,
  action,
}: EmptyProps): JSX.Element {
  return (
    <Desktop title={title} totals={null} action={action} className={style.empty}>
      <div>
        {[...Array<number>(loadingLineCount)].map((_e, index) => (
          <Item key={index} />
        ))}
      </div>
      <span className={style.separator} />
      {totals}
    </Desktop>
  )
}
