import { UnsavedConcession } from './types';

export const GRAVEYARD_CONCESSIONS = 'Graveyard/Concessions';

export const CONCESSION_SKELETON: UnsavedConcession = {
  name: null,
  description: null,
  graveyard_concession_term_id: null,
  graveyards_concession_type_id: null,
  is_built: null,
  size_square_meters: null,
  size_places: null,
  height: null,
  length: null,
  width: null,
  price: null,
};
