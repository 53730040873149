import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl, FormattedDate, FormattedTime } from 'react-intl';

import { NotificationJSON } from '@advitam/api/models/Notification';
import { FlatButton, Text } from '@advitam/ui';

import { setIsNotificationPanelOpen } from '../slice';
import { markAsRead } from '../thunk';
import tilePropsFactory from './propsFactory';
import style from './NotificationTile.module.scss';

interface NotificationTileProps {
  notification: NotificationJSON;
}

export default function NotificationTile({
  notification,
}: NotificationTileProps): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { IconComponent, title, body, link } = tilePropsFactory(
    notification,
    intl,
  );

  const classes = [style.tile, !notification.viewed && style.unread].filter(
    Boolean,
  );

  const onClick = useCallback(() => {
    if (!notification.viewed) {
      dispatch(markAsRead(notification));
    }
    dispatch(setIsNotificationPanelOpen(false));
  }, [dispatch, notification]);

  const content = (
    <>
      <span className={style.icon}>
        <IconComponent />
      </span>
      <span className={style.infos}>
        <Text>
          <b>{title}</b>
        </Text>
        {body && <Text small>{body}</Text>}
        <Text extraSmall className={style.date}>
          <FormattedDate value={notification.created_at} />
          {' - '}
          <FormattedTime value={notification.updated_at} />
        </Text>
      </span>
    </>
  );

  if (!link) {
    return (
      <FlatButton onClick={onClick} className={classes.join(' ')}>
        {content}
      </FlatButton>
    );
  }

  return (
    <Link
      to={link}
      /* TODO: Remove this when the deal has been refactored
         Right now the app crash when switch deal through the react router
      */
      reloadDocument
      onClick={onClick}
      className={classes.join(' ')}
    >
      {content}
    </Link>
  );
}
