import { Cable } from 'lib/reactvitam/action_cable';
import { useCable } from './useCable';

interface ActionCableProps<InitParams> {
  cable: Cable<InitParams>;
  cableParams: InitParams;
}

/**
 * @deprecated Use the useCable hook instead
 * This only exists for when we can not use hooks
 */
export function ActionCable<InitParams>({
  cable,
  cableParams,
}: ActionCableProps<InitParams>): null {
  useCable(cable, cableParams);
  return null;
}
