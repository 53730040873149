import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  managedPlaces: {
    id: 'app.containers.Police.sections.Miscellaneous.managedPlaces',
  },
  managedPlacesTooltip: {
    id: 'app.containers.Police.sections.Miscellaneous.managedPlacesTooltip',
  },
  place: { id: 'app.containers.Police.sections.Miscellaneous.place' },
});
