import { defineMessages } from 'react-intl';

export default defineMessages({
  unsubscribeSection: {
    id: 'app.containers.DealFuneral.UnsubscribeSection.unsubscribeSection',
    defaultMessage: 'Unsubscribe section',
  },
  account: {
    id: 'app.containers.DealFuneral.UnsubscribeSection.account',
    defaultMessage: 'Close accounts',
  },
  socialMedia: {
    id: 'app.containers.DealFuneral.UnsubscribeSection.socialMedia',
    defaultMessage: 'Close social medias',
  },
});
