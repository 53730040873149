import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from 'lib/support';

import { makeSelectClient } from '../../selectors';
import type { AppStateSubset } from '../../slice';
import { CLIENT_RESOURCES } from './constants';

export const fetchResources = createAsyncThunk(
  `${CLIENT_RESOURCES}/FETCH`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const client = makeSelectClient()(state);
    assert(client !== null);

    try {
      const { body } = await request(Api.V1.Clients.Abilities.index(client.id));
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
