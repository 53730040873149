import PropTypes from 'prop-types';

/**
 * @deprecated
 */
export default function H2({ children, className, dark, inline }) {
  const classes = [];
  if (inline) {
    classes.push('h2--inline');
  }
  if (dark) {
    classes.push('h2--dark');
  }
  if (className) {
    classes.push(className);
  }
  return <h2 className={classes.filter(Boolean).join(' ')}>{children}</h2>;
}

H2.propTypes = {
  children: PropTypes.node,
  dark: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
};
