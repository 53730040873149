import type { ReactNode } from 'react'

import Text from '../../../Text'
import style from '../UI.module.scss'

interface ErrorMessageProps {
  error: ReactNode
}

export default function ErrorMessage({ error }: ErrorMessageProps): JSX.Element {
  return (
    <Text small className={style.error_message}>
      {error}
    </Text>
  )
}
