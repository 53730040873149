import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { FullClientJSON } from '@advitam/api/models/Client/Full';
import type { SupportingDocumentIndexJSON } from '@advitam/api/models/SupportingDocuments/SupportingDocumentIndex';
import { assert } from 'lib/support';

import { makeSelectRawClient } from '../../selectors';
import type { AppStateSubset as ClientAppStateSubset } from '../../slice';
import { CLIENT_DOCUMENTS } from './constants';
import { makeSelectSupportingDocuments } from './selectors';
import type { AppStateSubset as DocumentsAppStateSubset } from './slice';
import type { SectionValues, UnsavedSupportingDocument } from './types';

type AppStateSubset = ClientAppStateSubset & DocumentsAppStateSubset;

async function fetchAllDocuments(
  client: FullClientJSON,
): Promise<SupportingDocumentIndexJSON[]> {
  const requestDescriptor =
    client.defunct_id === null
      ? Api.V1.Clients.SupportingDocuments.index(client.id)
      : Api.V1.Defuncts.SupportingDocuments.index(client.defunct_id);
  const { body } = await request(requestDescriptor);
  return body;
}

export const fetchDocuments = createAsyncThunk(
  `${CLIENT_DOCUMENTS}/FETCH`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const client = makeSelectRawClient()(state);
    assert(client !== null);

    try {
      return fetchAllDocuments(client);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

async function saveDocument(
  document: SupportingDocumentIndexJSON | UnsavedSupportingDocument,
  client: FullClientJSON,
): Promise<void> {
  if (document.id === undefined) {
    const requestDescriptor =
      client.defunct_id === null
        ? Api.V1.Clients.SupportingDocuments.create(
            client.id,
            document.document_type.id,
            document.file,
          )
        : Api.V1.Defuncts.SupportingDocuments.create(
            client.defunct_id,
            document.document_type.id,
            document.file,
          );

    await request(requestDescriptor);
  }
}

export const saveDocuments = createAsyncThunk(
  `${CLIENT_DOCUMENTS}/SAVE`,
  async (values: SectionValues, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const existingDocuments = makeSelectSupportingDocuments()(state);
    const client = makeSelectRawClient()(state);
    assert(client !== null);

    try {
      await Promise.all(
        values.map(async document => {
          if (document.id === undefined) {
            await saveDocument(document, client);
          }
        }),
      );
      await Promise.all(
        existingDocuments.map(async document => {
          if (!values.some(value => value.id === document.id)) {
            const requestDescriptor =
              client.defunct_id === null
                ? Api.V1.Clients.SupportingDocuments.destroy(document.id)
                : Api.V1.Defuncts.SupportingDocuments.destroy(document.id);
            await request(requestDescriptor);
          }
        }),
      );

      return fetchAllDocuments(client);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
