import { ReactElement, ReactNode } from 'react'
import style from './Layout.module.scss'

interface ButtonProps {
  icon: ReactElement
  children: ReactNode | ReactNode[]
  className?: string
  onClick: () => void
}

export default function Button({
  icon,
  children,
  className = '',
  onClick,
}: ButtonProps): JSX.Element {
  return (
    <button type="button" className={`${style.button} ${className}`} onClick={onClick}>
      <div className={style.icon}>{icon}</div>
      <div>{children}</div>
    </button>
  )
}
