import { ApiError } from 'api';
import { OpsLog } from 'models/OpsLog';
import { createSelector, Selector } from 'reselect';
import { makeSelectDealOpsLogs } from '../../selectors';

import { PAYMENTS_OPS_LOGS } from './constants';
import { State, AppStateSubset } from './slice';
import { AppStateSubset as PaymentsAppStateSubset } from '../../slice';

type PaymentsOpsLogsSelector<T> = Selector<
  AppStateSubset & PaymentsAppStateSubset,
  T
>;

export const selectPaymentsOpsLogsDomain = (state: AppStateSubset): State =>
  state[PAYMENTS_OPS_LOGS];

export const makeSelectDealId = (): PaymentsOpsLogsSelector<number | null> =>
  createSelector(selectPaymentsOpsLogsDomain, substate => substate.dealId);

export const makeSelectOpsLogs = (
  dealId: number | null,
): PaymentsOpsLogsSelector<OpsLog[]> =>
  createSelector(
    makeSelectDealOpsLogs(dealId || -1),
    logs => logs?.reverse() || [],
  );

export const makeSelectIsLoading = (): PaymentsOpsLogsSelector<boolean> =>
  createSelector(selectPaymentsOpsLogsDomain, substate => substate.isLoading);

export const makeSelectIsCreationLoading = (): PaymentsOpsLogsSelector<
  boolean
> =>
  createSelector(
    selectPaymentsOpsLogsDomain,
    substate => substate.isCreationLoading,
  );

export const makeSelectError = (): PaymentsOpsLogsSelector<ApiError | null> =>
  createSelector(selectPaymentsOpsLogsDomain, substate => substate.error);
