import { ReactNode, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsPopup } from 'containers/Crud';
import { assert } from 'lib/support';

import Sidebar from '../Sidebar';
import { makeSelectRawConsulate } from '../selectors';
import { updateConsulateName } from '../thunk';
import type { ConsulateForm } from './types';
import style from '../Consulate.module.scss';

interface WrapperProps<T> {
  children: ReactNode;
  initialSectionValues?: T;
  onSubmit: (values: ConsulateForm<T>) => void;
}

export default function Wrapper<T>({
  children,
  initialSectionValues,
  onSubmit,
}: WrapperProps<T>): JSX.Element {
  const dispatch = useDispatch();

  const consulate = useSelector(makeSelectRawConsulate());
  assert(consulate !== null);

  const initialValues: Partial<ConsulateForm<T>> = {
    consulate,
    sectionValues: initialSectionValues,
  };

  const handleFormSubmit = useCallback(
    (values: ConsulateForm<T>): void => {
      if (values.sectionValues && consulate.name !== values.consulate.name) {
        dispatch(updateConsulateName(values.consulate.name));
      }

      onSubmit(values);
    },
    [onSubmit, consulate, dispatch],
  );

  return (
    <Form<ConsulateForm<T>>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <Sidebar />
          {(dirty || !consulate.id) && <ActionsPopup isNew={!consulate.id} />}
          {children}
        </form>
      )}
    </Form>
  );
}
