import File, { connected } from '.';
import TemplatePage from '../Page/Template';

class Template extends File {
  render(): JSX.Element | null {
    const { miniatures, uploads } = this.props;

    return (
      <>
        {uploads.map((src, i) => (
          <TemplatePage
            key={src}
            pageNumber={i}
            miniature={miniatures}
            src={src}
          />
        ))}
      </>
    );
  }
}

export default connected(Template);
