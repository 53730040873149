import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { HospitalJSON } from '@advitam/api/models/Hospital';
import { saveEditableDocuments } from 'containers/Crud/Documents/Editable';
import { saveSupportingDocuments } from 'containers/Crud/Documents/Supporting';
import { assert } from 'lib/support';

import { HOSPITAL } from './constants';
import type { NewHospital } from './types';
import {
  createHospital,
  destroyHospital,
  fetchHospital,
  setIsHospitalDisabled,
  updateHospital,
  updateHospitalName,
} from './thunk';

export interface State {
  hospital: HospitalJSON | NewHospital | null;
  isLoading: boolean;
  isSaving: boolean;
  isDestroying: boolean;
  isUpdatingName: boolean;
  error: SerializedApiError | null;
  destroyError: SerializedApiError | null;
}

export interface AppStateSubset {
  [HOSPITAL]: State;
}

export const initialState: State = {
  hospital: null,
  isLoading: false,
  isSaving: false,
  isDestroying: false,
  isUpdatingName: false,
  error: null,
  destroyError: null,
};

const slice = createSlice({
  name: HOSPITAL,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setHospital(
      state,
      { payload }: PayloadAction<HospitalJSON | NewHospital | null>,
    ) {
      state.hospital = payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchHospital.pending, state => {
      state.isLoading = true;
      state.destroyError = null;
    });
    builder.addCase(fetchHospital.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.hospital = payload;
    });
    builder.addCase(fetchHospital.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createHospital.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(createHospital.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(createHospital.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateHospital.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateHospital.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.hospital = payload;
    });
    builder.addCase(updateHospital.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateHospitalName.pending, state => {
      state.isUpdatingName = true;
    });
    builder.addCase(updateHospitalName.fulfilled, (state, { payload }) => {
      state.isUpdatingName = false;
      state.hospital = payload;
    });
    builder.addCase(updateHospitalName.rejected, (state, { payload }) => {
      state.isUpdatingName = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(destroyHospital.pending, state => {
      state.isDestroying = true;
    });
    builder.addCase(destroyHospital.fulfilled, state => {
      state.isDestroying = false;
    });
    builder.addCase(destroyHospital.rejected, (state, { payload }) => {
      state.isDestroying = false;
      state.destroyError = ApiError.serialize(payload);
    });

    builder.addCase(setIsHospitalDisabled.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setIsHospitalDisabled.fulfilled, (state, { meta }) => {
      state.isSaving = false;
      assert(state.hospital !== null);
      state.hospital.disabled = meta.arg;
    });
    builder.addCase(setIsHospitalDisabled.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveEditableDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveEditableDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveEditableDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveSupportingDocuments.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveSupportingDocuments.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(saveSupportingDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearError, setHospital } = slice.actions;
export default slice;
