import { authenticate } from '../../../lib/decorators'
import { get } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { PrestationCoverageJSON } from '../../../models/Prestation'
import { Keys } from './Keys'

export class Coverage {
  static readonly Keys = Keys

  @authenticate
  static index(key: string): ApiRequestDescriptor<PrestationCoverageJSON[]> {
    return get(`/api/v1/prestations/${key}/coverages`)
  }
}
