import { useCallback, useEffect, useRef } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { compose } from '@reduxjs/toolkit';

import { Model } from '@advitam/api/models/Model';
import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { Button } from '@advitam/ui';
import { addCollectionItem, Layout } from 'containers/Crud';
import { withDatasets } from 'slices/data';

import type { FuneralForm } from '../../Funeral/types';
import Client from './Form/Client';
import Defunct from './Form/Defunct';
import DefunctFather from './Form/DefunctFather';
import DefunctMother from './Form/DefunctMother';
import DefunctPartner from './Form/DefunctPartner';
import SpecialValuesSpy from './Form/SpecialValuesSpy';
import ClientAutocompletionModal from './ClientAutocompletionModal';
import clientAutocompletionModalSlice from './ClientAutocompletionModal/slice';
import messages from './messages';
import { ABILITIES_SKELETON, ABILITY_SKELETON } from './constants';
import style from './Identity.module.scss';

function Identity(): JSX.Element {
  const form = useForm<FuneralForm>();
  const { values } = useFormState<FuneralForm>();
  const clientsColumn = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (values.abilities.length === 0) {
      form.change('abilities', ABILITIES_SKELETON);
    }
  }, [form, values.abilities.length]);

  const onAddClient = useCallback(() => {
    assert(clientsColumn.current !== null);
    addCollectionItem(
      form,
      'abilities',
      ABILITY_SKELETON,
      clientsColumn.current,
    );
  }, [clientsColumn, form]);

  return (
    <Layout.Container>
      <Layout.ColumnGroup>
        <div className={style.header}>
          <Button
            primary
            onClick={onAddClient}
            text={<FormattedMessage id={messages.addClient.id} />}
          />
        </div>
      </Layout.ColumnGroup>
      <Layout.ColumnGroup>
        <Layout.Column>
          <Defunct />
          <DefunctFather />
          <DefunctMother />
          <DefunctPartner />
        </Layout.Column>
        <Layout.Column ref={clientsColumn}>
          <Field name="abilities">
            {(): JSX.Element | null => {
              if (values.abilities.length === 0) {
                return null;
              }
              return (
                <>
                  <SpecialValuesSpy />
                  <Client
                    index={values.abilities.findIndex(
                      ability => ability.special,
                    )}
                  />
                  {values.abilities.map((ability, index) =>
                    ability.special || Model.isDestroyed(ability) ? null : (
                      <Client
                        index={index}
                        key={ability.id || `idx-${index}`}
                      />
                    ),
                  )}
                </>
              );
            }}
          </Field>
        </Layout.Column>
      </Layout.ColumnGroup>

      <ClientAutocompletionModal />
    </Layout.Container>
  );
}

export default compose(
  withDatasets('worshipTypes'),
  withSlice(clientAutocompletionModalSlice),
)(Identity);
