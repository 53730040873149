import { ReactNode } from 'react'
import Text from '../../../Text'
import style from '../Step.module.scss'

interface DetailsProps {
  title: ReactNode
  details: ReactNode | ReactNode[]
}

export default function Details({ title, details }: DetailsProps): JSX.Element {
  return (
    <div className={style.details}>
      <Text large className={style.title}>
        <b>{title}</b>
      </Text>
      <Text className={style.text}>{details}</Text>
    </div>
  )
}
