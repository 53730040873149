import { DragEvent } from 'react';

import { assert } from 'lib/Assert';

import { InputTarget } from '../slice';

export const enum InputDragActionType {
  MOVE,
  RESIZE,
}

export const enum Edge {
  TOP = 1,
  RIGHT = 2,
  BOTTOM = 4,
  LEFT = 8,
}

export interface InputMoveAction {
  type: InputDragActionType.MOVE;
}

export interface InputResizeAction {
  type: InputDragActionType.RESIZE;
  edge: Edge;
}

export type InputDragAction = InputMoveAction | InputResizeAction;

export interface InputDragData {
  target: InputTarget;
  offset: [number, number];
  action: InputDragAction;
}

let dragData: InputDragData | undefined;

export function getDragData(): InputDragData {
  assert(dragData !== undefined);
  return dragData;
}

export function setDragDataTarget(page: number, input: number): void {
  assert(dragData !== undefined);
  dragData.target = { page, input };
}

export function clearDragData(): void {
  dragData = undefined;
}

export function createTransparentImage(): HTMLCanvasElement {
  const img = document.createElement('canvas');
  img.width = 1;
  img.height = 1;
  const ctx = img.getContext('2d');
  assert(ctx !== null);
  const data = ctx.getImageData(0, 0, 1, 1);
  data.data[3] = 0;
  ctx.putImageData(data, 0, 0);
  return img;
}

export function onInputDragStart(
  event: DragEvent,
  target: InputTarget,
  action: InputDragAction,
): void {
  event.dataTransfer.setDragImage(createTransparentImage(), 0, 0);

  const targetPosition = (event.target as HTMLDivElement).getBoundingClientRect();
  const offset: [number, number] = [
    event.clientX - targetPosition.left,
    event.clientY - targetPosition.top,
  ];
  dragData = { target, offset, action };
}
