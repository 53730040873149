import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { assert } from '@advitam/support';

import { HISTORY } from './constants';
import { makeSelectDeal } from '../../selectors.typed';
import type { AppStateSubset } from '../../slice';

export const fetchBatchesLogs = createAsyncThunk(
  `${HISTORY}_FETCH_BATCHES_LOGS`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      const { body } = await request(
        Api.V1.Deals.Documents.Batches.Logs.index(deal.uuid),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
