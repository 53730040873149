// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cremation-module__ceremony__wrapper--SCuYL,.Cremation-module__conviviality_room__wrapper--B6eWr{display:flex;align-items:center}.Cremation-module__conviviality_room--xBQrF{display:flex;align-items:center;margin-left:5px}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/DealFuneral/StepsSection/StepEditors/Cremation/Cremation.module.scss"],"names":[],"mappings":"AAAA,iGAEE,YAAA,CACA,kBAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".ceremony__wrapper,\n.conviviality_room__wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.conviviality_room {\n  display: flex;\n  align-items: center;\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ceremony__wrapper": `Cremation-module__ceremony__wrapper--SCuYL`,
	"conviviality_room__wrapper": `Cremation-module__conviviality_room__wrapper--B6eWr`,
	"conviviality_room": `Cremation-module__conviviality_room--xBQrF`
};
export default ___CSS_LOADER_EXPORT___;
