import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import BookingSection from '../../BookingSection/index.js';
import { makeSelectDealIsDirty } from '../../selectors.js';

const BookingSectionComponent = (BookingSection as unknown) as React.FC<{
  dealUUID: string | undefined;
}>;

export default function Suppliers(): JSX.Element | null {
  const isDealDirty = useSelector(makeSelectDealIsDirty());
  const { dirty: isFormDirty } = useFormState();
  const { dealUUID } = useParams();

  if (isDealDirty || isFormDirty) {
    return null;
  }

  return <BookingSectionComponent dealUUID={dealUUID} />;
}
