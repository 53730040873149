import { BackgroundValue } from './constants'
import Round from './round.svg'
import RoundReverse from './round-reverse.svg'
import RoundTransparent from './round-transparent.svg'
import style from './Transition.module.scss'

interface TransitionProps {
  main?: BackgroundValue
  secondary?: BackgroundValue
  reverse?: boolean
  separationColor?: BackgroundValue
}

export default function Transition({
  main,
  secondary,
  reverse = false,
  separationColor,
}: TransitionProps): JSX.Element {
  const classes = []
  if (reverse) {
    classes.push(style.reverse)
  }
  if (main) {
    classes.push(style[`fill__${main}`])
  }
  if (secondary) {
    if (main) {
      classes.push(style[`background__${secondary}`])
    } else {
      classes.push(style[`fill__${secondary}`])
    }
  }

  if (separationColor) {
    classes.push(style[`stroke__${separationColor}`])
  }

  if (main === secondary)
    return (
      <div className={style.transition}>
        <RoundTransparent className={classes.join(' ')} />
      </div>
    )

  const Shape = main ? Round : RoundReverse
  return (
    <div className={style.transition}>
      <Shape className={classes.join(' ')} />
    </div>
  )
}
