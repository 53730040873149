import { useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { formatAddress } from 'components/GoogleAutocomplete/utils';
import { formatMaritalStatus } from 'components/Format/Defunct/FormattedMaritalStatus';
import { DealDefunctJSON } from 'models/Deal/Defunct';
import { Civility } from 'models/Person';
import { MaritalStatus } from 'models/Person/MaritalStatus';
import modelMessages from 'models/messages';

import { makeSelectDefunct } from '../../DealFuneral/DefunctSection/selectors.js';
import messages from './messages';
import style from './style.scss';

type ValueType = string | number | boolean | null;

interface LayerValueProps {
  values: ValueType[];
  type: string;
  onChangePosition?: (position: number) => void;
  styleNameClass: string;
  position: number | null;
}

export default function LayerValue({
  values,
  type,
  styleNameClass,
  onChangePosition,
  position,
}: LayerValueProps): JSX.Element {
  const intl = useIntl();
  const defunct = useSelector(makeSelectDefunct()) as DealDefunctJSON;

  const valueToStr = (value: ValueType): ValueType => {
    if (value === undefined || value === null || value === '') {
      // Do not return when false !
      return '';
    }
    switch (type) {
      case 'civility':
        return intl.formatMessage(modelMessages[value as Civility]);
      case 'phone':
      case 'phone_2':
        return formatPhoneNumberIntl(value as string);
      case 'birth_date':
        return moment
          .unix(value as number)
          .utc()
          .format('DD/MM/YYYY');
      case 'pacemaker':
      case 'dead': {
        const key = value === true ? 'true' : 'false';
        return intl.formatMessage(messages[key]);
      }
      case 'address':
        if (typeof value === 'string') {
          return value;
        }
        return formatAddress((value as unknown) as Record<string, string>);
      case 'marital_status':
        return formatMaritalStatus(
          {
            marital_status: value as MaritalStatus,
            type: defunct.person.type,
          },
          intl,
        );
      default:
        return value;
    }
  };

  if (values.length === 1) {
    return (
      <span className={style[styleNameClass]}> {valueToStr(values[0])} </span>
    );
  }

  return (
    <select
      className={style[styleNameClass]}
      onChange={(e): void =>
        onChangePosition && onChangePosition(parseInt(e.target.value, 10))
      }
      value={position || 0}
    >
      {values.map((val, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <option key={index} value={index}>
          {valueToStr(val)}
        </option>
      ))}
    </select>
  );
}
