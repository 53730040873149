import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormattedApiError } from 'components/Format/ApiError';

import TodoListContent from './Content';
import { makeSelectError } from './selectors';
import messages from './messages';
import { makeSelectDeal } from '../selectors.typed';

export default function TodoList(): JSX.Element | null {
  const deal = useSelector(makeSelectDeal());
  const error = useSelector(makeSelectError());

  if (!deal?.id) {
    return null;
  }

  return (
    <section id="todolist">
      <FormattedMessage id={messages.header.id} tagName="h2" />
      {error && (
        <p className="text--error">
          <FormattedApiError error={error} />
        </p>
      )}
      <TodoListContent />
    </section>
  );
}
