/*
 * Owner component to change deal owner
 */

import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { SelectList } from 'components/Input';
import { makeSelectActiveFuneralTeam } from 'slices/data';

import { makeSelectDeal } from '../selectors';
import messages from './messages';
import { changeDealOwner } from './actions';
import { makeSelectDealOwner } from './selectors';

export default function DealOwner() {
  const dispatch = useDispatch();

  const team = useSelector(makeSelectActiveFuneralTeam());
  const dealOwner = useSelector(makeSelectDealOwner());
  const { uuid } = useSelector(makeSelectDeal());
  const options = team.map(({ email }) => ({ value: email, name: email }));

  const setOwner = ({ target: { value } }) => {
    if (value === '') {
      return;
    }

    const owner = team.find(user => user.email === value);
    dispatch(changeDealOwner(owner, uuid));
  };

  return (
    <div>
      <FormattedMessage {...messages.dealOwner} />
      <SelectList
        name="dealOwner"
        options={options || []}
        value={dealOwner.email || ''}
        onChange={setOwner}
        emptyOption
      />
    </div>
  );
}
