import { Numbers } from '../Numbers'
import { times } from '../times'

import { pullAt } from './pullAt'

/**
 * Creates an array of shuffled values, using a version of the
 * [Fisher-Yates shuffle](https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle).
 */
export function shuffle<T>(collection: T[]): T[] {
  const values = collection.map(v => v)
  return times(values.length, () => pullAt(values, Numbers.randomInt(0, values.length - 1))[0])
}
