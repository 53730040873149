import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  AUTOCOMPLETE_ARS,
  AUTOCOMPLETE_POLICES,
  AUTOCOMPLETE_FUNERAL_PARLOR,
  AUTOCOMPLETE_SUPPLIER_WAREHOUSE,
  AUTOCOMPLETE_CONSULATE,
} from 'components/AutoCompleteByEntity/constants';
import AutoComplete from 'containers/AutoComplete';
import { SelectList } from 'components/Input';
import { ModelType } from 'models/Model';
import messagesEntity from 'messages/entity';

export class AutoCompleteByEntity extends PureComponent {
  /**
   * Function to get the related route of entity type
   *
   * @param {string} entityType entity type
   */
  getAutocompleteRoute = entityType => {
    switch (entityType) {
      case ModelType.RHA:
        return AUTOCOMPLETE_ARS;
      case ModelType.POLICE:
        return AUTOCOMPLETE_POLICES;
      case ModelType.SUPPLIER_WAREHOUSE:
        return AUTOCOMPLETE_SUPPLIER_WAREHOUSE;
      case ModelType.FUNERAL_PARLOR:
        return AUTOCOMPLETE_FUNERAL_PARLOR;
      case ModelType.CONSULATE:
        return AUTOCOMPLETE_CONSULATE;
      default:
        return `${entityType.toLowerCase()}s`;
    }
  };

  render() {
    const {
      intl,
      entityType,
      onChangeEntityType,
      addEntity,
      entities,
    } = this.props;

    const optionsEntities = entities.map(type => ({
      name: intl.formatMessage(messagesEntity[type.toLowerCase()]),
      value: type,
    }));

    return (
      <div>
        <SelectList
          name="entityType"
          value={entityType}
          options={optionsEntities}
          intl={intl}
          onChange={e => onChangeEntityType(e)}
          emptyOption
        />
        {entityType && (
          <AutoComplete
            url={`/api/v1/autocompletes/${this.getAutocompleteRoute(
              entityType,
            )}?q=`}
            getKey={item => item.id}
            getValue={item => item.description}
            onSelect={(_, item) => addEntity(item)}
            auth
          />
        )}
      </div>
    );
  }
}

AutoCompleteByEntity.propTypes = {
  /** translation */
  intl: PropTypes.object.isRequired,
  /** entity type */
  entityType: PropTypes.string,
  /** Function when changing entity */
  addEntity: PropTypes.func.isRequired,
  /** Function when changing entity type */
  onChangeEntityType: PropTypes.func.isRequired,
  /** list of entities to autocomplete on */
  entities: PropTypes.array.isRequired,
};

export default AutoCompleteByEntity;
