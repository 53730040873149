import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PrestationActions from 'components/PrestationActions';
import { findValidPrice } from 'components/Price/utils.ts';
import {
  PRESTATION_COMPUTE_AUTO,
  PRESTATION_STATUS_EDITED,
  PRESTATION_STATUS_DELETED,
  PRESTATION_SERVICE,
} from 'components/PrestationDialog/constants';

import messages from './messages';

function Service({
  service,
  removeService,
  onEditService,
  updateService,
  userRole,
  isManualMode,
}) {
  /**

   * Function to get a classname depending of the service status
   *
   * @param {string} status status of service
   */
  const getClassNameFromStatus = status => {
    switch (status) {
      case PRESTATION_STATUS_EDITED:
        return 'edited';
      case PRESTATION_STATUS_DELETED:
        return 'deleted';
      default:
        return null;
    }
  };

  const getClassFromCompute = compute =>
    compute === PRESTATION_COMPUTE_AUTO ? 'created-auto' : 'created-manual';

  const {
    description,
    estimation,
    manual_description: manualDescription,
    price,
    manual_price: manualPrice,
    not_negociated_prices: notNegociatedPrices,
  } = service.priceLine;

  const classNameFromEstimation = estimation ? 'estimation' : null;
  const classNameFromStatus = getClassNameFromStatus(service.status);
  const classNameFromService = getClassFromCompute(service.compute);
  const classNameFromNotNegociatedPrices = notNegociatedPrices
    ? 'not-negociated'
    : null;

  const serviceClass =
    classNameFromStatus ||
    classNameFromNotNegociatedPrices ||
    classNameFromEstimation ||
    classNameFromService;

  const validPrice = findValidPrice([manualPrice, price]);

  return (
    <div className={`service service--${serviceClass}`}>
      <div className="service__header">
        <div className="service__header__title">
          {manualDescription || description || service.type}
        </div>
      </div>
      <div className="flex margin--10-0-0-0">
        {service.type === 'tac' || service.type === 'tbc' ? (
          <FormattedMessage
            {...messages.price}
            values={{ price: validPrice }}
          />
        ) : (
          <FormattedMessage
            {...messages.price}
            values={{ price: validPrice * service.amount }}
          />
        )}
        <PrestationActions
          prestationType={PRESTATION_SERVICE}
          isManualMode={isManualMode}
          userRole={userRole}
          updatePrestation={updateService}
          onEditPrestation={e => {
            e.stopPropagation();
            onEditService(service);
          }}
          removePrestation={() => removeService(service)}
          prestation={service}
        />
      </div>
    </div>
  );
}

Service.propTypes = {
  /** service data */
  service: PropTypes.object.isRequired,
  /** function to remove a service */
  removeService: PropTypes.func.isRequired,
  /** function to remove a service */
  onEditService: PropTypes.func.isRequired,
  /** function to update services */
  updateService: PropTypes.func.isRequired,
  /** role of user */
  userRole: PropTypes.number.isRequired,
  /** is the manual mode on */
  isManualMode: PropTypes.bool.isRequired,
};

export default Service;
