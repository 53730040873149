import { PaymentState } from '@advitam/api/models/Payment/State';
import { PaymentMean } from '@advitam/api/models/Payment/Mean';

import { PaymentProblem } from 'models/Deal/PaymentProblem';
import modelMessages from 'models/messages';

import messages from './messages';

export const PAYMENTS_SPACE = 'Deal/Payments';

export const FUNERAL_ADVISOR_ALLOWED_STATES = [
  PaymentState.WAITING,
  PaymentState.GUARANTEE,
];

export const PAYMENT_PROBLEM_OPTIONS = [
  { value: PaymentProblem.NONE, message: messages.noRisk },
  { value: PaymentProblem.RISKY, message: modelMessages.risky },
  {
    value: PaymentProblem.OVERDUE,
    message: modelMessages.overdue,
  },
  {
    value: PaymentProblem.LITIGATION,
    message: modelMessages.litigation,
  },
];

export const COUNTED_PAYMENT_STATES = [
  PaymentState.ACCEPTED,
  PaymentState.WAITING,
];

export const PAYMENT_MEANS = [
  PaymentMean.CHECK,
  PaymentMean.TRANSFER,
  PaymentMean.DEFUNCT_ACCOUNT,
  PaymentMean.CASH,
  PaymentMean.FUNERAL_CONTRACT,
];

export const PAYMENT_STATUS = [
  PaymentState.WAITING,
  PaymentState.ACCEPTED,
  PaymentState.ERROR,
  PaymentState.CANCEL,
  PaymentState.GUARANTEE,
];

export const REFUNDABLE_PAYMENT_MEANS: string[] = [
  PaymentMean.CREDIT_CARD,
  PaymentMean.FOUR_TIMES,
  PaymentMean.SEPA_TRANSFER,
  PaymentMean.TEN_TIMES,
  PaymentMean.THREE_TIMES,
  PaymentMean.TWELVE_TIMES,
  PaymentMean.ALMA_TWO_TIMES,
  PaymentMean.ALMA_THREE_TIMES,
  PaymentMean.ALMA_FOUR_TIMES,
  PaymentMean.ALMA_FIVE_TIMES,
  PaymentMean.ALMA_SIX_TIMES,
  PaymentMean.ALMA_SEVEN_TIMES,
  PaymentMean.ALMA_EIGHT_TIMES,
  PaymentMean.ALMA_NINE_TIMES,
  PaymentMean.ALMA_TEN_TIMES,
  PaymentMean.ALMA_ELEVEN_TIMES,
  PaymentMean.ALMA_TWELVE_TIMES,
];
