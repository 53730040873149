import { ReactNode } from 'react'
import { Field, UseFieldConfig } from 'react-final-form'

import { NullableString } from '../converters'
import SelectUI, { SelectableItem } from '../UI/Select'
import { composeValidators, isRequired, Validator } from '../validators'

export interface SelectProps<Value> {
  name: string
  items: SelectableItem<Value>[]
  placeholder?: ReactNode
  multiple?: boolean
  filter?: boolean
  contained?: boolean
  unselectable?: boolean
  required?: boolean
  validate?: Validator<Value>
  label?: ReactNode
  disabled?: boolean
  tooltip?: ReactNode
  className?: string
  selectClassName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: UseFieldConfig<any>['format']
  renderElement?: (item: SelectableItem<Value>, selected: boolean) => JSX.Element
}

export default function Select<Value>({
  name,
  items,
  placeholder,
  multiple,
  filter,
  contained,
  unselectable,
  required,
  label,
  disabled,
  tooltip,
  className,
  selectClassName,
  parse = NullableString.parse,
  format,
  validate,
  renderElement,
}: SelectProps<Value>): JSX.Element {
  const validators = composeValidators<Value>(required && isRequired, validate)
  return (
    <Field name={name} validate={validators} parse={parse} format={format}>
      {({ input, meta }): JSX.Element => (
        <SelectUI
          name={input.name}
          label={label}
          items={items}
          onChange={input.onChange}
          placeholder={placeholder}
          multiple={multiple}
          filter={filter}
          contained={contained}
          unselectable={unselectable}
          value={input.value as Value}
          error={!!(meta.touched && meta.error)}
          disabled={disabled}
          tooltip={tooltip}
          className={className}
          selectClassName={selectClassName}
          renderElement={renderElement}
        />
      )}
    </Field>
  )
}
