import { WorshipJSON } from '@advitam/api/models/Worship';
import { formatEntity } from '../Entity';

export function formatName(worship: WorshipJSON): string {
  return formatEntity(worship);
}

export function FormattedName(worship: WorshipJSON): JSX.Element {
  return <>{formatName(worship)}</>;
}
