import { authenticate } from '../../../../lib/decorators'
import { del } from '../../../../lib/methods'
import { ApiRequestDescriptor } from '../../../../lib/request'
import { DealDocumentBatchJSON } from '../../../../models/Deal/Document/Batch'
import { RecipientType } from '../../../../models/RecipientType'

export class RemoveRecipient {
  @authenticate
  static destroy(
    dealUuid: string,
    batchId: number,
    entityId: number,
    entityType: RecipientType,
  ): ApiRequestDescriptor<DealDocumentBatchJSON> {
    return del(`/api/v1/deals/${dealUuid}/documents/batches/${batchId}/remove_recipient`, {
      entity_id: entityId,
      entity_type: entityType,
    })
  }
}
