import { authenticate, withSessionId } from '../../../../lib/decorators'
import { del, get, post, put } from '../../../../lib/methods'
import { ApiRequestDescriptor } from '../../../../lib/request'
import { SupplierWarehouseZoneJSON } from '../../../../models/Supplier/Warehouse'
import { Coverage } from './Coverage'
import { FuneralWorks } from './FuneralWorks'
import { WarehouseZoneBody } from './types'

export class Zones {
  static Coverage = Coverage

  static FuneralWorks = FuneralWorks

  @authenticate
  @withSessionId
  static create(
    warehouseId: number,
    zone: Omit<WarehouseZoneBody, 'id'>,
  ): ApiRequestDescriptor<SupplierWarehouseZoneJSON> {
    return post(`/api/v1/suppliers/warehouses/${warehouseId}/zones`, zone)
  }

  @authenticate
  static index(warehouseId: number): ApiRequestDescriptor<SupplierWarehouseZoneJSON[]> {
    return get(`/api/v1/suppliers/warehouses/${warehouseId}/zones`)
  }

  @authenticate
  @withSessionId
  static update(zone: WarehouseZoneBody): ApiRequestDescriptor<SupplierWarehouseZoneJSON> {
    return put(`/api/v1/suppliers/warehouses/zones/${zone.id}`, zone)
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/suppliers/warehouses/zones/${id}`)
  }
}
