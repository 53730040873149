import { cloneElement, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Tippy from '@tippyjs/react';

import { TOOLTIP_DELAY } from './constants';
import messages from './messages';

interface CopyToClipboardProps {
  textToCopy: string;
  children: ReactElement;
}

export default function CopyToClipboard({
  textToCopy,
  children,
}: CopyToClipboardProps): JSX.Element {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  function onClick(): void {
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.setAttribute('value', textToCopy);
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    setIsTooltipVisible(true);
    setTimeout(() => setIsTooltipVisible(false), TOOLTIP_DELAY);
  }

  return (
    <Tippy
      content={<FormattedMessage id={messages.copied.id} />}
      visible={isTooltipVisible}
    >
      {cloneElement(children, { onClick })}
    </Tippy>
  );
}

CopyToClipboard.propTypes = {
  children: PropTypes.element.isRequired,
  textToCopy: PropTypes.string.isRequired,
};
