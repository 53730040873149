import { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import groupMessages from 'messages/families';
import { safeTranslation } from 'utils/functions';

export default function Prestations({ prestations, groupName }) {
  const intl = useIntl();
  const [showPrestation, setShowPrestation] = useState(false);
  const renderPrestation = () =>
    prestations.map((description, i) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`${description}-${i}`}
        className="booking__prestation__selection__prestations--prestation"
      >
        -{` ${description}`}
      </div>
    ));

  return (
    <div className="booking__prestation__selection__prestations">
      <div className="booking__prestation__selection__prestations--groupName">
        {safeTranslation(groupMessages, groupName, intl)}
        <button
          className="booking__prestation__selection__prestations--toggle"
          type="button"
          onClick={() => setShowPrestation(!showPrestation)}
        >
          {showPrestation ? '↑' : '↓'}
        </button>
      </div>
      {showPrestation && renderPrestation()}
    </div>
  );
}

Prestations.propTypes = {
  prestations: PropTypes.array.isRequired,
  groupName: PropTypes.string.isRequired,
};
