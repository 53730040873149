import { ReactNode } from 'react'
import Container from '../Container'
import AboutColumn from './parts/AboutColumn'
import Bottom from './parts/Bottom'
import GroupsColumns from './parts/GroupsColumns'
import LogoColumn from './parts/LogoColumn'
import { Bottom as BottomProps, Lang, LinksGroup, SocialLink } from './types'
import style from './Footer.module.scss'

interface FooterPropsBase {
  logo: ReactNode
  description: ReactNode
  socialLinks: SocialLink[]
  linksGroups: LinksGroup[]
  bottom: BottomProps
  small?: boolean
  lang?: Lang
}

interface FooterPropsWithGoogleReviews extends FooterPropsBase {
  googleRate: number
  reviewsLink: string
  reviewsLinkInternal?: boolean
}

interface FooterPropsWithoutGoogleReviews extends FooterPropsBase {
  googleRate?: undefined
  reviewsLink?: undefined
  reviewsLinkInternal?: undefined
}

type FooterProps = FooterPropsWithGoogleReviews | FooterPropsWithoutGoogleReviews

export default function Footer({
  logo,
  googleRate,
  reviewsLink,
  reviewsLinkInternal,
  description,
  socialLinks,
  linksGroups,
  bottom,
  small,
  lang,
}: FooterProps): JSX.Element {
  return (
    <footer className={style.footer}>
      <Container>
        {small || (
          <div className={style.row}>
            <LogoColumn
              logo={logo}
              googleRate={googleRate as number}
              reviewsLink={reviewsLink as string}
              reviewsLinkInternal={reviewsLinkInternal}
            />
            <GroupsColumns groups={linksGroups} />
            <AboutColumn description={description} socialLinks={socialLinks} lang={lang} />
          </div>
        )}
        <Bottom copyright={bottom.copyright} links={bottom.links} />
      </Container>
    </footer>
  )
}
