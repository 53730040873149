import { ReactNode } from 'react';

import { Service as ServiceModel } from 'models/Deal/Service';
import { TransparentButton } from 'components/Button';

import { UPDATABLE_TYPES } from './constants';

interface ServiceWrapperProps {
  service: ServiceModel;
  children: ReactNode | ReactNode[];
  onOpenUpdateServiceModal: () => void;
}

export default function ServiceWrapper({
  service,
  children,
  onOpenUpdateServiceModal,
}: ServiceWrapperProps): JSX.Element {
  if (UPDATABLE_TYPES.includes(service.type)) {
    return (
      <TransparentButton
        tabIndex={0}
        role="button"
        className="pointer"
        onClick={onOpenUpdateServiceModal}
      >
        {children}
      </TransparentButton>
    );
  }

  return <>{children}</>;
}
