import Cross from '../../images/icons/times.svg'
import { FlatButton } from '../Button'
import style from './Modal.module.scss'

interface CloseButtonProps {
  onClick: () => void
  className?: string
}

export default function CloseButton({ onClick, className = '' }: CloseButtonProps): JSX.Element {
  return (
    <FlatButton onClick={onClick} className={`${style.close_button} ${className}`}>
      <Cross />
    </FlatButton>
  )
}
