import type { ReactElement, ReactNode } from 'react'

import Tooltip from './Tooltip'
import style from '../UI.module.scss'
import ErrorMessage from './ErrorMessage'

interface SemanticLabelProps {
  value?: ReactNode
  tooltip?: ReactNode
  error?: ReactElement | boolean
  className?: string
  children: ReactNode
}

interface VisualLabelProps {
  value?: ReactNode
  tooltip?: ReactNode
  error?: undefined
  className?: string
  children?: undefined
}

export default function Label({
  value,
  tooltip,
  error,
  className = '',
  children,
}: SemanticLabelProps | VisualLabelProps): JSX.Element | null {
  const showErrorMessage = Boolean(error) && error !== true

  if (children) {
    return (
      <label className={className}>
        {value && (
          <span className={style.label}>
            <span className={style.label_text}>{value}</span>
            {tooltip && <Tooltip content={tooltip} placement="bottom" />}
          </span>
        )}
        {children}
        {showErrorMessage && <ErrorMessage error={error} />}
      </label>
    )
  }

  if (!value) {
    return null
  }

  return (
    <span className={`${style.label} ${className}`}>
      <span className={style.label_text}>{value}</span>
      {tooltip && <Tooltip content={tooltip} placement="bottom" />}
    </span>
  )
}
