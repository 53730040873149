import { authenticate } from '../lib/decorators'
import { get } from '../lib/methods'
import { ApiRequestDescriptor } from '../lib/request'
import { ConcessionSemelleTypeJSON } from '../models/Concession'

export class ConcessionSemelleTypes {
  @authenticate
  static index(): ApiRequestDescriptor<ConcessionSemelleTypeJSON[]> {
    return get('/api/v1/concession_semelle_types')
  }
}
