import type { FundingAgencyJSON } from '@advitam/api/models/FundingAgency';
import { PaymentReceived } from '@advitam/api/models/Payment/Received';
import { PaymentState } from '@advitam/api/models/Payment/State';
import { Model } from 'models/Model';

import { PaymentMean } from './Mean';

interface PaymentSummaryBase {
  id: number;
  amount: number;
  deal_id: number;
  state: PaymentState;
  mean: PaymentMean;
  received: PaymentReceived | null;
  funding_agency: FundingAgencyJSON;
}

export interface PaymentSummaryJSON extends PaymentSummaryBase {
  paid_at: string | null;
}

export interface PaymentSummary extends PaymentSummaryBase {
  paid_at: Date | null;
}

export class PaymentSummary extends Model<PaymentSummaryJSON> {
  constructor(data: PaymentSummaryJSON) {
    super(data);
    this.paid_at = Model.parseDate(data.paid_at);
  }

  get isAccepted(): boolean {
    return this.state === PaymentState.ACCEPTED && this.amount >= 0;
  }

  get isReimbursed(): boolean {
    return this.state === PaymentState.ACCEPTED && this.amount < 0;
  }
}
