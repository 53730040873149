import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';

import { assert } from 'lib/support';
import { DOCUMENTS_EDITOR } from './constants';
import { makeSelectIsSingleDocumentUpload } from './selectors';
import { saveDocuments, uploadDocument, uploadDocuments } from './thunk';
import {
  DocumentConflict,
  DocumentEdition,
  NewUploadResource,
  UploadError,
} from './types';

export interface State {
  uploadedDocuments: DocumentEdition[];
  uploadErrors: UploadError[];
  documentsConflicts: DocumentConflict[];
  currentResource: NewUploadResource | null;
  currentSessionId: string | null;
  processedUploadCount: number;
  totalFilesCount: number;
  isLoading: boolean;
  isSaving: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DOCUMENTS_EDITOR]: State;
}

export const initialState: State = {
  uploadedDocuments: [],
  uploadErrors: [],
  documentsConflicts: [],
  currentResource: null,
  currentSessionId: null,
  totalFilesCount: 0,
  processedUploadCount: 0,
  isLoading: false,
  isSaving: false,
  error: null,
};

const slice = createSlice({
  name: DOCUMENTS_EDITOR,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    reset: (): State => initialState,
    clearUploadErrors: state => {
      state.uploadErrors = initialState.uploadErrors;
    },
    setCurrentResource: (
      state,
      { payload }: PayloadAction<NewUploadResource>,
    ) => {
      state.currentResource = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      uploadDocuments.pending,
      (state, { meta: { arg, requestId } }) => {
        state.isLoading = true;
        state.currentSessionId = requestId;
        state.totalFilesCount = arg.length;
      },
    );
    builder.addCase(uploadDocuments.fulfilled, state => {
      state.isLoading = false;
      state.totalFilesCount = 0;
      state.processedUploadCount = 0;
    });

    builder.addCase(
      uploadDocument.fulfilled,
      (state, { meta: { arg: file }, payload }) => {
        assert(state.currentResource !== null);

        const { document, currentSessionId } = payload;
        const globalState = { [DOCUMENTS_EDITOR]: state };
        const isSingleDocumentUpload = makeSelectIsSingleDocumentUpload()(
          globalState,
        );

        state.processedUploadCount += 1;

        if (document.error) {
          state.uploadErrors.push({
            fileName: file.name,
            error: document.error,
          });
          return;
        }

        if (!isSingleDocumentUpload || state.uploadedDocuments.length === 0) {
          state.uploadedDocuments.push({
            ...document,
            resource: state.currentResource,
            sessionId: currentSessionId,
          });
          return;
        }

        state.uploadedDocuments[0].sessionId = currentSessionId;
        state.uploadedDocuments[0].pages.push(...document.pages);
      },
    );
    builder.addCase(
      uploadDocument.rejected,
      (state, { meta: { arg: file }, payload }) => {
        state.processedUploadCount += 1;

        const error = ApiError.serialize(payload);
        if (error) {
          state.uploadErrors.push({
            fileName: file.name,
            error,
          });
        }
      },
    );

    builder.addCase(saveDocuments.pending, state => {
      state.isSaving = true;
      state.documentsConflicts = [];
    });
    builder.addCase(saveDocuments.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.documentsConflicts = payload || [];

      if (!payload) {
        state.currentResource = initialState.currentResource;
        state.uploadedDocuments = initialState.uploadedDocuments;
        state.uploadErrors = initialState.uploadErrors;
      }
    });
    builder.addCase(saveDocuments.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
      state.documentsConflicts = [];
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clearUploadErrors, reset, setCurrentResource } = slice.actions;
export default slice;
