import {
  QUERY_UPDATE_DEAL_OWNER,
  PUT_DEAL_BRAND,
} from 'containers/Deal/constants.ts';
import { setDealFields } from 'containers/Deal/slice.ts';

export function setDealOwner(userId) {
  return setDealFields({
    isTouched: false,
    update: {
      user_id: userId,
    },
  });
}

export function changeDealOwner(newOwner, uuid) {
  return {
    type: QUERY_UPDATE_DEAL_OWNER,
    newOwner,
    uuid,
  };
}

/**
 * Function to update the brand of the deal
 *
 * @param {string} dealUUID uuid of deal
 * @param {object} brand new brand
 * @returns {object}
 */
export function putDealBrand(dealUUID, brand) {
  return {
    type: PUT_DEAL_BRAND,
    dealUUID,
    brand,
  };
}
