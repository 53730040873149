import { IntlShape, useIntl } from 'react-intl';

import { DefunctPersonFields } from 'models/Defunct';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from './messages';

type PartialDefunct = Pick<DefunctPersonFields, 'type' | 'marital_status'>;

export function formatMaritalStatus(
  defunct: PartialDefunct,
  intl: IntlShape,
): string {
  if (!defunct.marital_status) {
    return '';
  }

  return safeFormatMessage(intl, messages, defunct.marital_status, {
    civility: defunct.type,
  });
}

interface FormattedMaritalStatusProps {
  defunct: PartialDefunct;
}

export function FormattedMaritalStatus({
  defunct,
}: FormattedMaritalStatusProps): JSX.Element {
  const intl = useIntl();
  return <>{formatMaritalStatus(defunct, intl)}</>;
}
