import { updateDeal } from 'containers/Deal/slice.ts';

export function updateDefunct(update, defunctInfo) {
  return updateDeal({
    defunctInfo: {
      ...defunctInfo,
      person: update,
    },
  });
}

export function updateDefunctCompInfo(update, defunctInfo) {
  return updateDeal({
    defunctInfo: {
      ...defunctInfo,
      defunctCompInfo: update,
    },
  });
}
