// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button-module__button_v1--v70f6{float:right;margin:3px;padding:0;overflow:hidden;border-width:0;outline:none;cursor:pointer;border-radius:2px;box-shadow:0 1px 4px rgba(0,0,0,.6);background-color:#2ecc71;color:#fff;transition:filter .1s}.Button-module__button_v1--v70f6:hover{filter:brightness(90%)}.Button-module__button_v1--v70f6>*{display:block;padding:8px 16px}.Button-module__button_v1__small--eZ1VE>*{padding:3px 5px}.Button-module__unfloat--kttJX{float:none}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.scss"],"names":[],"mappings":"AAAA,iCACE,WAAA,CACA,UAAA,CACA,SAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA,CACA,cAAA,CACA,iBAAA,CACA,mCAAA,CACA,wBAAA,CACA,UAAA,CACA,qBAAA,CAEA,uCACE,sBAAA,CAGF,mCACE,aAAA,CACA,gBAAA,CAKF,0CACE,eAAA,CAIJ,+BAEE,UAAA","sourcesContent":[".button_v1 {\n  float: right;\n  margin: 3px;\n  padding: 0;\n  overflow: hidden;\n  border-width: 0;\n  outline: none;\n  cursor: pointer;\n  border-radius: 2px;\n  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);\n  background-color: #2ecc71;\n  color: white;\n  transition: filter 0.1s;\n\n  &:hover {\n    filter: brightness(90%);\n  }\n\n  > * {\n    display: block;\n    padding: 8px 16px;\n  }\n}\n\n.button_v1__small {\n  > * {\n    padding: 3px 5px;\n  }\n}\n\n.unfloat {\n  // Used by Button v1 for backward compat, but this does not belong here.\n  float: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_v1": `Button-module__button_v1--v70f6`,
	"button_v1__small": `Button-module__button_v1__small--eZ1VE`,
	"unfloat": `Button-module__unfloat--kttJX`
};
export default ___CSS_LOADER_EXPORT___;
