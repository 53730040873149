import type { ReactNode } from 'react'
import { Field } from 'react-final-form'
import type { MessageDescriptor } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { NullableString } from '../converters'
import { ErrorCode, getFormError } from '../errors'
import PhoneUI from '../UI/Phone'
import { composeValidators, isRequired, Validator, ValidatorReturnType } from '../validators'

function validatePhone(value?: string | null): ValidatorReturnType {
  if (value && !isValidPhoneNumber(value)) {
    return ErrorCode.PHONE_INVALID
  }

  return undefined
}

interface PhoneProps<FormValues> {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  required?: boolean
  validate?: Validator<string | null | undefined, FormValues>
  className?: string
  tooltip?: ReactNode
  withCallButton?: boolean
  errorMessages?: Record<string, MessageDescriptor>
  hideErrorMessage?: boolean
}

export default function Phone<FormValues = unknown>({
  name,
  label,
  placeholder,
  disabled,
  required,
  validate,
  className,
  tooltip,
  withCallButton,
  errorMessages,
  hideErrorMessage,
}: PhoneProps<FormValues>): JSX.Element {
  const validators = composeValidators<string | null, FormValues>(
    validatePhone,
    required && isRequired,
    validate,
  )

  return (
    <Field name={name} validate={validators} parse={NullableString.parse}>
      {({ input, meta }): JSX.Element => (
        <PhoneUI
          label={label}
          name={input.name}
          onChange={input.onChange}
          onBlur={input.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          value={input.value || ''}
          error={getFormError(meta, errorMessages, hideErrorMessage)}
          className={className}
          tooltip={tooltip}
          withCallButton={withCallButton}
        />
      )}
    </Field>
  )
}
