import { PaymentReceived } from '@advitam/api/models/Payment/Received';
import { DealState } from 'models/Deal/State';
import { PaymentProblem } from 'models/Deal/PaymentProblem';

export const PAYMENTS = 'Payments';

export const DEALS_PER_PAGE = 50;

export const DEFAULT_PAID_EQ = false;
export const DEFAULT_STATE_FILTERS = [DealState.IN_PROGRESS, DealState.DONE];
export const DEFAULT_PROBLEM_FILTERS = [
  PaymentProblem.NONE,
  PaymentProblem.RISKY,
  PaymentProblem.LITIGATION,
];
export const DEFAULT_RECEIVED_FILTER = PaymentReceived.YES;
