import { SupplierWarehouseJSON } from '@advitam/api/models/Supplier/Warehouse';
import type { Address } from '@advitam/ui/components/Form/GooglePlace/types';
import { NewSupplierWarehouse } from '../../types';

export function serializeWarehouseAddress(
  warehouse: NewSupplierWarehouse | SupplierWarehouseJSON,
): Address {
  return {
    address: warehouse.address,
    city: warehouse.city,
    country: warehouse.country?.code || null,
    latitude: warehouse.latitude,
    longitude: warehouse.longitude,
    postal_code: warehouse.postal_code,
  };
}
