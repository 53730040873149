import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { StayJSON } from '@advitam/api/models/FuneralParlor';
import { withSlice } from '@advitam/react';
import { Layout } from 'containers/Crud';
import { assert } from 'lib/Assert';

import { makeSelectRawFuneralParlor } from '../../selectors';
import type { FuneralParlorForm } from '../types';
import Wrapper from '../Wrapper';
import slice from './slice';
import { makeSelectStays } from './selectors';
import { saveStays } from './thunk';
import Contents from './parts/Contents';

function Stays(): JSX.Element {
  const dispatch = useDispatch();

  const crematorium = useSelector(makeSelectRawFuneralParlor());
  assert(crematorium?.id !== undefined);
  const stays = useSelector(makeSelectStays());

  const onSubmit = useCallback(
    ({ sectionValues }: FuneralParlorForm<StayJSON[]>) => {
      dispatch(saveStays(sectionValues));
    },
    [],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={stays}>
      <Layout.Container>
        <Contents />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(slice)(Stays);
