import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-final-form';

import type { FlightJSON } from '@advitam/api/models/Flight';
import {
  CloseButton,
  ContentModal,
  ModalTitle,
  SubmitButton,
} from '@advitam/ui';
import { withSlice } from '@advitam/react';

import Content from './Content';
import { makeSelectIsOpen } from './selectors';
import slice, { setIsOpen } from './slice';
import { createFlight } from './thunk';
import messages from './messages';
import style from './CreateModal.module.scss';
import { FLIGHT_SKELETON } from './constants';

function CreateModal(): JSX.Element {
  const dispatch = useDispatch();
  const isOpen = useSelector(makeSelectIsOpen());

  const onClose = useCallback((): void => {
    dispatch(setIsOpen(false));
  }, [dispatch, setIsOpen]);

  const onSubmit = useCallback(
    (values: FlightJSON): void => {
      dispatch(createFlight(values));
    },
    [dispatch],
  );

  return (
    <ContentModal
      overlayClassName={style.overlay}
      className={style.modal}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Form<FlightJSON> onSubmit={onSubmit} initialValues={FLIGHT_SKELETON}>
        {({ handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <CloseButton onClick={onClose} />
            <ModalTitle>
              <FormattedMessage id={messages.title.id} />
            </ModalTitle>
            <Content />
            <SubmitButton
              primary
              text={<FormattedMessage id={messages.submit.id} />}
              className={style.submit}
            />
          </form>
        )}
      </Form>
    </ContentModal>
  );
}

export default withSlice(slice)(CreateModal);
