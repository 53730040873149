import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import CloseCross from 'components/CloseCross';
import { ButtonV2 as Button } from 'components/Button';
import messagesAction from 'messages/actions';
import messagesCrud from 'messages/crud';
import injectReducer from 'utils/injectReducer';

import reducer from './reducer';
import * as selectors from './selectors';
import { toggleIsOpen } from './actions';

class ConfirmModal extends PureComponent {
  /**
   * Call delete function and close the modal
   */
  onConfirm = () => {
    const { deleteFunction, toggleModal } = this.props;
    deleteFunction();
    toggleModal();
  };

  render() {
    const { isOpen, toggleModal } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel="dialog"
        onRequestClose={toggleModal}
        ariaHideApp={false}
        className="warning modal"
        overlayClassName="warning__overlay modal__overlay"
      >
        <CloseCross handleClose={toggleModal} />
        <div className="warning__container">
          <FormattedMessage {...messagesCrud.askConfirm} />
          <div className="confirmModal__container__buttons">
            <Button onClick={toggleModal} className="button--red">
              <FormattedMessage {...messagesAction.cancel} />
            </Button>
            <Button onClick={this.onConfirm}>
              <FormattedMessage {...messagesAction.confirm} />
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  /** State of modal */
  isOpen: PropTypes.bool.isRequired,
  /** The function to call when user click on confirm */
  deleteFunction: PropTypes.func.isRequired,
  /** Function to update the state of modal */
  toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isOpen: selectors.makeSelectIsOpen(),
  deleteFunction: selectors.makeSelectDeleteFunction(),
  message: selectors.makeSelectMessage(),
});

const mapDispatchToProps = dispatch => ({
  toggleModal: () => dispatch(toggleIsOpen()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = [injectReducer({ key: 'confirmModal', reducer })];

export default compose(...withReducer, withConnect)(ConfirmModal);
