import { useCallback, useRef, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { Layout } from 'containers/Crud';
import actionsMessages from 'messages/actions';

import { ConfirmationModal } from '@advitam/ui';
import { GraveyardConcessionsForm } from './types';
import messages from './messages';
import Concession from './Concession';
import Filters from './Filters';

export default function ConcessionsContainer(): JSX.Element {
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);
  const gridContainer = useRef<HTMLDivElement>(null);

  const form = useForm<GraveyardConcessionsForm>();
  const { values } = useFormState<GraveyardConcessionsForm>();
  const { sectionValues: concessions } = values;

  const closeDeleteConfirmation = useCallback(() => {
    setIndexToDelete(null);
  }, [setIndexToDelete]);

  const onDeleteConcession = useCallback(() => {
    const newConcessions = concessions.filter(
      (_, index) => indexToDelete !== index,
    );
    form.change('sectionValues', newConcessions);
    closeDeleteConfirmation();
  }, [form, closeDeleteConfirmation, concessions, indexToDelete]);

  return (
    <>
      <Layout.Container>
        <Layout.ColumnGroup>
          <Filters gridContainer={gridContainer} />
        </Layout.ColumnGroup>
        <Field name="sectionValues">
          {(): JSX.Element => (
            // Declare the field so final-form picks up array changes
            <Layout.FieldsetGrid ref={gridContainer}>
              {concessions.map((concession, idx) => (
                <Concession
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${concession.id || 0}-${idx}`}
                  idx={idx}
                  deleteConcession={(): void => setIndexToDelete(idx)}
                />
              ))}
            </Layout.FieldsetGrid>
          )}
        </Field>
      </Layout.Container>

      {indexToDelete !== null && (
        <ConfirmationModal
          isOpen
          cancel={<FormattedMessage id={actionsMessages.cancel.id} />}
          confirm={<FormattedMessage id={actionsMessages.confirm.id} />}
          onCancel={closeDeleteConfirmation}
          onConfirm={onDeleteConcession}
          text={
            <FormattedMessage
              id={messages.deleteConfirmation.id}
              values={{ name: concessions[indexToDelete]?.name }}
            />
          }
        />
      )}
    </>
  );
}
