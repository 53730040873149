import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';

import { AppStateSubset as ConcessionInfosAppStateSubset } from '../../concessionInfosSlice/slice';
import {
  makeSelectConcessionTerms,
  makeSelectConcessionTypes,
} from '../../concessionInfosSlice/selectors';
import { AppStateSubset as GraveyardAppStateSubset } from '../../slice';
import { makeSelectGraveyard } from '../../selectors';
import { GRAVEYARD_MISC } from './constants';
import { AppStateSubset as MiscAppStateSubset, State } from './slice';
import { FormSectionValues } from './types';
import { getPermissionAsEnum } from './utils';

type AppStateSubset = GraveyardAppStateSubset &
  ConcessionInfosAppStateSubset &
  MiscAppStateSubset;
type GraveyardMiscSelectors<T> = Selector<AppStateSubset, T>;

const selectMiscDomain = (state: AppStateSubset): State =>
  state[GRAVEYARD_MISC];

export const makeSelectInitialSectionValues = (): GraveyardMiscSelectors<
  FormSectionValues
> =>
  createSelector(
    makeSelectGraveyard(),
    makeSelectConcessionTerms(),
    makeSelectConcessionTypes(),
    (graveyard, concessionTerms, concessionTypes) => ({
      concessionTerms,
      concessionTypes,
      fausse_case: getPermissionAsEnum(
        graveyard ? graveyard.fausse_case : null,
      ),
      semelle: getPermissionAsEnum(graveyard ? graveyard.semelle : null),
    }),
  );

export const makeSelectIsSaving = (): GraveyardMiscSelectors<boolean> =>
  createSelector(selectMiscDomain, ({ isSaving }) => isSaving);

export const makeSelecthasAlreadyInUseTermError = (): GraveyardMiscSelectors<
  boolean
> =>
  createSelector(
    selectMiscDomain,
    ({ hasAlreadyInUseTermError }) => hasAlreadyInUseTermError,
  );

export const makeSelecthasAlreadyInUseTypeError = (): GraveyardMiscSelectors<
  boolean
> =>
  createSelector(
    selectMiscDomain,
    ({ hasAlreadyInUseTypeError }) => hasAlreadyInUseTypeError,
  );

export const makeSelectError = (): GraveyardMiscSelectors<SerializedApiError | null> =>
  createSelector(selectMiscDomain, ({ error }) => error);
