import { Objects } from '@advitam/support'
import { CityhallJSON } from '../../models/Cityhall'
import { CityhallBody } from './types'

type CityhallPayload = Partial<
  Omit<CityhallJSON, 'country' | 'headquarter_country'> & {
    country_code: string | null
    headquarter_country_code: string | null
  }
>

export function serialize(cityhall: CityhallBody): CityhallPayload {
  return {
    ...Objects.omit(cityhall, 'country', 'headquarter_country'),
    country_code: cityhall.country === undefined ? undefined : cityhall.country?.code || null,
    headquarter_country_code:
      cityhall.headquarter_country === undefined
        ? undefined
        : cityhall.headquarter_country?.code || null,
  }
}
