import { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ErrorText,
  FormattedApiError,
  Spinner,
  SubmitButton,
} from '@advitam/ui';

import messages from '../messages';
import { close } from '../slice';
import { makeSelectError, makeSelectIsLoading } from '../selectors';
import style from './Buttons.module.scss';

interface ButtonsProps {
  submitText: ReactNode;
}

export default function Buttons({ submitText }: ButtonsProps): JSX.Element {
  const dispatch = useDispatch();

  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const onCancel = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  return (
    <>
      {error && (
        <ErrorText center>
          <FormattedApiError error={error} />
        </ErrorText>
      )}
      <div className={style.buttons}>
        <Button
          outline
          text={<FormattedMessage id={messages.cancel.id} />}
          onClick={onCancel}
        />
        <SubmitButton
          primary
          fixedSize
          text={submitText}
          icon={isLoading ? <Spinner /> : undefined}
        />
      </div>
    </>
  );
}
