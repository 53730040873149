import { defineMessages } from 'react-intl';

export default defineMessages({
  memorialSection: {
    id: 'app.containers.DealFuneral.MemorialSection.memorialSection',
  },
  accessMemorial: {
    id: 'app.containers.DealFuneral.MemorialSection.accessMemorial',
  },
  notAvailable: {
    id: 'app.containers.DealFuneral.MemorialSection.notAvailable',
  },
});
