import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import PriceLine from 'models/Deal/PriceLine';
import Input, { SelectList } from 'components/Input/index.js';
import messagesInput from 'components/Input/messages.js';
import {
  listPriceCategories,
  listTva,
  listCategory,
} from 'components/PrestationDialog/constants.js';
import {
  priceFromString,
  priceToString,
  findValidPrice,
} from 'components/Price/utils';
import messagesPrestation from 'components/PrestationDialog/messages.js';
import { withSlice } from 'utils/injectReducer.js';

import EntityInput from './EntityInput';
import messages from './messages';
import slice from './slice';
import { fetchEntity } from './thunk';

interface PrestationFormProps {
  priceLine: PriceLine;
  onChangePrestation: (key: string, value: unknown) => void;
  onChangePrestationPriceLine: (key: string, value: unknown) => void;
  isAmountNegative: boolean;
  amount?: number;
  isValueMissing?: boolean;
}

function PrestationForm({
  priceLine,
  isValueMissing,
  onChangePrestation,
  onChangePrestationPriceLine,
  amount,
  isAmountNegative,
}: PrestationFormProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [manualAmount, setManualAmount] = useState(priceToString(amount));
  const [manualCost, setManualCost] = useState(
    priceToString(priceLine.manual_cost),
  );
  const [manualPrice, setManualPrice] = useState(
    priceToString(priceLine.manual_price),
  );
  const isFamilyCategory =
    priceLine.manual_price_category === 'family' ||
    (priceLine.manual_price_category === null &&
      priceLine.price_category === 'family');

  const onChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>): void => {
    onChangePrestationPriceLine(name, value);
    if (name === 'manual_price_category' && value === 'family') {
      setManualCost('');
      onChangePrestationPriceLine('manual_cost', null);
    }
  };

  const onAmountChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setManualAmount(value);
    onChangePrestation(name, priceFromString(value));
  };

  const onCostChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>): void => {
    if (!isFamilyCategory) {
      setManualCost(value);
      onChangePrestationPriceLine(name, priceFromString(value));
    } else {
      setManualCost('');
      onChangePrestationPriceLine('manual_cost', null);
    }
  };

  const onPriceChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setManualPrice(value);
    onChangePrestationPriceLine(name, priceFromString(value));
  };

  useEffect(() => {
    dispatch(fetchEntity(priceLine));
  }, []);

  return (
    <div className="addPrestationsDialog__addPrestation">
      <div className="row">
        <div className="col-12">
          <Input
            name="manual_description"
            value={priceLine.manual_description || ''}
            placeholder={
              priceLine.description ||
              intl.formatMessage(messagesPrestation.addPrestation)
            }
            onChange={onChange}
            className="fullWidth"
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <SelectList
            name="manual_price_category"
            value={priceLine.manual_price_category || priceLine.price_category}
            options={listPriceCategories(intl)}
            intl={intl}
            onChange={onChange}
            emptyOption
          />
        </div>
        <div className="addPrestationsDialog__addPrestation__selection">
          <EntityInput
            priceLine={priceLine}
            onChangePrestationPriceLine={onChangePrestationPriceLine}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <SelectList
            name="manual_tva_rate"
            value={findValidPrice([
              priceLine.manual_tva_rate,
              priceLine.tva_rate,
            ])}
            options={listTva(intl)}
            intl={intl}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              onChangePrestationPriceLine(
                'manual_tva_rate',
                priceFromString(e.target.value),
              )
            }
            emptyOption
          />
        </div>
        <div className="col-4">
          <Input
            name="manual_cost"
            type="number"
            step="0.01"
            value={isFamilyCategory ? '' : manualCost}
            placeholder={
              priceToString(priceLine.cost) ||
              intl.formatMessage(messages.costWithoutVAT)
            }
            className="fullWidth"
            onChange={onCostChange}
            disabled={isFamilyCategory}
            required={!isFamilyCategory}
          />
        </div>
        <div className="col-4">
          <Input
            name="manual_price"
            type="number"
            step="0.01"
            value={manualPrice}
            placeholder={
              priceToString(priceLine.price) ||
              intl.formatMessage(messages.priceWithVAT)
            }
            className="fullWidth"
            onChange={onPriceChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <SelectList
            name="manual_category_id"
            value={
              (priceLine.manual_category && priceLine.manual_category.id) ||
              (priceLine.category && priceLine.category.id)
            }
            options={listCategory(intl)}
            intl={intl}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              onChangePrestationPriceLine('manual_category', {
                id: parseInt(e.target.value, 10) || '',
              })
            }
            emptyOption
            className="fullWidth"
          />
        </div>
        <div className="col-4">
          <Input
            name="amount"
            type="number"
            step="1"
            min="1"
            value={manualAmount}
            placeholder={intl.formatMessage(messages.amount)}
            className="fullWidth"
            onChange={onAmountChange}
          />
        </div>
      </div>
      {isValueMissing && (
        <div className="row">
          <div className="col-12 text--red">
            <FormattedMessage id={messagesInput.missingInformation.id} />
          </div>
        </div>
      )}
      {isAmountNegative && (
        <div className="row">
          <div className="col-12 text--red">
            <FormattedMessage id={messages.amountShouldBePositive.id} />
          </div>
        </div>
      )}
    </div>
  );
}

export default withSlice(slice)(PrestationForm);
