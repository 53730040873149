import { authenticate, withSessionId } from '../../lib/decorators'
import { put } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'

interface UserUpdateBody {
  uuid: string
  user_email: string
}

export class User {
  @withSessionId
  @authenticate
  static update(dealUuid: string, user_email: string): ApiRequestDescriptor<UserUpdateBody> {
    return put(`/api/v1/deals/${dealUuid}/user`, { user_email })
  }
}
