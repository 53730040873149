import { updateDeal as updateDealSlice } from '../slice.ts';
import {
  FETCH_FUNERAL_DETAILS,
  SET_DEAL,
  SUCCESS_RESPONSE,
  ERROR,
} from './constants';

/**
 * Action called when a saga succeeded to create or update a deal. Stores the
 * received deal
 *
 * @param      {Object}  deal    The deal received from the API
 * @return     {Object}  An action object asking the reducer to put the new deal
 *                       in the store
 */
export function setDeal(deal, dealType) {
  return {
    type: SET_DEAL,
    deal,
    dealType,
    new: false,
  };
}

export function fetchFuneralDetails(deal) {
  return {
    type: FETCH_FUNERAL_DETAILS,
    deal,
  };
}

/**
 * Action to dispatch to update the deal in the reducer
 *
 * @param      {Object}  updateObj  The update object
 *                                  object used to update the deal state
 * @param      {Object}  funeral   initial value of funeral
 * @return     {Object}  An action object asking the reducer to update the deal
 *                       using the update object provided above
 */
export function updateDeal(updateObj, funeral) {
  return updateDealSlice({
    funeral: {
      ...funeral,
      ...updateObj,
    },
  });
}

export function updateWishes(wishType, active, deal, dealType) {
  const tempWishes = [...deal.wishes];
  let wishFound = false;
  const finalWishes = tempWishes.map(wish => {
    if (wish.type === wishType) {
      wishFound = true;
      return { active, type: wish.type, value: wish.value };
    }
    return wish;
  });
  if (wishFound === false) {
    finalWishes.push({
      active,
      type: wishType,
    });
  }
  return updateDealSlice({
    [dealType]: {
      ...deal,
      wishes: finalWishes,
    },
  });
}

export function error(err) {
  return {
    type: ERROR,
    err,
  };
}

/**
 * Action to call when a request succeeded but no change to the store is needed.
 *
 * @return     {Object}  An action object asking the reducer to reset the
 *                       loading state.
 */
export function emptySuccessResponse() {
  return {
    type: SUCCESS_RESPONSE,
  };
}
