import { RefObject, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-final-form';

import { useBreakpoint } from '@advitam/react';
import {
  CeremonyJSON,
  CeremonyType,
  CoffinWoodType,
} from '@advitam/api/models/Crematorium/Ceremony';
import { CrematoriumCeremonyFilters } from '@advitam/api/v1/Crematoriums/Ceremonies';
import { Button, ResourceList } from '@advitam/ui';
import type { Filter } from '@advitam/ui/components/ResourceList';
import { addCollectionItem } from 'containers/Crud';
import { assert } from 'lib/support';

import type { CrematoriumForm } from '../../types';
import messages from '../messages';
import {
  makeSelectAgeRanges,
  makeSelectFilters,
  makeSelectRooms,
} from '../selectors';
import { setFilters } from '../slice';
import { fetchCeremonies } from '../thunk';
import style from '../Ceremonies.module.scss';

const XXL_BREAKPOINT = parseInt(style.xxl, 10);
const LG_BREAKPOINT = parseInt(style.lg, 10);

interface FiltersProps {
  gridContainer: RefObject<HTMLDivElement>;
}

export default function Filters({ gridContainer }: FiltersProps): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();
  const form = useForm<CrematoriumForm<CeremonyJSON[]>>();

  const isAboveLg = useBreakpoint(LG_BREAKPOINT);
  const isAboveXxl = useBreakpoint(XXL_BREAKPOINT);

  const ageRanges = useSelector(makeSelectAgeRanges());
  const rooms = useSelector(makeSelectRooms());
  const initialValues = useSelector(makeSelectFilters());

  const onCreate = useCallback(() => {
    assert(gridContainer.current !== null);
    addCollectionItem(
      form,
      'sectionValues',
      {} as CeremonyJSON,
      gridContainer.current,
    );
  }, [form, gridContainer]);

  const onChange = useCallback((newValues: CrematoriumCeremonyFilters) => {
    dispatch(setFilters(newValues));
    dispatch(fetchCeremonies());
  }, []);

  const filters: Filter[] = [
    {
      type: 'Select',
      name: 'ceremony_type',
      placeholder: <FormattedMessage id={messages.ceremony.id} />,
      initialValue: initialValues.ceremony_type,
      items: [
        {
          name: intl.formatMessage(messages.ceremonyTypeStandard),
          value: CeremonyType.STANDARD,
        },
        {
          name: intl.formatMessage(messages.ceremonyTypePremium),
          value: CeremonyType.PREMIUM,
        },
        {
          name: intl.formatMessage(messages.ceremonyTypeNoFamily),
          value: CeremonyType.NO_FAMILY,
        },
      ],
    },
    {
      type: 'Select',
      name: 'age_range_id',
      placeholder: <FormattedMessage id={messages.age.id} />,
      initialValue: initialValues.age_range_id,
      isHidden: !isAboveXxl,
      items: ageRanges.map(ageRange => ({
        name: ageRange.title,
        value: ageRange.id,
      })),
    },
    {
      type: 'Select',
      name: 'room_id',
      placeholder: <FormattedMessage id={messages.room.id} />,
      initialValue: initialValues.room_id,
      isHidden: !isAboveLg,
      items: rooms.map(room => ({
        name: room.name,
        value: room.id,
      })),
    },
    {
      type: 'Select',
      name: 'coffin_wood_type',
      multiple: true,
      placeholder: <FormattedMessage id={messages.coffinWoodType.id} />,
      initialValue: initialValues.coffin_wood_type,
      items: [
        {
          name: intl.formatMessage(messages.coffinWoodAny),
          value: CoffinWoodType.ANY,
        },
        {
          name: intl.formatMessage(messages.coffinWoodHard),
          value: CoffinWoodType.HARD,
        },
        {
          name: intl.formatMessage(messages.coffinWoodSoft),
          value: CoffinWoodType.SOFT,
        },
        {
          name: intl.formatMessage(messages.coffinWoodCardboard),
          value: CoffinWoodType.CARTON,
        },
      ],
    },
  ];

  return (
    <ResourceList.Filters
      filters={filters}
      onChange={onChange}
      className={style.filters}
    >
      <Button
        primary
        onClick={onCreate}
        text={<FormattedMessage id={messages.add.id} />}
      />
    </ResourceList.Filters>
  );
}
