import { useField } from 'react-form';
import PropTypes from 'prop-types';
import { Input } from 'components/Form';

import messages from './messages.ts';

function validator(required, validate) {
  return (value, instance) => {
    if (required && (!value || value === '')) {
      return messages.requiredField.id;
    }
    if (validate) {
      return validate(value, instance);
    }
    return false;
  };
}

function InputField({
  validate,
  field,
  className,
  message,
  toolTipMessage,
  errorMessages,
  required,
  disabled,
  hideErrorMessage,
  placeholderMessage,
  type,
  inputRef,
  min,
  max,
  onChange: superChange,
}) {
  const { meta, getInputProps } = useField(field, {
    validate: validator(required, validate),
  });
  const { value, onChange: reactChange } = getInputProps();
  const onChange = async ev => {
    ev.persist();
    await reactChange(ev);
    if (superChange) {
      superChange(ev);
    }
  };

  return (
    <Input
      input={{
        name: field,
        value,
        onChange,
        disabled,
        type,
        ref: inputRef,
        min,
        max,
      }}
      meta={{ error: meta.error, touched: meta.isTouched }}
      className={className}
      message={message}
      hideErrorMessage={hideErrorMessage}
      errorMessage={errorMessages && errorMessages[meta.error]}
      toolTipMessage={toolTipMessage}
      placeholderMessage={placeholderMessage}
    />
  );
}

InputField.defaultProps = {
  hideErrorMessage: false,
};

InputField.propTypes = {
  validate: PropTypes.func,
  onChange: PropTypes.func,
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
  message: PropTypes.object,
  toolTipMessage: PropTypes.object,
  errorMessages: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hideErrorMessage: PropTypes.bool,
  placeholderMessage: PropTypes.object,
  type: PropTypes.string,
  inputRef: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default InputField;
