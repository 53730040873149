import { useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import {
  Button,
  DimensionsInput,
  FormLayout,
  Input,
  NumberInput,
  Select,
  TextArea,
} from '@advitam/ui';

import { Layout, messages as crudMessages } from 'containers/Crud';
import actionsMessages from 'messages/actions';

import { ConcessionDuration } from '../../constants';
import {
  makeSelectConcessionTerms,
  makeSelectConcessionTypes,
} from '../../concessionInfosSlice/selectors';
import type { GraveyardConcessionsForm } from './types';
import messages from './messages';
import { makeSelectFilters } from './selectors';
import { isFiltered } from './utils';
import style from './Concessions.module.scss';

interface ConcessionProps {
  idx: number;
  deleteConcession: () => void;
}

export default function Concession({
  idx,
  deleteConcession,
}: ConcessionProps): JSX.Element {
  const intl = useIntl();
  const concessionTerms = useSelector(makeSelectConcessionTerms());
  const concessionTypes = useSelector(makeSelectConcessionTypes());
  const filters = useSelector(makeSelectFilters());

  const { values } = useFormState<GraveyardConcessionsForm>();
  const { sectionValues: concessions } = values;
  const concession = concessions[idx];

  const prefix = `sectionValues[${idx}]`;

  const booleanOptions: SelectableItem[] = [
    { name: intl.formatMessage(actionsMessages.yes), value: true },
    { name: intl.formatMessage(actionsMessages.no), value: false },
  ];

  const concessionTypesOptions: SelectableItem<number>[] = concessionTypes.map(
    ({ id, concession_type: concessionType }) => ({
      value: id,
      name: concessionType.name,
    }),
  );

  const concessionTermsOptions: SelectableItem<number>[] = concessionTerms.map(
    ({ id, term }) => ({
      value: id,
      name:
        term === ConcessionDuration.PERPETUAL
          ? intl.formatMessage(messages.perpetually)
          : intl.formatMessage(messages.year, { year: term }),
    }),
  );

  return (
    <Layout.Fieldset
      title={concession.name}
      className={isFiltered(concession, filters) ? style.hidden : undefined}
    >
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.title.id} />}
          name={`${prefix}.name`}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          required
          name={`${prefix}.graveyards_concession_type_id`}
          items={concessionTypesOptions}
          label={<FormattedMessage id={messages.type.id} />}
        />
        <Select
          required
          name={`${prefix}.graveyard_concession_term_id`}
          items={concessionTermsOptions}
          label={<FormattedMessage id={messages.duration.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          min={0}
          label={<FormattedMessage id={messages.price.id} />}
          name={`${prefix}.price`}
          step={0.1}
          suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
        />
        <Select
          label={<FormattedMessage id={messages.built.id} />}
          tooltip={<FormattedMessage id={messages.builtTooltip.id} />}
          name={`${prefix}.is_built`}
          items={booleanOptions}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          min={0}
          label={<FormattedMessage id={messages.area.id} />}
          tooltip={<FormattedMessage id={messages.areaTooltip.id} />}
          name={`${prefix}.size_square_meters`}
          step={0.1}
          suffix={<FormattedMessage id={crudMessages.unitSquareMeter.id} />}
        />
        <NumberInput
          min={1}
          label={<FormattedMessage id={messages.placeCount.id} />}
          tooltip={<FormattedMessage id={messages.placeCountTooltip.id} />}
          name={`${prefix}.size_places`}
          suffix={<FormattedMessage id={crudMessages.unitPlace.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <DimensionsInput
          names={[`${prefix}.length`, `${prefix}.width`, `${prefix}.height`]}
          label={<FormattedMessage id={messages.dimensions.id} />}
          tooltip={<FormattedMessage id={messages.dimensionsTooltip.id} />}
          suffix={<FormattedMessage id={crudMessages.unitCentimeter.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <TextArea
          label={<FormattedMessage id={messages.commentary.id} />}
          name={`${prefix}.description`}
        />
      </FormLayout.Row>
      <Button
        outline
        onClick={deleteConcession}
        text={<FormattedMessage id={actionsMessages.delete.id} />}
        className={style.delete}
      />
    </Layout.Fieldset>
  );
}
