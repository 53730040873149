import { UnsavedFuneralWork } from './types';

export const SUPPLIER_WAREHOUSE_ZONE = 'SupplierWarehouse/Zone';

export const PER_PAGE = 100;

export const FUNERAL_WORK_SKELETON: UnsavedFuneralWork = {
  cost: null,
  funeral_work_type: null,
  supplier_ref: null,
  price: null,
};
