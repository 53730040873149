import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { requestAsync } from 'api';
import type { AppStateSubset as DealAppStateSubset } from 'containers/Deal/slice';
import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import { assert } from 'lib/support';

import { LAYERS_MODAL } from './constants';
import { makeSelectLayers } from './selectors';
import type { AppStateSubset as LayersAppStateSubset } from './slice';

type AppStateSubset = DealAppStateSubset & LayersAppStateSubset;

export const fetchLayers = createAsyncThunk(
  `${LAYERS_MODAL}/FETCH`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal !== null);

    try {
      const { body } = await requestAsync(Api.V1.Deals.Layers.index(deal));
      assert(body !== null);
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const cleanLayers = createAsyncThunk(
  `${LAYERS_MODAL}/CLEAN`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const layers = makeSelectLayers()(state);

    try {
      await Promise.all([
        layers.map(layer => requestAsync(Api.V1.Deals.Layers.destroy(layer))),
      ]);
    } catch (err) {
      return rejectWithValue(err);
    }

    return undefined;
  },
);
