import { BillingType } from '@advitam/api/models/BillingType';
import { NewGraveyard } from './types';

export const GRAVEYARD = 'Graveyard';

export const Path = {
  CONCESSIONS: 'concessions',
  GENERAL: '',
  MISCELLANEOUS: 'divers',
};

export const ConcessionDuration = {
  FIVE_YEARS: 5,
  TEN_YEARS: 10,
  THIRTY_YEARS: 30,
  FIFTY_YEARS: 50,
  PERPETUAL: -1,
};

export const GRAVEYARD_SKELETON: NewGraveyard = {
  name: '',
  disabled: false,
  address: null,
  address_l2: null,
  postal_code: null,
  insee_code: null,
  city: null,
  department: null,
  country: null,
  latitude: null,
  longitude: null,
  manual_address: false,
  phone: null,
  email: null,
  prefered_contact_media: null,
  opening_hours: null,
  fax: null,
  phone_2: null,
  phone_3: null,
  email_2: null,
  email_3: null,
  email_4: null,
  comment: null,
  current_update_user_id: null,
  last_update_user_id: null,
  billing_type: BillingType.DEAL,
  dispersion_jds_fee: null,
  cheque_order: null,
  cheque_address: null,
  rib: null,
  iban: null,
  vat: null,
  checkout_type: null,
  authentic_document_needed: null,
  siret: null,
  headquarter_name: null,
  headquarter_address: null,
  headquarter_postal_code: null,
  headquarter_country: null,
  headquarter_city: null,
  website: null,
  is_full: null,
  graveyard_type: null,
  has_jds: false,
  is_family_buying_concession: false,
  default_concession_type: null,
  jewish_space: null,
  muslim_space: null,
  cityhalls: [],
  vault_mandatory: null,
  should_buy_col_plate: null,
  stele: null,
  gravillon: null,
  tomb_no_engraving: null,
  fausse_case: null,
  semelle: null,
  semelle_length: null,
  semelle_width: null,
  required_semelle_granite_type: null,
  required_semelle_shape: null,
  coffin_interment_tax: null,
  urn_interment_tax: null,
  exhumation_tax: null,
  col_plate_tax: null,
  temporary_vault_oneoff: null,
  temporary_vault_max_length: null,
  temporary_vault_length: null,
  temporary_vault_price_per_day: null,
  col_plate_color: null,
  col_plate_width: null,
  col_plate_height: null,
  concession_semelle: null,
  current_update_dt: null,
  last_update_dt: null,
};
