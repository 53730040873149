import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';

import type { SupportingDocumentIndexJSON } from '@advitam/api/models/SupportingDocuments/SupportingDocumentIndex';
import Paperclip from '@advitam/ui/images/icons/paperclip.svg';
import Upload from '@advitam/ui/images/icons/upload.svg';
import { ButtonLike, LinkButton } from '@advitam/ui/components/Button';
import { isRequired } from '@advitam/ui/components/Form/validators';

import type { UnsavedSupportingDocument } from '../types';
import style from '../SupportingDocuments.module.scss';

interface FileButtonProps {
  document: SupportingDocumentIndexJSON | UnsavedSupportingDocument;
  prefix: string;
}

export default function FileButton({
  document,
  prefix,
}: FileButtonProps): JSX.Element {
  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    if (document.id === undefined && document.file) {
      setBlobUrl(URL.createObjectURL(document.file));
    }
    return (): void => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [document]);

  if (document.id) {
    return (
      <LinkButton outline newTab href={document.file} icon={<Paperclip />} />
    );
  }

  if (document.id === undefined && document.file) {
    return <LinkButton outline newTab href={blobUrl} icon={<Paperclip />} />;
  }

  return (
    <Field type="file" name={`${prefix}.file`} validate={isRequired}>
      {({ input, meta }): JSX.Element => (
        <label>
          <input
            type="file"
            className="soft-hide"
            onChange={(ev): void => input.onChange(ev.target.files?.[0])}
            onBlur={input.onBlur}
            name={input.name}
          />
          <ButtonLike
            outline
            icon={<Upload />}
            className={meta.touched && !meta.valid ? style.error : undefined}
          />
        </label>
      )}
    </Field>
  );
}
