import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  defunctAge: { id: 'app.containers.Deal.Sections.Identity.Form.defunctAge' },
  defunctTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.defunctTooltip',
  },
  fatherTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.fatherTooltip',
  },
  motherTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.motherTooltip',
  },
  partnerTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.partnerTooltip',
  },
  civility: { id: 'app.containers.Deal.Sections.Identity.Form.civility' },
  mr: { id: 'app.containers.Deal.Sections.Identity.Form.mr' },
  mrs: { id: 'app.containers.Deal.Sections.Identity.Form.mrs' },
  firstname: { id: 'app.containers.Deal.Sections.Identity.Form.firstname' },
  lastname: { id: 'app.containers.Deal.Sections.Identity.Form.lastname' },
  birthName: { id: 'app.containers.Deal.Sections.Identity.Form.birthName' },
  address: { id: 'app.containers.Deal.Sections.Identity.Form.address' },
  city: { id: 'app.containers.Deal.Sections.Identity.Form.city' },
  postalCode: { id: 'app.containers.Deal.Sections.Identity.Form.postalCode' },
  maritalStatus: {
    id: 'app.containers.Deal.Sections.Identity.Form.maritalStatus',
  },
  birthDate: { id: 'app.containers.Deal.Sections.Identity.Form.birthDate' },
  birthPlace: { id: 'app.containers.Deal.Sections.Identity.Form.birthPlace' },
  nationality: { id: 'app.containers.Deal.Sections.Identity.Form.nationality' },
  work: { id: 'app.containers.Deal.Sections.Identity.Form.work' },
  worship: { id: 'app.containers.Deal.Sections.Identity.Form.worship' },
  decoration: { id: 'app.containers.Deal.Sections.Identity.Form.decoration' },
  weight: { id: 'app.containers.Deal.Sections.Identity.Form.weight' },
  size: { id: 'app.containers.Deal.Sections.Identity.Form.size' },
  sizePlaceholder: {
    id: 'app.containers.Deal.Sections.Identity.Form.sizePlaceholder',
  },
  sizeTooltip: { id: 'app.containers.Deal.Sections.Identity.Form.sizeTooltip' },
  poweredDevice: {
    id: 'app.containers.Deal.Sections.Identity.Form.poweredDevice',
  },
  guardianship: {
    id: 'app.containers.Deal.Sections.Identity.Form.guardianship',
  },
  guardianshipTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.guardianshipTooltip',
  },
  deceased: { id: 'app.containers.Deal.Sections.Identity.Form.deceased' },
  empoweredClientTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.empoweredClientTooltip',
  },
  linkWithDefunct: {
    id: 'app.containers.Deal.Sections.Identity.Form.linkWithDefunct',
  },
  email: { id: 'app.containers.Deal.Sections.Identity.Form.email' },
  emailDuplicated: {
    id: 'app.containers.Deal.Sections.Identity.Form.emailDuplicated',
  },
  emailTaken: {
    id: 'app.containers.Deal.Sections.Identity.Form.emailTaken',
  },
  mobilePhone: { id: 'app.containers.Deal.Sections.Identity.Form.mobilePhone' },
  homePhone: { id: 'app.containers.Deal.Sections.Identity.Form.homePhone' },
  noPhoneError: {
    id: 'app.containers.Deal.Sections.Identity.Form.noPhoneError',
  },
  call: { id: 'app.containers.Deal.Sections.Identity.Form.call' },
  empowered: { id: 'app.containers.Deal.Sections.Identity.Form.empowered' },
  empoweredTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.empoweredTooltip',
  },
  role: { id: 'app.containers.Deal.Sections.Identity.Form.role' },
  roleTooltip: { id: 'app.containers.Deal.Sections.Identity.Form.roleTooltip' },
  owner: { id: 'app.containers.Deal.Sections.Identity.Form.owner' },
  editor: { id: 'app.containers.Deal.Sections.Identity.Form.editor' },
  reader: { id: 'app.containers.Deal.Sections.Identity.Form.reader' },
  notary: { id: 'app.containers.Deal.Sections.Identity.Form.notary' },
  remove: { id: 'app.containers.Deal.Sections.Identity.Form.remove' },
  removeTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.removeTooltip',
  },
  removeSpecialTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.removeSpecialTooltip',
  },
  removeOwnerTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.removeOwnerTooltip',
  },
  profile: { id: 'app.containers.Deal.Sections.Identity.Form.profile' },
  profileTooltip: {
    id: 'app.containers.Deal.Sections.Identity.Form.profileTooltip',
  },
});
