// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentsEditor-module__container--XgBP8{height:100%;display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/containers/DocumentsEditor/DocumentsEditor.module.scss"],"names":[],"mappings":"AAAA,0CACE,WAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DocumentsEditor-module__container--XgBP8`
};
export default ___CSS_LOADER_EXPORT___;
