import { authenticate } from '../../../lib/decorators'
import { post } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { DealDocumentBatchJSON } from '../../../models/Deal/Document/Batch'
import { serializeSendBatch } from './serializer'
import { BatchEntityRecipient } from './types'

export class SendBatch {
  @authenticate
  static create(
    dealUuid: string,
    batch: DealDocumentBatchJSON,
    recipients: BatchEntityRecipient[],
  ): ApiRequestDescriptor<void> {
    return post(
      `/api/v1/deals/${dealUuid}/documents/send_batch`,
      serializeSendBatch(batch, recipients),
    )
  }
}
