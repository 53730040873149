// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionFrame-module__frame--t2MHf{width:100%;padding:8px 16px}@media(min-width: 576px){.ActionFrame-module__frame--t2MHf{width:-moz-fit-content;width:fit-content;margin-left:32px}}`, "",{"version":3,"sources":["webpack://./src/containers/Deal/Sections/Todolist/List/parts/ActionFrame.module.scss","webpack://./../ui/scss/_media.scss"],"names":[],"mappings":"AAGA,kCACE,UAAA,CACA,gBAAA,CCSE,yBDXJ,kCAKI,sBAAA,CAAA,iBAAA,CACA,gBAAA,CAAA","sourcesContent":["@import '~@advitam/ui/scss/media';\n@import './vars.module.scss';\n\n.frame {\n  width: 100%;\n  padding: 8px 16px;\n\n  @include above(sm) {\n    width: fit-content;\n    margin-left: $checkbox_size + $checkbox_margin;\n  }\n}\n","$breakpoints: (\n  xxl: 1440px,\n  xl: 1200px,\n  lg: 992px,\n  md: 768px,\n  sm: 576px,\n  xs: 375px\n);\n\n@mixin above($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n@mixin below($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (max-width: ($breakpoint-value - 1)) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n:export {\n  /* stylelint-disable property-no-unknown */\n  xxl: 1440px;\n  xl: 1200px;\n  lg: 992px;\n  md: 768px;\n  sm: 576px;\n  xs: 375px;\n  /* stylelint-enable property-no-unknown */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"frame": `ActionFrame-module__frame--t2MHf`
};
export default ___CSS_LOADER_EXPORT___;
