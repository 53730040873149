import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Select } from 'components/Input/SelectList';

import {
  VAULT,
  VAULT_FRONT_DOOR,
  VAULT_FRONT_DOOR_UNDERGROUND,
  PLAIN_EARTH,
  COLUMBARIUM_CASE,
  URN_VAULT,
  UNKNOWN,
} from 'containers/Deal/DealFuneral/StepsSection/StepEditors/Interment/constants';
import otherMessages from 'components/ConcessionSelection/messages';

import messages from './messages';

function SelectConcessionType({
  concession,
  intl,
  graveyardConcessions,
  graveyardConcessionTypes,
  handleChange,
  locationId,
  isUrn,
}) {
  /**
   * Get similar concession from our previous concession
   *
   * @param   {String}  value                 value of new graveyard concession type
   * @returns {Object}  values of similar concession
   */
  const getSimilarConcession = value => {
    let concessionTerm = null;
    let graveyardConcessionType = value;
    let graveyardConcessionId = null;
    let similarConcession = false;

    // default case
    if (graveyardConcessions[value]) {
      similarConcession = graveyardConcessions[value].find(
        item => String(item.id) === String(concession.graveyard_concession_id),
      );
    }

    // specific case with vault types
    if (
      !graveyardConcessions[value] &&
      (value === VAULT ||
        value === VAULT_FRONT_DOOR ||
        value === VAULT_FRONT_DOOR_UNDERGROUND) &&
      graveyardConcessions.plainearth
    ) {
      similarConcession = graveyardConcessions.plainearth.find(
        item => String(item.id) === String(concession.graveyard_concession_id),
      );
      graveyardConcessionType = PLAIN_EARTH;
    }

    if (concession.graveyard_concession_id && Boolean(similarConcession)) {
      concessionTerm = similarConcession.term;
      graveyardConcessionId = similarConcession.id;
    }

    return {
      concessionTerm,
      graveyardConcessionType,
      graveyardConcessionId,
    };
  };

  /**
   * Update the type of concession, if it's the same details keep the data
   *
   * @param     {Object}    e     event object
   */
  const onChangeGraveyardConcessionTypes = ({ target: { value } }) => {
    const similarConcession = getSimilarConcession(value);

    handleChange({
      concession: {
        ...concession,
        graveyard_concession_type:
          similarConcession.graveyardConcessionType || null,
        graveyard_concession_id:
          similarConcession.graveyardConcessionId || null,
        term: similarConcession.concessionTerm || null,
        type: {
          ...concession.type,
          name: value,
        },
      },
    });
  };

  let concessionTypes = graveyardConcessionTypes;
  if ((isUrn !== null || isUrn !== undefined) && isUrn === false) {
    concessionTypes = graveyardConcessionTypes.filter(
      concessionType =>
        concessionType.concession_type.concession_type !== COLUMBARIUM_CASE &&
        concessionType.concession_type.concession_type !== URN_VAULT,
    );
  }

  if (!locationId) return false;
  return (
    <div>
      <FormattedMessage {...messages.type} />
      <Select
        onChange={onChangeGraveyardConcessionTypes}
        value={concession.type.name || 'default'}
      >
        {concessionTypes.map(type => (
          <option key={type.id} value={type.concession_type.concession_type}>
            {intl.formatMessage(messages[type.concession_type.concession_type])}
          </option>
        ))}
        <option key={UNKNOWN} value={UNKNOWN}>
          {intl.formatMessage(messages.unknown)}
        </option>
        <option key="default" value="default" disabled>
          {intl.formatMessage(otherMessages.defaultSelect)}
        </option>
      </Select>
    </div>
  );
}

SelectConcessionType.propTypes = {
  /** concession data */
  concession: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  /** step location id */
  locationId: PropTypes.number,
  /** concessions types of graveyard selected */
  graveyardConcessionTypes: PropTypes.array,
  /** concessions available in graveyard selected */
  graveyardConcessions: PropTypes.object,
  /** Are we dealing with an urn in this concession */
  isUrn: PropTypes.bool,
  /** Function to update the step */
  handleChange: PropTypes.func.isRequired,
};

export default injectIntl(SelectConcessionType);
