import { ReactNode } from 'react'

import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'
import style from './Boolean.module.scss'

interface DefaultProps {
  label: ReactNode
  tooltip?: ReactNode
  borderless?: boolean
  checked?: boolean
  error?: boolean
  className?: string
  children: ReactNode
}

/**
 * UI for boolean fields (radios and checkboxes)
 *
 * It is not meant to be used directly
 */
export default function Default({
  label,
  tooltip,
  borderless = false,
  checked = false,
  error = false,
  className = '',
  children,
}: DefaultProps): JSX.Element {
  const classes = [
    uiStyle.input,
    style.field,
    borderless && style.borderless,
    checked && style.checked,
    error && uiStyle.error,
    className,
  ].filter(Boolean)

  return (
    <Label value={label} tooltip={tooltip} className={classes.join(' ')}>
      {children}
    </Label>
  )
}
