import { authenticate, formdataEncoded } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { DocumentTypeJSON } from '../../models/DocumentType'
import { SupportingDocumentIndexJSON } from '../../models/SupportingDocuments/SupportingDocumentIndex'

export class SupportingDocuments {
  @authenticate
  static index(formalityId: number): ApiRequestDescriptor<SupportingDocumentIndexJSON[]> {
    return get(`/api/v1/ongoing_formalities/${formalityId}/supporting_documents`)
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<SupportingDocumentIndexJSON> {
    return get(`/api/v1/ongoing_formalities/supporting_documents/${id}`)
  }

  @authenticate
  @formdataEncoded
  static create(
    formalityId: number,
    docType: DocumentTypeJSON,
    file: Blob,
  ): ApiRequestDescriptor<SupportingDocumentIndexJSON> {
    return post(`/api/v1/ongoing_formalities/${formalityId}/supporting_documents`, {
      file,
      supporting_document: { document_type: docType.id },
    })
  }

  @authenticate
  @formdataEncoded
  static update(id: number, file: Blob): ApiRequestDescriptor<SupportingDocumentIndexJSON> {
    return put(`/api/v1/ongoing_formalities/supporting_documents/${id}`, {
      file,
    })
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<SupportingDocumentIndexJSON> {
    return del(`/api/v1/ongoing_formalities/supporting_documents/${id}`)
  }
}
