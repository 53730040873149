import { useLocation } from 'react-router-dom';

export interface EntitySeed {
  name: string;
  city: string;
  postal_code: string;
  insee_code: string;
}

export interface LegacyEntitySeed {
  name: string;
  address: {
    city: string;
    postal_code: string;
    insee_code: string;
  };
}

interface NavigationArguments {
  seed: EntitySeed;
}

export function useEntitySeed(): EntitySeed | undefined {
  const state = useLocation().state as NavigationArguments;
  return state?.seed;
}

export function useLegacyEntitySeed(): LegacyEntitySeed | undefined {
  const state = useLocation().state as NavigationArguments;
  if (!state?.seed) {
    return undefined;
  }
  return {
    name: state.seed.name,
    address: {
      city: state.seed.city,
      postal_code: state.seed.postal_code,
      insee_code: state.seed.insee_code,
    },
  };
}
