import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import Api, { isApiError, request } from '@advitam/api';
import { CrematoriumJSON } from '@advitam/api/models/Crematorium';
import type { AgeRangeJSON } from '@advitam/api/models/Crematorium/AgeRange';
import { assert } from 'lib/Assert';

import { makeSelectRawCrematorium } from '../../selectors';
import type { AppStateSubset as CrematoriumAppStateSubset } from '../../slice';
import { CREMATORIUM_AGE_RANGES, ERROR_AGE_RANGE_IN_USE } from './constants';
import { makeSelectAgeRanges } from './selectors';
import type { AppStateSubset as AgeRangeAppStateSubset } from './slice';
import type { UnsavedAgeRange } from './types';

type AppStateSubset = CrematoriumAppStateSubset & AgeRangeAppStateSubset;

export const fetchAgeRanges = createAsyncThunk(
  `${CREMATORIUM_AGE_RANGES}/FETCH`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const crematorium = makeSelectRawCrematorium()(state);
    assert(crematorium?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Crematoriums.AgeRanges.index(crematorium.id),
      );
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

async function saveAgeRange(
  ageRange: AgeRangeJSON | UnsavedAgeRange,
  existingAgeRanges: AgeRangeJSON[],
  crematorium: CrematoriumJSON,
): Promise<AgeRangeJSON> {
  if (ageRange.id === undefined) {
    const { body } = await request(
      Api.V1.Crematoriums.AgeRanges.create(crematorium.id, ageRange),
    );
    return body;
  }

  const existingAgeRange = existingAgeRanges.find(
    range => range.id === ageRange.id,
  );
  assert(existingAgeRange !== undefined);
  if (isEqual(ageRange, existingAgeRange)) {
    return existingAgeRange;
  }

  const { body } = await request(
    Api.V1.Crematoriums.AgeRanges.update(ageRange),
  );

  return body;
}

export const saveAgeRanges = createAsyncThunk(
  `${CREMATORIUM_AGE_RANGES}/SAVE`,
  async (payload: AgeRangeJSON[], { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const crematorium = makeSelectRawCrematorium()(state);
    const existingRanges = makeSelectAgeRanges()(state);
    assert(crematorium?.id !== undefined);

    try {
      const result = await Promise.all(
        payload.map(ageRange =>
          saveAgeRange(ageRange, existingRanges, crematorium),
        ),
      );
      const errors = await Promise.all(
        existingRanges.map(async ageRange => {
          if (!payload.some(value => value.id === ageRange.id)) {
            try {
              await request(Api.V1.Crematoriums.AgeRanges.destroy(ageRange.id));
            } catch (error) {
              if (
                !isApiError(error) ||
                error.errorCodes[0] !== ERROR_AGE_RANGE_IN_USE
              ) {
                throw error;
              }

              result.push(ageRange);
              return error;
            }
          }
          return undefined;
        }),
      );

      const hasAlreadyInUseError = errors.some(Boolean);
      return { hasAlreadyInUseError, result };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
