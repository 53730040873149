import { FormattedMessage, useIntl } from 'react-intl';

import { Permission } from '@advitam/api/models/Graveyard';
import { FormLayout, Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import { Layout } from 'containers/Crud';
import actionsMessages from 'messages/actions';

import messages from '../messages';
import YearPermissionSelect from './parts/YearPermissionSelect';

const PREFIX = 'graveyard';

export function Works(): JSX.Element {
  const intl = useIntl();

  const permissionOptions: SelectableItem<Permission>[] = [
    {
      name: intl.formatMessage(messages.allowed),
      value: Permission.ALLOWED,
    },
    {
      name: intl.formatMessage(messages.mandatory),
      value: Permission.REQUIRED,
    },
    {
      name: intl.formatMessage(messages.forbidden),
      value: Permission.FORBIDDEN,
    },
  ];

  const booleanOptions: SelectableItem<boolean>[] = [
    {
      name: intl.formatMessage(actionsMessages.yes),
      value: true,
    },
    {
      name: intl.formatMessage(actionsMessages.no),
      value: false,
    },
  ];

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.works.id} />}>
      <FormLayout.Row>
        <Select
          unselectable
          name={`${PREFIX}.vault_mandatory`}
          items={permissionOptions}
          label={<FormattedMessage id={messages.vault.id} />}
          tooltip={<FormattedMessage id={messages.vaultTooltip.id} />}
        />
        <Select
          unselectable
          name={`${PREFIX}.tomb_no_engraving`}
          items={booleanOptions}
          label={<FormattedMessage id={messages.engravedGraveNumber.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          unselectable
          name={`${PREFIX}.stele`}
          items={permissionOptions}
          label={<FormattedMessage id={messages.stele.id} />}
          tooltip={<FormattedMessage id={messages.steleTooltip.id} />}
        />
        <Select
          unselectable
          name={`${PREFIX}.gravillon`}
          items={booleanOptions}
          label={<FormattedMessage id={messages.gravel.id} />}
          tooltip={<FormattedMessage id={messages.gravelTooltip.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <YearPermissionSelect
          name="fausse_case"
          permissionInputLabel={<FormattedMessage id={messages.fakeBox.id} />}
          permissionInputTooltip={
            <FormattedMessage id={messages.fakeBoxTooltip.id} />
          }
          yearInputLabel={<FormattedMessage id={messages.minDuration.id} />}
          yearInputTooltip={
            <FormattedMessage id={messages.fakeBoxMinDurationTooltip.id} />
          }
        />
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
