import { ReactNode } from 'react'
import Infos from './parts/Infos'
import Details from './parts/Details'
import Separator from './parts/Separator'
import style from './Step.module.scss'

interface TransportStepProps {
  date: Date | null
  details: ReactNode | ReactNode[]
  distance: ReactNode
  icon?: ReactNode
  title: ReactNode
}

export default function TransportStep({
  date,
  details,
  distance,
  icon,
  title,
}: TransportStepProps): JSX.Element {
  return (
    <div className={style.step}>
      <Infos date={date} distance={distance} />
      <Separator icon={icon} />
      <Details title={title} details={details} />
    </div>
  )
}
