import { chunk } from './chunk'
import { deburr } from './deburr'
import { sanitizeSpaces } from './sanitize'
import { tokenizeJavascriptPath } from './tokenizeJavascriptPath'
import { toUrlSegment } from './toUrlSegment'

export const Strings = {
  chunk,
  deburr,
  sanitizeSpaces,
  tokenizeJavascriptPath,
  toUrlSegment,
}
