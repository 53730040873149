import { defineMessages } from 'react-intl';

export default defineMessages({
  engravingText: {
    id: 'app.components.DealFuneral.StepEditors.ServicesSelector.engravingText',
  },
  daysText: {
    id: 'app.components.DealFuneral.StepEditors.ServicesSelector.daysText',
  },
  holdersText: {
    id: 'app.components.DealFuneral.StepEditors.ServicesSelector.holdersText',
  },
});
