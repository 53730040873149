import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import { FullClient, FullClientJSON } from '@advitam/api/models/Client/Full';
import {
  DeathDeclarationJSON,
  DeathDeclarationState,
} from '@advitam/api/models/DeathDeclaration';

import { CLIENT } from './constants';
import type { AppStateSubset, State } from './slice';

type ClientSelectors<T> = Selector<AppStateSubset, T>;

const selectClientDomain = (state: AppStateSubset): State => state[CLIENT];

export const makeSelectRawClient = (): ClientSelectors<FullClientJSON | null> =>
  createSelector(selectClientDomain, ({ client }) => client);

export const makeSelectClient = (): ClientSelectors<FullClient | null> =>
  createSelector(makeSelectRawClient(), rawClient =>
    rawClient?.id ? new FullClient(rawClient) : null,
  );

export const makeSelectDeathDeclarations = (): ClientSelectors<
  DeathDeclarationJSON[]
> => createSelector(selectClientDomain, state => state.deathDeclarations);

export const makeSelectPendingDeathDeclarations = (): ClientSelectors<
  DeathDeclarationJSON[]
> =>
  createSelector(makeSelectDeathDeclarations(), declarations =>
    declarations.filter(
      declaration => declaration.state === DeathDeclarationState.PENDING,
    ),
  );

export const makeSelectIsLoading = (): ClientSelectors<boolean> =>
  createSelector(selectClientDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): ClientSelectors<boolean> =>
  createSelector(selectClientDomain, ({ isSaving }) => isSaving);

export const makeSelectError = (): ClientSelectors<SerializedApiError | null> =>
  createSelector(selectClientDomain, ({ error }) => error);
