import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LockIcon from 'components/Icons/LockIcon';
import EditIcon from 'components/Icons/EditIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import { isUserFuneralDirectorOrAbove } from 'components/Authorization/utils';

import {
  PRESTATION_COMPUTE_MANUAL,
  PRESTATION_COMPUTE_AUTO,
  PRESTATION_STATUS_EDITED,
  PRESTATION_STATUS_DELETED,
  PRESTATION_STATUS_NONE,
  PRESTATION_PRODUCT,
} from 'components/PrestationDialog/constants';

class PrestationActions extends PureComponent {
  /**
   * Function to lock or unlock prestation
   */
  onLockPrestation = e => {
    e.stopPropagation();
    const { prestation, updatePrestation } = this.props;
    const updatedPrestation = {
      ...prestation,
      status:
        prestation.status === PRESTATION_STATUS_EDITED
          ? PRESTATION_STATUS_NONE
          : PRESTATION_STATUS_EDITED,
    };
    updatePrestation(updatedPrestation);
  };

  renderEditIcon = () => {
    const { onEditPrestation, userRole, isManualMode } = this.props;
    if (!isManualMode || !isUserFuneralDirectorOrAbove(userRole)) return null;
    return (
      <button type="button" onClick={onEditPrestation}>
        <EditIcon />
      </button>
    );
  };

  renderLockIcon = () => {
    const { prestation, userRole, isManualMode } = this.props;
    if (!isManualMode || !isUserFuneralDirectorOrAbove(userRole)) return null;
    return (
      <button type="button" onClick={this.onLockPrestation}>
        <LockIcon
          isActivated={prestation.status === PRESTATION_STATUS_EDITED}
        />
      </button>
    );
  };

  deletePrestation = prestation => {
    const {
      removePrestation,
      updatePrestation,
      prestationType,
      isManualMode,
    } = this.props;

    const isProduct = prestationType === PRESTATION_PRODUCT;
    // We hard delete the prestation if the user added it
    if (
      prestation.compute === PRESTATION_COMPUTE_MANUAL ||
      (isProduct && !isManualMode)
    ) {
      removePrestation();
      return;
    }
    // Soft delete
    const updatedPrestation = {
      ...prestation,
      status:
        prestation.status === PRESTATION_STATUS_DELETED
          ? PRESTATION_STATUS_NONE
          : PRESTATION_STATUS_DELETED,
    };
    updatePrestation(updatedPrestation);
  };

  renderDeleteIcon = () => {
    const { prestation, userRole, isManualMode, prestationType } = this.props;

    const isProduct = prestationType === PRESTATION_PRODUCT;

    if (isProduct) {
      if (
        prestation.status !== PRESTATION_STATUS_NONE &&
        (!isManualMode || !isUserFuneralDirectorOrAbove(userRole))
      ) {
        return null;
      }
    } else {
      switch (prestation.compute) {
        case PRESTATION_COMPUTE_MANUAL:
          if (prestation.status === PRESTATION_STATUS_EDITED && !isManualMode)
            return null;
          break;
        case PRESTATION_COMPUTE_AUTO:
          if (!isManualMode || !isUserFuneralDirectorOrAbove(userRole))
            return null;
          break;
        default:
          break;
      }
    }

    return (
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          this.deletePrestation(prestation);
        }}
      >
        <TrashIcon
          isActivated={prestation.status === PRESTATION_STATUS_DELETED}
        />
      </button>
    );
  };

  render() {
    return (
      <div className="prestationActions">
        {this.renderEditIcon()}
        {this.renderLockIcon()}
        {this.renderDeleteIcon()}
      </div>
    );
  }
}

PrestationActions.propTypes = {
  /** prestation data */
  prestation: PropTypes.object.isRequired,
  /** function to remove a prestation */
  removePrestation: PropTypes.func.isRequired,
  /** function to remove a prestation */
  onEditPrestation: PropTypes.func.isRequired,
  /** function to update prestations */
  updatePrestation: PropTypes.func.isRequired,
  /** role of user */
  userRole: PropTypes.number.isRequired,
  /** is the manual mode on */
  isManualMode: PropTypes.bool.isRequired,
  /** type of the prestation, can be service or product */
  prestationType: PropTypes.string.isRequired,
};

export default PrestationActions;
