import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  EmailInput,
  FormLayout,
  PhoneInput,
} from '@advitam/ui/components/Form';
import { makeSelectUser } from 'slices/auth';
import { Layout } from 'containers/Crud';

import LocationField from './LocationField';
import messages from './messages';

export default function Contact(): JSX.Element {
  const user = useSelector(makeSelectUser());

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.contact.id} />}>
      <FormLayout.Row>
        <PhoneInput
          label={<FormattedMessage id={messages.mobilePhone.id} />}
          name="client.phone"
          withCallButton
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <PhoneInput
          label={<FormattedMessage id={messages.phone.id} />}
          name="client.phone_2"
          withCallButton
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <EmailInput
          label={<FormattedMessage id={messages.email.id} />}
          name="client.email"
          disabled={!user?.isAdmin}
        />
      </FormLayout.Row>
      <LocationField />
    </Layout.Fieldset>
  );
}
