import { GraveyardJSON } from '@advitam/api/models/Graveyard';
import { formatEntity } from '../Entity';

export function formatName(graveyard: GraveyardJSON): string {
  return formatEntity(graveyard);
}

export function FormattedName(graveyard: GraveyardJSON): JSX.Element {
  return <>{formatName(graveyard)}</>;
}
