import { Fragment } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import remarkGfm from 'remark-gfm'

import { ErrorText, FormattedApiError, Text } from '@advitam/ui'

import MessageFeedback from './MessageFeedback'
import QuestionSuggestion from './QuestionSuggestion'
import Source from './Source'
import {
  makeSelectError,
  makeSelectIsMessageLoading,
  makeSelectMessages,
  makeSelectQuestionSuggestions,
} from '../selectors'
import { MessageAuthor } from '../types'
import style from './Conversation.module.scss'
import LoadingMessage from './LoadingMessage'

interface MessageFeedProps {
  isReady: boolean
  onAskQuestion: (question: string) => void
}

export default function MessageFeed({ isReady, onAskQuestion }: MessageFeedProps): JSX.Element {
  const chatMessages = useSelector(makeSelectMessages())
  const questionSuggestions = useSelector(makeSelectQuestionSuggestions())
  const isLoading = useSelector(makeSelectIsMessageLoading())
  const error = useSelector(makeSelectError())

  return (
    <>
      {chatMessages.map((message, index) => (
        <Fragment key={index}>
          <Text
            tagName="div"
            className={[
              style.message,
              message.author === MessageAuthor.USER ? style.user : style.chatbot,
            ].join(' ')}
          >
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.message.answer}</ReactMarkdown>
            <MessageFeedback message={message} />
          </Text>
          {message.message.sources.map((source, sourceIndex) => (
            <Source source={source} index={sourceIndex + 1} />
          ))}
          {questionSuggestions.map(question => (
            <QuestionSuggestion
              question={question}
              onClick={onAskQuestion}
              key={question}
              disabled={!isReady}
            />
          ))}
        </Fragment>
      ))}
      {isLoading && (
        <Text tagName="div" className={`${style.message} ${style.chatbot}`}>
          <LoadingMessage />
        </Text>
      )}
      {error && (
        <ErrorText center>
          <FormattedApiError error={error} />
        </ErrorText>
      )}
    </>
  )
}
