import { authenticate, formdataEncoded } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { post } from 'api/methods';

import { PublicUploadJSON } from 'models/PublicUpload';

export class PublicUploads {
  @authenticate
  @formdataEncoded
  static create(
    file: Blob,
    resource_type: string,
    resource_id: number,
  ): ApiRequestDescriptor<PublicUploadJSON> {
    return post('/api/v1/public_uploads', {
      file,
      resource_type,
      resource_id,
    });
  }
}
