// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(min-width: 768px){.Miscellaneous-module__row--CFFjk{align-items:flex-end}.Miscellaneous-module__row--CFFjk:last-child{margin-bottom:0}}`, "",{"version":3,"sources":["webpack://./../ui/scss/_media.scss","webpack://./src/containers/Worship/sections/Miscellaneous/Miscellaneous.module.scss"],"names":[],"mappings":"AAcI,yBCXF,kCACE,oBAAA,CAEA,6CACE,eAAA,CAAA","sourcesContent":["$breakpoints: (\n  xxl: 1440px,\n  xl: 1200px,\n  lg: 992px,\n  md: 768px,\n  sm: 576px,\n  xs: 375px\n);\n\n@mixin above($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (min-width: $breakpoint-value) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n@mixin below($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    // stylelint-disable-next-line function-no-unknown\n    $breakpoint-value: map-get($breakpoints, $breakpoint);\n\n    @media (max-width: ($breakpoint-value - 1)) {\n      @content;\n    }\n  }\n\n  @else {\n    @warn 'Invalid breakpoint: #{$breakpoint}.';\n  }\n}\n\n:export {\n  /* stylelint-disable property-no-unknown */\n  xxl: 1440px;\n  xl: 1200px;\n  lg: 992px;\n  md: 768px;\n  sm: 576px;\n  xs: 375px;\n  /* stylelint-enable property-no-unknown */\n}\n","@import '~@advitam/ui/scss/media';\n\n@include above(md) {\n  .row {\n    align-items: flex-end;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxl": `1440px`,
	"xl": `1200px`,
	"lg": `992px`,
	"md": `768px`,
	"sm": `576px`,
	"xs": `375px`,
	"row": `Miscellaneous-module__row--CFFjk`
};
export default ___CSS_LOADER_EXPORT___;
