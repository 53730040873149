import type { ReactNode } from 'react'
import { Field } from 'react-final-form'

import { NullableString } from '../converters'
import { ErrorMessages, getFormError } from '../errors'
import InputUI from '../UI/Input'
import { composeValidators, isEmail, isRequired, Validator } from '../validators'

interface InputProps<FormValuesType> {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  hideErrorMessage?: boolean
  errorMessages?: ErrorMessages
  validate?: Validator<string | null, FormValuesType>
  className?: string
  tooltip?: ReactNode
}

export default function Input<FormValuesType = unknown>({
  name,
  label,
  placeholder,
  disabled,
  readOnly,
  required,
  hideErrorMessage,
  errorMessages,
  validate,
  className,
  tooltip,
}: InputProps<FormValuesType>): JSX.Element {
  return (
    <Field
      name={name}
      validate={composeValidators<string | null, FormValuesType>(
        required && isRequired,
        isEmail,
        validate,
      )}
      parse={NullableString.parse}
      format={NullableString.format}
      formatOnBlur
    >
      {({ meta, input }): JSX.Element => (
        <InputUI
          type="email"
          label={label}
          onChange={input.onChange}
          onBlur={input.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly as false}
          value={input.value}
          error={getFormError(meta, errorMessages, hideErrorMessage)}
          className={className}
          tooltip={tooltip}
        />
      )}
    </Field>
  )
}
