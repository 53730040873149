// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Concessions-module__tag--aafr0{margin:8px 8px 0 0}`, "",{"version":3,"sources":["webpack://./src/containers/Graveyard/sections/Misc/fields/Concessions/Concessions.module.scss"],"names":[],"mappings":"AAAA,gCACE,kBAAA","sourcesContent":[".tag {\n  margin: 8px 8px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `Concessions-module__tag--aafr0`
};
export default ___CSS_LOADER_EXPORT___;
