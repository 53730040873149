import { ApiRequestDescriptor } from '../../../lib/request'
import { del, get, post, put } from '../../../lib/methods'
import { authenticate, formdataEncoded } from '../../../lib/decorators'
import { SupportingDocumentOwnerType } from '../../../models/SupportingDocuments/OwnerType'
import { SupportingDocumentJSON } from '../../../models/SupportingDocuments/Version'
import { SupportingDocumentOwner } from '../../../models/SupportingDocuments/Owner'
import { serializeCreation, serializeUpdate } from './serializers'
import { NewSupportingDocument } from './types'

export interface SupportingDocumentFilters {
  document_type_id?: number
  owner_type?: SupportingDocumentOwnerType
  owner_id?: number
  current_version?: boolean
  start_dt?: Date
  end_dt?: Date
  page?: number
  per_page?: number
}

export class Versions {
  @authenticate
  static index(
    filters?: SupportingDocumentFilters,
  ): ApiRequestDescriptor<SupportingDocumentJSON[]> {
    return get('/api/v1/supporting_documents/versions', filters)
  }

  @authenticate
  @formdataEncoded
  static create(
    document: NewSupportingDocument,
    file: Blob,
    owner: SupportingDocumentOwner,
  ): ApiRequestDescriptor<SupportingDocumentJSON[]> {
    return post('/api/v1/supporting_documents/versions', serializeCreation(document, file, owner))
  }

  @authenticate
  static update(document: SupportingDocumentJSON): ApiRequestDescriptor<SupportingDocumentJSON> {
    return put(
      `/api/v1/supporting_documents/versions/${document.uploaded_document.id}`,
      serializeUpdate(document),
    )
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/supporting_documents/versions/${id}`)
  }
}
