import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import { AutocompleteResult } from '@advitam/api/v1/Autocompletes';

import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import { AppStateSubset as DealAppStateSubset } from 'containers/Deal/slice';
import { assert } from 'lib/support';

import { AppStateSubset as SendBatchAppStateSubset } from '../../slice';
import { makeSelectBatch } from '../../selectors';
import { SEND_BATCH_MODAL_DOCUMENTS } from './constants';

type AppStateSubset = DealAppStateSubset & SendBatchAppStateSubset;

export const createDocument = createAsyncThunk(
  `${SEND_BATCH_MODAL_DOCUMENTS}/CREATE`,
  async ({ id }: AutocompleteResult, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const batch = makeSelectBatch()(state);
    assert(batch !== null);
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      const {
        body: { batches },
      } = await request(
        Api.V1.Deals.Documents.Batches.AddDocument.create(deal.uuid, batch.id, [
          id,
        ]),
      );

      const updatedBatch = batches.find(b => b.id === batch.id);
      assert(updatedBatch !== undefined);

      const createdDocument = updatedBatch.documents.find(
        document => document.deal_document_id === id,
      );

      assert(createdDocument !== undefined);
      return createdDocument;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const destroyDocument = createAsyncThunk(
  `${SEND_BATCH_MODAL_DOCUMENTS}/DESTROY`,
  async (id: number, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const batch = makeSelectBatch()(state);
    assert(batch !== null);
    const deal = makeSelectDeal()(state);
    assert(deal?.uuid !== undefined);

    try {
      await request(
        Api.V1.Deals.Documents.Batches.RemoveDocument.destroy(
          deal.uuid,
          batch.id,
          [id],
        ),
      );

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
