import { FormattedMessage, FormattedDate as ReactIntlFormattedDate } from 'react-intl'
import messages from './messages'

interface FormattedDateProps {
  value: Date
}

export default function FormattedDate({ value }: FormattedDateProps): JSX.Element {
  return (
    <FormattedMessage
      id={messages.formattedDate.id}
      values={{
        date: <ReactIntlFormattedDate dateStyle="short" value={value} />,
        time: <ReactIntlFormattedDate timeStyle="short" value={value} />,
      }}
    />
  )
}
