import type { FormApi } from 'final-form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'

import { useThunkDispatch } from '@advitam/react'
import { assert } from '@advitam/support'
import { Input, SubmitButton } from '@advitam/ui'
import Send from '@advitam/ui/images/icons/send.svg'

import type { ChatbotForm } from './types'
import messages from './messages'
import style from './Conversation.module.scss'
import { askQuestion } from '../thunk'
import MessageFeed from './MessageFeed'
import { ChatWebsocket } from './ChatWebsocket'
import {
  makeSelectConversationUuid,
  makeSelectHasUserMessage,
  makeSelectIsAnswerLoading,
  makeSelectLastMessageLength,
} from '../selectors'
import type { ChatbotAnswerSource } from '../types'

interface ChatFeedProps {
  addSources?: (sources: ChatbotAnswerSource[]) => void
  initialQuestion?: string
  showInitialQuestion?: boolean
}

export default function ChatFeed({
  addSources,
  initialQuestion,
  showInitialQuestion,
}: ChatFeedProps): JSX.Element {
  const dispatch = useThunkDispatch()
  const intl = useIntl()

  const conversationUuid = useSelector(makeSelectConversationUuid())
  const hasUserMessage = useSelector(makeSelectHasUserMessage())
  const isAnswerLoading = useSelector(makeSelectIsAnswerLoading())
  const lastMessageLength = useSelector(makeSelectLastMessageLength())

  const messagesRef = useRef<HTMLDivElement | null>(null)
  const [websocket, setWebsocket] = useState<ChatWebsocket | null>(null)

  useEffect(() => {
    if (conversationUuid) {
      if (hasUserMessage) {
        return
      }

      const ws = new ChatWebsocket(conversationUuid, dispatch, addSources)
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      ws.create().then(() => {
        setWebsocket(ws)
      })
    } else {
      websocket?.close()
      setWebsocket(null)
    }
  }, [conversationUuid, initialQuestion])

  useEffect(() => {
    if (!websocket || !initialQuestion || hasUserMessage) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(
      askQuestion({
        question: initialQuestion,
        showQuestion: showInitialQuestion,
        websocket,
      }),
    )
  }, [websocket, initialQuestion])

  const scrollToBottom = useCallback(
    (behavior?: 'smooth') => {
      assert(messagesRef.current !== null)
      messagesRef.current.scrollTo({ top: messagesRef.current.scrollHeight, behavior })
    },
    [messagesRef],
  )

  const onAskQuestion = useCallback(
    (question: string) => {
      assert(websocket !== null)
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(askQuestion({ question, websocket }))
    },
    [dispatch, websocket],
  )

  const onSubmit = useCallback(
    (values: ChatbotForm, formApi: FormApi<ChatbotForm>) => {
      onAskQuestion(values.question)
      formApi.reset()
    },
    [onAskQuestion],
  )

  useEffect(() => {
    scrollToBottom('smooth')
  }, [lastMessageLength, scrollToBottom])

  useEffect(() => {
    scrollToBottom()

    return (): void => {
      websocket?.close()
    }
  }, [])

  return (
    <>
      <div className={`thin-scrollbar ${style.messages}`} ref={messagesRef}>
        <MessageFeed onAskQuestion={onAskQuestion} isReady={websocket !== null} />
      </div>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, values }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <Input name="question" placeholder={intl.formatMessage(messages.placeholder)} />
            <SubmitButton
              primary
              icon={<Send />}
              disabled={!values.question || isAnswerLoading || !conversationUuid || !websocket}
            />
          </form>
        )}
      </Form>
    </>
  )
}
