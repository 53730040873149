import { Strings } from '@advitam/support'

export function formatIban(iban: string): string {
  return Strings.chunk(iban, 4).join(' ')
}

interface FormattedIbanProps {
  iban: string
}

export function FormattedIban({ iban }: FormattedIbanProps): JSX.Element {
  return <>{formatIban(iban)}</>
}
