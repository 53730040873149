// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input-module__input--GRggP{margin:0 3px;padding:2px 5px;border:1px solid #aaa;border-radius:3px;color:#333;background-color:#fafafa}.Input-module__select--iyAmY{padding:2px 5px;color:#000;border:1px solid #aaa;border-radius:3px;background-color:#fafafa;cursor:pointer}.Input-module__bullet_list--PflQw{display:flex;flex-direction:column}.Input-module__bullet_list--PflQw.Input-module__inline--G1rco{justify-content:space-between;flex-direction:row}.Input-module__bullet_list__item--HNJ39{margin-right:16px}`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,eAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA,CACA,wBAAA,CAGF,6BACE,eAAA,CACA,UAAA,CACA,qBAAA,CACA,iBAAA,CACA,wBAAA,CACA,cAAA,CAGF,kCACE,YAAA,CACA,qBAAA,CAEA,8DACE,6BAAA,CACA,kBAAA,CAIJ,wCACE,iBAAA","sourcesContent":[".input {\n  margin: 0 3px;\n  padding: 2px 5px;\n  border: 1px solid #aaa;\n  border-radius: 3px;\n  color: #333;\n  background-color: #fafafa;\n}\n\n.select {\n  padding: 2px 5px;\n  color: black;\n  border: 1px solid #aaa;\n  border-radius: 3px;\n  background-color: #fafafa;\n  cursor: pointer;\n}\n\n.bullet_list {\n  display: flex;\n  flex-direction: column;\n\n  &.inline {\n    justify-content: space-between;\n    flex-direction: row;\n  }\n}\n\n.bullet_list__item {\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Input-module__input--GRggP`,
	"select": `Input-module__select--iyAmY`,
	"bullet_list": `Input-module__bullet_list--PflQw`,
	"inline": `Input-module__inline--G1rco`,
	"bullet_list__item": `Input-module__bullet_list__item--HNJ39`
};
export default ___CSS_LOADER_EXPORT___;
