import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { GradedDealReviewJSON } from '@advitam/api/models/Deal/GradedReview';

import { DEAL_REVIEWS } from './constants';
import { fetchReviews } from './thunk';

export interface State {
  reviews: GradedDealReviewJSON[];
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DEAL_REVIEWS]: State;
}

const initialState: State = {
  reviews: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: DEAL_REVIEWS,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clear(state) {
      state.reviews = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchReviews.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchReviews.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.reviews = payload;
    });
    builder.addCase(fetchReviews.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { clear } = slice.actions;
export default slice;
