import { UNKNOWN } from 'containers/Deal/DealFuneral/StepsSection/StepEditors/Interment/constants';
import { Person } from 'models/Person/index.ts';
import { RepatriationType } from 'models/RepatriationType';

export const SUPPLIER_WAREHOUSE = 'SupplierWarehouse';

export const stepSkeletons = {
  death: {
    eventType: 'death',
    location: {
      type: 'HealthFacility',
    },
  },
  casketing: {
    eventType: 'casketing',
    location: {
      type: 'HealthFacility',
      sameAsPreviousStep: true,
    },
  },
  closing: {
    eventType: 'closing',
    location: {
      type: 'HealthFacility',
      sameAsPreviousStep: true,
    },
  },
  deposit: {
    eventType: 'deposit',
    location: {
      type: 'FuneralParlor',
      autoFind: true,
    },
  },
  ceremony: {
    eventType: 'ceremony',
    location: {
      type: 'Worship',
    },
  },
  interment: {
    eventType: 'interment',
    location: {
      type: 'Graveyard',
    },
    concession: {
      buyer: {},
      rightHolders: [],
      places: { remaining: 1, total: 1 },
      burieds: [],
      type: { name: UNKNOWN, info: {} },
      address: {},
    },
  },
  cremation: {
    eventType: 'cremation',
    location: {
      type: 'Crematorium',
      autoFind: true,
    },
    ceremony: null,
    convivialityRoomUsage: null,
  },
  repatriation: {
    eventType: 'repatriation',
    repatriation_type: RepatriationType.PLANE,
    location: {
      type: SUPPLIER_WAREHOUSE,
    },
    destination: {},
    trip: {
      contact: {
        isCompany: true,
        infoCompany: {},
        infoCivilian: {
          type: Person.Civility.MR,
        },
      },
      flights: [],
    },
  },
  urnPickup: {
    eventType: 'urnPickup',
    foreignLand: false,
    location: {
      sameAsPreviousStep: true,
    },
    to: 'family',
  },
  dispersion: {
    eventType: 'dispersion',
    location: {
      sameAsPreviousStep: true,
    },
  },
  urnSealing: {
    eventType: 'urnSealing',
    location: {
      type: 'Graveyard',
    },
    concession: {
      buyer: {},
      rightHolders: [],
      places: { remaining: 1, total: 1 },
      burieds: [],
      type: { name: UNKNOWN, info: {} },
      address: {},
    },
  },
};

const commonSkeleton = [
  {
    id: 0,
    ...stepSkeletons.death,
  },
  {
    id: 1,
    ...stepSkeletons.casketing,
  },
  {
    id: 2,
    ...stepSkeletons.closing,
    withFamily: true,
  },
];

const dealSkeletons = {
  interment: [
    ...commonSkeleton,
    {
      id: 3,
      ...stepSkeletons.interment,
    },
  ],
  cremation: [
    ...commonSkeleton,
    {
      id: 3,
      ...stepSkeletons.cremation,
    },
    {
      id: 4,
      ...stepSkeletons.urnPickup,
    },
  ],
  repatriation: [
    ...commonSkeleton,
    {
      id: 3,
      ...stepSkeletons.repatriation,
    },
  ],
  casketing: [
    {
      id: 0,
      ...stepSkeletons.death,
    },
    {
      id: 1,
      ...stepSkeletons.casketing,
      doneByAnotherFuneral: true,
    },
  ],
  custom: [],
};

export default function getSkeleton(skeletonType) {
  return dealSkeletons[skeletonType];
}
