import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwipeableProps, useSwipeable } from 'react-swipeable';
import { FormattedMessage } from 'react-intl';

import { useIsOnline } from '@advitam/react';
import { FlatButton, Text } from '@advitam/ui';
import { useCable } from 'lib/reactvitam/redux';

import cable from './cable';
import NotificationList from './NotificationList';
import {
  makeSelectIsInitialized,
  makeSelectIsNotificationPanelOpen,
  makeSelectUnreadNotificationCount,
} from './selectors';
import { setIsNotificationPanelOpen } from './slice';
import style from './NotificationCenter.module.scss';
import messages from './messages';
import {
  fetchUnreadNotificationCount,
  markAllAsRead,
  reconnected,
} from './thunk';
import { isSupported } from './utils';

export default function NotificationCenter(): JSX.Element {
  const dispatch = useDispatch();
  const isPanelOpen = useSelector(makeSelectIsNotificationPanelOpen());
  const unreadCount = useSelector(makeSelectUnreadNotificationCount());
  const isInitialized = useSelector(makeSelectIsInitialized());
  const isOnline = useIsOnline();

  const closePanel = useCallback((): void => {
    dispatch(setIsNotificationPanelOpen(false));
  }, [dispatch]);
  const onMarkAllAsRead = useCallback((): void => {
    dispatch(markAllAsRead());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUnreadNotificationCount());
    if (isSupported()) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Notification.requestPermission();
    }
  }, [dispatch]);

  useEffect(() => {
    if (isInitialized && isOnline) {
      dispatch(reconnected());
    }
  }, [isOnline]);

  useCable(cable, null);

  const swipeProps: SwipeableProps = {
    preventScrollOnSwipe: true,
    onSwipedRight: closePanel,
  };
  const handleBackdropSwipe = useSwipeable(swipeProps);
  const handleSwipe = useSwipeable(swipeProps);

  return (
    <>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...handleBackdropSwipe}
        onClick={closePanel}
        className={`${style.backdrop} ${isPanelOpen ? '' : style.closed}`}
        tabIndex={0}
        role="button"
        onKeyDown={undefined}
      />
      <aside
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...handleSwipe}
        className={`${style.container} ${isPanelOpen ? '' : style.closed}`}
      >
        <div className={style.header}>
          <Text>
            <FormattedMessage id={messages.title.id} tagName="b" />
          </Text>
          {unreadCount > 0 && (
            <FlatButton
              onClick={onMarkAllAsRead}
              className={style.mark_all_as_read}
            >
              <Text small>
                <FormattedMessage id={messages.markAllAsRead.id} />
              </Text>
            </FlatButton>
          )}
        </div>
        <NotificationList />
      </aside>
    </>
  );
}
