import { post } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { IngenicoResponse } from '../../../models/Ingenico/Response'

export interface CallbackCreationPayload {
  deal_uuid: string
}

export interface CallbackCreationError {
  deal_uuid: string
  errors: string[]
}

export const Callback = {
  create: (payload: IngenicoResponse): ApiRequestDescriptor<CallbackCreationPayload> =>
    post('/api/v1/payments/ingenico/callback', payload),
}
