import { getResizeObserver } from './ResizeObserver'
import { getStorage } from './storage'
import { unsafeContext } from './unsafeContext'

export const Browsers = {
  getResizeObserver,
  get context(): ReturnType<typeof unsafeContext> {
    return unsafeContext()
  },
  get storage(): ReturnType<typeof getStorage> {
    return getStorage()
  },
}
