import { DocumentPageRotation } from '@advitam/api/models/DocumentEditions';
import { DocumentEdition, DocumentEditionPage, RotateDirection } from './types';

const ROTATE_DIRECTION_MAP: Record<
  DocumentPageRotation,
  Record<RotateDirection, DocumentPageRotation>
> = {
  0: {
    [RotateDirection.RIGHT]: 90,
    [RotateDirection.LEFT]: 270,
  },
  90: {
    [RotateDirection.RIGHT]: 180,
    [RotateDirection.LEFT]: 0,
  },
  180: {
    [RotateDirection.RIGHT]: 270,
    [RotateDirection.LEFT]: 90,
  },
  270: {
    [RotateDirection.RIGHT]: 0,
    [RotateDirection.LEFT]: 180,
  },
};

export function rotatePage(
  page: DocumentEditionPage,
  direction: RotateDirection,
): DocumentEditionPage {
  const nextRotation = ROTATE_DIRECTION_MAP[page.rotate || 0][direction];
  return { ...page, rotate: nextRotation };
}

export function rotateDocuments(
  documents: DocumentEdition[],
  direction: RotateDirection,
): DocumentEdition[] {
  return documents.map(document => ({
    ...document,
    pages: document.pages.map(page => rotatePage(page, direction)),
  }));
}
