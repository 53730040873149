// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__dropdown--huOqR{position:relative;outline:none}.style__input--BigxW{display:flex;flex-direction:row;justify-content:space-between;cursor:pointer}.style__input--BigxW>div{-webkit-user-select:none;-moz-user-select:none;user-select:none}.style__placeholder--qovH5{flex-grow:1}.style__drop-icon--haCGc{font-size:1.5em}.style__children-wrapper--a7eWG{position:absolute;top:100%;left:0;z-index:5;min-width:100%;width:-moz-max-content;width:max-content;box-shadow:0 6px 6px 3px rgba(0,0,0,.25)}.style__contained--uiEMD{right:0;width:unset}`, "",{"version":3,"sources":["webpack://./src/components/Dropdown/style.scss","webpack://./../ui/scss/_z_index.scss"],"names":[],"mappings":"AAEA,wBACE,iBAAA,CACA,YAAA,CAGF,qBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,cAAA,CAEA,yBACE,wBAAA,CAAA,qBAAA,CAAA,gBAAA,CAIJ,2BACE,WAAA,CAGF,yBACE,eAAA,CAGF,gCACE,iBAAA,CACA,QAAA,CACA,MAAA,CACA,SC3BkB,CD4BlB,cAAA,CACA,sBAAA,CAAA,iBAAA,CACA,wCAAA,CAGF,yBACE,OAAA,CACA,WAAA","sourcesContent":["@import \"@advitam/ui/scss/z_index\";\n\n.dropdown {\n  position: relative;\n  outline: none;\n}\n\n.input {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  cursor: pointer;\n\n  > div {\n    user-select: none;\n  }\n}\n\n.placeholder {\n  flex-grow: 1;\n}\n\n.drop-icon {\n  font-size: 1.5em;\n}\n\n.children-wrapper {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  z-index: $z-index__dropdown;\n  min-width: 100%;\n  width: max-content;\n  box-shadow: 0 6px 6px 3px rgba(0, 0, 0, 0.25);\n}\n\n.contained {\n  right: 0;\n  width: unset;\n}\n","$z-index__background: -1;\n$z-index__content: 0;\n$z-index__content--over: $z-index__content + 1;\n$z-index__dropdown: 5;\n$z-index__navbar: 10;\n$z-index__popup: $z-index__navbar + 5;\n$z-index__modal: $z-index__popup + 5;\n$z-index__foreground: $z-index__modal + 5;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `style__dropdown--huOqR`,
	"input": `style__input--BigxW`,
	"placeholder": `style__placeholder--qovH5`,
	"drop-icon": `style__drop-icon--haCGc`,
	"children-wrapper": `style__children-wrapper--a7eWG`,
	"contained": `style__contained--uiEMD`
};
export default ___CSS_LOADER_EXPORT___;
