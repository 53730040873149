import { authenticate } from '../../lib/decorators'
import { post } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'

export class Archive {
  @authenticate
  static create(admProcedureId: number): ApiRequestDescriptor<void> {
    return post(`/api/v1/administrative_procedures/${admProcedureId}/archive`)
  }
}
