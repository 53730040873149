import { createSelector } from 'reselect';

const selectConfirmModalDomain = state => state.confirmModal;

const makeSelectIsOpen = () =>
  createSelector(selectConfirmModalDomain, substate => substate.isOpen);

const makeSelectDeleteFunction = () =>
  createSelector(selectConfirmModalDomain, substate => substate.deleteFunction);

const makeSelectMessage = () =>
  createSelector(selectConfirmModalDomain, substate => substate.message);

export {
  selectConfirmModalDomain,
  makeSelectDeleteFunction,
  makeSelectIsOpen,
  makeSelectMessage,
};
