import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { deburr } from 'lodash';

import Dropdown from 'components/Dropdown';
import { TransparentButton } from 'components/Button/index.js';

import messages from './messages';

import style from './style.scss';

export type Value = number | string | boolean;

export interface SelectableItem {
  value: Value;
  name: string;
}

interface SelectProps {
  items: SelectableItem[];
  initialValue?: Value | Value[];
  placeholder: string;
  onChange: (value?: Value | Value[]) => void;
  multiple: boolean;
  filter: boolean;
  unselectable?: boolean;
}

export default function Select({
  items,
  initialValue,
  onChange,
  placeholder,
  multiple,
  filter,
  unselectable,
}: SelectProps): JSX.Element {
  const [value, setValue] = useState<Value | Value[] | undefined>(initialValue);
  const [filterText, setFilterText] = useState('');

  const onSelect = (item: SelectableItem): void => {
    let newValue;

    if (multiple) {
      const currentValue = value as Value[] | undefined;

      if (!currentValue) {
        newValue = [item.value];
      } else if (currentValue.includes(item.value)) {
        newValue = currentValue.filter(v => v !== item.value);
      } else {
        newValue = [...currentValue, item.value];
      }
    } else if (!unselectable || value !== item.value) {
      newValue = item.value;
    }

    setValue(newValue);
    onChange(newValue);
  };

  const isSelected = (v: Value): boolean =>
    multiple
      ? value !== undefined && (value as Value[]).includes(v)
      : value === v;

  const selectAll = (): void => setValue(items.map(item => item.value));
  const deselectAll = (): void => setValue([]);

  const normalizeSearch = (text: string): string => deburr(text.toLowerCase());

  const renderFilter = (): JSX.Element | null => {
    if (!filter) {
      return null;
    }

    return (
      <div className={style.filter}>
        {multiple && (
          <>
            <button type="button" onClick={selectAll}>
              <FormattedMessage {...messages.checkAll} />
            </button>
            <button type="button" onClick={deselectAll}>
              <FormattedMessage {...messages.uncheckAll} />
            </button>
          </>
        )}
        <input
          value={filterText}
          onChange={({ target: { value: text } }): void =>
            setFilterText(normalizeSearch(text))
          }
        />
        <i className="material-icons">search</i>
      </div>
    );
  };

  const isFiltered = (item: SelectableItem): boolean => {
    if (!filter || filterText === '') {
      return false;
    }

    return !normalizeSearch(item.name).includes(filterText);
  };

  const renderItem = (item: SelectableItem): JSX.Element | null => {
    if (isFiltered(item)) {
      return null;
    }

    return (
      <li
        key={item.value.toString()}
        className={[
          style.elem,
          isSelected(item.value) ? style['elem--selected'] : '',
        ].join(' ')}
      >
        <TransparentButton
          onClick={(event: Event): void => {
            onSelect(item);
            event.preventDefault();
          }}
        >
          {item.name}
        </TransparentButton>
      </li>
    );
  };

  return (
    <Dropdown placeholder={placeholder} closeOnClick={!multiple}>
      {renderFilter()}
      <ul className={style.select}>{items.map(item => renderItem(item))}</ul>
    </Dropdown>
  );
}

Select.defaultProps = {
  multiple: false,
  filter: false,
};

const valuePropTypes = [PropTypes.number, PropTypes.string, PropTypes.bool];

Select.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  initialValue: PropTypes.oneOfType([
    ...valuePropTypes,
    PropTypes.arrayOf(PropTypes.oneOfType(valuePropTypes)),
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  filter: PropTypes.bool,
};
