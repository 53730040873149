import { ClientRole } from 'models/Client';
import { Model } from 'models/Model';
import {
  ClientResourceDeal as ResourceDeal,
  ClientResourceDealJSON as ResourceDealJSON,
} from './Resource/Deal';
import {
  ClientResourceMemorial as ResourceMemorial,
  ClientResourceMemorialJSON as ResourceMemorialJSON,
} from './Resource/Memorial';
import {
  ClientResourceAdministrativeProcedure as ResourceAdministrativeProcedure,
  ClientResourceAdministrativeProcedureJSON as ResourceAdministrativeProcedureJSON,
} from './Resource/AdministrativeProcedure';
import {
  ClientResourceFuneralWill,
  ClientResourceFuneralWillJSON,
} from './Resource/FuneralWill';
import { ClientResource, ClientResourceJSON } from './Resource';

export enum ResourceType {
  ADMINISTRATIVE_PROCEDURE = 'AdministrativeProcedure',
  DEAL = 'Deal',
  FUNERAL_WILL = 'FuneralWill',
  MEMORIAL = 'Memorial',
  SAFE_KEEPER = 'SafeKeeper',
}

export interface ClientAbilityBase {
  id: number;
  role: ClientRole;
  special: boolean;
  link: string | null;
  resource_type: ResourceType;
}

export type ClientAbilityJSON = ClientAbilityBase &
  (
    | {
        resource_type: ResourceType.DEAL;
        resource: ResourceDealJSON;
      }
    | {
        resource_type: ResourceType.MEMORIAL;
        resource: ResourceMemorialJSON;
      }
    | {
        resource_type: ResourceType.ADMINISTRATIVE_PROCEDURE;
        resource: ResourceAdministrativeProcedureJSON;
      }
    | {
        resource_type: ResourceType.FUNERAL_WILL;
        resource: ClientResourceFuneralWillJSON;
      }
    | {
        resource_type: ResourceType.SAFE_KEEPER;
        resource: ClientResourceJSON;
      }
  );

export type Resource =
  | ResourceDeal
  | ResourceMemorial
  | ResourceAdministrativeProcedure
  | ClientResource;

export interface ClientAbility extends ClientAbilityBase {
  resource: Resource;
}

export class ClientAbility extends Model<ClientAbilityJSON> {
  constructor(data: ClientAbilityJSON) {
    super(data);

    if (data.resource) {
      switch (data.resource_type) {
        case ResourceType.DEAL:
          this.resource = new ResourceDeal(data.resource);
          break;
        case ResourceType.MEMORIAL:
          this.resource = new ResourceMemorial(data.resource);
          break;
        case ResourceType.ADMINISTRATIVE_PROCEDURE:
          this.resource = new ResourceAdministrativeProcedure(data.resource);
          break;
        case ResourceType.FUNERAL_WILL:
          this.resource = new ClientResourceFuneralWill(data.resource);
          break;
        default:
          this.resource = new ClientResource(data.resource);
          break;
      }
    }
  }
}
