import { BillingType } from '@advitam/api/models/BillingType';
import type { NewHospital } from './types';

export const HOSPITAL = 'Hospital';

export const Path = {
  GENERAL: '',
  MISCELLANEOUS: 'divers',
};

export const HOSPITAL_SKELETON: NewHospital = {
  name: '',
  disabled: false,
  address: null,
  address_l2: null,
  postal_code: null,
  insee_code: null,
  city: null,
  department: null,
  country: null,
  latitude: null,
  longitude: null,
  manual_address: false,
  phone: null,
  email: null,
  prefered_contact_media: null,
  opening_hours: null,
  fax: null,
  phone_2: null,
  phone_3: null,
  email_2: null,
  email_3: null,
  email_4: null,
  comment: null,
  current_update_user_id: null,
  last_update_user_id: null,
  billing_type: BillingType.DEAL,
  cheque_order: null,
  cheque_address: null,
  rib: null,
  iban: null,
  vat: null,
  checkout_type: null,
  authentic_document_needed: null,
  siret: null,
  headquarter_name: null,
  headquarter_address: null,
  headquarter_postal_code: null,
  headquarter_country: null,
  headquarter_city: null,
  website: null,
  cleaning: null,
  casketing: null,
  supply_shroud: null,
  coffin_delivery: null,
  paper_hold: null,
  nb_holders: null,
  mortuary: null,
  agreement_location: null,
  casketing_fee: null,
  lab_fee: null,
  base_fee: null,
  base_days: null,
  cost_per_day: null,
  tva_rate: null,
  base_fee_family: null,
  base_days_family: null,
  cost_per_day_family: null,
  current_update_dt: null,
  last_update_dt: null,
};
