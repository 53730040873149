import { DateTime } from 'luxon';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useInterval } from '@advitam/react';
import { HardSpinner, Modal, ModalTitle, Spinner, Text } from '@advitam/ui';

import { makeSelectLoadingStartTime } from '../selectors.js';
import { makeSelectIsSaving } from './selectors';
import messages from './messages';
import style from './SavingLayer.module.scss';

export default function SavingLayer(): JSX.Element | null {
  const [isSavingSlow, setIsSavingSlow] = useState(false);

  const isSaving = useSelector(makeSelectIsSaving());
  const savingStartTime = useSelector(makeSelectLoadingStartTime());

  useInterval(
    () => {
      const isSlow =
        isSaving &&
        savingStartTime &&
        DateTime.now().diff(DateTime.fromISO(savingStartTime), 'seconds')
          .seconds > 60;
      setIsSavingSlow(isSlow);
    },
    1000,
    [isSaving, savingStartTime, setIsSavingSlow],
  );

  if (!isSaving) {
    return null;
  }

  if (!isSavingSlow) {
    return <HardSpinner />;
  }

  return (
    <Modal isOpen className={`new-design col-6 ${style.modal}`}>
      <ModalTitle>
        <FormattedMessage id={messages.slowSavingTitle.id} />
      </ModalTitle>
      <Text>
        <FormattedMessage id={messages.slowSavingText.id} />
      </Text>
      <div className={style.spinner}>
        <Spinner />
      </div>
    </Modal>
  );
}
