import { ChangeEvent } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Tippy from '@tippyjs/react';

import style from './style.scss';

export interface SwitchElement {
  value: boolean;
}

function buildFakeEvent(value: boolean): ChangeEvent<SwitchElement> {
  return { target: { value } } as ChangeEvent<SwitchElement>;
}

interface SwitchInputProps {
  name?: string;
  message?: MessageDescriptor;
  tooltipMessage?: MessageDescriptor;
  onChange?: (ev: ChangeEvent<SwitchElement>) => void;
  value: boolean;
  className?: string;
}

export default function SwitchInput({
  className = '',
  message,
  tooltipMessage,
  onChange,
  value,
  name,
}: SwitchInputProps): JSX.Element {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={[style.container, className].join(' ')}>
      <input
        name={name}
        type="checkbox"
        onChange={(_ev): void => {
          if (onChange) {
            onChange(buildFakeEvent(!value));
          }
        }}
        checked={value || false}
      />
      {message && (
        <span>
          <FormattedMessage {...message} />
        </span>
      )}
      {tooltipMessage && (
        <Tippy content={<FormattedMessage {...tooltipMessage} />}>
          <span className="input__message--tooltip" />
        </Tippy>
      )}
      <span className={style.switch} />
    </label>
  );
}
