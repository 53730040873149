import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { EditableDocumentJSON } from '@advitam/api/models/Documents/Editable';
import { PolymorphicEntity } from '@advitam/api/models/PolymorphicEntity';
import { LocationType } from '@advitam/api/models/LocationType';
import Layout from 'containers/Crud/Layout';
import {
  EditableDocuments,
  editableDocumentsSlice,
  makeSelectEditableDocuments,
  saveEditableDocuments,
} from 'containers/Crud';
import { assert } from 'lib/Assert';

import Wrapper from './Wrapper';
import { makeSelectRawFuneralParlor } from '../selectors';
import { FuneralParlorForm } from './types';

function EditableDocumentsSection(): JSX.Element {
  const dispatch = useDispatch();

  const funeralParlor = useSelector(makeSelectRawFuneralParlor());
  assert(funeralParlor?.id !== undefined);
  const documents = useSelector(makeSelectEditableDocuments());

  const entity: PolymorphicEntity = {
    type: LocationType.FUNERAL_PARLOR,
    id: funeralParlor.id,
  };
  const onSubmit = useCallback(
    ({ sectionValues }: FuneralParlorForm<EditableDocumentJSON[]>) => {
      dispatch(saveEditableDocuments({ values: sectionValues, entity }));
    },
    [entity.type, entity.id],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={documents}>
      <Layout.Container>
        <EditableDocuments
          entity={entity}
          entityRules={[{ name: '', value: 'id-funeral_parlor' }]}
        />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(editableDocumentsSlice)(EditableDocumentsSection);
