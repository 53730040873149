import { ReactNode, useCallback } from 'react'

import Api from '@advitam/api'
import { getTokens } from '@advitam/api/lib/tokens'
import type { AutocompleteCountriesResult } from '@advitam/api/v1/Autocompletes'
import type { Country } from '@advitam/api/models/Country'

import ApiAutocomplete from './ApiAutocomplete'

interface CountryAutocompleteProps {
  placeholder?: string
  required?: boolean
  label?: ReactNode
  tooltip?: ReactNode
  name: string
  className?: string
  searchParams?: Record<string, string>
  disabled?: boolean
}

export default function CountryAutocomplete({
  placeholder,
  name,
  label,
  tooltip,
  required,
  searchParams,
  disabled,
  className,
}: CountryAutocompleteProps): JSX.Element {
  const getDisplayValue = useCallback(
    (result: AutocompleteCountriesResult | Country): string =>
      `${result.name} (${
        (result as AutocompleteCountriesResult).country_code || (result as Country).code || ''
      })`,
    [],
  )

  return (
    <ApiAutocomplete<AutocompleteCountriesResult>
      placeholder={placeholder}
      name={name}
      label={label}
      tooltip={tooltip}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.countries)}
      required={required}
      className={className}
      disabled={disabled}
      getDisplayValue={getDisplayValue}
      keys={['country_code', 'name']}
      transformKeys={{ country_code: 'code' }}
      requestHeaders={{ ...getTokens() }}
      searchParams={searchParams}
      minCharsRequest={2}
    />
  )
}
