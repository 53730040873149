import { ErrorCode } from '../errors'
import { ValidatorReturnType } from './Validator'

const PASSWORD_MIN_LENGTH = 8

export function isPasswordValid(value: string | null): ValidatorReturnType {
  const re = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{${PASSWORD_MIN_LENGTH},}$`, 'g')

  if (value && !re.test(value)) {
    return ErrorCode.PASSWORD_INVALID
  }

  return undefined
}
