import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { Civility } from '@advitam/api/models/Person/Civility';
import {
  DateOnlyInput,
  FormLayout,
  Input,
  Select,
} from '@advitam/ui/components/Form';
import { Layout } from 'containers/Crud';
import actionMessages from 'messages/actions';
import { assert } from 'lib/support';

import { makeSelectRawClient } from '../../selectors';
import style from '../../Client.module.scss';
import messages from './messages';

function p(chunk: ReactNode): ReactNode {
  return <p className={style.tooltip_p}>{chunk}</p>;
}

export default function Informations(): JSX.Element {
  const intl = useIntl();

  const client = useSelector(makeSelectRawClient());
  assert(client !== null);

  // The act of death is uploaded before the client update
  const isDefunctValid = client.firstname && client.lastname;

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.informations.id} />}>
      <FormLayout.Row>
        <Select
          name="client.civility"
          label={<FormattedMessage id={messages.civility.id} />}
          unselectable
          items={[
            {
              value: Civility.MR,
              name: intl.formatMessage(messages.mr),
            },
            {
              value: Civility.MRS,
              name: intl.formatMessage(messages.mrs),
            },
          ]}
        />
        <Input
          name="client.firstname"
          label={<FormattedMessage id={messages.firstname.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          name="client.lastname"
          label={<FormattedMessage id={messages.lastname.id} />}
        />
        <Input
          name="client.birth_name"
          label={<FormattedMessage id={messages.birthName.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <DateOnlyInput
          name="client.birth_date"
          label={<FormattedMessage id={messages.birthDate.id} />}
        />
        <Input
          name="client.birth_location"
          label={<FormattedMessage id={messages.birthPlace.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          name="client.nationality"
          label={<FormattedMessage id={messages.nationality.id} />}
        />
        <Input
          name="client.work"
          label={<FormattedMessage id={messages.work.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          name="client.deceased"
          label={<FormattedMessage id={messages.deceased.id} />}
          tooltip={
            <FormattedMessage id={messages.deceasedTooltip.id} values={{ p }} />
          }
          items={[
            { value: true, name: intl.formatMessage(actionMessages.yes) },
            { value: false, name: intl.formatMessage(actionMessages.no) },
          ]}
          disabled={client.deceased || !isDefunctValid}
        />
        {client.deceased ? (
          <DateOnlyInput
            name="client.death_date"
            label={<FormattedMessage id={messages.deathDate.id} />}
          />
        ) : (
          <div />
        )}
      </FormLayout.Row>
    </Layout.Fieldset>
  );
}
