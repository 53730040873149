import { createSelector, Selector } from 'reselect'

import type { SerializedApiError } from '@advitam/api'
import { Arrays } from '@advitam/support'

import { PROF } from './constants'
import type { AppStateSubset, State } from './slice'
import type { Message } from './types'

type ProfSelector<T> = Selector<AppStateSubset, T>

const selectProfDomain = (state: AppStateSubset): State => state[PROF]

export const makeSelectConversationUuid = (): ProfSelector<string | null> =>
  createSelector(selectProfDomain, state => state.conversationUuid)

export const makeSelectMessages = (): ProfSelector<Message[]> =>
  createSelector(selectProfDomain, state => state.messages)

export const makeSelectHasUserMessage = (): ProfSelector<boolean> =>
  createSelector(selectProfDomain, state => state.hasUserMessage)

export const makeSelectQuestionSuggestions = (): ProfSelector<string[]> =>
  createSelector(selectProfDomain, state => state.questionSuggestions)

export const makeSelectLastMessageLength = (): ProfSelector<number> =>
  createSelector(makeSelectMessages(), messages => {
    const lastMessage = Arrays.last(messages)
    if (!lastMessage) {
      return 0
    }
    return lastMessage.message.answer.length + lastMessage.message.sources.length
  })

export const makeSelectError = (): ProfSelector<SerializedApiError | null> =>
  createSelector(selectProfDomain, state => state.error)

/** Whether an answer is loading (i.e. there are more tokens to fetch) */
export const makeSelectIsAnswerLoading = (): ProfSelector<boolean> =>
  createSelector(selectProfDomain, state => state?.isAnswerLoading || false)

const makeSelectIsTokenLoading = (): ProfSelector<boolean> =>
  createSelector(selectProfDomain, state => state.isTokenLoading)

/** Whether a new message is loading (i.e. a new one will have to be displayed) */
export const makeSelectIsMessageLoading = (): ProfSelector<boolean> =>
  createSelector(
    makeSelectIsAnswerLoading(),
    makeSelectIsTokenLoading(),
    (isAnswerLoading, isTokenLoading) => isAnswerLoading && !isTokenLoading,
  )

const makeSelectLoadingFeedbacks = (): ProfSelector<string[]> =>
  createSelector(selectProfDomain, state => state.loadingFeedbacks)

export const makeSelectIsFeedbackLoading = (message: Message): ProfSelector<boolean> =>
  createSelector(makeSelectLoadingFeedbacks(), loadingFeedbacks =>
    loadingFeedbacks.includes(message.message.runId || ''),
  )
