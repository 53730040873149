import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import DocumentTemplateEditor from 'containers/DocumentTemplateEditor';
import {
  setDictionary,
  setFile,
} from 'containers/DocumentTemplateEditor/slice';
import HardSpinner from 'components/HardSpinner';
import { FullscreenModal } from 'components/Modal';
import ErrorModal from 'components/ErrorModal';

import { initialFetch, generateDocument } from './thunk';
import {
  makeSelectIsGenerationLoading,
  makeSelectVariables,
  makeSelectDocumentsGenerationQueue,
  makeSelectDictionary,
  makeSelectError,
} from './selectors';
import { DEAL_DOCUMENT } from './constants';
import slice, { resetState } from './slice';

interface DocumentGenerationModalProps {
  dealId: number;
}

function DocumentGenerationModal({
  dealId,
}: DocumentGenerationModalProps): JSX.Element | null {
  const dispatch = useDispatch();

  const documentsGenerationQueue = useSelector(
    makeSelectDocumentsGenerationQueue(),
  );
  const variables = useSelector(makeSelectVariables());
  const dictionary = useSelector(makeSelectDictionary());
  const isGenerationLoading = useSelector(makeSelectIsGenerationLoading());
  const error = useSelector(makeSelectError());

  const onCancel = (): void => {
    dispatch(resetState());
    dispatch(setFile(null));
  };

  const onSave = (): void => {
    if (documentsGenerationQueue[0]) {
      dispatch(generateDocument(documentsGenerationQueue[0]));
    }
  };

  useEffect(() => {
    dispatch(initialFetch(dealId));
  }, [dispatch, documentsGenerationQueue, dealId]);

  useEffect(() => {
    dispatch(setDictionary(dictionary || {}));
  }, [dispatch, dictionary]);

  useEffect(
    () => (): void => {
      onCancel();
    },
    [],
  );

  if (
    documentsGenerationQueue.length === 0 ||
    documentsGenerationQueue[0].document === null
  ) {
    return null;
  }

  return (
    <>
      <FullscreenModal>
        {isGenerationLoading && <HardSpinner />}
        <DocumentTemplateEditor
          variables={variables}
          resource={{ type: DEAL_DOCUMENT, id: documentsGenerationQueue[0].id }}
          templateId={documentsGenerationQueue[0].document.template_id}
          onCancel={onCancel}
          onSave={onSave}
        />
      </FullscreenModal>
      {error && <ErrorModal error={error} />}
    </>
  );
}

export default withSlice<DocumentGenerationModalProps>(slice)(
  DocumentGenerationModal,
);
