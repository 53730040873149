import { DateTime } from 'luxon';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';

import { DEAL_REMINDER } from './constants';
import { createReminder } from './thunk';
import messages from './messages';
import { getLaterToday, getNextMonth, getNextWeek, getTomorrow } from './dates';

export interface State {
  isModalOpen: boolean;
  isLoading: boolean;
  error: SerializedApiError | null;
  option: number | null;
  value: string | null;
  inputError: keyof typeof messages | null;
}

export interface AppStateSubset {
  [DEAL_REMINDER]: State;
}

const initialState: State = {
  isModalOpen: false,
  isLoading: false,
  error: null,
  option: null,
  value: null,
  inputError: null,
};

const slice = createSlice({
  name: DEAL_REMINDER,
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    openModal(state) {
      state.isModalOpen = true;
      state.error = null;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.option = null;
      state.value = null;
      state.inputError = null;
    },
    setOption(state, { payload }: PayloadAction<number>) {
      state.option = payload;
      if (payload === 1) {
        state.value = getLaterToday().toISO();
      } else if (payload === 2) {
        state.value = getTomorrow().toISO();
      } else if (payload === 3) {
        state.value = getNextWeek().toISO();
      } else if (payload === 4) {
        state.value = getNextMonth().toISO();
      }
    },
    setValue(state, { payload }: PayloadAction<string>) {
      if (DateTime.fromISO(payload) > DateTime.now()) {
        state.value = payload;
        state.inputError = null;
      } else {
        state.inputError = 'valueMustBeFuture';
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(createReminder.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createReminder.fulfilled, state => {
      state.isLoading = false;
      state.isModalOpen = false;
      state.option = null;
      state.value = null;
      state.inputError = null;
    });
    builder.addCase(createReminder.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { openModal, closeModal, setOption, setValue } = slice.actions;
export default slice;
