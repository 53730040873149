import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import messages from './messages';

export class GraveyardBasicInfos extends PureComponent {
  render() {
    const { intl, graveyardBasicInfos } = this.props;
    if (Object.keys(graveyardBasicInfos).length === 0) {
      return null;
    }

    return (
      <div className="graveyardBasicInfos">
        {(graveyardBasicInfos.semelle_length !== null ||
          graveyardBasicInfos.semelle_width !== null) && (
          <div>
            <div className="graveyardBasicInfos__label">
              {intl.formatMessage(messages.dimensions)}
            </div>
            <div>
              {intl.formatMessage(messages.dimensionsValues, {
                length: graveyardBasicInfos.semelle_length,
                width: graveyardBasicInfos.semelle_width,
              })}
            </div>
          </div>
        )}
        {graveyardBasicInfos.concession_semelle &&
          graveyardBasicInfos.concession_semelle.semelle_type !== null && (
            <div>
              <div className="graveyardBasicInfos__label">
                {intl.formatMessage(messages.semelleType)}
              </div>
              <div>
                {intl.formatMessage(
                  messages[graveyardBasicInfos.concession_semelle.semelle_type],
                )}
              </div>
            </div>
          )}
        {graveyardBasicInfos.required_semelle_shape !== null && (
          <div>
            <div className="graveyardBasicInfos__label">
              {intl.formatMessage(messages.semelleShape)}
            </div>
            <div>
              {intl.formatMessage(
                messages[graveyardBasicInfos.required_semelle_shape],
              )}
            </div>
          </div>
        )}
        {graveyardBasicInfos.graveyard_type !== null && (
          <div>
            <div className="graveyardBasicInfos__label">
              {intl.formatMessage(messages.graveyardType)}
            </div>
            <div>
              {intl.formatMessage(messages[graveyardBasicInfos.graveyard_type])}
            </div>
          </div>
        )}
        {graveyardBasicInfos.vault_mandatory !== null && (
          <div>
            <div className="graveyardBasicInfos__label">
              {intl.formatMessage(messages.vaultMandatory)}
            </div>
            <div>
              {intl.formatMessage(
                messages[graveyardBasicInfos.vault_mandatory],
              )}
            </div>
          </div>
        )}
        {graveyardBasicInfos.fausse_case !== null && (
          <div>
            <div className="graveyardBasicInfos__label">
              {intl.formatMessage(messages.fausseCase)}
            </div>
            <div>
              {intl.formatMessage(messages.fausseCaseValue, {
                time: graveyardBasicInfos.fausse_case,
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

GraveyardBasicInfos.propTypes = {
  intl: PropTypes.object.isRequired,
  /** basic infos of selected graveyard */
  graveyardBasicInfos: PropTypes.object,
};

export default GraveyardBasicInfos;
