import { TOGGLE_IS_OPEN, SET_DELETE_FUNCTION } from './constants';

export const initialState = {
  isOpen: false,
  deleteFunction: () => {},
  message: null,
};

function confirmModalReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_IS_OPEN:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case SET_DELETE_FUNCTION:
      return {
        ...state,
        deleteFunction: action.deleteFunction,
        isOpen: true,
        message: action.message,
      };
    default:
      return state;
  }
}

export default confirmModalReducer;
