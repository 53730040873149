import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { makeSelectUser } from 'slices/auth';
import { Path as CrudPath, Sidebar, usePreventLeaving } from 'containers/Crud';
import crudMessages from 'containers/Crud/messages';

import { Path } from './constants';
import messages from './messages';
import { makeSelectFuneralParlor, makeSelectIsUpdatingName } from './selectors';

export default function FuneralParlorSidebar(): JSX.Element {
  const intl = useIntl();

  const user = useSelector(makeSelectUser());
  const isUpdatingName = useSelector(makeSelectIsUpdatingName());
  const funeralParlor = useSelector(makeSelectFuneralParlor());

  const { dirty } = useFormState();
  usePreventLeaving(!funeralParlor?.id || dirty);

  return (
    <Sidebar.Menu>
      <Sidebar.Title
        isCreation={!funeralParlor?.id}
        isLoading={isUpdatingName}
        fieldName="funeralParlor.name"
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
      />
      {funeralParlor?.current_update_dt &&
        funeralParlor?.current_update_user_id && (
          <Sidebar.LastUpdate
            date={funeralParlor.current_update_dt}
            userId={funeralParlor.current_update_user_id}
          />
        )}
      <Sidebar.LinksSection>
        <Sidebar.Link
          to={Path.GENERAL}
          message={<FormattedMessage id={messages.general.id} />}
        />
        {funeralParlor?.id && (
          <>
            <Sidebar.LinkGroup
              placeholder={<FormattedMessage id={crudMessages.documents.id} />}
            >
              {user?.isFuneralDirectorOrAbove ? (
                <Sidebar.Link
                  to={CrudPath.EDITABLE_DOCUMENTS}
                  message={
                    <FormattedMessage id={crudMessages.editableDocuments.id} />
                  }
                />
              ) : undefined}
              <Sidebar.Link
                to={CrudPath.SUPPORTING_DOCUMENTS}
                message={
                  <FormattedMessage id={crudMessages.supportingDocuments.id} />
                }
              />
            </Sidebar.LinkGroup>
            <Sidebar.Link
              to={CrudPath.OPENING_HOURS}
              message={<FormattedMessage id={crudMessages.openingHours.id} />}
            />
            <Sidebar.Link
              to={Path.MISCELLANEOUS}
              message={<FormattedMessage id={messages.miscellaneous.id} />}
            />
          </>
        )}
      </Sidebar.LinksSection>
      {funeralParlor?.id && (
        <Sidebar.LinksSection>
          <Sidebar.Link
            to={Path.STAYS}
            message={<FormattedMessage id={messages.stays.id} />}
          />
        </Sidebar.LinksSection>
      )}
    </Sidebar.Menu>
  );
}
