import { useCallback } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import type { DeathDeclarationJSON } from '@advitam/api/models/DeathDeclaration';
import { LinkButton } from '@advitam/ui/components/Button';
import { FormLayout, HiddenInput, TextArea } from '@advitam/ui/components/Form';
import { FormattedApiError, Person } from '@advitam/ui/components/Format';
import Modal, { ModalTitle } from '@advitam/ui/components/Modal';
import Text from '@advitam/ui/components/Text';

import ErrorText from '@advitam/ui/components/ErrorText';
import { withSlice } from '@advitam/react';
import { makeSelectPendingDeathDeclarations } from '../selectors';
import messages from './messages';
import Buttons from './Buttons';
import { updateDeathDeclaration } from './thunk';
import { makeSelectError } from './selectors';
import style from './DeathDeclarationValidation.module.scss';
import slice from './slice';

function DeathDeclarationValidation(): JSX.Element | null {
  const dispatch = useDispatch();

  const deathDeclarations = useSelector(makeSelectPendingDeathDeclarations());
  const error = useSelector(makeSelectError());

  const onSubmit = useCallback((values: DeathDeclarationJSON) => {
    dispatch(updateDeathDeclaration(values));
  }, []);

  if (deathDeclarations.length === 0) {
    return null;
  }

  return (
    <Modal isOpen className={`col-6 ${style.modal}`}>
      <ModalTitle>
        <FormattedMessage
          id={messages.title.id}
          values={{
            name: (
              <Person.FormattedName
                person={deathDeclarations[0].deceased_client}
              />
            ),
          }}
        />
      </ModalTitle>

      <Text>
        <FormattedMessage
          id={messages.requester.id}
          values={{
            name: <Person.FormattedName person={deathDeclarations[0].client} />,
          }}
        />
        <br />
        <FormattedMessage
          id={messages.email.id}
          values={{ email: deathDeclarations[0].client.email }}
        />
      </Text>

      <div className={style.act_of_death}>
        <LinkButton
          outline
          newTab
          href={deathDeclarations[0].act_of_death}
          text={<FormattedMessage id={messages.actOfDeath.id} />}
        />
      </div>

      {error && (
        <ErrorText center>
          <FormattedApiError error={error} />
        </ErrorText>
      )}

      <Form onSubmit={onSubmit} initialValues={deathDeclarations[0]}>
        {({ handleSubmit }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <FormLayout.Row>
              <TextArea
                name="comment"
                label={<FormattedMessage id={messages.comment.id} />}
              />
            </FormLayout.Row>
            <HiddenInput name="state" />
            <Buttons />
          </form>
        )}
      </Form>
    </Modal>
  );
}

export default withSlice(slice)(DeathDeclarationValidation);
