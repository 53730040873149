import type { ApiRequestDescriptor } from '../../lib/request'
import { post } from '../../lib/methods'
import { authenticate } from '../../lib/decorators'

export interface DuplicateResponse {
  new_uuid: string
}

export class Duplicate {
  @authenticate
  static create(uuid: string): ApiRequestDescriptor<DuplicateResponse> {
    return post(`/api/v1/deals/${uuid}/duplicate`)
  }
}
