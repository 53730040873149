import type { ReactNode } from 'react'

import ChevronDown from '../../../../../images/icons/chevron-down.svg'
import Dropdown from '../../../../Dropdown'
import menuStyle from '../../Menu.module.scss'
import DropdownItem, { DropdownItemProps } from './Item'
import style from './Dropdown.module.scss'

interface MenuDropdownProps {
  placeholder: ReactNode
  items: DropdownItemProps[]
  className?: string
}

export default function MenuDropdown({
  placeholder,
  items,
  className,
}: MenuDropdownProps): JSX.Element {
  const classes = [className, style.dropdown].filter(Boolean)
  return (
    <Dropdown
      className={classes.join(' ')}
      placeholder={
        <span className={[menuStyle.link, menuStyle.link_text].join(' ')}>
          {placeholder} <ChevronDown />
        </span>
      }
    >
      {items.map((item, idx) => (
        <DropdownItem
          key={idx}
          text={item.text}
          href={item.href}
          subtext={item.subtext}
          internal={item.internal}
        />
      ))}
    </Dropdown>
  )
}
