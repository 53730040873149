import { AutocompleteResult } from '../../UI/Autosuggest/types'
import { CityhallAutocompleteResult } from '../CityhallAutocomplete/types'

export type { CityhallAutocompleteResult }

export interface EntityAutocompleteResult extends AutocompleteResult {
  name: string
  city: string
  /* eslint-disable camelcase */
  postal_code: string
  insee_code: string
  /* eslint-enable camelcase */
}

export function entityToCityhallAutocompleteResult(
  r: EntityAutocompleteResult,
): CityhallAutocompleteResult {
  return {
    id: 0,
    insee_code: r.insee_code,
    postal_code: r.postal_code,
    name: r.city,
    description: `${r.city} (${r.postal_code})`,
  }
}
