import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { LegacyPersonFields } from 'models/Person';
import EditPerson from 'containers/Deal/EditPerson/index.js';
import { ButtonV2 as Button } from 'components/Button/index.js';
import { TextInput } from 'components/Input/index.js';
import messagesCrud from 'messages/crud.js';

import { RepatriationTripContact } from '../types';
import messages from '../messages';

interface TripContactProps {
  isCompany: boolean;
  infoCompany: LegacyPersonFields;
  infoCivilian: LegacyPersonFields;
  handleChange: (value: Partial<RepatriationTripContact>) => void;
}

export default function TripContact({
  isCompany,
  infoCompany,
  infoCivilian,
  handleChange,
}: TripContactProps): JSX.Element {
  const infos = isCompany ? infoCompany : infoCivilian;
  const classes = [
    'trip__contact__switch',
    `trip__contact__switch--${isCompany ? 'company' : 'civilian'}`,
  ];

  const handleChangeInfos = (value: LegacyPersonFields): void => {
    const infosType = isCompany ? 'infoCompany' : 'infoCivilian';
    handleChange({ [infosType]: value });
  };

  const handleChangeIsCompany = (value: boolean): void => {
    handleChange({ isCompany: value });
  };

  const handleChangeContactAddress = (name: string, value: string): void => {
    const data = infos || {};
    const updatedContact = {
      ...data,
      address: {
        ...data.address,
        [name]: value,
      },
    };

    handleChangeInfos(updatedContact);
  };

  return (
    <div className="trip__contact">
      <div className={classes.join(' ')}>
        <Button onClick={(): void => handleChangeIsCompany(true)}>
          <FormattedMessage id={messages.companyContact.id} tagName="h4" />
        </Button>
        <Button onClick={(): void => handleChangeIsCompany(false)}>
          <FormattedMessage id={messages.civilContact.id} tagName="h4" />
        </Button>
      </div>
      <EditPerson
        key={isCompany}
        person={infos || {}}
        handleUpdate={handleChangeInfos}
        withoutCiv={isCompany}
        withoutFirstname={isCompany}
        withoutBirthname={isCompany}
        withContact
        withIconBlack
        noEmailAutoComplete
      />
      <div className="trip__contact--custom-address">
        <div className="text--white">
          <FormattedMessage id={messagesCrud.addressTitle.id} />
        </div>
        <div>
          <TextInput
            name="address"
            value={(infos && infos.address && infos.address.address) || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              handleChangeContactAddress(e.target.name, e.target.value)
            }
          />
        </div>
      </div>
    </div>
  );
}
