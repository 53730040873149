import type { ReactNode } from 'react';
import { Field } from 'react-final-form';
import { FormattedDate, FormattedMessage } from 'react-intl';

import type { CrematoriumJSON } from '@advitam/api/models/Crematorium';
import { OpeningHours, Text } from '@advitam/ui';
import {
  DAY,
  MONTH,
  YEAR,
} from '@advitam/ui/components/Calendar/OpeningHours/utils';

import Layout from '../Layout';
import DayText from './DayText';
import messages from './messages';
import { SKELETON } from './constants';
import style from './OpeningHours.module.scss';

interface OpeningHoursSectionProps {
  prefix?: string;
  tooltip?: ReactNode;
}

export default function OpeningHoursSection({
  prefix,
  tooltip = <FormattedMessage id={messages.tooltip.id} />,
}: OpeningHoursSectionProps): JSX.Element {
  const name = [prefix, 'opening_hours'].filter(Boolean).join('.');

  return (
    <Layout.ColumnGroup>
      <Layout.Column>
        <Layout.Fieldset
          title={<FormattedMessage id={messages.title.id} />}
          tooltip={tooltip}
        >
          <Field<CrematoriumJSON['opening_hours']> name={name}>
            {({ input }): JSX.Element => (
              <>
                <OpeningHours
                  value={input.value || SKELETON}
                  onChange={input.onChange}
                  minTime={7}
                  maxTime={20}
                  className={style.calendar}
                />
                <div className={style.listing}>
                  {Array(7)
                    .fill(0)
                    .map((_, dayIndex) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Text key={dayIndex} small tagName="div">
                        <b>
                          <FormattedDate
                            value={new Date(YEAR, MONTH, DAY + dayIndex)}
                            weekday="long"
                          />
                          {': '}
                        </b>
                        <DayText value={input.value?.[dayIndex]} />
                      </Text>
                    ))}
                </div>
              </>
            )}
          </Field>
        </Layout.Fieldset>
      </Layout.Column>
    </Layout.ColumnGroup>
  );
}
