import { BackgroundValue } from './constants'
import { BackgroundStyleProps, getClassList } from './Full'
import Round from './round.svg'
import style from './Background.module.scss'

interface OneThirdsProps extends BackgroundStyleProps {
  color2: BackgroundValue
}

export default function OneThirds({
  color,
  color2,
  textured,
  rounded,
}: OneThirdsProps): JSX.Element {
  const classes = [style.background, style[`flat__${color}`]]
  const bottomClasses = [style.one_thirds__bottom].concat(
    getClassList({ color: color2, textured, rounded }),
  )

  return (
    <div className={classes.join(' ')}>
      <div className={bottomClasses.join(' ')}>
        {rounded && <Round className={style[`round__${color}`]} />}
      </div>
    </div>
  )
}
