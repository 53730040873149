import Image from '../../../Image'
import Text from '../../../Text'
import { formatInitial } from '../utils'
import style from '../Testimonials.module.scss'

interface ThumbnailProps {
  firstname: string
  lastname: string
  squaredThumbnail?: boolean
  imageSrc?: string | null
}

export default function Thumbnail({
  imageSrc,
  firstname,
  lastname,
  squaredThumbnail,
}: ThumbnailProps): JSX.Element {
  const classes = [style.thumbnail]
  if (squaredThumbnail) {
    classes.push(style.squared)
  }

  if (!imageSrc) {
    return (
      <Text large className={classes.join(' ')}>
        <b>
          {formatInitial(firstname)} {formatInitial(lastname)}
        </b>
      </Text>
    )
  }

  return (
    <Image
      src={imageSrc}
      alt={`Vignette ${firstname} ${lastname}`}
      className={classes.join(' ')}
      width={120}
      height={120}
    />
  )
}
