import { cloneDeep } from 'lodash';

import { DealType } from '@advitam/api/models/Deal/Type';
import { Civility } from '@advitam/api/models/Person/Civility';
import { Deal, DealJSON, MarbleDetails } from 'models/Deal';
import { PaymentProblem } from 'models/Deal/PaymentProblem';

import { UNKNOWN } from '../DealFuneral/StepsSection/StepEditors/Interment/constants';

const DEFAULT_DEFUNCT_BODYSTATE = 0;

const PERSON_SKELETON = {
  type: Civility.MR,
  firstName: '',
  lastName: '',
  contact: {},
  address: {},
};

const FUNERAL_DEAL_SKELETON: DealJSON = {
  deal_type: DealType.FUNERAL,
  commentary: '',
  defunctBodyState: DEFAULT_DEFUNCT_BODYSTATE,
  state: Deal.State.QUOTE,
  client_commentary: '',
  auto_price: null,
  manual_price: null,
  payment_problem: PaymentProblem.NONE,
  clients: [cloneDeep(PERSON_SKELETON)],
  business_id: null,
  user_id: null,
  ko_reason: null,
  ko_comment: null,
  remind_at: null,
  defunctInfo: {
    death_date: null,
    person: {
      ...cloneDeep(PERSON_SKELETON),
      marital_status: null,
      birthDate: null,
    },
    defunctCompInfo: {
      on_guardianship: false,
      father: {
        ...cloneDeep(PERSON_SKELETON),
        dead: null,
      },
      mother: {
        ...cloneDeep(PERSON_SKELETON),
        type: Civility.MRS,
        dead: null,
      },
      partner: {
        ...cloneDeep(PERSON_SKELETON),
        dead: null,
      },
    },
  },
  funeral: {
    steps: null,
    services: [],
    products: [],
    wishes: [
      {
        type: 'want_ceremony_master',
        active: false,
      },
      {
        type: 'want_closing_account',
        active: false,
      },
      {
        type: 'want_closing_social_media',
        active: false,
      },
      {
        type: 'want_cleaning',
        active: true,
      },
      {
        type: 'want_pre_casketing',
        active: false,
      },
    ],
  },
};

export const ITEM_DEAL_SKELETON: DealJSON = {
  deal_type: DealType.ITEM,
  state: Deal.State.QUOTE,
  auto_price: null,
  manual_price: null,
  commentary: '',
  client_commentary: '',
  payment_problem: PaymentProblem.NONE,
  clients: [cloneDeep(PERSON_SKELETON)],
  business_id: null,
  user_id: null,
  ko_reason: null,
  ko_comment: null,
  remind_at: null,
  item: {
    services: [],
    products: [],
    delivery: {
      message: '',
      signature: '',
      step_id: null,
      step_type: null,
    },
  },
};

export const MARBLE_DEAL_SKELETON: DealJSON = {
  deal_type: DealType.MARBLE,
  state: Deal.State.QUOTE,
  clients: [cloneDeep(PERSON_SKELETON)],
  auto_price: null,
  manual_price: null,
  client_commentary: '',
  commentary: '',
  payment_problem: PaymentProblem.NONE,
  business_id: null,
  user_id: null,
  ko_reason: null,
  ko_comment: null,
  remind_at: null,
  marble: {
    graveyard: ({
      eventType: 'interment',
      location: {
        type: 'Graveyard',
      },
      concession: {
        buyer: {},
        rightHolders: [],
        places: { remaining: 1, total: 1 },
        burieds: [],
        type: { name: UNKNOWN, info: {} },
        address: {},
      },
    } as unknown) as MarbleDetails['graveyard'], // TODO: Define it
    products: [],
    services: [],
    wishes: [],
  },
};

export const DEAL_SKELETON = {
  [DealType.FUNERAL]: FUNERAL_DEAL_SKELETON,
  [DealType.ITEM]: ITEM_DEAL_SKELETON,
  [DealType.MARBLE]: MARBLE_DEAL_SKELETON,
};
