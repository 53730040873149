import { ComponentType, useCallback, useEffect, useMemo, useRef } from 'react'
import type { EventProps } from 'react-big-calendar'

import type { OpeningEvent, OpeningHours } from './types'
import Times from '../../../images/icons/times.svg'
import { removeEvent } from './utils'

function EventWrapper(
  onDelete: (event: OpeningEvent) => void,
): ComponentType<EventProps<OpeningEvent>> {
  return function Event({ title, event }: EventProps<OpeningEvent>): JSX.Element {
    const onDeleteMemo = useCallback((): void => onDelete(event), [event])

    return (
      <>
        {title}
        <Times onClick={onDeleteMemo} />
      </>
    )
  }
}

export default function useEventWrapper(
  value: OpeningHours,
  onChange?: (value: OpeningHours) => void,
): ComponentType<EventProps<OpeningEvent>> {
  // react-big-calendar over-optimizes custom components, making `value`
  // memoized to its initial value and reverting any change made before.
  const valueRef = useRef(value)
  useEffect(() => {
    valueRef.current = value
  }, [value])

  return useMemo(
    () =>
      EventWrapper(event => {
        if (!onChange) {
          return
        }

        const updated = removeEvent(valueRef.current, event)
        onChange(updated)
      }),
    [onChange, valueRef],
  )
}
