import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: { id: 'app.containers.Deal.Sections.Todolist.CreationModal.title' },
  titleInputLabel: {
    id: 'app.containers.Deal.Sections.Todolist.CreationModal.titleInputLabel',
  },
  titleInputPlaceholder: {
    id:
      'app.containers.Deal.Sections.Todolist.CreationModal.titleInputPlaceholder',
  },
});
