import { defineMessages } from 'react-intl';

export default defineMessages({
  noClients: { id: 'app.containers.Clients.noClients' },
  name: { id: 'app.containers.Clients.name' },
  email: { id: 'app.containers.Clients.email' },
  phone: { id: 'app.containers.Clients.phone' },
  address: { id: 'app.containers.Clients.address' },
  status: { id: 'app.containers.Clients.status' },
  alive: { id: 'app.containers.Clients.alive' },
  deceased: { id: 'app.containers.Clients.deceased' },
  search: { id: 'app.containers.Clients.search' },
});
