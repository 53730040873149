import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError } from 'api';

import { PAYMENTS_OPS_LOGS } from './constants';
import { fetchDealsOpsLogs } from '../../thunk';
import { createOpsLog } from './thunk';

export interface State {
  dealId: number | null;
  isLoading: boolean;
  isCreationLoading: boolean;
  error: ApiError | null;
}

export interface AppStateSubset {
  [PAYMENTS_OPS_LOGS]: State;
}

export const initialState: State = {
  dealId: null,
  isLoading: false,
  isCreationLoading: false,
  error: null,
};

const slice = createSlice({
  name: PAYMENTS_OPS_LOGS,
  initialState,
  reducers: {
    openFor: (state, { payload }: PayloadAction<number>) => {
      // eslint-disable-next-line no-param-reassign
      state.dealId = payload;
    },
    close: state => ({ ...state, dealId: null }),
  },

  extraReducers: builder => {
    builder.addCase(fetchDealsOpsLogs.pending, (state, { meta: { arg } }) => {
      if (arg.length > 1 || arg[0] !== state.dealId) {
        return state;
      }
      return { ...state, isLoading: true, error: null };
    });
    builder.addCase(fetchDealsOpsLogs.fulfilled, (state, { meta: { arg } }) => {
      if (arg.length > 1 || arg[0] !== state.dealId) {
        return state;
      }
      return { ...state, isLoading: false };
    });
    builder.addCase(
      fetchDealsOpsLogs.rejected,
      (state, { payload, meta: { arg } }) => {
        if (arg.length > 1 || arg[0] !== state.dealId) {
          return state;
        }
        return { ...state, isLoading: false, error: payload as ApiError };
      },
    );

    builder.addCase(createOpsLog.pending, state => ({
      ...state,
      isCreationLoading: true,
      error: null,
    }));
    builder.addCase(createOpsLog.fulfilled, state => ({
      ...state,
      isCreationLoading: false,
    }));
    builder.addCase(createOpsLog.rejected, (state, { payload }) => ({
      ...state,
      isCreationLoading: false,
      error: payload as ApiError,
    }));
  },
});

export const { openFor, close } = slice.actions;
export default slice;
