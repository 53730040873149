import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { createIntl } from 'react-intl';

import Api, { Pagination, request } from '@advitam/api';
import type { NotificationJSON } from '@advitam/api/models/Notification';
import { assert } from '@advitam/support';
import { LOCALE } from 'components/LanguageProvider/constants';
import { unsafeContext } from 'lib/support';
import { translationMessages } from 'i18n';

import tilePropsFactory from './NotificationTile/propsFactory';
import { APP_NOTIFICATION_CENTER, NOTIFICATION_PER_PAGE } from './constants';
import type { AppStateSubset } from './slice';
import {
  makeSelectCurrentOffset,
  makeSelectUnreadNotificationCount,
  makeSelectUnreadNotificationIds,
} from './selectors';
import { isSupported } from './utils';

export const fetchUnreadNotificationCount = createAsyncThunk(
  `${APP_NOTIFICATION_CENTER}/FETCH_UNREAD_NOTIFICATION_COUNT`,
  async (_: void, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Notifications.Count.show(false));
      assert(body !== null);
      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchNotificationsPage = createAsyncThunk(
  `${APP_NOTIFICATION_CENTER}/FETCH_NOTIFICATIONS_PAGE`,
  async (_: void, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AppStateSubset;
      const offset = makeSelectCurrentOffset()(state);

      const response = await request(
        Api.V1.Notifications.index({ offset, per_page: NOTIFICATION_PER_PAGE }),
      );
      assert(response.body !== null);
      const pageCount = Pagination.getPageCount(response);

      return { notifications: response.body, pageCount };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const markAsRead = createAsyncThunk(
  `${APP_NOTIFICATION_CENTER}/MARK_AS_READ`,
  async (notification: NotificationJSON, { rejectWithValue }) => {
    try {
      await request(Api.V1.Notifications.Viewed.create([notification.id]));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const markAllAsRead = createAsyncThunk(
  `${APP_NOTIFICATION_CENTER}/MARK_ALL_AS_READ`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    // A new notification may be pushed waiting for the request. In this case,
    // we do not want to mark it as read.
    const notificationIds = makeSelectUnreadNotificationIds()(state);

    try {
      await request(Api.V1.Notifications.Viewed.All.create());
      return notificationIds;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const pushNotification = createAsyncThunk(
  `${APP_NOTIFICATION_CENTER}/PUSH_NOTIFICATION`,
  async (notification: NotificationJSON) => {
    if (!isSupported() || Notification.permission !== 'granted') {
      return;
    }

    const intl = createIntl({
      locale: LOCALE,
      messages: translationMessages[LOCALE],
    });
    const { title, body, icon, link } = tilePropsFactory(notification, intl);

    const serviceWorker = await unsafeContext().serviceWorker?.ready;
    if (!serviceWorker) {
      return;
    }

    const tag = notification.id.toString();
    const sent = await serviceWorker.getNotifications({ tag });
    if (sent.length > 0) {
      return;
    }

    await serviceWorker.showNotification(title, {
      body: body || undefined,
      icon,
      tag,
      data: link,
    });
  },
);

export const resetState = createAsyncThunk(
  `${APP_NOTIFICATION_CENTER}/RESET_STATE`,
  async (_: void, { dispatch }) => {
    await dispatch(fetchNotificationsPage());
  },
);

export const reconnected = createAsyncThunk(
  `${APP_NOTIFICATION_CENTER}/RECONNECTED`,
  async (_: void, { dispatch, getState }) => {
    const state = getState() as AppStateSubset;
    const knownCount = makeSelectUnreadNotificationCount()(state);
    const currentCount = await dispatch(fetchUnreadNotificationCount());
    try {
      if (unwrapResult(currentCount) === knownCount) {
        return;
      }
    } catch {
      return;
    }

    await dispatch(resetState());
  },
);
