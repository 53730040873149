import { FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import CreatePrestationForm from './CreatePrestationForm';
import EditGroupForm from './EditGroupForm';
import EditPrestationForm from './EditPrestationForm';
import { makeSelectInvoice, makeSelectPrestation } from './selectors';
import type { PrestationForm } from './types';

export default function EditModal({
  handleSubmit,
}: FormRenderProps<PrestationForm>): JSX.Element {
  const invoice = useSelector(makeSelectInvoice());
  const prestation = useSelector(makeSelectPrestation());

  if (!invoice) {
    return (
      <form onSubmit={handleSubmit}>
        <CreatePrestationForm />
      </form>
    );
  }

  if (!prestation) {
    return (
      <form onSubmit={handleSubmit}>
        <EditGroupForm />
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <EditPrestationForm />
    </form>
  );
}
