import { ReactElement, ReactNode } from 'react'

import Arrow from '../../../../images/icons/arrow-right.svg'
import Text from '../../../Text'
import LayoutLink from '../Layout/Link'
import style from '../Index.module.scss'

interface CardLinkButtonProps {
  icon: ReactElement
  text: ReactNode
  href: string
  internal?: boolean
  external?: boolean
}

export default function CardLinkButton({
  icon,
  text,
  href,
  external,
  internal,
}: CardLinkButtonProps): JSX.Element {
  return (
    <LayoutLink
      icon={icon}
      href={href}
      external={external}
      internal={internal}
      className={style.card}
    >
      <div className={style.text}>
        <Text large>
          <b>{text}</b>
        </Text>
        <Arrow />
      </div>
    </LayoutLink>
  )
}
