/*
 * StepEditors Messages
 *
 * This contains all the text for the StepEditors components.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  death: {
    id: 'app.components.DealFuneral.StepEditors.death',
    defaultMessage: 'Death ',
  },
  casketing: {
    id: 'app.components.DealFuneral.StepEditors.casketing',
    defaultMessage: 'Casketing ',
  },
  closing: {
    id: 'app.components.DealFuneral.StepEditors.closing',
    defaultMessage: 'Closing ',
  },
  deposit: {
    id: 'app.components.DealFuneral.StepEditors.deposit',
    defaultMessage: 'Depost ',
  },
  transportAfterCasketing: {
    id: 'app.components.DealFuneral.StepEditors.transportAfterCasketing',
    defaultMessage: 'Transport after casketing ',
  },
  ceremony: {
    id: 'app.components.DealFuneral.StepEditors.ceremony',
    defaultMessage: 'Ceremony ',
  },
  cremation: {
    id: 'app.components.DealFuneral.StepEditors.cremation',
    defaultMessage: 'Cremation ',
  },
  interment: {
    id: 'app.components.DealFuneral.StepEditors.interment',
    defaultMessage: 'Interment ',
  },
  repatriation: {
    id: 'app.components.DealFuneral.StepEditors.repatriation',
    defaultMessage: 'Repatriation ',
  },
  urnPickup: {
    id: 'app.components.DealFuneral.StepEditors.urnPickup',
    defaultMessage: 'Urn pickup ',
  },
  dispersion: {
    id: 'app.components.DealFuneral.StepEditors.dispersion',
    defaultMessage: 'Dispersion ',
  },
  urnSealing: {
    id: 'app.components.DealFuneral.StepEditors.urnSealing',
    defaultMessage: 'Urn sealing ',
  },
  transportBeforeCasketing: {
    id: 'app.components.DealFuneral.StepEditors.transportBeforeCasketing',
    defaultMessage: 'Transport before casketing ',
  },
  // tbc done by ...
  tbcDoneBy: {
    id: 'app.components.DealFuneral.StepEditors.tbcDoneBy',
    defaultMessage: 'Transport done by :',
  },
  advitam: {
    id: 'app.components.DealFuneral.StepEditors.advitam',
    defaultMessage: 'Advitam',
  },
  police: {
    id: 'app.components.DealFuneral.StepEditors.police',
    defaultMessage: 'Police',
  },
  other: {
    id: 'app.components.DealFuneral.StepEditors.other',
    defaultMessage: 'Other',
  },
  // step location same as ...
  sameAsPlace: {
    id: 'app.components.DealFuneral.StepEditors.sameAsPlace',
    defaultMessage: 'same place as ',
  },
  sameAsDeathPlace: {
    id: 'app.components.DealFuneral.StepEditors.sameAsDeathPlace',
    defaultMessage: 'same as death place',
  },
  // closing with family
  withFamily: {
    id: 'app.components.DealFuneral.StepEditors.withFamily',
    defaultMessage: 'presence of the family',
  },
  // services
  embalming: {
    id: 'app.components.DealFuneral.StepEditors.embalming',
    defaultMessage: 'embalming',
  },
  table: {
    id: 'app.components.DealFuneral.StepEditors.table',
    defaultMessage: 'Refrigerated table',
  },
  cleaning: {
    id: 'app.components.DealFuneral.StepEditors.cleaning',
    defaultMessage: 'Pas de toilette',
  },
  pre_casketing: {
    id: 'app.components.DealFuneral.StepEditors.pre_casketing',
    defaultMessage: 'Mise en bière anticipée',
  },
  // Location types
  home: {
    id: 'app.components.DealFuneral.StepEditors.home',
    defaultMessage: 'Home',
  },
  healthFacility: {
    id: 'app.components.DealFuneral.StepEditors.healthFacility',
    defaultMessage: 'Hospital or Health facility',
  },
  funeralParlor: {
    id: 'app.components.DealFuneral.StepEditors.funeralParlor',
    defaultMessage: 'Funeral parlor',
  },
  public: {
    id: 'app.components.DealFuneral.StepEditors.public',
    defaultMessage: 'Public',
  },
  autoFind: {
    id: 'app.components.DealFuneral.StepEditors.autoFind',
    defaultMessage: 'Auto find {type}',
  },
  // Step display
  date: {
    id: 'app.components.DealFuneral.StepEditors.date',
    defaultMessage: 'Date',
  },
  startDate: {
    id: 'app.components.DealFuneral.StepEditors.startDate',
    defaultMessage: 'Start date',
  },
  endDate: {
    id: 'app.components.DealFuneral.StepEditors.endDate',
    defaultMessage: 'End date',
  },
  location: {
    id: 'app.components.DealFuneral.StepEditors.location',
    defaultMessage: 'Location',
  },
  startLocation: {
    id: 'app.components.DealFuneral.StepEditors.startLocation',
    defaultMessage: 'Start location :',
  },
  endLocation: {
    id: 'app.components.DealFuneral.StepEditors.endLocation',
    defaultMessage: 'End location :',
  },
  name: {
    id: 'app.components.DealFuneral.StepEditors.name',
    defaultMessage: 'Name : {name}',
  },
  address: {
    id: 'app.components.DealFuneral.StepEditors.address',
    defaultMessage: 'Address : {address}',
  },
  city: {
    id: 'app.components.DealFuneral.StepEditors.city',
    defaultMessage: 'City : {city}',
  },
  postalCode: {
    id: 'app.components.DealFuneral.StepEditors.postalCode',
    defaultMessage: 'Postal code : {postalCode}',
  },
  wantPayCeremony: {
    id: 'app.components.DealFuneral.StepEditors.wantPayCeremony',
    defaultMessage: 'Paiement par la famille',
  },
  // common messages
  person: {
    id: 'app.components.DealFuneral.StepEditors.person',
    defaultMessage: '{name} :',
  },
  remove: {
    id: 'app.components.DealFuneral.StepEditors.remove',
    defaultMessage: 'Remove',
  },
  sameCrema: {
    id: 'app.components.DealFuneral.Dispersion.sameCrema',
    defaultMessage: 'Same crematorium',
  },
  anotherCrematorium: {
    id: 'app.components.DealFuneral.Dispersion.anotherCrematorium',
    defaultMessage: 'Another crematorium',
  },
  graveyard: {
    id: 'app.components.DealFuneral.Dispersion.graveyard',
    defaultMessage: 'A graveyard',
  },
  otherPrice: {
    id: 'app.components.DealFuneral.tbc.otherPrice',
    defaultMessage: 'Price',
  },
  otherName: {
    id: 'app.components.DealFuneral.tbc.otherName',
    defaultMessage: 'Company name',
  },
  prefectureExemptionReason: {
    id: 'app.components.DealFuneral.casketing.prefectureExemptionReason',
    defaultMessage: 'Motif de dérogation préfectorale',
  },
  delegate: {
    id: 'app.components.DealFuneral.delegate',
    defaultMessage: "Déléguer cette étape à d'autres pompes funèbres ?",
  },
  funeralParlorStayTypes: {
    id: 'app.components.DealFuneral.casketing.funeralParlorStayTypes',
    defaultMessage: 'Type de séjour :',
  },
});
