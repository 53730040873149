import { FormattedMessage } from 'react-intl';

import { TransparentButton } from 'components/Button';

import messages from '../../messages.js';
import style from './style.scss';

interface HeaderProps {
  isFileListOpen: boolean;
  toggleFileListOpen: () => void;
  isHistoryOpen: boolean;
  fileCount: number;
}

export default function Header({
  isFileListOpen,
  toggleFileListOpen,
  isHistoryOpen,
  fileCount,
}: HeaderProps): JSX.Element | null {
  if (isHistoryOpen) {
    return null;
  }

  return (
    <TransparentButton onClick={toggleFileListOpen} className={style.header}>
      <FormattedMessage {...messages.nbFiles} values={{ nb: fileCount }} />
      <FormattedMessage {...messages.toolbarTitle} tagName="h3" />
      {fileCount > 0 ? (
        <i className={`material-icons ${style.icon}`}>
          {isFileListOpen ? 'minimize' : 'filter_none'}
        </i>
      ) : (
        <div />
      )}
    </TransparentButton>
  );
}
