import { Funeral } from './Funeral';
import { Item } from './Item';
import { Marble } from './Marble';

export class Deals {
  static readonly Funeral = Funeral;

  static readonly Item = Item;

  static readonly Marble = Marble;
}
