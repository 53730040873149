import { ChangeEvent, ReactNode, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { SerializedApiError } from '@advitam/api';
import {
  Button,
  ConfirmationModal,
  FormattedApiError,
  FormUI,
} from '@advitam/ui';

import { makeSelectUser } from 'slices/auth';

import messages from './messages';
import style from './AdministrationActions.module.scss';
import Layout from '../Layout';

interface AdministrationActionsProps {
  entityName: string;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  onDelete: () => void;
  isDeletionLoading: boolean;
  destroyError: SerializedApiError | null;
  children?: ReactNode;
  className?: string;
}

export default function AdministrationActions({
  entityName,
  isDisabled,
  setIsDisabled,
  onDelete,
  isDeletionLoading,
  destroyError,
  children,
  className,
}: AdministrationActionsProps): JSX.Element | null {
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const user = useSelector(makeSelectUser());

  const openDeletionModal = useCallback(() => setIsDeletionModalOpen(true), [
    setIsDeletionModalOpen,
  ]);
  const closeDeletionModal = useCallback(() => setIsDeletionModalOpen(false), [
    setIsDeletionModalOpen,
  ]);
  const onChangeIsDisabled = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void =>
      setIsDisabled(!event.target.checked),
    [setIsDisabled],
  );

  if (!user?.isFuneralDirectorOrAbove) {
    return null;
  }

  return (
    <>
      <Layout.ColumnGroup>
        <div className={[style.wrapper, className].filter(Boolean).join(' ')}>
          <Button
            outline
            text={<FormattedMessage id={messages.delete.id} />}
            onClick={openDeletionModal}
          />
          {children}
          <FormUI.BorderlessSwitch
            label={<FormattedMessage id={messages.active.id} />}
            name="enabled"
            value="enabled"
            onChange={onChangeIsDisabled}
            checked={!isDisabled}
          />
        </div>
      </Layout.ColumnGroup>

      <ConfirmationModal
        isOpen={isDeletionModalOpen}
        title={<FormattedMessage id={messages.deletionConfirmationTitle.id} />}
        text={
          <>
            <FormattedMessage
              id={messages.deletionConfirmationText.id}
              values={{ entityName }}
            />
            {destroyError && (
              <div className={style.error}>
                <FormattedApiError error={destroyError} />
              </div>
            )}
          </>
        }
        confirm={<FormattedMessage id={messages.confirmDeletion.id} />}
        onConfirm={onDelete}
        isConfirmationLoading={isDeletionLoading}
        cancel={<FormattedMessage id={messages.cancelDeletion.id} />}
        onCancel={closeDeletionModal}
      />
    </>
  );
}
