import { post } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { authenticate, withSessionId } from '../../../lib/decorators'
import { SupplierWarehouseJSON } from '../../../models/Supplier/Warehouse'

export class Duplicate {
  @authenticate
  @withSessionId
  static create(id: number): ApiRequestDescriptor<SupplierWarehouseJSON> {
    return post(`/api/v1/suppliers/warehouses/${id}/duplicate`)
  }
}
