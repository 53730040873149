import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import Api, { request } from '@advitam/api';
import type { CrematoriumJSON } from '@advitam/api/models/Crematorium';
import type { CeremonyJSON } from '@advitam/api/models/Crematorium/Ceremony';
import { assert } from 'lib/Assert';

import { makeSelectRawCrematorium } from '../../selectors';
import type { AppStateSubset as CrematoriumAppStateSubset } from '../../slice';
import { CREMATORIUM_CEREMONIES } from './constants';
import { makeSelectCeremonies, makeSelectFilters } from './selectors';
import type { AppStateSubset as AgeRangeAppStateSubset } from './slice';
import type { UnsavedCeremony } from './types';

type AppStateSubset = CrematoriumAppStateSubset & AgeRangeAppStateSubset;

async function fetchCeremoniesRequest(
  state: AppStateSubset,
): Promise<CeremonyJSON[]> {
  const crematorium = makeSelectRawCrematorium()(state);
  const filters = makeSelectFilters()(state);
  assert(crematorium?.id !== undefined);

  const { body } = await request(
    Api.V1.Crematoriums.Ceremonies.index(crematorium.id, filters),
  );
  return body;
}

export const fetchCeremonies = createAsyncThunk(
  `${CREMATORIUM_CEREMONIES}/FETCH`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;

    try {
      return fetchCeremoniesRequest(state);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const initialize = createAsyncThunk(
  `${CREMATORIUM_CEREMONIES}/INITIALIZE`,
  async (_: void, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const crematorium = makeSelectRawCrematorium()(state);
    assert(crematorium?.id !== undefined);

    try {
      const [ageRangesResponse, roomsResponse, ceremonies] = await Promise.all([
        request(Api.V1.Crematoriums.AgeRanges.index(crematorium.id)),
        request(Api.V1.Crematoriums.Rooms.index(crematorium.id)),
        fetchCeremoniesRequest(state),
      ]);
      return {
        ageRanges: ageRangesResponse.body,
        rooms: roomsResponse.body,
        ceremonies,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

async function saveCeremony(
  ceremony: CeremonyJSON | UnsavedCeremony,
  existingCeremonies: CeremonyJSON[],
  crematorium: CrematoriumJSON,
): Promise<CeremonyJSON> {
  if (ceremony.id === undefined) {
    const { body } = await request(
      Api.V1.Crematoriums.Ceremonies.create(crematorium.id, ceremony),
    );
    return body;
  }

  const existingCeremony = existingCeremonies.find(c => c.id === ceremony.id);
  assert(existingCeremony !== undefined);
  if (isEqual(ceremony, existingCeremony)) {
    return existingCeremony;
  }

  const { body } = await request(
    Api.V1.Crematoriums.Ceremonies.update(ceremony),
  );

  return body;
}

export const saveCeremonies = createAsyncThunk(
  `${CREMATORIUM_CEREMONIES}/SAVE`,
  async (payload: CeremonyJSON[], { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const crematorium = makeSelectRawCrematorium()(state);
    const existingCeremonies = makeSelectCeremonies()(state);
    assert(crematorium?.id !== undefined);

    try {
      const result = await Promise.all(
        payload.map(ceremony =>
          saveCeremony(ceremony, existingCeremonies, crematorium),
        ),
      );
      await Promise.all(
        existingCeremonies.map(async ceremony => {
          if (!payload.some(value => value.id === ceremony.id)) {
            await request(Api.V1.Crematoriums.Ceremonies.destroy(ceremony.id));
          }
        }),
      );

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
