import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { PaymentReceived } from '@advitam/api/models/Payment/Received';
import { useBreakpoint } from '@advitam/react';
import { ResourceList, Text, FormattedPrice } from '@advitam/ui';
import type { Filter } from '@advitam/ui/components/ResourceList';
import { FILTERS } from 'containers/DealsList/constants';

import { PaymentProblem } from 'models/Deal/PaymentProblem';
import { DealState } from 'models/Deal/State';
import modelsMessages from 'models/messages';
import { makeSelectFuneralBrands, makeSelectFuneralTeam } from 'slices/data';

import messages from '../messages';
import { makeSelectFilters, makeSelectOutstandingsTotal } from '../selectors';
import style from '../style.scss';
import { setFilters } from '../slice';
import { fetchDeals } from '../thunk';
import { Filters } from '../types';

export default function PaymentFilters(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const brands = useSelector(makeSelectFuneralBrands());
  const team = useSelector(makeSelectFuneralTeam());
  const isAboveXL = useBreakpoint(parseInt(style.xl, 10));
  const isAboveXXL = useBreakpoint(parseInt(style.xxl, 10));
  const isAdvisorShown = useBreakpoint(
    parseInt(style.advisorFilterBreakpoint, 10),
  );
  const outstandingsTotal = useSelector(makeSelectOutstandingsTotal());
  const currentFilters = useSelector(makeSelectFilters());

  const onChange = useCallback(
    (filters: Filters): void => {
      dispatch(setFilters(filters));
      dispatch(fetchDeals());
    },
    [dispatch],
  );

  const stateFilter = FILTERS.STATE(intl, currentFilters.deals_state_key_in);

  const filters: Filter[] = [
    {
      ...stateFilter,
      items: stateFilter.items.filter(f => f.value !== DealState.KO),
    },
    FILTERS.TYPE(intl, currentFilters.deal_type_eq),
  ];

  filters.push({
    type: 'Select',
    name: 'payment_received_cont',
    placeholder: intl.formatMessage(messages.receivedStatus),
    items: [
      {
        value: PaymentReceived.YES,
        name: intl.formatMessage(messages.received),
      },
      {
        value: PaymentReceived.NO,
        name: intl.formatMessage(messages.notReceived),
      },
      { value: PaymentReceived.LOST, name: intl.formatMessage(messages.lost) },
    ],
    initialValue: currentFilters.payment_received_cont,
    isHidden: !isAboveXL,
  });
  filters.push({
    ...FILTERS.FUNERAL_BRAND(intl, brands, currentFilters.funeral_brand_id_eq),
    isHidden: !isAboveXXL,
  });
  filters.push({
    ...FILTERS.USER(intl, team, currentFilters.user_id_eq),
    isHidden: !isAdvisorShown,
  });
  filters.push({
    type: 'Select',
    name: 'payment_problem_in',
    placeholder: intl.formatMessage(messages.risk),
    items: [
      {
        value: PaymentProblem.NONE,
        name: intl.formatMessage(modelsMessages.none),
      },
      {
        value: PaymentProblem.RISKY,
        name: intl.formatMessage(modelsMessages.risky),
      },
      {
        value: PaymentProblem.OVERDUE,
        name: intl.formatMessage(modelsMessages.overdue),
      },
      {
        value: PaymentProblem.LITIGATION,
        name: intl.formatMessage(modelsMessages.litigation),
      },
    ],
    initialValue: currentFilters.payment_problem_in,
    multiple: true,
  });
  filters.push({
    type: 'Select',
    name: 'paid_eq',
    placeholder: intl.formatMessage(messages.paid),
    items: [
      { value: true, name: intl.formatMessage(messages.paid) },
      { value: false, name: intl.formatMessage(messages.notPaid) },
    ],
    initialValue: currentFilters.paid_eq,
  });
  filters.push({
    ...FILTERS.DATE(intl),
    isHidden: !isAboveXXL,
  });

  return (
    <>
      <ResourceList.Filters
        onChange={onChange}
        filters={filters}
        searchFilter={FILTERS.SEARCH(intl, currentFilters.q)}
        className={style.filters}
      />
      <Text tagName="div" className={style.outstandings}>
        <FormattedMessage
          id={messages.outstandingsTotal.id}
          values={{
            total: <FormattedPrice value={outstandingsTotal} />,
          }}
        />
      </Text>
    </>
  );
}
