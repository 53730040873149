import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import RadialProgressBar from 'components/RadialProgressBar';
import ProgressBar from 'components/ProgressBar';
import messages from './messages';
import style from '../Account.module.scss';

const computeProgress = (done, goal) => {
  const dealsProgress = Math.round((done / goal) * 100);
  let dealsExtraProgress;
  if (done > goal) {
    dealsExtraProgress = Math.round(((done - goal) / goal) * 100);
  } else {
    dealsExtraProgress = false;
  }
  return { progress: dealsProgress, extraProgress: dealsExtraProgress };
};

const StatsPerMonth = ({ userStats, teamStats }) => {
  const userProgress = computeProgress(
    userStats.deals_done,
    userStats.deals_goal,
  );
  const teamProgress = computeProgress(
    teamStats.deals_done,
    teamStats.deals_goal,
  );

  return (
    <div className={style.stats_container}>
      <div className={style.monthly_stats_row}>
        <div className={`${style.stat} ${style.monthly_stat}`}>
          <FormattedMessage id={messages.userDeals.id} />
          <RadialProgressBar
            progress={userProgress.progress}
            extraProgress={userProgress.extraProgress}
          >
            <span
              className={`${style.stat_text} ${style.radial_progress_text}`}
            >
              {userStats.deals_done}/{userStats.deals_goal}
            </span>
          </RadialProgressBar>
        </div>

        <div className={`${style.stat} ${style.monthly_stat}`}>
          <FormattedMessage id={messages.teamDeals.id} />
          <RadialProgressBar
            progress={teamProgress.progress}
            extraProgress={teamProgress.extraProgress}
          >
            <span
              className={`${style.stat_text} ${style.radial_progress_text}`}
            >
              {teamStats.deals_done}/{teamStats.deals_goal}
            </span>
          </RadialProgressBar>
        </div>

        <div className={`${style.stat} ${style.monthly_stat}`}>
          <FormattedMessage id={messages.conversionRate.id} />
          <div className={style.conversion_rate}>
            <span className={style.stat_text}>
              <FormattedMessage
                id={messages.conversionRateInfo.id}
                values={{ conversionRate: userStats.conversion_rate }}
              />
            </span>
            <ProgressBar
              progress={userStats.conversion_rate}
              goal={userStats.conversion_rate_goal}
            />
          </div>
        </div>
      </div>

      <div className={style.monthly_stats_row}>
        <div className={`${style.stat} ${style.monthly_stat}`}>
          <FormattedMessage id={messages.dealsErrors.id} />
          <div
            className={`${style.deal_errors} ${
              userStats.deals_errors > 0 ? style.have_errors : ''
            }`}
          >
            {userStats.deals_errors}
          </div>
        </div>
        <div className={`${style.stat} ${style.monthly_stat}`}>
          <FormattedMessage id={messages.gradeAverage.id} />
          <div
            className={`${style.grade} ${
              userStats.grade_average < 3 ? style.bad : ''
            }`}
          >
            <FormattedMessage
              id={messages.gradeAverageValue.id}
              values={{ grade: userStats.grade_average }}
            />
          </div>
        </div>
        <div className={`${style.stat} ${style.monthly_stat}`}>
          <FormattedMessage id={messages.gradeCeremonyAverage.id} />
          <div
            className={`${style.grade} ${
              userStats.ceremony_grade_average < 3 ? style.bad : ''
            }`}
          >
            <FormattedMessage
              id={messages.gradeCeremonyAverageValue.id}
              values={{ grade: userStats.ceremony_grade_average }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StatsPerMonth.propTypes = {
  userStats: PropTypes.object.isRequired,
  teamStats: PropTypes.object.isRequired,
};

export default StatsPerMonth;
