import { Component, ComponentType, CSSProperties } from 'react';
import { IntlShape } from 'react-intl';
import Tippy from '@tippyjs/react';

import { assert } from '@advitam/support';
import { Button } from '@advitam/ui';
import TrashIcon from '@advitam/ui/images/icons/trash.svg';
import { safeFormatMessage } from 'utils/functions.typed';

import { InputTarget } from '../../slice';
import { InputDragActionType, onInputDragStart } from '../utils';
import style from '../Engine.module.scss';
import { BACKGROUND_UPSCALE } from '../constants';
import sectionMessages from '../../sectionMessages';
import variableMessages from '../../variableMessages';

interface InputProps<ValueType extends string | boolean | undefined> {
  intl: IntlShape;
  target: InputTarget;
  position: [number, number];
  value: ValueType;
  variable?: string;
  dictionary: Record<string, unknown>;
  isEditMode: boolean;
  isPreviewMode: boolean;
  onChange: (target: InputTarget, value: ValueType) => void;
  onDelete: (target: InputTarget) => void;
  setFontSize: (target: InputTarget, fontSize: number) => void;
}

interface InputState {
  isFocused: boolean;
}

export type InputType = ComponentType<
  Omit<InputProps<string | boolean | undefined>, 'intl'>
>;

export default abstract class Input<
  ValueType extends string | boolean | undefined,
  T = Record<string, never> | undefined
> extends Component<InputProps<ValueType> & T, InputState> {
  private static Z_INDEX_BASE = 1;

  private static Z_INDEX_OVER = Input.Z_INDEX_BASE + 1;

  constructor(props: InputProps<ValueType> & T) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  get value(): string {
    const { dictionary, variable, value } = this.props;

    if (value) {
      return value as string;
    }
    if (!variable) {
      return '';
    }
    assert(typeof variable === 'string');
    return ((dictionary[variable] as unknown) as ValueType)?.toString() || '';
  }

  protected getStyle(): CSSProperties {
    const { isFocused } = this.state;
    const {
      position: [left, top],
      isEditMode,
    } = this.props;

    const scale = isEditMode ? 1 : BACKGROUND_UPSCALE;
    return {
      position: 'absolute',
      color: 'black',
      left: `${left * scale}px`,
      top: `${top * scale}px`,
      zIndex: isFocused ? Input.Z_INDEX_OVER : Input.Z_INDEX_BASE,
      WebkitTransformOrigin: 'top left',
      WebkitTransform: `scale(${scale})`,
    };
  }

  protected getPlaceholder(): string | undefined {
    const { variable, intl } = this.props;
    if (!variable) {
      return undefined;
    }

    const [section, varName] = variable.split('__', 2);
    if (!varName) {
      // e.g. `current_date`
      return safeFormatMessage(intl, variableMessages, section);
    }

    return `${safeFormatMessage(
      intl,
      sectionMessages,
      section,
    )} - ${safeFormatMessage(intl, variableMessages, varName)}`;
  }

  private setIsFocused(isFocused: boolean): void {
    this.setState(prev => ({ ...prev, isFocused }));
  }

  abstract renderInput(): JSX.Element | null;

  // eslint-disable-next-line class-methods-use-this
  protected renderInner(): JSX.Element | null {
    return null;
  }

  render(): JSX.Element | null {
    const { isEditMode, intl, target, onDelete, variable } = this.props;
    const { isFocused } = this.state;

    let tooltip: string | undefined;
    if (variable && isEditMode) {
      const section = safeFormatMessage(
        intl,
        sectionMessages,
        variable.split('__')[0],
      );
      const varString = safeFormatMessage(
        intl,
        variableMessages,
        variable.split('__')[1],
      );
      tooltip = [section, varString].join(' - ');
    }

    return (
      <Tippy content={tooltip} visible={tooltip ? undefined : false}>
        <div
          style={this.getStyle()}
          draggable
          onDragStart={(event): void =>
            onInputDragStart(event, target, { type: InputDragActionType.MOVE })
          }
          onDrop={(event): void => event.preventDefault()}
          onFocus={(): void => this.setIsFocused(true)}
          onBlur={({ currentTarget }): void => {
            setTimeout(() => {
              if (!currentTarget.contains(document.activeElement)) {
                this.setIsFocused(false);
              }
            }, 0);
          }}
        >
          {this.renderInput()}
          {this.renderInner()}
          {isFocused && (
            <Button
              primary
              className={[style.input_button, style.trash_button].join(' ')}
              onClick={(): void => onDelete(target)}
              icon={<TrashIcon />}
            />
          )}
        </div>
      </Tippy>
    );
  }
}
