import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  actions: { id: 'app.containers.Supplier.sections.Warehouses.actions' },
  add: { id: 'app.containers.Supplier.sections.Warehouses.add' },
  city: { id: 'app.containers.Supplier.sections.Warehouses.city' },
  deletionConfirmationTitle: {
    id: 'app.containers.Supplier.sections.Warehouses.deletionConfirmationTitle',
  },
  deletionConfirmationText: {
    id: 'app.containers.Supplier.sections.Warehouses.deletionConfirmationText',
  },
  department: { id: 'app.containers.Supplier.sections.Warehouses.department' },
  disableConfirmationTitle: {
    id: 'app.containers.Supplier.sections.Warehouses.disableConfirmationTitle',
  },
  disableConfirmationText: {
    id: 'app.containers.Supplier.sections.Warehouses.disableConfirmationText',
  },
  disabled: { id: 'app.containers.Supplier.sections.Warehouses.disabled' },
  enabled: { id: 'app.containers.Supplier.sections.Warehouses.enabled' },
  name: { id: 'app.containers.Supplier.sections.Warehouses.name' },
  status: { id: 'app.containers.Supplier.sections.Warehouses.status' },
});
