import { PaymentState } from '@advitam/api/models/Payment/State';
import { isUserFuneralDirectorOrAbove } from 'components/Authorization/utils';
import { FUNERAL_ADVISOR_ALLOWED_STATES } from './constants';

export const canUserEditPayment = (
  paymentState: PaymentState,
  isNewPayment: boolean,
  userRole: number,
): boolean =>
  isUserFuneralDirectorOrAbove(userRole) ||
  isNewPayment ||
  FUNERAL_ADVISOR_ALLOWED_STATES.includes(paymentState);
