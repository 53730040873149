import { CSSProperties, useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import Api, { request } from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import { AutocompleteResult } from '@advitam/api/v1/Autocompletes';
import { FormUI, isRequired } from '@advitam/ui';

import { WarehouseProductsForm } from '../../types';
import style from '../List.module.scss';
import {
  useProduct,
  useProductIndex,
  useProductPrefix,
} from '../../ProductListProvider';

const FIELD_PLACEHOLDER = '__';

interface AdvitamRefCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function AdvitamRefCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: AdvitamRefCellProps): JSX.Element | null {
  const form = useForm<WarehouseProductsForm>();
  const { values } = useFormState<WarehouseProductsForm>();
  const { sectionValues: products } = values;
  const warehouseProduct = useProduct(rowIndex);
  const { product } = warehouseProduct;
  const prefix = useProductPrefix(rowIndex);
  const index = useProductIndex(rowIndex);

  const onAutocompleteSelect = useCallback(
    async (result: AutocompleteResult | undefined): Promise<void> => {
      if (!result) {
        return;
      }

      try {
        const { body } = await request(Api.V1.Products.show(result.id));
        const newProducts = [...products];
        newProducts.splice(index, 1, {
          ...warehouseProduct,
          product: body,
        });
        form.change('sectionValues', newProducts);
      } catch {
        // do nothing
      }
    },
    [form, products, index, warehouseProduct],
  );

  return (
    <div
      style={wrapperStyle}
      className={[className, style.advitam_cell].join(' ')}
    >
      {product ? (
        product.advitam_name
      ) : (
        <Field name={`${prefix}.product.id`} validate={isRequired}>
          {({ meta }): JSX.Element => (
            <FormUI.Autosuggest
              placeholder={FIELD_PLACEHOLDER}
              error={Boolean(meta.touched && meta.error)}
              className={style.autocomplete}
              endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.products)}
              searchParams={{ with_variants: 'true' }}
              requestHeaders={{ ...getTokens() }}
              onChange={onAutocompleteSelect}
            />
          )}
        </Field>
      )}
    </div>
  );
}
