import { Model } from '../../../Model'

interface SupplierWarehouseZoneBase {
  id: number
  name: string
  last_update_user_id: number | null
  current_update_user_id: number | null
}

export interface SupplierWarehouseZoneJSON extends SupplierWarehouseZoneBase {
  last_update_dt: string | null
  current_update_dt: string | null
}

export interface SupplierWarehouseZone extends SupplierWarehouseZoneBase {
  last_update_dt: Date | null
  current_update_dt: Date | null
}

export class SupplierWarehouseZone extends Model<SupplierWarehouseZoneJSON> {
  constructor(data: SupplierWarehouseZoneJSON) {
    super(data)
    this.current_update_dt = Model.parseDate(data.current_update_dt)
    this.last_update_dt = Model.parseDate(data.last_update_dt)
  }
}

export type {
  SupplierWarehouseZoneFuneralWorkJSON,
  SupplierWarehouseZoneFuneralWorkTypeJSON,
} from './FuneralWork'
