import { assert } from '@advitam/support'

import { authenticate, withSessionId } from '../../../lib/decorators'
import { del, get, post, put } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'
import { AgeRangeJSON } from '../../../models/Crematorium/AgeRange'

export class AgeRanges {
  @authenticate
  static index(crematoriumId: number): ApiRequestDescriptor<AgeRangeJSON[]> {
    return get(`/api/v1/crematoriums/${crematoriumId}/age_ranges`)
  }

  @authenticate
  @withSessionId
  static create(
    crematoriumId: number,
    ageRange: Omit<AgeRangeJSON, 'id'>,
  ): ApiRequestDescriptor<AgeRangeJSON> {
    return post(`/api/v1/crematoriums/${crematoriumId}/age_ranges`, ageRange)
  }

  @authenticate
  @withSessionId
  static update(ageRange: AgeRangeJSON): ApiRequestDescriptor<AgeRangeJSON> {
    assert(ageRange.id !== undefined)
    return put(`/api/v1/crematoriums/age_ranges/${ageRange.id}`, ageRange)
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/crematoriums/age_ranges/${id}`)
  }
}
