import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from 'api';
import { DealDocument } from 'models/Deal/Document';
import { fetchDocument } from 'containers/Documents/thunk';

import { DOCUMENT_GENERATION_MODAL } from './constants';
import { initialFetch, fetchVariables, generateDocument } from './thunk';

export interface State {
  documentsGenerationQueue: DealDocument[];
  variables: string[];
  dictionary: Record<string, unknown> | null;
  isLoading: boolean;
  isGenerationLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [DOCUMENT_GENERATION_MODAL]: State;
}

export const initialState: State = {
  documentsGenerationQueue: [],
  variables: [],
  dictionary: null,
  isLoading: false,
  isGenerationLoading: false,
  error: null,
};

const slice = createSlice({
  name: DOCUMENT_GENERATION_MODAL,
  initialState,
  reducers: {
    resetState(): State {
      return initialState;
    },
    setDocumentsGenerationQueue(
      state,
      { payload }: PayloadAction<DealDocument[]>,
    ): State {
      return {
        ...state,
        documentsGenerationQueue: payload,
      };
    },
  },
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(initialFetch.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(initialFetch.fulfilled, state => {
      state.isLoading = false;
    });

    builder.addCase(fetchVariables.fulfilled, (state, { payload }) => {
      state.variables = payload;
    });
    builder.addCase(fetchVariables.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
      state.isLoading = false;
    });

    builder.addCase(generateDocument.pending, state => {
      state.isGenerationLoading = true;
    });
    builder.addCase(generateDocument.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
      state.isLoading = false;
    });

    builder.addCase(fetchDocument.fulfilled, (state, { payload }) => {
      const documentIndex = state.documentsGenerationQueue.findIndex(
        doc => doc.id === payload.id,
      );

      if (documentIndex !== -1) {
        state.documentsGenerationQueue.splice(documentIndex, 1);
        state.isGenerationLoading = false;
      }
    });
    builder.addCase(fetchDocument.rejected, (state, { meta, payload }) => {
      const documentIndex = state.documentsGenerationQueue.findIndex(
        doc => doc.id === meta.arg,
      );

      if (documentIndex !== -1) {
        state.error = ApiError.serialize(payload);
        state.documentsGenerationQueue.splice(documentIndex, 1);
        state.isGenerationLoading = false;
      }
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { resetState, setDocumentsGenerationQueue } = slice.actions;
export default slice;
