import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { FlightIndexJSON } from '@advitam/api/models/Flight/FlightIndex';

import { FLIGHTS } from './constants';
import { fetchFlights } from './thunk';
import { FlightsFilters } from './types';

export interface State {
  filters: FlightsFilters;
  flights: FlightIndexJSON[];
  currentPage: number;
  hasMore: boolean;
  lastRequestId: string | null;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [FLIGHTS]: State;
}

export const initialState: State = {
  filters: {},
  flights: [],
  currentPage: 1,
  hasMore: true,
  lastRequestId: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: FLIGHTS,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters: (
      state,
      { payload }: PayloadAction<Partial<FlightsFilters>>,
    ) => {
      state.filters = { ...state.filters, ...payload };
      state.flights = [];
      state.currentPage = 1;
      state.hasMore = true;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFlights.pending, (state, { meta }) => {
      state.isLoading = true;
      state.lastRequestId = meta.requestId;
    });
    builder.addCase(fetchFlights.fulfilled, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }

      const { flights, hasMore } = payload;
      state.flights.push(...flights);
      state.hasMore = hasMore;
      state.currentPage += 1;
      state.isLoading = false;
    });
    builder.addCase(fetchFlights.rejected, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }

      state.error = ApiError.serialize(payload);
      state.isLoading = false;
      state.hasMore = false;
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters } = slice.actions;
export default slice;
