import { PrefectureJSON } from '@advitam/api/models/Prefecture';
import { formatEntity } from '../Entity';

export function formatName(prefecture: PrefectureJSON): string {
  return formatEntity(prefecture);
}

export function FormattedName(prefecture: PrefectureJSON): JSX.Element {
  return <>{formatName(prefecture)}</>;
}
