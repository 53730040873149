import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-final-form';

import Button from '@advitam/ui/components/Button';
import ResourceList from '@advitam/ui/components/ResourceList';

import messages from '../messages';
import style from '../SupportingDocuments.module.scss';
import { setFilters } from '../slice';
import { makeSelectFilters } from '../selectors';
import {
  DocumentFilters,
  DocumentsForm,
  UnsavedSupportingDocument,
} from '../types';

export default function Filters(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const form = useForm<DocumentsForm>();

  const filters = useSelector(makeSelectFilters());

  const onChange = useCallback(
    (values: DocumentFilters): void => {
      dispatch(setFilters(values));
    },
    [dispatch],
  );

  const onAddDocument = useCallback(() => {
    const { sectionValues } = form.getState().values;
    form.change('sectionValues', [
      {} as UnsavedSupportingDocument,
      ...sectionValues,
    ]);
  }, [form]);

  return (
    <ResourceList.Filters
      filters={[]}
      onChange={onChange}
      className={style.filters}
      searchFilter={{
        name: 'q',
        placeholder: intl.formatMessage(messages.search),
        initialValue: filters.q,
      }}
    >
      <Button
        primary
        text={<FormattedMessage id={messages.add.id} />}
        onClick={onAddDocument}
      />
    </ResourceList.Filters>
  );
}
