import BaseSpinner from './Spinner';
import style from './style.scss';

// The parent must be in absolute position
export default function RelativeSpinner() {
  return (
    <div className={style.relative}>
      <BaseSpinner />
    </div>
  );
}
