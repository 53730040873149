import { useMemo } from 'react';
import { FormattedDate } from 'react-intl';

import {
  EntityInvoice,
  EntityInvoiceJSON,
} from '@advitam/api/models/EntityInvoice';
import { useBreakpoint } from '@advitam/react';
import { FormattedPrice, Person, ResourceList } from '@advitam/ui';

import InvoiceIcon from './InvoiceIcon';
import OpenDeal from './OpenDeal';
import ValidateIcon from './ValidateIcon';
import EditPrestationIcon from './Edit/EditPrestationIcon';
import style from './List.module.scss';
import PaymentIcon from './PaymentIcon';

interface RowProps {
  invoice: EntityInvoiceJSON;
}

export default function Row({ invoice }: RowProps): JSX.Element {
  const invoiceInstance = useMemo(() => new EntityInvoice(invoice), [invoice]);
  const [prestation] = invoiceInstance.prestations;

  const isAboveXXL = useBreakpoint(parseInt(style.xxl, 10));
  const person = prestation.deal.defunct || prestation.deal.owner;

  return (
    <ResourceList.Row className={style.row}>
      {isAboveXXL && (
        <ResourceList.Cell>
          <FormattedDate value={prestation.deal.ceremony_dt} />
        </ResourceList.Cell>
      )}
      <ResourceList.Cell>{invoice.entity.name}</ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>{invoice.group.name}</ResourceList.Cell>
      <ResourceList.Cell>
        {person && <Person.FormattedName person={person} />}
      </ResourceList.Cell>
      <ResourceList.Cell>{prestation.description}</ResourceList.Cell>
      {isAboveXXL && <ResourceList.Cell>{prestation.amount}</ResourceList.Cell>}
      <ResourceList.Cell>
        <FormattedPrice value={invoice.cost_tax_excl || prestation.cost_ht} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedPrice value={invoice.cost_tax_incl || prestation.cost_ttc} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <div className={style.actions}>
          <OpenDeal prestation={invoice.prestations[0]} />
          <InvoiceIcon invoice={invoice} />
          <ValidateIcon invoice={invoice} />
          <PaymentIcon invoice={invoiceInstance} />
          <EditPrestationIcon
            invoice={invoice}
            prestation={invoice.prestations[0]}
          />
        </div>
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
