// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Password-module__visible_btn--nMz3H{padding:0}`, "",{"version":3,"sources":["webpack://./../ui/components/Form/UI/Password/Password.module.scss"],"names":[],"mappings":"AAAA,qCACE,SAAA","sourcesContent":[".visible_btn {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visible_btn": `Password-module__visible_btn--nMz3H`
};
export default ___CSS_LOADER_EXPORT___;
