import { CSSProperties, useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { Button } from '@advitam/ui';
import Trash from '@advitam/ui/images/icons/trash.svg';

import { makeSelectCanEdit } from '../../../../../selectors';
import { WarehouseProductsForm } from '../../types';
import style from '../List.module.scss';
import { useProductIndex } from '../../ProductListProvider';

interface ActionsCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function ActionsCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: ActionsCellProps): JSX.Element | null {
  const canUserEdit = useSelector(makeSelectCanEdit());

  const form = useForm<WarehouseProductsForm>();
  const { values } = useFormState<WarehouseProductsForm>();
  const { sectionValues: products } = values;
  const index = useProductIndex(rowIndex);

  const onDelete = useCallback((): void => {
    const newProducts = [...products];
    newProducts.splice(index, 1);
    form.change('sectionValues', newProducts);
  }, [form, products, index]);

  return (
    <div style={wrapperStyle} className={[className, style.actions].join(' ')}>
      <Button
        outline
        disabled={!canUserEdit}
        className={style.delete}
        onClick={onDelete}
        icon={<Trash />}
      />
    </div>
  );
}
