import { ReactNode, useCallback } from 'react'

import Api from '@advitam/api'
import { getTokens } from '@advitam/api/lib/tokens'
import type { AutocompleteResult } from '@advitam/api/v1/Autocompletes'

import ApiAutocomplete from './ApiAutocomplete'

function buildNewValue(description: string): Omit<AutocompleteResult, 'id'> {
  return { description }
}

const KEYS = ['description'] as const

interface FuneralWorkTypeAutocompleteProps {
  placeholder?: string
  required?: boolean
  allowNewValue?: boolean
  label?: ReactNode
  tooltip?: ReactNode
  name: string
  className?: string
  disabled?: boolean
}

export default function FuneralWorkTypeAutocomplete({
  placeholder,
  name,
  label,
  tooltip,
  allowNewValue,
  required,
  disabled,
  className,
}: FuneralWorkTypeAutocompleteProps): JSX.Element {
  const getDisplayValue = useCallback((data: AutocompleteResult): string => data.description, [])

  return (
    <ApiAutocomplete<AutocompleteResult>
      placeholder={placeholder}
      name={name}
      label={label}
      tooltip={tooltip}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.funeralWorkTypes)}
      required={required}
      buildNewValue={allowNewValue ? buildNewValue : undefined}
      className={className}
      disabled={disabled}
      getDisplayValue={getDisplayValue}
      keys={KEYS}
      requestHeaders={{ ...getTokens() }}
      minCharsRequest={2}
    />
  )
}
