import { useCallback } from 'react';
import { deburr } from 'lodash';
import { useFormState } from 'react-final-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import type { ConcessionTermJSON } from '@advitam/api/models/Concession';
import { SelectableAutocomplete } from '@advitam/ui';
import type { Result } from '@advitam/ui/components/Form/UI/Autosuggest/Selectable';

import { ConcessionDuration } from '../../../../constants';
import messages from '../../messages';
import type { GraveyardMiscForm, UnsavedConcessionTerm } from '../../types';

const FIELD_NAME = 'sectionValues.concessionTerms';

function getBaseSuggestions(
  intl: IntlShape,
): Result<ConcessionTermJSON | UnsavedConcessionTerm>[] {
  return [
    {
      name: intl.formatMessage(messages.years, { years: 5 }),
      value: { id: undefined, term: ConcessionDuration.FIVE_YEARS },
    },
    {
      name: intl.formatMessage(messages.years, { years: 10 }),
      value: { id: undefined, term: ConcessionDuration.TEN_YEARS },
    },
    {
      name: intl.formatMessage(messages.years, { years: 30 }),
      value: { id: undefined, term: ConcessionDuration.THIRTY_YEARS },
    },
    {
      name: intl.formatMessage(messages.years, { years: 50 }),
      value: { id: undefined, term: ConcessionDuration.FIFTY_YEARS },
    },
    {
      name: intl.formatMessage(messages.perpetually),
      value: { id: undefined, term: ConcessionDuration.PERPETUAL },
    },
  ];
}

function parseFreeSuggestion(query: string): number | null {
  const freeSuggestionString = query.match(/\d+/)?.[0];
  return freeSuggestionString ? parseInt(freeSuggestionString, 10) : null;
}

function getSuggestions(
  values: Array<ConcessionTermJSON | UnsavedConcessionTerm>,
  intl: IntlShape,
): Result<ConcessionTermJSON | UnsavedConcessionTerm>[] {
  return values.map(term => {
    if (term.term === -1) {
      return {
        name: intl.formatMessage(messages.perpetually),
        value: term,
      };
    }

    return {
      name: intl.formatMessage(messages.years, { years: term.term }),
      value: term,
    };
  });
}

export default function TermsInput(): JSX.Element {
  const intl = useIntl();
  const { values } = useFormState<GraveyardMiscForm>();

  const { concessionTerms } = values.sectionValues;
  const suggestions = getSuggestions(concessionTerms, intl);

  const fetchSuggestions = useCallback(
    (query: string) => {
      const allSuggestions = getBaseSuggestions(intl);

      const freeSuggestion = parseFreeSuggestion(query);
      const isDuplicate = allSuggestions.some(
        suggestion => suggestion.value.term === freeSuggestion,
      );
      if (freeSuggestion && !isDuplicate) {
        allSuggestions.push({
          name: intl.formatMessage(messages.years, { years: freeSuggestion }),
          value: { id: undefined, term: freeSuggestion },
        });
      }

      const availableSuggestions = allSuggestions
        .filter(
          suggestion =>
            !concessionTerms.some(term => suggestion.value.term === term.term),
        )
        .sort((a, b) => a.value.term - b.value.term);

      const results = availableSuggestions.filter(suggestion =>
        deburr(suggestion.name)
          .toLowerCase()
          .includes(deburr(query).toLowerCase()),
      );

      return Promise.resolve(results);
    },
    [concessionTerms, intl],
  );

  return (
    <SelectableAutocomplete
      name={FIELD_NAME}
      suggestions={suggestions}
      fetchSuggestions={fetchSuggestions}
      label={<FormattedMessage id={messages.availableDurations.id} />}
      minCharsRequest={1}
    />
  );
}
