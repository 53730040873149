import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { TextInput, NumberInput } from 'components/Input';
import messages from './messages';

const Semelle = ({ concession, updateConcession, intl }) => {
  /**
   * Function to update concession
   *
   * @param {string} name key to update
   * @param {string} value updated value
   */
  const onChange = ({ target: { name, value } }) => {
    updateConcession({
      type: {
        ...concession.type,
        info: {
          ...concession.type.info,
          [name]: value,
        },
      },
    });
  };

  return (
    <div className="concession__field">
      <h5>
        <FormattedMessage {...messages.semelle} />
      </h5>
      <div>
        <FormattedMessage {...messages.dimensions} />
        <div className="concession__field__dimensions">
          <NumberInput
            placeholder="240"
            name="semelle_length"
            value={concession.type.info.semelle_length || ''}
            onChange={onChange}
            max={999}
          />
          x
          <NumberInput
            placeholder="140"
            name="semelle_width"
            value={concession.type.info.semelle_width || ''}
            onChange={onChange}
            max={999}
          />
        </div>
      </div>
      <FormattedMessage {...messages.slope} />
      <div>
        <FormattedMessage {...messages.leftHead} />
        <TextInput
          onChange={onChange}
          name="top_left_slope"
          placeholder={intl.formatMessage(messages.placeholder)}
          value={concession.type.info.top_left_slope || ''}
        />
      </div>
      <div>
        <FormattedMessage {...messages.rightHead} />
        <TextInput
          onChange={onChange}
          name="top_right_slope"
          placeholder={intl.formatMessage(messages.placeholder)}
          value={concession.type.info.top_right_slope || ''}
        />
      </div>
      <div>
        <FormattedMessage {...messages.leftFoot} />
        <TextInput
          onChange={onChange}
          name="bot_left_slope"
          placeholder={intl.formatMessage(messages.placeholder)}
          value={concession.type.info.bot_left_slope || ''}
        />
      </div>
      <div>
        <FormattedMessage {...messages.rightFoot} />
        <TextInput
          onChange={onChange}
          name="bot_right_slope"
          placeholder={intl.formatMessage(messages.placeholder)}
          value={concession.type.info.bot_right_slope || ''}
        />
      </div>
    </div>
  );
};

Semelle.propTypes = {
  /** concession data */
  concession: PropTypes.object.isRequired,
  /** function to update a concession */
  updateConcession: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Semelle);
