import { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../../messages';
import style from '../List.module.scss';
import { useProduct } from '../../ProductListProvider';

interface CategoryCellProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function CategoryCell({
  rowIndex,
  style: wrapperStyle,
  className,
}: CategoryCellProps): JSX.Element | null {
  const { product } = useProduct(rowIndex);

  return (
    <div
      style={wrapperStyle}
      className={[className, style.category_cell].join(' ')}
    >
      {product?.type && (
        <FormattedMessage id={messages[`${product.type}Category`].id} />
      )}
    </div>
  );
}
