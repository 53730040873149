import { Field } from 'react-final-form'

interface InputProps {
  name: string
}

export default function HiddenInput({ name }: InputProps): JSX.Element {
  return (
    <Field name={name}>
      {(props): JSX.Element => (
        <input type="hidden" name={props.input.name} value={props.input.value as string} />
      )}
    </Field>
  )
}
