import type { TippyProps } from '@tippyjs/react'
import type { ReactNode } from 'react'

import Info from '../../../../images/icons/info-circle.svg'
import TooltipBase from '../../../Tooltip'
import style from '../UI.module.scss'

interface TooltipProps {
  content: ReactNode
  placement?: TippyProps['placement']
}

export default function Tooltip({ content, placement }: TooltipProps): JSX.Element {
  return (
    <TooltipBase content={content} placement={placement}>
      <Info className={style.tooltip_icon} />
    </TooltipBase>
  )
}
