import { LabelWithAction } from 'components/Label';
import { DealDocument } from 'models/Deal/Document';

import style from './style.scss';

interface FileListProps {
  isOpen: boolean;
  documents: DealDocument[];
  open: (link: string | null) => void;
  deselect: (file: DealDocument) => void;
}

export default function FileList({
  isOpen,
  documents,
  deselect,
  open,
}: FileListProps): JSX.Element | null {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={style.list}>
      {documents.map(doc => (
        <LabelWithAction
          key={doc.id}
          name={doc.name}
          className={`label label--light ${style.label}`}
          onClick={(): void => open(doc.file)}
          onRemove={(): void => deselect(doc)}
          isDoubleClick
        />
      ))}
    </div>
  );
}
