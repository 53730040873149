export enum DealState {
  QUOTE = 'quote',
  IN_PROGRESS = 'order',
  DONE = 'ok',
  KO = 'ko',
  FUNERAL_CONTRACT = 'funeral_contract',
  FUNERAL_CONTRACT_OK = 'funeral_contract_ok',
  STAGING = 'staging',
}

export const DealStateOrder = {
  [DealState.IN_PROGRESS]: 1,
  [DealState.QUOTE]: 2,
  [DealState.DONE]: 3,
  [DealState.KO]: 4,
  [DealState.FUNERAL_CONTRACT_OK]: 5,
  [DealState.FUNERAL_CONTRACT]: 6,
  [DealState.STAGING]: 7,
};
