import { useSelector } from 'react-redux';
import { makeSelectItems } from '../selectors';
import Header from './Header';
import Todo from './Todo';
import style from './List.module.scss';

export default function List(): JSX.Element {
  const items = useSelector(makeSelectItems());

  return (
    <>
      <Header />
      <ul className={style.list}>
        {items.map(item => (
          <li key={item.id}>
            <Todo item={item} />
          </li>
        ))}
      </ul>
    </>
  );
}
