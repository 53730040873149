import { deleteTokens } from './tokens'

let onTokensExpiredCallback: () => void

export function setOnTokensExpiredCallback(callback: () => void): void {
  onTokensExpiredCallback = callback
}

export function onTokensExpired(): void {
  deleteTokens()
  onTokensExpiredCallback()
}
