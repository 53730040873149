import { assert } from 'lib/Assert';
import { Deal, DealJSON, DealParentType } from 'models/Deal';
import { ItemDelivery } from 'models/Deal/ItemDetails';

export interface ItemPayload {
  comment: string;
  client_id?: number;
  delivery: ItemDelivery;
  parent_type?: DealParentType;
  parent_id?: number;
  products: Record<number, number>;
}

export function serialize(deal: Deal | DealJSON): ItemPayload {
  assert(deal.item !== undefined);

  return {
    comment: deal.commentary,
    client_id: deal.clients[0].id,
    delivery: { ...deal.item.delivery },
    parent_type: deal.item.parent_type,
    parent_id: deal.item.parent_id,
    products: deal.item.products.reduce(
      (prev, product) => ({
        ...prev,
        [product.productObject.id]: product.amount,
      }),
      {},
    ),
  };
}
