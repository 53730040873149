import { ErrorCode } from '../errors'
import { ValidatorReturnType } from './Validator'
import { ValueType } from './ValueType'

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function isEmail<T extends ValueType>(value: T): ValidatorReturnType {
  if (typeof value !== 'string' || re.test(value.toLowerCase())) {
    return undefined
  }

  return ErrorCode.EMAIL_INVALID
}
