import { useContext } from 'react'
import ImageContext from './Context'

export interface ImageProps {
  src: unknown
  alt?: string
  width?: number
  height?: number
  className?: string
}

/**
 * Loader-agnostic image component.
 *
 * By default, it assumes that the loader will return an url useable as the src
 * property. This behavior is overridable with a custom ImageProvider. For
 * example, if your loader exports a component :
 *
 * ```ts
 * import { ImageContext, ImageProps } from '@advitam/ui';
 *
 * function MyImageProvider({
 *   src: ImageComponent,
 *   className,
 * }: ImageProps): JSX.Element {
 *   assert(typeof ImageComponent === 'function');
 *   return <ImageComponent className={className} />;
 * }
 *
 * function render(): JSX.Element {
 *   return (
 *     <ImageContext.Provider value={MyImageProvider}>
 *       <AppOrComponent />
 *     </ImageContext.Provider>
 *   );
 * }
 * ```
 */

export default function Image({ src, alt, width, height, className }: ImageProps): JSX.Element {
  const ImageImplementation = useContext(ImageContext)

  return (
    <ImageImplementation src={src} alt={alt} width={width} height={height} className={className} />
  )
}

export { ImageContext }
