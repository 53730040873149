import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { requestAsync } from 'api';
import { setDictionary } from 'containers/DocumentTemplateEditor/slice';
import { DealDocument } from 'models/Deal/Document';
import { Document } from 'models/Document';
import { assert } from 'lib/Assert';

import { DOCUMENT_GENERATION_MODAL } from './constants';
import { makeSelectDocumentsGenerationQueue } from './selectors';
import { AppStateSubset } from './slice';

export const fetchVariables = createAsyncThunk(
  `${DOCUMENT_GENERATION_MODAL}/FETCH_VARIABLES`,
  async (document: DealDocument, { rejectWithValue }) => {
    try {
      const { body } = await requestAsync(
        Api.V1.DocumentTypes.Variables.show(document.document_type.slug),
      );
      assert(body !== null);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

interface FetchDictionaryArgs {
  dealId: number;
  document: Document;
}

export const fetchDictionary = createAsyncThunk(
  `${DOCUMENT_GENERATION_MODAL}/FETCH_DICTIONARY`,
  async (
    { dealId, document }: FetchDictionaryArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { body } = await requestAsync(
        Api.V1.Deals.Documents.Dictionaries.show(dealId, document.id),
      );
      assert(body !== null);
      dispatch(setDictionary(body));
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const initialFetch = createAsyncThunk(
  `${DOCUMENT_GENERATION_MODAL}/INITIAL_FETCH`,
  async (dealId: number, { getState, dispatch }) => {
    const state = getState() as AppStateSubset;
    const documentsGenerationQueue = makeSelectDocumentsGenerationQueue()(
      state,
    );

    if (
      documentsGenerationQueue.length > 0 &&
      documentsGenerationQueue[0].document
    ) {
      await Promise.all([
        dispatch(fetchVariables(documentsGenerationQueue[0])),
        dispatch(
          fetchDictionary({
            dealId,
            document: documentsGenerationQueue[0].document,
          }),
        ),
      ]);
    }
  },
);

export const generateDocument = createAsyncThunk(
  `${DOCUMENT_GENERATION_MODAL}/GENERATE_DOCUMENT`,
  async (document: DealDocument, { rejectWithValue }) => {
    try {
      await requestAsync(
        Api.V1.Deals.Documents.Generation.create([document.id]),
      );
      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
