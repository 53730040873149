import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';

import { DECEASED_MODAL } from './constants';
import type { AppStateSubset, State } from './slice';

type DeceasedModalSelector<T> = Selector<AppStateSubset, T>;

const selectDeceasedModalDomain = (state: AppStateSubset): State =>
  state[DECEASED_MODAL];

export const makeSelectIsOpen = (): DeceasedModalSelector<boolean> =>
  createSelector(selectDeceasedModalDomain, ({ isOpen }) => isOpen);

export const makeSelectIsLoading = (): DeceasedModalSelector<boolean> =>
  createSelector(selectDeceasedModalDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): DeceasedModalSelector<SerializedApiError | null> =>
  createSelector(selectDeceasedModalDomain, ({ error }) => error);
