import { TodoItem } from '@advitam/api/models/Deal/TodoItem';
import { DateInput } from '@advitam/ui';
import ActionFrame from '../parts/ActionFrame';
import Task from '../parts/Task';

const FIELDS_PREFIX = 'deal.marble';

interface MarbleWorkProps {
  item: TodoItem;
}

export default function MarbleWork({ item }: MarbleWorkProps): JSX.Element {
  return (
    <>
      <Task item={item} />
      <ActionFrame>
        <DateInput
          name={`${FIELDS_PREFIX}.ceremony_dt`}
          disabled={item.isDone}
        />
      </ActionFrame>
    </>
  );
}
