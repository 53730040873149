import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { EntitiesIndexFilters } from '@advitam/api/v1/Entities';
import { IndexEntityJSON } from '@advitam/api/models/Entity/Index';

import { ENTITIES } from './constants';
import { fetchEntities } from './thunk';
import type { Filters } from './types';

export interface State {
  entities: IndexEntityJSON[];
  filters: Filters;
  page: number;
  hasMore: boolean;
  lastRequestId: string | null;
  isLoading: boolean;
  error: SerializedApiError | null;
  isCreationModalOpen: boolean;
}

export interface AppStateSubset {
  [ENTITIES]: State;
}

const initialState: State = {
  entities: [],
  filters: {},
  page: 1,
  hasMore: true,
  lastRequestId: null,
  isLoading: false,
  error: null,
  isCreationModalOpen: false,
};

const slice = createSlice({
  name: ENTITIES,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    resetContent(state): void {
      state.page = 1;
      state.hasMore = true;
      state.entities = [];
    },
    setFilters(state, { payload }: PayloadAction<EntitiesIndexFilters>): void {
      state.filters = {
        ...state.filters,
        ...payload,
      };
      state.page = 1;
      state.hasMore = true;
      state.entities = [];
    },
    setIsCreationModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isCreationModalOpen = payload;
    },
    /* eslint-enable no-param-reassign */
  },
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchEntities.pending, (state, { meta }) => {
      state.error = null;
      state.isLoading = true;
      state.lastRequestId = meta.requestId;
    });
    builder.addCase(fetchEntities.fulfilled, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }

      state.entities.push(...payload.entities);
      state.hasMore = payload.hasMore;
      state.page += 1;
      state.isLoading = false;
    });
    builder.addCase(fetchEntities.rejected, (state, { payload, meta }) => {
      if (meta.requestId !== state.lastRequestId) {
        return;
      }

      state.hasMore = false;
      state.error = ApiError.serialize(payload);
      state.isLoading = false;
    });
    /* eslint-enable no-param-reassign */
  },
});

export const {
  resetContent,
  setFilters,
  setIsCreationModalOpen,
} = slice.actions;
export default slice;
