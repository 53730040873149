import { ReactNode } from 'react'
import style from './ResourceList.module.scss'

interface HeaderProps {
  children: ReactNode[]
  className?: string
}

export default function Header({ children, className = '' }: HeaderProps): JSX.Element {
  return <div className={[style.header, style.row, className].join(' ')}>{children}</div>
}
