import { useState, ReactNode } from 'react'
import { Field, useForm } from 'react-final-form'

import Autosuggest from '../../UI/Autosuggest'
import {
  CityhallAutocompleteResult,
  EntityAutocompleteResult,
  entityToCityhallAutocompleteResult,
} from './types'
import style from './EntityAutocompleteWithFilters.module.scss'
import * as FormLayout from '../../Layout'
import { composeValidators, isRequired } from '../../validators'

interface AutocompleteProps {
  placeholder?: string
  required?: boolean
  label: ReactNode
  name: string
  cityhallsEndpoint: string
  endpoint: string
  params?: Record<string, string>
  className?: string
}

export default function Autocomplete({
  name,
  label,
  endpoint,
  cityhallsEndpoint,
  required,
  params = {},
  className,
}: AutocompleteProps): JSX.Element {
  const form = useForm()
  const values = form.getFieldState(name)
  const [filterCity, setFilterCity] = useState<CityhallAutocompleteResult | undefined>(
    values as CityhallAutocompleteResult | undefined,
  )
  const [displayValue, setDisplayValue] = useState<EntityAutocompleteResult | undefined>(
    values as EntityAutocompleteResult | undefined,
  )

  function setValue(r: EntityAutocompleteResult | undefined): void {
    form.change(`${name}.name`, r?.name)
    form.change(`${name}.city`, r?.city)
    form.change(`${name}.postal_code`, r?.postal_code)
    form.change(`${name}.id`, r?.id)
    setDisplayValue(r)
  }

  function onFilterChange(r: CityhallAutocompleteResult | undefined): void {
    form.change(`${name}.insee_code`, r?.insee_code)
    setFilterCity(r)
    setValue(undefined)
  }

  function onChange(r: EntityAutocompleteResult | undefined): void {
    if (!r) {
      setValue(undefined)
      return
    }

    setFilterCity(entityToCityhallAutocompleteResult(r))
    setValue(r)
  }

  const queryParams = { ...params }
  if (filterCity) {
    queryParams.insee_code_eq = filterCity.insee_code
  }

  return (
    <div className={className}>
      <Field name={`${name}.id`} validate={composeValidators(required && isRequired)}>
        {({ input, meta }): JSX.Element => (
          <>
            <FormLayout.Row className={style.filters}>
              <Autosuggest
                label="Code postal"
                endpoint={cityhallsEndpoint}
                value={filterCity}
                getDisplayValue={(r): string => r.postal_code}
                onChange={onFilterChange}
              />
              <Autosuggest
                label="Ville"
                endpoint={cityhallsEndpoint}
                value={filterCity}
                getDisplayValue={(r): string => r.name}
                onChange={onFilterChange}
              />
            </FormLayout.Row>
            <FormLayout.Row className={style.autocomplete}>
              <Autosuggest
                label={label}
                endpoint={endpoint}
                searchParams={queryParams}
                value={displayValue}
                getDisplayValue={(r): string => r.name}
                onChange={onChange}
                error={meta.touched && !meta.valid}
              />
            </FormLayout.Row>
            <input type="hidden" name={input.name} value={input.value as number} />
          </>
        )}
      </Field>
    </div>
  )
}
