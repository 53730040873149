import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { PageSpinner } from '@advitam/ui';

import ErrorBanner from 'components/ErrorBanner';
import { withDatasets } from 'slices/data';

import Wrapper from '../Wrapper';
import Content from './Content';
import slice from './slice';
import {
  makeSelectIsLoading,
  makeSelectInitialValues,
  makeSelectError,
} from './selectors';
import { fetchCoveragesPerZones, saveZones } from './thunk';
import { WarehouseZonesForm } from './types';

function Zones(): JSX.Element {
  const dispatch = useDispatch();

  const initialValues = useSelector(makeSelectInitialValues());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const initialFetch = useCallback(() => {
    dispatch(fetchCoveragesPerZones());
  }, [dispatch]);

  const onSubmit = useCallback(
    (values: WarehouseZonesForm) => {
      dispatch(saveZones(values));
    },
    [dispatch],
  );

  useEffect(initialFetch, [initialFetch]);

  return (
    <Wrapper onSubmit={onSubmit} initialSectionValues={initialValues}>
      {isLoading ? <PageSpinner /> : <Content />}
      {error && <ErrorBanner errors={error} />}
    </Wrapper>
  );
}

export default compose<FC<Record<string, never>>>(
  withSlice(slice),
  withDatasets('departments'),
)(Zones);
