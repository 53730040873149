import { createContext, ReactNode, useCallback, useContext } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useMemoizedObject } from '@advitam/react';
import { SupplierWarehouseProductJSON } from '@advitam/api/models/Supplier/Warehouse';

import { getFilteredProductIndices } from './List/utils';
import { makeSelectFilters } from './selectors';
import {
  UnsavedSupplierWarehouseProduct,
  WarehouseProductsForm,
} from './types';

const ProductContext = createContext<number[]>([]);

interface ProductListProviderProps {
  children: ReactNode;
}

export function ProductListProvider({
  children,
}: ProductListProviderProps): JSX.Element {
  const filters = useSelector(makeSelectFilters());

  const { values } = useFormState<WarehouseProductsForm>();
  const indices = useMemoizedObject(
    getFilteredProductIndices(values.sectionValues, filters),
  );

  return (
    <ProductContext.Provider value={indices}>
      {children}
    </ProductContext.Provider>
  );
}

export function useProductItemKeyGetter(): (rowIndex: number) => string {
  const indices = useContext(ProductContext);
  return useCallback(
    (rowIndex: number): string => indices[rowIndex].toString(),
    [indices],
  );
}

export function useProductCount(): number {
  return useContext(ProductContext).length;
}

export function useProductIndex(rowIndex: number): number {
  return useContext(ProductContext)[rowIndex];
}

export function useProduct(
  rowIndex: number,
): SupplierWarehouseProductJSON | UnsavedSupplierWarehouseProduct {
  const index = useProductIndex(rowIndex);
  const { values } = useFormState<WarehouseProductsForm>();
  return values.sectionValues[index];
}

export function useProductPrefix(rowIndex: number): string {
  return `sectionValues[${useProductIndex(rowIndex)}]`;
}
