import { ChangeEvent, ReactNode, useCallback, useRef } from 'react'
import { Range } from 'react-range'

import Text from '../../../Text'
import { setValueWithEvent } from '../../support'
import style from './Range.module.scss'
import Thumb from './Thumb'
import Track from './Track'

interface RangeProps {
  name: string
  value: number
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void
  max: number
  formatValue?: (v: number) => ReactNode
}

export default function RangeInput({
  name,
  value,
  onChange,
  max,
  formatValue = (v): string => v.toString(),
}: RangeProps): JSX.Element {
  const ref = useRef<HTMLInputElement>(null)

  const onRangeChange = useCallback(
    (values: number[]): void => {
      if (ref.current) {
        setValueWithEvent(ref.current, values[0])
      }
    },
    [ref],
  )

  return (
    <>
      <Text small className={style.text}>
        <b>{formatValue(0)}</b>
        <b>{formatValue(value)}</b>
      </Text>
      <span className={style.range}>
        <Range
          values={[value]}
          onChange={onRangeChange}
          min={0}
          max={max}
          renderTrack={(props): JSX.Element => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Track {...props} value={value} max={max} />
          )}
          renderThumb={Thumb}
        />
        <input type="number" name={name} ref={ref} onChange={onChange} />
      </span>
    </>
  )
}
