import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';

import { Layout } from 'containers/Crud';
import slice from './slice';
import type { DocumentsForm } from './types';
import { saveDocuments } from './thunk';
import { makeSelectSupportingDocuments } from './selectors';
import Wrapper from '../Wrapper';
import Content from './Content';

function SupportingDocuments(): JSX.Element {
  const dispatch = useDispatch();

  const documents = useSelector(makeSelectSupportingDocuments());

  const onSubmit = useCallback(
    ({ sectionValues }: DocumentsForm) => {
      dispatch(saveDocuments(sectionValues));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit} initialValues={{ sectionValues: documents }}>
      <Layout.Container>
        <Content />
      </Layout.Container>
    </Wrapper>
  );
}

export default withSlice(slice)(SupportingDocuments);
