import { SerializedApiError } from '@advitam/api';
import {
  DocumentJSON,
  DocumentPageJSON,
} from '@advitam/api/models/DocumentEditions';
import {
  DocumentUploadDealResource,
  DocumentUploadInvoiceResource,
} from '@advitam/api/v1/DocumentEditions/types';
import { DOCUMENTS_FIELD_NAME } from './constants';

export const enum RotateDirection {
  LEFT,
  RIGHT,
}

export interface UploadError {
  fileName: string;
  error: SerializedApiError | string;
}

export type NewUploadResource =
  | DocumentUploadDealResource
  | (Omit<DocumentUploadInvoiceResource, 'id'> & {
      id?: number;
    });

export type DocumentEditionPage = DocumentPageJSON & { isRemoved?: boolean };

export type DocumentEdition = Omit<DocumentJSON, 'pages'> & {
  pages: DocumentEditionPage[];
  resource: NewUploadResource;
  sessionId: string;
};

export interface DocumentEditionsForm {
  [DOCUMENTS_FIELD_NAME]: DocumentEdition[];
}

export interface DocumentConflict {
  document: DocumentEdition;
  documentName: string;
}
