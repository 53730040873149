import type { ApiRequestDescriptor } from '../../lib/request'
import { get } from '../../lib/methods'
import type { ReviewSummaryJSON } from '../../models/Website/ReviewSummary'
import { authenticateAsAdvitamService } from '../../lib/decorators'

export const Reviews = {
  index: authenticateAsAdvitamService(
    (): ApiRequestDescriptor<ReviewSummaryJSON> => get('/api/v1/website/reviews'),
  ),
}
