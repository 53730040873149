import { createSelector, Selector } from 'reselect';

import { Hospital, HospitalJSON } from '@advitam/api/models/Hospital';
import type { SerializedApiError } from '@advitam/api';

import { HOSPITAL } from './constants';
import type { AppStateSubset, State } from './slice';
import { NewHospital } from './types';

type HospitalSelector<T> = Selector<AppStateSubset, T>;

const selectHospitalDomain = (state: AppStateSubset): State => state[HOSPITAL];

export const makeSelectRawHospital = (): HospitalSelector<
  HospitalJSON | NewHospital | null
> => createSelector(selectHospitalDomain, ({ hospital }) => hospital);

export const makeSelectHospital = (): HospitalSelector<Hospital | null> =>
  createSelector(makeSelectRawHospital(), rawHospital =>
    rawHospital?.id ? new Hospital(rawHospital) : null,
  );

export const makeSelectIsLoading = (): HospitalSelector<boolean> =>
  createSelector(selectHospitalDomain, ({ isLoading }) => isLoading);

export const makeSelectIsSaving = (): HospitalSelector<boolean> =>
  createSelector(selectHospitalDomain, ({ isSaving }) => isSaving);

export const makeSelectIsDestroying = (): HospitalSelector<boolean> =>
  createSelector(selectHospitalDomain, ({ isDestroying }) => isDestroying);

export const makeSelectIsUpdatingName = (): HospitalSelector<boolean> =>
  createSelector(selectHospitalDomain, ({ isUpdatingName }) => isUpdatingName);

export const makeSelectError = (): HospitalSelector<SerializedApiError | null> =>
  createSelector(selectHospitalDomain, ({ error }) => error);

export const makeSelectDestroyError = (): HospitalSelector<SerializedApiError | null> =>
  createSelector(selectHospitalDomain, ({ destroyError }) => destroyError);
