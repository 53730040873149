import * as V1 from './v1'
import * as V2 from './v2'
import * as Chat from './chat'
import * as Next from './next'
import ApiError from './lib/Error/ApiError'
import * as Company from './lib/company'
import * as Pagination from './lib/pagination'
import * as Session from './lib/session'
import { S3 } from './s3'

export type { ApiPayload, ApiResponse, ApiRequestDescriptor } from './lib/request'

export type { SerializedApiError } from './lib/Error/ApiError'

export { request, pollUntil, downloadFile } from './lib/request'
export { authenticate, withSessionId } from './lib/decorators'
export { get, post, put, del } from './lib/methods'

export { ApiError, V1, Next, Company, Pagination, Session }
export { isApiError } from './lib/Error/ApiError'
export { ErrorCodes } from './lib/Error/codes'
export default { Error: ApiError, V1, V2, Chat, Next, S3 }
