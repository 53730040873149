import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';

export function Input({
  className,
  message,
  toolTipMessage,
  errorMessage,
  placeholderMessage,
  hideErrorMessage,
  input: {
    onChange,
    onBlur,
    value,
    name,
    placeholder,
    disabled,
    type,
    ref,
    min,
    max,
  },
  meta: { error, touched },
}) {
  const { formatMessage } = useIntl();

  return (
    <label className={`input ${className}`} htmlFor={name}>
      {message && (
        <div className="input__message">
          <span className="input__message--text">{formatMessage(message)}</span>
          {toolTipMessage && (
            <Tippy content={<FormattedMessage {...toolTipMessage} />}>
              <span className="input__message--tooltip" />
            </Tippy>
          )}
        </div>
      )}
      <input
        type={type || 'text'}
        id={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value || ''}
        placeholder={
          placeholder ||
          (placeholderMessage && formatMessage(placeholderMessage))
        }
        className={touched && error ? 'errored' : ''}
        disabled={disabled}
        ref={ref}
        min={min}
        max={max}
      />
      {!hideErrorMessage && touched && error && (
        <span className="error">
          {formatMessage(errorMessage || { id: error })}
        </span>
      )}
    </label>
  );
}

Input.defaultProps = {
  meta: {},
  className: '',
  hideErrorMessage: false,
};

Input.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object,
  toolTipMessage: PropTypes.object,
  errorMessage: PropTypes.object,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  placeholderMessage: PropTypes.object,
  hideErrorMessage: PropTypes.bool,
};

export default Input;
