import { FormattedMessage } from 'react-intl';
import { ResourceList } from '@advitam/ui';
import messages from '../messages';
import style from './List.module.scss';

export default function ZoneHeader(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell>
        <FormattedMessage id={messages.serviceName.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.category.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile>
        <FormattedMessage id={messages.supplierRef.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.cost.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.price.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.actions.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
