import type { IntlShape } from 'react-intl';

import { BookingStatus } from '@advitam/api/models/Deal/BookingTransaction/BookingStatus';
import type { BookingAnsweredNotificationJSON } from '@advitam/api/models/Notification';
import Check from '@advitam/ui/images/icons/check.svg';
import Times from '@advitam/ui/images/icons/times.svg';
import { Path } from 'containers/App/constants';

import CheckStandalone from './icons/check-standalone.png.bin';
import TimesStandalone from './icons/times-standalone.png.bin';
import type { TileProps } from './TileProps';
import messages from './messages';
import style from './NotificationTile.module.scss';

export default function getBookingAnsweredProps(
  notification: BookingAnsweredNotificationJSON,
  intl: IntlShape,
): TileProps {
  const isAccepted = notification.comp_info.status === BookingStatus.ACCEPTED;
  const titleMessage = isAccepted
    ? messages.bookingAcceptedTitle
    : messages.bookingRefusedTitle;
  const bodyMessage = isAccepted
    ? messages.bookingAcceptedText
    : messages.bookingRefusedText;
  const IconComponent = isAccepted ? Check : Times;
  const icon = isAccepted ? CheckStandalone : TimesStandalone;

  return {
    link: Path.DEAL(notification.comp_info.deal_uuid, 'fournisseurs'),
    IconComponent: (): JSX.Element => (
      <IconComponent className={style.booking_answer} />
    ),
    icon,
    title: intl.formatMessage(titleMessage),
    body: intl.formatMessage(bodyMessage, {
      supplierName: notification.comp_info.supplier_name,
    }),
  };
}
