import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoWindow } from '@react-google-maps/api';

import { LocationType } from '@advitam/api/models/LocationType';
import { EntityType } from '@advitam/api/models/Entity/EntityType';
import { Text, Link } from '@advitam/ui';

import { Path } from 'containers/App/constants';
import { LocationMarker } from 'components/Map';
import { FormattedAddress } from 'components/Format';

import { EntityMarker } from '../types';
import { setActiveMarker } from '../slice';
import { makeSelectIsActiveMarker } from '../selectors';
import contentStyle from './Content.module.scss';

const ENTITY_BASE_LINK: Record<EntityType, string> = {
  [LocationType.AIRPORT]: Path.FLIGHTS,
  [LocationType.CITYHALL]: Path.CITYHALLS,
  [LocationType.CONSULATE]: Path.CONSULATES,
  [LocationType.CREMATORIUM]: Path.CREMATORIUMS,
  [LocationType.FUNERAL_PARLOR]: Path.FUNERAL_PARLORS,
  [LocationType.GRAVEYARD]: Path.GRAVEYARDS,
  [LocationType.HOSPITAL]: Path.HOSPITALS,
  [LocationType.POLICE]: Path.POLICE,
  [LocationType.PREFECTURE]: Path.PREFECTURES,
  [LocationType.REGIONAL_HEALTH_AUTHORITY]: Path.RHAS,
  [LocationType.WORSHIP]: Path.WORSHIPS,
};

function getEntityHref(entity: EntityMarker): string {
  const baseLink = ENTITY_BASE_LINK[entity.type];

  if (entity.type === LocationType.AIRPORT) {
    return baseLink;
  }

  return [baseLink, entity.id].join('/');
}

interface MarkerProps {
  entityMarker: EntityMarker;
}

export default function Marker({ entityMarker }: MarkerProps): JSX.Element {
  const dispatch = useDispatch();
  const isActive = useSelector(makeSelectIsActiveMarker(entityMarker));

  const onOpenMarker = useCallback((): void => {
    dispatch(setActiveMarker(entityMarker));
  }, [dispatch, entityMarker]);

  const onCloseMarker = useCallback((): void => {
    dispatch(setActiveMarker(null));
  }, [dispatch]);

  return (
    <LocationMarker
      lat={entityMarker.latitude}
      lng={entityMarker.longitude}
      type={entityMarker.type}
      onClick={onOpenMarker}
    >
      {isActive && (
        <InfoWindow onCloseClick={onCloseMarker}>
          <>
            <Text>
              <Link
                newTab
                internal
                href={getEntityHref(entityMarker)}
                className={contentStyle.link}
              >
                <b>{entityMarker.name}</b>
              </Link>
            </Text>
            <br />
            <Text small>
              <FormattedAddress entity={entityMarker} />
            </Text>
            <br />
            <Text tagName="div" extraSmall className={contentStyle.links}>
              {entityMarker.phone && (
                <Link
                  href={`tel:${entityMarker.phone}`}
                  className={contentStyle.link}
                >
                  {entityMarker.phone}
                </Link>
              )}
              <br />
              {entityMarker.email && (
                <Link
                  href={`mail:${entityMarker.email}`}
                  className={contentStyle.link}
                >
                  {entityMarker.email}
                </Link>
              )}
            </Text>
          </>
        </InfoWindow>
      )}
    </LocationMarker>
  );
}
