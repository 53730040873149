import type { FormApi } from 'final-form';
import { MouseEvent, useCallback } from 'react';
import { useForm } from 'react-final-form';

import { Button } from '@advitam/ui';
import SaveIcon from '@advitam/ui/images/icons/floppy-disk.svg';
import ResetIcon from '@advitam/ui/images/icons/times.svg';

import style from './ActionsPopup.module.scss';

interface ActionsPopupProps<T> {
  isNew: boolean;
  beforeSubmit?: (form: FormApi<T>) => Promise<boolean> | boolean;
}

export default function ActionsPopup<T = unknown>({
  isNew,
  beforeSubmit,
}: ActionsPopupProps<T>): JSX.Element {
  const form = useForm<T>();

  // `restart` must be wrapped, as it takes form initial values as arguments.
  // Not wrapping it will result as passing mouse event as initial values.
  const onFormRestart = useCallback(
    (_event: MouseEvent): void => {
      form.restart();
    },
    [form],
  );

  const onSubmit = useCallback(async () => {
    if (beforeSubmit) {
      const canSubmit = await beforeSubmit(form);
      if (!canSubmit) {
        return;
      }
    }
    await form.submit();
  }, [beforeSubmit, form]);

  return (
    <div className={style.popup}>
      {!isNew && (
        <Button
          outline
          className={style.icon_button}
          icon={<ResetIcon />}
          onClick={onFormRestart}
        />
      )}
      <Button
        primary
        className={style.icon_button}
        icon={<SaveIcon />}
        onClick={onSubmit}
      />
    </div>
  );
}
