import { ReactNode } from 'react'

import Text from '../Text'
import style from './Circled.module.scss'

interface CircledProps {
  children: ReactNode
}

export default function Circled({ children }: CircledProps): JSX.Element {
  return (
    <span className={style.index}>
      <Text small>
        <b>{children}</b>
      </Text>
    </span>
  )
}
