import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  typeFilter: {
    id: 'app.containers.Graveyard.sections.Concessions.typeFilter',
  },
  termFilter: {
    id: 'app.containers.Graveyard.sections.Concessions.termFilter',
  },
  addConcession: {
    id: 'app.containers.Graveyard.sections.Concessions.addConcession',
  },
  area: { id: 'app.containers.Graveyard.sections.Concessions.area' },
  areaTooltip: {
    id: 'app.containers.Graveyard.sections.Concessions.areaTooltip',
  },
  built: { id: 'app.containers.Graveyard.sections.Concessions.built' },
  builtTooltip: {
    id: 'app.containers.Graveyard.sections.Concessions.builtTooltip',
  },
  commentary: {
    id: 'app.containers.Graveyard.sections.Concessions.commentary',
  },
  deleteConfirmation: {
    id: 'app.containers.Graveyard.sections.Concessions.deleteConfirmation',
  },
  dimensions: {
    id: 'app.containers.Graveyard.sections.Concessions.dimensions',
  },
  dimensionsTooltip: {
    id: 'app.containers.Graveyard.sections.Concessions.dimensionsTooltip',
  },
  duration: { id: 'app.containers.Graveyard.sections.Concessions.duration' },
  perpetually: {
    id: 'app.containers.Graveyard.sections.Concessions.perpetually',
  },
  placeCount: {
    id: 'app.containers.Graveyard.sections.Concessions.placeCount',
  },
  placeCountTooltip: {
    id: 'app.containers.Graveyard.sections.Concessions.placeCountTooltip',
  },
  price: { id: 'app.containers.Graveyard.sections.Concessions.price' },
  title: { id: 'app.containers.Graveyard.sections.Concessions.title' },
  type: { id: 'app.containers.Graveyard.sections.Concessions.type' },
  year: { id: 'app.containers.Graveyard.sections.Concessions.year' },
});
