import { DocumentGenerationType } from '@advitam/api/models/Documents/Editable/GenerationType';

export { DocumentGenerationType };

export const enum DocumentDealType {
  BOTH = 'funeral|marble',
  FUNERAL = 'funeral',
  MARBLE = 'marble',
}

export interface Document {
  id: number;
  uuid: string;
  generation_type: DocumentGenerationType;
  template_id: number | null;
}
