import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar, Map, MapStyle } from '@advitam/ui';
import { withSlice } from '@advitam/react';

import {
  makeSelectCenter,
  makeSelectMarkers,
  makeSelectZoom,
} from '../selectors';
import { getMarkerId } from '../utils';
import Marker from './Marker';
import {
  PrestationsAutocomplete,
  PrestationsCoverages,
  prestationsSlice,
} from './Prestations';
import {
  SuppliersAutocomplete,
  SuppliersCoverages,
  suppliersSlice,
} from './Suppliers';
import CityhallAutocomplete from './CityhallAutocomplete';
import EntityTypesSelect from './EntityTypesSelect';
import style from './Content.module.scss';
import SupplierWindow from './SupplierWindow';
import { setActiveMarker, setActiveSupplier } from '../slice';

function Content(): JSX.Element {
  const dispatch = useDispatch();

  const center = useSelector(makeSelectCenter());
  const zoom = useSelector(makeSelectZoom());
  const entityMarkers = useSelector(makeSelectMarkers());

  const onMapClick = useCallback((): void => {
    dispatch(setActiveMarker(null));
    dispatch(setActiveSupplier(null));
  }, [dispatch]);

  return (
    <>
      <Sidebar className={style.sidebar}>
        <div className={style.filters}>
          <CityhallAutocomplete />
          <EntityTypesSelect />
          <PrestationsAutocomplete />
          <SuppliersAutocomplete />
        </div>
      </Sidebar>

      <Map
        scrollwheel
        center={center}
        zoom={zoom}
        mapStyle={MapStyle.LIGHT_BEIGE}
        onClick={onMapClick}
        className={style.map}
      >
        <PrestationsCoverages />
        <SuppliersCoverages />

        <SupplierWindow />

        {entityMarkers.map(entityMarker => (
          <Marker key={getMarkerId(entityMarker)} entityMarker={entityMarker} />
        ))}
      </Map>
    </>
  );
}

export default withSlice(suppliersSlice, prestationsSlice)(Content);
