import { createSelector, Selector } from 'reselect';

import { makeSelectAcceptedPayments } from '../../Payments/selectors';
import { makeSelectDeal } from '../../selectors.typed';
import type { AppStateSubset as DealAppStateSubset } from '../../slice';
import type { AppStateSubset as PaymentsAppStateSubset } from '../../Payments/slice';

type HomeSelector<T> = Selector<PaymentsAppStateSubset & DealAppStateSubset, T>;

export const makeSelectRemainingToPay = (): HomeSelector<number> =>
  createSelector(
    makeSelectDeal(),
    makeSelectAcceptedPayments(),
    (deal, payments) =>
      deal?.finalPrice
        ? deal.finalPrice -
          payments.reduce((amount, item) => amount + item.amount, 0)
        : 0,
  );
