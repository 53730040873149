import { createAction } from 'lib/reactvitam/action_cable';
import { DealJSON } from 'models/v2/Deal';

interface DealUpdate {
  commentary: DealJSON['commentary'];
}

export interface UpdatePayload {
  user_id: number;
  session_id: string;
  body: DealUpdate;
  date: string;
}

/**
 * The resource have been updated
 * Not for sending by the frontend, this is only the interface for reception of
 * backend messages.
 */
function updateV2(
  user_id: number,
  session_id: string,
  body: DealUpdate,
  date: string,
): UpdatePayload {
  return { user_id, session_id, body, date };
}

export default createAction(updateV2, 'update_v2');
