import {
  ADD_TRIGGER_PING_SERVICE,
  REMOVE_TRIGGER_PING_SERVICE,
} from 'containers/Deal/constants.ts';
import { setDealFields } from 'containers/Deal/slice.ts';
import { determineIdForNewObject } from 'utils/functions';

/**
 * Adds the service payload received from the API. isNewDeal decides if we put
 * the service in the pending state or directly in the deal.
 *
 * @param      {String}  service  The service data.
 * @param      {object}  deal     The data of funeral deal
 * @param      {string}  dealType The type of the deal
 * @return     {Object}  An action object asking the reducer to store the
 *                       service.
 */
export function addService(service, deal, dealType) {
  return setDealFields({
    isTouched: true,
    update: {
      [dealType]: {
        ...deal,
        services: [
          ...deal.services,
          { id: determineIdForNewObject(deal.services, 'id'), ...service },
        ],
      },
    },
  });
}

/**
 * Removes a service from the deal.
 *
 * @param      {object}  service      The update service to be applied to the
 *                                    service.
 * @param      {object}  deal      The data of deal
 * @param      {string}  dealType The deal type
 * @return     {Object}  An action object deleting the service in the reducer.
 */
export function removeService(service, deal, dealType) {
  return setDealFields({
    isTouched: true,
    update: {
      [dealType]: {
        ...deal,
        services: deal.services.filter(s => service.id !== s.id),
      },
    },
  });
}

/**
 * Updates a service in the deal.
 *
 * @param      {object}  service      The update service to be applied to the
 *                                    service.
 * @param      {object}  funeral      The data of funeral deal
 * @param      {string}  dealType The data of deal type
 * @return     {Object}  An action object asking the reducer to make the change
 *                       in the store
 */
export function updateService(service, deal, dealType) {
  const services = [...deal.services];
  const updatedServices = services.map(s => {
    if (s.id === service.id) return service;
    return s;
  });

  return setDealFields({
    isTouched: true,
    update: {
      [dealType]: {
        ...deal,
        services: updatedServices,
      },
    },
  });
}

export function addTriggerPingService(service) {
  return {
    type: ADD_TRIGGER_PING_SERVICE,
    service,
  };
}

export function removeTriggerPingService(service) {
  return {
    type: REMOVE_TRIGGER_PING_SERVICE,
    service,
  };
}
