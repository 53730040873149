import { createSelector, Selector } from 'reselect';
import { SerializedApiError } from '@advitam/api';
import { FORGOTTEN_PASSWORD } from './constants';
import type { AppStateSubset, State } from './slice';

type ForgottenPasswordSelector<T> = Selector<AppStateSubset, T>;

const selectForgottenPasswordDomain = (state: AppStateSubset): State =>
  state[FORGOTTEN_PASSWORD];

export const makeSelectSuccessMessage = (): ForgottenPasswordSelector<
  string | null
> =>
  createSelector(
    selectForgottenPasswordDomain,
    ({ successMessage }) => successMessage,
  );

export const makeSelectIsLoading = (): ForgottenPasswordSelector<boolean> =>
  createSelector(selectForgottenPasswordDomain, ({ isLoading }) => isLoading);

export const makeSelectError = (): ForgottenPasswordSelector<SerializedApiError | null> =>
  createSelector(selectForgottenPasswordDomain, ({ error }) => error);
