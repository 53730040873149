import { assert, Objects } from '@advitam/support'

import type { EditableDocumentJSON } from '../../../models/Documents/Editable'
import { DocumentDealType } from '../../../models/Documents/Editable/DealType'
import { DocumentGenerationType } from '../../../models/Documents/Editable/GenerationType'
import { EntityType } from '../../../models/Entity/EntityType'
import { PolymorphicEntity } from '../../../models/PolymorphicEntity'

interface DocumentPayload {
  name: string
  rule_tag: string
  generation_type: DocumentGenerationType
  deal_type: DocumentDealType
}

interface OwnerPayload {
  entity_type: EntityType
  entity_id: number
  entity_rule_tag: string
  rule_tag: string
}

interface CreateDocumentPayload {
  document: DocumentPayload
  document_owner: OwnerPayload
}

export default function serialize(
  document: EditableDocumentJSON,
  owner: PolymorphicEntity,
): CreateDocumentPayload {
  assert(document.owners.length !== 0, 'document must have an owner')

  return {
    document: {
      ...Objects.pick(document, 'name', 'generation_type', 'deal_type'),
      rule_tag: document.rule.tag,
    },
    document_owner: {
      entity_type: owner.type,
      entity_id: owner.id,
      entity_rule_tag: document.owners[0].entity_rule_tag,
      rule_tag: 'always',
    },
  }
}
