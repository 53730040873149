import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Api, { downloadFile } from '@advitam/api';
import type { DealCommunication } from '@advitam/api/models/Deal/Communication';
import { CommunicationChannelType } from '@advitam/api/models/Deal/Communication/ChannelType';
import { Button, Tooltip } from '@advitam/ui';
import Download from '@advitam/ui/images/icons/download.svg';

import messages from '../../messages';
import style from './Details.module.scss';

interface DownloadButtonProps {
  communication: DealCommunication;
}

export default function DownloadButton({
  communication,
}: DownloadButtonProps): JSX.Element | null {
  const onClick = useCallback(
    () =>
      downloadFile(
        Api.S3.download(communication.files[0].link),
        communication.files[0].name,
      ),
    [communication.files],
  );

  if (
    communication.channel_type !== CommunicationChannelType.PHONE ||
    communication.files.length === 0
  ) {
    return null;
  }

  return (
    <Tooltip content={<FormattedMessage id={messages.download.id} />}>
      <Button
        className={style.button}
        outline
        icon={<Download />}
        onClick={onClick}
      />
    </Tooltip>
  );
}
