import { Objects } from '@advitam/support'
import { FlightJSON } from '../../../../models/Flight'
import { RateJSON } from '../../../../models/Flight/Rate'
import { FlightBody } from './types'

type FlightPayload = Partial<
  Omit<
    FlightJSON,
    | 'id'
    | 'airline'
    | 'departure_airport'
    | 'arrival_airport'
    | 'stopover_airport'
    | 'rates'
    | 'supplier_warehouse_id'
  > & {
    airline_id: number | null
    departure_airport_id: number | null
    stopover_airport_id: number | null
    arrival_airport_id: number | null
    rates_attributes: RateJSON[]
  }
>

export function serialize(flight: Omit<FlightBody, 'id'>): FlightPayload {
  return {
    ...Objects.omit(
      flight,
      'airline',
      'departure_airport',
      'stopover_airport',
      'arrival_airport',
      'supplier_name',
      'rates',
      'supplier_warehouse_id',
    ),
    airline_id: flight.airline === undefined ? undefined : flight.airline?.id || null,
    departure_airport_id:
      flight.departure_airport === undefined ? undefined : flight.departure_airport?.id || null,
    stopover_airport_id:
      flight.stopover_airport === undefined ? undefined : flight.stopover_airport?.id || null,
    arrival_airport_id:
      flight.arrival_airport === undefined ? undefined : flight.arrival_airport?.id || null,
    rates_attributes: flight.rates,
  }
}
