import { post } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { AlmaPaymentResponseJSON } from '../../../models/Alma/PaymentResponse'
import type { PaymentJSON } from '../../../models/Payment'

import serialize from '../serializers'
import { Callback } from './Callback'
import { EligiblePlans } from './EligiblePlans'

export const Alma = {
  Callback,
  EligiblePlans,

  create: (
    payload: PaymentJSON,
    redirect_url: string,
  ): ApiRequestDescriptor<AlmaPaymentResponseJSON> =>
    post('/api/v1/payments/alma', { ...serialize(payload), redirect_url }),
}
