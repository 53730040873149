import { authenticate } from 'api/decorators';
import { get, post, put, del } from 'api/methods';
import { ApiRequestDescriptor } from 'api/request';
import { serialize } from 'api/serializers/Deals/Layers';

import { assert } from 'lib/Assert';
import { Deal, DealJSON, Layer } from 'models/Deal';

export default class Layers {
  @authenticate
  static index(deal: Deal | DealJSON): ApiRequestDescriptor<Layer[]> {
    return get(`/api/v1/deals/${deal.id}/layers`);
  }

  @authenticate
  static create(deal: Deal, layer: Layer): ApiRequestDescriptor<Layer> {
    return post(`/api/v1/deals/${deal.id}/layers`, serialize(layer));
  }

  @authenticate
  static update(layer: Layer): ApiRequestDescriptor<Layer> {
    assert(layer.id !== undefined);
    return put(`/api/v1/deals/layers/${layer.id}`, serialize(layer));
  }

  @authenticate
  static destroy(layer: Layer): ApiRequestDescriptor<Layer> {
    assert(layer.id !== undefined);
    return del(`/api/v1/deals/layers/${layer.id}`);
  }

  static updateOrCreate(deal: Deal, layer: Layer): ApiRequestDescriptor<Layer> {
    if (layer.id) {
      return Layers.update(layer);
    }
    return Layers.create(deal, layer);
  }
}
