import { FormattedMessage } from 'react-intl';

import ResourceList from '@advitam/ui/components/ResourceList';

import messages from '../messages';
import style from '../Resources.module.scss';

export default function Header(): JSX.Element {
  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell>
        <FormattedMessage id={messages.id.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.category.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.owner.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.status.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.actions.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
