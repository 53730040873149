import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import actionsMessages from 'messages/actions';

import { makeSelectCanEdit } from '../../../../../selectors';
import style from '../List.module.scss';
import { useProductPrefix } from '../../ProductListProvider';

interface DefaultProps {
  rowIndex: number;
  style?: CSSProperties;
  className: string;
}

export default function Default({
  rowIndex,
  style: wrapperStyle,
  className,
}: DefaultProps): JSX.Element | null {
  const intl = useIntl();
  const canUserEdit = useSelector(makeSelectCanEdit());

  const prefix = useProductPrefix(rowIndex);

  const booleanOptions: SelectableItem<boolean>[] = [
    {
      value: true,
      name: intl.formatMessage(actionsMessages.yes),
    },
    {
      value: false,
      name: intl.formatMessage(actionsMessages.no),
    },
  ];

  return (
    <div
      style={wrapperStyle}
      className={[className, style.default_cell].join(' ')}
    >
      <Select
        name={`${prefix}.default`}
        items={booleanOptions}
        disabled={!canUserEdit}
        className={style.input}
      />
    </div>
  );
}
