import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get } from 'api/methods';

export class Dictionaries {
  @authenticate
  static show(
    dealId: number,
    documentID: number,
  ): ApiRequestDescriptor<Record<string, unknown>> {
    return get(`/api/v1/deals/${dealId}/documents/${documentID}/dictionary`);
  }
}
