import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ChangeEvent, useCallback, useState } from 'react';

import { Button, ContentModal, FormUI, ModalTitle, Text } from '@advitam/ui';
import actionsMessages from 'messages/actions';
import { assert } from 'lib/support';

import { makeSelectDocumentsConflicts } from '../selectors';
import { DocumentEdition } from '../types';
import messages from './messages';
import style from './ConflictsModal.module.scss';

interface ConflictsModalProps {
  save: (
    documents: DocumentEdition[],
    shouldBypassConflicts?: true,
  ) => Promise<void>;
}

export default function ConflictsModal({
  save,
}: ConflictsModalProps): JSX.Element {
  const documentsConflicts = useSelector(makeSelectDocumentsConflicts());
  const [checkedUuids, setCheckedUuids] = useState<string[]>([]);

  const onConfirm = useCallback(async (): Promise<void> => {
    const documentsToUpload = checkedUuids.map(uuid => {
      const conflictedDocument = documentsConflicts.find(
        conflict => conflict.document.uuid === uuid,
      );

      assert(conflictedDocument !== undefined);
      return conflictedDocument.document;
    });

    await save(documentsToUpload, true);
  }, [documentsConflicts, checkedUuids, save]);

  const isChecked = useCallback(
    (uuid: string): boolean => checkedUuids.includes(uuid),
    [checkedUuids],
  );

  const onConflictToggle = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
      let newUuids = [...checkedUuids];

      if (isChecked(value)) {
        newUuids = newUuids.filter(uuid => uuid !== value);
      } else {
        newUuids.push(value);
      }

      setCheckedUuids(newUuids);
    },
    [checkedUuids],
  );

  return (
    <ContentModal
      isOpen={documentsConflicts.length > 0}
      onRequestClose={onConfirm}
      className={`col-xl-7 ${style.modal}`}
    >
      <ModalTitle>
        <FormattedMessage id={messages.title.id} />
      </ModalTitle>
      <Text tagName="div">
        <FormattedMessage id={messages.text.id} />
        <ul className={style.conflicts}>
          {documentsConflicts.map(({ document, documentName }) => (
            <li key={document.uuid}>
              <FormUI.Checkbox
                borderless
                label={documentName}
                value={document.uuid}
                checked={isChecked(document.uuid)}
                onChange={onConflictToggle}
              />
            </li>
          ))}
        </ul>
      </Text>
      <Button
        primary
        text={<FormattedMessage id={actionsMessages.continue.id} />}
        className={style.cta}
        onClick={onConfirm}
      />
    </ContentModal>
  );
}
