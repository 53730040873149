import { ApiRequestDescriptor } from '../../lib/request'
import { del, get, post, put } from '../../lib/methods'
import { ConcessionTermJSON } from '../../models/Concession'
import { authenticate, withSessionId } from '../../lib/decorators'

type CreationPayload = Omit<ConcessionTermJSON, 'id'>

export class ConcessionTerms {
  @authenticate
  @withSessionId
  static create(
    graveyardId: number,
    concessionTerm: CreationPayload,
  ): ApiRequestDescriptor<ConcessionTermJSON> {
    return post(`/api/v1/graveyards/${graveyardId}/concession_terms`, concessionTerm)
  }

  @authenticate
  static index(graveyardId: number): ApiRequestDescriptor<ConcessionTermJSON[]> {
    return get(`/api/v1/graveyards/${graveyardId}/concession_terms`)
  }

  @authenticate
  @withSessionId
  static update(concessionTerm: ConcessionTermJSON): ApiRequestDescriptor<ConcessionTermJSON> {
    return put(`/api/v1/graveyards/concession_terms/${concessionTerm.id}`, concessionTerm)
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/graveyards/concession_terms/${id}`)
  }
}
