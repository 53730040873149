import { ReactNode, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsPopup } from 'containers/Crud';
import { assert } from 'lib/support';

import Sidebar from '../Sidebar';
import { makeSelectRawPrefecture } from '../selectors';
import { updatePrefectureName } from '../thunk';
import type { PrefectureForm } from './types';
import style from '../Prefecture.module.scss';

interface WrapperProps<T> {
  children: ReactNode;
  initialSectionValues?: T;
  onSubmit: (values: PrefectureForm<T>) => void;
}

export default function Wrapper<T>({
  children,
  initialSectionValues,
  onSubmit,
}: WrapperProps<T>): JSX.Element {
  const dispatch = useDispatch();

  const prefecture = useSelector(makeSelectRawPrefecture());
  assert(prefecture !== null);

  const initialValues: Partial<PrefectureForm<T>> = {
    prefecture,
    sectionValues: initialSectionValues,
  };

  const handleFormSubmit = useCallback(
    (values: PrefectureForm<T>): void => {
      if (values.sectionValues && prefecture.name !== values.prefecture.name) {
        dispatch(updatePrefectureName(values.prefecture.name));
      }

      onSubmit(values);
    },
    [onSubmit, prefecture, dispatch],
  );

  return (
    <Form<PrefectureForm<T>>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <Sidebar />
          {(dirty || !prefecture.id) && <ActionsPopup isNew={!prefecture.id} />}
          {children}
        </form>
      )}
    </Form>
  );
}
