import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from '@advitam/ui';

import { makeSelectIsUpdated, makeSelectIsUpdateModalOpen } from './selectors';
import {
  resetUpdated,
  setIsErrorBannerOpen,
  setIsUpdateModalOpen,
} from './slice';
import messages from './messages';

interface UpdateModalProps {
  onRefresh: () => void;
}

export default function UpdateModal({
  onRefresh,
}: UpdateModalProps): JSX.Element | null {
  const dispatch = useDispatch();
  const isUpdated = useSelector(makeSelectIsUpdated());
  const isOpen = useSelector(makeSelectIsUpdateModalOpen());

  const onCancel = useCallback((): void => {
    dispatch(setIsUpdateModalOpen(false));
    dispatch(setIsErrorBannerOpen(true));
  }, [dispatch]);

  const onConfirm = useCallback((): void => {
    dispatch(setIsUpdateModalOpen(false));
    dispatch(resetUpdated());
    onRefresh();
  }, [dispatch, onRefresh]);

  useEffect(
    () => (): void => {
      dispatch(resetUpdated());
    },
    [dispatch],
  );

  if (!isUpdated) {
    return null;
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      text={<FormattedMessage id={messages.updated.id} />}
      confirm={<FormattedMessage id={messages.refresh.id} />}
      onConfirm={onConfirm}
      cancel={<FormattedMessage id={messages.dismiss.id} />}
      onCancel={onCancel}
      className="new-design"
    />
  );
}
