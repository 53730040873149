import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DealDocumentBatchDocumentJSON } from '@advitam/api/models/Deal/Document/Batch';
import { Link } from '@advitam/ui';
import LinkIcon from '@advitam/ui/images/icons/arrow-up-right-from-square.svg';

import { PartialBatchDocument } from '../../types';
import { isValidBatchDocument } from '../../utils';
import AutocompletesItem from '../Item';
import { makeSelectIsDocumentLoading } from './selectors';
import { destroyDocument } from './thunk';
import style from './Documents.module.scss';

interface DocumentProps {
  document: DealDocumentBatchDocumentJSON | PartialBatchDocument;
}

export default function Document({ document }: DocumentProps): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsDocumentLoading(document));

  const onDestroy = useCallback((): void => {
    dispatch(destroyDocument(document.deal_document_id));
  }, [dispatch, document]);

  return (
    <AutocompletesItem
      isLoading={isLoading}
      actions={
        isValidBatchDocument(document) && (
          <Link newTab href={document.link} className={style.link}>
            <LinkIcon />
          </Link>
        )
      }
      onRemove={onDestroy}
    >
      {document.name}
    </AutocompletesItem>
  );
}
