import { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ReactReduxContext } from 'react-redux';

import getInjectors from 'lib/reactvitam/redux/reducerInjectors';

/**
 * Dynamically injects a reducer
 *
 * @param key A key of the reducer
 * @param reducer A reducer that will be injected
 *
 */
const injectReducer = ({ key, reducer }) => WrappedComponent => {
  class ReducerInjector extends Component {
    static WrappedComponent = WrappedComponent;

    constructor(props, context) {
      super(props, context);

      getInjectors(context.store).injectReducer(key, reducer);
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />;
    }
  }

  ReducerInjector.contextType = ReactReduxContext;
  ReducerInjector.displayName = `withReducer(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return hoistNonReactStatics(ReducerInjector, WrappedComponent);
};

export default injectReducer;

export function withSlice(slice) {
  return injectReducer({
    key: slice.name,
    reducer: slice.reducer,
  });
}
