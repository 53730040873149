import { IntlShape, MessageDescriptor } from 'react-intl';
import uuid from 'uuid/v4';

/**
 * Return a string in camelCase
 *
 * @param str string to format in snakeCase
 *
 * @returns string
 */
export function toCamelCase(str: string): string {
  return str.replace(/(_\w)/g, matches => matches[1].toUpperCase());
}

/**
 * Translate an API message
 */
export function formatApiMessage(
  intl: IntlShape,
  message: string,
  vars?: Record<string, string>,
): string {
  const key = `api.${toCamelCase(message.replace(/__/g, '.'))}`;
  return intl.formatMessage(
    { id: key, defaultMessage: `Traduction manquante: "${key}"` },
    vars,
  );
}

/**
 * Returns the value of a cookie
 *
 * @param   {string}    name    The cookie name
 *
 * @return  {string}    The cookie value
 */
export function getCookie(name: string): string | null {
  const cookie = document.cookie
    .split('; ')
    .map(cookieStr => {
      const parts = cookieStr.split('=');
      return { name: parts[0], value: parts[1] };
    })
    .find(cookieObj => cookieObj.name === name);
  return cookie ? cookie.value : null;
}

/**
 * Function to generate a 32 caracter long uuid
 *
 * @returns {String} uuid
 */
export function getUuid(): string {
  return uuid().replace(/-/g, '');
}

export function safeFormatMessage(
  intl: IntlShape | undefined,
  messages: Record<string, MessageDescriptor>,
  message: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: Record<string, any>,
): string {
  if (!intl?.formatMessage || !messages[message]) {
    return message;
  }
  return intl.formatMessage({ ...messages[message] }, values);
}
