import { del, get, post, put } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { authenticate, withSessionId } from '../../../lib/decorators'
import type {
  SupplierMinimalWarehouseJSON,
  SupplierWarehouseJSON,
} from '../../../models/Supplier/Warehouse'
import { Duplicate } from './Duplicate'
import { Disabled } from './Disabled'
import { Flights } from './Flights'
import { Products } from './Products'
import { Zones } from './Zones'
import type { WarehouseBody } from './types'
import { serialize } from './serializers'

export interface SupplierWarehousesIndexFilters {
  q?: string
  department_eq?: string
  disabled_eq?: boolean
  covered_cityhall_id?: number
  page?: number
  per_page?: number
}

export class Warehouses {
  static Disabled = Disabled

  static Duplicate = Duplicate

  static Flights = Flights

  static Products = Products

  static Zones = Zones

  @authenticate
  static index(
    supplierId: number,
    filters?: SupplierWarehousesIndexFilters,
  ): ApiRequestDescriptor<SupplierMinimalWarehouseJSON[]> {
    return get(`/api/v1/suppliers/${supplierId}/warehouses`, filters)
  }

  @authenticate
  static show(id: number): ApiRequestDescriptor<SupplierWarehouseJSON> {
    return get(`/api/v1/suppliers/warehouses/${id}`)
  }

  @authenticate
  @withSessionId
  static create(
    supplierId: number,
    warehouse: Omit<WarehouseBody, 'id'>,
  ): ApiRequestDescriptor<SupplierWarehouseJSON> {
    return post(`/api/v1/suppliers/${supplierId}/warehouses`, serialize(warehouse))
  }

  @authenticate
  @withSessionId
  static update(warehouse: WarehouseBody): ApiRequestDescriptor<SupplierWarehouseJSON> {
    return put(`/api/v1/suppliers/warehouses/${warehouse.id}`, serialize(warehouse))
  }

  @authenticate
  @withSessionId
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/suppliers/warehouses/${id}`)
  }
}
