import type { ApiRequestDescriptor } from '../../lib/request'
import { get } from '../../lib/methods'
import type { CountryJSON, MinimalCountryJSON } from '../../models/Website/Country'
import { authenticateAsAdvitamService } from '../../lib/decorators'

export const Countries = {
  index: authenticateAsAdvitamService(
    (): ApiRequestDescriptor<MinimalCountryJSON[]> => get('/api/v1/website/countries'),
  ),

  show: authenticateAsAdvitamService(
    (slug: string): ApiRequestDescriptor<CountryJSON> => get(`/api/v1/website/countries/${slug}`),
  ),
}
