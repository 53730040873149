import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import { TodoItemJSON } from '@advitam/api/models/Deal/TodoItem';

import { fetchItem, fetchItems } from '../Sections/Todolist/thunk';
import { createItem, createAccount, updateItem, deleteItem } from './thunk';
import { TODO_LIST } from './constants';

export interface State {
  items: TodoItemJSON[];
  isLoading: boolean;
  error: SerializedApiError | null;
  loadingItems: number[];
}

export type AppStateSubset = {
  [TODO_LIST]: State;
};

export const initialState: State = {
  items: [],
  isLoading: true,
  error: null,
  loadingItems: [],
};

const slice = createSlice({
  name: TODO_LIST,
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    removeItem: (state, { payload }: PayloadAction<number>): State => ({
      ...state,
      items: state.items.filter(item => item.id !== payload),
    }),
  },
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchItems.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.items = payload;
    });
    builder.addCase(fetchItems.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createItem.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateItem.pending, (state, { meta }) => {
      const { id } = meta.arg;
      state.loadingItems.push(id);
    });
    builder.addCase(updateItem.fulfilled, (state, { payload }) => {
      const itemIdx = state.items.findIndex(i => i.id === payload.id);
      if (itemIdx !== -1) {
        state.items[itemIdx] = payload;
        state.loadingItems = state.loadingItems.filter(id => id !== payload.id);
      }
    });
    builder.addCase(updateItem.rejected, (state, { payload, meta }) => {
      const { id: itemId } = meta.arg;
      state.loadingItems = state.loadingItems.filter(id => id !== itemId);
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(deleteItem.pending, (state, { meta }) => {
      const { id: itemId } = meta.arg;
      state.items = state.items.filter(item => item.id !== itemId);
    });
    builder.addCase(deleteItem.rejected, (state, { payload, meta }) => {
      state.items.push(meta.arg);
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(createAccount.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(fetchItem.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      const itemIdx = state.items.findIndex(i => i.id === payload.id);

      if (itemIdx !== -1) {
        state.items[itemIdx] = payload;
      } else {
        state.items.push(payload);
      }
    });
    builder.addCase(fetchItem.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const { setIsLoading, removeItem } = slice.actions;
export default slice;
