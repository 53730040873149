import { ReactNode } from 'react'
import { Field } from 'react-final-form'

import FileInputUI from '../UI/File'
import { composeValidators, hasFileType, isRequired, Validator } from '../validators'

interface FileInputProps {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  required?: boolean
  validate?: Validator<string | null>
  renderBelow?: (value: string) => ReactNode
  className?: string
  tooltip?: ReactNode
  allowedTypes?: string[]
}

export default function FileInput({
  name,
  label,
  placeholder,
  disabled,
  required,
  validate,
  renderBelow,
  className,
  tooltip,
  allowedTypes,
}: FileInputProps): JSX.Element {
  return (
    <Field
      name={name}
      validate={composeValidators<string | null>(
        required && isRequired,
        allowedTypes && hasFileType(allowedTypes),
        validate,
      )}
    >
      {(props): JSX.Element => (
        <FileInputUI
          label={label}
          onChange={props.input.onChange}
          onBlur={props.input.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          value={props.input.value as string}
          error={props.meta.touched && !props.meta.valid}
          className={className}
          tooltip={tooltip}
          renderBelow={renderBelow}
          allowedTypes={allowedTypes}
        />
      )}
    </Field>
  )
}
