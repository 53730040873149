import { ReactNode, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, useForm, useFormState } from 'react-final-form';
import { get } from 'lodash';

import { Model } from '@advitam/api/models/Model';
import { ActionTag, Button, FormLayout, Select, Tooltip } from '@advitam/ui';
import Cross from '@advitam/ui/images/icons/times.svg';
import Plus from '@advitam/ui/images/icons/plus.svg';

import { Layout } from 'containers/Crud';
import { safeFormatMessage } from 'utils/functions.typed';

import { FormSupplierDependency } from '../../types';
import messages from './messages';
import { ALL_DEPENDENCY_GROUP } from './constants';
import { SupplierMiscForm } from './types';
import style from './Miscellaneous.module.scss';

const NEW_DEPENDENCY_PREFIX = 'sectionValues.newDependency';
const GROUP_INPUT_NAME = `${NEW_DEPENDENCY_PREFIX}.group`;
const GROUP_INPUT_DEPENDENCY_NAME = `${NEW_DEPENDENCY_PREFIX}.group_dependency`;

const REQUIRED_DEPENDENCY_ATTRIBUTES: Array<keyof FormSupplierDependency> = [
  'group',
  'group_dependency',
  'optional',
];

function isDependencyValid(
  dependency: Partial<FormSupplierDependency>,
): dependency is FormSupplierDependency {
  return REQUIRED_DEPENDENCY_ATTRIBUTES.every(attribute =>
    Object.prototype.hasOwnProperty.call(dependency, attribute),
  );
}

function p(chunk: ReactNode): ReactNode {
  return <p>{chunk}</p>;
}

export default function Dependencies(): JSX.Element {
  const intl = useIntl();

  const form = useForm<SupplierMiscForm>();
  const { values } = useFormState<SupplierMiscForm>();
  const { newDependency } = values.sectionValues;

  const { suppliers_dependencies: dependencies } = values.supplier;
  const displayedDependencies = dependencies.filter(
    dependency => !Model.isDestroyed(dependency),
  );

  const dependencyItems = ALL_DEPENDENCY_GROUP.map(group => ({
    value: group,
    name: safeFormatMessage(intl, messages, `${group}DependencyOption`),
  }));

  const onAddDependency = useCallback(() => {
    if (newDependency && isDependencyValid(newDependency)) {
      const newDependencies = [...dependencies, newDependency];
      form.change('supplier.suppliers_dependencies', newDependencies);
      form.change('sectionValues.newDependency', null);
    }
  }, [form, newDependency, dependencies]);

  const onRemoveDependency = useCallback(
    (dependencyIdx: number): void => {
      const dependencyToRemove = dependencies[dependencyIdx];
      const newDependencies = [...dependencies];

      if (!dependencyToRemove.id) {
        newDependencies.splice(dependencyIdx, 1);
      } else {
        newDependencies[dependencyIdx] = { ...dependencyToRemove };
        Model.setDestroyed(newDependencies[dependencyIdx]);
      }

      form.change('supplier.suppliers_dependencies', newDependencies);
    },
    [form, dependencies],
  );

  return (
    <Layout.Fieldset
      title={<FormattedMessage id={messages.dependencies.id} />}
      tooltip={
        <FormattedMessage id={messages.dependenciesTooltip.id} values={{ p }} />
      }
    >
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.wantedPrestation.id} />}
          name={GROUP_INPUT_NAME}
          items={dependencyItems.filter(
            ({ value }) => value !== get(values, GROUP_INPUT_DEPENDENCY_NAME),
          )}
        />
        <Select
          label={<FormattedMessage id={messages.requiredPrestation.id} />}
          name={GROUP_INPUT_DEPENDENCY_NAME}
          items={dependencyItems.filter(
            ({ value }) => value !== get(values, GROUP_INPUT_NAME),
          )}
        />
      </FormLayout.Row>
      <FormLayout.Row className={style.dependency_row}>
        <Select
          label={<FormattedMessage id={messages.functioning.id} />}
          tooltip={<FormattedMessage id={messages.functioningTooltip.id} />}
          name={`${NEW_DEPENDENCY_PREFIX}.optional`}
          items={[
            {
              value: false,
              name: intl.formatMessage(messages.generalCase),
            },
            {
              value: true,
              name: intl.formatMessage(messages.ifPrestationInDeal),
            },
          ]}
        />
        <Tooltip
          contentClassName={style.add_dependency}
          content={<FormattedMessage id={messages.addDependency.id} />}
        >
          <Button
            disabled={!newDependency || !isDependencyValid(newDependency)}
            onClick={onAddDependency}
            outline
            icon={<Plus />}
          />
        </Tooltip>
      </FormLayout.Row>

      <Field name="supplier.suppliers_dependencies">
        {(): JSX.Element => (
          <>
            {displayedDependencies.map((dependency, idx) => (
              <ActionTag
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                icon={<Cross />}
                onClick={(): void => onRemoveDependency(idx)}
                className={style.dependency}
              >
                <FormattedMessage
                  id={
                    dependency.optional
                      ? messages.optionalDependencySentence.id
                      : messages.requiredDependencySentence.id
                  }
                  values={{
                    wanted: safeFormatMessage(
                      intl,
                      messages,
                      `${dependency.group}DependencyText`,
                    ),
                    required: safeFormatMessage(
                      intl,
                      messages,
                      `${dependency.group_dependency}DependencyText`,
                    ),
                  }}
                />
              </ActionTag>
            ))}
          </>
        )}
      </Field>
    </Layout.Fieldset>
  );
}
