import { DocumentTypeJSON } from '../DocumentType'
import { Model } from '../Model'

interface SupportingDocumentIndexBase {
  id: number
  file: string
  miniature: string
  document_type: DocumentTypeJSON
}

export interface SupportingDocumentIndexJSON extends SupportingDocumentIndexBase {
  created_at: string | null
}

export interface SupportingDocumentIndex extends SupportingDocumentIndexBase {
  created_at: Date | null
}

export class SupportingDocumentIndex extends Model<SupportingDocumentIndexJSON> {
  constructor(data: SupportingDocumentIndexJSON) {
    super(data)
    this.created_at = this.created_at && Model.parseDate(data.created_at)
  }
}
