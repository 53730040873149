import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from 'react-autocomplete';

import { addParamsToQuery } from 'utils/functions';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './sagas';
import { makeSelectEntities } from './selectors';
import {
  getEntitiesList as getEntitiesListDispatch,
  eraseEntitiesList as eraseEntitiesListDispatch,
} from './actions';
import style from './style.scss';

/**
 * Autocomplete
 */
export class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /** value of autocomplete */
      value: props.initialValue,
    };
    this.timer = null;
  }

  componentDidUpdate(prevProps) {
    const { url, initialValue } = this.props;

    if (initialValue !== prevProps.initialValue) {
      this.setValue(initialValue);
    }

    if (url !== prevProps.url) {
      this.resetComponent();
    }
  }

  componentWillUnmount() {
    const { eraseEntitiesList } = this.props;
    eraseEntitiesList();
  }

  /**
   * Function to set value in state
   *
   * @param {string} value input value
   */
  setValue = value => this.setState({ value });

  /**
   * Reset data of component
   */
  resetComponent = () => {
    const { eraseEntitiesList } = this.props;
    this.setState({ value: '' }, eraseEntitiesList);
  };

  getQueryString = value => {
    const {
      paramsToAddToQuery,
      paramsToAddToQueryFormat,
      url,
      isEncodingQuery,
    } = this.props;
    let queryString = `${url}${value}`;

    if (isEncodingQuery) queryString = `${url}${encodeURIComponent(value)}`;

    if (paramsToAddToQuery && queryString) {
      queryString = addParamsToQuery(
        paramsToAddToQuery,
        queryString,
        paramsToAddToQueryFormat,
      );
    }

    return queryString;
  };

  /**
   * On change autocomplete
   *
   * @param {Object} event node targeted by the user
   * @param {String} value value of autocomplete
   */
  onChange = (event, value) => {
    const { getEntitiesList, throttle, onChange } = this.props;
    this.setState({ value });
    clearTimeout(this.timer);

    const queryString = this.getQueryString(value);

    this.timer = setTimeout(
      () => getEntitiesList(queryString),
      throttle || 250,
    );
    if (onChange !== undefined) {
      onChange(event, value);
    }
  };

  /**
   * On select item of autocomplete
   *
   * @param {String} valueSelected  value selected
   * @param {Object} item item containing the value selected
   */
  onSelect = (valueSelected, item) => {
    const {
      onSelect,
      saveValueOnSelect,
      getValue,
      keepValueAfterSelect,
    } = this.props;
    if (!keepValueAfterSelect) this.resetComponent();
    onSelect(valueSelected, item);
    if (saveValueOnSelect) this.setState({ value: getValue(item) });
  };

  onFocus = () => {
    const { value } = this.state;
    const { getEntitiesList, eraseEntitiesList } = this.props;
    eraseEntitiesList();

    const queryString = this.getQueryString(value);
    getEntitiesList(queryString);
  };

  render() {
    const {
      entities,
      placeholder,
      getValue,
      getKey,
      fullWidth,
      shouldItemRender,
      disabled,
      error,
    } = this.props;
    const { value } = this.state;
    const inputClasses = ['autocomplete__input', error && style.errored].filter(
      Boolean,
    );

    const autoCompleteProps = {
      items: entities,
      value,
      onChange: this.onChange,
      inputProps: {
        className: inputClasses.join(' '),
        placeholder: placeholder || 'Recherche...',
        disabled,
        onFocus: this.onFocus,
      },
      wrapperProps: {
        className: fullWidth ? 'autocomplete--fullWidth' : 'autocomplete',
      },
      getItemValue: item => getValue(item) || '',
      onSelect: (valueSelected, item) => this.onSelect(valueSelected, item),
      renderMenu: items => <div className="autocomplete__menu">{items}</div>,
      renderItem: (item, isHighlighted) => (
        <div
          className={`autocomplete__menu__item ${
            isHighlighted ? 'autocomplete__menu__item--selected' : ''
          }`}
          key={getKey(item)}
        >
          {getValue(item)}
        </div>
      ),
      shouldItemRender,
    };

    return <Autocomplete {...autoCompleteProps} />;
  }
}

AutoComplete.propTypes = {
  /** Url of the autocomplete */
  url: PropTypes.string.isRequired,
  /** Function to get the key of an item  */
  getKey: PropTypes.func.isRequired,
  /** Function to get the value of an item */
  getValue: PropTypes.func.isRequired,
  /** initialValue of input */
  initialValue: PropTypes.string,
  /** Time throttle for api request */
  throttle: PropTypes.number,
  /** Function to select value */
  onSelect: PropTypes.func,
  /** Function to change value */
  onChange: PropTypes.func,
  /** Placeholder of autocomplete */
  placeholder: PropTypes.string,
  /** List of suggestions */
  entities: PropTypes.array,
  /** Get list of suggestions */
  getEntitiesList: PropTypes.func,
  /** Remove list of suggestions */
  eraseEntitiesList: PropTypes.func,
  /** Params to add to the query */
  paramsToAddToQuery: PropTypes.object,
  /** Params for qs stringify format */
  paramsToAddToQueryFormat: PropTypes.object,
  /** Is it fullwidth */
  fullWidth: PropTypes.bool,
  /** Does it keep the value after on select */
  saveValueOnSelect: PropTypes.bool,
  /** Should we keep the value in the input after a select */
  keepValueAfterSelect: PropTypes.bool,
  /** should we encode the query string */
  isEncodingQuery: PropTypes.bool,
  auth: PropTypes.bool,
  shouldItemRender: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  entities: makeSelectEntities(),
});

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    getEntitiesList: url =>
      dispatch(
        getEntitiesListDispatch(url, ownProps.parseResponse, ownProps.auth),
      ),
    eraseEntitiesList: () => dispatch(eraseEntitiesListDispatch()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AutoComplete);

export const withAutocomplete = [
  injectReducer({ key: 'autoComplete', reducer }),
  injectSaga({ key: 'autoComplete', saga }),
];
