import { ReactNode } from 'react'
import { Field } from 'react-final-form'

import { Number } from '../converters'
import { Range } from '../UI'
import { Validator } from '../validators'

interface RangeFieldProps {
  name: string
  max: number
  validate?: Validator<number | null>
  /** Formatter for the displayed value, to not be confused with Field.format() */
  formatValue?: (v: number) => ReactNode
}

export default function RangeField({
  name,
  max,
  validate,
  formatValue,
}: RangeFieldProps): JSX.Element {
  return (
    <Field name={name} validate={validate} parse={Number.parse}>
      {({ input: { onChange, value } }): JSX.Element => (
        <Range
          name={name}
          onChange={onChange}
          value={(value as unknown) as number}
          max={max}
          formatValue={formatValue}
        />
      )}
    </Field>
  )
}
