import { Reducer } from 'redux'

import createReducer from './createReducer'
import { Store } from './Store'

export type ReducerInjector = (key: string, reducer: Reducer) => void

export function injectReducer(store: Store): (key: string, reducer: Reducer) => void {
  return (key: string, reducer: Reducer): void => {
    // Check `store.injectedReducers[key] === reducer` for hot reloading when a key is the same but a reducer is different
    if (Reflect.has(store.injectedReducers, key) && store.injectedReducers[key] === reducer) {
      return
    }

    store.injectedReducers[key] = reducer // eslint-disable-line no-param-reassign
    store.replaceReducer(createReducer(store.getState(), store.injectedReducers))
  }
}

export default function getInjectors(
  store: Store,
): {
  injectReducer: ReducerInjector
} {
  return {
    injectReducer: injectReducer(store),
  }
}
